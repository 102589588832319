import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";
import { addCallback } from "../../common";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { BLOCK_TYPES } from "../../common/index";
import { MCQ_DEFAULT_VALUE, renderCardMCQ } from "../MCQ";
import { renderCardV2Mixed } from "../V2Mixed";
import { arrayMoveImmutable } from "array-move";
import getV2FillBlankDefaultValue from "../V2FillBlank/defaultValue";
import { getBareBlock } from "../..";
import { V2_STORIES_DEFAULT_VALUE, renderCardV2Stories } from "../V2Stories";
import { renderCardV2Voice } from "../V2Voice";
import { renderCardCrossword } from "../Crossword";

const {
    MCQ_BLOCK,
    V2_MIXED_BLOCK,
    V2_RAPID_QA_BLOCK,
    V2_STORIES_BLOCK,
    V2_VOICE_BLOCK,
    CROSSWORD_BLOCK,
} = BLOCK_TYPES;

const renderCardV2RapidQA = (props: any) => {
    const {
        block,
        setBlock,
        idx,
        setCurrentBlock,
        currentBlock,
        currentSubBlock,
        setCurrentSubBlock,
        setSaveCounter,
        setIsNewBlock,
        setIsEditor,

        cardTitle = "Rapid QA Block",
        blockType = V2_RAPID_QA_BLOCK,
    } = props;
    const {
        data: {
            [blockType]: { title },
        },
        children,
    } = block;

    const subCardStyle = { width: "80%", marginLeft: "18%" };

    const SortableItemCustom = SortableElement((props: any) => {
        const { blocks, sid, child, difficultyIndex } = props;
        const ribbonCollorDict = {
            easy: "#27AE60",
            medium: "#2980B9",
            hard: "#E74C3C",
        };

        const ribbonColor =
            blockType === V2_RAPID_QA_BLOCK
                ? ribbonCollorDict[child?.data?.other?.difficulty_level]
                : "purple";

        const cardProps = {
            ...props,
            child,
            idx: sid,
            type: child.type,
            block: child,
            currentBlock: currentBlock == idx ? currentSubBlock : null,
            currentSubBlock,
            setCurrentBlock: () => {
                setCurrentBlock(idx);
                setCurrentSubBlock(sid);
            },
            deleteBlock: (index: number) => {
                setCurrentSubBlock(null);
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = [...children];
                _.pullAt(tmpChildren, sid);
                tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);
                setBlock(tmpBlock, idx);
            },
            disableDuplicateAction: false,
            ribbonColor,
            ribbonText:
                blockType === V2_RAPID_QA_BLOCK
                    ? (
                          (child?.data?.other?.difficulty_level || "")[0] || ""
                      ).toUpperCase() +
                      (difficultyIndex !== null ? `${difficultyIndex + 1}` : "")
                    : null,
            addBlockCustom: (type: string, index: number, newBlock: any) => {
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = [...children];
                const newBlock2 = newBlock
                    ? newBlock
                    : { tmpId: uuid(), ...getBareBlock(type) };
                tmpChildren.splice(index, 0, newBlock2);
                tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);

                setBlock(tmpBlock);
                setCurrentBlock(idx);
                setCurrentSubBlock(index);
            },
        };

        const renderChildCard = () => {
            switch (child.type) {
                case MCQ_BLOCK:
                    return renderCardMCQ(cardProps);
                case V2_STORIES_BLOCK:
                    return renderCardV2Stories(cardProps);
                case V2_MIXED_BLOCK:
                    return renderCardV2Mixed(cardProps);
                case V2_VOICE_BLOCK:
                    return renderCardV2Voice(cardProps);
                case CROSSWORD_BLOCK:
                    return renderCardCrossword(cardProps);
                default:
                    return <></>;
            }
        };

        return (
            <div
                key={`${idx}_${sid}`}
                style={{ position: "relative", zIndex: 999 }}
            >
                {renderChildCard()}
            </div>
        );
    });

    const SortableContainerCustom = SortableContainer(({ children }: any) => {
        return <div>{children}</div>;
    });

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            children,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);
        setBlock(tmpBlock, idx);
    };

    const difficultyStore = {
        easy: 0,
        medium: 0,
        hard: 0,
    };

    const displayText = title
        ? title.slice(0, title.length > 10 ? 10 : title.length)
        : "...";
    return (
        <div style={{ position: "relative", zIndex: 99 }}>
            <BlockCard {...props}>
                {displayText}

                <div style={{ fontSize: "11px", fontWeight: "bold" }}>
                    {cardTitle}
                </div>
            </BlockCard>

            <div style={subCardStyle}>
                <SortableContainerCustom onSortEnd={onSortEnd} useDragHandle>
                    {children.map((child: any, sid: number) => {
                        const difficultyIndex =
                            difficultyStore[
                                child?.data?.other?.difficulty_level
                            ];

                        difficultyStore[child?.data?.other?.difficulty_level] =
                            difficultyStore[
                                child?.data?.other?.difficulty_level
                            ] + 1;

                        return (
                            <SortableItemCustom
                                {...props}
                                sid={sid}
                                key={`nested-item-${child.id || child.tmpId}`}
                                index={sid}
                                child={child}
                                difficultyIndex={difficultyIndex}
                            />
                        );
                    })}
                </SortableContainerCustom>
            </div>

            <div
                style={subCardStyle}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <BlockCard
                    {...props}
                    disableActions={true}
                    onClick={() => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["children", children.length],
                            {
                                ...MCQ_DEFAULT_VALUE,
                                order: children.length + 1,
                                tmpId: uuid(),
                            },
                        );
                        const tmpBlocks = setBlock(tmpBlock, idx);
                        setCurrentBlock(idx);
                        setCurrentSubBlock(children.length);
                        addCallback({
                            setSaveCounter,
                            currentBlocks: tmpBlocks,
                        });
                        setIsNewBlock(true);
                    }}
                    idx={`+`}
                    ribbonColor="green"
                >
                    Add MCQ
                </BlockCard>
            </div>

            <div
                style={subCardStyle}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <BlockCard
                    {...props}
                    disableActions={true}
                    onClick={() => {
                        const V2_MIXED_DEFAULT_VALUE = {
                            type: V2_MIXED_BLOCK,
                            data: {
                                [V2_MIXED_BLOCK]: {
                                    text: [],
                                },
                                other: {
                                    duration: 0,
                                    audio: "",
                                    difficulty_level: "medium",
                                },
                                children: [getV2FillBlankDefaultValue()],
                            },
                        };

                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["children", children.length],
                            {
                                ...V2_MIXED_DEFAULT_VALUE,
                                order: children.length + 1,
                                tmpId: uuid(),
                            },
                        );
                        const tmpBlocks = setBlock(tmpBlock, idx);
                        setCurrentBlock(idx);
                        setCurrentSubBlock(children.length);
                        addCallback({
                            setSaveCounter,
                            currentBlocks: tmpBlocks,
                        });
                        setIsNewBlock(true);
                    }}
                    idx={`+`}
                    ribbonColor="green"
                >
                    Add Mixed
                </BlockCard>
            </div>

            <div
                style={subCardStyle}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <BlockCard
                    {...props}
                    disableActions={true}
                    onClick={() => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["children", children.length],
                            {
                                ...V2_STORIES_DEFAULT_VALUE(),
                                order: children.length + 1,
                                tmpId: uuid(),
                            },
                        );
                        const tmpBlocks = setBlock(tmpBlock, idx);
                        setCurrentBlock(idx);
                        setCurrentSubBlock(children.length);
                        addCallback({
                            setSaveCounter,
                            currentBlocks: tmpBlocks,
                        });
                        setIsNewBlock(true);
                    }}
                    idx={`+`}
                    ribbonColor="green"
                >
                    Add Stories
                </BlockCard>
            </div>
        </div>
    );
};
export default renderCardV2RapidQA;
