const convertPathToClipPath = (pathData) => {
    // Step 1: Extract Commands and Coordinates from the Path Data
    const commands = pathData.match(/[a-zA-Z][^a-zA-Z]*/g);

    let points = [];
    let currentPoint = { x: 0, y: 0 };

    commands.forEach((command) => {
        const type = command[0];
        const args = command
            .slice(1)
            .trim()
            .split(/[\s,]+/)
            .map(Number);

        switch (type) {
            case "M": // Move to absolute
            case "L": // Line to absolute
                currentPoint = { x: args[0], y: args[1] };
                points.push({ ...currentPoint });
                break;
            case "H": // Horizontal line to absolute
                currentPoint.x = args[0];
                points.push({ ...currentPoint });
                break;
            case "V": // Vertical line to absolute
                currentPoint.y = args[0];
                points.push({ ...currentPoint });
                break;
            case "Z": // Close path
                // Optionally handle closing the path
                break;
            // Add cases for other SVG commands if necessary
        }
    });

    // Step 2: Find Min and Max Values for Normalization
    const xValues = points.map((p) => p.x);
    const yValues = points.map((p) => p.y);

    const minX = Math.min(...xValues);
    const maxX = Math.max(...xValues);
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);

    const xRange = maxX - minX;
    const yRange = maxY - minY;

    // Step 3: Normalize the Points to Range 1-100
    const normalizedPoints = points.map((point) => {
        const normalizedX = ((point.x - minX) / xRange) * 99 + 1;
        const normalizedY = ((point.y - minY) / yRange) * 99 + 1;
        return { x: normalizedX, y: normalizedY };
    });

    // Step 4: Create Points Data String
    const pointsString = normalizedPoints
        .map((point) => `${point.x},${point.y}`)
        .join(" ");

    // Step 5: Convert Points to Clip-Path Format
    const clipPath = pointsString
        .split(" ")
        .map((point) => {
            const [x, y] = point.split(",");
            return `${x}% ${y}%`;
        })
        .join(", ");

    return clipPath;
};

export default convertPathToClipPath;
