import { ConceptList, ConceptCreate, ConceptEdit } from "./views";

import { Route } from "react-router-dom";
export const CONCEPT_ROUTES = [
    <Route path="/concept" element={<ConceptList />} />,
    <Route path="/concept/create" element={<ConceptCreate />} />,
    <Route path="/concept/update/:concept_id" element={<ConceptEdit />} />,
];

export { ConceptList, ConceptCreate, ConceptEdit } from "./views";
