import { EditIcon, EllipseIcon, ViewIcon } from "src/components";
import { Can } from "src/services/casl";
import CopyWorksheetModal from "src/modules/worksheet/components/custom/CopyWorksheetModal";
import DeleteView from "src/modules/worksheet/components/custom/DeleteView";
import DuplicateWorksheetButton from "src/modules/worksheet/components/custom/DuplicateWorksheetButton";
import PublishWorksheetButton from "src/modules/worksheet/components/custom/PublishWorksheetButton";
import { Link } from "src/components";
import { Button, Divider, Dropdown, Menu, Space, message } from "antd";
import { CompressOutlined } from "@ant-design/icons";

export const renderAction = ({
    record,
    userData,
    updateUserOther,
    group_id,
    dataSource,
    refetchWorksheets,
    redirect_to,
    tabType,
}: any) => {
    const menu = (
        <Menu
            items={[
                {
                    key: "1",
                    label: <CopyWorksheetModal worksheetId={record?.id} />,
                },
                {
                    key: "2",
                    label: (
                        <span
                            onClick={() => {
                                const { other, id } = userData;
                                const payload = {
                                    content_testing_mobile_data: {
                                        testing_type: "worksheet",
                                        worksheet_id: record?.id,
                                    },
                                };
                                updateUserOther({
                                    id,
                                    other: other
                                        ? { ...other, ...payload }
                                        : payload,
                                });
                            }}
                        >
                            Mirror Preview
                        </span>
                    ),
                },
            ]}
        />
    );
    return (
        <Space size="small">
            <a href={`/worksheet/preview/${record?.id}`} target="_blank">
                <ViewIcon isButton={true} />
            </a>
            <Divider />

            <Can I="create" a={"worksheet"}>
                {(allowed) => (
                    <DuplicateWorksheetButton
                        worksheetId={record.id}
                        groupId={group_id}
                        order={dataSource?.length || 1}
                        callback={refetchWorksheets}
                        disabled={!allowed}
                    />
                )}
            </Can>
            <Divider />

            <Can I="edit" a={"worksheet"} passThrough>
                {(allowed: any) => (
                    <Can I="read" a={"worksheet_block"} passThrough>
                        {(allowedRead: any) => (
                            <Link
                                href={{
                                    pathname:
                                        record?.type ===
                                        "personalized_learning_v4"
                                            ? `/worksheet/update/${record?.id}`
                                            : `/worksheet/update_enhanced/${record?.id}`,
                                    query: {
                                        redirect_to,
                                        group_id,
                                        tabType,
                                    },
                                }}
                            >
                                <EditIcon
                                    isButton={true}
                                    disabled={!allowed && !allowedRead}
                                />
                            </Link>
                        )}
                    </Can>
                )}
            </Can>

            <Divider />
            <Can I="delete" a={"worksheet"} passThrough>
                {(allowed) =>
                    allowed && (
                        <DeleteView
                            id={record?.id}
                            isButton={true}
                            disabled={!allowed}
                            callback={refetchWorksheets}
                        />
                    )
                }
            </Can>

            <Button
                icon={<CompressOutlined />}
                shape="circle"
                type="dashed"
                onClick={async () => {
                    const { other, id } = userData;
                    const payload = {
                        content_testing_mobile_data: {
                            testing_type: "worksheet",
                            worksheet_id: record?.id,
                        },
                    };
                    await updateUserOther({
                        id,
                        other: other ? { ...other, ...payload } : payload,
                    });
                    message.success("successfully mirrored!");
                }}
            />
            <Divider />

            {/* <Can I="publish" a={"worksheet"} passThrough>
                {(allowed) =>
                    allowed && (
                        <PublishWorksheetButton
                            worksheet_id={record?.id}
                            disabled={false}
                        />
                    )
                }
            </Can> */}

            <Divider />

            <Can I="create" a={"worksheet"}>
                {(allowed) => (
                    <Dropdown overlay={menu} trigger={["click"]}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <EllipseIcon isButton={true} type={"dashed"} />
                            </Space>
                        </a>
                    </Dropdown>
                )}
            </Can>
        </Space>
    );
};
