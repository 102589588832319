import { CheckOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons";
import {
    Badge,
    Divider,
    Switch,
    Form,
    Space,
    Row,
    Col,
    Button,
    Card,
} from "antd";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import React, { useState } from "react";
import { BLOCK_TYPES } from "../../../common";
import { arrayMoveImmutable } from "array-move";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { Actions } from "src/modules/worksheet/components/WorksheetEditor/components/Editor/BlocksColumn/Actions";
import {
    renderEditorV2Stories,
    V2_STORIES_DEFAULT_VALUE,
} from "../../V2Stories";

const { V2_STORIES_BLOCK } = BLOCK_TYPES;

const EditorV2Stories = renderEditorV2Stories;

const LearnigModeEditor = (props: any) => {
    const {
        data,
        setConfigChildren,
        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        disableWrite,
        isReadOnlyMode,
    } = props;
    const {
        other: { children = [] },
    } = data;

    const [currentSubBlock, setCurrentSubBlock] = useState(-1);

    const [isPlaying, setIsPLaying] = useState(false);

    const updateCurrentBlock = (val: number) => {
        setIsPLaying(false);
        setCurrentSubBlock(val);
    };

    const editorProps = {
        block: children[currentSubBlock],
        setBlock: (subBlock: any) => {
            let tmpBlock = _.cloneDeep(children);
            tmpBlock = _.set(tmpBlock, [currentSubBlock], subBlock);
            setConfigChildren(tmpBlock);
        },
        isEditor,
        setIsEditor,
        currentBlock: currentSubBlock + 1,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        childBlockCount: children?.length || 0,
        isPlaying,
        setIsPLaying,
        disableWrite,
        isReadOnlyMode,
    };

    const DragHandle = SortableHandle(() => <MenuOutlined />);

    const SortableItem = SortableElement(({ value, child }) => {
        const actionsProps = {
            deleteBlock: () => {
                updateCurrentBlock(currentSubBlock - 1);
                const tmpOptions = [...children];
                _.pullAt(tmpOptions, currentSubBlock);
                setConfigChildren(tmpOptions);
            },
            idx: currentSubBlock,
            addBlockCustom: (type: string, index: number, newBlock: any) => {
                const tmpChildren = [...children];
                tmpChildren.splice(index, 0, newBlock);
                setConfigChildren(tmpChildren);
                setCurrentSubBlock(index);
            },
            block: child,
        };

        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Badge.Ribbon
                    color={"cyan"}
                    text={value + 1}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            background:
                                currentSubBlock === value ? "#F1F1F1" : "",
                            borderBottom:
                                child?.type === V2_STORIES_BLOCK &&
                                child?.data?.children?.length <= 0
                                    ? "3px solid red"
                                    : currentSubBlock === value
                                    ? "3px solid indigo"
                                    : "",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            minWidth: "90px",
                        }}
                        bodyStyle={{
                            background: "transparent",
                        }}
                        onClick={() => {
                            updateCurrentBlock(value);
                        }}
                    >
                        {child?.type === V2_STORIES_BLOCK
                            ? "Stories"
                            : child?.type === "learning_mode"
                            ? "Learning Mode"
                            : "Unsupported"}

                        {currentSubBlock === value && !disableWrite && (
                            <div
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    padding: "2px",
                                }}
                            >
                                <DragHandle />
                            </div>
                        )}
                    </Card>
                </Badge.Ribbon>

                {currentSubBlock === value && !disableWrite && (
                    <Actions {...actionsProps} />
                )}
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={index}
                        child={child}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const tmpChildren = arrayMoveImmutable(
            children,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        setConfigChildren(tmpChildren);
        updateCurrentBlock(newIndex);
    };

    return (
        <Row>
            <Col span={24}>
                <Form.Item label="Enabled?">
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={true}
                        disabled
                    />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item label="Show Children Details">
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={currentSubBlock !== -1}
                        onChange={(value) => {
                            setCurrentSubBlock(
                                value && children?.length > 0 ? 0 : -1,
                            );
                        }}
                    />
                </Form.Item>
            </Col>
            {!disableWrite && (
                <Col span={24}>
                    <Space>
                        <Button
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(children);
                                tmpBlock = _.set(tmpBlock, [children.length], {
                                    ...V2_STORIES_DEFAULT_VALUE(),
                                    order: children.length + 1,
                                    tmpId: uuid(),
                                });
                                setConfigChildren(tmpBlock);
                                updateCurrentBlock(children.length);
                            }}
                        >
                            Add Stories Block
                        </Button>
                        <Button
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(children);
                                tmpBlock = _.set(tmpBlock, [children.length], {
                                    type: "learning_mode",
                                    data: {},
                                    order: children.length + 1,
                                    tmpId: uuid(),
                                });
                                setConfigChildren(tmpBlock);
                                updateCurrentBlock(children.length);
                            }}
                        >
                            Add Learning Mode Block
                        </Button>
                    </Space>
                </Col>
            )}
            <Divider />
            <Col span={24}>
                <SortableList
                    items={children}
                    onSortEnd={onSortEnd}
                    axis="xy"
                    useDragHandle
                />
            </Col>
            <Col span={24}>
                <hr style={{ margin: "20px 0" }} />
                {currentSubBlock !== -1 &&
                    children[currentSubBlock] &&
                    children[currentSubBlock]?.type === V2_STORIES_BLOCK && (
                        <EditorV2Stories {...editorProps} />
                    )}

                {currentSubBlock !== -1 &&
                    children[currentSubBlock] &&
                    children[currentSubBlock]?.type === "learning_mode" && (
                        <div>Learning Mode</div>
                    )}
            </Col>
        </Row>
    );
};

export default LearnigModeEditor;
