import React from "react";
import { Layout as AntdLayout, Breadcrumb, BackTop, Button } from "antd";
import { ArrowUpOutlined, HomeOutlined } from "@ant-design/icons";

const { Content } = AntdLayout;

import { Header, Sider, Footer } from "src/components";
import { Link } from "src/components";

const SiderDemo = (props) => {
    const { titles, page } = props;

    const breadcrumbItem = (title) => (
        <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
            {title.name}
        </Breadcrumb.Item>
    );

    return (
        <AntdLayout style={{ minHeight: "100vh" }}>
            <Sider />

            <AntdLayout className="site-layout">
                <Header />

                <Content style={{ margin: "0 16px" }}>
                    <Breadcrumb style={{ margin: "16px 10px" }}>
                        <Link href={"/"} passHref>
                            <Breadcrumb.Item>
                                <HomeOutlined
                                    hidden={!!page?.includes("question")}
                                />
                            </Breadcrumb.Item>
                        </Link>

                        {titles && titles.length > 0 && (
                            <>
                                {titles.map((title) =>
                                    title.link && title.link !== "" ? (
                                        <Link href={title.link} passHref>
                                            {breadcrumbItem(title)}
                                        </Link>
                                    ) : (
                                        breadcrumbItem(title)
                                    ),
                                )}
                            </>
                        )}
                    </Breadcrumb>

                    <div style={{ padding: 12, minHeight: "75vh" }}>
                        {props.children}
                    </div>
                </Content>

                <BackTop>
                    <Button
                        icon={<ArrowUpOutlined />}
                        type="primary"
                        shape="circle"
                        size="large"
                    />
                </BackTop>
                <Footer />
            </AntdLayout>
        </AntdLayout>
    );
};

export const Layout = SiderDemo;
