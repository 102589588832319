import { BLOCK_TYPES } from "../../common/index";
const { V2_TEXT_BLOCK } = BLOCK_TYPES;

const V2_TEXT_DEFAULT_VALUE = {
    type: V2_TEXT_BLOCK,
    data: {
        [V2_TEXT_BLOCK]: "",
        textAlign: "left"
    },
};

export default V2_TEXT_DEFAULT_VALUE;
