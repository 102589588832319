import { BLOCK_TYPES } from "../../common/index";
const { IMAGE_BLOCK } = BLOCK_TYPES;
const IMAGE_DEFAULT_VALUE = {
    type: IMAGE_BLOCK,
    data: {
        image: "",
        jumpTo: {
            default: null,
            conditions: [],
        },
        other: {
            duration: 0,
        },
    },
};

export default IMAGE_DEFAULT_VALUE;
