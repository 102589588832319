import { StudentList, StudentCreate, StudentEdit } from "./views";

import { Route } from "react-router-dom";
export const STUDENT_ROUTES = [
    <Route path="/student" element={<StudentList />} />,

    <Route path="/student/create" element={<StudentCreate />} />,
    <Route path="/student/update/:student_id" element={<StudentEdit />} />,
];

export { StudentList, StudentCreate, StudentEdit } from "./views";
