import { UserList, UserCreate, UserEdit } from "./views";

import { Route } from "react-router-dom";
export const USER_ROUTES = [
    <Route path="/user" element={<UserList />} />,

    <Route path="/user/create" element={<UserCreate />} />,
    <Route path="/user/update/:user_id" element={<UserEdit />} />,
];

export { UserList, UserCreate, UserEdit } from "./views";
