import { SlateEditor } from "src/components/plate";
import { DatePicker, Form, Select, TimePicker } from "antd";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { renderer } from "../../helpers";
import moment from "moment";

const renderEditorCountDown = ({
    block,
    setBlock,
    currentEditor,
    setCurrentEditor,
    hasMentions = false,
    mentionsList = [],
    disableWrite,
}: any) => {
    const {
        id,
        tmpId,
        data: { v2_countdown, textAlign },
        other: { date_value, time_value },
    } = block;
    console.log(date_value, "data_value");
    const isValid = Boolean(renderer(v2_countdown));
    return (
        <div>
            <Form.Item
                rules={[
                    {
                        required: true,
                    },
                ]}
                validateStatus={!isValid ? "error" : ""}
                help={!isValid ? "Field cannot be empty" : ""}
            >
                <SlateEditor
                    id={`${id || tmpId}_text`}
                    onChange={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["data", "v2_countdown"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    value={v2_countdown}
                    isFocused={currentEditor == `${id || tmpId}_text`}
                    setEditor={(id: string) => setCurrentEditor(id)}
                    v2={true}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                />
            </Form.Item>

            <Form.Item label="Text Align">
                <Select
                    value={textAlign || "left"}
                    placeholder="Select type"
                    options={[
                        { label: "Left", value: "left" },
                        { label: "Center", value: "center" },
                        { label: "Right", value: "right" },
                        { label: "Justify", value: "justify" },
                    ]}
                    onChange={(val) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(tmpBlock, ["data", "textAlign"], val);
                        setBlock(tmpBlock);
                    }}
                    disabled={disableWrite}
                />
            </Form.Item>

            <DatePicker
                value={date_value ? moment(date_value) : undefined}
                onChange={(value, dateString) => {
                    let tmpBlock = _.cloneDeep(block);
                    tmpBlock = _.set(
                        tmpBlock,
                        ["other", "date_value"],
                        dateString,
                    );
                    setBlock(tmpBlock);
                }}
                allowClear={false}
            />
            <TimePicker
                value={time_value ? moment(time_value, "HH:mm:ss") : undefined}
                onChange={(value, timeString) => {
                    let tmpBlock = _.cloneDeep(block);
                    tmpBlock = _.set(
                        tmpBlock,
                        ["other", "time_value"],
                        timeString,
                    );
                    setBlock(tmpBlock);
                }}
                allowClear={false}
            />
        </div>
    );
};

export default renderEditorCountDown;
