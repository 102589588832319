import React from "react";
import { usePlateEditorRef } from "@udecode/plate-core";
import { insertAudio } from "../../node";
import { ToolbarButton, ToolbarButtonProps } from "@udecode/plate-ui-toolbar";

export interface AudioToolbarButtonProps extends ToolbarButtonProps {}

export const AudioToolbarButton = (props: AudioToolbarButtonProps) => {
    const editor = usePlateEditorRef()!;

    return (
        <ToolbarButton
            onMouseDown={async (event) => {
                if (!editor) return;

                event.preventDefault();

                let audioString = "";

                insertAudio(editor, audioString);
            }}
            {...props}
        />
    );
};
