import { Divider, Input, List, Switch } from "antd";
import { Form, Button, message, Select } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
    COMPUTATION_OPERATION_TYPE,
    COMPUTE_CONJUNCTIONS,
    CROSSWORD_CONDITION_COMPUTATIONS,
    DIGIT_POSITIONS,
    OPERATOR_OPTIONS,
} from "../../helpers/constants";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const ComputeFunctionEditor = (props: any) => {
    const {
        disableWrite,
        item: computeCondition,
        index,
        updateCondition,
        variables = [],
        deleteComputeFunction,
    } = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading)
            setTimeout(() => {
                setLoading(false);
            }, 50);
    }, [loading]);

    return (
        <>
            {index > 0 && (
                <Form.Item>
                    <Divider />
                    <Select
                        disabled={disableWrite}
                        defaultValue={computeCondition?.conjunction}
                        onChange={(type) => {
                            let tmpBlock = _.cloneDeep(computeCondition);
                            tmpBlock = _.set(tmpBlock, ["conjunction"], type);
                            updateCondition(tmpBlock);
                        }}
                        options={COMPUTE_CONJUNCTIONS}
                    />
                </Form.Item>
            )}

            <div style={{ display: "flex", gap: "12px", marginBottom: "20px" }}>
                <div
                    style={{
                        borderRadius: "50%",
                        width: "24px",
                        height: "24px",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#4EDEB2",
                        color: "#333333",
                        display: "inline-flex",
                        fontSize: "12px",
                        fontWeight: "bold",
                    }}
                >
                    {index + 1}
                </div>
                <Button
                    danger
                    disabled={disableWrite}
                    onClick={deleteComputeFunction}
                >
                    Delete
                </Button>
            </div>

            <Form.Item label="Type">
                <Select
                    disabled={disableWrite}
                    defaultValue={computeCondition?.type}
                    onChange={(type) => {
                        let tmpBlock = _.cloneDeep(computeCondition);
                        tmpBlock = _.set(tmpBlock, ["type"], type);
                        updateCondition(tmpBlock);
                    }}
                    options={CROSSWORD_CONDITION_COMPUTATIONS}
                />
            </Form.Item>

            <Form.Item
                label="Variables to Check"
                validateStatus={
                    !computeCondition?.variables?.length ? "error" : ""
                }
                help={
                    !computeCondition?.variables?.length
                        ? "Field cannot be empty"
                        : ""
                }
            >
                <Form.Item label="Select All">
                    <Switch
                        disabled={
                            disableWrite ||
                            computeCondition?.variables?.length ===
                                variables?.length
                        }
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={
                            computeCondition?.variables?.length ===
                            variables?.length
                        }
                        onChange={(value) => {
                            if (value) {
                                let tmpBlock = _.cloneDeep(computeCondition);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["variables"],
                                    variables,
                                );

                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["number_operation"],
                                    variables.map((v: any) => ({
                                        type: "variable",
                                        value: v,
                                    })),
                                );

                                updateCondition(tmpBlock);
                                setLoading(true);
                            }
                        }}
                    />
                </Form.Item>
                {!loading && (
                    <Select
                        placeholder="Variables to Check"
                        disabled={disableWrite}
                        mode="multiple"
                        defaultValue={computeCondition?.variables}
                        onChange={(data) => {
                            let tmpBlock = _.cloneDeep(computeCondition);
                            tmpBlock = _.set(tmpBlock, ["variables"], data);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["number_operation"],
                                data.map((v: any) => ({
                                    type: "variable",
                                    value: v,
                                })),
                            );
                            updateCondition(tmpBlock);
                        }}
                        options={variables.map((v: string) => ({
                            value: v,
                            label: v,
                        }))}
                    />
                )}
            </Form.Item>

            {!loading && computeCondition?.type === "digit_operation" && (
                <Form.Item
                    label="Digit Positions"
                    validateStatus={
                        !computeCondition?.digit_operation?.length
                            ? "error"
                            : ""
                    }
                    help={
                        !computeCondition?.digit_operation?.length
                            ? "Field cannot be empty"
                            : ""
                    }
                >
                    <Select
                        disabled={disableWrite}
                        mode="multiple"
                        defaultValue={computeCondition?.digit_operation.map(
                            (v: { value: any }) => v?.value,
                        )}
                        onChange={(data) => {
                            let tmpBlock = _.cloneDeep(computeCondition);

                            const isAll = data.includes("all");
                            tmpBlock = _.set(
                                tmpBlock,
                                ["digit_operation"],
                                isAll
                                    ? [{ type: "position", value: "all" }]
                                    : data.map((v: any) => ({
                                          type: "position",
                                          value: v,
                                      })),
                            );
                            updateCondition(tmpBlock);
                            if (isAll) setLoading(true);
                        }}
                        options={[
                            { label: "All", value: "all" },
                            ...DIGIT_POSITIONS,
                        ]}
                    />
                </Form.Item>
            )}

            {["digit_operation", "number_operation"].includes(
                computeCondition?.type,
            ) && (
                <Form.Item
                    label="Operation Type"
                    validateStatus={
                        !computeCondition?.operation_type ? "error" : ""
                    }
                    help={
                        !computeCondition?.operation_type
                            ? "Field cannot be empty"
                            : ""
                    }
                >
                    <Select
                        placeholder="Operation Type"
                        disabled={disableWrite}
                        defaultValue={computeCondition?.operation_type}
                        onChange={(type) => {
                            let tmpBlock = _.cloneDeep(computeCondition);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["operation_type"],
                                type,
                            );
                            updateCondition(tmpBlock);
                        }}
                        options={COMPUTATION_OPERATION_TYPE}
                    />
                </Form.Item>
            )}

            {[
                "digit_operation",
                "number_operation",
                "digit_operation",
            ].includes(computeCondition?.type) && (
                <Form.Item
                    label="Match Type"
                    validateStatus={
                        !computeCondition?.match_type ? "error" : ""
                    }
                    help={
                        !computeCondition?.match_type
                            ? "Field cannot be empty"
                            : ""
                    }
                >
                    <Select
                        placeholder="Match Type"
                        disabled={disableWrite}
                        defaultValue={computeCondition?.match_type}
                        onChange={(type) => {
                            let tmpBlock = _.cloneDeep(computeCondition);
                            tmpBlock = _.set(tmpBlock, ["match_type"], type);
                            updateCondition(tmpBlock);
                        }}
                        options={OPERATOR_OPTIONS}
                    />
                </Form.Item>
            )}

            {!loading &&
                ["multiple_answers"].includes(computeCondition?.type) && (
                    <>
                        <Form.Item
                            validateStatus={
                                !computeCondition?.multiple_answers?.length ||
                                computeCondition?.multiple_answers?.some(
                                    (item: { value: string; type: string }) =>
                                        !item?.value || !item?.type,
                                )
                                    ? "error"
                                    : ""
                            }
                            help={
                                !computeCondition?.multiple_answers?.length ||
                                computeCondition?.multiple_answers?.some(
                                    (item: { value: string; type: string }) =>
                                        !item?.value || !item?.type,
                                )
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <List
                                header={"Match Answers"}
                                itemLayout="horizontal"
                                loadMore={
                                    <div
                                        style={{
                                            textAlign: "center",
                                            marginTop: 12,
                                            height: 32,
                                            lineHeight: "32px",
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            type="primary"
                                            disabled={disableWrite}
                                            onClick={() => {
                                                let tmpBlock =
                                                    _.cloneDeep(
                                                        computeCondition,
                                                    );

                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "multiple_answers",
                                                        computeCondition
                                                            ?.multiple_answers
                                                            ?.length || 0,
                                                    ],
                                                    {
                                                        type: "constant", // variable/constant/position
                                                        value: "",
                                                    },
                                                );
                                                updateCondition(tmpBlock);
                                            }}
                                        >
                                            Add Another Answer
                                        </Button>
                                    </div>
                                }
                                dataSource={computeCondition?.multiple_answers}
                                renderItem={(item: any, idx) => (
                                    <List.Item
                                        actions={[
                                            <Button
                                                danger
                                                disabled={disableWrite}
                                                onClick={() => {
                                                    if (
                                                        computeCondition
                                                            ?.multiple_answers
                                                            ?.length === 1
                                                    ) {
                                                        message.warn(
                                                            `Need at least 1 value`,
                                                        );
                                                        return;
                                                    }

                                                    let tmpBlock =
                                                        _.cloneDeep(
                                                            computeCondition,
                                                        );

                                                    const tmpConditions =
                                                        _.cloneDeep(
                                                            computeCondition?.multiple_answers,
                                                        );

                                                    tmpConditions?.splice(
                                                        idx,
                                                        1,
                                                    );

                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        ["multiple_answers"],
                                                        tmpConditions,
                                                    );
                                                    updateCondition(tmpBlock);
                                                    setLoading(true);
                                                }}
                                            >
                                                Delete
                                            </Button>,
                                        ]}
                                    >
                                        <Select
                                            placeholder="Type"
                                            disabled={disableWrite}
                                            defaultValue={item?.type}
                                            onChange={(value) => {
                                                let tmpBlock =
                                                    _.cloneDeep(
                                                        computeCondition,
                                                    );
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "multiple_answers",
                                                        idx,
                                                        "type",
                                                    ],
                                                    value,
                                                );
                                                updateCondition(tmpBlock);
                                            }}
                                            options={getOptions(
                                                computeCondition?.type,
                                            )}
                                        />
                                        {["variable", "position"].includes(
                                            item?.type,
                                        ) && (
                                            <Select
                                                placeholder="Answer"
                                                disabled={disableWrite}
                                                defaultValue={item?.value}
                                                onChange={(value) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(
                                                            computeCondition,
                                                        );
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "multiple_answers",
                                                            idx,
                                                            "value",
                                                        ],
                                                        value,
                                                    );
                                                    updateCondition(tmpBlock);
                                                }}
                                                options={
                                                    item?.type === "variable"
                                                        ? variables.map(
                                                              (v: string) => ({
                                                                  value: v,
                                                                  label: v,
                                                              }),
                                                          )
                                                        : DIGIT_POSITIONS
                                                }
                                            />
                                        )}
                                        {["constant"].includes(item?.type) && (
                                            <Input
                                                placeholder="Value"
                                                disabled={disableWrite}
                                                defaultValue={item?.value}
                                                onChange={(e) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(
                                                            computeCondition,
                                                        );
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "multiple_answers",
                                                            idx,
                                                            "value",
                                                        ],
                                                        e.target.value,
                                                    );
                                                    updateCondition(tmpBlock);
                                                }}
                                            />
                                        )}
                                    </List.Item>
                                )}
                            />
                        </Form.Item>
                    </>
                )}

            {["single_answer", "number_operation", "digit_operation"].includes(
                computeCondition?.type,
            ) && (
                <Form.Item>
                    <h3>Match Answer</h3>
                    <Form.Item label="Type">
                        <Select
                            disabled={disableWrite}
                            defaultValue={computeCondition?.answer?.type}
                            onChange={(type) => {
                                let tmpBlock = _.cloneDeep(computeCondition);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["answer", "type"],
                                    type,
                                );
                                updateCondition(tmpBlock);
                            }}
                            options={getOptions(computeCondition?.type)}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Answer"
                        validateStatus={
                            !computeCondition?.answer?.value ? "error" : ""
                        }
                        help={
                            !computeCondition?.answer?.value
                                ? "Field cannot be empty"
                                : ""
                        }
                    >
                        {["variable", "position"].includes(
                            computeCondition?.answer?.type,
                        ) && (
                            <Select
                                placeholder="Answer"
                                disabled={disableWrite}
                                defaultValue={computeCondition?.answer?.value}
                                onChange={(value) => {
                                    let tmpBlock =
                                        _.cloneDeep(computeCondition);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["answer", "value"],
                                        value,
                                    );
                                    updateCondition(tmpBlock);
                                }}
                                options={
                                    computeCondition?.answer?.type ===
                                    "variable"
                                        ? variables.map((v: string) => ({
                                              value: v,
                                              label: v,
                                          }))
                                        : DIGIT_POSITIONS
                                }
                            />
                        )}
                        {["constant"].includes(
                            computeCondition?.answer?.type,
                        ) && (
                            <Input
                                placeholder="Answer"
                                disabled={disableWrite}
                                defaultValue={computeCondition?.answer?.value}
                                onChange={(e) => {
                                    let tmpBlock =
                                        _.cloneDeep(computeCondition);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["answer", "value"],
                                        e.target.value,
                                    );
                                    updateCondition(tmpBlock);
                                }}
                            />
                        )}
                    </Form.Item>
                </Form.Item>
            )}

            {!loading && computeCondition?.type === "digit_position" && (
                <Form.Item
                    validateStatus={
                        !computeCondition?.digit_position?.length ||
                        computeCondition?.digit_position?.some(
                            (item: { position: string; value: string }) =>
                                !item?.position || !item?.value,
                        )
                            ? "error"
                            : ""
                    }
                    help={
                        !computeCondition?.digit_position?.length ||
                        computeCondition?.digit_position?.some(
                            (item: { position: string; value: string }) =>
                                !item?.position || !item?.value,
                        )
                            ? "Field cannot be empty"
                            : ""
                    }
                >
                    <List
                        header={"Digit Positions"}
                        itemLayout="horizontal"
                        loadMore={
                            <div
                                style={{
                                    textAlign: "center",
                                    marginTop: 12,
                                    height: 32,
                                    lineHeight: "32px",
                                }}
                            >
                                <Button
                                    size="small"
                                    type="primary"
                                    disabled={disableWrite}
                                    onClick={() => {
                                        let tmpBlock =
                                            _.cloneDeep(computeCondition);

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "digit_position",
                                                computeCondition?.digit_position
                                                    ?.length,
                                            ],
                                            {
                                                position: "",
                                                value: "",
                                            },
                                        );
                                        updateCondition(tmpBlock);
                                    }}
                                >
                                    Add Another digit
                                </Button>
                            </div>
                        }
                        dataSource={computeCondition?.digit_position}
                        renderItem={(item: any, idx: number) => (
                            <List.Item
                                key={idx}
                                actions={[
                                    <Button
                                        danger
                                        disabled={disableWrite}
                                        onClick={() => {
                                            if (
                                                computeCondition?.digit_position
                                                    ?.length === 1
                                            ) {
                                                message.warn(
                                                    `Need at least 1 value`,
                                                );
                                                return;
                                            }

                                            let tmpBlock =
                                                _.cloneDeep(computeCondition);

                                            const tmpConditions = _.cloneDeep(
                                                computeCondition?.digit_position,
                                            );

                                            tmpConditions?.splice(idx, 1);

                                            tmpBlock = _.set(
                                                tmpBlock,
                                                ["digit_position"],
                                                tmpConditions,
                                            );
                                            updateCondition(tmpBlock);
                                            setLoading(true);
                                        }}
                                    >
                                        Delete
                                    </Button>,
                                ]}
                            >
                                <Input
                                    placeholder="Digit Value"
                                    disabled={disableWrite}
                                    defaultValue={item?.value}
                                    onChange={(e) => {
                                        let tmpBlock =
                                            _.cloneDeep(computeCondition);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["digit_position", idx, "value"],
                                            e.target.value,
                                        );
                                        updateCondition(tmpBlock);
                                    }}
                                />
                                <Select
                                    placeholder="Digit Position"
                                    disabled={disableWrite}
                                    defaultValue={item?.position}
                                    onChange={(value) => {
                                        let tmpBlock =
                                            _.cloneDeep(computeCondition);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["digit_position", idx, "position"],
                                            value,
                                        );
                                        updateCondition(tmpBlock);
                                    }}
                                    options={DIGIT_POSITIONS}
                                />
                            </List.Item>
                        )}
                    />
                </Form.Item>
            )}

            {!loading && computeCondition?.type === "digit_repeat" && (
                <Form.Item
                    validateStatus={
                        !computeCondition?.digit_repeat?.length ||
                        computeCondition?.digit_repeat?.some(
                            (item: {
                                repeat_value: string;
                                value: string;
                                match_type: string;
                            }) =>
                                !item?.repeat_value ||
                                !item?.value ||
                                !item?.match_type,
                        )
                            ? "error"
                            : ""
                    }
                    help={
                        !computeCondition?.digit_repeat?.length ||
                        computeCondition?.digit_repeat?.some(
                            (item: {
                                repeat_value: string;
                                value: string;
                                match_type: string;
                            }) =>
                                !item?.repeat_value ||
                                !item?.value ||
                                !item?.match_type,
                        )
                            ? "Field cannot be empty"
                            : ""
                    }
                >
                    <List
                        header={"Digit Repeat"}
                        itemLayout="horizontal"
                        loadMore={
                            <div
                                style={{
                                    textAlign: "center",
                                    marginTop: 12,
                                    height: 32,
                                    lineHeight: "32px",
                                }}
                            >
                                <Button
                                    size="small"
                                    type="primary"
                                    disabled={disableWrite}
                                    onClick={() => {
                                        let tmpBlock =
                                            _.cloneDeep(computeCondition);

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "digit_repeat",
                                                computeCondition?.digit_repeat
                                                    ?.length,
                                            ],
                                            {
                                                position: "",
                                                value: "",
                                            },
                                        );
                                        updateCondition(tmpBlock);
                                    }}
                                >
                                    Add Another digit
                                </Button>
                            </div>
                        }
                        dataSource={computeCondition?.digit_repeat}
                        renderItem={(item: any, idx) => (
                            <List.Item
                                actions={[
                                    <Button
                                        danger
                                        disabled={disableWrite}
                                        onClick={() => {
                                            if (
                                                computeCondition?.digit_repeat
                                                    ?.length === 1
                                            ) {
                                                message.warn(
                                                    `Need at least 1 value`,
                                                );
                                                return;
                                            }

                                            let tmpBlock =
                                                _.cloneDeep(computeCondition);

                                            const tmpConditions = _.cloneDeep(
                                                computeCondition?.digit_repeat,
                                            );

                                            tmpConditions?.splice(idx, 1);

                                            tmpBlock = _.set(
                                                tmpBlock,
                                                ["digit_repeat"],
                                                tmpConditions,
                                            );
                                            updateCondition(tmpBlock);
                                            setLoading(true);
                                        }}
                                    >
                                        Delete
                                    </Button>,
                                ]}
                            >
                                <Input
                                    placeholder="Digit Value"
                                    disabled={disableWrite}
                                    defaultValue={item?.value}
                                    onChange={(e) => {
                                        let tmpBlock =
                                            _.cloneDeep(computeCondition);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["digit_repeat", idx, "value"],
                                            e.target.value,
                                        );
                                        updateCondition(tmpBlock);
                                    }}
                                />
                                <Select
                                    placeholder="Match"
                                    disabled={disableWrite}
                                    defaultValue={item?.match_type}
                                    onChange={(value) => {
                                        let tmpBlock =
                                            _.cloneDeep(computeCondition);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["digit_repeat", idx, "match_type"],
                                            value,
                                        );
                                        updateCondition(tmpBlock);
                                    }}
                                    options={OPERATOR_OPTIONS}
                                />
                                {/* <Form.Item label="Repeat"> */}
                                <Input
                                    placeholder="Repeat Value"
                                    disabled={disableWrite}
                                    defaultValue={item?.repeat_value}
                                    onChange={(e) => {
                                        let tmpBlock =
                                            _.cloneDeep(computeCondition);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "digit_repeat",
                                                idx,
                                                "repeat_value",
                                            ],
                                            e.target.value,
                                        );
                                        updateCondition(tmpBlock);
                                    }}
                                />
                                {/* </Form.Item> */}
                            </List.Item>
                        )}
                    />
                </Form.Item>
            )}
        </>
    );
};

const getOptions = (type: any) => {
    const options = [
        {
            label: "Constant",
            value: "constant",
        },
    ];

    switch (type) {
        case "single_answer":
        case "multiple_answers":
        case "number_operation":
            options.push({
                label: "Variable",
                value: "variable",
            });
            break;
        case "digit_operation":
            options.push({
                label: "Position",
                value: "position",
            });
            break;
        default:
            break;
    }

    return options;
};

export default ComputeFunctionEditor;
