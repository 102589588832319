import { CheckOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Form,
    Input,
    Select,
    Switch,
    Table,
    message,
} from "antd";
import { cloneDeep, pullAt, set } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { CopyIcon, DeleteIcon, SlateEditor } from "src/components";
import { v4 as uuid } from "uuid";

const splitAndUpperCase = (str: string) =>
    str
        ?.split("_")
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(" ");

const OptionsTable: React.FC = ({
    setBlock,
    disableWrite,
    currentEditor,
    setCurrentEditor,
    hasMentions,
    mentionsList = [],
    value: items = [],
    schema,
    renderLinkSelect,
}: any) => {
    const [tempConfig, setTempConfig] = useState("");

    const componentsSchema =
        typeof schema === "string" ? JSON.parse(schema) : schema;

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            let tmpBlock = cloneDeep(items);
            const dragRow = tmpBlock[dragIndex];
            tmpBlock.splice(dragIndex, 1);
            tmpBlock.splice(hoverIndex, 0, dragRow);
            setBlock(tmpBlock);
        },
        [items],
    );

    return (
        <div
            style={{
                padding: "20px",
            }}
        >
            <DndProvider backend={HTML5Backend}>
                <Table
                    scroll={{
                        // y: "60vh",
                        x: 1500,
                    }}
                    size="small"
                    title={() => <h2>Options</h2>}
                    pagination={false}
                    dataSource={items?.map((v, i) => {
                        return {
                            key: i,
                            text: v?.props?.text?.value,
                            text_value: v?.props?.text_value?.value,
                            is_editable: v?.props.is_editable?.value,
                            is_checkable: v?.props.is_checkable?.value,
                            has_highlight: v?.props.has_highlight?.value,
                            has_correctness_bg:
                                v?.props.has_correctness_bg?.value,
                            link_variable:
                                v?.linked_global_context_variable?.name,
                            value: v?.value,
                        };
                    })}
                    columns={[
                        {
                            title: "Index",
                            dataIndex: "key",
                            width: 60,
                            render: (v) => (
                                <>
                                    {v + 1}{" "}
                                    <MenuOutlined
                                        style={{
                                            cursor: "grab",
                                        }}
                                    />
                                </>
                            ),
                        },
                        {
                            width: 200,
                            title: "Text",
                            dataIndex: "text",
                            render: (v, data) => {
                                return (
                                    <div
                                        style={{
                                            minWidth: "150px",
                                        }}
                                    >
                                        <SlateEditor
                                            disabled={disableWrite}
                                            hasMentions={hasMentions}
                                            mentionsList={mentionsList}
                                            id={`${data.key}_tag_edit`}
                                            onChange={(value: any) => {
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[
                                                    data?.key
                                                ].props.text.value = value;
                                                setBlock(tmpBlock);
                                            }}
                                            isFocused={
                                                currentEditor ===
                                                `${data.key}_tag_edit`
                                            }
                                            setEditor={(id: string) =>
                                                setCurrentEditor(id)
                                            }
                                            value={v}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            title: "Text Value",
                            dataIndex: "text_value",
                            width: 150,
                            render: (v, data) => {
                                return (
                                    <>
                                        <Form.Item
                                            label="value"
                                            rules={[{ required: true }]}
                                            validateStatus={!v ? "error" : ""}
                                            help={
                                                !v
                                                    ? "Field cannot be empty"
                                                    : ""
                                            }
                                        >
                                            <Input
                                                disabled={disableWrite}
                                                value={v}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    let tmpBlock =
                                                        cloneDeep(items);
                                                    tmpBlock[
                                                        data?.key
                                                    ].props.text_value.value =
                                                        value;
                                                    setBlock(tmpBlock);
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item label="variable">
                                            <Select
                                                value={
                                                    typeof v === "string" &&
                                                    v?.includes("@@")
                                                        ? v
                                                        : null
                                                }
                                                placeholder="Select type"
                                                options={[
                                                    {
                                                        label: "none",
                                                        value: null,
                                                    },
                                                    ...mentionsList?.map(
                                                        (v) => ({
                                                            label: v.text,
                                                            value: `@@${v.text}@@`,
                                                        }),
                                                    ),
                                                ]}
                                                style={{
                                                    width: "150px",
                                                    flexGrow: 0,
                                                }}
                                                onChange={(val) => {
                                                    let tmpBlock =
                                                        cloneDeep(items);
                                                    tmpBlock[
                                                        data?.key
                                                    ].props.text_value.value =
                                                        val;
                                                    setBlock(tmpBlock);
                                                }}
                                                disabled={disableWrite}
                                            />
                                        </Form.Item>
                                    </>
                                );
                            },
                        },
                        {
                            title: "Has highlight?",
                            dataIndex: "has_highlight",
                            width: 80,
                            render: (v, data) => {
                                return (
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={v}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[
                                                data?.key
                                            ].props.has_highlight.value = value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: "Has Correctness Bg?",
                            dataIndex: "has_correctness_bg",
                            width: 80,
                            render: (v, data) => {
                                return (
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={v}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[
                                                data?.key
                                            ].props.has_correctness_bg.value =
                                                value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: "Is Editable?",
                            dataIndex: "is_editable",
                            width: 80,
                            render: (v, data) => {
                                return (
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={v}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[
                                                data?.key
                                            ].props.is_editable.value = value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: "Is Checkable?",
                            dataIndex: "is_checkable",
                            width: 80,
                            render: (v, data) => {
                                return (
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={v}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[
                                                data?.key
                                            ].props.is_checkable.value = value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: "Is Tapped?",
                            dataIndex: "is_tapped",
                            width: 80,
                            render: (v, data) => {
                                return (
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={v}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(items);
                                            if (
                                                !tmpBlock[data?.key].props
                                                    .is_tapped
                                            ) {
                                                tmpBlock[
                                                    data?.key
                                                ].props.is_tapped = {
                                                    value: false,
                                                };
                                            }
                                            tmpBlock[
                                                data?.key
                                            ].props.is_tapped.value = value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: "Linked Variable & Value",
                            dataIndex: "link_variable",
                            width: 230,
                            render: (v, data) => (
                                <>
                                    {renderLinkSelect
                                        ? renderLinkSelect({
                                              value: v,
                                              onChange: (value: any) => {
                                                  let tmpBlock =
                                                      cloneDeep(items);
                                                  tmpBlock = set(
                                                      tmpBlock,
                                                      [
                                                          data.key,
                                                          "linked_global_context_variable",
                                                          "name",
                                                      ],
                                                      value,
                                                  );
                                                  //   tmpBlock = _.set(
                                                  //       tmpBlock,
                                                  //       [data.key, "value"],
                                                  //       value !== null
                                                  //           ? `@@${value}@@`
                                                  //           : value,
                                                  //   );
                                                  setBlock(tmpBlock);
                                              },
                                              style: {
                                                  width: 230,
                                              },
                                              next_line: true,
                                          })
                                        : ""}
                                    <Form.Item label={"Value"}>
                                        <Select
                                            value={data.value}
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: 120,
                                            }}
                                            onChange={(value) => {
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[data?.key].value =
                                                    value;
                                                setBlock(tmpBlock);
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item>
                                </>
                            ),
                        },
                        {
                            title: "Actions",
                            key: "operation",
                            fixed: "right",
                            width: 80,
                            render: (v, data) => (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                    }}
                                >
                                    <CopyIcon
                                        disabled={disableWrite}
                                        isButton={true}
                                        onClick={() => {
                                            let tmpItems = cloneDeep(items);
                                            tmpItems.splice(data.key + 1, 0, {
                                                ...cloneDeep(
                                                    tmpItems[data.key],
                                                ),
                                                genericId: uuid(),
                                            });
                                            setBlock(tmpItems);
                                            message.info(`Option duplicated!`);
                                        }}
                                    />
                                    <DeleteIcon
                                        title="Delete this?"
                                        disabled={disableWrite}
                                        onConfirm={() => {
                                            let tmpBlock = cloneDeep(items);
                                            pullAt(tmpBlock, data.key);
                                            setBlock(tmpBlock);
                                            message.success(
                                                "successfully removed",
                                            );
                                        }}
                                    />
                                </div>
                            ),
                        },
                    ]}
                    rowKey="index"
                    bordered
                    rowClassName="item-rows"
                    footer={() =>
                        disableWrite ? (
                            <></>
                        ) : (
                            <>
                                {componentsSchema.map(
                                    (item: any, key: number) => (
                                        <Button
                                            key={key}
                                            ghost
                                            type="primary"
                                            onClick={() => {
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock.push({
                                                    ...item,
                                                    genericId: uuid(),
                                                });
                                                setBlock(tmpBlock);
                                            }}
                                        >
                                            Add {splitAndUpperCase(item.type)}
                                        </Button>
                                    ),
                                )}
                                {!items?.length && (
                                    <span
                                        style={{
                                            color: "red",
                                            marginLeft: "10px",
                                            fontSize: "16px",
                                        }}
                                    >
                                        No Options Added
                                    </span>
                                )}
                            </>
                        )
                    }
                    components={{
                        body: {
                            row: DraggableRow,
                        },
                    }}
                    onRow={(_, index) => ({
                        index,
                        moveRow,
                        disableWrite,
                    })}
                />
            </DndProvider>
            {!disableWrite && (
                <>
                    <Divider />
                    {items?.length > 0 && (
                        <>
                            <Button
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied Tags config to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(items),
                                    );
                                }}
                            >
                                Copy this options to Clipboard
                            </Button>
                            <Divider />
                        </>
                    )}
                    <Form.Item label={"Paste tags config here"}>
                        <Input.TextArea
                            disabled={disableWrite}
                            value={tempConfig}
                            placeholder="Paste Config here"
                            onChange={(e) => setTempConfig(e.target.value)}
                        />
                        <Button
                            disabled={disableWrite}
                            style={{ marginTop: "10px" }}
                            type="primary"
                            onClick={() => {
                                try {
                                    const parsedJson = JSON.parse(tempConfig);
                                    if (
                                        !Array.isArray(parsedJson) ||
                                        parsedJson?.some(
                                            (v) =>
                                                v.type !== "rich_text" ||
                                                !v?.props,
                                        )
                                    ) {
                                        message.error("Error in JSON!");
                                        return;
                                    }
                                    setBlock(parsedJson);
                                    setTempConfig("");
                                    message.info("Updated!");
                                } catch (e) {
                                    // captureException(e)
                                    message.error("Error in JSON!");
                                }
                            }}
                        >
                            Update config
                        </Button>
                    </Form.Item>
                </>
            )}
        </div>
    );
};

const DraggableRow = ({ index, moveRow, disableWrite, ...restProps }) => {
    const ref = useRef();
    const [, drop] = useDrop({
        accept: "row",
        drop(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            // Position checking logic (as in previous example) can be added here for better UX
            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [, drag] = useDrag({
        type: "row",
        item: { index },
    });

    drag(drop(ref));
    return <tr ref={disableWrite ? null : ref} {...restProps} />;
};

export default OptionsTable;
