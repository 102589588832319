import React from "react";
import { Row, Col, Form, Button } from "antd";
import { withFormik } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderField,
} from "src/components";

const ConceptForm = (props) => {
    const { onFinishFailed, values, handleSubmit, isSubmitting } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Concept Title"
                        placeholder="Concept Title"
                        name={"title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const ConceptFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { concept } = props;

        return {
            ...(concept?.id ? { id: concept.id } : {}),
            title: concept?.title || `Read, learn & quiz it!`,
        };
    },

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

export default ConceptFormWithFormik(ConceptForm);
