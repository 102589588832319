import { renderer } from "../../helpers";

const renderBlockV2Mixed = (block: any) => {
    const {
        data: { text },
    } = block;
    return <div></div>;
};

export default renderBlockV2Mixed;
