import React from "react";
import { Result, Button } from "antd";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
    }

    componentDidCatch(error: Error) {
        // captureException(error)
        this.setState({ error });
    }

    render() {
        if (!this.state.error) {
            return this.props.children;
        } else {
            return (
                <div className={"HVCenter"}>
                    <Result
                        status="500"
                        title="500"
                        subTitle="Sorry, something went wrong."
                        extra={
                            <Button href={"/"} type="primary">
                                Back Home
                            </Button>
                        }
                    />
                </div>
            );
        }
    }
}

export default ErrorBoundary;
