import { gql } from "@apollo/client";export default gql`
mutation updateChapterOther($other: jsonb!, $id: Int!) {
  update_group_by_pk(pk_columns: {id: $id}, _append: {other: $other}) {
      id
      description
      cover
      other
      title
      type
  }
}
`;
