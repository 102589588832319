import { renderer } from "../../helpers";

const renderBlockText = (block: any) => {
    const {
        data: { text },
    } = block;
    return <div>{renderer(text)}</div>;
};

export default renderBlockText;
