import React from "react";
import { Card, Divider, Tag, Row } from "antd";
import {
    getEditor,
    getIsValidFunction,
    renderBlock,
} from "../../../../blocks/index";
import { BLOCK_TYPES } from "src/modules/worksheet/components/blocks/common";
import { Can } from "src/services/casl";

export const DataColumn = (props: any) => {
    const {
        worksheet,
        block,
        currentSubBlock,
        currentBlock,
        setIsEditor,
        isReadOnlyMode,
    } = props;

    if (!block) return <></>;
    const isValidFunction =
        getIsValidFunction(
            currentSubBlock != null
                ? block?.children[currentSubBlock]?.type
                : block?.type,
        ) ||
        (() => ({
            isValid: true,
        }));
    const { isValid, error }: any = isValidFunction({
        block:
            currentSubBlock != null ? block?.children[currentSubBlock] : block,
        currentSubBlock,
        currentBlock,
        worksheet,
    });
    return (
        <Card
            style={{ borderRadius: "8px", marginRight: "8px" }}
            bordered={true}
            onClick={() => {
                setIsEditor(false);
            }}
        >
            {[
                BLOCK_TYPES.MCQ_BLOCK,
                BLOCK_TYPES.CASE_STUDY_BLOCK,
                BLOCK_TYPES.SUBJECTIVE_BLOCK,
                BLOCK_TYPES.RE_ORDER_BLOCK,
                // daily quiz
                BLOCK_TYPES.LONG_ANSWER_BLOCK,
                BLOCK_TYPES.SHORT_ANSWER_BLOCK,
            ].includes(block.type) && (
                <Row align="top">
                    <h3 style={{ fontWeight: "bold", marginRight: "5px" }}>
                        Q.{currentBlock}
                    </h3>
                    {block.tags &&
                        Boolean(block.tags.length) &&
                        block.tags.map(({ label }) => (
                            <Tag color="#ECECEC" style={{ color: "#333333" }}>
                                {label}
                            </Tag>
                        ))}
                </Row>
            )}

            <Divider />
            <Can I="edit" a={"worksheet_block"} passThrough>
                {(allowed: boolean) => (
                    <Can I="read" a={"worksheet_block"} passThrough>
                        {(allowedRead: boolean) =>
                            allowed || allowedRead ? (
                                <div style={{ margin: "24px 30px" }}>
                                    {getEditor({
                                        ...props,
                                        allowedRead,
                                        disableWrite:
                                            !allowed || isReadOnlyMode,
                                    })}
                                </div>
                            ) : (
                                <div style={{ margin: "24px 30px" }}>
                                    {renderBlock(block)}
                                </div>
                            )
                        }
                    </Can>
                )}
            </Can>

            {!isValid && (
                <>
                    {error}
                    <br />
                </>
            )}
        </Card>
    );
};
