import { get } from "lodash";

const areObjectsSimilar = (
    obj1: { [x: string]: any },
    obj2: { [x: string]: any },
): boolean => {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
        return obj1 === obj2;
    }

    const keys1 = Object.keys(obj1 || {});
    const keys2 = Object.keys(obj2 || {});

    if (keys1.length !== keys2.length) {
        return false;
    }

    return keys1.every((key) => {
        const val1 = obj1[key];
        const val2 = obj2[key];

        if (Array.isArray(val1) && Array.isArray(val2)) {
            return (
                val1.length === val2.length &&
                val1.every((elem, index) =>
                    areObjectsSimilar(elem, val2[index]),
                )
            );
        }

        return areObjectsSimilar(val1, val2);
    });
};

export function areAllElementsSame(
    arrayOfObjects: any[],
    key: string | string[],
    value: string = "",
) {
    if (!Array.isArray(arrayOfObjects) || arrayOfObjects.length === 0) {
        return false;
    }

    let arrVal =
        typeof key === "string"
            ? arrayOfObjects[0][key]
            : get(arrayOfObjects[0], key);

    if (arrayOfObjects?.length === 1 && value && value !== arrVal) {
        return false;
    }

    const firstValue = value || arrVal;
    for (let i = 1; i < arrayOfObjects.length; i++) {
        let tempArrVal =
            typeof key === "string"
                ? arrayOfObjects[i][key]
                : get(arrayOfObjects[i], key);
        if (!areObjectsSimilar(tempArrVal, firstValue)) return false;
    }
    return firstValue;
}
