import * as React from "react";
import { Button, Divider, message } from "antd";
import {
    CheckOutlined,
    CopyOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
} from "@ant-design/icons";
import { cloneDeep } from "lodash";
import CellInnerContentPreview from "../CellInnerContentPreview";
import { CopyIcon } from "src/components";
import { TOGGLE_HIGHLIGHT } from "src/helpers/events/table/constants";
import emitter from "src/helpers/emitter";

const CellForm = ({
    inde: index,
    i,
    cellData,
    disableWrite,
    selectedCells,
    setSelectedCells,
    showModal,
    tagsList,
    optionsSchema,
    tagsClusterList,
    curCell,
    table,
    setBlock,
    currentEditor,
    setCurrentEditor,
    hasMentions,
    mentionsList,
    cells,
    isSliderEnabled,
    isPuzzleEnabled,
}: any) => {
    const [isHighlighted, setIsHighlighted] = React.useState(false);
    // use emitter.on and off to listen for events
    React.useEffect(() => {
        const handleHighlight = (data: any) => {
            const coordinates = data?.coordinates;
            if (
                coordinates?.rowIndex != index ||
                coordinates?.columnIndex != i
            ) {
                return;
            }
            if (data?.isHighlighted) {
                setIsHighlighted(true);
            } else {
                setIsHighlighted(false);
            }
        };
        emitter.on(TOGGLE_HIGHLIGHT, handleHighlight);
        return () => {
            emitter.off(TOGGLE_HIGHLIGHT, handleHighlight);
        };
    }, [index, i]);

    const borderStyles = React.useMemo(() => {
        let borderStyle = {
            borderWidth: "2px",
            borderColor: "transparent",
            borderStyle: "solid",
        };
        if (isHighlighted) {
            borderStyle = {
                ...borderStyle,
                borderColor: "#1890ff",
            };
        }
        return borderStyle;
    }, [isHighlighted]);
    return (
        <div
            style={{
                ...borderStyles,
                margin: "auto",
                position: "relative",
            }}
        >
            {index !== cells.length && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                    }}
                >
                    <div
                        style={{
                            fontSize: "14px",
                            color: "#555",
                        }}
                    >
                        Cell {index} {i}
                    </div>
                    <div>
                        <Button
                            disabled={disableWrite}
                            shape="circle"
                            type={
                                selectedCells?.find(
                                    (v) => v[0] === index && v[1] === i,
                                )
                                    ? "primary"
                                    : "default"
                            }
                            size="small"
                            icon={<CheckOutlined />}
                            onClick={() => {
                                if (
                                    selectedCells?.find(
                                        (v) => v[0] === index && v[1] === i,
                                    )
                                )
                                    setSelectedCells(
                                        selectedCells?.filter(
                                            (v) =>
                                                !(v[0] === index && v[1] === i),
                                        ),
                                    );
                                else {
                                    setSelectedCells([
                                        ...selectedCells,
                                        [index, i],
                                    ]);
                                }
                            }}
                        />
                        <Button
                            shape="circle"
                            type={"default"}
                            size="small"
                            disabled={disableWrite}
                            icon={<CopyIcon />}
                            onClick={() => {
                                message.info(`Copied Cell data to clipboard!`);
                                navigator.clipboard.writeText(
                                    JSON.stringify({
                                        ...cellData,
                                        type: "cell",
                                    }),
                                );
                            }}
                        />
                        <Button
                            disabled={disableWrite}
                            shape="circle"
                            type="default"
                            icon={<EditOutlined />}
                            size="small"
                            onClick={() => showModal(index, i, cellData)}
                        />
                    </div>
                </div>
            )}

            {cellData?.hidden ? (
                <div
                    style={{
                        background: "#f7f7f7",
                        width: "100%",
                        height: "50px",
                        fontSize: "40px",
                        color: "#d1d1d1",
                        display: "flex",
                        justifyContent: "c enter",
                    }}
                >
                    <EyeInvisibleOutlined />
                </div>
            ) : cellData ? (
                <>
                    <CellInnerContentPreview
                        {...{
                            tagsList,
                            optionsSchema,
                            tagsClusterList,
                            curCellRow: index,
                            curCellCol: i,
                            curCell,
                            block: table,
                            setCurrentCell: (cell: any, data = {}) => {
                                let tmpBlock = cloneDeep(table);
                                const tempData = tmpBlock?.cells;
                                tempData[index][i] = cell;
                                tmpBlock.cells = tempData;
                                setBlock(tmpBlock, data);
                            },
                            disableWrite,
                            currentEditor,
                            setCurrentEditor,
                            hasMentions,
                            mentionsList,
                            isSliderEnabled,
                            isPuzzleEnabled,
                        }}
                        cell={cellData}
                        tabIndex={index * (cells?.[0]?.length || 10) + i + 1}
                    />
                </>
            ) : (
                <></>
            )}
            {cellData?.linked_global_context_variable?.name && (
                <>
                    <Divider />
                    <strong
                        style={{
                            fontSize: "12px",
                        }}
                    >
                        Linked -{" "}
                        {cellData?.linked_global_context_variable?.name}
                    </strong>
                </>
            )}
        </div>
    );
};

export default CellForm;
