import { BLOCK_TYPES } from "../../common/index";
const { V2_CONTENT_BLOCK } = BLOCK_TYPES;

const V2_CONTENT_DEFAULT_VALUE = {
    type: V2_CONTENT_BLOCK,
    data: {
        [V2_CONTENT_BLOCK]: {
            text: [],
            options: [""],
            video_url: "",
            background_color: "",
        },
        other: { duration: 0, audio: "" },
    },
};

export default V2_CONTENT_DEFAULT_VALUE;
