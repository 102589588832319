import { BLOCK_TYPES } from "../../common";
import { isValidCallbackCrossword } from "../Crossword";

const { DRAG_AND_TAG_BLOCK } = BLOCK_TYPES;

const isValidCallback = (props: any) =>
    isValidCallbackCrossword({
        ...props,
        blockType: DRAG_AND_TAG_BLOCK,
    });

export default isValidCallback;
