import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";

const renderCard = (props: any) => {
    return (
        <div style={{ position: "relative", zIndex: 99 }}>
            <BlockCard {...props}>Paint Block</BlockCard>
        </div>
    );
};

export default renderCard;
