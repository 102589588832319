import { BLOCK_TYPES } from "../../common/index";
const { BUTTON_BLOCK } = BLOCK_TYPES;

const BUTTON_DEFAULT_VALUE = {
    type: BUTTON_BLOCK,
    data: {
        name: "",
        value: "",
        [BUTTON_BLOCK]: {
            text: "",
            color: "",
            font_size: "",
            font_weight: "",
            background_color: "",
            action: {
                type: "", // internal | external | compute function
                value: "",
            },
        },
    },
};

export default BUTTON_DEFAULT_VALUE;
