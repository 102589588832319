import { isEditorEmpty } from "../../common";
import { BLOCK_TYPES } from "../../common/";

const { V2_TABLE_BLOCK } = BLOCK_TYPES;

const isValidCallbackTable = ({ block }: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            [V2_TABLE_BLOCK]: { row_heading, data, column_heading },
        },
    } = block;

    return {
        isValid:
            row_heading?.length > 0 &&
            !row_heading.some(
                (item: any) =>
                    item?.type === "text" && isEditorEmpty(item.text),
            ) &&
            column_heading?.length > 0 &&
            !column_heading.some(
                (item: any) =>
                    item?.type === "text" && isEditorEmpty(item.text),
            ) &&
            data?.length > 0 &&
            !data
                .map((row: any) =>
                    row.some(
                        (cell: any) =>
                            cell.type === "text" && isEditorEmpty(cell.text),
                    ),
                )
                .some((item: boolean) => item === true),
        error: <></>,
    };
};

export default isValidCallbackTable;
