import React, { useState } from "react";
import { Form, Input, Row, Select, Switch } from "antd";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import ImageField from "./ImageField";
import VideoField from "./VideoField";
import ColorPicker from "react-best-gradient-color-picker";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export const PLACEHOLDER_TYPE_OPTIONS = [
    {
        label: "Image",
        value: "IMAGE",
    },
    {
        label: "Video",
        value: "VIDEO",
    },
    {
        label: "Solid/Gradient Color",
        value: "SOLID_COLOR",
    },
];

const PlaceholderField = (props) => {
    const { label, block, setBlock, path, typePath, preview, defaultPath, loopPath, useGamlet = false } =
        props;
    const type = get(block, typePath) || PLACEHOLDER_TYPE_OPTIONS[0].value;

    function callback(e) {
        let tmpBlock = cloneDeep(e);
        if (!get(block, typePath)) {
            tmpBlock = set(
                tmpBlock,
                typePath,
                PLACEHOLDER_TYPE_OPTIONS[0].value,
            );
        }
        setBlock(tmpBlock);
    }

    return (
        <>
            {/* {type === PLACEHOLDER_TYPE_OPTIONS[1].value && (
                <Form.Item label={"Default Background"}>
                    <ColorPicker
                        value={get(block, defaultPath)}
                        onChange={(value: any) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(tmpBlock, defaultPath, value);
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>
            )} */}

            {/* <hr /> */}
            <h3>{label}</h3>
            <Row justify={"space-between"}>
                <Form.Item
                    label={"Type"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        style={{
                            minWidth: "200px",
                        }}
                        defaultValue={type}
                        onChange={(value) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(tmpBlock, typePath, value);
                            tmpBlock = set(tmpBlock, path, "");
                            setBlock(tmpBlock);
                        }}
                        options={PLACEHOLDER_TYPE_OPTIONS}
                    />
                </Form.Item>
            </Row>
            {PLACEHOLDER_TYPE_OPTIONS.filter((ty) => ty.value === type).map(
                (ty) => {
                    if (type === PLACEHOLDER_TYPE_OPTIONS[0].value) {
                        return (
                            <ImageField
                                block={block}
                                setBlock={callback}
                                path={path}
                                label={ty.label}
                                preview={preview}
                            />
                        );
                    }

                    if (type === PLACEHOLDER_TYPE_OPTIONS[1].value) {
                        return (
                            <>
                                <Form.Item label="Loop?">
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={get(block, loopPath)}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                loopPath,
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                                <VideoField
                                    block={block}
                                    setBlock={callback}
                                    path={path}
                                    label={ty.label}
                                    preview={preview}
                                    isS3={true}
                                    useGamlet={useGamlet}
                                />
                            </>
                        );
                    }

                    if (
                        [
                            PLACEHOLDER_TYPE_OPTIONS[2].value,
                            "LINEAR_COLOR",
                            "RADIAL_COLOR",
                        ].indexOf(type) !== -1
                    ) {
                        return (
                            <Form.Item
                                label={PLACEHOLDER_TYPE_OPTIONS[2].label}
                            >
                                <ColorPicker
                                    value={get(block, path)}
                                    onChange={(value: any) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(tmpBlock, path, value);
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        );
                    }
                },
            )}
        </>
    );
};

export default PlaceholderField;
