import {
    // BLOCK_TYPES,
    isEditorEmpty,
} from "../../common";
// import { isValidCallbackDragArrangeList } from "../DragArrangeList";

// const { INPUT_ARRANGE_LIST_BLOCK } = BLOCK_TYPES;

const isValidCallback = ({
    block,
}: // blockType = INPUT_ARRANGE_LIST_BLOCK,
any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: { other: { description = [] } = {} },
    } = block;

    return {
        isValid: !isEditorEmpty(description),
        error: <></>,
    };
};

export default isValidCallback;
