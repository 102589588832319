import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import {
    withAddCollectionTagMap,
    withDeleteCollectionTagMap,
    withHookForCollection,
    withUpdateCollection,
} from "src/modules/collection/operations";
import { compose } from "src/helpers";

import EditView from "./component";
import { omit } from "lodash";
import get from "lodash/get";

const Edit = (props) => {
    const {
        updateCollection,
        collection,
        addCollectionTagMap,
        deleteCollectionTagMap,
    } = props;
    const router = useRouter();

    const onFinish = async (values: any) => {
        console.log({ values });
        await updateCollection(omit(values, ["tags"]));

        const newTags = get(values, ["tags", "data"]).map((tag) => tag.tag_id);
        const oldTagIds = collection.tags.map((tag) => tag.tag.id);
        console.log(newTags, oldTagIds, values);

        const tagsToAdd = newTags.filter(
            (newTag) => oldTagIds.indexOf(newTag) == -1,
        );
        const tagsToRemove = oldTagIds.filter(
            (newTag) => newTags.indexOf(newTag) == -1,
        );

        console.log("newTags", newTags, oldTagIds, tagsToAdd, tagsToRemove);

        if (tagsToAdd.length)
            await addCollectionTagMap({
                objects: tagsToAdd.map((tag) => ({
                    tag_id: tag,
                    collection_id: collection.id,
                })),
            });
        if (tagsToRemove.length)
            await deleteCollectionTagMap({
                ids: collection.tags
                    .filter((tag) => tagsToRemove.includes(tag.tag.id))
                    .map((tag) => tag.id),
            });

        const { returnTo } = router.query;
        if (returnTo) router.push(returnTo);
        else router.push("/collection");
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <EditView
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            {...props}
        />
    );
};

export default compose(
    withHookForCollection,
    withUpdateCollection,
    withAddCollectionTagMap,
    withDeleteCollectionTagMap,
)(Edit);
