import React from "react";
import { compose } from "src/helpers";
import {
    withHookForAggregateOrder,
    withHookForOrders,
} from "src/modules/order/operations";
import { withStateAndActionsForOrder } from "src/modules/order/resolvers";
import ListView from "./component";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    withStateAndActionsForOrder,
    withHookForOrders,
    withHookForAggregateOrder,
)(List);
