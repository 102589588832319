import React, { Component } from "react";
import { Col, Form, Row, Switch, InputNumber, Card } from "antd";
import _ from "lodash";
import TextArea from "antd/lib/input/TextArea";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";

const InteractionLimitEditor = ({ block, setBlock, disableWrite }: any) => {
    return (
        <KTWrapper feature="Interaction_Limit_Handling">
            <Card
                title={<h3>Interaction Limit Handling</h3>}
                extra={
                    <Switch
                        disabled={disableWrite}
                        checked={block.interactionLimit?.enabled}
                        onChange={(val) => {
                            let tmpBlock = _.cloneDeep(block);
                            if (tmpBlock.interactionLimit === undefined)
                                tmpBlock.interactionLimit = {};
                            tmpBlock.interactionLimit.enabled = val;
                            setBlock(tmpBlock);
                        }}
                    />
                }
                bodyStyle={{
                    padding: block.interactionLimit?.enabled ? "12px" : "0px",
                }}
            >
                {/*Switch field for enable disable */}

                {block.interactionLimit?.enabled && (
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Minimum Interaction Limit">
                                <InputNumber
                                    disabled={disableWrite}
                                    value={block.interactionLimit?.min}
                                    onChange={(val) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        if (
                                            tmpBlock.interactionLimit ===
                                            undefined
                                        )
                                            tmpBlock.interactionLimit = {};
                                        tmpBlock.interactionLimit.min = val;
                                        setBlock(tmpBlock);
                                    }}
                                    min={0}
                                    max={block.interactionLimit?.max ?? 5}
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Maximum Interaction Limit">
                                <InputNumber
                                    disabled={disableWrite}
                                    value={block.interactionLimit?.max}
                                    onChange={(val) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        if (
                                            tmpBlock.interactionLimit ===
                                            undefined
                                        )
                                            tmpBlock.interactionLimit = {};
                                        tmpBlock.interactionLimit.max = val;
                                        setBlock(tmpBlock);
                                    }}
                                    min={block.interactionLimit?.min ?? 0}
                                    defaultValue={5}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            {/*Textarea antd field for warning message when user exceeds max attempt count */}
                            <Form.Item label="Warning Message">
                                <TextArea
                                    disabled={disableWrite}
                                    value={
                                        block.interactionLimit
                                            ?.maxWarningMessage
                                    }
                                    onChange={(e) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        if (
                                            tmpBlock.interactionLimit ===
                                            undefined
                                        )
                                            tmpBlock.interactionLimit = {};
                                        tmpBlock.interactionLimit.maxWarningMessage =
                                            e.target.value;
                                        setBlock(tmpBlock);
                                    }}
                                    rows={4}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </Card>
        </KTWrapper>
    );
};

export default InteractionLimitEditor;
