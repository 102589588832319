import { BLOCK_TYPES } from "../../common/";

const { V2_MCQ_BLOCK } = BLOCK_TYPES;

const MCQ_DEFAULT_VALUE = {
    type: V2_MCQ_BLOCK,
    data: {
        [V2_MCQ_BLOCK]: {
            options: [
                {
                    // feedback: [],
                    text: [],
                    explanation: [],
                },
            ],
            correct_options: [],
            solution: [],
            layout: "vertical",
            is_image: false,
        },
        other: {
            duration: 0,
            audio: "",
            description: [],
            global_feedback: null,
            solution_video: "",
            feedbacks: [],
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
};

export default MCQ_DEFAULT_VALUE;
