import React from "react";
import { Form, Input, Select, Divider, Card, Button, Tooltip } from "antd";
import _ from "lodash";
import { DeleteOutlined, GatewayOutlined } from "@ant-design/icons";
import SelectionTagForm from "./SelectionTagsForm";

const PathForm = ({ block, setBlock, disableWrite }: any) => {
    const [activePathIndex, setActivePathIndex] = React.useState<number | null>(
        null,
    );

    return (
        <>
            <Card
                title={
                    <h3
                        style={{
                            marginBottom: "0px",
                        }}
                    >
                        <GatewayOutlined /> {" Paths"}
                    </h3>
                }
                style={{
                    border: "2px solid #f0f0f0",
                }}
                bodyStyle={{
                    padding: "10px",
                    backgroundColor: "#f5f5f5",
                }}
            >
                {block.feedback?.paths?.map((path: any, index: number) => {
                    return (
                        <div
                            key={path.id}
                            style={{
                                position: "relative",
                            }}
                        >
                            <h3>Path {index + 1}</h3>
                            <div
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                }}
                            >
                                <Tooltip title="Delete Path">
                                    <Button
                                        shape="circle"
                                        size="small"
                                        color="red"
                                        onClick={() => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock.feedback.paths.splice(
                                                index,
                                                1,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                            <Form.Item label="Path Type">
                                <Select
                                    disabled={disableWrite}
                                    value={path.type}
                                    onChange={(val) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock.feedback.paths[index].type =
                                            val;
                                        setBlock(tmpBlock);
                                    }}
                                    style={{
                                        width: "200px",
                                    }}
                                >
                                    <Select.Option value="ONE_BY_ONE">
                                        One By One
                                    </Select.Option>
                                    <Select.Option value="ONE_BY_ONE_TILL_INCORRECT">
                                        One By One Till Incorrect
                                    </Select.Option>
                                    <Select.Option value="ALL_IN_ONE_GO">
                                        All in one go
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Cells">
                                {/* <Input.TextArea
                                    disabled={disableWrite}
                                    rows={6}
                                    value={JSON.stringify(path.cells)}
                                    onChange={(e) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock.feedback.paths[index].cells =
                                            JSON.parse(e.target.value);
                                        setBlock(tmpBlock);
                                    }}
                                /> */}
                                <SelectionTagForm
                                    path={path}
                                    setPath={(val: any) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock.feedback.paths[index] = val;
                                        setBlock(tmpBlock);
                                    }}
                                    pathIndex={index}
                                    activePathIndex={activePathIndex}
                                    setActivePathIndex={setActivePathIndex}
                                    disableWrite={disableWrite}
                                />
                            </Form.Item>
                            <Divider
                                style={{
                                    border: "2px solid gray",
                                }}
                            />
                        </div>
                    );
                })}
                <Button
                    disabled={disableWrite}
                    onClick={() => {
                        let tmpBlock = _.cloneDeep(block);
                        if (tmpBlock.feedback.paths === undefined) {
                            tmpBlock.feedback.paths = [];
                        }
                        tmpBlock.feedback.paths.push({
                            id: _.uniqueId(),
                            type: "ONE_BY_ONE",
                            cells: [],
                        });
                        setBlock(tmpBlock);
                    }}
                >
                    Add Path
                </Button>
            </Card>
        </>
    );
};

export default PathForm;
