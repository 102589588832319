// PuzzleTagManager.ts
import emitter from "src/helpers/emitter";
import { TABLE_PUZZLE_TAG_ACTIVATED } from "../utils/constants";
import {
    generatePuzzleCellsNonDrawing,
    generatePuzzlePiecesDrawingData,
} from "./utils";

class PuzzleTagManager {
    // Properties to hold state
    activeTagId: string | null = null; // Currently active tag ID
    selectedCells: any[] = []; // Array to store selected cells

    // Activate a tag by ID
    activateTag(tagId: string): void {
        if (this.activeTagId === tagId) {
            // Tag is already active
            return;
        }
        this.deactivateActiveTag();
        this.activeTagId = tagId;
        emitter.emit(TABLE_PUZZLE_TAG_ACTIVATED, tagId);
        console.log(`Tag with ID "${this.activeTagId}" activated.`);
    }

    // Deactivate the active tag
    deactivateActiveTag(): void {
        if (this.activeTagId !== null) {
            console.log(`Tag with ID "${this.activeTagId}" deactivated.`);
            this.activeTagId = null;
            this.clearSelectedCells();
            emitter.emit(TABLE_PUZZLE_TAG_ACTIVATED, null);
        }
    }

    // Add a selected cell
    addSelectedCell(
        cellContent: any,
        rowIndex: number,
        colIndex: number,
    ): void {
        if (this.activeTagId !== null) {
            const cellData = {
                content: cellContent,
                rowIndex: rowIndex,
                colIndex: colIndex,
            };
            this.selectedCells.push(cellData);
            // Additional logic to update UI if needed
        } else {
            console.warn(
                "No active tag. Please activate a tag before selecting cells.",
            );
        }
    }

    removeSelectedCell(
        cellContent: any,
        rowIndex: number,
        colIndex: number,
    ): void {
        if (this.activeTagId !== null) {
            this.selectedCells = this.selectedCells.filter(
                (cell) =>
                    cell.rowIndex !== rowIndex || cell.colIndex !== colIndex,
            );
        }
    }

    // Get the list of selected cells
    getSelectedCells(): any[] {
        // Return a copy to prevent external modification
        return this.selectedCells.slice();
    }

    generatePuzzleTag(tag: any, tableData: any): void {
        // Generate a puzzle tag from the selected cells
        // Additional logic to update UI if needed
        if (tableData?.drawing?.enabled) {
            const existingCells =
                tag?.cells && tag.cells[0] ? tag.cells[0] : [];
            const { cells, width, height } = generatePuzzlePiecesDrawingData(
                this.selectedCells,
                tableData,
                existingCells,
                tag,
            );
            tag.cells = [cells];
            tag.drawing = {
                drawingAreaDimensions: {
                    width,
                    height,
                },
            };
        } else {
            tag.cells = generatePuzzleCellsNonDrawing(
                this.selectedCells,
                tableData,
            );
        }
        return tag;
    }

    // Clear selected cells
    clearSelectedCells(): void {
        this.selectedCells.length = 0; // Clear the array
        // Additional logic to update UI if needed
    }

    // Get the currently active tag ID
    getActiveTagId(): string | null {
        return this.activeTagId;
    }
}

// Export an initialized instance of PuzzleTagManager
export default new PuzzleTagManager();
