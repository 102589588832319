import { gql } from "@apollo/client";export default gql`query MyQuery($search: String!, $status: String = "active") {
    blocks: worksheet_block(
        where: {
            data_as_text: { _similar: $search }
            worksheets: { worksheet: { status: { _eq: $status } } }
        }
    ) {
        id
        data_as_text
        data
        type
        parent_id
    }
}
`;
