import React, { useState } from "react";

import { Layout, MetaTag } from "src/components";
import { CollectionForm } from "../../components";
import { Card, Col, Row, Spin } from "antd";

const EditView = (props: any) => {
    const { collection, onFinish, onFinishFailed } = props;

    return (
        <Layout
            titles={[
                { name: "Collections", link: "/collection" },
                { name: "Edit", link: "" },
                { name: collection?.name, link: "" },
            ]}
        >
            <MetaTag title="Add Collection" />
            <Row justify="center" align="middle">
                <Col xs={12} lg={20} md={24}>
                    <Card
                        style={{ borderRadius: "8px", padding: "12px" }}
                        title={"Collection Update"}
                        bordered={true}
                    >
                        {collection && (
                            <CollectionForm
                                collection={collection}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            />
                        )}
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default EditView;
