import { arrayMoveImmutable } from "array-move";

export const onSortEnd = ({
    oldIndex,
    newIndex,
    dataSource,
    updateGroupTabMap,
    setDataSource
}: any) => {
    if (oldIndex !== newIndex) {
        const newData: any[] = arrayMoveImmutable(
            [].concat(dataSource),
            oldIndex,
            newIndex,
        ).filter((el) => !!el);

        newData.forEach(async (d: any, index: number ) => {
            if (d.order !== index + 1) {
                // for topic set_id will actually be topic_id
                // updateChapterOther({
                //     other: {
                //         order: index + 1,
                //     },
                //     id: d.id,
                // });

                await updateGroupTabMap({
                    id: d.group_worksheet_map_id,
                    _set: { order: index + 1 },
                });

                newData[index].order = index;
            }
        });

        setDataSource(newData);
    }
};
