import axios from "axios";
import _, { cloneDeep } from "lodash";
import * as acorn from "acorn";
import * as walk from "acorn-walk";
import { captureException } from "@sentry/react";

export function isDynamicText(text: string | undefined) {
    const regex = /@@(.*?)@@/g;
    if (!text) return true;
    return regex.test(text);
}

export async function generateAudios(
    levels: any[],
    type = "personalized_learning_v3",
) {
    // NEEDS UPDATE
    const TEXT_PATH = ["audio", "audio_texts", "0", "text"];
    const AUDIO_PATH = ["audio", "audio_texts", "0", "audio_urls", "0", "url"];
    // const ACTIVE_PATH = ["audio", "audio_texts", "0", "audio_urls", "0", "is_active"];

    const BLOCKS_PATH =
        type === "personalized_learning_v4"
            ? ["children"]
            : ["data", "chunk", "blocks"];
    const ALL_IN_ONE_PATH = ["data", "all_in_one"];
    const V2_STORY_PATH = ["data", "v2_story"];
    const BG_AUDIO_LIST_PATH = ["bg_audio_list"];
    const QUESTION_AUDIO_PATH = ["question_audio"];
    const PREVIEW_PATH = ["data", "other"];
    const STORIES_PATH = ["data", "other", "stories"];
    const STORY_BLOCK_PATH = ["data", "children"];
    const STORY_BLOCK_STORY_PATH = ["data", "other"];

    const textsPayload: any[] = [];

    const samplePayload = {
        client: "ElevenLabs",
        text: "",
        dataOnly: false,
        language: "Hindi",
        artist: {
            name: "Madhu",
            other: {
                voice_id: "XeXqtVbGZphseIE5t8Ga",
            },
        },
    };

    levels.forEach((b: any, index: number) => {
        const chunks = b?.children;
        chunks?.forEach((chunk: any) => {
            const allBlocks = _.get(chunk, BLOCKS_PATH);
            allBlocks?.forEach((block: any) => {
                switch (block.type) {
                    case "all_in_one": {
                        const previewTextPath = [...PREVIEW_PATH, ...TEXT_PATH];
                        const previewAudioPath = [
                            ...PREVIEW_PATH,
                            ...AUDIO_PATH,
                        ];

                        const audio = _.get(block, previewAudioPath);
                        const previewAudioText = _.get(block, previewTextPath);
                        if (!audio && !isDynamicText(previewAudioText)) {
                            textsPayload.push({
                                ...samplePayload,
                                path: {
                                    question_preview: {
                                        level_id: b.id ?? b.tmpId,
                                    },
                                },
                                text: previewAudioText,
                            });
                        }

                        const questionAudioPath = [
                            ...ALL_IN_ONE_PATH,
                            ...QUESTION_AUDIO_PATH,
                        ];
                        const questionAudios = _.get(block, questionAudioPath);

                        questionAudios?.forEach((qa: any) => {
                            const questionAudioText = _.get(qa, TEXT_PATH);
                            const questionAudio = _.get(qa, AUDIO_PATH);
                            if (
                                !questionAudio &&
                                !isDynamicText(questionAudioText)
                            ) {
                                textsPayload.push({
                                    ...samplePayload,
                                    text: questionAudioText,
                                    path: {
                                        question_audio: {
                                            level_id: b.id ?? b.tmpId,
                                            question_index: qa.id ?? qa.tmpId,
                                        },
                                    },
                                });
                            }
                        });

                        const stories = _.get(block, STORIES_PATH);
                        stories?.forEach((story: any) => {
                            const storyChildren = _.get(
                                story,
                                STORY_BLOCK_PATH,
                            );
                            storyChildren?.forEach((storyChild: any) => {
                                const storyText = _.get(storyChild, [
                                    ...STORY_BLOCK_STORY_PATH,
                                    ...TEXT_PATH,
                                ]);
                                const storyAudio = _.get(storyChild, [
                                    ...STORY_BLOCK_STORY_PATH,
                                    ...AUDIO_PATH,
                                ]);
                                if (!storyAudio && !isDynamicText(storyText)) {
                                    textsPayload.push({
                                        ...samplePayload,
                                        text: storyText,
                                    });
                                }

                                const bgAudioListPath = [
                                    ...V2_STORY_PATH,
                                    ...BG_AUDIO_LIST_PATH,
                                ];

                                const bgAudiosList = _.get(
                                    storyChild,
                                    bgAudioListPath,
                                );

                                bgAudiosList?.forEach((qa: any) => {
                                    const questionAudioText = _.get(
                                        qa,
                                        TEXT_PATH,
                                    );
                                    const questionAudio = _.get(qa, AUDIO_PATH);
                                    if (
                                        !questionAudio &&
                                        !isDynamicText(questionAudioText)
                                    ) {
                                        textsPayload.push({
                                            ...samplePayload,
                                            text: questionAudioText,
                                            // path: {
                                            //     question_audio: {
                                            //         level_id: b.id ?? b.tmpId,
                                            //         question_index: qa.id ?? qa.tmpId,
                                            //     }
                                            // }
                                        });
                                    }
                                });
                            });
                        });

                        break;
                    }
                    case "v2_stories": {
                        const storyChildren = _.get(block, STORY_BLOCK_PATH);
                        storyChildren?.forEach((storyChild: any) => {
                            const storyText = _.get(storyChild, [
                                ...STORY_BLOCK_STORY_PATH,
                                ...TEXT_PATH,
                            ]);
                            const storyAudio = _.get(storyChild, [
                                ...STORY_BLOCK_STORY_PATH,
                                ...AUDIO_PATH,
                            ]);
                            if (!storyAudio && !isDynamicText(storyText)) {
                                textsPayload.push({
                                    ...samplePayload,
                                    text: storyText,
                                });
                            }
                            const bgAudioListPath = [
                                ...V2_STORY_PATH,
                                ...BG_AUDIO_LIST_PATH,
                            ];
                            const bgAudiosList = _.get(
                                storyChild,
                                bgAudioListPath,
                            );

                            bgAudiosList?.forEach((qa: any) => {
                                const questionAudioText = _.get(
                                    qa,
                                    TEXT_PATH,
                                );
                                const questionAudio = _.get(qa, AUDIO_PATH);
                                if (
                                    !questionAudio &&
                                    !isDynamicText(questionAudioText)
                                ) {
                                    textsPayload.push({
                                        ...samplePayload,
                                        text: questionAudioText,
                                        // path: {
                                        //     question_audio: {
                                        //         level_id: b.id ?? b.tmpId,
                                        //         question_index: qa.id ?? qa.tmpId,
                                        //     }
                                        // }
                                    });
                                }
                            });
                        });
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
        });
    });
    if (!textsPayload?.length) return { job_id: null, error: null };
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v3/personalizedLearning/generateAudios`,
            {
                data: textsPayload,
            },
        );
        const job_id = res?.data?.data?.job_id;
        // console.log("job_id", job_id);
        return { job_id, error: null };
    } catch (err) {
        captureException(err)
        console.log("err", err);
        return { job_id: null, error: err };
    }
}

export async function addGenerateLogicForVarAudio(
    levels: any[],
    type = "personalized_learning_v3",
) {
    const TEXT_PATH = ["audio", "audio_texts", "0", "text"];
    // const BLOCKS_PATH = ["data", "chunk", "blocks"];
    const BLOCKS_PATH =
        type === "personalized_learning_v4"
            ? ["children"]
            : ["data", "chunk", "blocks"];
    const STORIES_PATH = ["data", "other", "stories"];
    const STORY_BLOCK_PATH = ["data", "children"];
    const STORY_BLOCK_STORY_PATH = ["data", "other"];
    levels.forEach((b: any, index: number) => {
        const chunks = b?.children;
        // const baseVariables = _.get(levels, [index, 'data', 'progress_level', 'global_context_variables', 'base_variables'])
        // const derivedVariables = _.get(levels, [index, 'data', 'progress_level', 'global_context_variables', 'derived_variables'])
        const baseVariables = _.get(
            levels,
            type === "personalized_learning_v4"
                ? [
                    index,
                    "backend",
                    "global_context_variables",
                    "base_variables",
                ]
                : [
                    index,
                    "data",
                    "progress_level",
                    "global_context_variables",
                    "base_variables",
                ],
        );
        const derivedVariables = _.get(
            levels,
            type === "personalized_learning_v4"
                ? [
                    index,
                    "backend",
                    "global_context_variables",
                    "derived_variables",
                ]
                : [
                    index,
                    "data",
                    "progress_level",
                    "global_context_variables",
                    "derived_variables",
                ],
        );

        const global_context_variables: any = {};
        baseVariables.forEach((i: any) => {
            global_context_variables[i.name] = "random_val";
        });
        derivedVariables.forEach((i: any) => {
            global_context_variables[i.name] = "random_val";
        });
        const newChunks = chunks?.map((chunk: any) => {
            const allBlocks = _.get(chunk, BLOCKS_PATH);
            const newBlocks = allBlocks?.map((block: any) => {
                let storyChildren = [];
                switch (block.type) {
                    case "all_in_one": {
                        storyChildren = _.get(block, STORIES_PATH) ?? [];
                        break;
                    }
                    case "v2_stories": {
                        storyChildren = _.get(block, STORY_BLOCK_PATH) ?? [];
                        break;
                    }
                    default: {
                        break;
                    }
                }
                let data: any[] = [];
                storyChildren?.forEach((storyChild: any, story_idx: number) => {
                    const storyText = _.get(storyChild, [
                        ...STORY_BLOCK_STORY_PATH,
                        ...TEXT_PATH,
                    ]);
                    if (storyText && isDynamicText(storyText)) {
                        const audioData = _.get(storyChild, ["data", "other"]);
                        const audioTextData = _.get(audioData, [
                            "audio",
                            "audio_texts",
                            0,
                        ]);
                        const language = audioTextData?.language || "Hindi";
                        const artist = audioTextData?.artist || {
                            name: "Madhu",
                            other: {
                                voice_id: "XeXqtVbGZphseIE5t8Ga",
                            },
                        };
                        const payload = {
                            data: [
                                {
                                    client: "ElevenLabs",
                                    text: "",
                                    language,
                                    artist,
                                },
                            ],
                        };
                        payload.data[0].text = storyText;
                        const variables: any = [];
                        extractContentBetweenMarkers(storyText).forEach((i) => {
                            const dVar = derivedVariables.find(
                                (j: any) => j.name == i,
                            );
                            if (dVar) {
                                const usedVarCompFunc = getVariablesFromAST(
                                    dVar.computeFunction.output,
                                    global_context_variables,
                                );
                                variables.push(...usedVarCompFunc);
                                return;
                            }
                            variables.push(i);
                        });
                        data.push({
                            story_idx,
                            variables,
                            payload,
                        });
                    }
                });
                const valesDontExistsFor = storyChildren
                    ?.map((storyChild: any) =>
                        storyChild.data.children
                            .map(
                                (i: any) =>
                                    i.data?.linked_global_context_variable
                                        ?.name,
                            )
                            .filter((i: any) => i),
                    )
                    .flat(1);
                const allObtainedVars: any[] = [
                    ...Object.keys(global_context_variables).filter(
                        (i) => !valesDontExistsFor.includes(i),
                    ),
                ];
                const gen_audio_map: any = [];
                storyChildren?.forEach((storyChild: any) => {
                    const vars = storyChild.data.children
                        .map(
                            (i: any) =>
                                i.data?.linked_global_context_variable?.name,
                        )
                        .filter((i: any) => i);
                    allObtainedVars.push(...vars);
                    gen_audio_map.push(
                        data.filter((i) =>
                            i.variables.every((i: any) =>
                                allObtainedVars.includes(i),
                            ),
                        ),
                    );
                    data = data.filter(
                        (i) =>
                            !i.variables.every((i: any) =>
                                allObtainedVars.includes(i),
                            ),
                    );
                });

                let tmpBlock = cloneDeep(block);
                tmpBlock.data.gen_audio_map = gen_audio_map;
                return tmpBlock;
            });
            _.set(chunk, BLOCKS_PATH, newBlocks);
            return chunk;
        });
        _.set(b, "children", newChunks);
    });
    return levels;
}

function getVariablesFromAST(str: string, obj: any) {
    const ast = acorn.parse(str, { ecmaVersion: 2020, sourceType: "module" });
    const variables = new Set();
    walk.simple(ast, {
        MemberExpression(node) {
            if (
                node.object.type === "Identifier" &&
                node.object.name === "obj"
            ) {
                if (node.property.type === "Identifier" && !node.computed) {
                    variables.add(node.property.name);
                } else if (node.computed && node.property.type === "Literal") {
                    variables.add(node.property.value);
                }
            }
        },
        ObjectPattern(node) {
            node.properties.forEach((prop: any) => {
                if (prop.value.type === "Identifier") {
                    let key = prop.key.name;
                    if (prop.value.name !== key) {
                        // Handling renaming in destructuring
                        key = prop.value.name;
                    }
                    variables.add(key);
                }
            });
        },
        VariableDeclaration(node: any) {
            if (
                node.init &&
                node.init.type === "Identifier" &&
                node.init.name === "obj"
            ) {
                if (node.id.type === "ObjectPattern") {
                    node.id.properties.forEach((prop: any) => {
                        let key = prop.key.name;
                        if (prop.value.type === "AssignmentPattern") {
                            // Handling default values in destructuring
                            key = prop.value.left.name;
                        } else if (prop.value.name) {
                            key = prop.value.name;
                        }
                        variables.add(key);
                    });
                }
            }
        },
        CallExpression(node: any) {
            // Handle function calls where object keys might be passed as arguments to functions
            if (node.arguments) {
                node.arguments.forEach((arg: any) => {
                    if (arg.type === "Literal") {
                        variables.add(arg.value);
                    } else if (
                        arg.type === "Identifier" &&
                        node.callee.name === "getVal"
                    ) {
                        // If the function getVal is being called, it suggests dynamic access which needs special handling
                        variables.add(arg.name); // We capture the name assuming it may map back to an object key
                    }
                });
            }
        },
    });
    return Array.from(variables).filter((key: any) => key in obj);
}

function extractContentBetweenMarkers(str: string) {
    const regex = /@@([^@]+)@@/g;
    const matches = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
        matches.push(match[1]);
    }
    return matches;
}
