import { checkBlockValid } from "../..";
import { jumpLogicValid } from "../ProgressLevel/isValid";
import { BLOCK_TYPES } from "../../common/index";
const { V2_STORIES_BLOCK } = BLOCK_TYPES;

const isValidCallback = ({
    block,
    worksheet,
    chunkIndex,
    chunkSlug,
    blockIndex,
}: any) => {
    const {
        data: {
            slug: dataSlug,
            [V2_STORIES_BLOCK]: { logic },
            children,
        },
        backend,
    } = block;
    let slug =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.slug
            : dataSlug;
    let compute_functions =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.compute_functions || []
            : logic?.compute_functions;

    let jumpValid = jumpLogicValid({
        functions: compute_functions,
        chunkIndex,
        chunkSlug,
        blockIndex,
        blockSlug: slug,
    });

    const checkId =
        worksheet?.type === "personalized_learning_v4" ? true : block?.id;
    return {
        isValid: jumpValid && checkId && checkStoriesValid(children, worksheet),
        error: "",
    };
};

const checkStoriesValid = (blocks, worksheet) => {
    if (!blocks?.length) return false;
    for (let index = 0; index < blocks.length; index++) {
        const retVal = checkBlockValid(blocks[index], worksheet);
        if (!retVal) return false;
    }
    return true;
};

export default isValidCallback;
