import React from "react";
import { compose } from "src/helpers";
import {
    withHookForAggregateUser,
    withHookForUsers,
} from "src/modules/user/operations";
import { withStateAndActionsForUser } from "src/modules/user/resolvers";
import ListView from "./component";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    withStateAndActionsForUser,
    withHookForUsers,
    withHookForAggregateUser,
)(List);
