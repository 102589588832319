import { gql } from "@apollo/client";export default gql`mutation createTopic($object: group_insert_input = {}) {
    insert_group_one(
        object: $object
        on_conflict: { constraint: group_pkey, update_columns: id }
    ) {
        id
        description
        cover
        other
        title
        type
        parent_id
    }
}
`;
