import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import { withCreateTab } from "src/modules/tab/operations";
import { compose } from "src/helpers";

import CreateView from "./component";

const Create = (props) => {
    const { createTab } = props;
    const router = useRouter();
    const {
        query: { redirect_to, order },
    } = router;

    const onFinish = async (values: any) => {
        await createTab(values);
        router.push(redirect_to);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <CreateView
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            order={order}
        />
    );
};

export default compose(withCreateTab)(Create);
