import { Row } from "antd";
import { BLOCK_TYPES } from "..";
import GridTable from "./GridTable";
import GridOptions from "./GridOptions";

const {
    CROSSWORD_BLOCK,
    DRAG_AND_SWIPE_BLOCK,
    SWIPE_AND_FORM_BLOCK,
    DRAG_EXPAND_NUMBER_BLOCK,
    INPUT_EXPAND_NUMBER_BLOCK,
    V2_DRAG_AND_SWIPE_BLOCK,
    DRAG_AND_TAG_BLOCK,
} = BLOCK_TYPES;

const GridField = (props: any) => {
    const {
        blockType = CROSSWORD_BLOCK,
        hasMentions,
        mentionsList = [],
        block_input,
    } = props;

    return (
        <Row style={{ width: "100%" }}>
            {[
                CROSSWORD_BLOCK,
                DRAG_AND_SWIPE_BLOCK,
                SWIPE_AND_FORM_BLOCK,
                DRAG_EXPAND_NUMBER_BLOCK,
                INPUT_EXPAND_NUMBER_BLOCK,
                V2_DRAG_AND_SWIPE_BLOCK,
                DRAG_AND_TAG_BLOCK,
            ].includes(blockType) && <GridTable {...props} />}

            {!(
                hasMentions &&
                mentionsList?.length > 0 &&
                block_input?.is_enabled
            ) && <GridOptions {...props} />}
        </Row>
    );
};

export default GridField;
