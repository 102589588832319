import { DeleteOutlined } from "@ant-design/icons";
import {
    Badge,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Typography,
    message,
} from "antd";
import { Button, List } from "antd";
import { cloneDeep, pullAt } from "lodash";
import React, { useEffect, useState } from "react";

const TopicEventsModal = (props: any) => {
    const { disableWrite, topicEventEffectsMap = [], setBlock } = props;

    const [currTopicEventEffectsMap, setCurrTopicEventsMap] =
        useState(topicEventEffectsMap);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempQuesConfig, setTempQuesConfig] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = () => {
        if (!disableWrite) {
            if (
                currTopicEventEffectsMap.some(
                    (v) => !v.event || !v.computeFunction?.output,
                )
            ) {
                message.error("Some fields are empty");
                return;
            } else {
                setBlock(currTopicEventEffectsMap);
                message.success("Success!");
            }
        }
        setIsModalOpen(false);
    };

    useEffect(() => {
        setCurrTopicEventsMap(topicEventEffectsMap);
    }, [isModalOpen]);

    return (
        <div
            style={{
                display: "flex",
                gap: "10px",
            }}
        >
            <Form.Item label={"Effects"} labelCol={{ span: 24 }}>
                <Button size="small" type="primary" ghost onClick={showModal}>
                    Add Effects
                </Button>
                <br />
                {topicEventEffectsMap?.length ? (
                    <span>Added!!</span>
                ) : (
                    <span>Not Added!</span>
                )}
                <Modal
                    title={
                        <Typography.Title level={4}>
                            Event Effects
                        </Typography.Title>
                    }
                    onCancel={closeForm}
                    onOk={handleSubmit}
                    open={isModalOpen}
                    destroyOnClose
                    width={800}
                    maskClosable={false}
                    style={{ top: 10 }}
                >
                    <Row
                        style={{
                            maxHeight: "70vh",
                            overflowY: "auto",
                            overflowX: "hidden",
                            scrollbarWidth: "thin",
                        }}
                        gutter={[20, 20]}
                    >
                        {!disableWrite && (
                            <>
                                <Col span={10}>
                                    <Button
                                        size="small"
                                        disabled={disableWrite}
                                        type="primary"
                                        onClick={() => {
                                            message.info(
                                                `Copied Event Effects to clipboard!`,
                                            );
                                            navigator.clipboard.writeText(
                                                JSON.stringify({
                                                    currTopicEventEffectsMap,
                                                }),
                                            );
                                        }}
                                    >
                                        Copy the Event Effects
                                    </Button>
                                </Col>
                                <Col span={14}>
                                    <Form.Item
                                        label={"Paste Event Effects here"}
                                    >
                                        <Input.TextArea
                                            disabled={disableWrite}
                                            value={tempQuesConfig}
                                            placeholder="Paste Variables here"
                                            onChange={(e) =>
                                                setTempQuesConfig(
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        <Button
                                            disabled={disableWrite}
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                try {
                                                    const parsedJson =
                                                        JSON.parse(
                                                            tempQuesConfig,
                                                        );
                                                    if (
                                                        parsedJson?.currTopicEventEffectsMap
                                                    )
                                                        setCurrTopicEventsMap(
                                                            parsedJson?.currTopicEventEffectsMap,
                                                        );
                                                    setTempQuesConfig("");
                                                    message.info("Updated!");
                                                } catch (e) {
                                                    // captureException(e)
                                                    message.error(
                                                        "Error in JSON!",
                                                    );
                                                }
                                            }}
                                        >
                                            Replace config
                                        </Button>
                                        <Divider type="vertical" />
                                        <Button
                                            disabled={disableWrite}
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                try {
                                                    const parsedJson: any =
                                                        JSON.parse(
                                                            tempQuesConfig,
                                                        );
                                                    setCurrTopicEventsMap([
                                                        ...(currTopicEventEffectsMap ||
                                                            []),
                                                        ...(parsedJson?.currTopicEventEffectsMap ||
                                                            []),
                                                    ]);
                                                    setTempQuesConfig("");
                                                    message.info("Updated!");
                                                } catch (e) {
                                                    // captureException(e);
                                                    message.error(
                                                        "Error in JSON!",
                                                    );
                                                }
                                            }}
                                        >
                                            Append config
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                        <Col span={24}>
                            <List
                                size="large"
                                bordered
                                dataSource={currTopicEventEffectsMap}
                                renderItem={(
                                    { event, computeFunction }: any,
                                    index: number,
                                ) => (
                                    <List.Item
                                        key={index}
                                        actions={[
                                            <Button
                                                icon={
                                                    <DeleteOutlined
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    />
                                                }
                                                shape="circle"
                                                danger
                                                disabled={disableWrite}
                                                onClick={() => {
                                                    let tmpChildren = cloneDeep(
                                                        currTopicEventEffectsMap,
                                                    );
                                                    pullAt(tmpChildren, index);
                                                    setCurrTopicEventsMap(
                                                        tmpChildren,
                                                    );
                                                }}
                                            />,
                                        ]}
                                    >
                                        <Badge.Ribbon
                                            text={index + 1}
                                            color="#faad14"
                                            placement="start"
                                        >
                                            <Row
                                                style={{
                                                    paddingLeft: "20px",
                                                    minWidth: "400px",
                                                }}
                                            >
                                                <Col span={24}>
                                                    <Form.Item
                                                        label="Name"
                                                        validateStatus={
                                                            !event?.trim?.()
                                                                ? "error"
                                                                : ""
                                                        }
                                                        help={
                                                            !event?.trim?.()
                                                                ? "Field cannot be empty"
                                                                : ""
                                                        }
                                                    >
                                                        <Input
                                                            size="small"
                                                            value={event}
                                                            onChange={(e) => {
                                                                let tmpVars =
                                                                    cloneDeep(
                                                                        currTopicEventEffectsMap,
                                                                    );
                                                                tmpVars[
                                                                    index
                                                                ].event =
                                                                    e.target.value;
                                                                setCurrTopicEventsMap(
                                                                    tmpVars,
                                                                );
                                                            }}
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            placeholder="Name"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label="Compute Function"
                                                        validateStatus={
                                                            !computeFunction?.output?.trim?.()
                                                                ? "error"
                                                                : ""
                                                        }
                                                        help={
                                                            !computeFunction?.output?.trim?.()
                                                                ? "Field cannot be empty"
                                                                : ""
                                                        }
                                                    >
                                                        <Input.TextArea
                                                            value={
                                                                computeFunction.output
                                                            }
                                                            rows={4}
                                                            onChange={(e) => {
                                                                let tmpVars =
                                                                    cloneDeep(
                                                                        currTopicEventEffectsMap,
                                                                    );
                                                                tmpVars[
                                                                    index
                                                                ].computeFunction.output =
                                                                    e.target.value;
                                                                setCurrTopicEventsMap(
                                                                    tmpVars,
                                                                );
                                                            }}
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            placeholder="Value"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Badge.Ribbon>
                                    </List.Item>
                                )}
                                footer={
                                    <Button
                                        onClick={() => {
                                            setCurrTopicEventsMap([
                                                ...currTopicEventEffectsMap,
                                                {
                                                    event: "input-change",
                                                    computeFunction: {
                                                        output: `function onInputChange(panIndex, tagIndex, tagContainer, eventData) {
                                                            const { value } = eventData;
                                                            const updatedTag = JSON.parse(JSON.stringify(tagContainer))
                                                            updatedTag.data = updatedTag.data.map(d => {
                                                              if (d.showText) {
                                                                d.text[0].children[0].text = value || "?";
                                                              }
                                                              return d;
                                                            })
                                                            const data = {
                                                              type: "UPDATE_TAG",
                                                              props: {
                                                                value: updatedTag,
                                                                panIndex,
                                                                tagIndex
                                                              }
                                                            }
                                                            const data1 = {
                                                                type: "UPDATE_TAG_WEIGHT",
                                                                props: {
                                                                  value,
                                                                  panIndex,
                                                                  tagIndex
                                                                }
                                                              }
                                                            return [data, data1]
                                                          }`,
                                                    },
                                                },
                                            ]);
                                        }}
                                        type="primary"
                                        ghost
                                        disabled={disableWrite}
                                    >
                                        Add Event Effect
                                    </Button>
                                }
                            />
                        </Col>
                    </Row>
                </Modal>
            </Form.Item>
        </div>
    );
};

export default TopicEventsModal;
