import { message } from "antd";
import { v4 as uuid } from "uuid";
import { getAddCallback, getBareBlock } from "../../blocks";

export const addBlock = ({
    setIsNewBlock,
    blocks,
    block,
    type,
    index,
    setBlocks,
    setCurrentBlock,
    setSaveCounter,
    addBlockCallback,
    worksheet,
}: any) => {
    setIsNewBlock(true);
    const tmpBlocks = [...blocks];
    const newBlock = block ? block : { tmpId: uuid(), ...getBareBlock(type) };

    tmpBlocks.splice(index, 0, newBlock);

    setBlocks(tmpBlocks);
    setCurrentBlock(index);

    const addCallback = getAddCallback(type);

    if (addCallback) addCallback({ setSaveCounter, currentBlocks: tmpBlocks });

    addBlockCallback();

    if (worksheet?.type === "quiz_form") {
        message.warning(
            "Please update the branching logic and conditions and reference variables.",
        );
    }
};
