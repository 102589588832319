import React, { useState, useEffect, useCallback } from "react";
import {
    MARK_FONT_SIZE,
    MARK_FONT_WEIGHT,
    focusEditor,
    getMark,
    getPluginType,
    select,
    setMarks,
    usePlateEditorRef,
    usePlateEditorState,
} from "@udecode/plate";
import { Form, Select } from "antd";

const { Option } = Select;

const weights = ["100", "200", "300", "400", "500", "600", "700", "800", "900"];
const sizes = [
    "8px",
    "10px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
    "30px",
    "31px",
    "32px",
];

const FontToolbarButton = ({
    id,
    pluginKey,
    label,
}: {
    id?: string;
    pluginKey: string;
    label: string;
}): JSX.Element => {
    const editor = usePlateEditorState(id);
    const editorRef = usePlateEditorRef(id);

    const type = getPluginType(editorRef, pluginKey);

    const font = editorRef && (getMark(editorRef, type) as string);

    const [selectedFont, setSelectedFont] = useState<string>();

    useEffect(() => {
        if (editor?.selection) {
            setSelectedFont(font);
        }
    }, [font, editor?.selection]);

    const updateFont = useCallback(
        (value: string) => {
            if (editorRef && editor && editor.selection) {
                setSelectedFont(value);

                select(editorRef, editor.selection);
                focusEditor(editorRef);

                setMarks(editor, { [type]: value });
            }
        },
        [editor, editorRef, type],
    );

    return (
        <Form.Item label={label} style={{ marginBottom: "0px" }}>
            <Select
                value={selectedFont}
                size={"small"}
                style={{ width: 80, margin: "4px 0" }}
                onChange={updateFont}
            >
                {pluginKey === MARK_FONT_WEIGHT &&
                    weights.map((s) => (
                        <Option value={s} key={s}>
                            {s}
                        </Option>
                    ))}
                {pluginKey === MARK_FONT_SIZE &&
                    sizes.map((s) => (
                        <Option value={s} key={s}>
                            {s}
                        </Option>
                    ))}
            </Select>
        </Form.Item>
    );
};

export default FontToolbarButton;
