import { Card } from "antd";
import { renderer } from "../../helpers/";
import { BLOCK_TYPES } from "../../common/";

const { TABLE_BLOCK } = BLOCK_TYPES;

const renderBlock = (block: any) => {
    const {
        data: {
            [TABLE_BLOCK]: { text },
        },
    } = block;
    return (
        <div>
            <Card>{renderer(text)}</Card>
        </div>
    );
};

export default renderBlock;
