import { BLOCK_TYPES } from "../../common/index";
const { V2_STORY_BLOCK } = BLOCK_TYPES;

const COMPUTE_FUNCS: any = [
    {
        name: "getNextStory",
        output: "function getNextStory(history, inputs, global_context_variables) {\n  return 'next';\n}",
        params: [],
        label: "Get Next Story",
        isGlobal: true,
    },
];

export const INPUT_FIELD_VALUE = ({ type, ...others }) => {
    return {
        type: type,
        data: {
            name: "",
            value: "",
            input: {
                default_value: "",
                placeholder: "Input",
                custom_keyboard: [],
                inputType: "NUMBER",
            },
        },
        ...others,
    };
};

const V2_STORY_DEFAULT_VALUE = {
    type: V2_STORY_BLOCK,
    data: {
        [V2_STORY_BLOCK]: {
            name: "",
            background: {
                type: "", // image, audio, color background
                value: "",
                default: "",
                loop: false,
            },
            bg_audio_list: [],
            duration_type: null, // max, avatar, bg audio, bg video
            is_skip: true,
            logic: {
                compute_functions: COMPUTE_FUNCS,
            },
            play_multiple_audio: true,
        },
        other: { audio: "", duration: 0 },
        children: [], // V2RichText | V2Avatar | Image | V2Button
    },
};

export default V2_STORY_DEFAULT_VALUE;
