import {
    createBasicElementsPlugin,
    createBlockquotePlugin,
    createBoldPlugin,
    createCodeBlockPlugin,
    createCodePlugin,
    createFontBackgroundColorPlugin,
    createFontColorPlugin,
    createFontSizePlugin,
    createFontWeightPlugin,
    createHeadingPlugin,
    createImagePlugin,
    createIndentPlugin,
    createItalicPlugin,
    createLineHeightPlugin,
    createParagraphPlugin,
    createSelectOnBackspacePlugin,
    createStrikethroughPlugin,
    createUnderlinePlugin,
} from "@udecode/plate";
import { CONFIG } from "./config";
import { createFontDropShadowPlugin } from "./plugins/createFontDropShadowPlugin";

export const basicFontPlugins = [
    createFontColorPlugin(),
    createFontSizePlugin(),
    createFontWeightPlugin(),
    createLineHeightPlugin(CONFIG.lineHeight),
    createFontDropShadowPlugin(),
    createIndentPlugin(CONFIG.indent),
    createFontBackgroundColorPlugin()
];

const basicElements = [
    createParagraphPlugin(), // paragraph element
    createBlockquotePlugin(), // blockquote element
    createCodeBlockPlugin(), // code block element
    createHeadingPlugin(), // heading elements
];

const basicMarks = [
    createBoldPlugin(), // bold mark
    createItalicPlugin(), // italic mark
    createUnderlinePlugin(), // underline mark
    createStrikethroughPlugin(), // strikethrough mark
    createCodePlugin(), // code mark
];

export const PLUGINS = {
    basicElements,
    basicMarks,
    basicNodes: [...basicElements, ...basicMarks],
    image: [
        createBasicElementsPlugin(),
        ...basicMarks,
        createImagePlugin(),
        createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
    ],
};
