import { gql } from "@apollo/client";export default gql`query BookQuery($limit: Int = 12, $offset: Int = 0, $where: group_bool_exp) {
    books: group(
        limit: $limit
        offset: $offset
        where: $where
        order_by: { created_at: desc }
    ) {
        id
        cover
        title
    }
}
`;
