import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { TagsAssignComponent } from "../../common";
import { Form, Switch } from "antd";
import _ from "lodash";
import { BLOCK_TYPES } from "../../common/";

const { V2_MCQ_BLOCK } = BLOCK_TYPES;

const renderSettingsMCQ = (props: any, isMCQ = true) => {
    const { block, setBlock, currentSubBlock } = props;

    return (
        <div>
            <TagsAssignComponent
                key={block.id + (currentSubBlock ? `${currentSubBlock}` : ``)}
                handleChange={(data: any) => {
                    let tmpBlock = _.cloneDeep(block);
                    tmpBlock = _.set(tmpBlock, ["tags"], data);
                    setBlock(tmpBlock);
                }}
                selected={
                    block.tags?.map(
                        ({ label, value, key, tag }: any, index: number) => {
                            return {
                                value: value || JSON.stringify(tag),
                                key:
                                    key ||
                                    tag.id ||
                                    tag?.tmpId ||
                                    JSON.parse(value).tmpId,
                                label: label || `${tag.value} (${tag.name})`,
                            };
                        },
                    ) || []
                }
            />

            {!isMCQ && (
                <>
                    <br />
                    <Form.Item label="Randomize options">
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={_.get(block, [
                                "data",
                                V2_MCQ_BLOCK,
                                "random",
                            ])}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_MCQ_BLOCK, "random"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </>
            )}
        </div>
    );
};

export default renderSettingsMCQ;
