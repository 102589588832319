import React from "react";
import { compose } from "src/helpers";
import {
    withCreateGroupCollection,
    withDeleteGroupCollection,
    withHookForAggregateTopic,
    withHookForTopics,
    withUpdateGroupCollection,
    withUpdateChapterOther,
} from "src/modules/book/operations";
import { withStateAndActionsForBook as withStateAndActionsForTopic } from "src/modules/book/resolvers";
import ListView from "./component";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    // withStateAndActionsForTopic,
    withHookForTopics,
    withHookForAggregateTopic,
    withUpdateGroupCollection,
    withCreateGroupCollection,
    withDeleteGroupCollection,
    withUpdateChapterOther,
)(List);
