import React from "react";
import parse from "html-react-parser";
import style from "style-to-object";

const removeInlineStyling = [
    "p",
    // "div",
    // "span",
    "sup",
    "sub",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
];

const TextHtml = ({ html }) => {
    const { domToReact } = parse;

    return (
        <div className="htmlQuestionContainer">
            {parse(html, {
                replace: (domNode) => {
                    if (domNode.attribs && domNode.attribs.style) {
                        try {
                            style(domNode.attribs.style);
                            if (
                                domNode.name &&
                                removeInlineStyling.includes(domNode.name)
                            ) {
                                // delete the attribute who's inline styling is not required
                                // then convert the dom node to react
                                delete domNode.attribs.style;
                                return domToReact(domNode);
                            }
                        } catch (error) {
                            // delete the attribute that's causing the error
                            // then convert the dom node to react
                            delete domNode.attribs.style;
                            return domToReact(domNode);
                        }
                    }
                },
            })}
        </div>
    );
};

export default TextHtml;
