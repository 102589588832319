import { FunctionComponent } from "react";
import update from "immutability-helper";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import set from "lodash/set";
import { makeVar, useReactiveVar } from "@apollo/client";

import config from "src/helpers/config";

interface StateValue {
    where: any;
    pagination: PaginationUserInput;
    reset?: boolean;
}

interface PaginationUserInput {
    limit: number;
    offset: number;
}

const initialValue = {
    where: {},
    pagination: {
        limit: config.PAGINATION_LIMIT,
        offset: 0,
    },
};
const stateVar = makeVar(initialValue);

export const withStateAndActionsForUser = (Component: FunctionComponent) => {
    const WithComponent = (props: any) => {
        const state = useReactiveVar(stateVar);

        function updateState({ where, pagination, reset = false }: StateValue) {
            const newState = update(
                state,
                reset
                    ? {
                          where: { $set: {} },
                          pagination: {
                              $set: {
                                  limit: config.PAGINATION_LIMIT,
                                  offset: 0,
                              },
                          },
                      }
                    : {
                          where: isEmpty(where)
                              ? { $set: where }
                              : {
                                    $merge: omit(where, "_and"),
                                    ...(where._and && {
                                        _and: {
                                            $set: where._and,
                                        },
                                    }),
                                },
                          pagination: { $merge: pagination },
                      },
            );

            stateVar(newState);
        }
        function onPaginationChange(pagination: PaginationUserInput) {
            updateState({ ...state, pagination });
        }
        function onStateReset() {
            updateState({ ...initialValue, reset: true });
        }

        function onNameChange(name: string) {
            const path = ["name", "_ilike"];
            const where = set({}, path, `%${name}%`);
            updateState({
                ...state,
                where,
                pagination: { ...state.pagination, offset: 0 },
            });
        }

        function onEmailChange(name: string) {
            const path = ["email", "_ilike"];
            const where = set({}, path, `%${name}%`);
            updateState({
                ...state,
                where,
                pagination: { ...state.pagination, offset: 0 },
            });
        }

        return (
            <Component
                {...{
                    // State
                    ...state,

                    // State Actions
                    onPaginationChange,
                    onStateReset,
                    onNameChange,
                    onEmailChange,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export default {
    read() {
        return stateVar();
    },
};
