import {
    EditOutlined,
    CheckCircleOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { SlateEditor } from "src/components/plate";
import CellInputPopup from "./CellInputPopup";
import HeadingPopupForm from "./CellInputPopup/HeadingPopupForm";
import { Button, Col, Popconfirm, Row, Space, Table } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { DEFAULT_CELL } from "../defaultValue";
import {
    AudioPicker,
    BLOCK_TYPES,
    DurationPicker,
    isEditorEmpty,
    prefillText,
} from "../../../common/index";
import { renderer } from "../../../helpers";

const { TABLE_BLOCK } = BLOCK_TYPES;

const renderEditorTable = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    setIsNewBlock,
    isNewBlock,
    mentionsList = [],
    hasMentions,
    worksheet,

    showDuration = true,
    showBackgroundAudio = true,
    disableWrite,
    isReadOnlyMode,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [TABLE_BLOCK]: {
                text = [],
                is_required,
                description = [],
                heading = [],
                is_heading,
                data = [[]],
                type,
            },
        },
    } = block;

    const [currentEditor, setCurrentEditor] = useState(
        disableWrite ? "" : `${id || tmpId}_text`,
    );

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isHeadModalOpen, setIsHeadModalOpen] = useState(false);

    const [curHeadCol, setCurHeadCol] = useState(-1);

    const [curCellRow, setCurCellRow] = useState(-1);
    const [curCellCol, setCurCellCol] = useState(-1);
    const [curCell, setCurCell] = useState({});

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    const showHeadModal = (column: number) => {
        setCurHeadCol(column);
        setIsHeadModalOpen(true);
    };

    const showModal = (row: number, column: number, cell: any) => {
        setCurCellRow(row);
        setCurCellCol(column);
        setCurCell(cell);
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setCurCellRow(-1);
        setCurCellCol(-1);
        setCurCell({});
        setIsModalOpen(false);
    };

    const closeHeadForm = () => {
        setCurHeadCol(-1);
        setIsHeadModalOpen(false);
    };

    useEffect(() => {
        if (!disableWrite) setCurrentEditor(`${id || tmpId}_text`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const columns = [
        ...heading.map((item: any, i: number) => {
            return {
                width: "auto",
                title: () => (
                    <div>
                        <Button
                            disabled={disableWrite}
                            shape="circle"
                            type="default"
                            icon={<EditOutlined />}
                            style={{
                                float: "right",
                                marginLeft: "20px",
                            }}
                            size="small"
                            onClick={() => showHeadModal(i)}
                        />
                        {renderer(item.text)}
                    </div>
                ),
                dataIndex: i,
                key: i,
                render: (text: any, __: any, index: number) => {
                    const cellData =
                        index === data?.length ? null : data[index][i];

                    const cellStyle =
                        type !== "select" &&
                        (cellData?.type === "input" ||
                            cellData?.type === "options")
                            ? { borderBottom: "2px solid #999" }
                            : {};

                    const invalidStyle =
                        typeof text === "string" &&
                        text.length < 1 &&
                        (cellData?.type === "text" || type === "select")
                            ? { background: "rgba(255,0,0,0.1)" }
                            : {};

                    return {
                        props: {
                            style:
                                index === data.length
                                    ? {}
                                    : {
                                          borderLeft: "1px solid black",
                                          borderBottom: "1px solid black",
                                          ...invalidStyle,
                                      },
                        },
                        children: (
                            <>
                                {index !== data.length && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        {type === "select" ? (
                                            <CheckCircleOutlined
                                                style={{
                                                    color: "white",
                                                    background:
                                                        cellData?.is_correct
                                                            ? "green"
                                                            : "#ccc",
                                                    borderRadius: "50%",
                                                    fontSize: "25px",
                                                }}
                                            />
                                        ) : (
                                            <div />
                                        )}
                                        <Button
                                            disabled={disableWrite}
                                            shape="circle"
                                            type="default"
                                            icon={<EditOutlined />}
                                            size="small"
                                            onClick={() =>
                                                showModal(index, i, cellData)
                                            }
                                        />
                                    </div>
                                )}
                                <div
                                    style={
                                        index === data.length
                                            ? {}
                                            : {
                                                  minHeight: "30px",
                                                  ...cellStyle,
                                              }
                                    }
                                >
                                    {(cellData?.type === "text" ||
                                        !cellData?.type) &&
                                        text}
                                    <div
                                        style={{
                                            color: "#999",
                                            textAlign: "center",
                                        }}
                                    >
                                        {cellData?.type === "input" &&
                                            "Input field"}
                                        {cellData?.type === "options" &&
                                            "Options field"}
                                    </div>
                                </div>
                            </>
                        ),
                    };
                },
            };
        }),
        {
            dataIndex: "index",
            key: "index",
            render: (val: number) => {
                return {
                    props: {
                        style:
                            val >= 0
                                ? {
                                      borderLeft: "1px solid black",
                                  }
                                : null,
                    },
                    children:
                        val >= 0 && !disableWrite ? (
                            <Space>
                                <Popconfirm
                                    title={"Delete this row?"}
                                    icon={
                                        <QuestionCircleOutlined
                                            style={{ color: "red" }}
                                        />
                                    }
                                    onConfirm={() => {
                                        let tmpBlock = _.cloneDeep(block);

                                        const tempData =
                                            tmpBlock?.data[TABLE_BLOCK].data;

                                        tempData.splice(val, 1);

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["data", TABLE_BLOCK, "data"],
                                            tempData,
                                        );

                                        setBlock(tmpBlock);
                                    }}
                                    okText={"Yes"}
                                    cancelText={"Cancel"}
                                >
                                    <Button
                                        shape="circle"
                                        danger
                                        icon={
                                            <DeleteOutlined
                                                style={{ color: "red" }}
                                            />
                                        }
                                    />
                                </Popconfirm>

                                <Popconfirm
                                    title={"Add a row below?"}
                                    onConfirm={() => {
                                        let tmpBlock = _.cloneDeep(block);

                                        const tempData =
                                            tmpBlock?.data[TABLE_BLOCK].data;

                                        tempData.splice(
                                            val + 1,
                                            0,
                                            heading.map(() => DEFAULT_CELL),
                                        );

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["data", TABLE_BLOCK, "data"],
                                            tempData,
                                        );

                                        setBlock(tmpBlock);
                                    }}
                                    okText={"Yes"}
                                    cancelText={"Cancel"}
                                >
                                    <Button
                                        shape="circle"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                    />
                                </Popconfirm>
                            </Space>
                        ) : null,
                };
            },
        },
    ];

    const actionButtons = () => {
        const cell: any = { key: data.map + 1 };

        heading?.forEach((__: any, i: number) => {
            cell[i] = (
                <Space>
                    <Popconfirm
                        title={"Delete this column?"}
                        icon={
                            <QuestionCircleOutlined style={{ color: "red" }} />
                        }
                        onConfirm={() => {
                            let tmpBlock = _.cloneDeep(block);

                            let tempData = tmpBlock?.data[TABLE_BLOCK].heading;

                            tempData.splice(i, 1);

                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", TABLE_BLOCK, "heading"],
                                tempData,
                            );

                            tempData = tmpBlock?.data[TABLE_BLOCK].data?.map(
                                (val: any) =>
                                    val.filter(
                                        (_: any, idx: number) => idx !== i,
                                    ),
                            );

                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", TABLE_BLOCK, "data"],
                                tempData,
                            );

                            setBlock(tmpBlock);
                        }}
                        okText={"Yes"}
                        cancelText={"Cancel"}
                    >
                        <Button
                            shape="circle"
                            danger
                            icon={<DeleteOutlined style={{ color: "red" }} />}
                        />
                    </Popconfirm>

                    <Popconfirm
                        title={"Add a new column?"}
                        onConfirm={() => {
                            let tmpBlock = _.cloneDeep(block);

                            let tempData = tmpBlock?.data[TABLE_BLOCK].heading;

                            tempData.splice(i + 1, 0, {
                                text: prefillText(`New Heading`),
                            });

                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", TABLE_BLOCK, "heading"],
                                tempData,
                            );

                            tempData = tmpBlock?.data[TABLE_BLOCK].data?.map(
                                (val: any) => {
                                    val.splice(i + 1, 0, DEFAULT_CELL);

                                    return val;
                                },
                            );

                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", TABLE_BLOCK, "data"],
                                tempData,
                            );

                            setBlock(tmpBlock);
                        }}
                        okText={"Yes"}
                        cancelText={"Cancel"}
                    >
                        <Button
                            shape="circle"
                            type="primary"
                            icon={<PlusOutlined />}
                        />
                    </Popconfirm>
                </Space>
            );
        });
        return cell;
    };

    const tableData = [
        ...data.map((item: any, i: number) => {
            const cell: any = { key: i + 1, index: i };

            item?.forEach((element: any, j: number) => {
                cell[j] =
                    (type === "select" || element?.type === "text") &&
                    !isEditorEmpty(element.text)
                        ? renderer(element.text)
                        : element?.type === "v2_fill_blank"
                        ? renderer(element.data["v2_fill_blank"]?.text || [])
                        : "";
            });
            return cell;
        }),
        ...(!disableWrite ? [actionButtons()] : []),
    ];

    const cellInputProps = {
        isModalOpen,
        closeForm,
        curCellRow,
        curCellCol,
        curCell,
        block,
        setBlock,
        tableType: type,
        mentionsList,
        hasMentions,
        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
    };

    const headInputProps = {
        isModalOpen: isHeadModalOpen,
        closeForm: closeHeadForm,
        curCellCol: curHeadCol,
        mentionsList,
        hasMentions,
        block,
        setBlock,
        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        curHeading: heading[curHeadCol],
    };

    return (
        <div>
            <CellInputPopup {...cellInputProps} />
            <HeadingPopupForm {...headInputProps} />
            <Row style={{ width: "100%" }}>
                <Col span={22}>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_text`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", TABLE_BLOCK, "text"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={currentEditor == `${id || tmpId}_text`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={text}
                        placeholder={"Type your question here..."}
                        textStyle={{
                            padding: "0 14px",
                            fontSize: "18px",
                            color: "#444",
                        }}
                        addAsterisk={is_required}
                    />
                </Col>

                <Col span={22}>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_description`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", TABLE_BLOCK, "description"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={
                            currentEditor == `${id || tmpId}_description`
                        }
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={description}
                        placeholder={"Description (optional)"}
                        required={false}
                        textStyle={{
                            padding: "0 14px",
                            fontSize: "18px",
                            color: "#999",
                        }}
                    />
                </Col>

                <Col span={24}>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={tableData}
                        pagination={false}
                        showHeader={is_heading}
                        scroll={{ x: "max-content" }}
                        footer={() =>
                            data.length > 0 || disableWrite ? null : (
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        let tmpBlock = _.cloneDeep(block);

                                        const tempData =
                                            tmpBlock?.data[TABLE_BLOCK].data;

                                        tempData.push(
                                            heading.map(() => DEFAULT_CELL),
                                        );

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["data", TABLE_BLOCK, "data"],
                                            tempData,
                                        );

                                        setBlock(tmpBlock);
                                    }}
                                >
                                    Add Row
                                </Button>
                            )
                        }
                    />
                </Col>
            </Row>

            {showDuration &&
                ["timed", "personalized_learning"].includes(
                    worksheet?.type,
                ) && (
                    <div
                        style={{
                            border: "1px dashed #808080",
                            margin: "10px auto",
                            padding: "10px 10px",
                        }}
                    >
                        <h3>Duration</h3>
                        <DurationPicker
                            disabled={disableWrite}
                            onChange={onDurationChange}
                            initialValue={block?.data?.other?.duration || 0}
                        />
                    </div>
                )}

            {showBackgroundAudio &&
                ["personalized_learning"].includes(worksheet?.type) && (
                    <div
                        style={{
                            border: "1px dashed #808080",
                            margin: "10px auto",
                            padding: "10px 10px",
                        }}
                    >
                        <h3>BackgroundAudio</h3>
                        <AudioPicker
                            block={block}
                            disabled={isReadOnlyMode}
                            setBlock={setBlock}
                        />
                    </div>
                )}
        </div>
    );
};

export default renderEditorTable;
