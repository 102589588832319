export const OPERATOR_OPTIONS = [
    {
        label: "Equal to",
        value: "eq",
    },
    {
        label: "Not Equal to",
        value: "neq",
    },
    {
        label: "Less than",
        value: "lt",
    },
    {
        label: "Less than or Equal to",
        value: "lte",
    },
    {
        label: "Greater than",
        value: "gt",
    },
    {
        label: "Greater than or Equal to",
        value: "gte",
    },
];

export const CROSSWORD_CONDITION_COMPUTATIONS = [
    {
        label: "Unique",
        value: "unique",
    },
    {
        label: "Single Answer",
        value: "single_answer",
    },
    {
        label: "Multiple Answers",
        value: "multiple_answers",
    },
    {
        label: "Digit Position",
        value: "digit_position",
    },
    {
        label: "Digit Repeat",
        value: "digit_repeat",
    },
    {
        label: "Digit Operation",
        value: "digit_operation",
    },
    {
        label: "Number Operation",
        value: "number_operation",
    },
];

export const COMPUTATION_OPERATION_TYPE = [
    {
        label: "Sum",
        value: "sum",
    },
    {
        label: "Difference",
        value: "difference",
    },
    {
        label: "Multiply",
        value: "multiply",
    },
    {
        label: "Divide",
        value: "divide",
    },
];

export const COMPUTE_CONJUNCTIONS = [
    {
        label: "And",
        value: "and",
    },
    {
        label: "Or",
        value: "or",
    },
];

export const DIGIT_POSITIONS = [
    {
        label: "One",
        value: "one",
    },
    {
        label: "Ten",
        value: "ten",
    },
    {
        label: "Hundred",
        value: "hundred",
    },
    {
        label: "Thousand",
        value: "thousand",
    },
    {
        label: "Ten Thousand",
        value: "ten_thousand",
    },
    {
        label: "Lakh",
        value: "lakh",
    },
    {
        label: "Ten Lakh",
        value: "ten_lakh",
    },
    {
        label: "Crore",
        value: "crore",
    },
    {
        label: "Ten Crore",
        value: "ten_crore",
    },
];
