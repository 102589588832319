import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { SlateEditor } from "src/components/plate";
import {
    Form,
    Space,
    Row,
    Col,
    Button,
    message,
    Switch,
    Select,
    Input,
} from "antd";
import _, { cloneDeep, set } from "lodash";
import React, { useEffect, useState } from "react";
import { BLOCK_TYPES, isEditorEmpty } from "../../../../common";
import {
    CustomKeyboardEditor,
    KeyboardError,
} from "src/components/CustomKeyboardEditor";
import { renderEditorV2FillBlank } from "../../../V2FillBlank";
import { captureException } from "@sentry/react";

const EditorV2FillBlank = renderEditorV2FillBlank;

const { Option } = Select;

const CellForm = (props: any) => {
    const {
        closeForm,
        tableType,
        curCell,
        curCellRow,
        curCellCol,
        mentionsList,
        hasMentions,
        block,
        setBlock,
        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
    } = props;

    const tmpId = `${curCellRow}_${curCellCol}_text`;

    const [isSubmitting, setSubmitting] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [currentCell, setCurrentCell] = useState(curCell);

    const [currentEditor, setCurrentEditor] = useState(tmpId);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [tmpId, currentEditor]);

    useEffect(() => {
        if (
            ((tableType === "select" || currentCell?.type === "text") &&
                isEditorEmpty(currentCell?.text || [])) ||
            (tableType === "input" &&
                (!currentCell ||
                    (currentCell.type === "options" &&
                        (currentCell.correct_option < 0 ||
                            !currentCell.options ||
                            currentCell.options.length < 1 ||
                            currentCell.options.some((option: any) =>
                                isEditorEmpty(option),
                            ))) ||
                    (currentCell.type === "input" &&
                        (currentCell.correct_answer?.length < 1 ||
                            currentCell.correct_answer?.some(
                                (v) => v === "" || v === null,
                            )))))
        ) {
            setDisabled(true);
        } else setDisabled(false);
    }, [currentCell]);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            let tmpBlock = cloneDeep(block);

            const tempData = tmpBlock?.data[BLOCK_TYPES.TABLE_BLOCK].data;

            tempData[curCellRow][curCellCol] = currentCell;

            tmpBlock = set(
                tmpBlock,
                ["data", BLOCK_TYPES.TABLE_BLOCK, "data"],
                tempData,
            );

            setBlock(tmpBlock);

            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e)
            message.success("Could not complete the request!");
        }
        setSubmitting(false);
        closeForm();
    };

    const renderOption = (option: any, idx: number) => (
        <Row
            key={idx}
            justify="start"
            style={{
                border: "1px solid #ECECEC",
                borderRadius: "15px",
                marginBottom: "10px",
            }}
        >
            <Col
                span={1}
                style={{
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        padding: "10px 0px",
                    }}
                >
                    <div
                        style={{
                            borderRadius: "50%",
                            width: "24px",
                            height: "24px",
                            justifyContent: "center",
                            alignItems: "center",
                            background:
                                currentCell?.correct_option === idx
                                    ? "#4EDEB2"
                                    : "lightgray",
                            color: "#333333",
                            display: "inline-flex",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        {idx + 1}.
                    </div>
                </div>
            </Col>
            <Col
                span={21}
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div style={{ width: "100%" }}>
                    <SlateEditor
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${tmpId}_option_${idx}`}
                        onChange={(value: any) => {
                            const tmpCell = cloneDeep(currentCell);
                            tmpCell.options[idx] = value;
                            setCurrentCell(tmpCell);
                        }}
                        value={option}
                        isFocused={currentEditor == `${tmpId}_option_${idx}`}
                        setEditor={(id: string) => {
                            setCurrentEditor(id);
                        }}
                        isOption={true}
                    />
                </div>
            </Col>
            {currentEditor == `${tmpId}_option_${idx}` && (
                <Col span={2} style={{ paddingTop: "10px" }}>
                    <Button
                        icon={<DeleteOutlined />}
                        type="primary"
                        shape="circle"
                        danger
                        size="small"
                        onClick={() => {
                            if (currentCell?.options.length == 1) {
                                message.warn(`Need at least 1 option`);
                                return;
                            }

                            const tmpCell = cloneDeep(currentCell);
                            tmpCell?.options.splice(idx, 1);
                            setCurrentCell(tmpCell);
                        }}
                    />
                </Col>
            )}
        </Row>
    );

    const renderAnswer = (answer: any, idx: number) => (
        <div
            key={idx}
            style={{
                width: "100%",
                display: "flex",
                gap: "16px",
                border: "1px solid #ECECEC",
                borderRadius: "15px",
                marginBottom: "10px",
            }}
        >
            <div
                style={{
                    padding: "10px 0px",
                    flexShrink: 0,
                }}
            >
                <div
                    style={{
                        borderRadius: "50%",
                        width: "24px",
                        height: "24px",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "lightgray",
                        color: "#333333",
                        display: "inline-flex",
                        fontSize: "12px",
                        fontWeight: "bold",
                    }}
                >
                    {idx + 1}.
                </div>
            </div>
            <Form.Item
                rules={[
                    {
                        required: true,
                    },
                ]}
                style={{ width: "100%" }}
                validateStatus={!answer.trim() ? "error" : ""}
                help={!answer.trim() ? "Field cannot be empty" : ""}
            >
                <Input
                    style={{
                        padding: "10px",
                        borderRadius: "8px",
                        width: "100%",
                    }}
                    value={answer || ""}
                    onChange={(e) => {
                        const tmpCell = cloneDeep(currentCell);
                        tmpCell.correct_answer[idx] = e.target.value;
                        setCurrentCell(tmpCell);
                    }}
                    required
                />
            </Form.Item>

            <Button
                icon={<DeleteOutlined />}
                type="primary"
                shape="circle"
                danger
                size="small"
                onClick={() => {
                    if (currentCell?.correct_answer.length == 1) {
                        message.warn(`Need at least 1 correct answer`);
                        return;
                    }

                    const tmpCell = cloneDeep(currentCell);
                    tmpCell?.correct_answer.splice(idx, 1);
                    setCurrentCell(tmpCell);
                }}
            />
        </div>
    );

    const fillBlankProps = {
        block: currentCell,
        setBlock: setCurrentCell,
        setIsEditor,
        isEditor,
        isNewBlock,
        setIsNewBlock,

        showDuration: false,
        showBackgroundAudio: false,
    };

    return (
        <Form>
            <Row gutter={16}>
                {tableType !== "select" && (
                    <Col span={24}>
                        <Form.Item label="Cell Type">
                            <Select
                                defaultValue={currentCell?.type}
                                onChange={(value) => {
                                    const tmpCell = cloneDeep(currentCell);
                                    tmpCell.type = value;
                                    setCurrentCell(tmpCell);
                                }}
                                options={[
                                    { value: "text", label: "Text" },
                                    { value: "input", label: "Input" },
                                    { value: "options", label: "Options" },
                                    {
                                        value: "v2_fill_blank",
                                        label: "Fill Blank",
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                )}

                {tableType === "select" && (
                    <Col span={12}>
                        <Form.Item label="Is Correct Option?">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={currentCell?.is_correct}
                                onChange={(value) => {
                                    const tmpCell = cloneDeep(currentCell);
                                    tmpCell.is_correct = value;
                                    setCurrentCell(tmpCell);
                                }}
                            />
                        </Form.Item>
                    </Col>
                )}

                {(tableType === "select" || currentCell?.type === "text") && (
                    <Col span={22}>
                        <div
                            style={{
                                marginBottom: "20px",
                            }}
                        >
                            Cell Text:
                        </div>
                        <SlateEditor
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={tmpId}
                            onChange={(value: any) => {
                                const tmpCell = cloneDeep(currentCell);
                                tmpCell.text = value;
                                setCurrentCell(tmpCell);
                            }}
                            isFocused={currentEditor === tmpId}
                            setEditor={(id: string) => setCurrentEditor(id)}
                            value={currentCell?.text}
                            placeholder={"Type the cell data here..."}
                        />
                    </Col>
                )}

                {tableType !== "select" && currentCell?.type === "options" && (
                    <Col span={24}>
                        <div>
                            {currentCell?.options.map(
                                (option: any, idx: number) =>
                                    renderOption(option, idx),
                            )}
                            <Button
                                type="dashed"
                                onClick={() => {
                                    const tmpCell = cloneDeep(currentCell);
                                    tmpCell?.options.push([]);
                                    setCurrentCell(tmpCell);
                                }}
                                style={{ margin: "0px 24px" }}
                            >
                                Add Option
                            </Button>
                        </div>
                        <br />
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            label={"Correct Option"}
                            validateStatus={
                                currentCell?.correct_option < 0 ? "error" : ""
                            }
                            help={
                                currentCell?.correct_option < 0
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Select
                                defaultValue={
                                    currentCell?.correct_option >= 0
                                        ? currentCell.correct_option
                                        : null
                                }
                                onChange={(value: any) => {
                                    const tmpCell = cloneDeep(currentCell);
                                    tmpCell.correct_option = value;
                                    setCurrentCell(tmpCell);
                                }}
                            >
                                <Option value={null} disabled>
                                    Select Correct Option
                                </Option>
                                {currentCell?.options.map(
                                    (_: any, idx: number) => {
                                        return (
                                            <Option value={idx} key={idx}>
                                                {idx + 1}
                                            </Option>
                                        );
                                    },
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                {tableType !== "select" && currentCell?.type === "input" && (
                    <Col span={22}>
                        <div
                            style={{
                                marginBottom: "20px",
                            }}
                        >
                            Correct answers:
                        </div>
                        <div>
                            {currentCell?.correct_answer.map(
                                (option: any, idx: number) =>
                                    renderAnswer(option, idx),
                            )}
                            <Button
                                type="dashed"
                                onClick={() => {
                                    const tmpCell = cloneDeep(currentCell);
                                    tmpCell?.correct_answer.push("");
                                    setCurrentCell(tmpCell);
                                }}
                                style={{
                                    margin: "0px 24px",
                                    marginBottom: "20px",
                                }}
                            >
                                Add Correct Answer
                            </Button>
                        </div>

                        <KeyboardError
                            value={currentCell?.correct_answer.reduce(
                                (acc: string, v: string) => `${acc}${v}`,
                                "",
                            )}
                            keyboards={currentCell?.custom_keyboard}
                        />

                        <div style={{ marginBottom: "20px" }}>
                            <CustomKeyboardEditor
                                value={currentCell?.custom_keyboard}
                                onOk={(data: any) => {
                                    let tmpCell = _.cloneDeep(currentCell);

                                    tmpCell = _.set(
                                        tmpCell,
                                        ["custom_keyboard"],
                                        data,
                                    );

                                    setCurrentCell(tmpCell);
                                }}
                            />
                        </div>
                    </Col>
                )}

                {tableType !== "select" &&
                    currentCell?.type === "v2_fill_blank" && (
                        <Col span={22}>
                            <div
                                style={{
                                    marginBottom: "20px",
                                }}
                            >
                                <EditorV2FillBlank {...fillBlankProps} />
                            </div>
                        </Col>
                    )}

                {tableType !== "select" &&
                    ["input", "options", "v2_fill_blank"].includes(
                        currentCell?.type,
                    ) && (
                        <Col span={22}>
                            <div
                                style={{
                                    marginBottom: "20px",
                                }}
                            >
                                Solution (optional):
                            </div>
                            <SlateEditor
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                id={`${tmpId}_solution`}
                                onChange={(value: any) => {
                                    const tmpCell = cloneDeep(currentCell);
                                    tmpCell.solution = value;
                                    setCurrentCell(tmpCell);
                                }}
                                isFocused={
                                    currentEditor === `${tmpId}_solution`
                                }
                                setEditor={(id: string) => setCurrentEditor(id)}
                                value={currentCell?.solution || []}
                                placeholder={"Type the solution here..."}
                                required={false}
                            />
                        </Col>
                    )}

                <Col span={24}>
                    <Space style={{ float: "right" }}>
                        <Button type="default" onClick={closeForm}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            loading={isSubmitting}
                            disabled={isDisabled}
                        >
                            Submit
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};

export default CellForm;
