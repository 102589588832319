import { FunctionComponent } from "react";

import {
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import {
    STUDENT_QUERY,
    STUDENTS_QUERY,
    STUDENT_AGGREGATE_QUERY,
} from "../graphql";

export const withHookForStudents = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { pagination } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            STUDENTS_QUERY,
            {
                variables: {
                    ...pagination,
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "students", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    students: items,
                    subscribeToMore,
                    updateQuery,
                    loadStudentsData: loadItemsData,
                    refetchStudents: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateStudent = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            STUDENT_AGGREGATE_QUERY,
            {
                variables: {},
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "studentAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    studentAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForStudent = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            STUDENT_QUERY,
            {
                variables: {
                    id: getLastStringParamFromUrl(),
                },
            },
        );

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingStudent: loading,
                    student: data?.student,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
