import { FunctionComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";

import {
    CHAPTERS_QUERY,
    CREATE_CHAPTER_MUTATION,
    UPDATE_CHAPTER,
    DELETE_CHAPTER_MUTATION,
} from "../../graphql";
import { getLastStringParamFromUrl } from "src/helpers/functions";
import { captureException } from "@sentry/react";

// Query
export const withChapters = (Component: FunctionComponent) =>
    graphql(CHAPTERS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,

                    // temp
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                chapters,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadChaptersData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            chapters: [
                                ...previousResult.chapters,
                                ...fetchMoreResult.chapters,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                chapters,
                subscribeToMore,
                updateQuery,
                loadChaptersData,
                refetchChapters: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreateChapter = (Component: FunctionComponent) =>
    graphql(CREATE_CHAPTER_MUTATION, {
        props: ({ mutate }) => ({
            createChapter: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createChapter: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { insert_group_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createChapter: {
                                object,
                                __typename: "insert_group_one",
                            },
                        },
                    });
                    return insert_group_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateChapter = (Component: FunctionComponent) =>
    graphql(UPDATE_CHAPTER, {
        props: ({ mutate }) => ({
            updateChapter: (object: Object) => {
                console.log({ object });
                mutate({
                    variables: { object, id: object.id },
                    optimisticResponse: {
                        updateChapter: {
                            ...object,
                            __typename: "group",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withDeleteChapter = (Component: FunctionComponent) =>
    graphql(DELETE_CHAPTER_MUTATION, {
        props: ({ mutate }) => ({
            deleteChapter: ({ id, submissionIds }) => {
                mutate({
                    variables: { id, submissionIds },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteChapter: {
                            id,
                            __typename: "DELETE_CHAPTER_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);
