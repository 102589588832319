import { Button, Modal, Typography } from "antd";
import React, { useState } from "react";
import BlockJumpCard from "./BlockJumpCard";

const { Text } = Typography;

const AdjustDifficultyModal = (props: any) => {
    const { isSaving, blocks, currentBlock } = props;
    const block = blocks[currentBlock];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div style={{ marginTop: "20px" }}>
            <Text>Adjust Difficulty Level logic</Text>

            <Button
                style={{
                    marginTop: "10px",
                }}
                type="primary"
                block
                onClick={showModal}
            >
                Modify
            </Button>
            <Modal
                title="Add level change logic and conditions"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                width={720}
            >
                <div
                    style={{
                        marginTop: "20px",
                    }}
                />
                <BlockJumpCard {...props} block={block} idx={currentBlock} />
                <div
                    style={{
                        marginTop: "20px",
                    }}
                />
            </Modal>
        </div>
    );
};

export default AdjustDifficultyModal;
