import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Form, Select, Switch } from "antd";
import _ from "lodash";
import { BLOCK_TYPES } from "../../common/";

const { TABLE_BLOCK } = BLOCK_TYPES;

const renderSettingsTable = ({ block, setBlock }: any) => {
    const {
        data: {
            [TABLE_BLOCK]: { is_heading, is_required, type },
        },
    } = block;

    return (
        <>
            <Form.Item label="Required?">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={is_required}
                    onChange={(value) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", TABLE_BLOCK, "is_required"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            </Form.Item>
            <Form.Item label="Has Heading?">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={is_heading}
                    onChange={(value) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", TABLE_BLOCK, "is_heading"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            </Form.Item>
            <Form.Item label="Table Type">
                <Select
                    defaultValue={type}
                    onChange={(value) => {
                        let tmpBlock = _.cloneDeep(block);

                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", TABLE_BLOCK, "type"],
                            value,
                        );

                        setBlock(tmpBlock);
                    }}
                    options={[
                        { value: "input", label: "User Input" },
                        { value: "select", label: "Tap to answer" },
                    ]}
                />
            </Form.Item>
        </>
    );
};

export default renderSettingsTable;
