import React from "react";
import { Form, Input, Select, Switch } from "antd";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { CustomKeyboardEditor, MathRender } from "src/components";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const INPUT_BLOCK = "input";
// const RICHTEXT_INPUT_BLOCK = "rich_text_input";
const MATH_INPUT_BLOCK = "rich_text_mathquill_input_html";

const EditorInput = ({
    block,
    setBlock,
    disableWrite,
    renderLinkSelect,
}: any) => {
    const {
        type,
        data: {
            name = "",
            input: {
                default_value = "",
                placeholder = "Input",
                custom_keyboard = [],
                isTextarea,
                inputType,
            },
            linked_global_context_variable,
        },
    } = block;

    return (
        <>
            <div>
                <br />
                <Form.Item
                    label="Name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    validateStatus={!name || !isNaN(name[0]) ? "error" : ""}
                    help={
                        !name
                            ? "Field cannot be empty"
                            : !isNaN(name[0])
                            ? "Cannot  start with a number"
                            : ""
                    }
                >
                    <Input
                        value={name}
                        disabled={disableWrite}
                        onChange={(e) => {
                            const value = e.target.value;
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "name"],
                                value?.trim(),
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>

                {renderLinkSelect &&
                    renderLinkSelect({
                        value: linked_global_context_variable?.name,
                        onChange: (v) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                [
                                    "data",
                                    "linked_global_context_variable",
                                    "name",
                                ],
                                v,
                            );
                            // tmpBlock = _.set(
                            //     tmpBlock,
                            //     ["data", "value"],
                            //     v !== null ? `@@${v}@@` : v,
                            // );
                            setBlock(tmpBlock);
                        },
                    })}

                {type !== MATH_INPUT_BLOCK && (
                    <Form.Item label={"Placeholder"}>
                        <Input
                            value={placeholder}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", "input", "placeholder"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                )}

                <Form.Item label={"Default Value"}>
                    {type !== INPUT_BLOCK ? (
                        <>
                            <Input.TextArea
                                style={{
                                    width: "100%",
                                    flexShrink: 0,
                                }}
                                value={default_value}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["data", "input", "default_value"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                            <div
                                style={{
                                    padding: "10px",
                                    width: "100%",
                                    border: "1px dashed #aaa",
                                }}
                            >
                                <MathRender
                                    src={
                                        default_value
                                            ? `$${default_value}$`
                                            : ""
                                    }
                                />
                            </div>
                        </>
                    ) : (
                        <Input
                            value={default_value}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", "input", "default_value"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    )}
                </Form.Item>

                {type === INPUT_BLOCK && (
                    <>
                        <Form.Item label="Is Textarea?">
                            <Switch
                                disabled={disableWrite}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={isTextarea}
                                onChange={(value) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["data", "input", "isTextarea"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>

                        <Form.Item label="Input Type?">
                            <Select
                                disabled={disableWrite}
                                placeholder="Select"
                                showSearch
                                value={inputType}
                                style={{
                                    flex: 0.5,
                                    width: "100%",
                                }}
                                onChange={(value) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["data", "input", "inputType"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                options={[
                                    {
                                        value: "NORMAL",
                                        label: `Normal`,
                                    },
                                    {
                                        value: "NUMBER",
                                        label: `Number`,
                                    },
                                    {
                                        value: "CUSTOM",
                                        label: `Custom`,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </>
                )}

                {inputType === "CUSTOM" && (
                    <Form.Item label="Custom Keyboard">
                        <CustomKeyboardEditor
                            disabled={disableWrite}
                            value={custom_keyboard}
                            onOk={(data: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "input", "custom_keyboard"],
                                    data,
                                );
                                setBlock(tmpBlock);
                            }}
                            hasLatex={type !== INPUT_BLOCK}
                        />
                    </Form.Item>
                )}
            </div>
        </>
    );
};

export default EditorInput;
