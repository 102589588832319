import {
    Button,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Select,
    Switch,
} from "antd";
import { cloneDeep, isString, set } from "lodash";
import { useEffect, useMemo, useState } from "react";
import DroppedItem from "../../../AllInOne/renderEditor/ComponentsEditor/GenericTable/preview/DroppedItem";
import { RenderUpload, SlateEditor } from "src/components";
import { BlockPicker } from "react-color";
import { replaceCDNUrl } from "../../../../helpers";
import { v4 as uuid } from "uuid";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import TagSelector from "./TagSelector";

const DropItemModal = ({
    tagsList = [],
    isModalOpen,
    setModalOpen,
    droppedSource,
    onSubmit,
    disableWrite,
    currentEditor,
    setCurrentEditor,
    hasMentions,
    mentionsList,
    optionsSchema,
    tagsClusterList = [],
    disableCreate = false,
}: any) => {
    const [source, setSource] = useState(droppedSource);
    const [currentIndex, setCurrIndex] = useState(-1);
    const [deletePrev, setDeletePrev] = useState(false);

    useEffect(() => {
        setCurrIndex(
            tagsClusterList?.find((v) => v?.label?.includes("Tags"))?.value,
        );
    }, []);

    const handleSubmit = () => {
        if (
            !tagsClusterList?.filter((v) => v?.label?.includes("Tags"))?.length
        ) {
            message.error("No tags cluster present!");
            return;
        }
        let data: any = { delete_prev_tag: deletePrev };
        if (
            droppedSource?.genericId &&
            droppedSource?.genericId !== source?.genericId
        ) {
            data.prev_tag_id = droppedSource?.genericId;
        }

        if (source) {
            delete source.is_cluster_visible;
            data.tag_data = source;
            if (
                source?.genericId &&
                tagsList?.find((v) => v.genericId === source?.genericId)
            ) {
                data.tag_id = source?.genericId;
            } else {
                source.genericId = uuid();
                data.tag_cluster_index = currentIndex;
            }
        }
        const currentCount = source?.props?.currentCount?.value;
        if (currentCount == 0 || currentCount == 1) {
            const modifiedSource = set(
                source,
                ["props", "textVisible", "value"],
                "0",
            );
            onSubmit(modifiedSource, data);
        } else {
            const modifiedSource = set(
                source,
                ["props", "currentCount", "value"],
                currentCount - 1,
            );
            onSubmit(modifiedSource, data);
        }

        setModalOpen(false);
    };

    const isNewTag =
        !source?.genericId ||
        !tagsList?.find((v) => v.genericId === source?.genericId);

    return (
        <Modal
            title={"Dropped Item"}
            onCancel={() => setModalOpen(false)}
            onOk={handleSubmit}
            open={isModalOpen}
            destroyOnClose
            width={600}
            maskClosable={false}
        >
            <TagSelector
                tagsList={tagsList}
                source={source}
                setSource={setSource}
                droppedSource={droppedSource}
            />
            {!disableCreate && (
                <>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            let data = isString(optionsSchema)
                                ? JSON.parse(optionsSchema)
                                : cloneDeep(optionsSchema);
                            setSource(
                                data?.find((v) => v?.type === "text_option"),
                            );
                        }}
                    >
                        Add Tag
                    </Button>
                    {source && (
                        <div
                            style={{
                                border: "2px solid green",
                                padding: "10px",
                                marginTop: "10px",
                            }}
                        >
                            {droppedSource?.genericId &&
                                droppedSource?.genericId !==
                                    source?.genericId &&
                                tagsList?.find(
                                    (v) =>
                                        v.genericId ===
                                        droppedSource?.genericId,
                                ) && (
                                    <Form.Item label="Delete Previously Selected Tag from tags cluster?">
                                        <Switch
                                            disabled={disableWrite}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                            checked={deletePrev}
                                            onChange={(value) =>
                                                setDeletePrev(value)
                                            }
                                        />
                                    </Form.Item>
                                )}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h3
                                    style={{
                                        fontWeight: 700,
                                    }}
                                >
                                    {isNewTag ? "New Tag" : "Selected Tag"}
                                </h3>
                                <Button
                                    type="primary"
                                    danger
                                    size="small"
                                    onClick={() => {
                                        droppedSource = undefined;
                                        setSource(droppedSource);
                                    }}
                                >
                                    Remove
                                </Button>
                            </div>
                            {isNewTag && (
                                <Form.Item
                                    label={"Which tag cluster to save in?"}
                                >
                                    <Select
                                        style={{
                                            width: "100%",
                                        }}
                                        value={currentIndex}
                                        onChange={(value) =>
                                            setCurrIndex(value)
                                        }
                                        placeholder="Select or add tags"
                                        options={tagsClusterList?.filter((v) =>
                                            v?.label?.includes("Tags"),
                                        )}
                                    />
                                </Form.Item>
                            )}
                            <Form.Item label={"Text"}>
                                <SlateEditor
                                    disabled={disableWrite}
                                    hasMentions={hasMentions}
                                    mentionsList={mentionsList}
                                    id={`drop-cell-text-value-latex`}
                                    onChange={(value: any) => {
                                        let tmpCell = cloneDeep(source);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["props", "text", "value"],
                                            value,
                                        );
                                        setSource(tmpCell);
                                    }}
                                    isFocused={
                                        currentEditor ===
                                        `drop-cell-text-value-latex`
                                    }
                                    setEditor={(id: string) =>
                                        setCurrentEditor(id)
                                    }
                                    value={source?.props?.text?.value}
                                    placeholder={"Type here..."}
                                    required={false}
                                    // v2={true}
                                />
                            </Form.Item>
                            <Form.Item label={"Value"}>
                                <Input
                                    disabled={disableWrite}
                                    onChange={(e: any) => {
                                        let tmpCell = cloneDeep(source);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["props", "drop_value", "value"],
                                            e.target.value,
                                        );
                                        setSource(tmpCell);
                                    }}
                                    value={source?.props?.drop_value?.value}
                                    placeholder={"Type here..."}
                                    // v2={true}
                                />
                            </Form.Item>
                            <Form.Item label="Categories">
                                <Select
                                    disabled={disableWrite}
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    mode="tags"
                                    value={
                                        typeof source?.props?.categoryNew
                                            ?.value === "string"
                                            ? [
                                                  source?.props?.categoryNew
                                                      ?.value,
                                              ]
                                            : source?.props?.categoryNew?.value
                                    }
                                    onChange={(value) => {
                                        let tmpBlock = cloneDeep(source);
                                        set(
                                            tmpBlock,
                                            ["props", "categoryNew", "value"],
                                            value,
                                        );
                                        setSource(tmpBlock);
                                    }}
                                    options={[]}
                                />
                            </Form.Item>
                            <Form.Item label={"Background"}>
                                <Form.Item>
                                    <Select
                                        disabled={disableWrite}
                                        defaultValue={
                                            source?.props?.input_background
                                                ?.value?.type
                                        }
                                        style={{
                                            width: 200,
                                        }}
                                        placeholder="Type"
                                        onChange={(value: any) => {
                                            let tmpCell = cloneDeep(source);
                                            tmpCell = _.set(
                                                tmpCell,
                                                [
                                                    "props",
                                                    "input_background",
                                                    "value",
                                                    "type",
                                                ],
                                                value,
                                            );
                                            setSource(tmpCell);
                                        }}
                                        options={[
                                            {
                                                value: "SOLID",
                                                label: "Solid Color",
                                            },
                                            {
                                                value: "IMAGE",
                                                label: "Image",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                {source?.props?.input_background?.value
                                    ?.type === "SOLID" && (
                                    <Form.Item label="Color">
                                        <BlockPicker
                                            colors={[]}
                                            color={
                                                source?.props?.input_background
                                                    ?.value?.color
                                            }
                                            onChangeComplete={(color: any) => {
                                                let tmpCell = cloneDeep(source);
                                                tmpCell = _.set(
                                                    tmpCell,
                                                    [
                                                        "props",
                                                        "input_background",
                                                        "value",
                                                        "color",
                                                    ],
                                                    color.hex,
                                                );
                                                setSource(tmpCell);
                                            }}
                                            triangle="hide"
                                        />
                                    </Form.Item>
                                )}
                                {["IMAGE", "GIF"].includes(
                                    source?.props?.input_background?.value
                                        ?.type,
                                ) && (
                                    <>
                                        <Form.Item label="Image">
                                            <RenderUpload
                                                singleUpload={true}
                                                disabled={disableWrite}
                                                addExtension={true}
                                                path="home-explore/document/"
                                                onChangeCustom={(e) => {
                                                    const value = replaceCDNUrl(
                                                        e.target.value,
                                                        e.target.bucket,
                                                    );
                                                    let tmpCell =
                                                        cloneDeep(source);
                                                    tmpCell = _.set(
                                                        tmpCell,
                                                        [
                                                            "props",
                                                            "input_background",
                                                            "value",
                                                            "asset",
                                                        ],
                                                        value,
                                                    );
                                                    setSource(tmpCell);
                                                }}
                                                label="upload the file"
                                                value={
                                                    source?.props
                                                        ?.input_background
                                                        ?.value?.asset
                                                }
                                            />
                                            <Input
                                                placeholder="Add value here"
                                                value={
                                                    source?.props
                                                        ?.input_background
                                                        ?.value?.asset
                                                }
                                                onChange={(e) => {
                                                    const value =
                                                        e.target.value;
                                                    let tmpCell =
                                                        cloneDeep(source);
                                                    tmpCell = _.set(
                                                        tmpCell,
                                                        [
                                                            "props",
                                                            "input_background",
                                                            "value",
                                                            "asset",
                                                        ],
                                                        value,
                                                    );
                                                    setSource(tmpCell);
                                                }}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.Item>
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
};

export default DropItemModal;
