import { isEditorEmpty } from "../../common/index";
import { getValidateStatus } from "../MCQ/renderEditor";

const isValidCallbackReOrder = ({ block }: any) => {
    if (!block || !block.data)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            mcq: { text, options, correct_options },
        },
    } = block;

    const optsIsNotValid = options
        .map((o, idx) => getValidateStatus(correct_options, idx).error == "")
        .includes(false);

    return {
        isValid:
            !isEditorEmpty(text) &&
            !options.some((option: any) => isEditorEmpty(option)) &&
            Boolean(correct_options.length) &&
            !optsIsNotValid,
        error: (
            // <Alert
            //     message="Validation Error"
            //     description={`Question Text, options, correct option cannot be empty`}
            //     type="error"
            //     showIcon
            // />
            <></>
        ),
    };
};

export default isValidCallbackReOrder;
