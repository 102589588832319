import { SlateEditor } from "src/components/plate";
import { Button, Checkbox, Col, Row, Select } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { AudioPicker, BLOCK_TYPES, DurationPicker } from "../../common";
import FeedbackField from "../../common/FeedBackField";
import PreviewBlocksField from "../../common/PreviewBlocksField";
import { VOICE_TYPE } from "./defaultValue";

const { V2_VOICE_BLOCK } = BLOCK_TYPES;

const Editor = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    setIsNewBlock,
    isNewBlock,
    worksheet,

    showDuration = true,
    showBackgroundAudio = true,
    hasMentions,
    mentionsList = [],
    disableWrite,
    hasPreviewBlocks,
    isReadOnlyMode,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [V2_VOICE_BLOCK]: {
                title = [],
                correct_answer = [],
                type,
                question_audio,
                is_correct_input,
            },
            other: { global_feedback = null, description = [] } = {},
        },
    } = block;

    const [currentEditor, setCurrentEditor] = useState(``);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [curCell, setCurCell] = useState({});

    useEffect(() => {
        setCurrentEditor(``);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor !== "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    const showFeedbackModal = () => {
        setCurCell(
            global_feedback || {
                index: 0,
                values: [],
            },
        );
        setIsFeedbackModalOpen(true);
    };

    const feedbackFieldProps = {
        isModalOpen: isFeedbackModalOpen,
        isGlobal: true,
        closeForm: () => setIsFeedbackModalOpen(false),
        feedback: curCell,
        onSave: (data: any) => {
            const obj = {
                ...block,
                data: {
                    ...block.data,
                    other: { ...block.data.other, global_feedback: data },
                },
            };
            setBlock(obj);
        },
        curIndex: 0,
        isEditor,
        setIsEditor,
        hasMentions,
        mentionsList,
        disableWrite,
        isReadOnlyMode,
    };

    return (
        <div>
            <FeedbackField {...feedbackFieldProps} />
            <h3
                style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                }}
            >
                Preview Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                {hasPreviewBlocks && (
                    <PreviewBlocksField
                        disableWrite={disableWrite}
                        fixed_blocks={block?.data?.other?.fixed_blocks || []}
                        updateFixedBlocks={(data: any) => {
                            const obj = {
                                ...block,
                                data: {
                                    ...block.data,
                                    other: {
                                        ...block.data.other,
                                        fixed_blocks: data,
                                    },
                                },
                            };
                            setBlock(obj);
                        }}
                    />
                )}

                <Col span={22}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Question
                    </h3>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_description`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "other", "description"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={
                            currentEditor === `${id || tmpId}_description`
                        }
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={description}
                        placeholder={"Type your description here..."}
                    />
                </Col>
                <Col span={24}>
                    {showBackgroundAudio &&
                        [
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <h3>Background Audio</h3>
                                <AudioPicker
                                    disabled={isReadOnlyMode}
                                    block={block}
                                    setBlock={setBlock}
                                />
                            </div>
                        )}
                </Col>
            </Row>

            <h3
                style={{
                    fontWeight: "bold",
                    margin: "10px 0",
                }}
            >
                Play Area
            </h3>

            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                <Col span={22}>
                    <h3 style={{ fontWeight: "bold" }}>Text</h3>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_text`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", V2_VOICE_BLOCK, "title"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={currentEditor === `${id || tmpId}_text`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={title}
                        placeholder={"Type the voice text here..."}
                    />
                </Col>

                <Col span={24}>
                    <div
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        <h3>Question Audio</h3>
                        <AudioPicker
                            block={{
                                data: {
                                    other: {
                                        audio: question_audio,
                                    },
                                },
                            }}
                            disabled={isReadOnlyMode}
                            setBlockCustom={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_VOICE_BLOCK, "question_audio"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </div>
                </Col>

                <Col span={22} style={{ marginTop: "10px" }}>
                    <h3 style={{ fontWeight: "bold" }}>Type</h3>
                    <Select
                        value={type}
                        style={{ width: 200 }}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", V2_VOICE_BLOCK, "type"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        options={VOICE_TYPE}
                    />
                </Col>

                <Col span={22} style={{ margin: "30px 0" }}>
                    <h3 style={{ fontWeight: "bold" }}>Correct Answers</h3>

                    {hasMentions && mentionsList?.length > 0 && (
                        <Checkbox
                            style={{
                                marginBottom: "20px",
                            }}
                            disabled={disableWrite}
                            checked={is_correct_input}
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        V2_VOICE_BLOCK,
                                        "is_correct_input",
                                    ],
                                    e.target.checked,
                                );

                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_VOICE_BLOCK, "correct_answer"],
                                    [],
                                );
                                setBlock(tmpBlock);
                            }}
                        >
                            {`Use Input Variables as Correct Answers`}
                        </Checkbox>
                    )}

                    {is_correct_input ? (
                        <Select
                            disabled={disableWrite}
                            value={correct_answer || []}
                            placeholder="Correct Answers"
                            style={{ width: "100%" }}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_VOICE_BLOCK, "correct_answer"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            mode={`multiple`}
                        >
                            {mentionsList.map((item: any, idx: number) => {
                                return (
                                    <Select.Option value={item?.data} key={idx}>
                                        {item?.data}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    ) : (
                        <Select
                            disabled={disableWrite}
                            value={correct_answer || []}
                            mode="tags"
                            style={{ width: "100%" }}
                            placeholder="Correct Answers"
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_VOICE_BLOCK, "correct_answer"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            options={[]}
                            open={false}
                        />
                    )}
                </Col>

                <Col span={24} style={{ marginTop: "20px" }}>
                    <Button onClick={() => showFeedbackModal()}>
                        {global_feedback && global_feedback.values?.length > 0
                            ? "Edit"
                            : "Add"}{" "}
                        Global Feedback
                    </Button>
                </Col>

                {showDuration &&
                    [
                        "timed",
                        "personalized_learning",
                        "personalized_learning_v2",
                    ].includes(worksheet?.type) && (
                        <div
                            style={{
                                margin: "20px 0",
                            }}
                        >
                            <h3>Duration</h3>
                            <DurationPicker
                                disabled={disableWrite}
                                onChange={onDurationChange}
                                initialValue={block?.data?.other?.duration || 0}
                            />
                        </div>
                    )}
            </Row>
        </div>
    );
};

export default Editor;
