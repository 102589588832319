import React, { useState } from "react";

import { Layout, MetaTag } from "src/components";
import { DocumentForm } from "../../components";
import { Card, Col, Row, Spin } from "antd";

const EditView = (props: any) => {
    const { document, onFinish, onFinishFailed } = props;

    return (
        <Layout
            titles={[
                { name: "Documents", link: "/document" },
                { name: "Edit", link: "" },
                { name: document?.name, link: "" },
            ]}
        >
            <MetaTag title="Add Document" />
            <Row justify="center" align="middle">
                <Col xs={12} lg={20} md={24}>
                    <Card
                        style={{ borderRadius: "8px", padding: "12px" }}
                        title={"Document Update"}
                        bordered={true}
                    >
                        {document && (
                            <DocumentForm
                                document={document}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            />
                        )}
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default EditView;
