import { gql } from "@apollo/client";export default gql`mutation InsertVersion($object: versioning_version_insert_input!) {
    insert_versioning_version_one(
        object: $object
        on_conflict: { constraint: version_pkey, update_columns: data }
    ) {
        id
        data
        index
        version_model_map_id
    }
}
`;
