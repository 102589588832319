import { isEditorEmpty, BLOCK_TYPES } from "../../common/";

const { V2_MCQ_BLOCK } = BLOCK_TYPES;

const isValidCallbackMCQ = ({ block }: any) => {
    if (!block || !block.data)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            [V2_MCQ_BLOCK]: { options, correct_options },
            other: { description = [] },
        },
    } = block;

    const checkCorrectOption = () => Boolean(correct_options.length);

    return {
        isValid:
            !isEditorEmpty(description) &&
            !options.some((option: any) => isEditorEmpty(option?.text)) &&
            checkCorrectOption(),
        error: <></>,
    };
};

export default isValidCallbackMCQ;
