import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Form, Select, Divider } from "antd";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import React from "react";
import CondiionsFormCard from "./CondiionsFormCard";

import { v4 as uuid } from "uuid";
import { RULES_SCHEMA } from "src/modules/worksheet/components/blocks/renderers/ProgressionStage/defaultValue";

const BlockJumpCard = (props: any) => {
    const {
        blocks = [],
        setBlock,
        block,
        focusElement,
        setFocusElement,
        disableWrite,
    } = props;

    const { condition_jump } = block;

    const conditions = condition_jump?.conditions || [];

    return (
        <Card
            title={null}
            style={{
                marginBottom: "20px",
                border: "1px solid black",
                borderRadius: "8px",
            }}
            bodyStyle={{
                paddingBottom: 0,
            }}
        >
            <>
                {conditions.map((item: any, i: any) => (
                    <div key={uuid()}>
                        <CondiionsFormCard
                            conditionIndex={i}
                            condition={item}
                            blocks={blocks}
                            setBlock={setBlock}
                            block={block}
                            conditions={conditions}
                            focusElement={focusElement}
                            setFocusElement={setFocusElement}
                            disableWrite={disableWrite}
                        />
                        <Divider
                            style={{
                                background: "black",
                            }}
                        />
                    </div>
                ))}

                <Form.Item>
                    <Button
                        type="link"
                        disabled={disableWrite}
                        onClick={() => {
                            const tempConditions = [...conditions];
                            tempConditions.push({
                                rules: [RULES_SCHEMA],
                                block_id: null,
                            });

                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["condition_jump", "conditions"],
                                tempConditions,
                            );

                            setBlock(tmpBlock);
                            setFocusElement(null);
                        }}
                    >
                        <PlusCircleOutlined /> Add rule
                    </Button>
                </Form.Item>

                <Form.Item
                    label={
                        condition_jump?.conditions?.length > 0
                            ? "In all other cases, go to"
                            : "Always go to"
                    }
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        defaultValue={condition_jump?.default}
                        disabled={disableWrite}
                        onChange={(value) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["condition_jump", "default"],
                                value,
                            );
                            setBlock(tmpBlock);
                            setFocusElement(null);
                        }}
                        options={[
                            {
                                value: null,
                                label: `Next`,
                            },
                            ...blocks.map(
                                (item: {
                                    id: any;
                                    tmpId: any;
                                    variants: { block: { type: any } }[];
                                }) => ({
                                    value: item?.id || item?.tmpId,
                                    label: (
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item?.id || item?.tmpId} -{" "}
                                            {item?.variants[0]?.block?.type
                                                ?.split("_")
                                                ?.join(" ") || ``}
                                        </span>
                                    ),
                                }),
                            ),
                        ]}
                        showSearch
                    />
                </Form.Item>
            </>
        </Card>
    );
};

export default BlockJumpCard;
