const replaceAll = (str: string, find: string, replace: string) => {
    return str.replace(new RegExp(find, "g"), replace);
};

const cleanLatex = (text: string, delimiter = "$") => {
    const split = text.split(delimiter);
    let returnString = "";
    split.map((item, index) => {
        if (index % 2 === 0) {
            returnString += item;
        } else {
            returnString += replaceAll(
                `${delimiter}${item}${delimiter}`,
                "\n",
                " ",
            );
        }
    });
    return returnString;
};

export const getParts = (text: any, displayType: string) => {
    const parts = [];
    if (!text) {
        return parts;
    }
    let txt = text.trim();
    txt = cleanLatex(txt);
    txt = cleanLatex(txt, "$$");
    txt = txt.replace("< image", "<image");
    txt = txt.replace("<image =", "<image=");
    txt = txt.replace("<image = ", "<image=");
    txt = txt.replace("<image= ", "<image=");
    txt = txt.replace('" >', '">');
    const splitted = txt.split("\n");

    for (let splt of splitted) {
        splt = splt.trim();
        if (splt.includes("<image")) {
            let currentPart = splt;
            const prts = [];
            while (true) {
                const imgIndex = currentPart.indexOf("<image=");

                if (imgIndex === -1) {
                    prts.push({ type: "latex_span", val: currentPart });
                    break;
                }

                const endIndex = currentPart.indexOf('">');
                prts.push({
                    type: "latex_span",
                    val: currentPart.substr(0, imgIndex),
                });

                displayType === "card" &&
                    prts.push({
                        type: "img",
                        val: currentPart.substr(
                            imgIndex + 8,
                            endIndex - imgIndex - 8,
                        ),
                    });

                currentPart = currentPart.substr(endIndex + 2);
            }
            parts.push({
                type: "parts",
                val: prts,
            });
        } else {
            const splitArr = splt.split("");
            const isLatex =
                splitArr[0] === "$" && splitArr[splitArr.length - 1] === "$";

            if (isLatex) {
                parts.push({
                    type: "latex_div",
                    val: splt,
                });
            } else {
                parts.push({
                    type: "latex_div",
                    val: splt,
                });
            }
        }
    }
    return parts;
};

export function isHTML(input: string) {
    return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(
        input,
    );
}

export function getHasuraMutationInput(input: any) {
    const newObj: any = {};
    Object.keys(input).map((d) => {
        if (typeof input[d] !== "object" || input[d] === null) {
            newObj[d] = input[d];
        } else {
            if (Array.isArray(input[d].data)) {
                const update_columns = Object.keys(input[d].data[0]);
                update_columns.splice(update_columns.indexOf("metadata"), 1);
                newObj[d] = {
                    data: input[d].data.map((subD: any) =>
                        getHasuraMutationInput(subD),
                    ),
                    on_conflict: {
                        constraint: d.replace("s", "") + "_pkey",
                        update_columns,
                    },
                };
            } else {
                if (input[d].data) {
                    const update_columns = Object.keys(input[d].data);
                    if (update_columns.indexOf("metadata") !== -1)
                        update_columns.splice(
                            update_columns.indexOf("metadata"),
                            1,
                        );
                    newObj[d] = {
                        data: input[d].data,
                        on_conflict: {
                            constraint: d + "_pkey",
                            update_columns,
                        },
                    };
                } else newObj[d] = input[d];
            }
        }
    });
    return newObj;
}

export function interpolate(t: any, c: any) {
    return t.replace(
        /\${([^}]+)}/g,
        (m: any, p: any) =>
            p
                .split(".")
                .reduce((a: any, f: any) => (a ? a[f] : undefined), c) ?? m,
    );
}

export function getLastStringFromUrl() {
    let id = "";
    if (typeof window !== "undefined") {
        const urlByParts = window.location.pathname.split("/");
        id = urlByParts[urlByParts.length - 1];
    }
    return id;
}

export function getLastStringParamFromUrl(param = "id") {
    let id = "";
    if (typeof window !== "undefined") {
        if (window.location.search) {
            id = new URL(location.href).searchParams.get(param) || "";
            console.log("here");
        } else if (param !== "id") id = "";
        else {
            const urlByParts = window.location.pathname.split("/");
            id = urlByParts[urlByParts.length - 1];
        }
    }
    return id;
}

export function getOptionIndex(index: number) {
    const alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];
    return alphabet[index];
}

export const validateMail = (value: string) => {
    const re =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    return re.test(value);
};
