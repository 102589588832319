import React, { useState } from "react";
import _ from "lodash";
import { Button, Form, Input, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const CorrectInputValuesEditor = ({ cellData, setCell }: any) => {
    // Initialize correctInputValues from cellData, or an empty array if undefined
    const [correctInputValues, setCorrectInputValues] = useState(
        _.isString(cellData?.correctInputValues)
            ? []
            : cellData?.correctInputValues || [],
    );

    const handleAddValue = () => {
        // Add a new empty value to the list
        const newValues = [...correctInputValues, ""];
        setCorrectInputValues(newValues);
        updateCellData(newValues);
    };

    const handleDeleteValue = (index) => {
        // Remove the value at the specified index
        const newValues = correctInputValues.filter((_, idx) => idx !== index);
        setCorrectInputValues(newValues);
        updateCellData(newValues);
    };

    const handleValueChange = (value, index) => {
        // Update the value at the specified index
        const newValues = correctInputValues.map((val, idx) =>
            idx === index ? value : val,
        );
        setCorrectInputValues(newValues);
        updateCellData(newValues);
    };

    const updateCellData = (newValues) => {
        // Update the cell's data with the new correctInputValues
        let tmpCell = _.cloneDeep(cellData);
        tmpCell = _.set(tmpCell, "correctInputValues", newValues);
        setCell(tmpCell);
    };

    return (
        <>
            {correctInputValues.map((value, idx) => (
                <Form.Item key={idx} label={`Value ${idx + 1}`}>
                    <Space align="baseline">
                        <Input
                            placeholder="Enter value"
                            value={value}
                            onChange={(e) =>
                                handleValueChange(e.target.value, idx)
                            }
                        />
                        <MinusCircleOutlined
                            onClick={() => handleDeleteValue(idx)}
                        />
                    </Space>
                </Form.Item>
            ))}
            <Form.Item>
                <Button
                    type="dashed"
                    onClick={handleAddValue}
                    block
                    icon={<PlusOutlined />}
                >
                    Add Value
                </Button>
            </Form.Item>
        </>
    );
};

export default CorrectInputValuesEditor;
