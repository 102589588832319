import { Button, Modal, Typography } from "antd";
import React, { useState } from "react";
import FileUpload from "./FileUpload";
import { v4 as uuid } from "uuid";
import _ from "lodash";

const StoriesUpload = (props: any) => {
    const { block, setBlock, disabled, setNewJob, storiesPath = [] } = props;
    const {
        id,
        data: {
            other: { job },
        },
    } = block;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <Button type="ghost" disabled={disabled} onClick={showModal}>
                Upload
            </Button>
            <Modal
                title={
                    <Typography.Title level={4}>
                        Upload stories
                    </Typography.Title>
                }
                open={isModalOpen}
                maskClosable
                destroyOnClose
                width={720}
                footer={null}
                onCancel={() => setIsModalOpen(false)}
            >
                {job?.id && (
                    <>
                        <Typography.Title level={5}>
                            Current Job - {job?.id}{" "}
                            {job?.name ? `(${job?.name})` : ""}
                        </Typography.Title>
                        <Typography.Text
                            style={{
                                marginBottom: "20px",
                            }}
                        >
                            Last Added - {job?.last_updated?.toLocaleString?.()}
                        </Typography.Text>
                    </>
                )}
                <FileUpload
                    jobName={job?.name}
                    setIsModalOpen={setIsModalOpen}
                    setBlock={({ jobId, name }: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(tmpBlock, ["data", "other", "job"], {
                            id: jobId,
                            last_updated: new Date(),
                            name,
                        });
                        let newId;
                        if (!storiesPath?.length && !id) {
                            newId = tmpBlock?.data?.storiesId || uuid();
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "storiesId"],
                                newId,
                            );
                            setBlock(tmpBlock);
                            if (setNewJob) {
                                setNewJob({
                                    path: [newId],
                                    job_id: jobId,
                                    name,
                                });
                            }
                        } else if (storiesPath?.length > 1 && !storiesPath[0]) {
                            newId = uuid();
                            setBlock(tmpBlock, newId);
                            if (setNewJob) {
                                storiesPath[0] = newId;
                                setNewJob({
                                    path: [...storiesPath, id],
                                    job_id: jobId,
                                    name,
                                });
                            }
                        } else {
                            setBlock(tmpBlock);
                            if (setNewJob)
                                setNewJob({
                                    path: [...storiesPath, id],
                                    job_id: jobId,
                                    name,
                                });
                        }
                    }}
                />
            </Modal>
        </>
    );
};

export default StoriesUpload;
