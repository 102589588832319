import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";
import { BLOCK_TYPES } from "../../common/index";
const { V2_AVATAR_BLOCK } = BLOCK_TYPES;

const renderCard = (props: any) => {
    const {
        block: {
            data: {
                [V2_AVATAR_BLOCK]: { value, type },
            },
        },
    } = props;
    return (
        <BlockCard {...props}>
            {type === "image" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={value} style={{ height: "30px" }} />
                </div>
            )}
            {type === "video" && <div>Video Block</div>}
        </BlockCard>
    );
};
export default renderCard;
