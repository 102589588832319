import React from "react";
import { Table, Row, Button, Spin } from "antd";
import { LoadMoreBtn } from "..";

const RenderTable = (props) => {
    const { loading, columns, data, loadData, aggregate } = props;
    const [fetching, setFetching] = React.useState(false);

    return (
        <Spin spinning={fetching}>
            {data && (
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    scroll={{ x: "auto" }}
                />
            )}
            <LoadMoreBtn
                aggregate={aggregate}
                dataLength={data?.length}
                loadData={loadData}
                setFetching={setFetching}
                disabled={fetching}
            />
        </Spin>
    );
};

export default RenderTable;
