import { Field, RenderCheckBox } from "src/components";
import FormikPersist from "src/components/FormikPersist";
import { Form, Space, Typography, Row, Col, Button, message } from "antd";
import { withFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { captureException } from "@sentry/react";

const UtmForm = (props: any) => {
    const { onFinishFailed, values, handleSubmit, isSubmitting, closeForm } =
        props;

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            <Typography.Title level={5}>Activate UTM tracking</Typography.Title>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={16}>
                <Col span={"8"}>
                    <Field
                        label="utm_source"
                        name={"utm_source"}
                        component={RenderCheckBox}
                        checked={values.utm_source}
                        checkStyle={{
                            paddingTop: "20px",
                        }}
                    />
                </Col>
                <Col span={"8"}>
                    <Field
                        label="utm_medium"
                        name={"utm_medium"}
                        component={RenderCheckBox}
                        checked={values.utm_medium}
                        checkStyle={{
                            paddingTop: "20px",
                        }}
                    />
                </Col>
                <Col span={"8"}>
                    <Field
                        label="utm_campaign"
                        name={"utm_campaign"}
                        component={RenderCheckBox}
                        checked={values.utm_campaign}
                        checkStyle={{
                            paddingTop: "20px",
                        }}
                    />
                </Col>
                <Col span={"8"}>
                    <Field
                        label="utm_term"
                        name={"utm_term"}
                        component={RenderCheckBox}
                        checked={values.utm_term}
                        checkStyle={{
                            paddingTop: "0",
                        }}
                    />
                </Col>
                <Col span={"8"}>
                    <Field
                        label="utm_content"
                        name={"utm_content"}
                        component={RenderCheckBox}
                        checked={values.utm_content}
                        checkStyle={{
                            paddingTop: "0",
                        }}
                    />
                </Col>

                <Col span={24}>
                    <Space style={{ float: "right" }}>
                        <Button type="default" onClick={closeForm}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isSubmitting}
                        >
                            Submit
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};

export const FormSchema = yup.object().shape({
    utm_source: yup.bool().default(false),
    utm_medium: yup.bool().default(false),
    utm_campaign: yup.bool().default(false),
    utm_term: yup.bool().default(false),
    utm_content: yup.bool().default(false),
});

const UtmFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { worksheet } = props;
        return {
            utm_source: worksheet?.other?.utm_source || false,
            utm_medium: worksheet?.other?.utm_medium || false,
            utm_campaign: worksheet?.other?.utm_campaign || false,
            utm_term: worksheet?.other?.utm_term || false,
            utm_content: worksheet?.other?.utm_content || false,
        };
    },
    validationSchema: FormSchema,

    async handleSubmit(
        values,
        { props: { updateWorksheet, worksheet, worksheet_id, closeForm } }: any,
    ) {
        let other = worksheet?.other || {};

        other = { ...other, ...values };

        try {
            await updateWorksheet({
                id: worksheet_id || worksheet?.id,
                object: { other },
            });

            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e)
            message.success("Could not complete the request!");
        }

        closeForm();
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = UtmFormWithFormik(UtmForm);

export default FormikForm;
