import { gql } from "@apollo/client";export default gql`mutation updateTopic(
    $object: group_set_input = {}
    $id: Int!
) {
    updateTopic: update_group_by_pk(
        pk_columns: { id: $id }
        _set: $object
    ) {
        id
        description
        cover
        other
        title
        type
    }
}
`;
