import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import {
    withHookForPlan,
    withUpdatePlan,
    withDeletePlanTagMap,
    withAddPlanTagMap,
} from "src/modules/plan/operations";
import { compose } from "src/helpers";

import EditView from "./component";
import { get, omit } from "lodash";

const Edit = (props) => {
    const {
        planData: plan,
        updatePlan,
        addPlanTagMap,
        deletePlanTagMap,
    } = props;
    const router = useRouter();

    const onFinish = async (values: any) => {
        console.log(plan);
        await updatePlan(omit(values, ["tags"]));

        const newTags = get(values, ["tags", "data"]).map((tag) => tag.tag_id);
        const oldTagIds = plan.tags.map((tag) => tag.tag.id);
        console.log(newTags, oldTagIds, values);

        const tagsToAdd = newTags.filter(
            (newTag) => oldTagIds.indexOf(newTag) == -1,
        );
        const tagsToRemove = oldTagIds.filter(
            (newTag) => newTags.indexOf(newTag) == -1,
        );

        console.log("newTags", newTags, oldTagIds, tagsToAdd, tagsToRemove);

        if (tagsToAdd.length)
            await addPlanTagMap({
                objects: tagsToAdd.map((tag) => ({
                    tag_id: tag,
                    plan_id: plan.id,
                })),
            });
        if (tagsToRemove.length)
            await deletePlanTagMap({
                ids: plan.tags
                    .filter((tag) => tagsToRemove.includes(tag.tag.id))
                    .map((tag) => tag.id),
            });

        router.push("/plan");
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <EditView
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            {...props}
        />
    );
};

export default compose(
    withHookForPlan,
    withUpdatePlan,
    withAddPlanTagMap,
    withDeletePlanTagMap,
)(Edit);
