import React from "react";
import { Card, Col, Input, Row } from "antd";

import { TabsField } from ".";

const TabAssignComponent = (props) => {
    const { selected, handleChange, dropdownOpen } = props;

    return (
        // <Card bodyStyle={{ padding: "8px" }}>
        // <Row gutter={[24, 0]} align="middle">
        // {isSet && (
        <TabsField
            value={selected}
            onChange={handleChange}
            dropdownOpen={dropdownOpen}
        />
        // )}
        // </Row>
        // </Card>
    );
};

export default TabAssignComponent;
