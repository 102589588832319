import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import { withCreateTopic } from "src/modules/book/operations";
import { compose } from "src/helpers";

import CreateView from "./component";

const Create = (props) => {
    const { createTopic } = props;
    const router = useRouter();
    const { chapter_id, redirect_to, order } = router.query;

    const onFinish = async (values: any) => {
        await createTopic(values);
        // router.push(`/tab/topic/${chapter_id}`);
        router.push(redirect_to);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <CreateView
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            order={order}
        />
    );
};

export default compose(withCreateTopic)(Create);
