import {
    message,
    Button,
    Row,
    Col,
    Space,
    Form,
    Input,
    Radio,
    Modal,
} from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { AUDIO_TEXT_SCHEMA, AUDIO_URL_SCHEMA } from "./schema";
import { DeleteOutlined } from "@ant-design/icons";
import { CLIENTS } from "./constants";
import ReactPlayer from "react-player";
import { generateAudio } from "./helpers";
import { RenderOnlyRecord, RenderUpload } from "src/components/form";
import { DeleteIcon } from "src/components";
import { captureException } from "@sentry/react";

const currentClient = CLIENTS[0].value;

export const AudioForm = ({
    audioInfo,
    disableWrite,
    onSave,
    closeForm,
    isAudioPool = false,
}: any) => {
    const [curConfig, setCurConfig] = useState(audioInfo);
    const [loading, setLoading] = useState(false);
    const [highlighted, setHighlighted] = useState("");
    const [showOptions, setShowOptions] = useState("generate");
    const [isSubmitting, setSubmitting] = useState(false);
    const [tempConfig, setTempConfig] = useState("");
    // const tempConfig = useMemo(() => _.cloneDeep(audioInfo), [audioInfo]);

    function handleSetCurConfig(config: any) {
        setCurConfig(config);
        if (isAudioPool) {
            onSave(config);
        }
    }

    useEffect(() => {
        if (highlighted) {
            setTimeout(() => {
                setHighlighted("");
            }, 3000);
        }
    }, [highlighted]);

    useEffect(() => {
        if (loading) {
            setTimeout(() => setLoading(false), 50);
        }
    }, [loading]);

    useEffect(() => {
        if (!curConfig?.audio_texts || curConfig?.audio_texts?.length) {
            return;
        }

        let tmpBlock = _.cloneDeep(curConfig);
        let tempData = _.cloneDeep(AUDIO_TEXT_SCHEMA);
        tempData.text = curConfig?.display_text;
        if (tmpBlock?.audio_texts) {
            _.set(
                tmpBlock,
                ["audio_texts", tmpBlock?.audio_texts?.length],
                tempData,
            );
        } else {
            _.set(tmpBlock, ["audio_texts"], [tempData]);
        }
        handleSetCurConfig(tmpBlock);
    }, [curConfig]);

    return (
        <Row>
            {!isAudioPool && !disableWrite && (
                <Col span={24}>
                    {curConfig?.audio_texts?.length && (
                        <Button
                            disabled={disableWrite}
                            type="primary"
                            size="small"
                            onClick={() => {
                                message.info(
                                    `Copied components config to clipboard!`,
                                );

                                navigator.clipboard.writeText(
                                    JSON.stringify(curConfig),
                                );
                            }}
                        >
                            Copy this Audio Config to Clipboard
                        </Button>
                    )}
                    <Form.Item
                        label={"Paste Audio Config here"}
                        style={{ marginTop: "10px" }}
                    >
                        <Input.TextArea
                            size="small"
                            disabled={disableWrite}
                            value={tempConfig}
                            placeholder="Paste Config here"
                            onChange={(e) => setTempConfig(e.target.value)}
                        />
                        <Button
                            size="small"
                            disabled={disableWrite}
                            type="primary"
                            onClick={() => {
                                try {
                                    const parsedJson = JSON.parse(tempConfig);
                                    handleSetCurConfig(parsedJson);
                                    setTempConfig("");
                                    message.info("Updated!");
                                    setLoading(true);
                                } catch (e) {
                                    // captureException(e)
                                    message.error("Error in JSON!");
                                }
                            }}
                        >
                            Update config
                        </Button>
                    </Form.Item>
                </Col>
            )}
            <Col span={24}>
                {!loading && curConfig?.audio_texts?.length > 0 ? (
                    curConfig?.audio_texts?.map(
                        (
                            audTxt: {
                                language: string;
                                artist: { name: string };
                                text: string;
                                audio_urls: any[];
                            },
                            idx: number,
                        ) => {
                            return (
                                <Row gutter={20} key={`audio_texts_${idx}`}>
                                    {!disableWrite && (
                                        <Col span={24}>
                                            <Radio.Group
                                                options={[
                                                    {
                                                        label: "Generate",
                                                        value: "generate",
                                                    },
                                                    {
                                                        label: "Upload",
                                                        value: "upload",
                                                    },
                                                    {
                                                        label: "Record",
                                                        value: "record",
                                                    },
                                                ]}
                                                onChange={(e) =>
                                                    setShowOptions(
                                                        e.target.value,
                                                    )
                                                }
                                                value={showOptions}
                                                optionType="button"
                                                style={{
                                                    marginBottom: "16px",
                                                    marginRight: "20px",
                                                }}
                                            />
                                            <DeleteIcon
                                                onConfirm={() => {
                                                    let tmpBlock =
                                                        _.cloneDeep(curConfig);
                                                    let tempData =
                                                        _.cloneDeep(
                                                            AUDIO_TEXT_SCHEMA,
                                                        );
                                                    _.set(
                                                        tmpBlock,
                                                        ["audio_texts"],
                                                        [tempData],
                                                    );
                                                    handleSetCurConfig(
                                                        tmpBlock,
                                                    );
                                                }}
                                            />
                                        </Col>
                                    )}
                                    {showOptions === "generate" ? (
                                        <Col span={24}>
                                            <Form.Item
                                                label={"Audio Text"}
                                                labelCol={{
                                                    span: 24,
                                                }}
                                            >
                                                <Input.TextArea
                                                    disabled={disableWrite}
                                                    rows={2}
                                                    value={audTxt.text}
                                                    placeholder="Type here..."
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    onChange={(e) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                curConfig,
                                                            );

                                                        let value =
                                                            e.target.value;
                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [
                                                                "audio_texts",
                                                                idx,
                                                                "text",
                                                            ],
                                                            value,
                                                        );
                                                        handleSetCurConfig(
                                                            tmpBlock,
                                                        );
                                                    }}
                                                />
                                            </Form.Item>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <Button
                                                    type="ghost"
                                                    disabled={disableWrite}
                                                    onClick={async () => {
                                                        if (!audTxt.language) {
                                                            message.warn(
                                                                "Please select a language!",
                                                            );
                                                            return;
                                                        }
                                                        if (
                                                            !audTxt?.artist
                                                                ?.name
                                                        ) {
                                                            message.warn(
                                                                "Please select an artist!",
                                                            );
                                                            return;
                                                        }
                                                        if (!audTxt?.text) {
                                                            message.warn(
                                                                "No text added!",
                                                            );
                                                            return;
                                                        }
                                                        message.loading(
                                                            "Please wait... Generating Audio",
                                                            0,
                                                        );
                                                        try {
                                                            let tempAudUrl =
                                                                await generateAudio(
                                                                    audTxt?.text,
                                                                    {
                                                                        language:
                                                                            audTxt.language,
                                                                        artist: audTxt.artist,
                                                                        client: currentClient,
                                                                    },
                                                                );

                                                            if (!tempAudUrl) {
                                                                throw new Error(
                                                                    "No URL",
                                                                );
                                                            }

                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    curConfig,
                                                                );

                                                            let tempAudSch =
                                                                _.cloneDeep(
                                                                    AUDIO_URL_SCHEMA,
                                                                );

                                                            tempAudSch.url =
                                                                tempAudUrl;
                                                            tempAudSch.is_active =
                                                                !audTxt
                                                                    ?.audio_urls
                                                                    ?.length;

                                                            let tempUrls = [];
                                                            let tempIdx = 0;
                                                            if (
                                                                audTxt
                                                                    ?.audio_urls
                                                                    ?.length &&
                                                                audTxt
                                                                    ?.audio_urls[0]
                                                                    ?.is_active
                                                            ) {
                                                                tempUrls = [
                                                                    audTxt
                                                                        ?.audio_urls[0],
                                                                    tempAudSch,
                                                                    ...audTxt?.audio_urls.slice(
                                                                        1,
                                                                    ),
                                                                ];
                                                                tempIdx = 1;
                                                            } else {
                                                                tempUrls = [
                                                                    tempAudSch,
                                                                    ...audTxt?.audio_urls,
                                                                ];
                                                            }

                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [
                                                                    "audio_texts",
                                                                    idx,
                                                                    "audio_urls",
                                                                ],
                                                                tempUrls,
                                                            );
                                                            handleSetCurConfig(
                                                                tmpBlock,
                                                            );

                                                            setHighlighted(
                                                                `${idx}-${tempIdx}`,
                                                            );
                                                            message.destroy();
                                                            message.success(
                                                                "Success!",
                                                            );
                                                        } catch (e) {
                                                            captureException(e);
                                                            message.destroy();
                                                            console.log(e);
                                                            message.error(
                                                                "Could not complete the request!",
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Generate
                                                </Button>
                                            </div>
                                        </Col>
                                    ) : showOptions === "upload" ? (
                                        <Form.Item label="Show upload options">
                                            <RenderUpload
                                                accept="audio/*"
                                                path="home-explore/document/"
                                                onChangeCustom={({
                                                    target: { value },
                                                }) => {
                                                    try {
                                                        let tempAudUrl = value;
                                                        if (!tempAudUrl) return;
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                curConfig,
                                                            );

                                                        let tempAudSch =
                                                            _.cloneDeep(
                                                                AUDIO_URL_SCHEMA,
                                                            );
                                                        tempAudSch.url =
                                                            tempAudUrl;
                                                        tempAudSch.is_active =
                                                            !audTxt?.audio_urls
                                                                ?.length;
                                                        let tempUrls = [];
                                                        let tempIdx = 0;
                                                        if (
                                                            audTxt?.audio_urls
                                                                ?.length &&
                                                            audTxt
                                                                ?.audio_urls[0]
                                                                ?.is_active
                                                        ) {
                                                            tempUrls = [
                                                                audTxt
                                                                    ?.audio_urls[0],
                                                                tempAudSch,
                                                                ...audTxt?.audio_urls.slice(
                                                                    1,
                                                                ),
                                                            ];
                                                            tempIdx = 1;
                                                        } else {
                                                            tempUrls = [
                                                                tempAudSch,
                                                                ...audTxt?.audio_urls,
                                                            ];
                                                        }

                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [
                                                                "audio_texts",
                                                                idx,
                                                                "audio_urls",
                                                            ],
                                                            tempUrls,
                                                        );
                                                        handleSetCurConfig(
                                                            tmpBlock,
                                                        );
                                                        setHighlighted(
                                                            `${idx}-${tempIdx}`,
                                                        );
                                                        message.destroy();
                                                        message.success(
                                                            "Success!",
                                                        );
                                                    } catch (e) {
                                                        captureException(e);
                                                        message.destroy();
                                                        console.log(e);
                                                        message.error(
                                                            "Could not complete the request!",
                                                        );
                                                    }
                                                }}
                                                label="Upload Audio"
                                                value={""}
                                                uploadType="custom"
                                                singleUpload={true}
                                                addExtension={true}
                                                disabled={disableWrite}
                                            />
                                        </Form.Item>
                                    ) : (
                                        <>
                                            <RenderOnlyRecord
                                                isClearFinal={true}
                                                checkValid={() => {
                                                    if (!audTxt.language) {
                                                        message.warn(
                                                            "Please select a language!",
                                                        );
                                                        return false;
                                                    }
                                                    if (!audTxt?.artist?.name) {
                                                        message.warn(
                                                            "Please select an artist!",
                                                        );
                                                        return false;
                                                    }
                                                    return true;
                                                }}
                                                path="worksheet/audio/"
                                                onChangeCustom={(e: {
                                                    target: {
                                                        value: any;
                                                    };
                                                }) => {
                                                    const value =
                                                        e.target.value;
                                                    let tempAudUrl = value;
                                                    let tmpBlock =
                                                        _.cloneDeep(curConfig);

                                                    let tempAudSch =
                                                        _.cloneDeep(
                                                            AUDIO_URL_SCHEMA,
                                                        );

                                                    tempAudSch.url = tempAudUrl;
                                                    tempAudSch.is_active =
                                                        !audTxt?.audio_urls
                                                            ?.length;

                                                    let tempUrls = [];
                                                    let tempIdx = 0;
                                                    if (
                                                        audTxt?.audio_urls
                                                            ?.length &&
                                                        audTxt?.audio_urls[0]
                                                            ?.is_active
                                                    ) {
                                                        tempUrls = [
                                                            audTxt
                                                                ?.audio_urls[0],
                                                            tempAudSch,
                                                            ...audTxt?.audio_urls.slice(
                                                                1,
                                                            ),
                                                        ];
                                                        tempIdx = 1;
                                                    } else {
                                                        tempUrls = [
                                                            tempAudSch,
                                                            ...audTxt?.audio_urls,
                                                        ];
                                                    }

                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "audio_texts",
                                                            idx,
                                                            "audio_urls",
                                                        ],
                                                        tempUrls,
                                                    );
                                                    handleSetCurConfig(
                                                        tmpBlock,
                                                    );
                                                    setHighlighted(
                                                        `${idx}-${tempIdx}`,
                                                    );
                                                    message.destroy();
                                                    message.success("Success!");
                                                }}
                                                disabled={disableWrite}
                                                value={""}
                                                required={false}
                                                uploadType={`custom`}
                                                extension={`.mp3`}
                                                accept={"audio/*"}
                                                onRemove={() => {}}
                                                setEditState={() => {}}
                                            >
                                                <Button
                                                    type="primary"
                                                    disabled={disableWrite}
                                                >
                                                    Upload
                                                </Button>
                                            </RenderOnlyRecord>
                                        </>
                                    )}
                                    <Col span={24}>
                                        <Radio.Group
                                            disabled={disableWrite}
                                            onChange={(e) => {
                                                let tmpBlock =
                                                    _.cloneDeep(curConfig);
                                                let tmpAud =
                                                    _.cloneDeep(audTxt);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "audio_texts",
                                                        idx,
                                                        "audio_urls",
                                                    ],
                                                    tmpAud?.audio_urls?.map(
                                                        (
                                                            v: {
                                                                is_active: boolean;
                                                            },
                                                            i: number,
                                                        ) => {
                                                            v.is_active =
                                                                i ===
                                                                e.target.value;
                                                            return v;
                                                        },
                                                    ),
                                                );
                                                handleSetCurConfig(tmpBlock);
                                            }}
                                            value={audTxt?.audio_urls?.findIndex(
                                                (v) => v.is_active,
                                            )}
                                            style={{
                                                width: "100%",
                                            }}
                                            size="large"
                                        >
                                            {audTxt?.audio_urls?.map(
                                                (
                                                    audUrl: {
                                                        url: string;
                                                    },
                                                    urlIdx: number,
                                                ) => {
                                                    return (
                                                        <div
                                                            key={urlIdx}
                                                            style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                gap: "20px",
                                                                alignItems:
                                                                    "center",
                                                                marginTop:
                                                                    "10px",
                                                                background:
                                                                    highlighted ===
                                                                    `${idx}-${urlIdx}`
                                                                        ? "#FFDE99"
                                                                        : "white",
                                                                borderRadius:
                                                                    "50px",
                                                            }}
                                                        >
                                                            <Radio
                                                                value={urlIdx}
                                                            />
                                                            <div
                                                                style={{
                                                                    width: "80%",
                                                                }}
                                                            >
                                                                <ReactPlayer
                                                                    url={
                                                                        audUrl.url
                                                                    }
                                                                    controls
                                                                    file={{
                                                                        forceAudio:
                                                                            true,
                                                                    }}
                                                                    height={
                                                                        "60px"
                                                                    }
                                                                    width="100%"
                                                                />
                                                            </div>
                                                            <Button
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                icon={
                                                                    <DeleteOutlined />
                                                                }
                                                                type="primary"
                                                                shape="circle"
                                                                danger
                                                                size="small"
                                                                onClick={() => {
                                                                    Modal.confirm(
                                                                        {
                                                                            title: "Are you sure to delete this audio?",
                                                                            content:
                                                                                (
                                                                                    <Form.Item
                                                                                        label={
                                                                                            "Audio"
                                                                                        }
                                                                                    >
                                                                                        <ReactPlayer
                                                                                            url={
                                                                                                audUrl.url
                                                                                            }
                                                                                            controls
                                                                                            file={{
                                                                                                forceAudio:
                                                                                                    true,
                                                                                            }}
                                                                                            height={
                                                                                                "60px"
                                                                                            }
                                                                                            width="100%"
                                                                                        />
                                                                                    </Form.Item>
                                                                                ),
                                                                            onOk: () => {
                                                                                let tmpBlock =
                                                                                    _.cloneDeep(
                                                                                        curConfig,
                                                                                    );
                                                                                let tmpAudUrl =
                                                                                    _.cloneDeep(
                                                                                        audTxt?.audio_urls,
                                                                                    );

                                                                                _.pullAt(
                                                                                    tmpAudUrl,
                                                                                    urlIdx,
                                                                                );
                                                                                tmpBlock =
                                                                                    _.set(
                                                                                        tmpBlock,
                                                                                        [
                                                                                            "audio_texts",
                                                                                            idx,
                                                                                            "audio_urls",
                                                                                        ],
                                                                                        tmpAudUrl,
                                                                                    );
                                                                                handleSetCurConfig(
                                                                                    tmpBlock,
                                                                                );
                                                                            },
                                                                        },
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                },
                                            )}
                                        </Radio.Group>
                                    </Col>
                                </Row>
                            );
                        },
                    )
                ) : (
                    <>
                        <Button
                            type="primary"
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(curConfig);
                                let tempData = _.cloneDeep(AUDIO_TEXT_SCHEMA);
                                tempData.text = curConfig?.display_text;
                                if (tmpBlock?.audio_texts) {
                                    _.set(
                                        tmpBlock,
                                        [
                                            "audio_texts",
                                            tmpBlock?.audio_texts?.length,
                                        ],
                                        tempData,
                                    );
                                } else {
                                    _.set(
                                        tmpBlock,
                                        ["audio_texts"],
                                        [tempData],
                                    );
                                }
                                handleSetCurConfig(tmpBlock);
                            }}
                        >
                            Add
                        </Button>
                    </>
                )}
            </Col>
            {!isAudioPool && (
                <Col span={24}>
                    <Space style={{ float: "right" }}>
                        <Button
                            type="default"
                            onClick={() => {
                                // setCurConfig(tempConfig);
                                // onSave(tempConfig);
                                closeForm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                setSubmitting(true);
                                let tmpConfig = _.cloneDeep(curConfig);
                                tmpConfig.audio_texts =
                                    tmpConfig?.audio_texts?.filter(
                                        (v: {
                                            text: string;
                                            audio_urls: any[];
                                        }) => v.text || v.audio_urls?.length,
                                        // &&
                                        // v.audio_urls?.every(
                                        //     (u) => !u.url,
                                        // )
                                    );
                                setCurConfig(tmpConfig);
                                onSave(tmpConfig);
                                setSubmitting(false);
                                closeForm();
                            }}
                            loading={isSubmitting}
                            disabled={
                                disableWrite
                                // ||
                                // curConfig?.audio_texts?.some(
                                //     (v: { text: string; audio_urls: any[] }) =>
                                // !v.text &&
                                //         !v.audio_urls?.length ||
                                //         v.audio_urls?.every(
                                //             (u) => !u.is_active,
                                //         ),
                                // )
                            }
                        >
                            Submit
                        </Button>
                    </Space>
                </Col>
            )}
        </Row>
    );
};
