import { SlateEditor } from "src/components/plate";
import { Col, Row } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import InputBlock from "../../common/InputBlock";

const renderEditor = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    setIsNewBlock,
    isNewBlock,
    mentionsList = [],
    hasMentions,
}: any) => {
    const {
        id,
        tmpId,
        data: { text, description = [], isRequired },
    } = block;

    const [currentEditor, setCurrentEditor] = useState(`${id || tmpId}_text`);

    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_text`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    return (
        <div>
            <Row style={{ width: "100%" }}>
                <Col span={22}>
                    <SlateEditor
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_text`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(tmpBlock, ["data", "text"], value);
                            setBlock(tmpBlock);
                        }}
                        isFocused={currentEditor == `${id || tmpId}_text`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={text}
                        placeholder={"Type your question here..."}
                        textStyle={{
                            padding: "0 14px",
                            fontSize: "18px",
                            color: "#444",
                        }}
                        addAsterisk={isRequired}
                    />
                </Col>

                <Col span={22}>
                    <SlateEditor
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_description`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "description"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={
                            currentEditor == `${id || tmpId}_description`
                        }
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={description}
                        placeholder={"Description (optional)"}
                        required={false}
                        textStyle={{
                            padding: "0 14px",
                            fontSize: "18px",
                            color: "#999",
                        }}
                    />
                </Col>

                <Col span={22}>
                    <InputBlock placeholder={"name@exapmle.com"} />
                </Col>
            </Row>
        </div>
    );
};

export default renderEditor;
