import axios from "axios";
import { ELEVENLABS_VOICE_IDS } from "./constants";
import _ from "lodash";

const generateAudio = async (
    text: string,
    params: { language: any; artist: any; client?: any },
) => {
    const { artist, language, client } = params;

    if (client === "ElevenLabs") {
        _.set(artist, ["other", "voice_id"], ELEVENLABS_VOICE_IDS[artist?.name])
    }

    return axios
        .post(
            `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v3/personalizedLearning/getGeneratedAudio`,
            {
                data: {
                    text,
                    client,
                    artist,
                    language,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
        .then(function (response) {
            return response?.data?.data.audio_url;
        });
};

export { generateAudio };
