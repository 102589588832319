import { gql } from "@apollo/client";export default gql`query students(
    $limit: Int = 12
    $offset: Int = 0
    $where: student_bool_exp # $type: String # $parent_id: uuid
) {
    students: student(
        limit: $limit
        offset: $offset
        where: $where # order_by: { metadata: { created_at: desc } }
    ) {
        id
        mobile
        class
        streak
        trophies
        points
        attempts
    }
}
`;
