import { FunctionComponent } from "react";

import {
    getLastStringFromUrl,
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import {
    CONTENT_QUERY,
    CONTENTS_QUERY,
    CONTENT_AGGREGATE_QUERY,
} from "../graphql";
import { set } from "lodash";

export const withHookForContents = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { pagination, where } = props;
        const collection_id = parseInt(getLastStringFromUrl());

        const queryDocumentResult = useHasuraSubscriptionWithCache(
            CONTENTS_QUERY,
            {
                variables: {
                    where: collection_id
                        ? set(where, ["collection_id", "_eq"], collection_id)
                        : {},
                    ...pagination,
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "contents", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    contents: items,
                    subscribeToMore,
                    updateQuery,
                    loadContentsData: loadItemsData,
                    refetchContents: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateContent = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { where } = props;
        const collection_id = parseInt(getLastStringFromUrl());

        const queryDocumentResult = useHasuraSubscriptionWithCache(
            CONTENT_AGGREGATE_QUERY,
            {
                variables: {
                    where: collection_id
                        ? set(where, ["collection_id", "_eq"], collection_id)
                        : {},
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "contentAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    contentAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForContent = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            CONTENT_QUERY,
            {
                variables: {
                    id: getLastStringFromUrl(),
                },
            },
        );

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingContent: loading,
                    content: data?.content,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
