import { BLOCK_TYPES } from "../../common/index";
import { v4 as uuid } from "uuid";
const { PROGRESSION_STAGE_BLOCK } = BLOCK_TYPES;

export const STUDENT_TYPES = ["below_average", "average", "above_average"];

export const CONFIG_BLOCK_STATES = [
    "result",
    "result_feedback",
    "result_answer",
    "result_answer_feedback",
];

export const CONFIG_VALUES: any = {
    learning_mode: {
        label: "Learning Mode",
        data: {
            type: "learning_mode",
            value: "",
            other: {
                children: [], // learning mode, story
            },
        },
    },
    solution: {
        label: "Solution",
        data: {
            type: "solution",
            value: "",
        },
    },
    block_state: {
        label: "Block State",
        data: {
            type: "block_state",
            value: "result", // CONFIG_BLOCK_STATES
        },
    },
    try_again: {
        label: "Try Again State",
        data: {
            type: "try_again",
            value: 1,
            other: {
                is_reset: false,
                audio: "",
            },
        },
    },
};

export const VARIABLE_OPTIONS = [
    {
        label: "Correct Answer",
        value: "correct_answer",
    },
    {
        label: "Lives Lost",
        value: "lives_lost",
    },
];

export const RULES_SCHEMA = {
    conjunction: "and", // and - or
    value: 1,
    question_count: 1, // no of questions to consider
    variable: "correct_answer", // lives_lost - correct_answer
    operator: "eq",
};

const CONDITION_CONFIG = {
    conditions: [
        // {
        //     rules: [RULES_SCHEMA],
        //     block_id: "",
        // },
    ],
    default: "",
};

export const PATH_CHILD = {
    condition_jump: CONDITION_CONFIG,
    has_solution: false,
    variants: [
        {
            type: "variant", // primary / variant
            badge_count: 1,
            config: [],
            screen_config: {
                button: {
                    is_enabled: true,
                    btn_text: "Submit",
                },
            },
            block: {}, // the question block
        },
    ],
};

export const DEFAULT_PATH = {
    student_type: "average", // "below_average", "average", "above_average"
    passing_badge_count: 0,
    retries_count: 1,
    children: [],
    has_solution: false,
};

export const DEFAULT_PROGRESSION = {
    type: "progression",
    data: {
        title: "",
        type: "activity", // level, challenge, activity, practice, recommendation
        banner: "",
        card_banner: "",
        student_type: STUDENT_TYPES,
        paths: [
            {
                order: 1,
                id: uuid(),
                tmpId: uuid(),
                ...DEFAULT_PATH,
            },
        ],
        has_solution: false,
    },
};

const DEFAULT_VALUE = {
    type: PROGRESSION_STAGE_BLOCK,
    data: {},
    children: [
        {
            order: 1,
            tmpId: uuid(),
            ...DEFAULT_PROGRESSION,
        },
    ],
};

export default DEFAULT_VALUE;
