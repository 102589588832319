import renderBlockProgressLevel from "./renderBlock";
import renderEditorProgressLevel from "./renderEditor";
import isValidCallbackProgressLevel from "./isValid";
import PROGRESS_LEVEL_BLOCK_DEFAULT_VALUE from "./defaultValue";
import renderSettingsProgressLevel from "./renderSettings";
import renderCardProgressLevel from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common";

const { PROGRESS_LEVEL_BLOCK } = BLOCK_TYPES;

const PROGRESS_LEVEL_BLOCK_CONFIG = {
    [PROGRESS_LEVEL_BLOCK]: {
        label: "Progress Level",
        defaultValue: PROGRESS_LEVEL_BLOCK_DEFAULT_VALUE,
        renderBlock: renderBlockProgressLevel,
        renderEditor: renderEditorProgressLevel,
        renderCard: renderCardProgressLevel,
        renderSettings: renderSettingsProgressLevel,
        addCallback,
        isValidCallback: isValidCallbackProgressLevel,
    },
};

export {
    renderBlockProgressLevel,
    renderCardProgressLevel,
    renderEditorProgressLevel,
    renderSettingsProgressLevel,
    isValidCallbackProgressLevel,
    PROGRESS_LEVEL_BLOCK_DEFAULT_VALUE,
    PROGRESS_LEVEL_BLOCK_CONFIG,
};
