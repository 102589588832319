import renderBlockV2RapidQA from "../V2RapidQA/renderBlock";
import renderEditorV2RapidQA from "../V2RapidQA/renderEditor";
import isValidCallbackV2RapidQA from "../V2RapidQA/isValid";
import V2_THREE_LIVES_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2RapidQA from "../V2RapidQA/renderSettings";
import renderCardV2RapidQA from "../V2RapidQA/renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { V2_THREE_LIVES_BLOCK } = BLOCK_TYPES;

const V2_THREE_LIVES_BLOCK_CONFIG = {
    [V2_THREE_LIVES_BLOCK]: {
        label: "V2 Three Lives",
        defaultValue: V2_THREE_LIVES_DEFAULT_VALUE,
        renderBlock: (props: any) =>
            renderBlockV2RapidQA({ ...props, blockType: V2_THREE_LIVES_BLOCK }),
        renderEditor: (props: any) =>
            renderEditorV2RapidQA({
                ...props,
                blockType: V2_THREE_LIVES_BLOCK,
            }),
        renderCard: (props: any) =>
            renderCardV2RapidQA({
                ...props,
                cardTitle: `Three Lives Block`,
                blockType: V2_THREE_LIVES_BLOCK,
            }),
        renderSettings: (props: any) =>
            renderSettingsV2RapidQA({
                ...props,
                blockType: V2_THREE_LIVES_BLOCK,
            }),
        addCallback,
        isValidCallback: (props: any) =>
            isValidCallbackV2RapidQA({
                ...props,
                blockType: V2_THREE_LIVES_BLOCK,
            }),
    },
};

export {
    renderBlockV2RapidQA,
    renderCardV2RapidQA,
    renderEditorV2RapidQA,
    renderSettingsV2RapidQA,
    isValidCallbackV2RapidQA,
    V2_THREE_LIVES_DEFAULT_VALUE,
    V2_THREE_LIVES_BLOCK_CONFIG,
};
