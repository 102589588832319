import { BLOCK_TYPES } from "../../common";
import { isValidCallbackDragArrangeList } from "../DragArrangeList";

const { INPUT_EXPAND_NUMBER_BLOCK } = BLOCK_TYPES;

const isValidCallback = (props: any) =>
    isValidCallbackDragArrangeList({
        ...props,
        blockType: INPUT_EXPAND_NUMBER_BLOCK,
    });

export default isValidCallback;
