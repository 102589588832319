import { gql } from "@apollo/client";export default gql`mutation createConcept($object: concept_insert_input = {}) {
    insert_concept_one(
        object: $object
        on_conflict: { constraint: concept_pkey, update_columns: id }
    ) {
        id
        title
    }
}
`;
