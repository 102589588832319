import React from "react";
import { Row, Col, Form, Button, Divider } from "antd";
import { withFormik, FieldArray } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderCheckBox,
    RenderField,
    RenderAutoComplete,
    RenderTags,
    RenderDynamicField,
    RenderSelect,
    RenderUpload,
} from "src/components";
import { Select } from "antd";
import { mergeOptions } from "@apollo/client";
import User_id from "pages/user/update/[user_id]";
import { useAuth0 } from "@auth0/auth0-react";

const Option = Select.Option;

const subjects = [
    "Maths",
    "Science",
    "Physics",
    "Biology",
    "Chemistry",
    "Environmental Studies",
    "English",
    "Social Studies",
    "Social Science",
    "Hindi",
];

const classNames = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
];

// Set Title
// Set Subject
// Set Class
// Set Chapter
// Set Topic
// Set Type

import * as yup from "yup";

export const FormSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    chapter: yup.string().required("This field is required"),
    topic: yup.string().required("This field is required"),
    type: yup.string().required("This field is required"),
    class: yup.string().required("This field is required"),
    subject: yup.string().required("This field is required"),
});

const SetForm = (props) => {
    const { onFinishFailed, values, handleSubmit, isSubmitting } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Set Title"
                        placeholder="Set Title"
                        name={"title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Set Description"
                        placeholder="Set Description"
                        name={"description"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.description}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Set Cover"
                        placeholder="Set Cover"
                        name={"cover"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.cover}
                        rules={[{ required: true }]}
                        path="set/"
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Set Subject"
                        placeholder="Set Subject"
                        name={"subject"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.subject}
                        rules={[{ required: true }]}
                    >
                        {subjects.map((subject) => (
                            <Option value={subject}>{subject}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Set Class"
                        placeholder="Set Class"
                        name={"class"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.class}
                        rules={[{ required: true }]}
                    >
                        {classNames.map((className) => (
                            <Option value={className}>{className}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Set Chapter"
                        placeholder="Set Chapter"
                        name={"chapter"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.chapter}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Set Topic"
                        placeholder="Set Topic"
                        name={"topic"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.topic}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Set Type"
                        placeholder="Set Type"
                        name={"type"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.type}
                        rules={[{ required: true }]}
                    >
                        <Option value="understanding">Understanding</Option>
                        <Option value="practice">Practice</Option>
                        <Option value="practise_learn_practise">
                            Practice - Learn - Practice
                        </Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Set Duration"
                        placeholder="Set Duration"
                        name={"duration"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.duration}
                        rules={[{ required: true }]}
                        disabled={true}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Set Attempts"
                        placeholder="Set Attempts"
                        name={"attempts"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.attempts}
                        rules={[{ required: true }]}
                        disabled={true}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Set Status"
                        placeholder="Set Status"
                        name={"status"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.status}
                        rules={[{ required: true }]}
                    >
                        <Option value="inactive">Inactive</Option>
                        <Option value="active">Active</Option>
                    </Field>
                </Col>

                <Col lg={12} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const SetFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { set } = props;

        return {
            ...(set?.id ? { id: set.id } : {}),
            title: set?.title || "",
            description: set?.description || "",
            cover: set?.cover || "",
            subject: set?.subject || "",
            chapter: set?.chapter || "",
            class: set?.class || "",

            topic: set?.topic || "",
            duration: set?.duration || 0,
            attempts: set?.attempts || 0,
            status: set?.status || "inactive",
            type: set?.type || "",
            created_by: props.user_id,
        };
    },

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
    validationSchema: (props) => FormSchema,
});

const FormikForm = SetFormWithFormik(SetForm);
const FormikFormWithUser = (props) => {
    const { user } = useAuth0();

    const namespace = "https://hasura.io/jwt/claims/";
    const user_id = user && user[namespace + "user_id"];
    return <FormikForm {...props} user_id={user_id} />;
};

export default FormikFormWithUser;
