import _ from "lodash";
import { renderSettingsMCQ } from "../MCQ";
import { BLOCK_TYPES } from "../../common/index";

import { Button } from "antd";
import { AdjustDifficultyModal } from "../../../WorksheetEditor/components/Modals";
const { V2_RAPID_QA_BLOCK, MCQ_BLOCK, V2_MIXED_BLOCK } = BLOCK_TYPES;

const renderSettingsV2Mixed = ({
    block,
    setBlock,
    currentSubBlock,
    blockType = V2_RAPID_QA_BLOCK,
    worksheet,
    jumpLogicProps,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [blockType]: { title },
        },
        children,
    } = block;

    const renderSettingsCaseStudy = () => (
        <div>
            <div>{renderSettingsMCQ({ block, setBlock })} </div>{" "}
            {worksheet?.type === "personalized_learning" &&
                blockType == V2_RAPID_QA_BLOCK &&
                block.children.length > 0 && (
                    <AdjustDifficultyModal {...jumpLogicProps} />
                )}
        </div>
    );

    return (
        <div>
            {currentSubBlock !== null &&
                worksheet?.type === "personalized_learning" &&
                [MCQ_BLOCK, V2_MIXED_BLOCK].includes(
                    children[currentSubBlock]?.type,
                ) &&
                id !== null &&
                children[currentSubBlock]?.id && (
                    <Button
                        style={{ marginBottom: "20px" }}
                        type="primary"
                        onClick={() => {
                            const newWindow = window.open(
                                `${process.env.REACT_APP_CLIENT_PREVIEW}/personalized_learning/preview?worksheet_id=${worksheet?.id}&parent_block_id=${id}&child_block_id=${children[currentSubBlock]?.id}`,
                                "_blank",
                                "noopener,noreferrer",
                            );
                            if (newWindow) newWindow.opener = null;
                        }}
                    >
                        Preview Question
                    </Button>
                )}
            {currentSubBlock == null
                ? renderSettingsCaseStudy()
                : renderSettingsMCQ({
                      block: children[currentSubBlock],
                      setBlock: (subBlock: any) => {
                          let tmpBlock = _.cloneDeep(block);

                          tmpBlock = _.set(
                              tmpBlock,
                              ["children", currentSubBlock],
                              subBlock,
                          );
                          setBlock(tmpBlock);
                      },
                  })}
        </div>
    );
};
export default renderSettingsV2Mixed;
