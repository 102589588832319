import { BLOCK_TYPES } from "../../common/index";
const { EMAIL_BLOCK } = BLOCK_TYPES;

const EMAIL_DEFAULT_VALUE = {
    type: EMAIL_BLOCK,
    data: {
        text: [],
        description: [],
        isRequired: false,
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
};

export default EMAIL_DEFAULT_VALUE;
