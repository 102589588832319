import { checkBlockValid } from "../..";
import { BLOCK_TYPES } from "../../common";

const { PROGRESS_LEVEL_BLOCK } = BLOCK_TYPES;

const checkRootValid = (children: any[], worksheet) => {
    if (children?.length <= 0) return false;

    for (let index = 0; index < children.length; index++) {
        const retVal = checkChunkValid({
            block: children[index],
            worksheet,
            chunkIndex: index,
        }).isValid;
        if (!retVal) return false;
    }
    return true;
};

const isValidCallback = (props) => {
    const { block, worksheet } = props;
    const {
        data: {
            [PROGRESS_LEVEL_BLOCK]: { name, banner, card_banner },
        },
    } = block;

    return {
        isValid:
            name &&
            banner &&
            card_banner &&
            checkRootValid(block.children, worksheet), // !isEditorEmpty(description),
        error: <></>,
    };
};

export default isValidCallback;

export const checkChunkValid = ({ block, worksheet, chunkIndex }) => {
    if (!block) return { isValid: false };
    const {
        data: { chunk },
        children,
    } = block;

    if (worksheet?.type === "personalized_learning_v4") {
        if (!chunk || !children?.length) {
            return { isValid: false };
        }
    } else if (!chunk || !chunk.blocks || !chunk.blocks.length) {
        return { isValid: false };
    }

    const {
        data: {
            slug: dataSlug,
            chunk: { compute_functions: computeFuncs = [] },
        },
        backend,
    } = block;
    let slug =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.slug
            : dataSlug;
    let compute_functions =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.compute_functions || []
            : computeFuncs;
    if (
        !jumpLogicValid({
            functions: compute_functions,
            chunkIndex,
            chunkSlug: slug,
        })
    ) {
        return { isValid: false };
    }

    const currBlocks =
        worksheet?.type === "personalized_learning_v4"
            ? children
            : chunk.blocks;
    for (let index = 0; index < currBlocks.length; index++) {
        const retVal = checkBlockValid(currBlocks[index], worksheet, {
            chunkIndex,
            chunkSlug: slug,
            blockIndex: index,
        });
        if (!retVal) return { isValid: false };
    }
    return { isValid: true };
};

export const jumpLogicValid = ({
    functions = [],
    chunkIndex,
    blockIndex = null,
    chunkSlug = null,
    blockSlug = null,
}: any) => {
    return !functions?.some(
        (v) =>
            (v.name === "getNextChunk" &&
                (v.output?.includes(`C${chunkIndex + 1}`) ||
                    (chunkSlug && v.output?.includes(chunkSlug)))) ||
            (v.name === "getNextBlock" &&
                (v.output?.includes(`C${chunkIndex + 1}B${blockIndex + 1}`) ||
                    (chunkSlug &&
                        blockSlug &&
                        (v.output?.includes(`${chunkSlug}:${blockSlug}`) ||
                            v.output?.includes(
                                `chunk:${chunkSlug};block:${blockSlug}`,
                            ) ||
                            v.output?.includes(`${chunkSlug};${blockSlug}`))))),
    );
};
