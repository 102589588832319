import renderBlockV2RapidQA from "./renderBlock";
import renderEditorV2RapidQA from "./renderEditor";
import isValidCallbackV2RapidQA from "./isValid";
import V2_RAPID_QA_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2RapidQA from "./renderSettings";
import renderCardV2RapidQA from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { V2_RAPID_QA_BLOCK } = BLOCK_TYPES;

const V2_RAPID_QA_BLOCK_CONFIG = {
    [V2_RAPID_QA_BLOCK]: {
        label: "V2 Rapid QA",
        defaultValue: V2_RAPID_QA_DEFAULT_VALUE,
        renderBlock: renderBlockV2RapidQA,
        renderEditor: renderEditorV2RapidQA,
        renderCard: renderCardV2RapidQA,
        renderSettings: renderSettingsV2RapidQA,
        addCallback,
        isValidCallback: isValidCallbackV2RapidQA,
    },
};

export {
    renderBlockV2RapidQA,
    renderCardV2RapidQA,
    renderEditorV2RapidQA,
    renderSettingsV2RapidQA,
    isValidCallbackV2RapidQA,
    V2_RAPID_QA_DEFAULT_VALUE,
    V2_RAPID_QA_BLOCK_CONFIG,
};
