import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';
import { getLastStringParamFromUrl } from "src/helpers";

import {
    withCreateCollection,
    withCreateSetCollection,
} from "src/modules/collection/operations";
import { compose } from "src/helpers";

import CreateView from "./component";

const Create = (props) => {
    const { createCollection, createSetCollection } = props;
    const router = useRouter();

    const onFinish = async (values: any) => {
        const { id } = await createCollection(values);
        const set_id = parseInt(getLastStringParamFromUrl());

        if (set_id) {
            const object = {
                set_id,
                collection_id: id,
                order: -1,
            };
            const set_collection = await createSetCollection(object);
            router.push(`/collection/set/${set_id}`);
        } else router.push("/collection");
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return <CreateView onFinish={onFinish} onFinishFailed={onFinishFailed} />;
};

export default compose(withCreateCollection, withCreateSetCollection)(Create);
