import React from "react";
import { message } from "antd";

import { DeleteIcon } from "src/components";
import { compose } from "src/helpers";
import { withDeleteUser } from "src/modules/user/operations";
import { captureException } from "@sentry/react";

const DeleteView = (props) => {
    const { deleteUser, id } = props;

    const handleDelete = async () => {
        try {
            await deleteUser(id);
            message.success("Deleted!");
        } catch (err) {
            captureException(err)
            message.error("Failed!");
        }
    };

    return <DeleteIcon {...props} onConfirm={handleDelete} />;
};

export default compose(withDeleteUser)(DeleteView);
