import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import { withCreateQuestion } from "src/modules/question/operations";
import { compose } from "src/helpers";

import CreateView from "./component";

const Create = (props) => {
    const { createQuestion } = props;
    const router = useRouter();

    const onFinish = async (values: any) => {
        await createQuestion(values);
        router.push("/question");
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return <CreateView onFinish={onFinish} onFinishFailed={onFinishFailed} />;
};

export default compose(withCreateQuestion)(Create);
