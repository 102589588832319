import { BLOCK_TYPES } from "../../common/index";
const { V2_VOICE_BLOCK } = BLOCK_TYPES;

export const VOICE_TYPE = [
    {
        label: "International",
        value: "international",
    },
    {
        label: "Indian",
        value: "indian",
    },
];

const V2_VOICE_DEFAULT_VALUE = {
    type: V2_VOICE_BLOCK,
    data: {
        [V2_VOICE_BLOCK]: {
            title: [],
            correct_answer: [],
            type: "indian",
            question_audio: "",
            is_correct_input: false,
        },
        other: {
            duration: 0,
            audio: "",
            description: [],
            global_feedback: null,
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
};

export default V2_VOICE_DEFAULT_VALUE;
