import React, { useEffect, useMemo, useState } from "react";

import { Button, Tooltip } from "antd";
import isSelectionRectangle from "../utils/cell-merge/isSelectionRectangle";

const CellMergeComponent = ({
    selectedCellsIndices,
    selectedCells,
    setProperty,
}: any) => {
    const [isSelectedCellsAMerge, setIsSelectedCellsAMerge] = useState(false);
    const [
        areSelectedCellsMakingRectangularSelection,
        setAreSelectedCellsMakingRectangularSelection,
    ] = useState(false);

    useEffect(() => {
        const areSelectedCellsMakingRectangularSelection = isSelectionRectangle(
            selectedCellsIndices,
            selectedCells,
        );
        setAreSelectedCellsMakingRectangularSelection(
            areSelectedCellsMakingRectangularSelection,
        );
    }, [selectedCellsIndices, selectedCells]);

    useEffect(() => {
        if (selectedCells.length === 0 || selectedCells.length > 1) {
            setIsSelectedCellsAMerge(false);
            return;
        }
        const cell = selectedCells[0];
        if (cell?.merge?.enabled) {
            // const isMergeSelectionRectangle = isSelectionRectangle(cell.merge.mergedCells);
            setIsSelectedCellsAMerge(true);
        } else {
            setIsSelectedCellsAMerge(false);
        }
    }, [selectedCells]);

    const disabled = useMemo(() => {
        if (selectedCells.length === 1) {
            return !isSelectedCellsAMerge;
        } else if (selectedCells.length > 1) {
            return (
                !areSelectedCellsMakingRectangularSelection &&
                !isSelectedCellsAMerge
            );
        }
    }, [
        areSelectedCellsMakingRectangularSelection,
        isSelectedCellsAMerge,
        selectedCells,
    ]);

    const buttonText = useMemo(() => {
        return isSelectedCellsAMerge ? "Un-Merge" : "Merge";
    }, [isSelectedCellsAMerge]);

    // console.log("CellMergeComponent---------", JSON.parse(JSON.stringify({selectedCellsIndices, selectedCells, isSelectedCellsAMerge, areSelectedCellsMakingRectangularSelection})));
    return (
        <Tooltip placement="top" title="Merge Selection">
            <Button
                onClick={() => {
                    // handlePasteToCells();
                    setProperty({
                        type: "MERGE",
                        value: {
                            mergeEnable: !isSelectedCellsAMerge,
                            mergedCellIndex: isSelectedCellsAMerge
                                ? selectedCellsIndices[0]
                                : [],
                        },
                    });
                }}
                disabled={disabled}
                type={"primary"}
            >
                {buttonText}
            </Button>
        </Tooltip>
    );
};

export default CellMergeComponent;
