import {
    Badge,
    Button,
    Card,
    Col,
    Dropdown,
    Form,
    Input,
    Row,
    Select,
    Switch,
    message,
} from "antd";
import { BLOCK_TYPES } from "../../../common";
import _ from "lodash";
import { useEffect, useState } from "react";
import { STUDENT_TYPES } from "../defaultValue";
import { v4 as uuid } from "uuid";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import {
    CheckOutlined,
    CloseOutlined,
    MenuOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { Actions } from "../../../../WorksheetEditor/components/Editor/BlocksColumn/Actions";
import { arrayMoveImmutable } from "array-move";
import { getBareBlock, getBlockTypes } from "../../../";
import VariantEditor from "./VariantEditor";
import { checkVariantsValid } from "../isValid";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import InputVariablesList from "../../../common/InputVariablesList";

const { PROGRESSION_STAGE_BLOCK } = BLOCK_TYPES;

const PathEditor = (props: any) => {
    const {
        worksheet,
        block,
        setBlock,
        currentBlock,
        blocks,
        disableWrite,
        previewData,
    } = props;
    const {
        student_type,
        passing_badge_count,
        children,
        retries_count,
        has_solution,
    } = block;

    const [currentSubBlock, setCurrentSubBlock] = useState(
        children?.length > 0 ? 0 : -1,
    );

    const [tempChildren, setTempChildren] = useState("");

    const updateCurrentBlock = (val: number) => {
        setCurrentSubBlock(val);
    };

    const [loading, setLoad] = useState(false);

    useEffect(() => {
        setLoad(true);
        setTempChildren("");
        setTimeout(() => setLoad(false), 50);
    }, [currentSubBlock]);

    useEffect(() => {
        updateCurrentBlock(children?.length > 0 ? 0 : -1);
    }, [currentBlock]);

    const DragHandle = SortableHandle(() => <MenuOutlined />);

    const SortableItem = SortableElement(({ value, child }) => {
        const actionsProps = {
            deleteBlock: () => {
                updateCurrentBlock(currentSubBlock - 1);
                let tmpBlock = _.cloneDeep(block);
                const tmpOptions = [...children];
                _.pullAt(tmpOptions, currentSubBlock);
                tmpBlock = _.set(tmpBlock, ["children"], tmpOptions);

                setBlock(tmpBlock);
            },
            idx: currentSubBlock,
            addBlockCustom: (type: string, index: number, newBlock: any) => {
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = [...children];
                tmpChildren.splice(index, 0, newBlock);
                tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);
                setBlock(tmpBlock);
                setCurrentSubBlock(index);
            },
            block: child,
            // for progression stage
            updateId: true,
        };

        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Badge.Ribbon
                    color={"cyan"}
                    text={value + 1}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            background:
                                currentSubBlock === value ? "#F1F1F1" : "",
                            borderBottom: !checkVariantsValid(child)
                                ? "3px solid red"
                                : currentSubBlock === value
                                ? "3px solid indigo"
                                : "",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            minWidth: "90px",
                        }}
                        bodyStyle={{
                            background: "transparent",
                        }}
                        onClick={() => {
                            updateCurrentBlock(value);
                        }}
                    >
                        <span style={{ textTransform: "capitalize" }}>
                            {child?.variants[0]?.block?.type
                                ?.split("_")
                                ?.join(" ") || `Block ${value + 1}`}
                        </span>

                        {currentSubBlock === value && !disableWrite && (
                            <div
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    padding: "2px",
                                }}
                            >
                                <DragHandle />
                            </div>
                        )}
                    </Card>
                </Badge.Ribbon>

                {currentSubBlock === value && !disableWrite && (
                    <Actions {...actionsProps} />
                )}
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={index}
                        child={child}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            children,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);
        setBlock(tmpBlock);
        updateCurrentBlock(newIndex);
    };

    const variantProps = {
        ...props,
        block: children[currentSubBlock],
        setBlock: (subBlock: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(tmpBlock, ["children", currentSubBlock], subBlock);

            const hasSolution = subBlock?.has_solution;
            if (!hasSolution) {
                tmpBlock = _.set(tmpBlock, ["has_solution"], false);
            }
            setBlock(tmpBlock);
        },
        currentBlock: currentSubBlock,
        deleteParentBlock: () => {
            updateCurrentBlock(currentSubBlock - 1);
            let tmpBlock = _.cloneDeep(block);
            const tmpOptions = [...children];
            _.pullAt(tmpOptions, currentSubBlock);
            tmpBlock = _.set(tmpBlock, ["children"], tmpOptions);

            setBlock(tmpBlock);
        },
        previewData: {
            ...previewData,
            path_id: block?.id || block?.tmpId,
        },
        pathChildren: children,
    };

    const blocksMenu = getBlockTypes({ type: PROGRESSION_STAGE_BLOCK }).map(
        ({ label, value }: any, idx: number) => ({
            key: idx,
            label: <span>{label}</span>,
            onClick: () => {
                let tmpBlock = _.cloneDeep(block);
                tmpBlock = _.set(tmpBlock, ["children", children.length], {
                    variants: [
                        {
                            id: uuid(),
                            tmpId: uuid(),
                            order: 1,
                            screen_config: {
                                button: {
                                    is_enabled: true,
                                    btn_text: "Submit",
                                },
                            },
                            type: "primary",
                            badge_count: 1,
                            config: [],
                            block: {
                                ...getBareBlock(value),
                                id: uuid(),
                                tmpId: uuid(),
                            },
                        },
                    ],
                    order: children.length + 1,
                    id: uuid(),
                    tmpId: uuid(),
                });
                setBlock(tmpBlock);
                updateCurrentBlock(children.length);
            },
        }),
    );

    const getInpVars = () => {
        const tempVars = worksheet?.other?.input_variables || [];

        const data = children.flatMap(
            (item: { variants: { block: { id: any; tmpId: any } }[] }) =>
                item?.variants.flatMap((variant) =>
                    [variant?.block?.id, variant?.block?.tmpId].filter(
                        (value) => value !== null && value !== "",
                    ),
                ),
        );

        return tempVars.filter((item: { block_id: any }) =>
            data.includes(item.block_id),
        );
    };

    return (
        <div>
            <Row gutter={[20, 20]}>
                {(block?.tmpId || block?.id) && (
                    <Col span={15}>
                        <Form.Item label="Path Id">
                            <Input.Search
                                value={block.id || block.tmpId}
                                onChange={() => {}}
                                enterButton="Copy"
                                size="middle"
                                onSearch={() => {
                                    message.info(
                                        `Copied Path Id to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        `${block.id || block.tmpId}`,
                                    );
                                }}
                            />
                        </Form.Item>
                    </Col>
                )}
                <Col span={15}>
                    <InputVariablesList
                        inputVariables={getInpVars()}
                        text="Path"
                    />
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Student Type"
                        rules={[{ required: true }]}
                        validateStatus={!student_type ? "error" : ""}
                        help={!student_type ? "Field cannot be empty" : ""}
                    >
                        <Select
                            disabled={disableWrite}
                            defaultValue={student_type}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock.student_type = value;
                                setBlock(tmpBlock);
                            }}
                        >
                            {STUDENT_TYPES.map((item, i) => (
                                <Select.Option value={item} key={i}>
                                    {item.split("_").join(" ")}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Passing Badge Count"
                        rules={[{ required: true }]}
                        validateStatus={
                            !passing_badge_count || passing_badge_count <= 0
                                ? "error"
                                : ""
                        }
                        help={
                            !passing_badge_count || passing_badge_count <= 0
                                ? "Field cannot be empty"
                                : ""
                        }
                    >
                        <Input
                            disabled={disableWrite}
                            value={passing_badge_count}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock.passing_badge_count = value;
                                setBlock(tmpBlock);
                            }}
                            required
                            type="number"
                            placeholder={`Passing Badge Count`}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="No of Retries"
                        rules={[{ required: true }]}
                        validateStatus={
                            !retries_count || retries_count < 1 ? "error" : ""
                        }
                        help={
                            !retries_count || retries_count < 1
                                ? "Field cannot be empty or less than one"
                                : ""
                        }
                    >
                        <Input
                            disabled={disableWrite}
                            value={retries_count}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock.retries_count = value;
                                setBlock(tmpBlock);
                            }}
                            required
                            type="number"
                            placeholder={`No. of Retries`}
                        />
                    </Form.Item>
                </Col>

                <Col span={15}>
                    <Form.Item label="All blocks have solution">
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={has_solution}
                            disabled={disableWrite}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["has_solution"],
                                    value,
                                );

                                if (value) {
                                    const tempChildren = children?.map(
                                        (item: any) => ({
                                            ...item,
                                            has_solution: true,
                                        }),
                                    );
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["children"],
                                        tempChildren,
                                    );
                                }

                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <hr />

            <h2
                style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    fontWeight: "bold",
                }}
            >
                Blocks Details
            </h2>
            <div>
                {!disableWrite && (
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            marginBottom: "20px",
                            flexWrap: "wrap",
                        }}
                    >
                        <Dropdown
                            menu={{ items: blocksMenu }}
                            trigger={["click"]}
                        >
                            <Button
                                type="dashed"
                                onClick={(e) => e.preventDefault()}
                            >
                                Add Block{" "}
                                <PlusOutlined style={{ fontSize: "15px" }} />
                            </Button>
                        </Dropdown>
                        <Input.Search
                            placeholder="Add Block with ID"
                            allowClear
                            enterButton="Add Block"
                            size="middle"
                            style={{
                                width: "500px",
                            }}
                            onSearch={(data) => {
                                if (!data) return;

                                let curVariant = null;
                                blocks.some(
                                    (block: {
                                        children: {
                                            data: {
                                                paths: {
                                                    children: {
                                                        id: string;
                                                        tmpId: string;
                                                    }[];
                                                }[];
                                            };
                                        }[];
                                    }) =>
                                        block.children?.some((child) =>
                                            child?.data?.paths?.some((path) =>
                                                path?.children?.some(
                                                    (variant) => {
                                                        if (
                                                            variant.id ===
                                                                data ||
                                                            variant.tmpId ===
                                                                data
                                                        ) {
                                                            curVariant =
                                                                variant;
                                                            return true;
                                                        }
                                                        return false;
                                                    },
                                                ),
                                            ),
                                        ),
                                );

                                if (curVariant) {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["children", children.length],
                                        updateKeysAndCopy(
                                            _.omit(curVariant, [
                                                "id",
                                                "worksheet_block_map_id",
                                                "tmpId",
                                            ]),
                                            false,
                                        ),
                                    );
                                    setBlock(tmpBlock);
                                    updateCurrentBlock(children.length);

                                    message.success("Block Added");
                                } else message.error("Block doesn't exist!");
                            }}
                        />

                        <div
                            style={{
                                width: "100%",
                                flexShrink: 0,
                            }}
                        >
                            <Form.Item label={"Paste Block here"}>
                                <Input.TextArea
                                    disabled={disableWrite}
                                    value={tempChildren}
                                    placeholder="Paste Block here"
                                    onChange={(e) =>
                                        setTempChildren(e.target.value)
                                    }
                                    rows={2}
                                />
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    onClick={() => {
                                        try {
                                            const parsedJson =
                                                JSON.parse(tempChildren);
                                            if (!parsedJson?.variants?.length) {
                                                message.error("Invalid JSON!");
                                                return;
                                            }
                                            let tmpBlock = _.cloneDeep(block);
                                            if (parsedJson) {
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "children",
                                                        children.length,
                                                    ],
                                                    updateKeysAndCopy(
                                                        _.omit(parsedJson, [
                                                            "id",
                                                            "worksheet_block_map_id",
                                                            "tmpId",
                                                        ]),
                                                        false,
                                                    ),
                                                );
                                                setBlock(tmpBlock);
                                                updateCurrentBlock(
                                                    children.length,
                                                );

                                                message.success("Block Added");
                                                setTempChildren("");
                                            }
                                        } catch (e) {
                                            // captureException(e)
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Add Block
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                )}
                <SortableList
                    items={children}
                    onSortEnd={onSortEnd}
                    axis="xy"
                    useDragHandle
                />
                <hr />
                {!loading && children[currentSubBlock] && (
                    <VariantEditor {...variantProps} />
                )}
            </div>
        </div>
    );
};

export default PathEditor;
