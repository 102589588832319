import renderBlockContent from "./renderBlock";
import renderEditorContent from "./renderEditor";
import isValidCallbackContent from "./isValid";
import CONTENT_DEFAULT_VALUE from "./defaultValue";
import renderSettingsContent from "./renderSettings";
import renderCardContent from "./renderCard";
import { BLOCK_TYPES } from "../../common/index";

const { CONTENT_BLOCK } = BLOCK_TYPES;

const CONTENT_BLOCK_CONFIG = {
    [CONTENT_BLOCK]: {
        label: "Content",
        defaultValue: CONTENT_DEFAULT_VALUE,
        renderBlock: renderBlockContent,
        renderEditor: renderEditorContent,
        renderCard: renderCardContent,
        renderSettings: renderSettingsContent,
        isValidCallback: isValidCallbackContent,
    },
};

export {
    renderBlockContent,
    renderCardContent,
    renderEditorContent,
    renderSettingsContent,
    isValidCallbackContent,
    CONTENT_DEFAULT_VALUE,
    CONTENT_BLOCK_CONFIG,
};
