import React, { useState } from "react";
import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    message,
    Row,
    Switch,
} from "antd";
import {
    CheckOutlined,
    CloseCircleFilled,
    CloseOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {
    ALPHABETS,
    DEFAULT_ALPHABETS,
    DEFAULT_ALPHABETS_KEYBOARD,
    DEFAULT_DIGITS,
    DEFAULT_DIGITS_KEYBOARD,
    DEFAULT_LATEX,
    DEFAULT_LATEX_KEYBOARD,
    DEFAULT_SETTINGS_KEYBOARD,
    DEFAULT_SYMBOLS,
    DEFAULT_SYMBOLS_KEYBOARD,
    DIGITS,
    LATEX,
    QWERTY_KEYS,
    SETTINGS,
    SYMBOLS,
} from "./constants";
// import { captureException } from "@sentry/react";

const CellEditor = ({ value, onChange, onDelete }: any) => {
    return (
        <div>
            <Input {...{ value, onChange }} />

            <span
                style={{
                    position: "absolute",
                    top: 0,
                    marginTop: "-10px",
                    marginLeft: "-10px",
                    cursor: "pointer",
                }}
                onClick={onDelete}
            >
                <CloseCircleFilled style={{ color: "#DC3545" }} />
            </span>
        </div>
    );
};

const Editor = ({
    keys,
    setKeys,
    defaultKeys,
    title,
    hasLatex,
    isAlphabet,
}: any) => {
    const handleChange = (index: number, value: string) => {
        const tmp = [...keys];
        tmp[index] = value;
        setKeys(tmp);
    };
    const handleAdd = () => {
        setKeys([...keys, ""]);
    };

    const handleQwerty = () => {
        setKeys([...keys, ...QWERTY_KEYS]);
    };

    const handleDelete = (index: number) => {
        setKeys(keys.toSpliced(index, 1));
    };

    const handleCheckbox = (e: any) => {
        const enabled = e.target.checked;

        if (enabled) setKeys(defaultKeys);
        else setKeys([]);
    };

    return (
        <Card>
            <h4>{title}</h4>
            <Checkbox onChange={handleCheckbox} checked={keys.length}>
                Enabled
            </Checkbox>
            {isAlphabet && (
                <Button
                    style={{
                        marginBottom: "20px",
                    }}
                    onClick={handleQwerty}
                >
                    Add QWERTY
                </Button>
            )}
            <Row gutter={[16, 16]} style={{ margin: "20px 0" }}>
                {keys.map((value: string, idx: number) => (
                    <Col span={hasLatex ? 8 : 4}>
                        <CellEditor
                            value={value}
                            onChange={(e: any) => {
                                // console.log(
                                //     `changing ${idx} to ${e.target.value}`,
                                // );
                                handleChange(idx, e.target.value);
                            }}
                            onDelete={() => {
                                // console.log(`deleting ${idx} `);
                                handleDelete(idx);
                            }}
                        />
                    </Col>
                ))}
                <Col span={4}>
                    <Button icon={<PlusOutlined />} block onClick={handleAdd} />
                </Col>
            </Row>
        </Card>
    );
};

const SettingsEditor = ({ settings, setSettings, title, hasLatex }: any) => {
    return (
        <Card>
            <h4>{title}</h4>
            {hasLatex && (
                <Form.Item label={"Separate keyboard?"}>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={settings?.is_separated}
                        onChange={(value) =>
                            setSettings({ ...settings, is_separated: value })
                        }
                    />
                </Form.Item>
            )}
            <Form.Item label={"Has spacebar?"}>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={settings?.has_space}
                    onChange={(value) =>
                        setSettings({ ...settings, has_space: value })
                    }
                />
            </Form.Item>
            {!hasLatex && (
                <Form.Item label={"Has nextline?"}>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={settings?.has_nextline}
                        onChange={(value) =>
                            setSettings({ ...settings, has_nextline: value })
                        }
                    />
                </Form.Item>
            )}
            <Form.Item label={"Has Caps?"}>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={settings?.has_caps}
                    onChange={(value) =>
                        setSettings({ ...settings, has_caps: value })
                    }
                />
            </Form.Item>
        </Card>
    );
};

export const KeyboardForm: React.FC<any> = ({
    hasLatex,
    digits,
    setDigits,
    symbols,
    setSymbols,
    alphabets,
    setAlphabets,
    latex,
    setLatex,
    settings,
    setSettings,
}: any) => {
    const [tempChildren, setTempChildren] = useState("");

    return (
        <>
            <Editor
                title={"Digits Editor"}
                keys={digits}
                setKeys={setDigits}
                defaultKeys={DEFAULT_DIGITS}
            />

            <Editor
                title={"Alphabets Editor"}
                keys={alphabets}
                setKeys={setAlphabets}
                defaultKeys={DEFAULT_ALPHABETS}
                isAlphabet={true}
            />

            {!hasLatex && (
                <Editor
                    title={"Symbols Editor"}
                    keys={symbols}
                    setKeys={setSymbols}
                    defaultKeys={DEFAULT_SYMBOLS}
                />
            )}

            {hasLatex && (
                <>
                    <Editor
                        title={"Latex Editor"}
                        keys={latex}
                        setKeys={setLatex}
                        defaultKeys={DEFAULT_LATEX}
                        hasLatex={hasLatex}
                    />
                </>
            )}
            <SettingsEditor
                title={"Settings"}
                settings={settings}
                setSettings={setSettings}
                defaultSettings={DEFAULT_SETTINGS_KEYBOARD}
                hasLatex={hasLatex}
            />
            <Col span={24}>
                <Button
                    type="primary"
                    onClick={() => {
                        const customKeyboardValue = [];

                        if (digits.length)
                            customKeyboardValue.push({
                                ...DEFAULT_DIGITS_KEYBOARD,
                                keys: digits,
                            });
                        if (!hasLatex && symbols.length)
                            customKeyboardValue.push({
                                ...DEFAULT_SYMBOLS_KEYBOARD,
                                keys: symbols,
                            });

                        if (alphabets.length)
                            customKeyboardValue.push({
                                ...DEFAULT_ALPHABETS_KEYBOARD,
                                keys: alphabets,
                            });

                        if (hasLatex && latex?.length)
                            customKeyboardValue.push({
                                ...DEFAULT_LATEX_KEYBOARD,
                                keys: latex,
                            });

                        // if (hasLatex) {
                        customKeyboardValue.push(settings);
                        // }

                        navigator.clipboard.writeText(
                            JSON.stringify(customKeyboardValue),
                        );
                        message.info(`Copied keyboard config to clipboard!`);
                    }}
                    size="small"
                >
                    Copy keyboard config to Clipboard
                </Button>
                <Form.Item label={"Paste keyboard config here"}>
                    <Input.TextArea
                        value={tempChildren}
                        placeholder="Paste config here"
                        onChange={(e) => setTempChildren(e.target.value)}
                        rows={2}
                    />
                    <Button
                        style={{ marginTop: "10px" }}
                        type="primary"
                        size="small"
                        onClick={() => {
                            try {
                                const parsedJson = JSON.parse(tempChildren);
                                const defaultDigits =
                                    parsedJson.find(
                                        ({ type }: any) => type === DIGITS,
                                    )?.keys || [];
                                const defaultSymbols =
                                    parsedJson.find(
                                        ({ type }: any) => type === SYMBOLS,
                                    )?.keys || [];
                                const defaultAlphabets =
                                    parsedJson.find(
                                        ({ type }: any) => type === ALPHABETS,
                                    )?.keys || [];
                                const defaultLatex =
                                    parsedJson.find(
                                        ({ type }: any) => type === LATEX,
                                    )?.keys || [];
                                const defaultSettings = parsedJson.find(
                                    ({ type }: any) => type === SETTINGS,
                                ) || { ...DEFAULT_SETTINGS_KEYBOARD };

                                setDigits(defaultDigits);
                                setSymbols(defaultSymbols);
                                setAlphabets(defaultAlphabets);
                                setLatex(defaultLatex);
                                setSettings(defaultSettings);
                                setTempChildren("");
                                message.info("Updated!");
                            } catch (e) {
                                // captureException(e)
                                message.error("Error in JSON!");
                            }
                        }}
                    >
                        Update Keyboard
                    </Button>
                </Form.Item>
            </Col>
        </>
    );
};

export default KeyboardForm;
