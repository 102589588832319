import { Badge, Card, Col } from "antd";
import { isInteger } from "lodash";

export const Content = ({
    ribbonColor,
    idx,
    currentBlock,
    isValid,
    onClick,
    setCurrentBlock,
    children,
    ribbonText,
}: any) => {
    const getBorderBottom = () => {
        if (!isInteger(idx)) return "3px solid indigo";

        return isValid ? "3px solid green" : "3px solid red";
    };

    return (
        <Col span={19}>
            <Badge.Ribbon
                color={ribbonColor || "cyan"}
                text={
                    ribbonText
                        ? ribbonText
                        : Number.isInteger(idx)
                        ? idx + 1
                        : idx
                }
                placement={"start"}
            >
                <Card
                    hoverable
                    style={{
                        borderRadius: "8px",
                        height: "75px",
                        marginBottom: "4px",
                        background: currentBlock == idx ? "#F1F1F1" : "",
                        borderBottom: getBorderBottom(),
                    }}
                    onClick={() => {
                        if (onClick) onClick();
                        else setCurrentBlock(idx);
                    }}
                    bodyStyle={{
                        background: "transparent",
                    }}
                >
                    {children}
                </Card>
            </Badge.Ribbon>
        </Col>
    );
};
