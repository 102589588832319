import React from "react";
import { Button, Form, Select } from "antd";
import PropTypes from "prop-types";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import _ from "lodash";

import { Field, RenderCheckBox } from "src/components";
import {
    RenderField,
    RenderSelect,
    RenderTags,
    RenderAutoComplete,
} from "src/components";

const FormItem = Form.Item;
const Option = Select.Option;

export default class DynamicFieldSet extends React.Component {
    add = () => {
        const arrayHelpers = this.props.arrayHelpers;
        let obj = {};
        const keys = this.props.keys;

        keys.map((k) => {
            if (k.type !== "dynamic")
                _.set(
                    obj,
                    k.key,
                    k.type === "number"
                        ? 1
                        : k.type === "tags" || k.type === "enums"
                        ? []
                        : k.type === "select"
                        ? !k?.options?.[0] || typeof k.options[0] === "string"
                            ? k.options[0]
                            : k.options[0]?.value
                        : "",
                );
            else _.set(obj, k.key, []);
        });

        arrayHelpers.push(obj);
    };

    render() {
        // const { getFieldDecorator, getFieldValue } = this.props.form;
        // const formItemLayoutWithOutLabel = {
        //   wrapperCol: {
        //     xs: { span: 24, offset: 0 },
        //     sm: { span: 20, offset: 4 }
        //   }
        // };
        // getFieldDecorator('keys', { initialValue: [] });
        const keys = this.props.keys;
        const name = this.props.name;
        const values = this.props.values;
        const arrayHelpers = this.props.arrayHelpers;
        let formItems = null;

        const formItemLayoutWithOutLabel = (k, indexk, v, indexv) => (
            <FormItem
                style={{ display: "inline-block", margin: "0px 5px" }}
                key={indexk}
            >
                {k.type == "text" ? (
                    <Field
                        name={`${name}[${indexv}].${k.key}`}
                        // name={[...name, indexv, k.key]}
                        component={RenderField}
                        placeholder={k.placeholder || k.key}
                        type="text"
                        label={`${k.label || k.key}`}
                        // label={`${k.label || k.key} #${indexv + 1}`}
                        value={_.get(v, k.key)}
                        key={indexv}
                        // style={{ display: 'inline-block', margin: '0px 5px' }}
                    />
                ) : null}

                {k.type == "textarea" ? (
                    <Field
                        name={`${name}[${indexv}].${k.key}`}
                        // name={[...name, indexv, k.key]}
                        component={RenderField}
                        placeholder={k.placeholder || k.key}
                        type="textarea"
                        label={`${k.label || k.key}`}
                        // label={`${k.label || k.key} #${indexv + 1}`}
                        value={_.get(v, k.key)}
                        key={indexv}
                        // style={{ display: 'inline-block', margin: '0px 5px' }}
                    />
                ) : null}

                {k.type == "number" ? (
                    <Field
                        name={`${name}[${indexv}].${k.key}`}
                        component={RenderField}
                        placeholder={k.placeholder || k.key}
                        type="number"
                        label={`${k.label || k.key}`}
                        value={_.get(v, k.key)}
                        key={indexv}
                    />
                ) : null}

                {k.type == "select" ? (
                    <Field
                        name={`${name}[${indexv}].${k.key}`}
                        component={RenderSelect}
                        placeholder={k.placeholder || k.key}
                        label={`${k.label || k.key}`}
                        value={_.get(v, k.key) || k.options[0]}
                        key={indexv}
                    >
                        {k.options.map((m, i) =>
                            typeof m === "string" ? (
                                <Option key={i} value={m}>
                                    {m}
                                </Option>
                            ) : (
                                <Option key={i} value={m.value}>
                                    {m.label}
                                </Option>
                            ),
                        )}
                    </Field>
                ) : null}

                {k.type == "tags" ? (
                    <Field
                        name={`${name}[${indexv}].${k.key}`}
                        component={RenderTags}
                        placeholder={k.placeholder || k.key}
                        label={`${k.label || k.key}`}
                        value={_.get(v, k.key) || []}
                        key={indexv}
                    />
                ) : null}

                {k.type == "enums" ? (
                    <Field
                        name={`${name}[${indexv}].${k.key}`}
                        component={RenderEnums}
                        placeholder={k.placeholder || k.key}
                        label={`${k.label || k.key}`}
                        value={_.get(v, k.key) || []}
                        category={k.category}
                        mode="multiple"
                        key={indexv}
                    />
                ) : null}

                {k.type == "auto_complete" ? (
                    <Field
                        name={`${name}[${indexv}].${k.key}`}
                        component={RenderAutoComplete}
                        placeholder={k.placeholder || k.key}
                        label={`${k.label || k.key}`}
                        value={_.get(v, k.key)}
                        key={indexv}
                        options={k.options}
                    />
                ) : null}

                {k.type == "checkbox" ? (
                    <Field
                        name={`${name}[${indexv}].${k.key}`}
                        component={RenderCheckBox}
                        placeholder={k.placeholder || k.key}
                        label={`${k.label || k.key}`}
                        checked={_.get(v, k.key)}
                        key={indexv}
                    />
                ) : null}
            </FormItem>
        );

        if (values) {
            // console.log(values);
            formItems = values.map((v, indexv) => (
                <FormItem
                    required={false}
                    key={indexv}
                    style={{ margin: "0px" }}
                >
                    {keys.map((k, indexk) =>
                        k.visible
                            ? k.visible(indexv)
                                ? formItemLayoutWithOutLabel(
                                      k,
                                      indexk,
                                      v,
                                      indexv,
                                  )
                                : null
                            : formItemLayoutWithOutLabel(k, indexk, v, indexv),
                    )}
                    {keys.length > 0 ? (
                        <MinusCircleOutlined
                            style={{ paddingTop: "50px", margin: "0px 5px" }}
                            title="Remove "
                            className="dynamic-delete-button"
                            onClick={() => arrayHelpers.remove(indexv)}
                        />
                    ) : null}
                </FormItem>
            ));
        }
        return (
            <div>
                <FormItem label={this.props.label}>
                    {formItems}
                    <FormItem
                        style={{ display: "inline-block", margin: "0px 5px" }}
                    >
                        <Button style={{ width: "200px" }} onClick={this.add}>
                            <PlusOutlined />
                            {this.props.buttonText || "Add more"}
                        </Button>
                    </FormItem>
                </FormItem>
            </div>
        );
    }
}

DynamicFieldSet.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    values: PropTypes.array,
    keys: PropTypes.array,
    setload: PropTypes.func,

    buttonText: PropTypes.string,
    arrayHelpers: PropTypes.object,
    options: PropTypes.array,
};
