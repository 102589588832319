import React from "react";
import {
    ColorPickerToolbarDropdown,
    MARK_BG_COLOR,
    MARK_COLOR,
} from "@udecode/plate";
import { FormatColorText } from "@styled-icons/material/FormatColorText";
import { Check } from "@styled-icons/material/Check";
import { BgColorsOutlined } from "@ant-design/icons";

export const ColorPickerToolbarButton = (): JSX.Element => {
    return (
        <ColorPickerToolbarDropdown
            pluginKey={MARK_COLOR}
            icon={<FormatColorText />}
            selectedIcon={<Check />}
        />
    );
};

export const BackgroundColorPickerToolbarButton = (): JSX.Element => {
    return (
        <ColorPickerToolbarDropdown
            pluginKey={MARK_BG_COLOR}
            icon={<BgColorsOutlined style={{ fontSize: "20px" }} />}
            selectedIcon={<Check />}
        />
    );
};
