import { BLOCK_TYPES, prefillText } from "../../common/index";
import { DEFAULT_VALUE_FILL_BLANK } from "../V2FillBlank/defaultValue";
const { TABLE_BLOCK } = BLOCK_TYPES;

export const DEFAULT_CELL = {
    ...DEFAULT_VALUE_FILL_BLANK,

    type: "text", // input / options / text / v2_fill_blank
    options: [[]],
    correct_option: -1,
    correct_answer: [""],
    text: prefillText(""),
    is_correct: false,
    solution: [],
    custom_keys: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
    custom_keyboard: [],
};

const PHONE_DEFAULT_VALUE = {
    type: TABLE_BLOCK,
    data: {
        [TABLE_BLOCK]: {
            text: [],
            is_heading: true,
            is_required: false,
            description: [],
            type: "input", // input / select
            heading: [...Array(3)].map((_, i) => ({
                text: prefillText(`Heading ${i + 1}`),
            })),
            data: [[...Array(3)].map(() => DEFAULT_CELL)],
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
};

export default PHONE_DEFAULT_VALUE;
