import { gql } from "@apollo/client";export default gql`query plans(
  $limit: Int = 20
  $offset: Int = 0
  $where: subscription_plan_bool_exp # $type: String # $parent_id: uuid
) {
  plans: subscription_plan(
    limit: $limit
    offset: $offset
    where: $where
  ) {
    id
    banner
    message_banner
    created_at
    credits
    description
    parent_id
    slug
    status
    tags {
      id
      tag {
        id
        name
        value
      }
    }
    title
    type
    variants
    breakdown
    order
  }
}
`;
