import { SetList, SetCreate, SetEdit } from "./views";

import { Route } from "react-router-dom";
export const SET_ROUTES = [
    <Route path="/set" element={<SetList />} />,

    <Route path="/set/create" element={<SetCreate />} />,
    <Route path="/set/update/:set_id" element={<SetEdit />} />,
];
export { SetList, SetCreate, SetEdit } from "./views";
