import React, { useEffect, useState } from "react";
import { Link } from "src/components";
import { Button, Col, Divider, Image, Row, Space, Spin, Table } from "antd";
import {
    AppstoreAddOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
} from "@ant-design/icons";

import DeleteView from "src/modules/tab/components/DeleteView";
import {
    EditIcon,
    RenderTable,
    Layout,
    MetaTag,
    ViewIcon,
} from "src/components";

import { FilterComponent } from "src/modules/tab/components";
import { Can } from "src/services/casl";

import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
import _ from "lodash";
// import { BooksComponent } from "src/modules/tab/components/BooksComponent";
import { useRouter } from "src/helpers";
import { BookList } from "../book/list/";
import { PublishTabToProd } from "../../components/PublishTabToProd";

const ListView: React.FC = (props: any) => {
    const {
        loading,
        tabs,
        tabAggregate,
        loadTabsData,
        updateTab,
        updateGroupTabMap,
    } = props;
    const [dataSource, setDataSource] = useState([]);
    const [tabId, setTabId] = useState();
    const router = useRouter();
    const { isReady, query, asPath: pathname } = router;
    const { tabId: queryTabId } = query;

    useEffect(() => {
        if (tabs.length && isReady) {
            const tmp = tabs
                .map((c, index) => {
                    return {
                        ...c,
                        index,
                        order: c.order,
                    };
                })
                .sort((a, b) => {
                    if (a.status === b.status) return a.order - b.order;
                    else return a.status > b.status ? 1 : -1;
                });
            setDataSource(tmp);

            if (queryTabId) setTabId(parseInt(queryTabId));
            else setTabId(tmp[0].id);
        }
    }, [tabs.length, isReady, queryTabId]);

    const columns = [
        {
            title: "Sort",
            dataIndex: "sort",
            width: 30,
            className: "drag-visible",
            render: () => <DragHandle />,
        },
        {
            title: "Title",
            key: "title",
            render: (text, record) => record?.title,
        },
        {
            title: "Status",
            key: "status",
            render: (text, record) =>
                record?.status == "active" ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                ),
        },
        // {
        //     title: "Status",
        //     key: "status",
        //     render: (text, record) => record?.status,
        // },
        {
            title: "Action",
            key: "action",
            align: "right",
            render: (text, record) => (
                <Space size="middle">
                    <ViewIcon
                        isButton={true}
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            });
                            setTabId(record.id);
                            router.push(
                                {
                                    pathname: "/tab",
                                    query: { tabId: record.id },
                                },
                                undefined,
                                { shallow: true },
                            );
                        }}
                    />

                    <Can I="edit" this={{ ...record }} passThrough>
                        {(allowed) => (
                            <Link
                                href={{
                                    pathname: `/tab/update/${record?.id}`,
                                    query: { redirect_to: encodeURI(pathname) },
                                }}
                            >
                                <EditIcon isButton={true} disabled={!allowed} />
                            </Link>
                        )}
                    </Can>

                    {process.env.REACT_APP_API_ENDPOINT_PROD && (
                        <Can I="copyToProd" a={"tab"} passThrough>
                            {(allowed) => (
                                <PublishTabToProd
                                    tab_id={record?.id}
                                    allowed={
                                        allowed &&
                                        record?.type == "worksheet_content"
                                    }
                                />
                            )}
                        </Can>
                    )}

                    <Can I="delete" a={"tab"} passThrough>
                        {(allowed) => (
                            <DeleteView
                                id={record?.id}
                                isButton={true}
                                disabled={!allowed}
                            />
                        )}
                    </Can>
                </Space>
            ),
        },
    ];

    const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
    ));

    const SortableItem = SortableElement((props) => (
        <tr
            {...props}
            style={{
                backgroundColor: props.selected ? "lightcyan" : "",
            }}
        />
    ));
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(
                [].concat(dataSource),
                oldIndex,
                newIndex,
            ).filter((el) => !!el);

            newData.map((d, index) => {
                if (d.order !== index + 1) {
                    console.log("Updating order: ", d.id, index + 1);
                    updateTab({ id: d.id, object: { order: index + 1 } });

                    // newData[index].order = index;
                }
            });

            setDataSource(newData);
        }
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helper="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(
            (x) => x.index === restProps["data-row-key"],
        );

        const id = dataSource.filter(
            (d) => d.index === restProps["data-row-key"],
        )[0]?.id;
        return (
            <SortableItem index={index} {...restProps} selected={id == tabId} />
        );
    };

    const booksProps = {
        loading,
        tabs,
        tabId,
        updateGroupTabMap,
        redirect_to: pathname,
    };

    return (
        <Layout titles={[{ name: "Tabs", link: "/tab" }]}>
            <MetaTag title="Tabs" />

            {/* <FilterComponent isTab={true} {...props} /> */}
            {/* <hr /> */}

            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <h1>Tabs List ({dataSource.length})</h1>
                        </Col>
                        <Col>
                            <Can I="create" a={"tab"}>
                                {(allowed) => (
                                    <Link
                                        href={{
                                            pathname: `/tab/create`,
                                            query: {
                                                redirect_to: pathname,
                                                order: tabs.length + 1,
                                            },
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            icon={<AppstoreAddOutlined />}
                                            disabled={!allowed}
                                        >
                                            Add Tab
                                        </Button>
                                    </Link>
                                )}
                            </Can>
                        </Col>
                    </Row>
                    <hr />

                    <Spin spinning={loading}>
                        {Boolean(tabs?.length) && (
                            <Table
                                pagination={false}
                                dataSource={dataSource}
                                columns={columns}
                                rowKey="index"
                                components={{
                                    body: {
                                        wrapper: DraggableContainer,
                                        row: DraggableBodyRow,
                                    },
                                }}
                            />
                        )}
                    </Spin>
                </Col>

                <Col span={16}>
                    <BookList {...booksProps} />
                </Col>
            </Row>
        </Layout>
    );
};

export default ListView;
