import { FunctionComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";
import set from "lodash/set";

import {
    TAGS_QUERY,
    LITE_BOOKS_QUERY,
    BOOKS_QUERY,
    CREATE_BOOK_MUTATION,
    UPDATE_BOOK,
    DELETE_BOOK_MUTATION,
    ADD_GROUP_TAG_MAP,
    DELETE_GROUP_TAG_MAP,
    ADD_TAG,
} from "../../graphql";
import { captureException } from "@sentry/react";

// Query
export const withTags = (Component: FunctionComponent) =>
    graphql(TAGS_QUERY, {
        options: ({ where, pagination }) => {
            const customWhere = set(where, ["group", "_eq"], "book");
            return {
                variables: {
                    where: { ...customWhere },
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                tags,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadTagsData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            tags: [
                                ...previousResult.tags,
                                ...fetchMoreResult.tags,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                tags,
                subscribeToMore,
                updateQuery,
                loadTagsData,
                refetchTags: refetch,
            };
        },
    })(Component);

// Query
export const withBooks = (Component: FunctionComponent) =>
    graphql(BOOKS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                books,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadBooksData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            books: [
                                ...previousResult.books,
                                ...fetchMoreResult.books,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                books,
                subscribeToMore,
                updateQuery,
                loadBooksData,
                refetchBooks: refetch,
            };
        },
    })(Component);

export const withLiteBooks = (Component: FunctionComponent) =>
    graphql(LITE_BOOKS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                books,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadBooksData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            books: [
                                ...previousResult.books,
                                ...fetchMoreResult.books,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                books,
                subscribeToMore,
                updateQuery,
                loadBooksData,
                refetchBooks: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreateBook = (Component: FunctionComponent) =>
    graphql(CREATE_BOOK_MUTATION, {
        props: ({ mutate }) => ({
            createBook: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createBook: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { insert_group_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createBook: {
                                object,
                                __typename: "insert_group_one",
                            },
                        },
                    });
                    return insert_group_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withCreateTag = (Component: FunctionComponent) =>
    graphql(ADD_TAG, {
        props: ({ mutate }) => ({
            addTag: async (object: Object) => {
                try {
                    const {
                        data: { insert_tag_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            insert_tag_one: {
                                id: "temp-id",
                                ...object,
                                __typename: "tag",
                            },
                        },
                    });
                    return insert_tag_one;
                } catch (e) {
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateBook = (Component: FunctionComponent) =>
    graphql(UPDATE_BOOK, {
        props: ({ mutate }) => ({
            updateBook: (object: Object) => {
                mutate({
                    variables: { ...object },
                    optimisticResponse: {
                        updateBook: {
                            ...object,
                            __typename: "group",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withAddGroupTagMap = (Component: FunctionComponent) =>
    graphql(ADD_GROUP_TAG_MAP, {
        props: ({ mutate }) => ({
            addGroupTagMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     updateBook: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withDeleteBook = (Component: FunctionComponent) =>
    graphql(DELETE_BOOK_MUTATION, {
        props: ({ mutate }) => ({
            deleteBook: ({ id, submissionIds }) => {
                mutate({
                    variables: { id, submissionIds },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteBook: {
                            id,
                            __typename: "DELETE_GROUP_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withDeleteGroupTagMap = (Component: FunctionComponent) =>
    graphql(DELETE_GROUP_TAG_MAP, {
        props: ({ mutate }) => ({
            deleteGroupTagMap: (object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     __typename: "Mutation",
                    //     deleteBook: {
                    //         id,
                    //         __typename: "DELETE_GROUP_BY_PK",
                    //     },
                    // },
                });
            },
        }),
    })(Component);
