import { FunctionComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";

import {
    PLANS_QUERY,
    CREATE_PLAN_MUTATION,
    UPDATE_PLAN,
    DELETE_PLAN_MUTATION,
    DELETE_PLAN_TAG_MAP,
    ADD_PLAN_TAG_MAP,
} from "../graphql";
import { captureException } from "@sentry/react";

// Query
export const withPlans = (Component: FunctionComponent) =>
    graphql(PLANS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                plans,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadQuestionsData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            plans: [
                                ...previousResult.plans,
                                ...fetchMoreResult.plans,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                plans,
                subscribeToMore,
                updateQuery,
                loadQuestionsData,
                refetchPlans: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreatePlan = (Component: FunctionComponent) =>
    graphql(CREATE_PLAN_MUTATION, {
        props: ({ mutate }) => ({
            createPlan: async (object: Object) => {
                // console.log('🚀 ~ file: Operations.ts ~ line 60 ~ createPlan: ~ object', object);
                try {
                    const {
                        data: { insert_plan_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createPlan: {
                                object,
                                __typename: "INSERT_PLAN_ONE",
                            },
                        },
                    });
                    return insert_plan_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdatePlan = (Component: FunctionComponent) =>
    graphql(UPDATE_PLAN, {
        props: ({ mutate }) => ({
            updatePlan: (object: Object) => {
                mutate({
                    variables: { id: object.id, object },
                    // optimisticResponse: {
                    //     updatePlan: {
                    //         ...object,
                    //         __typename: "plan",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withDeletePlan = (Component: FunctionComponent) =>
    graphql(DELETE_PLAN_MUTATION, {
        props: ({ mutate }) => ({
            deletePlan: (id: string) => {
                mutate({
                    variables: { id },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deletePlan: {
                            id,
                            __typename: "DELETE_PLAN_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withAddPlanTagMap = (Component: FunctionComponent) =>
    graphql(ADD_PLAN_TAG_MAP, {
        props: ({ mutate }) => ({
            addPlanTagMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     updateBook: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withDeletePlanTagMap = (Component: FunctionComponent) =>
    graphql(DELETE_PLAN_TAG_MAP, {
        props: ({ mutate }) => ({
            deletePlanTagMap: (object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     __typename: "Mutation",
                    //     deleteBook: {
                    //         id,
                    //         __typename: "DELETE_GROUP_BY_PK",
                    //     },
                    // },
                });
            },
        }),
    })(Component);
