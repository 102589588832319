import { gql } from "@apollo/client";export default gql`mutation UpdateUserOtherField($id: Int!, $other: jsonb!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { other: $other }) {
        other
        name
        id
        email
        created_at
        updated_at
        role
        auth0_id
    }
}
`;
