import React from "react";
import { Row, Col, Form, Button, Divider } from "antd";
import { withFormik, FieldArray } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderCheckBox,
    RenderField,
    RenderAutoComplete,
    RenderTags,
    RenderDynamicField,
    RenderSelect,
    RenderUpload,
} from "src/components";
import * as yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";

import { Select } from "antd";
const Option = Select.Option;

const MemeForm = (props) => {
    const { onFinishFailed, values, handleSubmit, isSubmitting } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Meme Asset"
                        placeholder="Meme Asset"
                        name={"asset_url"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.asset_url}
                        rules={[{ required: true }]}
                        path="meme/"
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Meme Group"
                        placeholder="Meme Group"
                        name={"group"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.group}
                        rules={[{ required: true }]}
                    >
                        <Option value="fast_wrong_answer">
                            Fast + Wrong answer
                        </Option>
                        <Option value="2_wrong_answers">
                            2 + back to back wrong answers
                        </Option>
                        <Option value="wrong_answer">Wrong Answer</Option>
                        <Option value="speedy_correct_answer">
                            Speedy + Correct answer
                        </Option>
                        <Option value="2_correct_answers">
                            2 back to back correct answers
                        </Option>
                        <Option value="3_correct_answers">
                            3 back to back correct answers
                        </Option>
                        <Option value="correct_answer">Correct Answer</Option>
                        <Option value="no_attempt">No Attempt</Option>
                        <Option value="2_no_attempt">
                            2 back to back No Attempt
                        </Option>
                        <Option value="3_no_attempt">
                            3 + back to back No Attempt
                        </Option>
                        <Option value="learn_mode_ended_without_success">
                            learn_mode_ended_without_success
                        </Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Meme Status"
                        placeholder="Meme Status"
                        name={"status"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.status}
                        rules={[{ required: true }]}
                    >
                        <Option value="inactive">Inactive</Option>
                        <Option value="active">Active</Option>
                    </Field>
                </Col>

                <Col lg={12} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export const FormSchema = yup.object().shape({
    asset_url: yup.string().required("This field is required"),
    group: yup.string().required("This field is required"),
});

const MemeFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { meme, user_id } = props;

        return {
            ...(meme?.id ? { id: meme.id } : {}),
            asset_url: meme?.asset_url || "",
            group: meme?.group || "",
            status: meme?.status || "active",
            user_id,
        };
    },
    validationSchema: FormSchema,

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = MemeFormWithFormik(MemeForm);

const FormikFormWithUser = (props) => {
    const { user } = useAuth0();

    const namespace = "https://hasura.io/jwt/claims/";
    const user_id = user && user[namespace + "user_id"];
    return <FormikForm {...props} user_id={user_id} />;
};

export default FormikFormWithUser;
