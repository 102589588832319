import V2_DRAG_AND_SWIPE_DEFAULT_VALUE from "./defaultValue";
import renderCardV2DragAndSwipe from "./renderCard";
import renderBlockV2DragAndSwipe from "./renderBlock";
import renderSettingsV2DragAndSwipe from "./renderSettings";
import isValidCallbackV2DragAndSwipe from "./isValid";
import renderEditorV2DragAndSwipe from "./renderEditor";
import { addCallback, BLOCK_TYPES } from "../../common";

const { V2_DRAG_AND_SWIPE_BLOCK } = BLOCK_TYPES;

const V2_DRAG_AND_SWIPE_BLOCK_CONFIG = {
    [V2_DRAG_AND_SWIPE_BLOCK]: {
        label: "V2 Drag and Swipe",
        defaultValue: V2_DRAG_AND_SWIPE_DEFAULT_VALUE,
        renderBlock: renderBlockV2DragAndSwipe,
        renderEditor: renderEditorV2DragAndSwipe,
        renderCard: renderCardV2DragAndSwipe,
        renderSettings: renderSettingsV2DragAndSwipe,
        addCallback,
        isValidCallback: isValidCallbackV2DragAndSwipe,
    },
};

export {
    V2_DRAG_AND_SWIPE_DEFAULT_VALUE,
    V2_DRAG_AND_SWIPE_BLOCK_CONFIG,
    renderBlockV2DragAndSwipe,
    renderCardV2DragAndSwipe,
    renderSettingsV2DragAndSwipe,
    renderEditorV2DragAndSwipe,
    isValidCallbackV2DragAndSwipe,
};
