import renderBlockPhone from "./renderBlock";
import renderEditorPhone from "./renderEditor";
import isValidCallbackPhone from "./isValid";
import PHONE_DEFAULT_VALUE from "./defaultValue";
import renderSettingsPhone from "./renderSettings";
import renderCardPhone from "./renderCard";
import { BLOCK_TYPES } from "../../common/index";

const { PHONE_BLOCK } = BLOCK_TYPES;

const PHONE_BLOCK_CONFIG = {
    [PHONE_BLOCK]: {
        label: "Phone",
        defaultValue: PHONE_DEFAULT_VALUE,
        renderBlock: renderBlockPhone,
        renderEditor: renderEditorPhone,
        renderCard: renderCardPhone,
        renderSettings: renderSettingsPhone,
        isValidCallback: isValidCallbackPhone,
    },
};

export {
    renderBlockPhone,
    renderCardPhone,
    renderEditorPhone,
    renderSettingsPhone,
    isValidCallbackPhone,
    PHONE_DEFAULT_VALUE,
    PHONE_BLOCK_CONFIG,
};
