import renderBlockV2Table from "./renderBlock";
import renderEditorV2Table from "./renderEditor";
import isValidCallbackV2Table from "./isValid";
import V2_TABLE_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2Table from "./renderSettings";
import renderCardV2Table from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/";

const { V2_TABLE_BLOCK } = BLOCK_TYPES;

const V2_TABLE_BLOCK_CONFIG = {
    [V2_TABLE_BLOCK]: {
        label: "V2 Table",
        defaultValue: V2_TABLE_DEFAULT_VALUE,
        renderBlock: renderBlockV2Table,
        renderEditor: renderEditorV2Table,
        renderCard: renderCardV2Table,
        renderSettings: renderSettingsV2Table,
        addCallback,
        isValidCallback: isValidCallbackV2Table,
    },
};

export {
    renderBlockV2Table,
    renderCardV2Table,
    renderEditorV2Table,
    renderSettingsV2Table,
    isValidCallbackV2Table,
    V2_TABLE_DEFAULT_VALUE,
    V2_TABLE_BLOCK_CONFIG,
};
