import { DesktopOutlined, UserOutlined } from "@ant-design/icons";
import { Layout as AntdLayout, Menu } from "antd";
import { Link } from "src/components";
import React from "react";

const { Sider: AntdSider } = AntdLayout;
const { SubMenu } = Menu;

class SiderDemo extends React.Component {
    state = {
        collapsed: true,
    };

    onCollapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };

    render() {
        const { collapsed } = this.state;
        return (
            <AntdSider
                collapsible
                collapsed={collapsed}
                onCollapse={this.onCollapse}
            >
                <div className="logo">
                    <img
                        src="/icons/logo-icon.svg"
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>

                <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
                    <Menu.Item key="2" icon={<DesktopOutlined />}>
                        <Link href={"/"}>Dashboard</Link>
                    </Menu.Item>

                    <SubMenu key="sub1" icon={<UserOutlined />} title="Profile">
                        <Menu.Item key="3">
                            <Link href={"/profile"}>Profile</Link>
                        </Menu.Item>

                        <Menu.Item key="4">
                            <Link href={"/api/auth/logout"}>Logout</Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </AntdSider>
        );
    }
}

export const Sider = SiderDemo;
