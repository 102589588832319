import React from "react";
import { Link } from "src/components";
import { Card, Row } from "antd";

const { Meta } = Card;

const NavCard = ({ href, title, icon }) => {
    return (
        <Link href={href}>
            <Card hoverable>
                <Row justify="center">{icon}</Row>
                <br />

                <Row justify="center">
                    <Meta
                        title={
                            <strong style={{ color: "#5a67d8" }}>
                                {title}
                            </strong>
                        }
                    />
                </Row>
            </Card>
        </Link>
    );
};

export default NavCard;
