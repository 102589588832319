import { MetaTag } from "src/components";
import { renderBlock } from "src/modules/worksheet/components/blocks";
import { Card, Col, Row, Tag } from "antd";

export const Component = (props: any) => {
    const { worksheet, blocks, isLiveComponent = false } = props;

    return (
        <div
            style={{
                padding: "10px 10px",
                background: "#F7F7F7",
            }}
        >
            <MetaTag
                title={isLiveComponent ? "Live Worksheet" : "Preview Worksheet"}
            />

            <Row justify="center" align="middle">
                <Col xs={16} lg={16} md={16}>
                    <Card
                        style={{ borderRadius: "8px", marginBottom: "8px" }}
                        bordered={true}
                        bodyStyle={{ padding: "12px 12px" }}
                    >
                        <h3>
                            {isLiveComponent
                                ? "Preview for "
                                : "Live version for "}
                            <Tag>{worksheet?.title}</Tag>
                        </h3>

                        <div>
                            <span style={{ marginRight: "5px" }}>Tags</span>
                            {worksheet?.tags?.map(({ tag }: any) => (
                                <Tag>{`${tag?.value} (${tag?.name})`}</Tag>
                            ))}
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row justify="center" align="middle">
                <Col xs={16} lg={16} md={16}>
                    {blocks.map((block: any) => {
                        return (
                            <Card
                                style={{
                                    borderRadius: "8px",
                                    marginBottom: "8px",
                                }}
                                bordered={true}
                                bodyStyle={{ padding: "12px 12px" }}
                            >
                                <div style={{ margin: "5px 0px" }}>
                                    {renderBlock({ ...block, heading: false })}
                                </div>
                            </Card>
                        );
                    })}
                </Col>
            </Row>
        </div>
    );
};
