import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Input, Select } from "antd";
import { cloneDeep, set } from "lodash";
import { v4 as uuid } from "uuid";
import {
    RULES_SCHEMA,
    VARIABLE_OPTIONS,
} from "src/modules/worksheet/components/blocks/renderers/ProgressionStage/defaultValue";
import { OPERATOR_OPTIONS } from "src/modules/worksheet/components/blocks/helpers/constants";

const CondiionsFormCard = (props: any) => {
    const {
        condition,
        conditionIndex,
        blocks,
        setBlock,
        block,
        conditions,
        focusElement,
        setFocusElement,
        disableWrite,
    } = props;

    return (
        <Card
            title={`Rule ${conditionIndex + 1}:`}
            extra={
                <Button
                    type="text"
                    danger
                    size="small"
                    disabled={disableWrite}
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        const tempConditions = [...conditions];

                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["condition_jump", "conditions"],
                            tempConditions.filter(
                                (_v, i) => i !== conditionIndex,
                            ),
                        );

                        setBlock(tmpBlock);
                    }}
                >
                    Delete this rule
                </Button>
            }
            bordered={false}
            bodyStyle={{
                padding: 0,
            }}
            headStyle={{
                padding: 0,
            }}
        >
            {condition?.rules.map(
                (
                    item: {
                        conjunction: string;
                        variable: string;
                        value: any;
                        question_count: any;
                        operator: any;
                    },
                    ci: number,
                ) => (
                    <Card
                        bodyStyle={{
                            padding: 0,
                        }}
                        headStyle={{
                            padding: 0,
                        }}
                        bordered={false}
                        key={uuid()}
                    >
                        {ci > 0 && (
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue={item?.conjunction}
                                    disabled={disableWrite}
                                    onChange={(value) => {
                                        const tempItem = cloneDeep(item);

                                        tempItem.conjunction = value;

                                        const tempRules = [...condition?.rules];

                                        tempRules[ci] = tempItem;

                                        const tempConditions =
                                            cloneDeep(conditions);
                                        tempConditions[conditionIndex].rules =
                                            tempRules;

                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["condition_jump", "conditions"],
                                            tempConditions,
                                        );
                                        setBlock(tmpBlock);
                                        setFocusElement(null);
                                    }}
                                    options={[
                                        {
                                            value: "and",
                                            label: "and",
                                        },
                                        {
                                            value: "or",
                                            label: "or",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            label={"If:"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                defaultValue={item.variable}
                                disabled={disableWrite}
                                onChange={(value) => {
                                    const tempItem = cloneDeep(item);

                                    tempItem.variable = value;
                                    tempItem.value = 0;
                                    tempItem.question_count = 0;

                                    const tempRules = [...condition?.rules];

                                    tempRules[ci] = tempItem;

                                    const tempConditions =
                                        cloneDeep(conditions);
                                    tempConditions[conditionIndex].rules =
                                        tempRules;

                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["condition_jump", "conditions"],
                                        tempConditions,
                                    );
                                    setBlock(tmpBlock);
                                    setFocusElement(null);
                                }}
                                options={VARIABLE_OPTIONS}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Is:"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                defaultValue={item.operator}
                                disabled={disableWrite}
                                onChange={(value) => {
                                    const tempItem = cloneDeep(item);

                                    tempItem.operator = value;

                                    const tempRules = [...condition?.rules];

                                    tempRules[ci] = tempItem;

                                    const tempConditions =
                                        cloneDeep(conditions);
                                    tempConditions[conditionIndex].rules =
                                        tempRules;

                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["condition_jump", "conditions"],
                                        tempConditions,
                                    );
                                    setBlock(tmpBlock);
                                    setFocusElement(null);
                                }}
                                options={OPERATOR_OPTIONS}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Count:"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                disabled={disableWrite}
                                autoFocus={
                                    focusElement === `${conditionIndex}-value`
                                }
                                onFocus={() =>
                                    setFocusElement(`${conditionIndex}-value`)
                                }
                                id={`${conditionIndex}-value`}
                                min={0}
                                value={item?.value}
                                onChange={(e) => {
                                    const tempItem = cloneDeep(item);
                                    tempItem.value = e.target.value
                                        ? Number(e.target.value)
                                        : null;

                                    const tempRules = [...condition?.rules];

                                    tempRules[ci] = tempItem;

                                    const tempConditions =
                                        cloneDeep(conditions);
                                    tempConditions[conditionIndex].rules =
                                        tempRules;

                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["condition_jump", "conditions"],
                                        tempConditions,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Questions to consider:"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                autoFocus={
                                    focusElement ===
                                    `${conditionIndex}-question_count`
                                }
                                disabled={disableWrite}
                                onFocus={() =>
                                    setFocusElement(
                                        `${conditionIndex}-question_count`,
                                    )
                                }
                                id={`${conditionIndex}-question_count`}
                                min={0}
                                value={item?.question_count}
                                onChange={(e) => {
                                    const tempItem = cloneDeep(item);
                                    tempItem.question_count = e.target.value
                                        ? Number(e.target.value)
                                        : null;

                                    const tempRules = [...condition?.rules];

                                    tempRules[ci] = tempItem;

                                    const tempConditions =
                                        cloneDeep(conditions);
                                    tempConditions[conditionIndex].rules =
                                        tempRules;

                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["condition_jump", "conditions"],
                                        tempConditions,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>

                        <Button
                            type="text"
                            danger
                            size="small"
                            style={{
                                marginBottom: "20px",
                                float: "right",
                            }}
                            disabled={disableWrite}
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                const tempRules = [...condition?.rules].filter(
                                    (v, i) => i !== ci,
                                );

                                let tempConditions = cloneDeep(conditions);

                                if (tempRules.length > 0) {
                                    tempConditions[conditionIndex].rules =
                                        tempRules;
                                } else {
                                    tempConditions = tempConditions.filter(
                                        (_v: any, i: any) =>
                                            i !== conditionIndex,
                                    );
                                }

                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["condition_jump", "conditions"],
                                    tempConditions,
                                );

                                setBlock(tmpBlock);
                                setFocusElement(null);
                            }}
                        >
                            Delete this condition
                        </Button>
                    </Card>
                ),
            )}

            <Form.Item>
                <Button
                    type="link"
                    disabled={disableWrite}
                    onClick={() => {
                        const tempRules = [...condition?.rules];

                        tempRules.push(RULES_SCHEMA);

                        const tempConditions = cloneDeep(conditions);
                        tempConditions[conditionIndex].rules = tempRules;

                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["condition_jump", "conditions"],
                            tempConditions,
                        );

                        setBlock(tmpBlock);
                        setFocusElement(null);
                    }}
                >
                    <PlusCircleOutlined /> Add condition
                </Button>
            </Form.Item>

            <Divider />

            <Form.Item
                label={"Go To:"}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select
                    defaultValue={condition?.block_id}
                    disabled={disableWrite}
                    onChange={(value) => {
                        const tempConditions = cloneDeep(conditions);
                        tempConditions[conditionIndex].block_id = value;

                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["condition_jump", "conditions"],
                            tempConditions,
                        );
                        setBlock(tmpBlock);
                        setFocusElement(null);
                    }}
                    options={[
                        {
                            value: null,
                            label: `Next`,
                        },
                        ...blocks.map(
                            (item: {
                                id: any;
                                tmpId: any;
                                variants: { block: { type: any } }[];
                            }) => ({
                                value: item?.id || item?.tmpId,
                                label: (
                                    <span
                                        style={{
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        {item?.id || item?.tmpId} -{" "}
                                        {item?.variants[0]?.block?.type
                                            ?.split("_")
                                            ?.join(" ") || ``}
                                    </span>
                                ),
                            }),
                        ),
                    ]}
                    showSearch
                />
            </Form.Item>
        </Card>
    );
};

export default CondiionsFormCard;
