import _ from "lodash";

export const setBlock = ({
    block,
    index,
    blocks,
    hasChanged,
    toggleHasChanged,
    setBlocks,
}: any) => {
    const tmpBlocks = [...blocks];
    if (!hasChanged) {
        const prevBlock = blocks[index];
        if (!_.isEqual(prevBlock, block)) toggleHasChanged(true);
    }

    tmpBlocks[index] = block;
    setBlocks(tmpBlocks);
    return tmpBlocks;
};
