import { SlateEditor } from "src/components/plate";
import { Form, Select } from "antd";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { renderer } from "../../helpers";

const renderEditorText = ({
    block,
    setBlock,
    currentEditor,
    setCurrentEditor,
    hasMentions = false,
    mentionsList = [],
    disableWrite,
}: any) => {
    const {
        id,
        tmpId,
        data: { v2_rich_text, textAlign },
    } = block;
    const isValid = Boolean(renderer(v2_rich_text));
    return (
        <div>
            <Form.Item
                rules={[
                    {
                        required: true,
                    },
                ]}
                validateStatus={!isValid ? "error" : ""}
                help={!isValid ? "Field cannot be empty" : ""}
            >
                <SlateEditor
                    id={`${id || tmpId}_text`}
                    onChange={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["data", "v2_rich_text"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    value={v2_rich_text}
                    isFocused={currentEditor == `${id || tmpId}_text`}
                    setEditor={(id: string) => setCurrentEditor(id)}
                    v2={true}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                />
            </Form.Item>

            <Form.Item label="Text Align">
                <Select
                    value={textAlign || "left"}
                    placeholder="Select type"
                    options={[
                        { label: "Left", value: "left" },
                        { label: "Center", value: "center" },
                        { label: "Right", value: "right" },
                        { label: "Justify", value: "justify" },
                    ]}
                    onChange={(val) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(tmpBlock, ["data", "textAlign"], val);
                        setBlock(tmpBlock);
                    }}
                    disabled={disableWrite}
                />
            </Form.Item>
        </div>
    );
};

export default renderEditorText;
