import renderBlockV2MCQ from "./renderBlock";
import renderEditorV2MCQ from "./renderEditor";
import isValidCallbackV2MCQ from "./isValid";
import V2_MCQ_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2MCQ from "./renderSettings";
import renderCardV2MCQ from "./renderCard";
import { BLOCK_TYPES, addCallback } from "../../common/";

const { V2_MCQ_BLOCK } = BLOCK_TYPES;

const V2_MCQ_BLOCK_CONFIG = {
    [V2_MCQ_BLOCK]: {
        label: "V2 MCQ",
        defaultValue: V2_MCQ_DEFAULT_VALUE,
        renderBlock: renderBlockV2MCQ,
        renderEditor: renderEditorV2MCQ,
        renderCard: renderCardV2MCQ,
        renderSettings: renderSettingsV2MCQ,
        addCallback,
        isValidCallback: isValidCallbackV2MCQ,
    },
};

export {
    renderBlockV2MCQ,
    renderCardV2MCQ,
    renderEditorV2MCQ,
    renderSettingsV2MCQ,
    isValidCallbackV2MCQ,
    V2_MCQ_DEFAULT_VALUE,
    V2_MCQ_BLOCK_CONFIG,
};
