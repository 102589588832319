import { gql } from "@apollo/client";export default gql`query WorksheetBooksQuery {
    worksheetBooks: home_explore_tab(
        where: { type: { _eq: "worksheet_content" } }
    ) {
        id
        groups {
            id
            group {
                id
                title
                other
            }
        }
    }
}
`;
