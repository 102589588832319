import { Tag } from "antd";

export const renderTitle = ({ record }: any) => {
    return (
        <div>
            <div>
                <b style={{ marginRight: "5px" }}>Title:</b> {record?.title}
            </div>
            <div>
                <b style={{ marginRight: "5px" }}>Author:</b>{" "}
                {record?.user?.name}
            </div>
            <div>
                <b style={{ marginRight: "5px" }}>Tags:</b>
                {record?.tags?.map(({ tag: { name, value } }: any) => (
                    <Tag>
                        {value}({name})
                    </Tag>
                ))}
            </div>
        </div>
    );
};
