import { Spin } from "antd";
import LoadingOverlay from "react-loading-overlay";

export const OverlaySpinner = ({ active, children }: any) => {
    return (
        <LoadingOverlay active={active} spinner={<Spin />}>
            {children}
        </LoadingOverlay>
    );
};
