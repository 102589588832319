import { BLOCK_TYPES } from "../../common/";
const { COLOR_COMBINE_BLOCK } = BLOCK_TYPES;

export const COLOR_COMBINE_TYPES = [
    { label: "One Container", value: "one_container" },
    { label: "Two Container", value: "two_container" },
    { label: "No Container", value: "no_container" },
    { label: "Side Container", value: "side_container" },
];

export const getColorCombine = ({
    type,
    top_input = {},
    bottom_input = {},
    containers = [],
    correct_answer = [],
    layout = "row",
}: any) => ({
    type: COLOR_COMBINE_BLOCK,
    data: {
        [COLOR_COMBINE_BLOCK]: {
            type, // one_container, two_container, no_container, side_container
            text: [],
            top_input: {
                color: "#ffffff",
                is_fixed: false,
                initial_count: 1,
                minCount: 1,
                maxCount: 5,
                description: [],
                ...top_input,
            },
            bottom_input: {
                color: "#ffffff",
                is_fixed: false,
                initial_count: 1,
                minCount: 1,
                maxCount: 5,
                description: [],
                ...bottom_input,
            },
            containers,
            correct_answer, // [4, 3],
            layout, // row, column
        },
        other: {
            duration: 0,
            audio: "",
            description: [],
            global_feedback: null,
            solution_video: "",
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
});

export const COLOR_COMBINE_DEFAULT_VALUE = {
    type: COLOR_COMBINE_BLOCK,
    data: {
        [COLOR_COMBINE_BLOCK]: {
            type: "", // one_container, two_container, no_container, side_container
            text: [],
            top_input: {
                color: "#ffffff", // @@color1@@
                is_fixed: false,
                initial_count: 1,
                minCount: 1,
                maxCount: 5,
                variable: "",
                description: [],
            },
            bottom_input: {
                color: "#ffffff",
                is_fixed: false,
                initial_count: 1,
                minCount: 1,
                maxCount: 5,
                variable: "",
                description: [],
            },
            containers: [
                {
                    is_fixed: false,
                    description: [],
                },
                {
                    is_fixed: true,
                    description: [],
                },
            ],
            correct_answer: [], // [4, 3],
            layout: "row", // row, column
        },
        other: {
            duration: 0,
            audio: "",
            description: [],
            global_feedback: null,
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
};

export default COLOR_COMBINE_DEFAULT_VALUE;
