import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import {
    withHookForStudent,
    withUpdateStudent,
} from "src/modules/student/operations";
import { compose } from "src/helpers";

import EditView from "./component";

const Edit = (props) => {
    const { updateStudent } = props;
    const router = useRouter();

    const onFinish = async (values: any) => {
        await updateStudent(values);
        router.push("/student");
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <EditView
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            {...props}
        />
    );
};

export default compose(withHookForStudent, withUpdateStudent)(Edit);
