import {
    getPluginType,
    insertNodes,
    PlateEditor,
    TElement,
} from "@udecode/plate-core";
import { ELEMENT_LATEX } from "../createLatexPlugin";

export const insertLatex = (editor: PlateEditor, latexString: string) => {
    const text = { text: latexString };
    const latex = {
        type: getPluginType(editor, ELEMENT_LATEX),
        children: [text],
    };
    insertNodes<TElement>(editor, latex);
};
