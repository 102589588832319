import { Card } from "antd";
import { renderTags, BLOCK_TYPES } from "../../common";
import { renderer } from "../../helpers/";

const { LONG_ANSWER_BLOCK } = BLOCK_TYPES;

const renderBlock = (block: any) => {
    const {
        data: {
            [LONG_ANSWER_BLOCK]: {
                text,
                solution,
                limit,
                hasLimit,
                description,
            },
        },
        tags,
    } = block;
    return (
        <div>
            {renderTags(tags)}
            {hasLimit && limit ? (
                <div
                    style={{
                        margin: "10px 0",
                    }}
                >
                    {`Characters limit - ${limit}`}
                </div>
            ) : (
                ""
            )}
            <Card>{renderer(text)}</Card>
            {description && <Card>{renderer(description)}</Card>}
            <Card>{renderer(solution)}</Card>
        </div>
    );
};

export default renderBlock;
