import React, { useState } from "react";
import { Link } from "src/components";
import { Button, Col, Divider, Row, Space, Spin } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";

import DeleteView from "src/modules/user/components/DeleteView";
import { EditIcon, RenderTable, Layout, MetaTag } from "src/components";

import { FilterComponent } from "src/modules/user/components";

const ListView: React.FC = (props: any) => {
    const { loading, users, userAggregate, loadUsersData } = props;

    const columns = [
        {
            title: "Name",
            key: "name",
            render: (text, record) => record?.name,
        },
        {
            title: "email",
            key: "email",
            render: (text, record) => record?.email,
        },
        {
            title: "role",
            key: "role",
            render: (text, record) => record?.role,
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            render: (text, record) => (
                <Space size="middle">
                    <Link href={`/user/update/${record?.id}`}>
                        <EditIcon isButton={true} />
                    </Link>
                    <Divider />
                    <DeleteView id={record?.id} isButton={true} />
                </Space>
            ),
        },
    ];

    return (
        <Layout titles={[{ name: "Users", link: "/user" }]}>
            <MetaTag title="Users" />
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Users List</h1>
                </Col>
                <Col>
                    <Link href={"/user/create"}>
                        <Button type="primary" icon={<AppstoreAddOutlined />}>
                            Add
                        </Button>
                    </Link>
                </Col>
            </Row>
            <hr />

            <FilterComponent isUser={true} {...props} />
            <hr />

            <Spin spinning={loading}>
                {Boolean(users?.length) && (
                    <RenderTable
                        loading={loading}
                        columns={columns}
                        data={users || []}
                        loadData={loadUsersData}
                        aggregate={userAggregate?.aggregate?.count}
                    />
                )}
            </Spin>
        </Layout>
    );
};

export default ListView;
