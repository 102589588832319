import { isEditorEmpty } from "../../common/index";

export const checkExactInValid = (
    worksheet: { type: string },
    multiSelect: { type: string; exact: number },
    options: string | any[],
): boolean => {
    return (
        worksheet?.type === "quiz_form" &&
        multiSelect?.type == "exact" &&
        (multiSelect?.exact < 1 || multiSelect?.exact > options?.length)
    );
};

export const checkRangeInValid = (
    worksheet: { type: string },
    multiSelect: { type: string; range: number[] },
    options: string | any[],
): boolean => {
    return (
        worksheet?.type === "quiz_form" &&
        multiSelect?.type == "range" &&
        (!multiSelect?.range ||
            multiSelect?.range[0] < 1 ||
            multiSelect?.range[1] < 1 ||
            multiSelect?.range[0] > options?.length ||
            multiSelect?.range[1] > options?.length ||
            multiSelect?.range[0] > multiSelect?.range[1])
    );
};

const isValidCallbackMCQ = ({ block, worksheet }: any) => {
    if (!block || !block.data)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            mcq: { text, options, correct_options, multiSelect },
        },
    } = block;

    const checkCorrectOption = () =>
        (worksheet && worksheet.type === "quiz_form") ||
        ((!worksheet || worksheet?.type !== "quiz_form") &&
            Boolean(correct_options.length));

    return {
        isValid:
            !isEditorEmpty(text) &&
            !options.some((option: any) => isEditorEmpty(option)) &&
            checkCorrectOption() &&
            !checkExactInValid(worksheet, multiSelect, options) &&
            !checkRangeInValid(worksheet, multiSelect, options),
        error: <></>,
    };
};

export default isValidCallbackMCQ;
