import { gql } from "@apollo/client";export default gql`mutation createDocument($object: home_explore_document_insert_input = {}) {
    insert_home_explore_document_one(
        object: $object
        on_conflict: { constraint: document_pkey, update_columns: id }
    ) {
        id
        status
        banner
        category
        created_at
        cta_action
        cta_text
        preview_images
        title
        type
        updated_at
        url
    }
}
`;
