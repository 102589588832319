import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";

const renderCardV2FillBlank = (props: any) => {
    const {
        block: {
            data: {
                v2_fill_blank: { text },
            },
        },
    } = props;
    const tmp = text.find(({ type, children }: any) => type == "p")?.children[0]
        .text;
    const displayText = tmp
        ? tmp.slice(0, tmp.length > 10 ? 10 : tmp.length)
        : "...";
    return (
        <BlockCard {...props}>
            <div>{displayText}</div>
            <div style={{ fontSize: "11px", fontWeight: "bold" }}>
                {`Fill Blank (V2) Block`}
            </div>
        </BlockCard>
    );
};

export default renderCardV2FillBlank;
