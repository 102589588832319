import { BLOCK_TYPES } from "../../common/index";
const { SHORT_ANSWER_BLOCK } = BLOCK_TYPES;

const SHORT_ANSWER_DEFAULT_VALUE = {
    type: SHORT_ANSWER_BLOCK,
    data: {
        [SHORT_ANSWER_BLOCK]: {
            text: [],
            solution: [],
            limit: 250,
            hasLimit: false,
            description: [],
            isRequired: false,
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
};

export default SHORT_ANSWER_DEFAULT_VALUE;
