export default {
  PAGINATION_LIMIT: 12,

  // PRIMARY_CLR: '',
  NO_IMG:
    "http://scanivalve.com/wp-content/plugins/lightbox/images/No-image-found.jpg",

  // // batch query config
  // batch: {
  //   enable: false,
  //   max: 5,
  //   batchInterval: 20,
  //   batchDebounce: true
  // },

  // Select field
  EMPTY_OPTION: {
    label: "None",
    value: "",
  },

  // Env variables
  // NODE_ENV: process.env.NODE_ENV,

  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || "",
  HASURA_ADMIN_SECRET: process.env.REACT_APP_HASURA_ADMIN_SECRET || "",

  // CLOUDINARY_CLOUDNAME: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
  // CLOUDINARY_PRESET: process.env.REACT_APP_CLOUDINARY_PRESET
};
