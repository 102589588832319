import {
    Field,
    RenderDynamicField,
    RenderField,
    RenderSelect,
    RenderUpload,
} from "src/components";
import FormikPersist from "src/components/FormikPersist";
import { compose } from "src/helpers/index";
import { Button, Col, Form, message, Row, Select } from "antd";
import { FieldArray, withFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";

import { withUpdateSetMetadataTags } from "src/modules/set/operations";

const WorksheetForm = (props: any) => {
    const {
        onFinishFailed,
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetValues,
        toggleResetValues,
        resetForm,
        disableStatus = false,
    } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (resetValues) {
            resetForm();
            toggleResetValues(false);
        }
    }, [resetValues]);

    const fieldProps = {
        title: {
            label: "Metadata Title",
            placeholder: "Metadata Title",
            name: "metadata_tags.title",
            icon: "FontSizeOutlined",
            component: RenderField,
            type: "text",
            value: values.metadata_tags?.title,
            rules: [{ required: true }],
            path: "worksheet/",
        },
        description: {
            label: "Metadata Description",
            placeholder: "Metadata Description",
            name: "metadata_tags.description",
            icon: "FontSizeOutlined",
            component: RenderField,
            type: "text",
            value: values.metadata_tags?.description,
            rules: [{ required: true }],
            path: "worksheet/",
        },
        image: {
            label: "Metadata Image",
            placeholder: "Metadata Image",
            name: "metadata_tags.image",
            icon: "FontSizeOutlined",
            component: RenderUpload,
            type: "textarea",
            value: values.metadata_tags?.image,
            rules: [{ required: true }],
            path: "worksheet/",
            setLoad: setLoading,
        },
    };

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field {...fieldProps.title} />
                </Col>

                <Col lg={24} md={24}>
                    <Field {...fieldProps.description} />
                </Col>

                <Col lg={24} md={24}>
                    <Field {...fieldProps.image} />
                </Col>

                <Col lg={12} md={24}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting || loading}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export const FormSchema = yup.object().shape({
    metadata_tags: yup.object().shape({
        title: yup.string().required("This field is required"),
        description: yup.string().required("This field is required"),
        image: yup.string().required("This field is required"),
    }),
});

const WorksheetFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { worksheet } = props;
        return {
            ...(worksheet?.id ? { id: worksheet.id } : {}),
            metadata_tags: worksheet?.metadata_tags || {
                title: worksheet?.title || "",
                description: worksheet?.title || "",
                image: worksheet?.banner || "",
            },
        };
    },
    validationSchema: FormSchema,

    async handleSubmit(
        values,
        { props: { onFinish, worksheet, updateSet } }: any,
    ) {
        try {
            await onFinish(values);
        } catch {}

        if (worksheet?.stats?.set_id) {
            console.log(`update set metadata tags json`, updateSet);
            updateSet({
                id: worksheet?.stats?.set_id,
                metadata_tags: values.metadata_tags,
            });
        }
        message.success("Successfully saved");
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const CustomForm = WorksheetFormWithFormik(WorksheetForm);

export default compose(withUpdateSetMetadataTags)(CustomForm);
