import { gql } from "@apollo/client";export default gql`query content($id: Int!) {
    content: collection_content_by_pk(id: $id) {
        id
        index
        duration
        type
        user_id
    }
}
`;
