import React, { useState } from "react";
import { Link } from "src/components";
import { Button, Col, Divider, message, Row, Space, Spin, Tag } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";

import DeleteView from "src/modules/book/components/DeleteView";
import {
    EditIcon,
    ViewIcon,
    CopyIcon,
    RenderTable,
    Layout,
    MetaTag,
} from "src/components";
import { Can } from "src/services/casl";

import { FilterComponent } from "src/modules/book/components";

const ListView: React.FC = (props: any) => {
    const { loading, books, bookAggregate, loadBooksData } = props;

    const columns = [
        {
            title: "Id",
            key: "id",
            render: (text, record) => record?.id,
            fixed: "left",
        },
        {
            title: "Title",
            key: "title",
            render: (text, record) => record?.title,
            fixed: "left",
            width: 200,
        },
        {
            title: "Subject",
            key: "subject",
            render: (text, record) => record?.other?.subject,
        },
        {
            title: "Class",
            key: "class",
            render: (text, record) => record?.other?.class,
        },
        {
            title: "Tags",
            key: "tags",
            render: (text, record) => {
                console.log(record?.tags);
                return record?.tags?.map(({ tag: { name, value } }: any) => (
                    <Tag>{`${value} (${name})`}</Tag>
                ));
            },
        },
        {
            title: "Rating",
            key: "rating",
            render: (text, record) => record?.other?.rating,
        },
        {
            title: "Attempts",
            key: "attemtps",
            render: (text, record) => record?.other?.attempts,
        },

        {
            title: "Status",
            key: "status",
            render: (text, record) => record?.status,
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            fixed: "right",
            width: 200,
            render: (text, record) => (
                <Space size="middle">
                    {/* <CopyIcon
                        isButton={true}
                        onClick={() => {
                            message.info(`Copied book link to clipboard!`);
                            navigator.clipboard.writeText(
                                `${process.env.REACT_APP_CLIENT_BASE}/book/${record.id}`,
                            );
                        }}
                    />
                    <Divider /> */}
                    <Link href={`/book/chapter/${record?.id}`}>
                        <ViewIcon isButton={true} />
                    </Link>
                    <Divider />

                    <Can I="edit" a={"book"} passThrough>
                        {(allowed) => (
                            <Link href={`/book/update/${record?.id}`}>
                                <EditIcon isButton={true} disabled={!allowed} />
                            </Link>
                        )}
                    </Can>

                    <Divider />
                    <Can I="delete" a={"book"} passThrough>
                        {(allowed) => (
                            <DeleteView
                                id={record?.id}
                                isButton={true}
                                disabled={!allowed}
                                record={record}
                            />
                        )}
                    </Can>
                </Space>
            ),
        },
    ];
    return (
        <Layout titles={[{ name: "Books", link: "/book" }]}>
            <MetaTag title="Books" />
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Books List</h1>
                </Col>
                <Col>
                    <Can I="create" a={"book"} passThrough>
                        {(allowed) => (
                            <Link href={"/book/create"}>
                                <Button
                                    type="primary"
                                    icon={<AppstoreAddOutlined />}
                                    disabled={!allowed}
                                >
                                    Add
                                </Button>
                            </Link>
                        )}
                    </Can>
                </Col>
            </Row>
            <hr />

            <FilterComponent isBook={false} {...props} />
            <hr />

            <Spin spinning={loading}>
                {Boolean(books?.length) && (
                    <RenderTable
                        loading={loading}
                        columns={columns}
                        data={books || []}
                        loadData={loadBooksData}
                        aggregate={bookAggregate?.aggregate?.count}
                    />
                )}
            </Spin>
        </Layout>
    );
};

export default ListView;
