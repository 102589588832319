import { Col, Row, Spin } from "antd";
import React from "react";
const LoadingPage = ({ redirect = false }) => {
    return (
        <Row justify="center" align="middle" style={{ height: "100vh" }}>
            <Col>
                <Spin size="large" />
            </Col>
            {redirect && <Col>redirecting to login...</Col>}
        </Row>
    );
};
export default LoadingPage;
