import { isString } from "lodash";

export function convertHexToRGBA(hex, opacity) {
    hex = hex.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r},${g},${b},${opacity})`;
}

export function generateBorderConfig(obj = {}) {
    const style = {
        // Common styles
        borderColor: obj.color,
        borderRadius: obj.radius + "px",
        borderStyle: obj.style?.toLowerCase() ?? "none", // Converts "DASHED" or "SOLID" to lowercase
        borderWidth: obj.width + "px",
        borderOpacity: obj.opacity,
    };

    // Additional style for DASHED
    if (obj.style === "DASHED") {
        style.borderStyle = "dashed";
        style.borderImageSlice = 1;
    }

    if (obj?.selective) {
        style.borderTop = obj?.top?.enabled
            ? `${
                  obj?.top?.width
              }px ${obj?.top?.style?.toLowerCase()} ${convertHexToRGBA(
                  obj?.top?.color ?? "#000",
                  obj?.top?.opacity ?? 1,
              )}`
            : "none";
        style.borderBottom = obj?.bottom?.enabled
            ? `${
                  obj?.bottom?.width
              }px ${obj?.bottom?.style?.toLowerCase()} ${convertHexToRGBA(
                  obj?.bottom?.color ?? "#000",
                  obj?.bottom?.opacity ?? 1,
              )}`
            : "none";
        style.borderLeft = obj?.left?.enabled
            ? `${
                  obj?.left?.width
              }px ${obj?.left?.style?.toLowerCase()} ${convertHexToRGBA(
                  obj?.left?.color ?? "#000",
                  obj?.left?.opacity ?? 1,
              )}`
            : "none";
        style.borderRight = obj?.right?.enabled
            ? `${
                  obj?.right?.width
              }px ${obj?.right?.style?.toLowerCase()} ${convertHexToRGBA(
                  obj?.right?.color ?? "#000",
                  obj?.right?.opacity ?? 1,
              )}`
            : "none";
        style.borderOpacity = 1;
    }

    return style;
}

export const getBackgroundColor = (bgIndex) => {
    const idx = parseInt(bgIndex);
    switch (idx) {
        case 1:
            // default
            return "#F9F8F8";
        case 2:
            // correct
            return "#D9F8D9";
        case 3:
            // incorrect
            return "#FFD9D9";
        default:
            return "#F9F8F8";
    }
};

function rgbaToHex(color) {
    // console.log("🚀 ~ file: layout.js:35 ~ rgbaToHex ~ color:", color);
    if (["string", "number"].includes(typeof color)) return color;

    let r = Math.round(color.r * 255);
    let g = Math.round(color.g * 255);
    let b = Math.round(color.b * 255);
    let a = color.a && Math.round(color.a * 255);

    r = r.toString(16).padStart(2, "0");
    g = g.toString(16).padStart(2, "0");
    b = b.toString(16).padStart(2, "0");
    a = a && a.toString(16).padStart(2, "0");

    return `${r}${g}${b}${a || ""}`;
}

export const parseColor = (color) => {
    if (!color) {
        return 0xffffff;
    }
    return rgbaToHex(color);
};

export const getBoxShadow = (highlightData = {}) => {
    const highlight = isString(highlightData)
        ? JSON.parse(highlightData)
        : highlightData;
    let { color, offset, radius: blur, spread = 1, visible = 0 } = highlight;
    if (visible == "0") {
        return "none";
    }
    color = parseColor(color);
    return `${offset.x}px ${offset.y}px ${4}px ${2}px ${color}`;
};
