import { gql } from "@apollo/client";export default gql`query order($id: Int!) {
  order: subscription_order_by_pk(id: $id) {
    id
    human_readable_id
    created_at
    breakdown
    amount
    other
    payment_id
    status
    updated_at
    user_id
  }
}
`;
