import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, Select, Input } from "antd";
import _ from "lodash";

import { SlateEditor } from "src/components/plate";
import { MenuOutlined } from "@ant-design/icons";
import { SortableHandle } from "react-sortable-hoc";
import { AudioPicker, DurationPicker } from "../../common";
// import VideoField from "../../common/VideoField";
import { BLOCK_TYPES } from "../../common";
import FeedbackField from "../../common/FeedBackField";
import CustomSelectField from "../../common/CustomSelectField";
import { COLOR_COMBINE_TYPES, getColorCombine } from "./defaultValue";
import PreviewBlocksField from "../../common/PreviewBlocksField";
import { BlockPicker } from "react-color";

const { COLOR_COMBINE_BLOCK } = BLOCK_TYPES;

export const DragHandle = SortableHandle(() => <MenuOutlined />);

const Editor = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    currentBlock,
    isNewBlock,
    setIsNewBlock,
    worksheet,
    mentionsList = [],
    hasMentions,
    // toggleShouldBlockUI,
    disableWrite,
    hasPreviewBlocks,
    isReadOnlyMode,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [COLOR_COMBINE_BLOCK]: {
                type,
                text,
                // top_input,
                // bottom_input,
                containers = [],
                correct_answer,
                // layout,
            },
            other: { global_feedback = null, description = [] } = {},
        },
    } = block;
    const [currentEditor, setCurrentEditor] = useState(``);
    const [isGbFeedbackModalOpen, setIsGbFeedbackModalOpen] = useState(false);
    const [curCell, setCurCell] = useState({});

    // useEffect(() => {
    //     setCurrentEditor(`${id || tmpId}_text`);
    // }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor !== "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const showGbFeedbackModal = () => {
        setCurCell(
            global_feedback || {
                index: 0,
                values: [],
            },
        );
        setIsGbFeedbackModalOpen(true);
    };

    const closeForm = () => {
        setCurCell({});
        setIsGbFeedbackModalOpen(false);
    };

    const feedbackFieldProps = {
        isModalOpen: isGbFeedbackModalOpen,
        isGlobal: true,
        closeForm,
        feedback: curCell,
        onSave: (data: any) => {
            const obj = {
                ...block,
                data: {
                    ...block.data,
                    other: { ...block.data.other, global_feedback: data },
                },
            };
            setBlock(obj);
        },
        curIndex: 0,
        isEditor,
        setIsEditor,
        hasMentions,
        mentionsList,
        disableWrite,
        isReadOnlyMode,
    };

    const renderInputFields = (inputType: string) => {
        const dataType = block?.data[COLOR_COMBINE_BLOCK][inputType];
        return (
            <>
                <div
                    style={{
                        padding: "10px",
                        marginBottom: "10px",
                        border: "1px solid #666",
                    }}
                >
                    <h3>Select Color</h3>
                    <Form.Item label={"Pick Color"}>
                        <BlockPicker
                            colors={[]}
                            color={dataType?.color}
                            onChangeComplete={(color: any) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        COLOR_COMBINE_BLOCK,
                                        inputType,
                                        "color",
                                    ],
                                    color.hex,
                                );
                                setBlock(tmpBlock);
                            }}
                            triangle="hide"
                        />
                    </Form.Item>
                    Or
                    <Form.Item label={"Choose from Variable"}>
                        <CustomSelectField
                            disabled={disableWrite}
                            value={dataType?.color}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        COLOR_COMBINE_BLOCK,
                                        inputType,
                                        "color",
                                    ],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            options={mentionsList.map(
                                (item: { text: any }) => ({
                                    value: `@@${item.text}@@`,
                                    label: item.text,
                                }),
                            )}
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    label={
                        ["no_container", "side_container"].includes(type)
                            ? "Bottles Count"
                            : "Initial Count"
                    }
                >
                    <CustomSelectField
                        type="number"
                        disabled={disableWrite}
                        value={dataType?.initial_count}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                [
                                    "data",
                                    COLOR_COMBINE_BLOCK,
                                    inputType,
                                    "initial_count",
                                ],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        options={mentionsList.map((item: { text: any }) => ({
                            value: `@@${item.text}@@`,
                            label: item.text,
                        }))}
                    />
                </Form.Item>

                {["one_container", "two_container"].includes(type) && (
                    <>
                        <Form.Item label={"Minimum Count"}>
                            <CustomSelectField
                                type="number"
                                disabled={disableWrite}
                                value={dataType?.minCount}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            COLOR_COMBINE_BLOCK,
                                            inputType,
                                            "minCount",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                options={mentionsList.map(
                                    (item: { text: any }) => ({
                                        value: `@@${item.text}@@`,
                                        label: item.text,
                                    }),
                                )}
                            />
                        </Form.Item>

                        <Form.Item label={"Maximum Count"}>
                            <CustomSelectField
                                type="number"
                                disabled={disableWrite}
                                value={dataType?.maxCount}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            COLOR_COMBINE_BLOCK,
                                            inputType,
                                            "maxCount",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                options={mentionsList.map(
                                    (item: { text: any }) => ({
                                        value: `@@${item.text}@@`,
                                        label: item.text,
                                    }),
                                )}
                            />
                        </Form.Item>
                    </>
                )}

                {["no_container", "side_container"].includes(type) && (
                    <>
                        <h3>Description</h3>
                        <SlateEditor
                            required={false}
                            disabled={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`${id || tmpId}_${inputType}`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        COLOR_COMBINE_BLOCK,
                                        inputType,
                                        "description",
                                    ],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            value={dataType?.description}
                            isFocused={
                                currentEditor === `${id || tmpId}_${inputType}`
                            }
                            setEditor={(id: string) => setCurrentEditor(id)}
                        />
                    </>
                )}
            </>
        );
    };

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    return (
        <>
            <FeedbackField {...feedbackFieldProps} />
            <Form.Item label={"Color Combine Type"}>
                <Select
                    placeholder="Select Color Combine Type"
                    disabled={type}
                    value={type}
                    onChange={(value) => {
                        let newBlock = {};
                        switch (value) {
                            case "no_container":
                                newBlock = getColorCombine({
                                    type: value,
                                    top_input: {
                                        is_fixed: true,
                                    },
                                    bottom_input: {
                                        is_fixed: true,
                                    },
                                });
                                break;
                            case "one_container":
                                newBlock = getColorCombine({
                                    type: value,
                                    containers: [
                                        {
                                            is_fixed: false,
                                            description: [],
                                        },
                                    ],
                                });
                                break;
                            case "side_container":
                                newBlock = getColorCombine({
                                    type: value,
                                    top_input: {
                                        is_fixed: true,
                                    },
                                    bottom_input: {
                                        is_fixed: true,
                                    },
                                    containers: [
                                        {
                                            is_fixed: false,
                                            description: [],
                                        },
                                    ],
                                    layout: "column",
                                });
                                break;
                            case "two_container":
                                newBlock = getColorCombine({
                                    type: value,
                                    containers: [
                                        {
                                            is_fixed: true,
                                            description: [],
                                        },
                                        {
                                            is_fixed: false,
                                            description: [],
                                        },
                                    ],
                                });
                                break;

                            default:
                                break;
                        }
                        setBlock({ ...block, ...newBlock });
                    }}
                    options={COLOR_COMBINE_TYPES}
                />
            </Form.Item>
            {type && (
                <div>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Preview Area
                    </h3>
                    <Row
                        style={{
                            width: "100%",
                            border: "1px dashed #808080",
                            margin: "10px auto",
                            padding: "20px",
                        }}
                    >
                        {hasPreviewBlocks && (
                            <PreviewBlocksField
                                fixed_blocks={
                                    block?.data?.other?.fixed_blocks || []
                                }
                                disableWrite={disableWrite}
                                updateFixedBlocks={(data: any) => {
                                    const obj = {
                                        ...block,
                                        data: {
                                            ...block.data,
                                            other: {
                                                ...block.data.other,
                                                fixed_blocks: data,
                                            },
                                        },
                                    };
                                    setBlock(obj);
                                }}
                            />
                        )}
                        <Col span={22}>
                            <h3>Question</h3>
                            <SlateEditor
                                disabled={disableWrite}
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                id={`${id || tmpId}_text`}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "other", "description"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                value={description}
                                required={false}
                                isFocused={
                                    currentEditor === `${id || tmpId}_text`
                                }
                                setEditor={(id: string) => setCurrentEditor(id)}
                            />
                        </Col>

                        <Col span={24}>
                            {[
                                "personalized_learning",
                                "personalized_learning_v2",
                            ].includes(worksheet?.type) && (
                                <div
                                    style={{
                                        margin: "20px auto",
                                    }}
                                >
                                    <h3>Background Audio</h3>
                                    <AudioPicker
                                        disabled={isReadOnlyMode}
                                        block={block}
                                        setBlock={setBlock}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>

                    <h3
                        style={{
                            fontWeight: "bold",
                            margin: "10px 0",
                        }}
                    >
                        Play Area
                    </h3>
                    <div
                        style={{
                            width: "100%",
                            border: "1px dashed #808080",
                            margin: "10px auto",
                            padding: "20px",
                        }}
                    >
                        <Row justify="space-between" gutter={20}>
                            <>
                                {["no_container", "side_container"].includes(
                                    type,
                                ) && (
                                    <Col span={22}>
                                        <h3>Input Text</h3>
                                        <SlateEditor
                                            required={false}
                                            disabled={disableWrite}
                                            hasMentions={hasMentions}
                                            mentionsList={mentionsList}
                                            id={`${id || tmpId}_input`}
                                            onChange={(value: any) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "data",
                                                        COLOR_COMBINE_BLOCK,
                                                        "text",
                                                    ],
                                                    value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                            value={text}
                                            isFocused={
                                                currentEditor ===
                                                `${id || tmpId}_input`
                                            }
                                            setEditor={(id: string) =>
                                                setCurrentEditor(id)
                                            }
                                        />
                                    </Col>
                                )}
                                <Col span={24}>
                                    <h2>Bottles details</h2>
                                </Col>
                                <Col span={12}>
                                    <h3>Bottle count details - 1</h3>
                                    {renderInputFields("top_input")}
                                </Col>
                                <Col span={12}>
                                    <h3>Bottle count details - 2</h3>
                                    {renderInputFields("bottom_input")}
                                </Col>
                            </>

                            {["two_container"].includes(type) && (
                                <>
                                    <Col
                                        span={24}
                                        style={{ marginTop: "20px" }}
                                    >
                                        <h2>Containers details</h2>
                                    </Col>

                                    {containers.map(
                                        (container: any, idx: number) => (
                                            <Col span={12}>
                                                <h3>Container - {idx + 1}</h3>{" "}
                                                <strong>
                                                    {container?.is_fixed
                                                        ? "Fixed Container"
                                                        : "Input Container"}
                                                </strong>
                                                <h4>Description</h4>
                                                <SlateEditor
                                                    required={false}
                                                    disabled={disableWrite}
                                                    hasMentions={hasMentions}
                                                    mentionsList={mentionsList}
                                                    id={`${
                                                        id || tmpId
                                                    }_${idx}_container`}
                                                    onChange={(value: any) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(block);
                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [
                                                                "data",
                                                                COLOR_COMBINE_BLOCK,
                                                                "containers",
                                                                idx,
                                                                "description",
                                                            ],
                                                            value,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                    value={
                                                        container?.description
                                                    }
                                                    isFocused={
                                                        currentEditor ===
                                                        `${
                                                            id || tmpId
                                                        }_${idx}_container`
                                                    }
                                                    setEditor={(id: string) =>
                                                        setCurrentEditor(id)
                                                    }
                                                />
                                            </Col>
                                        ),
                                    )}
                                    <Col span={24}>
                                        <h3>Correct Answer</h3>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item label={"Color 1 Count"}>
                                            <CustomSelectField
                                                type="number"
                                                disabled={disableWrite}
                                                value={
                                                    correct_answer
                                                        ? correct_answer[0]
                                                        : 0
                                                }
                                                onChange={(value: any) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "data",
                                                            COLOR_COMBINE_BLOCK,
                                                            "correct_answer",
                                                            0,
                                                        ],
                                                        value,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                                options={mentionsList.map(
                                                    (item: { text: any }) => ({
                                                        value: `@@${item.text}@@`,
                                                        label: item.text,
                                                    }),
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={"Color 2 Count"}>
                                            <CustomSelectField
                                                type="number"
                                                disabled={disableWrite}
                                                value={
                                                    correct_answer
                                                        ? correct_answer[1]
                                                        : 0
                                                }
                                                onChange={(value: any) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "data",
                                                            COLOR_COMBINE_BLOCK,
                                                            "correct_answer",
                                                            1,
                                                        ],
                                                        value,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                                options={mentionsList.map(
                                                    (item: { text: any }) => ({
                                                        value: `@@${item.text}@@`,
                                                        label: item.text,
                                                    }),
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>

                        {/* <Form.Item label="Layout">
                        <Select
                            value={layout || null}
                            style={{ width: 120 }}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", COLOR_COMBINE_BLOCK, "layout"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        >
                            <Option value={"column"}>{"Column"}</Option>
                            <Option value={"row"}>{"Row"}</Option>
                        </Select>
                    </Form.Item> */}

                        {["two_container"].includes(type) && (
                            <div style={{ marginTop: "20px" }}>
                                <Button onClick={() => showGbFeedbackModal()}>
                                    {global_feedback &&
                                    global_feedback.values?.length > 0
                                        ? "Edit"
                                        : "Add"}{" "}
                                    Global Feedback
                                </Button>
                            </div>
                        )}

                        {[
                            "timed",
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    margin: "20px auto",
                                }}
                            >
                                <h3>Duration</h3>
                                <DurationPicker
                                    onChange={onDurationChange}
                                    initialValue={
                                        block?.data?.other?.duration || 0
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Editor;
