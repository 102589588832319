import {
    AppstoreAddOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    MenuOutlined,
} from "@ant-design/icons";
import { EditIcon, ViewIcon } from "src/components";
import DeleteView from "src/modules/book/components/DeleteView";
import { Can } from "src/services/casl";
import { Button, Col, Divider, Row, Space, Spin, Table } from "antd";
import { arrayMoveImmutable } from "array-move";
import { Link } from "src/components";
import { useRouter } from "src/helpers";
import React, { useEffect, useState } from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";

const ListView: React.FC = (props: any) => {
    const {
        loadingTopics,
        topics,
        updateChapterOther,
        chapter_id,
        chapter_title,
        setGroupTitle,
        setGroupId,
        groupId,
        redirect_to,
        book_id,
    } = props;
    const { query, isReady, push, asPath: pathname } = useRouter();
    const [dataSource, setDataSource] = useState([]);
    const { tabType } = query;

    // const [totalSetCount, setTotalSetCount] = useState(0)
    useEffect(() => {
        if (topics.length) {
            // console.log(topics.reduce((acc, cur) => acc+cur.collections.length, 0));
            // setTotalSetCount(topics.reduce((acc, cur) => acc+cur.collections.length, 0)) ;
            const data = topics
                .map((t, index) => {
                    console.log(index, t.other?.order);
                    return {
                        ...t,
                        index,
                        order: t.other?.order || -1,
                    };
                })
                .sort((a, b) => {
                    if (a.status === b.status) return a.order - b.order;
                    else return a.status > b.status ? 1 : -1;
                });
            setDataSource(data);

            if (groupId) setGroupId(groupId);
            else {
                if (setGroupId) setGroupId(data[0].id);
                if (setGroupTitle) setGroupTitle(data[0].title);
            }
        } else {
            setGroupId(null);
            setGroupTitle("");
        }
    }, [topics]);

    // console.log({loading})

    if (!isReady) return <div>Loading...</div>;

    const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
    ));

    const SortableItem = SortableElement((props) => (
        <tr
            {...props}
            style={{
                backgroundColor: props.selected ? "lightcyan" : "",
            }}
        />
    ));
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(
                [].concat(dataSource),
                oldIndex,
                newIndex,
            ).filter((el) => !!el);

            newData.map((d, index) => {
                if (d.order !== index + 1) {
                    console.log("Updating order: ", d.id, index + 1);
                    // for topic set_id will actually be topic_id
                    const url_split =
                        typeof window !== "undefined" &&
                        window.location.pathname.split("/");
                    const filter_type =
                        url_split && url_split[url_split.length - 2];
                    updateChapterOther({
                        other: {
                            order: index + 1,
                        },
                        id: d.id,
                    });

                    newData[index].order = index;
                }
            });

            setDataSource(newData);
        }
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helper="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(
            (x) => x.index === restProps["data-row-key"],
        );

        const id = dataSource.filter(
            (d) => d.index === restProps["data-row-key"],
        )[0]?.id;
        return (
            <SortableItem
                index={index}
                {...restProps}
                selected={id == groupId}
            />
        );
    };

    const columns = [
        {
            title: "Sort",
            dataIndex: "sort",
            width: 30,
            className: "drag-visible",
            render: () => <DragHandle />,
        },
        // {
        //     title: "Id",
        //     key: "id",
        //     className: "drag-visible",

        //     render: (text, record) => record?.id,
        //     fixed: "left",
        // },
        {
            title: "Title",
            key: "title",
            render: (text, record) => record?.title,
            // fixed: "left",
            width: 200,
        },
        {
            title: "Status",
            key: "status",
            render: (text, record) =>
                record?.status == "active" ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                ),
        },
        // {
        //     title: "Description",
        //     key: "description",
        //     render: (text, record) => record?.description,
        // },

        // {
        //     title: "Status",
        //     key: "status",
        //     render: (text, record) => record?.status,
        // },
        {
            title: "Action",
            key: "action",
            // align: "right",
            // fixed: "right",
            width: 200,
            render: (text, record) => (
                <Space size="small">
                    {/* <CopyIcon
                        isButton={true}
                        onClick={() => {
                            message.info(`Copied topic link to clipboard!`);
                            navigator.clipboard.writeText(
                                `${process.env.REACT_APP_CLIENT_BASE}/topic/${record.id}`,
                            );
                        }}
                    />
                    <Divider /> */}
                    <ViewIcon
                        isButton={true}
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            });

                            setGroupId(record.id);
                            push(
                                {
                                    pathname: `/tab/chapter/${book_id}`,
                                    query: {
                                        chapterId: chapter_id,
                                        groupId: record.id,
                                        tabType: tabType,
                                    },
                                },
                                undefined,
                                { shallow: true },
                            );
                        }}
                    />

                    <Divider />

                    <Can I="edit" a={"topic"} passThrough>
                        {(allowed) => (
                            <Link
                                href={{
                                    pathname: `/tab/topic/update/${record?.id}`,
                                    query: {
                                        redirect_to: encodeURI(redirect_to),
                                    },
                                }}
                            >
                                <EditIcon isButton={true} disabled={!allowed} />
                            </Link>
                        )}
                    </Can>

                    <Divider />
                    <Can I="delete" a={"topic"} passThrough>
                        {(allowed) => (
                            <DeleteView
                                id={record?.id}
                                isButton={true}
                                disabled={!allowed}
                                record={record}
                            />
                        )}
                    </Can>
                </Space>
            ),
        },
    ];
    return (
        <>
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>
                        Topics for {chapter_title || ""} ({dataSource.length})
                    </h1>
                </Col>
                <Col>
                    <Can I="create" a={"tab_topic"} passThrough>
                        {(allowed) => (
                            <Link
                                href={{
                                    pathname: `/tab/topic/create/${chapter_id}`,
                                    query: {
                                        redirect_to: encodeURI(redirect_to),
                                        order: topics.length + 1,
                                    },
                                }}
                            >
                                <Button
                                    type="primary"
                                    icon={<AppstoreAddOutlined />}
                                    disabled={!allowed}
                                >
                                    Add Topic
                                </Button>
                            </Link>
                        )}
                    </Can>
                </Col>
            </Row>
            <hr />

            {/* <FilterComponent isTopic={false} {...props} />
            <hr /> */}

            <Spin spinning={loadingTopics}>
                {Boolean(topics?.length) && (
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={dataSource || []}
                        rowKey="index"
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                    />
                )}
            </Spin>
        </>
    );
};

export default ListView;
