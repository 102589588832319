import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import { withHookForTopic, withUpdateTopic } from "src/modules/book/operations";
import { compose } from "src/helpers";

import EditView from "./component";

const Edit = (props) => {
    const { updateTopic } = props;
    const router = useRouter();

    const onFinish = async (values: any) => {
        await updateTopic(values);
        router.push(`/book/topic/${values.parent_id}`);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <EditView
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            {...props}
        />
    );
};

export default compose(withHookForTopic, withUpdateTopic)(Edit);
