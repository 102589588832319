import { useLazyQuery } from "@apollo/client";
import { compose } from "src/helpers";
import { BOOKS_PARENT_QUERY } from "src/modules/book/graphql";
import React, { useEffect, useState } from "react";
import { ListView } from "./component";

const List: React.FC = (props: any) => {
    const { tabId } = props;
    const [books, setBooks] = useState([]);

    const [getBooks, { loading, refetch, error, data = {} }] = useLazyQuery(
        BOOKS_PARENT_QUERY,

        {
            variables: { parent_id: tabId },
            fetchPolicy: "network-only",
        },
    );

    useEffect(() => {
        const asyncFunc = async () => {
            if (tabId) {
                const { data } = await getBooks();
                if (data.group_tab.length)
                    setBooks(
                        data.group_tab.map(({ book, id, order }: any) => ({
                            ...book,
                            group_tab_map_id: id,
                            order,
                        })),
                    );
                else setBooks([]);
            }
        };
        asyncFunc();
    }, [tabId]);

    useEffect(() => {
        const asyncFunc = async () => {
            if (data?.group_tab?.length) {
                setBooks(
                    data.group_tab.map(({ book, id, order }: any) => ({
                        ...book,
                        group_tab_map_id: id,
                        order,
                    })),
                );
            }
        };
        asyncFunc();
    }, [data]);

    const extraProps = {
        tabId,
        loading,
        books,
        refetch,
    };

    return <ListView {...props} {...extraProps} />;
};

export default compose()(List);
// withStateAndActionsForBook,
// withHookForBooks,
// withHookForAggregateBook,
