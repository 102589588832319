import { gql } from "@apollo/client";export default gql`query TagQuery(
    $limit: Int = 12
    $offset: Int = 0
    $where: tag_bool_exp
) {
  tags: tag(
    limit: $limit
        offset: $offset
        where: $where # order_by: { metadata: { created_at: desc } }
        order_by: { id: desc }
    ) {
    id
    group
    name
    value
  }
}
`;
