import _ from "lodash";
import { renderSettingsMCQ } from "../MCQ";

const renderSettingsCaseStudy = ({ block, setBlock, currentSubBlock }: any) => {
    const {
        id,
        tmpId,
        data: {
            case_study: { text },
        },
        children,
    } = block;

    const renderSettingsCaseStudy = () => (
        <div>{renderSettingsMCQ({ block, setBlock })}</div>
    );

    return (
        <div>
            {currentSubBlock == null
                ? renderSettingsCaseStudy()
                : renderSettingsMCQ({
                      block: children[currentSubBlock],
                      setBlock: (subBlock: any) => {
                          let tmpBlock = _.cloneDeep(block);

                          tmpBlock = _.set(
                              tmpBlock,
                              ["children", currentSubBlock],
                              subBlock,
                          );
                          setBlock(tmpBlock);
                      },
                  })}
        </div>
    );
};

export default renderSettingsCaseStudy;
