import { gql } from "@apollo/client";export default gql`mutation updateMeme(
    $id: Int!
    $asset_url: String = ""
    $group: String = ""
    $status: String = ""
    $user_id: Int
) {
    updateMeme: update_meme_by_pk(
        pk_columns: { id: $id }
        _set: {
            asset_url: $asset_url
            group: $group
            status: $status
            user_id: $user_id
        }
    ) {
        id
        asset_url
        group
        status
        user_id
    }
}
`;
