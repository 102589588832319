import { isEditorEmpty } from "../../common";
import { BLOCK_TYPES } from "../../common/index";
import { isValidCallbackV2Stories } from "../V2Stories";
const { FIGMA_BLOCK } = BLOCK_TYPES;

const isValidCallbackFigma = ({ block }: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            [FIGMA_BLOCK]: {
                figma_data: { url, file_key, node_id },
            },
            other,
        },
    } = block;

    const description = other?.description || [];
    const stories = other?.stories || [];
    return {
        isValid:
            url &&
            file_key &&
            node_id &&
            !isEditorEmpty(description) &&
            stories.every(
                (figSt: {
                    type: string;
                    data: { children: string | any[] };
                }) => {
                    return isValidCallbackV2Stories({ block: figSt }).isValid;
                },
            ),
        error: <></>,
    };
};

export default isValidCallbackFigma;
