import { FunctionComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";

import {
    COLLECTIONS_QUERY,
    CREATE_COLLECTION_MUTATION,
    CREATE_SET_COLLECTION_MUTATION,
    UPDATE_COLLECTION,
    DELETE_COLLECTION_MUTATION,
    UPDATE_SET_COLLECTION_MUTATION,
    UPDATE_GROUP_COLLECTION_MUTATION,
    DELETE_SET_COLLECTION_MUTATION,
    INSERT_BATCH_COLLECTION,
    ADD_COLLECTION_TAG_MAP,
    DELETE_COLLECTION_TAG_MAP,
    UPDATE_USER_OTHER,
} from "../graphql";
import { set } from "lodash";
import { captureException } from "@sentry/react";

// Query
export const withCollections = (Component: FunctionComponent) =>
    graphql(COLLECTIONS_QUERY, {
        options: ({ where, pagination }) => {
            // remove interactive_rapid_quiz
            const modWhere = set(
                where,
                ["type", "_neq"],
                "interactive_rapid_quiz",
            );

            return {
                variables: {
                    where: modWhere,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                collections,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadQuestionsData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            collections: [
                                ...previousResult.collections,
                                ...fetchMoreResult.collections,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                collections,
                subscribeToMore,
                updateQuery,
                loadQuestionsData,
                refetchCollections: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreateCollection = (Component: FunctionComponent) =>
    graphql(CREATE_COLLECTION_MUTATION, {
        props: ({ mutate }) => ({
            createCollection: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createCollection: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { insert_collection_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createCollection: {
                                object,
                                __typename: "INSERT_COLLECTION_ONE",
                            },
                        },
                    });
                    return insert_collection_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withInsertBatchCollection = (Component: FunctionComponent) =>
    graphql(INSERT_BATCH_COLLECTION, {
        props: ({ mutate }) => ({
            insertBatchCollection: async (object: Object) => {
                console.log(
                    "🚀 ~ file: Operations.ts ~ line 60 ~ createCollection: ~ object",
                    object,
                );
                try {
                    const {
                        data: { insert_collection },
                    } = await mutate({
                        variables: object,
                        optimisticResponse: {
                            __typename: "Mutation",
                            createCollection: {
                                object,
                                __typename: "INSERT_COLLECTION",
                            },
                        },
                    });
                    return insert_collection;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withCreateSetCollection = (Component: FunctionComponent) =>
    graphql(CREATE_SET_COLLECTION_MUTATION, {
        props: ({ mutate }) => ({
            createSetCollection: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createSetCollection: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { insert_set_collection_one },
                    } = await mutate({
                        variables: object,
                        optimisticResponse: {
                            __typename: "Mutation",
                            createCollection: {
                                object,
                                __typename: "INSERT_SET_COLLECTION_ONE",
                            },
                        },
                    });
                    return insert_set_collection_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateSetCollection = (Component: FunctionComponent) =>
    graphql(UPDATE_SET_COLLECTION_MUTATION, {
        props: ({ mutate }) => ({
            updateSetCollection: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createSetCollection: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { update_set_collection_by_pk },
                    } = await mutate({
                        variables: object,
                        // optimisticResponse: {
                        //     __typename: "Mutation",
                        //     createCollection: {
                        //         object,
                        //         __typename: "INSERT_SET_COLLECTION_ONE",
                        //     },
                        // },
                    });
                    return update_set_collection_by_pk;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateGroupCollection = (Component: FunctionComponent) =>
    graphql(UPDATE_GROUP_COLLECTION_MUTATION, {
        props: ({ mutate }) => ({
            updateGroupCollection: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createSetCollection: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { update_group_collection_by_pk },
                    } = await mutate({
                        variables: object,
                        // optimisticResponse: {
                        //     __typename: "Mutation",
                        //     createCollection: {
                        //         object,
                        //         __typename: "INSERT_SET_COLLECTION_ONE",
                        //     },
                        // },
                    });
                    return update_group_collection_by_pk;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withDeleteSetCollection = (Component: FunctionComponent) =>
    graphql(DELETE_SET_COLLECTION_MUTATION, {
        props: ({ mutate }) => ({
            deleteSetCollection: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createSetCollection: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { delete_set_collection },
                    } = await mutate({
                        variables: object,
                        // optimisticResponse: {
                        //     __typename: "Mutation",
                        //     createCollection: {
                        //         object,
                        //         __typename: "INSERT_SET_COLLECTION_ONE",
                        //     },
                        // },
                    });
                    return delete_set_collection;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateCollection = (Component: FunctionComponent) =>
    graphql(UPDATE_COLLECTION, {
        props: ({ mutate }) => ({
            updateCollection: (object: Object) => {
                mutate({
                    variables: { ...object },
                    optimisticResponse: {
                        updateCollection: {
                            ...object,
                            __typename: "collection",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withDeleteCollection = (Component: FunctionComponent) =>
    graphql(DELETE_COLLECTION_MUTATION, {
        props: ({ mutate }) => ({
            deleteCollection: ({ id, submissionIds }) => {
                mutate({
                    variables: { id, submissionIds },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteCollection: {
                            id,
                            __typename: "DELETE_COLLECTION_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withAddCollectionTagMap = (Component: FunctionComponent) =>
    graphql(ADD_COLLECTION_TAG_MAP, {
        props: ({ mutate }) => ({
            addCollectionTagMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     updateBook: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withDeleteCollectionTagMap = (Component: FunctionComponent) =>
    graphql(DELETE_COLLECTION_TAG_MAP, {
        props: ({ mutate }) => ({
            deleteCollectionTagMap: (object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     __typename: "Mutation",
                    //     deleteBook: {
                    //         id,
                    //         __typename: "DELETE_GROUP_BY_PK",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withUpdateUserOther = (Component: FunctionComponent) =>
    graphql(UPDATE_USER_OTHER, {
        props: ({ mutate }) => ({
            updateUserOther: (object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     __typename: "Mutation",
                    //     deleteBook: {
                    //         id,
                    //         __typename: "DELETE_GROUP_BY_PK",
                    //     },
                    // },
                });
            },
        }),
    })(Component);
