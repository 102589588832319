import { gql } from "@apollo/client";export default gql`query worksheets($limit: Int = 100, $offset: Int = 0, $group_id: Int = 10) {
    worksheets: worksheet_worksheet(
        limit: $limit
        offset: $offset
        where: { groups: { group_id: { _eq: $group_id } } }
    ) {
        id
        status
        banner
        created_at
        title
        type
        updated_at
        class
        subject
        slug
        groups(where: { group_id: { _eq: $group_id } }) {
            id
            group_id
            order
        }
        user_id
        user {
            id
            name
            email
        }
        tags {
            id
            tag_id
            tag {
                id
                name
                value
            }
        }
    }
}
`;
