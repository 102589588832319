import { FunctionComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";

import {
    TABS_QUERY,
    CREATE_TAB_MUTATION,
    UPDATE_TAB,
    DELETE_TAB_MUTATION,
    ADD_GROUP_TAB_MAP,
    DELETE_GROUP_TAB_MAP,
    UPDATE_GROUP_TAB_MAP,
} from "../graphql";
import { captureException } from "@sentry/react";

// Query
export const withTabs = (Component: FunctionComponent) =>
    graphql(TABS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                tabs,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadQuestionsData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            tabs: [
                                ...previousResult.tabs,
                                ...fetchMoreResult.tabs,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                tabs,
                subscribeToMore,
                updateQuery,
                loadQuestionsData,
                refetchTabs: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreateTab = (Component: FunctionComponent) =>
    graphql(CREATE_TAB_MUTATION, {
        props: ({ mutate }) => ({
            createTab: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createTab: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { insert_tab_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createTab: {
                                object,
                                __typename: "INSERT_TAB_ONE",
                            },
                        },
                    });
                    return insert_tab_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateTab = (Component: FunctionComponent) =>
    graphql(UPDATE_TAB, {
        props: ({ mutate }) => ({
            updateTab: ({ id, object }: Object) => {
                mutate({
                    variables: { id, object },
                    optimisticResponse: {
                        updateTab: {
                            ...object,
                            __typename: "tab",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withDeleteTab = (Component: FunctionComponent) =>
    graphql(DELETE_TAB_MUTATION, {
        props: ({ mutate }) => ({
            deleteTab: (id: string) => {
                mutate({
                    variables: { id },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteTab: {
                            id,
                            __typename: "DELETE_TAB_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withAddGroupTabMap = (Component: FunctionComponent) =>
    graphql(ADD_GROUP_TAB_MAP, {
        props: ({ mutate }) => ({
            addGroupTabMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     updateBook: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withDeleteGroupTabMap = (Component: FunctionComponent) =>
    graphql(DELETE_GROUP_TAB_MAP, {
        props: ({ mutate }) => ({
            deleteGroupTabMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                });
            },
        }),
    })(Component);

export const withUpdateGroupTabMap = (Component: FunctionComponent) =>
    graphql(UPDATE_GROUP_TAB_MAP, {
        props: ({ mutate }) => ({
            updateGroupTabMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     updateBook: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);
