import React from "react";
import { Row, Col, Form, Button, Divider } from "antd";
import { withFormik, FieldArray } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderCheckBox,
    RenderField,
    RenderAutoComplete,
    RenderTags,
    RenderDynamicField,
    RenderSelect,
    RenderUpload,
} from "src/components";
import { Select } from "antd";
const Option = Select.Option;

const StudentForm = (props) => {
    const { onFinishFailed, values, handleSubmit, isSubmitting } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Student Mobile"
                        placeholder="Student Mobile"
                        name={"mobile"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.mobile}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Student Attempts"
                        placeholder="Student Attempts"
                        name={"attempts"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.attempts}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Student Class"
                        placeholder="Student Class"
                        name={"class"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.class}
                        rules={[{ required: true }]}
                    />
                </Col>
                <Col lg={24} md={24}>
                    <Field
                        label="Student Streak"
                        placeholder="Student Streak"
                        name={"streak"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.streak}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Student Trophies"
                        placeholder="Student Trophies"
                        name={"trophies"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.trophies}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Student Points"
                        placeholder="Student Points"
                        name={"points"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.points}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const StudentFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { student } = props;

        return {
            ...(student?.id ? { id: student.id } : {}),
            mobile: student?.mobile || "",
            attempts: student?.attempts || 0,
            class: student?.class || 0,
            streak: student?.streak || 0,
            trophies: student?.trophies || 0,
            points: student?.points || 0,
        };
    },

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

export default StudentFormWithFormik(StudentForm);
