import { BLOCK_TYPES } from "../../common/index";
const { MCQ_BLOCK } = BLOCK_TYPES;

const MCQ_DEFAULT_VALUE = {
    type: MCQ_BLOCK,
    data: {
        mcq: {
            text: [],
            options: [[]],
            correct_options: [],
            solution: [],
            multiSelect: {
                isMultiSelect: false,
                type: "unlimited", // range, unlimited, exact
                range: [1, 1],
                exact: 1,
            },
        },
        other: {
            duration: 0,
            audio: "",
            difficulty_level: "medium",
            solution_video: "",
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
};

export default MCQ_DEFAULT_VALUE;
