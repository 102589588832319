import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, List, Space, Switch, message } from "antd";
import _ from "lodash";
import { FC, useEffect, useState } from "react";

const PreviewBlocksField: FC<any> = (props) => {
    const { fixed_blocks = [], updateFixedBlocks, disableWrite } = props;
    const [tempVal, setTempVal] = useState("");

    useEffect(() => setTempVal(""), [fixed_blocks]);

    return (
        <Col md={24} lg={16}>
            <List
                style={{
                    marginBottom: "20px",
                }}
                size="large"
                header={
                    <h3
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        Fixed Blocks
                    </h3>
                }
                footer={
                    <Form.Item help="Only add Id of primary variants which come before it.">
                        <Space.Compact style={{ width: "100%" }}>
                            <Input
                                allowClear
                                value={tempVal}
                                disabled={disableWrite}
                                placeholder="Add Preview Block Id"
                                onChange={(e) => setTempVal(e.target.value)}
                            />
                            <Button
                                disabled={disableWrite}
                                onClick={() => {
                                    const tempList = [...fixed_blocks];

                                    const isPresent = tempList.some(
                                        (item) => item.block_id === tempVal,
                                    );

                                    if (isPresent) {
                                        message.error("Block Id already added");
                                        return;
                                    }

                                    tempList.push({
                                        block_id: tempVal,
                                    });
                                    updateFixedBlocks(tempList);
                                }}
                                type="primary"
                            >
                                Submit
                            </Button>
                        </Space.Compact>
                    </Form.Item>
                }
                bordered
                dataSource={fixed_blocks}
                renderItem={(
                    { block_id, is_interactive }: any,
                    index: number,
                ) => (
                    <List.Item
                        key={index}
                        actions={[
                            <Button
                                icon={
                                    <DeleteOutlined style={{ color: "red" }} />
                                }
                                shape="circle"
                                danger
                                disabled={disableWrite}
                                onClick={() => {
                                    const tmpin = [...fixed_blocks];
                                    _.pullAt(tmpin, index);
                                    updateFixedBlocks(tmpin);
                                }}
                            />,
                        ]}
                    >
                        <List.Item.Meta
                            title={block_id}
                            description={
                                <Form.Item label={"Is interactive?"}>
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={is_interactive}
                                        onChange={(value) => {
                                            let tempList =
                                                _.cloneDeep(fixed_blocks);
                                            tempList[index].is_interactive =
                                                value;
                                            updateFixedBlocks(tempList);
                                        }}
                                    />
                                </Form.Item>
                            }
                        />
                    </List.Item>
                )}
            />
        </Col>
    );
};

export default PreviewBlocksField;
