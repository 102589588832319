import React from "react";
import { Card, Col, Input, Row } from "antd";

import { CollectionsField } from ".";
import { RenderField, Field } from "src/components";
import resetFilterHook from "src/helpers/resetFilterHook";
import { get } from "lodash";

const FilterComponent = (props) => {
    const {
        where = {},
        onStateReset,
        isCollection = false,
        onNameChange,
    } = props;
    resetFilterHook(onStateReset);

    return (
        <Card bodyStyle={{ padding: "8px" }}>
            <Row gutter={[24, 0]} align="middle">
                {isCollection && (
                    <Col lg={8} md={24}>
                        <CollectionsField />
                    </Col>
                )}
                <Col lg={8} md={24}>
                    <Field
                        label="Collection title search"
                        placeholder="Collection title"
                        icon="FontSizeOutlined"
                        component={RenderField}
                        onChange={(e) => onNameChange(e)}
                        value={get(where, "title._ilike")?.replace(/%/g, "")}
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default FilterComponent;
