import { BLOCK_TYPES } from "../../common/index";
const { V2_AVATAR_BLOCK } = BLOCK_TYPES;

const isValidCallback = ({ block }: any) => {
    const {
        data: {
            [V2_AVATAR_BLOCK]: { value },
        },
    } = block;
    return {
        isValid: Boolean(value.trim()),
        error: <div />,
    };
};

export default isValidCallback;
