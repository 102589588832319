import { isEditorEmpty } from "../../common/index";
import { isValidCallbackMCQ } from "../MCQ";

const isValidCallbackCaseStudy = ({
    block,
    currentSubBlock,
    currentBlock,
}: any) => {
    if (!block || currentSubBlock > (block?.data?.children?.length || 0))
        return {
            isValid: true,
            error: "",
        };

    const {
        data: {
            case_study: { text },
        },
        children,
    } = block;

    return currentSubBlock == null || block.type == "case_study"
        ? {
              isValid: !isEditorEmpty(text),
              error: (
                  //   <Alert
                  //       message="Validation Error"
                  //       description={`Text cannot be empty`}
                  //       type="error"
                  //       showIcon
                  //   />
                  <></>
              ),
          }
        : isValidCallbackMCQ({ block: children[currentSubBlock] });
};

export default isValidCallbackCaseStudy;
