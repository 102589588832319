import { gql } from "@apollo/client";export default gql`query chapter($id: Int!) {
    chapter: group_by_pk(id: $id) {
        id
        description
        cover
        other
        title
        type
        status
        parent_id
    }
}
`;
