import { gql } from "@apollo/client";export default gql`mutation createStudent($object: student_insert_input = {}) {
    insert_student_one(
        object: $object
        on_conflict: { constraint: student_pkey, update_columns: id }
    ) {
        id
        mobile
        class
        streak
        trophies
        points
        attempts
    }
}
`;
