import { SlateEditor } from "src/components/plate";
import { Button, Col, Form, List, Row, Select, Switch, message } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { AudioPicker, BLOCK_TYPES, DurationPicker } from "../../common/index";
import GridField from "../../common/GridField";
import { CheckOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { CustomKeyboardEditor } from "src/components";
import { CROSSWORD_CONDITION, DIGIT_POSITION_STATES } from "./defaultValue";
import FeedbackField from "../../common/FeedBackField";
import {
    DRAG_LIST_OPERATIONS,
    NUMBER_SYSTEM_LIST_OPERATIONS,
    TAP_LIST_OPERATIONS,
} from "src/helpers/blockOperation";
import PreviewBlocksField from "../../common/PreviewBlocksField";
import { renderer } from "../../helpers";
import ConditionField from "../../common/ConditionField";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const {
    CROSSWORD_BLOCK,
    COMPARE_ARRANGE_LIST_BLOCK,
    COMPARE_DIGITS_BLOCK,
    SCRATCH_ARRANGE_LIST_BLOCK,
    DRAG_ARRANGE_LIST_BLOCK,
    INPUT_AND_FORM_BLOCK,
    INPUT_EXPAND_NUMBER_BLOCK,
    V2_DRAG_AND_SWIPE_BLOCK,
    V2_INPUT_AND_FORM_BLOCK,
} = BLOCK_TYPES;

const getComputeOptions = (blockType: any) => {
    switch (blockType) {
        case COMPARE_ARRANGE_LIST_BLOCK:
        case COMPARE_DIGITS_BLOCK:
        case SCRATCH_ARRANGE_LIST_BLOCK:
            return TAP_LIST_OPERATIONS;
        case DRAG_ARRANGE_LIST_BLOCK:
            return DRAG_LIST_OPERATIONS;
        case V2_DRAG_AND_SWIPE_BLOCK:
            return NUMBER_SYSTEM_LIST_OPERATIONS;
        default:
            return [];
    }
};

const DragHandle = SortableHandle(() => <MenuOutlined />);

const Editor = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    setIsNewBlock,
    isNewBlock,
    worksheet,

    showDuration = true,
    showBackgroundAudio = true,
    blockType = CROSSWORD_BLOCK,
    showFeedback = false,
    hasMentions,
    mentionsList = [],
    disableWrite,
    hasPreviewBlocks,
    isReadOnlyMode,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [blockType]: {
                custom_keyboard = [],
                options = [],
                compute_function,
                result_type,
                question_audio,
                correct_answer = [],
                conditions = [],
                is_label_visible,
                grid = [],
            },
            other: {
                description = [],
                block_input = {},
                global_feedback,
                digit_position_state,
                grid_feedbacks = [],
                feedbacks = [],
            },
        },
    } = block;

    const [currentEditor, setCurrentEditor] = useState("");
    const [loading, setLoading] = useState(false);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [curCell, setCurCell] = useState({});

    const [isConditionModalOpen, setIsConditionModalOpen] = useState(false);
    const [curCondIndex, setCurCondIndex] = useState(-1);

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    useEffect(() => {
        setLoading(true);
        // if (!disableWrite) setCurrentEditor(`${id || tmpId}_description`);
        setTimeout(() => setLoading(false), 50);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    useEffect(() => {
        if (
            [
                COMPARE_ARRANGE_LIST_BLOCK,
                COMPARE_DIGITS_BLOCK,
                SCRATCH_ARRANGE_LIST_BLOCK,
                DRAG_ARRANGE_LIST_BLOCK,
            ].includes(blockType) &&
            options.some((row: any) => row?.type === "input_variable") &&
            !compute_function?.is_enabled
        ) {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(
                tmpBlock,
                ["data", blockType, "compute_function", "is_enabled"],
                true,
            );
            setBlock(tmpBlock);
        }
    }, [options]);

    const renderCorrectOption = () => (
        <>
            <Form.Item
                label={
                    <h3 style={{ fontWeight: "bold" }}>
                        Correct{" "}
                        {[DRAG_ARRANGE_LIST_BLOCK].includes(blockType)
                            ? "Order"
                            : "Answer"}
                    </h3>
                }
                rules={[{ required: true }]}
                validateStatus={!correct_answer?.length ? "error" : ""}
                help={!correct_answer?.length ? "Field cannot be empty" : ""}
            >
                {[V2_DRAG_AND_SWIPE_BLOCK].includes(blockType) ? (
                    <Select
                        disabled={disableWrite}
                        value={correct_answer}
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Correct Answers"
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", blockType, "correct_answer"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        options={[]}
                        open={false}
                    />
                ) : (
                    <Select
                        disabled={disableWrite}
                        value={correct_answer}
                        style={{ width: 120 }}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", blockType, "correct_answer"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        mode={`multiple`}
                    >
                        {options.map((__: any, idx: number) => {
                            return (
                                <Select.Option value={idx + 1} key={idx}>
                                    {idx + 1}
                                </Select.Option>
                            );
                        })}
                    </Select>
                )}
            </Form.Item>
        </>
    );

    const showFeedbackModal = () => {
        setCurCell(
            global_feedback || {
                index: 0,
                values: [],
            },
        );
        setIsFeedbackModalOpen(true);
    };

    const showCondModal = (index: number) => {
        setCurCondIndex(index);
        setIsConditionModalOpen(true);
    };

    const closeForm = () => {
        setCurCondIndex(-1);
        setIsFeedbackModalOpen(false);
        setIsConditionModalOpen(false);
    };

    const SortableItem = SortableElement(({ value: idx, child: item }: any) => {
        const variablesArray = item?.computation
            ?.map((v) => v?.variables)
            ?.flat();

        const variablesSet = new Set(variablesArray);

        const joinedVariables = Array.from(variablesSet).join(", ");

        return (
            <List.Item
                actions={[
                    <Button onClick={() => showCondModal(idx)}>Edit</Button>,
                    <Button
                        danger
                        disabled={disableWrite}
                        onClick={() => {
                            if (conditions?.length === 1) {
                                message.warn(`Need at least 1 condition`);
                                return;
                            }

                            let tmpBlock = _.cloneDeep(block);

                            const tmpConditions = _.cloneDeep(conditions);

                            tmpConditions?.splice(idx, 1);

                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", blockType, "conditions"],
                                tmpConditions,
                            );
                            setBlock(tmpBlock);
                        }}
                    >
                        Delete
                    </Button>,
                ]}
                style={{ display: "flex" }}
            >
                <List.Item.Meta
                    avatar={
                        <>
                            {!disableWrite && <DragHandle />}
                            <div
                                style={{
                                    marginLeft: "5px",
                                    borderRadius: "50%",
                                    width: "24px",
                                    height: "24px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: "#4EDEB2",
                                    color: "#333333",
                                    display: "inline-flex",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                }}
                            >
                                {idx + 1}
                            </div>
                        </>
                    }
                    title={renderer(item?.description)}
                    description={
                        !item?.computation?.length ? (
                            <div style={{ color: "red" }}>
                                Update Computation Function
                            </div>
                        ) : (
                            <></>
                        )
                    }
                />
                {joinedVariables}
            </List.Item>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <List
                header={
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Conditions
                    </h3>
                }
                itemLayout="horizontal"
                loadMore={
                    <div
                        style={{
                            textAlign: "center",
                            marginTop: 12,
                            height: 32,
                            lineHeight: "32px",
                        }}
                    >
                        <Button
                            disabled={disableWrite}
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(block);
                                const conditionsCount = conditions?.length;

                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        blockType,
                                        "conditions",
                                        conditionsCount,
                                    ],
                                    CROSSWORD_CONDITION(conditionsCount + 1),
                                );
                                setBlock(tmpBlock);

                                setTimeout(
                                    () => showCondModal(conditionsCount),
                                    500,
                                );
                            }}
                        >
                            Add new Condition
                        </Button>
                    </div>
                }
                dataSource={items}
                renderItem={(item: any, idx: number) => {
                    return (
                        <SortableItem
                            key={`item-${idx}`}
                            index={idx}
                            value={idx}
                            child={item}
                        />
                    );
                }}
            />
        );
    });

    const feedbackFieldProps = {
        isModalOpen: isFeedbackModalOpen,
        isGlobal: true,
        closeForm,
        feedback: curCell,
        onSave: (data: any) => {
            const obj = {
                ...block,
                data: {
                    ...block.data,
                    other: { ...block.data.other, global_feedback: data },
                },
            };
            setBlock(obj);
        },
        curIndex: 0,
        isEditor,
        setIsEditor,
        hasMentions,
        mentionsList,
        disableWrite,
        isReadOnlyMode,
    };

    const conditionsFieldProps = {
        isModalOpen: isConditionModalOpen,
        closeForm,
        condition: conditions[curCondIndex],
        onSave: (data: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(
                tmpBlock,
                ["data", blockType, "conditions", curCondIndex],
                data,
            );
            setBlock(tmpBlock);
        },
        curIndex: curCondIndex,
        isEditor,
        setIsEditor,
        hasMentions,
        mentionsList,
        disableWrite,
        variables:
            grid
                ?.flat()
                .filter((cell: { type: string }) => cell.type === "variable")
                .map(
                    (cell: { content: { value: any } }) => cell?.content?.value,
                ) || [],
        isReadOnlyMode,
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            conditions,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(
            tmpBlock,
            ["data", blockType, "conditions"],
            tmpChildren,
        );
        setBlock(tmpBlock);
    };

    return (
        <div>
            <FeedbackField {...feedbackFieldProps} />
            <ConditionField {...conditionsFieldProps} />
            <h3
                style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                }}
            >
                Preview Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                {hasPreviewBlocks && (
                    <PreviewBlocksField
                        disableWrite={disableWrite}
                        fixed_blocks={block?.data?.other?.fixed_blocks || []}
                        updateFixedBlocks={(data: any) => {
                            const obj = {
                                ...block,
                                data: {
                                    ...block.data,
                                    other: {
                                        ...block.data.other,
                                        fixed_blocks: data,
                                    },
                                },
                            };
                            setBlock(obj);
                        }}
                    />
                )}

                <Col span={22}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Question
                    </h3>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_description`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "other", "description"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={
                            currentEditor === `${id || tmpId}_description`
                        }
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={description}
                        placeholder={"Type your description here..."}
                    />
                </Col>

                {[CROSSWORD_BLOCK].includes(blockType) && (
                    <Col span={24}>
                        <SortableList
                            items={conditions}
                            onSortEnd={onSortEnd}
                            axis="y"
                            useDragHandle
                        />
                    </Col>
                )}

                <Col span={24}>
                    {showBackgroundAudio &&
                        [
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <h3>Background Audio</h3>
                                <AudioPicker
                                    disabled={isReadOnlyMode}
                                    block={block}
                                    setBlock={setBlock}
                                />
                            </div>
                        )}
                </Col>
            </Row>

            <h3
                style={{
                    fontWeight: "bold",
                    margin: "10px 0",
                }}
            >
                Play Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                {hasMentions && mentionsList?.length > 0 && (
                    <>
                        <Col span={12}>
                            <Form.Item label="Use Input from variable?">
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={block_input?.is_enabled}
                                    onChange={(value) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "data",
                                                "other",
                                                "block_input",
                                                "is_enabled",
                                            ],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        {block_input?.is_enabled && (
                            <Col span={12}>
                                <Form.Item
                                    label="Input Variable"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    validateStatus={
                                        !block_input?.value ? "error" : ""
                                    }
                                    help={
                                        !block_input?.value
                                            ? "Field cannot be empty"
                                            : ""
                                    }
                                >
                                    <Select
                                        disabled={disableWrite}
                                        defaultValue={block_input?.value}
                                        style={{
                                            width: 200,
                                            marginBottom: "20px",
                                        }}
                                        placeholder="Input Variable"
                                        onChange={(value: any) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    "other",
                                                    "block_input",
                                                    "value",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                        options={mentionsList.map(
                                            (item: { text: any }) => ({
                                                value: item.text,
                                                label: item.text,
                                            }),
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </>
                )}

                {[V2_INPUT_AND_FORM_BLOCK].includes(blockType) && (
                    <Col span={24}>
                        <div
                            style={{
                                marginTop: "20px",
                            }}
                        >
                            <h3>Question Audio</h3>
                            <AudioPicker
                                disabled={isReadOnlyMode}
                                block={{
                                    data: {
                                        other: {
                                            audio: question_audio,
                                        },
                                    },
                                }}
                                setBlockCustom={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", blockType, "question_audio"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </div>
                    </Col>
                )}

                <Col span={24}>
                    {!loading && (
                        <GridField
                            disableWrite={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            blockType={blockType}
                            crossword={block.data[blockType]}
                            setCrossword={(
                                value: any,
                                feedbacks = null,
                                { grid_feedbacks = null } = {},
                            ) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", blockType],
                                    value,
                                );

                                if (feedbacks !== null) {
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "other", "feedbacks"],
                                        feedbacks,
                                    );
                                }

                                if (grid_feedbacks !== null) {
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "other", "grid_feedbacks"],
                                        grid_feedbacks,
                                    );
                                }

                                setBlock(tmpBlock);
                            }}
                            grid_feedbacks={grid_feedbacks}
                            feedbacks={feedbacks || []}
                            setFeedback={(value: any, key = "feedbacks") => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "other", key],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            isEditor={isEditor}
                            setIsEditor={setIsEditor}
                            showFeedback={showFeedback}
                            block_input={block_input}
                            isReadOnlyMode={isReadOnlyMode}
                        />
                    )}

                    <Row>
                        {[
                            COMPARE_ARRANGE_LIST_BLOCK,
                            COMPARE_DIGITS_BLOCK,
                            SCRATCH_ARRANGE_LIST_BLOCK,
                            DRAG_ARRANGE_LIST_BLOCK,
                            V2_DRAG_AND_SWIPE_BLOCK,
                        ].includes(blockType) && (
                            <>
                                <Col span={24}>
                                    <Form.Item label="Use Compute Function">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                            checked={
                                                compute_function?.is_enabled ||
                                                options.some(
                                                    (row: any) =>
                                                        row?.type ===
                                                        "input_variable",
                                                )
                                            }
                                            disabled={
                                                disableWrite ||
                                                options.some(
                                                    (row: any) =>
                                                        row?.type ===
                                                        "input_variable",
                                                )
                                            }
                                            onChange={(value) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "data",
                                                        blockType,
                                                        "compute_function",
                                                        "is_enabled",
                                                    ],
                                                    value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    {compute_function?.is_enabled ||
                                    options.some(
                                        (row: any) =>
                                            row?.type === "input_variable",
                                    ) ? (
                                        <>
                                            <Form.Item
                                                label={
                                                    <h3
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Compute Function
                                                    </h3>
                                                }
                                                rules={[{ required: true }]}
                                                validateStatus={
                                                    !compute_function?.value
                                                        ? "error"
                                                        : ""
                                                }
                                                help={
                                                    !compute_function?.value
                                                        ? "Field cannot be empty"
                                                        : ""
                                                }
                                            >
                                                <Select
                                                    disabled={disableWrite}
                                                    value={
                                                        compute_function?.value
                                                    }
                                                    style={{
                                                        width: 120,
                                                        marginBottom: "20px",
                                                    }}
                                                    onChange={(value: any) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(block);
                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [
                                                                "data",
                                                                blockType,
                                                                "compute_function",
                                                            ],
                                                            {
                                                                value,
                                                                is_enabled:
                                                                    true,
                                                            },
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                    options={getComputeOptions(
                                                        blockType,
                                                    )}
                                                />
                                            </Form.Item>
                                        </>
                                    ) : (
                                        renderCorrectOption()
                                    )}
                                </Col>
                            </>
                        )}

                        {[CROSSWORD_BLOCK].includes(blockType) && (
                            <Col span={12}>
                                <Form.Item label="Is Grid label visible?">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={is_label_visible}
                                        onChange={(value) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    blockType,
                                                    "is_label_visible",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        )}

                        <Col
                            span={24}
                            style={{
                                marginTop: "20px",
                            }}
                        >
                            <Form.Item
                                label="Show Digit Positions"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    disabled={disableWrite}
                                    defaultValue={digit_position_state}
                                    style={{
                                        width: 200,
                                        marginBottom: "20px",
                                    }}
                                    placeholder="Show Digit Positions"
                                    onChange={(value: any) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "data",
                                                "other",
                                                "digit_position_state",
                                            ],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    options={DIGIT_POSITION_STATES}
                                />
                                {" (One, Tenth, Hundredth, etc)"}
                            </Form.Item>
                        </Col>
                    </Row>

                    {[
                        INPUT_AND_FORM_BLOCK,
                        INPUT_EXPAND_NUMBER_BLOCK,
                        V2_INPUT_AND_FORM_BLOCK,
                    ].includes(blockType) && (
                        <>
                            <Col span={12}>
                                <Form.Item label="Custom Keyboard">
                                    <CustomKeyboardEditor
                                        disabled={disableWrite}
                                        value={custom_keyboard}
                                        onOk={(data: any) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    blockType,
                                                    "custom_keyboard",
                                                ],
                                                data,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    )}

                    {[INPUT_AND_FORM_BLOCK].includes(blockType) && (
                        <>
                            <Col span={12}>
                                <Form.Item label="Show Result">
                                    <Select
                                        disabled={disableWrite}
                                        defaultValue={result_type}
                                        style={{
                                            marginBottom: "20px",
                                        }}
                                        placeholder="Show Result"
                                        onChange={(value: any) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    blockType,
                                                    "result_type",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                        options={[
                                            {
                                                value: "field",
                                                label: "Field",
                                            },
                                            {
                                                value: "question",
                                                label: "Question",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    )}

                    <Col span={24} style={{ marginTop: "20px" }}>
                        <Button onClick={() => showFeedbackModal()}>
                            {global_feedback &&
                            global_feedback.values?.length > 0
                                ? "Edit"
                                : "Add"}{" "}
                            Global Feedback
                        </Button>
                    </Col>

                    {showDuration &&
                        [
                            "timed",
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    margin: "20px 0",
                                }}
                            >
                                <h3>Duration</h3>
                                <DurationPicker
                                    disabled={disableWrite}
                                    onChange={onDurationChange}
                                    initialValue={
                                        block?.data?.other?.duration || 0
                                    }
                                />
                            </div>
                        )}
                </Col>
            </Row>
        </div>
    );
};

export default Editor;
