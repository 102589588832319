import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";

const renderCard = (props: any) => {
    return (
        <BlockCard {...props}>
            <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                Input And Form Block
            </div>
        </BlockCard>
    );
};

export default renderCard;
