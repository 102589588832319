import { CopyOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Radio, Result, Tooltip, message } from "antd";
import axios from "axios";
import { useRouter } from "src/helpers";
import { useState } from "react";
import { captureException } from "@sentry/react";

export const SelectSheetsModal = (props: any) => {
    const {
        selectSheetsFlow,
        toggleSelectSheetsFlow,
        worksheet,
        setGcpSpreadsheetURL,
    } = props;
    const [isNew, toggleNew] = useState(true);
    const [successFlow, toggleSucessFlow] = useState(false);
    const [existingResponsesFlow, toggleExistingResponsesFlow] =
        useState(false);

    const [spreadsheetURL, setSpreadsheetURL] = useState("");
    const [sheetTitle, setSheetTitle] = useState("");
    const router = useRouter();

    const handleOk = () => {
        toggleSelectSheetsFlow(false);
    };

    const handleCancel = () => {
        if (successFlow) toggleSelectSheetsFlow(false);
    };

    const afterSync = () => {
        toggleSucessFlow(true);
        message.success(`Synced with Google Sheets!`);

        const removeQueryParam = (param) => {
            const { pathname, query } = router;
            const params = new URLSearchParams(query);
            params.delete(param);
            router.replace({ pathname, query: params.toString() }, undefined, {
                shallow: true,
            });
        };
        removeQueryParam("selectSheetsFlow");
    };

    const syncGoogleSheet = () => {
        const data = {
            spreadsheet_id: spreadsheetURL
                ? spreadsheetURL.split("/").length >= 5
                    ? spreadsheetURL.split("/")[5]
                    : ""
                : "",
            sheet_title:
                sheetTitle ||
                `The Homework App - Submissions for ${worksheet?.title}`,
            worksheet_id: worksheet.id,
        };
        axios
            .post(process.env.REACT_APP_WORKSHEET_SHEET_SYNC_API || "", {
                data,
            })
            .then(function (response) {
                const { sheet_url, responses } = response.data.data;

                setSpreadsheetURL(sheet_url);
                setGcpSpreadsheetURL(sheet_url);

                if (responses < 1) afterSync();
                else toggleExistingResponsesFlow(true);
            })
            .catch(function (error) {
                captureException(error)
                console.log(error);
            });
    };

    const ConnectSheetComponent = () => {
        return (
            <>
                <h3>Your Google Account is successfully Connected!</h3>
                <h4>Where do you want to send your responses?</h4>
                <br />
                <Radio.Group
                    value={isNew ? "new" : "existing"}
                    onChange={(e) => {
                        if (e.target.value === "new") toggleNew(true);
                        else toggleNew(false);
                    }}
                >
                    <Radio.Button value="new">Create New Sheet</Radio.Button>
                    <Radio.Button value="existing">Use Existing</Radio.Button>
                </Radio.Group>
                {!isNew ? (
                    <>
                        <br /> <br />
                        <h5>Use existing spreadsheet</h5>
                        <Input
                            placeholder="copy and paste the spreadsheet link here"
                            onChange={(e) => setSpreadsheetURL(e.target.value)}
                            value={spreadsheetURL}
                        />
                        <br /> <br />
                        <Button
                            onClick={syncGoogleSheet}
                            type={"primary"}
                            disabled={
                                !spreadsheetURL ||
                                !/docs.google.com\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/g.test(
                                    spreadsheetURL,
                                )
                            }
                        >
                            Connect Sheet
                        </Button>
                    </>
                ) : (
                    <>
                        <br /> <br />
                        <h5>Name your spreadsheet</h5>
                        <Input
                            value={
                                sheetTitle ||
                                `The Homework App - Submissions for ${worksheet?.title}`
                            }
                            onChange={(e) => setSheetTitle(e.target.value)}
                        />
                        <br /> <br />
                        <Button onClick={syncGoogleSheet} type={"primary"}>
                            Create Sheet
                        </Button>
                    </>
                )}
            </>
        );
    };

    const SuccessComponent = () => {
        return (
            <>
                <Result
                    status="success"
                    title="Successful!"
                    subTitle="You can now start collecting responses from your students!"
                    extra={[
                        <Input.Group compact>
                            <Input
                                style={{ width: "calc(100% - 50px)" }}
                                defaultValue={spreadsheetURL}
                                readOnly={true}
                            />
                            <Tooltip title="copy set live url">
                                <Button
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            spreadsheetURL,
                                        );
                                        message.info(
                                            `copied link to clipboard!`,
                                        );
                                    }}
                                />
                            </Tooltip>
                        </Input.Group>,
                    ]}
                />
            </>
        );
    };

    const ExistingResponsesComponent = () => {
        const [loading, setLoading] = useState(false);
        const syncSheet = () => {
            setLoading(true);

            axios
                .post(
                    process.env
                        .REACT_APP_WORKSHEET_SHEET_SYNC_EXISTING_RESPONSES_API ||
                    "",
                    {
                        data: {
                            worksheet_id: worksheet.id,
                        },
                    },
                )
                .then(function (response) {
                    const { sheet_url } = response.data.data;

                    setSpreadsheetURL(sheet_url);
                    setGcpSpreadsheetURL(sheet_url);
                    setLoading(false);
                    afterSync();
                })
                .catch(function (error) {
                    captureException(error)
                    console.log(error);
                });
        };

        return (
            <div>
                <h4>Looks like you already collected some responses</h4>
                <h3>Do you want to send them to your spreadsheet?</h3>
                <br />
                <Space wrap>
                    <Button
                        onClick={syncSheet}
                        type={"primary"}
                        loading={loading}
                    >
                        Yes, send Them
                    </Button>
                    <Button onClick={afterSync} type={"dashed"}>
                        No thanks
                    </Button>
                </Space>
            </div>
        );
    };

    return (
        <>
            <Modal
                title="Start collecting responses with google sheets!"
                open={selectSheetsFlow}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                closable={successFlow}
            >
                {successFlow ? (
                    <SuccessComponent />
                ) : existingResponsesFlow ? (
                    <ExistingResponsesComponent />
                ) : (
                    <ConnectSheetComponent />
                )}
            </Modal>
        </>
    );
};
