import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Form, Col, Select, Switch, Button, Space } from "antd";
import _ from "lodash";
import React from "react";
import {
    CustomKeyboardEditor,
    MathRender,
    RenderUpload,
    SlateEditor,
} from "src/components";
import { DEFAULT_CUSTOM_KEYBOARD } from "src/components/CustomKeyboardEditor";
import { replaceCDNUrl } from "../../../../helpers";
import { BlockPicker } from "react-color";

const ComponentPropForm = ({
    getValidateStatus,
    disableWrite,
    data,
    setCurConfig,
    idx,
    v,
    key,
    hasMentions,
    mentionsList,
    currentEditor,
    setCurrentEditor,
}: any) => {
    return (
        <Col span={24}>
            <Form.Item
                label={
                    <>
                        {v}
                        {data[v].type === "JSONSTRING" ? " (JSON)" : ""}
                    </>
                }
                validateStatus={getValidateStatus(data[v], v).error}
                {...([
                    // "RICH_TEXT",
                    "LATEX",
                    // "TEXTAREA",
                    "FILL",
                    "DROPDOWN",
                    "TABLE_DATA",
                    "DROPDOWN_RICH_TEXT_OPTIONS",
                    "MATHQUILL_RICH_TEXT",
                ].includes(data[v]?.type)
                    ? { labelCol: { span: 24 } }
                    : {})}
                help={getValidateStatus(data[v], v).msg}
            >
                {(() => {
                    switch (data[v].type) {
                        case "BOOLEAN":
                            return (
                                <>
                                    {!(
                                        typeof data[v]?.value === "string" &&
                                        data[v]?.value?.includes("@@")
                                    ) && (
                                        <Switch
                                            disabled={disableWrite}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                            checked={Boolean(data[v].value)}
                                            onChange={(value) => {
                                                setCurConfig(value);
                                            }}
                                        />
                                    )}
                                    {/* <Form.Item label="variable">
                                        <Select
                                            value={
                                                typeof data[v]?.value ===
                                                    "string" &&
                                                data[v]?.value?.includes("@@")
                                                    ? data[v]?.value
                                                    : null
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                setCurConfig(val ?? false);
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item> */}
                                </>
                            );

                        case "CUSTOM_KEYBOARD":
                        case "RICH_TEXT_CUSTOM_KEYBOARD":
                            return (
                                <>
                                    {!(
                                        typeof data[v]?.value === "string" &&
                                        data[v]?.value?.includes("@@")
                                    ) && (
                                        <CustomKeyboardEditor
                                            hasLatex={
                                                data[v].type ===
                                                "RICH_TEXT_CUSTOM_KEYBOARD"
                                            }
                                            disabled={disableWrite}
                                            value={
                                                _.isArray(data[v].value)
                                                    ? data[v].value
                                                    : DEFAULT_CUSTOM_KEYBOARD
                                            }
                                            onOk={(data: any) =>
                                                setCurConfig(data)
                                            }
                                        />
                                    )}
                                    <Form.Item label="variable">
                                        <Select
                                            value={
                                                typeof data[v]?.value ===
                                                    "string" &&
                                                data[v]?.value?.includes("@@")
                                                    ? data[v]?.value
                                                    : null
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                setCurConfig(
                                                    val ??
                                                        DEFAULT_CUSTOM_KEYBOARD,
                                                );
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item>
                                </>
                            );
                        case "RICH_TEXT":
                            return v !== "default_value" ? (
                                <>
                                    <SlateEditor
                                        disabled={disableWrite}
                                        hasMentions={hasMentions}
                                        mentionsList={mentionsList}
                                        id={`${idx}_figma_${key}`}
                                        onChange={(value: any) =>
                                            setCurConfig(value)
                                        }
                                        isFocused={
                                            currentEditor ===
                                            `${idx}_figma_${key}`
                                        }
                                        setEditor={(id: string) =>
                                            setCurrentEditor(id)
                                        }
                                        value={data[v].value || ""}
                                        placeholder={"Type here..."}
                                    />
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <Input.TextArea
                                            style={{
                                                width: "60%",
                                                flexShrink: 0,
                                            }}
                                            value={data[v].value}
                                            disabled={disableWrite}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setCurConfig(value);
                                            }}
                                        />
                                        <div
                                            style={{
                                                padding: "10px",
                                                width: "40%",
                                                border: "1px dashed #aaa",
                                            }}
                                        >
                                            <MathRender
                                                src={
                                                    data[v].value
                                                        ? `$${data[v].value}$`
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </div>
                                    <Form.Item label="variable">
                                        <Select
                                            value={
                                                typeof data[v]?.value ===
                                                    "string" &&
                                                data[v]?.value?.includes("@@")
                                                    ? data[v]?.value
                                                    : null
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                setCurConfig(val ?? "");
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item>
                                </>
                            );
                        case "LATEX":
                            return (
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <Input.TextArea
                                            style={{
                                                width: "60%",
                                                flexShrink: 0,
                                            }}
                                            value={data[v].value}
                                            disabled={disableWrite}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setCurConfig(value);
                                            }}
                                        />
                                        <div
                                            style={{
                                                padding: "10px",
                                                width: "40%",
                                                border: "1px dashed #aaa",
                                            }}
                                        >
                                            <MathRender
                                                src={
                                                    data[v].value
                                                        ? `$${data[v].value}$`
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </div>
                                    <Form.Item label="variable">
                                        <Select
                                            value={
                                                typeof data[v]?.value ===
                                                    "string" &&
                                                data[v]?.value?.includes("@@")
                                                    ? data[v]?.value
                                                    : null
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                setCurConfig(val ?? "");
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item>
                                </>
                            );
                        case "FILL":
                            return (
                                <>
                                    <Form.Item label="Background Type">
                                        <Select
                                            disabled={disableWrite}
                                            defaultValue={data[v]?.value?.type}
                                            style={{
                                                width: 200,
                                                marginBottom: "20px",
                                            }}
                                            placeholder="Background Type"
                                            onChange={(value: any) => {
                                                setCurConfig({
                                                    ...data[v]?.value,
                                                    type: value,
                                                });
                                            }}
                                            options={[
                                                {
                                                    value: "SOLID",
                                                    label: "Solid Color",
                                                },
                                                {
                                                    value: "IMAGE",
                                                    label: "Image",
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                    {data[v].value?.type === "SOLID" && (
                                        <Form.Item label="Color">
                                            <BlockPicker
                                                colors={[]}
                                                color={data[v].value?.color}
                                                onChangeComplete={(
                                                    color: any,
                                                ) => {
                                                    const value = color.hex;
                                                    setCurConfig({
                                                        ...data[v]?.value,
                                                        color: value,
                                                    });
                                                }}
                                                triangle="hide"
                                            />
                                        </Form.Item>
                                    )}
                                    {["IMAGE", "GIF"].includes(
                                        data[v].value?.type,
                                    ) && (
                                        <>
                                            <Form.Item label="Asset">
                                                <RenderUpload
                                                    singleUpload={true}
                                                    disabled={disableWrite}
                                                    addExtension={true}
                                                    path="home-explore/document/"
                                                    onChangeCustom={(e) => {
                                                        const value =
                                                            replaceCDNUrl(
                                                                e.target.value,
                                                                e.target.bucket,
                                                            );

                                                        setCurConfig({
                                                            ...data[v]?.value,
                                                            asset: value,
                                                        });
                                                    }}
                                                    label="upload the file"
                                                    value={data[v].value?.asset}
                                                />
                                                <Input
                                                    placeholder="Add value here"
                                                    value={data[v].value?.asset}
                                                    onChange={(e) => {
                                                        const value =
                                                            e.target.value;
                                                        setCurConfig({
                                                            ...data[v]?.value,
                                                            asset: value,
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                        </>
                                    )}
                                </>
                            );
                        case "OPTIONS":
                            return (
                                <Select
                                    disabled={disableWrite}
                                    defaultValue={data[v]?.value}
                                    style={{
                                        width: 200,
                                        marginBottom: "20px",
                                    }}
                                    placeholder="Background Type"
                                    onChange={(value: any) => {
                                        setCurConfig(value);
                                    }}
                                    options={[
                                        {
                                            label: "Options",
                                            options: data[v]?.options,
                                        },
                                        {
                                            label: "Variables",
                                            options: mentionsList?.map((v) => ({
                                                label: v.text,
                                                value: `@@${v.text}@@`,
                                            })),
                                        },
                                    ]}
                                />
                            );
                        case "DROPDOWN_RICH_TEXT_OPTIONS":
                            return (
                                <>
                                    {!(
                                        typeof data[v]?.value === "string" &&
                                        data[v]?.value?.includes("@@")
                                    ) && (
                                        <>
                                            {(
                                                (_.isString(data[v]?.value)
                                                    ? JSON.parse(data[v]?.value)
                                                    : data[v]?.value) || []
                                            ).map(
                                                (
                                                    dropOp: {
                                                        label: any;
                                                        value: string;
                                                    },
                                                    idx: number,
                                                ) => (
                                                    <Form.Item
                                                        label={`Option ${
                                                            idx + 1
                                                        }`}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            gap: "20px",
                                                        }}
                                                    >
                                                        <SlateEditor
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            hasMentions={
                                                                hasMentions
                                                            }
                                                            mentionsList={
                                                                mentionsList
                                                            }
                                                            id={`${idx}_drop_label`}
                                                            onChange={(
                                                                value: any,
                                                            ) => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        data[v]
                                                                            ?.value,
                                                                    );

                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            idx,
                                                                            "label",
                                                                        ],
                                                                        value,
                                                                    );
                                                                setCurConfig(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                            isFocused={
                                                                currentEditor ===
                                                                `${idx}_drop_label`
                                                            }
                                                            setEditor={(
                                                                id: string,
                                                            ) =>
                                                                setCurrentEditor(
                                                                    id,
                                                                )
                                                            }
                                                            value={
                                                                dropOp?.label
                                                            }
                                                            placeholder={
                                                                "Label"
                                                            }
                                                        />
                                                        <Input
                                                            placeholder="Value"
                                                            value={
                                                                dropOp?.value
                                                            }
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            onChange={(e) => {
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        data[v]
                                                                            ?.value,
                                                                    );
                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            idx,
                                                                            "value",
                                                                        ],
                                                                        value,
                                                                    );
                                                                setCurConfig(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />
                                                        <Button
                                                            ghost
                                                            danger
                                                            type="primary"
                                                            onClick={() => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        data[v]
                                                                            ?.value,
                                                                    );
                                                                _.pullAt(
                                                                    tmpBlock,
                                                                    idx,
                                                                );
                                                                setCurConfig(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Form.Item>
                                                ),
                                            )}
                                            <Button
                                                disabled={disableWrite}
                                                onClick={() => {
                                                    let tmpBlock = _.cloneDeep(
                                                        data[v]?.value,
                                                    );
                                                    if (_.isString(tmpBlock)) {
                                                        tmpBlock = [
                                                            {
                                                                label: "",
                                                                value: "",
                                                            },
                                                        ];
                                                    } else
                                                        tmpBlock.push({
                                                            label: "",
                                                            value: "",
                                                        });
                                                    setCurConfig(tmpBlock);
                                                }}
                                            >
                                                Add Option
                                            </Button>
                                        </>
                                    )}
                                    <Form.Item label="variable">
                                        <Select
                                            value={
                                                typeof data[v]?.value ===
                                                    "string" &&
                                                data[v]?.value?.includes("@@")
                                                    ? data[v]?.value
                                                    : null
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                setCurConfig(val ?? []);
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item>
                                </>
                            );

                        case "DROPDOWN":
                            return (
                                <>
                                    {!(
                                        typeof data[v]?.value === "string" &&
                                        data[v]?.value?.includes("@@")
                                    ) && (
                                        <>
                                            {(
                                                (_.isString(data[v]?.value)
                                                    ? JSON.parse(data[v]?.value)
                                                    : data[v]?.value) || []
                                            ).map(
                                                (
                                                    dropOp: {
                                                        label: string;
                                                        value: string;
                                                    },
                                                    idx: number,
                                                ) => (
                                                    <Form.Item
                                                        label={`Option ${
                                                            idx + 1
                                                        }`}
                                                    >
                                                        <Space.Compact
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Input
                                                                placeholder="Label"
                                                                value={
                                                                    dropOp?.label
                                                                }
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    const value =
                                                                        e.target
                                                                            .value;
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            data[
                                                                                v
                                                                            ]
                                                                                ?.value,
                                                                        );

                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                idx,
                                                                                "label",
                                                                            ],
                                                                            value,
                                                                        );
                                                                    setCurConfig(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                            <Input
                                                                placeholder="Value"
                                                                value={
                                                                    dropOp?.value
                                                                }
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    const value =
                                                                        e.target
                                                                            .value;
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            data[
                                                                                v
                                                                            ]
                                                                                ?.value,
                                                                        );

                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                idx,
                                                                                "value",
                                                                            ],
                                                                            value,
                                                                        );
                                                                    setCurConfig(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                            <Button
                                                                ghost
                                                                danger
                                                                type="primary"
                                                                onClick={() => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            data[
                                                                                v
                                                                            ]
                                                                                ?.value,
                                                                        );
                                                                    _.pullAt(
                                                                        tmpBlock,
                                                                        idx,
                                                                    );
                                                                    setCurConfig(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </Space.Compact>
                                                    </Form.Item>
                                                ),
                                            )}
                                            <Button
                                                disabled={disableWrite}
                                                onClick={() => {
                                                    let tmpBlock = _.cloneDeep(
                                                        data[v]?.value,
                                                    );
                                                    if (_.isString(tmpBlock)) {
                                                        tmpBlock = [
                                                            {
                                                                label: "",
                                                                value: "",
                                                            },
                                                        ];
                                                    } else
                                                        tmpBlock.push({
                                                            label: "",
                                                            value: "",
                                                        });
                                                    setCurConfig(tmpBlock);
                                                }}
                                            >
                                                Add Option
                                            </Button>
                                        </>
                                    )}
                                    <Form.Item label="variable">
                                        <Select
                                            value={
                                                typeof data[v]?.value ===
                                                    "string" &&
                                                data[v]?.value?.includes("@@")
                                                    ? data[v]?.value
                                                    : null
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                setCurConfig(val ?? []);
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item>
                                </>
                            );
                        case "NUMBER":
                            return (
                                <>
                                    {!(
                                        typeof data[v]?.value === "string" &&
                                        data[v]?.value?.includes("@@")
                                    ) && (
                                        <Input
                                            disabled={disableWrite}
                                            type="number"
                                            value={
                                                _.isObject(data[v].value)
                                                    ? JSON.stringify(
                                                          data[v].value,
                                                      )
                                                    : data[v].value
                                            }
                                            placeholder="Type here..."
                                            style={{
                                                width: "100%",
                                            }}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                setCurConfig(value);
                                            }}
                                        />
                                    )}
                                    <Form.Item label="variable">
                                        <Select
                                            value={
                                                typeof data[v]?.value ===
                                                    "string" &&
                                                data[v]?.value?.includes("@@")
                                                    ? data[v]?.value
                                                    : null
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                setCurConfig(val ?? 0);
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item>
                                </>
                            );

                        default:
                            return (
                                <>
                                    <Input.TextArea
                                        disabled={disableWrite}
                                        rows={5}
                                        value={
                                            _.isObject(data[v].value)
                                                ? JSON.stringify(data[v].value)
                                                : data[v].value
                                        }
                                        placeholder="Type here..."
                                        style={{
                                            width: "100%",
                                        }}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            setCurConfig(value);
                                        }}
                                    />
                                    <Form.Item label="variable">
                                        <Select
                                            value={
                                                typeof data[v]?.value ===
                                                    "string" &&
                                                data[v]?.value?.includes("@@")
                                                    ? data[v]?.value
                                                    : null
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                setCurConfig(val ?? "");
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item>
                                </>
                            );
                    }
                })()}
            </Form.Item>
        </Col>
    );
};

export default ComponentPropForm;
