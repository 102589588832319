import { gql } from "@apollo/client";export default gql`query VersionModelMapQuery($model_name: String = "worksheet", $model_id: Int!) {
    versionModelMap: versioning_version_model_map(
        where: {
            model_name: { _eq: $model_name }
            model_id: { _eq: $model_id }
        }
    ) {
        id
        versions(order_by: { index: desc }, limit: 1) {
            id
            index
            data
        }
        versions_aggregate {
            aggregate {
                count
            }
        }
    }
}
`;
