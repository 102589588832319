import { gql } from "@apollo/client";export default gql`mutation createContent($object: collection_content_insert_input = {}) {
    insert_collection_content_one(
        object: $object
        on_conflict: { constraint: collection_content_pkey, update_columns: id }
    ) {
        id
        index
        duration
        type
        user_id
    }
}
`;
