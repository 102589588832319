import { BLOCK_TYPES } from "../../common/index";
import { GRID_INPUT_OPTIONS, PUZZLE_OTHER } from "../Crossword/defaultValue";
const { V2_DRAG_AND_SWIPE_BLOCK } = BLOCK_TYPES;

export const DEFAULT_V2_DRAG_AND_SWIPE_CELL = {
    type: "fillable",

    // optional and can be null
    content: {
        type: "string", // string / asset
        value: "0",
        asset: "",
    },

    is_fixed: true,
};

const V2_DRAG_AND_SWIPE_DEFAULT_VALUE = {
    type: V2_DRAG_AND_SWIPE_BLOCK,
    data: {
        [V2_DRAG_AND_SWIPE_BLOCK]: {
            options: GRID_INPUT_OPTIONS(),
            grid: [[...Array(3)].map(() => DEFAULT_V2_DRAG_AND_SWIPE_CELL)],
            correct_answer: [],
            compute_function: { is_enabled: true, value: "indian" },
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
        other: { ...PUZZLE_OTHER },
    },
};

export default V2_DRAG_AND_SWIPE_DEFAULT_VALUE;
