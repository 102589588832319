import { gql } from "@apollo/client";export default gql`query contents(
    $limit: Int = 12
    $offset: Int = 0
    $where: collection_content_bool_exp # $type: String # $parent_id: uuid
) {
    contents: collection_content(
        limit: $limit
        offset: $offset
        where: $where # order_by: { metadata: { created_at: desc } }
    ) {
        id
        index
        duration
        type
        question_id
        concept_id
        user_id
        submissions {
            submission_question {
                id
            }
        }
        collection {
            sets {
                id
                set_id
            }
        }
    }
}
`;
