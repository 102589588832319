import { BLOCK_TYPES, isEditorEmpty } from "../../common";

const { SHORT_ANSWER_BLOCK } = BLOCK_TYPES;

const isValidCallbackShortAnswer = ({ block }: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            [SHORT_ANSWER_BLOCK]: { text, limit, hasLimit },
        },
    } = block;

    return {
        isValid:
            !isEditorEmpty(text) &&
            (!hasLimit || (hasLimit && limit && limit > 0)),
        error: <></>,
    };
};

export default isValidCallbackShortAnswer;
