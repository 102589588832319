import React, { useMemo } from "react";
import Cell from "./Cell";
import IrregularPolygon from "./drawingComponents/IrregularPolygon";

const CellWrapperComponent = ({
    cell,
    cellContent,
    positionStyles,
    borderCustomizations,
    showHighlight,
    isHovering,
    isPeeked,
    sizeStyles,
    index,
    hideCell,
    mainTableSelectionActive,
    dropArea,
}: any) => {
    const [isClickActive, setIsClickActive] = React.useState(false);

    const { borderProperties1, showBorder1 } = useMemo(() => {
        let stroke = "black";
        let strokeWidth = 3;
        let fill = "none";
        let strokeOpacity = 1;
        let strokeType = "solid";
        let showBorder = false;

        if (cell.border?.enabled) {
            stroke = cell.border.color;
            strokeWidth = cell.border.width;
            strokeOpacity = cell.border.opacity;
            strokeType = cell.border?.style?.toLowerCase() ?? "solid";
            // showBorder = true;
        } else if (borderCustomizations?.enabled) {
            stroke = borderCustomizations.color;
            strokeWidth = borderCustomizations.width;
            strokeOpacity = borderCustomizations.opacity;
            strokeType = borderCustomizations?.style?.toLowerCase() ?? "solid";
            // showBorder = true;
        }

        if (isHovering || isPeeked || isClickActive) {
            if (strokeOpacity !== 1) {
                strokeOpacity = 1;
            }
            if (strokeWidth < 3) {
                strokeWidth = 3;
            }
            strokeType = "solid";
            if (isPeeked || isClickActive) {
                stroke = "#000FFF";
                strokeType = "dashed";
            }
            if (isHovering) {
                stroke = "red";
                strokeType = "dashed";
            }
            showBorder = true;
        }
        if (mainTableSelectionActive) {
            showBorder = false;
        }

        return {
            borderProperties1: {
                stroke,
                strokeWidth,
                fill,
                strokeOpacity,
                strokeType,
            },
            showBorder1: showBorder,
        };
    }, [
        cell,
        borderCustomizations,
        showHighlight,
        isHovering,
        isPeeked,
        isClickActive,
        mainTableSelectionActive,
    ]);
    const { borderProperties, showBorder } = useMemo(() => {
        let stroke = "black";
        let strokeWidth = 3;
        let fill = "none";
        let strokeOpacity = 1;
        let strokeType = "solid";
        let showBorder = false;
        let selective = false;
        let selectiveBorders = {};

        if (cell.border?.enabled) {
            const setBorderProperties = (borderConfig: any) => {
                return {
                    stroke: borderConfig.color,
                    strokeWidth: borderConfig.width,
                    strokeOpacity: borderConfig.opacity,
                    strokeType: borderConfig.style?.toLowerCase() ?? "solid",
                };
            };

            const borderProps = setBorderProperties(cell.border);
            selective = cell.border?.selective;
            stroke = borderProps.stroke;
            strokeWidth = borderProps.strokeWidth;
            strokeOpacity = borderProps.strokeOpacity;
            strokeType = borderProps.strokeType;
            showBorder = true;

            if (selective) {
                selectiveBorders = {
                    top: cell.border?.top?.enabled
                        ? setBorderProperties(cell.border?.top)
                        : null,
                    bottom: cell.border?.bottom?.enabled
                        ? setBorderProperties(cell.border?.bottom)
                        : null,
                    left: cell.border?.left?.enabled
                        ? setBorderProperties(cell.border?.left)
                        : null,
                    right: cell.border?.right?.enabled
                        ? setBorderProperties(cell.border?.right)
                        : null,
                };
            }
            if (cell?.border?.selective) {
                // console.log("selectiveBorders----------", selectiveBorders);
            }
        } else if (borderCustomizations?.enabled) {
            stroke = borderCustomizations.color;
            strokeWidth = borderCustomizations.width;
            strokeOpacity = borderCustomizations.opacity;
            strokeType = borderCustomizations?.style?.toLowerCase() ?? "solid";
            showBorder = true;
        }

        if (showHighlight && !mainTableSelectionActive) {
            stroke = "#000FFF";
            if (strokeOpacity !== 1) {
                strokeOpacity = 1;
            }
            if (strokeWidth < 3) {
                strokeWidth = 3;
            }
            showBorder = true;
            selective = false;
        }
        // if(mainTableSelectionActive){
        //     showBorder = false;
        // }

        return {
            borderProperties: {
                stroke,
                strokeWidth,
                fill,
                strokeOpacity,
                strokeType,
                selectiveBorders,
                selective,
            },
            showBorder,
        };
    }, [cell, borderCustomizations, showHighlight, mainTableSelectionActive]);

    return (
        <div
            style={{
                ...positionStyles,
                width: "fit-content",
                height: "fit-content",
            }}
            onPointerDown={() => {
                setIsClickActive(true);
            }}
            onPointerCancel={() => {
                setIsClickActive(false);
            }}
            onPointerUp={() => {
                console.log("onPointerUp");
                setIsClickActive(false);
            }}
            onPointerLeave={() => {
                setIsClickActive(false);
            }}
            // onMouseOut={() => {
            //     setIsClickActive(false);
            // }}
            // onMouseLeave={() => {
            //     setIsClickActive(false);
            // }}
        >
            {dropArea}
            {showBorder && !hideCell && (
                <IrregularPolygon
                    points={cell?.drawing?.shape?.path}
                    {...borderProperties}
                    sizeStyles={sizeStyles}
                    type={cell?.drawing?.shape?.type}
                    drawing={cell?.drawing}
                />
            )}
            {showBorder1 && !hideCell && (
                <IrregularPolygon
                    points={cell?.drawing?.shape?.path}
                    {...borderProperties1}
                    sizeStyles={sizeStyles}
                    type={cell?.drawing?.shape?.type}
                    drawing={cell?.drawing}
                    zIndex={10}
                />
            )}
            {cellContent}
        </div>
    );
};

export default CellWrapperComponent;
