import { BLOCK_TYPES } from "../../common";

const { BUTTON_BLOCK } = BLOCK_TYPES;

const INPUT_BLOCK = "input";
const RICHTEXT_INPUT_BLOCK = "rich_text_input";
const MATH_INPUT_BLOCK = "rich_text_mathquill_input_html";

const isValidCallback = ({ block }: any) => {
    const {
        data: { children },
    } = block;
    return {
        isValid: checkStorySubblocksValid(children),
        error: "",
    };
};

const checkStorySubblocksValid = (blocks) => {
    if (!blocks?.length) return true;
    for (let index = 0; index < blocks.length; index++) {
        const retVal = checkStorySubblockValid(blocks[index]).isValid;
        if (!retVal) return false;
    }
    return true;
};

export const checkStorySubblockValid = (block) => {
    switch (block?.type) {
        case BUTTON_BLOCK:
        case INPUT_BLOCK:
        case RICHTEXT_INPUT_BLOCK:
        case MATH_INPUT_BLOCK:
            if (!block?.data?.name || !isNaN(block?.data?.name[0]))
                return { isValid: false };
            break;
        default:
            break;
    }
    return { isValid: true };
};

export default isValidCallback;
