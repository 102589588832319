import { PlateEditor, Value } from "@udecode/plate-core";
import {
    AutoformatPlugin,
    CodeBlockElement,
    createPlateUI,
    ELEMENT_BLOCKQUOTE,
    ELEMENT_CODE_BLOCK,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    ELEMENT_IMAGE,
    ELEMENT_PARAGRAPH,
    ELEMENT_TODO_LI,
    ExitBreakPlugin,
    IndentPlugin,
    isBlockAboveEmpty,
    isSelectionAtBlockStart,
    KEYS_HEADING,
    PlatePlugin,
    ResetNodePlugin,
    SelectOnBackspacePlugin,
    TrailingBlockPlugin,
    withProps,
} from "@udecode/plate";
import { EditableProps } from "slate-react/dist/components/editable";
import { css } from "styled-components";
import { autoformatRules } from "./autoformat/autoformatRules";
// import { MENTIONABLES } from './mentionables'
import { ELEMENT_LATEX } from "../plugins/latex/node";

const resetBlockTypesCommonRule = {
    types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
    defaultType: ELEMENT_PARAGRAPH,
};

export const CONFIG: {
    components: Record<string, any>;
    editableProps: EditableProps;

    align: Partial<PlatePlugin>;
    autoformat: Partial<
        PlatePlugin<
            AutoformatPlugin<Value, PlateEditor<Value>>,
            Value,
            PlateEditor<Value>
        >
    >;
    exitBreak: Partial<PlatePlugin<ExitBreakPlugin, Value, PlateEditor<Value>>>;
    // forceLayout: Partial<PlatePlugin<{}, NormalizeTypesPlugin>>
    indent: Partial<PlatePlugin<IndentPlugin, Value, PlateEditor<Value>>>;
    lineHeight: Partial<PlatePlugin>;
    // mentionItems: any
    resetBlockType: Partial<
        PlatePlugin<
            ResetNodePlugin<Value, PlateEditor<Value>>,
            Value,
            PlateEditor<Value>
        >
    >;
    selectOnBackspace: Partial<
        PlatePlugin<SelectOnBackspacePlugin, Value, PlateEditor<Value>>
    >;
    // softBreak: Partial<PlatePlugin<{}, SoftBreakPlugin>>
    trailingBlock: Partial<
        PlatePlugin<TrailingBlockPlugin, Value, PlateEditor<Value>>
    >;
} = {
    editableProps: {
        spellCheck: true,
        autoFocus: false,
        placeholder: "add text, image or latex here",
        style: {
            padding: "15px",
        },
    },
    components: createPlateUI({
        [ELEMENT_CODE_BLOCK]: withProps(CodeBlockElement, {
            styles: {
                root: [
                    css`
                        background-color: #111827;
                        code {
                            color: white;
                        }
                    `,
                ],
            },
        }),
    }),

    align: {
        inject: {
            props: {
                validTypes: [
                    ELEMENT_PARAGRAPH,
                    // ELEMENT_H1,
                    // ELEMENT_H2,
                    // ELEMENT_H3,
                    // ELEMENT_H4,
                    // ELEMENT_H5,
                    // ELEMENT_H6,
                ],
            },
        },
    },
    indent: {
        inject: {
            props: {
                validTypes: [
                    ELEMENT_PARAGRAPH,
                    ELEMENT_H1,
                    ELEMENT_H2,
                    ELEMENT_H3,
                    ELEMENT_H4,
                    ELEMENT_H5,
                    ELEMENT_H6,
                    ELEMENT_BLOCKQUOTE,
                    ELEMENT_CODE_BLOCK,
                ],
            },
        },
    },
    lineHeight: {
        inject: {
            props: {
                defaultNodeValue: 1.5,
                validNodeValues: [1, 1.2, 1.5, 2, 3],
                validTypes: [
                    ELEMENT_PARAGRAPH,
                    // ELEMENT_H1,
                    // ELEMENT_H2,
                    // ELEMENT_H3,
                    // ELEMENT_H4,
                    // ELEMENT_H5,
                    // ELEMENT_H6,
                ],
            },
        },
    },
    resetBlockType: {
        options: {
            rules: [
                {
                    ...resetBlockTypesCommonRule,
                    hotkey: "Enter",
                    predicate: isBlockAboveEmpty,
                },
                {
                    ...resetBlockTypesCommonRule,
                    hotkey: "Backspace",
                    predicate: isSelectionAtBlockStart,
                },
            ],
        },
    },
    trailingBlock: { type: ELEMENT_PARAGRAPH },
    // softBreak: {
    //   options: {
    //     rules: [
    //       { hotkey: 'shift+enter' },
    //       {
    //         hotkey: 'enter',
    //         query: {
    //           allow: [ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE, ELEMENT_TD],
    //         },
    //       },
    //     ],
    //   },
    // },
    exitBreak: {
        options: {
            rules: [
                {
                    hotkey: "mod+enter",
                },
                {
                    hotkey: "mod+shift+enter",
                    before: true,
                },
                {
                    hotkey: "enter",
                    query: {
                        start: true,
                        end: true,
                        allow: KEYS_HEADING,
                    },
                },
            ],
        },
    },
    selectOnBackspace: {
        options: {
            query: {
                // allow: [ELEMENT_IMAGE, ELEMENT_HR],
                allow: [ELEMENT_IMAGE, ELEMENT_LATEX],
            },
        },
    },
    autoformat: {
        options: {
            rules: autoformatRules,
        },
    },
    // mentionItems: MENTIONABLES,
    // forceLayout: {
    //   options: {
    //     rules: [{ path: [0], strictType: ELEMENT_H1 }],
    //   },
    // },
};
