import { gql } from "@apollo/client";export default gql`query concepts(
    $limit: Int = 12
    $offset: Int = 0
    $where: concept_bool_exp # $type: String # $parent_id: uuid
) {
    concepts: concept(
        limit: $limit
        offset: $offset
        where: $where # order_by: { metadata: { created_at: desc } }
    ) {
        id
        title
    }
}
`;
