import React, { useEffect, useState } from "react";
import { Link } from "src/components";
import {
    Table,
    Button,
    Col,
    Divider,
    message,
    Row,
    Space,
    Spin,
    Modal,
} from "antd";
import { AppstoreAddOutlined, FolderAddOutlined } from "@ant-design/icons";
import { MenuOutlined } from "@ant-design/icons";

import DeleteView from "src/modules/book/components/DeleteView";
import {
    EditIcon,
    ViewIcon,
    CopyIcon,
    RenderTable,
    Layout,
    MetaTag,
} from "src/components";
import { Can } from "src/services/casl";

import { FilterComponent } from "src/modules/book/components";
import { useRouter } from "src/helpers";
import { CollectionsAssignComponent } from "src/modules/collection/components";
import { curry } from "lodash";
import { ApolloConsumer } from "@apollo/client";

import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { gql } from "@apollo/client";

const SET_COUNT_QUERY = gql`
    query SetCountQuery($id: Int!) {
        group_by_pk(id: $id) {
            children {
                collections_aggregate {
                    aggregate {
                        count
                    }
                }
            }
        }
    }
`;

const AssignGroupModal = ({
    group,
    createGroupCollection,
    deleteGroupCollection,
    topics,
    updateChapterOther,
    chapter_id,
    // totalSetCount,
    // setTotalSetCount
    // incrementGroupDuration,
    client,
}) => {
    const [isModalVisible, groupIsModalVisible] = useState(false);
    const initialValue = group?.collections?.map(
        ({ collection: { id, title } }, index) => ({
            value: id,
            key: index,
            label: title,
        }),
    );

    const [selected, setSelected] = React.useState(initialValue);
    const [prevSelected, setPrevSelected] = useState(initialValue);
    const [prevValues, setPrevValues] = useState(initialValue);
    const [dropdownOpen, groupDropdownOpen] = useState(false);
    const [editedValues, setEditedValues] = useState(initialValue);

    const handleChange = (value) => {
        setSelected(value);
        setPrevSelected(prevValues);
    };

    const showModal = () => {
        groupIsModalVisible(true);
        setTimeout(() => {
            groupDropdownOpen(true);
        }, 300);
    };

    const handleOk = async () => {
        // set newValue
        setPrevValues(selected);

        const collectionIds = group.collections.map(
            ({ collection }) => collection.id,
        );

        let removedCollectionIds = [...collectionIds];
        let countChange = 0;

        function randomIntFromInterval(min, max) {
            // min and max included
            return Math.floor(Math.random() * (max - min + 1) + min);
        }

        await Promise.all(
            selected.map(async (collection) => {
                if (collectionIds.includes(collection.value)) {
                    console.log(
                        `collection ${collection.value}, already assigned`,
                    );
                    removedCollectionIds = removedCollectionIds.filter(
                        (id) => id !== collection.value,
                    );
                } else {
                    console.log(
                        `collection ${collection.value}, is being assigned`,
                    );
                    await createGroupCollection({
                        group_id: group.id,
                        collection_id: collection.value,
                        order: randomIntFromInterval(1, 1000),
                    });
                    // await incrementGroupDuration({
                    //     duration: collection.duration,
                    //     id: group.value,
                    // });
                    countChange += 1;
                }
            }),
        );

        console.log(
            `collections ${JSON.stringify(
                removedCollectionIds,
            )}, is being removed`,
        );
        const removedGroupCollectionIds: number[] = [];
        group.collections.map(
            ({ id, collection: { id: collection_id } }, index) => {
                if (removedCollectionIds.includes(collection_id))
                    removedGroupCollectionIds.push(id);
            },
        );
        if (removedGroupCollectionIds.length > 0) {
            await deleteGroupCollection({ ids: removedGroupCollectionIds });
            // await Promise.all(
            //     removedGroupIds.map(async (group_id) => {
            //         await incrementGroupDuration({
            //             duration: -1 * collection.duration,
            //             id: group_id,
            //         });
            //     }),
            // );
            countChange -= 1;
        }
        // increment chapter setCount by countChange
        // console.log({totalSetCount})

        const response = await client.query({
            query: SET_COUNT_QUERY,
            variables: {
                id: chapter_id,
            },
            fetchPolicy: "network-only",
        });
        const set_count = response["data"]["group_by_pk"]["children"].reduce(
            (partialSum: number, item: any) =>
                partialSum +
                item["collections_aggregate"]["aggregate"]["count"],
            0,
        );
        console.log({ set_count });
        await updateChapterOther({
            other: {
                set_count,
            },
            id: chapter_id,
        });
        // console.log({totalSetCount, s: selected.length, p: prevSelected});
        // setTotalSetCount(totalSetCount + selected.length - prevSelected.length)

        message.success("Groups assigned successfully");
        setEditedValues(selected);
        handleCancel(false);
    };

    const handleCancel = (reset = true) => {
        groupDropdownOpen(false);
        setTimeout(() => {
            groupIsModalVisible(false);
            if (reset) setSelected(editedValues);
        }, 200);
    };
    // console.log({selected})
    // console.log({group})
    return (
        <>
            <Button
                icon={<FolderAddOutlined />}
                shape="circle"
                onClick={showModal}
            />
            <Modal
                style={{ top: 20 }}
                bodyStyle={{ height: 750 }}
                title="Assign Collection to Groups"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <CollectionsAssignComponent
                    isGroup={true}
                    onChange={handleChange}
                    selected={selected}
                    dropdownOpen={dropdownOpen}
                />
            </Modal>
        </>
    );
};

const ListView: React.FC = (props: any) => {
    const {
        loading,
        loadingTopics,
        topics,
        topicAggregate,
        loadTopicsData,
        createGroupCollection,
        deleteGroupCollection,
        updateChapterOther,
    } = props;
    const { query, isReady } = useRouter();
    const { chapter_id } = query;
    const [dataSource, setDataSource] = useState([]);

    // const [totalSetCount, setTotalSetCount] = useState(0)
    useEffect(() => {
        if (topics.length) {
            // console.log(topics.reduce((acc, cur) => acc+cur.collections.length, 0));
            // setTotalSetCount(topics.reduce((acc, cur) => acc+cur.collections.length, 0)) ;
            setDataSource(
                topics
                    .map((t, index) => {
                        return {
                            ...t,
                            index,
                            order: t.other?.order || -1,
                        };
                    })
                    .sort((a, b) => {
                        if (a.status === b.status) return a.order - b.order;
                        else return a.status > b.status ? 1 : -1;
                    }),
            );
        }
    }, [topics]);

    // console.log({loading})

    if (!isReady) return <div>Loading...</div>;

    const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
    ));

    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(
                [].concat(dataSource),
                oldIndex,
                newIndex,
            ).filter((el) => !!el);

            newData.map((d, index) => {
                if (d.order !== index + 1) {
                    console.log("Updating order: ", d.id, index + 1);
                    // for topic set_id will actually be topic_id
                    const url_split =
                        typeof window !== "undefined" &&
                        window.location.pathname.split("/");
                    const filter_type =
                        url_split && url_split[url_split.length - 2];
                    updateChapterOther({
                        other: {
                            order: index + 1,
                        },
                        id: d.id,
                    });

                    newData[index].order = index;
                }
            });

            setDataSource(newData);
        }
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helper="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(
            (x) => x.index === restProps["data-row-key"],
        );
        return <SortableItem index={index} {...restProps} />;
    };

    const columns = [
        {
            title: "Sort",
            dataIndex: "sort",
            width: 30,
            className: "drag-visible",
            render: () => <DragHandle />,
        },
        {
            title: "Id",
            key: "id",
            className: "drag-visible",

            render: (text, record) => record?.id,
            fixed: "left",
        },
        {
            title: "Title",
            key: "title",
            render: (text, record) => record?.title,
            // fixed: "left",
            width: 200,
        },
        {
            title: "Description",
            key: "description",
            render: (text, record) => record?.description,
        },

        {
            title: "Status",
            key: "status",
            render: (text, record) => record?.status,
        },
        {
            title: "Action",
            key: "action",
            // align: "right",
            // fixed: "right",
            width: 200,
            render: (text, record) => (
                <Space size="middle">
                    {/* <CopyIcon
                        isButton={true}
                        onClick={() => {
                            message.info(`Copied topic link to clipboard!`);
                            navigator.clipboard.writeText(
                                `${process.env.REACT_APP_CLIENT_BASE}/topic/${record.id}`,
                            );
                        }}
                    />
                    <Divider /> */}
                    <Link href={`/collection/topic/${record?.id}`}>
                        <ViewIcon isButton={true} />
                    </Link>
                    <Divider />

                    {!loading && (
                        <>
                            <Can I="assign" a="collection" passThrough>
                                <ApolloConsumer>
                                    {(client) => (
                                        <AssignGroupModal
                                            group={record}
                                            createGroupCollection={
                                                createGroupCollection
                                            }
                                            deleteGroupCollection={
                                                deleteGroupCollection
                                            }
                                            topics={topics}
                                            updateChapterOther={
                                                updateChapterOther
                                            }
                                            chapter_id={chapter_id}
                                            // incrementGroupDuration={incrementGroupDuration}
                                            // totalSetCount={totalSetCount}
                                            // setTotalSetCount={setTotalSetCount}
                                            client={client}
                                        />
                                    )}
                                </ApolloConsumer>
                            </Can>
                            <Divider />
                        </>
                    )}

                    <Can I="edit" a={"topic"} passThrough>
                        {(allowed) => (
                            <Link href={`/book/topic/update/${record?.id}`}>
                                <EditIcon isButton={true} disabled={!allowed} />
                            </Link>
                        )}
                    </Can>

                    <Divider />
                    <Can I="delete" a={"topic"} passThrough>
                        {(allowed) => (
                            <DeleteView
                                id={record?.id}
                                isButton={true}
                                disabled={!allowed}
                                record={record}
                            />
                        )}
                    </Can>
                </Space>
            ),
        },
    ];
    return (
        <Layout titles={[{ name: "Topics", link: "/topic" }]}>
            <MetaTag title="Topics" />
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Topics List</h1>
                </Col>
                <Col>
                    <Can I="create" a={"topic"} passThrough>
                        {(allowed) => (
                            <Link href={`/book/topic/create/${chapter_id}`}>
                                <Button
                                    type="primary"
                                    icon={<AppstoreAddOutlined />}
                                    disabled={!allowed}
                                >
                                    Add
                                </Button>
                            </Link>
                        )}
                    </Can>
                </Col>
            </Row>
            <hr />

            {/* <FilterComponent isTopic={false} {...props} />
            <hr /> */}

            <Spin spinning={loadingTopics}>
                {Boolean(topics?.length) && (
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={dataSource || []}
                        rowKey="index"
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                    />
                )}
            </Spin>
        </Layout>
    );
};

export default ListView;
