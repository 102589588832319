import React from "react";
import { Card } from "antd";

const renderBlock = (_: any) => {
    return (
        <div>
            <Card>Color Combine Block</Card>
        </div>
    );
};
export default renderBlock;
