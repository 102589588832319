import { FunctionComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";

import {
    CONTENTS_QUERY,
    CREATE_CONTENT_MUTATION,
    CREATE_CONTENT_BATCH_MUTATION,
    UPDATE_CONTENT,
    DELETE_CONTENT_MUTATION,
    INCREMENT_SET_DURATION_MUTATION,
    INCREMENT_COLLECTION_DURATION_MUTATION,
    DELETE_CONTENT_BY_COLLECTION_ID_MUTATION,
} from "../graphql";
import { captureException } from "@sentry/react";

// Query
export const withContents = (Component: FunctionComponent) =>
    graphql(CONTENTS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                contents,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadContentsData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            contents: [
                                ...previousResult.contents,
                                ...fetchMoreResult.contents,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                contents,
                subscribeToMore,
                updateQuery,
                loadContentsData,
                refetchContents: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreateContent = (Component: FunctionComponent) =>
    graphql(CREATE_CONTENT_MUTATION, {
        props: ({ mutate }) => ({
            createContent: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createContent: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { insert_collection_content_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createContent: {
                                object,
                                __typename: "INSERT_CONTENT_ONE",
                            },
                        },
                    });
                    return insert_collection_content_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withCreateContentBatch = (Component: FunctionComponent) =>
    graphql(CREATE_CONTENT_BATCH_MUTATION, {
        props: ({ mutate }) => ({
            createContentBatch: async (objects: any) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createContent: ~ object",
                //     objects,
                // );
                try {
                    const {
                        data: { insert_collection_content },
                    } = await mutate({
                        variables: { objects },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createContent: {
                                objects,
                                __typename: "INSERT_COLLECTION_CONTENT",
                            },
                        },
                    });
                    return insert_collection_content;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateContent = (Component: FunctionComponent) =>
    graphql(UPDATE_CONTENT, {
        props: ({ mutate }) => ({
            updateContent: (object: Object) => {
                mutate({
                    variables: { ...object },
                    optimisticResponse: {
                        updateContent: {
                            ...object,
                            __typename: "content",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withDeleteContent = (Component: FunctionComponent) =>
    graphql(DELETE_CONTENT_MUTATION, {
        props: ({ mutate }) => ({
            deleteContent: (id: string) => {
                mutate({
                    variables: { id },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteContent: {
                            id,
                            __typename: "DELETE_CONTENT_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withDeleteContentsByCollectionId = (
    Component: FunctionComponent,
) =>
    graphql(DELETE_CONTENT_BY_COLLECTION_ID_MUTATION, {
        props: ({ mutate }) => ({
            deleteCollectionContent: ({ id, submissionIds }) => {
                mutate({
                    variables: { id, submissionIds },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteCollectionContent: {
                            id,
                            __typename: "DELETE_CONTENT_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withIncrementCollectionDuration = (Component: FunctionComponent) =>
    graphql(INCREMENT_COLLECTION_DURATION_MUTATION, {
        props: ({ mutate }) => ({
            incrementCollectionDuration: (object) => {
                mutate({
                    variables: object,
                });
            },
        }),
    })(Component);

export const withIncrementSetDuration = (Component: FunctionComponent) =>
    graphql(INCREMENT_SET_DURATION_MUTATION, {
        props: ({ mutate }) => ({
            incrementSetDuration: (object) => {
                mutate({
                    variables: object,
                });
            },
        }),
    })(Component);
