import { DocumentList, DocumentCreate, DocumentEdit } from "./views";

import { Route } from "react-router-dom";
export const DOCUMENT_ROUTES = [
    <Route path="/document/:group_id" element={<DocumentList />} />,

    <Route path="/document/create/:group_id" element={<DocumentCreate />} />,
    <Route path="/document/update/:document_id" element={<DocumentEdit />} />,
];

export { DocumentList, DocumentCreate, DocumentEdit } from "./views";
