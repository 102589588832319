import { BLOCK_TYPES } from "../../common/index";
import { MCQ_DEFAULT_VALUE } from "../MCQ";
const { V2_THREE_LIVES_BLOCK } = BLOCK_TYPES;
import { v4 as uuid } from "uuid";

const getDefaultValue = () => ({
    type: V2_THREE_LIVES_BLOCK,
    data: {
        [V2_THREE_LIVES_BLOCK]: {
            title: "",
            banner: "",
            sub_title: "",
        },

        other: {
            duration: 0,
            audio: "",
        },
    },
    children: [
        {
            ...MCQ_DEFAULT_VALUE,
            order: 1,
            tmpId: uuid(),
        },
    ],
});

export default getDefaultValue;
