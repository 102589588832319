import React, { useState, createContext, useContext } from "react";
import { gql } from "graphql-request";
import { graphQLClient } from "src/modules/tab/components/PublishTabToProd";

const WORKSHEET_BLOCK = gql`
    query BlockQuery($id: Int!) {
        block: worksheet_block_by_pk(id: $id) {
            id
            data
            type
            backend
            is_hidden
            children(order_by: { order: asc }) {
                id
                data
                type
                order
                backend
                children(order_by: { order: asc }) {
                    id
                    data
                    type
                    order
                    backend
                }
            }
        }
    }
`;

export const WorksheetContext = createContext(null);

export const useWorksheetState = () => {
    const context = useContext(WorksheetContext);
    if (!context) {
        throw new Error("Worksheet context not provided");
    }
    return context;
};

const DnDItemsTableColumns: any = {
    showAlignItems: false,
    showLinkedVariables: false,
    showValue: false,
    showEvaluation: false,
    showFeedbackType: false,
    showDefaultTags: false,
    showHightlight: false,
    showFeedbackStroke: false,
    showFeedbackFill: false,
    showIsEnabled: false,
};

export const WorksheetContextProvider = ({
    children,
    levelIndex,
    chunkIndex,
    blockIndex,
    publishedBlocksMap = {},
}) => {
    const [
        checkedDnDItemsTableColumnsList,
        setCheckedDnDItemsTableColumnsList,
    ] = useState(
        Object.keys(DnDItemsTableColumns).filter(
            (i) => DnDItemsTableColumns[i] ?? true,
        ),
    );

    const getBlockfromDb = async (id: any) => {
        const { block }: any = await graphQLClient.request(WORKSHEET_BLOCK, {
            id,
        });

        return block;
    };

    return (
        <WorksheetContext.Provider
            value={{
                checkedDnDItemsTableColumnsList,
                setCheckedDnDItemsTableColumnsList,
                DnDItemsTableColumns,
                getBlockfromDb,
                levelIndex,
                chunkIndex,
                blockIndex,
                publishedBlocksMap,
            }}
        >
            {children}
        </WorksheetContext.Provider>
    );
};
