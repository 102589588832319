import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import {
    withHookForDocument,
    withUpdateDocument,
} from "src/modules/document/operations";
import { compose } from "src/helpers";

import EditView from "./component";

const Edit = (props) => {
    const { updateDocument } = props;
    const router = useRouter();
    const { group_id, redirect_to } = router.query;

    const onFinish = async (values: any) => {
        await updateDocument({ id: values.id, object: values });
        router.push(redirect_to);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <EditView
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            {...props}
        />
    );
};

export default compose(withHookForDocument, withUpdateDocument)(Edit);
