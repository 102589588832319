import React from "react";
import PropTypes from "prop-types";
import { Space, Form, Input } from "antd";

const FormItem = Form.Item;

const { TextArea } = Input;

export const RenderField = ({
    icon,
    input,
    label,
    type,
    meta: { touched, error },
    placeholder,
    rows = 6,
    tooltip,
    hidden,
    required,
}) => {
    let validateStatus = "";
    if (touched && error) {
        validateStatus = "error";
    }

    return (
        <FormItem
            label={
                <Space align="left">
                    {/* {icon && <Icon type={icon} />} */}
                    {label}
                </Space>
            }
            className={hidden && "hidden"}
            hasFeedback={type != "textarea"}
            validateStatus={validateStatus}
            help={touched && error}
            tooltip={tooltip}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            required={required}
        >
            {type != "textarea" ? (
                <Input
                    {...input}
                    placeholder={placeholder || label}
                    type={type}
                />
            ) : null}
            {type == "textarea" ? (
                <TextArea
                    rows={rows}
                    {...input}
                    placeholder={placeholder || label}
                />
            ) : null}
        </FormItem>
    );
};

RenderField.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    type: PropTypes.string,
    meta: PropTypes.object,
    tooltip: PropTypes.object,
    icon: PropTypes.node,
};
