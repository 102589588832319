import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";

const renderCardButton = (props: any) => {
    const {
        block: { data },
    } = props;
    return (
        <BlockCard {...props}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <h1>card</h1>
                {/* <img src={data.image} style={{ height: "30px" }} /> */}
            </div>
        </BlockCard>
    );
};
export default renderCardButton;
