import { v4 as uuid } from "uuid";

export const DEFAULT_DEADLINE_STORY = {
    __typename: "worksheet_block",
    id: 265835,
    data: {
        other: {
            job: { id: "", name: "", last_updated: null },
            audio: "",
            duration: 0,
        },
        children: [
            {
                id: "3922d671-8988-4641-9f12-2d30bc229869",
                data: {
                    other: { audio: "", duration: -1 },
                    children: [
                        {
                            data: {
                                name: "Yes",
                                button: {
                                    text: "Ok, thanks!",
                                    color: "#333333",
                                    action: { type: "internal", value: "" },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#FFDE49",
                                },
                            },
                            type: "button",
                            order: 0,
                            other: {
                                size: { width: 412, height: 84 },
                                position: { top: 648, left: 0 },
                            },
                            tmpId: "82a4c88f-e096-42db-9fde-64d4c1821b6f",
                        },
                    ],
                    v2_story: {
                        name: "1",
                        logic: {
                            compute_functions: [
                                {
                                    name: "getNextStory",
                                    label: "Get Next Story",
                                    output: "function getNextStory(history, inputs, global_context_variables) {\n  return 'next';\n}",
                                    params: [],
                                    isGlobal: true,
                                },
                            ],
                        },
                        is_skip: false,
                        background: {
                            loop: false,
                            type: "VIDEO",
                            value: "https://video.gumlet.io/6607c654258edd12779481f2/6722015b7a5365818691defb/main.mp4",
                            default: "",
                        },
                        bg_audio_list: [],
                        duration_type: null,
                        play_multiple_audio: true,
                    },
                },
                type: "v2_story",
                order: 1,
                tmpId: "532e9fc3-e642-49c8-8c3c-fba83ade2def",
            },
        ],
        v2_stories: {
            name: "Default Deadline story",
            banner: "",
            sub_title: "",
            dev_remarks: [{ type: "p", children: [{ text: "" }] }],
        },
        gen_audio_map: [[]],
    },
    type: "v2_stories",
    order: 1,
    backend: {
        slug: "default_deadline_story",
        compute_functions: [
            {
                name: "getNextBlock",
                label: "Get Next Block",
                output: "function getNextBlock(history, next, global_context_variables) {\n  return next;\n}",
                params: [],
                isGlobal: true,
            },
        ],
    },
};

export const DEFAULT_UNLOCK_TIME_STORY = {
    __typename: "worksheet_block",
    id: 265835,
    data: {
        other: {
            job: { id: "", name: "", last_updated: null },
            audio: "",
            duration: 0,
        },
        children: [
            {
                id: uuid(),
                data: {
                    other: { audio: "", duration: -1 },
                    children: [
                        {
                            data: {
                                name: "Yes",
                                button: {
                                    text: "Ok, thanks!",
                                    color: "#333333",
                                    action: { type: "internal", value: "" },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#FFDE49",
                                },
                            },
                            type: "button",
                            order: 0,
                            other: {
                                size: { width: 412, height: 84 },
                                position: { top: 648, left: 0 },
                            },
                            tmpId: uuid(),
                        },
                    ],
                    v2_story: {
                        name: "1",
                        logic: {
                            compute_functions: [
                                {
                                    name: "getNextStory",
                                    label: "Get Next Story",
                                    output: "function getNextStory(history, inputs, global_context_variables) {\n  return 'next';\n}",
                                    params: [],
                                    isGlobal: true,
                                },
                            ],
                        },
                        is_skip: false,
                        background: {
                            loop: false,
                            type: "VIDEO",
                            value: "https://video.gumlet.io/6607c654258edd12779481f2/6722015b7a5365818691defb/main.mp4",
                            default: "",
                        },
                        bg_audio_list: [],
                        duration_type: null,
                        play_multiple_audio: true,
                    },
                },
                type: "v2_story",
                order: 1,
                tmpId: uuid(),
            },
        ],
        v2_stories: {
            name: "Default Unlock time story",
            banner: "",
            sub_title: "",
            dev_remarks: [{ type: "p", children: [{ text: "" }] }],
        },
        gen_audio_map: [[]],
    },
    type: "v2_stories",
    order: 1,
    backend: {
        slug: "default_unlock_time_story",
        compute_functions: [
            {
                name: "getNextBlock",
                label: "Get Next Block",
                output: "function getNextBlock(history, next, global_context_variables) {\n  return next;\n}",
                params: [],
                isGlobal: true,
            },
        ],
    },
};
