import { Card } from "antd";
import { renderTags } from "../../common/index";
import { renderer } from "../../helpers";
import { renderBlockMCQ } from "../MCQ";

const renderBlockCaseStudy = (block: any) => {
    const {
        data: {
            case_study: { text },
        },
        children,
        heading,
        tags,
    } = block;
    return (
        <div>
            {/* <Row gutter={[0, 24]}> */}
            <div>
                {heading && <h3>Case Study Text</h3>}
                {renderTags(tags)}

                <Card bodyStyle={{ padding: "12px" }}>{renderer(text)}</Card>
            </div>

            {heading && <h3>Questions</h3>}
            {children.map((child: any) => (
                <div>
                    <Card bodyStyle={{ padding: "12px" }}>
                        {renderBlockMCQ({ ...child, heading })}
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default renderBlockCaseStudy;
