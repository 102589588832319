import {
    Badge,
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    Switch,
    message,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import {
    CheckOutlined,
    CloseOutlined,
    MenuOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { Actions } from "../../../../WorksheetEditor/components/Editor/BlocksColumn/Actions";
import { arrayMoveImmutable } from "array-move";
import { getBareBlock } from "../../../";
import BlockEditor from "./BlockEditor";
import { BLOCK_TYPES } from "../../../common";
import { v4 as uuid } from "uuid";
import { checkVariantValid } from "../isValid";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import V2JumpLogicModal from "src/modules/worksheet/components/WorksheetEditor/components/Modals/V2JumpLogicModal";

const VariantEditor = (props: any) => {
    const {
        block,
        setBlock,
        currentBlock,
        deleteParentBlock,
        blocks,
        disableWrite,
        previewData,
        pathChildren,
    } = props;
    const { variants, has_solution } = block;

    const [currentSubBlock, setCurrentSubBlock] = useState(
        variants?.length > 0 ? 0 : -1,
    );
    const [loading, setLoad] = useState(false);

    const updateCurrentBlock = (val: number) => {
        setCurrentSubBlock(val);
    };
    useEffect(() => {
        setLoad(true);
        setTimeout(() => setLoad(false), 50);
    }, [currentSubBlock]);

    useEffect(() => {
        updateCurrentBlock(variants?.length > 0 ? 0 : -1);
    }, [currentBlock]);

    const DragHandle = SortableHandle(() => <MenuOutlined />);

    const SortableItem = SortableElement(({ value, child }: any) => {
        const actionsProps = {
            deleteBlock: () => {
                updateCurrentBlock(currentSubBlock - 1);
                let tmpBlock = _.cloneDeep(block);
                const tmpOptions = [...variants];
                _.pullAt(tmpOptions, currentSubBlock);
                tmpBlock = _.set(tmpBlock, ["variants"], tmpOptions);

                setBlock(tmpBlock);
                if (tmpOptions.length === 0) deleteParentBlock();
            },
            idx: currentSubBlock,
            addBlockCustom: (type: string, index: number, newBlock: any) => {
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = [...variants];
                tmpChildren.splice(index, 0, newBlock);
                tmpBlock = _.set(tmpBlock, ["variants"], tmpChildren);
                setBlock(tmpBlock);
                setCurrentSubBlock(index);
            },
            block: child,
            // for progression stage
            updateId: true,
        };

        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Badge.Ribbon
                    color={child?.type === "primary" ? "blue" : "cyan"}
                    text={(child?.type === "primary" ? "P " : "") + (value + 1)}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            background:
                                currentSubBlock === value ? "#F1F1F1" : "",
                            borderBottom: !checkVariantValid(
                                child,
                                has_solution,
                            )
                                ? "3px solid red"
                                : currentSubBlock === value
                                ? "3px solid indigo"
                                : "",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            minWidth: "90px",
                        }}
                        onClick={() => {
                            updateCurrentBlock(value);
                        }}
                        bodyStyle={{
                            background: "transparent",
                        }}
                    >
                        <span style={{ textTransform: "capitalize" }}>
                            {`Variant ${value + 1}`}
                        </span>

                        {currentSubBlock === value && !disableWrite && (
                            <div
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    padding: "2px",
                                }}
                            >
                                <DragHandle />
                            </div>
                        )}
                    </Card>
                </Badge.Ribbon>

                {currentSubBlock === value && !disableWrite && (
                    <Actions {...actionsProps} />
                )}
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }: any) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child: any, index: number) => (
                    <SortableItem
                        key={`variant-${index}`}
                        index={index}
                        value={index}
                        child={child}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            variants,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(tmpBlock, ["variants"], tmpChildren);
        setBlock(tmpBlock);
        updateCurrentBlock(newIndex);
    };

    const blockProps = {
        ...props,
        block: variants[currentSubBlock],
        setBlock: (subBlock: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(tmpBlock, ["variants", currentSubBlock], subBlock);
            setBlock(tmpBlock);
        },
        currentBlock: currentSubBlock,
        updatePrimary: (sid: any) => {
            let tmpBlock = _.cloneDeep(block);
            const tempVariants = (tmpBlock?.variants || []).map(
                (item: any, index: any) => {
                    return {
                        ...item,
                        type: sid === index ? "primary" : "variant",
                    };
                },
            );

            tmpBlock = _.set(tmpBlock, ["variants"], tempVariants);
            setBlock(tmpBlock);
        },
        previewData: {
            ...previewData,
            block_id: block?.id || block?.tmpId,
        },
        has_solution,
    };

    const jumpLogicProps = {
        currentBlock,
        blocks: pathChildren,
        setBlock,
        disableWrite,
    };

    return (
        <div>
            {(block?.tmpId || block?.id) && (
                <Col span={15} style={{ marginTop: "30px" }}>
                    <Divider />
                    <Form.Item label="Block Id">
                        <Input.Search
                            value={block.id || block.tmpId}
                            onChange={() => {}}
                            enterButton="Copy"
                            size="middle"
                            onSearch={() => {
                                message.info(`Copied Block Id to clipboard!`);
                                navigator.clipboard.writeText(
                                    `${block.id || block.tmpId}`,
                                );
                            }}
                        />
                    </Form.Item>
                </Col>
            )}

            <Button
                disabled={disableWrite}
                type="primary"
                onClick={() => {
                    message.info(`Copied Block to clipboard!`);

                    navigator.clipboard.writeText(JSON.stringify(block));
                }}
            >
                Copy this block to Clipboard
            </Button>

            {<V2JumpLogicModal {...jumpLogicProps} />}

            {![BLOCK_TYPES.V2_STORIES_BLOCK].includes(
                variants[currentSubBlock]?.block?.type,
            ) && (
                <>
                    <br />
                    <Form.Item label="Variants have Solution">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={has_solution}
                            onChange={(value) => {
                                setBlock({ ...block, has_solution: value });
                            }}
                        />
                    </Form.Item>
                </>
            )}

            <Divider />

            {![BLOCK_TYPES.V2_STORIES_BLOCK].includes(
                variants[currentSubBlock]?.block?.type,
            ) && (
                <h2
                    style={{
                        marginTop: "20px",
                        fontWeight: "bold",
                        marginBottom: "20px",
                    }}
                >
                    Variants Details
                </h2>
            )}
            <div>
                {![BLOCK_TYPES.V2_STORIES_BLOCK].includes(
                    variants[currentSubBlock]?.block?.type,
                ) && (
                    <>
                        {!disableWrite && (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "20px",
                                    marginBottom: "20px",
                                }}
                            >
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["variants", variants.length],
                                            {
                                                id: uuid(),
                                                tmpId: uuid(),
                                                order: variants.length + 1,
                                                screen_config: {
                                                    button: {
                                                        is_enabled: true,
                                                        btn_text: "Submit",
                                                    },
                                                },
                                                type: "variant",
                                                badge_count: 1,
                                                config: [],
                                                block: {
                                                    ...getBareBlock(
                                                        variants[0]?.block
                                                            ?.type,
                                                    ),
                                                    id: uuid(),
                                                    tmpId: uuid(),
                                                },
                                            },
                                        );
                                        setBlock(tmpBlock);
                                        updateCurrentBlock(variants.length);
                                    }}
                                >
                                    Add Variant{" "}
                                    <PlusOutlined
                                        style={{ fontSize: "15px" }}
                                    />
                                </Button>

                                <Input.Search
                                    placeholder="Add Variant with ID"
                                    allowClear
                                    enterButton="Add Variant"
                                    size="middle"
                                    style={{
                                        width: "500px",
                                    }}
                                    onSearch={(data) => {
                                        if (!data) return;

                                        let curVariant = null;
                                        blocks.some(
                                            (block: {
                                                children: {
                                                    data: {
                                                        paths: {
                                                            children: {
                                                                variants: {
                                                                    id: string;
                                                                    tmpId: string;
                                                                }[];
                                                            }[];
                                                        }[];
                                                    };
                                                }[];
                                            }) =>
                                                block.children?.some((child) =>
                                                    child?.data?.paths?.some(
                                                        (path) =>
                                                            path?.children?.some(
                                                                (question) =>
                                                                    question?.variants?.some(
                                                                        (
                                                                            variant,
                                                                        ) => {
                                                                            if (
                                                                                variant.id ===
                                                                                    data ||
                                                                                variant.tmpId ===
                                                                                    data
                                                                            ) {
                                                                                curVariant =
                                                                                    variant;
                                                                                return true;
                                                                            }
                                                                            return false;
                                                                        },
                                                                    ),
                                                            ),
                                                    ),
                                                ),
                                        );

                                        if (curVariant) {
                                            let tmpVariant = _.cloneDeep(block);
                                            tmpVariant = _.set(
                                                tmpVariant,
                                                ["variants", variants.length],
                                                updateKeysAndCopy(
                                                    _.omit(curVariant, [
                                                        "id",
                                                        "worksheet_block_map_id",
                                                        "tmpId",
                                                    ]),
                                                    false,
                                                ),
                                            );
                                            setBlock(tmpVariant);
                                            updateCurrentBlock(variants.length);

                                            message.success("Variant Added");
                                        } else
                                            message.error(
                                                "Variant doesn't exist!",
                                            );
                                    }}
                                />
                            </div>
                        )}
                        <SortableList
                            items={variants}
                            onSortEnd={onSortEnd}
                            axis="xy"
                            useDragHandle
                        />
                    </>
                )}
                <Divider />
                {!loading && variants[currentSubBlock] && (
                    <BlockEditor {...blockProps} />
                )}
            </div>
        </div>
    );
};

export default VariantEditor;
