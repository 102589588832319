import { v4 as uuid } from "uuid";
import { BLOCK_TYPES } from "../../common/index";
import { MCQ_DEFAULT_VALUE } from "../MCQ";
const { CASE_STUDY_BLOCK } = BLOCK_TYPES;
const CASE_STUDY_DEFAULT_VALUE = () => ({
    type: CASE_STUDY_BLOCK,
    data: {
        [CASE_STUDY_BLOCK]: {
            text: [],
        },
    },
    children: [
        {
            ...MCQ_DEFAULT_VALUE,
            order: 1,
            tmpId: uuid(),
        },
    ],
});

export default CASE_STUDY_DEFAULT_VALUE;
