import { gql } from "@apollo/client";export default gql`mutation updateCollection(
    $id: Int!
    $title: String = ""
    $description: String = ""
    $cover: String = ""
    $chapter: String = ""
    $topic: String = ""
    $status: String = ""
    $type: String = ""
    $attempts: Int = 0
    $show_memes: Boolean = true
    $format: String = ""
    $class: String = ""
    $subject: String = ""
    
    $other: jsonb = "{}"

) {
    updateCollection: update_collection_by_pk(
        pk_columns: { id: $id }
        _set: {
            title: $title
            description: $description
            cover: $cover
            chapter: $chapter
            topic: $topic
            status: $status
            type: $type
            attempts: $attempts
            show_memes: $show_memes
            format: $format
            class: $class
            subject: $subject
            other: $other


        }
    ) {
        id
        title
        description
        cover
        chapter
        topic
        attempts
        type
        status
        show_memes
        format
        class
        subject
        other
    }
}
`;
