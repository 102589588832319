import { FC, useEffect, useMemo, useState } from "react";
import { HighlightFeedbackElement } from ".";
import {
    Button,
    Card,
    Col,
    Divider,
    Input,
    Row,
    Select,
    Switch,
    Tooltip,
    Typography,
    message,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { SlateEditor } from "../plate";
import "./style.css";

interface CreateHighlightProps {
    ids: string[];
    children: React.ReactNode;
}

const CreateHighlight: FC<CreateHighlightProps> = ({
    ids,
    children,
    ...rest
}) => {
    const positionDropdownValues = useMemo(
        () =>
            ["top", "left", "right", "bottom"].map((val) => ({
                label: val,
                value: val,
            })),
        [],
    );
    const [previewEnabled, setPreviewEnabled] = useState(false);

    const [elementIds, setElementIds] = useState(ids);
    // const elementIds

    const { setHighlight } = rest as any;
    const [selectedElementId, setSelectedElementId] = useState<string | null>(
        null,
    );

    const [highlightSchema, setHighlightSchema] = useState<
        HighlightFeedbackElement[]
    >([]);

    const selectedElementData = useMemo(
        () =>
            selectedElementId
                ? highlightSchema.filter(
                      (item) => item.id === selectedElementId,
                  )[0]
                : null,
        [highlightSchema, selectedElementId],
    );
    function addOnClickListener() {
        console.log(ids);
        ids.forEach((id) => {
            const element = document.getElementById(id)!;
            element.onclick = (e: any) => {
                e.preventDefault();
                setSelectedElementId(e.target?.id);
                setPreviewEnabled(true);
                // message.success(
                //     {
                //         content: `Selected id: ${id}`,
                //     },
                //     2,
                // );
            };
            element.style.cursor = "pointer";
        });
    }

    useEffect(() => {
        // if (previewEnabled) return;
        addOnClickListener();
    }, [elementIds, previewEnabled]);

    const handleSetFocus = () => {
        setHighlightSchema((prev) => {
            const idExists =
                prev && prev.find((item) => item.id === selectedElementId);
            if (idExists) {
                return prev.filter((item) => item.id !== selectedElementId);
            }
            const message_id = `${selectedElementId}/feedback`;
            setElementIds((prev) => [...prev, message_id]);
            return [
                ...prev,
                {
                    id: selectedElementId!,
                    message: {
                        message_id,
                        container: {
                            width: 0,
                            height: 0,
                            position: "top",
                            backgroundColor: "#ffffff",
                            gap: 10,
                        },
                        text: [],
                        targets: [],
                    },
                    arrow: {} as any,
                },
            ];
        });
    };

    const handlefeedbackAttribtesChange = (e: any) => {
        setHighlightSchema((prev) => {
            if (!prev) return [];
            return prev.map((item) => {
                if (item.id === selectedElementId) {
                    const temp = item;
                    temp.message!.container = {
                        ...temp.message!.container,
                        [e.target.name]: e.target.value,
                    };
                    return temp;
                }
                return item;
            });
        });
    };

    const addTarget = () => {
        setHighlightSchema((prev) =>
            prev.map((item) => {
                if (item.id === selectedElementId) {
                    const temp = item;
                    temp.message?.targets?.push({
                        target_id: "",
                        curr_position: "top",
                        target_position: "bottom",
                    });
                    return temp;
                }
                return item;
            }),
        );
    };

    const deleteTarget = (idx: number) => {
        setHighlightSchema((prev) =>
            prev.map((item) => {
                if (item.id === selectedElementId) {
                    const temp = item;
                    temp.message!.targets = temp.message?.targets?.filter(
                        (_, i) => i !== idx,
                    );
                }
                return item;
            }),
        );
    };

    const handleTargetDataChange = (e: any, idx: number) => {
        setHighlightSchema((prev) =>
            prev.map((item) => {
                if (item.id === selectedElementId) {
                    const temp = item;
                    temp.message!.targets = temp.message!.targets!.map(
                        (data, i) => {
                            if (idx === i) {
                                return {
                                    ...data,
                                    [e.target.name]: e.target.value,
                                };
                            }
                            return data;
                        },
                    );
                }
                return item;
            }),
        );
    };

    useEffect(() => {
        setHighlight(previewEnabled, highlightSchema);
    }, [highlightSchema, previewEnabled]);

    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                overflow: "scroll",
                zIndex: 2,
            }}
            onScroll={() => {
                setHighlight(previewEnabled, highlightSchema);
            }}
        >
            <Card
                style={{
                    zIndex: 3,
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    onClick={() => setPreviewEnabled((prev) => !prev)}
                    style={{ zIndex: "3" }}
                    type="primary"
                    danger={previewEnabled}
                >
                    {previewEnabled ? "Cancel preview" : "Preview"}
                </Button>
            </Card>
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    padding: 0,
                    zIndex: 2,
                    // border: "1px solid gray",
                }}
            >
                {children}
            </div>
            <Card
                style={{
                    zIndex: 2,
                    scrollBehavior: "smooth",
                    padding: 0,
                    gap: "16px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "16px",
                        width: "100%",
                        height: "max-content",
                        padding: "0 4px",
                    }}
                >
                    <Select
                        placeholder="Select id"
                        showSearch
                        value={selectedElementId}
                        style={{
                            flex: 0.5,
                            width: "100%",
                        }}
                        onChange={(value) => setSelectedElementId(value)}
                        options={elementIds.map((id) => ({
                            value: id,
                            label: `id: ${id}`,
                        }))}
                    />

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: "16px",
                            flex: 1,
                        }}
                    >
                        <Typography style={{ fontSize: "18px" }}>
                            Highlight component
                        </Typography>
                        <Switch
                            onChange={handleSetFocus}
                            disabled={selectedElementId === null}
                            checked={
                                highlightSchema.find(
                                    (item) => item.id === selectedElementId,
                                ) !== undefined
                            }
                        />
                    </div>
                </div>
                {highlightSchema.find(
                    (item) => item.id === selectedElementId,
                ) && (
                    <>
                        <Divider />
                        <div
                            style={{
                                border: "2px dotted black",
                                padding: "10px 16px",
                                borderRadius: "10px",
                                width: "100%",
                            }}
                        >
                            <SlateEditor
                                id={`${selectedElementId}_text`}
                                isFocused={true}
                                value={selectedElementData?.message?.text ?? []}
                                onChange={(value: any) =>
                                    setHighlightSchema((prev) =>
                                        prev.map((item) => {
                                            if (item.id === selectedElementId) {
                                                return {
                                                    ...item,
                                                    message: {
                                                        ...item.message,
                                                        text: value,
                                                    },
                                                } as any;
                                            }
                                            return item;
                                        }),
                                    )
                                }
                                isNew={true}
                                required={false}
                                v2={true}
                            />
                        </div>
                        <Divider />
                        <Typography
                            style={{
                                width: "100%",
                                fontSize: "24px",
                                fontWeight: 600,
                                marginBottom: "12px",
                            }}
                        >
                            Feedback Styling
                        </Typography>
                        <Row style={{ gap: "20px" }}>
                            <Col span={5}>
                                <Input
                                    type="number"
                                    placeholder="Width"
                                    name="width"
                                    value={
                                        selectedElementData?.message?.container
                                            .width ?? ""
                                    }
                                    onChange={handlefeedbackAttribtesChange}
                                />
                            </Col>
                            <Col span={5}>
                                <Input
                                    type="number"
                                    placeholder="Height"
                                    name="height"
                                    value={
                                        selectedElementData?.message?.container
                                            .height ?? ""
                                    }
                                    onChange={handlefeedbackAttribtesChange}
                                />
                            </Col>
                            <Col span={5}>
                                <Select
                                    options={positionDropdownValues}
                                    placeholder="Select position"
                                    style={{ width: "100%" }}
                                    value={
                                        selectedElementData?.message?.container
                                            .position ?? ""
                                    }
                                    onChange={(value) =>
                                        handlefeedbackAttribtesChange({
                                            target: {
                                                name: "position",
                                                value,
                                            },
                                        })
                                    }
                                />
                            </Col>
                            <Col span={5}>
                                <Input
                                    placeholder="Background color"
                                    name="backgroundColor"
                                    onChange={handlefeedbackAttribtesChange}
                                    value={
                                        selectedElementData?.message?.container
                                            .backgroundColor ?? ""
                                    }
                                />
                            </Col>
                            <Col span={5}>
                                <Input
                                    type="number"
                                    placeholder="Gap"
                                    name="gap"
                                    onChange={handlefeedbackAttribtesChange}
                                    value={
                                        selectedElementData?.message?.container
                                            .gap ?? ""
                                    }
                                    min={10}
                                />
                            </Col>
                            <Col span={5}>
                                <Input
                                    placeholder="Arrow type"
                                    name="arrow_type"
                                    onChange={handlefeedbackAttribtesChange}
                                />
                            </Col>
                            <Col span={5}>
                                <Input
                                    placeholder="Arrow color"
                                    name="arrow_color"
                                    onChange={handlefeedbackAttribtesChange}
                                />
                            </Col>
                        </Row>
                        <Divider />
                        <Row
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "12px",
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: "24px",
                                    fontWeight: 600,
                                }}
                            >
                                Feedback Targets
                            </Typography>
                            <Tooltip title="Add target">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                    onClick={addTarget}
                                />
                            </Tooltip>
                        </Row>
                        {selectedElementData?.message?.targets?.map(
                            (item, idx) => {
                                return (
                                    <Row
                                        style={{
                                            gap: "20px",
                                            marginBottom: "10px",
                                        }}
                                        key={item.target_id}
                                    >
                                        <Col span={4}>
                                            <Select
                                                placeholder="Target id"
                                                showSearch
                                                value={
                                                    item.target_id.length
                                                        ? item.target_id
                                                        : null
                                                }
                                                style={{
                                                    width: "100%",
                                                }}
                                                onChange={(value) =>
                                                    handleTargetDataChange(
                                                        {
                                                            target: {
                                                                name: "target_id",
                                                                value,
                                                            },
                                                        },
                                                        idx,
                                                    )
                                                }
                                                options={elementIds.map(
                                                    (id) => ({
                                                        value: id,
                                                        label: `id: ${id}`,
                                                    }),
                                                )}
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <Select
                                                options={positionDropdownValues}
                                                placeholder="Select current position"
                                                style={{ width: "100%" }}
                                                value={item.curr_position}
                                                onChange={(value) =>
                                                    handleTargetDataChange(
                                                        {
                                                            target: {
                                                                name: "curr_position",
                                                                value,
                                                            },
                                                        },
                                                        idx,
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <Select
                                                options={positionDropdownValues}
                                                placeholder="Select target position"
                                                style={{ width: "100%" }}
                                                value={item.target_position}
                                                onChange={(value) =>
                                                    handleTargetDataChange(
                                                        {
                                                            target: {
                                                                name: "target_position",
                                                                value,
                                                            },
                                                        },
                                                        idx,
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <Input
                                                placeholder="Arrow Type"
                                                name="arrow_type"
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <Input
                                                placeholder="Arrow Color"
                                                name="arrow_color"
                                            />
                                        </Col>
                                        <Col>
                                            <Tooltip title="Delete target">
                                                <Button
                                                    type="primary"
                                                    shape="circle"
                                                    icon={<DeleteOutlined />}
                                                    danger
                                                    style={{
                                                        marginLeft: "10px",
                                                    }}
                                                    onClick={() =>
                                                        deleteTarget(idx)
                                                    }
                                                />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                );
                            },
                        )}
                    </>
                )}
            </Card>
        </div>
    );
};

export default CreateHighlight;
