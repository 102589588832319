import { BLOCK_TYPES } from "../../common/index";
import { PUZZLE_OTHER } from "../Crossword/defaultValue";
const { INPUT_ARRANGE_LIST_BLOCK } = BLOCK_TYPES;

const INPUT_ARRANGE_LIST_DEFAULT_VALUE = {
    type: INPUT_ARRANGE_LIST_BLOCK,
    data: {
        [INPUT_ARRANGE_LIST_BLOCK]: {
            allow_repeat: false,
            custom_keyboard: [],
            digit_length: 4,
            input_type: {
                type: "fixed", // min-max / fixed
                minimum: 0,
                maximum: 5,
                fixed: 5,
            },
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
        other: {
            ...PUZZLE_OTHER,
        },
    },
};

export default INPUT_ARRANGE_LIST_DEFAULT_VALUE;
