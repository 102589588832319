import renderBlockV2FillBlank from "./renderBlock";
import renderEditorV2FillBlank from "./renderEditor";
import isValidCallbackV2FillBlank from "./isValid";
import V2_FILL_BLANK_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2FillBlank from "./renderSettings";
import renderCardV2FillBlank from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { V2_FILL_BLANK_BLOCK } = BLOCK_TYPES;

const V2_FILL_BLANK_BLOCK_CONFIG = {
    [V2_FILL_BLANK_BLOCK]: {
        label: "V2 Fill Blank",
        defaultValue: V2_FILL_BLANK_DEFAULT_VALUE,
        renderBlock: renderBlockV2FillBlank,
        renderEditor: renderEditorV2FillBlank,
        renderCard: renderCardV2FillBlank,
        renderSettings: renderSettingsV2FillBlank,
        addCallback,
        isValidCallback: isValidCallbackV2FillBlank,
    },
};

export {
    renderBlockV2FillBlank,
    renderCardV2FillBlank,
    renderEditorV2FillBlank,
    renderSettingsV2FillBlank,
    isValidCallbackV2FillBlank,
    V2_FILL_BLANK_DEFAULT_VALUE,
    V2_FILL_BLANK_BLOCK_CONFIG,
};
