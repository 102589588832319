import { gql } from "@apollo/client";

export default gql`
    query GetUser($skip: Int!) {
        personalized_learning_user(limit: 100, order_by: {id: asc}, offset: $skip) {
            class_name
            created_at
            email
            gender
            id
            mobile
            name
            other
            type
            updated_at
        }
    }
`;
