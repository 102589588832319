import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import { withHookForTab, withUpdateTab } from "src/modules/tab/operations";
import { compose } from "src/helpers";

import EditView from "./component";

const Edit = (props) => {
    const { updateTab } = props;
    const router = useRouter();
    const {
        query: { redirect_to },
    } = router;

    const onFinish = async (values: any) => {
        console.log({ id: values.id, object: values });
        await updateTab({ id: values.id, object: values });
        router.push(redirect_to);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <EditView
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            disableEditType={true}
            {...props}
        />
    );
};

export default compose(withHookForTab, withUpdateTab)(Edit);
