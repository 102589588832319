import {
    CheckOutlined,
    DeleteOutlined,
    DownOutlined,
    PlusSquareFilled,
    UpOutlined,
} from "@ant-design/icons";
import EditIcon from "src/components/EditIcon";
// // IMAGE_BLOCK
import { Field, RenderField, RenderUpload } from "src/components/form";
import { SlateEditor } from "src/components/plate";
import { getOptionIndex } from "src/helpers/functions";
// // TEXT_BLOCK
import {
    Button,
    Card,
    Checkbox,
    Col,
    Empty,
    Form,
    Input,
    Alert,
    message,
    Row,
    Select,
    Tag,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import _ from "lodash";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { v4 as uuid } from "uuid";
import { renderer } from "./renderer";

const { Option } = Select;

const { TextArea } = Input;

export const BLOCK_TYPES = {
    TEXT_BLOCK: "text",
    IMAGE_BLOCK: "image",
    MCQ_BLOCK: "mcq",
    CASE_STUDY_BLOCK: "case_study",
    SUBJECTIVE_BLOCK: "subjective",
    VIDEO_BLOCK: "video",
};

const {
    TEXT_BLOCK,
    IMAGE_BLOCK,
    MCQ_BLOCK,
    CASE_STUDY_BLOCK,
    SUBJECTIVE_BLOCK,
    VIDEO_BLOCK,
} = BLOCK_TYPES;

const MCQ_DEFAULT_VALUE = {
    type: MCQ_BLOCK,
    data: {
        mcq: {
            text: [],
            options: [[], []],
            correct_options: [],
            solution: [],
        },
    },
};

const isEditorEmpty = (value: any) => {
    if (!value.length) return true;
    return value.every((i: any) => {
        if (
            ["p"].includes(i.type) &&
            i.children.every(({ text }: any) => !text)
        )
            return true;

        if (i.type == "latex" && !i.latex) return true;

        if (i.type == "img" && !i.url) return true;

        return false;
    });
};

const renderCardMCQ = (props: any) => {
    const {
        block: {
            data: {
                mcq: { text },
            },
        },
    } = props;
    return (
        <div {...props}>
            <div style={{ height: "40px", overflow: "hidden" }}></div>
        </div>
    );
};

const renderEditorMCQ = ({
    block,
    setBlock,
    worksheet,
    isEditor,
    setIsEditor,
    currentBlock,
    isNewBlock,
    setIsNewBlock,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            mcq: { text, options, correct_options, solution },
        },
    } = block;

    const [currentEditor, setCurrentEditor] = useState(`${id || tmpId}_text`);
    const [hasSolution, setHasSolution] = useState(false);
    const [isNew, setIsNew] = useState(false);
    useEffect(() => {
        setIsNew(isNewBlock);
    }, [id, tmpId]);
    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_text`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            if (!isNewBlock) {
                setCurrentEditor("");
                setIsNew(false);
            }
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    return (
        <div>
            <div>
                <SlateEditor
                    id={`${id || tmpId}_text`}
                    onChange={(value: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", "mcq", "text"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    value={text}
                    isFocused={currentEditor == `${id || tmpId}_text`}
                    setEditor={(id: string) => setCurrentEditor(id)}
                    isNew={isNew}
                />
            </div>
            <div>
                {!isNew && !correct_options[0] && (
                    <Alert
                        description={"Please select a correct option"}
                        style={{
                            marginBottom: "5px",
                            backgroundColor: "#FFD8D5",
                            border: "1px solid #FF4343",
                            width: "100%",
                        }}
                    />
                )}
                <h3 style={{ fontWeight: "bold" }}>Options</h3>
                {options.map((option: any, idx: number) => {
                    return (
                        <Row
                            key={idx}
                            justify="start"
                            style={{
                                borderRadius: "15px",
                                marginBottom: "10px",
                            }}
                        >
                            <Col
                                span={1}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Row justify={"center"} align={"middle"}>
                                    <Col
                                        style={{
                                            padding: "10px 0px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                borderRadius: "50%",
                                                width: "28px",
                                                height: "28px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                background:
                                                    correct_options.length &&
                                                    correct_options[0] ==
                                                        idx + 1
                                                        ? "#4EDEB2"
                                                        : "lightgray",
                                                color: "#333333",
                                                display: "inline-flex",
                                                fontSize: "15px",
                                                fontWeight: "bold",
                                                paddingTop: "3px",
                                            }}
                                        >
                                            {getOptionIndex(idx)}
                                        </div>
                                    </Col>
                                    <Col>
                                        <Checkbox
                                            onChange={() => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "data",
                                                        "mcq",
                                                        "correct_options",
                                                    ],
                                                    [idx + 1],
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                            checked={
                                                correct_options.length &&
                                                correct_options[0] == idx + 1
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                span={
                                    currentEditor ==
                                    `${id || tmpId}_option_${idx}`
                                        ? 22
                                        : 23
                                }
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "5px",
                                        marginLeft: "14px",
                                    }}
                                >
                                    <SlateEditor
                                        id={`${id || tmpId}_option_${idx}`}
                                        onChange={(value: any) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                ["data", "mcq", "options", idx],
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                        value={option}
                                        isFocused={
                                            currentEditor ==
                                            `${id || tmpId}_option_${idx}`
                                        }
                                        setEditor={(id: string) =>
                                            setCurrentEditor(id)
                                        }
                                        isOption={true}
                                        isNew={isNew}
                                    />
                                </div>
                            </Col>
                            {currentEditor ==
                                `${id || tmpId}_option_${idx}` && (
                                <Col span={1} style={{ paddingTop: "10px" }}>
                                    <Row justify={"center"}>
                                        <Button
                                            icon={<DeleteOutlined />}
                                            type="primary"
                                            shape="circle"
                                            danger
                                            size="small"
                                            onClick={() => {
                                                if (options.length == 2) {
                                                    message.warning(
                                                        `Need at least 2 option`,
                                                    );
                                                    return;
                                                }

                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                const tmpOptions = [...options];
                                                _.pullAt(tmpOptions, idx);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    ["data", "mcq", "options"],
                                                    tmpOptions,
                                                );

                                                if (
                                                    correct_options[0] &&
                                                    idx < correct_options[0]
                                                )
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "data",
                                                            "mcq",
                                                            "correct_options",
                                                        ],
                                                        [],
                                                    );

                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    );
                })}
                <Button
                    type="dashed"
                    onClick={() => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", "mcq", "options", options.length],
                            [],
                        );
                        setBlock(tmpBlock);
                        setCurrentEditor(
                            `${id || tmpId}_option_${options.length}`,
                        );
                    }}
                    icon={<PlusSquareFilled />}
                >
                    Add Option
                </Button>
                <br />
                <br />
            </div>

            {worksheet?.type == "timed" && (
                <div style={{ width: "50%", marginTop: "20px" }}>
                    <Field
                        name={"duration"}
                        component={RenderField}
                        placeholder={"Add time in seconds"}
                        type="text"
                        label={<h3>Duration</h3>}
                        value={block?.data?.other?.duration || 0}
                        type={"number"}
                        onChange={onDurationChange}
                    />
                </div>
            )}

            {hasSolution ? (
                <div>
                    <h3>Solution</h3>
                    <SlateEditor
                        id={`${id || tmpId}_solution`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "mcq", "solution"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        value={solution}
                        isFocused={currentEditor == `${id || tmpId}_solution`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                        required={false}
                    />
                </div>
            ) : (
                <Button
                    type="dashed"
                    onClick={() => setHasSolution(true)}
                    icon={<PlusSquareFilled />}
                >
                    Add Solution
                </Button>
            )}
            {/* <div
        style={{
          border: "1px dashed #808080",
          margin: "10px auto",
          padding: "10px 10px"
        }}
      >
        <h3>Correct Option</h3>

        <div>
          <Form.Item
            rules={[
              {
                required: true
              }
            ]}
            validateStatus={!correct_options[0] ? "error" : ""}
            help={!correct_options[0] ? "Field cannot be empty" : ""}
          >
            <Select
              value={correct_options[0] || null}
              style={{ width: 120 }}
              onChange={(value: any) => {
                let tmpBlock = _.cloneDeep(block);
                tmpBlock = _.set(tmpBlock, ["data", "mcq", "correct_options"], [value]);
                setBlock(tmpBlock);
              }}
            >
              {options.map((option: any, idx: number) => {
                return (
                  <Option value={idx + 1} key={idx}>
                    {idx + 1}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </div> */}
        </div>
    );
};

const hasWord = (input: string): boolean => {
    input = input?.toLowerCase();
    const words = ["long", "case", "subjective"];
    for (const word of words) {
        if (input?.includes(word)) return true;
    }
    return false;
};

export const renderTags = (tags: any) => {
    if (tags && tags.length && "value" in tags[0]) {
        return (
            <div>
                {tags?.map(({ value: tag }: any, index: number) => {
                    const { value } = JSON.parse(tag);

                    return (
                        <Tag
                            key={index}
                            style={{
                                fontSize: "12px",
                                color: "#333333",
                                fontFamily: "Manrope",
                            }}
                        >
                            <img
                                src={
                                    hasWord(value)
                                        ? "/icons/questions/question_tag_long.svg"
                                        : "/icons/questions/question_tag.svg"
                                }
                                alt="Early Access"
                                width="15px"
                                height="10px"
                            />
                            <span
                                style={{
                                    marginLeft: "4px",
                                }}
                            >
                                {value}
                            </span>
                        </Tag>
                    );
                })}
            </div>
        );
    }

    return (
        <div>
            {tags?.map(({ tag: { value } }: any, index: number) => (
                <Tag
                    key={index}
                    style={{
                        fontSize: "12px",
                        color: "#333333",
                        fontFamily: "Manrope",
                    }}
                >
                    <img
                        src={
                            hasWord(value)
                                ? "/icons/questions/question_tag_long.svg"
                                : "/icons/questions/question_tag.svg"
                        }
                        alt="Early Access"
                        width="15px"
                        height="10px"
                    />
                    <span
                        style={{
                            marginLeft: "4px",
                        }}
                    >
                        {value}
                    </span>
                </Tag>
            ))}
        </div>
    );
};

export const SolutionBlock = ({ solution }: any) => {
    const [showSolution, setSolution] = useState(false);
    return (
        <div
            style={{
                margin: " 0 12px",
            }}
        >
            <Button
                type="ghost"
                onClick={() => setSolution(!showSolution)}
                style={{
                    border: "1px dashed #ACACAC",
                    borderRadius: "8px",
                    padding: "8px",
                    height: "40px",
                    marginBottom: "8px",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "14px",
                    color: "#333333",
                }}
            >
                {showSolution ? "Hide " : "Show "}Solution
                {showSolution ? <UpOutlined /> : <DownOutlined />}
            </Button>
            {showSolution && (
                <Card
                    style={{
                        padding: 0,
                        margin: "12px 0",
                    }}
                    bodyStyle={{
                        padding: "24px",
                        border: " 1px solid #ACACAC",
                        borderRadius: "8px",
                    }}
                >
                    {renderer(solution)}
                </Card>
            )}
        </div>
    );
};

const renderBlockMCQ = (block: any) => {
    const {
        data: {
            mcq: { text, options, correct_options, solution },
            other,
        },
        heading,
        tags,
        questionCount,
        isBank = false,
        isLive = false,
    } = block;

    return (
        <div>
            <div style={{ display: "flex", padding: "12px" }}>
                {questionCount && (
                    <span
                        style={{
                            marginRight: "8px",
                            fontSize: "16px",
                            flexShrink: 0,
                        }}
                    >
                        Q {questionCount}
                    </span>
                )}
                {renderTags(tags)}
                {other?.duration && (
                    <span>Duration: {other?.duration} seconds</span>
                )}
            </div>
            <div>
                {heading && <h3>Question Text</h3>}
                <div
                    style={{
                        padding: "12px",
                        fontSize: "16px",
                        color: "#000000",
                    }}
                >
                    {renderer(text)}
                </div>
            </div>

            {heading && <h3>Options</h3>}
            {options.map((option: any, idx: number) => (
                <div key={idx}>
                    <div
                        style={{
                            padding: "12px",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "16px",
                            color: "#000000",
                            maxWidth: "100%",
                        }}
                    >
                        <span
                            style={{
                                height: "36px",
                                width: "36px",
                                background:
                                    !isLive && correct_options[0] == idx + 1
                                        ? "#4EDEB2"
                                        : "#D9D9D9",
                                borderRadius: "50%",
                                marginRight: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexShrink: 0,
                            }}
                        >
                            {(idx + 10).toString(36).toUpperCase()}
                        </span>
                        <div>{renderer(option)}</div>
                    </div>
                </div>
            ))}

            {!isLive && !isBank && solution && solution.length > 0 && (
                <div>
                    {heading && <h3>Solution</h3>}
                    <SolutionBlock solution={solution} />
                </div>
            )}
        </div>
    );
};

const renderSettingsMCQ = (props: any) => {
    const { block, setBlock, currentSubBlock, setShow } = props;
    return (
        <Row
            justify={"space-between"}
            style={{ flexFlow: "column", height: "100%" }}
        >
            {/* <Col>
                <TagsAssignComponent
                    key={
                        block.id + (currentSubBlock ? `${currentSubBlock}` : ``)
                    }
                    handleChange={(data: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(tmpBlock, ["tags"], data);
                        setBlock(tmpBlock);
                    }}
                    selected={
                        block.tags?.map(
                            (
                                { label, value, key, tag }: any,
                                index: number,
                            ) => {
                                return {
                                    value: value || JSON.stringify(tag),
                                    key: key || tag.id,
                                    label:
                                        label || `${tag.value} (${tag.name})`,
                                };
                            },
                        ) || []
                    }
                />
            </Col> */}
            {/* <Col>
        <Card onClick={() => setShow(true)} hoverable>
          <Row justify={"center"}>
            <PlusSquareFilled color="#3030ED" style={{ fontSize: "60px" }} />
          </Row>
          <br />
          <Row justify={"center"}>
            <span style={{ textAlign: "center", fontWeight: "bold", fontSize: "25px" }}>Add from Question Bank</span>
          </Row>
        </Card>
      </Col> */}
        </Row>
    );
};

const isValidCallbackMCQ = ({ block }: any) => {
    if (!block || !block.data)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            mcq: { text, options, correct_options },
        },
    } = block;
    return {
        isValid:
            !isEditorEmpty(text) &&
            !options.some((option: any) => isEditorEmpty(option)) &&
            Boolean(correct_options.length),
        error: (
            // <Alert
            //     message="Validation Error"
            //     description={`Question Text, options, correct option cannot be empty`}
            //     type="error"
            //     showIcon
            // />
            <></>
        ),
    };
};

const addCallback = ({ setSaveCounter, currentBlocks }: any) => {
    // setSaveCounter({ currentBlocks });
};

// const DurationPicker = ({ initialValue, onChange }: any) => {
//   const [minutes, setMinutes] = useState(parseInt(initialValue / 60));
//   const [seconds, setSeconds] = useState(initialValue % 60);
//   useEffect(() => {
//     if (initialValue) {
//       setMinutes(parseInt(initialValue / 60));
//       setSeconds(initialValue % 60);
//     }
//   }, [initialValue]);

//   return (
//     <Input.Group size="large">
//       <Row gutter={8}>
//         <Col span={5}>
//           <InputNumber
//             addonBefore="Minutes"
//             min={0}
//             value={minutes}
//             onChange={(value: any) => {
//               setMinutes(value);
//               onChange(value * 60 + seconds);
//             }}
//           />
//         </Col>
//         <Col span={8}>
//           <InputNumber
//             addonBefore="Seconds"
//             min={0}
//             value={seconds}
//             onChange={(value: any) => {
//               setSeconds(value);
//               onChange(minutes * 60 + value);
//             }}
//           />
//         </Col>
//       </Row>
//     </Input.Group>
//   );
// };

export const BLOCK_DATA = {
    [TEXT_BLOCK]: {
        label: "Text",
        defaultValue: {
            type: TEXT_BLOCK,
            data: {
                [TEXT_BLOCK]: "",
            },
        },
        renderBlock: (block: any) => {
            const {
                data: { text },
            } = block;
            return <div>{renderer(text)}</div>;
        },
        renderEditor: ({ block, setBlock, isNewBlock }: any) => {
            const value = block?.data?.text;
            const [isNew, setIsNew] = useState(false);
            useEffect(() => {
                setIsNew(isNewBlock);
            }, [block.id, block.tmpId]);
            const isValid = Boolean(value.trim());
            return (
                <div>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        validateStatus={!isNew && !isValid ? "error" : ""}
                        help={!isNew && !isValid ? "Field cannot be empty" : ""}
                    >
                        <TextArea
                            style={{
                                background: "#F4F4F4",
                            }}
                            autoSize={{ minRows: 10 }}
                            value={value || ""}
                            onChange={(e) => {
                                if (isNew) {
                                    setIsNew(false);
                                }
                                setBlock({
                                    ...block,
                                    data: { text: e.target.value },
                                });
                            }}
                            placeholder={
                                "Type your text here.\n\nYou can add as long as a text you want"
                            }
                        />
                    </Form.Item>
                </div>
            );
        },
        renderCard: (props: any) => {
            const {
                block: { data },
            } = props;
            return (
                <div {...props}>
                    <div style={{ height: "40px", overflow: "hidden" }}>
                        {data.text.length
                            ? data.text.slice(0, 30) +
                              (data.text.length <= 30 ? `` : `...`)
                            : "Text Block"}
                    </div>
                </div>
            );
        },
        renderSettings: () => {
            return <Empty description={"Nothing to show."} />;
        },
        isValidCallback: ({ block }: any) => {
            const {
                data: { text },
            } = block;
            return {
                isValid: Boolean(text.trim()),
                error: (
                    // <Alert
                    //     message="Validation Error"
                    //     description={`Text cannot be empty`}
                    //     type="error"
                    //     showIcon
                    // />
                    <></>
                ),
            };
        },
    },
    [IMAGE_BLOCK]: {
        label: "Image",
        defaultValue: {
            type: IMAGE_BLOCK,
            data: {
                image: "",
                other: { duration: 0 },
            },
        },
        renderBlock: (block: any) => {
            const {
                data: { image, other },
            } = block;
            return (
                <div style={{ maxWidth: "872px", margin: "0 auto" }}>
                    {other?.duration && (
                        <span>Duration: {other?.duration}</span>
                    )}
                    <img src={image} style={{ width: "100%" }} />
                </div>
            );
        },
        renderEditor: ({ block, setBlock, worksheet, isNewBlock }: any) => {
            const imageUrl = block?.data?.image;
            const [isEditState, setEditState] = useState(!imageUrl);
            const [isNew, setIsNew] = useState(false);
            useEffect(() => {
                setIsNew(isNewBlock);
            }, [block.id, block.tmpId]);
            const onChange = (e: any) => {
                setBlock({
                    ...block,
                    data: { ...block.data, image: e.target.value },
                });
                setEditState(false);
            };

            const onDurationChange = (duration: number) => {
                const obj = {
                    ...block,
                    data: {
                        ...block.data,
                        other: { ...block.data.other, duration },
                    },
                };
                setBlock(obj);
            };
            useEffect(() => {
                setEditState(!imageUrl);
            }, [imageUrl]);

            const isValid = Boolean(imageUrl.trim());
            return (
                <div style={{ width: "100%" }}>
                    {isEditState ? (
                        <Row justify={"start"}>
                            <RenderUpload
                                path="home-explore/document/"
                                onChangeCustom={onChange}
                                label="upload an image"
                                value={imageUrl}
                                meta={{
                                    touched: true,
                                    error:
                                        !isNew && !isValid
                                            ? "Field cannot be Empty"
                                            : "",
                                }}
                            />
                        </Row>
                    ) : (
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "100%" }}>
                                <img src={imageUrl} style={{ width: "100%" }} />
                            </div>

                            {imageUrl && (
                                <RenderUpload
                                    path="home-explore/document/"
                                    onChangeCustom={onChange}
                                    label="upload an image"
                                    value={imageUrl}
                                    editButton={true}
                                />
                            )}
                        </div>
                    )}
                    {worksheet?.type == "timed" && (
                        <div style={{ width: "50%", marginTop: "20px" }}>
                            <Field
                                name={"duration"}
                                component={RenderField}
                                placeholder={"Add time in seconds"}
                                type="text"
                                label={<h3>Duration</h3>}
                                value={block?.data?.other?.duration || 0}
                                type={"number"}
                                onChange={onDurationChange}
                            />
                        </div>
                    )}
                </div>
            );
        },
        renderCard: (props: any) => {
            const {
                block: { data },
            } = props;
            return (
                <div {...props}>
                    {!data.image ? (
                        <div>{"Image Block"}</div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <img src={data.image} style={{ height: "30px" }} />
                        </div>
                    )}
                </div>
            );
        },
        renderSettings: () => {
            return <Empty description={"Nothing to show."} />;
        },
        isValidCallback: ({ block }: any) => {
            const {
                data: { image },
            } = block;
            return {
                isValid: Boolean(image.trim()),
                error: (
                    <div>
                        {/* <b>Validation Error: </b>
                     <span
                         style={{
                             color: "red",
                             textDecoration: "underline",
                             fontStyle: "italic",
                         }}
                     >
                         Image block cannot be Empty
                     </span> */}
                    </div>
                ),
            };
        },
    },
    [MCQ_BLOCK]: {
        label: "Multiple Choice Question",
        defaultValue: MCQ_DEFAULT_VALUE,
        renderBlock: renderBlockMCQ,
        renderEditor: renderEditorMCQ,
        renderCard: renderCardMCQ,
        renderSettings: renderSettingsMCQ,
        addCallback,
        isValidCallback: isValidCallbackMCQ,
    },
    [CASE_STUDY_BLOCK]: {
        label: "Case Study Question",
        defaultValue: () => ({
            type: CASE_STUDY_BLOCK,
            data: {
                [CASE_STUDY_BLOCK]: {
                    text: [],
                },
            },
            children: [
                {
                    ...MCQ_DEFAULT_VALUE,
                    order: 1,
                    tmpId: uuid(),
                },
            ],
        }),
        renderBlock: (block: any) => {
            const {
                data: {
                    case_study: { text },
                },
                children,
                heading,
                tags,
                questionCount,
                isLive = false,
            } = block;

            let subQuestionsCount = 0;
            return (
                <div>
                    <div>
                        {heading && <h3>Case Study Text</h3>}
                        <div style={{ display: "flex", padding: "12px" }}>
                            {questionCount && (
                                <span
                                    style={{
                                        marginRight: "8px",
                                        fontSize: "16px",
                                    }}
                                >
                                    Q {questionCount}
                                </span>
                            )}
                            {renderTags(tags)}
                        </div>

                        <div style={{ padding: "12px", marginBottom: "12px" }}>
                            {renderer(text)}
                        </div>
                    </div>

                    {heading && <h3>Questions</h3>}
                    {children.map((child: any, index: number) => {
                        subQuestionsCount++;
                        return (
                            <div
                                style={{
                                    padding: "0 12px",
                                    marginBottom:
                                        children.length === index + 1
                                            ? "12px"
                                            : "24px",
                                }}
                            >
                                <Card
                                    style={{
                                        border: 0,
                                    }}
                                    bodyStyle={{
                                        padding: "12px",
                                        borderRadius: "8px",
                                        border: "1px solid #ACACAC",
                                    }}
                                >
                                    {renderBlockMCQ({
                                        ...child,
                                        heading,
                                        questionCount: subQuestionsCount,
                                        isLive,
                                    })}
                                </Card>
                            </div>
                        );
                    })}
                </div>
            );
        },
        renderEditor: ({
            block,
            setBlock,
            currentSubBlock,
            isEditor,
            setIsEditor,
            currentBlock,
            isNewBlock,
            setIsNewBlock,
        }: any) => {
            const {
                id,
                tmpId,
                data: {
                    case_study: { text },
                },
                children,
            } = block;
            if (currentSubBlock == null) {
                return (
                    <CaseStudyQuestion
                        {...{
                            block,
                            setBlock,
                            currentSubBlock,
                            isEditor,
                            setIsEditor,
                            currentBlock,
                            isNewBlock,
                            setIsNewBlock,
                        }}
                    />
                );
            } else {
                return (
                    <div>
                        {renderEditorMCQ({
                            block: children[currentSubBlock],
                            setBlock: (subBlock: any) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["children", currentSubBlock],
                                    subBlock,
                                );
                                setBlock(tmpBlock);
                            },
                            isEditor,
                            setIsEditor,
                            currentBlock: currentSubBlock + 1,
                            isNewBlock,
                            setIsNewBlock,
                        })}
                    </div>
                );
            }
        },
        renderCard: (props: any) => {
            const {
                block,
                setBlock,
                idx,
                setCurrentBlock,
                currentBlock,
                currentSubBlock,
                setCurrentSubBlock,
                setSaveCounter,
                setIsNewBlock,
            } = props;
            const { children } = block;

            const subCardStyle = { marginLeft: "24px" };

            const SortableItemCustom = SortableElement((props: any) => {
                const { blocks, sid, child } = props;
                return (
                    <div key={`${idx}_${sid}`}>
                        {renderCardMCQ({
                            ...props,
                            child,
                            idx: sid,
                            type: MCQ_BLOCK,
                            block: child,
                            currentBlock:
                                currentBlock == idx ? currentSubBlock : null,
                            currentSubBlock,
                            setCurrentBlock: () => {
                                setCurrentBlock(idx);
                                setCurrentSubBlock(sid);
                            },
                            deleteBlock: (index: number) => {
                                setCurrentSubBlock(null);
                                let tmpBlock = _.cloneDeep(block);
                                const tmpChildren = [...children];
                                _.pullAt(tmpChildren, sid);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["children"],
                                    tmpChildren,
                                );
                                setBlock(tmpBlock, idx);
                            },
                            disableDuplicateAction: true,
                            ribbonColor: "purple",
                            sideIcon: "/icons/worksheet/mcq-block.svg",
                        })}
                    </div>
                );
            });

            const SortableContainerCustom = SortableContainer(
                ({ children }: any) => {
                    return <div>{children}</div>;
                },
            );

            const onSortEnd = ({ oldIndex, newIndex }: any) => {
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = arrayMoveImmutable(
                    children,
                    oldIndex,
                    newIndex,
                ).filter((el) => !!el);
                tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);
                setBlock(tmpBlock, idx);
            };

            return (
                <div>
                    <div {...props}>
                        <div style={{ height: "40px", overflow: "hidden" }}>
                            <RenderValue
                                value={block.data.case_study.text}
                                defaultText="Case Study Block"
                            />
                        </div>
                    </div>

                    <div style={subCardStyle}>
                        <SortableContainerCustom
                            onSortEnd={onSortEnd}
                            useDragHandle
                        >
                            {children.map((child: any, sid: number) => (
                                <SortableItemCustom
                                    {...props}
                                    sid={sid}
                                    key={`nested-item-${
                                        child.id || child.tmpId
                                    }`}
                                    index={sid}
                                    child={child}
                                />
                            ))}
                        </SortableContainerCustom>
                    </div>

                    <div
                        style={subCardStyle}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <div
                            {...props}
                            disableActions={true}
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["children", children.length],
                                    {
                                        ...MCQ_DEFAULT_VALUE,
                                        order: children.length + 1,
                                        tmpId: uuid(),
                                    },
                                );
                                const tmpBlocks = setBlock(tmpBlock, idx);
                                setCurrentBlock(idx);
                                setCurrentSubBlock(children.length);
                                addCallback({
                                    setSaveCounter,
                                    currentBlocks: tmpBlocks,
                                });
                                setIsNewBlock(true);
                            }}
                            idx={`+`}
                            ribbonColor="green"
                        >
                            Add Question
                        </div>
                    </div>
                </div>
            );
        },
        renderSettings: ({
            block,
            setBlock,
            currentSubBlock,
            setShow,
        }: any) => {
            const {
                id,
                tmpId,
                data: {
                    case_study: { text },
                },
                children,
            } = block;

            const renderSettingsCaseStudy = () => (
                <div style={{ height: "100%" }}>
                    {renderSettingsMCQ({
                        block,
                        setBlock,
                        currentSubBlock,
                        setShow,
                    })}
                </div>
            );

            return (
                <div style={{ height: "100%" }}>
                    {currentSubBlock == null
                        ? renderSettingsCaseStudy()
                        : renderSettingsMCQ({
                              block: children[currentSubBlock],
                              setBlock: (subBlock: any) => {
                                  let tmpBlock = _.cloneDeep(block);

                                  tmpBlock = _.set(
                                      tmpBlock,
                                      ["children", currentSubBlock],
                                      subBlock,
                                  );
                                  setBlock(tmpBlock);
                              },
                              currentSubBlock,
                              setShow,
                          })}
                </div>
            );
        },
        addCallback,
        isValidCallback: ({ block, currentSubBlock, currentBlock }: any) => {
            if (
                !block ||
                currentSubBlock > (block?.data?.children?.length || 0)
            )
                return {
                    isValid: true,
                    error: "",
                };

            const {
                data: {
                    case_study: { text },
                },
                children,
            } = block;

            return currentSubBlock == null || block.type == "case_study"
                ? {
                      isValid: !isEditorEmpty(text),
                      error: (
                          //   <Alert
                          //       message="Validation Error"
                          //       description={`Text cannot be empty`}
                          //       type="error"
                          //       showIcon
                          //   />
                          <></>
                      ),
                  }
                : isValidCallbackMCQ({ block: children[currentSubBlock] });
        },
    },
    [SUBJECTIVE_BLOCK]: {
        label: "Subjective Answer Question",
        defaultValue: {
            type: SUBJECTIVE_BLOCK,
            data: {
                [SUBJECTIVE_BLOCK]: {
                    text: [],
                    solution: [],
                },
            },
        },
        renderBlock: (block: any) => {
            const {
                data: {
                    subjective: { text, solution },
                },
                questionCount,
                tags,
                isBank = false,
                isLive = false,
            } = block;
            return (
                <div>
                    <div style={{ display: "flex", padding: "12px" }}>
                        {questionCount && (
                            <span
                                style={{ marginRight: "8px", fontSize: "16px" }}
                            >
                                Q {questionCount}
                            </span>
                        )}
                        {renderTags(tags)}
                    </div>
                    <div style={{ padding: 12 }}>{renderer(text)}</div>
                    {!isLive && !isBank && solution && solution.length > 0 && (
                        <SolutionBlock solution={solution} />
                    )}
                </div>
            );
        },
        renderEditor: ({
            block,
            setBlock,
            isEditor,
            setIsEditor,
            currentBlock,
            setIsNewBlock,
            isNewBlock,
        }: any) => {
            const {
                id,
                tmpId,
                data: {
                    subjective: { text, solution },
                },
            } = block;

            const [currentEditor, setCurrentEditor] = useState(
                `${id || tmpId}_text`,
            );
            const [hasSolution, setHasSolution] = useState(
                solution?.length ? true : false,
            );
            const [isNew, setIsNew] = useState(false);
            useEffect(() => {
                setIsNew(isNewBlock);
            }, [id, tmpId]);

            useEffect(() => {
                setCurrentEditor(`${id || tmpId}_text`);
            }, [id, tmpId]);

            useEffect(() => {
                if (!isEditor) {
                    if (!isNewBlock) {
                        setCurrentEditor("");
                        setIsNew(false);
                    }
                    isNewBlock && setIsNewBlock(false);
                }
            }, [id, tmpId, isEditor]);

            useEffect(() => {
                if (currentEditor != "") {
                    setIsEditor(true);
                }
            }, [id, tmpId, currentEditor]);

            return (
                <>
                    <div>
                        <SlateEditor
                            id={`${id || tmpId}_text`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "subjective", "text"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            isFocused={currentEditor == `${id || tmpId}_text`}
                            setEditor={(id: string) => setCurrentEditor(id)}
                            value={text}
                            isNew={isNew}
                        />
                    </div>

                    {hasSolution ? (
                        <div>
                            <h3>Solution</h3>
                            <SlateEditor
                                id={`${id || tmpId}_solution`}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "subjective", "solution"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                value={solution}
                                isFocused={
                                    currentEditor == `${id || tmpId}_solution`
                                }
                                setEditor={(id: string) => setCurrentEditor(id)}
                                required={false}
                            />
                        </div>
                    ) : (
                        <Button
                            type="dashed"
                            onClick={() => setHasSolution(true)}
                            icon={<PlusSquareFilled />}
                        >
                            Add Solution
                        </Button>
                    )}
                </>
            );
        },
        renderCard: (props: any) => {
            const {
                block: {
                    data: {
                        subjective: { text },
                    },
                },
            } = props;
            return (
                <div {...props}>
                    <div style={{ height: "40px", overflow: "hidden" }}>
                        <RenderValue
                            value={text}
                            defaultText="Subjective Block"
                        />
                    </div>
                </div>
            );
        },
        renderSettings: renderSettingsMCQ,
        addCallback,
        isValidCallback: ({ block }: any) => {
            if (!block)
                return {
                    isValid: true,
                    error: "",
                };
            const {
                data: {
                    subjective: { text },
                },
            } = block;

            return {
                isValid: !isEditorEmpty(text),
                error: (
                    // <Alert
                    //     message="Validation Error"
                    //     description={`Text cannot be empty`}
                    //     type="error"
                    //     showIcon
                    // />
                    <></>
                ),
            };
        },
    },
    [VIDEO_BLOCK]: {
        label: "Video",
        defaultValue: {
            type: VIDEO_BLOCK,
            data: {
                video: "",
            },
        },
        renderBlock: (block: any) => {
            const {
                data: { video },
            } = block;
            return (
                <div>
                    <ReactPlayer
                        controls
                        url={video}
                        style={{ maxWidth: "100%" }}
                    />
                </div>
            );
        },
        renderEditor: ({ block, setBlock }: any) => {
            const videoUrl = block?.data?.video;
            const [isEditState, setEditState] = useState(!videoUrl);
            const onChange = (e: any) => {
                setBlock({ ...block, data: { video: e.target.value } });
                setEditState(false);
            };
            const isValid = Boolean(videoUrl.trim());

            return (
                <div style={{ width: "100%" }}>
                    {isEditState ? (
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            validateStatus={!isValid ? "error" : ""}
                            help={!isValid ? "Field cannot be empty" : ""}
                        >
                            <div style={{ display: "flex" }}>
                                <TextArea
                                    rows={10}
                                    value={block?.data?.video || ""}
                                    onChange={(e) =>
                                        setBlock({
                                            ...block,
                                            data: { video: e.target.value },
                                        })
                                    }
                                />
                                {videoUrl && (
                                    <Button
                                        icon={
                                            <CheckOutlined
                                                onClick={() =>
                                                    setEditState(false)
                                                }
                                            />
                                        }
                                        shape="circle"
                                        type="primary"
                                        style={{ marginLeft: "10px" }}
                                    />
                                )}
                            </div>
                        </Form.Item>
                    ) : (
                        <div style={{ display: "flex" }}>
                            <div>
                                <ReactPlayer
                                    controls
                                    url={videoUrl}
                                    style={{ maxWidth: "100%" }}
                                />
                            </div>
                            {videoUrl && (
                                <EditIcon
                                    isButton={true}
                                    onClick={() => setEditState(true)}
                                    style={{ marginLeft: "10px" }}
                                />
                            )}
                        </div>
                    )}
                </div>
            );
        },
        renderCard: (props: any) => {
            const {
                block: { data },
            } = props;
            return (
                <div {...props}>
                    <div>Video Block</div>
                </div>
            );
        },
        renderSettings: () => {
            return <Empty description={"Nothing to show."} />;
        },
        isValidCallback: ({ block }: any) => {
            const {
                data: { video },
            } = block;

            return {
                isValid: Boolean(video.trim()),
                error: (
                    // <Alert
                    //     message="Validation Error"
                    //     description={`Video Block cannot be empty`}
                    //     type="error"
                    //     showIcon
                    // />
                    <></>
                ),
            };
        },
    },
};

const CaseStudyQuestion = ({
    block,
    setBlock,
    currentSubBlock,
    isEditor,
    setIsEditor,
    currentBlock,
    isNewBlock,
    setIsNewBlock,
}) => {
    const {
        id,
        tmpId,
        data: {
            case_study: { text },
        },
        children,
    } = block;
    const [currentCaseStudyEditor, setCurrentCaseStudyEditor] = useState(
        `${id || tmpId}_text`,
    );
    const [isNew, setIsNew] = useState(false);
    useEffect(() => {
        setIsNew(isNewBlock);
    }, [id, tmpId]);
    useEffect(() => {
        setCurrentCaseStudyEditor(`${id || tmpId}_text`);
    }, [id, tmpId]);
    useEffect(() => {
        if (currentSubBlock == null && !isEditor) {
            if (!isNewBlock) {
                setCurrentCaseStudyEditor("");
                setIsNew(false);
            }
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);
    useEffect(() => {
        if (currentCaseStudyEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentCaseStudyEditor]);
    const renderEditorCaseStudy = () => (
        <div>
            <SlateEditor
                id={`${id || tmpId}_text`}
                onChange={(value: any) => {
                    let tmpBlock = _.cloneDeep(block);
                    tmpBlock = _.set(
                        tmpBlock,
                        ["data", "case_study", "text"],
                        value,
                    );
                    setBlock(tmpBlock);
                }}
                isFocused={currentCaseStudyEditor === `${id || tmpId}_text`}
                value={text}
                setEditor={(id: string) => setCurrentCaseStudyEditor(id)}
                isNew={isNew}
            />
        </div>
    );
    return <>{renderEditorCaseStudy()}</>;
};
