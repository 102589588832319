import { BLOCK_TYPES } from "../../common";
import { renderEditorCrossword } from "../Crossword";

const { INPUT_AND_FORM_BLOCK } = BLOCK_TYPES;

const renderEditor = (props: any) =>
    renderEditorCrossword({
        ...props,
        blockType: INPUT_AND_FORM_BLOCK,
        // showFeedback: true,
    });

export default renderEditor;
