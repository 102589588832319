export const TOGGLE_HIGHLIGHT = "toggle_highlight";
export const AUTO_FOCUS_CELL = "auto_focus_cell";

export const TOGGLE_HIGHLIGHT_PATH = "toggle_highlight_path";
export const REMOVE_ALL_HIGHLIGHTS = "remove_all_highlights";

export const TOGGLE_SELECTION = "toggle_selection";

export const MAIN_TABLE_CELL_SELECTED = "main_table_cell_selected";

export const RESET_SELECTION = "reset_selection";
