import { gql } from "@apollo/client";export default gql`mutation deleteCollection($id: Int!, $submissionIds: [Int!]!) {
    delete_submission_question(where: { id: { _in: $submissionIds } }) {
        affected_rows
    }
    delete_submission(where: { collection_id: { _eq: $id } }) {
        affected_rows
    }
    delete_collection_content(where: { collection_id: { _eq: $id } }) {
        affected_rows
    }
    deleteCollection: delete_collection_by_pk(id: $id) {
        id
    }
}
`;
