import { gql } from "@apollo/client";export default gql`mutation UpdateGroupTabMap(
    $id: Int!
    $_set: home_explore_group_tab_map_set_input = {}
) {
    updateGroupTabMap: update_home_explore_group_tab_map(
        where: { id: { _eq: $id } }
        _set: $_set
    ) {
        affected_rows
    }
}
`;
