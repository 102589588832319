import React, { useState } from "react";
import { Link } from "src/components";
import { Button, Col, Divider, Row, Space, Spin } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";

import DeleteView from "src/modules/meme/components/DeleteView";
import { EditIcon, RenderTable, Layout, MetaTag } from "src/components";

import { FilterComponent } from "src/modules/meme/components";
import { Can } from "src/services/casl";

const ListView: React.FC = (props: any) => {
    const { loading, memes, memeAggregate, loadMemesData } = props;

    const columns = [
        {
            title: "Group",
            key: "group",
            render: (text, record) => record?.group,
        },
        {
            title: "Asset URL",
            key: "asset_url",
            render: (text, record) => record?.asset_url,
        },
        {
            title: "Status",
            key: "status",
            render: (text, record) => record?.status,
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            render: (text, record) => (
                <Space size="middle">
                    <Can I="edit" this={{ ...record }} passThrough>
                        {(allowed) => (
                            <Link href={`/meme/update/${record?.id}`}>
                                <EditIcon isButton={true} disabled={!allowed} />
                            </Link>
                        )}
                    </Can>

                    <Divider />
                    <Can I="delete" a={"meme"} passThrough>
                        {(allowed) => (
                            <DeleteView
                                id={record?.id}
                                isButton={true}
                                disabled={!allowed}
                            />
                        )}
                    </Can>
                </Space>
            ),
        },
    ];

    return (
        <Layout titles={[{ name: "Memes", link: "/meme" }]}>
            <MetaTag title="Memes" />
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Memes List</h1>
                </Col>
                <Col>
                    <Can I="create" a={"meme"}>
                        {(allowed) => (
                            <Link href={"/meme/create"}>
                                <Button
                                    type="primary"
                                    icon={<AppstoreAddOutlined />}
                                    disabled={!allowed}
                                >
                                    Add
                                </Button>
                            </Link>
                        )}
                    </Can>
                </Col>
            </Row>
            <hr />

            {/* <FilterComponent isMeme={true} {...props} /> */}
            <hr />

            <Spin spinning={loading}>
                {Boolean(memes?.length) && (
                    <RenderTable
                        loading={loading}
                        columns={columns}
                        data={memes || []}
                        loadData={loadMemesData}
                        aggregate={memeAggregate?.aggregate?.count}
                    />
                )}
            </Spin>
        </Layout>
    );
};

export default ListView;
