import { useAuth0 } from "@auth0/auth0-react";

import { Button, Layout as AntdLayout, PageHeader } from "antd";
import { useRouter } from "src/helpers";
import React from "react";
import headers from "./util";

const { Header: AntdHeader } = AntdLayout;

export const Header: React.FC = () => {
    const router = useRouter();
    const title = headers(router.asPath);
    const { user, logout } = useAuth0();

    return (
        <>
            <AntdHeader
                className="site-layout-background"
                style={{ padding: 0 }}
            >
                <PageHeader
                    onBack={() => router.back()}
                    title={title}
                    extra={[
                        <span style={{ float: "right", color: "#808080 " }}>
                            (logged in as {user?.email})
                        </span>,

                        <Button
                            type="dashed"
                            danger
                            onClick={() =>
                                logout({
                                    logoutParams: {
                                        returnTo: window.location.origin,
                                    },
                                })
                            }
                        >
                            Logout
                        </Button>,
                    ]}
                />
            </AntdHeader>
        </>
    );
};
