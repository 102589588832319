import { DEFAULT_CUSTOM_KEYBOARD } from "src/components/CustomKeyboardEditor";
import { BLOCK_TYPES } from "../../common/index";
import { v4 as uuid } from "uuid";
const { V2_FILL_BLANK_BLOCK } = BLOCK_TYPES;

export const DEFAULT_VALUE_FILL_BLANK = {
    order: 0,
    type: V2_FILL_BLANK_BLOCK,
    data: {
        [V2_FILL_BLANK_BLOCK]: {
            text: [],
            blanks: [
                {
                    options: [],
                    correct_options: [],
                    correct_answer: [],
                    custom_keys: [
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                    ],
                    custom_keyboard: DEFAULT_CUSTOM_KEYBOARD,
                    is_correct_input: false,
                    variable: "",
                },
            ],
        },
        other: {
            duration: 0,
            audio: "",
        },
    },
};

const getV2FillBlankDefaultValue = () => {
    return {
        tmpId: uuid(),
        ...DEFAULT_VALUE_FILL_BLANK,
    };
};

export default getV2FillBlankDefaultValue;
