import { gql } from "@apollo/client";export default gql`mutation updateQuestion(
    $id: Int!
    $text: String = ""
    $options: [String] = ""
    $correct_option: Int = 0
) {
    updateQuestion: update_question_by_pk(
        pk_columns: { id: $id }
        _question: {
            text: $text
            options: $options
            correct_option: $correct_option
        }
    ) {
        id
        text
        options
        correct_option
    }
}
`;
