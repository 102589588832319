import React from "react";
import Latex from "katex-react";
import parse from "html-react-parser";
import style from "style-to-object";

function isHTML(input: string) {
    return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(
        input,
    );
}

const MathRender = ({ src }) => {
    if (src) {
        return (
            <Latex throwOnError={false} errorColor={"#cc0000"}>
                {src}
            </Latex>
        );
    } else {
        return <></>;
    }
};

export default MathRender;

const removeInlineStyling = [
    "p",
    // "div",
    // "span",
    "sup",
    "sub",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
];

export const TextHtml = ({ html }) => {
    const { domToReact } = parse;

    return (
        <div className="htmlQuestionContainer">
            {parse(html, {
                replace: (domNode) => {
                    if (domNode.attribs && domNode.attribs.style) {
                        try {
                            style(domNode.attribs.style);
                            if (
                                domNode.name &&
                                removeInlineStyling.includes(domNode.name)
                            ) {
                                // delete the attribute who's inline styling is not required
                                // then convert the dom node to react
                                delete domNode.attribs.style;
                                return domToReact(domNode);
                            }
                        } catch (error) {
                            // delete the attribute that's causing the error
                            // then convert the dom node to react
                            delete domNode.attribs.style;
                            return domToReact(domNode);
                        }
                    }
                },
            })}
        </div>
    );
};

export const RenderPart = ({
    part,
    imgStyle = {},
    latexStyle = null,
    mentionStyle = {},
}) => {
    return part ? (
        part.type === "parts" ? (
            <div>
                {part.val.map((p, i) => (
                    <RenderPart
                        key={`p_p_${i}`}
                        part={p}
                        imgStyle={imgStyle}
                        latexStyle={latexStyle}
                    />
                ))}
            </div>
        ) : part.type === "img" ? (
            <div style={{ maxWidth: "55vw" }}>
                <img
                    // className="overflow-x question-img full-width"
                    style={{ width: "-webkit-fill-available", ...imgStyle }}
                    alt="Question Image"
                    src={part.val}
                />
            </div>
        ) : isHTML(part.val) ? (
            <TextHtml html={part.val} />
        ) : part.type === "latex_div" ? (
            <div className={latexStyle ?? "overflow-x"}>
                <MathRender src={part.val} />
            </div>
        ) : part.type === "break" ? (
            <br />
        ) : part.type === "latex_span" ? (
            <span className={latexStyle ?? "overflow-x"}>
                <MathRender src={part.val} />
            </span>
        ) : part.type === "mention_span" ? (
            <span
                style={{
                    border: "1px solid #00e",
                    background: "#eef",
                    fontSize: "14px",
                    padding: "2px",
                    marginRight: "4px",
                    ...mentionStyle,
                }}
            >
                {`${part.val} `}
            </span>
        ) : part.type === "text" && part.val == "" ? (
            <br />
        ) : (
            <div className="full-width overflow-wrap-break">{part.val}</div>
        )
    ) : null;
};

// HANDLE variable here
const parseCMSJson = (obj: any) => {
    if (typeof obj === "string") {
        return obj;
    }

    const returnObj = obj
        ?.map(function (o: any) {
            if (o.type === "p") {
                const elem = o.children
                    .map(function (o: any) {
                        let styleArr = "";
                        Object.keys(o).forEach((key) => {
                            if (key === "text") return;
                            styleArr += `${key}: ${o[key]}; `;
                        });
                        const style = styleArr ? ` style="${styleArr}"` : "";

                        if (o.bold) return `<b${style}>${o.text}</b>`;
                        else if (o.italic) return `<i${style}>${o.text}</i>`;
                        else if (o.underline) return `<u${style}>${o.text}</u>`;
                        else if (o.type === "mention")
                            return o.value && o.value.length > 0
                                ? `@@@@{{${o.value}}}@@@@`
                                                                  : "";

                        return style
                            ? `<span${style}>${o.text}</span>`
                            : o.text;
                    })
                    .join(" ");

                let styleArr = "";
                Object.keys(o).forEach((key) => {
                    if (key === "text") return;
                    if (key === "indent") {
                        styleArr += `paddingLeft: ${o[key] * 25}px; `;
                        return;
                    }
                    styleArr += `${key}: ${o[key]}; `;
                });
                const style = styleArr ? ` style="${styleArr}"` : "";
                return style ? `<div${style}>${elem}</div>` : elem;
            } else if (o.type === "img") {
                return `<image=\"${o.url}\">`;
            } else if (o.type === "latex") {
                return o.latex;
            }
        })
        .join("\n");

    return returnObj;
};

const replaceAll = (str: string, find: string, replace: string) => {
    return str.replace(new RegExp(find, "g"), replace);
};

const cleanLatex = (text: string, delimiter = "$") => {
    const split = text.split(delimiter);
    let returnString = "";
    split.map((item, index) => {
        if (index % 2 === 0) {
            returnString += item;
        } else {
            returnString += replaceAll(
                `${delimiter}${item}${delimiter}`,
                "\n",
                " ",
            );
        }
    });
    return returnString;
};

const getParts = (text: any, displayType: string) => {
    const parts = [];
    if (!text) {
        return parts;
    }
    let txt = text.trim();
    txt = cleanLatex(txt);
    txt = cleanLatex(txt, "$$");
    txt = txt.replace("< image", "<image");
    txt = txt.replace("<image =", "<image=");
    txt = txt.replace("<image = ", "<image=");
    txt = txt.replace("<image= ", "<image=");
    txt = txt.replace('" >', '">');
    const splitted = txt.split("\n");

    for (let splt of splitted) {
        splt = splt.trim();
        if (splt.includes("<image")) {
            let currentPart = splt;
            const prts = [];
            while (true) {
                const imgIndex = currentPart.indexOf("<image=");

                if (imgIndex === -1) {
                    prts.push({
                        type: "latex_span",
                        val: currentPart,
                    });
                    break;
                }

                const endIndex = currentPart.indexOf('">');
                prts.push({
                    type: "latex_span",
                    val: currentPart.substr(0, imgIndex),
                });

                displayType === "card" &&
                    prts.push({
                        type: "img",
                        val: currentPart.substr(
                            imgIndex + 8,
                            endIndex - imgIndex - 8,
                        ),
                    });

                currentPart = currentPart.substr(endIndex + 2);
            }
            parts.push({
                type: "parts",
                val: prts,
            });
        } else {
            const splitArr = splt.split("");
            const isLatex =
                splitArr[0] === "$" && splitArr[splitArr.length - 1] === "$";

            if (isLatex) {
                parts.push({
                    type: "latex_div",
                    val: splt,
                });
            } else {
                if (!splt)
                    parts.push({
                        type: "break",
                        val: splt,
                    });
                else if (!splt.includes("@@@@{{")) {
                    parts.push({
                        type: "latex_div",
                        val: splt,
                    });
                } else {
                    const nextSplitArr = splt.split("@@@@");
                    if (nextSplitArr.length > 1) {
                        for (const mentionSplit of nextSplitArr) {
                            if (/^\{\{.*\}\}$/.test(mentionSplit)) {
                                parts.push({
                                    type: "mention_span",
                                    val: mentionSplit.replace(
                                        /^\{\{|\}\}$/g,
                                        "",
                                    ),
                                });
                            } else {
                                parts.push({
                                    type: "latex_span",
                                    val: mentionSplit,
                                });
                            }
                        }
                    } else {
                        parts.push({
                            type: "latex_div",
                            val: splt,
                        });
                    }
                }
            }
        }
    }
    return parts;
};

export const renderer = (json: any, mentionStyle = {}) => {
    const text = parseCMSJson(json);
    return isHTML(text) ? (
        <TextHtml html={text} />
    ) : (
        getParts(text, "card").map((part, pIndex) => (
            <RenderPart
                part={part}
                key={"_" + pIndex}
                mentionStyle={mentionStyle}
            />
        ))
    );
};
