import { FunctionComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";

import {
    TOPICS_QUERY,
    CREATE_TOPIC_MUTATION,
    UPDATE_TOPIC,
    DELETE_TOPIC_MUTATION,
    CREATE_GROUP_COLLECTION_MUTATION,
    UPDATE_GROUP_COLLECTION_MUTATION,
    DELETE_GROUP_COLLECTION_MUTATION,
    UPDATE_CHAPTER_OTHER,
} from "../../graphql";
import { captureException } from "@sentry/react";

// Query
export const withTopics = (Component: FunctionComponent) =>
    graphql(TOPICS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                topics,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadTopicsData = (offgroup: number) => {
                return fetchMore({
                    variables: {
                        offgroup,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            topics: [
                                ...previousResult.topics,
                                ...fetchMoreResult.topics,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                topics,
                subscribeToMore,
                updateQuery,
                loadTopicsData,
                refetchTopics: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreateTopic = (Component: FunctionComponent) =>
    graphql(CREATE_TOPIC_MUTATION, {
        props: ({ mutate }) => ({
            createTopic: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createTopic: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { insert_group_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createTopic: {
                                object,
                                __typename: "insert_group_one",
                            },
                        },
                    });
                    return insert_group_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateTopic = (Component: FunctionComponent) =>
    graphql(UPDATE_TOPIC, {
        props: ({ mutate }) => ({
            updateTopic: (object: Object) => {
                mutate({
                    variables: { object, id: object.id },
                    optimisticResponse: {
                        updateTopic: {
                            ...object,
                            __typename: "group",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withUpdateChapterOther = (Component: FunctionComponent) =>
    graphql(UPDATE_CHAPTER_OTHER, {
        props: ({ mutate }) => ({
            updateChapterOther: ({ other, id }) => {
                mutate({
                    variables: { other, id },
                    // optimisticResponse: {
                    //     updateTopic: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withDeleteTopic = (Component: FunctionComponent) =>
    graphql(DELETE_TOPIC_MUTATION, {
        props: ({ mutate }) => ({
            deleteTopic: ({ id, submissionIds }) => {
                mutate({
                    variables: { id, submissionIds },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteTopic: {
                            id,
                            __typename: "DELETE_GROUP_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withUpdateGroupCollection = (Component: FunctionComponent) =>
    graphql(UPDATE_GROUP_COLLECTION_MUTATION, {
        props: ({ mutate }) => ({
            updateGroupCollection: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createGroupCollection: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { update_group_collection_by_pk },
                    } = await mutate({
                        variables: object,
                        // optimisticResponse: {
                        //     __typename: "Mutation",
                        //     createCollection: {
                        //         object,
                        //         __typename: "INSERT_GROUP_COLLECTION_ONE",
                        //     },
                        // },
                    });
                    return update_group_collection_by_pk;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withDeleteGroupCollection = (Component: FunctionComponent) =>
    graphql(DELETE_GROUP_COLLECTION_MUTATION, {
        props: ({ mutate }) => ({
            deleteGroupCollection: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createGroupCollection: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { delete_group_collection },
                    } = await mutate({
                        variables: object,
                        // optimisticResponse: {
                        //     __typename: "Mutation",
                        //     createCollection: {
                        //         object,
                        //         __typename: "INSERT_GROUP_COLLECTION_ONE",
                        //     },
                        // },
                    });
                    return delete_group_collection;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withCreateGroupCollection = (Component: FunctionComponent) =>
    graphql(CREATE_GROUP_COLLECTION_MUTATION, {
        props: ({ mutate }) => ({
            createGroupCollection: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createGroupCollection: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { insert_group_collection_one },
                    } = await mutate({
                        variables: object,
                        optimisticResponse: {
                            __typename: "Mutation",
                            createCollection: {
                                object,
                                __typename: "INSERT_GROUP_COLLECTION_ONE",
                            },
                        },
                    });
                    return insert_group_collection_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);
