import { gql } from "@apollo/client";export default gql`query ChaptersQuery($parent_id: Int!) {
    chapters: group(
        where: { parent_id: { _eq: $parent_id }, type: { _eq: "chapter" } }
    ) {
        id
        description
        cover
        other
        title
        type
        status
        parent {
            id
            other
        }
    }
}
`;
