import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";

const renderCardText = (props: any) => {
    const {
        block: { data },
    } = props;
    return (
        <BlockCard {...props}>
            <div>Audio Block</div>
        </BlockCard>
    );
};
export default renderCardText;
