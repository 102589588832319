import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import {
    withAddGroupDocumentMap,
    withCreateDocument,
} from "src/modules/document/operations";
import { compose } from "src/helpers";

import CreateView from "./component";

const Create = (props) => {
    const { createDocument, addGroupTabMap } = props;
    const router = useRouter();
    const { group_id, redirect_to, order } = router.query;

    const onFinish = async (values: any) => {
        const data = await createDocument(values);
        // console.log({ data });
        await addGroupTabMap({
            objects: [{ document_id: data.id, group_id, order }],
        });

        router.push(redirect_to);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return <CreateView onFinish={onFinish} onFinishFailed={onFinishFailed} />;
};

export default compose(withCreateDocument, withAddGroupDocumentMap)(Create);
