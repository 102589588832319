import renderBlockV2Text from "./renderBlock";
import renderEditorV2Text from "./renderEditor";
import isValidCallbackV2Text from "./isValid";
import V2_TEXT_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2Text from "./renderSettings";
import renderCardV2Text from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { V2_TEXT_BLOCK } = BLOCK_TYPES;

const V2_TEXT_BLOCK_CONFIG = {
    [V2_TEXT_BLOCK]: {
        label: "V2 Rich Text",
        defaultValue: V2_TEXT_DEFAULT_VALUE,
        renderBlock: renderBlockV2Text,
        renderEditor: renderEditorV2Text,
        renderCard: renderCardV2Text,
        renderSettings: renderSettingsV2Text,
        addCallback,
        isValidCallback: isValidCallbackV2Text,
    },
};

export {
    renderBlockV2Text,
    renderCardV2Text,
    renderEditorV2Text,
    renderSettingsV2Text,
    isValidCallbackV2Text,
    V2_TEXT_DEFAULT_VALUE,
    V2_TEXT_BLOCK_CONFIG,
};
