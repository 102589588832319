import { BLOCK_TYPES } from "../../common/index";
const { PAINT_ACTIVITY_BLOCK } = BLOCK_TYPES;

const DEFAULT_VALUE = {
    type: PAINT_ACTIVITY_BLOCK,
    data: {
        [PAINT_ACTIVITY_BLOCK]: {
            variables: {
                color1: "#ffffff",
                color2: "#ffffff",
            },
        },
        other: {
            duration: 0,
        },
        children: [],
    },
};

export default DEFAULT_VALUE;
