import React from "react";
import CellComponent from "../preview/Cell";
import DrawingComponent from "../preview/DrawingComponent";

export const Grid = ({
    gridData, // Pre-processed 2D grid data containing cells or null values
    // cellWidth = 50, // Default cell width in pixels
    // cellHeight = 50, // Default cell height in pixels
    cellMargin = 2, // Margin between cells
    cellBackgroundColor = "#F5F5F5", // Background color for non-empty cells
    emptyCellBackgroundColor = "#FFFFFF", // Background color for empty cells
    borderColor = "#999", // Border color for cells
    fontSize = 16, // Default font size for text in cells
}) => {
    // Early return if the grid data is empty
    if (!gridData || gridData.length === 0) return null;
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(${
                    (gridData && gridData[0] && gridData[0].length) ?? 0
                }, 1fr)`, // or "auto" if needed
                gridTemplateRows: `repeat(${gridData?.length}, 1fr)`, // or "auto" if needed
                gap: "0",
            }}
        >
            {gridData.flatMap((row, rowIndex) =>
                row.map((cell, colIndex) => {
                    if (!cell) {
                        return (
                            <div
                                style={{
                                    width: 0,
                                    height: 0,
                                }}
                            ></div>
                        );
                    }
                    return (
                        <CellComponent
                            key={colIndex}
                            cell={cell.content}
                            rowIndex={rowIndex}
                            cellIndex={colIndex}
                            borderCustomizations={{}}
                            wrapperId={`cell-${rowIndex}-${colIndex}`}
                            modifier={{}}
                            varIndex={0}
                            mappings={{}}
                            triggerInteraction={() => {}}
                            // customStyles={{
                            //   width: cell.content?.width,
                            //   height: cell.content?.height
                            // }}
                            inputData={{}} // Add this line
                            cellTdProps={{}} // Add this line
                            cellTdStyles={{}} // Add this line
                            interactionConfig={{}} // Add this line
                            minMaxWidth={cell?.content?.minMaxWidth}
                            minHeightCustomization={cell?.content?.minHeight}
                            isPuzzlePiece={true}
                        />
                    );
                }),
            )}
        </div>
    );
};

const PuzzleTagPreview = ({
    tag,
    background,
}: {
    tag: any;
    background: string;
}) => {
    return (
        <div
            // @ts-ignore
            dataDropzone={1}
            id={`datadragareawrapper`}
        >
            <div id={tag?.genericId}>
                {tag?.isDrawing ? (
                    <DrawingComponent
                        tableChildProps={{
                            tableData: tag,
                        }}
                        path={tag?.genericId}
                        isPuzzlePiece={tag?.isPuzzlePiece}
                    />
                ) : (
                    <div
                        style={{
                            position: "relative",
                            width: "fit-content",
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            gridData={tag.cells}
                            // cellWidth={tag.width}
                            // cellHeight={tag.height}
                            cellBackgroundColor={background}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PuzzleTagPreview;
