import { useEffect, useRef } from "react";

const resetFilterHook = (onStateReset = () => {}) => {
    const handleFiltersRemove = useRef(() => {});

    handleFiltersRemove.current = onStateReset;

    useEffect(() => {
        return () => handleFiltersRemove.current();
    }, []);
};

export default resetFilterHook;
