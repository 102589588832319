import { compose } from "src/helpers";
// import deepdash from 'deepdash-es';
import {
    withAddGroupTagMap,
    withDeleteGroupTagMap,
    withHookForBook,
    withUpdateBook,
} from "src/modules/book/operations";
import { withAddGroupTabMap } from "src/modules/tab/operations";
import { omit } from "lodash";
// import lodash from 'lodash';
import get from "lodash/get";

import { useRouter } from "src/helpers";
import EditView from "./component";

const Edit = (props) => {
    const {
        updateBook,
        book,
        addGroupTagMap,
        deleteGroupTagMap,
        addGroupTabMap,
    } = props;
    const router = useRouter();
    const {
        query: { redirect_to },
    } = router;

    const onFinish = async (values: any) => {
        await updateBook({ object: omit(values, ["tags"]), id: values.id });
        const newTags = get(values, ["tags", "data"]).map((tag) => tag.tag_id);
        const oldTagIds = book.tags.map((tag) => tag.tag.id);
        console.log(newTags, oldTagIds, values);

        const tagsToAdd = newTags.filter(
            (newTag) => oldTagIds.indexOf(newTag) == -1,
        );
        const tagsToRemove = oldTagIds.filter(
            (newTag) => newTags.indexOf(newTag) == -1,
        );

        console.log("newTags", newTags, oldTagIds, tagsToAdd, tagsToRemove);

        if (tagsToAdd.length)
            await addGroupTagMap({
                objects: tagsToAdd.map((tag) => ({
                    tag_id: tag,
                    group_id: book.id,
                })),
            });
        if (tagsToRemove.length)
            await deleteGroupTagMap({
                ids: book.tags
                    .filter((tag) => tagsToRemove.includes(tag.tag.id))
                    .map((tag) => tag.id),
            });
        router.push(redirect_to);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <EditView
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            {...props}
            disableSubjectClass={true}
        />
    );
};

export default compose(
    withHookForBook,
    withUpdateBook,
    withAddGroupTagMap,
    withDeleteGroupTagMap,
    withAddGroupTabMap,
)(Edit);
