import renderBlockTable from "./renderBlock";
import renderEditorTable from "./renderEditor";
import isValidCallbackTable from "./isValid";
import TABLE_DEFAULT_VALUE from "./defaultValue";
import renderSettingsTable from "./renderSettings";
import renderCardTable from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/";

const { TABLE_BLOCK } = BLOCK_TYPES;

const TABLE_BLOCK_CONFIG = {
    [TABLE_BLOCK]: {
        label: "Table",
        defaultValue: TABLE_DEFAULT_VALUE,
        renderBlock: renderBlockTable,
        renderEditor: renderEditorTable,
        renderCard: renderCardTable,
        renderSettings: renderSettingsTable,
        addCallback,
        isValidCallback: isValidCallbackTable,
    },
};

export {
    renderBlockTable,
    renderCardTable,
    renderEditorTable,
    renderSettingsTable,
    isValidCallbackTable,
    TABLE_DEFAULT_VALUE,
    TABLE_BLOCK_CONFIG,
};
