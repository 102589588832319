import React, { useEffect, useState } from "react";
import { Spin, Select } from "antd";

import { compose, config, resetFilterHook } from "src/helpers";
import { Field, RenderSelect } from "src/components";

import { withCollections } from "src/modules/collection/operations";

import { withStateAndActionsForCollection } from "../resolvers";

const Option = Select.Option;

const SubjectsField = (props) => {
    const {
        loading,
        collections,
        where,
        onNameChange,
        onChange,
        value,
        onStateReset,
        dropdownOpen,
        passedWhere,
        hasChanged,
        callback,
    } = props;
    resetFilterHook(onStateReset);

    useEffect(() => {
        if (hasChanged) {
            onNameChange(value, passedWhere);
            callback();
        }
    }, [hasChanged]);

    return (
        <>
            <Field
                labelInValue
                showSearch={true}
                filterOption={false}
                onSearch={(e) => onNameChange(e, passedWhere)}
                notFoundContent={loading ? <Spin size="small" /> : null}
                name={"collections"}
                component={RenderSelect}
                placeholder={"Collections"}
                value={value || "None"}
                loading={loading}
                onChange={onChange}
                inFilter={true}
                noBotMarging={true}
                mode={"multiple"}
                open={dropdownOpen}
            >
                {[
                    // config.EMPTY_OPTION,
                    ...(collections?.map((collection) => ({
                        id: collection.id,
                        label: collection.title || "",
                        value: collection.id,
                    })) || []),
                ]?.map(
                    ({
                        id,
                        value,
                        label = "",
                    }: {
                        id: string;
                        value: string;
                        label: string;
                    }) => (
                        <Option key={id} value={value}>
                            {label}
                        </Option>
                    ),
                )}
            </Field>
        </>
    );
};

export default compose(
    withStateAndActionsForCollection,
    withCollections,
)(SubjectsField);
