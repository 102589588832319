import renderBlockButton from "./renderBlock";
import renderEditorButton from "./renderEditor";
import isValidCallbackButton from "./isValid";
import BUTTON_DEFAULT_VALUE from "./defaultValue";
import renderSettingsButton from "./renderSettings";
import renderCardButton from "./renderCard";
import { BLOCK_TYPES, addCallback } from "../../common/index";

const { BUTTON_BLOCK } = BLOCK_TYPES;

const BUTTON_BLOCK_CONFIG = {
    [BUTTON_BLOCK]: {
        label: "Button",
        defaultValue: BUTTON_DEFAULT_VALUE,
        renderBlock: renderBlockButton,
        renderEditor: renderEditorButton,
        renderCard: renderCardButton,
        renderSettings: renderSettingsButton,
        addCallback,
        isValidCallback: isValidCallbackButton,
    },
};

export {
    renderBlockButton,
    renderCardButton,
    renderEditorButton,
    renderSettingsButton,
    isValidCallbackButton,
    BUTTON_DEFAULT_VALUE,
    BUTTON_BLOCK_CONFIG,
};
