import {
    CopyOutlined,
    FireOutlined,
    QuestionCircleOutlined,
} from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import {
    duplicateWorksheet,
    duplicateWorksheetVersion,
} from "src/modules/worksheet/components/custom/DuplicateWorksheetButton";
import {
    CREATE_WORKSHEET_MUTATION,
    INSERT_VERSION,
    INSERT_WORKSHEET_BLOCK_MAP,
    VERSION_MODEL_MAP_QUERY,
    WORKSHEET_BLOCKS_QUERY,
} from "src/modules/worksheet/graphql";
import { Button, message, Modal, Popconfirm, Spin } from "antd";
import { gql, GraphQLClient } from "graphql-request";
import _ from "lodash";
import { useState } from "react";
// import { CREATE_TAB_MUTATION } from "../graphql";
import gql2 from "graphql-tag";
import TabAssignComponent from "../components/TabAssignComponent";

export const graphQLClient = new GraphQLClient(
    process.env.REACT_APP_API_ENDPOINT,
    {
        headers: {
            "x-hasura-admin-secret": process.env.REACT_APP_HASURA_ADMIN_SECRET,
        },
    },
);

export const graphQLClientProd = graphQLClient;

const WORKSHEET_QUERY = gql2`
    query worksheet($id: Int!) {
        worksheet: worksheet_worksheet_by_pk(id: $id) {
            id
            status
            banner
            created_at
            title
            class
            subject
            updated_at
            tags {
                id
                tag_id
                tag {
                    id
                    name
                    value
                }
            }
            other
        }
    }
`;

const CREATE_GROUP_TAB_MUTATION = gql`
    mutation CREATE_GROUP_TAB_MAP(
        $object: home_explore_group_tab_map_insert_input!
    ) {
        insert_home_explore_group_tab_map_one(object: $object) {
            id
        }
    }
`;

const BOOK_QUERY = gql`
    query BookQuery($id: Int!) {
        book: group_by_pk(id: $id) {
            cover
            created_at
            description
            other

            status
            title
            type
            children {
                id
                cover
                created_at
                description
                other

                status
                title
                type
                children {
                    id
                    cover
                    created_at
                    description
                    other

                    status
                    title
                    type
                }
            }
        }
    }
`;

const GET_WORKSHEET_IDS = gql`
    query GetWorksheetsIds($_in: [Int!]!) {
        worksheet_group_worksheet_map(where: { group_id: { _in: $_in } }) {
            group_id
            order
            worksheet_id
        }
    }
`;

const GET_LAST_GROUP_ID = gql`
    query GetLastGroupId {
        group(limit: 1, order_by: { id: desc }) {
            id
        }
    }
`;

export const createWorksheet = async (object: any) => {
    const { insert_worksheet_worksheet_one } = await graphQLClientProd.request(
        CREATE_WORKSHEET_MUTATION,
        {
            object,
        },
    );
    return insert_worksheet_worksheet_one;
};
export const insertWorksheetBlockMap = async (object: any) => {
    const newObject = _.cloneDeep(object);

    if (newObject?.objects?.length > 0) {
        const newObjects = object.objects.map((item: any) => {
            if (
                item?.block?.data?.type === "image" &&
                item?.block?.data?.other
            ) {
                delete item.block.data.other;
            }
            return item;
        });
        newObject.objects = newObjects;
    }

    const {
        insert_worksheet_worksheet_block_map: { returning },
    } = await graphQLClientProd.request(INSERT_WORKSHEET_BLOCK_MAP, newObject);
    return returning;
};
export const insertVersion = async (object: any) => {
    const { insert_versioning_version_one } = await graphQLClientProd.request(
        INSERT_VERSION,
        {
            object,
        },
    );
    return insert_versioning_version_one;
};

const copyBookToTab = async ({
    tabId,
    getWorksheet,
    getWorksheetBlocks,
    getWorksheetVersion,
    bookId,
}: any) => {
    const { book } = await graphQLClientProd.request(BOOK_QUERY, {
        id: bookId,
    });

    const lastGroupIdRes = await graphQLClientProd.request(GET_LAST_GROUP_ID);
    const lastGroupId = lastGroupIdRes.group[0].id;

    let tmpGroupId = lastGroupId + 1;
    const worksheetParentMap: any = {};

    const worksheetParents: any = [];
    const object = {
        tab_id: tabId,
        group: {
            data: {
                id: tmpGroupId++,
                ..._.omit(book, ["children"]),
                children: {
                    data: book.children.map((chapter: any) => {
                        const newChapterId = tmpGroupId++;
                        if (!chapter.other?.has_topics) {
                            worksheetParents.push(chapter.id);
                            worksheetParentMap[chapter.id] = newChapterId;
                        }

                        return {
                            id: newChapterId,
                            ..._.omit(chapter, ["children", "id"]),
                            ...(chapter.other?.has_topics
                                ? {
                                      children: {
                                          data: chapter.children.map(
                                              (topic: any) => {
                                                  worksheetParents.push(
                                                      topic.id,
                                                  );
                                                  const newTopicId =
                                                      tmpGroupId++;

                                                  worksheetParentMap[topic.id] =
                                                      newTopicId;
                                                  return {
                                                      id: newTopicId,

                                                      ..._.omit(topic, ["id"]),
                                                  };
                                              },
                                          ),
                                      },
                                  }
                                : {}),
                        };
                    }),
                },
                status: "inactive",
            },
        },
        order: 999,
    };

    // duplicate tab->book->chapter->topic
    const { insert_home_explore_group_tab_map_one } =
        await graphQLClientProd.request(CREATE_GROUP_TAB_MUTATION, {
            object,
        });
    message.success("copied tab->book->chapter->topic");

    const { worksheet_group_worksheet_map: worksheetsGroupArr } =
        await graphQLClient.request(GET_WORKSHEET_IDS, {
            _in: worksheetParents,
        });

    for (let i = 0; i < worksheetsGroupArr.length; i++) {
        const {
            group_id,
            worksheet_id: worksheetId,
            order,
        } = worksheetsGroupArr[i];
        const newWorksheetId = await duplicateWorksheet({
            getWorksheet,
            worksheetId,
            getWorksheetBlocks,
            groupId: worksheetParentMap[group_id],
            order,
            createWorksheet,
            insertWorksheetBlockMap,
            addCopySuffix: false,
            maintainStatus: true,
        });

        await duplicateWorksheetVersion({
            prevWorkseetId: worksheetId,
            newWorksheetId,
            getWorksheetVersion,
            insertVersion,
            getWorksheet,
        });
        message.success(`copied worksheet, ${worksheetId}`);
    }
    message.success("done");
};

export const CopyBookToTab = ({ book_id }: any) => {
    const [getWorksheet, {}] = useLazyQuery(WORKSHEET_QUERY);
    const [getWorksheetBlocks, {}] = useLazyQuery(WORKSHEET_BLOCKS_QUERY);
    const [getWorksheetVersion, {}] = useLazyQuery(VERSION_MODEL_MAP_QUERY);

    const [loading, setLoading] = useState(false);
    const [tabId, setTabId] = useState<any>();

    const onConfirm = async () => {
        setLoading(true);

        await copyBookToTab({
            tabId: tabId,
            bookId: book_id,
            getWorksheet,
            getWorksheetBlocks,
            getWorksheetVersion,
        });
        setLoading(false);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                block
                icon={<CopyOutlined />}
                shape="circle"
                danger
                onClick={showModal}
            />
            <Modal
                title="Copy Worksheet to other Chapter / Topic"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
            >
                <TabAssignComponent
                    handleChange={({ value: id }: any) => {
                        console.log(id);
                        setTabId(id);
                    }}
                    selected={tabId}
                />
                <br />
                <br />
                <Button block onClick={onConfirm} disabled={!tabId}>
                    Copy
                </Button>
            </Modal>
        </>
    );
};
