import { useRouter } from "src/helpers"; // import lodash from 'lodash';
// import deepdash from 'deepdash-es';

import { withCreateBook } from "src/modules/book/operations";
import { compose } from "src/helpers";

import CreateView from "./component";
import { withAddGroupTabMap } from "src/modules/tab/operations";

const Create = (props) => {
    const { createBook, addGroupTabMap } = props;
    const router = useRouter();
    const { tab_id } = router.query;
    const {
        query: { redirect_to, order },
    } = router;

    const onFinish = async (values: any) => {
        const data = await createBook(values);
        await addGroupTabMap({
            objects: [{ tab_id, group_id: data.id, order }],
        });
        router.push(redirect_to);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return <CreateView onFinish={onFinish} onFinishFailed={onFinishFailed} />;
};

export default compose(withCreateBook, withAddGroupTabMap)(Create);
