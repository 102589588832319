import { Card } from "antd";
import { renderTags } from "../../common";
import { renderer } from "../../helpers";
import { renderBlockMCQ } from "../MCQ";
import { BLOCK_TYPES } from "../../common/index";
const { V2_RAPID_QA_BLOCK } = BLOCK_TYPES;

const renderBlockV2RapidQA = (
    block: any,
    blockType: string = V2_RAPID_QA_BLOCK,
) => {
    const {
        data: {
            [blockType]: { title },
        },
        children,
        heading,
        tags,
    } = block;
    return (
        <div>
            {/* <Row gutter={[0, 24]}> */}
            <div>
                {heading && <h3>Rapid Qa Title</h3>}
                {renderTags(tags)}

                <Card bodyStyle={{ padding: "12px" }}>{renderer(title)}</Card>
            </div>

            {heading && <h3>Questions</h3>}
            {children.map((child: any) => (
                <div>
                    <Card bodyStyle={{ padding: "12px" }}>
                        {renderBlockMCQ({ ...child, heading })}
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default renderBlockV2RapidQA;
