/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, ReactComponentElement, useMemo, useState } from "react";
import { renderer } from "./renderer";
import ReactDOMServer from "react-dom/server";

interface HighlightWrapperProps {
    children: ReactComponentElement<any>;
}
type Position = "top" | "bottom" | "left" | "right";

type ArrowTargets = {
    target_id: string;
    curr_position: Position;
    target_position: Position;
    distance?: {
        start: number;
        end: number;
    };
};

type HighlightFeedbackMessageContainer = {
    width: number;
    height: number;
    position?: Position;
    backgroundColor?: string;
    gap: number;
};

type HighlightFeedbackMessage = {
    /**
     *message_id format - id/feedback/[feedback_number]
     */
    message_id: string;
    text: any;
    container: HighlightFeedbackMessageContainer;
    targets?: ArrowTargets[];
};

type HighlightFeedbackArrow = {
    length: number;
    type: string;
    reverse?: boolean;
    distance?: {
        start?: number;
        end?: number;
    };
};

/**
 * *schema*
 * id: string,
 * message: {
 *    message_id: string (message_id format - id/feedback/[feedback_number])
 *    text: rich_text
 *    container: {
 *       width: number;
         height: number;
         position: left | right | top | bottom;
         backgroundColor: string;
         gap: number;
 *    }
      targets: [{
        target_id: string;
        curr_position: left | right | top | bottom;
        target_position: left | right | top | bottom;
      }]
 * },
   arrow: {
        length: number;
        type: string;
        reverse: boolean;
        distance: {
            start: number;
            end: number;
        };
   }
 */
export type HighlightFeedbackElement = {
    id: string;
    message?: HighlightFeedbackMessage;
    arrow: HighlightFeedbackArrow;
};

export type SetHighlightType = (
    ovelayEnabled: boolean,
    highlightElements?: HighlightFeedbackElement[] | null,
    customFunc?: null | (() => void),
) => void;

export const setHighlightOnElement = (element: HighlightFeedbackElement) => {
    const elem = document.getElementById(element.id);
    if (!elem) return;
    elem.classList.add("highlight");
    const rect = elem.getBoundingClientRect();
    const { left: x, top: y, width: w, height: h } = rect;

    const highlight = document.createElement("div");
    highlight.id = `highlight/${element.id}`;
    highlight.classList.add("highlight");

    highlight.style.width = `${w}px`;
    highlight.style.height = `${h}px`;
    highlight.style.top = `${y}px`;
    highlight.style.left = `${x}px`;
    highlight.style.position = "absolute";
    document.body.appendChild(highlight);
};

const HighlightWrapper: FC<HighlightWrapperProps> = ({ children }) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const getFeedbackPosition = (
        element: HTMLElement,
        feedbackWidth: number,
        feedbackHeight: number,
        gap: string | number,
        position: Position = "top",
    ) => {
        const rect = element.getBoundingClientRect();

        const { left: x, top: y, width: w, height: h } = rect;

        const convertedGap = typeof gap === "string" ? parseInt(gap) : gap;

        const pos = {
            top: {
                top: y - convertedGap - feedbackHeight,
                left: x + w / 2 - feedbackWidth / 2,
            },
            bottom: {
                top: convertedGap + y + h,
                left: x + w / 2 - feedbackWidth / 2,
            },
            left: {
                left: x - convertedGap - feedbackWidth,
                top: y + h / 2 - feedbackHeight / 2,
            },
            right: {
                left: convertedGap + x + w,
                top: y + h / 2 - feedbackHeight / 2,
            },
        };
        return pos[position];
    };

    const getAngle = (
        currX: number,
        currY: number,
        targetX: number,
        targetY: number,
    ) => {
        let angle = Math.atan2(targetY - currY, targetX - currX);
        angle *= 180 / Math.PI;
        return angle + 180;
    };

    const getCenterPositionXY = (element: HTMLElement, position: Position) => {
        const rect = element.getBoundingClientRect();

        const { left: x, top: y, width: w, height: h } = rect;

        const center = {
            top: {
                x: x + w / 2,
                y: y,
            },
            bottom: {
                x: x + w / 2,
                y: y + h,
            },
            left: {
                x: x,
                y: y + h / 2,
            },
            right: {
                x: x + w,
                y: y + h / 2,
            },
        };

        const pos = center[position];
        return pos;
    };

    const createArrow = (
        x: number,
        y: number,
        length: number,
        angle: number,
    ) => {
        const arrow = document.createElement("div");
        arrow.classList.add("arrow", "arrow-line");
        arrow.style.top = `${y - 2}px`;
        arrow.style.left = `${x + 12 - length}px`;
        arrow.style.width = `${length - 10}px`;
        arrow.style.transform = `rotate(${angle}deg)`;
        arrow.style.transformOrigin = "bottom right";
        arrow.style.position = "absolute";

        return arrow;
    };

    const drawArrowWithAngle = (
        currId: string,
        targetId: string,
        currPosition: Position,
        targetPosition: Position,
        distance?: {
            start: number;
            end: number;
        },
    ) => {
        if (!currId.length || !targetId.length) return;
        const currElem = document.getElementById(currId)!;
        const targetElem = document.getElementById(targetId)!;

        const currCenterPos = getCenterPositionXY(currElem, currPosition);
        const targetCenterPos = getCenterPositionXY(targetElem, targetPosition);

        const currX = currCenterPos.x;
        const currY = currCenterPos.y;
        const targetX = targetCenterPos.x;
        const targetY = targetCenterPos.y;

        const angle = getAngle(currX, currY, targetX, targetY);

        const arrowLength = Math.sqrt(
            Math.pow(currX - targetX, 2) + Math.pow(currY - targetY, 2),
        );

        const arrow = createArrow(currX, currY, arrowLength, angle);

        document.body.appendChild(arrow);

        return arrow;
    };

    function createFeedbackContainer(element: HighlightFeedbackElement) {
        if (!element?.message) return;
        const elem = document.getElementById(element.id)!

        const feedback = document.createElement("span");
        feedback.classList.add("feedback");
        feedback.id = `${element.message.message_id}`;
        const html = ReactDOMServer.renderToStaticMarkup(
            renderer(element.message.text) as any,
        );
        feedback.innerHTML = html.toString();
        elem.appendChild(feedback);

        feedback.style.padding = "4px";
        feedback.style.width = `${element.message.container.width}px`;
        feedback.style.height = `${element.message.container.height}px`;
        feedback.style.backgroundColor =
            element.message.container.backgroundColor ?? "#FFFFFF";
        const feedbackRect = feedback.getBoundingClientRect();

        const feedbackWidth = feedbackRect.width;
        const feedbackHeight = feedbackRect.height;

        const feedbackGap = element.message.container.gap;
        // const feedbackGap =
        //     element.arrow.length +
        //     (element.arrow.distance?.end ?? 0) +
        //     (element.arrow.distance?.start ?? 0);

        const feedbackContainerPosition = getFeedbackPosition(
            elem,
            feedbackWidth,
            feedbackHeight,
            feedbackGap,
            element.message.container.position,
        );
        feedback.style.top = `${feedbackContainerPosition.top}px`;
        feedback.style.left = `${Math.max(
            feedbackContainerPosition.left,
            0,
        )}px`;
        feedback.style.left = `${feedbackContainerPosition.left}px`;
        feedback.style.overflow = "hidden";

        if (
            feedbackContainerPosition.left + feedbackWidth >
            window.innerWidth
        ) {
            feedback.style.left = `${window.innerWidth - feedbackWidth}px`;
        }
    }

    function setFeedbackOnElement(element: HighlightFeedbackElement) {
        if (
            !element ||
            !element.message ||
            !element.message.container.width ||
            !element.message.container.height ||
            !element.message.container.gap
        )
            return;

        createFeedbackContainer(element);

        const containerPosition = element.message.container.position ?? "top";

        let currPosition: Position | null = null;
        const targetPosition = containerPosition;

        switch (containerPosition) {
            case "top":
                currPosition = "bottom";
                break;
            case "bottom":
                currPosition = "top";
                break;
            case "left":
                currPosition = "right";
                break;
            default:
                currPosition = "left";
                break;
        }

        // setArrowOnElement(element);
        if (element.message.container.gap > 0)
            drawArrowWithAngle(
                element.message.message_id,
                element.id,
                currPosition,
                targetPosition,
            );

        const messageTargets = element.message.targets;
        const currId = element.message.message_id;
        messageTargets?.forEach((target) => {
            drawArrowWithAngle(
                currId,
                target.target_id,
                target.curr_position,
                target.target_position,
            );
        });
    }

    const resetHighlight = () => {
        document.querySelectorAll(".highlight").forEach((elem) => {
            elem.classList.remove("highlight");
        });
        document.querySelectorAll(".feedback").forEach((elem) => {
            elem.remove();
        });
        document.querySelectorAll(".arrow").forEach((elem) => {
            elem.remove();
        });
        setShowOverlay(false);
        // document.querySelectorAll(".overlay")?.forEach((elem) => {
        //     elem.remove();
        // });
    };

    function setHighlight(
        ovelayEnabled: boolean,
        highlightElements: HighlightFeedbackElement[] | null = null,
        customFunc: null | (() => void) = null,
    ) {
        resetHighlight();
        if (customFunc) {
            setShowOverlay(false);
            customFunc();
            return;
        }
        setShowOverlay(ovelayEnabled);
        highlightElements?.forEach((element) => {
            if (ovelayEnabled) {
                setHighlightOnElement(element);
            }
            setFeedbackOnElement(element);
        });
    }

    const wrappedChildren = useMemo(
        () =>
            React.Children.map(children, (child) =>
                React.cloneElement(child, { setHighlight: setHighlight }),
            ),
        [children],
    );
    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
                zIndex: 2,
            }}
        >
            {showOverlay && (
                <div
                    style={{
                        position: "absolute",
                        // top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgb(0, 0, 0, 0.25)",
                        zIndex: 2,
                    }}
                />
            )}
            {wrappedChildren}
        </div>
    );
};

export function getElementsPosition(
    ids: string[],
    heightTunning = 0,
    widthTunning = 0,
) {
    if (!ids.length)
        return {
            top: 0,
            left: 0,
            height: 0,
            width: 0,
        };
    const elements = ids.map((id) => document.getElementById(id)!);

    elements.sort((a, b) => {
        const aRect = a.getBoundingClientRect();
        const bRect = b.getBoundingClientRect();

        if (aRect.top === bRect.top) return aRect.left - bRect.left;
        return aRect.top - bRect.top;
    });

    if (ids.length === 1) {
        const { top, left, height, width } =
            elements[0].getBoundingClientRect();
        return {
            top: top - heightTunning,
            left: left - widthTunning,
            height: height + 2 * heightTunning,
            width: width + 2 * widthTunning,
        };
    }

    const firstElemRect = elements[0].getBoundingClientRect();
    const lastElemRect = elements[elements.length - 1].getBoundingClientRect();

    if (firstElemRect.top === lastElemRect.top) {
        const { top, left, height } = firstElemRect;

        const width = lastElemRect.left + lastElemRect.width - left;

        return {
            top: top - heightTunning,
            left: left - widthTunning,
            height: height + 2 * heightTunning,
            width: width + 2 * widthTunning,
        };
    }

    const { top, left, width } = firstElemRect;
    const height = lastElemRect.top + lastElemRect.height - top;

    return {
        top: top - heightTunning,
        left: left - widthTunning,
        height: height + 2 * heightTunning,
        width: width + 2 * widthTunning,
    };
}

export function createOverlay(
    top: number,
    left: number,
    width: number,
    height: number,
) {
    const leftOverlay = document.createElement("div");
    const rightOverlay = document.createElement("div");
    const topOverlay = document.createElement("div");
    const bottomOverlay = document.createElement("div");

    leftOverlay.classList.add("overlay", "left-overlay");
    rightOverlay.classList.add("overlay", "right-overlay");
    topOverlay.classList.add("overlay", "top-overlay");
    bottomOverlay.classList.add("overlay", "bottom-overlay");

    const layoutView = document.querySelector(
        ".set-layout-view",
    )! as HTMLElement;

    const {
        left: layoutViewLeft,
        height: layoutViewHeight,
        width: layoutViewWidth,
    } = layoutView.getBoundingClientRect();

    topOverlay.style.top = "0";
    topOverlay.style.left = `${layoutViewLeft}px`;
    topOverlay.style.height = `${Math.max(top, 0)}px`;
    topOverlay.style.width = `${layoutViewWidth}px`;

    bottomOverlay.style.left = `${layoutViewLeft}px`;
    bottomOverlay.style.top = `${top + height}px`;
    bottomOverlay.style.width = `100%`;
    bottomOverlay.style.height = `${window.innerHeight - top - height}px`;

    leftOverlay.style.left = `${layoutViewLeft}px`;
    leftOverlay.style.top = `${top}px`;
    leftOverlay.style.height = `${height}px`;
    leftOverlay.style.width = `${left - layoutViewLeft}px`;

    rightOverlay.style.left = `${left + width}px`;
    rightOverlay.style.top = `${top}px`;
    rightOverlay.style.height = `${height}px`;
    rightOverlay.style.width = `${
        layoutViewWidth - width - (left - layoutViewLeft)
    }px`;

    document.body.appendChild(leftOverlay);
    document.body.appendChild(rightOverlay);
    document.body.appendChild(topOverlay);
    document.body.appendChild(bottomOverlay);

    return {
        leftOverlay,
        rightOverlay,
        topOverlay,
        bottomOverlay,
    };
}

export function getOverlays() {
    return {
        leftOverlay: document.querySelector(".left-overlay") as HTMLDivElement,
        rightOverlay: document.querySelector(
            ".right-overlay",
        ) as HTMLDivElement,
        topOverlay: document.querySelector(".top-overlay") as HTMLDivElement,
        bottomOverlay: document.querySelector(
            ".bottom-overlay",
        ) as HTMLDivElement,
    };
}

export function removeOverlay() {
    document.querySelectorAll(".overlay").forEach((overlay) => {
        overlay.remove();
    });
}

export default HighlightWrapper;
