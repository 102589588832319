import { gql } from "@apollo/client";export default gql`query worksheetAggregate($group_id: Int) {
    worksheetAggregate: worksheet_worksheet_aggregate(
        where: { groups: { group_id: { _eq: $group_id } } }
    ) {
        aggregate {
            count
        }
    }
}
`;
