import { gql } from "@apollo/client";export default gql`query meme($id: Int!) {
    meme: meme_by_pk(id: $id) {
        id
        asset_url
        group
        status
        user_id
    }
}
`;
