import { FunctionComponent } from "react";

import {
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import {
    QUESTION_QUERY,
    QUESTIONS_QUERY,
    QUESTION_AGGREGATE_QUERY,
} from "../graphql";

export const withHookForQuestions = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { pagination } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            QUESTIONS_QUERY,
            {
                variables: {
                    ...pagination,
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "questions", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    questions: items,
                    subscribeToMore,
                    updateQuery,
                    loadQuestionsData: loadItemsData,
                    refetchQuestions: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateQuestion = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            QUESTION_AGGREGATE_QUERY,
            {
                variables: {},
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "questionAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    questionAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForQuestion = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            QUESTION_QUERY,
            {
                variables: {
                    id: getLastStringParamFromUrl(),
                },
            },
        );

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingQuestion: loading,
                    question: data?.question,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
