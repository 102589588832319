import { FunctionComponent } from "react";

import {
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import { MEME_QUERY, MEMES_QUERY, MEME_AGGREGATE_QUERY } from "../graphql";

export const withHookForMemes = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { pagination } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            MEMES_QUERY,
            {
                variables: {
                    ...pagination,
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "memes", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    memes: items,
                    subscribeToMore,
                    updateQuery,
                    loadMemesData: loadItemsData,
                    refetchMemes: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateMeme = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            MEME_AGGREGATE_QUERY,
            {
                variables: {},
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "memeAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    memeAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForMeme = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(MEME_QUERY, {
            variables: {
                id: getLastStringParamFromUrl(),
            },
        });

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingMeme: loading,
                    meme: data?.meme,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
