const renderBlockButton = (block: any) => {
    const {
        data: { button, other },
    } = block;
    return (
        <div style={{ width: "40%" }}>
            {/* {other?.duration ? <span>Duration: {other?.duration}</span> : null} */}
            <h1>block</h1>

            {/* <img src={image} style={{ width: "-webkit-fill-available" }} /> */}
        </div>
    );
};

export default renderBlockButton;
