import { gql } from "@apollo/client";
export default gql`
    query WorksheetBlocksQuery($worksheet_id: Int!) {
        worksheetBlockMap: worksheet_worksheet_block_map(
            where: {
                worksheet_id: { _eq: $worksheet_id }
                is_published: { _is_null: true }
            }
            order_by: { order: asc }
        ) {
            id
            order
            worksheet_id
            block {
                id
                data
                parent_id
                type
                backend
                is_hidden
                children(order_by: { order: asc }) {
                    id
                    data
                    type
                    order
                    backend
                    tags {
                        id
                        tag_id
                        tag {
                            id
                            name
                            value
                        }
                    }
                    children(order_by: { order: asc }) {
                        id
                        data
                        type
                        order
                        backend
                    }
                }
                tags {
                    id
                    tag_id
                    tag {
                        id
                        name
                        value
                    }
                }
            }
        }
    }
`;
