import React, { Component } from "react";
import {
    Col,
    Form,
    Row,
    Switch,
    InputNumber,
    Card,
    Button,
    Input,
    Select,
} from "antd";
import _ from "lodash";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";

const Animations = ({ block, setBlock, disableWrite }: any) => {
    return (
        <KTWrapper feature="table_animations">
            <Card
                title={<h3>Animations</h3>}
                bodyStyle={{
                    padding: block.interactionLimit?.enabled ? "12px" : "0px",
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={"Row"}
                            style={{
                                padding: "0rem 1rem",
                            }}
                        >
                            <Select
                                value={block?.animations?.cell?.type || "None"}
                                placeholder="Select Animation type"
                                options={[
                                    {
                                        label: "None",
                                        value: "NONE",
                                    },
                                    {
                                        label: "Alternate right-left",
                                        value: "ALTERNATE_RIGHT_LEFT",
                                    },
                                ]}
                                style={{
                                    flexGrow: 0,
                                }}
                                onChange={(val) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock.animations = {
                                        ...tmpBlock?.animations,
                                        cell: {
                                            ...tmpBlock?.animations?.cell,
                                            type: val,
                                        },
                                    };
                                    setBlock(tmpBlock);
                                }}
                                disabled={disableWrite}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Duration (sec)"}
                            style={{
                                padding: "0rem 1rem",
                            }}
                        >
                            <Input
                                disabled={disableWrite}
                                value={block?.animations?.duration}
                                placeholder="duration"
                                type="number"
                                onChange={(e) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock.animations = {
                                        ...tmpBlock?.animations,
                                        duration: Number(e.target.value),
                                    };
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </KTWrapper>
    );
};

export default Animations;
