import { gql } from "@apollo/client";export default gql`mutation createCollection($object: collection_insert_input = {}) {
    insert_collection_one(
        object: $object
        on_conflict: { constraint: collection_pkey, update_columns: id }
    ) {
        id
        title
        description
        cover
        chapter
        topic
        attempts
        type
        status
        show_memes
        format
                class
                subject
        creator {
            email
        }
    }
}
`;
