import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import {
    Badge,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Typography,
    message,
} from "antd";
import { Button, List } from "antd";
import { cloneDeep, pullAt } from "lodash";
import React, { useEffect, useState } from "react";
import { Can } from "src/services/casl";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { CopyIcon } from "src/components";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: "pointer",
        }}
    />
));

const SortableContainerCustom = SortableContainer(({ children }: any) => {
    return <div>{children}</div>;
});

const SortableItemCustom = SortableElement((props: any) => {
    const { children, sid } = props;
    return (
        <div style={{ zIndex: 1000 }} key={`${sid}`}>
            {children}
        </div>
    );
});

const GlobalContextVariablesModal = (props: any) => {
    const {
        global_context_variables: {
            base_variables = [],
            derived_variables = [],
        } = {},
        isReadOnlyMode,
        setBlock,
        disableWrite,
    } = props;

    const [baseVars, setBaseVars] = useState(base_variables);
    const [derivedVars, setDerivedVars] = useState(derived_variables);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempConfig, setTempConfig] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = () => {
        if (!disableWrite) {
            if (
                baseVars.some((v) => !v.name) ||
                derivedVars.some((v) => !v.name || !v.computeFunction?.output)
            ) {
                message.error("Some fields are empty");
                return;
            } else {
                setBlock({
                    base_variables: baseVars,
                    derived_variables: derivedVars,
                });
                message.success("Success!");
            }
        }
        setIsModalOpen(false);
    };

    useEffect(() => {
        setBaseVars(base_variables);
        setDerivedVars(derived_variables);
    }, [isModalOpen]);

    const onSortQuestionEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(baseVars);
        tmpBlock = arrayMoveImmutable(tmpBlock, oldIndex, newIndex).filter(
            (el) => !!el,
        );
        setBaseVars(tmpBlock);
    };

    const onSortDerivedEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(derivedVars);
        tmpBlock = arrayMoveImmutable(tmpBlock, oldIndex, newIndex).filter(
            (el) => !!el,
        );
        setDerivedVars(tmpBlock);
    };

    return (
        <>
            <Button size="small" type="primary" ghost onClick={showModal}>
                Add Variables
            </Button>
            <Modal
                title={
                    <Typography.Title level={4}>
                        Global Variables List
                    </Typography.Title>
                }
                onCancel={closeForm}
                onOk={handleSubmit}
                open={isModalOpen}
                destroyOnClose
                width={600}
                maskClosable={false}
                style={{ top: 10 }}
            >
                <Row
                    style={{
                        maxHeight: "70vh",
                        overflowY: "auto",
                        overflowX: "hidden",
                        scrollbarWidth: "thin",
                    }}
                    gutter={[20, 20]}
                >
                    {!disableWrite && (
                        <>
                            <Col span={10}>
                                <Button
                                    size="small"
                                    disabled={disableWrite}
                                    type="primary"
                                    onClick={() => {
                                        message.info(
                                            `Copied Variables to clipboard!`,
                                        );
                                        navigator.clipboard.writeText(
                                            JSON.stringify({
                                                base_variables: baseVars,
                                                derived_variables: derivedVars,
                                            }),
                                        );
                                    }}
                                >
                                    Copy the Variables to Clipboard
                                </Button>
                            </Col>
                            <Col span={14}>
                                <Form.Item label={"Paste Variables here"}>
                                    <Input.TextArea
                                        disabled={disableWrite}
                                        value={tempConfig}
                                        placeholder="Paste Variables here"
                                        onChange={(e) =>
                                            setTempConfig(e.target.value)
                                        }
                                    />
                                    <Button
                                        disabled={disableWrite}
                                        type="primary"
                                        size="small"
                                        onClick={() => {
                                            try {
                                                const parsedJson =
                                                    JSON.parse(tempConfig);
                                                setBaseVars(
                                                    parsedJson?.base_variables ||
                                                        parsedJson?.questionVars ||
                                                        [],
                                                );
                                                setDerivedVars(
                                                    parsedJson?.derived_variables ||
                                                        parsedJson?.questionDerivedVars ||
                                                        [],
                                                );
                                                setTempConfig("");
                                                message.info("Updated!");
                                            } catch (e) {
                                                // captureException(e)
                                                message.error("Error in JSON!");
                                            }
                                        }}
                                    >
                                        Replace config
                                    </Button>
                                    <Divider type="vertical" />
                                    <Button
                                        disabled={disableWrite}
                                        type="primary"
                                        size="small"
                                        onClick={() => {
                                            try {
                                                const parsedJson =
                                                    JSON.parse(tempConfig);
                                                setBaseVars([
                                                    ...(baseVars ?? []),
                                                    ...(parsedJson?.base_variables ||
                                                        parsedJson?.questionVars ||
                                                        []),
                                                ]);
                                                setDerivedVars([
                                                    ...(derivedVars ?? []),
                                                    ...(parsedJson?.derived_variables ||
                                                        parsedJson?.questionDerivedVars ||
                                                        []),
                                                ]);
                                                setTempConfig("");
                                                message.info("Updated!");
                                            } catch (e) {
                                                // captureException(e);
                                                message.error("Error in JSON!");
                                            }
                                        }}
                                    >
                                        Append config
                                    </Button>
                                </Form.Item>
                            </Col>
                        </>
                    )}
                    <Col span={24}>
                        <Can I="edit" a="worksheet_details" passThrough>
                            {(allowed: boolean) => (
                                <>
                                    <SortableContainerCustom
                                        onSortEnd={onSortQuestionEnd}
                                        useDragHandle
                                    >
                                        <List
                                            size="large"
                                            bordered
                                            header={<h3>Base Variables</h3>}
                                            dataSource={baseVars}
                                            renderItem={(
                                                item: any,
                                                index: number,
                                            ) => {
                                                const { name, value } = item;
                                                return (
                                                    <SortableItemCustom
                                                        disabled={disableWrite}
                                                        index={index}
                                                        sid={index}
                                                        key={index}
                                                    >
                                                        <List.Item key={index}>
                                                            <Badge.Ribbon
                                                                text={index + 1}
                                                                color="#faad14"
                                                                placement="end"
                                                            >
                                                                <DragHandle />
                                                                <Row
                                                                    style={{
                                                                        paddingLeft:
                                                                            "20px",
                                                                        minWidth:
                                                                            "400px",
                                                                    }}
                                                                >
                                                                    <Col
                                                                        span={
                                                                            24
                                                                        }
                                                                    >
                                                                        <Form.Item
                                                                            label="Name"
                                                                            validateStatus={
                                                                                !name?.trim?.()
                                                                                    ? "error"
                                                                                    : ""
                                                                            }
                                                                            help={
                                                                                !name?.trim?.()
                                                                                    ? "Field cannot be empty"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            <Input
                                                                                value={
                                                                                    name
                                                                                }
                                                                                onChange={(
                                                                                    e,
                                                                                ) => {
                                                                                    let tmpVars =
                                                                                        cloneDeep(
                                                                                            baseVars,
                                                                                        );
                                                                                    tmpVars[
                                                                                        index
                                                                                    ].name =
                                                                                        e.target.value;
                                                                                    setBaseVars(
                                                                                        tmpVars,
                                                                                    );
                                                                                }}
                                                                                disabled={
                                                                                    !allowed ||
                                                                                    isReadOnlyMode
                                                                                }
                                                                                placeholder="Name"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col
                                                                        span={
                                                                            24
                                                                        }
                                                                    >
                                                                        <Form.Item label="Value">
                                                                            <Input.TextArea
                                                                                value={
                                                                                    value
                                                                                }
                                                                                onChange={(
                                                                                    e,
                                                                                ) => {
                                                                                    let tmpVars =
                                                                                        cloneDeep(
                                                                                            baseVars,
                                                                                        );
                                                                                    tmpVars[
                                                                                        index
                                                                                    ].value =
                                                                                        e.target.value;
                                                                                    setBaseVars(
                                                                                        tmpVars,
                                                                                    );
                                                                                }}
                                                                                disabled={
                                                                                    !allowed ||
                                                                                    isReadOnlyMode
                                                                                }
                                                                                placeholder="Value"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Badge.Ribbon>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexWrap:
                                                                        "wrap",
                                                                    width: "100%",
                                                                    gap: "10px",
                                                                    marginLeft:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <Button
                                                                    icon={
                                                                        <DeleteOutlined
                                                                            style={{
                                                                                color: "red",
                                                                            }}
                                                                        />
                                                                    }
                                                                    shape="circle"
                                                                    danger
                                                                    disabled={
                                                                        !allowed ||
                                                                        isReadOnlyMode
                                                                    }
                                                                    onClick={() => {
                                                                        let tmpChildren =
                                                                            cloneDeep(
                                                                                baseVars,
                                                                            );
                                                                        pullAt(
                                                                            tmpChildren,
                                                                            index,
                                                                        );
                                                                        setBaseVars(
                                                                            tmpChildren,
                                                                        );
                                                                    }}
                                                                />
                                                                <Button
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    icon={
                                                                        <CopyIcon />
                                                                    }
                                                                    type="primary"
                                                                    shape="circle"
                                                                    onClick={() => {
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                baseVars,
                                                                            );
                                                                        tmpBlock.splice(
                                                                            index,
                                                                            0,
                                                                            _.clone(
                                                                                item,
                                                                            ),
                                                                        );
                                                                        setBaseVars(
                                                                            tmpBlock,
                                                                        );
                                                                        message.info(
                                                                            `Duplicated!`,
                                                                        );
                                                                    }}
                                                                />
                                                                <Button
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    type="primary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        message.info(
                                                                            `Copied to clipboard!`,
                                                                        );
                                                                        navigator.clipboard.writeText(
                                                                            JSON.stringify(
                                                                                {
                                                                                    base_variables:
                                                                                        [
                                                                                            item,
                                                                                        ],
                                                                                },
                                                                            ),
                                                                        );
                                                                    }}
                                                                >
                                                                    Copy
                                                                </Button>
                                                            </div>
                                                        </List.Item>
                                                    </SortableItemCustom>
                                                );
                                            }}
                                            footer={
                                                <Button
                                                    onClick={() => {
                                                        setBaseVars([
                                                            ...baseVars,
                                                            {
                                                                name: "",
                                                                value: "",
                                                            },
                                                        ]);
                                                    }}
                                                    type="primary"
                                                    ghost
                                                    disabled={
                                                        !allowed ||
                                                        isReadOnlyMode
                                                    }
                                                >
                                                    Add Base Variable
                                                </Button>
                                            }
                                        />
                                    </SortableContainerCustom>
                                    <SortableContainerCustom
                                        onSortEnd={onSortDerivedEnd}
                                        useDragHandle
                                    >
                                        <List
                                            size="large"
                                            bordered
                                            header={<h3>Derived Variables</h3>}
                                            dataSource={derivedVars}
                                            renderItem={(
                                                item: any,
                                                index: number,
                                            ) => {
                                                const {
                                                    name,
                                                    computeFunction,
                                                } = item;
                                                return (
                                                    <SortableItemCustom
                                                        disabled={disableWrite}
                                                        index={index}
                                                        sid={index}
                                                        key={index}
                                                    >
                                                        <List.Item key={index}>
                                                            <Badge.Ribbon
                                                                text={index + 1}
                                                                color="#faad14"
                                                                placement="end"
                                                            >
                                                                <DragHandle />
                                                                <Row
                                                                    style={{
                                                                        paddingLeft:
                                                                            "20px",
                                                                        minWidth:
                                                                            "400px",
                                                                    }}
                                                                >
                                                                    <Col
                                                                        span={
                                                                            24
                                                                        }
                                                                    >
                                                                        <Form.Item
                                                                            label="Name"
                                                                            validateStatus={
                                                                                !name?.trim?.()
                                                                                    ? "error"
                                                                                    : ""
                                                                            }
                                                                            help={
                                                                                !name?.trim?.()
                                                                                    ? "Field cannot be empty"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            <Input
                                                                                value={
                                                                                    name
                                                                                }
                                                                                onChange={(
                                                                                    e,
                                                                                ) => {
                                                                                    let tmpVars =
                                                                                        cloneDeep(
                                                                                            derivedVars,
                                                                                        );
                                                                                    tmpVars[
                                                                                        index
                                                                                    ].name =
                                                                                        e.target.value;
                                                                                    setDerivedVars(
                                                                                        tmpVars,
                                                                                    );
                                                                                }}
                                                                                disabled={
                                                                                    !allowed ||
                                                                                    isReadOnlyMode
                                                                                }
                                                                                placeholder="Name"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col
                                                                        span={
                                                                            24
                                                                        }
                                                                    >
                                                                        <Form.Item
                                                                            label="Compute Function"
                                                                            validateStatus={
                                                                                !computeFunction?.output?.trim?.()
                                                                                    ? "error"
                                                                                    : ""
                                                                            }
                                                                            help={
                                                                                !computeFunction?.output?.trim?.()
                                                                                    ? "Field cannot be empty"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            <Input.TextArea
                                                                                value={
                                                                                    computeFunction.output
                                                                                }
                                                                                onChange={(
                                                                                    e,
                                                                                ) => {
                                                                                    let tmpVars =
                                                                                        cloneDeep(
                                                                                            derivedVars,
                                                                                        );
                                                                                    tmpVars[
                                                                                        index
                                                                                    ].computeFunction.output =
                                                                                        e.target.value;
                                                                                    setDerivedVars(
                                                                                        tmpVars,
                                                                                    );
                                                                                }}
                                                                                disabled={
                                                                                    !allowed ||
                                                                                    isReadOnlyMode
                                                                                }
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Badge.Ribbon>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexWrap:
                                                                        "wrap",
                                                                    width: "100%",
                                                                    gap: "10px",
                                                                    marginLeft:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <Button
                                                                    icon={
                                                                        <DeleteOutlined
                                                                            style={{
                                                                                color: "red",
                                                                            }}
                                                                        />
                                                                    }
                                                                    shape="circle"
                                                                    danger
                                                                    disabled={
                                                                        !allowed ||
                                                                        isReadOnlyMode
                                                                    }
                                                                    onClick={() => {
                                                                        let tmpChildren =
                                                                            cloneDeep(
                                                                                derivedVars,
                                                                            );
                                                                        pullAt(
                                                                            tmpChildren,
                                                                            index,
                                                                        );
                                                                        setDerivedVars(
                                                                            tmpChildren,
                                                                        );
                                                                    }}
                                                                />
                                                                <Button
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    icon={
                                                                        <CopyIcon />
                                                                    }
                                                                    type="primary"
                                                                    shape="circle"
                                                                    onClick={() => {
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                derivedVars,
                                                                            );
                                                                        tmpBlock.splice(
                                                                            index,
                                                                            0,
                                                                            _.clone(
                                                                                item,
                                                                            ),
                                                                        );
                                                                        setDerivedVars(
                                                                            tmpBlock,
                                                                        );
                                                                        message.info(
                                                                            `Duplicated!`,
                                                                        );
                                                                    }}
                                                                />
                                                                <Button
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    type="primary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        message.info(
                                                                            `Copied to clipboard!`,
                                                                        );
                                                                        navigator.clipboard.writeText(
                                                                            JSON.stringify(
                                                                                {
                                                                                    derived_variables:
                                                                                        [
                                                                                            item,
                                                                                        ],
                                                                                },
                                                                            ),
                                                                        );
                                                                    }}
                                                                >
                                                                    Copy
                                                                </Button>
                                                            </div>
                                                        </List.Item>
                                                    </SortableItemCustom>
                                                );
                                            }}
                                            footer={
                                                <Button
                                                    onClick={() => {
                                                        setDerivedVars([
                                                            ...derivedVars,
                                                            {
                                                                name: "",
                                                                computeFunction:
                                                                    {
                                                                        output: "",
                                                                        params: {},
                                                                    },
                                                            },
                                                        ]);
                                                    }}
                                                    type="primary"
                                                    ghost
                                                    disabled={
                                                        !allowed ||
                                                        isReadOnlyMode
                                                    }
                                                >
                                                    Add Derived Variable
                                                </Button>
                                            }
                                        />
                                    </SortableContainerCustom>
                                </>
                            )}
                        </Can>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default GlobalContextVariablesModal;
