import { gql } from "@apollo/client";export default gql`mutation createSet($object: set_insert_input = {}) {
    insert_set_one(
        object: $object
        on_conflict: { constraint: set_pkey, update_columns: id }
    ) {
        id
        title
        description
        cover
        chapter
        topic
        attempts
        duration
        status
        type
        subject
        class
        creator {
            email
        }
    }
}
`;
