import { useAuth0 } from "@auth0/auth0-react";

import {
    Field,
    RenderDynamicField,
    RenderField,
    RenderSelect,
} from "src/components";
import FormikPersist from "src/components/FormikPersist";
import { TagsAssignComponent } from "src/modules/book/components";
import { Button, Col, Form, Row, Select } from "antd";
import { FieldArray, withFormik } from "formik";
import React from "react";

const Option = Select.Option;

const subjects = [
    "Maths",
    "Science",
    "Physics",
    "Biology",
    "Chemistry",
    "Environmental Studies",
    "English",
    "Social Studies",
    "Social Science",
    "Hindi",
];

const classNames = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
];

const medium = ["english", "hindi"];

const board = ["cbse", "state"];

const BookForm = (props) => {
    const {
        onFinishFailed,
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
    } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values.tags);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Book Title"
                        placeholder="Book Title"
                        name={"title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Description"
                        placeholder="Book Description"
                        name={"description"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.description}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Cover"
                        placeholder="Book Cover"
                        name={"cover"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.cover}
                        rules={[{ required: true }]}
                        path="book/"
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Banner"
                        placeholder="Book Banner"
                        name={"other.banner"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.other.banner}
                        rules={[{ required: true }]}
                        path="book/"
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Subject"
                        placeholder="Book Subject"
                        name={"other.subject"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.other.subject}
                        rules={[{ required: true }]}
                    >
                        {subjects.map((subject) => (
                            <Option value={subject}>{subject}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Class"
                        placeholder="Book Class"
                        name={"other.class"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.other.class}
                        rules={[{ required: true }]}
                    >
                        {classNames.map((className) => (
                            <Option value={className}>{className}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Board"
                        placeholder="Book Board"
                        name={"other.board"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.other.board}
                        rules={[{ required: true }]}
                    >
                        {board.map((className) => (
                            <Option value={className}>{className}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Medium"
                        placeholder="Book Medium"
                        name={"other.medium"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.other.medium}
                        rules={[{ required: true }]}
                    >
                        {medium.map((className) => (
                            <Option value={className}>{className}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Rating"
                        placeholder="Book Rating"
                        name={"other.rating"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.other.rating}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Attempts"
                        placeholder="Book Attempts"
                        name={"other.attempts"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.other.attempts}
                        rules={[{ required: true }]}
                        disabled={true}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <FieldArray
                        name={"other.details"}
                        render={(arrayHelpers) => (
                            <RenderDynamicField
                                buttonText="Add Details"
                                keys={[
                                    {
                                        key: "title",
                                        type: "text",
                                        label: "Title",
                                    },
                                    {
                                        key: "description",
                                        type: "textarea",
                                        label: "Description",
                                    },
                                ]}
                                arrayHelpers={arrayHelpers}
                                values={values.other.details}
                                name={"other.details"}
                            />
                        )}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <FieldArray
                        name={"other.ratings"}
                        render={(arrayHelpers) => (
                            <RenderDynamicField
                                buttonText="Add Ratings"
                                keys={[
                                    {
                                        key: "user_name",
                                        type: "text",
                                        label: "User Name",
                                    },
                                    {
                                        key: "user_description",
                                        type: "text",
                                        label: "User Description",
                                    },
                                    {
                                        key: "rating",
                                        type: "number",
                                        label: "Rating",
                                    },
                                    {
                                        key: "comment",
                                        type: "textarea",
                                        label: "Comment",
                                    },
                                    {
                                        key: "avatar",
                                        type: "text",
                                        label: "Avatar",
                                    },
                                ]}
                                arrayHelpers={arrayHelpers}
                                values={values.other.ratings}
                                name={"other.ratings"}
                            />
                        )}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Book Status"
                        placeholder="Book Status"
                        name={"status"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.status}
                        rules={[{ required: true }]}
                    >
                        <Option value="inactive">Inactive</Option>
                        <Option value="active">Active</Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <TagsAssignComponent
                        name="tags"
                        formik={props}
                        isSet={true}
                        handleChange={(data) => {
                            setFieldValue(
                                ["tags", "data"],
                                data.map((d) => ({
                                    tag_id: d.value,
                                    label: d.label,
                                })),
                            );
                        }}
                        selected={values?.tags?.data?.map((tag, index) => ({
                            value: tag.tag_id,
                            key: index,
                            label: tag.label,
                        }))}
                    />
                    <br />
                </Col>

                <Col lg={12} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const BookFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { book } = props;
        return {
            ...(book?.id ? { id: book.id } : {}),
            title: book?.title || "",
            description: book?.description || "",
            cover: book?.cover || "",

            // other
            other: {
                subject: book?.other?.subject || "",
                class: book?.other?.class || "",
                board: book?.other?.board || "",
                medium: book?.other?.medium || "",

                banner: book?.other?.banner || "",

                rating: book?.other?.rating || 0,
                attempts: book?.other?.attempts || 0,

                details: book?.other?.details || [
                    {
                        title: "About this Book",
                        description: "Example Description",
                    },
                ],
                ratings: book?.other?.ratings || [
                    {
                        rating: 4,
                        comment: "Very good book.",
                        user_name: "John Cena",
                        user_description: "Maths Teacher, DPS",
                        avatar: "paste avatar link here",
                    },
                ],
            },

            status: book?.status || "inactive",
            type: "book",
            tags: {
                data:
                    book?.tags?.map((tag) => ({
                        tag_id: tag.tag.id,
                        // value: tag.tag.value, name: tag.tag.name
                        label: `${tag.tag.value} (${tag.tag.name})` || "",
                    })) || [],
            },
            // created_by: props.user_id,
        };
    },

    async handleSubmit(values, { props: { onFinish, book } }: any) {
        const isStudentPractise = values?.tags?.data?.filter(
            ({ label }) => label == "daily_practise (stream)",
        );
        const isRevision = values?.tags?.data?.filter(
            ({ label }) => label == "revision (stream)",
        );

        await onFinish({
            ...values,
            tags: {
                data: values.tags.data.map(({ tag_id }: any) => ({ tag_id })),
            },
            status:
                (values.status || book.status) == "active" ||
                (values.status || book.status) == "active_1" ||
                (values.status || book.status) == "active_2"
                    ? isStudentPractise && isStudentPractise.length > 0
                        ? "active_1"
                        : isRevision && isRevision.length > 0
                        ? "active_2"
                        : "active"
                    : values.status,
        });
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = BookFormWithFormik(BookForm);
const FormikFormWithUser = (props) => {
    const { user } = useAuth0();

    const namespace = "https://hasura.io/jwt/claims/";
    const user_id = user && user[namespace + "user_id"];
    return <FormikForm {...props} user_id={user_id} />;
};

export default FormikFormWithUser;
