const isValidCallbackVideo = ({ block }: any) => {
    const {
        data: { video },
    } = block;

    return {
        isValid: Boolean(video.trim()),
        error: (
            // <Alert
            //     message="Validation Error"
            //     description={`Video Block cannot be empty`}
            //     type="error"
            //     showIcon
            // />
            <></>
        ),
    };
};

export default isValidCallbackVideo;
