import { gql } from "@apollo/client";export default gql`query users(
  $limit: Int = 12
  $offset: Int = 0
  $where: user_bool_exp # $type: String # $parent_id: uuid
) {
  users: user(
    limit: $limit
    offset: $offset
    where: $where # order_by: { metadata: { created_at: desc } }
  ) {
    id
    name
    email
    role
    other
  }
}
`;
