import { gql } from "@apollo/client";export default gql`mutation updateOrder($id: Int!, $object: subscription_order_set_input) {
  updateOrder: update_subscription_order_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
    human_readable_id
    created_at
    breakdown
    amount
    other
    payment_id
    status
    updated_at
    user_id
  }
}
`;
