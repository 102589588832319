import React from "react";
import { usePlateEditorRef } from "@udecode/plate-core";
import { insertVideo } from "../../node";
import { ToolbarButton, ToolbarButtonProps } from "@udecode/plate-ui-toolbar";

export interface VideoToolbarButtonProps extends ToolbarButtonProps {}

export const VideoToolbarButton = (props: VideoToolbarButtonProps) => {
    const editor = usePlateEditorRef()!;

    return (
        <ToolbarButton
            onMouseDown={async (event) => {
                if (!editor) return;

                event.preventDefault();

                let videoString = "";

                insertVideo(editor, videoString);
            }}
            {...props}
        />
    );
};
