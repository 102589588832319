import { Card } from "antd";
import { renderTags } from "../../common";
import { renderer } from "../../helpers";
import { BLOCK_TYPES } from "../../common/index";
const { V2_STORIES_BLOCK } = BLOCK_TYPES;

const renderBlock = (block: any, blockType: string = V2_STORIES_BLOCK) => {
    const {
        data: {
            [blockType]: { title },
        },
        // children,
        heading,
        tags,
    } = block;
    return (
        <div>
            <div>
                {heading && <h3>Stories Title</h3>}
                {renderTags(tags)}

                <Card bodyStyle={{ padding: "12px" }}>{renderer(title)}</Card>
            </div>
        </div>
    );
};

export default renderBlock;
