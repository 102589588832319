import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { SketchPicker } from "react-color";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { CheckOutlined } from "@ant-design/icons";
import EditIcon from "src/components/EditIcon";

const ColorField = (props) => {
    const { label, block, setBlock, path } = props;
    const [isEditState, setEditState] = useState(!get(block, path));

    const color = get(block, path);
    return (
        <div style={{ width: "100%" }}>
            {isEditState ? (
                <div style={{ display: "flex" }}>
                    <Form.Item label={label}>
                        <SketchPicker
                            color={get(block, path)}
                            onChangeComplete={(e) => {
                                const value = e.hex;
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(tmpBlock, path, value);
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    {color && (
                        <Button
                            icon={
                                <CheckOutlined
                                    onClick={() => setEditState(false)}
                                />
                            }
                            shape="circle"
                            type="primary"
                            style={{ marginLeft: "10px" }}
                        />
                    )}
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "20px 0",
                    }}
                >
                    <div>
                        <h4>{label}</h4>
                        <div
                            style={{
                                height: "50px",
                                width: "100%",
                                backgroundColor: color,
                                borderRadius: "8px",
                            }}
                        />
                    </div>
                    {color && (
                        <EditIcon
                            isButton={true}
                            onClick={() => setEditState(true)}
                            style={{ marginLeft: "10px" }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default ColorField;
