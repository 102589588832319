import { gql } from "@apollo/client";

export default gql`
    query other_job_by_pk($id: Int!) {
        other_job_by_pk(id: $id) {
            id
            updated_at
            status
            data
            progress
        }
    }
`;
