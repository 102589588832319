import React, { useState, useEffect } from "react";
import { message, Button, Col, Row, Form, Select, Switch } from "antd";
import _ from "lodash";

import { SlateEditor } from "src/components/plate";
import { getOptionIndex } from "src/helpers/functions";
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { AudioPicker, DurationPicker } from "../../common";
// import VideoField from "../../common/VideoField";
import { BLOCK_TYPES } from "../../common/";
import { getValidateStatus } from "../MCQ/renderEditor";
import FeedbackField from "../../common/FeedBackField";
import PreviewBlocksField from "../../common/PreviewBlocksField";

const { V2_MCQ_BLOCK } = BLOCK_TYPES;

const { Option } = Select;

export const DragHandle = SortableHandle(() => <MenuOutlined />);

export const feedbackUpdate = (
    oldIndex: number,
    newIndex: number,
    feedbacks: any[],
) => {
    let tempFeedbacks = feedbacks.map((item: { index: number }) => {
        if (item.index === oldIndex) {
            return {
                ...item,
                index: newIndex,
            };
        }
        if (
            newIndex > oldIndex &&
            item.index <= newIndex &&
            item.index > oldIndex
        ) {
            return {
                ...item,
                index: item.index - 1,
            };
        }

        if (
            newIndex < oldIndex &&
            item.index >= newIndex &&
            item.index < oldIndex
        ) {
            return {
                ...item,
                index: item.index + 1,
            };
        }

        return item;
    });

    return tempFeedbacks;
};

const EditorMCQ = (
    {
        block,
        setBlock,
        isEditor,
        setIsEditor,
        currentBlock,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        mentionsList = [],
        hasMentions,
        toggleShouldBlockUI,
        disableWrite,
        hasPreviewBlocks,
        isReadOnlyMode,
    }: any,
    isMCQ = true,
) => {
    const {
        id,
        tmpId,
        data: {
            [V2_MCQ_BLOCK]: {
                options,
                correct_options,
                solution,
                layout,
                is_image,
            },
            other: {
                feedbacks = [],
                global_feedback = null,
                description = [],
            } = {},
        },
    } = block;
    const [reOrder, setReOrder] = useState(false);

    const [currentEditor, setCurrentEditor] = useState("");
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [isGbFeedbackModalOpen, setIsGbFeedbackModalOpen] = useState(false);
    const [curIndex, setCurIndex] = useState(-1);
    const [curCell, setCurCell] = useState({});

    // useEffect(() => {
    //     if (!disableWrite) setCurrentEditor(`${id || tmpId}_text`);
    // }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor !== "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    const SortableItemCustom = SortableElement((props: any) => {
        const { idx } = props;
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    border: "1px #aaa dashed",
                    padding: "10px",
                }}
            >
                <DragHandle />
                <span>Option {idx + 1}</span>
            </div>
        );
    });

    const SortableContainerCustom = SortableContainer(({ children }: any) => (
        <div>{children}</div>
    ));

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        const newOptions = arrayMoveImmutable(
            options,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        let tmpBlock = _.cloneDeep(block);
        tmpBlock = _.set(
            tmpBlock,
            ["data", V2_MCQ_BLOCK, "options"],
            newOptions,
        );

        if (oldIndex !== newIndex) {
            tmpBlock = _.set(
                tmpBlock,
                ["data", V2_MCQ_BLOCK, "correct_options"],
                [],
            );

            const tempFB = feedbackUpdate(oldIndex, newIndex, feedbacks);

            tmpBlock = _.set(tmpBlock, ["data", "other", "feedbacks"], tempFB);
        }
        setBlock(tmpBlock);
    };

    const renderOption = (option: any, idx: number, isMCQ = false) => (
        <div
            key={idx}
            style={{
                border: "1px solid #999999",
                borderRadius: "15px",
                marginBottom: "10px",
                margin: "10px",
                padding: "10px",
            }}
        >
            <h4>Text</h4>
            <Row
                key={idx}
                justify="start"
                style={{
                    border: "1px solid #ECECEC",
                    borderRadius: "15px",
                    marginBottom: "10px",
                }}
            >
                <Col
                    span={1}
                    style={{
                        paddingLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            padding: "10px 0px",
                        }}
                    >
                        <div
                            style={{
                                borderRadius: "50%",
                                width: "24px",
                                height: "24px",
                                justifyContent: "center",
                                alignItems: "center",
                                background:
                                    correct_options.length &&
                                    isMCQ &&
                                    correct_options?.includes(idx + 1)
                                        ? "#4EDEB2"
                                        : "lightgray",
                                color: "#333333",
                                display: "inline-flex",
                                fontSize: "12px",
                                fontWeight: "bold",
                            }}
                        >
                            {getOptionIndex(idx)}.
                        </div>
                    </div>
                </Col>
                <Col
                    span={21}
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div style={{ width: "100%" }}>
                        <SlateEditor
                            disabled={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`${id || tmpId}_option_text_${idx}`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        V2_MCQ_BLOCK,
                                        "options",
                                        idx,
                                        "text",
                                    ],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            value={option.text}
                            isFocused={
                                currentEditor ===
                                `${id || tmpId}_option_text_${idx}`
                            }
                            setEditor={(id: string) => {
                                if (isMCQ || !reOrder) setCurrentEditor(id);
                            }}
                            isOption={true}
                        />
                    </div>
                </Col>

                <Col span={2} style={{ paddingTop: "10px" }}>
                    <Button
                        disabled={disableWrite}
                        icon={<DeleteOutlined />}
                        type="primary"
                        shape="circle"
                        danger
                        size="small"
                        onClick={() => {
                            if (options.length === 1) {
                                message.warn(`Need at least 1 option`);
                                return;
                            }

                            let tmpBlock = _.cloneDeep(block);
                            const tmpOptions = [...options];
                            _.pullAt(tmpOptions, idx);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", V2_MCQ_BLOCK, "options"],
                                tmpOptions,
                            );

                            if (
                                isMCQ &&
                                correct_options[0] &&
                                idx < correct_options[0]
                            )
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_MCQ_BLOCK, "correct_options"],
                                    [],
                                );

                            if (!isMCQ && Boolean(correct_options[idx]))
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_MCQ_BLOCK, "correct_options"],
                                    [],
                                );

                            const tempFB = [...feedbacks]
                                .filter((item) => item.index !== idx)
                                .map((item) => ({
                                    ...item,
                                    index:
                                        item.index > idx
                                            ? item.index - 1
                                            : item.index,
                                }));

                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "other", "feedbacks"],
                                tempFB,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Col>
            </Row>

            <div style={{ width: "100%" }}>
                <Form.Item label={"Feedback"}>
                    <Button onClick={() => showFeedbackModal(idx)}>
                        {feedbacks.some(
                            (fb: { index: number; values: any[] }) =>
                                fb.index === idx && fb.values?.length > 0,
                        )
                            ? "Edit"
                            : "Add"}{" "}
                        Feedback
                    </Button>
                </Form.Item>
            </div>

            <div style={{ width: "100%" }}>
                <h4>Explanation</h4>
                <SlateEditor
                    disabled={disableWrite}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    id={`${id || tmpId}_option_explanation_${idx}`}
                    onChange={(value: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            [
                                "data",
                                V2_MCQ_BLOCK,
                                "options",
                                idx,
                                "explanation",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    value={option.explanation}
                    isFocused={
                        currentEditor ===
                        `${id || tmpId}_option_explanation_${idx}`
                    }
                    setEditor={(id: string) => {
                        if (isMCQ || !reOrder) setCurrentEditor(id);
                    }}
                    isOption={true}
                    required={false}
                />
            </div>
        </div>
    );

    const renderCorrectOption = () => (
        <>
            <Row>
                <h3 style={{ padding: "0px 24px", fontWeight: "bold" }}>
                    Correct Option
                </h3>
            </Row>
            <Row
                style={{ padding: "0px 24px" }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    validateStatus={!correct_options[0] ? "error" : ""}
                    help={!correct_options[0] ? "Field cannot be empty" : ""}
                >
                    <Select
                        disabled={disableWrite}
                        value={correct_options}
                        style={{ width: 120 }}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", V2_MCQ_BLOCK, "correct_options"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        mode={`multiple`}
                    >
                        {options.map((option: any, idx: number) => {
                            return (
                                <Option value={idx + 1} key={idx}>
                                    {idx + 1}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Row>
        </>
    );

    const renderCorrectOrder = () => (
        <Row>
            <Col span={24}>
                <h3
                    style={{
                        padding: "0px 24px",
                        fontWeight: "bold",
                    }}
                >
                    Correct Order of Options
                </h3>
            </Col>
            {options.map((_option: any, idx: number) => {
                return (
                    <Col span={24}>
                        <Row
                            style={{ padding: "0px 24px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                validateStatus={
                                    getValidateStatus(correct_options, idx)
                                        .error
                                }
                                help={
                                    getValidateStatus(correct_options, idx).msg
                                }
                            >
                                <Select
                                    disabled={disableWrite}
                                    value={correct_options[idx] || null}
                                    style={{ width: 120 }}
                                    onChange={(value: any) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "data",
                                                V2_MCQ_BLOCK,
                                                "correct_options",
                                                idx,
                                            ],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                >
                                    {options.map((option: any, idx: number) => {
                                        return (
                                            <Option value={idx + 1} key={idx}>
                                                {String.fromCharCode(
                                                    97 + idx,
                                                ).toUpperCase()}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Row>
                    </Col>
                );
            })}
        </Row>
    );

    const showFeedbackModal = (index: number) => {
        const findCellIndex = feedbacks.findIndex(
            (item: any) => item?.index === index,
        );

        setCurIndex(
            findCellIndex !== -1 ? findCellIndex : feedbacks?.length || 0,
        );
        setCurCell(
            findCellIndex !== -1
                ? feedbacks[findCellIndex]
                : {
                      index,
                      values: [],
                  },
        );
        setIsFeedbackModalOpen(true);
    };

    const showGbFeedbackModal = () => {
        setCurIndex(0);
        setCurCell(
            global_feedback || {
                index: 0,
                values: [],
            },
        );
        setIsGbFeedbackModalOpen(true);
    };

    const closeForm = () => {
        setCurIndex(-1);
        setCurCell({});
        setIsFeedbackModalOpen(false);
        setIsGbFeedbackModalOpen(false);
    };

    const feedbackFieldProps = {
        isModalOpen: isGbFeedbackModalOpen || isFeedbackModalOpen,
        isGlobal: isGbFeedbackModalOpen ? true : false,
        closeForm,
        feedback: curCell,
        onSave: (data: any) => {
            if (isFeedbackModalOpen) {
                const tempFB = [...feedbacks];
                tempFB[curIndex] = data;

                const obj = {
                    ...block,
                    data: {
                        ...block.data,
                        other: { ...block.data.other, feedbacks: tempFB },
                    },
                };
                setBlock(obj);
            }

            if (isGbFeedbackModalOpen) {
                const obj = {
                    ...block,
                    data: {
                        ...block.data,
                        other: { ...block.data.other, global_feedback: data },
                    },
                };
                setBlock(obj);
            }
        },
        curIndex,
        isEditor,
        setIsEditor,
        hasMentions,
        mentionsList,
        disableWrite,
        isReadOnlyMode,
    };

    return (
        <>
            <FeedbackField {...feedbackFieldProps} />
            <div>
                <h3
                    style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                    }}
                >
                    Preview Area
                </h3>
                <Row
                    style={{
                        width: "100%",
                        border: "1px dashed #808080",
                        margin: "10px auto",
                        padding: "20px",
                    }}
                >
                    {hasPreviewBlocks && (
                        <PreviewBlocksField
                            disableWrite={disableWrite}
                            fixed_blocks={
                                block?.data?.other?.fixed_blocks || []
                            }
                            updateFixedBlocks={(data: any) => {
                                const obj = {
                                    ...block,
                                    data: {
                                        ...block.data,
                                        other: {
                                            ...block.data.other,
                                            fixed_blocks: data,
                                        },
                                    },
                                };
                                setBlock(obj);
                            }}
                        />
                    )}
                    <Col span={22}>
                        <h3
                            style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                            }}
                        >
                            Question
                        </h3>
                        <SlateEditor
                            disabled={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`${id || tmpId}_text`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "other", "description"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            value={description}
                            isFocused={currentEditor == `${id || tmpId}_text`}
                            setEditor={(id: string) => setCurrentEditor(id)}
                        />
                    </Col>
                    <Col span={24}>
                        {[
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    margin: "20px auto",
                                }}
                            >
                                <h3>Background Audio</h3>
                                <AudioPicker
                                    disabled={isReadOnlyMode}
                                    block={block}
                                    setBlock={setBlock}
                                />
                            </div>
                        )}
                    </Col>
                </Row>

                <h3
                    style={{
                        fontWeight: "bold",
                        margin: "10px 0",
                    }}
                >
                    Play Area
                </h3>
                <div
                    style={{
                        width: "100%",
                        border: "1px dashed #808080",
                        margin: "10px auto",
                        padding: "20px",
                    }}
                >
                    <Row justify="space-between">
                        <Col>
                            <h3
                                style={{
                                    padding: "0px 24px",
                                    fontWeight: "bold",
                                }}
                            >
                                Options
                            </h3>
                        </Col>
                        <Col>
                            {!isMCQ && (
                                <Form.Item label="Enable Re-order">
                                    <Switch
                                        checked={reOrder}
                                        onChange={setReOrder}
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <div>
                        {options.map((option: any, idx: number) =>
                            renderOption(option, idx, true),
                        )}
                        {!disableWrite && (
                            <>
                                <h4>Sort the options</h4>
                                <SortableContainerCustom
                                    onSortEnd={onSortEnd}
                                    useDragHandle
                                    axis="xy"
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            width: "100%",
                                            flexWrap: "wrap",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        {options.map((_: any, idx: number) => (
                                            <SortableItemCustom
                                                idx={idx}
                                                key={`item-${idx}`}
                                                index={idx}
                                            />
                                        ))}
                                    </div>
                                </SortableContainerCustom>
                            </>
                        )}
                        <Button
                            disabled={disableWrite}
                            type="dashed"
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        V2_MCQ_BLOCK,
                                        "options",
                                        options.length,
                                    ],
                                    { feedback: [], text: [], explanation: [] },
                                );
                                setIsNewBlock(true);
                                setBlock(tmpBlock);
                                setCurrentEditor(
                                    `${id || tmpId}_option_${options.length}`,
                                );
                            }}
                            style={{ margin: "0px 24px" }}
                        >
                            Add Option
                        </Button>
                    </div>
                    <br />

                    <Row>
                        <h3 style={{ padding: "0px 24px", fontWeight: "bold" }}>
                            Solution
                        </h3>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col span={22}>
                            <SlateEditor
                                disabled={disableWrite}
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                id={`${id || tmpId}_solution`}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", V2_MCQ_BLOCK, "solution"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                value={solution}
                                isFocused={
                                    currentEditor === `${id || tmpId}_solution`
                                }
                                setEditor={(id: string) => setCurrentEditor(id)}
                                required={false}
                            />
                        </Col>
                    </Row>

                    {isMCQ ? renderCorrectOption() : renderCorrectOrder()}

                    <Form.Item label="Layout">
                        <Select
                            disabled={disableWrite}
                            value={layout || null}
                            style={{ width: 120 }}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_MCQ_BLOCK, "layout"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        >
                            <Option value={"horizontal"}>{"Horizontal"}</Option>
                            <Option value={"vertical"}>{"Vertical"}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label={"Has only Image?"}>
                        <Switch
                            checked={is_image}
                            onChange={(v) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_MCQ_BLOCK, "is_image"],
                                    v,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>

                    <div style={{ marginTop: "20px" }}>
                        <Button onClick={() => showGbFeedbackModal()}>
                            {global_feedback &&
                            global_feedback.values?.length > 0
                                ? "Edit"
                                : "Add"}{" "}
                            Global Feedback
                        </Button>
                    </div>

                    {/* <div>
                        <VideoField
                            label={`Solution Video URL`}
                            path={["data", "other", "solution_video"]}
                            block={block}
                            setBlock={setBlock}
                            isYoutube={true}
                            toggleShouldBlockUI={toggleShouldBlockUI}
                        />
                    </div> */}

                    {[
                        "timed",
                        "personalized_learning",
                        "personalized_learning_v2",
                    ].includes(worksheet?.type) && (
                        <div
                            style={{
                                margin: "20px auto",
                            }}
                        >
                            <h3>Duration</h3>
                            <DurationPicker
                                disabled={disableWrite}
                                onChange={onDurationChange}
                                initialValue={block?.data?.other?.duration || 0}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default EditorMCQ;
