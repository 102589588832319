import { gql } from "@apollo/client";export default gql`query questions(
    $limit: Int = 12
    $offset: Int = 0
    $where: question_bool_exp # $type: String # $parent_id: uuid
) {
    questions: question(
        limit: $limit
        offset: $offset
        where: $where # order_by: { metadata: { created_at: desc } }
    ) {
        id
        text
        options
        correct_option
    }
}
`;
