import { Input } from "antd";
import { AudioPicker, BLOCK_TYPES, DurationPicker } from "../../common/index";
import { renderEditorMCQ } from "../MCQ";
import renderEditorMixed from "../V2Mixed/renderEditor";
import { renderEditorV2Stories } from "../V2Stories";
import _ from "lodash";
import { renderEditorV2Voice } from "../V2Voice";
import { renderEditorCrossword } from "../Crossword";
const {
    V2_RAPID_QA_BLOCK,
    MCQ_BLOCK,
    V2_MIXED_BLOCK,
    V2_STORIES_BLOCK,
    V2_VOICE_BLOCK,
    CROSSWORD_BLOCK,
} = BLOCK_TYPES;
const { TextArea } = Input;

const EditorMCQ = renderEditorMCQ;
const EditorMixed = renderEditorMixed;
const EditorStories = renderEditorV2Stories;
const EditorVoice = renderEditorV2Voice;
const EditorCW = renderEditorCrossword;

const renderEditorV2RapidQA = ({
    block,
    setBlock,
    currentSubBlock,
    isEditor,
    setIsEditor,
    currentBlock,
    isNewBlock,
    setIsNewBlock,
    worksheet,
    blockType = V2_RAPID_QA_BLOCK,
    toggleShouldBlockUI,
    isReadOnlyMode,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [blockType]: { title, sub_title = "", banner },
        },
        children,
    } = block;

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    if (currentSubBlock == null) {
        const renderEditorCaseStudy = () => (
            <div>
                <div>
                    <h4>Title</h4>
                    <Input
                        value={title}
                        onChange={(e) => {
                            const value = e.target.value;
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", blockType, "title"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </div>

                {blockType !== V2_RAPID_QA_BLOCK && (
                    <div>
                        <h4>Sub Title</h4>
                        <Input
                            value={sub_title}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", blockType, "sub_title"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </div>
                )}

                <div>
                    <h4>Banner</h4>
                    <TextArea
                        value={banner}
                        onChange={(e) => {
                            const value = e.target.value;
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", blockType, "banner"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </div>

                {["timed", "personalized_learning"].includes(worksheet?.type) &&
                    blockType === V2_RAPID_QA_BLOCK && (
                        <div
                            style={{
                                border: "1px dashed #808080",
                                margin: "10px auto",
                                padding: "10px 10px",
                            }}
                        >
                            <h3>Duration</h3>
                            <DurationPicker
                                onChange={onDurationChange}
                                initialValue={block?.data?.other?.duration || 0}
                            />
                        </div>
                    )}

                {["personalized_learning"].includes(worksheet?.type) && (
                    <div
                        style={{
                            border: "1px dashed #808080",
                            margin: "10px auto",
                            padding: "10px 10px",
                        }}
                    >
                        <h3>BackgroundAudio</h3>
                        <AudioPicker
                            block={block}
                            disabled={isReadOnlyMode}
                            setBlock={setBlock}
                        />
                    </div>
                )}
            </div>
        );
        return <>{renderEditorCaseStudy()}</>;
    } else {
        const editorProps = {
            block: children[currentSubBlock],
            setBlock: (subBlock: any) => {
                let tmpBlock = _.cloneDeep(block);

                tmpBlock = _.set(
                    tmpBlock,
                    ["children", currentSubBlock],
                    subBlock,
                );
                setBlock(tmpBlock);
            },
            isEditor,
            setIsEditor,
            currentBlock: currentSubBlock + 1,
            isNewBlock,
            setIsNewBlock,
            worksheet,
            showDifficultyLevel: blockType === V2_RAPID_QA_BLOCK,
            toggleShouldBlockUI,
        };

        return (
            <div>
                <CustomEditor
                    {...editorProps}
                    type={children[currentSubBlock].type}
                />
            </div>
        );
    }
};

const CustomEditor = (props: any) => {
    const { type } = props;
    switch (type) {
        case MCQ_BLOCK:
            return <EditorMCQ {...props} />;
        case V2_MIXED_BLOCK:
            return <EditorMixed {...props} />;
        case V2_STORIES_BLOCK:
            return <EditorStories {...props} />;
        case V2_VOICE_BLOCK:
            return <EditorVoice {...props} />;
        case CROSSWORD_BLOCK:
            return <EditorCW {...props} />;
        default:
            return <></>;
    }
};

export default renderEditorV2RapidQA;
