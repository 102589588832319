import { MenuOutlined } from "@ant-design/icons";
import {
    Badge,
    Space,
    Row,
    Col,
    Button,
    Card,
    Form,
    Input,
    message,
    Typography,
} from "antd";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import React, { useState } from "react";
import { BLOCK_TYPES } from "../../../common";
import { arrayMoveImmutable } from "array-move";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { Actions } from "src/modules/worksheet/components/WorksheetEditor/components/Editor/BlocksColumn/Actions";
import {
    isValidCallbackV2Stories,
    renderEditorV2Stories,
    V2_STORIES_DEFAULT_VALUE,
} from "../../V2Stories";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import { BLOCK_COMPUTE_FUNCS } from "../../ProgressLevel/defaultValue";

const { V2_STORIES_BLOCK } = BLOCK_TYPES;

const EditorV2Stories = renderEditorV2Stories;

const FigmaStoryEditor = (props: any) => {
    const {
        setStories,
        stories,
        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        disableWrite,
        isReadOnlyMode,
        mentionsList = [],
        hasMentions,
        storiesPath,
        setNewJob,
        updateStoriesJob,
        previewData,
    } = props;

    const [currentSubBlock, setCurrentSubBlock] = useState(-1);
    const [tempChildren, setTempChildren] = useState("");
    const [isPlaying, setIsPLaying] = useState(false);

    const updateCurrentBlock = (val: number) => {
        setIsPLaying(false);
        setCurrentSubBlock(val);
    };

    const editorProps = {
        block: stories[currentSubBlock],
        setBlock: (subBlock: any, storiesId: any) => {
            let tmpBlock = _.cloneDeep(stories);
            tmpBlock = _.set(tmpBlock, [currentSubBlock], subBlock);
            setStories(tmpBlock, storiesId);
        },
        isChildStory: true,
        isEditor,
        setIsEditor,
        currentBlock: currentSubBlock + 1,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        childBlockCount: stories?.length || 0,
        isPlaying,
        setIsPLaying,
        disableWrite,
        isReadOnlyMode,
        mentionsList,
        hasMentions,
        storiesPath,
        setNewJob,
        updateStoriesJob,
        isChild: true,
        previewData,
    };

    const DragHandle = SortableHandle(() => <MenuOutlined />);

    const SortableItem = SortableElement(({ value, child }) => {
        const actionsProps = {
            deleteBlock: () => {
                updateCurrentBlock(currentSubBlock - 1);
                const tmpOptions = [...stories];
                _.pullAt(tmpOptions, currentSubBlock);
                setStories(tmpOptions);
            },
            idx: currentSubBlock,
            addBlockCustom: (type: string, index: number, newBlock: any) => {
                const tmpChildren = [...stories];
                tmpChildren.splice(index, 0, {
                    tmpId: uuid(),
                    id: uuid(),
                    ...newBlock,
                });
                setStories(tmpChildren);
                setCurrentSubBlock(index);
            },
            block: child,
        };

        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Badge.Ribbon
                    color={"cyan"}
                    text={value + 1}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            background:
                                currentSubBlock === value ? "#F1F1F1" : "",
                            borderBottom:
                                child?.type === V2_STORIES_BLOCK &&
                                !isValidCallbackV2Stories({ block: child })
                                    .isValid
                                    ? "3px solid red"
                                    : currentSubBlock === value
                                    ? "3px solid indigo"
                                    : "",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            minWidth: "90px",
                        }}
                        bodyStyle={{
                            background: "transparent",
                        }}
                        onClick={() => {
                            updateCurrentBlock(value);
                        }}
                    >
                        {child?.data[V2_STORIES_BLOCK]?.name || "Stories"}
                        {currentSubBlock === value && !disableWrite && (
                            <div
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    padding: "2px",
                                }}
                            >
                                <DragHandle />
                            </div>
                        )}
                    </Card>
                </Badge.Ribbon>

                {currentSubBlock === value && !disableWrite && (
                    <Actions {...actionsProps} />
                )}
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={index}
                        child={child}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const tmpChildren = arrayMoveImmutable(
            stories,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        setStories(tmpChildren);
        updateCurrentBlock(newIndex);
    };

    return (
        <Row gutter={[20, 20]}>
            {!disableWrite && (
                <>
                    <Col span={24}>
                        <Typography.Title level={5}>
                            Add New Stories Block
                        </Typography.Title>
                    </Col>
                    <Col span={6}>
                        <Space>
                            <Button
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(stories);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [stories.length],
                                        {
                                            ...V2_STORIES_DEFAULT_VALUE(
                                                {
                                                    logic: {
                                                        compute_functions:
                                                            BLOCK_COMPUTE_FUNCS,
                                                    },
                                                },
                                                worksheet?.type,
                                            ),
                                            order: stories.length + 1,
                                            tmpId: uuid(),
                                            id: uuid(),
                                        },
                                    );
                                    setStories(tmpBlock);
                                    updateCurrentBlock(stories.length);
                                }}
                            >
                                Add Stories Block
                            </Button>
                        </Space>
                    </Col>
                    <Col
                        span={12}
                        style={{
                            display: "flex",
                        }}
                    >
                        <Input.TextArea
                            disabled={disableWrite}
                            value={tempChildren}
                            placeholder="Paste Block here"
                            onChange={(e) => setTempChildren(e.target.value)}
                            rows={2}
                        />
                        <Button
                            disabled={disableWrite}
                            type="primary"
                            size="small"
                            onClick={() => {
                                try {
                                    const parsedJson = JSON.parse(tempChildren);
                                    if (parsedJson?.type !== V2_STORIES_BLOCK) {
                                        message.warn("Invalid JSON");
                                        return;
                                    }
                                    let tmpBlock = _.cloneDeep(stories);

                                    if (parsedJson) {
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [stories.length],
                                            updateKeysAndCopy(
                                                _.omit(parsedJson, [
                                                    "id",
                                                    "worksheet_block_map_id",
                                                    "tmpId",
                                                ]),
                                                false,
                                            ),
                                        );
                                        setStories(tmpBlock);
                                        updateCurrentBlock(stories.length);
                                        message.success("Block Added");
                                        setTempChildren("");
                                    }
                                } catch (e) {
                                    // captureException(e)
                                    message.error("Error in JSON!");
                                }
                            }}
                        >
                            Add New Stories Block
                        </Button>
                    </Col>
                </>
            )}
            <Col span={24}>
                <SortableList
                    items={stories}
                    onSortEnd={onSortEnd}
                    axis="xy"
                    useDragHandle
                />
            </Col>
            <Col span={24}>
                <hr style={{ margin: "20px 0" }} />
                {currentSubBlock !== -1 && stories[currentSubBlock] && (
                    // children[currentSubBlock]?.type === V2_STORIES_BLOCK &&
                    <Row>
                        {stories[currentSubBlock].id && (
                            <Col span={15}>
                                <Form.Item label="Stories Id">
                                    <Input.Search
                                        value={
                                            stories[currentSubBlock].id ||
                                            stories[currentSubBlock].tmpId
                                        }
                                        onChange={() => {}}
                                        enterButton="Copy"
                                        size="middle"
                                        onSearch={() => {
                                            message.info(
                                                `Copied Stories Id to clipboard!`,
                                            );
                                            navigator.clipboard.writeText(
                                                `${
                                                    stories[currentSubBlock]
                                                        .id ||
                                                    stories[currentSubBlock]
                                                        .tmpId
                                                }`,
                                            );
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        )}

                        <Col span={24}>
                            <EditorV2Stories {...editorProps} />
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );
};

export default FigmaStoryEditor;
