import { BLOCK_TYPES, isEditorEmpty } from "../../common";

const { DRAG_AND_INPUT_BLOCK } = BLOCK_TYPES;

const isValidCallback = ({ block, blockType = DRAG_AND_INPUT_BLOCK }: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            [blockType]: {
                blanks = [],
                text = [],
                options = [],
                has_options,
                custom_keyboard,
            },
            other: { description = [] } = {},
        },
    } = block;

    const checkValiaCondition = () => {
        if (!blanks?.length) return false;
        if (has_options && (!options?.length || !options[0]?.length))
            return false;
        if (!has_options && !custom_keyboard?.length) return false;

        return true;
    };
    return {
        isValid:
            !isEditorEmpty(description) &&
            !isEditorEmpty(text) &&
            checkValiaCondition(),
        error: <></>,
    };
};

export default isValidCallback;
