import React from "react";
import { compose } from "src/helpers";
import {
    withCreateSetCollection,
    withDeleteSetCollection,
    withHookForAggregateCollection,
    withHookForCollections,
    withInsertBatchCollection,
    withUpdateSetCollection,
    withUpdateGroupCollection,
    withUpdateUserOther,
} from "src/modules/collection/operations";
import { withStateAndActionsForCollection } from "src/modules/collection/resolvers";
import ListView from "./component";
import {
    withIncrementCollectionDuration,
    withIncrementSetDuration,
} from "src/modules/content/operations";
import {
    withDeleteGroupCollection,
    withUpdateChapterOther,
} from "src/modules/book/operations";

// import { withDeleteContentsByCollectionId } from "src/modules/content/operations";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    withStateAndActionsForCollection,
    withHookForCollections,
    withHookForAggregateCollection,
    // withDeleteContentsByCollectionId,
    withUpdateSetCollection,
    withUpdateGroupCollection,
    withCreateSetCollection,
    withDeleteSetCollection,
    withIncrementSetDuration,
    withIncrementCollectionDuration,
    withInsertBatchCollection,
    withDeleteGroupCollection,
    withUpdateChapterOther,
    withUpdateUserOther,
)(List);
