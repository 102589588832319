import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import {
    Modal,
    Radio,
    Switch,
    Typography,
    Form,
    Space,
    Row,
    Col,
    Button,
    message,
    Select,
    Input,
} from "antd";
import _, { cloneDeep, set } from "lodash";
import React, { useEffect, useState } from "react";
import { RenderUpload } from "src/components";
import { BLOCK_TYPES } from "..";
import { captureException } from "@sentry/react";

const {
    CROSSWORD_BLOCK,
    DRAG_AND_SWIPE_BLOCK,
    SWIPE_AND_FORM_BLOCK,
    DRAG_EXPAND_NUMBER_BLOCK,
    INPUT_EXPAND_NUMBER_BLOCK,
    V2_DRAG_AND_SWIPE_BLOCK,
    DRAG_AND_TAG_BLOCK,
} = BLOCK_TYPES;

const CellInput = (props: any) => {
    const {
        isModalOpen,
        closeForm,
        curCell,
        curCellRow,
        curCellCol,
        crossword,
        setCrossword,
        blockType = CROSSWORD_BLOCK,
        grid,
        optionsForCorrectDropdown,
    } = props;

    const [isSubmitting, setSubmitting] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [currentCell, setCurrentCell] = useState(curCell);
    const [currentCoordinate, setCurrCoord] = useState("start");

    useEffect(() => setCurrentCell(curCell), [curCell]);

    const checkDisabled = () => {
        if (!currentCell) return true;

        if (
            (currentCell?.type === "variable" ||
                (currentCell?.type === "fillable" && currentCell?.content)) &&
            (!currentCell?.content?.value?.trim() ||
                (currentCell?.content?.type === "asset" &&
                    !currentCell?.content?.asset?.trim()))
        ) {
            return true;
        }

        if (
            currentCell?.type === "variable" &&
            !currentCell?.other?.variable?.position
        ) {
            return true;
        }

        if (
            blockType === CROSSWORD_BLOCK &&
            currentCell?.type === "fillable" &&
            (!currentCell?.correct_answer?.length ||
                currentCell?.correct_answer?.some(
                    (item: string) => !item?.trim(),
                ))
        ) {
            return true;
        }

        if (
            blockType === DRAG_AND_TAG_BLOCK &&
            currentCell?.has_tag &&
            !currentCell?.correct_answer?.length
        ) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        setDisabled(checkDisabled());
    }, [currentCell]);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            let tmpBlock = cloneDeep(crossword);

            const tempData = tmpBlock?.grid;

            tempData[curCellRow][curCellCol] = currentCell;

            tmpBlock = set(tmpBlock, ["grid"], tempData);

            setCrossword(tmpBlock);

            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e)
            console.log(e);
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        closeForm();
    };

    const checkCoordinate = (
        coordinates: { [x: string]: any[] },
        position: string | number,
        i: any,
        j: any,
    ) =>
        coordinates &&
        coordinates[position] &&
        coordinates[position][0] === i &&
        coordinates[position][1] === j;

    const renderAnswer = (answer: any, idx: number) => (
        <div
            key={idx}
            style={{
                width: "100%",
                display: "flex",
                gap: "16px",
                border: "1px solid #ECECEC",
                borderRadius: "15px",
                marginBottom: "10px",
            }}
        >
            <div
                style={{
                    padding: "10px 0px",
                    flexShrink: 0,
                }}
            >
                <div
                    style={{
                        borderRadius: "50%",
                        width: "24px",
                        height: "24px",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "lightgray",
                        color: "#333333",
                        display: "inline-flex",
                        fontSize: "12px",
                        fontWeight: "bold",
                    }}
                >
                    {idx + 1}.
                </div>
            </div>
            <Form.Item
                rules={[
                    {
                        required: true,
                    },
                ]}
                style={{ width: "100%" }}
                validateStatus={!answer.trim() ? "error" : ""}
                help={!answer.trim() ? "Field cannot be empty" : ""}
            >
                <Input
                    style={{
                        padding: "10px",
                        borderRadius: "8px",
                        width: "100%",
                    }}
                    value={answer || ""}
                    onChange={(e) => {
                        const tmpCell = cloneDeep(currentCell);
                        tmpCell.correct_answer[idx] = e.target.value;
                        setCurrentCell(tmpCell);
                    }}
                    required
                />
            </Form.Item>

            <Button
                icon={<DeleteOutlined />}
                type="primary"
                shape="circle"
                danger
                size="small"
                onClick={() => {
                    if (currentCell?.correct_answer.length == 1) {
                        message.warn(`Need at least 1 correct answer`);
                        return;
                    }

                    const tmpCell = cloneDeep(currentCell);
                    tmpCell?.correct_answer.splice(idx, 1);
                    setCurrentCell(tmpCell);
                }}
            />
        </div>
    );

    return (
        <>
            <Modal
                title={
                    <Typography.Title level={4}>Update Cell</Typography.Title>
                }
                open={isModalOpen}
                onOk={closeForm}
                onCancel={closeForm}
                destroyOnClose
                width={720}
                footer={null}
            >
                <Form>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Cell Type">
                                <Select
                                    disabled={[
                                        SWIPE_AND_FORM_BLOCK,
                                        DRAG_AND_SWIPE_BLOCK,
                                        DRAG_EXPAND_NUMBER_BLOCK,
                                        INPUT_EXPAND_NUMBER_BLOCK,
                                        V2_DRAG_AND_SWIPE_BLOCK,
                                        DRAG_AND_TAG_BLOCK,
                                    ].includes(blockType)}
                                    defaultValue={currentCell?.type}
                                    onChange={(value) => {
                                        const tmpCell = cloneDeep(currentCell);
                                        tmpCell.type = value;
                                        if (
                                            value === "empty" ||
                                            (value === "fillable" &&
                                                blockType === CROSSWORD_BLOCK)
                                        ) {
                                            tmpCell.content = null;
                                        } else if (
                                            ["variable", "fillable"].includes(
                                                value,
                                            )
                                        ) {
                                            if (!tmpCell.content) {
                                                tmpCell.content = {
                                                    type: "string",
                                                    value: "1",
                                                };
                                            }
                                        }
                                        setCurrentCell(tmpCell);
                                    }}
                                    options={[
                                        { value: "empty", label: "Empty" },
                                        {
                                            value: "variable",
                                            label: "Variable",
                                        },
                                        {
                                            value: "fillable",
                                            label: "Fillable",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>

                        {currentCell?.type === "fillable" && (
                            <Col span={12}>
                                <Form.Item label="Has Value?">
                                    <Switch
                                        disabled={[
                                            SWIPE_AND_FORM_BLOCK,
                                            DRAG_EXPAND_NUMBER_BLOCK,
                                            INPUT_EXPAND_NUMBER_BLOCK,
                                            V2_DRAG_AND_SWIPE_BLOCK,
                                            DRAG_AND_TAG_BLOCK,
                                        ].includes(blockType)}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={currentCell?.content}
                                        onChange={(value) => {
                                            const tmpCell =
                                                cloneDeep(currentCell);
                                            tmpCell.content = value
                                                ? {
                                                    type: "string",
                                                    value: "1",
                                                }
                                                : null;
                                            setCurrentCell(tmpCell);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        )}

                        {currentCell?.type === "fillable" &&
                            currentCell?.content && (
                                <Col span={22}>
                                    <Form.Item label="Content Type">
                                        <Select
                                            defaultValue={
                                                currentCell?.content?.type
                                            }
                                            onChange={(type) => {
                                                const tmpCell =
                                                    cloneDeep(currentCell);
                                                tmpCell.content = {
                                                    ...tmpCell.content,
                                                    type,
                                                };
                                                setCurrentCell(tmpCell);
                                            }}
                                            options={[
                                                {
                                                    value: "string",
                                                    label: "String",
                                                },
                                                {
                                                    value: "asset",
                                                    label: "Asset",
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            )}

                        {currentCell?.type === "fillable" &&
                            currentCell?.content &&
                            currentCell?.content?.type === "asset" && (
                                <Col span={22}>
                                    <Form.Item label="Value">
                                        <RenderUpload
                                            path="home-explore/document/"
                                            onChangeCustom={({
                                                target: { value },
                                            }: any) => {
                                                const tmpCell =
                                                    cloneDeep(currentCell);
                                                tmpCell.content = {
                                                    ...tmpCell.content,
                                                    asset: value,
                                                };
                                                setCurrentCell(tmpCell);
                                            }}
                                            label="upload an image"
                                            value={
                                                currentCell?.content?.asset ||
                                                ""
                                            }
                                            meta={{
                                                touched: true,
                                                error: !currentCell?.content
                                                    ?.asset
                                                    ? "Field cannot be Empty"
                                                    : "",
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}

                        {(currentCell?.type === "variable" ||
                            (currentCell?.type === "fillable" &&
                                currentCell?.content)) && (
                                <Col span={22}>
                                    <Form.Item label="Value">
                                        <Input
                                            placeholder={"Type the value here..."}
                                            value={currentCell?.content?.value}
                                            onChange={({
                                                target: { value },
                                            }: any) => {
                                                const tmpCell =
                                                    cloneDeep(currentCell);
                                                tmpCell.content = {
                                                    ...tmpCell.content,
                                                    value,
                                                };
                                                setCurrentCell(tmpCell);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}

                        {currentCell?.type === "variable" && (
                            <Col span={22}>
                                {/* <Form.Item
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    style={{ width: "100%" }}
                                    validateStatus={
                                        !currentCell?.other?.variable?.name?.trim()
                                            ? "error"
                                            : ""
                                    }
                                    help={
                                        !currentCell?.other?.variable?.name?.trim()
                                            ? "Field cannot be empty"
                                            : ""
                                    }
                                >
                                    <Input
                                        value={
                                            currentCell?.other?.variable
                                                ?.name || ""
                                        }
                                        onChange={(e) => {
                                            let tmpCell =
                                                cloneDeep(currentCell);
                                            tmpCell = _.set(
                                                tmpCell,
                                                ["other", "variable", "name"],
                                                e.target.value,
                                            );

                                            setCurrentCell(tmpCell);
                                        }}
                                        required
                                    />
                                </Form.Item> */}

                                <Form.Item label="Position">
                                    <Select
                                        defaultValue={
                                            currentCell?.other?.variable
                                                ?.position
                                                ? `${currentCell?.other?.variable?.position[0]}-${currentCell?.other?.variable?.position[1]}`
                                                : ""
                                        }
                                        onChange={(value) => {
                                            let tmpCell =
                                                cloneDeep(currentCell);
                                            tmpCell = _.set(
                                                tmpCell,
                                                [
                                                    "other",
                                                    "variable",
                                                    "position",
                                                ],
                                                value.split("-"),
                                            );

                                            setCurrentCell(tmpCell);
                                        }}
                                        options={[
                                            {
                                                value: "top-left",
                                                label: "Top Left",
                                            },
                                            {
                                                value: "top-right",
                                                label: "Top RIght",
                                            },
                                            {
                                                value: "bottom-left",
                                                label: "Bottom Left",
                                            },
                                            {
                                                value: "bottom-right",
                                                label: "Bottom RIght",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        )}

                        {[
                            DRAG_AND_SWIPE_BLOCK,
                            V2_DRAG_AND_SWIPE_BLOCK,
                        ].includes(blockType) &&
                            currentCell?.type === "fillable" && (
                                <>
                                    <Col span={12}>
                                        <Form.Item label="Is fixed?">
                                            <Switch
                                                checkedChildren={
                                                    <CheckOutlined />
                                                }
                                                unCheckedChildren={
                                                    <CloseOutlined />
                                                }
                                                checked={currentCell?.is_fixed}
                                                onChange={(value) => {
                                                    setCurrentCell({
                                                        ...currentCell,
                                                        is_fixed: value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}

                        {[DRAG_AND_TAG_BLOCK].includes(blockType) && (
                            <>
                                <Col span={12}>
                                    <Form.Item label="Has tag?">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                            checked={currentCell?.has_tag}
                                            onChange={(value) => {
                                                setCurrentCell({
                                                    ...currentCell,
                                                    has_tag: value,
                                                    correct_answer:
                                                        currentCell?.has_tag
                                                            ? currentCell.correct_answer
                                                            : [],
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                {currentCell?.has_tag && (
                                    <Col span={24}>
                                        <Form.Item label="Correct Answer">
                                            <Select
                                                value={
                                                    currentCell?.correct_answer
                                                }
                                                mode="multiple"
                                                style={{ width: "100%" }}
                                                placeholder="Correct Answer"
                                                onChange={(value) => {
                                                    const tmpCell =
                                                        cloneDeep(currentCell);
                                                    tmpCell.correct_answer =
                                                        value;
                                                    setCurrentCell(tmpCell);
                                                }}
                                                options={
                                                    optionsForCorrectDropdown
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                )}
                            </>
                        )}

                        {[CROSSWORD_BLOCK].includes(blockType) &&
                            currentCell?.type === "fillable" && (
                                <>
                                    <Col span={22}>
                                        <div
                                            style={{
                                                marginBottom: "20px",
                                            }}
                                        >
                                            Correct answers:
                                        </div>
                                        {!currentCell?.correct_answer
                                            ?.length && (
                                                <h4 style={{ color: "red" }}>
                                                    Add Correct answers
                                                </h4>
                                            )}
                                        <div>
                                            {currentCell?.correct_answer.map(
                                                (option: any, idx: number) =>
                                                    renderAnswer(option, idx),
                                            )}
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    const tmpCell =
                                                        cloneDeep(currentCell);
                                                    tmpCell?.correct_answer.push(
                                                        "",
                                                    );
                                                    setCurrentCell(tmpCell);
                                                }}
                                                style={{
                                                    margin: "0px 24px",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                Add Correct Answer
                                            </Button>
                                        </div>
                                    </Col>
                                </>
                            )}

                        {[DRAG_AND_SWIPE_BLOCK].includes(blockType) &&
                            currentCell?.type === "fillable" && (
                                <>
                                    <Col span={24}>
                                        <Form.Item label="Correct Answers">
                                            <Select
                                                value={
                                                    currentCell?.correct_answer
                                                }
                                                mode="tags"
                                                style={{ width: "100%" }}
                                                placeholder="Correct Answers"
                                                onChange={(value) => {
                                                    const tmpCell =
                                                        cloneDeep(currentCell);
                                                    tmpCell.correct_answer =
                                                        value;
                                                    setCurrentCell(tmpCell);
                                                }}
                                                options={[]}
                                                open={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}

                        {currentCell?.type === "variable" && (
                            <Col span={24}>
                                <Form.Item label={"Coordinates"}>
                                    <Radio.Group
                                        value={currentCoordinate}
                                        onChange={(e) =>
                                            setCurrCoord(e.target.value)
                                        }
                                    >
                                        <Radio.Button value="start">
                                            Start -{" "}
                                            {currentCell?.other?.variable?.coordinates[0]?.join(
                                                ",",
                                            ) || ""}
                                        </Radio.Button>
                                        <Radio.Button value="end">
                                            End -{" "}
                                            {currentCell?.other?.variable?.coordinates[1]?.join(
                                                ",",
                                            ) || ""}
                                        </Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                {grid?.map((row: any[], i: number) => (
                                    <div style={{ display: "flex" }}>
                                        {row?.map((gridCell, j) => (
                                            <div
                                                onClick={() => {
                                                    if (
                                                        gridCell?.type ===
                                                        "fillable"
                                                    ) {
                                                        let tmpCell =
                                                            cloneDeep(
                                                                currentCell,
                                                            );

                                                        let tempCoord =
                                                            currentCell?.other
                                                                ?.variable
                                                                ?.coordinates || [
                                                                [],
                                                                [],
                                                            ];

                                                        tempCoord[
                                                            currentCoordinate ===
                                                                "start"
                                                                ? 0
                                                                : 1
                                                        ] = [i, j];

                                                        tmpCell = _.set(
                                                            tmpCell,
                                                            [
                                                                "other",
                                                                "variable",
                                                                "coordinates",
                                                            ],
                                                            tempCoord,
                                                        );

                                                        setCurrentCell(tmpCell);
                                                        setCurrCoord(
                                                            currentCoordinate ===
                                                                "start"
                                                                ? "end"
                                                                : "start",
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    border:
                                                        gridCell?.type ===
                                                            "fillable"
                                                            ? "1px dashed #000000"
                                                            : "",
                                                    minHeight: "56px",
                                                    minWidth: "56px",
                                                    textAlign: "center",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    background:
                                                        i === curCellRow &&
                                                            j === curCellCol
                                                            ? "yellow"
                                                            : gridCell?.type ===
                                                                "fillable"
                                                                ? "white"
                                                                : "#f4f4f4",
                                                    cursor:
                                                        i === curCellRow &&
                                                            j === curCellCol
                                                            ? "not-allowed"
                                                            : gridCell?.type ===
                                                                "fillable"
                                                                ? "pointer"
                                                                : "not-allowed",
                                                }}
                                            >
                                                <div
                                                    style={{ display: "block" }}
                                                >
                                                    {checkCoordinate(
                                                        currentCell?.other
                                                            ?.variable
                                                            ?.coordinates,
                                                        0,
                                                        i,
                                                        j,
                                                    ) && (
                                                            <div
                                                                style={{
                                                                    color: "green",
                                                                }}
                                                            >
                                                                Start
                                                            </div>
                                                        )}
                                                    <div>
                                                        {i}, {j}
                                                    </div>
                                                    {checkCoordinate(
                                                        currentCell?.other
                                                            ?.variable
                                                            ?.coordinates,
                                                        1,
                                                        i,
                                                        j,
                                                    ) && (
                                                            <div
                                                                style={{
                                                                    color: "green",
                                                                }}
                                                            >
                                                                End
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </Col>
                        )}

                        <Col style={{ marginTop: "30px" }} span={24}>
                            <Space style={{ float: "right" }}>
                                <Button type="default" onClick={closeForm}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                    disabled={isDisabled}
                                >
                                    Submit
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default CellInput;
