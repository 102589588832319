import React from "react";
import { compose } from "src/helpers";
import {
    withHookForAggregateContent,
    withHookForContents,
    withCreateContentBatch,
    withIncrementSetDuration,
    withIncrementCollectionDuration,
    withDeleteContentsByCollectionId,
} from "src/modules/content/operations";
import { withStateAndActionsForContent } from "src/modules/content/resolvers";
import ListView from "./component";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    withStateAndActionsForContent,
    withHookForContents,
    withHookForAggregateContent,
    withCreateContentBatch,
    withIncrementSetDuration,
    withIncrementCollectionDuration,
    withDeleteContentsByCollectionId,
)(List);
