import React from "react";
import { Card, Col, Input, Row } from "antd";
import get from "lodash/get";

import { resetFilterHook } from "src/helpers";

// import { UsersField } from "..";
import { RenderField, Field } from "src/components";

const FilterComponent = (props) => {
    const { where = {}, onStateReset, onNameChange, onIdChange } = props;

    resetFilterHook(onStateReset);

    return (
        <Card bodyStyle={{ padding: "4px" }}>
            <Row gutter={[24, 0]} align="middle">
                <Col lg={12} md={12}>
                    <Field
                        label="Set title search"
                        placeholder="Set title"
                        icon="FontSizeOutlined"
                        component={RenderField}
                        onChange={(e) => onNameChange(e)}
                        value={get(where, "title._ilike")?.replace(/%/g, "")}
                    />
                </Col>
                <Col lg={12} md={12}>
                    <Field
                        label="Set id search"
                        placeholder="Set id"
                        icon="FontSizeOutlined"
                        component={RenderField}
                        onChange={(e) => onIdChange(e)}
                        value={get(where, "id._eq")}
                        type={"number"}
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default FilterComponent;
