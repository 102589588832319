import { BLOCK_TYPES } from "../../common/index";
import { GRID_INPUT_OPTIONS, PUZZLE_OTHER } from "../Crossword/defaultValue";
const { DRAG_AND_SWIPE_BLOCK } = BLOCK_TYPES;

export const DEFAULT_DRAG_AND_SWIPE_CELL = {
    type: "fillable",

    // optional and can be null
    content: {
        type: "string", // string / asset
        value: "0",
        asset: "",
    },

    // optional and can be null
    correct_answer: [],
};

const DRAG_AND_SWIPE_DEFAULT_VALUE = {
    type: DRAG_AND_SWIPE_BLOCK,
    data: {
        [DRAG_AND_SWIPE_BLOCK]: {
            options: GRID_INPUT_OPTIONS(),
            grid: [[...Array(3)].map(() => DEFAULT_DRAG_AND_SWIPE_CELL)],
            // custom_keyboard: [],
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
        other: { ...PUZZLE_OTHER },
    },
};

export default DRAG_AND_SWIPE_DEFAULT_VALUE;
