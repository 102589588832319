import { gql } from "@apollo/client";
export default gql`
    mutation UpdateWorksheetUserStats(
        $object: worksheet_worksheet_stats_insert_input!
    ) {
        insert_worksheet_worksheet_stats_one(
            object: $object
            on_conflict: {
                constraint: worksheet_stats_worksheet_id_key
                update_columns: [other]
            }
        ) {
            id
            other
        }
    }
`;
