import { DEFAULT_CUSTOM_KEYBOARD } from "src/components/CustomKeyboardEditor";
import { BLOCK_TYPES, prefillText } from "../../common/index";
const { V2_TABLE_BLOCK } = BLOCK_TYPES;

export const DEFAULT_V2_CELL = {
    type: "text", // input / component / text
    correct_answer: [],
    text: prefillText(""),
    custom_keyboard: DEFAULT_CUSTOM_KEYBOARD,
    component: {},
    is_correct_input: false,
};

const V2_TABLE_DEFAULT_VALUE = {
    type: V2_TABLE_BLOCK,
    data: {
        [V2_TABLE_BLOCK]: {
            row_heading: [...Array(2)].map((_, i) => ({
                type: "text", // text / component
                text: prefillText(`Heading ${i + 1}`),
                component: {},
            })),
            column_heading: [
                {
                    type: "text", // text / component
                    text: prefillText(`Row 1`),
                    component: {},
                },
            ],
            data: [[...Array(2)].map(() => DEFAULT_V2_CELL)],
        },
        other: {
            description: [],
            audio: "",
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
};

export const getV2DefaultValue = ({
    row_heading,
    column_heading,
    data,
}: any) => ({
    type: V2_TABLE_BLOCK,
    data: {
        [V2_TABLE_BLOCK]: {
            row_heading:
                row_heading ||
                [...Array(2)].map((_, i) => ({
                    type: "text", // text / component
                    text: prefillText(`Heading ${i + 1}`),
                    component: {},
                })),
            column_heading: column_heading || [
                {
                    type: "text", // text / component
                    text: prefillText(`Row 1`),
                    component: {},
                },
            ],
            data: data || [[...Array(2)].map(() => DEFAULT_V2_CELL)],
        },
        other: {
            description: [],
            audio: "",
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
});

export default V2_TABLE_DEFAULT_VALUE;
