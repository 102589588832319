import { gql } from "@apollo/client";export default gql`mutation updateSet($id: Int!, $metadata_tags: json = null) {
    updateSet: update_set_by_pk(
        pk_columns: { id: $id }
        _set: { metadata_tags: $metadata_tags }
    ) {
        id
        title
        description
        cover
        chapter
        topic
        attempts
        duration
        status
        type
        class
    }
}
`;
