import {
    Modal,
    Form,
    message,
    Input,
    Button,
    Col,
    Space,
    Row,
    Divider,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { AudioGenerateComponent } from "src/modules/worksheet/components/WorksheetEditor/components/Modals/AudioGenerateComponent";
import { CopyIcon } from "src/components";
import {
    SortableHandle,
    SortableContainer,
    SortableElement,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { captureException } from "@sentry/react";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: "pointer",
        }}
    />
));

const SortableContainerCustom = SortableContainer(({ children }: any) => {
    return <div>{children}</div>;
});

const SortableItemCustom = SortableElement((props: any) => {
    const { children, sid } = props;
    return (
        <div style={{ zIndex: 1000 }} key={`${sid}`}>
            {children}
        </div>
    );
});

const SCHEMA = {
    name: "",
    audio: "",
    start_sec: 0,
};

export const StoryAudioListModal = ({
    audioList,
    disableWrite,
    onSave,
}: any) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [curConfig, setCurConfig] = useState(
        (
            (typeof audioList === "string"
                ? JSON.parse(audioList)
                : audioList) || []
        ).map((v: any) => ({ ...v, uniqueId: _.uniqueId() })),
    );
    const [loading, setLoading] = useState(false);
    const [tempConfig, setTempConfig] = useState("");

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            let v = curConfig.map((v: { uniqueId: any }) => {
                delete v.uniqueId;
                return v;
            });
            await onSave(v);
            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e);
            console.log(e);
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (loading) {
            setTimeout(() => setLoading(false), 50);
        }
    }, [loading]);

    useEffect(() => {
        if (isModalOpen) {
            setCurConfig(
                (
                    (typeof audioList === "string"
                        ? JSON.parse(audioList)
                        : audioList) || []
                ).map((v: any) => ({ ...v, uniqueId: _.uniqueId() })),
            );
        }
    }, [isModalOpen]);

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(curConfig);
        tmpBlock = arrayMoveImmutable(tmpBlock, oldIndex, newIndex).filter(
            (el) => !!el,
        );
        setCurConfig(tmpBlock);
    };

    return (
        <>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
                Add
            </Button>
            <Modal
                title={`Story Audio List`}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                width={720}
                maskClosable={false}
                style={{ top: 10 }}
                destroyOnClose
            >
                <Row
                    style={{
                        maxHeight: "70vh",
                        overflowY: "auto",
                        overflowX: "hidden",
                        scrollbarWidth: "thin",
                    }}
                >
                    <Col span={24}>
                        {!disableWrite && (
                            <Button
                                ghost
                                type="primary"
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(curConfig);
                                    tmpBlock.push({
                                        ..._.cloneDeep(SCHEMA),
                                        uniqueId: _.uniqueId(),
                                    });
                                    setCurConfig(tmpBlock);
                                }}
                            >
                                Add New Audio
                            </Button>
                        )}
                        <Divider />
                    </Col>
                    <Col span={24}>
                        {!loading && (
                            <SortableContainerCustom
                                onSortEnd={onSortEnd}
                                useDragHandle
                            >
                                {curConfig?.map(
                                    (audio: any, audioIdx: number) => {
                                        return (
                                            <SortableItemCustom
                                                disabled={disableWrite}
                                                index={audioIdx}
                                                sid={audio?.uniqueId}
                                                key={audio?.uniqueId}
                                            >
                                                <div
                                                    key={audio?.uniqueId}
                                                    style={{
                                                        border: "1px dotted black",
                                                        padding: "8px",
                                                        margin: "8px 0",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                            marginBottom:
                                                                "10px",
                                                        }}
                                                    >
                                                        <Space>
                                                            <DragHandle />
                                                            <h3>
                                                                {audio?.name}
                                                            </h3>
                                                        </Space>
                                                        <Space>
                                                            <Button
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                icon={
                                                                    <DeleteOutlined />
                                                                }
                                                                type="primary"
                                                                shape="circle"
                                                                danger
                                                                size="small"
                                                                onClick={() => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            curConfig,
                                                                        );
                                                                    _.pullAt(
                                                                        tmpBlock,
                                                                        audioIdx,
                                                                    );
                                                                    setCurConfig(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                            <Button
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                icon={
                                                                    <CopyIcon />
                                                                }
                                                                type="primary"
                                                                shape="circle"
                                                                size="small"
                                                                onClick={() => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            curConfig,
                                                                        );
                                                                    tmpBlock.splice(
                                                                        audioIdx,
                                                                        0,
                                                                        {
                                                                            ..._.cloneDeep(
                                                                                audio,
                                                                            ),
                                                                            uniqueId:
                                                                                _.uniqueId(),
                                                                        },
                                                                    );
                                                                    setCurConfig(
                                                                        tmpBlock,
                                                                    );
                                                                    message.info(
                                                                        `Duplicated!`,
                                                                    );
                                                                }}
                                                            />
                                                            <Button
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                type="primary"
                                                                size="small"
                                                                onClick={() => {
                                                                    message.info(
                                                                        `Copied to clipboard!`,
                                                                    );
                                                                    navigator.clipboard.writeText(
                                                                        JSON.stringify(
                                                                            [
                                                                                _.omit(
                                                                                    audio,
                                                                                    [
                                                                                        "uniqueId",
                                                                                    ],
                                                                                ),
                                                                            ],
                                                                        ),
                                                                    );
                                                                }}
                                                            >
                                                                Copy to
                                                                clipboard
                                                            </Button>
                                                        </Space>
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: 10,
                                                        }}
                                                    >
                                                        <Form.Item
                                                            label={"Name"}
                                                        >
                                                            <Input
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                value={
                                                                    audio?.name
                                                                }
                                                                placeholder="Type here..."
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    let value =
                                                                        e.target
                                                                            .value;
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            curConfig,
                                                                        );
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            audioIdx,
                                                                            "name",
                                                                        ],
                                                                        value,
                                                                    );
                                                                    setCurConfig(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={
                                                                "Start Second"
                                                            }
                                                        >
                                                            <Input
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                type="number"
                                                                value={
                                                                    audio?.start_sec
                                                                }
                                                                placeholder="Type here..."
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    let value =
                                                                        e.target
                                                                            .value;
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            curConfig,
                                                                        );
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            audioIdx,
                                                                            "start_sec",
                                                                        ],
                                                                        value,
                                                                    );
                                                                    setCurConfig(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <AudioGenerateComponent
                                                        name={""}
                                                        disableWrite={false}
                                                        audioData={audio?.audio}
                                                        onSave={(
                                                            value: any,
                                                        ) => {
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    curConfig,
                                                                );
                                                            _.set(
                                                                tmpBlock,
                                                                [
                                                                    audioIdx,
                                                                    "audio",
                                                                ],
                                                                value,
                                                            );
                                                            setCurConfig(
                                                                tmpBlock,
                                                            );
                                                        }}
                                                        isAudioPool={true}
                                                        index={audioIdx}
                                                    />
                                                    {/* </Form.Item> */}
                                                </div>
                                            </SortableItemCustom>
                                        );
                                    },
                                )}
                            </SortableContainerCustom>
                        )}
                    </Col>
                    {curConfig?.length > 0 && !disableWrite && (
                        <Col span={24}>
                            <Button
                                ghost
                                type="primary"
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(curConfig);
                                    tmpBlock.push({
                                        ..._.cloneDeep(SCHEMA),
                                        uniqueId: _.uniqueId(),
                                    });
                                    setCurConfig(tmpBlock);
                                }}
                            >
                                Add New Audio
                            </Button>
                            <Divider />
                        </Col>
                    )}
                    <Col span={24}>
                        <Form.Item label={"Paste Audio Config here"}>
                            <Input.TextArea
                                disabled={disableWrite}
                                value={tempConfig}
                                placeholder="Paste Config here"
                                onChange={(e) => setTempConfig(e.target.value)}
                            />
                            <Space>
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    onClick={() => {
                                        try {
                                            const parsedJson =
                                                JSON.parse(tempConfig);
                                            if (!Array.isArray(parsedJson)) {
                                                message.error("Error in JSON!");
                                                return;
                                            }
                                            setLoading(true);
                                            setCurConfig(
                                                parsedJson.map((v) => ({
                                                    ...v,
                                                    uniqueId: _.uniqueId(),
                                                })),
                                            );
                                            setTempConfig("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            // captureException(e)
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Replace
                                </Button>
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    onClick={() => {
                                        try {
                                            const parsedJson =
                                                JSON.parse(tempConfig);
                                            if (!Array.isArray(parsedJson)) {
                                                message.error("Error in JSON!");
                                                return;
                                            }
                                            setCurConfig([
                                                ...curConfig,
                                                ...parsedJson.map((v) => ({
                                                    ...v,
                                                    uniqueId: _.uniqueId(),
                                                })),
                                            ]);
                                            setTempConfig("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            // captureException(e);
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Append
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <div
                    style={{
                        paddingTop: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    {curConfig?.length > 0 ? (
                        <>
                            <Button
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied components config to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(
                                            curConfig.map(
                                                (v: { uniqueId: any }) => {
                                                    delete v.uniqueId;
                                                    return v;
                                                },
                                            ),
                                        ),
                                    );
                                }}
                            >
                                Copy this Audio Config to Clipboard
                            </Button>
                        </>
                    ) : (
                        <div />
                    )}
                    <Space>
                        <Button
                            type="default"
                            onClick={() => setIsModalOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            loading={isSubmitting}
                            disabled={disableWrite}
                        >
                            Submit
                        </Button>
                    </Space>
                </div>
            </Modal>
        </>
    );
};
