import {
    Button,
    Card,
    Col,
    Row,
    Popover,
    Modal,
    Typography,
    Form,
    Switch,
} from "antd";
import React, { useState } from "react";
import { Can } from "src/services/casl";
import PublishModal from "../../Navbar/PublishModal";
import { useAuth0 } from "@auth0/auth0-react";
import {
    CheckOutlined,
    CloseOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import TimeAgo from "react-timeago";

const PublishColumn = ({
    setIsEditor,
    router,
    props,
    lastSavedAt,
    currentDateTime,
    version,
    onSave,
    isSaving,
    blocks,
    hasChanged,
    worksheet_id,
    worksheet,
    gcpSpreadsheetURL,
    selectSheetsFlow,
    toggleSelectSheetsFlow,
    setGcpSpreadsheetURL,
    publishWorksheet,
    canPublishFinal,
    isReadOnlyMode,
    activeUserProps = {},
    worksheetStats,
    worksheetWorksheetStatsUS,
    currentSavedCount,
    editorProps,
}: any) => {
    const {
        user: {
            "https://hasura.io/jwt/claims/role": user_role,
            "https://hasura.io/jwt/claims/user_id": currentUserId,
        } = {},
    } = useAuth0();
    const [isOpen, setIsOpen] = useState(false);
    const [publish, setPublish] = useState(false);
    const [generateAudio, setGenerateAudio] = useState(false);
    const isRead =
        worksheet?.type === "personalized_learning_v4" &&
        activeUserProps?.activeUsers?.length &&
        activeUserProps?.activeUsers.every(
            (item: { user_id: any; is_editor: any }) =>
                item?.user_id !== currentUserId ||
                (item?.user_id === currentUserId && !item?.is_editor),
        );
    const currentUser = activeUserProps?.activeUsers?.find(
        (user) => user.is_editor,
    );
    const handleClose = async () => {
        setPublish(false);
        setIsOpen(false);
        setGenerateAudio(false);
    };
    return (
        <Row
            justify="center"
            align="middle"
            style={{
                padding: "10px 10px 0 10px",
                background: "#F7F7F7",
            }}
        >
            <Modal
                open={isOpen}
                title={
                    currentUser?.user_id != currentUserId
                        ? "Are you sure?"
                        : "Save Worksheet"
                }
                okText={publish ? "Publish" : "Save"}
                onOk={() => {
                    handleClose();
                    if (
                        (worksheet?.type === "personalized_learning_v3" &&
                            blocks?.some(
                                (v) =>
                                    !v?.id ||
                                    v?.children?.some((vc) => !vc?.id),
                            )) ||
                        publish
                    )
                        publishWorksheet({});
                    else {
                        onSave({
                            isSaveButton: true,
                            generate: generateAudio,
                        });
                    }
                }}
                onCancel={handleClose}
            >
                {currentUser?.user_id != currentUserId && (
                    <Typography.Text>
                        You currently don't have edit access to this worksheet.
                    </Typography.Text>
                )}
                {worksheet?.type === "personalized_learning_v4" && (
                    <Form.Item label={`Generate Audio?`}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={generateAudio}
                            onChange={setGenerateAudio}
                        />
                    </Form.Item>
                )}
            </Modal>
            <Col xs={24} lg={24} md={24}>
                <Card
                    style={{
                        borderRadius: "8px",
                        marginBottom: "8px",
                    }}
                    bordered={true}
                    bodyStyle={{ padding: "12px 12px" }}
                    onClick={() => {}}
                >
                    <Row align="middle" justify={"end"} gutter={4}>
                        <Col xs={5} lg={5} md={5}>
                            {worksheet?.type !== "personalized_learning_v4" ? (
                                lastSavedAt &&
                                currentDateTime && (
                                    <p style={{ color: "#808080" }}>
                                        (v{version}) Last saved (
                                        {Math.round(
                                            Math.abs(
                                                currentDateTime.getTime() -
                                                    lastSavedAt.getTime(),
                                            ) / 1000,
                                        )}{" "}
                                        seconds ago)
                                    </p>
                                )
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                    }}
                                >
                                    {worksheetStats?.other?.current_save && (
                                        <Row>
                                            Last saved: <br />
                                            <Popover
                                                placement="bottom"
                                                title={"Save & Publish Details"}
                                                content={
                                                    <>
                                                        <p>
                                                            Last saved:{" "}
                                                            <strong>
                                                                {new Date(
                                                                    worksheetStats?.other?.current_save?.time,
                                                                ).toLocaleString(
                                                                    "en-IN",
                                                                    {
                                                                        timeZone:
                                                                            "Asia/Kolkata",
                                                                        year: "numeric",
                                                                        month: "long",
                                                                        day: "numeric",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        second: "2-digit",
                                                                        hour12: true,
                                                                    },
                                                                )}
                                                            </strong>{" "}
                                                            by{" "}
                                                            {worksheetStats
                                                                ?.other
                                                                ?.current_save
                                                                ?.user?.name ||
                                                                worksheetStats
                                                                    ?.other
                                                                    ?.current_save
                                                                    ?.user
                                                                    ?.email}
                                                        </p>
                                                        {worksheetStats?.other
                                                            ?.current_publish && (
                                                            <p>
                                                                Last published:{" "}
                                                                <strong>
                                                                    {new Date(
                                                                        worksheetStats?.other?.current_publish?.time,
                                                                    ).toLocaleString(
                                                                        "en-IN",
                                                                        {
                                                                            timeZone:
                                                                                "Asia/Kolkata",
                                                                            year: "numeric",
                                                                            month: "long",
                                                                            day: "numeric",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                            second: "2-digit",
                                                                            hour12: true,
                                                                        },
                                                                    )}
                                                                </strong>{" "}
                                                                by{" "}
                                                                {worksheetStats
                                                                    ?.other
                                                                    ?.current_publish
                                                                    ?.user
                                                                    ?.name ||
                                                                    worksheetStats
                                                                        ?.other
                                                                        ?.current_publish
                                                                        ?.user
                                                                        ?.email}
                                                            </p>
                                                        )}
                                                        {worksheetWorksheetStatsUS
                                                            ?.other
                                                            ?.current_publish && (
                                                            <p>
                                                                Last published
                                                                (US):{" "}
                                                                <strong>
                                                                    {new Date(
                                                                        worksheetWorksheetStatsUS?.other?.current_publish?.time,
                                                                    ).toLocaleString(
                                                                        "en-IN",
                                                                        {
                                                                            timeZone:
                                                                                "Asia/Kolkata",
                                                                            year: "numeric",
                                                                            month: "long",
                                                                            day: "numeric",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                            second: "2-digit",
                                                                            hour12: true,
                                                                        },
                                                                    )}
                                                                </strong>{" "}
                                                                by{" "}
                                                                {worksheetWorksheetStatsUS
                                                                    ?.other
                                                                    ?.current_publish
                                                                    ?.user
                                                                    ?.name ||
                                                                    worksheetWorksheetStatsUS
                                                                        ?.other
                                                                        ?.current_publish
                                                                        ?.user
                                                                        ?.email}
                                                            </p>
                                                        )}
                                                    </>
                                                }
                                            >
                                                <Button
                                                    type="link"
                                                    icon={
                                                        <InfoCircleOutlined />
                                                    }
                                                />
                                            </Popover>
                                            <TimeAgo
                                                date={
                                                    worksheetStats?.other
                                                        ?.current_save?.time
                                                }
                                            />
                                        </Row>
                                    )}
                                    {worksheetStats?.other?.current_publish && (
                                        <Row>
                                            Last published: <br />
                                            <TimeAgo
                                                date={
                                                    worksheetStats?.other
                                                        ?.current_publish?.time
                                                }
                                            />
                                        </Row>
                                    )}
                                </div>
                            )}
                        </Col>
                        <Col xs={2} lg={2} md={2}>
                            <Can I="save" a={"worksheet"} passThrough>
                                {(allowed: boolean) => (
                                    <Button
                                        onClick={(e) => {
                                            if (
                                                currentUser?.user_id !=
                                                    currentUserId ||
                                                worksheet?.type ===
                                                    "personalized_learning_v4"
                                            ) {
                                                setIsOpen(true);
                                            } else {
                                                if (
                                                    worksheet?.type ===
                                                        "personalized_learning_v3" &&
                                                    blocks?.some(
                                                        (v) =>
                                                            !v?.id ||
                                                            v?.children?.some(
                                                                (vc) => !vc?.id,
                                                            ),
                                                    )
                                                )
                                                    publishWorksheet(e);
                                                else
                                                    onSave({
                                                        ...e,
                                                        isSaveButton: true,
                                                        // generate: true,
                                                    });
                                            }
                                        }}
                                        block
                                        loading={isSaving}
                                        disabled={
                                            !blocks?.length ||
                                            !hasChanged ||
                                            isReadOnlyMode ||
                                            !allowed
                                        }
                                    >
                                        {isSaving ? "Saving" : "Save"}
                                    </Button>
                                )}
                            </Can>
                        </Col>
                        <Col xs={6} lg={6} md={6}>
                            <Can I="publish" a={"worksheet"} passThrough>
                                {(allowed: boolean) =>
                                    worksheet?.type ===
                                    "personalized_learning_v4" ? (
                                        <PublishModal
                                            isRead={isRead}
                                            blocks={blocks}
                                            disableWrite={
                                                isSaving ||
                                                canPublishFinal ||
                                                (!allowed &&
                                                    !(
                                                        [
                                                            "personalized_learning_v3",
                                                            "personalized_learning_v4",
                                                        ].includes(
                                                            worksheet?.type,
                                                        ) &&
                                                        user_role === "creator"
                                                    )) ||
                                                isReadOnlyMode
                                            }
                                            publishWorksheet={publishWorksheet}
                                            worksheetStats={worksheetStats}
                                            worksheet={worksheet}
                                        />
                                    ) : (
                                        <Button
                                            type={"primary"}
                                            onClick={() => {
                                                const currentUser =
                                                    activeUserProps.activeUsers.find(
                                                        (user) =>
                                                            user.is_editor,
                                                    );
                                                if (
                                                    currentUser?.user_id !=
                                                    currentUserId
                                                ) {
                                                    setPublish(true);
                                                    setIsOpen(true);
                                                } else {
                                                    publishWorksheet();
                                                }
                                            }}
                                            block
                                            disabled={
                                                canPublishFinal ||
                                                (!allowed &&
                                                    !(
                                                        [
                                                            "personalized_learning_v3",
                                                            "personalized_learning_v4",
                                                        ].includes(
                                                            worksheet?.type,
                                                        ) &&
                                                        user_role === "creator"
                                                    )) ||
                                                isReadOnlyMode
                                            }
                                        >
                                            {worksheet?.status == "active"
                                                ? "Publish Changes"
                                                : "Publish"}
                                        </Button>
                                    )
                                }
                            </Can>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};

export default PublishColumn;
