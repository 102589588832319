import { MemeList, MemeCreate, MemeEdit } from "./views";

import { Route } from "react-router-dom";
export const MEME_ROUTES = [
    <Route path="/meme" element={<MemeList />} />,

    <Route path="/meme/create" element={<MemeCreate />} />,
    <Route path="/meme/update/:meme_id" element={<MemeEdit />} />,
];

export { MemeList, MemeCreate, MemeEdit } from "./views";
