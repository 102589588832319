import { BLOCK_TYPES } from "../../common";
import { renderEditorCrossword } from "../Crossword";

const { DRAG_EXPAND_NUMBER_BLOCK } = BLOCK_TYPES;

const renderEditor = (props: any) =>
    renderEditorCrossword({
        ...props,
        blockType: DRAG_EXPAND_NUMBER_BLOCK,
        // showFeedback: true,
    });

export default renderEditor;
