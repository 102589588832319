import { BLOCK_TYPES } from "../../common/index";
const { V2_RAPID_QA_BLOCK } = BLOCK_TYPES;

const isValidCallbackText = ({ block, currentSubBlock, currentBlock }: any) => {
    return {
        isValid: true,
        error: "",
    };

    if (!block || currentSubBlock > (block?.data?.children?.length || 0))
        return {
            isValid: true,
            error: "",
        };

    const {
        data: {
            [V2_RAPID_QA_BLOCK]: { title },
        },
        children,
    } = block;

    return true;
};

export default isValidCallbackText;
