import { Button, Form, Modal } from "antd";
import React, { useState } from "react";
import BlockJumpCard from "./BlockJumpCard";

const V2JumpLogicModal = (props: any) => {
    const { blocks, currentBlock, setBlock, disableWrite } = props;
    const [block, updateBlock] = useState(null);
    const [focusElement, setFocusElement] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        updateBlock(blocks[currentBlock]);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        if (!disableWrite) {
            setBlock(block);
        }
        setIsModalOpen(false);
        updateBlock(null);
        setFocusElement(null);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        updateBlock(null);
        setFocusElement(null);
    };

    return (
        <div style={{ marginTop: "40px" }}>
            <Form.Item
                label="Adjust Jump Logic"
                help={
                    blocks[currentBlock]?.condition_jump?.conditions?.length >
                        0 || blocks[currentBlock]?.condition_jump?.default
                        ? "Jump Logic Added"
                        : ""
                }
            >
                <Button
                    type="primary"
                    style={{ width: "100px" }}
                    onClick={showModal}
                >
                    Modify
                </Button>
            </Form.Item>
            <Modal
                title="Add jump logic and conditions"
                open={isModalOpen}
                onOk={handleOk}
                okText="Submit"
                onCancel={handleCancel}
                destroyOnClose
                width={720}
                {...(disableWrite
                    ? {
                          footer: null,
                      }
                    : {})}
            >
                <div
                    style={{
                        marginTop: "20px",
                    }}
                />
                <BlockJumpCard
                    {...props}
                    block={block}
                    idx={currentBlock}
                    setBlock={updateBlock}
                    setIsModalOpen={setIsModalOpen}
                    focusElement={focusElement}
                    setFocusElement={setFocusElement}
                />
                <div
                    style={{
                        marginTop: "20px",
                    }}
                />
            </Modal>
        </div>
    );
};

export default V2JumpLogicModal;
