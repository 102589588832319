import { setNodes } from "@udecode/plate-core";
import { getRootProps } from "@udecode/plate-styled-components";
import React, {
    ChangeEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Node, Transforms } from "slate";
import { ReactEditor, useFocused, useSelected } from "slate-react";
import { ImageUploadComponent } from "../ImageUploadComponent";
import { getImageElementStyles } from "./ImageElement.styles";
import { ImageElementProps } from "./ImageElement.types";
import { Input } from "antd";

export const ImageElement = (props: ImageElementProps) => {
    const {
        attributes,
        children,
        element,
        nodeProps,
        caption = {},
        resizableProps = {
            minWidth: 92,
        },
        align = "center",
        draggable,
        editor,
    } = props;

    const rootProps = getRootProps(props);

    const { placeholder = "Write a caption..." } = caption;

    const {
        url,
        width: nodeWidth = "100%",
        caption: nodeCaption = [{ children: [{ text: "" }] }],
    } = element;
    if (!url) caption.disabled = true;

    const focused = useFocused();
    const selected = useSelected();
    const [width, setWidth] = useState(nodeWidth);

    // const [captionId] = useState(nanoid());

    useEffect(() => {
        setWidth(nodeWidth);
    }, [nodeWidth]);

    const styles = getImageElementStyles({
        ...props,
        align,
        focused,
        selected,
    });

    const setNodeWidth = useCallback(
        (w: number) => {
            const path = ReactEditor.findPath(editor, element);

            if (w === nodeWidth) {
                // Focus the node if not resized
                Transforms.select(editor, path);
            } else {
                setNodes(editor, { width: w }, { at: path });
            }
        },
        [editor, element, nodeWidth],
    );

    const onChangeCaption: ChangeEventHandler<HTMLTextAreaElement> =
        useCallback(
            (e) => {
                const path = ReactEditor.findPath(
                    editor as ReactEditor,
                    element,
                );
                setNodes(
                    editor,
                    { caption: [{ text: e.target.value }] },
                    { at: path },
                );
            },
            [editor, element],
        );

    const captionString = useMemo(() => {
        return Node.string(nodeCaption[0]) || "";
    }, [nodeCaption]);

    const onChangeImage = ({ target: { value: url } }: any) => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        setNodes(
            editor,
            {
                url,
            },
            { at: path },
        );
    };

    const onDeleteImage = () => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        // console.log(path);
        Transforms.delete(editor, { at: path });
    };

    return (
        <div
            {...attributes}
            /* @ts-ignore */
            css={styles.root.css}
            className={styles.root.className}
            {...rootProps}
            {...nodeProps}
        >
            <div contentEditable={false}>
                <figure
                    /* @ts-ignore */
                    css={styles.figure?.css}
                    className={`group ${styles.figure?.className}`}
                >
                    {/* <Resizable
                        // @ts-ignore
                        css={styles.resizable?.css}
                        className={styles.resizable?.className}
                        size={{ width, height: "90%" }}
                        maxWidth="90%"
                        lockAspectRatio
                        resizeRatio={align === "center" ? 2 : 1}
                        enable={{
                            left: ["center", "left"].includes(align),
                            right: ["center", "right"].includes(align),
                        }}
                        handleComponent={{
                            left: (
                                <ImageHandle
                                    css={[styles.handleLeft?.css]}
                                    className={styles.handleLeft?.className}
                                />
                            ),
                            right: (
                                <ImageHandle
                                    css={styles.handleRight?.css}
                                    className={styles.handleRight?.className}
                                />
                            ),
                        }}
                        handleStyles={{
                            left: { left: 0 },
                            right: { right: 0 },
                        }}
                        onResize={(e, direction, ref) => {
                            setWidth(ref.offsetWidth);
                        }}
                        onResizeStop={(e, direction, ref) =>
                            setNodeWidth(ref.offsetWidth)
                        }
                        {...resizableProps}
                    > */}
                    {url ? (
                        <div style={{ /* width: "40%", */ marginLeft: "5px" }}>
                            <img
                                data-testid="ImageElementImage"
                                /* @ts-ignore */
                                css={styles.img?.css}
                                style={{ width: "-webkit-fill-available" }}
                                className={styles.img?.className}
                                src={url}
                                alt={captionString}
                                draggable={draggable}
                                {...nodeProps}
                            />
                            <Input
                                style={{ width: "100%", maxWidth: "400px" }}
                                placeholder="Paste image here"
                                value={url}
                                onChange={onChangeImage}
                            />
                        </div>
                    ) : (
                        <div contentEditable={false}>
                            <ImageUploadComponent
                                onChange={onChangeImage}
                                onDelete={onDeleteImage}
                            />
                            <Input
                                style={{ width: "100%", maxWidth: "400px" }}
                                placeholder="Paste image here"
                                value={url}
                                onChange={onChangeImage}
                            />
                        </div>
                    )}
                </figure>
            </div>
            {children}
        </div>
    );
};
