import { BLOCK_TYPES } from "../../common/index";
const { TEXT_BLOCK } = BLOCK_TYPES;

const TEXT_DEFAULT_VALUE = {
    type: TEXT_BLOCK,
    data: {
        [TEXT_BLOCK]: "",
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
};

export default TEXT_DEFAULT_VALUE;
