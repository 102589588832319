import { gql } from "@apollo/client";export default gql`mutation createTab($object: home_explore_tab_insert_input = {}) {
    insert_home_explore_tab_one(
        object: $object
        on_conflict: { constraint: tab_pkey, update_columns: id }
    ) {
        id
        banners
        created_at
        deeplink_template
        description
        group
        header_link
        last_child
        title
        type
        updated_at
        status
        order
        build_number
    }
}
`;
