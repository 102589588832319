import { graphQLClient } from "src/modules/tab/components/PublishTabToProd";
import { gql } from "graphql-request";

export const getCollectionId = async (set_id: number) => {
    const GET_COLLECT_ID = gql`
        query collections($id: Int!) {
            collections: collection(
                where: {
                    sets: { set_id: { _eq: $id } }
                    status: { _eq: "active" }
                }
            ) {
                id
            }
        }
    `;

    const { collections } = await graphQLClient.request(GET_COLLECT_ID, {
        id: set_id,
    });

    const collectId = collections[0]?.id;

    return collectId;
};

const getRows = (summaries: any, isQuiz = false) => {
    const rows: any = [];

    summaries.map(({ sheets, count_total, student, other }: any) => {
        Object.keys(sheets).forEach(function (play_count) {
            if (Object.keys(sheets[play_count]).length == count_total) {
                const answers = Object.entries(sheets[play_count])
                    .map(([key, value]) => {
                        return { key, value };
                    })
                    .sort((a: any, b: any) => a.key - b.key)
                    .map((item: any) => item.value);

                const row = isQuiz
                    ? [...answers, other?.submitted_at]
                    : [student.name, play_count, ...answers];
                rows.push(row);
            }
        });
    });
    return rows;
};

export const getSummaries = async (set_id: number, isQuiz = false) => {
    const GET_QUESTIONS = gql`
        query SetQuery($id: Int!) {
            set_by_pk(id: $id) {
                collections(
                    where: { collection: { status: { _eq: "active" } } }
                ) {
                    collection {
                        homework_summaries {
                            count_total
                            sheets
                            student {
                                name
                            }
                        }
                        content(
                            where: { type: { _eq: "question" } }
                            order_by: { index: asc }
                        ) {
                            index
                            question {
                                text
                            }
                        }
                    }
                }
            }
        }
    `;
    const {
        set_by_pk: { collections },
    } = await graphQLClient.request(GET_QUESTIONS, {
        id: set_id,
    });
    const summaries = collections[0]?.collection.homework_summaries;
    let header = collections[0]?.collection.content.map(
        ({ question: { text } }: any) => text,
    );
    const rows = getRows(summaries, isQuiz);

    if (isQuiz) header = [...header, "Submitted At"];
    else header = ["Student Name", "Attempt Number", ...header];

    return [header, ...rows];
};
