import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";

const renderCardMixed = (props: any) => {
    const {
        block: {
            data: {
                v2_mixed: { text },
            },
        },
    } = props;
    const tmp = text.find(({ type }: any) => type === "p")?.children[0].text;
    const displayText = tmp
        ? tmp.slice(0, tmp.length > 10 ? 10 : tmp.length)
        : "...";
    return (
        <BlockCard {...props}>
            <div>{displayText}</div>
            <div style={{ fontSize: "11px", fontWeight: "bold" }}>
                Mixed Block
            </div>
        </BlockCard>
    );
};

export default renderCardMixed;
