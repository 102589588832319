import React from "react";
import { compose } from "src/helpers";
import {
    withHookForAggregateQuestion,
    withHookForQuestions,
} from "src/modules/question/operations";
import { withStateAndActionsForQuestion } from "src/modules/question/resolvers";
import ListView from "./component";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    withStateAndActionsForQuestion,
    withHookForQuestions,
    withHookForAggregateQuestion,
)(List);
