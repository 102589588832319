import { MenuOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Divider,
    Form,
    Input,
    InputNumber,
    Select,
    Table,
    message,
} from "antd";
import { cloneDeep, pullAt, set } from "lodash";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { BlockPicker } from "react-color";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
    CopyIcon,
    DeleteIcon,
    RenderUpload,
    SlateEditor,
} from "src/components";
import { replaceCDNUrl } from "src/modules/worksheet/components/blocks/helpers";
import { v4 as uuid } from "uuid";

const splitAndUpperCase = (str: string) =>
    str
        ?.split("_")
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(" ");

type Props = {
    items: any;
    setItems: any;
};

export default function Items({ items, setItems }: Props) {
    useEffect(() => {
        if (!items) {
            setItems([]);
        }
    }, [items]);

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            let tmpBlock = cloneDeep(items);
            const dragRow = tmpBlock[dragIndex];
            tmpBlock.splice(dragIndex, 1);
            tmpBlock.splice(hoverIndex, 0, dragRow);
            setItems(tmpBlock);
        },
        [items],
    );

    const categories = ["circle", "rectangle", "triangle", "image"];
    const [tempConfig, setTempConfig] = useState("");

    return (
        <div
            style={{
                padding: "20px",
            }}
        >
            <Card
                title="Add Item types"
                bordered={true}
                style={{
                    // width: 400,
                    width: "100%",
                    margin: "0 auto",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    padding: "20px",
                }}
                headStyle={{ textAlign: "center", fontSize: "18px" }}
            >
                <DndProvider backend={HTML5Backend}>
                    <Table
                        scroll={{ y: "60vh", x: 1000 }}
                        size="small"
                        pagination={false}
                        dataSource={(items ?? []).map((item, index) => ({
                            ...item,
                            key: index,
                        }))}
                        columns={[
                            {
                                title: "Index",
                                dataIndex: "key",
                                width: 60,
                                render: (v) => (
                                    <>
                                        {v + 1} <MenuOutlined />
                                    </>
                                ),
                            },
                            {
                                title: "Type",
                                dataIndex: "type",
                                width: 100,
                                render: (v, record, index) => (
                                    <Select
                                        value={v}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(items);
                                            setItems(
                                                set(
                                                    tmpBlock,
                                                    `${index}.type`,
                                                    value,
                                                ),
                                            );
                                        }}
                                    >
                                        {categories.map((category) => (
                                            <Select.Option value={category}>
                                                {splitAndUpperCase(category)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ),
                            },
                            {
                                title: "Value",
                                dataIndex: "value",
                                width: 200,
                                render: (v, record, index) => (
                                    <Input
                                        value={v}
                                        onChange={(e) => {
                                            let tmpBlock = cloneDeep(items);
                                            setItems(
                                                set(
                                                    tmpBlock,
                                                    `${index}.value`,
                                                    e.target.value,
                                                ),
                                            );
                                        }}
                                    />
                                ),
                            },
                            {
                                title: "Text",
                                dataIndex: "text",
                                width: 200,
                                render: (v, record, index) => (
                                    <Input
                                        disabled={record?.type === "image"}
                                        value={record?.text || ""}
                                        onChange={(e) => {
                                            let tmpBlock = cloneDeep(items);
                                            setItems(
                                                set(
                                                    tmpBlock,
                                                    `${index}.text`,
                                                    e.target.value,
                                                ),
                                            );
                                        }}
                                    />
                                ),
                            },
                            {
                                title: "Color",
                                dataIndex: "color",
                                width: 300,
                                render: (v, record, index) =>
                                    record?.type === "image" ? (
                                        <div>Image can't have color</div>
                                    ) : (
                                        <BlockPicker
                                            color={v}
                                            onChange={(color) => {
                                                if (record?.type === "image")
                                                    return;
                                                let tmpBlock = cloneDeep(items);
                                                setItems(
                                                    set(
                                                        tmpBlock,
                                                        `${index}.color`,
                                                        color.hex,
                                                    ),
                                                );
                                            }}
                                        />
                                    ),
                            },
                            {
                                title: "Image",
                                dataIndex: "src",
                                width: 300,
                                render: (v, record, index) => (
                                    <>
                                        <RenderUpload
                                            singleUpload={true}
                                            disabled={record?.type !== "image"}
                                            addExtension={true}
                                            path="home-explore/document/"
                                            onChangeCustom={(e) => {
                                                const value = e?.target?.value
                                                    ? replaceCDNUrl(
                                                          e?.target?.value,
                                                          e?.target?.bucket,
                                                      )
                                                    : "";
                                                setItems(
                                                    set(
                                                        cloneDeep(items),
                                                        `${index}.src`,
                                                        value,
                                                    ),
                                                );
                                            }}
                                            label="upload the file"
                                            value={v}
                                        />
                                        <Input
                                            placeholder="Add value here"
                                            value={v}
                                            disabled={record?.type !== "image"}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                let tmpBlock = cloneDeep(items);
                                                setItems(
                                                    set(
                                                        tmpBlock,
                                                        `${index}.src`,
                                                        value,
                                                    ),
                                                );
                                            }}
                                        />
                                    </>
                                ),
                            },
                            {
                                title: "Shape",
                                dataIndex: "shape",
                                width: 300,
                                render: (v, record, index) => (
                                    <>
                                        <Form.Item label="Width">
                                            <InputNumber
                                                disabled={
                                                    record?.type == "circle"
                                                }
                                                value={v?.width}
                                                onChange={(value) => {
                                                    let tmpBlock =
                                                        cloneDeep(items);
                                                    setItems(
                                                        set(
                                                            tmpBlock,
                                                            `${index}.shape.width`,
                                                            value,
                                                        ),
                                                    );
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item label="Height">
                                            <InputNumber
                                                disabled={
                                                    record?.type == "circle"
                                                }
                                                value={v?.height}
                                                onChange={(value) => {
                                                    let tmpBlock =
                                                        cloneDeep(items);
                                                    setItems(
                                                        set(
                                                            tmpBlock,
                                                            `${index}.shape.height`,
                                                            value,
                                                        ),
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                    </>
                                ),
                            },
                            {
                                title: "Radius",
                                dataIndex: "radius",
                                width: 100,
                                render: (v, record, index) => (
                                    <InputNumber
                                        value={v}
                                        disabled={record?.type !== "circle"}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(items);
                                            setItems(
                                                set(
                                                    tmpBlock,
                                                    `${index}.radius`,
                                                    value,
                                                ),
                                            );
                                        }}
                                    />
                                ),
                            },
                            {
                                title: "Action",
                                dataIndex: "operation",
                                width: 100,
                                render: (v, record, index) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                        }}
                                    >
                                        <CopyIcon
                                            isButton={true}
                                            onClick={() => {
                                                let tmpItems = cloneDeep(items);
                                                tmpItems.splice(index + 1, 0, {
                                                    ...cloneDeep(
                                                        tmpItems[index],
                                                    ),
                                                    genericId: uuid(),
                                                });
                                                setItems(tmpItems);
                                                message.info(`Tag duplicated!`);
                                            }}
                                        />
                                        <DeleteIcon
                                            title="Delete this?"
                                            onConfirm={() => {
                                                let tmpBlock = cloneDeep(items);
                                                pullAt(tmpBlock, index);
                                                setItems(tmpBlock);
                                                message.success(
                                                    "successfully removed",
                                                );
                                            }}
                                        />
                                    </div>
                                ),
                            },
                        ]}
                        rowKey="index"
                        bordered
                        rowClassName="item-rows"
                        footer={() => (
                            <>
                                <Button
                                    ghost
                                    type="primary"
                                    onClick={() => {
                                        setItems([
                                            ...items,
                                            {
                                                type: "circle",
                                                value: "1",
                                                color: "#000000",
                                                shape: {
                                                    width: 50,
                                                    height: 50,
                                                },
                                                radius: 25,
                                                text: "",
                                            },
                                        ]);
                                    }}
                                >
                                    Add Item
                                </Button>
                                {!items?.length && (
                                    <span
                                        style={{
                                            color: "red",
                                            marginLeft: "10px",
                                            fontSize: "16px",
                                        }}
                                    >
                                        No Tags Added
                                    </span>
                                )}
                            </>
                        )}
                        components={{
                            body: {
                                row: DraggableRow,
                            },
                        }}
                        onRow={(_, index) => ({
                            index,
                            moveRow,
                        })}
                    />
                </DndProvider>
                <>
                    <Divider />
                    {items?.length > 0 && (
                        <>
                            <Button
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied item types to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(items),
                                    );
                                }}
                            >
                                Copy item types
                            </Button>
                            <Divider />
                        </>
                    )}
                    <Form.Item label={"Paste item types here"}>
                        <Input.TextArea
                            value={tempConfig}
                            placeholder="Paste Config here"
                            onChange={(e) => setTempConfig(e.target.value)}
                        />
                        <Button
                            style={{ marginTop: "10px" }}
                            type="primary"
                            onClick={() => {
                                try {
                                    const parsedJson = JSON.parse(tempConfig);
                                    if (!Array.isArray(parsedJson)) {
                                        message.error("Error in JSON!");
                                        return;
                                    }
                                    setItems(parsedJson);
                                    setTempConfig("");
                                    message.info("Updated!");
                                } catch (e) {
                                    // captureException(e)
                                    message.error("Error in JSON!");
                                }
                            }}
                        >
                            Update config
                        </Button>
                    </Form.Item>
                </>
            </Card>
        </div>
    );
}

const DraggableRow = ({ index, moveRow, ...restProps }) => {
    const ref = useRef();
    const [, drop] = useDrop({
        accept: "row",
        drop(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            // Position checking logic (as in previous example) can be added here for better UX
            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [, drag] = useDrag({
        type: "row",
        item: { index },
    });

    drag(drop(ref));
    return <tr ref={ref} {...restProps} />;
};
