import React, { useState } from "react";
import { Card, Modal, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getKTData } from "./KTData";
interface CardWrapperProps {
    children: React.ReactNode;
    feature: string;
}

const KTWrapper: React.FC<CardWrapperProps> = ({ children, feature }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const modalData = getKTData(feature);

    if (!modalData) {
        return <>{children}</>;
    }

    const showModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);

    return (
        <div style={{ position: "relative" }}>
            {/* Tooltip Icon */}
            <Tooltip title={`${feature}`}>
                <QuestionCircleOutlined
                    style={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        fontSize: "16px",
                        color: "#1890ff",
                        zIndex: 1,
                        cursor: "pointer",
                    }}
                    onClick={showModal}
                />
            </Tooltip>

            {children}

            {/* Modal */}
            <Modal
                title={`${feature} info`}
                open={isModalVisible}
                onCancel={closeModal}
                onOk={closeModal}
                footer={null}
            >
                <p>
                    <strong>Developer:</strong> {modalData.developer}
                </p>
                {modalData.discription && (
                    <p>
                        <strong>Discription: </strong>
                        {modalData.discription}
                    </p>
                )}
                <p>
                    <strong>Take KT From:</strong> {modalData.ktSource}
                </p>
                {modalData.dashboardDemoLink && (
                    <p>
                        <strong>Dashboard Demo: </strong>{" "}
                        <a
                            href={modalData.dashboardDemoLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Dashboard Link
                        </a>
                    </p>
                )}

                {modalData.QAPreviewLink && (
                    <p>
                        <strong>QA Preview: </strong>{" "}
                        <a
                            href={modalData.QAPreviewLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            QA Link
                        </a>
                    </p>
                )}

                {modalData.demoLink && (
                    <>
                        <iframe
                            title={`${feature} video`}
                            src={modalData.demoLink}
                            allowFullScreen
                            style={{ width: "100%", height: "250px" }}
                        />
                        <p>
                            <a
                                href={modalData.demoLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                or Play Video in new Tab
                            </a>
                        </p>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default KTWrapper;
