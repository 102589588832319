export const COMPONENT_RICH_TEXT_SCHEMA = {
    text: [],
    background: "#ffffff",
    align: "left",
};

export const COMPONENT_CONDITION_SCHEMA = {
    conditions: [
        {
            text: [], // RICH TEXT
        },
    ],
    compute_functions: [
        {
            isGlobal: true,
            params: [
                "variables",
                "isStart",
                "conditions",
                "currConditionIndex",
                "isConditionCorrect",
            ],
            name: "updateFeedbackAudio",
            output: `
                function updateFeedbackAudio(
                    variables,
                    isStart,
                    conditions,
                    currConditionIndex,
                    isConditionCorrect,
                ) {
                    if (isStart) {
                        return "question_audio_checking_condition";
                    }
                    if (currConditionIndex == 0) {
                        if (isConditionCorrect) return "question_audio_correct_c_1";
                        return "question_audio_incorrect_c_1";
                    }
                    if (currConditionIndex == 1) {
                        if (isConditionCorrect) return "question_audio_correct_c_2";
                        return "question_audio_incorrect_c_2";
                    }
                }
            `,
            type: "JAVASCRIPT",
        },
        {
            isGlobal: true,
            params: [
                "variables",
                "isStart",
                "conditions",
                "currConditionIndex",
                "isConditionCorrect",
            ],
            name: "updateFeedbackText",
            output: `
                function updateFeedbackText(
                    variables,
                    isStart,
                    conditions,
                    currConditionIndex,
                    isConditionCorrect,
                ) {
                    if (isStart) {
                        return "Checking condition";
                    }
                    if (currConditionIndex == 0) {
                        if (isConditionCorrect) return "Correct condition 1";
                        return "Incorrect condition 1";
                    }
                    if (currConditionIndex == 1) {
                        if (isConditionCorrect) return "Correct condition 2";
                        return "Incorrect condition 2";
                    }
                }
            `,
            type: "JAVASCRIPT",
        },
        {
            isGlobal: true,
            params: [
                "variables",
                "conditions",
                "currConditionIndex",
                "getValue",
            ],
            name: "getConditionCorrect",
            output: `
                function getConditionCorrect(
                    variables,
                    conditions,
                    currConditionIndex,
                    getValue,
                ) {
                    const sum = 5;
                    const diff = 1;
                    const data = getValue("Components");
                    function calculateDifference(arr) {
                        let result = arr[0];
                        for (let i = 1; i < arr.length; i++) {
                            result -= arr[i];
                        }
                        return result;
                    }
                    const values = data
                        .filter((i) => i.value.value)
                        .map((i) => parseInt(i.value.text_value.value));
                    if (currConditionIndex == 0) {
                        return values.reduce((sum, i) => sum + parseInt(i), 0) == sum;
                    }
                    if (currConditionIndex == 1) {
                        return Math.abs(calculateDifference(values)) == diff;
                    }
                    return false;
                }
            `,
            type: "JAVASCRIPT",
        },
        {
            name: "getCurrentConditionFeedback",
            output: "function getCurrentFeedback(feedback, feedbackList) {console.log(feedback, feedbackList);\n  const tempList = Array.isArray(feedbackList)\n    ? feedbackList\n    : JSON.parse(feedbackList);\n  return tempList.find((v) => v.name === feedback);\n}",
            params: ["feedback", "feedbackList"],
            type: "JAVASCRIPT",
            isGlobal: false,
            label: "",
            show: true,
        },
        {
            name: "updateFeedbackPool",
            output: 'function updateFeedbackText(feedbackPool, global_context_variables) {\n    function replaceValuesInTemplate(template, valueMap) {\n        function replace(obj) {\n            if (Array.isArray(obj)) {\n                return obj.map((item) => replace(item));\n            } else if (typeof obj === "object" && obj !== null) {\n                const replacedObj = {};\n                Object.keys(obj).forEach((key) => {\n                    if (typeof obj[key] === "string") {\n                        const replacedValue = replaceVariablesInString(\n                            obj[key],\n                            valueMap,\n                        );\n                        replacedObj[key] = replacedValue;\n                    } else {\n                        replacedObj[key] = replace(obj[key]);\n                    }\n                });\n                return replacedObj;\n            } else if (typeof obj === "string") {\n                return replaceVariablesInString(obj, valueMap);\n            } else {\n                return obj;\n            }\n        }\n        function replaceVariablesInString(str, valueMap) {\n            const regex = /@@@(.*?)@@@/g;\n            const parsedText = str.replace(regex, (match, variableName) => {\n                const val =\n                    valueMap[variableName] !== undefined\n                        ? valueMap[variableName]\n                        : match;\n                return typeof val == "object" ? JSON.stringify(val) : val;\n            });\n            try {\n                const text = JSON.parse(parsedText);\n                return typeof text === "number" ? `${text}` : text;\n            } catch {\n                return parsedText;\n            }\n        }\n        return [...replace(template)];\n    }\n    return replaceValuesInTemplate(feedbackPool, global_context_variables);\n}',
            params: ["feedbackPool"],
            type: "JAVASCRIPT",
            isGlobal: true,
            forceGlobal: true,
            show: true,
            label: "Update Feedback Pool",
        },
        {
            forceGlobal: true,
            isGlobal: true,
            params: [],
            name: "updateConditionStatus",
            output: "function updateConditionStatus(defaults) { return defaults.condition_status; }",
            type: "JAVASCRIPT",
            show: true,
            label: "Update Condition Status",
        },
    ],
    variables: [
        {
            name: "currentFeedbackData",
            default: "",
        },
        {
            name: "scrollId",
            default: "crossword_with_conditions",
        },
        {
            objectType: "NUMBER",
            value: [],
            name: "condition_status",
        },
        {
            objectType: "FEEDBACK_POOL",
            value: [],
            schema: {
                text: [],
                name: "",
                duration: 5,
            },
            name: "feedback_pool",
            type: "GLOBAL",
            label: "Feedback Pool",
        },
    ],
};

export const COMPONENT_FIGMA_SCHEMA = {
    name: "",
    variables: [],
    compute_functions: [],
    figma_json: {},
    figma_data: {
        url: "",
        file_key: "",
        node_id: "",
    },
};

export const COMPONENT_GENERIC_RENDERER_SCHEMA = {
    name: "",
    variables: [],
    effects: [],
    default_value: "",
};

export const COMPONENT_SURFER_GAME_SCHEMA = {
    name: "",
    config: {
        collector: {
            type: "circle",
            color: "cyan",
            shape: {
                width: 50,
                height: 50,
            },
            radius: 25,
        },
        item_types: [],
        max_items: 5,
        item_speed: 3,
        item_movement_type: "random",
        on_fall_operation: "destroy",
        lives: 3,
        is_correct: false,
        feedback_pool: [],
        start_button_name: "Start now!",
        backpress_action: "pause",
    },
    functions: {
        updateGameOnCollision: {
            output: `function updateOnCollision(eventData, game) {
          const item = eventData.item;
          if (game?.collector) {
              game.collector.color = item.color || "cyan";
          }

          const sum = game.collectedItems.reduce((acc, item) => acc + item.value, 0);
          if (sum < 30) {
              game.itemSpeed = 4;
          } else if (sum < 60) {
              game.itemSpeed = 5;
          } else {
              game.stopGame();
          }
      }`,
            params: ["eventData", "game"],
        },
        updateEndSound: {
            output: `function updateEndSound(game) {
                return "";
            }`,
            params: ["game"],
        },
        updateEndFeedback: {
            output: `function updateEndFeedback(game) {
                return "";
            }`,
            params: ["game"],
        },
        updateEndStory: {
            output: `function updateEndStory(game) {
                return "";
            }`,
            params: ["game"],
        },
        onGameEndEffects: {
            output: `function onGameEndEffects(game) {
                let effects = [
                    {
                        type: "END_QUESTION"
                    }
                ];
                return effects;
            }`,
            params: ["game"],
        },
    },
};

export const COMPONENT_BALANCE_GAME_SCHEMA = {
    name: "",
    show_in_preview: false, // done
    skip_evaluation: false, // done
    disable_interaction: false, // done
    scale_balance_settings: {
        // done
        leverImage:
            "https://sets-gamify-assets.s3.ap-south-1.amazonaws.com/dev/home-explore/document/1727719483508.png",
        // done
        baseImage:
            "https://sets-gamify-assets.s3.ap-south-1.amazonaws.com/dev/home-explore/document/1727721101046.png",
        animation: "submit", // done
        feedback_pool: [], // done
        evaluation: "ALL_IN_ONE_GO", // done
        feedbackType: "MANUAL_AUDIO", // done
        hasFeedbackStroke: false, // done
        hasHighlight: false, // done
        maxRotation: 30,
        backgroundSettings: {
            enabled: false,
            backgroundImage: "",
            minHeight: "",
            position: "",
            size: "contain",
            includeTagCluster: false,
        },
        containers: [
            {
                index: 0,
                imageBackground: {
                    // done
                    enabled: false,
                    src: {
                        default: "",
                    },
                    size: "contain",
                    position: "bottom",
                },
                fillColor: { enabled: false, default: "#F5F5F5" }, // done
                minHeight: {
                    enabled: true,
                    default: "80",
                    fixedHeight: false,
                },
                padding: {
                    enabled: false, // done
                },
                items: [],
                flexSize: {
                    enabled: false,
                    wrap: "wrap",
                    alignItems: "end",
                    rowItems: null,
                    rowGap: 0,
                    columnGap: 0,
                },
                settings: {
                    evaluation: "ALL_IN_ONE_GO", // done
                    feedbackType: "MANUAL_AUDIO", // done
                    hasFeedbackStroke: false, // done
                    hasHighlight: false, // done
                },
                type: "normal",
                dndSettings: {
                    category: ["left", "both"],
                    minItems: 1,
                    maxItems: null,
                },
            },
            {
                index: 1,
                imageBackground: {
                    enabled: false,
                    src: { default: "" },
                    size: "contain",
                    position: "bottom",
                },
                fillColor: {
                    enabled: false,
                    default: "#F5F5F5",
                    opacity: "50",
                },
                //border,
                // padding,
                padding: {
                    enabled: false,
                },
                // margin
                flexSize: {
                    enabled: false,
                    wrap: "wrap",
                    alignItems: "end",
                    rowItems: null,
                    rowGap: 0,
                    columnGap: 0,
                },
                minHeight: {
                    enabled: true,
                    default: "80",
                    fixedHeight: false,
                },
                items: [],
                settings: {
                    evaluation: "ALL_IN_ONE_GO",
                    feedbackType: "MANUAL_AUDIO",
                    hasFeedbackStroke: false,
                    hasHighlight: false,
                },
                type: "normal",
                dndSettings: {
                    category: ["right", "both"],
                    minItems: 1,
                    maxItems: null,
                },
            },
        ],
        dragItems: [],
        tagClusterSettings: {
            isBackgroundTransparent: false,
        },
    },
    component_functions: [
        {
            name: "updateFeedbackPool",
            output: `function updateFeedbackText(feedbackPool, global_context_variables) {
                function replaceValuesInTemplate(template, valueMap) {
                  function replace(obj) {
                    if (Array.isArray(obj)) {
                      return obj.map(item => replace(item));
                    } else if (typeof obj === 'object' && obj !== null) {
                      const replacedObj = {};
                      Object.keys(obj).forEach(key => {
                        if (typeof obj[key] === 'string') {
                          const replacedValue = replaceVariablesInString(obj[key], valueMap);
                          replacedObj[key] = replacedValue;
                        } else {
                          replacedObj[key] = replace(obj[key]);
                        }
                      });
                      return replacedObj;
                    } else if (typeof obj === 'string') {
                      return replaceVariablesInString(obj, valueMap);
                    } else {
                      return obj;
                    }
                  }
                  function replaceVariablesInString(str, valueMap) {
                    const regex = /@@@(.*?)@@@/g;
                    const parsedText = str.replace(regex, (match, variableName) => {
                      const val = valueMap[variableName] !== undefined ? valueMap[variableName] : match;
                      return typeof val == 'object' ? JSON.stringify(val) : val;
                    });
                    try {
                      const text = JSON.parse(parsedText);
                      return typeof text === 'number' ? text : text;
                    } catch {
                      return parsedText;
                    }
                  }
                  return [...replace(template)];
              }
                return replaceValuesInTemplate(feedbackPool, global_context_variables);
              }`,
            params: ["feedbackPool"],
        },
        {
            name: "updateEndFeedback",
            output: `function updateEndFeedback(is_correct, sides) {
                if(is_correct) return 'question_audio_correct';
                const totalLives = pixiData.globalContext.parent_variables?.find((i) =>
                        i.name.includes("total_lives"),
                    );
                    const totalLivesVal = totalLives.default ?? totalLives.value;
                    if (totalLivesVal == 2 && !is_correct) {
                        return "question_audio_incorrect_try1";
                    }
                    if (totalLivesVal == 1 && !is_correct) {
                        return "question_audio_incorrect_try2";
                    }
                }`,
            params: ["is_correct", "sides"],
        },
        {
            name: "updateStartFeedback",
            output: `function updateStartAudio(is_correct, sides) {
                return 'question_audio_checking';
              }`,
            params: ["is_correct", "sides"],
        },
        {
            name: "updateEndAudio",
            output: `function updateEndAudio(is_correct, sides) {
                if(is_correct) return 'question_audio_correct';
                const totalLives = pixiData.globalContext.parent_variables?.find((i) =>
                        i.name.includes("total_lives"),
                    );
                    const totalLivesVal = totalLives.default ?? totalLives.value;
                    if (totalLivesVal == 2 && !is_correct) {
                        return "question_audio_incorrect_try1";
                    }
                    if (totalLivesVal == 1 && !is_correct) {
                        return "question_audio_incorrect_try2";
                    }
                }`,
            params: ["is_correct", "sides"],
        },
        {
            name: "updateStartAudio",
            output: `function updateStartAudio(is_correct, sides) {
                return 'question_audio_checking';
              }`,
            params: ["is_correct", "sides"],
        },
        {
            name: "checkCorrectAnswers",
            output: `function checkCorrectAnswers(attempt, sides, global_context_variables) {
                const leftWeight = sides[0].reduce((sum, tag) => sum + Number(tag.weight), 0);
                const rightWeight = sides[1].reduce((sum, tag) => sum + Number(tag.weight), 0);
                let is_correct = false;
                if (leftWeight === rightWeight) {
                  is_correct = true;
                }
                return sides.map((i, ii) => {
                  const pan = [];
                  i.map((j,jj) => {
                    if(j.type == 'effect'){
                      pan[jj] = is_correct
                    }
                  })
                  return {
                    is_correct: is_correct,
                    children_status: pan,
                  };
                });
              }`,
            params: ["sides"],
        },
        {
            name: "updateStory",
            output: `function updateStory(is_correct, sides) {
                const totalLives = pixiData.globalContext.parent_variables?.find((i) =>
                        i.name.includes("total_lives"),
                    );
                    const totalLivesVal = totalLives.default ?? totalLives.value;
                    if (totalLivesVal == 2 && !is_correct) {
                        return "name:manual_story";
                    }
                }`,
            params: ["is_correct", "sides"],
        },
        {
            name: "updateContainerStartAudio",
            output: `function updateStartAudio(is_correct, sides) {
                return 'question_audio_checking';
              }`,
            params: ["is_correct", "container"],
        },
        {
            name: "updateContainerEndAudio",
            output: `function updateContainerEndAudio(is_correct, container) {
                if(is_correct) return 'question_audio_correct';
                const totalLives = pixiData.globalContext.parent_variables?.find((i) =>
                        i.name.includes("total_lives"),
                    );
                    const totalLivesVal = totalLives.default ?? totalLives.value;
                    if (totalLivesVal == 2 && !is_correct) {
                        return "question_audio_incorrect_try1";
                    }
                    if (totalLivesVal == 1 && !is_correct) {
                        return "question_audio_incorrect_try2";
                    }
                }`,
            params: ["is_correct", "container"],
        },
        {
            name: "updateContainerEndFeedback",
            output: `function updateContainerEndAudio(is_correct, container) {
                if(is_correct) return 'question_audio_correct';
                const totalLives = pixiData.globalContext.parent_variables?.find((i) =>
                        i.name.includes("total_lives"),
                    );
                    const totalLivesVal = totalLives.default ?? totalLives.value;
                    if (totalLivesVal == 2 && !is_correct) {
                        return "question_audio_incorrect_try1";
                    }
                    if (totalLivesVal == 1 && !is_correct) {
                        return "question_audio_incorrect_try2";
                    }
                }`,
            params: ["is_correct", "container"],
        },
        {
            name: "updateContainerStory",
            output: `function updateStory(is_correct, sides) {
                const totalLives = pixiData.globalContext.parent_variables?.find((i) =>
                        i.name.includes("total_lives"),
                    );
                    const totalLivesVal = totalLives.default ?? totalLives.value;
                    if (totalLivesVal == 2 && !is_correct) {
                        return "name:manual_story";
                    }
                }`,
            params: ["is_correct", "container"],
        },
    ],
};
