import { SlateEditor } from "src/components/plate";
import { useEffect, useState } from "react";
import { renderEditorMCQ } from "../MCQ";
import _ from "lodash";

const renderEditorCaseStudy = ({
    block,
    setBlock,
    currentSubBlock,
    isEditor,
    setIsEditor,
    currentBlock,
    isNewBlock,
    setIsNewBlock,
    worksheet,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            case_study: { text },
        },
        children,
    } = block;
    if (currentSubBlock == null) {
        const [reOrder, setReOrder] = useState(false);

        const [currentCaseStudyEditor, setCurrentCaseStudyEditor] = useState(
            `${id || tmpId}_text`,
        );
        useEffect(() => {
            setCurrentCaseStudyEditor(`${id || tmpId}_text`);
        }, [id, tmpId]);
        useEffect(() => {
            if (currentSubBlock == null && !isEditor) {
                !isNewBlock && setCurrentCaseStudyEditor("");
                isNewBlock && setIsNewBlock(false);
            }
        }, [id, tmpId, isEditor]);
        useEffect(() => {
            if (currentCaseStudyEditor != "") {
                setIsEditor(true);
            }
        }, [id, tmpId, currentCaseStudyEditor]);
        const renderEditorCaseStudy = () => (
            <div>
                <SlateEditor
                    id={`${id || tmpId}_text`}
                    onChange={(value: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", "case_study", "text"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    isFocused={currentCaseStudyEditor === `${id || tmpId}_text`}
                    value={text}
                    setEditor={(id: string) => setCurrentCaseStudyEditor(id)}
                />
            </div>
        );
        return <>{renderEditorCaseStudy()}</>;
    } else {
        return (
            <div>
                {renderEditorMCQ({
                    block: children[currentSubBlock],
                    setBlock: (subBlock: any) => {
                        let tmpBlock = _.cloneDeep(block);

                        tmpBlock = _.set(
                            tmpBlock,
                            ["children", currentSubBlock],
                            subBlock,
                        );
                        setBlock(tmpBlock);
                    },
                    isEditor,
                    setIsEditor,
                    currentBlock: currentSubBlock + 1,
                    isNewBlock,
                    setIsNewBlock,
                    worksheet,
                })}
            </div>
        );
    }
};

export default renderEditorCaseStudy;
