import { Button, Card, Form, Input, InputNumber, Select } from "antd";
import React from "react";
import { LogicModal } from "../common/LogicModal";
import { FeedbackPool } from "../common/FeedbackPool";

type Props = {
    config: any;
    setConfig: any;
    functions: any;
    setFunctions: any;
    renderLinkSelect: any;
    mentionsList: any[];
};

export default function GameConfigs({
    config,
    setConfig,
    functions,
    setFunctions,
    renderLinkSelect,
    mentionsList,
}: Props) {
    function getConvertedFunctions(functions: any) {
        if (!functions) {
            return [];
        }
        return Object.keys(functions).map((key) => {
            const name = key;
            return {
                name,
                ...functions[key],
                isGlobal: true,
                label: name,
            };
        });
    }

    const [isModalOpen, setIsModalOpen] = React.useState(false);

    return (
        <Card
            title="Customize Game Configs"
            bordered={true}
            style={{
                // width: 400,
                width: "90%",
                margin: "0 auto",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "10px",
                padding: "20px",
            }}
            headStyle={{ textAlign: "center", fontSize: "18px" }}
        >
            <Form
                layout="vertical"
                style={{
                    display: "flex",
                    gap: "10px",
                    marginBottom: "10px",
                }}
            >
                <div style={{ flex: 1 }}>
                    {functions && (
                        <LogicModal
                            computeFUnctions={getConvertedFunctions(functions)}
                            onSave={(newFunctions) => {
                                const obj: any = {};
                                newFunctions.forEach((f) => {
                                    obj[f.name] = f;
                                });
                                setFunctions(obj);
                            }}
                        />
                    )}
                </div>
                <div style={{ flex: 1 }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                        size="small"
                    >
                        Feedback pool
                    </Button>
                    <FeedbackPool
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        name="feedback_pool"
                        feedbackPool={config.feedback_pool}
                        onSave={(feedback_pool: any) => {
                            setConfig({
                                ...config,
                                feedback_pool,
                            });
                        }}
                    />
                </div>
            </Form>
            <Form
                layout="vertical"
                style={{
                    display: "flex",
                    gap: "10px",
                }}
            >
                <Form.Item label="Max items on screen" style={{ flex: 1 }}>
                    <InputNumber
                        min={0}
                        max={20}
                        value={config.max_items}
                        onChange={(value) =>
                            setConfig({
                                ...config,
                                max_items: value,
                            })
                        }
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <Form.Item label="Initial item speed" style={{ flex: 1 }}>
                    <InputNumber
                        min={0}
                        max={20}
                        value={config.item_speed}
                        onChange={(value) =>
                            setConfig({
                                ...config,
                                item_speed: value,
                            })
                        }
                        style={{ width: "100%" }}
                    />
                </Form.Item>
            </Form>
            <Form
                layout="vertical"
                style={{
                    display: "flex",
                    gap: "10px",
                }}
            >
                <Form.Item label="Start button name" style={{ flex: 1 }}>
                    <Input
                        value={config.start_button_name || "Start now!"}
                        onChange={(e) =>
                            setConfig({
                                ...config,
                                start_button_name: e.target.value,
                            })
                        }
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <Form.Item label="Backpress Action" style={{ flex: 1 }}>
                    <Select
                        value={config.backpress_action || "PAUSE"}
                        onChange={(value) =>
                            setConfig({
                                ...config,
                                backpress_action: value,
                            })
                        }
                    >
                        <Select.Option value="PAUSE">Pause</Select.Option>
                        <Select.Option value="EXIT">Exit</Select.Option>
                    </Select>
                </Form.Item>
            </Form>

            <Form
                layout="vertical"
                style={{
                    display: "flex",
                    gap: "10px",
                }}
            >
                <div style={{ flex: 1 }}>
                    {renderLinkSelect
                        ? renderLinkSelect({
                              value: config.linked_global_context_variable,
                              onChange: (value: any) => {
                                  setConfig({
                                      ...config,
                                      linked_global_context_variable: value,
                                  });
                              },
                          })
                        : null}
                </div>
                <div style={{ flex: 1 }}>
                    <Form.Item label={"Value"}>
                        <Select
                            value={config.value}
                            placeholder="Select type"
                            options={[
                                {
                                    label: "none",
                                    value: null,
                                },
                                ...mentionsList?.map((v) => ({
                                    label: v.text,
                                    value: `@@${v.text}@@`,
                                })),
                            ]}
                            onChange={(value) => {
                                setConfig({
                                    ...config,
                                    value,
                                });
                            }}
                        />
                    </Form.Item>
                </div>
            </Form>
            <Form
                layout="vertical"
                style={{
                    display: "flex",
                    gap: "10px",
                }}
            >
                <Form.Item label="On start audio" style={{ flex: 1 }}>
                    <Input
                        value={config.on_start_audio}
                        onChange={(e) =>
                            setConfig({
                                ...config,
                                on_start_audio: e.target.value,
                            })
                        }
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <Form.Item label="On start feedback" style={{ flex: 1 }}>
                    <Input
                        value={config.on_start_feedback}
                        onChange={(e) =>
                            setConfig({
                                ...config,
                                on_start_feedback: e.target.value,
                            })
                        }
                        style={{ width: "100%" }}
                    />
                </Form.Item>
            </Form>
        </Card>
    );
}
