import React, { useState } from "react";

import { Layout, MetaTag } from "src/components";
import ChapterForm from "../../../components/ChapterForm";
import { Card, Col, Row, Spin } from "antd";

const EditView = (props: any) => {
    const {
        chapter,
        onFinish,
        onFinishFailed,
        disableHasTopics = false,
    } = props;

    return (
        <Layout
            titles={[
                { name: "Chapters", link: "/chapter" },
                { name: "Edit", link: "" },
                { name: chapter?.name, link: "" },
            ]}
        >
            <MetaTag title="Add Chapter" />
            <Row justify="center" align="middle">
                <Col xs={12} lg={20} md={24}>
                    <Card
                        style={{ borderRadius: "8px", padding: "12px" }}
                        title={"Chapter Update"}
                        bordered={true}
                    >
                        {chapter && (
                            <ChapterForm
                                chapter={chapter}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                disableHasTopics={disableHasTopics}
                            />
                        )}
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default EditView;
