import React from "react";
import { Card, Col, Input, Row } from "antd";
import get from "lodash/get";

import { resetFilterHook } from "src/helpers";

import { UsersField } from ".";
import { RenderField, Field } from "src/components";

const FilterComponent = (props) => {
    const { where = {}, onStateReset, isUser = false, onEmailChange } = props;

    resetFilterHook(onStateReset);

    return (
        <Card bodyStyle={{ padding: "8px" }}>
            <Row gutter={[24, 0]} align="middle">
                {/* {isUser && (
                    <Col lg={8} md={24}>
                        <UsersField where={where} />
                    </Col>
                )} */}

                <Col lg={8} md={24}>
                    <Field
                        label="user email search"
                        placeholder="User email"
                        icon="FontSizeOutlined"
                        component={RenderField}
                        onChange={(e) => onEmailChange(e)}
                        value={get(where, "email._ilike")?.replace(/%/g, "")}
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default FilterComponent;
