import {
    Modal,
    Switch,
    Typography,
    Form,
    Space,
    Row,
    Col,
    Button,
    message,
    Select,
    Input,
} from "antd";
import { cloneDeep, set } from "lodash";
import React, { useEffect, useState } from "react";
import { RenderUpload } from "src/components";
import { BLOCK_TYPES } from "..";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { captureException } from "@sentry/react";

const {
    INPUT_AND_FORM_BLOCK,
    DRAG_EXPAND_NUMBER_BLOCK,
    INPUT_EXPAND_NUMBER_BLOCK,
    V2_INPUT_AND_FORM_BLOCK,
} = BLOCK_TYPES;

const CellButtonInput = (props: any) => {
    const {
        blockType,
        isModalOpen,
        closeForm,
        curCell,
        curCellRow,
        curCellCol,
        crossword,
        setCrossword,
        // hasMentions,
        // mentionsList = [],
    } = props;

    const [isSubmitting, setSubmitting] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [currentCell, setCurrentCell] = useState(curCell);

    useEffect(() => setCurrentCell(curCell), [curCell]);

    useEffect(() => {
        if (
            !currentCell ||
            !currentCell.value?.trim() ||
            (currentCell.type === "asset" && !currentCell.asset?.trim()) ||
            (["signs", "options"].includes(currentCell.type) &&
                (!currentCell.options?.length ||
                    !currentCell.correct_options?.length))
        ) {
            setDisabled(true);
        } else setDisabled(false);
    }, [currentCell]);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            let tmpBlock = cloneDeep(crossword);
            const tempData = tmpBlock?.options;
            tempData[curCellRow][curCellCol] = currentCell;
            tmpBlock = set(tmpBlock, ["options"], tempData);
            setCrossword(tmpBlock);
            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e)
            console.log(e);
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        closeForm();
    };

    const cellOptions = () => {
        const tempOptions = [
            {
                value: "string",
                label: "String",
            },
            {
                value: "asset",
                label: "Asset",
            },
        ];

        if (
            [INPUT_AND_FORM_BLOCK, V2_INPUT_AND_FORM_BLOCK].includes(blockType)
        ) {
            tempOptions.push({
                value: "input",
                label: "Input",
            });
        } else if ([INPUT_EXPAND_NUMBER_BLOCK].includes(blockType)) {
            tempOptions.push(
                {
                    value: "input",
                    label: "Input",
                },
                {
                    value: "signs",
                    label: "Signs",
                },
            );
        }

        return tempOptions;
    };

    return (
        <>
            <Modal
                title={
                    <Typography.Title level={4}>
                        Update Input Cell
                    </Typography.Title>
                }
                open={isModalOpen}
                onOk={closeForm}
                onCancel={closeForm}
                destroyOnClose
                width={720}
                footer={null}
            >
                <Form>
                    <Row gutter={16}>
                        <Col span={22}>
                            <Form.Item label="Cell Type">
                                <Select
                                    disabled={[
                                        V2_INPUT_AND_FORM_BLOCK,
                                    ].includes(blockType)}
                                    defaultValue={currentCell?.type}
                                    onChange={(type) => {
                                        const tempCell = {
                                            ...currentCell,
                                            type,
                                        };

                                        if (
                                            ["options", "signs"].includes(type)
                                        ) {
                                            if (!tempCell.options) {
                                                tempCell.options = [];
                                                tempCell.correct_options = [];
                                            }
                                        }

                                        setCurrentCell(tempCell);
                                    }}
                                    options={cellOptions()}
                                />
                            </Form.Item>
                        </Col>

                        {["string", "asset"].includes(currentCell?.type) && (
                            <Col span={22}>
                                <Form.Item label="Value">
                                    <Input
                                        placeholder={"Type the value here..."}
                                        value={currentCell?.value}
                                        onChange={({
                                            target: { value },
                                        }: any) =>
                                            setCurrentCell({
                                                ...currentCell,
                                                value,
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        )}

                        {currentCell?.type === "signs" && (
                            <>
                                <Col span={24}>
                                    <Form.Item label="Options">
                                        <Select
                                            value={currentCell?.options}
                                            mode="tags"
                                            style={{ width: "100%" }}
                                            placeholder="Options"
                                            onChange={(value) => {
                                                setCurrentCell({
                                                    ...currentCell,
                                                    options: value,
                                                    correct_options: [],
                                                });
                                            }}
                                            options={[
                                                ":",
                                                "÷",
                                                "×",
                                                "-",
                                                "+",
                                            ].map((i) => ({
                                                value: i,
                                                label: i,
                                            }))}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )}

                        {["signs", "options"].includes(currentCell?.type) && (
                            <>
                                <Col span={12}>
                                    <Form.Item label={"Correct Option"}>
                                        <Select
                                            value={
                                                currentCell.correct_options[0]
                                            }
                                            onChange={(value: any) =>
                                                setCurrentCell({
                                                    ...currentCell,
                                                    correct_options: [value],
                                                })
                                            }
                                            style={{ width: "100%" }}
                                            placeholder={"Correct Option"}
                                        >
                                            {currentCell.options.map(
                                                (option: any, idx: number) => {
                                                    return (
                                                        <Select.Option
                                                            value={idx + 1}
                                                            key={idx}
                                                        >
                                                            {option}
                                                        </Select.Option>
                                                    );
                                                },
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </>
                        )}

                        {currentCell?.type === "asset" && (
                            <Col span={22}>
                                <Form.Item label="Asset">
                                    <RenderUpload
                                        path="home-explore/document/"
                                        onChangeCustom={({
                                            target: { value },
                                        }: any) => {
                                            setCurrentCell({
                                                ...currentCell,
                                                asset: value,
                                            });
                                        }}
                                        label="upload an image"
                                        value={currentCell?.asset || ""}
                                        meta={{
                                            touched: true,
                                            error: !currentCell?.asset
                                                ? "Field cannot be Empty"
                                                : "",
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        )}

                        {[
                            INPUT_AND_FORM_BLOCK,
                            INPUT_EXPAND_NUMBER_BLOCK,
                            V2_INPUT_AND_FORM_BLOCK,
                        ].includes(blockType) && (
                                <>
                                    {[INPUT_AND_FORM_BLOCK].includes(blockType) &&
                                        currentCell?.type === "string" && (
                                            <Col span={12}>
                                                <Form.Item label="Remove border">
                                                    <Switch
                                                        checkedChildren={
                                                            <CheckOutlined />
                                                        }
                                                        unCheckedChildren={
                                                            <CloseOutlined />
                                                        }
                                                        checked={
                                                            currentCell?.no_border
                                                        }
                                                        onChange={(value) => {
                                                            setCurrentCell({
                                                                ...currentCell,
                                                                no_border: value,
                                                            });
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )}
                                    {currentCell?.type === "input" && (
                                        <>
                                            <Col span={24}>
                                                <Form.Item label="Correct Answers">
                                                    <Select
                                                        value={
                                                            currentCell?.correct_answer
                                                        }
                                                        mode="tags"
                                                        style={{ width: "100%" }}
                                                        placeholder="Correct Answers"
                                                        onChange={(value) => {
                                                            setCurrentCell({
                                                                ...currentCell,
                                                                correct_answer:
                                                                    value,
                                                            });
                                                        }}
                                                        options={[]}
                                                        open={false}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={24}>
                                                <Form.Item label="Cell Width (no of digits)">
                                                    <Input
                                                        placeholder={
                                                            "Type the value here..."
                                                        }
                                                        type="number"
                                                        value={currentCell?.width}
                                                        onChange={({
                                                            target: {
                                                                value: width,
                                                            },
                                                        }: any) =>
                                                            setCurrentCell({
                                                                ...currentCell,
                                                                width,
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}
                                </>
                            )}

                        {[DRAG_EXPAND_NUMBER_BLOCK].includes(blockType) && (
                            <>
                                <Col span={12}>
                                    <Form.Item label="Is fixed?">
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                            checked={currentCell?.is_fixed}
                                            onChange={(value) => {
                                                setCurrentCell({
                                                    ...currentCell,
                                                    is_fixed: value,
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                {!currentCell?.is_fixed && (
                                    <Col span={24}>
                                        <Form.Item label="Correct Answers">
                                            <Select
                                                value={
                                                    currentCell?.correct_answer
                                                }
                                                mode="tags"
                                                style={{ width: "100%" }}
                                                placeholder="Correct Answers"
                                                onChange={(value) => {
                                                    setCurrentCell({
                                                        ...currentCell,
                                                        correct_answer: value,
                                                    });
                                                }}
                                                options={[]}
                                                open={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                )}
                            </>
                        )}

                        <Col style={{ marginTop: "30px" }} span={24}>
                            <Space style={{ float: "right" }}>
                                <Button type="default" onClick={closeForm}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                    disabled={isDisabled}
                                >
                                    Submit
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default CellButtonInput;
