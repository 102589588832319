const headers = (path: string) => {
    let header = "";

    if (path.includes("/create")) header += "Create ";
    else if (path.includes("/update")) header += "Update ";
    else if (path.includes("/book")) header += "List ";

    if (path.includes("/question"))
        header += `Question${header.includes("List") ? "s" : ""}`;
    else if (path.includes("/topic"))
        header += `Topic${header.includes("List") ? "s" : ""}`;
    else if (path.includes("/chapter"))
        header += `Chapter${header.includes("List") ? "s" : ""}`;
    else if (path.includes("/book"))
        header += `Book${header.includes("List") ? "s" : ""}`;
    else header += "Home";

    return header;
};

export default headers;
