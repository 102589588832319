import { graphql } from "@apollo/client/react/hoc";
import { getLastStringFromUrl } from "src/helpers/functions";
import { FunctionComponent } from "react";
import {
    ADD_BLOCK_TAG_MAP,
    ADD_GROUP_WORKSHEET_MAP,
    ADD_WORKSHEET_TAG_MAP,
    CREATE_WORKSHEET_MUTATION,
    DELETE_BLOCKS,
    DELETE_BLOCK_TAG_MAP,
    DELETE_WORKSHEET_BLOCK_MAP,
    DELETE_WORKSHEET_MUTATION,
    DELETE_WORKSHEET_TAG_MAP,
    INSERT_VERSION,
    INSERT_WORKSHEET_BLOCK,
    INSERT_WORKSHEET_BLOCK_MAP,
    UPDATE_GROUP_WORKSHEET_MAP,
    UPDATE_WORKSHEET,
    UPDATE_WORKSHEET_BLOCK,
    UPDATE_WORKSHEET_USER_STATS,
    VERSION_MODEL_MAP_QUERY,
    WORKSHEETS_QUERY,
    WORKSHEET_BLOCKS_QUERY,
    WORKSHEET_BOOKS_QUERY,
    WORKSHEET_PUBLISHED_BLOCKS_QUERY,
    WORKSHEET_STATS,
} from "../graphql";
import _ from "lodash";
import { captureException } from "@sentry/react";

// Query
export const withWorksheets = (Component: FunctionComponent) =>
    graphql(WORKSHEETS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                worksheets,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadQuestionsData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            worksheets: [
                                ...previousResult.worksheets,
                                ...fetchMoreResult.worksheets,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                worksheets,
                subscribeToMore,
                updateQuery,
                loadQuestionsData,
                refetchWorksheets: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreateWorksheet = (Component: FunctionComponent) =>
    graphql(CREATE_WORKSHEET_MUTATION, {
        props: ({ mutate }) => ({
            createWorksheet: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createWorksheet: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { insert_worksheet_worksheet_one },
                    } = await mutate({
                        variables: { object },
                        // optimisticResponse: {
                        //     __typename: "Mutation",
                        //     createWorksheet: {
                        //         object,
                        //         __typename: "INSERT_WORKSHEET_ONE",
                        //     },
                        // },
                    });
                    return insert_worksheet_worksheet_one;
                } catch (e) {
                    captureException(e);
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateWorksheet = (Component: FunctionComponent) =>
    graphql(UPDATE_WORKSHEET, {
        props: ({ mutate }) => ({
            updateWorksheet: (object: Object) => {
                mutate({
                    variables: { ...object },
                    optimisticResponse: {
                        updateWorksheet: {
                            ...object,
                            __typename: "worksheet",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withDeleteWorksheet = (Component: FunctionComponent) =>
    graphql(DELETE_WORKSHEET_MUTATION, {
        props: ({ mutate }) => ({
            deleteWorksheet: (id: string) => {
                mutate({
                    variables: { id },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteWorksheet: {
                            id,
                            __typename: "DELETE_WORKSHEET_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withAddGroupWorksheetMap = (Component: FunctionComponent) =>
    graphql(ADD_GROUP_WORKSHEET_MAP, {
        props: ({ mutate }) => ({
            addGroupTabMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     updateBook: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withUpdateGroupWorksheetMap = (Component: FunctionComponent) =>
    graphql(UPDATE_GROUP_WORKSHEET_MAP, {
        props: ({ mutate }) => ({
            updateGroupTabMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     updateBook: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withWorksheetBlocks = (Component: FunctionComponent) =>
    graphql(WORKSHEET_BLOCKS_QUERY, {
        options: (props) => {
            return {
                variables: {
                    worksheet_id: getLastStringFromUrl(),
                },
                fetchPolicy: "network-only",
            };
        },
        props: ({ data }) => {
            const { loading, error, worksheetBlockMap, refetch } = data;

            if (error) {
                // throw new Error(error.message);
                console.log("withWorksheetBlocks error", error);
                captureException(error);
            }
            return {
                loadingWorksheetMap: loading,
                worksheetBlockMap: error ? [] : worksheetBlockMap,
                refetchWorksheetBlockMap: refetch,
            };
        },
    })(Component);

export const withWorksheetPublishedBlocks = (Component: FunctionComponent) =>
    graphql(WORKSHEET_PUBLISHED_BLOCKS_QUERY, {
        options: (props) => {
            return {
                variables: {
                    worksheet_id: getLastStringFromUrl(),
                },
                fetchPolicy: "network-only",
            };
        },
        props: ({ data }) => {
            const { loading, error, publlishedBlocks, refetch } = data;

            if (error) {
                // throw new Error(error.message);
                console.log("withWorksheetPublishedBlocks error", error);
                captureException(error);
            }
            return {
                loadingPublishedBlocks: loading,
                publlishedBlocks: error ? [] : publlishedBlocks,
                refetchPublishedBlocks: refetch,
            };
        },
    })(Component);

export const withWorksheetStats = (Component: FunctionComponent) =>
    graphql(WORKSHEET_STATS, {
        options: () => {
            return {
                variables: {
                    worksheet_id: getLastStringFromUrl(),
                },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ data }: any) => {
            const { loading, error, worksheet_worksheet_stats, refetch } = data;
            if (error) {
                console.log("ERROR:", error?.message);
                if (
                    !error?.message?.includes?.(
                        'invalid input syntax for type integer: ""',
                    )
                ) {
                    // throw new Error(error.message);
                    console.log("withWorksheetStats error", error);
                    captureException(error);
                }
            }
            return {
                worksheetStatsLoading: loading,
                worksheetStats: error ? null : worksheet_worksheet_stats?.[0],
                refetchWorksheetStats: refetch,
            };
        },
    })(Component);

export const withInsertWorksheetBlockMap = (Component: FunctionComponent) =>
    graphql(INSERT_WORKSHEET_BLOCK_MAP, {
        props: ({ mutate }) => ({
            insertWorksheetBlockMap: async (object: any) => {
                const newObject = _.cloneDeep(object);

                if (newObject?.objects?.length > 0) {
                    const newObjects = object?.objects.map((item: any) => {
                        if (
                            item?.block?.data?.type === "image" &&
                            item?.block?.data?.other
                        ) {
                            delete item.block.data.other;
                        }
                        return item;
                    });
                    newObject.objects = newObjects;
                }

                const {
                    data: {
                        insert_worksheet_worksheet_block_map: { returning },
                    },
                }: any = await mutate({
                    variables: { ...newObject },
                    // optimisticResponse: {
                    //     insertWorksheetBlockMap: {
                    //         ...object,
                    //         __typename: "worksheet_worksheet_block_map",
                    //     },
                    // },
                });

                return returning;
            },
        }),
    })(Component);

export const withDeleteWorksheetBlockMap = (Component: FunctionComponent) =>
    graphql(DELETE_WORKSHEET_BLOCK_MAP, {
        props: ({ mutate }) => ({
            deleteWorksheetBlockMap: async (object: Object) => {
                const { data } = await mutate({
                    variables: { ...object },
                });
                return data;
            },
        }),
    })(Component);

export const withDeleteBlocks = (Component: FunctionComponent) =>
    graphql(DELETE_BLOCKS, {
        props: ({ mutate }) => ({
            deleteBlocks: async (object: Object) => {
                const { data } = await mutate({
                    variables: { ...object },
                });
                return data;
            },
        }),
    })(Component);

export const withAddBlockTagMap = (Component: FunctionComponent) =>
    graphql(ADD_BLOCK_TAG_MAP, {
        props: ({ mutate }) => ({
            addBlockTagMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     updateBook: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withDeleteBlockTagMap = (Component: FunctionComponent) =>
    graphql(DELETE_BLOCK_TAG_MAP, {
        props: ({ mutate }) => ({
            deleteBlockTagMap: (object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     __typename: "Mutation",
                    //     deleteBook: {
                    //         id,
                    //         __typename: "DELETE_GROUP_BY_PK",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withAddWorksheetTagMap = (Component: FunctionComponent) =>
    graphql(ADD_WORKSHEET_TAG_MAP, {
        props: ({ mutate }) => ({
            addWorksheetTagMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     updateBook: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withDeleteWorksheetTagMap = (Component: FunctionComponent) =>
    graphql(DELETE_WORKSHEET_TAG_MAP, {
        props: ({ mutate }) => ({
            deleteWorksheetTagMap: (object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     __typename: "Mutation",
                    //     deleteBook: {
                    //         id,
                    //         __typename: "DELETE_GROUP_BY_PK",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withWorksheetBooks = (Component: FunctionComponent) =>
    graphql(WORKSHEET_BOOKS_QUERY, {
        options: () => {
            return {
                variables: {},
            };
        },
        props: ({ data }) => {
            const { loading, error, worksheetBooks } = data;

            if (error) throw new Error(error.message);
            return {
                loadingWorksheetBooks: loading,
                worksheetBooks,
            };
        },
    })(Component);

export const withVersionModelMap = (Component: FunctionComponent) =>
    graphql(VERSION_MODEL_MAP_QUERY, {
        options: (props) => {
            return {
                variables: {
                    model_name: "worksheet",
                    model_id: getLastStringFromUrl(),
                },
                fetchPolicy: "network-only",
            };
        },
        props: ({ data }) => {
            const { loading, error, versionModelMap, refetch } = data;

            if (error) {
                // throw new Error(error.message);
                console.log("withVersionModelMap error", error);
                captureException(error);
            }
            return {
                loadingVersionModelMap: loading,
                versionModelMap: error ? [] : versionModelMap,
                refetchVersionModelMap: refetch,
            };
        },
    })(Component);

export const withInsertVersion = (Component: FunctionComponent) =>
    graphql(INSERT_VERSION, {
        props: ({ mutate }) => ({
            insertVersion: async (object: Object) => {
                try {
                    const {
                        data: { insert_versioning_version_one },
                    } = await mutate({
                        variables: { object },
                    });
                    return insert_versioning_version_one;
                } catch (e) {
                    captureException(e);
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateWorksheetUserStats = (Component: FunctionComponent) =>
    graphql(UPDATE_WORKSHEET_USER_STATS, {
        props: ({ mutate }) => ({
            updateWorksheetUserStats: async (object: Object) => {
                try {
                    const {
                        data: { insert_worksheet_worksheet_stats_one },
                    } = await mutate({
                        variables: { object },
                        fetchPolicy: "no-cache",
                    });
                    return insert_worksheet_worksheet_stats_one;
                } catch (e) {
                    captureException(e);
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateWorksheetBlock = (Component: FunctionComponent) =>
    graphql(UPDATE_WORKSHEET_BLOCK, {
        props: ({ mutate }) => ({
            updateWorksheetBlock: async (object: Object) => {
                const { data } = await mutate({
                    variables: { ...object },
                    optimisticResponse: {
                        updateWorksheetBlock: {
                            ...object,
                        },
                    },
                });
                return data;
            },
        }),
    })(Component);

export const withInsertWorksheetBlock = (Component: FunctionComponent) =>
    graphql(INSERT_WORKSHEET_BLOCK, {
        props: ({ mutate }) => ({
            insertWorksheetBlock: async (object: Object) => {
                try {
                    const {
                        data: { insert_worksheet_block_one },
                    } = await mutate({
                        variables: { object },
                    });
                    return insert_worksheet_block_one;
                } catch (e) {
                    captureException(e);
                    console.error(e);
                }
            },
        }),
    })(Component);
