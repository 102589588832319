import { BLOCK_TYPES, isEditorEmpty } from "../../common/index";
const { V2_FILL_BLANK_BLOCK } = BLOCK_TYPES;

const isValidCallbackV2FillBlank = ({ block }: any) => {
    if (!block || !block.data)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            [V2_FILL_BLANK_BLOCK]: { text, blanks },
        },
    } = block;
    return {
        isValid: !isEditorEmpty(text) && blanks.length,
        error: <></>,
    };
};

export default isValidCallbackV2FillBlank;
