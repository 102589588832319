import { useLazyQuery } from "@apollo/client";
import { compose } from "src/helpers";
import { Button, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
    LIVE_VERSION_MODEL_MAP_QUERY,
    VERSION_MODEL_MAP_QUERY,
    WORKSHEET_BLOCKS_QUERY,
    WORKSHEET_BOOKS_QUERY,
    WORKSHEET_QUERY,
} from "../../graphql";
import {
    withCreateWorksheet,
    withInsertVersion,
    withInsertWorksheetBlockMap,
} from "../../operations";
import {
    duplicateWorksheet,
    duplicateWorksheetVersion,
} from "./DuplicateWorksheetButton";
import GroupsAssignComponent from "./GroupsAssignComponent";
import {
    graphQLClient,
    graphQLClientProd,
    createWorksheet as createWorksheetProd,
    insertWorksheetBlockMap as insertWorksheetBlockMapProd,
    insertVersion as insertVersionProd,
} from "../../../tab/components/PublishTabToProd";
import { Can } from "src/services/casl";
const { Option } = Select;

const CopyWorksheetModal: React.FC = ({
    worksheetId,
    // worksheetBooks,
    createWorksheet,
    insertWorksheetBlockMap,
    insertVersion,
}: any) => {
    const [env, setEnv] = useState<any>("qa");
    const [bookId, setBookId] = useState<any>();
    const [chapterId, setChapterId] = useState<any>();
    const [showTopics, setShowTopics] = useState<any>(false);

    const [topicId, setTopicId] = useState<any>();
    const [groupId, setGroupId] = useState<any>();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setBookId(null);
        setChapterId(null);
        setTopicId(null);
        setGroupId(null);
    };

    const ENVSelect = () => {
        return (
            <Select
                style={{ width: "100%" }}
                onChange={(value: any) => {
                    setEnv(value);
                }}
                placeholder={"select env"}
                value={env}
            >
                <Option value={"qa"}>QA</Option>
                <Option value={"prod"}>PROD</Option>
            </Select>
        );
    };

    const BooksSelect = () => {
        const [worksheetBooks, setWorksheetBooks] = useState<any>([]);

        useEffect(() => {
            const asyncFunc = async () => {
                if (env == "qa") {
                    setWorksheetBooks(
                        (await graphQLClient.request(WORKSHEET_BOOKS_QUERY))
                            .worksheetBooks,
                    );
                } else {
                    setWorksheetBooks(
                        (await graphQLClientProd.request(WORKSHEET_BOOKS_QUERY))
                            .worksheetBooks,
                    );
                }
            };
            asyncFunc();
        }, [env]);

        const books = worksheetBooks
            .map(({ groups }: any) => groups.map(({ group }: any) => group))
            .flat();
        return (
            <>
                <Select
                    style={{ width: "100%" }}
                    onChange={(value: any) => {
                        setBookId(value);
                        setChapterId(null);
                        setTopicId(null);
                        setGroupId(null);
                    }}
                    placeholder={"select Book"}
                    value={bookId}
                >
                    {books.map(
                        ({
                            id,
                            title,
                            other: { subject, class: className },
                        }: any) => (
                            <Option value={id}>
                                {title} ({className} - {subject})
                            </Option>
                        ),
                    )}
                </Select>
            </>
        );
    };

    const [getWorksheet, {}] = useLazyQuery(WORKSHEET_QUERY);
    const [getWorksheetBlocks, {}] = useLazyQuery(WORKSHEET_BLOCKS_QUERY);
    const [getWorksheetVersion, {}] = useLazyQuery(VERSION_MODEL_MAP_QUERY);
    const [getWorksheetVersionLive, {}] = useLazyQuery(
        LIVE_VERSION_MODEL_MAP_QUERY,
    );

    return (
        <>
            <span onClick={showModal}>Copy To</span>
            <Modal
                title="Copy Worksheet to other Chapter / Topic"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
            >
                {process.env.REACT_APP_API_ENDPOINT_PROD && (
                    <Can I="copyToProd" a={"worksheet"} passThrough>
                        {(allowed) =>
                            allowed ? (
                                <>
                                    <ENVSelect />
                                    <br />
                                    <br />
                                </>
                            ) : (
                                <></>
                            )
                        }
                    </Can>
                )}

                <BooksSelect />

                {bookId && (
                    <>
                        <br />
                        <br />
                        <GroupsAssignComponent
                            handleChange={(props: any) => {
                                const {
                                    value,
                                    object: {
                                        other: { has_topics },
                                    },
                                } = props;
                                setChapterId(value);

                                setShowTopics(has_topics);

                                if (!has_topics) setGroupId(value);
                                else {
                                    setGroupId(null);
                                }
                                setTopicId(null);
                            }}
                            selected={chapterId}
                            parentId={bookId}
                            env={env}
                        />
                    </>
                )}

                {chapterId && showTopics && (
                    <>
                        <br />

                        <GroupsAssignComponent
                            handleChange={({ value }: any) => {
                                setTopicId(value);
                                setGroupId(value);
                            }}
                            selected={topicId}
                            parentId={chapterId}
                            env={env}
                        />
                    </>
                )}
                <br />
                <br />
                <Button
                    block
                    onClick={async () => {
                        setLoading(true);
                        const newWorksheetId = await duplicateWorksheet({
                            getWorksheet,
                            getWorksheetBlocks,
                            groupId,
                            worksheetId,
                            createWorksheet:
                                env == `qa`
                                    ? createWorksheet
                                    : createWorksheetProd,
                            insertWorksheetBlockMap:
                                env == `qa`
                                    ? insertWorksheetBlockMap
                                    : insertWorksheetBlockMapProd,
                        });

                        const version_model_map_id =
                            await duplicateWorksheetVersion({
                                prevWorkseetId: worksheetId,
                                newWorksheetId,
                                getWorksheetVersion,
                                insertVersion:
                                    env == `qa`
                                        ? insertVersion
                                        : insertVersionProd,
                                getWorksheet,
                            });

                        await duplicateWorksheetVersion({
                            prevWorkseetId: worksheetId,
                            newWorksheetId,
                            getWorksheetVersion: getWorksheetVersionLive,
                            insertVersion:
                                env == `qa` ? insertVersion : insertVersionProd,
                            version: 1,
                            version_model_map_id,
                            getWorksheet,
                        });

                        setLoading(false);
                        handleCancel();
                    }}
                    type={"primary"}
                    disabled={!!!groupId}
                    loading={loading}
                >
                    Copy
                </Button>
            </Modal>
        </>
    );
};

export default compose(
    // withWorksheetBooks,
    withCreateWorksheet,
    withInsertWorksheetBlockMap,
    withInsertVersion,
)(CopyWorksheetModal);
