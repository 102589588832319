import React from "react";
import { Form, Input, Select } from "antd";
import { BLOCK_TYPES } from "../../common/index";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import ColorField from "../../common/ColorField";

const { BUTTON_BLOCK } = BLOCK_TYPES;

const BUTTON_TYPE_OPTIONS = [
    {
        label: "Internal",
        value: "internal",
    },
    {
        label: "External",
        value: "external",
    },
];

const renderEditorButton = ({
    block,
    setBlock,
    childBlockCount,
    disableWrite,
    currentBlock,

    renderLinkSelect,
}: any) => {
    const {
        data: {
            name = "",
            [BUTTON_BLOCK]: {
                text = "",
                font_size = "",
                font_weight = "",
                action: { type = "", value = "" } = {},
            },
            linked_global_context_variable,
        },
    } = block;

    const CTA_OPTIONS = [
        {
            label: "Next",
            value: "next",
        },
        {
            label: "End",
            value: "end",
        },
        ...[...Array(childBlockCount)]
            .map((_, i) => ({
                label: `Story ${i + 1}`,
                value: i + 1,
            }))
            .filter((item) => item.value !== currentBlock),
    ];

    return (
        <>
            <div>
                <br />
                <Form.Item
                    label="Name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    validateStatus={!name || !isNaN(name[0]) ? "error" : ""}
                    help={
                        !name
                            ? "Field cannot be empty"
                            : !isNaN(name[0])
                            ? "Cannot  start with a number"
                            : ""
                    }
                >
                    <Input
                        value={name}
                        disabled={disableWrite}
                        onChange={(e) => {
                            const value = e.target.value;
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "name"],
                                value?.trim(),
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>

                {renderLinkSelect &&
                    renderLinkSelect({
                        value: linked_global_context_variable?.name,
                        onChange: (v) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                [
                                    "data",
                                    "linked_global_context_variable",
                                    "name",
                                ],
                                v,
                            );
                            // tmpBlock = _.set(
                            //     tmpBlock,
                            //     ["data", "value"],
                            //     v !== null ? `@@${v}@@` : v,
                            // );
                            setBlock(tmpBlock);
                        },
                    })}
                <Form.Item label={"Button text"}>
                    <Input
                        value={text}
                        onChange={(e) => {
                            const value = e.target.value;
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["data", BUTTON_BLOCK, "text"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>

                <ColorField
                    label={"Button Text Color"}
                    path={["data", BUTTON_BLOCK, "color"]}
                    block={block}
                    setBlock={setBlock}
                />

                <Form.Item label={"Button Text Size"}>
                    <Input
                        value={font_size}
                        type={"number"}
                        onChange={(e) => {
                            const value = e.target.value;
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["data", BUTTON_BLOCK, "font_size"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>

                <Form.Item label={"Button Text Weight"}>
                    <Input
                        value={font_weight}
                        type={"number"}
                        onChange={(e) => {
                            const value = e.target.value;
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["data", BUTTON_BLOCK, "font_weight"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>

                <ColorField
                    label={"Button Color"}
                    path={["data", BUTTON_BLOCK, "background_color"]}
                    block={block}
                    setBlock={setBlock}
                />

                <Form.Item
                    label={"Button Type"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        defaultValue={type || BUTTON_TYPE_OPTIONS[0].value}
                        onChange={(value) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["data", BUTTON_BLOCK, "action", "type"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        options={BUTTON_TYPE_OPTIONS}
                    />
                </Form.Item>

                {(type === BUTTON_TYPE_OPTIONS[1].value || !type) && (
                    <Form.Item label={"Button click action"}>
                        <Input
                            value={value}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", BUTTON_BLOCK, "action", "value"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                )}
            </div>
        </>
    );
};

export default renderEditorButton;
