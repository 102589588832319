import React from "react";
import { Form, Input, Select, Divider } from "antd";
import _ from "lodash";

import SvgField from "./SvgField";

const CanvasSettings = ({ block, setBlock, disableWrite }: any) => {
    return (
        <>
            <SvgField
                disableWrite={disableWrite}
                block={block}
                setBlock={setBlock}
            />
        </>
    );
};

export default CanvasSettings;
