import { gql } from "@apollo/client";export default gql`mutation createPlan($object: subscription_plan_insert_input = {}) {
  insert_subscription_plan_one(object: $object, on_conflict: {constraint: plan_pkey, update_columns: id}) {
    id
    banner
    message_banner
    created_at
    credits
    description
    parent_id
    slug
    status
    tags {
      id
      tag {
        id
        name
        value
      }
    }
    title
    type
    variants
    breakdown
    order
  }
}
`;
