import { FunctionComponent } from "react";

import {
    getLastStringFromUrl,
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import {
    BOOK_QUERY,
    BOOKS_QUERY,
    BOOK_AGGREGATE_QUERY,
    TAGS_QUERY,
} from "../../graphql";

import set from "lodash/set";

export const withHookForBooks = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { pagination, where } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            BOOKS_QUERY,
            {
                variables: {
                    ...pagination,
                    where: set(where, ["type", "_eq"], "book"),
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "books", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    books: items,
                    subscribeToMore,
                    updateQuery,
                    loadBooksData: loadItemsData,
                    refetchBooks: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateBook = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { where } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            BOOK_AGGREGATE_QUERY,
            {
                variables: { where },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "bookAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    bookAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForBook = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(BOOK_QUERY, {
            variables: {
                id: getLastStringFromUrl(),
            },
        });

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingBook: loading,
                    book: data?.book,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForTags = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { where, pagination, tagGroup } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(TAGS_QUERY, {
            variables: {
                where: set(where, ["group", "_eq"], tagGroup),
                ...pagination,
            },
        });
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "tags", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    tags: items,
                    subscribeToMore,
                    updateQuery,
                    loadBookData: loadItemsData,
                    refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
