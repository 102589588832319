import {
    Badge,
    Button,
    Card,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    Row,
    Switch,
} from "antd";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import React, { useEffect, useState } from "react";
import {
    CheckOutlined,
    CloseOutlined,
    MenuOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { Actions } from "../../../../WorksheetEditor/components/Editor/BlocksColumn/Actions";
import { BLOCK_TYPES, DurationPicker } from "../../../common";
import { DEFAULT_VALUE_FILL_BLANK } from "../../V2FillBlank/defaultValue";
import { CustomEditor } from "../../..";
import { getV2DefaultValue } from "../../V2Table/defaultValue";
import {
    COLOR_COMBINE_TYPES,
    getColorCombine,
} from "../../ColorCombine/defaultValue";

const { PAINT_ACTIVITY_BLOCK, COLOR_COMBINE_BLOCK } = BLOCK_TYPES;

const Editor: React.FC<any> = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    isNewBlock,
    setIsNewBlock,
    worksheet,
    currentBlock,
    hasMentions,
    mentionsList = [],
    disableWrite,
}: any) => {
    const {
        data: {
            [PAINT_ACTIVITY_BLOCK]: { variables },
            children,
        },
    } = block;

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    const [currentSubBlock, setCurrentSubBlock] = useState(
        children?.length > 0 ? 0 : -1,
    );

    const updateCurrentBlock = (val: number) => {
        setCurrentSubBlock(val);
    };

    useEffect(() => {
        updateCurrentBlock(children?.length > 0 ? 0 : -1);
    }, [currentBlock]);

    const editorProps = {
        type: children[currentSubBlock]?.type,
        block: children[currentSubBlock],
        setBlock: (subBlock: any) => {
            let tmpBlock = _.cloneDeep(block);

            tmpBlock = _.set(
                tmpBlock,
                ["data", "children", currentSubBlock],
                subBlock,
            );
            setBlock(tmpBlock);
        },
        isEditor,
        setIsEditor,
        currentBlock: currentSubBlock + 1,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        childBlockCount: children?.length || 0,
        hasMentions,
        mentionsList,
        disableWrite,
        useInputAnswer: true,
    };

    const DragHandle = SortableHandle(() => <MenuOutlined />);

    const SortableItem = SortableElement(({ value, child }) => {
        const actionsProps = {
            deleteBlock: () => {
                updateCurrentBlock(currentSubBlock - 1);
                let tmpBlock = _.cloneDeep(block);
                const tmpOptions = [...children];
                _.pullAt(tmpOptions, currentSubBlock);
                tmpBlock = _.set(tmpBlock, ["data", "children"], tmpOptions);

                setBlock(tmpBlock);
            },
            idx: currentSubBlock,
            addBlockCustom: (type: string, index: number, newBlock: any) => {
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = [...children];
                tmpChildren.splice(index, 0, newBlock);
                tmpBlock = _.set(tmpBlock, ["data", "children"], tmpChildren);
                setBlock(tmpBlock);
                setCurrentSubBlock(index);
            },
            block: child,
        };

        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Badge.Ribbon
                    color={"cyan"}
                    text={value + 1}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            background:
                                currentSubBlock === value ? "#F1F1F1" : "",
                            borderBottom:
                                currentSubBlock === value
                                    ? "3px solid indigo"
                                    : "",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            minWidth: "90px",
                        }}
                        onClick={() => {
                            updateCurrentBlock(value);
                        }}
                    >
                        <span style={{ textTransform: "capitalize" }}>
                            {child?.type?.split("_")?.join(" ") ||
                                `Block ${value + 1}`}
                        </span>

                        {child?.type === COLOR_COMBINE_BLOCK && (
                            <span
                                style={{
                                    textTransform: "capitalize",
                                    color: "#555",
                                    fontSize: "12px",
                                    display: "block",
                                }}
                            >
                                {child?.data[COLOR_COMBINE_BLOCK]?.type
                                    ?.split("_")
                                    ?.join(" ") || ``}
                            </span>
                        )}

                        {currentSubBlock === value && (
                            <div
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    padding: "2px",
                                }}
                            >
                                <DragHandle />
                            </div>
                        )}
                    </Card>
                </Badge.Ribbon>

                {currentSubBlock === value && <Actions {...actionsProps} />}
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={index}
                        child={child}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            children,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(tmpBlock, ["data", "children"], tmpChildren);
        setBlock(tmpBlock);
        updateCurrentBlock(newIndex);
    };

    const blocksMenu = COLOR_COMBINE_TYPES.map(
        ({ label, value }: any, idx: number) => ({
            key: idx,
            label: <span>{label}</span>,
            onClick: () => {
                let tmpBlock = _.cloneDeep(block);

                let newBlock = {};
                switch (value) {
                    case "no_container":
                        newBlock = getColorCombine({
                            type: value,
                            top_input: {
                                is_fixed: true,
                            },
                            bottom_input: {
                                is_fixed: true,
                            },
                        });
                        break;
                    case "one_container":
                        newBlock = getColorCombine({
                            type: value,
                            containers: [
                                {
                                    is_fixed: false,
                                    description: [],
                                },
                            ],
                        });
                        break;
                    case "side_container":
                        newBlock = getColorCombine({
                            type: value,
                            top_input: {
                                is_fixed: true,
                            },
                            bottom_input: {
                                is_fixed: true,
                            },
                            containers: [
                                {
                                    is_fixed: false,
                                    description: [],
                                },
                            ],
                            layout: "column",
                        });
                        break;
                    case "two_container":
                        newBlock = getColorCombine({
                            type: value,
                            containers: [
                                {
                                    is_fixed: true,
                                    description: [],
                                },
                                {
                                    is_fixed: false,
                                    description: [],
                                },
                            ],
                        });
                        break;

                    default:
                        break;
                }
                tmpBlock = _.set(
                    tmpBlock,
                    ["data", "children", children.length],
                    {
                        ...newBlock,
                        order: children.length + 1,
                        tmpId: uuid(),
                    },
                );
                setBlock(tmpBlock);
                updateCurrentBlock(children.length);
            },
        }),
    );

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Form.Item
                        label={"Color 1"}
                        validateStatus={!variables?.color1 ? "error" : ""}
                        help={!variables?.color1 ? "Field cannot be empty" : ""}
                    >
                        <Input
                            type="color"
                            style={{ maxWidth: "200px" }}
                            value={variables?.color1 || "#ffffff"}
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        PAINT_ACTIVITY_BLOCK,
                                        "variables",
                                        "color1",
                                    ],
                                    e.target.value,
                                );
                                setBlock(tmpBlock);
                            }}
                            required
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label={"Color 2"}
                        validateStatus={!variables?.color2 ? "error" : ""}
                        help={!variables?.color2 ? "Field cannot be empty" : ""}
                    >
                        <Input
                            type="color"
                            style={{ maxWidth: "200px" }}
                            value={variables?.color2 || "#ffffff"}
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        PAINT_ACTIVITY_BLOCK,
                                        "variables",
                                        "color2",
                                    ],
                                    e.target.value,
                                );
                                setBlock(tmpBlock);
                            }}
                            required
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    {[
                        "timed",
                        "personalized_learning",
                        "personalized_learning_v2",
                    ].includes(worksheet?.type) && (
                        <div
                            style={{
                                margin: "20px auto",
                            }}
                        >
                            <h3>Duration</h3>
                            <DurationPicker
                                onChange={onDurationChange}
                                initialValue={block?.data?.other?.duration || 0}
                            />
                        </div>
                    )}
                </Col>
            </Row>

            <Divider />
            <h3>Child blocks</h3>
            <div
                style={{
                    display: "flex",
                    gap: "20px",
                    marginBottom: "20px",
                    flexWrap: "wrap",
                }}
            >
                <Dropdown menu={{ items: blocksMenu }} trigger={["click"]}>
                    <Button type="dashed" onClick={(e) => e.preventDefault()}>
                        Add Color Combine Block{" "}
                        <PlusOutlined style={{ fontSize: "15px" }} />
                    </Button>
                </Dropdown>

                <Button
                    type="dashed"
                    onClick={() => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", "children", children.length],
                            {
                                ...getV2DefaultValue({
                                    row_heading: [
                                        {
                                            type: "component",
                                            component: {
                                                type: "svg",
                                                fill: "@@color1@@",
                                            },
                                        },
                                        {
                                            type: "component",
                                            component: {
                                                type: "svg",
                                                fill: "@@color2@@",
                                            },
                                        },
                                    ],
                                }),
                                order: children.length + 1,
                                tmpId: uuid(),
                            },
                        );
                        setBlock(tmpBlock);
                        updateCurrentBlock(children.length);
                    }}
                >
                    {"Add Table (V2) Block"}
                    <PlusOutlined style={{ fontSize: "15px" }} />
                </Button>

                <Button
                    type="dashed"
                    onClick={() => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", "children", children.length],
                            {
                                ...DEFAULT_VALUE_FILL_BLANK,
                                order: children.length + 1,
                                tmpId: uuid(),
                            },
                        );
                        setBlock(tmpBlock);
                        updateCurrentBlock(children.length);
                    }}
                >
                    {"Add Fill Blank Block"}
                    <PlusOutlined style={{ fontSize: "15px" }} />
                </Button>
            </div>
            <SortableList
                items={children}
                onSortEnd={onSortEnd}
                axis="xy"
                useDragHandle
            />
            <hr />
            {currentSubBlock !== -1 && children[currentSubBlock] && (
                <div style={{ marginTop: "20px" }}>
                    <Form.Item label="Clear Previous Blocks?">
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={
                                children[currentSubBlock]?.data?.other
                                    ?.clear_blocks
                            }
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        "children",
                                        currentSubBlock,
                                        "data",
                                        "other",
                                        "clear_blocks",
                                    ],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    <CustomEditor {...editorProps} />
                </div>
            )}
        </div>
    );
};

export default Editor;
