import { isEditorEmpty } from "../../common";
import { BLOCK_TYPES } from "../../common/";

const { TABLE_BLOCK } = BLOCK_TYPES;

const isValidCallbackTable = ({ block }: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            [TABLE_BLOCK]: { text, heading, is_heading, data, type },
        },
    } = block;

    return {
        isValid:
            !isEditorEmpty(text) &&
            (!is_heading ||
                (is_heading &&
                    heading?.length > 0 &&
                    !heading.some((item: any) => isEditorEmpty(item.text)))) &&
            data?.length > 0 &&
            data.map(
                (rows: any) =>
                    rows?.length > 0 && rows.length === heading.length,
            ) &&
            ((type === "select" &&
                !data
                    .map((row: any) =>
                        row.some((cell: any) => isEditorEmpty(cell.text)),
                    )
                    .some((item: boolean) => item === true)) ||
                (type === "input" &&
                    !data
                        .map((row: any) =>
                            row.some(
                                (cell: any) =>
                                    (cell.type === "text" &&
                                        isEditorEmpty(cell.text)) ||
                                    (cell.type === "options" &&
                                        !cell.options?.length),
                            ),
                        )
                        .some((item: boolean) => item === true))),
        error: <></>,
    };
};

export default isValidCallbackTable;
