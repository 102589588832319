import React from "react";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd/lib/button";
import _ from "lodash";

export type IButton = ButtonProps;

const EditIcon: React.FC<any> = (props) => {
    const { isButton = false, size } = props;
    const clickAction = props?.onClick || (() => {});
    let buttonSize = "default";

    if (size === "sm") {
        buttonSize = "small";
    } else if (size === "lg") {
        buttonSize = "large";
    }

    const handleClick = _.debounce(clickAction, 2000);

    return isButton ? (
        <Button
            size={buttonSize}
            icon={<EditOutlined onClick={handleClick} />}
            shape="circle"
            {...props}
        />
    ) : (
        <EditOutlined {...props} onClick={handleClick} />
    );
};

export default EditIcon;
