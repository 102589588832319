import { gql } from "@apollo/client";export default gql`mutation createQuestion($object: question_insert_input = {}) {
    insert_question_one(
        object: $object
        on_conflict: { constraint: question_pkey, update_columns: id }
    ) {
        id
        text
        options
        correct_option
    }
}
`;
