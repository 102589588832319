import { Button, Modal, Typography } from "antd";
import React, { useState } from "react";
import UtmForm from "./UtmForm";

const UtmPopup = (props: any) => {
    const { isSaving } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                style={{
                    margin: "20px 0",
                }}
                type="ghost"
                block
                disabled={isSaving}
                onClick={showModal}
            >
                UTM tracking
            </Button>
            <Modal
                title={
                    <Typography.Title level={4}>UTM tracking</Typography.Title>
                }
                open={isModalOpen}
                onOk={closeForm}
                onCancel={closeForm}
                destroyOnClose
                width={720}
                footer={null}
            >
                <UtmForm {...props} closeForm={closeForm} />
            </Modal>
        </>
    );
};

export default UtmPopup;
