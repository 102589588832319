import { gql } from "@apollo/client";
export default gql`
    mutation createWorksheet($object: worksheet_worksheet_insert_input = {}) {
        insert_worksheet_worksheet_one(
            object: $object
            on_conflict: { constraint: worksheet_pkey, update_columns: id }
        ) {
            id
            status
            banner
            created_at
            title
            type
            slug
            updated_at
            class
            subject
            user_id
            user {
                id
                name
                email
            }
            tags {
                id
                tag_id
                tag {
                    id
                    name
                    value
                }
            }
            other
        }
    }
`;
