import { BLOCK_TYPES, isEditorEmpty } from "../../common";

const { CROSSWORD_BLOCK, DRAG_AND_TAG_BLOCK } = BLOCK_TYPES;

const isValidCallback = ({ block, blockType = CROSSWORD_BLOCK }: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            [blockType]: { grid, conditions = [], options = [] },
            other: { description = [] } = {},
        },
    } = block;

    const checkValiaCondition = () => {
        if (blockType === CROSSWORD_BLOCK) {
            if (!conditions?.length) return false;

            if (
                conditions.some(
                    (item: { computation: any[] }) =>
                        !item?.computation?.length,
                )
            )
                return false;
        }
        if (blockType === DRAG_AND_TAG_BLOCK) {
            if (!options?.length) return false;
        }

        return true;
    };
    return {
        isValid:
            grid?.length > 0 &&
            !grid.some((rows: any) => !rows?.length) &&
            !isEditorEmpty(description) &&
            checkValiaCondition(),
        error: <></>,
    };
};

export default isValidCallback;
