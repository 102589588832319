import { DeleteOutlined } from "@ant-design/icons";
import { captureException } from "@sentry/react";
import { Divider, Input, Modal, Select, Typography } from "antd";
import { Form, Space, Row, Col, Button, message } from "antd";
import React, { useEffect, useState } from "react";

function checkUniqueNames(array: any[]) {
    const uniqueNames = new Set();

    for (let i = 0; i < array.length; i++) {
        const variable_name = array[i].variable_name;
        if (uniqueNames.has(variable_name)) {
            return false; // Two similar variable_names found
        }
        uniqueNames.add(variable_name);
    }

    return true; // All names are unique
}

const InputVariableField = (props: any) => {
    const {
        input_variables = [],
        isModalOpen,
        closeForm,
        onInputVariableUpdate,
        disableWrite,
        block_id,
        block_tmpId,
        variablePaths = [],
    } = props;

    const [isSubmitting, setSubmitting] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [inputVariables, setInpVar] = useState(input_variables);

    useEffect(() => {
        if (
            inputVariables.some(
                (inputVariable: { variable_name: string; path: string[] }) =>
                    !inputVariable?.variable_name?.trim() ||
                    (variablePaths.length > 0 &&
                        inputVariable?.path?.length === 0),
            ) ||
            !checkUniqueNames(inputVariables)
        )
            setDisabled(true);
        else setDisabled(false);
    }, [inputVariables]);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            if (onInputVariableUpdate) {
                const input: {
                    values?: any[];
                    block_id: string;
                    block_tmpId: string;
                } = {
                    block_id,
                    block_tmpId,
                };

                input.values = inputVariables;

                const paths = new Set();

                for (const obj of inputVariables) {
                    const { path } = obj;

                    if (paths.has(path.join("/"))) {
                        message.error(
                            "Two variables cannot be defined for same input!",
                        );
                        setSubmitting(false);
                        return; // Path is not unique
                    }

                    paths.add(path.join("/"));
                }

                const retVal = await onInputVariableUpdate(input);

                if (!retVal) {
                    setSubmitting(false);
                    return;
                }
            }
            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e)
            console.log(e);
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        closeForm();
    };

    return (
        <Modal
            title={
                <Typography.Title level={4}>
                    Add Variable to Input for the Block
                </Typography.Title>
            }
            onCancel={closeForm}
            open={isModalOpen}
            destroyOnClose
            width={600}
            footer={null}
        >
            <Form>
                <Row gutter={20}>
                    {inputVariables.map(
                        (
                            inputVariable: {
                                variable_name: string;
                                path: string[];
                            },
                            i: number,
                        ) => (
                            <>
                                <Col span={22}>
                                    <Form.Item
                                        help={
                                            inputVariable?.variable_name?.trim() &&
                                                input_variables.some(
                                                    (inv: {
                                                        variable_name: string;
                                                    }) =>
                                                        inv?.variable_name ===
                                                        inputVariable?.variable_name,
                                                )
                                                ? "Already saved!"
                                                : ""
                                        }
                                        label="Variable Name (unique)"
                                    >
                                        <Input
                                            disabled={disableWrite}
                                            placeholder="Variable Name (unique)"
                                            value={inputVariable?.variable_name}
                                            onChange={(e: any) => {
                                                let tempVar = {
                                                    ...inputVariable,
                                                };

                                                tempVar.variable_name =
                                                    e.target.value;

                                                const tempinv = [
                                                    ...inputVariables,
                                                ];

                                                tempinv[i] = tempVar;
                                                setInpVar(tempinv);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Button
                                        disabled={disableWrite}
                                        icon={<DeleteOutlined />}
                                        type="primary"
                                        shape="circle"
                                        danger
                                        size="small"
                                        onClick={() => {
                                            const tmpin = [...inputVariables];
                                            _.pullAt(tmpin, i);
                                            setInpVar(tmpin);
                                        }}
                                    />
                                </Col>
                                {variablePaths?.length > 0 && (
                                    <Col span={24}>
                                        <Form.Item label="For">
                                            <Select
                                                disabled={disableWrite}
                                                style={{ width: "100%" }}
                                                placeholder="Variable for"
                                                value={inputVariable?.path?.join(
                                                    "-",
                                                )}
                                                onChange={(value: any) => {
                                                    let tempVar = {
                                                        ...inputVariable,
                                                    };

                                                    tempVar.path =
                                                        value.split("-");

                                                    const tempinv = [
                                                        ...inputVariables,
                                                    ];

                                                    tempinv[i] = tempVar;

                                                    setInpVar(tempinv);
                                                }}
                                                options={variablePaths.map(
                                                    (item: {
                                                        name: any;
                                                        path: any;
                                                    }) => ({
                                                        label: item.name,
                                                        value: item.path.join(
                                                            "-",
                                                        ),
                                                    }),
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>
                                )}

                                <Divider />
                            </>
                        ),
                    )}

                    <Col span={24}>
                        <Button
                            type="primary"
                            onClick={() =>
                                setInpVar([
                                    ...inputVariables,
                                    {
                                        block_id: block_id || block_tmpId,
                                        variable_name: "",
                                        path: variablePaths[0]?.path || [],
                                        is_global: true,
                                    },
                                ])
                            }
                            loading={isSubmitting}
                            disabled={disableWrite}
                        >
                            Add New Input
                        </Button>
                    </Col>
                    <Col style={{ marginTop: "30px" }} span={24}>
                        <Space style={{ float: "right" }}>
                            <Button type="default" onClick={closeForm}>
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleSubmit}
                                loading={isSubmitting}
                                disabled={isDisabled || disableWrite}
                            >
                                Submit
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default InputVariableField;
