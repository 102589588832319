import React, { useState } from "react";
import { Card, Col, Input, Row } from "antd";

import { CollectionsSearchField } from ".";
import { withFormik } from "formik";

import { Select, Form, Button, Divider } from "antd";
import { FieldArray } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderCheckBox,
    RenderField,
    RenderAutoComplete,
    RenderTags,
    RenderDynamicField,
    RenderSelect,
    RenderUpload,
} from "src/components";

const Option = Select.Option;

const subjects = [
    "Maths",
    "Science",
    "Physics",
    "Biology",
    "Chemistry",
    "Environmental Studies",
    "English",
    "Social Studies",
    "Social Science",
    "Hindi",
];

const classNames = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
];

const CollectionsAssignComponent = (props) => {
    const {
        isCollection = false,
        selected,
        onChange,
        dropdownOpen,
        values,
    } = props;
    const [hasChanged, setHasChanged] = useState(false);

    const getWhere = {
        ...(values.class ? { class: { _ilike: values.class } } : {}),
        ...(values.subject ? { subject: { _ilike: values.subject } } : {}),
        ...(values.chapter
            ? { chapter: { _ilike: `%${values.chapter}%` } }
            : {}),
        ...(values.topic ? { topic: { _ilike: `%${values.topic}%` } } : {}),
    };

    return (
        // <Card bodyStyle={{ padding: "8px" }}>
        // <Row gutter={[24, 0]} align="middle">
        // {isCollection && (
        <>
            <Form>
                <Row gutter={24}>
                    <Col lg={24} md={24}>
                        <Field
                            label="Collection Subject"
                            placeholder="Collection Subject"
                            name={"subject"}
                            icon="FontSizeOutlined"
                            component={RenderSelect}
                            type="text"
                            value={values.subject}
                            rules={[{ required: true }]}
                            onChangeCallback={() => setHasChanged(true)}
                        >
                            <Option key={"none1"} value={""}>
                                {"None"}
                            </Option>

                            {subjects.map((subject) => (
                                <Option value={subject}>{subject}</Option>
                            ))}
                        </Field>
                    </Col>

                    <Col lg={24} md={24}>
                        <Field
                            label="Collection Class"
                            placeholder="Collection Class"
                            name={"class"}
                            icon="FontSizeOutlined"
                            component={RenderSelect}
                            type="text"
                            value={values.class}
                            rules={[{ required: true }]}
                            onChangeCallback={() => setHasChanged(true)}
                        >
                            <Option key={"none"} value={""}>
                                {"None"}
                            </Option>

                            {classNames.map((className) => (
                                <Option value={className}>{className}</Option>
                            ))}
                        </Field>
                    </Col>

                    <Col lg={24} md={24}>
                        <Field
                            label="Collection Chapter"
                            placeholder="Collection Chapter"
                            name={"chapter"}
                            icon="FontSizeOutlined"
                            component={RenderField}
                            type="text"
                            value={values.chapter}
                            rules={[{ required: true }]}
                            onChangeCallback={() => setHasChanged(true)}
                        />
                    </Col>

                    <Col lg={24} md={24}>
                        <Field
                            label="Collection Topic"
                            placeholder="Collection Topic"
                            name={"topic"}
                            icon="FontSizeOutlined"
                            component={RenderField}
                            type="text"
                            value={values.topic}
                            rules={[{ required: true }]}
                            onChangeCallback={() => setHasChanged(true)}
                        />
                    </Col>
                </Row>
            </Form>

            <CollectionsSearchField
                passedWhere={getWhere}
                value={selected}
                onChange={onChange}
                hasChanged={hasChanged}
                callback={() => setHasChanged(false)}
                dropdownOpen={dropdownOpen}
            />
        </>

        // )}
        // </Row>
        // </Card>
    );
};

export default withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { set } = props;

        return {
            subject: "",
            class: "",
        };
    },

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
    // validationSchema: (props) => FormSchema,
})(CollectionsAssignComponent);
