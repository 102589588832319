import { BLOCK_TYPES } from "../../common/index";
import { CROSSWORD_CONDITION_COMPUTATIONS } from "../../helpers/constants";
const { CROSSWORD_BLOCK } = BLOCK_TYPES;

export const DEFAULT_CROSSWORD_CELL = {
    type: "empty", // empty / variable / fillable

    // optional and can be null
    content: {
        type: "string", // string / asset
        value: "0",
        asset: "",
    },

    if_fixed: false,

    // optional and can be null
    other: {
        variable: {
            // name: "",
            position: ["bottom", "left"], // [top/bottom, left/right]
            coordinates: [
                [0, 0],
                [0, 0],
            ],
        },
    },

    // optional and can be null
    correct_answer: [],
};

export const GRID_INPUT_OPTION = ({
    type = "string",
    otherFields = {},
} = {}) => ({
    type, // string / asset // input
    value: "1",
    asset: "",
    ...otherFields,
});

export const GRID_INPUT_OPTIONS = ({
    type = "string",
    otherFields = {},
} = {}) => [[GRID_INPUT_OPTION({ type, otherFields })]];

export const FEEDBACK_LABEL_VALUES = [
    { label: "Line 1", value: "line_1" },
    { label: "Line 2", value: "line_2" },
];

export const ANSWER_FEEDBACK_LABEL_VALUES = [
    { label: "Selected", value: "selected" },
    { label: "Not Selected", value: "not_selected" },
    { label: "Always", value: "always" },
    { label: "Same Number", value: "same_number" },
];

export const GLOBAL_FEEDBACK_LABEL_VALUES = [
    { label: "Correct", value: "correct" },
    { label: "Incorrect", value: "incorrect" },
    { label: "Solution", value: "solution" },
];

export const FEEDBACK_OPTIONS = {
    name: FEEDBACK_LABEL_VALUES[0].value,
    value: [],
    audio: "",
    show: ANSWER_FEEDBACK_LABEL_VALUES[2].value, // ANSWER_FEEDBACK_LABEL_VALUES
};

export const FEEDBACK_OPTION = ({
    name = FEEDBACK_LABEL_VALUES[0].value,
    value = [],
    audio = "",
    show = ANSWER_FEEDBACK_LABEL_VALUES[2].value,
}) => ({ name, value, audio, show });

export const DEFAULT_FEEDBACK = {
    index: 0,
    values: [FEEDBACK_OPTIONS],
};

export const DEFAULT_FEEDBACKS = [DEFAULT_FEEDBACK];

export const BLOCK_INPUT = {
    is_enabled: false,
    value: "",
};

export const DIGIT_POSITION_STATES = [
    { label: "Always", value: "always" },
    { label: "Never", value: "never" },
    { label: "Only Answer", value: "only_answer" },
    { label: "All when Answered", value: "all_answer" },
];

export const PUZZLE_OTHER = {
    duration: 0,
    audio: "",
    description: [],
    block_input: BLOCK_INPUT,
    global_feedback: null,
    digit_position_state: "never", // DIGIT_POSITION_STATES
};

export const CROSSWORD_CONDITION = (i: number) => {
    return {
        feedbacks: {
            check: {
                audio: "",
                text: [
                    {
                        children: [
                            {
                                text: `Checking condition ${i}`,
                            },
                        ],
                        type: "p",
                    },
                ],
            },
            correct: {
                audio: "",
                text: [
                    {
                        children: [
                            {
                                text: `Condition ${i}`,
                            },
                        ],
                        type: "p",
                    },
                ],
            },
            incorrect: {
                audio: "",
                text: [
                    {
                        children: [
                            {
                                text: `Condition ${i}`,
                            },
                        ],
                        type: "p",
                    },
                ],
            },
        },
        description: [
            {
                children: [
                    {
                        text: `Temp Condition ${i}`,
                    },
                ],
                type: "p",
            },
        ],
        computation: [
            // DEFAULT_COMPUTATION_SCHEMA
        ],
    };
};

export const DEFAULT_COMPUTATION_SCHEMA = {
    type: CROSSWORD_CONDITION_COMPUTATIONS[0].value,
    variables: [],
    operation_type: "sum",
    match_type: "eq",
    answer: {
        type: "constant", // variable/constant/position
        value: "",
    },
    multiple_answers: [
        // {
        //     type: "constant", // variable/constant/position
        //     value: "",
        // },
    ],
    digit_position: [
        {
            position: "",
            value: "",
        },
    ],
    digit_repeat: [
        {
            repeat_value: "1",
            value: "",
            match_type: "eq",
        },
    ],
    digit_operation: [
        // {
        //     type: "position", // position/constant
        //     value: "",
        // },
    ],
    number_operation: [
        // {
        //     type: "variable", // variable/constant
        //     value: "",
        // },
    ],
    conjunction: "and",
};

const CROSSWORD_DEFAULT_VALUE = {
    type: CROSSWORD_BLOCK,
    data: {
        [CROSSWORD_BLOCK]: {
            options: GRID_INPUT_OPTIONS(),
            grid: [[...Array(3)].map(() => DEFAULT_CROSSWORD_CELL)],
            conditions: [CROSSWORD_CONDITION(1)],
            is_label_visible: false,
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
        other: {
            ...PUZZLE_OTHER,
        },
    },
};

export default CROSSWORD_DEFAULT_VALUE;
