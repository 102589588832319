import renderBlockScratchArrangeList from "./renderBlock";
import renderEditorScratchArrangeList from "./renderEditor";
import isValidCallbackScratchArrangeList from "./isValid";
import SCRATCH_ARRANGE_LIST_DEFAULT_VALUE from "./defaultValue";
import renderSettingsScratchArrangeList from "./renderSettings";
import renderCardScratchArrangeList from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common";

const { SCRATCH_ARRANGE_LIST_BLOCK } = BLOCK_TYPES;

const SCRATCH_ARRANGE_LIST_BLOCK_CONFIG = {
    [SCRATCH_ARRANGE_LIST_BLOCK]: {
        label: "Scratch Arrange List",
        defaultValue: SCRATCH_ARRANGE_LIST_DEFAULT_VALUE,
        renderBlock: renderBlockScratchArrangeList,
        renderEditor: renderEditorScratchArrangeList,
        renderCard: renderCardScratchArrangeList,
        renderSettings: renderSettingsScratchArrangeList,
        addCallback,
        isValidCallback: isValidCallbackScratchArrangeList,
    },
};

export {
    renderBlockScratchArrangeList,
    renderCardScratchArrangeList,
    renderEditorScratchArrangeList,
    renderSettingsScratchArrangeList,
    isValidCallbackScratchArrangeList,
    SCRATCH_ARRANGE_LIST_DEFAULT_VALUE,
    SCRATCH_ARRANGE_LIST_BLOCK_CONFIG,
};
