import { gql } from "@apollo/client";export default gql`mutation updateDocument(
    $id: Int!
    $object: home_explore_document_set_input = {}
) {
    updateDocument: update_home_explore_document_by_pk(
        pk_columns: { id: $id }
        _set: $object
    ) {
        id
        status
        banner
        category
        created_at
        cta_action
        cta_text
        preview_images
        title
        type
        updated_at
        url
    }
}
`;
