import { Button, Form, Input, message, Space } from "antd";
import { useLazyQuery } from "@apollo/client";
import deepdash from "deepdash-es";
import lodash from "lodash";
import {
    CREATE_WORKSHEET_MUTATION,
    INSERT_VERSION,
    INSERT_WORKSHEET_BLOCK_MAP,
    WORKSHEET_BLOCKS_QUERY,
    WORKSHEET_QUERY,
    GET_USERS,
    INSERT_USERS,
} from "../../graphql";
import { useState } from "react";
import {
    getParsedBlocks,
    getWorksheetBlockMapObject,
} from "../edit_enhanced/container";
import {
    getV4ParsedBlocks,
    getV4WorksheetBlockMapObject,
} from "../update/container";
import { gql } from "@apollo/client";
import { graphQLClient, graphQLClientProd } from "src/modules/tab/components/PublishTabToProd";

const GET_GENERIC_DATA = gql`
    query getSubmission($contextId: String) {
        other_generic_data(where: { context_id: { _like: $contextId } }) {
            context_id
            data
            id
            model
        }
    }
`;

const _ = deepdash(lodash);

// export const graphQLClientProd = new GraphQLClient(
//     "http://localhost:8080/v1/graphql",
//     // process.env.REACT_APP_API_ENDPOINT_PROD ,
//     {
//         headers: {
//             "x-hasura-admin-secret": "ultimate",
//             // process.env.REACT_APP_HASURA_ADMIN_SECRET_PROD,
//         },
//     },
// );

const createWorksheet = async (data: any) => {
    const { insert_worksheet_worksheet_one }: any =
        await graphQLClientProd.request(CREATE_WORKSHEET_MUTATION, {
            object: data,
        });
    return insert_worksheet_worksheet_one;
};

const insertVersion = async (data: any) => {
    const { insert_versioning_version_one }: any =
        await graphQLClientProd.request(INSERT_VERSION, {
            object: data,
        });
    return insert_versioning_version_one;
};

const insertWorksheetBlockMap = async (data: any) => {
    const newObject = _.cloneDeep(data);

    if (newObject?.objects?.length > 0) {
        const newObjects = data?.objects.map((item: any) => {
            if (
                item?.block?.data?.type === "image" &&
                item?.block?.data?.other
            ) {
                delete item.block.data.other;
            }
            return item;
        });
        newObject.objects = newObjects;
    }

    const {
        insert_worksheet_worksheet_block_map: { returning },
    }: any = await graphQLClientProd.request(INSERT_WORKSHEET_BLOCK_MAP, {
        ...newObject,
    });
    return returning;
};

const insertPLUserStats = async (data: any) => {
    const {
        insert_personalized_learning_user: { returning },
    }: any = await graphQLClientProd.request(INSERT_USERS, {
        objects: data,
    });
    console.log("Returning", returning);
    return returning?.map((item: any) => item.id);
};

const getGenericData = async (contextId: string) => {
    const { other_generic_data }: any = await graphQLClient.request(
        GET_GENERIC_DATA,
        {
            contextId,
        },
    );
    return other_generic_data;
};

const insertGenericData = async (data: any) => {
    const INSERT_GENERIC_DATA = gql`
        mutation insertDevToProdMap($object: other_generic_data_insert_input!) {
            insert_other_generic_data_one(
                object: $object
                on_conflict: {
                    constraint: generic_data_context_id_key
                    update_columns: data
                }
            ) {
                id
            }
        }
    `;

    const { insert_other_generic_data_one }: any =
        await graphQLClientProd.request(INSERT_GENERIC_DATA, {
            object: data,
        });

    return insert_other_generic_data_one;
};

const GET_USER_COUNT = gql`
    query {
        personalized_learning_user_aggregate {
            aggregate {
                count
            }
        }
    }
`;
const insertPublishedBlocks = async (data: any) => {
    const query = gql`
        mutation InsertWorksheetBlockMap(
            $objects: [worksheet_worksheet_block_map_insert_input!]!
        ) {
            insert_worksheet_worksheet_block_map(
                objects: $objects
                on_conflict: {
                    constraint: worksheet_block_map_pkey
                    update_columns: [order]
                }
            ) {
                affected_rows
                returning {
                    id
                    order
                    block {
                        id
                        reference_id
                        children(order_by: { order: asc }) {
                            id
                            reference_id
                            children(order_by: { order: asc }) {
                                id
                                reference_id
                            }
                        }
                    }
                }
            }
        }
    `;
    return graphQLClientProd.request(query, {
        objects: data,
    });
};

const getWorksheetPublishedBlockMapObject = ({
    blockData,
    worksheetData,
    version = 1,
}: any) => {
    return blockData?.map((blockMap: any, idx: number) => {
        const { block: level } = blockMap;

        const children = level?.children?.length
            ? {
                children: {
                    data: level.children?.map((chunk: any, index: number) => {
                        const gChildren = chunk?.children?.length
                            ? {
                                children: {
                                    data: chunk.children?.map(
                                        (block: any, index: number) => {
                                            return {
                                                reference_id: block.id,
                                                is_published: true,
                                                ..._.omit(block, [
                                                    "id",
                                                    "tmpId",
                                                    "__typename",
                                                    "tags",
                                                    "children",
                                                ]),
                                                order: index + 1,
                                            };
                                        },
                                    ),
                                    on_conflict: {
                                        constraint: "block_pkey",
                                        update_columns: [
                                            "data",
                                            "type",
                                            "order",
                                            "backend",
                                        ],
                                    },
                                },
                            }
                            : {};
                        return {
                            ...gChildren,
                            reference_id: chunk.id,
                            is_published: true,
                            ..._.omit(chunk, [
                                "id",
                                "tmpId",
                                "__typename",
                                "tags",
                                "children",
                            ]),
                            order: index + 1,
                        };
                    }),
                    on_conflict: {
                        constraint: "block_pkey",
                        update_columns: ["data", "type", "order", "backend"],
                    },
                },
            }
            : {};
        return {
            order: idx + 1,
            ...worksheetData,
            block: {
                data: {
                    ...children,
                    reference_id: level.id,
                    is_published: true,
                    ..._.omit(level, [
                        "id",
                        "tmpId",
                        "worksheet_block_map_id",
                        "__typename",
                        "children",
                        "tags",
                    ]),
                    version,
                    reset_submission_version: version,
                },
                on_conflict: {
                    constraint: "block_pkey",
                    update_columns: [
                        "data",
                        "type",
                        "backend",
                        "version",
                        "is_hidden",
                        "reset_submission_version",
                    ],
                },
            },
            is_published: true,
        };
    });
};

const getProdWorksheetBlocks = async (id: number) => {
    const query = gql`
        query WorksheetBlocksQuery($worksheet_id: Int!) {
            worksheetBlockMap: worksheet_worksheet_block_map(
                where: {
                    worksheet_id: { _eq: $worksheet_id }
                    is_published: { _is_null: true }
                }
                order_by: { order: asc }
            ) {
                id
                order
                worksheet_id
                block {
                    id
                    data
                    parent_id
                    type
                    backend
                    is_hidden
                    children(order_by: { order: asc }) {
                        id
                        data
                        type
                        order
                        backend
                        children(order_by: { order: asc }) {
                            id
                            data
                            type
                            order
                            backend
                        }
                    }
                }
            }
        }
    `;

    const variables = {
        worksheet_id: id,
    };
    const data = await graphQLClientProd.request(query, variables);
    return data;
};

const updatePublishedBlocks = async (
    worksheet_id: number,
    worksheetBlockMap: any[],
) => {
    const objects: any = getWorksheetPublishedBlockMapObject({
        blockData: worksheetBlockMap,
        worksheetData: { worksheet_id },
    });

    const {
        insert_worksheet_worksheet_block_map: { returning: response },
    }: any = await insertPublishedBlocks(objects);

    return response;
};

const getWorksheetBlockIds = async (id: any, isDev = true) => {
    const query = gql`
        query WorksheetBlocksQuery($worksheet_id: Int!) {
            worksheetBlockMap: worksheet_worksheet_block_map(
                where: { worksheet_id: { _eq: $worksheet_id } }
                order_by: { order: asc }
            ) {
                id
                block {
                    id
                    children(order_by: { order: asc }) {
                        id
                        children(order_by: { order: asc }) {
                            id
                        }
                    }
                }
            }
        }
    `;

    const variables = {
        worksheet_id: id,
    };
    const data = await (isDev ? graphQLClient : graphQLClientProd).request(
        query,
        variables,
    );
    return data;
};

async function getFigmaDataCount(isDev) {
    const QUERY = gql`
        query getFigmaDataCount {
            other_generic_data_aggregate(
                where: {
                    _or: [
                        { context_id: { _ilike: "figma_screen|%" } }
                        { context_id: { _ilike: "behave_graph_template|%" } }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    `;

    const {
        other_generic_data_aggregate: {
            aggregate: { count },
        },
    }: any = await (isDev ? graphQLClient : graphQLClientProd).request(QUERY);
    return count;
}

async function getFigmaData(skipCount: number) {
    const QUERY = gql`
        query getFigmaData($skip: Int!) {
            other_generic_data(
                where: {
                    _or: [
                        { context_id: { _ilike: "figma_screen|%" } }
                        { context_id: { _ilike: "behave_graph_template|%" } }
                    ]
                }
                limit: 100
                offset: $skip
            ) {
                context_id
                data
                id
                model
            }
        }
    `;
    const { other_generic_data }: any = await graphQLClient.request(QUERY, {
        skip: skipCount,
    });
    return other_generic_data;
}

const GET_VERSION_MODEL_MAP_LATEST_ID = gql`
    query GetVersionModelMapLatestId($id: Int!) {
        versioning_version_model_map(
            where: { model_name: { _eq: "worksheet" }, model_id: { _eq: $id } }
        ) {
            id
            versions(order_by: { index: desc }, limit: 1) {
                id
            }
        }
    }
`;

async function getLatestVersionData(worksheetId: string) {
    const latestVersionData = await graphQLClientProd.request(
        GET_VERSION_MODEL_MAP_LATEST_ID,
        {
            id: parseInt(worksheetId),
        },
    );

    const VERSION_MODEL_MAP_DATA_PATH = [
        "versioning_version_model_map",
        0,
        "versions",
        0,
    ];

    const latest_version_id = _.get(latestVersionData, [
        ...VERSION_MODEL_MAP_DATA_PATH,
        "id",
    ]);

    return latest_version_id;
}

const PushToProd = () => {
    const [worksheetId, setWorksheetId] = useState("");
    const [newId, setNewId] = useState("");
    const [groupId, setGroupId] = useState("");
    const [load, setLoad] = useState(false);

    const [migratingStudents, setMigratingStudents] = useState(false);
    const [migratingFigmaData, setMigratingFigmaData] = useState(false);

    const [getWorksheet] = useLazyQuery(WORKSHEET_QUERY);
    const [getWorksheetBlocks] = useLazyQuery(WORKSHEET_BLOCKS_QUERY);

    const [getUsers] = useLazyQuery(GET_USERS);
    const [getUsersCountQuery] = useLazyQuery(GET_USER_COUNT);
    const getUsersCount = async () => {
        const {
            data: {
                personalized_learning_user_aggregate: {
                    aggregate: { count },
                },
            },
        }: any = await getUsersCountQuery();
        return Number(count);
    };

    const [logs, setLogs] = useState({
        migration: "",
        save_map: "",
        on_migration_complete: {
            expected: "",
            got: "",
        },
    });

    async function getMap(id: string, cnt = 0) {
        if (cnt > 10) {
            console.log("Failed to get map", id);
            return;
        }
        try {
            const { other_generic_data }: any = await graphQLClientProd.request(
                gql`
                    query getDevToProdMap($id: String) {
                        other_generic_data(
                            where: { context_id: { _eq: $id } }
                        ) {
                            data
                        }
                    }
                `,
                {
                    id,
                },
            );

            let map = other_generic_data.length
                ? other_generic_data[0].data
                : {};
            if (map) {
                map = _.isObject(map) ? map : JSON.parse(map);
            }
            return map;
        } catch (error) {
            return getMap(id, cnt + 1);
        }
    }

    async function saveMap(map: any, id: string, cnt = 0) {
        if (cnt > 10) {
            console.log("Failed to save map", id, map);
            return;
        }
        try {
            // let studentToProdMap = await getMap("migrate_user_dev_to_prod_map");
            // studentToProdMap = { ...studentToProdMap, ...map };

            await graphQLClientProd.request(
                gql`
                    mutation insertDevToProdMap($id: String, $data: json) {
                        insert_other_generic_data_one(
                            object: { context_id: $id, data: $data }
                            on_conflict: {
                                constraint: generic_data_context_id_key
                                update_columns: data
                            }
                        ) {
                            data
                        }
                    }
                `,
                {
                    id,
                    data: map,
                },
            );
        } catch (error) {
            return saveMap(map, id, cnt + 1);
        }
    }

    async function onInsertUsers(cnt = 0) {
        if (cnt > 10) return console.log("Failed to migrate users");
        let skipCount = 0;
        const totalUserCount = await getUsersCount();

        setMigratingStudents(true);
        let studentDevToProdMap: any =
            (await getMap("migrate_user_dev_to_prod_map")) || {};

        while (skipCount < totalUserCount) {
            const migration = `Migrating from ${skipCount} users to ${skipCount + 100
                } users`;
            setLogs((prev) => ({ ...prev, migration }));
            try {
                const {
                    data: { personalized_learning_user },
                }: any = await getUsers({
                    variables: { skip: skipCount },
                });

                // console.log("Users", personalized_learning_user);

                await Promise.all(
                    personalized_learning_user.map(async (user: any) => {
                        const { __typename, ...data } = user;
                        const id = data.id;
                        try {
                            if (studentDevToProdMap[id]) {
                                return data;
                            }
                            const ids: any = await insertPLUserStats([data]);
                            console.log("Inserted user", ids);
                            if (ids?.length) {
                                studentDevToProdMap[id] = ids[0];
                            }
                            return data;
                        } catch (e) {
                            console.log("Failed to insert user", data, e);
                            return null;
                        }
                    }),
                );
                const migration = `Successfully migrated from ${skipCount} users to ${skipCount + 100
                    } users`;
                setLogs((logs) => ({
                    ...logs,
                    migration,
                }));
                // console.log("Added user", skipCount);
                skipCount += 100;
            } catch (e) {
                console.log("Failed to get users", e);
            }
        }

        const migration = `Migrated all users`;
        setLogs((logs) => ({
            ...logs,
            migration,
        }));

        setLogs((logs) => ({
            ...logs,
            save_map: "Saving user map",
        }));

        await saveMap(studentDevToProdMap, "migrate_user_dev_to_prod_map");

        setLogs((logs) => ({
            ...logs,
            save_map: "Saved user map",
        }));

        const updatedMap = await getMap("migrate_user_dev_to_prod_map");
        const migratedCount = Object.keys(updatedMap).length;

        setLogs((logs) => ({
            ...logs,
            on_migration_complete: {
                expected: totalUserCount,
                got: String(migratedCount),
            },
        }));

        if (Object.keys(updatedMap).length == totalUserCount) {
            setMigratingStudents(false);
        } else {
            console.log(
                "Expected",
                totalUserCount,
                "Got",
                Object.keys(updatedMap).length,
            );
            await onInsertUsers(cnt + 1);
        }
    }

    const copyWorksheet = async () => {
        if (Number(worksheetId) < 15900) return {};
        const {
            data: { worksheet },
        } = await getWorksheet({
            variables: { id: worksheetId },
        });

        if (!worksheet) return {};

        const {
            data: { worksheetBlockMap },
        } = await getWorksheetBlocks({
            variables: { worksheet_id: worksheetId },
        });

        const worksheetData: any = {
            ..._.omit(worksheet, [
                "user",
                "created_at",
                "updated_at",
                "__typename",
                "tags",
                "id",
                "stats",
            ]),
            slug: worksheet?.slug || `${worksheet.id}`,
            tags: {
                data: worksheet.tags.map(({ tag_id }: any) => ({
                    tag_id,
                })),
            },
            other: {
                ...(worksheet?.other || {}),
                save_count: 1,
                publish_count: 1,
            },
        };

        if (groupId) {
            worksheetData.groups = {
                data: [
                    {
                        group_id: groupId,
                        order: 1,
                    },
                ],
            };
        }
        const { id: newWorksheetId } = await createWorksheet(worksheetData);
        if (worksheet?.type !== "personalized_learning_v4") {
            const parsedBlocks = getParsedBlocks(worksheetBlockMap);
            if (parsedBlocks.length) {
                const blockData =
                    worksheet?.type === "personalized_learning_v3"
                        ? parsedBlocks?.map((v: any) => {
                            let level = _.omit(v, ["id"]);
                            if (level?.children?.length)
                                level.children = level.children?.map(
                                    (w: any) => {
                                        let chunk = _.omit(w, ["id"]);
                                        return chunk;
                                    },
                                );
                            return level;
                        })
                        : _.omitDeep(parsedBlocks, ["id"]);

                const payload = getWorksheetBlockMapObject({
                    blockData,
                    worksheetData: { worksheet_id: newWorksheetId },
                });

                const objects =
                    worksheet?.type === "personalized_learning_v3"
                        ? payload?.map((v: any) => {
                            let block = _.omit(v, ["id"]);
                            return block;
                        })
                        : _.omitDeep(payload, ["id"]);

                await insertWorksheetBlockMap({
                    objects: objects,
                });
            }
        } else {
            const parsedBlocks = getV4ParsedBlocks(worksheetBlockMap);
            if (parsedBlocks.length) {
                const blockData = parsedBlocks?.map((v: any) => {
                    let level = _.omit(v, ["id"]);
                    if (level?.children?.length)
                        level.children = level.children?.map((w: any) => {
                            let chunk = _.omit(w, ["id"]);
                            if (chunk?.children?.length)
                                chunk.children = chunk.children?.map(
                                    (w: any) => {
                                        let block = _.omit(w, ["id"]);
                                        return block;
                                    },
                                );
                            return chunk;
                        });
                    return level;
                });

                const payload = getV4WorksheetBlockMapObject({
                    blockData,
                    worksheetData: { worksheet_id: newWorksheetId },
                });

                const objects = payload?.map((v: any) => {
                    let block = _.omit(v, ["id"]);
                    return block;
                });

                await insertWorksheetBlockMap({ objects: objects });
            }
        }

        if (worksheet?.type === "personalized_learning_v4") {
            const { worksheetBlockMap = [] }: any =
                await getProdWorksheetBlocks(newWorksheetId);

            const blockData = getV4ParsedBlocks(worksheetBlockMap);
            await Promise.all([
                insertVersion({
                    version_model_map: {
                        data: {
                            model_name: "worksheet",
                            model_id: newWorksheetId,
                        },
                    },
                    index: 1,
                    data: blockData,
                }),
                updatePublishedBlocks(newWorksheetId, worksheetBlockMap),
            ]);

            return { devId: worksheetId, prodId: newWorksheetId };
        }

        const { worksheetBlockMap: worksheetBlockMapProd = [] }: any =
            await getProdWorksheetBlocks(newWorksheetId);
        const parsedBlocks = getParsedBlocks(worksheetBlockMapProd);
        // create version for new worksheet_id
        let versionModelMapData: any = {
            version_model_map: {
                data: {
                    model_name: "worksheet",
                    model_id: newWorksheetId,
                },
            },
        };

        const version_model_map_id = (
            await insertVersion({
                ...versionModelMapData,
                index: 0,
                data: parsedBlocks,
            })
        ).version_model_map_id;

        versionModelMapData = { version_model_map_id };

        await insertVersion({
            ...versionModelMapData,
            index: 1,
            data: parsedBlocks,
        });

        return { devId: worksheetId, prodId: newWorksheetId };
    };

    async function replaceUserSubmission(devId: string, prodId: string) {
        function getWorksheetMap(devBlockIds: any, prodBlockIds: any) {
            let map: any = {};

            for (let i = 0; i < devBlockIds.length; i++) {
                const block = devBlockIds[i].block;
                const prodBlock = prodBlockIds[i].block;
                console.log("Block", block, prodBlock);
                const devBlockId = block.id;
                const prodBlockId = prodBlock.id;

                map[devBlockId] = prodBlockId;

                if (block.children?.length) {
                    for (let i = 0; i < block.children.length; i++) {
                        const devChunk = block.children[i];
                        const prodChunk = prodBlock.children[i];

                        const devChunkId = devChunk.id;
                        const prodChunkId = prodChunk.id;

                        map[devChunkId] = prodChunkId;

                        if (devChunk.children?.length) {
                            for (let i = 0; i < devChunk.children.length; i++) {
                                const devBlock = devChunk.children[i];
                                const prodBlock = prodChunk.children[i];

                                const devBlockId = devBlock.id;
                                const prodBlockId = prodBlock.id;

                                map[devBlockId] = prodBlockId;
                            }
                        }
                    }
                }
            }
            return map;
        }

        function deepCloneWithReplacement(item, idsMap, ignoreKeys) {
            // Helper function to check if a value is an object (including arrays, excluding null)
            const isObject = (val) => val && typeof val === "object";

            // Base case: if the item is not an object, return it directly (covers strings, numbers, etc.)
            if (!isObject(item)) {
                // Check if the value should be replaced
                return idsMap[item] || item;
            }

            // If the item is an array, iterate over each element, apply the function recursively, and return the array
            if (Array.isArray(item)) {
                return item.map((element) =>
                    deepCloneWithReplacement(element, idsMap, ignoreKeys),
                );
            }

            // If the item is an object, create a new object
            const clonedObject: any = {};
            for (const key in item) {
                if (ignoreKeys.includes(key)) {
                    clonedObject[key] = item[key];
                    continue;
                }
                const newKey = idsMap[key] || key; // Replace key with mapping if exists
                // Apply recursively to each property, and also replace values if they match keys in idsMap
                const value = item[key];
                clonedObject[newKey] = isObject(value)
                    ? deepCloneWithReplacement(value, idsMap, ignoreKeys)
                    : idsMap[value] || value;
            }

            return clonedObject;
        }

        const { worksheetBlockMap: devBlockIds }: any =
            await getWorksheetBlockIds(devId, true);
        const { worksheetBlockMap: prodBlockIds }: any =
            await getWorksheetBlockIds(prodId, false);

        console.log("Dev Block Ids", devBlockIds, prodBlockIds);

        const devUsersMap = await getMap("migrate_user_dev_to_prod_map");
        const devWorksheetMap = getWorksheetMap(devBlockIds, prodBlockIds);

        console.log("Dev Worksheet Map", devWorksheetMap);

        // const userSbmission =
        const submissionData = await getGenericData(
            `personalizedLearning|getNextStateV2|${devId}|%`,
        );
        console.log("Worksheet Data", submissionData);

        const latest_version_id = await getLatestVersionData(prodId);

        const updatedSubmissionData = submissionData.map((item: any) => {
            const contextIdSplits = item.context_id?.split("|");
            const devUserId = contextIdSplits.pop();
            contextIdSplits.pop();
            contextIdSplits.push(prodId);
            const prodUserId = devUsersMap[devUserId];
            contextIdSplits.push(prodUserId);

            const contextId = contextIdSplits.join("|");

            const ignoreKeys = [
                "duration",
                "global_context_variables",
                "answers",
                "lives_lost",
                "is_correct",
            ];
            const updatedItem = deepCloneWithReplacement(
                item,
                devWorksheetMap,
                ignoreKeys,
            );
            updatedItem.context_id = contextId;

            console.log("Updated Item", item, updatedItem);
            if (updatedItem.data?.version_id) {
                updatedItem.data.version_id = latest_version_id;
            }

            if (updatedItem.data?.progressionStageData?.length) {
                updatedItem.data.progressionStageData =
                    updatedItem.data.progressionStageData.map((stage: any) => {
                        if (stage.version_id) {
                            stage.version_id = 1;
                        }
                        return stage;
                    });
            }
            return updatedItem;
        });

        await Promise.all(
            updatedSubmissionData.map(async (item: any) => {
                const { id, __typename, ...data } = item;
                const hasUploaded = await insertGenericData(data);
                if (hasUploaded) {
                    console.log("Uploaded", data);
                }
                return id;
            }),
        );
    }

    async function onMigrateFigmaData(cnt = 0) {
        if (cnt > 10) {
            console.log("Failed to migrate figma data");
            setMigratingFigmaData(false);
            message.error("Failed to migrate figma data");
            return;
        }
        setMigratingFigmaData(true);
        const figmaDataCount = await getFigmaDataCount(true);
        let skipCount = 0;
        while (skipCount < figmaDataCount) {
            const figmaData = await getFigmaData(skipCount);
            try {
                // eslint-disable-next-line no-loop-func
                await Promise.all(
                    figmaData.map(async (item: any) => {
                        const { id, __typename, ...data } = item;
                        const hasUploaded = await insertGenericData(data);
                        if (hasUploaded) {
                            console.log("Uploaded", id);
                        }
                        return id;
                    }),
                );
                skipCount += 100;

                // console.log("Figma Data", figmaData);
            } catch (e) {
                console.log("Failed to get figma data", e);
            }
        }
        let prodFigmaDataCount = await getFigmaDataCount(false);
        console.log(
            "Prod Figma Data Count",
            prodFigmaDataCount,
            figmaDataCount,
        );

        if (prodFigmaDataCount == figmaDataCount) {
            console.log("Migration complete");
            message.success("FigmaData Migration complete");
            setMigratingFigmaData(false);
        } else {
            console.log("Migration failed\nRetrying...");
            onMigrateFigmaData(cnt + 1);
        }
    }

    return (
        <div
            style={{
                marginTop: "40px",
                padding: "40px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
            }}
        >
            <h2>Add data to prod</h2>
            <br />
            <h3>New ID - {newId}</h3>
            <Space>
                <Form.Item label="Dev Worksheet Id">
                    <Input
                        placeholder="Add Worksheet id"
                        onChange={(e) => setWorksheetId(e.target.value)}
                        value={worksheetId}
                        type="number"
                    />
                </Form.Item>
                <Form.Item label="Prod Group Id">
                    <Input
                        placeholder="Add Group id"
                        onChange={(e) => setGroupId(e.target.value)}
                        value={groupId}
                        type="number"
                    />
                </Form.Item>
            </Space>
            <Button
                loading={load}
                type="primary"
                onClick={async () => {
                    setNewId("");
                    setLoad(true);
                    let worksheetMap = await getMap(
                        "migrate_worksheet_dev_to_prod_map",
                    );
                    if (worksheetMap[worksheetId]) {
                        setLoad(false);
                        message.error("Already Added!");
                        return;
                    }
                    const { devId, prodId }: any = await copyWorksheet();
                    if (devId) {
                        worksheetMap[devId] = prodId;
                        console.log("Worksheet Map", worksheetMap);
                        await saveMap(
                            worksheetMap,
                            "migrate_worksheet_dev_to_prod_map",
                        );
                        // replace user submission
                        await replaceUserSubmission(devId, prodId);
                        message.success(`Added ${prodId} for ${devId}!`);
                        setNewId(prodId);
                    } else {
                        message.error("Failed!");
                    }
                    setLoad(false);
                }}
            >
                Add Worksheet
            </Button>
            <Button
                type="primary"
                onClick={() => onInsertUsers()}
                loading={migratingStudents}
                disabled={migratingStudents}
            >
                Migrate Users From Dev to Prod
            </Button>
            <Button
                type="primary"
                onClick={() => onMigrateFigmaData()}
                loading={migratingFigmaData}
                disabled={migratingFigmaData}
            >
                Migrate Figma Data
            </Button>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 20,
                    gap: 10,
                }}
            >
                <h3>Logs</h3>
                <p>{logs.migration}</p>
                <p>{logs.save_map}</p>
                <p>
                    Expected: {logs.on_migration_complete.expected} Got:{" "}
                    {logs.on_migration_complete.got}
                </p>
            </div>
        </div>
    );
};

export default PushToProd;
