const getSelectedAndMergedCells = (selectedCells, tableCells) => {
    const cellSet = new Set(); // Use a set to store unique cells

    // Helper function to add a coordinate to the set
    const addCellToSet = (row, col) => {
        const coordinate = JSON.stringify([row, col]); // Convert array to string to maintain uniqueness in Set
        cellSet.add(coordinate);
    };

    // Process each selected cell
    selectedCells.forEach(([row, col]) => {
        addCellToSet(row, col); // Add the selected cell itself

        // Check if the cell has mergedCells in tableCells
        const mergedCells = tableCells[row]?.[col]?.merge?.mergedCells;
        if (mergedCells) {
            // Add all merged cells
            mergedCells.forEach(([mergedRow, mergedCol]) => {
                addCellToSet(mergedRow, mergedCol);
            });
        }
    });

    // Convert the set of JSON strings back to an array of coordinates
    const result = Array.from(cellSet).map((cell) => JSON.parse(cell));

    // Sort the result by row first, then by column
    return result.sort(([rowA, colA], [rowB, colB]) => {
        if (rowA === rowB) {
            return colA - colB; // Sort by column if rows are the same
        }
        return rowA - rowB; // Otherwise, sort by row
    });
};

export default getSelectedAndMergedCells;
