import { CheckOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons";
import {
    Button,
    Divider,
    Form,
    Input,
    Select,
    Switch,
    Table,
    message,
} from "antd";
import { cloneDeep, isObject, pullAt } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BlockPicker } from "react-color";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
    CopyIcon,
    DeleteIcon,
    RenderUpload,
    SlateEditor,
} from "src/components";
import { replaceCDNUrl } from "src/modules/worksheet/components/blocks/helpers";
import { v4 as uuid } from "uuid";

const splitAndUpperCase = (str: string) =>
    str
        ?.split("_")
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(" ");

const defaultItem = {
    type: "button_option",
    props: {
        icon: {
            type: "FILL",
            value: {
                type: "IMAGE",
                asset: "https://sets-gamify-assets.s3.ap-south-1.amazonaws.com/dev/home-explore/document/1711339773079.png",
            },
        },
        text: {
            type: "RICH_TEXT",
            value: [
                {
                    type: "p",
                    children: [
                        {
                            text: "Reset",
                        },
                    ],
                },
            ],
        },
        events: {
            type: "EVENTS",
            value: '[{"event":"run_compute_function","index":0, "onclick": "function updateTableData(params){ console.log(\'this is params\',params); const {initialTableData} = params; return initialTableData; }"}]',
            schema: {
                event: "",
                index: 0,
            },
            options: [
                {
                    label: "Reset Table Data",
                    value: "reset_table_data",
                },
            ],
        },
        iconVisible: {
            type: "NUMBER",
            value: "1",
        },
        is_editable: {
            type: "BOOLEAN",
            value: true,
        },
        textVisible: {
            type: "NUMBER",
            value: "1",
        },
        input_background: {
            type: "FILL",
            value: {
                type: "SOLID",
                color: "#F9F8F8",
            },
        },
    },
    genericId: "",
};

const ButtonsTable: React.FC = ({
    setBlock,
    disableWrite,
    currentEditor,
    setCurrentEditor,
    hasMentions,
    mentionsList = [],
    value: items = [],
    schema,
}: any) => {
    const [tempConfig, setTempConfig] = useState("");
    const [firstTimeSet, setFirstTimeSet] = useState(false);

    const componentsSchema =
        typeof schema === "string" ? JSON.parse(schema) : schema;

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            let tmpBlock = cloneDeep(items);
            const dragRow = tmpBlock[dragIndex];
            tmpBlock.splice(dragIndex, 1);
            tmpBlock.splice(hoverIndex, 0, dragRow);
            setBlock(tmpBlock);
        },
        [items],
    );

    useEffect(() => {
        if (!firstTimeSet && items?.length === 0) {
            setBlock([
                {
                    ...defaultItem,
                    genericId: uuid(),
                },
            ]);
            setFirstTimeSet(true);
        }
    }, [items]);

    return (
        <div
            style={{
                padding: "20px",
            }}
        >
            <DndProvider backend={HTML5Backend}>
                <Table
                    scroll={{ y: "60vh", x: 1500 }}
                    size="small"
                    pagination={false}
                    dataSource={items?.map((v, i) => {
                        return {
                            key: i,
                            text: v?.props?.text?.value,
                            textVisible: v?.props.textVisible?.value,
                            background_type:
                                v?.props.input_background?.value?.type ||
                                "SOLID",
                            input_background: v?.props.input_background?.value,
                            icon: v?.props.icon?.value,
                            iconVisible: v?.props.iconVisible?.value,
                            events: v?.props.events?.value,
                            is_editable: v?.props.is_editable?.value,
                        };
                    })}
                    columns={[
                        {
                            title: "Index",
                            dataIndex: "key",
                            width: 60,
                            render: (v) => (
                                <>
                                    {v + 1} <MenuOutlined />
                                </>
                            ),
                        },
                        {
                            title: "Text",
                            dataIndex: "text",
                            render: (v, data) => {
                                return (
                                    <div
                                        style={{
                                            minWidth: "150px",
                                        }}
                                    >
                                        <SlateEditor
                                            disabled={disableWrite}
                                            hasMentions={hasMentions}
                                            mentionsList={mentionsList}
                                            id={`${data.key}_tag_edit`}
                                            onChange={(value: any) => {
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[
                                                    data?.key
                                                ].props.text.value = value;
                                                setBlock(tmpBlock);
                                            }}
                                            isFocused={
                                                currentEditor ===
                                                `${data.key}_tag_edit`
                                            }
                                            setEditor={(id: string) =>
                                                setCurrentEditor(id)
                                            }
                                            value={v}
                                            required={false}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            title: "Bg Type",
                            width: 140,
                            dataIndex: "background_type",
                            render: (v, data) => {
                                return (
                                    <Select
                                        style={{
                                            width: 120,
                                        }}
                                        disabled={disableWrite}
                                        defaultValue={v}
                                        placeholder="Background Type"
                                        onChange={(value: any) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[
                                                data?.key
                                            ].props.input_background.value.type =
                                                value;
                                            setBlock(tmpBlock);
                                        }}
                                        options={[
                                            {
                                                value: "SOLID",
                                                label: "Solid Color",
                                            },
                                            {
                                                value: "IMAGE",
                                                label: "Image",
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                        {
                            title: "Background",
                            dataIndex: "input_background",
                            render: (v, data) => {
                                return (
                                    <>
                                        {v?.type === "SOLID" && (
                                            <BlockPicker
                                                colors={[]}
                                                color={v?.color}
                                                onChangeComplete={(
                                                    color: any,
                                                ) => {
                                                    if (disableWrite) return;
                                                    const value = color.hex;
                                                    let tmpBlock =
                                                        cloneDeep(items);
                                                    tmpBlock[
                                                        data?.key
                                                    ].props.input_background.value.color =
                                                        value;
                                                    setBlock(tmpBlock);
                                                }}
                                                triangle="hide"
                                            />
                                        )}
                                        {["IMAGE", "GIF"].includes(v?.type) && (
                                            <>
                                                <RenderUpload
                                                    singleUpload={true}
                                                    disabled={disableWrite}
                                                    addExtension={true}
                                                    path="home-explore/document/"
                                                    onChangeCustom={(e) => {
                                                        const value = e?.target
                                                            ?.value
                                                            ? replaceCDNUrl(
                                                                  e?.target
                                                                      ?.value,
                                                                  e?.target
                                                                      ?.bucket,
                                                              )
                                                            : "";
                                                        let tmpBlock =
                                                            cloneDeep(items);
                                                        tmpBlock[
                                                            data?.key
                                                        ].props.input_background.value.asset =
                                                            value;
                                                        setBlock(tmpBlock);
                                                    }}
                                                    label="upload the file"
                                                    value={v?.asset}
                                                />
                                                <Input
                                                    placeholder="Add value here"
                                                    value={v?.asset}
                                                    disabled={disableWrite}
                                                    onChange={(e) => {
                                                        const value =
                                                            e.target.value;
                                                        let tmpBlock =
                                                            cloneDeep(items);
                                                        tmpBlock[
                                                            data?.key
                                                        ].props.input_background.value.asset =
                                                            value;
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            </>
                                        )}
                                    </>
                                );
                            },
                        },
                        {
                            title: "Icon",
                            dataIndex: "icon",
                            render: (v, data) => {
                                return (
                                    <>
                                        {v?.type === "SOLID" && (
                                            <BlockPicker
                                                colors={[]}
                                                color={v?.color}
                                                onChangeComplete={(
                                                    color: any,
                                                ) => {
                                                    if (disableWrite) return;
                                                    const value = color.hex;
                                                    let tmpBlock =
                                                        cloneDeep(items);
                                                    tmpBlock[
                                                        data?.key
                                                    ].props.icon.value.color =
                                                        value;
                                                    setBlock(tmpBlock);
                                                }}
                                                triangle="hide"
                                            />
                                        )}
                                        {["IMAGE", "GIF"].includes(v?.type) && (
                                            <>
                                                <RenderUpload
                                                    singleUpload={true}
                                                    disabled={disableWrite}
                                                    addExtension={true}
                                                    path="home-explore/document/"
                                                    onChangeCustom={(e) => {
                                                        const value = e?.target
                                                            ?.value
                                                            ? replaceCDNUrl(
                                                                  e?.target
                                                                      ?.value,
                                                                  e?.target
                                                                      ?.bucket,
                                                              )
                                                            : "";
                                                        let tmpBlock =
                                                            cloneDeep(items);
                                                        tmpBlock[
                                                            data?.key
                                                        ].props.icon.value.asset =
                                                            value;
                                                        setBlock(tmpBlock);
                                                    }}
                                                    label="upload the file"
                                                    value={v?.asset}
                                                />
                                                <Input
                                                    placeholder="Add value here"
                                                    value={v?.asset}
                                                    disabled={disableWrite}
                                                    onChange={(e) => {
                                                        const value =
                                                            e.target.value;
                                                        let tmpBlock =
                                                            cloneDeep(items);
                                                        tmpBlock[
                                                            data?.key
                                                        ].props.icon.value.asset =
                                                            value;
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            </>
                                        )}
                                    </>
                                );
                            },
                        },
                        {
                            title: "Icon visible?",
                            dataIndex: "iconVisible",
                            width: 80,
                            render: (v, data) => {
                                return (
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={!!Number(v)}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[
                                                data?.key
                                            ].props.iconVisible.value = value
                                                ? 1
                                                : 0;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: "Events?",
                            dataIndex: "events",
                            width: 250,
                            render: (v, data) => {
                                return (
                                    <Input.TextArea
                                        rows={3}
                                        disabled={disableWrite}
                                        value={
                                            isObject(v) ? JSON.stringify(v) : v
                                        }
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[
                                                data?.key
                                            ].props.events.value = value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: "Is Active?",
                            dataIndex: "is_editable",
                            width: 80,
                            render: (v, data) => {
                                return (
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={v}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[
                                                data?.key
                                            ].props.is_editable.value = value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: "Button visible?",
                            dataIndex: "textVisible",
                            width: 80,
                            render: (v, data) => {
                                return (
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={!!Number(v)}
                                        onChange={(value) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[
                                                data?.key
                                            ].props.textVisible.value = value
                                                ? 1
                                                : 0;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: "Actions",
                            key: "operation",
                            fixed: "right",
                            width: 100,
                            render: (v, data) => (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                    }}
                                >
                                    <CopyIcon
                                        disabled={disableWrite}
                                        isButton={true}
                                        onClick={() => {
                                            let tmpItems = cloneDeep(items);
                                            tmpItems.splice(data.key + 1, 0, {
                                                ...cloneDeep(
                                                    tmpItems[data.key],
                                                ),
                                                genericId: uuid(),
                                            });
                                            setBlock(tmpItems);
                                            message.info(`Tag duplicated!`);
                                        }}
                                    />
                                    <DeleteIcon
                                        title="Delete this?"
                                        disabled={disableWrite}
                                        onConfirm={() => {
                                            let tmpBlock = cloneDeep(items);
                                            pullAt(tmpBlock, data.key);
                                            setBlock(tmpBlock);
                                            message.success(
                                                "successfully removed",
                                            );
                                        }}
                                    />
                                </div>
                            ),
                        },
                    ]}
                    rowKey="index"
                    bordered
                    rowClassName="item-rows"
                    footer={() =>
                        disableWrite ? (
                            <></>
                        ) : (
                            <>
                                {componentsSchema.map(
                                    (item: any, key: number) => (
                                        <Button
                                            key={key}
                                            ghost
                                            type="primary"
                                            onClick={() => {
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock.push({
                                                    ...item,
                                                    genericId: uuid(),
                                                });
                                                setBlock(tmpBlock);
                                            }}
                                        >
                                            Add {splitAndUpperCase(item.type)}
                                        </Button>
                                    ),
                                )}
                                {!items?.length && (
                                    <span
                                        style={{
                                            color: "red",
                                            marginLeft: "10px",
                                            fontSize: "16px",
                                        }}
                                    >
                                        No Tags Added
                                    </span>
                                )}
                            </>
                        )
                    }
                    components={{
                        body: {
                            row: DraggableRow,
                        },
                    }}
                    onRow={(_, index) => ({
                        index,
                        moveRow,
                        disableWrite,
                    })}
                />
            </DndProvider>
            {!disableWrite && (
                <>
                    <Divider />
                    {items?.length > 0 && (
                        <>
                            <Button
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied Tags config to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(items),
                                    );
                                }}
                            >
                                Copy this components Config to Clipboard
                            </Button>
                            <Divider />
                        </>
                    )}
                    <Form.Item label={"Paste tags config here"}>
                        <Input.TextArea
                            disabled={disableWrite}
                            value={tempConfig}
                            placeholder="Paste Config here"
                            onChange={(e) => setTempConfig(e.target.value)}
                        />
                        <Button
                            disabled={disableWrite}
                            style={{ marginTop: "10px" }}
                            type="primary"
                            onClick={() => {
                                try {
                                    const parsedJson = JSON.parse(tempConfig);
                                    if (!Array.isArray(parsedJson)) {
                                        message.error("Error in JSON!");
                                        return;
                                    }
                                    setBlock(parsedJson);
                                    setTempConfig("");
                                    message.info("Updated!");
                                } catch (e) {
                                    // captureException(e)
                                    message.error("Error in JSON!");
                                }
                            }}
                        >
                            Update config
                        </Button>
                    </Form.Item>
                </>
            )}
        </div>
    );
};

const DraggableRow = ({ index, moveRow, disableWrite, ...restProps }) => {
    const ref = useRef();
    const [, drop] = useDrop({
        accept: "row",
        drop(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            // Position checking logic (as in previous example) can be added here for better UX
            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [, drag] = useDrag({
        type: "row",
        item: { index },
    });

    drag(drop(ref));
    return <tr ref={disableWrite ? null : ref} {...restProps} />;
};

export default ButtonsTable;
