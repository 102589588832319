import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";

const renderCardV2MCQ = (props: any, isMCQ = true) => {
    const {
        block: {
            data: { other: { description = [] } = {} },
        },
    } = props;
    const tmp = description.find(({ type }: any) => type === "p")?.children[0]
        .text;
    const displayText = tmp
        ? tmp.slice(0, tmp.length > 10 ? 10 : tmp.length)
        : "...";
    return (
        <BlockCard {...props}>
            <div>{displayText}</div>
            <div style={{ fontSize: "11px", fontWeight: "bold" }}>
                {isMCQ ? `MCQ (V2) Block` : "Reordering Question (V2) Block"}
            </div>
        </BlockCard>
    );
};

export default renderCardV2MCQ;
