import React from "react";

const renderSettings: React.FC<any> = ({ block }: any) => {
    // const {
    //     id,
    //     tmpId,
    //     data: {
    //         [blockType]: { title },
    //     },
    //     children,
    // } = block;

    return <div></div>;
};

export default renderSettings;
