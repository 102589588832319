import { ApolloProvider } from "@apollo/client";
import { StyledThemeProvider } from "src/definitions/styled-components";
import { initializeApollo } from "src/services/graphql";
import "antd/dist/antd.css";
import "src/styles/katex/katex.min.css";
import "src/styles/global.css";

import "react-phone-input-2/lib/style.css";
import React, { useEffect } from "react";
import { initCASL } from "src/services/casl/initCASL";
import LoadingPage from "src/components/LoadingPage";
import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
    Navigate,
} from "react-router-dom";
import { ALL_ROUTES } from "./modules";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import Test from "./components/HighlightWrapper/Test";
import { setUser } from "@sentry/react";
const PrivateRoute = () => {
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
    if (isAuthenticated) {
        return <Outlet />;
    } else {
        if (!isLoading)
            localStorage.setItem(
                "previousUrl",
                window?.location?.pathname + (window?.location?.search || ""),
            );
        return <Navigate to={loginWithRedirect()} />;
    }
};

export const ComponentWithCASL = ({ children }) => {
    const { user, isLoading } = useAuth0();
    useEffect(() => {
        if (!isLoading && user) {
            initCASL(user)
            setUser(user)
        }
    }, [isLoading]);

    if (isLoading) return <LoadingPage />;

    return <>{children}</>;
};

function MyApp({ Component, pageProps }): JSX.Element {
    const apolloClient = initializeApollo();

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_ISSUER_BASE_URL}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: window.location.origin,
            }}
        >
            <StyledThemeProvider>
                <ApolloProvider client={apolloClient}>
                    <ComponentWithCASL>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/test" element={<Test />} />
                                <Route element={<PrivateRoute />}>
                                    {ALL_ROUTES}
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </ComponentWithCASL>
                </ApolloProvider>
            </StyledThemeProvider>
        </Auth0Provider>
    );
}

export default MyApp;
