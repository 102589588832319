import { FunctionComponent } from "react";

import {
    getLastStringFromUrl,
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import {
    TOPIC_QUERY,
    TOPICS_QUERY,
    TOPIC_AGGREGATE_QUERY,
} from "../../graphql";

export const withHookForTopics = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { pagination, where } = props;
        const chapter_id = parseInt(getLastStringParamFromUrl());

        const queryDocumentResult = useHasuraSubscriptionWithCache(
            TOPICS_QUERY,
            {
                variables: {
                    ...pagination,
                    where,
                    parent_id: chapter_id,
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "topics", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingTopics: loading,
                    topics: items,
                    subscribeToMore,
                    updateQuery,
                    loadTopicsData: loadItemsData,
                    refetchTopics: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateTopic = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { where } = props;
        const chapter_id = parseInt(getLastStringParamFromUrl());

        const queryDocumentResult = useHasuraSubscriptionWithCache(
            TOPIC_AGGREGATE_QUERY,
            {
                variables: { where, parent_id: chapter_id },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "topicAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    topicAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForTopic = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            TOPIC_QUERY,
            {
                variables: {
                    id: getLastStringFromUrl(),
                },
            },
        );

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingTopic: loading,
                    topic: data?.topic,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
