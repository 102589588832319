import React from "react";
import { Row, Col, Form, Button, Divider } from "antd";
import { withFormik, FieldArray } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderCheckBox,
    RenderField,
    RenderAutoComplete,
    RenderTags,
    RenderDynamicField,
    RenderSelect,
    RenderUpload,
} from "src/components";
import { Select } from "antd";
import { useAuth0 } from "@auth0/auth0-react";

const Option = Select.Option;

const subjects = [
    "Maths",
    "Science",
    "Physics",
    "Biology",
    "Chemistry",
    "Environmental Studies",
    "English",
    "Social Studies",
    "Social Science",
    "Hindi",
];

const classNames = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
];

import * as yup from "yup";
import { TagsAssignComponent } from "src/modules/book/components";

export const FormSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    chapter: yup.string().required("This field is required"),
    topic: yup.string().required("This field is required"),
    type: yup.string().required("This field is required"),
    format: yup.string().required("This field is required"),
    class: yup.string().required("This field is required"),
    subject: yup.string().required("This field is required"),
});

const CollectionForm = (props) => {
    const {
        onFinishFailed,
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
    } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Collection Title"
                        placeholder="Collection Title"
                        name={"title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection Description"
                        placeholder="Collection Description"
                        name={"description"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.description}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection Cover"
                        placeholder="Collection Cover"
                        name={"cover"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.cover}
                        rules={[{ required: true }]}
                        path="collection/"
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection Subject"
                        placeholder="Collection Subject"
                        name={"subject"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.subject}
                        rules={[{ required: true }]}
                    >
                        {subjects.map((subject) => (
                            <Option value={subject}>{subject}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection Class"
                        placeholder="Collection Class"
                        name={"class"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.class}
                        rules={[{ required: true }]}
                    >
                        {classNames.map((className) => (
                            <Option value={className}>{className}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection Chapter"
                        placeholder="Collection Chapter"
                        name={"chapter"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.chapter}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection Topic"
                        placeholder="Collection Topic"
                        name={"topic"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.topic}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection Type"
                        placeholder="Collection Type"
                        name={"type"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.type}
                        rules={[{ required: true }]}
                    >
                        <Option value="understanding">Understanding</Option>
                        <Option value="practice">Practice</Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection format"
                        placeholder="Collection format"
                        name={"format"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.format}
                        rules={[{ required: true }]}
                    >
                        <Option value="rapid_quiz">Rapid Quiz</Option>
                        <Option value="concept_quiz">Concept Quiz</Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection Duration"
                        placeholder="Collection Duration"
                        name={"duration"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.duration}
                        rules={[{ required: true }]}
                        disabled={true}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection Attempts"
                        placeholder="Collection Attempts"
                        name={"attempts"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.attempts}
                        rules={[{ required: true }]}
                        disabled={true}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection Enable Memes"
                        placeholder="Collection Enable Memes"
                        name={"show_memes"}
                        icon="FontSizeOutlined"
                        component={RenderCheckBox}
                        checked={values.show_memes}
                    />
                </Col>

                {/* <Col lg={24} md={24}>
                <Field
                    name={`other.tags`}
                    component={RenderTags}
                    placeholder={`Collection Tags`}
                    label={`Collection Tags`}
                    value={values.other.tags}
                />
                </Col> */}
                <Col lg={24} md={24}>
                    <TagsAssignComponent
                        name="tags"
                        tagGroup="collection"
                        formik={props}
                        isSet={true}
                        handleChange={(data) => {
                            setFieldValue(
                                ["tags", "data"],
                                data.map((d) => ({
                                    tag_id: d.value,
                                    label: d.label,
                                })),
                            );
                        }}
                        selected={values?.tags?.data?.map((tag, index) => ({
                            value: tag.tag_id,
                            key: index,
                            label: tag.label,
                        }))}
                    />
                    <br />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Collection Status"
                        placeholder="Collection Status"
                        name={"status"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.status}
                        rules={[{ required: true }]}
                    >
                        <Option value="inactive">Inactive</Option>
                        <Option value="active">Active</Option>
                    </Field>
                </Col>

                <Col lg={12} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const CollectionFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { collection } = props;

        return {
            ...(collection?.id ? { id: collection.id } : {}),
            title: collection?.title || "",
            description: collection?.description || "",
            cover: collection?.cover || "",
            chapter: collection?.chapter || "",
            topic: collection?.topic || "",
            type: collection?.type || "",
            format: collection?.format || "",
            attempts: collection?.attempts || 0,
            status: collection?.status || "inactive",
            duration: collection?.duration || 0,
            show_memes: collection?.show_memes == false ? false : true,
            created_by: props.user_id,

            subject: collection?.subject || "",
            class: collection?.class || "",

            tags: {
                data:
                    collection?.tags?.map((tag) => ({
                        tag_id: tag.tag.id,
                        // value: tag.tag.value, name: tag.tag.name
                        label: `${tag.tag.value} (${tag.tag.name})` || "",
                    })) || [],
            },
        };
    },

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
    validationSchema: (props) => FormSchema,
});

const FormikForm = CollectionFormWithFormik(CollectionForm);

const FormikFormWithUser = (props) => {
    const { user } = useAuth0();

    const namespace = "https://hasura.io/jwt/claims/";
    const user_id = user && user[namespace + "user_id"];
    return <FormikForm {...props} user_id={user_id} />;
};

export default FormikFormWithUser;
