import { Card } from "antd";
import { renderer } from "../../helpers/";
import { BLOCK_TYPES } from "../../common/index";

const { CONTENT_BLOCK } = BLOCK_TYPES;

const renderBlock = (block: any) => {
    const {
        data: {
            [CONTENT_BLOCK]: { title },
        },
    } = block;
    return (
        <div>
            <Card>{renderer(title)}</Card>
        </div>
    );
};

export default renderBlock;
