import { SlateEditor } from "src/components/plate";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import _ from "lodash";

const renderEditorSubjective = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    currentBlock,
    setIsNewBlock,
    isNewBlock,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            subjective: { text, solution },
        },
    } = block;

    const [currentEditor, setCurrentEditor] = useState(`${id || tmpId}_text`);

    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_text`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    return (
        <div>
            <Row style={{ width: "100%" }}>
                <Col span={22}>
                    <SlateEditor
                        id={`${id || tmpId}_text`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "subjective", "text"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={currentEditor == `${id || tmpId}_text`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={text}
                    />
                </Col>
            </Row>
            <Row>
                <h3
                    style={{
                        padding: "0px 24px",
                        fontWeight: "bold",
                    }}
                >
                    Solution
                </h3>
            </Row>
            <Row>
                <Col span={22}>
                    <SlateEditor
                        id={`${id || tmpId}_solution`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "subjective", "solution"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        value={solution}
                        isFocused={currentEditor == `${id || tmpId}_solution`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                        required={false}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default renderEditorSubjective;
