import { gql } from "@apollo/client";export default gql`mutation updateConcept($id: Int!, $title: Int = 0) {
    updateConcept: update_concept_by_pk(
        pk_columns: { id: $id }
        _concept: { title: $title }
    ) {
        id
        title
    }
}
`;
