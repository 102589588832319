import { PlanList, PlanCreate, PlanEdit } from "./views";

import { Route } from "react-router-dom";
export const PLAN_ROUTES = [
    <Route path="/plan" element={<PlanList />} />,

    <Route path="/plan/create" element={<PlanCreate />} />,
    <Route path="/plan/update/:plan_id" element={<PlanEdit />} />,
];

export { PlanList, PlanCreate, PlanEdit } from "./views";
