import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";

const renderCardText = (props: any) => {
    const {
        block: { data },
    } = props;
    return (
        <BlockCard {...props}>
            <div>
                {data.v2_rich_text.slice(0, 10) +
                    (data.v2_rich_text.length <= 10 ? `` : `...`)}
            </div>
        </BlockCard>
    );
};
export default renderCardText;
