import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";
import { arrayMoveImmutable } from "array-move";
import _ from "lodash";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { v4 as uuid } from "uuid";
import { addCallback } from "../../common/index";
import { MCQ_DEFAULT_VALUE, renderCardMCQ } from "../MCQ";
import { BLOCK_TYPES } from "../../common/index";
const { MCQ_BLOCK } = BLOCK_TYPES;

const renderCardCaseStudy = (props: any) => {
    const {
        block,
        setBlock,
        idx,
        setCurrentBlock,
        currentBlock,
        currentSubBlock,
        setCurrentSubBlock,
        setSaveCounter,
        setIsNewBlock,
        setIsEditor,
    } = props;
    const { children } = block;

    const subCardStyle = { width: "80%", marginLeft: "18%" };

    const SortableItemCustom = SortableElement((props: any) => {
        const { blocks, sid, child } = props;
        return (
            <div
                key={`${idx}_${sid}`}
                style={{ position: "relative", zIndex: 99 }}
            >
                {renderCardMCQ({
                    ...props,
                    child,
                    idx: sid,
                    type: MCQ_BLOCK,
                    block: child,
                    currentBlock: currentBlock == idx ? currentSubBlock : null,
                    currentSubBlock,
                    setCurrentBlock: () => {
                        setCurrentBlock(idx);
                        setCurrentSubBlock(sid);
                    },
                    deleteBlock: (index: number) => {
                        setCurrentSubBlock(null);
                        let tmpBlock = _.cloneDeep(block);
                        const tmpChildren = [...children];
                        _.pullAt(tmpChildren, sid);
                        tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);
                        setBlock(tmpBlock, idx);
                    },
                    disableDuplicateAction: true,
                    ribbonColor: "purple",
                })}
            </div>
        );
    });

    const SortableContainerCustom = SortableContainer(({ children }: any) => {
        return <div>{children}</div>;
    });

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            children,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);
        setBlock(tmpBlock, idx);
    };

    return (
        <div>
            <BlockCard {...props}>Case Study Block</BlockCard>

            <div style={subCardStyle}>
                <SortableContainerCustom onSortEnd={onSortEnd} useDragHandle>
                    {children.map((child: any, sid: number) => (
                        <SortableItemCustom
                            {...props}
                            sid={sid}
                            key={`nested-item-${child.id || child.tmpId}`}
                            index={sid}
                            child={child}
                        />
                    ))}
                </SortableContainerCustom>
            </div>

            <div
                style={subCardStyle}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <BlockCard
                    {...props}
                    disableActions={true}
                    onClick={() => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["children", children.length],
                            {
                                ...MCQ_DEFAULT_VALUE,
                                order: children.length + 1,
                                tmpId: uuid(),
                            },
                        );
                        const tmpBlocks = setBlock(tmpBlock, idx);
                        setCurrentBlock(idx);
                        setCurrentSubBlock(children.length);
                        addCallback({
                            setSaveCounter,
                            currentBlocks: tmpBlocks,
                        });
                        setIsNewBlock(true);
                    }}
                    idx={`+`}
                    ribbonColor="green"
                >
                    Add Question
                </BlockCard>
            </div>
        </div>
    );
};

export default renderCardCaseStudy;
