import renderBlockAudio from "./renderBlock";
import renderEditorAudio from "./renderEditor";
import isValidCallbackAudio from "./isValid";
import AUDIO_DEFAULT_VALUE from "./defaultValue";
import renderSettingsAudio from "./renderSettings";
import renderCardAudio from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { AUDIO_BLOCK } = BLOCK_TYPES;

const AUDIO_BLOCK_CONFIG = {
    [AUDIO_BLOCK]: {
        label: "Audio",
        defaultValue: AUDIO_DEFAULT_VALUE,
        renderBlock: renderBlockAudio,
        renderEditor: renderEditorAudio,
        renderCard: renderCardAudio,
        renderSettings: renderSettingsAudio,
        addCallback,
        isValidCallback: isValidCallbackAudio,
    },
};

export {
    renderBlockAudio,
    renderCardAudio,
    renderEditorAudio,
    renderSettingsAudio,
    isValidCallbackAudio,
    AUDIO_DEFAULT_VALUE,
    AUDIO_BLOCK_CONFIG,
};
