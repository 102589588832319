import { gql } from "@apollo/client";export default gql`query memes(
    $limit: Int = 12
    $offset: Int = 0
    $where: meme_bool_exp # $type: String # $parent_id: uuid
) {
    memes: meme(
        limit: $limit
        offset: $offset
        where: $where # order_by: { metadata: { created_at: desc } }
    ) {
        id
        asset_url
        group
        status
        user_id
    }
}
`;
