import { SlateEditor } from "src/components/plate";
import {
    Button,
    Col,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Space,
    Typography,
} from "antd";
import { cloneDeep, set } from "lodash";
import React, { useEffect, useState } from "react";
import { BLOCK_TYPES, isEditorEmpty } from "../../../../common";
import CustomSelectField from "../../../../common/CustomSelectField";
import { captureException } from "@sentry/react";

const { V2_TABLE_BLOCK } = BLOCK_TYPES;

const HeadingPopupForm = (props: any) => {
    const {
        isModalOpen,
        closeForm,
        curCellCol,
        mentionsList,
        hasMentions,
        block,
        setBlock,
        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        curHeading,
        headType = "row_heading",
        disableWrite,
    } = props;

    const tmpId = `${curCellCol}_text`;

    const [isSubmitting, setSubmitting] = useState(false);
    const [currentHeading, setCurrentHead] = useState(curHeading);

    const [currentEditor, setCurrentEditor] = useState(tmpId);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [tmpId, currentEditor]);

    useEffect(() => {
        setCurrentHead(curHeading);
    }, [curCellCol]);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            let tmpBlock = cloneDeep(block);

            const tempData = tmpBlock?.data[V2_TABLE_BLOCK][headType];

            tempData[curCellCol] = currentHeading;

            tmpBlock = set(
                tmpBlock,
                ["data", V2_TABLE_BLOCK, headType],
                tempData,
            );

            setBlock(tmpBlock);

            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e)
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        closeForm();
    };

    return (
        <>
            <Modal
                title={
                    <Typography.Title level={4}>
                        Update Heading
                    </Typography.Title>
                }
                open={isModalOpen}
                onCancel={closeForm}
                destroyOnClose
                width={720}
                footer={null}
            >
                <Form>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Cell Type">
                                <Select
                                    defaultValue={currentHeading?.type}
                                    onChange={(value) => {
                                        const tmpCell =
                                            cloneDeep(currentHeading);
                                        tmpCell.type = value;

                                        if (
                                            value === "component" &&
                                            !tmpCell?.component?.type
                                        ) {
                                            if (tmpCell?.component)
                                                tmpCell.component.type = "svg";
                                            else
                                                tmpCell.component = {
                                                    type: "svg",
                                                };
                                        }
                                        setCurrentHead(tmpCell);
                                    }}
                                    options={[
                                        { value: "text", label: "Text" },
                                        {
                                            value: "component",
                                            label: "Component",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>

                        {currentHeading?.type === "text" && (
                            <Col span={22}>
                                <div
                                    style={{
                                        marginBottom: "20px",
                                    }}
                                >
                                    Heading Text:
                                </div>
                                <SlateEditor
                                    hasMentions={hasMentions}
                                    mentionsList={mentionsList}
                                    id={tmpId}
                                    onChange={(value: any) =>
                                        setCurrentHead({
                                            ...currentHeading,
                                            text: value,
                                        })
                                    }
                                    isFocused={currentEditor === tmpId}
                                    setEditor={(id: string) =>
                                        setCurrentEditor(id)
                                    }
                                    value={currentHeading?.text || []}
                                    placeholder={"Type the heading here..."}
                                />
                            </Col>
                        )}

                        {currentHeading?.type === "component" && (
                            <>
                                <Col span={24}>
                                    <Form.Item label="Component Type">
                                        <Select
                                            disabled
                                            defaultValue={
                                                currentHeading?.component?.type
                                            }
                                            onChange={(value) => {
                                                const tmpCell =
                                                    cloneDeep(currentHeading);
                                                tmpCell["component"].type =
                                                    value;
                                                setCurrentHead(tmpCell);
                                            }}
                                            options={[
                                                {
                                                    value: "svg",
                                                    label: "SVG",
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <div
                                        style={{
                                            padding: "10px",
                                            marginBottom: "10px",
                                            border: "1px solid #666",
                                        }}
                                    >
                                        <h3>Select Fill Color</h3>
                                        <Form.Item label={"Pick Color"}>
                                            <Input
                                                disabled={disableWrite}
                                                type="color"
                                                style={{ maxWidth: "200px" }}
                                                value={
                                                    currentHeading?.component
                                                        ?.fill
                                                }
                                                onChange={(e) => {
                                                    const tmpCell =
                                                        cloneDeep(
                                                            currentHeading,
                                                        );
                                                    tmpCell["component"].fill =
                                                        e.target.value;
                                                    setCurrentHead(tmpCell);
                                                }}
                                                required
                                            />
                                        </Form.Item>
                                        Or
                                        <Form.Item
                                            label={"Choose from Variable"}
                                        >
                                            <CustomSelectField
                                                disabled={disableWrite}
                                                value={
                                                    currentHeading?.component
                                                        ?.fill
                                                }
                                                onChange={(value: any) => {
                                                    const tmpCell =
                                                        cloneDeep(
                                                            currentHeading,
                                                        );
                                                    tmpCell["component"].fill =
                                                        value;
                                                    setCurrentHead(tmpCell);
                                                }}
                                                options={mentionsList.map(
                                                    (item: { text: any }) => ({
                                                        value: `@@${item.text}@@`,
                                                        label: item.text,
                                                    }),
                                                )}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </>
                        )}

                        <Col span={24}>
                            <Space style={{ float: "right" }}>
                                <Button type="default" onClick={closeForm}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                    disabled={
                                        (currentHeading?.type === "text" &&
                                            isEditorEmpty(
                                                currentHeading?.text || [],
                                            )) ||
                                        (currentHeading?.type === "component" &&
                                            !currentHeading?.component?.fill)
                                    }
                                >
                                    Submit
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default HeadingPopupForm;
