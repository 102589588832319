import * as React from "react";
import { ImportOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Switch } from "antd";
import _ from "lodash";

import parseSvg from "./utils/parseSvg";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";

const SvgField = ({ disableWrite, block, setBlock }: any) => {
    // state to store svg string
    const [svgString, setSvgString] = React.useState<string>("");
    // loading state for when svg is being processed
    const [loading, setLoading] = React.useState<boolean>(false);

    // function to handle import and process of svg string
    // for now just set loading to true then false
    // use callback with svgString as dependency

    const handleSvgImport = React.useCallback(() => {
        setLoading(true);
        const firstRowCells = block.cells[0];
        const {
            newCells: updatedCells,
            paths,
            width,
            height,
        } = parseSvg(
            svgString,
            firstRowCells,
            block.feedback?.paths,
            block?.puzzle?.enabled,
        );
        let tmpBlock = _.cloneDeep(block);
        tmpBlock.cells[0] = updatedCells;
        if (!tmpBlock.drawing) {
            tmpBlock.drawing = {};
        }
        tmpBlock.drawing.drawingAreaDimensions = { width, height };
        if (paths) {
            tmpBlock.feedback.paths = paths;
        }
        setBlock(tmpBlock);
        setLoading(false);
    }, [svgString]);

    const handleDimensionChange = (value: number, dimension: string) => {
        let tmpBlock = _.cloneDeep(block);
        if (!tmpBlock.drawing) {
            tmpBlock.drawing = {};
        }
        if (!tmpBlock.drawing?.drawingAreaDimensions) {
            tmpBlock.drawing.drawingAreaDimensions = { width: 0, height: 0 };
        }
        tmpBlock.drawing.drawingAreaDimensions[dimension] = value;
        setBlock(tmpBlock);
    };
    return (
        <>
            {/** Toggle switch for drawing.enabled */}
            <KTWrapper feature="drawing_enabled">
                <Form.Item label="Drawing Enabled">
                    <Switch
                        disabled={disableWrite}
                        checked={block.drawing?.enabled}
                        onChange={(val) => {
                            let tmpBlock = _.cloneDeep(block);
                            if (!tmpBlock.drawing) {
                                tmpBlock.drawing = {};
                            }
                            tmpBlock.drawing.enabled = val;
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>
            </KTWrapper>
            {block?.drawing?.enabled && (
                <>
                    <Form.Item label="Width">
                        <InputNumber
                            disabled={disableWrite}
                            value={block.drawing?.drawingAreaDimensions?.width}
                            onChange={(e) => {
                                handleDimensionChange(e, "width");
                            }}
                            min={50}
                            max={320}
                        />
                    </Form.Item>
                    <Form.Item label="Height">
                        <InputNumber
                            disabled={disableWrite}
                            value={block.drawing?.drawingAreaDimensions?.height}
                            onChange={(e) => {
                                handleDimensionChange(e, "height");
                            }}
                            min={50}
                            max={1000}
                        />
                    </Form.Item>
                    <Form.Item label="SVG">
                        <Input.TextArea
                            disabled={disableWrite}
                            rows={6}
                            value={svgString}
                            onChange={(e) => {
                                setSvgString(e.target.value);
                            }}
                        />
                    </Form.Item>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                        }}
                    >
                        <Button
                            onClick={handleSvgImport}
                            // loading={loading}
                            // disabled={loading}
                            type="primary"
                            style={{ marginTop: "10px" }}
                        >
                            {/** Button to trigger svg import, with import icon which disapeears when loading is true */}
                            {loading ? "Importing" : "Import"}
                            {!loading && <ImportOutlined rotate={270} />}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

export default SvgField;
