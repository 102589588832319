import renderBlockMCQ from "./renderBlock";
import renderEditorMCQ from "./renderEditor";
import isValidCallbackMCQ from "./isValid";
import MCQ_DEFAULT_VALUE from "./defaultValue";
import renderSettingsMCQ from "./renderSettings";
import renderCardMCQ from "./renderCard";
import { BLOCK_TYPES, addCallback } from "../../common/index";

const { MCQ_BLOCK } = BLOCK_TYPES;

const MCQ_BLOCK_CONFIG = {
    [MCQ_BLOCK]: {
        label: "MCQ",
        defaultValue: MCQ_DEFAULT_VALUE,
        renderBlock: renderBlockMCQ,
        renderEditor: renderEditorMCQ,
        renderCard: renderCardMCQ,
        renderSettings: renderSettingsMCQ,
        addCallback,
        isValidCallback: isValidCallbackMCQ,
    },
};

export {
    renderBlockMCQ,
    renderCardMCQ,
    renderEditorMCQ,
    renderSettingsMCQ,
    isValidCallbackMCQ,
    MCQ_DEFAULT_VALUE,
    MCQ_BLOCK_CONFIG,
};
