import { JumpLogicModal, UtmPopup } from "src/modules/worksheet/components";
import { Card, Divider } from "antd";
import { renderSettings } from "../../../../blocks/index";
import { Status } from "./Status";
import InputVariablesModal from "../../Modals/InputVariablesModal";

export const SettingsColumn = (props: any) => {
    const {
        worksheet,
        setIsEditor,
        setBlock,
        block,
        jumpLogicProps,
        blocksLength,
        utmProps,
        checkInputVarExist,
        onInputVariableUpdate,
        isReadOnlyMode,
    } = props;
    return (
        <Card
            style={{ borderRadius: "8px", height: "85vh", overflow: "scroll" }}
            bordered={true}
            onClick={() => {
                try {
                    setIsEditor(false);
                } catch (err) {}
            }}
        >
            <br />
            Settings
            {worksheet?.type === "personalized_learning_v2" && (
                <InputVariablesModal
                    worksheet={worksheet}
                    checkInputVarExist={checkInputVarExist}
                    onInputVariableUpdate={onInputVariableUpdate}
                    isReadOnlyMode={isReadOnlyMode}
                />
            )}
            {worksheet?.type === "quiz_form" && <UtmPopup {...utmProps} />}
            {["quiz_form", "personalized_learning"].includes(worksheet?.type) &&
                blocksLength > 0 && <JumpLogicModal {...jumpLogicProps} />}
            <Divider />
            {renderSettings(props)}
            <div style={{ marginTop: "20px" }}>
                <h4>Status</h4>
                <Status
                    block={block}
                    setBlock={setBlock}
                    isReadOnlyMode={isReadOnlyMode}
                />
            </div>
        </Card>
    );
};
