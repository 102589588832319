import renderBlockFigma from "./renderBlock";
import renderEditorFigma from "./renderEditor/";
import isValidCallbackFigma from "./isValid";
import FIGMA_DEFAULT_VALUE from "./defaultValue";
import renderSettingsFigma from "./renderSettings";
import renderCardFigma from "./renderCard";
import { BLOCK_TYPES } from "../../common/index";

const { FIGMA_BLOCK } = BLOCK_TYPES;

const FIGMA_BLOCK_CONFIG = {
    [FIGMA_BLOCK]: {
        label: "Figma",
        defaultValue: FIGMA_DEFAULT_VALUE,
        renderBlock: renderBlockFigma,
        renderEditor: renderEditorFigma,
        renderCard: renderCardFigma,
        renderSettings: renderSettingsFigma,
        isValidCallback: isValidCallbackFigma,
    },
};

export {
    renderBlockFigma,
    renderCardFigma,
    renderEditorFigma,
    renderSettingsFigma,
    isValidCallbackFigma,
    FIGMA_DEFAULT_VALUE,
    FIGMA_BLOCK_CONFIG,
};
