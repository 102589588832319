import { DeleteOutlined } from "@ant-design/icons";
import { Divider, Form, Input, Modal, Typography } from "antd";
import { Button, List } from "antd";
import React, { useState } from "react";
import { Can } from "src/services/casl";

const InputVariablesModal = (props: any) => {
    const {
        worksheet,
        checkInputVarExist,
        onInputVariableUpdate,
        isReadOnlyMode,
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [varName, setVarName] = useState("");
    const [compFunc, setCompFunc] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                style={{
                    margin: "20px 0",
                }}
                onClick={showModal}
            >
                Check Input Variables
            </Button>
            <Modal
                title={
                    <Typography.Title level={4}>
                        Input Variables List
                    </Typography.Title>
                }
                onCancel={closeForm}
                open={isModalOpen}
                destroyOnClose
                width={600}
                footer={null}
            >
                <List
                    size="large"
                    bordered
                    header={<h3>Global Variables</h3>}
                    dataSource={worksheet?.other?.input_variables || []}
                    renderItem={(
                        { variable_name, block_id }: any,
                        index: number,
                    ) => (
                        <List.Item
                            key={index}
                            actions={[
                                checkInputVarExist(block_id) ? (
                                    "Block Present"
                                ) : (
                                    <Can
                                        I="edit"
                                        a="worksheet_details"
                                        passThrough
                                    >
                                        {(allowed: boolean) => (
                                            <Button
                                                icon={
                                                    <DeleteOutlined
                                                        style={{ color: "red" }}
                                                    />
                                                }
                                                shape="circle"
                                                danger
                                                disabled={
                                                    !allowed || isReadOnlyMode
                                                }
                                                onClick={() =>
                                                    onInputVariableUpdate({
                                                        variable_name,
                                                    })
                                                }
                                            />
                                        )}
                                    </Can>
                                ),
                            ]}
                        >
                            {variable_name}
                        </List.Item>
                    )}
                />

                <List
                    size="large"
                    style={{ marginTop: "20px" }}
                    bordered
                    header={<h3>Inferred Variables</h3>}
                    dataSource={worksheet?.other?.inferred_variables || []}
                    renderItem={(
                        { variable_name, computation_func }: any,
                        index: number,
                    ) => (
                        <List.Item
                            key={index}
                            actions={[
                                <Can I="edit" a="worksheet_details">
                                    {(allowed: boolean) => (
                                        <Button
                                            icon={
                                                <DeleteOutlined
                                                    style={{ color: "red" }}
                                                />
                                            }
                                            shape="circle"
                                            danger
                                            disabled={
                                                !allowed || isReadOnlyMode
                                            }
                                            onClick={() =>
                                                onInputVariableUpdate({
                                                    variable_name,
                                                    type: "inferred",
                                                })
                                            }
                                        />
                                    )}
                                </Can>,
                            ]}
                        >
                            <div>
                                {variable_name}

                                <div
                                    style={{
                                        color: "#888",
                                        marginTop: "5px",
                                    }}
                                >
                                    {computation_func}
                                </div>
                            </div>
                        </List.Item>
                    )}
                />

                {!isReadOnlyMode && (
                    <Can I="edit" a="worksheet_details">
                        {(allowed: boolean) => (
                            <>
                                <Divider />
                                <Form>
                                    <h3 style={{ marginBottom: "15px" }}>
                                        Add Inferred Variable
                                    </h3>
                                    <Form.Item label={"Variable Name"}>
                                        <Input
                                            placeholder="Variable Name"
                                            value={varName}
                                            onChange={(e) =>
                                                setVarName(e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item label={"Computation Function"}>
                                        <Input
                                            placeholder="Computation Function"
                                            value={compFunc}
                                            onChange={(e) =>
                                                setCompFunc(e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                    <Button
                                        type="primary"
                                        ghost
                                        disabled={
                                            !varName?.trim() ||
                                            !compFunc?.trim() ||
                                            !allowed
                                        }
                                        onClick={async () => {
                                            if (
                                                varName?.trim() &&
                                                compFunc?.trim()
                                            ) {
                                                const val =
                                                    await onInputVariableUpdate(
                                                        {
                                                            values: [
                                                                {
                                                                    variable_name:
                                                                        varName,
                                                                    computation_func:
                                                                        compFunc,
                                                                    is_global:
                                                                        false,
                                                                },
                                                            ],
                                                            type: "inferred",
                                                        },
                                                    );
                                                if (val) {
                                                    setCompFunc("");
                                                    setVarName("");
                                                }
                                            }
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Can>
                )}
            </Modal>
        </>
    );
};

export default InputVariablesModal;
