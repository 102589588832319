import { RenderPart, TextHtml } from "src/components";

const parseCMSJson = (obj: any) => {
    if (typeof obj === "string") {
        return obj;
    }
    const returnObj = obj
        .map(function (o: any) {
            if (o.type === "p") {
                return o.children
                    .map(function (o: any) {
                        if (o.bold) return `<b>${o.text}</b>`;
                        else if (o.italic) return `<i>${o.text}</i>`;
                        else if (o.underline) return `<u>${o.text}</u>`;

                        return o.text;
                    })
                    .join(" ");
            } else if (o.type === "img") {
                return `<image="${o.url}">`;
            } else if (o.type === "latex") {
                return o.latex;
            }
        })
        .join("\n");
    //
    return returnObj;
};

const replaceAll = (str: string, find: string, replace: string) => {
    return str.replace(new RegExp(find, "g"), replace);
};

const cleanLatex = (text: string, delimiter = "$") => {
    const split = text.split(delimiter);
    let returnString = "";
    split.map((item, index) => {
        if (index % 2 === 0) {
            returnString += item;
        } else {
            returnString += replaceAll(
                `${delimiter}${item}${delimiter}`,
                "\n",
                " ",
            );
        }
    });
    return returnString;
};

const getParts = (text: any, displayType: string) => {
    const parts: { type: string; val: any }[] = [];
    if (!text) {
        return parts;
    }
    let txt = text.trim();
    txt = cleanLatex(txt);
    txt = cleanLatex(txt, "$$");
    txt = txt.replace("< image", "<image");
    txt = txt.replace("<image =", "<image=");
    txt = txt.replace("<image = ", "<image=");
    txt = txt.replace("<image= ", "<image=");
    txt = txt.replace('" >', '">');
    const splitted = txt.split("\n");

    for (let splt of splitted) {
        splt = splt.trim();
        if (splt.includes("<image")) {
            let currentPart = splt;
            const prts = [];
            while (true) {
                const imgIndex = currentPart.indexOf("<image=");

                if (imgIndex === -1) {
                    prts.push({
                        type: "latex_span",
                        val: currentPart,
                    });
                    break;
                }

                const endIndex = currentPart.indexOf('">');
                prts.push({
                    type: "latex_span",
                    val: currentPart.substr(0, imgIndex),
                });

                displayType === "card" &&
                    prts.push({
                        type: "img",
                        val: currentPart.substr(
                            imgIndex + 8,
                            endIndex - imgIndex - 8,
                        ),
                    });

                currentPart = currentPart.substr(endIndex + 2);
            }
            parts.push({
                type: "parts",
                val: prts,
            });
        } else {
            const splitArr = splt.split("");
            const isLatex =
                splitArr[0] === "$" && splitArr[splitArr.length - 1] === "$";

            if (isLatex) {
                parts.push({
                    type: "latex_div",
                    val: splt,
                });
            } else {
                if (!splt)
                    parts.push({
                        type: "break",
                        val: splt,
                    });
                else
                    parts.push({
                        type: "latex_div",
                        val: splt,
                    });
            }
        }
    }

    return parts;
};

export const renderer = (json: any) => {
    const text = parseCMSJson(json);

    return (
        text &&
        // (isHTML(text) ? (
        // TODO: handle this
        (false ? (
            <TextHtml html={text} />
        ) : (
            getParts(text, "card").map((part, pIndex) => (
                <RenderPart part={part} key={"_" + pIndex} />
            ))
        ))
    );
};
