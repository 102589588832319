import mitt from "./events/mitt";

// const emitter = mitt();
const emit = mitt();

const emitter = {
    all: emit.all,
    emit: (type: string, payload: any = {}) => {
        if (emit.all.has(type)) {
            emit.emit(type, payload);
            // console.log(`Event: ${type} is emitted with payload:`, payload);
        } else {
            if (payload?.resolve) {
                payload.resolve("");
            }
            // console.log(`Event: ${type} is not registered`);
        }
    },
    on: emit.on,
    off: emit.off,
};

export default emitter;
