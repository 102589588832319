import { BLOCK_TYPES } from "../../common/index";
const { V2_STORIES_BLOCK } = BLOCK_TYPES;

const getV2StoriesDefaultValue = (
    params: any = {},
    type = "personalized_learning_v3",
) => {
    if (type === "personalized_learning_v4")
        return {
            type: V2_STORIES_BLOCK,
            data: {
                [V2_STORIES_BLOCK]: {
                    name: "Default story title",
                    sub_title: "",
                    banner: "",
                    dev_remarks: [],
                },
                other: {
                    duration: 0,
                    audio: "",
                    job: { id: "", last_updated: null, name: "" },
                },
                children: [],
            },
            backend: {
                slug: "",
                ...(params?.logic || {}),
            },
        };
    return {
    type: V2_STORIES_BLOCK,
    data: {
        slug: "",
        [V2_STORIES_BLOCK]: {
            name: "Default story title",
            sub_title: "",
            banner: "",
            dev_remarks: [],
            ...params,
        },
        other: {
            duration: 0,
            audio: "",
            difficulty_level: "medium",
            job: { id: "", last_updated: null, name: "" },
        },
        children: [],
    },
};
};

export default getV2StoriesDefaultValue;
