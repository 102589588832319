import { DEFAULT_CUSTOM_KEYBOARD } from "src/components/CustomKeyboardEditor";
import { BLOCK_TYPES } from "../../common/index";
import { GRID_INPUT_OPTIONS, PUZZLE_OTHER } from "../Crossword/defaultValue";
const { INPUT_AND_FORM_BLOCK } = BLOCK_TYPES;

const INPUT_AND_FORM_DEFAULT_VALUE = {
    type: INPUT_AND_FORM_BLOCK,
    data: {
        [INPUT_AND_FORM_BLOCK]: {
            options: GRID_INPUT_OPTIONS({
                type: "input",
                otherFields: {
                    correct_answer: [],
                    no_border: false,
                    width: 1,
                },
            }),
            custom_keyboard: DEFAULT_CUSTOM_KEYBOARD,
            result_type: "question", // field, question
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
        other: {
            ...PUZZLE_OTHER,
            feedbacks: [], // DEFAULT_FEEDBACKS,
        },
    },
};

export default INPUT_AND_FORM_DEFAULT_VALUE;
