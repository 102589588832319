import { gql } from "@apollo/client";export default gql`mutation deleteSet($id: Int!, $submissionIds: [Int!]!) {
    delete_submission_question(where: { id: { _in: $submissionIds } }) {
        affected_rows
    }
    delete_submission(where: { set_id: { _eq: $id } }) {
        affected_rows
    }
    deleteSet: delete_set_by_pk(id: $id) {
        id
    }
}
`;
