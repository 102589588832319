import { BLOCK_TYPES } from "../../common/index";
const { VIDEO_BLOCK } = BLOCK_TYPES;

const VIDEO_DEFAULT_VALUE = {
    type: VIDEO_BLOCK,
    data: {
        video: "",
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
};

export default VIDEO_DEFAULT_VALUE;
