import { gql } from "@apollo/client";export default gql`query document($id: Int!) {
    document: home_explore_document_by_pk(id: $id) {
        id
        status
        banner
        category
        created_at
        cta_action
        cta_text
        preview_images
        title
        type
        updated_at
        url
    }
}
`;
