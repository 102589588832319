import { BLOCK_TYPES } from "../../common/index";
const { AUDIO_BLOCK } = BLOCK_TYPES;

const AUDIO_DEFAULT_VALUE = {
    type: AUDIO_BLOCK,
    data: {
        [AUDIO_BLOCK]: "",
    },
};
export default AUDIO_DEFAULT_VALUE;
