import { gql } from "@apollo/client";export default gql`mutation insert_collection_content(
    $objects: [collection_content_insert_input!] = {}
) {
    insert_collection_content(objects: $objects) {
        affected_rows
        returning {
            id
            collection {
                sets {
                    id
                    set_id
                }
            }
        }
    }
}
`;
