import { gql } from "@apollo/client";export default gql`mutation updateContent(
    $id: Int!
    $type: String = ""
    $index: Int = 0
    $duration: Int = 0
    $user_id: Int
) {
    updateContent: update_collection_content_by_pk(
        pk_columns: { id: $id }
        _set: {
            index: $index
            duration: $duration
            type: $type
            user_id: $user_id
        }
    ) {
        id
        index
        duration
        type
        user_id
    }
}
`;
