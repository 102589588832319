import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Popover, Select } from "antd";
import { BlockPicker } from "react-color";
import { areAllElementsSame } from "./helper";

const BorderTable = ({ selectedCells, path, setProperty, type }) => (
    <>
        {type && (
            <Col span={5}>
                <strong>{type}</strong>
            </Col>
        )}
        <Col span={4}>
            <Popover
                content={
                    <BlockPicker
                        colors={[]}
                        color={areAllElementsSame(selectedCells, [
                            "border",
                            ...path,
                            "color",
                        ])}
                        onChangeComplete={(color: any) => {
                            setProperty({
                                type: "BORDER",
                                subType: "color",
                                path,
                                value: color.hex,
                            });
                        }}
                        triangle="hide"
                    />
                }
                title="Border Color"
                trigger="click"
            >
                <Button
                    style={{
                        background: areAllElementsSame(selectedCells, [
                            "border",
                            ...path,
                            "color",
                        ]),
                    }}
                    block
                    icon={<EditOutlined />}
                />
            </Popover>
        </Col>
        <Col span={5}>
            <Input
                type="number"
                value={areAllElementsSame(selectedCells, [
                    "border",
                    ...path,
                    "width",
                ])}
                placeholder="Width"
                onChange={(e) => {
                    let width = Number(e.target.value);
                    setProperty({
                        type: "BORDER",
                        subType: "width",
                        path,
                        value: width,
                    });
                }}
            />
        </Col>
        <Col span={5}>
            <Select
                value={areAllElementsSame(selectedCells, [
                    "border",
                    ...path,
                    "style",
                ])}
                placeholder="Style"
                onChange={(val) => {
                    setProperty({
                        type: "BORDER",
                        subType: "style",
                        path,
                        value: val,
                    });
                }}
                style={{
                    width: "100%",
                }}
            >
                <Select.Option value="SOLID">Solid</Select.Option>
                <Select.Option value="DASHED">Dashed</Select.Option>
            </Select>
        </Col>
        {!type && (
            <Col span={5}>
                <Input
                    type="number"
                    value={areAllElementsSame(selectedCells, [
                        "border",
                        ...path,
                        "radius",
                    ])}
                    placeholder="Radius"
                    onChange={(e) => {
                        let radius = Number(e.target.value);
                        setProperty({
                            type: "BORDER",
                            subType: "radius",
                            path,
                            value: radius,
                        });
                    }}
                />
            </Col>
        )}
        <Col span={5}>
            <Input
                type="number"
                value={areAllElementsSame(selectedCells, [
                    "border",
                    ...path,
                    "opacity",
                ])}
                placeholder="Opacity"
                onChange={(e) => {
                    let opacity = Number(e.target.value);
                    setProperty({
                        type: "BORDER",
                        subType: "opacity",
                        path,
                        value: opacity,
                    });
                }}
            />
        </Col>
    </>
);

export default BorderTable;
