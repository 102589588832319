import React, { useState } from "react";
import { Button, Form } from "antd";
import EditIcon from "src/components/EditIcon";
import { CheckOutlined } from "@ant-design/icons";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { RenderUpload } from "src/components";
// import { replaceCDNUrl } from "../helpers";

const ImageField = (props: any) => {
    const { block, setBlock, path, label, preview = true } = props;
    const imageUrl = get(block, path);

    const [isEditState, setEditState] = useState(!get(block, path));

    return (
        <div style={{ width: "100%" }}>
            {isEditState || !preview ? (
                <Form.Item label={label}>
                    <div style={{ display: "flex" }}>
                        <RenderUpload
                            path="home-explore/document/"
                            onChangeCustom={(e: any) => {
                                const value =
                                    // replaceCDNUrl(
                                    e.target.value;
                                //     , e.target.bucket,
                                // );
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(tmpBlock, path, value);
                                setBlock(tmpBlock);
                            }}
                            label="upload an image"
                            value={get(block, path) || ""}
                        />
                        {preview && imageUrl && (
                            <Button
                                icon={
                                    <CheckOutlined
                                        onClick={() => setEditState(false)}
                                    />
                                }
                                shape="circle"
                                type="primary"
                                style={{ marginLeft: "10px" }}
                            />
                        )}
                    </div>
                </Form.Item>
            ) : (
                preview && (
                    <div style={{ display: "flex" }}>
                        <div>
                            <h4>{label}</h4>
                            <img
                                src={imageUrl}
                                alt=""
                                style={{ maxWidth: "100%" }}
                            />
                        </div>
                        {imageUrl && (
                            <EditIcon
                                isButton={true}
                                onClick={() => setEditState(true)}
                                style={{ marginLeft: "10px" }}
                            />
                        )}
                    </div>
                )
            )}
        </div>
    );
};

export default ImageField;
