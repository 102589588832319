import renderBlockV2InputAndForm from "./renderBlock";
import renderEditorV2InputAndForm from "./renderEditor";
import isValidCallbackV2InputAndForm from "./isValid";
import V2_INPUT_AND_FORM_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2InputAndForm from "./renderSettings";
import renderCardV2InputAndForm from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common";

const { V2_INPUT_AND_FORM_BLOCK } = BLOCK_TYPES;

const V2_INPUT_AND_FORM_BLOCK_CONFIG = {
    [V2_INPUT_AND_FORM_BLOCK]: {
        label: "V2 Input And Form",
        defaultValue: V2_INPUT_AND_FORM_DEFAULT_VALUE,
        renderBlock: renderBlockV2InputAndForm,
        renderEditor: renderEditorV2InputAndForm,
        renderCard: renderCardV2InputAndForm,
        renderSettings: renderSettingsV2InputAndForm,
        addCallback,
        isValidCallback: isValidCallbackV2InputAndForm,
    },
};

export {
    renderBlockV2InputAndForm,
    renderCardV2InputAndForm,
    renderEditorV2InputAndForm,
    renderSettingsV2InputAndForm,
    isValidCallbackV2InputAndForm,
    V2_INPUT_AND_FORM_DEFAULT_VALUE,
    V2_INPUT_AND_FORM_BLOCK_CONFIG,
};
