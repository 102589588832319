import { Button, Form, Input, Select, Switch, Table, message } from "antd";
import { cloneDeep, pullAt, set } from "lodash";
import React from "react";
import { BlockPicker } from "react-color";
import {
    CopyIcon,
    DeleteIcon,
    RenderUpload,
    SlateEditor,
} from "src/components";
import { v4 as uuid } from "uuid";
import TopicEventsModal from "../common/TopicEventsModal";
import { isEditorEmpty } from "src/modules/worksheet/components/blocks/common";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { sortAndUpdate } from "../DragNDrop/TagTable";
import TablePaddingEditor from "../../../../Figma/renderEditor/TablePadding";
import TableBorderEditor from "../../../../Figma/renderEditor/TableBorder";

const TagContainer = (props) => {
    const {
        disableWrite,
        items,
        setBlock,
        hasMentions,
        mentionsList,
        currentEditor,
        setCurrentEditor,
        schema,
        categories,
        noEffects = false,
    } = props;
    const effectColumn = noEffects
        ? []
        : [
              {
                  title: "Type",
                  dataIndex: "type",
                  width: 100,
                  render: (v, data) => {
                      return (
                          <Select
                              disabled={disableWrite}
                              value={v}
                              onChange={(value) => {
                                  let tmpBlock = cloneDeep(items);
                                  tmpBlock[data?.key].type = value;
                                  setBlock(tmpBlock);
                              }}
                              style={{
                                  minWidth: "100px",
                              }}
                          >
                              <Select.Option value="normal">
                                  Normal
                              </Select.Option>
                              <Select.Option value="hidden">
                                  Hidden
                              </Select.Option>
                              <Select.Option value="effect">
                                  Effect
                              </Select.Option>
                              <Select.Option value="dnd">DnD</Select.Option>
                          </Select>
                      );
                  },
              },
              {
                  title: "Effects",
                  dataIndex: "effects",
                  width: 150,
                  render: (v, data) => {
                      return (
                          <>
                              {data.type == "effect" ? (
                                  <TopicEventsModal
                                      disableWrite={disableWrite}
                                      topicEventEffectsMap={v}
                                      setBlock={(v) => {
                                          let tmpBlock = cloneDeep(items);
                                          tmpBlock[data?.key].effects = v;
                                          setBlock(tmpBlock);
                                      }}
                                  />
                              ) : (
                                  <></>
                              )}
                          </>
                      );
                  },
              },
          ];
    return (
        <>
            <Table
                style={{
                    scrollbarWidth: "none",
                }}
                scroll={{ x: 3000, y: "60vh" }}
                size="small"
                pagination={false}
                dataSource={items?.map((v, i) => {
                    return {
                        key: i,
                        name: v?.name,
                        text: v?.data?.find((v) => v.text)?.text || [],
                        asset: v?.data?.find((v) => v.img)?.img?.asset,
                        background: v?.bg,
                        order: v.data
                            ?.filter((v) => v.showImg || v.showText)
                            ?.map((v) => (v.text ? "text" : "image")),
                        value: v?.value,
                        category: v?.category,
                        enable: v?.enable,
                        maxDragLimit: v?.maxDragLimit,
                        type1: v.type1,
                        width: v?.data?.find((v) => v.img)?.width,
                        height: v?.data?.find((v) => v.img)?.height,
                        type: v?.type,
                        weight: v?.weight,
                        effects: v?.effects,
                        padding: v?.padding,
                        border: v?.border,
                    };
                })}
                columns={[
                    {
                        title: "Index",
                        dataIndex: "key",
                        width: 60,
                        render: (v) => v + 1,
                    },
                    {
                        title: "Name",
                        dataIndex: "name",
                        render: (v, data) => {
                            return (
                                <Input
                                    disabled={disableWrite}
                                    style={{
                                        minWidth: "100px",
                                    }}
                                    value={v}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].name =
                                            e.target.value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Text",
                        dataIndex: "text",
                        render: (v, data) => {
                            return (
                                <div
                                    style={{
                                        minWidth: "150px",
                                    }}
                                >
                                    <SlateEditor
                                        disabled={disableWrite}
                                        hasMentions={hasMentions}
                                        mentionsList={mentionsList}
                                        id={`${data.key}_tag_edit`}
                                        onChange={(value: any) => {
                                            let tmpBlock = cloneDeep(items);
                                            // tmpBlock[data?.key].text = value;
                                            tmpBlock[data?.key].data = tmpBlock[
                                                data?.key
                                            ].data?.map((v) => {
                                                if (v.text) v.text = value;
                                                return v;
                                            });

                                            tmpBlock[data?.key].name =
                                                value[0]?.children[0]?.text;
                                            if (
                                                !isNaN(
                                                    parseInt(
                                                        value[0]?.children[0]
                                                            ?.text,
                                                    ),
                                                )
                                            ) {
                                                tmpBlock[data?.key].value =
                                                    parseInt(
                                                        value[0]?.children[0]
                                                            ?.text,
                                                    );
                                            }

                                            setBlock(tmpBlock);
                                        }}
                                        isFocused={
                                            currentEditor ===
                                            `${data.key}_tag_edit`
                                        }
                                        setEditor={(id: string) =>
                                            setCurrentEditor(id)
                                        }
                                        value={v}
                                        required={!data?.asset}
                                    />
                                </div>
                            );
                        },
                    },
                    {
                        title: "Image",
                        dataIndex: "asset",
                        render: (v, data) => {
                            return (
                                <Form.Item
                                    validateStatus={
                                        !v && isEditorEmpty(data.text)
                                            ? "error"
                                            : ""
                                    }
                                    help={
                                        !v && isEditorEmpty(data.text)
                                            ? "Field cannot be empty"
                                            : ""
                                    }
                                >
                                    <RenderUpload
                                        singleUpload={true}
                                        disabled={disableWrite}
                                        path="home-explore/document/"
                                        onChangeCustom={(e) => {
                                            const value = e.target.value;
                                            let tmpBlock = cloneDeep(items);
                                            // tmpBlock[data?.key].asset = value;
                                            tmpBlock[data?.key].data = tmpBlock[
                                                data?.key
                                            ].data?.map((v) => {
                                                if (v.img) v.img.asset = value;
                                                return v;
                                            });
                                            setBlock(tmpBlock);
                                        }}
                                        label=""
                                        value={v}
                                    />
                                    <Input
                                        placeholder="Add value here"
                                        value={v}
                                        disabled={disableWrite}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[data?.key].asset = value;
                                            tmpBlock[data?.key].data = tmpBlock[
                                                data?.key
                                            ].data?.map((v) => {
                                                if (v.img) v.img.asset = value;
                                                return v;
                                            });
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            );
                        },
                    },
                    {
                        title: "Dimensions",
                        dataIndex: "dimensions",
                        render: (v, data) => {
                            return (
                                <>
                                    <Form.Item
                                        label="Width"
                                        validateStatus={
                                            !data?.width ? "error" : ""
                                        }
                                        help={
                                            !data?.width
                                                ? "Field cannot be empty"
                                                : ""
                                        }
                                    >
                                        <Input
                                            type="number"
                                            style={{
                                                minWidth: "100px",
                                            }}
                                            disabled={disableWrite}
                                            value={data?.width}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[data?.key].data =
                                                    tmpBlock[
                                                        data?.key
                                                    ].data?.map((v) => {
                                                        if (v.img)
                                                            v.width = value;
                                                        return v;
                                                    });
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Height"
                                        validateStatus={
                                            !data?.height ? "error" : ""
                                        }
                                        help={
                                            !data?.height
                                                ? "Field cannot be empty"
                                                : ""
                                        }
                                    >
                                        <Input
                                            type="number"
                                            style={{
                                                minWidth: "100px",
                                            }}
                                            disabled={disableWrite}
                                            value={data?.height}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[data?.key].data =
                                                    tmpBlock[
                                                        data?.key
                                                    ].data?.map((v) => {
                                                        if (v.img)
                                                            v.height = value;
                                                        return v;
                                                    });
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                </>
                            );
                        },
                    },
                    {
                        title: "Background",
                        dataIndex: "background",
                        render: (v, data) => {
                            return (
                                <>
                                    <BlockPicker
                                        colors={[]}
                                        color={
                                            typeof v === "string"
                                                ? JSON.parse(v)?.color || ""
                                                : v?.color
                                        }
                                        onChangeComplete={(color: any) => {
                                            if (disableWrite) return;
                                            const value = color.hex;
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[data?.key].bg = {
                                                type: "SOLID",
                                                color: value,
                                            };
                                            setBlock(tmpBlock);
                                        }}
                                        triangle="hide"
                                        width="90px"
                                    />
                                    <Button
                                        disabled={disableWrite}
                                        onClick={() => {
                                            const key = "bg";
                                            let tmpBlock = cloneDeep(items);
                                            const value =
                                                tmpBlock[data?.key][key];
                                            setBlock(
                                                tmpBlock.map((i) => ({
                                                    ...i,
                                                    [key]: value,
                                                })),
                                            );
                                        }}
                                        size="small"
                                        type="primary"
                                    >
                                        Apply to all
                                    </Button>
                                </>
                            );
                        },
                    },
                    {
                        title: "Order",
                        dataIndex: "order",
                        render: (v, data) => (
                            <Form.Item
                                validateStatus={!v?.length ? "error" : ""}
                                help={!v?.length ? "Field cannot be empty" : ""}
                            >
                                <Select
                                    disabled={disableWrite}
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    value={v}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].data =
                                            sortAndUpdate(
                                                tmpBlock[data?.key].data,
                                                val,
                                            );
                                        setBlock(tmpBlock);
                                    }}
                                    options={[
                                        {
                                            value: "text",
                                            label: "Text",
                                        },
                                        {
                                            value: "image",
                                            label: "Image",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        ),
                    },
                    {
                        title: "Category",
                        dataIndex: "category",
                        render: (v, data) => {
                            return (
                                <>
                                    <Form.Item
                                        validateStatus={!v ? "error" : ""}
                                        help={!v ? "Field cannot be empty" : ""}
                                    >
                                        <Select
                                            disabled={disableWrite}
                                            style={{ width: "100%" }}
                                            placeholder="Select"
                                            value={v}
                                            onChange={(value) => {
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[data?.key].category =
                                                    value;
                                                setBlock(tmpBlock);
                                            }}
                                            options={Array.from(
                                                new Set(
                                                    [...categories].filter(
                                                        (i) => i,
                                                    ),
                                                ),
                                            ).map((i) => ({
                                                value: i,
                                                label: i,
                                            }))}
                                        />
                                    </Form.Item>
                                    <br />
                                    <br />
                                    <Button
                                        disabled={disableWrite}
                                        onClick={() => {
                                            const key = "category";
                                            let tmpBlock = cloneDeep(items);
                                            const value =
                                                tmpBlock[data?.key][key];
                                            setBlock(
                                                tmpBlock.map((i) => ({
                                                    ...i,
                                                    [key]: value,
                                                })),
                                            );
                                        }}
                                        size="small"
                                        type="primary"
                                    >
                                        Apply to all
                                    </Button>
                                </>
                            );
                        },
                    },
                    {
                        title: "Weight",
                        dataIndex: "weight",
                        render: (v, data) => {
                            return (
                                <Input
                                    type="number"
                                    style={{
                                        minWidth: "100px",
                                    }}
                                    disabled={disableWrite}
                                    value={v}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(items);
                                        console.log(data?.key);
                                        tmpBlock[data?.key].weight =
                                            e.target.value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Max Limit",
                        dataIndex: "maxDragLimit",
                        render: (v, data) => {
                            return (
                                <>
                                    <Input
                                        disabled={disableWrite}
                                        type="number"
                                        style={{
                                            minWidth: "100px",
                                        }}
                                        value={v}
                                        onChange={(e) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[data?.key].maxDragLimit =
                                                e.target.value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                    <Button
                                        disabled={disableWrite}
                                        onClick={() => {
                                            const key = "maxDragLimit";
                                            let tmpBlock = cloneDeep(items);
                                            const value =
                                                tmpBlock[data?.key][key];
                                            setBlock(
                                                tmpBlock.map((i) => ({
                                                    ...i,
                                                    [key]: value,
                                                })),
                                            );
                                        }}
                                        size="small"
                                        type="primary"
                                    >
                                        Apply to all
                                    </Button>
                                </>
                            );
                        },
                    },
                    {
                        title: "Is enabled?",
                        dataIndex: "enable",
                        render: (v, data) => {
                            return (
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={v}
                                    onChange={(value) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].enable = value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    // {
                    //     title: "Max used (hide tag)?",
                    //     dataIndex: "type1",
                    //     render: (v, data) => {
                    //         return (
                    //             <Switch
                    //                 disabled={disableWrite}
                    //                 checkedChildren={<CheckOutlined />}
                    //                 unCheckedChildren={<CloseOutlined />}
                    //                 checked={!v}
                    //                 onChange={(value) => {
                    //                     let tmpBlock = cloneDeep(items);
                    //                     tmpBlock[data?.key].type1 = value
                    //                         ? 0
                    //                         : 1;
                    //                     tmpBlock[data?.key].bg_type1 = value
                    //                         ? 1
                    //                         : 0;
                    //                     setBlock(tmpBlock);
                    //                 }}
                    //             />
                    //         );
                    //     },
                    // },
                    {
                        title: "padding",
                        dataIndex: "padding",
                        width: 400,
                        render: (v, data) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <TablePaddingEditor
                                        blockData={data}
                                        setBlock={(item) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[data?.key].padding =
                                                item.padding;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </div>
                            );
                        },
                    },
                    {
                        title: "Border",
                        dataIndex: "border",
                        width: 400,
                        render: (v, data) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <TableBorderEditor
                                        blockData={data}
                                        setBlock={(item) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[data?.key].border =
                                                item.border;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </div>
                            );
                        },
                    },
                    ...effectColumn,
                    {
                        title: "Actions",
                        key: "operation",
                        fixed: "right",
                        width: 100,
                        render: (v, data) => (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                }}
                            >
                                <CopyIcon
                                    disabled={disableWrite}
                                    isButton={true}
                                    onClick={() => {
                                        let tmpItems = cloneDeep(items);
                                        tmpItems.splice(data.key, 0, {
                                            ...cloneDeep(tmpItems[data.key]),
                                            tagId: uuid(),
                                        });
                                        setBlock(tmpItems);
                                        message.info(`Tag duplicated!`);
                                    }}
                                />
                                <DeleteIcon
                                    title="Delete this?"
                                    disabled={disableWrite}
                                    onConfirm={() => {
                                        let tmpBlock = cloneDeep(items);
                                        pullAt(tmpBlock, data.key);
                                        setBlock(tmpBlock);
                                        message.success("successfully removed");
                                    }}
                                />
                            </div>
                        ),
                    },
                ]}
                rowKey="index"
                bordered
                footer={() => (
                    <>
                        <Button
                            disabled={disableWrite}
                            onClick={() => {
                                let tmpItems = cloneDeep(items);
                                tmpItems.push({ ...schema, tagId: uuid() });
                                setBlock(tmpItems);
                            }}
                        >
                            Add Tag
                        </Button>
                        {!items?.length && (
                            <span
                                style={{
                                    color: "red",
                                    marginLeft: "10px",
                                    fontSize: "16px",
                                }}
                            >
                                No Tags Added
                            </span>
                        )}
                    </>
                )}
            />
        </>
    );
};

export default TagContainer;
