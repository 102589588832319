import React from "react";
import { Row, Button } from "antd";

export const LoadMoreBtn: React.FC<any> = (props) => {
    const {
        aggregate,
        dataLength,
        onClick,
        text = "Load More",
        setFetching,
        loadData,
    } = props;
    const [offset, setOffset] = React.useState(0);

    const handleFetch = async () => {
        setFetching(true);
        try {
            setOffset(offset + 12);
            await loadData(offset + 12, "add");
        } catch (err) {
            throw new Error(err.message);
        }
        setFetching(!true);
    };

    return aggregate > dataLength ? (
        <>
            <br />
            <Row justify="center">
                <Button type="primary" onClick={handleFetch}>
                    {text}
                </Button>
            </Row>
        </>
    ) : null;
};
