import renderBlockInputExpandNumber from "./renderBlock";
import renderEditorInputExpandNumber from "./renderEditor";
import isValidCallbackInputExpandNumber from "./isValid";
import INPUT_EXPAND_NUMBER_DEFAULT_VALUE from "./defaultValue";
import renderSettingsInputExpandNumber from "./renderSettings";
import renderCardInputExpandNumber from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common";

const { INPUT_EXPAND_NUMBER_BLOCK } = BLOCK_TYPES;

const INPUT_EXPAND_NUMBER_BLOCK_CONFIG = {
    [INPUT_EXPAND_NUMBER_BLOCK]: {
        label: "Input Expand Number",
        defaultValue: INPUT_EXPAND_NUMBER_DEFAULT_VALUE,
        renderBlock: renderBlockInputExpandNumber,
        renderEditor: renderEditorInputExpandNumber,
        renderCard: renderCardInputExpandNumber,
        renderSettings: renderSettingsInputExpandNumber,
        addCallback,
        isValidCallback: isValidCallbackInputExpandNumber,
    },
};

export {
    renderBlockInputExpandNumber,
    renderCardInputExpandNumber,
    renderEditorInputExpandNumber,
    renderSettingsInputExpandNumber,
    isValidCallbackInputExpandNumber,
    INPUT_EXPAND_NUMBER_DEFAULT_VALUE,
    INPUT_EXPAND_NUMBER_BLOCK_CONFIG,
};
