import { BLOCK_TYPES } from "../../common/index";
import { GRID_INPUT_OPTIONS, PUZZLE_OTHER } from "../Crossword/defaultValue";
const { COMPARE_DIGITS_BLOCK } = BLOCK_TYPES;

const COMPARE_DIGITS_DEFAULT_VALUE = {
    type: COMPARE_DIGITS_BLOCK,
    data: {
        [COMPARE_DIGITS_BLOCK]: {
            options: GRID_INPUT_OPTIONS(),
            correct_answer: [],
            compute_function: { is_enabled: false, value: "" },
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
        other: {
            ...PUZZLE_OTHER,
            feedbacks: [], // DEFAULT_FEEDBACKS,
        },
    },
};

export default COMPARE_DIGITS_DEFAULT_VALUE;
