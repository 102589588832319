import { gql } from "@apollo/client";export default gql`mutation updateWorksheet(
    $id: Int!
    $object: worksheet_worksheet_set_input = {}
) {
    updateWorksheet: update_worksheet_worksheet_by_pk(
        pk_columns: { id: $id }
        _set: $object
    ) {
        id
        status
        banner
        created_at
        title
        type
        class
        subject
        updated_at
        user_id
        slug
        user {
            id
            name
            email
        }
        tags {
            id
            tag_id
            tag {
                id
                name
                value
            }
        }
        other
    }
}
`;
