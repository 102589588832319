import SWIPE_AND_FORM_DEFAULT_VALUE from "./defaultValue";
import renderCardSwipeAndForm from "./renderCard";
import renderBlockSwipeAndForm from "./renderBlock";
import renderSettingsSwipeAndForm from "./renderSettings";
import isValidCallbackSwipeAndForm from "./isValid";
import renderEditorSwipeAndForm from "./renderEditor";
import { addCallback, BLOCK_TYPES } from "../../common";

const { SWIPE_AND_FORM_BLOCK } = BLOCK_TYPES;

const SWIPE_AND_FORM_BLOCK_CONFIG = {
    [SWIPE_AND_FORM_BLOCK]: {
        label: "Swipe and Form",
        defaultValue: SWIPE_AND_FORM_DEFAULT_VALUE,
        renderBlock: renderBlockSwipeAndForm,
        renderEditor: renderEditorSwipeAndForm,
        renderCard: renderCardSwipeAndForm,
        renderSettings: renderSettingsSwipeAndForm,
        addCallback,
        isValidCallback: isValidCallbackSwipeAndForm,
    },
};

export {
    SWIPE_AND_FORM_DEFAULT_VALUE,
    SWIPE_AND_FORM_BLOCK_CONFIG,
    renderBlockSwipeAndForm,
    renderCardSwipeAndForm,
    renderSettingsSwipeAndForm,
    renderEditorSwipeAndForm,
    isValidCallbackSwipeAndForm,
};
