import { renderAction } from "./renderers/renderAction";
import { renderStatus } from "./renderers/renderStatus";
import { renderTitle } from "./renderers/renderTitle";
import { renderCover } from "./renderers/renderCover";
import { renderSort } from "./renderers/renderSort";

export const getListColumns = ({
    userData,
    updateUserOther,
    group_id,
    dataSource,
    refetchWorksheets,
    redirect_to,
    tabType,
}: any) => {
    return [
        {
            title: "Sort",
            dataIndex: "sort",
            width: 15,
            className: "drag-visible",
            render: () => renderSort(),
        },

        {
            title: "Cover",
            key: "cover",
            render: (text, record) => renderCover({ record }),
            width: 200,
        },
        {
            title: "Title",
            key: "title",
            render: (text, record) => renderTitle({ record }),
        },

        {
            title: "Status",
            key: "status",
            render: (text, record) => renderStatus({ record }),
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            render: (text, record) => {
                return renderAction({
                    record,
                    userData,
                    updateUserOther,
                    group_id,
                    dataSource,
                    refetchWorksheets,
                    redirect_to,
                    tabType,
                });
                //         items={[
                //             {
                //                 key: "1",
                //                 label: (
                //                     <CopyWorksheetModal
                //                         worksheetId={record.id}
                //                     />
                //                 ),
                //             },
                //             {
                //                 key: "2",
                //                 label: (
                //                     <span
                //                         onClick={() => {
                //                             const { other, id } = userData;
                //                             const payload = {
                //                                 content_testing_mobile_data: {
                //                                     testing_type: "worksheet",
                //                                     worksheet_id: record?.id,
                //                                 },
                //                             };
                //                             updateUserOther({
                //                                 id,
                //                                 other: other
                //                                     ? { ...other, ...payload }
                //                                     : payload,
                //                             });
                //                         }}
                //                     >
                //                         Mirror Preview
                //                     </span>
                //                 ),
                //             },
                //         ]}
                //     />
                // );
                // return (
                //     <Space size="small">
                //         <a
                //             href={`/worksheet/preview/${record.id}`}
                //             target="_blank"
                //         >
                //             <ViewIcon isButton={true} />
                //         </a>
                //         <Divider />

                //         <Can I="create" a={"worksheet"}>
                //             {(allowed) => (
                //                 <DuplicateWorksheetButton
                //                     worksheetId={record.id}
                //                     groupId={group_id}
                //                     order={dataSource?.length || 1}
                //                     callback={refetchWorksheets}
                //                     disabled={!allowed}
                //                 />
                //             )}
                //         </Can>
                //         <Divider />

                //         <Can I="edit" a={"worksheet"} passThrough>
                //             {(allowed) => (
                //                 <Link
                //                     href={{
                //                         pathname: `/worksheet/update_enhanced/${record?.id}`,
                //                         query: {
                //                             redirect_to,
                //                             group_id,
                //                             tabType,
                //                         },
                //                     }}
                //                 >
                //                     <EditIcon
                //                         isButton={true}
                //                         disabled={!allowed}
                //                     />
                //                 </Link>
                //             )}
                //         </Can>

                //         <Divider />
                //         <Can I="delete" a={"worksheet"} passThrough>
                //             {(allowed) =>
                //                 allowed && (
                //                     <DeleteView
                //                         id={record?.id}
                //                         isButton={true}
                //                         disabled={!allowed}
                //                         callback={refetchWorksheets}
                //                     />
                //                 )
                //             }
                //         </Can>

                //         <Button
                //             icon={<CompressOutlined />}
                //             shape="circle"
                //             type="dashed"
                //             onClick={async () => {
                //                 const { other, id } = userData;
                //                 const payload = {
                //                     content_testing_mobile_data: {
                //                         testing_type: "worksheet",
                //                         worksheet_id: record?.id,
                //                     },
                //                 };
                //                 await updateUserOther({
                //                     id,
                //                     other: other
                //                         ? { ...other, ...payload }
                //                         : payload,
                //                 });
                //                 message.success("successfully mirrored!");
                //             }}
                //         />
                //         <Divider />

                //         <Can I="publish" a={"worksheet"} passThrough>
                //             {(allowed) =>
                //                 allowed ? (
                //                     <PublishWorksheetButton
                //                         worksheet_id={record?.id}
                //                         disabled={false}
                //                     />
                //                 ) : null
                //             }
                //         </Can>

                //         <Divider />

                //         <Can I="create" a={"worksheet"}>
                //             {(allowed) => (
                //                 <Dropdown overlay={menu} trigger={["click"]}>
                //                     <a onClick={(e) => e.preventDefault()}>
                //                         <Space>
                //                             <EllipseIcon
                //                                 isButton={true}
                //                                 type={"dashed"}
                //                             />
                //                         </Space>
                //                     </a>
                //                 </Dropdown>
                //             )}
                //         </Can>
                //     </Space>
                // );
            },
        },
    ];
};
