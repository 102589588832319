import { SlateEditor } from "src/components/plate";
import { Button, Col, Form, Row, Select, Switch } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { AudioPicker, BLOCK_TYPES, DurationPicker } from "../../common/index";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { CustomKeyboardEditor } from "src/components";
import FeedbackField from "../../common/FeedBackField";

import PreviewBlocksField from "../../common/PreviewBlocksField";
import GridOptions from "../../common/GridField/GridOptions";
import { parseCMSJson } from "src/modules/worksheet/views/edit_enhanced/helpers";
import { GRID_INPUT_OPTIONS } from "../Crossword/defaultValue";

const { DRAG_AND_INPUT_BLOCK } = BLOCK_TYPES;

const Editor = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    setIsNewBlock,
    isNewBlock,
    worksheet,

    showDuration = true,
    showBackgroundAudio = true,
    blockType = DRAG_AND_INPUT_BLOCK,
    showFeedback = false,
    hasMentions,
    mentionsList = [],
    disableWrite,
    hasPreviewBlocks,
    isReadOnlyMode,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [blockType]: {
                text = [],
                custom_keyboard = [],
                has_options,
                has_multiple,
                blanks = [],
                options = [],
                question_audio,
            },
            other: { description = [], global_feedback },
        },
    } = block;

    const [currentEditor, setCurrentEditor] = useState("");
    const [loading, setLoading] = useState(false);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [curCell, setCurCell] = useState({});

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    useEffect(() => {
        setLoading(true);
        // if (!disableWrite) setCurrentEditor(`${id || tmpId}_description`);
        setTimeout(() => setLoading(false), 50);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const showFeedbackModal = () => {
        setCurCell(
            global_feedback || {
                index: 0,
                values: [],
            },
        );
        setIsFeedbackModalOpen(true);
    };

    const closeForm = () => {
        setIsFeedbackModalOpen(false);
    };

    const feedbackFieldProps = {
        isModalOpen: isFeedbackModalOpen,
        isGlobal: true,
        closeForm,
        feedback: curCell,
        onSave: (data: any) => {
            const obj = {
                ...block,
                data: {
                    ...block.data,
                    other: { ...block.data.other, global_feedback: data },
                },
            };
            setBlock(obj);
        },
        curIndex: 0,
        isEditor,
        setIsEditor,
        hasMentions,
        mentionsList,
        disableWrite,
        isReadOnlyMode,
    };

    return (
        <div>
            <FeedbackField {...feedbackFieldProps} />
            <h3
                style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                }}
            >
                Preview Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                {hasPreviewBlocks && (
                    <PreviewBlocksField
                        disableWrite={disableWrite}
                        fixed_blocks={block?.data?.other?.fixed_blocks || []}
                        updateFixedBlocks={(data: any) => {
                            const obj = {
                                ...block,
                                data: {
                                    ...block.data,
                                    other: {
                                        ...block.data.other,
                                        fixed_blocks: data,
                                    },
                                },
                            };
                            setBlock(obj);
                        }}
                    />
                )}

                <Col span={22}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Question
                    </h3>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_description`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "other", "description"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={
                            currentEditor === `${id || tmpId}_description`
                        }
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={description}
                        placeholder={"Type your description here..."}
                    />
                </Col>

                <Col span={24}>
                    {showBackgroundAudio &&
                        [
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <h3>Background Audio</h3>
                                <AudioPicker
                                    disabled={isReadOnlyMode}
                                    block={block}
                                    setBlock={setBlock}
                                />
                            </div>
                        )}
                </Col>
            </Row>

            <h3
                style={{
                    fontWeight: "bold",
                    margin: "10px 0",
                }}
            >
                Play Area
            </h3>

            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                <Col span={24}>
                    <div
                        style={{
                            margin: "20px 0",
                        }}
                    >
                        <h3>Question Audio</h3>
                        <AudioPicker
                            disabled={isReadOnlyMode}
                            block={{
                                data: {
                                    other: {
                                        audio: question_audio,
                                    },
                                },
                            }}
                            setBlockCustom={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", blockType, "question_audio"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </div>
                </Col>

                <Col span={22}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Fill blank Text
                    </h3>
                    <SlateEditor
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        disabled={disableWrite}
                        id={`${id || tmpId}_text`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", blockType, "text"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        value={text}
                        isFocused={currentEditor === `${id || tmpId}_text`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                    />
                </Col>
                <Col span={24}>
                    {Array(parseCMSJson(text).split(`()`).length - 1)
                        .fill()
                        .map((a, blankIdx) => (
                            <div>
                                <h3>Blank {blankIdx + 1}</h3>
                                <hr />
                                {/* blank start */}

                                <div style={{ margin: "10px 0" }}>
                                    <h4>Correct Answers</h4>

                                    {/* {hasMentions &&
                                        mentionsList?.length > 0 && (
                                            <Checkbox
                                                style={{
                                                    marginBottom: "20px",
                                                }}
                                                disabled={disableWrite}
                                                checked={
                                                    blanks[blankIdx]
                                                        ?.is_correct_input
                                                }
                                                onChange={(e) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "data",
                                                            blockType,
                                                            "blanks",
                                                            blankIdx,
                                                            "is_correct_input",
                                                        ],
                                                        e.target.checked,
                                                    );

                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "data",
                                                            blockType,
                                                            "blanks",
                                                            blankIdx,
                                                            "correct_answer",
                                                        ],
                                                        [],
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                            >
                                                {`Use Input Variables as Correct Answers`}
                                            </Checkbox>
                                        )} */}

                                    {/* {blanks[blankIdx]?.is_correct_input ? (
                                        <Select
                                            disabled={disableWrite}
                                            value={
                                                blanks[blankIdx]
                                                    ?.correct_answer || []
                                            }
                                            placeholder="Correct Answers"
                                            style={{ width: "100%" }}
                                            onChange={(value: any) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "data",
                                                        blockType,
                                                        "blanks",
                                                        blankIdx,
                                                        "correct_answer",
                                                    ],
                                                    value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                            mode={`multiple`}
                                        >
                                            {mentionsList.map(
                                                (item: any, idx: number) => {
                                                    return (
                                                        <Select.Option
                                                            value={`@@${item?.data}@@`}
                                                            key={idx}
                                                        >
                                                            {item?.data}
                                                        </Select.Option>
                                                    );
                                                },
                                            )}
                                        </Select>
                                    ) : ( */}
                                    <Select
                                        disabled={disableWrite}
                                        value={
                                            blanks[blankIdx]?.correct_answer ||
                                            []
                                        }
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder="Correct Answers"
                                        onChange={(value) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    blockType,
                                                    "blanks",
                                                    blankIdx,
                                                    "correct_answer",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                        options={
                                            options?.length
                                                ? options[0].map(
                                                      ({ value }: any) => ({
                                                          label: value,
                                                          value: value,
                                                      }),
                                                  )
                                                : []
                                        }
                                    />
                                    {/* )} */}
                                </div>
                                <br />
                            </div>
                        ))}
                    <Form.Item label="Has Multiple">
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={has_multiple}
                            disabled={disableWrite}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", blockType, "has_multiple"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Has Options">
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={has_options}
                            disabled={disableWrite}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", blockType, "has_options"],
                                    value,
                                );
                                if (value && !options?.length)
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", blockType, "options"],
                                        GRID_INPUT_OPTIONS(),
                                    );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    {!has_options ? (
                        <Col span={12}>
                            {/* <h3 style={{ color: "red" }}>
                                {!custom_keyboard?.length &&
                                    "Update Custom Keyboard!"}
                            </h3> */}
                            <Form.Item label="Custom Keyboard">
                                <CustomKeyboardEditor
                                    disabled={disableWrite}
                                    value={custom_keyboard}
                                    onOk={(data: any) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "data",
                                                blockType,
                                                "custom_keyboard",
                                            ],
                                            data,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    ) : !loading ? (
                        <>
                            <h3 style={{ color: "red" }}>
                                {!options[0]?.length &&
                                    "Add atleast one option"}
                            </h3>
                            <GridOptions
                                disableWrite={disableWrite}
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                blockType={blockType}
                                crossword={block.data[blockType]}
                                setCrossword={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", blockType],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                setFeedback={() => {}}
                                isEditor={isEditor}
                                setIsEditor={setIsEditor}
                                showFeedback={showFeedback}
                                isReadOnlyMode={isReadOnlyMode}
                            />
                        </>
                    ) : (
                        <></>
                    )}

                    <Col span={24} style={{ marginTop: "20px" }}>
                        <Button onClick={() => showFeedbackModal()}>
                            {global_feedback &&
                            global_feedback.values?.length > 0
                                ? "Edit"
                                : "Add"}{" "}
                            Global Feedback
                        </Button>
                    </Col>

                    {showDuration &&
                        [
                            "timed",
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    margin: "20px 0",
                                }}
                            >
                                <h3>Duration</h3>
                                <DurationPicker
                                    disabled={disableWrite}
                                    onChange={onDurationChange}
                                    initialValue={
                                        block?.data?.other?.duration || 0
                                    }
                                />
                            </div>
                        )}
                </Col>
            </Row>
        </div>
    );
};

export default Editor;
