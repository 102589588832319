import { gql } from "@apollo/client";export default gql`query user($id: Int!) {
    user: user_by_pk(id: $id) {
        id
        name
        role
        email
        other
    }
}
`;
