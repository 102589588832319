import {
    getPreventDefaultHandler,
    indent,
    outdent,
    usePlateEditorRef,
    ToolbarButton,
} from "@udecode/plate";
import { FormatIndentDecrease } from "@styled-icons/material/FormatIndentDecrease";
import { FormatIndentIncrease } from "@styled-icons/material/FormatIndentIncrease";

export const IndentToolbarButton = () => {
    const editor = usePlateEditorRef();

    return (
        <>
            <ToolbarButton
                onMouseDown={
                    editor && getPreventDefaultHandler(outdent, editor)
                }
                icon={<FormatIndentDecrease />}
            />
            <ToolbarButton
                onMouseDown={editor && getPreventDefaultHandler(indent, editor)}
                icon={<FormatIndentIncrease />}
            />
        </>
    );
};
