import { DeleteOutlined } from "@ant-design/icons";
import { Divider, Input, Modal, Typography } from "antd";
import { Form, Space, Row, Col, Button, message, Select } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { SlateEditor } from "src/components";
import {
    FEEDBACK_LABEL_VALUES,
    FEEDBACK_OPTION,
    ANSWER_FEEDBACK_LABEL_VALUES,
    GLOBAL_FEEDBACK_LABEL_VALUES,
} from "../../renderers/Crossword/defaultValue";
import { AudioPicker, isEditorEmpty } from "..";
import { captureException } from "@sentry/react";

const FeedbackField = (props: any) => {
    const {
        isModalOpen,
        closeForm,
        feedback,
        onSave,
        curIndex,
        isEditor,
        setIsEditor,
        hasMentions,
        mentionsList = [],
        isGlobal = false,
        disableWrite,
        isReadOnlyMode,
    } = props;

    const tmpId = `${curIndex}_text`;

    const [currentEditor, setCurrentEditor] = useState("option_text_0");

    useEffect(() => {
        if (!isEditor) {
            setCurrentEditor("");
        }
    }, [tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor !== "" && setIsEditor) {
            setIsEditor(true);
        }
    }, [tmpId, currentEditor]);

    const [isSubmitting, setSubmitting] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [curFeedback, setCurFeedback] = useState(feedback);
    const [tempFeedback, setTempFeedback] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => setCurFeedback(feedback), [feedback]);

    useEffect(() => {
        if (
            curFeedback.values?.some(
                (val: { value: any; show: string }) =>
                    isEditorEmpty(val?.value) || !val?.show,
            )
        ) {
            setDisabled(true);
        } else setDisabled(false);
    }, [curFeedback]);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            await onSave(curFeedback);
            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e);
            console.log(e);
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        closeForm();
    };

    const renderFeedbackValue = (data: any, idx: number) => (
        <div
            style={{
                border: "1px solid #ECECEC",
                borderRadius: "15px",
                marginBottom: "10px",
                margin: "10px",
                padding: "10px",
            }}
        >
            <Row style={{ width: "100%" }} gutter={[20, 20]}>
                <Col span={21}>
                    <Form.Item label="Name">
                        <Select
                            disabled={isGlobal || disableWrite}
                            defaultValue={data?.name}
                            onChange={(type) => {
                                let tmpBlock = _.cloneDeep(curFeedback);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["values", idx, "name"],
                                    type,
                                );
                                setCurFeedback(tmpBlock);
                            }}
                            options={FEEDBACK_LABEL_VALUES}
                        />
                    </Form.Item>
                </Col>
                <Col span={2} style={{ paddingTop: "4px" }}>
                    <Button
                        disabled={disableWrite}
                        icon={<DeleteOutlined />}
                        type="primary"
                        shape="circle"
                        danger
                        size="small"
                        onClick={() => {
                            let tmpBlock = _.cloneDeep(curFeedback);
                            const tmpOptions = [...(curFeedback?.values || [])];
                            _.pullAt(tmpOptions, idx);

                            tmpBlock = _.set(tmpBlock, ["values"], tmpOptions);
                            setCurFeedback(tmpBlock);
                        }}
                    />
                </Col>

                <Col span={24}>
                    <Form.Item label="When to show?">
                        <Select
                            disabled={disableWrite}
                            defaultValue={data?.show}
                            onChange={(type) => {
                                let tmpBlock = _.cloneDeep(curFeedback);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["values", idx, "show"],
                                    type,
                                );
                                setCurFeedback(tmpBlock);
                            }}
                            options={
                                isGlobal
                                    ? GLOBAL_FEEDBACK_LABEL_VALUES
                                    : ANSWER_FEEDBACK_LABEL_VALUES
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>

            <h4>Value</h4>
            <Row
                key={idx}
                justify="start"
                style={{
                    border: "1px solid #ECECEC",
                    borderRadius: "15px",
                    marginBottom: "10px",
                }}
            >
                <Col
                    span={24}
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div style={{ width: "100%" }}>
                        <SlateEditor
                            disabled={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`option_text_${idx}`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(curFeedback);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["values", idx, "value"],
                                    value,
                                );
                                setCurFeedback(tmpBlock);
                            }}
                            value={data?.value}
                            isFocused={currentEditor === `option_text_${idx}`}
                            setEditor={(id: string) => {
                                setCurrentEditor(id);
                            }}
                            isOption={true}
                        />
                    </div>
                </Col>
            </Row>

            <div
                style={{
                    marginTop: "20px",
                }}
            >
                <h3>Audio</h3>
                <AudioPicker
                    audio={data?.audio}
                    disabled={isReadOnlyMode}
                    setBlockCustom={(value: any) => {
                        let tmpBlock = _.cloneDeep(curFeedback);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["values", idx, "audio"],
                            value,
                        );
                        setCurFeedback(tmpBlock);
                    }}
                />
            </div>
        </div>
    );

    useEffect(() => {
        if (loading) {
            setTimeout(() => setLoading(false), 50);
        }
    }, [loading]);

    return (
        <>
            <Modal
                title={
                    <Typography.Title level={4}>Add Feedbacks</Typography.Title>
                }
                onCancel={closeForm}
                open={isModalOpen}
                destroyOnClose
                width={800}
                footer={null}
            >
                <Form>
                    {curFeedback?.values?.length > 0 && (
                        <>
                            <Button
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied Feedbacks to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(curFeedback),
                                    );
                                }}
                            >
                                Copy this Feedback to Clipboard
                            </Button>
                            <Divider />
                        </>
                    )}
                    <Form.Item label={"Paste Feedback here"}>
                        <Input.TextArea
                            disabled={disableWrite}
                            value={tempFeedback}
                            placeholder="Paste Feedback here"
                            onChange={(e) => setTempFeedback(e.target.value)}
                        />
                        <Button
                            disabled={disableWrite}
                            style={{ marginTop: "10px" }}
                            type="primary"
                            onClick={() => {
                                try {
                                    const parsedJson = JSON.parse(tempFeedback);
                                    if (!Array.isArray(parsedJson?.values)) {
                                        message.error("Error in JSON!");
                                        return;
                                    }
                                    setLoading(true);
                                    setCurFeedback({
                                        ...parsedJson,
                                        index: feedback.index,
                                    });
                                    setTempFeedback("");
                                    message.success("Updated!");
                                } catch (e) {
                                    // captureException(e)
                                    message.error("Error in JSON!");
                                }
                            }}
                        >
                            Update Feedback
                        </Button>
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={24}>
                            <h4>Feedback Lines:</h4>
                            {!loading &&
                                curFeedback?.values?.map(
                                    (feedback: any, idx: number) =>
                                        renderFeedbackValue(feedback, idx),
                                )}

                            <Button
                                type="dashed"
                                disabled={disableWrite}
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(curFeedback);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "values",
                                            curFeedback?.values?.length || 0,
                                        ],
                                        curFeedback?.values?.length === 0
                                            ? FEEDBACK_OPTION(
                                                  isGlobal
                                                      ? {
                                                            show: GLOBAL_FEEDBACK_LABEL_VALUES[0]
                                                                .value,
                                                        }
                                                      : {},
                                              )
                                            : FEEDBACK_OPTION(
                                                  isGlobal
                                                      ? {
                                                            show: GLOBAL_FEEDBACK_LABEL_VALUES[1]
                                                                .value,
                                                        }
                                                      : {
                                                            name: FEEDBACK_LABEL_VALUES[1]
                                                                .value,
                                                        },
                                              ),
                                    );
                                    setCurFeedback(tmpBlock);
                                }}
                                style={{ margin: "0px 24px" }}
                            >
                                Add New Feedback Line
                            </Button>
                        </Col>
                        <Col style={{ marginTop: "30px" }} span={24}>
                            <Space style={{ float: "right" }}>
                                <Button type="default" onClick={closeForm}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                    disabled={isDisabled}
                                >
                                    Submit
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default FeedbackField;
