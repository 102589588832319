import { BLOCK_TYPES } from "../../common";
import { renderEditorCrossword } from "../Crossword";

const { V2_INPUT_AND_FORM_BLOCK } = BLOCK_TYPES;

const renderEditor = (props: any) =>
    renderEditorCrossword({
        ...props,
        blockType: V2_INPUT_AND_FORM_BLOCK,
    });

export default renderEditor;
