import { SlateEditor } from "src/components/plate";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Space,
    Switch,
    message,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { BLOCK_TYPES, DurationPicker } from "../../../common";
import FeedbackField from "../../../common/FeedBackField";
import PreviewBlocksField from "../../../common/PreviewBlocksField";
import { graphQLClient } from "src/modules/tab/components/PublishTabToProd";
import { gql } from "graphql-request";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { CustomKeyboardEditor, MathRender, RenderUpload } from "src/components";
import { DEFAULT_CUSTOM_KEYBOARD } from "src/components/CustomKeyboardEditor";
import FigmaStoryEditor from "./FigmaStoryEditor";
import TableEditor from "./TableEditor";
import ComponentsListEditor from "./ComponentsListEditor";
import { replaceCDNUrl } from "../../../helpers";
import { AudioPoolModal } from "./AudioPoolModal";
import { AudioGenerateComponent } from "src/modules/worksheet/components/WorksheetEditor/components/Modals/AudioGenerateComponent";
import CorrectValuesComponent from "./ComponentsListEditor/CorrectValuesComponent";
import { captureException } from "@sentry/react";

const { FIGMA_BLOCK } = BLOCK_TYPES;

export const DEFAULT_TABLE = {
    cells: [],
    gap: {
        horizontal: 0,
        vertical: 0,
    },
};

export const getFigmaJson = async (figmaContextId: string) => {
    const GET_QUESTIONS = gql`
        query ($figmaContextId: String!) {
            other_generic_data(
                where: { context_id: { _eq: $figmaContextId } }
            ) {
                data
                context_id
            }
        }
    `;

    const { other_generic_data }: any = await graphQLClient.request(
        GET_QUESTIONS,
        {
            figmaContextId,
        },
    );

    if (!other_generic_data || !other_generic_data[0])
        throw new Error("No Data");

    return JSON.parse(other_generic_data[0]?.data);
};

const Editor = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    setIsNewBlock,
    isNewBlock,
    worksheet,

    showDuration = true,
    showBackgroundAudio = true,
    hasMentions,
    mentionsList = [],
    disableWrite,
    hasPreviewBlocks,
    isReadOnlyMode,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [FIGMA_BLOCK]: {
                name = "",
                figma_data = {
                    url: "",
                    file_key: "",
                    node_id: "",
                },
                variables = [],
                referred_variables = [],
                compute_functions = [],
                render_via_pixi,
                hide_in_pixi,
            },
            other: {
                global_feedback = null,
                description = [],
                stories = [],
                is_fixed_block = false,
            } = {},
        },
    } = block;

    const [currentEditor, setCurrentEditor] = useState(``);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [curCell, setCurCell] = useState({});

    useEffect(() => {
        setCurrentEditor(``);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor !== "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    const showFeedbackModal = () => {
        setCurCell(
            global_feedback || {
                index: 0,
                values: [],
            },
        );
        setIsFeedbackModalOpen(true);
    };

    const feedbackFieldProps = {
        isModalOpen: isFeedbackModalOpen,
        isGlobal: true,
        closeForm: () => setIsFeedbackModalOpen(false),
        feedback: curCell,
        onSave: (data: any) => {
            const obj = {
                ...block,
                data: {
                    ...block.data,
                    other: { ...block.data.other, global_feedback: data },
                },
            };
            setBlock(obj);
        },
        curIndex: 0,
        isEditor,
        setIsEditor,
        hasMentions,
        mentionsList,
        disableWrite,
        isReadOnlyMode,
    };

    const getFigmaData = async (data: string) => {
        if (!data) return;
        message.loading("Fetching figma data");
        let tempData: {
            url?: string;
            file_key?: string;
            node_id?: string;
            env?: string;
        } = {};

        try {
            const urlObject = new URL(data);
            // const params = new URLSearchParams(urlObject.search);

            // const fileKey = urlObject.pathname.split("/")[2];
            // const nodeId = params.get("node-id")?.replace("-", ":");

            let figmaContextId = urlObject.pathname.split("/")[1];
            // URL decode the figma context id
            figmaContextId = decodeURIComponent(figmaContextId);
            const fileKey = figmaContextId.split("|")[1];
            const nodeId = figmaContextId.split("|")[2];
            const env = figmaContextId.split("|")[3];

            if (!fileKey || !nodeId) throw new Error();
            tempData = {
                url: data,
                file_key: fileKey,
                node_id: nodeId,
                env,
            };
        } catch (e) {
            // captureException(e)
            message.destroy();
            message.error("Invalid URL");
            return;
        }

        let tempVariables = [];
        let tempCompFuncs = [];
        let figmaName = "";

        try {
            const data = await getFigmaJson(
                `figma_screen|${tempData.file_key}|${tempData.node_id}${
                    tempData.env ? "|" + tempData.env : ""
                }`,
            );

            const prevVariables: any = {};
            const prevCompFuncs: any = {};

            variables.forEach(
                (v: { name: string | number; value: any; objectType: any }) => {
                    prevVariables[v.name] = {
                        value: v.value,
                        type: v.objectType,
                    };
                },
            );

            compute_functions.forEach((v: { name: string | number }) => {
                prevCompFuncs[v.name] = v;
            });

            console.log("dsta var", data.variables);

            tempVariables = (data.variables || [])
                .filter((v) => v.type === "GLOBAL")
                .map(
                    (v: {
                        name: any;
                        default: any;
                        defaultValue: any;
                        objectType: any;
                        type: any;
                        value: any;
                        schema: any;
                    }) => {
                        let newValue;

                        switch (v.objectType) {
                            case "DROPDOWN":
                            case "DROPDOWN_RICH_TEXT_OPTIONS":
                                newValue =
                                    prevVariables[v.name]?.type ===
                                        v.objectType &&
                                    prevVariables[v.name]?.value;

                                if (!newValue || !newValue?.length)
                                    newValue = v.default;

                                try {
                                    if (_.isString(newValue))
                                        newValue = JSON.parse(newValue);
                                    if (_.isArray(newValue)) {
                                        newValue = newValue.map((v) => ({
                                            value: v?.value,
                                            label: v?.label || v?.value,
                                        }));
                                    } else {
                                        newValue = [];
                                    }
                                } catch (e) {
                                    // captureException(e);
                                    console.log(e);
                                    newValue = [];
                                }
                                break;

                            case "TABLE_DATA":
                                newValue =
                                    prevVariables[v.name]?.type ===
                                        v.objectType &&
                                    prevVariables[v.name]?.value;

                                if (!newValue?.cells?.length)
                                    newValue = v.default || v.value;

                                try {
                                    if (_.isString(newValue))
                                        newValue = JSON.parse(newValue);
                                } catch (e) {
                                    // captureException(e);
                                    console.log(e);
                                    newValue = _.cloneDeep(DEFAULT_TABLE);
                                }
                                break;
                            case "AUDIO_POOL":
                            case "DYNAMIC_COMPONENTS_DATA":
                                newValue =
                                    prevVariables[v.name]?.type ===
                                        v.objectType &&
                                    prevVariables[v.name]?.value;

                                if (!newValue || !newValue?.length)
                                    newValue = v.default;

                                try {
                                    if (_.isString(newValue))
                                        newValue = JSON.parse(newValue);
                                } catch (e) {
                                    // captureException(e);
                                    console.log(e);
                                    newValue = [];
                                }
                                break;
                            case "MATHQUILL_RICH_TEXT":
                                newValue =
                                    prevVariables[v.name]?.type ===
                                        v.objectType &&
                                    prevVariables[v.name]?.value;

                                if (!newValue || !newValue?.length)
                                    newValue = v.default;
                                // console.log("newValue", newValue);
                                console.log("prevVariables", newValue);
                                try {
                                    if (_.isString(newValue))
                                        newValue = JSON.parse(v.default);
                                } catch (e) {
                                    // captureException(e);
                                    console.log(e);
                                    // newValue = v.default;
                                    newValue = {
                                        default_value: "",
                                        correct_values: [],
                                    };
                                }
                                break;
                            default:
                                newValue =
                                    (prevVariables[v.name]?.type ===
                                        v.objectType &&
                                        prevVariables[v.name]?.value) ||
                                    v.default ||
                                    v.defaultValue;
                                break;
                        }

                        let retVal: any = {
                            name: v.name,
                            value: newValue,
                            type: v.type,
                            objectType: v.objectType,
                        };

                        if (v.schema)
                            retVal.schema =
                                typeof v.schema === "string"
                                    ? JSON.parse(v.schema)
                                    : v.schema;
                        return retVal;
                    },
                );

            tempCompFuncs = (data.computeFunctions || [])
                .filter((v: { isGlobal: any }) => v.isGlobal)
                .map(
                    (v: {
                        name: string;
                        output: string;
                        params: any[];
                        type: string;
                    }) => {
                        let newValue =
                            prevCompFuncs[v.name]?.output || v.output;

                        return {
                            ...v,
                            output: newValue,
                        };
                    },
                );

            figmaName = data.name || "";
        } catch (e) {
            // captureException(e);
            console.log(e);
            message.destroy();
            message.error("Could not fetch the data. Please check the url!");
            return;
        }

        let tmpBlock = _.cloneDeep(block);
        tmpBlock = _.set(
            tmpBlock,
            ["data", FIGMA_BLOCK, "figma_data"],
            tempData,
        );
        tmpBlock = _.set(
            tmpBlock,
            ["data", FIGMA_BLOCK, "variables"],
            tempVariables,
        );
        tmpBlock = _.set(
            tmpBlock,
            ["data", FIGMA_BLOCK, "compute_functions"],
            tempCompFuncs,
        );
        tmpBlock = _.set(tmpBlock, ["data", FIGMA_BLOCK, "name"], figmaName);
        setBlock(tmpBlock);

        message.destroy();
        message.success("Data Updated");
    };

    const figmaStoryEditorProps = {
        stories,
        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        disableWrite,
        isReadOnlyMode,
        setStories: (data: any) => {
            const obj = {
                ...block,
                data: {
                    ...block.data,
                    other: { ...block.data.other, stories: data },
                },
            };
            setBlock(obj);
        },
    };

    const [openAudioModal, setOpenAudioModal] = useState("");

    const handleAddNewVariable = (varObj: any) => {
        let tmpBlock = _.cloneDeep(block);
        tmpBlock = _.set(
            tmpBlock,
            [
                "data",
                FIGMA_BLOCK,
                "variables",
                tmpBlock.data[FIGMA_BLOCK].variables.length,
            ],
            varObj,
        );

        setBlock(tmpBlock);
    };

    const updateAnotherVariable = (varName: string, value: any) => {
        let tmpBlock = _.cloneDeep(block);
        tmpBlock = _.set(
            tmpBlock,
            [
                "data",
                FIGMA_BLOCK,
                "variables",
                tmpBlock.data[FIGMA_BLOCK].variables.findIndex(
                    (v: any) => v.name === varName,
                ),
                "value",
            ],
            value,
        );

        setBlock(tmpBlock);
    };

    const handleUpdateAddVariable = (varObj: any) => {
        // if variable doesn't exist add new one else update the value
        if (
            block.data[FIGMA_BLOCK].variables.findIndex(
                (v: any) => v.name === varObj.name,
            ) === -1
        ) {
            handleAddNewVariable(varObj);
        } else {
            updateAnotherVariable(varObj.name, varObj.value);
        }
    };

    return (
        <div>
            <FeedbackField {...feedbackFieldProps} />
            <h3
                style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                }}
            >
                Preview Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                {hasPreviewBlocks && (
                    <PreviewBlocksField
                        disableWrite={disableWrite}
                        fixed_blocks={block?.data?.other?.fixed_blocks || []}
                        updateFixedBlocks={(data: any) => {
                            const obj = {
                                ...block,
                                data: {
                                    ...block.data,
                                    other: {
                                        ...block.data.other,
                                        fixed_blocks: data,
                                    },
                                },
                            };
                            setBlock(obj);
                        }}
                    />
                )}

                <Col span={22}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Question
                    </h3>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_description`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "other", "description"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={
                            currentEditor === `${id || tmpId}_description`
                        }
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={description}
                        placeholder={"Type your description here..."}
                    />
                </Col>

                <Col span={24}>
                    <Form.Item label={"Render via Pixi?"}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={render_via_pixi}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", FIGMA_BLOCK, "render_via_pixi"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={"Hide in pixi renderer?"}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={hide_in_pixi}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", FIGMA_BLOCK, "hide_in_pixi"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={"Use as fixed block?"}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={is_fixed_block}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "other", "is_fixed_block"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    {showBackgroundAudio &&
                        [
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <h3>Background Audio</h3>
                                <AudioGenerateComponent
                                    name={"Background Audio"}
                                    onSave={(data: any) => {
                                        setBlock({
                                            ...block,
                                            data: {
                                                ...block.data,
                                                other: {
                                                    ...block.data.other,
                                                    audio: data,
                                                },
                                            },
                                        });
                                    }}
                                    disableWrite={isReadOnlyMode}
                                    audioData={block?.data?.other?.audio}
                                />
                            </div>
                        )}
                </Col>
            </Row>

            <h3
                style={{
                    fontWeight: "bold",
                    margin: "10px 0",
                }}
            >
                Play Area
            </h3>

            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                <Col span={24}>
                    <Form.Item label="Figma URL">
                        <Input.Search
                            placeholder="Add Figma URL"
                            allowClear
                            defaultValue={figma_data?.url}
                            enterButton="Get Figma Data"
                            size="middle"
                            style={{
                                width: "100%",
                            }}
                            onSearch={async (data) => getFigmaData(data)}
                        />
                    </Form.Item>
                </Col>

                <Divider />

                <Col span={24}>
                    <Form.Item label="Figma Activity Name">
                        <i
                            style={{
                                wordWrap: "break-word",
                                color: "#555",
                            }}
                        >
                            {name}
                        </i>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Current Figma URL">
                        <i
                            style={{
                                wordWrap: "break-word",
                                color: "#555",
                            }}
                        >
                            <a
                                href={figma_data.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {" "}
                                {figma_data.url}
                            </a>
                        </i>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Figma File Key">
                        <i
                            style={{
                                wordWrap: "break-word",
                                color: "#555",
                            }}
                        >
                            {figma_data.file_key}
                        </i>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Figma Node ID">
                        <i
                            style={{
                                wordWrap: "break-word",
                                color: "#555",
                            }}
                        >
                            {figma_data.node_id}
                        </i>
                    </Form.Item>
                </Col>

                {hasMentions && (
                    <Form.Item label={<h3>Use Input variables</h3>}>
                        <Select
                            value={referred_variables}
                            style={{ minWidth: 150 }}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", FIGMA_BLOCK, "referred_variables"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            mode={`multiple`}
                            options={mentionsList.map((v) => ({
                                label: v.text,
                                value: v.text,
                            }))}
                        />
                    </Form.Item>
                )}

                <Col span={24}>
                    <h3>Variables</h3>
                    {variables?.length ? (
                        variables
                            .filter(
                                (v: { artificialVariable: boolean }) =>
                                    !v.artificialVariable,
                            )
                            .map(
                                (
                                    v: {
                                        name: string;
                                        objectType: string;
                                        value: any;
                                        schema: any;
                                    },
                                    i: number,
                                ) => (
                                    <Form.Item
                                        label={
                                            <div
                                                style={{
                                                    fontSize: "18px",
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                {v.name}
                                            </div>
                                        }
                                        key={i}
                                        {...([
                                            "RICH_TEXT",
                                            "LATEX",
                                            "TEXTAREA",
                                            "FILL",
                                            "DROPDOWN",
                                            "TABLE_DATA",
                                            "DROPDOWN_RICH_TEXT_OPTIONS",
                                            "MATHQUILL_RICH_TEXT",
                                        ].includes(v?.objectType)
                                            ? { labelCol: { span: 24 } }
                                            : {})}
                                    >
                                        {(() => {
                                            switch (v?.objectType) {
                                                case "BOOLEAN":
                                                    return (
                                                        <Switch
                                                            checkedChildren={
                                                                <CheckOutlined />
                                                            }
                                                            unCheckedChildren={
                                                                <CloseOutlined />
                                                            }
                                                            checked={Boolean(
                                                                v.value,
                                                            )}
                                                            onChange={(
                                                                value,
                                                            ) => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        block,
                                                                    );

                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            "data",
                                                                            FIGMA_BLOCK,
                                                                            "variables",
                                                                            i,
                                                                            "value",
                                                                        ],
                                                                        value,
                                                                    );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />
                                                    );
                                                case "STRING":
                                                case "NUMBER":
                                                case "COLOR":
                                                    return (
                                                        <Input
                                                            type={v?.objectType?.toLowerCase()}
                                                            value={v.value}
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            onChange={(e) => {
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        block,
                                                                    );

                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            "data",
                                                                            FIGMA_BLOCK,
                                                                            "variables",
                                                                            i,
                                                                            "value",
                                                                        ],
                                                                        value,
                                                                    );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />
                                                    );
                                                case "TEXTAREA":
                                                case "JSONSTRING":
                                                    return (
                                                        <Input.TextArea
                                                            rows={5}
                                                            value={v.value}
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            onChange={(e) => {
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        block,
                                                                    );

                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            "data",
                                                                            FIGMA_BLOCK,
                                                                            "variables",
                                                                            i,
                                                                            "value",
                                                                        ],
                                                                        value,
                                                                    );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />
                                                    );
                                                case "CUSTOM_KEYBOARD":
                                                case "RICH_TEXT_CUSTOM_KEYBOARD":
                                                    return (
                                                        <>
                                                            <CustomKeyboardEditor
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                hasLatex={
                                                                    v?.objectType ===
                                                                    "RICH_TEXT_CUSTOM_KEYBOARD"
                                                                }
                                                                value={
                                                                    _.isArray(
                                                                        v.value,
                                                                    )
                                                                        ? v.value
                                                                        : DEFAULT_CUSTOM_KEYBOARD
                                                                }
                                                                onOk={(
                                                                    data: any,
                                                                ) => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            block,
                                                                        );
                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                "data",
                                                                                FIGMA_BLOCK,
                                                                                "variables",
                                                                                i,
                                                                                "value",
                                                                            ],
                                                                            data,
                                                                        );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                            {!v?.value
                                                                ?.length && (
                                                                <div
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    Not added!
                                                                </div>
                                                            )}
                                                        </>
                                                    );
                                                case "LATEX":
                                                    return (
                                                        <>
                                                            <Input.TextArea
                                                                style={{
                                                                    width: "100%",
                                                                    flexShrink: 0,
                                                                }}
                                                                rows={5}
                                                                value={v.value}
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    const value =
                                                                        e.target
                                                                            .value;
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            block,
                                                                        );

                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                "data",
                                                                                FIGMA_BLOCK,
                                                                                "variables",
                                                                                i,
                                                                                "value",
                                                                            ],
                                                                            value,
                                                                        );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "15px",
                                                                    padding:
                                                                        "10px",
                                                                    width: "100%",
                                                                    border: "1px dashed #aaa",
                                                                }}
                                                            >
                                                                <MathRender
                                                                    src={
                                                                        v.value
                                                                            ? `$${v.value}$`
                                                                            : ""
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    );
                                                case "RICH_TEXT":
                                                    return (
                                                        <>
                                                            <SlateEditor
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                hasMentions={
                                                                    false // hasMentions
                                                                }
                                                                mentionsList={
                                                                    mentionsList
                                                                }
                                                                id={`${
                                                                    id || tmpId
                                                                }_figma_${i}`}
                                                                onChange={(
                                                                    value: any,
                                                                ) => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            block,
                                                                        );
                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                "data",
                                                                                FIGMA_BLOCK,
                                                                                "variables",
                                                                                i,
                                                                                "value",
                                                                            ],
                                                                            value,
                                                                        );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                                isFocused={
                                                                    currentEditor ===
                                                                    `${
                                                                        id ||
                                                                        tmpId
                                                                    }_figma_${i}`
                                                                }
                                                                setEditor={(
                                                                    id: string,
                                                                ) =>
                                                                    setCurrentEditor(
                                                                        id,
                                                                    )
                                                                }
                                                                value={
                                                                    v?.value ||
                                                                    ""
                                                                }
                                                                placeholder={
                                                                    "Type here..."
                                                                }
                                                            />
                                                        </>
                                                    );
                                                case "FILL":
                                                    return (
                                                        <>
                                                            <Form.Item label="Background Type">
                                                                <Select
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    defaultValue={
                                                                        v?.value
                                                                            ?.type
                                                                    }
                                                                    style={{
                                                                        width: 200,
                                                                        marginBottom:
                                                                            "20px",
                                                                    }}
                                                                    placeholder="Background Type"
                                                                    onChange={(
                                                                        value: any,
                                                                    ) => {
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                block,
                                                                            );

                                                                        tmpBlock =
                                                                            _.set(
                                                                                tmpBlock,
                                                                                [
                                                                                    "data",
                                                                                    FIGMA_BLOCK,
                                                                                    "variables",
                                                                                    i,
                                                                                    "value",
                                                                                    "type",
                                                                                ],
                                                                                value,
                                                                            );
                                                                        setBlock(
                                                                            tmpBlock,
                                                                        );
                                                                    }}
                                                                    options={[
                                                                        {
                                                                            value: "SOLID",
                                                                            label: "Solid Color",
                                                                        },
                                                                        {
                                                                            value: "IMAGE",
                                                                            label: "Image",
                                                                        },
                                                                        // {
                                                                        //     value: "GIF",
                                                                        //     label: "Gif",
                                                                        // },
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                            {v.value?.type ===
                                                                "SOLID" && (
                                                                <Form.Item label="Color">
                                                                    <Input
                                                                        type={
                                                                            "color"
                                                                        }
                                                                        value={
                                                                            v
                                                                                .value
                                                                                ?.color
                                                                        }
                                                                        disabled={
                                                                            disableWrite
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => {
                                                                            const value =
                                                                                e
                                                                                    .target
                                                                                    .value;
                                                                            let tmpBlock =
                                                                                _.cloneDeep(
                                                                                    block,
                                                                                );

                                                                            tmpBlock =
                                                                                _.set(
                                                                                    tmpBlock,
                                                                                    [
                                                                                        "data",
                                                                                        FIGMA_BLOCK,
                                                                                        "variables",
                                                                                        i,
                                                                                        "value",
                                                                                        "color",
                                                                                    ],
                                                                                    value,
                                                                                );
                                                                            setBlock(
                                                                                tmpBlock,
                                                                            );
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            )}
                                                            {[
                                                                "IMAGE",
                                                                "GIF",
                                                            ].includes(
                                                                v.value?.type,
                                                            ) && (
                                                                <>
                                                                    {/* <Form.Item label="Background scale mode">
                                                                <Select
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    defaultValue={
                                                                        v?.value
                                                                            ?.scaleMode
                                                                    }
                                                                    style={{
                                                                        width: 200,
                                                                        marginBottom:
                                                                            "20px",
                                                                    }}
                                                                    placeholder="Scale Mode"
                                                                    onChange={(
                                                                        value: any,
                                                                    ) => {
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                block,
                                                                            );
        
                                                                        tmpBlock =
                                                                            _.set(
                                                                                tmpBlock,
                                                                                [
                                                                                    "data",
                                                                                    FIGMA_BLOCK,
                                                                                    "variables",
                                                                                    i,
                                                                                    "value",
                                                                                    "scaleMode",
                                                                                ],
                                                                                value,
                                                                            );
                                                                        setBlock(
                                                                            tmpBlock,
                                                                        );
                                                                    }}
                                                                    options={[
                                                                        {
                                                                            value: "FILL",
                                                                            label: "Fill",
                                                                        },
                                                                        {
                                                                            value: "FIT",
                                                                            label: "Fit",
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item> */}
                                                                    <Form.Item label="Asset">
                                                                        <RenderUpload
                                                                            singleUpload={
                                                                                true
                                                                            }
                                                                            disabled={
                                                                                disableWrite
                                                                            }
                                                                            addExtension={
                                                                                true
                                                                            }
                                                                            path="home-explore/document/"
                                                                            onChangeCustom={(
                                                                                e,
                                                                            ) => {
                                                                                const value =
                                                                                    replaceCDNUrl(
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                        e
                                                                                            .target
                                                                                            .bucket,
                                                                                    );
                                                                                let tmpBlock =
                                                                                    _.cloneDeep(
                                                                                        block,
                                                                                    );

                                                                                tmpBlock =
                                                                                    _.set(
                                                                                        tmpBlock,
                                                                                        [
                                                                                            "data",
                                                                                            FIGMA_BLOCK,
                                                                                            "variables",
                                                                                            i,
                                                                                            "value",
                                                                                            "asset",
                                                                                        ],
                                                                                        value,
                                                                                    );
                                                                                setBlock(
                                                                                    tmpBlock,
                                                                                );
                                                                            }}
                                                                            label="upload the file"
                                                                            value={
                                                                                v
                                                                                    .value
                                                                                    ?.asset
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </>
                                                            )}
                                                        </>
                                                    );
                                                case "DROPDOWN_RICH_TEXT_OPTIONS":
                                                    return (
                                                        <>
                                                            {(
                                                                v?.value || []
                                                            ).map(
                                                                (
                                                                    dropOp: {
                                                                        label: any;
                                                                        value: string;
                                                                    },
                                                                    idx: number,
                                                                ) => (
                                                                    <Form.Item
                                                                        label={`Option ${
                                                                            idx +
                                                                            1
                                                                        }`}
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            flexDirection:
                                                                                "column",
                                                                            gap: "20px",
                                                                        }}
                                                                    >
                                                                        <SlateEditor
                                                                            disabled={
                                                                                disableWrite
                                                                            }
                                                                            hasMentions={
                                                                                false
                                                                            }
                                                                            mentionsList={[]}
                                                                            id={`${
                                                                                id ||
                                                                                tmpId
                                                                            }_${idx}_drop_label`}
                                                                            onChange={(
                                                                                value: any,
                                                                            ) => {
                                                                                let tmpBlock =
                                                                                    _.cloneDeep(
                                                                                        block,
                                                                                    );
                                                                                tmpBlock =
                                                                                    _.set(
                                                                                        tmpBlock,
                                                                                        [
                                                                                            "data",
                                                                                            FIGMA_BLOCK,
                                                                                            "variables",
                                                                                            i,
                                                                                            "value",
                                                                                            idx,
                                                                                            "label",
                                                                                        ],
                                                                                        value,
                                                                                    );
                                                                                setBlock(
                                                                                    tmpBlock,
                                                                                );
                                                                            }}
                                                                            isFocused={
                                                                                currentEditor ===
                                                                                `${
                                                                                    id ||
                                                                                    tmpId
                                                                                }_${idx}_drop_label`
                                                                            }
                                                                            setEditor={(
                                                                                id: string,
                                                                            ) =>
                                                                                setCurrentEditor(
                                                                                    id,
                                                                                )
                                                                            }
                                                                            value={
                                                                                dropOp?.label
                                                                            }
                                                                            placeholder={
                                                                                "Label"
                                                                            }
                                                                        />
                                                                        <Input
                                                                            placeholder="Value"
                                                                            value={
                                                                                dropOp?.value
                                                                            }
                                                                            disabled={
                                                                                disableWrite
                                                                            }
                                                                            onChange={(
                                                                                e,
                                                                            ) => {
                                                                                const value =
                                                                                    e
                                                                                        .target
                                                                                        .value;
                                                                                let tmpBlock =
                                                                                    _.cloneDeep(
                                                                                        block,
                                                                                    );

                                                                                tmpBlock =
                                                                                    _.set(
                                                                                        tmpBlock,
                                                                                        [
                                                                                            "data",
                                                                                            FIGMA_BLOCK,
                                                                                            "variables",
                                                                                            i,
                                                                                            "value",
                                                                                            idx,
                                                                                            "value",
                                                                                        ],
                                                                                        value,
                                                                                    );
                                                                                setBlock(
                                                                                    tmpBlock,
                                                                                );
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            ghost
                                                                            danger
                                                                            type="primary"
                                                                            onClick={() => {
                                                                                let tmpBlock =
                                                                                    _.cloneDeep(
                                                                                        block,
                                                                                    );
                                                                                const tmpOptions =
                                                                                    [
                                                                                        ...v.value,
                                                                                    ];
                                                                                _.pullAt(
                                                                                    tmpOptions,
                                                                                    idx,
                                                                                );
                                                                                tmpBlock =
                                                                                    _.set(
                                                                                        tmpBlock,
                                                                                        [
                                                                                            "data",
                                                                                            FIGMA_BLOCK,
                                                                                            "variables",
                                                                                            i,
                                                                                            "value",
                                                                                        ],
                                                                                        tmpOptions,
                                                                                    );

                                                                                setBlock(
                                                                                    tmpBlock,
                                                                                );
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    </Form.Item>
                                                                ),
                                                            )}
                                                            <Button
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                onClick={() => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            block,
                                                                        );

                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                "data",
                                                                                FIGMA_BLOCK,
                                                                                "variables",
                                                                                i,
                                                                                "value",
                                                                                v
                                                                                    .value
                                                                                    ?.length ||
                                                                                    0,
                                                                            ],
                                                                            {
                                                                                label: "",
                                                                                value: "",
                                                                            },
                                                                        );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            >
                                                                Add Option
                                                            </Button>
                                                        </>
                                                    );

                                                case "DROPDOWN":
                                                    return (
                                                        <>
                                                            {(
                                                                v?.value || []
                                                            ).map(
                                                                (
                                                                    dropOp: {
                                                                        label: string;
                                                                        value: string;
                                                                    },
                                                                    idx: number,
                                                                ) => (
                                                                    <Form.Item
                                                                        label={`Option ${
                                                                            idx +
                                                                            1
                                                                        }`}
                                                                    >
                                                                        <Space.Compact
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <Input
                                                                                placeholder="Label"
                                                                                value={
                                                                                    dropOp?.label
                                                                                }
                                                                                disabled={
                                                                                    disableWrite
                                                                                }
                                                                                onChange={(
                                                                                    e,
                                                                                ) => {
                                                                                    const value =
                                                                                        e
                                                                                            .target
                                                                                            .value;
                                                                                    let tmpBlock =
                                                                                        _.cloneDeep(
                                                                                            block,
                                                                                        );

                                                                                    tmpBlock =
                                                                                        _.set(
                                                                                            tmpBlock,
                                                                                            [
                                                                                                "data",
                                                                                                FIGMA_BLOCK,
                                                                                                "variables",
                                                                                                i,
                                                                                                "value",
                                                                                                idx,
                                                                                                "label",
                                                                                            ],
                                                                                            value,
                                                                                        );
                                                                                    setBlock(
                                                                                        tmpBlock,
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <Input
                                                                                placeholder="Value"
                                                                                value={
                                                                                    dropOp?.value
                                                                                }
                                                                                disabled={
                                                                                    disableWrite
                                                                                }
                                                                                onChange={(
                                                                                    e,
                                                                                ) => {
                                                                                    const value =
                                                                                        e
                                                                                            .target
                                                                                            .value;
                                                                                    let tmpBlock =
                                                                                        _.cloneDeep(
                                                                                            block,
                                                                                        );

                                                                                    tmpBlock =
                                                                                        _.set(
                                                                                            tmpBlock,
                                                                                            [
                                                                                                "data",
                                                                                                FIGMA_BLOCK,
                                                                                                "variables",
                                                                                                i,
                                                                                                "value",
                                                                                                idx,
                                                                                                "value",
                                                                                            ],
                                                                                            value,
                                                                                        );
                                                                                    setBlock(
                                                                                        tmpBlock,
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <Button
                                                                                ghost
                                                                                danger
                                                                                type="primary"
                                                                                onClick={() => {
                                                                                    let tmpBlock =
                                                                                        _.cloneDeep(
                                                                                            block,
                                                                                        );
                                                                                    const tmpOptions =
                                                                                        [
                                                                                            ...v.value,
                                                                                        ];
                                                                                    _.pullAt(
                                                                                        tmpOptions,
                                                                                        idx,
                                                                                    );
                                                                                    tmpBlock =
                                                                                        _.set(
                                                                                            tmpBlock,
                                                                                            [
                                                                                                "data",
                                                                                                FIGMA_BLOCK,
                                                                                                "variables",
                                                                                                i,
                                                                                                "value",
                                                                                            ],
                                                                                            tmpOptions,
                                                                                        );

                                                                                    setBlock(
                                                                                        tmpBlock,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Space.Compact>
                                                                    </Form.Item>
                                                                ),
                                                            )}
                                                            <Button
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                onClick={() => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            block,
                                                                        );

                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                "data",
                                                                                FIGMA_BLOCK,
                                                                                "variables",
                                                                                i,
                                                                                "value",
                                                                                v
                                                                                    .value
                                                                                    ?.length ||
                                                                                    0,
                                                                            ],
                                                                            {
                                                                                label: "",
                                                                                value: "",
                                                                            },
                                                                        );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            >
                                                                Add Option
                                                            </Button>
                                                        </>
                                                    );
                                                case "AUDIO":
                                                    return (
                                                        <AudioGenerateComponent
                                                            name={v.name}
                                                            disableWrite={
                                                                isReadOnlyMode
                                                            }
                                                            audioData={v.value}
                                                            onSave={(
                                                                value: any,
                                                            ) => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        block,
                                                                    );

                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            "data",
                                                                            FIGMA_BLOCK,
                                                                            "variables",
                                                                            i,
                                                                            "value",
                                                                        ],
                                                                        value,
                                                                    );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />
                                                    );
                                                case "TABLE_DATA":
                                                    return (
                                                        <TableEditor
                                                            table={
                                                                v.value ||
                                                                DEFAULT_TABLE
                                                            }
                                                            tableVariableName={
                                                                v.name
                                                            }
                                                            currentEditor={
                                                                currentEditor
                                                            }
                                                            setCurrentEditor={
                                                                setCurrentEditor
                                                            }
                                                            dropdownGlobalVariables={variables.filter(
                                                                (item: any) => {
                                                                    return (
                                                                        item.objectType ===
                                                                        "DROPDOWN"
                                                                    );
                                                                },
                                                            )}
                                                            droppableAreaIdsGlobalVariables={variables.filter(
                                                                (item: any) => {
                                                                    return (
                                                                        item.objectType ===
                                                                        "STRING"
                                                                    );
                                                                },
                                                            )}
                                                            disableWrite={
                                                                disableWrite
                                                            }
                                                            setBlock={(
                                                                value: any,
                                                                newVariables: any = [],
                                                            ) => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        block,
                                                                    );

                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            "data",
                                                                            FIGMA_BLOCK,
                                                                            "variables",
                                                                            i,
                                                                            "value",
                                                                        ],
                                                                        value,
                                                                    );
                                                                if (
                                                                    newVariables.length
                                                                ) {
                                                                    // Check if the variables already exist
                                                                    newVariables.forEach(
                                                                        (
                                                                            newVar: any,
                                                                        ) => {
                                                                            if (
                                                                                tmpBlock.data[
                                                                                    FIGMA_BLOCK
                                                                                ].variables.findIndex(
                                                                                    (
                                                                                        v: any,
                                                                                    ) =>
                                                                                        v.name ===
                                                                                        newVar.name,
                                                                                ) ===
                                                                                -1
                                                                            ) {
                                                                                tmpBlock =
                                                                                    _.set(
                                                                                        tmpBlock,
                                                                                        [
                                                                                            "data",
                                                                                            FIGMA_BLOCK,
                                                                                            "variables",
                                                                                        ],
                                                                                        [
                                                                                            ...tmpBlock
                                                                                                .data[
                                                                                                FIGMA_BLOCK
                                                                                            ]
                                                                                                .variables,
                                                                                            newVar,
                                                                                        ],
                                                                                    );
                                                                            } else {
                                                                                // Set the value of the variable
                                                                                const currentVariable =
                                                                                    tmpBlock.data[
                                                                                        FIGMA_BLOCK
                                                                                    ].variables.find(
                                                                                        (
                                                                                            v: any,
                                                                                        ) =>
                                                                                            v.name ===
                                                                                            newVar.name,
                                                                                    );
                                                                                if (
                                                                                    currentVariable.artificialVariable
                                                                                ) {
                                                                                    tmpBlock =
                                                                                        _.set(
                                                                                            tmpBlock,
                                                                                            [
                                                                                                "data",
                                                                                                FIGMA_BLOCK,
                                                                                                "variables",
                                                                                                tmpBlock.data[
                                                                                                    FIGMA_BLOCK
                                                                                                ].variables.findIndex(
                                                                                                    (
                                                                                                        v: any,
                                                                                                    ) =>
                                                                                                        v.name ===
                                                                                                        newVar.name,
                                                                                                ),
                                                                                                "value",
                                                                                            ],
                                                                                            newVar.value,
                                                                                        );
                                                                                } else {
                                                                                    tmpBlock =
                                                                                        _.set(
                                                                                            tmpBlock,
                                                                                            [
                                                                                                "data",
                                                                                                FIGMA_BLOCK,
                                                                                                "variables",
                                                                                                tmpBlock.data[
                                                                                                    FIGMA_BLOCK
                                                                                                ].variables.findIndex(
                                                                                                    (
                                                                                                        v: any,
                                                                                                    ) =>
                                                                                                        v.name ===
                                                                                                        newVar.name,
                                                                                                ),
                                                                                            ],
                                                                                            newVar,
                                                                                        );
                                                                                }
                                                                            }
                                                                        },
                                                                    );
                                                                }
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />
                                                    );

                                                case "AUDIO_POOL":
                                                    return (
                                                        <>
                                                            <Button
                                                                type="primary"
                                                                onClick={() =>
                                                                    setOpenAudioModal(
                                                                        v.name,
                                                                    )
                                                                }
                                                            >
                                                                Update Audio
                                                                Pool
                                                            </Button>
                                                            {openAudioModal ===
                                                                v.name && (
                                                                <AudioPoolModal
                                                                    isModalOpen={
                                                                        openAudioModal ===
                                                                        v.name
                                                                    }
                                                                    setIsModalOpen={(
                                                                        value: boolean,
                                                                    ) => {
                                                                        value ===
                                                                        true
                                                                            ? setOpenAudioModal(
                                                                                  v.name,
                                                                              )
                                                                            : setOpenAudioModal(
                                                                                  "",
                                                                              );
                                                                    }}
                                                                    name={
                                                                        v.name
                                                                    }
                                                                    audioPool={
                                                                        v.value
                                                                    }
                                                                    disableWrite={
                                                                        disableWrite
                                                                    }
                                                                    schema={
                                                                        v.schema
                                                                    }
                                                                    onSave={(
                                                                        value: any,
                                                                    ) => {
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                block,
                                                                            );

                                                                        tmpBlock =
                                                                            _.set(
                                                                                tmpBlock,
                                                                                [
                                                                                    "data",
                                                                                    FIGMA_BLOCK,
                                                                                    "variables",
                                                                                    i,
                                                                                    "value",
                                                                                ],
                                                                                value,
                                                                            );
                                                                        setBlock(
                                                                            tmpBlock,
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        </>
                                                    );
                                                case "DYNAMIC_COMPONENTS_DATA":
                                                    return (
                                                        <>
                                                            <Button
                                                                type="primary"
                                                                onClick={() =>
                                                                    setOpenAudioModal(
                                                                        v.name,
                                                                    )
                                                                }
                                                            >
                                                                Add Component
                                                                List Data
                                                            </Button>
                                                            {openAudioModal ===
                                                                v.name && (
                                                                <ComponentsListEditor
                                                                    onSave={(
                                                                        value: any,
                                                                    ) => {
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                block,
                                                                            );

                                                                        tmpBlock =
                                                                            _.set(
                                                                                tmpBlock,
                                                                                [
                                                                                    "data",
                                                                                    FIGMA_BLOCK,
                                                                                    "variables",
                                                                                    i,
                                                                                    "value",
                                                                                ],
                                                                                value,
                                                                            );
                                                                        setBlock(
                                                                            tmpBlock,
                                                                        );
                                                                    }}
                                                                    {...{
                                                                        closeForm:
                                                                            () =>
                                                                                setOpenAudioModal(
                                                                                    "",
                                                                                ),
                                                                        name: v.name,
                                                                        componentsList:
                                                                            v.value,
                                                                        componentsSchema:
                                                                            v.schema,
                                                                        isModalOpen:
                                                                            openAudioModal ===
                                                                            v.name,
                                                                        hasMentions,
                                                                        mentionsList,
                                                                        isEditor,
                                                                        setIsEditor,
                                                                        setIsNewBlock,
                                                                        isNewBlock,
                                                                        disableWrite,
                                                                    }}
                                                                />
                                                            )}
                                                            {!v?.value
                                                                ?.length && (
                                                                <div
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    Not added!
                                                                </div>
                                                            )}
                                                        </>
                                                    );
                                                case "MATHQUILL_RICH_TEXT": {
                                                    return (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: "10px",
                                                                flexDirection:
                                                                    "column",
                                                            }}
                                                        >
                                                            <Input.TextArea
                                                                style={{
                                                                    width: "100%",
                                                                    flexShrink: 0,
                                                                }}
                                                                rows={5}
                                                                value={
                                                                    v?.value
                                                                        ?.default_value ??
                                                                    ""
                                                                }
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    const value =
                                                                        e.target
                                                                            .value;
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            block,
                                                                        );

                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                "data",
                                                                                FIGMA_BLOCK,
                                                                                "variables",
                                                                                i,
                                                                                "value",
                                                                                "default_value",
                                                                            ],
                                                                            value,
                                                                        );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "15px",
                                                                    padding:
                                                                        "10px",
                                                                    width: "100%",
                                                                    border: "1px dashed #aaa",
                                                                }}
                                                            >
                                                                <MathRender
                                                                    src={
                                                                        v?.value
                                                                            ?.default_value
                                                                            ? `$${v?.value?.default_value}$`
                                                                            : ""
                                                                    }
                                                                />
                                                            </div>

                                                            <CorrectValuesComponent
                                                                data={{
                                                                    props: {
                                                                        default_value:
                                                                            {
                                                                                value:
                                                                                    v
                                                                                        ?.value
                                                                                        ?.default_value ??
                                                                                    "",
                                                                            },
                                                                    },
                                                                }}
                                                                correct_values={{
                                                                    value:
                                                                        v?.value
                                                                            ?.correct_values ||
                                                                        [],
                                                                    type: "MATHQUILL_RICH_TEXT",
                                                                }}
                                                                disableWrite={
                                                                    false
                                                                }
                                                                setCurConfig={(
                                                                    values: any[],
                                                                ) => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            block,
                                                                        );
                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                "data",
                                                                                FIGMA_BLOCK,
                                                                                "variables",
                                                                                i,
                                                                                "value",
                                                                                "correct_values",
                                                                            ],
                                                                            values,
                                                                        );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                }
                                                default:
                                                    return (
                                                        <Input
                                                            value={v.value}
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            onChange={(e) => {
                                                                const value =
                                                                    e.target
                                                                        .value;
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        block,
                                                                    );

                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            "data",
                                                                            FIGMA_BLOCK,
                                                                            "variables",
                                                                            i,
                                                                            "value",
                                                                        ],
                                                                        value,
                                                                    );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />
                                                    );
                                            }
                                        })()}
                                    </Form.Item>
                                ),
                            )
                    ) : (
                        <h4>No Variables</h4>
                    )}
                </Col>

                <Divider />
                <Col span={24}>
                    <h3>Global Compute Functions</h3>
                    {compute_functions?.length > 0 ? (
                        compute_functions.map(
                            (
                                v: {
                                    name: string;
                                    output: string;
                                    params: any[];
                                },
                                i: number,
                            ) => (
                                <Form.Item
                                    label={
                                        <div>
                                            <span
                                                style={{
                                                    fontSize: "18px",
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                {v.name}
                                            </span>
                                            <strong
                                                style={{
                                                    marginLeft: "10px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                (params: {v.params.join(", ")})
                                            </strong>
                                        </div>
                                    }
                                    key={i}
                                    labelCol={{ span: 24 }}
                                >
                                    {(() => {
                                        return (
                                            <Input.TextArea
                                                rows={5}
                                                value={v.output}
                                                disabled={disableWrite}
                                                onChange={(e) => {
                                                    const value =
                                                        e.target.value;
                                                    let tmpBlock =
                                                        _.cloneDeep(block);

                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "data",
                                                            FIGMA_BLOCK,
                                                            "compute_functions",
                                                            i,
                                                            "output",
                                                        ],
                                                        value,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                            />
                                        );
                                    })()}
                                </Form.Item>
                            ),
                        )
                    ) : (
                        <h4>No Global Compute Functions</h4>
                    )}
                </Col>

                {false && (
                    <Col span={24} style={{ marginTop: "20px" }}>
                        <Button onClick={() => showFeedbackModal()}>
                            {global_feedback &&
                            global_feedback.values?.length > 0
                                ? "Edit"
                                : "Add"}{" "}
                            Global Feedback
                        </Button>
                    </Col>
                )}

                {showDuration &&
                    [
                        "timed",
                        "personalized_learning",
                        "personalized_learning_v2",
                    ].includes(worksheet?.type) && (
                        <div
                            style={{
                                margin: "20px 0",
                            }}
                        >
                            <h3>Duration</h3>
                            <DurationPicker
                                disabled={disableWrite}
                                onChange={onDurationChange}
                                initialValue={block?.data?.other?.duration || 0}
                            />
                        </div>
                    )}

                <Col span={24}>
                    <Form.Item
                        label={"Stories"}
                        labelCol={{
                            span: 24,
                        }}
                    >
                        <FigmaStoryEditor {...figmaStoryEditorProps} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default Editor;
