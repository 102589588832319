import { EditOutlined } from "@ant-design/icons";
import { RenderUpload } from "src/components/form";
import { SlateEditor } from "src/components/plate";
import { Button, Modal } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { BLOCK_TYPES } from "../../common/index";

const { CONTENT_BLOCK } = BLOCK_TYPES;

const renderEditor = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    setIsNewBlock,
    isNewBlock,
    mentionsList = [],
    hasMentions,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [CONTENT_BLOCK]: {
                background: { image },
                title,
                description,
                button: { buttonColor, buttonText, textColor },
            },
        },
    } = block;

    const imageUrl = image;

    const [currentEditor, setCurrentEditor] = useState(`${id || tmpId}_text`);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_text`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const onImgChange = (e: any) => {
        let tmpBlock = _.cloneDeep(block);
        tmpBlock = _.set(
            tmpBlock,
            ["data", CONTENT_BLOCK, "background", "image"],
            e.target.value,
        );
        setBlock(tmpBlock);
    };

    return (
        <div
            style={{
                width: "412px",
                height: "732px",
                margin: "0 auto",
                border: "1px #111 solid",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                textAlign: "center",
                overflow: "hidden",
            }}
        >
            {imageUrl && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: "412px",
                        height: "732px",
                    }}
                >
                    <img
                        alt="Cover"
                        src={imageUrl}
                        style={{
                            height: "732px",
                            marginLeft: "50%",
                            transform: "translateX(-50%)",
                            minWidth: "100%",
                        }}
                    />
                </div>
            )}

            <div style={{ position: "absolute", top: 10, right: 10 }}>
                <Button
                    icon={<EditOutlined />}
                    shape="circle"
                    onClick={() => setModalOpen(true)}
                />
                <Modal
                    destroyOnClose
                    title="Change Background"
                    centered
                    open={modalOpen}
                    footer={null}
                    onCancel={() => setModalOpen(false)}
                >
                    <RenderUpload
                        path="home-explore/document/"
                        onChangeCustom={onImgChange}
                        label="upload an image"
                        value={imageUrl}
                    />
                </Modal>
            </div>

            <div style={{ width: "100%" }}>
                <SlateEditor
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    id={`${id || tmpId}_title`}
                    onChange={(value: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", CONTENT_BLOCK, "title"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    isFocused={currentEditor == `${id || tmpId}_title`}
                    setEditor={(id: string) => setCurrentEditor(id)}
                    value={title}
                    placeholder={"Type here..."}
                    textStyle={{
                        color: "#270F36",
                        fontWeight: 800,
                        fontSize: "48px",
                        lineHeight: "65px",
                    }}
                    mentionStyle={{
                        color: "#270F36",
                        fontWeight: 800,
                        fontSize: "40px",
                        lineHeight: "65px",
                    }}
                />

                <SlateEditor
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    id={`${id || tmpId}_description`}
                    onChange={(value: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", CONTENT_BLOCK, "description"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    isFocused={currentEditor == `${id || tmpId}_description`}
                    setEditor={(id: string) => setCurrentEditor(id)}
                    value={description}
                    placeholder={"Description"}
                    textStyle={{
                        color: "#270F36",
                        fontWeight: 400,
                        fontSize: "24px",
                        lineHeight: "26px",
                    }}
                    mentionStyle={{
                        color: "#270F36",
                        fontWeight: 400,
                        fontSize: "21px",
                        lineHeight: "26px",
                    }}
                />
            </div>

            <div
                style={{
                    padding: "16px",
                    width: "100%",
                    position: "absolute",
                    bottom: 0,
                }}
            >
                <Button
                    type="default"
                    style={{
                        background: buttonColor,
                        width: "100%",
                        minWidth: "100%",
                        border: 0,
                        color: buttonText.trim() ? textColor : "#aaa",
                        boxShadow: "2px 2px 16px rgba(0, 0, 0, 0.16)",
                        borderRadius: "8px",
                        height: "56px",

                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "25px",
                    }}
                >
                    {buttonText.trim() ? buttonText : "Add Button Text"}
                </Button>
            </div>
        </div>
    );
};

export default renderEditor;
