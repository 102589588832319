import { isEditorEmpty } from "../../common/index";

const isValidCallbackSubjective = ({ block }: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            subjective: { text },
        },
    } = block;

    return {
        isValid: !isEditorEmpty(text),
        error: (
            // <Alert
            //     message="Validation Error"
            //     description={`Text cannot be empty`}
            //     type="error"
            //     showIcon
            // />
            <></>
        ),
    };
};

export default isValidCallbackSubjective;
