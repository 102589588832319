import { Empty } from "antd";
import { BLOCK_DATA, BLOCK_TYPES } from "./config";

const {
    TEXT_BLOCK,
    IMAGE_BLOCK,
    MCQ_BLOCK,
    CASE_STUDY_BLOCK,
    SUBJECTIVE_BLOCK,
    VIDEO_BLOCK,
} = BLOCK_TYPES;

const {
    [TEXT_BLOCK]: {
        defaultValue: TEXT_DEFAULT_VALUE,
        renderCard: TextCard,
        renderEditor: TextEditor,
        renderBlock: TextBlock,
        label: TEXT_LABEL,
        renderSettings: TextSettings,
        addCallback: addCallbackText,
        isValidCallback: isValidText,
    },
    [IMAGE_BLOCK]: {
        defaultValue: IMAGE_DEFAULT_VALUE,
        renderCard: ImageCard,
        renderEditor: ImageEditor,
        renderBlock: ImageBlock,
        label: IMAGE_LABEL,
        renderSettings: ImageSettings,
        addCallback: addCallbackImage,
        isValidCallback: isValidImage,
    },
    [MCQ_BLOCK]: {
        defaultValue: MCQ_DEFAULT_VALUE,
        renderCard: MCQCard,
        renderEditor: MCQEditor,
        renderBlock: MCQBlock,
        label: MCQ_LABEL,
        renderSettings: MCQSettings,
        addCallback: addCallbackMCQ,
        isValidCallback: isValidMCQ,
    },
    [CASE_STUDY_BLOCK]: {
        defaultValue: CASE_STUDY_DEFAULT_VALUE,
        renderCard: CaseStudyCard,
        renderEditor: CaseStudyEditor,
        renderBlock: CaseStudyBlock,
        label: CASE_STUDY_LABEL,
        renderSettings: CaseStudySettings,
        addCallback: addCallbackCaseStudy,
        isValidCallback: isValidCaseStudy,
    },
    [SUBJECTIVE_BLOCK]: {
        defaultValue: SUBJECTIVE_BLOCK_DEFAULT_VALUE,
        renderCard: SubjectiveCard,
        renderEditor: SubjectiveEditor,
        renderBlock: SubjectiveBlock,
        label: SUBJECTIVE_LABEL,
        renderSettings: SubjectiveSettings,
        addCallback: addCallbackSubjective,
        isValidCallback: isValidSubjective,
    },
    [VIDEO_BLOCK]: {
        defaultValue: VIDEO_BLOCK_DEFAULT_VALUE,
        renderCard: VideoCard,
        renderEditor: VideoEditor,
        renderBlock: VideoBlock,
        label: VIDEO_LABEL,
        renderSettings: VideoSettings,
        addCallback: addCallbackVideo,
        isValidCallback: isValidVideo,
    },
} = BLOCK_DATA;

export const getBareBlock = (type: string) => {
    if (type == TEXT_BLOCK) return TEXT_DEFAULT_VALUE;
    else if (type == IMAGE_BLOCK) return IMAGE_DEFAULT_VALUE;
    else if (type == MCQ_BLOCK) return MCQ_DEFAULT_VALUE;
    else if (type == CASE_STUDY_BLOCK) return CASE_STUDY_DEFAULT_VALUE();
    else if (type == SUBJECTIVE_BLOCK) return SUBJECTIVE_BLOCK_DEFAULT_VALUE;
    else if (type == VIDEO_BLOCK) return VIDEO_BLOCK_DEFAULT_VALUE;
};

export const getAddCallback = (type: string) => {
    if (type == TEXT_BLOCK) return addCallbackText;
    else if (type == IMAGE_BLOCK) return addCallbackImage;
    else if (type == MCQ_BLOCK) return addCallbackMCQ;
    else if (type == CASE_STUDY_BLOCK) return addCallbackCaseStudy;
    else if (type == SUBJECTIVE_BLOCK) return addCallbackSubjective;
    else if (type == VIDEO_BLOCK) return addCallbackVideo;
};
export const getIsValidFunction = (type: string) => {
    if (type == TEXT_BLOCK) return isValidText;
    else if (type == IMAGE_BLOCK) return isValidImage;
    else if (type == MCQ_BLOCK) return isValidMCQ;
    else if (type == CASE_STUDY_BLOCK) return isValidCaseStudy;
    else if (type == SUBJECTIVE_BLOCK) return isValidSubjective;
    else if (type == VIDEO_BLOCK) return isValidVideo;
};

export const getCardPreview = (props: any) => {
    const { block } = props;
    const { type, data } = block;

    switch (type) {
        case TEXT_BLOCK:
            return (
                <TextCard
                    {...props}
                    block={block}
                    sideIcon={"/icons/worksheet/text-block.svg"}
                />
            );
        case IMAGE_BLOCK:
            return (
                <ImageCard
                    {...props}
                    block={block}
                    sideIcon={"/icons/worksheet/img-block.svg"}
                />
            );
        case MCQ_BLOCK:
            return (
                <MCQCard
                    {...props}
                    block={block}
                    sideIcon={"/icons/worksheet/mcq-block.svg"}
                />
            );
        case CASE_STUDY_BLOCK:
            return (
                <CaseStudyCard
                    {...props}
                    block={block}
                    sideIcon={"/icons/worksheet/case-block.svg"}
                />
            );
        case SUBJECTIVE_BLOCK:
            return (
                <SubjectiveCard
                    {...props}
                    block={block}
                    sideIcon={"/icons/worksheet/subj-block.svg"}
                />
            );
        case VIDEO_BLOCK:
            return (
                <VideoCard
                    {...props}
                    block={block}
                    sideIcon={"/icons/worksheet/vid-block.svg"}
                />
            );
        default:
            return <Empty description={`${type} not supported!`} />;
    }
};

export const getEditor = (props: any) => {
    const { block } = props;
    if (!block) return <Empty description={`Add a block to start.`} />;

    const { type } = block;

    switch (type) {
        case TEXT_BLOCK:
            return <TextEditor {...props} />;
        case IMAGE_BLOCK:
            return <ImageEditor {...props} />;
        case MCQ_BLOCK:
            return <MCQEditor {...props} />;
        case CASE_STUDY_BLOCK:
            return <CaseStudyEditor {...props} />;
        case SUBJECTIVE_BLOCK:
            return <SubjectiveEditor {...props} />;
        case VIDEO_BLOCK:
            return <VideoEditor {...props} />;
        default:
            return <Empty description={`${type} not supported!`} />;
    }
};

export const renderBlock = (block: any) => {
    const { type } = block;
    switch (type) {
        case TEXT_BLOCK:
            return <TextBlock {...block} />;
        case IMAGE_BLOCK:
            return <ImageBlock {...block} />;
        case MCQ_BLOCK:
            return <MCQBlock {...block} />;
        case CASE_STUDY_BLOCK:
            return <CaseStudyBlock {...block} />;
        case SUBJECTIVE_BLOCK:
            return <SubjectiveBlock {...block} />;
        case VIDEO_BLOCK:
            return <VideoBlock {...block} />;
        default:
            return <Empty description={`${type} not supported!`} />;
    }
};

export const renderSettings = (props: any) => {
    const { block } = props;
    if (!block) return <Empty description={`Add a block to start.`} />;

    const { type } = block;
    switch (type) {
        case TEXT_BLOCK:
            return <TextSettings {...props} />;
        case IMAGE_BLOCK:
            return <ImageSettings {...props} />;
        case MCQ_BLOCK:
            return <MCQSettings {...props} />;
        case CASE_STUDY_BLOCK:
            return <CaseStudySettings {...props} />;
        case SUBJECTIVE_BLOCK:
            return <SubjectiveSettings {...props} />;
        case VIDEO_BLOCK:
            return <VideoSettings {...props} />;
        default:
            return <Empty description={`${type} not supported!`} />;
    }
};

export const getBlockTypes = () => {
    return [
        {
            label: TEXT_LABEL,
            value: TEXT_BLOCK,
            icon: "/icons/worksheet/text-block.svg",
            desc: "Add a long or short text",
        },
        {
            label: IMAGE_LABEL,
            value: IMAGE_BLOCK,
            icon: "/icons/worksheet/img-block.svg",
            desc: "Add an image",
        },
        {
            label: MCQ_LABEL,
            value: MCQ_BLOCK,
            icon: "/icons/worksheet/mcq-block.svg",
            desc: "Add a question with multiple choice single correct",
        },
        {
            label: CASE_STUDY_LABEL,
            value: CASE_STUDY_BLOCK,
            icon: "/icons/worksheet/case-block.svg",
            desc: "Add a question with subjective answer",
        },
        {
            label: SUBJECTIVE_LABEL,
            value: SUBJECTIVE_BLOCK,
            icon: "/icons/worksheet/subj-block.svg",
            desc: "Add a descriptive block and multiple questions based on that",
        },
        {
            label: VIDEO_LABEL,
            value: VIDEO_BLOCK,
            icon: "/icons/worksheet/vid-block.svg",
            desc: "Add a playable video",
        },
    ];
};
