import React, { useState, useEffect } from "react";
import { message, Button, Col, Row, Form, Select, Checkbox } from "antd";
import _ from "lodash";

import { SlateEditor } from "src/components/plate";
import { DeleteOutlined } from "@ant-design/icons";

import { parseCMSJson } from "src/modules/worksheet/views/edit_enhanced/helpers";
import { AudioPicker, DurationPicker } from "../../common";
import CustomKeyboardEditor, {
    DEFAULT_CUSTOM_KEYBOARD,
} from "src/components/CustomKeyboardEditor";
import PreviewBlocksField from "../../common/PreviewBlocksField";
import FeedbackField from "../../common/FeedBackField";

const { Option } = Select;

const V2_FILL_BLANK_BLOCK = "v2_fill_blank";

const EditorFillBlank = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    currentBlock,
    isNewBlock,
    setIsNewBlock,
    worksheet,
    showDuration = true,
    showBackgroundAudio = true,
    disableWrite,
    hasMentions,
    mentionsList = [],
    hasPreviewBlocks,
    isReadOnlyMode,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [V2_FILL_BLANK_BLOCK]: { text, blanks },
            other: { description = [], global_feedback },
        },
    } = block;

    const [currentEditor, setCurrentEditor] = useState("");
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [curCell, setCurCell] = useState({});
    // useEffect(() => {
    //     if (!disableWrite) setCurrentEditor(`${id || tmpId}_text`);
    // }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    const renderOption = (option: any, idx: number, blankIdx) => (
        <div
            style={{
                border: "1px solid #ECECEC",
                borderRadius: "15px",
                marginBottom: "10px",
                margin: "10px",
                padding: "10px",
            }}
        >
            <h4>Text</h4>
            <Row
                key={idx}
                justify="start"
                style={{
                    border: "1px solid #ECECEC",
                    borderRadius: "15px",
                    marginBottom: "10px",
                }}
            >
                <Col
                    span={21}
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div style={{ width: "100%" }}>
                        <SlateEditor
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            disabled={disableWrite}
                            id={`${id || tmpId}_blank_text_${idx}`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        V2_FILL_BLANK_BLOCK,
                                        "blanks",
                                        blankIdx,
                                        "options",
                                        idx,
                                    ],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            value={option}
                            isFocused={
                                currentEditor ==
                                `${id || tmpId}_blank_text_${idx}`
                            }
                            setEditor={(id: string) => {
                                setCurrentEditor(id);
                            }}
                        />
                    </div>
                </Col>

                <Col span={2} style={{ paddingTop: "10px" }}>
                    <Button
                        disabled={disableWrite}
                        icon={<DeleteOutlined />}
                        type="primary"
                        shape="circle"
                        danger
                        size="small"
                        onClick={() => {
                            let tmpBlock = _.cloneDeep(block);
                            const tmpBlanks = [...blanks[blankIdx].options];
                            _.pullAt(tmpBlanks, idx);

                            tmpBlock = _.set(
                                tmpBlock,
                                [
                                    "data",
                                    V2_FILL_BLANK_BLOCK,
                                    "blanks",
                                    blankIdx,
                                    "options",
                                ],
                                tmpBlanks,
                            );

                            // if (
                            //     correct_options[0] &&
                            //     idx < correct_options[0]
                            // )
                            //     tmpBlock = _.set(
                            //         tmpBlock,
                            //         [
                            //             "data",
                            //             V2_FILL_BLANK_BLOCK,
                            //             "correct_options",
                            //         ],
                            //         [],
                            //     );

                            // if (Boolean(correct_options[idx]))
                            //     tmpBlock = _.set(
                            //         tmpBlock,
                            //         [
                            //             "data",
                            //             V2_FILL_BLANK_BLOCK,
                            //             "correct_options",
                            //         ],
                            //         [],
                            //     );

                            setBlock(tmpBlock);
                        }}
                    />
                </Col>
            </Row>
        </div>
    );

    const renderCorrectOption = (
        { correct_options = [], options = [] }: any,
        blankIdx: any,
    ) => (
        <>
            <Row>
                <h3 style={{ padding: "0px 24px", fontWeight: "bold" }}>
                    Correct Option / Order
                </h3>
            </Row>
            <Row
                style={{ padding: "0px 24px" }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Col span={24}>
                    {" "}
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        validateStatus={!correct_options[0] ? "error" : ""}
                        help={
                            !correct_options[0] ? "Field cannot be empty" : ""
                        }
                    >
                        <Select
                            disabled={disableWrite}
                            value={correct_options}
                            style={{ width: 120 }}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        V2_FILL_BLANK_BLOCK,
                                        "blanks",
                                        blankIdx,
                                        "correct_options",
                                    ],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            mode={`multiple`}
                        >
                            {options.map((option: any, idx: number) => {
                                return (
                                    <Option value={idx + 1} key={idx}>
                                        {idx + 1}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );

    const showFeedbackModal = () => {
        setCurCell(
            global_feedback || {
                index: 0,
                values: [],
            },
        );
        setIsFeedbackModalOpen(true);
    };

    const closeForm = () => {
        setIsFeedbackModalOpen(false);
    };

    const feedbackFieldProps = {
        isModalOpen: isFeedbackModalOpen,
        isGlobal: true,
        closeForm,
        feedback: curCell,
        onSave: (data: any) => {
            const obj = {
                ...block,
                data: {
                    ...block.data,
                    other: { ...block.data.other, global_feedback: data },
                },
            };
            setBlock(obj);
        },
        curIndex: 0,
        isEditor,
        setIsEditor,
        hasMentions,
        mentionsList,
        disableWrite,
        isReadOnlyMode,
    };

    return (
        <div>
            <FeedbackField {...feedbackFieldProps} />
            <h3
                style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                }}
            >
                Preview Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                {hasPreviewBlocks && (
                    <PreviewBlocksField
                        disableWrite={disableWrite}
                        fixed_blocks={block?.data?.other?.fixed_blocks || []}
                        updateFixedBlocks={(data: any) => {
                            const obj = {
                                ...block,
                                data: {
                                    ...block.data,
                                    other: {
                                        ...block.data.other,
                                        fixed_blocks: data,
                                    },
                                },
                            };
                            setBlock(obj);
                        }}
                    />
                )}
                <Col span={24}>
                    {showBackgroundAudio &&
                        [
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <h3>Background Audio</h3>
                                <AudioPicker
                                    disabled={isReadOnlyMode}
                                    block={block}
                                    setBlock={setBlock}
                                />
                            </div>
                        )}
                </Col>
                {["personalized_learning_v2"].includes(worksheet?.type) && (
                    <Col span={22}>
                        <h3
                            style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                            }}
                        >
                            Question
                        </h3>
                        <SlateEditor
                            disabled={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`${id || tmpId}_description`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "other", "description"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            isFocused={
                                currentEditor === `${id || tmpId}_description`
                            }
                            setEditor={(id: string) => setCurrentEditor(id)}
                            value={description}
                            placeholder={"Type your description here..."}
                        />
                    </Col>
                )}
            </Row>

            <h3
                style={{
                    fontWeight: "bold",
                    margin: "10px 0",
                }}
            >
                Play Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                <Col span={22}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Fill blank Text
                    </h3>
                    <SlateEditor
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        disabled={disableWrite}
                        id={`${id || tmpId}_text`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", V2_FILL_BLANK_BLOCK, "text"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        value={text}
                        isFocused={currentEditor == `${id || tmpId}_text`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                    />
                </Col>
                <Col span={24}>
                    {Array(parseCMSJson(text).split(`()`).length - 1)
                        .fill()
                        .map((a, blankIdx) => (
                            <div>
                                <h3>Blank {blankIdx + 1}</h3>
                                <hr />
                                {/* blank start */}
                                <div>
                                    <Checkbox
                                        disabled={disableWrite}
                                        checked={
                                            blanks[blankIdx]?.custom_keys
                                                ?.length
                                        }
                                        onChange={(e) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    V2_FILL_BLANK_BLOCK,
                                                    "blanks",
                                                    blankIdx,
                                                    "custom_keys",
                                                ],
                                                e.target.checked
                                                    ? [
                                                          "0",
                                                          "1",
                                                          "2",
                                                          "3",
                                                          "4",
                                                          "5",
                                                          "6",
                                                          "7",
                                                          "8",
                                                          "9",
                                                      ]
                                                    : [],
                                            );

                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    V2_FILL_BLANK_BLOCK,
                                                    "blanks",
                                                    blankIdx,
                                                    "custom_keyboard",
                                                ],
                                                e.target.checked
                                                    ? DEFAULT_CUSTOM_KEYBOARD
                                                    : [],
                                            );

                                            setBlock(tmpBlock);
                                        }}
                                    >
                                        {`is_custom_keyboard`}
                                    </Checkbox>

                                    {blanks[blankIdx]?.custom_keyboard &&
                                    blanks[blankIdx]?.custom_keyboard.length ? (
                                        <CustomKeyboardEditor
                                            disabled={disableWrite}
                                            value={
                                                blanks[blankIdx]
                                                    ?.custom_keyboard
                                            }
                                            onOk={(data: any) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);

                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "data",
                                                        V2_FILL_BLANK_BLOCK,
                                                        "blanks",
                                                        blankIdx,
                                                        "custom_keyboard",
                                                    ],
                                                    data,
                                                );

                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    ) : null}
                                </div>

                                {!blanks[blankIdx]?.custom_keys?.length ? (
                                    <div>
                                        <Row justify="space-between">
                                            <Col>
                                                <h4
                                                    style={{
                                                        padding: "0px 24px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Options
                                                </h4>
                                            </Col>
                                        </Row>
                                        <div>
                                            {blanks[blankIdx]?.options?.map(
                                                (option: any, idx: number) =>
                                                    renderOption(
                                                        option,
                                                        idx,
                                                        blankIdx,
                                                    ),
                                            )}

                                            <Button
                                                disabled={disableWrite}
                                                type="dashed"
                                                onClick={() => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "data",
                                                            V2_FILL_BLANK_BLOCK,
                                                            "blanks",
                                                            blankIdx,
                                                            "options",
                                                            blanks[blankIdx]
                                                                ?.options
                                                                ?.length || 0,
                                                        ],
                                                        [],
                                                    );
                                                    setIsNewBlock(true);
                                                    setBlock(tmpBlock);
                                                    setCurrentEditor(
                                                        `${id || tmpId}_blank_${
                                                            blanks.length
                                                        }`,
                                                    );
                                                }}
                                                style={{ margin: "0px 24px" }}
                                            >
                                                Add Options
                                            </Button>
                                        </div>
                                        <br />

                                        {blanks[blankIdx] &&
                                            renderCorrectOption(
                                                blanks[blankIdx],
                                                blankIdx,
                                            )}
                                    </div>
                                ) : (
                                    <div style={{ margin: "10px 0" }}>
                                        <h4>Correct Answers</h4>

                                        {hasMentions &&
                                            mentionsList?.length > 0 && (
                                                <Checkbox
                                                    style={{
                                                        marginBottom: "20px",
                                                    }}
                                                    disabled={disableWrite}
                                                    checked={
                                                        blanks[blankIdx]
                                                            ?.is_correct_input
                                                    }
                                                    onChange={(e) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(block);
                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [
                                                                "data",
                                                                V2_FILL_BLANK_BLOCK,
                                                                "blanks",
                                                                blankIdx,
                                                                "is_correct_input",
                                                            ],
                                                            e.target.checked,
                                                        );

                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [
                                                                "data",
                                                                V2_FILL_BLANK_BLOCK,
                                                                "blanks",
                                                                blankIdx,
                                                                "correct_answer",
                                                            ],
                                                            [],
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                >
                                                    {`Use Input Variables as Correct Answers`}
                                                </Checkbox>
                                            )}

                                        {blanks[blankIdx]?.is_correct_input ? (
                                            <Select
                                                disabled={disableWrite}
                                                value={
                                                    blanks[blankIdx]
                                                        ?.correct_answer || []
                                                }
                                                placeholder="Correct Answers"
                                                style={{ width: "100%" }}
                                                onChange={(value: any) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "data",
                                                            V2_FILL_BLANK_BLOCK,
                                                            "blanks",
                                                            blankIdx,
                                                            "correct_answer",
                                                        ],
                                                        value,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                                mode={`multiple`}
                                            >
                                                {mentionsList.map(
                                                    (
                                                        item: any,
                                                        idx: number,
                                                    ) => {
                                                        return (
                                                            <Option
                                                                value={`@@${item?.data}@@`}
                                                                key={idx}
                                                            >
                                                                {item?.data}
                                                            </Option>
                                                        );
                                                    },
                                                )}
                                            </Select>
                                        ) : (
                                            <Select
                                                disabled={disableWrite}
                                                value={
                                                    blanks[blankIdx]
                                                        ?.correct_answer || []
                                                }
                                                mode="tags"
                                                style={{ width: "100%" }}
                                                placeholder="Correct Answers"
                                                onChange={(value) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "data",
                                                            V2_FILL_BLANK_BLOCK,
                                                            "blanks",
                                                            blankIdx,
                                                            "correct_answer",
                                                        ],
                                                        value,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                                options={[]}
                                                open={false}
                                            />
                                        )}
                                    </div>
                                )}

                                {/* {hasMentions &&
                                mentionsList?.length > 0 && (
                                    <Form.Item
                                        label="Variable"
                                        help="Input vairable for saving the data"
                                    >
                                        <Select
                                            disabled={disableWrite}
                                            value={blanks[blankIdx]?.variable}
                                            placeholder="Variable"
                                            style={{ width: "100%" }}
                                            onChange={(value: any) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "data",
                                                        V2_FILL_BLANK_BLOCK,
                                                        "blanks",
                                                        blankIdx,
                                                        "variable",
                                                    ],
                                                    value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                        >
                                            {" "}
                                            <Option value={""}>N/A</Option>
                                            {mentionsList.map(
                                                (item: any, idx: number) => {
                                                    return (
                                                        <Option
                                                            value={item?.data}
                                                            key={idx}
                                                        >
                                                            {item?.data}
                                                        </Option>
                                                    );
                                                },
                                            )}
                                        </Select>
                                    </Form.Item>
                                )} */}

                                {/* blank end */}
                            </div>
                        ))}

                    {["personalized_learning_v2"].includes(worksheet?.type) && (
                        <Col span={24} style={{ marginTop: "20px" }}>
                            <Button onClick={() => showFeedbackModal()}>
                                {global_feedback &&
                                global_feedback.values?.length > 0
                                    ? "Edit"
                                    : "Add"}{" "}
                                Global Feedback
                            </Button>
                        </Col>
                    )}
                    {showDuration &&
                        [
                            "timed",
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    margin: "20px 0",
                                }}
                            >
                                <h3>Duration</h3>
                                <DurationPicker
                                    disabled={disableWrite}
                                    onChange={onDurationChange}
                                    initialValue={
                                        block?.data?.other?.duration || 0
                                    }
                                />
                            </div>
                        )}
                </Col>
            </Row>
        </div>
    );
};

export default EditorFillBlank;
