import { gql } from "@apollo/client";
export default gql`
    query WorksheetStatsQuery($worksheet_id: Int) {
        worksheet_worksheet_stats(
            where: { worksheet_id: { _eq: $worksheet_id } }
        ) {
            id
            other
        }
    }
`;
