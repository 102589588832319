import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { RenderUpload } from "src/components/form";
import { Form, Input, Select, Switch } from "antd";
import _ from "lodash";
import { BLOCK_TYPES } from "../../common/index";
import { BlockPicker } from "react-color";

const { CONTENT_BLOCK } = BLOCK_TYPES;

const renderSettingsEmail = (props: any) => {
    const { block, setBlock } = props;

    const {
        data: {
            [CONTENT_BLOCK]: {
                isEnd,
                background: { sound },
                button: {
                    buttonColor,
                    buttonText,
                    textColor,
                    buttonType, // internal/external
                    buttonUrl, // if external
                },
            },
        },
    } = block;

    return (
        <>
            <Form.Item label="Is End Block?">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={isEnd}
                    onChange={(value) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", CONTENT_BLOCK, "isEnd"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            </Form.Item>
            <RenderUpload
                accept="audio/*"
                path="home-explore/document/"
                onChangeCustom={({ target: { value } }) => {
                    let tmpBlock = _.cloneDeep(block);

                    tmpBlock = _.set(
                        tmpBlock,
                        ["data", CONTENT_BLOCK, "background", "sound"],
                        value,
                    );

                    setBlock(tmpBlock);
                }}
                label="Upload background music"
                value={sound}
                uploadType="custom"
                singleUpload={true}
                addExtension={true}
            />

            <hr />
            <br />

            <Form.Item
                label="Button Color"
                rules={[
                    {
                        required: true,
                    },
                ]}
                validateStatus={!buttonColor ? "error" : ""}
                help={!buttonColor ? "Field cannot be empty" : ""}
            >
                <BlockPicker
                    colors={[]}
                    color={buttonColor || "#ffffff"}
                    onChangeComplete={(color: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", CONTENT_BLOCK, "button", "buttonColor"],
                            color.hex,
                        );
                        setBlock(tmpBlock);
                    }}
                    triangle="hide"
                />
            </Form.Item>

            <Form.Item
                label="Button Text"
                rules={[
                    {
                        required: true,
                    },
                ]}
                validateStatus={!buttonText.trim() ? "error" : ""}
                help={!buttonText.trim() ? "Field cannot be empty" : ""}
            >
                <Input
                    value={buttonText || ""}
                    onChange={(e) => {
                        let tmpBlock = _.cloneDeep(block);

                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", CONTENT_BLOCK, "button", "buttonText"],
                            e.target.value,
                        );
                        setBlock(tmpBlock);
                    }}
                    required
                />
            </Form.Item>

            <Form.Item
                label="Text Color"
                rules={[
                    {
                        required: true,
                    },
                ]}
                validateStatus={!textColor ? "error" : ""}
                help={!textColor ? "Field cannot be empty" : ""}
            >
                <BlockPicker
                    colors={[]}
                    color={textColor || "#000000"}
                    onChangeComplete={(color: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", CONTENT_BLOCK, "button", "textColor"],
                            color.hex,
                        );
                        setBlock(tmpBlock);
                    }}
                    triangle="hide"
                />
            </Form.Item>

            <Form.Item
                label="Button Type"
                rules={[
                    {
                        required: true,
                    },
                ]}
                validateStatus={!buttonType ? "error" : ""}
                help={!buttonType ? "Field cannot be empty" : ""}
            >
                <Select
                    defaultValue={buttonType}
                    onChange={(value) => {
                        let tmpBlock = _.cloneDeep(block);

                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", CONTENT_BLOCK, "button", "buttonType"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    options={[
                        { value: "internal", label: "Internal" },
                        { value: "external", label: "External" },
                    ]}
                />
            </Form.Item>

            {buttonType &&
                (buttonType === "internal" ? (
                    <></>
                ) : (
                    <Form.Item
                        label="External URL"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        validateStatus={!buttonUrl ? "error" : ""}
                        help={!buttonUrl ? "Field cannot be empty" : ""}
                    >
                        <Input
                            value={buttonUrl}
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        CONTENT_BLOCK,
                                        "button",
                                        "buttonUrl",
                                    ],
                                    e.target.value,
                                );
                                setBlock(tmpBlock);
                            }}
                            required
                        />
                    </Form.Item>
                ))}
        </>
    );
};

export default renderSettingsEmail;
