import renderBlockPaintActivity from "./renderBlock";
import renderEditorPaintActivity from "./renderEditor";
import isValidCallbackPaintActivity from "./isValid";
import PAINT_ACTIVITY_DEFAULT_VALUE from "./defaultValue";
import renderSettingsPaintActivity from "./renderSettings";
import renderCardPaintActivity from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { PAINT_ACTIVITY_BLOCK } = BLOCK_TYPES;

const PAINT_ACTIVITY_BLOCK_CONFIG = {
    [PAINT_ACTIVITY_BLOCK]: {
        label: "Paint Activity",
        defaultValue: PAINT_ACTIVITY_DEFAULT_VALUE,
        renderBlock: renderBlockPaintActivity,
        renderEditor: renderEditorPaintActivity,
        renderCard: renderCardPaintActivity,
        renderSettings: renderSettingsPaintActivity,
        addCallback,
        isValidCallback: isValidCallbackPaintActivity,
    },
};

export {
    renderBlockPaintActivity,
    renderCardPaintActivity,
    renderEditorPaintActivity,
    renderSettingsPaintActivity,
    isValidCallbackPaintActivity,
    PAINT_ACTIVITY_DEFAULT_VALUE,
    PAINT_ACTIVITY_BLOCK_CONFIG,
};
