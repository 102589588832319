import React from "react";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd/lib/button";
import _ from "lodash";
export type IButton = ButtonProps;

const ViewIcon: React.FC<any> = (props) => {
    const { isButton = false, size } = props;
    const clickAction = props?.onClick;
    let buttonSize = "default";

    if (size === "sm") {
        buttonSize = "small";
    } else if (size === "lg") {
        buttonSize = "large";
    }

    const handleClick = clickAction;

    return isButton ? (
        <Button
            size={buttonSize}
            icon={<EyeOutlined onClick={handleClick} />}
            shape="circle"
            {...props}
        />
    ) : (
        <EyeOutlined {...props} onClick={handleClick} />
    );
};

export default ViewIcon;
