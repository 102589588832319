import { CheckOutlined } from "@ant-design/icons";
import EditIcon from "src/components/EditIcon";
import { Button, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import ReactPlayer from "react-player";
import { BLOCK_TYPES } from "../../common/index";
import { RenderUpload } from "src/components";
const { V2_AVATAR_BLOCK } = BLOCK_TYPES;

const renderEditorVideo = ({ block, setBlock, type, useGamlet }: any) => {
    const videoUrl = typeof block?.data?.video === "string" ? block?.data?.video : block?.data?.video?.data?.video
    const [isEditState, setEditState] = useState(!videoUrl);
    const [isTextField, setTextField] = useState(true);

    const onChange = (e: any) => {
        if (type === V2_AVATAR_BLOCK) {
            setBlock(e.target.value);
        } else {
            setBlock({
                ...block,
                data: { ...block.data, video: e.target.value },
            });
        }
    };
    const onRemove = (e: any) => {
        if (type === V2_AVATAR_BLOCK) {
            setBlock("");
        } else {
            setBlock({
                ...block,
                data: { ...block.data, video: "" },
            });
        }
    };


    const isValid = Boolean(videoUrl?.trim())
    return (
        <div>
            {isEditState ? (
                <>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => setTextField(!isTextField)}
                        style={{
                            marginBottom: "20px",
                        }}
                    >
                        {!isTextField ? "Add via text field" : "Add via Upload"}
                    </Button>
                    {isTextField ? (
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            validateStatus={!isValid ? "error" : ""}
                            help={!isValid ? "Field cannot be empty" : ""}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    gap: "20px",
                                    flexDirection: "column",
                                }}
                            >
                                {videoUrl && (
                                    <Button
                                        icon={
                                            <CheckOutlined
                                                onClick={() =>
                                                    setEditState(false)
                                                }
                                            />
                                        }
                                        shape="circle"
                                        type="primary"
                                        style={{ marginLeft: "10px" }}
                                    />
                                )}
                                <TextArea
                                    rows={10}
                                    value={block?.data?.video || ""}
                                    onChange={onChange}
                                />
                            </div>
                        </Form.Item>
                    ) : (
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            validateStatus={!isValid ? "error" : ""}
                            help={!isValid ? "Field cannot be empty" : ""}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    gap: "20px",
                                    flexDirection: "column",
                                }}
                            >
                                {videoUrl && (
                                    <Button
                                        icon={
                                            <CheckOutlined
                                                onClick={() =>
                                                    setEditState(false)
                                                }
                                            />
                                        }
                                        shape="circle"
                                        type="primary"
                                        style={{ marginLeft: "10px" }}
                                    />
                                )}
                                <RenderUpload
                                    path="home-explore/document/"
                                    onChangeCustom={onChange}
                                    label="upload a video"
                                    value={videoUrl}
                                    required={true}
                                    uploadType="custom"
                                    singleUpload={true}
                                    onRemove={onRemove}
                                    meta={{ touched: true }}
                                    useGamlet={useGamlet}
                                />
                            </div>
                        </Form.Item>
                    )}
                </>
            ) : (
                <div
                    style={{
                        display: "flex",
                        gap: "20px",
                        flexDirection: "column",
                    }}
                >
                    {videoUrl && (
                        <EditIcon
                            isButton={true}
                            onClick={() => setEditState(true)}
                            style={{ marginLeft: "10px" }}
                        />
                    )}
                    <div>
                        <ReactPlayer
                            url={videoUrl}
                            style={{
                                maxWidth:
                                    type === V2_AVATAR_BLOCK ? "300px" : "100%",
                            }}
                            controls
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default renderEditorVideo;
