import { BLOCK_TYPES } from "../../common/index";
import { GRID_INPUT_OPTIONS, PUZZLE_OTHER } from "../Crossword/defaultValue";
const { SWIPE_AND_FORM_BLOCK } = BLOCK_TYPES;

export const DEFAULT_SWIPE_AND_FORM_CELL = {
    type: "fillable",

    // optional and can be null
    content: {
        type: "string", // string / asset
        value: "0",
        asset: "",
    },
};

const SWIPE_AND_FORM_DEFAULT_VALUE = {
    type: SWIPE_AND_FORM_BLOCK,
    data: {
        [SWIPE_AND_FORM_BLOCK]: {
            options: GRID_INPUT_OPTIONS(),
            grid: [[...Array(3)].map(() => DEFAULT_SWIPE_AND_FORM_CELL)],
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
        other: {
            ...PUZZLE_OTHER,
            feedbacks: [], // DEFAULT_FEEDBACKS,
            grid_feedbacks: [], // GRID_FEEDBACKS,
        },
    },
};

export default SWIPE_AND_FORM_DEFAULT_VALUE;
