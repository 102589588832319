import React from "react";
import PropTypes from "prop-types";
import { AutoComplete as ADAutoComplete, Space, Form } from "antd";

const FormItem = Form.Item;

export const RenderAutoComplete = ({
    icon,
    children,
    label,
    placeholder,
    meta: { touched, error },
    onChange,

    ...props
}) => {
    let validateStatus = "";
    if (touched && error) {
        validateStatus = "error";
    }
    const handleChange = (value) => {
        const { formik, name } = props;
        if (onChange) {
            onChange(value);
        } else {
            formik.handleChange({ target: { value, name } });
        }
    };

    return (
        <FormItem
            label={
                <Space align="center">
                    {/* {icon && <Icon type={icon} />} */}
                    {label}
                </Space>
            }
            validateStatus={validateStatus}
            help={touched && error}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            placeholder={placeholder || label}
            style={{ width: "150px" }}
        >
            <ADAutoComplete
                {...props}
                onChange={handleChange}
                filterOption={(inputValue, option) =>
                    option!.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                }
            >
                {children}
            </ADAutoComplete>
        </FormItem>
    );
};

RenderAutoComplete.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    icon: PropTypes.node,
};
