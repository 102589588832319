import renderBlockCompareArrangeList from "./renderBlock";
import renderEditorCompareArrangeList from "./renderEditor";
import isValidCallbackCompareArrangeList from "./isValid";
import COMPARE_ARRANGE_LIST_DEFAULT_VALUE from "./defaultValue";
import renderSettingsCompareArrangeList from "./renderSettings";
import renderCardCompareArrangeList from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common";

const { COMPARE_ARRANGE_LIST_BLOCK } = BLOCK_TYPES;

const COMPARE_ARRANGE_LIST_BLOCK_CONFIG = {
    [COMPARE_ARRANGE_LIST_BLOCK]: {
        label: "Compare Arrange List",
        defaultValue: COMPARE_ARRANGE_LIST_DEFAULT_VALUE,
        renderBlock: renderBlockCompareArrangeList,
        renderEditor: renderEditorCompareArrangeList,
        renderCard: renderCardCompareArrangeList,
        renderSettings: renderSettingsCompareArrangeList,
        addCallback,
        isValidCallback: isValidCallbackCompareArrangeList,
    },
};

export {
    renderBlockCompareArrangeList,
    renderCardCompareArrangeList,
    renderEditorCompareArrangeList,
    renderSettingsCompareArrangeList,
    isValidCallbackCompareArrangeList,
    COMPARE_ARRANGE_LIST_DEFAULT_VALUE,
    COMPARE_ARRANGE_LIST_BLOCK_CONFIG,
};
