import { Button, Col, Empty, Input, Row, Select, Space } from "antd";
import { renderEditorV2FillBlank } from "../V2FillBlank";
import { renderEditorTable } from "../Table";

import { SlateEditor } from "src/components/plate";
import { useState } from "react";
import _ from "lodash";

import { AudioPicker, BLOCK_TYPES, DurationPicker } from "../../common/index";
import getV2FillBlankDefaultValue from "../V2FillBlank/defaultValue";
import PHONE_DEFAULT_VALUE from "../Table/defaultValue";
import { v4 as uuid } from "uuid";

import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
import VideoField from "../../common/VideoField";
import PreviewBlocksField from "../../common/PreviewBlocksField";
const { V2_MIXED_BLOCK } = BLOCK_TYPES;
const EditorV2FillBlank = renderEditorV2FillBlank;
const EditorTable = renderEditorTable;

const { Option } = Select;

const SortableContainerCustom = SortableContainer(({ children }: any) => {
    return <div>{children}</div>;
});
const DragHandle = SortableHandle(() => <MenuOutlined />);

const renderEditorMixed = ({
    block,
    setBlock,
    setIsEditor,
    isEditor,

    currentBlock,
    isNewBlock,
    setIsNewBlock,
    worksheet,

    showDuration = true,
    showBackgroundAudio = true,
    showDifficultyLevel = false,
    toggleShouldBlockUI,
    disableWrite,
    hasPreviewBlocks,
    isReadOnlyMode,
}: any) => {
    const {
        data: {
            [V2_MIXED_BLOCK]: { text },
            children,
        },
        id,
        tmpId,
    } = block;

    const [currectSubBlock, setCurrentSubBlock] = useState(0);

    const childProps = {
        block: children[currectSubBlock],
        setBlock: (b: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(
                tmpBlock,
                ["data", "children", currectSubBlock],
                b,
            );
            setBlock(tmpBlock);
        },
        setIsEditor,
        isEditor,

        currentBlock,
        isNewBlock,
        setIsNewBlock,
        worksheet,

        showDuration: false,
        showBackgroundAudio: true,
        disableWrite,
        isReadOnlyMode,
    };

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(
                [].concat(children),
                oldIndex,
                newIndex,
            ).filter((el) => !!el);
            let tmpBlock = _.cloneDeep(block);
            const tmpOptions = [...newData];

            tmpBlock = _.set(tmpBlock, ["data", "children"], tmpOptions);
            setBlock(tmpBlock);
        }
    };

    const SortableItemCustom = SortableElement((props: any) => {
        const { idx, type } = props;
        return (
            <Row gutter={3}>
                <Col span={20}>
                    <div
                        style={{
                            border: "1px dashed #808080",
                            padding: "4px",
                            backgroundColor:
                                currectSubBlock === idx ? "#dfdfdf" : "",
                        }}
                        onClick={() => setCurrentSubBlock(idx)}
                    >
                        {!disableWrite && (
                            <span
                                style={{
                                    cursor: "pointer",
                                    marginRight: "10px",
                                }}
                            >
                                <DragHandle />
                            </span>
                        )}
                        Sub Block {idx + 1} - {type}
                    </div>
                </Col>
                <Col span={4}>
                    <Button
                        disabled={disableWrite}
                        danger
                        onClick={() => {
                            let tmpBlock = _.cloneDeep(block);
                            const tmpOptions = [...children];
                            _.pullAt(tmpOptions, idx);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "children"],
                                tmpOptions,
                            );

                            setBlock(tmpBlock);
                        }}
                    >
                        Delete
                    </Button>
                </Col>
            </Row>
        );
    });

    return (
        <div>
            <h3
                style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                }}
            >
                Preview Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                {hasPreviewBlocks && (
                    <PreviewBlocksField
                        disableWrite={disableWrite}
                        fixed_blocks={block?.data?.other?.fixed_blocks || []}
                        updateFixedBlocks={(data: any) => {
                            const obj = {
                                ...block,
                                data: {
                                    ...block.data,
                                    other: {
                                        ...block.data.other,
                                        fixed_blocks: data,
                                    },
                                },
                            };
                            setBlock(obj);
                        }}
                    />
                )}
                <Col span={22}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Question
                    </h3>
                    <SlateEditor
                        disabled={disableWrite}
                        id={`${id || tmpId}_text`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", V2_MIXED_BLOCK, "text"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={isEditor === `${id || tmpId}_text`}
                        value={text}
                        setEditor={(id: string) => setIsEditor(id)}
                    />
                </Col>
                <Col span={24}>
                    {showBackgroundAudio &&
                        [
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <h3>Background Audio</h3>
                                <AudioPicker
                                    disabled={isReadOnlyMode}
                                    block={block}
                                    setBlock={setBlock}
                                />
                            </div>
                        )}
                </Col>
                <Col span={24}>
                    {showDifficultyLevel && (
                        <div
                            style={{
                                border: "1px dashed #808080",
                                margin: "10px auto",
                                padding: "10px 10px",
                            }}
                        >
                            <h4 style={{ fontWeight: "bold" }}>
                                Difficulty Level
                            </h4>

                            <Select
                                disabled={disableWrite}
                                value={block?.data?.other?.difficulty_level}
                                style={{ width: 120 }}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "other", "difficulty_level"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            >
                                <Option value={`easy`} key={`easy`}>
                                    Easy
                                </Option>
                                <Option value={`medium`} key={`medium`}>
                                    Medium
                                </Option>
                                <Option value={`hard`} key={`hard`}>
                                    Hard
                                </Option>
                            </Select>
                        </div>
                    )}
                </Col>
            </Row>
            <h3
                style={{
                    fontWeight: "bold",
                    margin: "10px 0",
                }}
            >
                Play Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                <Col span={24}>
                    <VideoField
                        label={`Solution Video URL`}
                        path={["data", "other", "solution_video"]}
                        block={block}
                        setBlock={setBlock}
                        disabled={isReadOnlyMode}
                        isYoutube={true}
                        toggleShouldBlockUI={toggleShouldBlockUI}
                    />
                </Col>
                <Col span={24}>
                    <div>
                        {showDuration &&
                            ["timed", "personalized_learning"].includes(
                                worksheet?.type,
                            ) && (
                                <div
                                    style={{
                                        border: "1px dashed #808080",
                                        margin: "10px auto",
                                        padding: "10px 10px",
                                    }}
                                >
                                    <h3>Duration</h3>
                                    <DurationPicker
                                        disabled={disableWrite}
                                        onChange={onDurationChange}
                                        initialValue={
                                            block?.data?.other?.duration || 0
                                        }
                                    />
                                </div>
                            )}
                    </div>
                </Col>
                <Col span={24}>
                    <div
                        style={{
                            border: "1px dashed #808080",
                            margin: "10px auto",
                            padding: "10px 10px",
                        }}
                    >
                        <div style={{ padding: "2px" }}>
                            <h3>Sub Blocks</h3>

                            <SortableContainerCustom
                                onSortEnd={onSortEnd}
                                useDragHandle
                            >
                                {children.map(
                                    (
                                        { type, tmpId: tmpId2 }: any,
                                        idx: number,
                                    ) => (
                                        <SortableItemCustom
                                            idx={idx}
                                            type={type}
                                            key={`item-${
                                                id || tmpId
                                            }-${tmpId2}`}
                                            index={idx}
                                        />
                                    ),
                                )}

                                {!children.length && (
                                    <Empty description={`Noting to show!`} />
                                )}
                            </SortableContainerCustom>

                            <div style={{ margin: "10px 0" }}>
                                <Space wrap>
                                    <Button
                                        disabled={disableWrite}
                                        onClick={() => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    "children",
                                                    children.length,
                                                ],
                                                {
                                                    ...getV2FillBlankDefaultValue(),
                                                    tmpId: uuid(),
                                                    order: children.length,
                                                },
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    >
                                        Add Fill Blank
                                    </Button>
                                    <Button
                                        disabled={disableWrite}
                                        onClick={() => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    "children",
                                                    children.length,
                                                ],
                                                {
                                                    ...PHONE_DEFAULT_VALUE,
                                                    tmpId: uuid(),
                                                    order: children.length,
                                                },
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    >
                                        Add Table
                                    </Button>
                                </Space>
                            </div>
                        </div>

                        <div style={{ margin: "30px 0" }}>
                            <h3>Sub Block Editor</h3>
                            <hr />
                            <div>
                                {children[currectSubBlock]?.type ===
                                    "v2_fill_blank" && (
                                    <EditorV2FillBlank {...childProps} />
                                )}

                                {children[currectSubBlock]?.type ===
                                    "table" && <EditorTable {...childProps} />}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default renderEditorMixed;
