const renderBlockImage = (block: any) => {
    const {
        data: { image, other },
    } = block;
    return (
        <div style={{ width: "40%" }}>
            {other?.duration ? <span>Duration: {other?.duration}</span> : null}

            <img src={image} style={{ width: "-webkit-fill-available" }} />
        </div>
    );
};

export default renderBlockImage;
