import { OrderList, OrderCreate, OrderEdit } from "./views";

import { Route } from "react-router-dom";
export const ORDER_ROUTES = [
    <Route path="/order" element={<OrderList />} />,

    <Route path="/order/create" element={<OrderCreate />} />,
    <Route path="/order/update/:user_id" element={<OrderEdit />} />,
];

export { OrderList, OrderCreate, OrderEdit } from "./views";
