import renderBlockV2Stories from "./renderBlock";
import renderEditorV2Stories from "./renderEditor";
import isValidCallbackV2Stories from "./isValid";
import V2_STORIES_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2Stories from "./renderSettings";
import renderCardV2Stories from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { V2_STORIES_BLOCK } = BLOCK_TYPES;

const V2_STORIES_BLOCK_CONFIG = {
    [V2_STORIES_BLOCK]: {
        label: "V2 Stories",
        defaultValue: V2_STORIES_DEFAULT_VALUE,
        renderBlock: renderBlockV2Stories,
        renderEditor: renderEditorV2Stories,
        renderCard: renderCardV2Stories,
        renderSettings: renderSettingsV2Stories,
        addCallback,
        isValidCallback: isValidCallbackV2Stories,
    },
};

export {
    renderBlockV2Stories,
    renderCardV2Stories,
    renderEditorV2Stories,
    renderSettingsV2Stories,
    isValidCallbackV2Stories,
    V2_STORIES_DEFAULT_VALUE,
    V2_STORIES_BLOCK_CONFIG,
};
