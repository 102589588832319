import { BLOCK_TYPES } from "src/modules/worksheet/components/blocks/common";
import { indexOf } from "lodash";

export const currentQuestionIndex = ({ blocks, currentBlock }: any) => {
    let totalQuestion = 0;
    if (!blocks || !blocks.length) {
        return totalQuestion;
    }
    for (const block of blocks) {
        const currentIndex = indexOf(blocks, block);

        if (currentIndex > currentBlock) {
            break;
        }
        if (
            [
                BLOCK_TYPES.MCQ_BLOCK,
                BLOCK_TYPES.CASE_STUDY_BLOCK,
                BLOCK_TYPES.SUBJECTIVE_BLOCK,
                BLOCK_TYPES.RE_ORDER_BLOCK,
            ].includes(block.type)
        ) {
            totalQuestion++;
        }
    }
    return totalQuestion;
};
