import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button, Image, message } from "antd";
import { withFormik } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderField,
    RenderSelect,
    RenderUpload,
} from "src/components";
import * as yup from "yup";
import { useAuth0 } from "@auth0/auth0-react";

import { Select } from "antd";
import axios from "axios";
import { captureException } from "@sentry/react";

const Option = Select.Option;

const DocumentForm = (props) => {
    const {
        onFinishFailed,
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
    } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);
    const [previewsLoading, setPreviewsLoading] = useState(false);
    const isMounted = useRef(false);

    async function createFile(url: string) {
        let response = await fetch(url);
        let data = await response.blob();
        let metadata = {
            type: "image/jpeg",
        };
        let file = new File([data], "test.jpg", metadata);
        return file;
    }

    const getPresignedURL = async (path = "") => {
        const bucket_name = process.env.REACT_APP_BUCKET_NAME;
        const url =
            "https://qjbn7p56fd.execute-api.ap-south-1.amazonaws.com/production/generate";

        const data = {
            bucket_name: bucket_name,
            key: process.env.REACT_APP_BUCKET_BASE + path + Date.now(),
        };
        // console.log(data);
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, options);
        const resObj = await response.json();

        return resObj;
    };

    const uploadFile = (file: any) =>
        new Promise(async (resolve, reject) => {
            try {
                const xhr = new XMLHttpRequest();
                const { url, bucket_url } = await getPresignedURL(
                    `home_explore/document`,
                );

                // S3 requires PUT method!
                xhr.open("PUT", url);
                xhr.onreadystatechange = async () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            // Calls the update prop
                            resolve(bucket_url);
                        } else {
                            reject(null);
                        }
                    }
                };
                xhr.send(file);
            } catch (e) {
                console.log("Error:", e);
                message.error("Something went wrong!");
                reject(null);
            }
        });

    const getPreviewImages = async (imageUrls: any) => {
        const imageFiles = await Promise.all(
            imageUrls.map(({ url }: any) => {
                return createFile(url);
            }),
        );

        const cdnImageUrls: any = [];

        for (var i = 0; i < imageFiles.length; i++) {
            const file = imageFiles[i];
            const imageUrl = await uploadFile(file);
            cdnImageUrls.push(imageUrl);
        }

        return cdnImageUrls;
    };

    useEffect(() => {
        if (isMounted.current) {
            if (values.url) {
                setPreviewsLoading(true);
                const fileUrl = values.url;
                axios
                    .post(
                        process.env.REACT_APP_PDF_CONVERT_API_BASE || "",
                        null,
                        {
                            params: {
                                Secret:
                                    process.env
                                        .REACT_APP_PDF_CONVERT_API_SECRET || "",
                                StoreFile: "true",
                                ImageQuality: "40",
                                File: fileUrl.trim(),
                                // https://ik.imagekit.io/thehomeworkapp/Concept_Maps__Teacher_kit_/Grade_10th_Maths/Chp-14_K87Q8FWhY.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1653494744648
                            },
                        },
                    )
                    .then((response) => {
                        const {
                            data: { Files },
                        } = response;

                        const imagesUrls = Files.map(({ Url }: any) => ({
                            url: Url,
                        }));
                        //   console.log(imagesUrls)
                        getPreviewImages(imagesUrls).then((cdnImageUrls) => {
                            setFieldValue(
                                "preview_images",
                                cdnImageUrls.map((url: any) => ({ url })),
                            );

                            console.log(
                                cdnImageUrls.map((url: any) => ({ url })),
                                cdnImageUrls.map((url: any) => ({ url }))
                                    .length,
                            );
                            setPreviewsLoading(false);
                        });
                    })
                    .catch((err) => {
                        captureException(err);
                        console.warn(err);
                        setPreviewsLoading(false);
                    });
            } else setFieldValue("preview_images", []);
        } else {
            isMounted.current = true;
        }
    }, [values.url]);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Document Title"
                        placeholder="Document Title"
                        name={"title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.title}
                        rules={[{ required: true }]}
                        path="document/"
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Document Banner"
                        placeholder="Document Banner"
                        name={"banner"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.banner}
                        rules={[{ required: true }]}
                        path="document/"
                    />
                </Col>

                <Col lg={20} md={20}>
                    <Field
                        label="Document Asset"
                        placeholder="Document Asset"
                        name={"url"}
                        icon="FontSizeOutlined"
                        component={RenderUpload}
                        type="textarea"
                        value={values.url}
                        rules={[{ required: true }]}
                        path="home-explore/document/"
                        uploadType="custom"
                    />
                </Col>

                {previewsLoading && (
                    <Col lg={4} md={4}>
                        <Button type="primary" loading={previewsLoading}>
                            Loading previews
                        </Button>
                    </Col>
                )}

                {values.preview_images.map(({ url }: any) => {
                    return <Image width={200} src={url} />;
                })}

                {/* <Col lg={24} md={24}>

                    <FieldArray
                        name={"preview_images"}
                        render={(arrayHelpers) => (
                            <RenderDynamicField
                                buttonText="Add Preview Image"
                                keys={[
                                    {
                                        key: "url",
                                        type: "text",
                                        label: "url",
                                    },
                                ]}
                                arrayHelpers={arrayHelpers}
                                values={values.preview_images}
                                name={"preview_images"}
                            />
                        )}
                    />
                </Col> */}

                <Col lg={24} md={24}>
                    <Field
                        label="Document Type"
                        placeholder="Document Type"
                        name={"type"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.type}
                        rules={[{ required: true }]}
                    >
                        <Option value="pdf">pdf</Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Document CTA Text"
                        placeholder="Document CTA Text"
                        name={"cta_text"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.cta_text}
                        rules={[{ required: true }]}
                        path="document/"
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Document CTA Action"
                        placeholder="Document CTA Action"
                        name={"cta_action"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.cta_action}
                        rules={[{ required: true }]}
                    >
                        <Option value="download">Download</Option>
                        <Option value="share">Share</Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Document Status"
                        placeholder="Document Status"
                        name={"status"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.status}
                        rules={[{ required: true }]}
                    >
                        <Option value="inactive">Inactive</Option>
                        <Option value="active">Active</Option>
                    </Field>
                </Col>

                <Col lg={12} md={24}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting || previewsLoading}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export const FormSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    url: yup.string().required("This field is required"),
    banner: yup.string().required("This field is required"),
    type: yup.string().required("This field is required"),
    cta_text: yup.string().required("This field is required"),
    cta_action: yup.string().required("This field is required"),
    status: yup.string().required("This field is required"),
});

const DocumentFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { document, user_id } = props;

        return {
            ...(document?.id ? { id: document.id } : {}),
            title: document?.title || "",
            url: document?.url || "",
            banner: document?.banner || "",
            preview_images: document?.preview_images || [],

            type: document?.type || "pdf",
            cta_text: document?.cta_text || "Download",
            cta_action: document?.cta_action || "download",

            category: document?.category || "",
            status: document?.status || "active",
            // user_id,
        };
    },
    validationSchema: FormSchema,

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = DocumentFormWithFormik(DocumentForm);

const FormikFormWithUser = (props) => {
    const { user } = useAuth0();

    const namespace = "https://hasura.io/jwt/claims/";
    const user_id = user && user[namespace + "user_id"];
    return <FormikForm {...props} user_id={user_id} />;
};

export default FormikFormWithUser;
