import React, { useEffect, useState } from "react";
import { useRouter } from "src/helpers";
import {
    Button,
    Card,
    Col,
    Empty,
    Modal,
    Row,
    Select,
    Space,
    Spin,
} from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { RenderTable, Layout, MetaTag, Option } from "src/components";
import axios from "axios";
import moment from "moment";
import ReactDiffViewer from "react-diff-viewer";
import { captureException } from "@sentry/react";

const AssignSetModal = ({ beforeData, afterData }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
        setTimeout(() => {
            setDropdownOpen(true);
        }, 300);
    };
    const handleCancel = () => {
        setDropdownOpen(false);
        setTimeout(() => {
            setIsModalVisible(false);
        }, 200);
    };

    return (
        <>
            <Button onClick={showModal}>Show Data </Button>
            <Modal
                bodyStyle={{ height: "auto" }}
                width={1000}
                title="View Changes"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <ReactDiffViewer
                    oldValue={JSON.stringify(beforeData, 2, "\t")}
                    newValue={JSON.stringify(afterData, 2, "\t")}
                    splitView={false}
                />
            </Modal>
        </>
    );
};

const ListView: React.FC = () => {
    const router = useRouter();
    const skip = router.query["skip"];
    const limit = router.query["limit"];
    const [count, setCount] = useState();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const globalSkip = 0;
    const globalLimit = 30;
    const [search, setSearch] = useState("");

    const actionNameArr = [
        "add_home_explore_tab",
        "update_home_explore_tab",
        "delete_home_explore_tab",
        "add_home_explore_worksheet",
        "update_home_explore_worksheet",
        "delete_home_explore_worksheet",
        "add_home_explore_book",
        "add_home_explore_chapter",
        "add_home_explore_topic",
        "update_home_explore_book",
        "update_home_explore_chapter",
        "update_home_explore_topic",
        "delete_home_explore_book",
        "delete_home_explore_chapter",
        "delete_home_explore_topic",
        "add_home_explore_document",
        "update_home_explore_document",
        "delete_home_explore_document",
        "add_plans",
        "update_plans",
        "delete_plans",
        "add_users",
        "update_users",
        "delete_users",
        "add_student",
        "update_student",
        "delete_student",
        "add_meme",
        "update_meme",
        "delete_meme",
        "add_question",
        "update_question",
        "delete_question",
        "add_concept",
        "update_concept",
        "delete_concept",
        "add_collection",
        "update_collection",
        "delete_collection",
        "add_set",
        "update_set",
        "delete_set",
        "add_set_collection",
        "update_set_collection",
        "delete_set_collection",
        "add_collection_content",
        "update_collection_content",
        "delete_collection_content",
    ];

    const handleChange = (value) => {
        setSearch(value);
    };

    useEffect(() => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_LOGS_BASE_URL}/prod/sg/getdashboardLogsSg?limit=${limit}&skip=${skip}&search=${search}`,
            )
            .then((response) => {
                setCount(response.data.count);
                setData(response.data.dashboardLogs);
                setLoading(false);
            })
            .catch(function (error) {
                captureException(error)
                console.log(error);
            });
    }, [limit, skip, search]);

    const getNextPage = () => {
        let tempSkip;
        let tempLimit;
        if (!skip) {
            tempSkip = globalSkip;
        } else {
            tempSkip = parseInt(skip);
        }
        if (!limit) {
            tempLimit = globalLimit;
        } else {
            tempLimit = parseInt(limit);
        }

        window.location.replace(
            `/logs?limit=${tempLimit}&skip=${Math.abs(tempSkip + tempLimit)}`,
        );
    };

    const getPrevPage = () => {
        let tempSkip;
        let tempLimit;
        if (!skip) {
            tempSkip = globalSkip;
        } else {
            tempSkip = parseInt(skip);
        }
        if (!limit) {
            tempLimit = globalLimit;
        } else {
            tempLimit = parseInt(limit);
        }
        if (tempSkip - tempLimit < 0) {
            tempSkip = globalSkip;
        } else {
            tempSkip = tempSkip - tempLimit;
        }

        window.location.replace(`/logs?limit=${tempLimit}&skip=${tempSkip}`);
    };

    const columns = [
        {
            title: "Created At",
            key: "created_at",
            render: (text, record) =>
                moment
                    .utc(record.dumped_at, "YYYY-MM-DD HH:mm:ss.SSSSSS")
                    .local()
                    .format("D MMM, YYYY, hh:mm a"),
        },
        {
            title: "Action Name",
            key: "action_name",
            render: (text, record) => (
                <Space size="middle" style={{ fontWeight: "bold" }}>
                    {record.action_name}{" "}
                </Space>
            ),
        },
        {
            title: "Action Data",
            key: "action_data",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <AssignSetModal
                            beforeData={record.action_data?.before}
                            afterData={record.action_data?.after}
                        />
                    </Space>
                );
            },
        },
    ];

    return (
        <Layout titles={[{ name: "Logs", link: "/logs" }]}>
            <MetaTag title="Logs" />
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Logs List</h1>
                </Col>
                <Col>
                    <Space size="large">
                        <Button
                            onClick={getPrevPage}
                            disabled={parseInt(skip) <= 0}
                        >
                            <ArrowLeftOutlined /> prev page
                        </Button>
                    </Space>
                    <Space size="large">
                        <Button
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            onClick={getNextPage}
                            disabled={data?.length + parseInt(skip) >= count}
                        >
                            next page <ArrowRightOutlined />
                        </Button>
                    </Space>
                    <Space>
                        Showing {parseInt(skip) + 1} to{" "}
                        {data?.length + parseInt(skip)} of {count}
                    </Space>
                </Col>
            </Row>
            <hr />
            <Card bodyStyle={{ padding: "8px" }}>
                <Row gutter={[24, 0]} align="middle">
                    <Col lg={8} md={24}>
                        <h4>Search by Action Name</h4>

                        <Select
                            style={{ align: "left", width: "100%" }}
                            placeholder="search action name here"
                            onChange={(e) => handleChange(e)}
                        >
                            {actionNameArr.map((item, index) => {
                                return <Option value={item}>{item}</Option>;
                            })}
                        </Select>
                    </Col>
                </Row>
            </Card>
            <hr />
            <Spin spinning={loading}>
                {/* spinning={loading} */}
                {Boolean(data?.length) ? (
                    <RenderTable
                        loading={loading}
                        columns={columns}
                        data={data || []}
                    />
                ) : (
                    <Empty description="No data found" />
                )}
            </Spin>
        </Layout>
    );
};

export default ListView;
