import { Card } from "antd";
import { renderer } from "../../helpers";

const renderBlock = (block: any) => {
    const {
        data: {
            v2_voice: { title },
        },
    } = block;
    return (
        <div>
            <Card>{renderer(title)}</Card>
        </div>
    );
};

export default renderBlock;
