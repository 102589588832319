import { Modal, Typography } from "antd";
import { Button, List } from "antd";
import React, { useState } from "react";

const InputVariablesList = (props: any) => {
    const { inputVariables = [], text = "Path" } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                type="primary"
                style={{
                    margin: "20px 0",
                }}
                onClick={showModal}
                disabled={inputVariables?.length === 0}
                size="small"
            >
                Check Input Variables for this {text}
            </Button>
            <Modal
                title={
                    <Typography.Title level={4}>
                        Input Variables List
                    </Typography.Title>
                }
                onCancel={closeForm}
                open={isModalOpen}
                destroyOnClose
                width={600}
                footer={null}
            >
                <List
                    size="large"
                    bordered
                    dataSource={inputVariables}
                    renderItem={({ variable_name }: any, index: number) => (
                        <List.Item key={index}>{variable_name}</List.Item>
                    )}
                />
            </Modal>
        </>
    );
};

export default InputVariablesList;
