import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import {
    Badge,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Typography,
    message,
} from "antd";
import { Button, List } from "antd";
import { arrayMoveImmutable } from "array-move";
import { cloneDeep, pullAt } from "lodash";
import React, { useEffect, useState } from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { CopyIcon } from "src/components";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: "pointer",
        }}
    />
));

const SortableContainerCustom = SortableContainer(({ children }: any) => {
    return <div>{children}</div>;
});

const SortableItemCustom = SortableElement((props: any) => {
    const { children, sid } = props;
    return (
        <div style={{ zIndex: 1000 }} key={`${sid}`}>
            {children}
        </div>
    );
});

const QuestionVariablesModal = (props: any) => {
    const {
        disableWrite,
        question_variables = [],
        question_derived_variables = [],
        setQuestionVariables,
    } = props;

    const [questionVars, setQuestionVars] = useState(question_variables);
    const [questionDerivedVars, setQuestionDerivedVars] = useState(
        question_derived_variables,
    );
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempQuesConfig, setTempQuesConfig] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = () => {
        if (!disableWrite) {
            if (
                questionVars.some((v) => !v.name) ||
                questionDerivedVars.some(
                    (v) => !v.name || !v.computeFunction?.output,
                )
            ) {
                message.error("Some fields are empty");
                return;
            } else {
                setQuestionVariables(questionVars, questionDerivedVars);
                message.success("Success!");
            }
        }
        setIsModalOpen(false);
    };

    useEffect(() => {
        setQuestionVars(question_variables);
        setQuestionDerivedVars(question_derived_variables);
    }, [isModalOpen]);

    const onSortQuestionEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(questionVars);
        tmpBlock = arrayMoveImmutable(tmpBlock, oldIndex, newIndex).filter(
            (el) => !!el,
        );
        setQuestionVars(tmpBlock);
    };

    const onSortDerivedEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(questionDerivedVars);
        tmpBlock = arrayMoveImmutable(tmpBlock, oldIndex, newIndex).filter(
            (el) => !!el,
        );
        setQuestionDerivedVars(tmpBlock);
    };

    return (
        <KTWrapper feature="question_variables">
            <Form.Item label={"Question Variables"}>
                <Button size="small" type="primary" ghost onClick={showModal}>
                    Add Variables
                </Button>
                <Modal
                    title={
                        <Typography.Title level={4}>
                            Question Variables List
                        </Typography.Title>
                    }
                    onCancel={closeForm}
                    onOk={handleSubmit}
                    open={isModalOpen}
                    destroyOnClose
                    width={600}
                    maskClosable={false}
                    style={{ top: 10 }}
                >
                    <Row
                        style={{
                            maxHeight: "70vh",
                            overflowY: "auto",
                            overflowX: "hidden",
                            scrollbarWidth: "thin",
                        }}
                        gutter={[20, 20]}
                    >
                        {!disableWrite && (
                            <>
                                <Col span={10}>
                                    <Button
                                        size="small"
                                        disabled={disableWrite}
                                        type="primary"
                                        onClick={() => {
                                            message.info(
                                                `Copied Question Variables to clipboard!`,
                                            );
                                            navigator.clipboard.writeText(
                                                JSON.stringify({
                                                    questionVars,
                                                    questionDerivedVars,
                                                }),
                                            );
                                        }}
                                    >
                                        Copy the Question Variables
                                    </Button>
                                </Col>
                                <Col span={14}>
                                    <Form.Item
                                        label={"Paste Question Variables here"}
                                    >
                                        <Input.TextArea
                                            disabled={disableWrite}
                                            value={tempQuesConfig}
                                            placeholder="Paste Variables here"
                                            onChange={(e) =>
                                                setTempQuesConfig(
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        <Button
                                            disabled={disableWrite}
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                try {
                                                    const parsedJson =
                                                        JSON.parse(
                                                            tempQuesConfig,
                                                        );
                                                    if (
                                                        parsedJson?.questionVars ||
                                                        parsedJson?.base_variables
                                                    )
                                                        setQuestionVars(
                                                            parsedJson?.questionVars ||
                                                                parsedJson?.base_variables,
                                                        );
                                                    if (
                                                        parsedJson?.questionDerivedVars ||
                                                        parsedJson?.derived_variables
                                                    )
                                                        setQuestionDerivedVars(
                                                            parsedJson?.questionDerivedVars ||
                                                                parsedJson?.derived_variables,
                                                        );
                                                    setTempQuesConfig("");
                                                    message.info("Updated!");
                                                } catch (e) {
                                                    // captureException(e)
                                                    message.error(
                                                        "Error in JSON!",
                                                    );
                                                }
                                            }}
                                        >
                                            Replace config
                                        </Button>
                                        <Divider type="vertical" />
                                        <Button
                                            disabled={disableWrite}
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                try {
                                                    const parsedJson: any =
                                                        JSON.parse(
                                                            tempQuesConfig,
                                                        );
                                                    setQuestionVars([
                                                        ...(questionVars || []),
                                                        ...(parsedJson?.questionVars ||
                                                            parsedJson?.base_variables ||
                                                            []),
                                                    ]);
                                                    setQuestionDerivedVars([
                                                        ...(questionDerivedVars ||
                                                            []),
                                                        ...(parsedJson?.questionDerivedVars ||
                                                            parsedJson?.derived_variables ||
                                                            []),
                                                    ]);
                                                    setTempQuesConfig("");
                                                    message.info("Updated!");
                                                } catch (e) {
                                                    // captureException(e);
                                                    message.error(
                                                        "Error in JSON!",
                                                    );
                                                }
                                            }}
                                        >
                                            Append config
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                        <Col span={24}>
                            <SortableContainerCustom
                                onSortEnd={onSortQuestionEnd}
                                useDragHandle
                            >
                                <List
                                    size="large"
                                    bordered
                                    header={<h3>Question Variables</h3>}
                                    dataSource={questionVars}
                                    renderItem={(item: any, index: number) => {
                                        const { name, value } = item;
                                        return (
                                            <SortableItemCustom
                                                disabled={disableWrite}
                                                index={index}
                                                sid={index}
                                                key={index}
                                            >
                                                <List.Item key={index}>
                                                    <Badge.Ribbon
                                                        text={index + 1}
                                                        color="#faad14"
                                                        placement="end"
                                                    >
                                                        <DragHandle />
                                                        <Row
                                                            style={{
                                                                paddingLeft:
                                                                    "20px",
                                                                minWidth:
                                                                    "400px",
                                                            }}
                                                        >
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    label="Name"
                                                                    validateStatus={
                                                                        !name?.trim?.()
                                                                            ? "error"
                                                                            : ""
                                                                    }
                                                                    help={
                                                                        !name?.trim?.()
                                                                            ? "Field cannot be empty"
                                                                            : ""
                                                                    }
                                                                >
                                                                    <Input
                                                                        value={
                                                                            name
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => {
                                                                            let tmpVars =
                                                                                cloneDeep(
                                                                                    questionVars,
                                                                                );
                                                                            tmpVars[
                                                                                index
                                                                            ].name =
                                                                                e.target.value;
                                                                            setQuestionVars(
                                                                                tmpVars,
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            disableWrite
                                                                        }
                                                                        placeholder="Name"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item label="Value">
                                                                    <Input.TextArea
                                                                        value={
                                                                            value
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => {
                                                                            let tmpVars =
                                                                                cloneDeep(
                                                                                    questionVars,
                                                                                );
                                                                            tmpVars[
                                                                                index
                                                                            ].value =
                                                                                e.target.value;
                                                                            setQuestionVars(
                                                                                tmpVars,
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            disableWrite
                                                                        }
                                                                        placeholder="Value"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Badge.Ribbon>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            width: "100%",
                                                            gap: "10px",
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <Button
                                                            icon={
                                                                <DeleteOutlined
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                />
                                                            }
                                                            shape="circle"
                                                            danger
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            onClick={() => {
                                                                let tmpChildren =
                                                                    cloneDeep(
                                                                        questionVars,
                                                                    );
                                                                pullAt(
                                                                    tmpChildren,
                                                                    index,
                                                                );
                                                                setQuestionVars(
                                                                    tmpChildren,
                                                                );
                                                            }}
                                                        />
                                                        <Button
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            icon={<CopyIcon />}
                                                            type="primary"
                                                            shape="circle"
                                                            onClick={() => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        questionVars,
                                                                    );
                                                                tmpBlock.splice(
                                                                    index,
                                                                    0,
                                                                    _.clone(
                                                                        item,
                                                                    ),
                                                                );
                                                                setQuestionVars(
                                                                    tmpBlock,
                                                                );
                                                                message.info(
                                                                    `Duplicated!`,
                                                                );
                                                            }}
                                                        />
                                                        <Button
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            type="primary"
                                                            size="small"
                                                            onClick={() => {
                                                                message.info(
                                                                    `Copied to clipboard!`,
                                                                );
                                                                navigator.clipboard.writeText(
                                                                    JSON.stringify(
                                                                        {
                                                                            questionVars:
                                                                                [
                                                                                    item,
                                                                                ],
                                                                        },
                                                                    ),
                                                                );
                                                            }}
                                                        >
                                                            Copy
                                                        </Button>
                                                    </div>
                                                </List.Item>
                                            </SortableItemCustom>
                                        );
                                    }}
                                    footer={
                                        <Button
                                            onClick={() => {
                                                setQuestionVars([
                                                    ...questionVars,
                                                    {
                                                        name: "",
                                                        value: "",
                                                    },
                                                ]);
                                            }}
                                            type="primary"
                                            ghost
                                            disabled={disableWrite}
                                        >
                                            Add Question Variable
                                        </Button>
                                    }
                                />
                            </SortableContainerCustom>
                        </Col>
                        <Col span={24}>
                            <SortableContainerCustom
                                onSortEnd={onSortDerivedEnd}
                                useDragHandle
                            >
                                <List
                                    size="large"
                                    bordered
                                    header={<h3>Question Derived Variables</h3>}
                                    dataSource={questionDerivedVars}
                                    renderItem={(item: any, index: number) => {
                                        const { name, computeFunction } = item;
                                        return (
                                            <SortableItemCustom
                                                disabled={disableWrite}
                                                index={index}
                                                sid={index}
                                                key={index}
                                            >
                                                <List.Item key={index}>
                                                    <Badge.Ribbon
                                                        text={index + 1}
                                                        color="#faad14"
                                                        placement="end"
                                                    >
                                                        <DragHandle />
                                                        <Row
                                                            style={{
                                                                paddingLeft:
                                                                    "20px",
                                                                minWidth:
                                                                    "400px",
                                                            }}
                                                        >
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    label="Name"
                                                                    validateStatus={
                                                                        !name?.trim?.()
                                                                            ? "error"
                                                                            : ""
                                                                    }
                                                                    help={
                                                                        !name?.trim?.()
                                                                            ? "Field cannot be empty"
                                                                            : ""
                                                                    }
                                                                >
                                                                    <Input
                                                                        value={
                                                                            name
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => {
                                                                            let tmpVars =
                                                                                cloneDeep(
                                                                                    questionDerivedVars,
                                                                                );
                                                                            tmpVars[
                                                                                index
                                                                            ].name =
                                                                                e.target.value;
                                                                            setQuestionDerivedVars(
                                                                                tmpVars,
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            disableWrite
                                                                        }
                                                                        placeholder="Name"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    label="Compute Function"
                                                                    validateStatus={
                                                                        !computeFunction?.output?.trim?.()
                                                                            ? "error"
                                                                            : ""
                                                                    }
                                                                    help={
                                                                        !computeFunction?.output?.trim?.()
                                                                            ? "Field cannot be empty"
                                                                            : ""
                                                                    }
                                                                >
                                                                    <Input.TextArea
                                                                        value={
                                                                            computeFunction.output
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => {
                                                                            let tmpVars =
                                                                                cloneDeep(
                                                                                    questionDerivedVars,
                                                                                );
                                                                            tmpVars[
                                                                                index
                                                                            ].computeFunction.output =
                                                                                e.target.value;
                                                                            setQuestionDerivedVars(
                                                                                tmpVars,
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            disableWrite
                                                                        }
                                                                        placeholder="Value"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Badge.Ribbon>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            width: "100%",
                                                            gap: "10px",
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <Button
                                                            icon={
                                                                <DeleteOutlined
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                />
                                                            }
                                                            shape="circle"
                                                            danger
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            onClick={() => {
                                                                let tmpChildren =
                                                                    cloneDeep(
                                                                        questionDerivedVars,
                                                                    );
                                                                pullAt(
                                                                    tmpChildren,
                                                                    index,
                                                                );
                                                                setQuestionDerivedVars(
                                                                    tmpChildren,
                                                                );
                                                            }}
                                                        />
                                                        <Button
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            icon={<CopyIcon />}
                                                            type="primary"
                                                            shape="circle"
                                                            onClick={() => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        questionDerivedVars,
                                                                    );
                                                                tmpBlock.splice(
                                                                    index,
                                                                    0,
                                                                    _.clone(
                                                                        item,
                                                                    ),
                                                                );
                                                                setQuestionDerivedVars(
                                                                    tmpBlock,
                                                                );
                                                                message.info(
                                                                    `Duplicated!`,
                                                                );
                                                            }}
                                                        />
                                                        <Button
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            type="primary"
                                                            size="small"
                                                            onClick={() => {
                                                                message.info(
                                                                    `Copied to clipboard!`,
                                                                );
                                                                navigator.clipboard.writeText(
                                                                    JSON.stringify(
                                                                        {
                                                                            questionDerivedVars:
                                                                                [
                                                                                    item,
                                                                                ],
                                                                        },
                                                                    ),
                                                                );
                                                            }}
                                                        >
                                                            Copy
                                                        </Button>
                                                    </div>
                                                </List.Item>
                                            </SortableItemCustom>
                                        );
                                    }}
                                    footer={
                                        <Button
                                            onClick={() => {
                                                setQuestionDerivedVars([
                                                    ...questionDerivedVars,
                                                    {
                                                        name: "",
                                                        computeFunction: {
                                                            output: "",
                                                            params: {},
                                                        },
                                                    },
                                                ]);
                                            }}
                                            type="primary"
                                            ghost
                                            disabled={disableWrite}
                                        >
                                            Add Question Derived Variable
                                        </Button>
                                    }
                                />
                            </SortableContainerCustom>
                        </Col>
                    </Row>
                </Modal>
            </Form.Item>
        </KTWrapper>
    );
};

export default QuestionVariablesModal;
