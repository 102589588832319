import React from "react";
import { Row, Col, Form, Button, Divider } from "antd";
import { withFormik, FieldArray } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderCheckBox,
    RenderField,
    RenderAutoComplete,
    RenderTags,
    RenderDynamicField,
    // RenderEnums,
    RenderSelect,
} from "src/components";
import { Select } from "antd";
const Option = Select.Option;

const OrderForm = (props) => {
    const { onFinishFailed, values, handleSubmit, isSubmitting } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={12} md={24}>
                    <Field
                        label="Order Name"
                        placeholder="Order Name"
                        name={"name"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.name}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Order role"
                        placeholder="Order role"
                        name={"role"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.role}
                        rules={[{ required: true }]}
                    >
                        <Option value="admin">Admin</Option>
                        <Option value="creator">Creator</Option>
                        <Option value="memer">Memer</Option>
                        <Option value="anonymous">Anonymous</Option>
                    </Field>
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Order email"
                        placeholder="Order email"
                        name={"email"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="email"
                        value={values.email}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const OrderFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { order } = props;
        return {
            ...(order?.id ? { id: order.id } : {}),
            name: order?.name || null,
            email: order?.email || null,
            role: order?.role || null,
        };
    },

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

export default OrderFormWithFormik(OrderForm);
