import React from "react";
import {
    Col,
    Form,
    Row,
    Select,
    Switch,
    Card,
    Divider,
    Button,
    Modal,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import _, { cloneDeep } from "lodash";
import { RenderUpload } from "src/components";
import { replaceCDNUrl } from "../../../../helpers";

interface RotationFlowProps {
    rotationFlow: any;
    setRotationFlow?: (flow: any) => void;
    setProperty?: (property: any) => void;
    isToolbarElement?: boolean;
}

interface RotationImagesProps {
    propImages: { url: string; anglesSupported: number[] }[];
    setPropImages: (
        images: { url: string; anglesSupported: number[] }[],
    ) => void;
}

const RotationImages: React.FC<RotationImagesProps> = ({
    propImages,
    setPropImages,
}) => {
    const [images, setImages] = React.useState<
        { url: string; anglesSupported: number[] }[]
    >([]);

    React.useEffect(() => {
        setImages(propImages ?? []);
    }, [propImages]);

    const handleUpdateImages = (imgs) => {
        setImages(imgs);
        setPropImages(imgs);
    };
    const handleImageChange = (index: number, key: string, value: any) => {
        const newImages = [...images];
        newImages[index] = { ...newImages[index], [key]: value };
        handleUpdateImages(newImages);
    };

    const addImage = () => {
        handleUpdateImages([...images, { url: "", anglesSupported: [] }]);
    };

    const removeImage = (index: number) => {
        const newImages = images.filter((_, i) => i !== index);
        handleUpdateImages(newImages);
    };

    return (
        <div
            style={{
                marginBottom: "10px",
                backgroundColor: "#f0f2f5",
                padding: "10px",
            }}
        >
            <h5>Rotation Images:</h5>
            {images.map((image, index) => (
                <Card
                    key={index}
                    style={{ marginBottom: "10px" }}
                    extra={<CloseOutlined onClick={() => removeImage(index)} />}
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item label={`Image URL ${index + 1}`}>
                                <RenderUpload
                                    singleUpload={true}
                                    disabled={false}
                                    addExtension={true}
                                    path="home-explore/document/"
                                    onChangeCustom={(e) => {
                                        const value = replaceCDNUrl(
                                            e.target.value,
                                            e.target.bucket,
                                        );
                                        handleImageChange(index, "url", value);
                                    }}
                                    label="upload the file"
                                    value={image.url}
                                />
                                <input
                                    type="text"
                                    value={image.url}
                                    onChange={(e) =>
                                        handleImageChange(
                                            index,
                                            "url",
                                            e.target.value,
                                        )
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Angles Supported">
                                <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Select supported angles"
                                    value={image.anglesSupported}
                                    onChange={(value) =>
                                        handleImageChange(
                                            index,
                                            "anglesSupported",
                                            value,
                                        )
                                    }
                                    options={[
                                        { value: 0, label: "0°" },
                                        { value: 90, label: "90°" },
                                        { value: 180, label: "180°" },
                                        { value: 270, label: "270°" },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            ))}
            <button onClick={addImage}>Add Image</button>
            <Divider />
        </div>
    );
};

const RotationFlow: React.FC<RotationFlowProps> = ({
    rotationFlow,
    setRotationFlow,
    setProperty,
    isToolbarElement = false,
}) => {
    console.log("rotationFlow----", rotationFlow);
    const handleChange = (key: string, value: any) => {
        if (!isToolbarElement) {
            let tmpFlow = _.cloneDeep(rotationFlow || {});
            if (key === "enabled") {
                tmpFlow = _.set(tmpFlow, key, value);
            } else {
                if (!tmpFlow?.settings) {
                    tmpFlow.settings = {};
                }
                tmpFlow.settings[key] = value;
            }
            setRotationFlow && setRotationFlow(tmpFlow);
        } else {
            setProperty &&
                setProperty({
                    type: "ROTATION_FLOW",
                    subType: key,
                    value,
                });
        }
    };

    return (
        <div
            style={{
                width: "100%",
                maxWidth: "500px",
            }}
        >
            <h5>Flow Control:</h5>
            <Form.Item label="Enable Flow">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={rotationFlow?.enabled}
                    onChange={(value) => handleChange("enabled", value)}
                />
            </Form.Item>
            {rotationFlow?.enabled && (
                <div>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Is Start">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={rotationFlow?.settings?.isStart}
                                    onChange={(value) =>
                                        handleChange("isStart", value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Is End">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={rotationFlow?.settings?.isEnd}
                                    onChange={(value) =>
                                        handleChange("isEnd", value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Openings">
                                <Select
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Select openings"
                                    value={
                                        rotationFlow?.settings?.openings || []
                                    }
                                    onChange={(value) =>
                                        handleChange("openings", value)
                                    }
                                    options={[
                                        { value: 0, label: "0°" },
                                        { value: 90, label: "90°" },
                                        { value: 180, label: "180°" },
                                        { value: 270, label: "270°" },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        {!rotationFlow?.settings?.isStart &&
                            !rotationFlow?.settings?.isEnd && (
                                <>
                                    <Col span={12}>
                                        <Form.Item label="Rotatable">
                                            <Switch
                                                checkedChildren={
                                                    <CheckOutlined />
                                                }
                                                unCheckedChildren={
                                                    <CloseOutlined />
                                                }
                                                checked={
                                                    rotationFlow?.settings
                                                        ?.rotatable
                                                }
                                                onChange={(value) =>
                                                    handleChange(
                                                        "rotatable",
                                                        value,
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Supported Rotations">
                                            <Select
                                                mode="multiple"
                                                style={{ width: "100%" }}
                                                placeholder="Select supported rotations"
                                                value={
                                                    rotationFlow?.settings
                                                        ?.supportedRotations ||
                                                    []
                                                }
                                                onChange={(value) =>
                                                    handleChange(
                                                        "supportedRotations",
                                                        value,
                                                    )
                                                }
                                                options={[
                                                    { value: 0, label: "0°" },
                                                    { value: 90, label: "90°" },
                                                    {
                                                        value: 180,
                                                        label: "180°",
                                                    },
                                                    {
                                                        value: 270,
                                                        label: "270°",
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Current Rotation">
                                            <Select
                                                style={{ width: "100%" }}
                                                placeholder="Select current rotation"
                                                value={
                                                    rotationFlow?.settings
                                                        ?.currentRotation
                                                }
                                                onChange={(value) =>
                                                    handleChange(
                                                        "currentRotation",
                                                        value,
                                                    )
                                                }
                                                options={[
                                                    { value: 0, label: "0°" },
                                                    { value: 90, label: "90°" },
                                                    {
                                                        value: 180,
                                                        label: "180°",
                                                    },
                                                    {
                                                        value: 270,
                                                        label: "270°",
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Rotation Unit">
                                            <Select
                                                style={{ width: "100%" }}
                                                placeholder="Select rotation unit"
                                                value={
                                                    rotationFlow?.settings
                                                        ?.rotationUnit
                                                }
                                                onChange={(value) =>
                                                    handleChange(
                                                        "rotationUnit",
                                                        value,
                                                    )
                                                }
                                                options={[
                                                    { value: 90, label: "90°" },
                                                    {
                                                        value: 180,
                                                        label: "180°",
                                                    },
                                                    {
                                                        value: 270,
                                                        label: "270°",
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Block Rotation">
                                            <Switch
                                                checkedChildren={
                                                    <CheckOutlined />
                                                }
                                                unCheckedChildren={
                                                    <CloseOutlined />
                                                }
                                                checked={
                                                    rotationFlow?.settings
                                                        ?.blockRotation
                                                }
                                                onChange={(value) =>
                                                    handleChange(
                                                        "blockRotation",
                                                        value,
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <RotationImages
                                            propImages={
                                                rotationFlow?.settings?.images
                                            }
                                            setPropImages={(value) =>
                                                handleChange("images", value)
                                            }
                                        />
                                    </Col>
                                </>
                            )}
                    </Row>
                </div>
            )}
        </div>
    );
};

const RotationFlowModalWrapper = (props: any) => {
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <>
            <Modal
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                onOk={() => setIsOpen(false)}
            >
                {isOpen && <RotationFlow {...props} />}
            </Modal>
            <Button type="primary" ghost onClick={() => setIsOpen(true)}>
                Rotation Flow
            </Button>
        </>
    );
};

export default RotationFlowModalWrapper;
