export const KEYBOARD_TYPES = {
    DIGITS: "digits",
    ALPHABETS: "alphabets",
    SYMBOLS: "symbols",
    LATEX: "latex",
    SETTINGS: "settings",
};

export const { DIGITS, ALPHABETS, SYMBOLS, LATEX, SETTINGS } = KEYBOARD_TYPES;

export const DEFAULT_DIGITS = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
];
export const DEFAULT_DIGITS_KEYBOARD = {
    type: DIGITS,
    keys: DEFAULT_DIGITS,
};

export const DEFAULT_ALPHABETS = [""];
export const DEFAULT_ALPHABETS_KEYBOARD = {
    type: ALPHABETS,
    keys: DEFAULT_ALPHABETS,
};

export const DEFAULT_SYMBOLS = [":", "÷", "×", "-", "+"];
export const DEFAULT_SYMBOLS_KEYBOARD = {
    type: SYMBOLS,
    keys: DEFAULT_SYMBOLS,
};

export const DEFAULT_SETTINGS_KEYBOARD = {
    type: SETTINGS,
    is_separated: false,
    has_space: false,
    has_nextline: false,
    has_caps: false,
};

export const DEFAULT_LATEX = [
    "plus",
    "minus",
    "times",
    "frac",
    "obelus",
    "leftParenthesis",
    "rightParenthesis",
    "lbrace",
    "rbrace",
    "lbracket",
    "rbracket",
    "angle",
    "degree",
    "overleftrightarrow",
    "overline",
    "overrightarrow",
    "pi",
    "dot",
    "comma",
    "equal",
    "neq",
    "power",
    "square",
    "cube",
    "colon",
    "percent",
    "rArr",
];
export const DEFAULT_LATEX_KEYBOARD = {
    type: LATEX,
    keys: DEFAULT_LATEX,
};

export const QWERTY_KEYS = [
    "q",
    "w",
    "e",
    "r",
    "t",
    "y",
    "u",
    "i",
    "o",
    "p",
    "a",
    "s",
    "d",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    "z",
    "x",
    "c",
    "v",
    "b",
    "n",
    "m",
];

export const DEFAULT_CUSTOM_KEYBOARD = [DEFAULT_DIGITS_KEYBOARD];
