import {
    TabList,
    TabCreate,
    TabEdit,
    TabBookCreate,
    TabBookEdit,
    TabChapterList,
    TabChapterCreate,
    TabChapterEdit,
    TabTopicList,
    TabTopicCreate,
    TabTopicEdit,
} from "./views";

import { Route } from "react-router-dom";
export const TAB_ROUTES = [
    <Route path="/tab" element={<TabList />} />,
    <Route path="/tab/create" element={<TabCreate />} />,

    <Route path="/tab/update/:tab_id" element={<TabEdit />} />,
    <Route path="/tab/book/create/:tab_id" element={<TabBookCreate />} />,
    <Route path="/tab/book/update/:book_id" element={<TabBookEdit />} />,
    <Route path="/tab/chapter/:book_id" element={<TabChapterList />} />,
    <Route
        path="/tab/chapter/create/:book_id"
        element={<TabChapterCreate />}
    />,
    <Route
        path="/tab/chapter/update/:chapter_id"
        element={<TabChapterEdit />}
    />,
    <Route path="/tab/topic/:chapter_id" element={<TabTopicList />} />,
    <Route path="/tab/topic/create/:chapter_id" element={<TabTopicCreate />} />,
    <Route path="/tab/topic/update/:topic_id" element={<TabTopicEdit />} />,
];

export {
    TabList,
    TabCreate,
    TabEdit,
    TabBookCreate,
    TabBookEdit,
    TabChapterList,
    TabChapterCreate,
    TabChapterEdit,
    TabTopicList,
    TabTopicCreate,
    TabTopicEdit,
} from "./views";
