import DRAG_AND_SWIPE_DEFAULT_VALUE from "./defaultValue";
import renderCardDragAndSwipe from "./renderCard";
import renderBlockDragAndSwipe from "./renderBlock";
import renderSettingsDragAndSwipe from "./renderSettings";
import isValidCallbackDragAndSwipe from "./isValid";
import renderEditorDragAndSwipe from "./renderEditor";
import { addCallback, BLOCK_TYPES } from "../../common";

const { DRAG_AND_SWIPE_BLOCK } = BLOCK_TYPES;

const DRAG_AND_SWIPE_BLOCK_CONFIG = {
    [DRAG_AND_SWIPE_BLOCK]: {
        label: "Drag and Swipe",
        defaultValue: DRAG_AND_SWIPE_DEFAULT_VALUE,
        renderBlock: renderBlockDragAndSwipe,
        renderEditor: renderEditorDragAndSwipe,
        renderCard: renderCardDragAndSwipe,
        renderSettings: renderSettingsDragAndSwipe,
        addCallback,
        isValidCallback: isValidCallbackDragAndSwipe,
    },
};

export {
    DRAG_AND_SWIPE_DEFAULT_VALUE,
    DRAG_AND_SWIPE_BLOCK_CONFIG,
    renderBlockDragAndSwipe,
    renderCardDragAndSwipe,
    renderSettingsDragAndSwipe,
    renderEditorDragAndSwipe,
    isValidCallbackDragAndSwipe,
};
