import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import {
    Badge,
    Divider,
    Input,
    Modal,
    Switch,
    Typography,
    Form,
    Space,
    Row,
    Col,
    Button,
    message,
    Select,
} from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { CONFIG_BLOCK_STATES, CONFIG_VALUES } from "../defaultValue";
import VideoField from "../../../common/VideoField";
import LearnigModeEditor from "./LearnigModeEditor";
import { checkLMConfig } from "../isValid";
import { AudioGenerateComponent } from "src/modules/worksheet/components/WorksheetEditor/components/Modals/AudioGenerateComponent";
import { captureException } from "@sentry/react";

const ConfigEditor = (props: any) => {
    const {
        isModalOpen,
        closeForm,
        config,
        onSave,
        toggleShouldBlockUI,
        disableWrite,

        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        isReadOnlyMode,
    } = props;

    const [isSubmitting, setSubmitting] = useState(false);
    const [curConfig, setCurConfig] = useState(config);
    const [loading, setLoading] = useState(false);
    const [tempConfig, setTempConfig] = useState("");

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            await onSave(curConfig);
            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e);
            console.log(e);
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        closeForm();
    };

    useEffect(() => {
        if (loading) {
            setTimeout(() => setLoading(false), 50);
        }
    }, [loading]);

    return (
        <>
            <Modal
                title={
                    <Typography.Title level={4}>Add Config</Typography.Title>
                }
                onCancel={closeForm}
                open={isModalOpen}
                destroyOnClose
                width={1000}
                footer={null}
            >
                {curConfig?.length > 0 && (
                    <>
                        <Button
                            disabled={disableWrite}
                            type="primary"
                            onClick={() => {
                                message.info(`Copied Config to clipboard!`);

                                navigator.clipboard.writeText(
                                    JSON.stringify(curConfig),
                                );
                            }}
                        >
                            Copy this Config to Clipboard
                        </Button>
                        <Divider />
                    </>
                )}
                <Form.Item label={"Paste Config here"}>
                    <Input.TextArea
                        disabled={disableWrite}
                        value={tempConfig}
                        placeholder="Paste Config here"
                        onChange={(e) => setTempConfig(e.target.value)}
                    />
                    <Button
                        disabled={disableWrite}
                        style={{ marginTop: "10px" }}
                        type="primary"
                        onClick={() => {
                            try {
                                const parsedJson = JSON.parse(tempConfig);
                                if (!Array.isArray(parsedJson)) {
                                    message.error("Error in JSON!");
                                    return;
                                }
                                setLoading(true);
                                setCurConfig(parsedJson);
                                setTempConfig("");
                                message.info("Updated!");
                            } catch (e) {
                                // captureException(e)
                                message.error("Error in JSON!");
                            }
                        }}
                    >
                        Update config
                    </Button>
                </Form.Item>
                <Form>
                    <Row gutter={16}>
                        <Col
                            span={24}
                            style={{
                                display: "flex",
                                gap: "20px",
                                flexWrap: "wrap",
                            }}
                        >
                            {Object.keys(CONFIG_VALUES).map(
                                (item: any, key) => (
                                    <Button
                                        key={key}
                                        ghost
                                        type="primary"
                                        disabled={
                                            (disableWrite &&
                                                item !== "solution") ||
                                            isReadOnlyMode
                                        }
                                        onClick={() => {
                                            let tmpBlock =
                                                _.cloneDeep(curConfig);
                                            tmpBlock.push(
                                                CONFIG_VALUES[item].data,
                                            );
                                            setCurConfig(tmpBlock);
                                        }}
                                    >
                                        Add New {CONFIG_VALUES[item].label}
                                    </Button>
                                ),
                            )}
                        </Col>

                        <Col
                            span={24}
                            style={{
                                marginTop: "20px",
                            }}
                        >
                            {!loading &&
                                Array.isArray(curConfig) &&
                                curConfig?.map(
                                    (
                                        data: {
                                            type: string;
                                            value: any;
                                            other: {
                                                is_reset: boolean;
                                                audio: string;
                                            };
                                        },
                                        idx: number,
                                    ) =>
                                        !data ? (
                                            <></>
                                        ) : (
                                            <div
                                                key={`item-${idx}`}
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <Badge.Ribbon
                                                    color={"cyan"}
                                                    text={idx + 1}
                                                    placement={"start"}
                                                >
                                                    <div
                                                        style={{
                                                            border: "1px solid #717171",
                                                            borderRadius:
                                                                "15px",
                                                            padding: "10px",
                                                            width: "100%",
                                                            display: "flex",
                                                            gap: "20px",
                                                        }}
                                                    >
                                                        <div />
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Row
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                gutter={[
                                                                    20, 20,
                                                                ]}
                                                            >
                                                                <Col span={21}>
                                                                    <h3>
                                                                        {
                                                                            CONFIG_VALUES[
                                                                                data
                                                                                    ?.type
                                                                            ]
                                                                                ?.label
                                                                        }
                                                                    </h3>
                                                                </Col>
                                                                <Col
                                                                    span={2}
                                                                    style={{
                                                                        paddingTop:
                                                                            "4px",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        disabled={
                                                                            disableWrite &&
                                                                            data?.type !==
                                                                                "solution"
                                                                        }
                                                                        icon={
                                                                            <DeleteOutlined />
                                                                        }
                                                                        type="primary"
                                                                        shape="circle"
                                                                        danger
                                                                        size="small"
                                                                        onClick={() => {
                                                                            let tmpBlock =
                                                                                _.cloneDeep(
                                                                                    curConfig,
                                                                                );
                                                                            _.pullAt(
                                                                                tmpBlock,
                                                                                idx,
                                                                            );
                                                                            setCurConfig(
                                                                                tmpBlock,
                                                                            );
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            <Divider />
                                                            <Row
                                                                key={idx}
                                                                justify="start"
                                                                style={{
                                                                    border: "1px solid #ECECEC",
                                                                    borderRadius:
                                                                        "15px",
                                                                    padding:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <Col
                                                                    span={24}
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {data?.type ===
                                                                        "block_state" && (
                                                                        <Form.Item
                                                                            label={
                                                                                "Value"
                                                                            }
                                                                        >
                                                                            <Select
                                                                                disabled={
                                                                                    disableWrite
                                                                                }
                                                                                defaultValue={
                                                                                    data?.value
                                                                                }
                                                                                onChange={(
                                                                                    value,
                                                                                ) => {
                                                                                    let tmpBlock =
                                                                                        _.cloneDeep(
                                                                                            curConfig,
                                                                                        );
                                                                                    tmpBlock =
                                                                                        _.set(
                                                                                            tmpBlock,
                                                                                            [
                                                                                                idx,
                                                                                                "value",
                                                                                            ],
                                                                                            value,
                                                                                        );
                                                                                    setCurConfig(
                                                                                        tmpBlock,
                                                                                    );
                                                                                }}
                                                                                style={{
                                                                                    textTransform:
                                                                                        "capitalize",
                                                                                    minWidth:
                                                                                        "200px",
                                                                                }}
                                                                                dropdownStyle={{
                                                                                    textTransform:
                                                                                        "capitalize",
                                                                                    minWidth:
                                                                                        "200px",
                                                                                }}
                                                                            >
                                                                                {CONFIG_BLOCK_STATES.map(
                                                                                    (
                                                                                        item,
                                                                                    ) => (
                                                                                        <Select.Option
                                                                                            value={
                                                                                                item
                                                                                            }
                                                                                        >
                                                                                            {item
                                                                                                .split(
                                                                                                    "_",
                                                                                                )
                                                                                                .join(
                                                                                                    " + ",
                                                                                                )}
                                                                                        </Select.Option>
                                                                                    ),
                                                                                )}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    )}

                                                                    {data?.type ===
                                                                        "solution" && (
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <VideoField
                                                                                label={`Solution Video URL`}
                                                                                path={[
                                                                                    "value",
                                                                                ]}
                                                                                block={
                                                                                    data
                                                                                }
                                                                                disabled={
                                                                                    isReadOnlyMode
                                                                                }
                                                                                setBlock={(
                                                                                    value: any,
                                                                                ) => {
                                                                                    let tmpBlock =
                                                                                        _.cloneDeep(
                                                                                            curConfig,
                                                                                        );
                                                                                    tmpBlock =
                                                                                        _.set(
                                                                                            tmpBlock,
                                                                                            [
                                                                                                idx,
                                                                                            ],
                                                                                            value,
                                                                                        );
                                                                                    setCurConfig(
                                                                                        tmpBlock,
                                                                                    );
                                                                                }}
                                                                                isYoutube={
                                                                                    true
                                                                                }
                                                                                toggleShouldBlockUI={
                                                                                    toggleShouldBlockUI
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {data?.type ===
                                                                        "learning_mode" && (
                                                                        <LearnigModeEditor
                                                                            {...{
                                                                                isReadOnlyMode,
                                                                                isEditor,
                                                                                setIsEditor,
                                                                                isNewBlock,
                                                                                setIsNewBlock,
                                                                                worksheet,
                                                                                data,
                                                                                disableWrite,
                                                                                setConfigChildren:
                                                                                    (
                                                                                        value: any,
                                                                                    ) => {
                                                                                        let tmpBlock =
                                                                                            _.cloneDeep(
                                                                                                curConfig,
                                                                                            );
                                                                                        tmpBlock =
                                                                                            _.set(
                                                                                                tmpBlock,
                                                                                                [
                                                                                                    idx,
                                                                                                    "other",
                                                                                                    "children",
                                                                                                ],
                                                                                                value,
                                                                                            );
                                                                                        setCurConfig(
                                                                                            tmpBlock,
                                                                                        );
                                                                                    },
                                                                            }}
                                                                        />
                                                                    )}

                                                                    {data?.type ===
                                                                        "try_again" && (
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                gap: "20px",
                                                                                width: "100%",
                                                                                flexDirection:
                                                                                    "column",
                                                                            }}
                                                                        >
                                                                            <Form.Item label="Reset State?">
                                                                                <Switch
                                                                                    disabled={
                                                                                        disableWrite
                                                                                    }
                                                                                    checkedChildren={
                                                                                        <CheckOutlined />
                                                                                    }
                                                                                    unCheckedChildren={
                                                                                        <CloseOutlined />
                                                                                    }
                                                                                    checked={
                                                                                        data
                                                                                            ?.other
                                                                                            ?.is_reset
                                                                                    }
                                                                                    onChange={(
                                                                                        value,
                                                                                    ) => {
                                                                                        let tmpBlock =
                                                                                            _.cloneDeep(
                                                                                                curConfig,
                                                                                            );
                                                                                        tmpBlock =
                                                                                            _.set(
                                                                                                tmpBlock,
                                                                                                [
                                                                                                    idx,
                                                                                                    "other",
                                                                                                    "is_reset",
                                                                                                ],
                                                                                                value,
                                                                                            );
                                                                                        setCurConfig(
                                                                                            tmpBlock,
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Form.Item>

                                                                            <Form.Item label="Audio">
                                                                                <AudioGenerateComponent
                                                                                    name={
                                                                                        "Try Again State"
                                                                                    }
                                                                                    disableWrite={
                                                                                        isReadOnlyMode
                                                                                    }
                                                                                    audioData={
                                                                                        data
                                                                                            ?.other
                                                                                            ?.audio
                                                                                    }
                                                                                    onSave={(
                                                                                        value: any,
                                                                                    ) => {
                                                                                        let tmpBlock =
                                                                                            _.cloneDeep(
                                                                                                curConfig,
                                                                                            );
                                                                                        tmpBlock =
                                                                                            _.set(
                                                                                                tmpBlock,
                                                                                                [
                                                                                                    idx,
                                                                                                    "other",
                                                                                                    "audio",
                                                                                                ],
                                                                                                value,
                                                                                            );
                                                                                        setCurConfig(
                                                                                            tmpBlock,
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Badge.Ribbon>
                                            </div>
                                        ),
                                )}
                        </Col>
                        <Col style={{ marginTop: "30px" }} span={24}>
                            <Space style={{ float: "right" }}>
                                <Button type="default" onClick={closeForm}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                    disabled={checkLMConfig(curConfig)}
                                >
                                    Submit
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ConfigEditor;
