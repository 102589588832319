import {
    getPluginType,
    insertNodes,
    PlateEditor,
    TElement,
} from "@udecode/plate-core";
import { ELEMENT_AUDIO } from "../createAudioPlugin";

export const insertAudio = (editor: PlateEditor, audioString: string) => {
    const text = { text: audioString };
    const audio = {
        type: getPluginType(editor, ELEMENT_AUDIO),
        children: [text],
    };
    insertNodes<TElement>(editor, audio);
};
