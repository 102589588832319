import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
// import { useLazyQuery } from "@apollo/client";
import { TagsAssignComponent } from "../../common";
// import { WORKSHEET_BLOCK_QUERY } from "src/modules/worksheet/graphql";
import { Form, Input, Select, Switch } from "antd";
import _ from "lodash";
import { checkExactInValid, checkRangeInValid } from "./isValid";

const renderSettingsMCQ = (props: any, isMCQ = true) => {
    const { block, setBlock, currentSubBlock, worksheet } = props;

    // const [getBlock, { loading: loadingBlock, error: errorBlock, data = {} }] =
    //     useLazyQuery(WORKSHEET_BLOCK_QUERY, {
    //         fetchPolicy: "network-only",
    //     });

    const multiSelect = block?.data?.mcq?.multiSelect;
    const options = block?.data?.mcq?.options;

    return (
        <div>
            {/* <InstantSearch
                searchClient={searchClient}
                indexName="worksheet_block_search"
            >
                <SearchBox />
                <RefinementList
                    attribute="type"
                    defaultRefinement={[block.type]}
                />
                <CustomHits
                    hitComponent={Hit}
                    onClick={async (b: any) => {
                        // fetch block from server
                        const {
                            data: { block },
                        } = await getBlock({
                            variables: { id: b.blockId },
                        });
                        setBlock(block);
                    }}
                />
            </InstantSearch> */}

            <TagsAssignComponent
                key={block.id + (currentSubBlock ? `${currentSubBlock}` : ``)}
                handleChange={(data: any) => {
                    let tmpBlock = _.cloneDeep(block);
                    tmpBlock = _.set(tmpBlock, ["tags"], data);
                    setBlock(tmpBlock);
                }}
                selected={
                    block.tags?.map(
                        ({ label, value, key, tag }: any, index: number) => {
                            return {
                                value: value || JSON.stringify(tag),
                                key:
                                    key ||
                                    tag.id ||
                                    tag?.tmpId ||
                                    JSON.parse(value).tmpId,
                                label: label || `${tag.value} (${tag.name})`,
                            };
                        },
                    ) || []
                }
            />

            {!isMCQ && (
                <>
                    <br />
                    <Form.Item label="Randomize options">
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={_.get(block, ["data", "mcq", "random"])}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "mcq", "random"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </>
            )}

            {worksheet?.type === "quiz_form" && (
                <>
                    <Form.Item
                        style={{ marginTop: "20px" }}
                        label="Multiple Selection"
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={multiSelect?.isMultiSelect}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);

                                if (multiSelect) {
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            "mcq",
                                            "multiSelect",
                                            "isMultiSelect",
                                        ],
                                        value,
                                    );
                                } else {
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "mcq", "multiSelect"],
                                        {
                                            isMultiSelect: value,
                                            type: "unlimited", // range, unlimited, exact
                                            range: [1, 1],
                                            exact: 1,
                                        },
                                    );
                                }
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>

                    <Form.Item label="Selection Type">
                        <Select
                            defaultValue={multiSelect?.type}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "mcq", "multiSelect", "type"],
                                    value,
                                );

                                setBlock(tmpBlock);
                            }}
                            options={[
                                { value: "unlimited", label: "Unlimited" },
                                { value: "range", label: "Range" },
                                { value: "exact", label: "Exact Number" },
                            ]}
                        />
                    </Form.Item>

                    {multiSelect?.type == "exact" && options?.length > 0 && (
                        <Form.Item
                            label="Exact Number"
                            validateStatus={
                                checkExactInValid(
                                    worksheet,
                                    multiSelect,
                                    options,
                                )
                                    ? "error"
                                    : ""
                            }
                            help={
                                checkExactInValid(
                                    worksheet,
                                    multiSelect,
                                    options,
                                )
                                    ? "Invalid value"
                                    : ""
                            }
                        >
                            <Input
                                value={multiSelect?.exact}
                                onChange={(e) => {
                                    let tmpBlock = _.cloneDeep(block);

                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "mcq", "multiSelect", "exact"],
                                        e.target.value,
                                    );

                                    setBlock(tmpBlock);
                                }}
                                required
                                type="number"
                                max={options?.length || 1}
                                min={1}
                                placeholder={`1 - ${options?.length}`}
                            />
                        </Form.Item>
                    )}

                    {multiSelect?.type == "range" && options?.length > 0 && (
                        <Form.Item
                            label="Range"
                            validateStatus={
                                checkRangeInValid(
                                    worksheet,
                                    multiSelect,
                                    options,
                                )
                                    ? "error"
                                    : ""
                            }
                            help={
                                checkRangeInValid(
                                    worksheet,
                                    multiSelect,
                                    options,
                                )
                                    ? "Invalid value"
                                    : ""
                            }
                        >
                            <Input
                                value={multiSelect?.range[0]}
                                onChange={(e) => {
                                    let tmpBlock = _.cloneDeep(block);

                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "mcq", "multiSelect", "range"],
                                        [e.target.value, multiSelect?.range[1]],
                                    );

                                    setBlock(tmpBlock);
                                }}
                                required
                                type="number"
                                max={options?.length || 1}
                                min={1}
                                placeholder={`1`}
                                style={{ width: "50%" }}
                            />

                            <Input
                                value={multiSelect?.range[1]}
                                onChange={(e) => {
                                    let tmpBlock = _.cloneDeep(block);

                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "mcq", "multiSelect", "range"],
                                        [multiSelect?.range[0], e.target.value],
                                    );

                                    setBlock(tmpBlock);
                                }}
                                required
                                type="number"
                                max={options?.length || 1}
                                min={1}
                                placeholder={`${options?.length}`}
                                style={{ width: "50%" }}
                            />
                        </Form.Item>
                    )}
                </>
            )}
        </div>
    );
};

export default renderSettingsMCQ;
