import { v4 as uuid } from "uuid";
import { BLOCK_TYPES } from "../../common/index";
const { PROGRESS_LEVEL_BLOCK } = BLOCK_TYPES;

const COMPUTE_FUNCS: any = [
    {
        name: "getNextChunk",
        output: "function getNextChunk(history, next, global_context_variables) {\n  return next;\n}",
        params: [],
        label: "Get Next Chunk",
        isGlobal: true,
    },
];

export const BLOCK_COMPUTE_FUNCS: any = [
    {
        name: "getNextBlock",
        output: "function getNextBlock(history, next, global_context_variables) {\n  return next;\n}",
        params: [],
        label: "Get Next Block",
        isGlobal: true,
    },
];

export const DEFAULT_CHUNK_SCHEMA = (type = "personalized_learning_v3") => {
    if (type === "personalized_learning_v4")
        return {
            type: "chunk",
            data: {
                chunk: {
                    name: "Default chunk title",
                    dev_remarks: [],
                },
            },
            children: [],
            backend: {
                is_variant: false,
                compute_functions: COMPUTE_FUNCS,
                slug: "",
            },
        };
    return {
        type: "chunk",
        data: {
            slug: "",
            chunk: {
                name: "Default chunk title",
                is_variant: false,
                compute_functions: COMPUTE_FUNCS,
                dev_remarks: [],
                blocks: [],
            },
        },
    };
};

const DEFAULT_VALUE = {
    type: PROGRESS_LEVEL_BLOCK,
    data: {
        slug: "",
        [PROGRESS_LEVEL_BLOCK]: {
            name: "Default level title",
            description: "",
            passing_badge_count: 1,
            banner: "",
            card_banner: "",
            compute_functions: [],
            global_context_variables: {
                base_variables: [],
                derived_variables: [],
            },
            dev_remarks: [],
        },
    },
    children: [
        {
            order: 1,
            tmpId: uuid(),
            ...DEFAULT_CHUNK_SCHEMA(),
        },
    ],
};

export const V4_PROGRESS_LEVEL_DEFAULT_VALUE = (
    type = "personalized_learning_v3",
) => {
    if (type === "personalized_learning_v4")
        return {
            type: PROGRESS_LEVEL_BLOCK,
            data: {
                [PROGRESS_LEVEL_BLOCK]: {
                    name: "Default level title",
                    description: "",
                    banner: "",
                    card_banner: "",
                    dev_remarks: [],
                },
            },
            children: [
                {
                    order: 1,
                    tmpId: uuid(),
                    ...DEFAULT_CHUNK_SCHEMA(type),
                },
            ],
            backend: {
                passing_badge_count: 1,
                compute_functions: [],
                global_context_variables: {
                    base_variables: [],
                    derived_variables: [],
                },
                slug: "",
            },
        };

    return DEFAULT_VALUE;
};

export default DEFAULT_VALUE;
