import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button } from "antd";

const CollapseBtn = (props) => {
  const { collapsed, toggleCollapsed, style = {} } = props;
  return (
    <Button
      type="primary"
      size="small"
      onClick={toggleCollapsed}
      style={style}
    >
      {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    </Button>
  );
};

export default CollapseBtn;
