import { BlocksColumn } from "./BlocksColumn";
import { DataColumn } from "./DataColumn";
import { SettingsColumn } from "./SettingsColumn";
import { BlockCard } from "./BlocksColumn/BlockCard";
import { Affix, Col, Row } from "antd";

const Editor = ({
    blocksColumnProps,
    dataColumnProps,
    settingColumnProps,
}: any) => {
    return (
        <Row justify="center" align="top" style={{ paddingBottom: "10px" }}>
            <Col xs={5} lg={5} md={5}>
                <Affix offsetTop={75}>
                    <BlocksColumn {...blocksColumnProps} />
                </Affix>
            </Col>
            <Col xs={15} lg={15} md={15}>
                <DataColumn {...dataColumnProps} />
            </Col>
            <Col xs={4} lg={4} md={4}>
                <Affix offsetTop={75}>
                    <SettingsColumn {...settingColumnProps} />
                </Affix>
            </Col>
        </Row>
    );
};

export { BlockCard, BlocksColumn, DataColumn, SettingsColumn, Editor };
