export const TAP_LIST_OPERATIONS = [
    {
        label: "Smallest",
        value: "smallest",
    },
    { label: "Greatest", value: "greatest" },
];

export const DRAG_LIST_OPERATIONS = [
    {
        label: "Ascending",
        value: "ascending",
    },
    { label: "Descending", value: "descending" },
];

export const NUMBER_SYSTEM_LIST_OPERATIONS = [
    { label: "Indian", value: "indian" },
    { label: "International", value: "international" },
];
