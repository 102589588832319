import CancelIcon from "src/components/CancelIcon";
import { Form, Input, Button, Card, Row, Col, Switch } from "antd";
import { useEffect, useRef, useState } from "react";
import { AudioGenerateComponent } from "src/modules/worksheet/components/WorksheetEditor/components/Modals/AudioGenerateComponent";
// import { AudioFilled } from "@ant-design/icons";
const { TextArea } = Input;
const FormItem = Form.Item;
// import AudioSpeechToTextModal from "./AudioSpeechToTextModal";

export const AudioInsertComponent = (props: any) => {
    const { onChange, onDelete, value } = props;
    const inputRef = useRef<any>();
    // const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    // Solving Caret Jumping in React Inputs
    const [val, setVal] = useState(value);
    const [config, setConfig] = useState(props.config ?? {});
    const updateVal = (val: any) => {
        /* Make update synchronous, to avoid caret jumping when the value doesn't change asynchronously */
        setVal(val);
        /* Make the real update afterwards */
        onChange({ audio: val, config });
    };
    const updateConfig = (type, value) => {
        setConfig((prev: any) => {
            onChange({ video: val, config: { ...prev, [type]: value } });
            return { ...prev, [type]: value };
        });
    };

    return (
        <Card>
            <Form layout={"inline"} component={false}>
                <Row gutter={12}>
                    <Col span={12}>
                        <FormItem className="audio-insert-form-item">
                            <TextArea
                                placeholder="type/paste audio"
                                value={val}
                                onChange={(e) => updateVal(e.target.value)}
                                ref={inputRef}
                                autoSize
                            />
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem label={"Limit reached msg"}>
                            <TextArea
                                value={config.restrict_play_msg}
                                onChange={(e) =>
                                    updateConfig(
                                        "restrict_play_msg",
                                        e.target.value,
                                    )
                                }
                                autoSize
                            />
                        </FormItem>

                        <h3>Limit Reached Audio</h3>
                        <AudioGenerateComponent
                            name={"Limit Reached Audio"}
                            onSave={(data: any) => {
                                updateConfig("audio", data);
                            }}
                            audioData={config?.audio}
                        />
                    </Col>

                    <Col span={12}>
                        <FormItem label="Max Play limit">
                            <Input
                                value={config.max_play_limit}
                                onChange={(e) =>
                                    updateConfig(
                                        "max_play_limit",
                                        e.target.value,
                                    )
                                }
                                type="number"
                            />
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem label="Try again Max Play limit">
                            <Input
                                value={config.try_again_max_play_limit}
                                onChange={(e) =>
                                    updateConfig(
                                        "try_again_max_play_limit",
                                        e.target.value,
                                    )
                                }
                                type="number"
                            />
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Show Controls">
                            <Switch
                                checked={config.show_controls}
                                onChange={(value) =>
                                    updateConfig("show_controls", value)
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col>
                        {/* <FormItem className="audio-insert-form-item">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<Save />}
                                style={{ padding: "2px" }}
                                onClick={() => {onChange(audio);}}
                            />
                            </FormItem> */}
                        {/* <FormItem
                            className="audio-insert-form-item"
                            style={{ marginLeft: "10px" }}
                        >
                            <Button
                                icon={
                                    <AudioFilled
                                        onClick={() => {
                                            setModalShow(true);
                                        }}
                                    />
                                }
                                shape="circle"
                                {...props}
                            />
                        </FormItem> */}

                        <FormItem
                            className="audio-insert-form-item"
                            style={{ marginLeft: "10px" }}
                        >
                            <CancelIcon
                                isButton={true}
                                onClick={() => {
                                    onDelete();
                                }}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>

            {/* <AudioSpeechToTextModal
                title="Add Equeation"
                show={modalShow}
                onHide={() => {
                    setModalShow(false);
                }}
                submitText="Copy Equeation"
            /> */}
        </Card>
    );
};
