import React from "react";
import { compose } from "src/helpers";
import {
    withHookForAggregateMeme,
    withHookForMemes,
} from "src/modules/meme/operations";
import { withStateAndActionsForMeme } from "src/modules/meme/resolvers";
import ListView from "./component";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    withStateAndActionsForMeme,
    withHookForMemes,
    withHookForAggregateMeme,
)(List);
