import renderBlockV2Voice from "./renderBlock";
import renderEditorV2Voice from "./renderEditor";
import isValidCallbackV2Voice from "./isValid";
import V2_VOICE_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2Voice from "./renderSettings";
import renderCardV2Voice from "./renderCard";
import { BLOCK_TYPES } from "../../common/index";

const { V2_VOICE_BLOCK } = BLOCK_TYPES;

const V2_VOICE_BLOCK_CONFIG = {
    [V2_VOICE_BLOCK]: {
        label: "V2 Voice",
        defaultValue: V2_VOICE_DEFAULT_VALUE,
        renderBlock: renderBlockV2Voice,
        renderEditor: renderEditorV2Voice,
        renderCard: renderCardV2Voice,
        renderSettings: renderSettingsV2Voice,
        isValidCallback: isValidCallbackV2Voice,
    },
};

export {
    renderBlockV2Voice,
    renderCardV2Voice,
    renderEditorV2Voice,
    renderSettingsV2Voice,
    isValidCallbackV2Voice,
    V2_VOICE_DEFAULT_VALUE,
    V2_VOICE_BLOCK_CONFIG,
};
