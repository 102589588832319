import React, { useState } from "react";
import { Link } from "src/components";
import { Button, Col, Divider, Row, Space, Spin } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";

import DeleteView from "src/modules/question/components/DeleteView";
import { EditIcon, RenderTable, Layout, MetaTag } from "src/components";

import { FilterComponent } from "src/modules/question/components";

const ListView: React.FC = (props: any) => {
    const { loading, questions, questionAggregate, loadQuestionsData } = props;

    const columns = [
        {
            title: "text",
            key: "text",
            render: (text, record) => record?.text,
        },
        {
            title: "options",
            key: "options",
            render: (text, record) => record?.options,
        },
        {
            title: "correct_option",
            key: "correct_option",
            render: (text, record) => record?.correct_option,
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            render: (text, record) => (
                <Space size="middle">
                    <Link href={`/question/update/${record?.id}`}>
                        <EditIcon isButton={true} />
                    </Link>
                    {/* <Divider />
                    <DeleteView id={record?.id} isButton={true} /> */}
                </Space>
            ),
        },
    ];

    return (
        <Layout titles={[{ name: "Questions", link: "/question" }]}>
            <MetaTag title="Questions" />
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Questions List</h1>
                </Col>
                <Col>
                    <Link href={"/question/create"}>
                        <Button type="primary" icon={<AppstoreAddOutlined />}>
                            Add
                        </Button>
                    </Link>
                </Col>
            </Row>
            <hr />

            {/* <FilterComponent isQuestion={true} {...props} /> */}
            <hr />

            <Spin spinning={loading}>
                {Boolean(questions?.length) && (
                    <RenderTable
                        loading={loading}
                        columns={columns}
                        data={questions || []}
                        loadData={loadQuestionsData}
                        aggregate={questionAggregate?.aggregate?.count}
                    />
                )}
            </Spin>
        </Layout>
    );
};

export default ListView;
