import React, { useEffect, useMemo } from "react";
import { getBackgroundColor, getBoxShadow } from "./helpers";

const CustomInput = ({
    style = {},
    fontStyle = {},
    initialValue,
    isTextarea = false,
    placeholder,
    inputProps = {},
    value,
    hardBackgroundColor = false,
    itemId,
}) => {
    const inputRef = React.useRef(null);
    const extraBorder = () => {
        return { border: "0.5px solid #333333" };
    };

    const inputBackground = useMemo(() => {
        if (hardBackgroundColor) {
            return style.backgroundColor;
        } else {
            return getBackgroundColor(inputProps?.bg_index?.value);
        }
    }, [
        hardBackgroundColor,
        style.backgroundColor,
        inputProps?.bg_index?.value,
    ]);

    useEffect(() => {
        if (inputRef.current) {
            // insert a stylesheet which customizes a class 's placeholder font color
            const existingStyleSheet = document.getElementById(
                "react-math-keyboard-input-placeholder" + itemId,
            );
            const styleSheet =
                existingStyleSheet ?? document.createElement("style");
            styleSheet.type = "text/css";
            styleSheet.id = "react-math-keyboard-input-placeholder" + itemId;
            styleSheet.innerText = `
                #${itemId}::placeholder {
                    color: ${fontStyle.color};
                }
            `;
            document.head.appendChild(styleSheet);
        }
    }, [fontStyle.color]);

    return (
        <div
            style={{
                display: "flex",
                width: "auto",
                border: 0,
                ...style,
                ...extraBorder(),
                boxShadow: getBoxShadow(inputProps?.highlight?.value),
            }}
            className="react-math-keyboard-input-container"
        >
            {isTextarea ? (
                <textarea
                    className="react-math-keyboard-input"
                    style={{
                        ...fontStyle,
                        border: 0,
                        resize: "none",
                        background: inputBackground,
                        pointerEvents: "nome",
                    }}
                    inputMode="none"
                    placeholder={placeholder}
                    value={initialValue || value}
                    ref={inputRef}
                    id={itemId}
                />
            ) : (
                <input
                    className="react-math-keyboard-input"
                    style={{
                        ...fontStyle,
                        color: "black",
                        fontWeight: "500",
                        border: 0,
                        background: inputBackground,
                        pointerEvents: "none",
                    }}
                    inputMode="none"
                    placeholder={placeholder}
                    value={initialValue || value}
                    ref={inputRef}
                    id={itemId}
                />
            )}
        </div>
    );
};

export default CustomInput;
