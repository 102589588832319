import { BLOCK_TYPES } from "../../common/index";
const { FIGMA_BLOCK } = BLOCK_TYPES;

const V2_VOICE_DEFAULT_VALUE = {
    type: FIGMA_BLOCK,
    data: {
        [FIGMA_BLOCK]: {
            name: "",
            render_via_pixi: false,
            figma_data: {
                url: "",
                file_key: "",
                node_id: "",
            },
            figma_json: {},
            variables: [],
            referred_variables: [],
            compute_functions: [],
        },
        other: {
            is_fixed_block: false,
            duration: 0,
            audio: "",
            description: [],
            global_feedback: null,
            stories: [],
        },
    },
};

export default V2_VOICE_DEFAULT_VALUE;
