import { SlateEditor } from "src/components/plate";
import { Col, Divider, Row } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { BLOCK_TYPES } from "../../common/";
import InputBlock from "../../common/InputBlock";

const { LONG_ANSWER_BLOCK } = BLOCK_TYPES;

const renderEditor = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    setIsNewBlock,
    isNewBlock,
    mentionsList = [],
    hasMentions,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [LONG_ANSWER_BLOCK]: {
                text,
                solution,
                description = [],
                isRequired,
            },
        },
    } = block;

    const [currentEditor, setCurrentEditor] = useState(`${id || tmpId}_text`);

    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_text`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    return (
        <div>
            <Row style={{ width: "100%" }}>
                <Col span={22}>
                    <SlateEditor
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_text`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", LONG_ANSWER_BLOCK, "text"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={currentEditor == `${id || tmpId}_text`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={text}
                        placeholder={"Type your question here..."}
                        textStyle={{
                            padding: "0 14px",
                            fontSize: "18px",
                            color: "#444",
                        }}
                        addAsterisk={isRequired}
                    />
                </Col>
            </Row>

            <Row style={{ width: "100%" }}>
                <Col span={22}>
                    <SlateEditor
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_description`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", LONG_ANSWER_BLOCK, "description"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={
                            currentEditor == `${id || tmpId}_description`
                        }
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={description}
                        required={false}
                        placeholder={"Description (optional)"}
                        textStyle={{
                            padding: "0 14px",
                            fontSize: "18px",
                            color: "#999",
                        }}
                    />
                </Col>
            </Row>

            <InputBlock type={LONG_ANSWER_BLOCK} />
            <Divider
                style={{
                    background: "#555",
                    margin: "40px 0",
                }}
            />

            <Row>
                <h3
                    style={{
                        padding: "0px 24px",
                        fontWeight: "bold",
                    }}
                >
                    Solution
                </h3>
            </Row>
            <Row>
                <Col span={22}>
                    <SlateEditor
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_solution`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", LONG_ANSWER_BLOCK, "solution"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        value={solution}
                        isFocused={currentEditor == `${id || tmpId}_solution`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                        required={false}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default renderEditor;
