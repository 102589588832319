import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import React from "react";
import { MathRender } from "src/components";

export default function MathQuillCorrectValues({
    setCurConfig,
    inputKey,
    inputs,
    setInputs,
    disableWrite,
}: any) {
    if (!Object.keys(inputs).length) return null;
    return (
        <>
            {inputs[inputKey].map((latex: string, idx: number) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                        }}
                        key={`${inputKey}-${idx}`}
                    >
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                            }}
                        >
                            <Input.TextArea
                                disabled={disableWrite}
                                value={latex}
                                placeholder="Correct Answer"
                                onChange={(e) => {
                                    const newInputs = { ...inputs };
                                    newInputs[inputKey] = newInputs[
                                        inputKey
                                    ].map((v: any, vIdx: number) => {
                                        if (vIdx === idx) {
                                            return e.target.value;
                                        }
                                        return v;
                                    });
                                    setInputs(newInputs);
                                    setCurConfig(Object.values(newInputs));
                                }}
                                style={{
                                    width: "60%",
                                    flexShrink: 0,
                                }}
                            />
                            <div
                                style={{
                                    padding: "10px",
                                    width: "30%",
                                    // flexShrink: 0,
                                    border: "1px dashed #aaa",
                                }}
                            >
                                <MathRender src={`$${latex}$` ?? ""} />
                            </div>
                            <Button
                                disabled={disableWrite}
                                icon={<DeleteOutlined />}
                                type="primary"
                                shape="circle"
                                danger
                                size="small"
                                onClick={() => {
                                    const newInputs = { ...inputs };
                                    newInputs[inputKey] = newInputs[
                                        inputKey
                                    ].filter(
                                        (_: any, vIdx: number) => vIdx !== idx,
                                    );
                                    setInputs(newInputs);
                                }}
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );
}
