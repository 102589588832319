import React from "react";
import { Card } from "antd";

import { renderer } from "../../helpers/";
import { renderTags } from "../../common/index";

const renderBlockSubjective = (block: any) => {
    const {
        data: {
            subjective: { text, solution },
        },
        tags,
    } = block;
    return (
        <div>
            {renderTags(tags)}
            <Card>{renderer(text)}</Card>
            <Card>{renderer(solution)}</Card>
        </div>
    );
};

export default renderBlockSubjective;
