import { BLOCK_TYPES, isEditorEmpty } from "../../common";

const {
    DRAG_ARRANGE_LIST_BLOCK,
    COMPARE_ARRANGE_LIST_BLOCK,
    SCRATCH_ARRANGE_LIST_BLOCK,
    COMPARE_DIGITS_BLOCK,
    INPUT_ARRANGE_LIST_BLOCK,
    DRAG_EXPAND_NUMBER_BLOCK,
    INPUT_EXPAND_NUMBER_BLOCK,
    INPUT_AND_FORM_BLOCK,
    V2_INPUT_AND_FORM_BLOCK,
} = BLOCK_TYPES;

const isValidCallback = ({
    block,
    blockType = DRAG_ARRANGE_LIST_BLOCK,
}: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            [blockType]: { options, correct_answer, compute_function },
            other: { description = [] } = {},
        },
    } = block;

    return {
        isValid:
            !isEditorEmpty(description) &&
            options?.length > 0 &&
            !options.some(
                (row: any) =>
                    (Array.isArray(row) && !row?.length) ||
                    (row?.type === "input_variable" && !row?.value),
            ) &&
            ([
                INPUT_ARRANGE_LIST_BLOCK,
                DRAG_EXPAND_NUMBER_BLOCK,
                INPUT_EXPAND_NUMBER_BLOCK,
                INPUT_AND_FORM_BLOCK,
                V2_INPUT_AND_FORM_BLOCK,
            ].includes(blockType) ||
                ([
                    DRAG_ARRANGE_LIST_BLOCK,
                    COMPARE_ARRANGE_LIST_BLOCK,
                    SCRATCH_ARRANGE_LIST_BLOCK,
                    COMPARE_DIGITS_BLOCK,
                ].includes(blockType) &&
                    ((!compute_function?.is_enabled &&
                        correct_answer?.length > 0) ||
                        (compute_function?.is_enabled &&
                            compute_function?.value)))),
        error: <></>,
    };
};

export default isValidCallback;
