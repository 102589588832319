import { Link as RLink } from "react-router-dom";

export const Link = ({ children, href }: any) => {
    if (typeof href === "string") return <RLink to={href}>{children}</RLink>;
    else {
        const { pathname, query } = href;

        return (
            <RLink to={`${pathname}?${new URLSearchParams(query).toString()}`}>
                {children}
            </RLink>
        );
    }
};
