import React, { useEffect, useState } from "react";
import { compose } from "src/helpers";
import {
    withCreateGroupCollection,
    withDeleteGroupCollection,
    withHookForAggregateTopic,
    withHookForTopics,
    withUpdateGroupCollection,
    withUpdateChapterOther,
} from "src/modules/book/operations";
import { withStateAndActionsForBook as withStateAndActionsForTopic } from "src/modules/book/resolvers";
import ListView from "./component";
import { useRouter } from "src/helpers";
import { useLazyQuery } from "@apollo/client";
import { TOPICS_QUERY } from "src/modules/book/graphql";

const List: React.FC = (props: any) => {
    const { chapter_id, setGroupId, setGroupTitle } = props;
    const [topics, setTopics] = useState([]);

    const [getTopics, { loading: loadingTopics, error, data = {} }] =
        useLazyQuery(
            TOPICS_QUERY,

            {
                variables: { parent_id: chapter_id },
                fetchPolicy: "network-only",
            },
        );

    useEffect(() => {
        const asyncFunc = async () => {
            if (chapter_id) {
                const { data } = await getTopics();
                // console.log("topics", data.topics);
                if (data.topics && data.topics.length) {
                    console.log(data.topics[0]);
                    const topicFirst = data.topics[0];
                    setGroupId(topicFirst.id);
                    setGroupTitle(topicFirst.title);
                }

                setTopics(data.topics);
            }
        };
        asyncFunc();
    }, [chapter_id]);

    const extraProps = {
        chapter_id,
        loadingTopics,
        topics,
    };

    return <ListView {...props} {...extraProps} />;
};

export default compose(
    // withStateAndActionsForTopic,
    // withHookForTopics,
    // withHookForAggregateTopic,
    withUpdateGroupCollection,
    withCreateGroupCollection,
    withDeleteGroupCollection,
    withUpdateChapterOther,
)(List);
