import DRAG_AND_TAG_DEFAULT_VALUE from "./defaultValue";
import renderCardDragAndTag from "./renderCard";
import renderBlockDragAndTag from "./renderBlock";
import renderSettingsDragAndTag from "./renderSettings";
import isValidCallbackDragAndTag from "./isValid";
import renderEditorDragAndTag from "./renderEditor";
import { addCallback, BLOCK_TYPES } from "../../common";

const { DRAG_AND_TAG_BLOCK } = BLOCK_TYPES;

const DRAG_AND_TAG_BLOCK_CONFIG = {
    [DRAG_AND_TAG_BLOCK]: {
        label: "Drag and Tag",
        defaultValue: DRAG_AND_TAG_DEFAULT_VALUE,
        renderBlock: renderBlockDragAndTag,
        renderEditor: renderEditorDragAndTag,
        renderCard: renderCardDragAndTag,
        renderSettings: renderSettingsDragAndTag,
        addCallback,
        isValidCallback: isValidCallbackDragAndTag,
    },
};

export {
    DRAG_AND_TAG_DEFAULT_VALUE,
    DRAG_AND_TAG_BLOCK_CONFIG,
    renderBlockDragAndTag,
    renderCardDragAndTag,
    renderSettingsDragAndTag,
    renderEditorDragAndTag,
    isValidCallbackDragAndTag,
};
