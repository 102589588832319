import { Button, Modal, Typography } from "antd";
import React, { useState } from "react";
import BlockJumpCard from "./BlockJumpCard";

const { Text } = Typography;

const JumpLogicModal = (props: any) => {
    const { blocks, isSaving } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Text>Branching logic & conditions</Text>

            <Button
                style={{
                    marginTop: "10px",
                }}
                type="primary"
                block
                disabled={isSaving || !blocks?.length}
                onClick={showModal}
            >
                Add
            </Button>
            <Modal
                title="Add branching logic and conditions"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                width={720}
            >
                <Text>
                    Note: Please re-define the conditions on changing the order
                    of blocks
                </Text>
                <div
                    style={{
                        marginTop: "20px",
                    }}
                />
                {blocks.map((block: any, idx: any) => (
                    <BlockJumpCard
                        key={idx}
                        {...props}
                        idx={idx}
                        block={block}
                    />
                ))}
                <div
                    style={{
                        marginTop: "20px",
                    }}
                />
                <Text>
                    Note: Please re-define the conditions on changing the order
                    of blocks
                </Text>
            </Modal>
        </>
    );
};

export default JumpLogicModal;
