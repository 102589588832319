import { gql } from "@apollo/client";export default gql`mutation updateStudent(
    $id: Int!
    $mobile: String = ""
    $class: Int = 0
    $streak: Int = 0
    $trophies: Int = 0
    $points: Int = 0
    $attempts: Int = 0
) {
    updateStudent: update_student_by_pk(
        pk_columns: { id: $id }
        _student: {
            mobile: $mobile
            class: $class
            streak: $streak
            trophies: $trophies
            points: $points
            attempts: $attempts
        }
    ) {
        id
        mobile
        class
        streak
        trophies
        points
        attempts
        stars
    }
}
`;
