import React from "react";
import PropTypes from "prop-types";
import { Form, Checkbox, Space } from "antd";

const FormItem = Form.Item;

const RenderCheckBox = ({
    icon = "CheckCircleOutlined",
    input,
    label,
    meta: { touched, error },
    inFilter = false,
    checkStyle = {},
}) => {
    let validateStatus = "";
    if (touched && error) {
        validateStatus = "error";
    }
    const labels = inFilter
        ? {}
        : {
              labelCol: { span: 24 },
              wrapperCol: { span: 24 },
          };
    return (
        <FormItem
            // label={
            //   <Space align="center">
            //     {icon && <Icon type={icon} />}
            //     {label}
            //   </Space>
            // }
            style={{ paddingTop: "40px", ...checkStyle }}
            validateStatus={validateStatus}
            help={error}
            {...labels}
        >
            <Checkbox {...input}>{label}</Checkbox>
        </FormItem>
    );
};

RenderCheckBox.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.object,
    icon: PropTypes.string,
    inFilter: PropTypes.bool,
};

export default RenderCheckBox;
