import { gql } from "@apollo/client";
export default gql`
mutation InsertWorksheetBlockMap(
    $objects: [worksheet_worksheet_block_map_insert_input!]!
) {
    insert_worksheet_worksheet_block_map(
        objects: $objects
        on_conflict: {
            constraint: worksheet_block_map_pkey
            update_columns: [order]
        }
    ) {
        affected_rows
        returning {
            id
            order
            worksheet_id
            block {
                id
                data
                parent_id
                type
                children(order_by: { order: asc }) {
                    id
                    data
                    type
                    order
                    tags {
                        id
                        tag_id
                        tag {
                            id
                            name
                            value
                        }
                    }
                }
                tags {
                    id
                    tag_id
                    tag {
                        id
                        name
                        value
                    }
                }
            }
        }
    }
}
`;
