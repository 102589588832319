import { SlateEditor } from "src/components/plate";
import { Col, Form, Input, Row, Select } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { AudioPicker, BLOCK_TYPES, DurationPicker } from "../../common/index";
import { CustomKeyboardEditor } from "src/components";
import PreviewBlocksField from "../../common/PreviewBlocksField";

const { INPUT_ARRANGE_LIST_BLOCK } = BLOCK_TYPES;

const Editor = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    setIsNewBlock,
    isNewBlock,
    worksheet,

    showDuration = true,
    showBackgroundAudio = true,
    blockType = INPUT_ARRANGE_LIST_BLOCK,
    showFeedback = false,
    hasMentions,
    mentionsList = [],
    disableWrite,
    hasPreviewBlocks,
    isReadOnlyMode,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [blockType]: {
                // allow_repeat,
                custom_keyboard = [],
                input_type = {},
                digit_length,
            },
            other: { description = [] },
        },
    } = block;

    const [currentEditor, setCurrentEditor] = useState("");
    // const [loading, setLoading] = useState(false);

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    // useEffect(() => {
    //     setLoading(true);
    //     // if (!disableWrite) setCurrentEditor(`${id || tmpId}_description`);
    //     setTimeout(() => setLoading(false), 50);
    // }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    return (
        <div>
            <h3
                style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                }}
            >
                Preview Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                {hasPreviewBlocks && (
                    <PreviewBlocksField
                        disableWrite={disableWrite}
                        fixed_blocks={block?.data?.other?.fixed_blocks || []}
                        updateFixedBlocks={(data: any) => {
                            const obj = {
                                ...block,
                                data: {
                                    ...block.data,
                                    other: {
                                        ...block.data.other,
                                        fixed_blocks: data,
                                    },
                                },
                            };
                            setBlock(obj);
                        }}
                    />
                )}

                <Col span={22}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Question
                    </h3>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_description`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "other", "description"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={
                            currentEditor === `${id || tmpId}_description`
                        }
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={description}
                        placeholder={"Type your description here..."}
                    />
                </Col>
                <Col span={24}>
                    {showBackgroundAudio &&
                        [
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <h3>Background Audio</h3>
                                <AudioPicker
                                    disabled={isReadOnlyMode}
                                    block={block}
                                    setBlock={setBlock}
                                />
                            </div>
                        )}
                </Col>
            </Row>

            <h3
                style={{
                    fontWeight: "bold",
                    margin: "10px 0",
                }}
            >
                Play Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                <Col span={24}>
                    <Col span={12}>
                        <Form.Item label="Digits Length">
                            <Input
                                disabled={disableWrite}
                                type="number"
                                placeholder={"Type the value here..."}
                                value={digit_length}
                                onChange={({ target: { value } }: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", blockType, "digit_length"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                                <Form.Item label="Allow repeat input?">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={allow_repeat}
                                        onChange={(value) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    blockType,
                                                    "allow_repeat",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            </Col> */}
                    <Col span={12}>
                        <Form.Item label="Input Type">
                            <Select
                                disabled={disableWrite}
                                value={input_type?.type}
                                style={{ width: 120 }}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            blockType,
                                            "input_type",
                                            "type",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                options={[
                                    {
                                        label: "Min-Max",
                                        value: "min-max",
                                    },
                                    { label: "Fixed", value: "fixed" },
                                ]}
                            />
                        </Form.Item>
                    </Col>

                    {input_type?.type === "min-max" && (
                        <>
                            <Col span={12}>
                                <Form.Item
                                    label="Minimum"
                                    validateStatus={
                                        Number(input_type?.maximum) <
                                        Number(input_type?.minimum)
                                            ? "error"
                                            : ""
                                    }
                                    help={
                                        Number(input_type?.maximum) <
                                        Number(input_type?.minimum)
                                            ? "Invalid Field"
                                            : ""
                                    }
                                >
                                    <Input
                                        disabled={disableWrite}
                                        type="number"
                                        placeholder={"Type the value here..."}
                                        value={input_type?.minimum}
                                        onChange={({
                                            target: { value },
                                        }: any) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    blockType,
                                                    "input_type",
                                                    "minimum",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Maximum"
                                    validateStatus={
                                        Number(input_type?.maximum) <
                                        Number(input_type?.minimum)
                                            ? "error"
                                            : ""
                                    }
                                    help={
                                        Number(input_type?.maximum) <
                                        Number(input_type?.minimum)
                                            ? "Invalid Field"
                                            : ""
                                    }
                                >
                                    <Input
                                        value={input_type?.maximum}
                                        onChange={({
                                            target: { value },
                                        }: any) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    blockType,
                                                    "input_type",
                                                    "maximum",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    )}

                    {input_type?.type === "fixed" && (
                        <Col span={12}>
                            <Form.Item label="No of Inputs">
                                <Input
                                    disabled={disableWrite}
                                    type="number"
                                    placeholder={"Type the value here..."}
                                    value={input_type?.fixed}
                                    onChange={({ target: { value } }: any) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "data",
                                                blockType,
                                                "input_type",
                                                "fixed",
                                            ],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    )}

                    <Col span={24}>
                        <Form.Item label="Custom Keyboard">
                            <CustomKeyboardEditor
                                disabled={disableWrite}
                                value={custom_keyboard}
                                onOk={(data: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", blockType, "custom_keyboard"],
                                        data,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>

                    {showDuration &&
                        [
                            "timed",
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    margin: "20px 0",
                                }}
                            >
                                <h3>Duration</h3>
                                <DurationPicker
                                    disabled={disableWrite}
                                    onChange={onDurationChange}
                                    initialValue={
                                        block?.data?.other?.duration || 0
                                    }
                                />
                            </div>
                        )}
                </Col>
            </Row>
        </div>
    );
};

export default Editor;
