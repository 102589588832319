import renderBlockImage from "./renderBlock";
import renderEditorImage from "./renderEditor";
import isValidCallbackImage from "./isValid";
import IMAGE_DEFAULT_VALUE from "./defaultValue";
import renderSettingsImage from "./renderSettings";
import renderCardImage from "./renderCard";
import { BLOCK_TYPES, addCallback } from "../../common/index";

const { IMAGE_BLOCK } = BLOCK_TYPES;

const IMAGE_BLOCK_CONFIG = {
    [IMAGE_BLOCK]: {
        label: "Image",
        defaultValue: IMAGE_DEFAULT_VALUE,
        renderBlock: renderBlockImage,
        renderEditor: renderEditorImage,
        renderCard: renderCardImage,
        renderSettings: renderSettingsImage,
        addCallback,
        isValidCallback: isValidCallbackImage,
    },
};

export {
    renderBlockImage,
    renderCardImage,
    renderEditorImage,
    renderSettingsImage,
    isValidCallbackImage,
    IMAGE_DEFAULT_VALUE,
    IMAGE_BLOCK_CONFIG,
};
