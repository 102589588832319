import { gql } from "@apollo/client";export default gql`mutation createOrder($object: subscription_order_insert_input = {}) {
  insert_subscription_order_one(object: $object, on_conflict: { constraint: order_pkey, update_columns: id }) {
    id
    human_readable_id
    created_at
    breakdown
    amount
    other
    payment_id
    status
    updated_at
    user_id
  }
}
`;
