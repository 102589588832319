import { Button, Field, RenderSelect } from "src/components";
import { compose, resetFilterHook } from "src/helpers";
import { withCreateTag, withHookForTags } from "src/modules/book/operations";
import { withStateAndActionsForTag } from "src/modules/book/resolvers/tag";
import { Can } from "src/services/casl";
import { Select, Spin, Tag } from "antd";
import { cloneDeep } from "lodash";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import React, { useState } from "react";
import { captureException } from "@sentry/react";

const Option = Select.Option;

const TagsFieldComponent = (props: any) => {
    const {
        loading,
        tags,
        where,
        onNameChange,
        onChange,
        value,
        onStateReset,
        dropdownOpen,
        addTag,
    } = props;
    const [addLoading, setAddLoading] = useState(false);
    const [searchValue, setSearchValue] = useState<any>(null);

    const handleAdd = async () => {
        setAddLoading(true);
        const { formik, name, tagGroup } = props;
        // const val = get(where, ['title', '_ilike'])?.replace(/%/g, '');
        try {
            if (!addLoading /* && val */ && searchValue) {
                const values = searchValue?.split(":");
                if (values.length == 2) {
                    const res = await addTag({
                        group: tagGroup,
                        name: values[0].trim(),
                        value: values[1].trim(),
                    });
                    res.id &&
                        onChange([
                            ...value,
                            {
                                value: JSON.stringify(res),
                                key: res.id,
                                label: `${res.value} (${res.name})` || "",
                            },
                        ]);

                    res.id && setSearchValue("");
                    res.id && onNameChange("");
                } else alert("Format should be -> name:value");
                setAddLoading(false);
            }
        } catch (e) {
            captureException(e)
            setAddLoading(false);
        }
    };

    resetFilterHook(onStateReset);

    return (
        <Field
            labelInValue
            showSearch={true}
            filterOption={false}
            onSearch={(e) => {
                onNameChange(e);
                setSearchValue(e);
            }}
            tagRender={tagRender}
            defaultValue={value}
            notFoundContent={
                <div align="center">
                    {addLoading || loading ? (
                        <Spin size="small" />
                    ) : (
                        <Can I="create" a="tag" passThrough>
                            {(allowed) => {
                                return (
                                    <Button
                                        type="primary"
                                        onClick={handleAdd}
                                        disabled={addLoading || !allowed}
                                    >
                                        {"Create New"}
                                    </Button>
                                );
                            }}
                        </Can>
                    )}
                </div>
            }
            name={"tags"}
            label={"Tags"}
            component={RenderSelect}
            searchValue={searchValue}
            placeholder={"Tags"}
            value={value || "None"}
            loading={loading}
            onChange={(data: any) => {
                onChange(data);
                setSearchValue("");
                onNameChange("");
            }}
            // inFilter={true}
            noBotMarging={true}
            mode={"multiple"}
            open={dropdownOpen}
        >
            {[
                // config.EMPTY_OPTION,
                ...(tags?.map((tagValue) => {
                    let tag = cloneDeep({
                        __typename: tagValue.__typename,
                        id: tagValue.id,
                        name: tagValue.name,
                        value: tagValue.value,
                    });
                    return {
                        id: tag.id,
                        label: `${tag.value} (${tag.name})` || "",
                        value: JSON.stringify(tag),
                    };
                }) || []),
            ]?.map(
                ({
                    id,
                    value,
                    label = "",
                }: {
                    id: string;
                    value: string;
                    label: string;
                }) => (
                    <Option key={id} value={value}>
                        {label}
                    </Option>
                ),
            )}
        </Field>
    );
};

const TagsField = compose(
    withStateAndActionsForTag,
    withHookForTags,
    withCreateTag,
)(TagsFieldComponent);

const TagsAssignComponent = (props: any) => {
    const {
        isSet = false,
        selected,
        handleChange,
        dropdownOpen,
        tagGroup = "block",
    } = props;
    return (
        // <Card bodyStyle={{ padding: "8px" }}>
        // <Row gutter={[24, 0]} align="middle">
        // {isSet && (
        <TagsField
            {...props}
            value={selected}
            onChange={handleChange}
            dropdownOpen={dropdownOpen}
            tagGroup={tagGroup}
        />
        // )}
        // </Row>
        // </Card>
    );
};

export default TagsAssignComponent;

const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color="#ECECEC"
            style={{ color: "#333333", marginRight: 3, marginTop: 3 }}
            onMouseDown={onPreventMouseDown}
            closable={true}
            onClose={onClose}
            closeIcon={<CloseOutlined style={{ color: "#333333" }} />}
        >
            {label}
        </Tag>
    );
};
