import { gql } from "@apollo/client";export default gql`query book($id: Int!) {
    book: group_by_pk(id: $id) {
        id
        description
        cover
        other
        title
        type
        status
        tags {
            id
            tag {
                id
                name
                value
            }
        }
    }
}
`;
