import { gql } from "@apollo/client";export default gql`mutation updateTab($id: Int!, $object: home_explore_tab_set_input = {}) {
    updateTab: update_home_explore_tab_by_pk(
        pk_columns: { id: $id }
        _set: $object
    ) {
        id
        banners
                build_number

        created_at
        deeplink_template
        description
        group
        header_link
        last_child
        title
        type
        updated_at
        status
        order
    }
}
`;
