import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    Form,
    Row,
    Select,
    Switch,
    Divider,
    Input,
} from "antd";
import { cloneDeep, set, get } from "lodash";
import TagsTable from "../common/TagsTable";
import { useState } from "react";
import TablePreviewComponent from "./preview";
import { TAG_SELECTION_PREVIEW } from "./utils/constants";
import { DEFAULT_TABLE } from "../../../../Figma/renderEditor";

interface TagsCollectionCardProps {
    variables: any;
    blockVarsMap: any;
    KEYMAP: any;
    currentCluster: number;
    disableWrite: boolean;
    loadingUpdateTagData: boolean;
    updateTagClusterDataInTable: (clusterIndex: number) => void;
    renderLinkSelect?: any;
    mentionsList?: any[];
    block: any;
    setBlock: (block: any) => void;
    currentEditor: any;
    setCurrentEditor: any;
    hasMentions?: boolean;
}

// Function to modify table data - moved outside component
const modifyTableData = (tableData: any) => {
    if (!tableData?.cells) return tableData;

    const modified = cloneDeep(tableData);

    return modified;
};

const TagsCollectionCard = ({
    variables,
    blockVarsMap,
    KEYMAP,
    currentCluster,
    disableWrite,
    loadingUpdateTagData,
    updateTagClusterDataInTable,
    renderLinkSelect,
    mentionsList = [],
    block,
    setBlock,
    currentEditor,
    setCurrentEditor,
    hasMentions,
}: TagsCollectionCardProps) => {
    const [isPreviewPuzzle, setIsPreviewPuzzle] = useState(false);
    const [modifiedTableData, setModifiedTableData] = useState<any>(null);

    // Get first cluster's table data
    const firstTableData = variables?.[
        blockVarsMap.current?.[KEYMAP.generic_cluster]
    ]?.value?.[0]?.props?.table?.value?.variables?.value?.variables?.find(
        (v: { name: string }) => v.name === "tableData",
    )?.value;

    // Get puzzle enabled state
    const isPuzzleEnabled = ["true", "1", true, 1].includes(
        variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value[
            currentCluster
        ]?.props?.tags_list?.value?.variables?.value?.variables?.find(
            (v: { name: string }) => v.name === "is_puzzle_enabled",
        )?.value ??
            variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value[
                currentCluster
            ]?.props?.tags_list?.value?.variables?.value?.variables?.find(
                (v: { name: string }) => v.name === "is_puzzle_enabled",
            )?.default,
    );

    // Function to update puzzle enabled state
    const updatePuzzleEnabled = (val: boolean) => {
        let tmpBlock = cloneDeep(block);

        // Reset modifiedTableData when disabling puzzle
        if (!val) {
            setModifiedTableData(null);
        } else {
            // Modify table data when enabling puzzle
            const tableData = variables?.[
                blockVarsMap.current?.[KEYMAP.generic_cluster]
            ]?.value?.[0]?.props?.table?.value?.variables?.value?.variables?.find(
                (v: { name: string }) => v.name === "tableData",
            )?.value;
            setModifiedTableData(modifyTableData(tableData));
        }

        // Update isPuzzleEnabled variable
        const puzzleEnabledIdx = variables[
            blockVarsMap.current[KEYMAP.generic_cluster]
        ]?.value[
            currentCluster
        ]?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
            (v: { name: string }) => v.name === "is_puzzle_enabled",
        );

        // Update options to include isPuzzlePiece and isDrawing
        const optionsIdx = variables[
            blockVarsMap.current[KEYMAP.generic_cluster]
        ]?.value[
            currentCluster
        ]?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
            (v: { name: string }) => v.name === "options",
        );

        if (optionsIdx >= 0) {
            const currentOptions = get(
                tmpBlock,
                [
                    "figma",
                    "variables",
                    blockVarsMap.current[KEYMAP.generic_cluster],
                    "value",
                    currentCluster,
                    "props",
                    "tags_list",
                    "value",
                    "variables",
                    "value",
                    "variables",
                    optionsIdx,
                    "value",
                ],
                [],
            );

            // Update isPuzzlePiece and isDrawing for all options
            const updatedOptions = currentOptions.map((option: any) => ({
                ...option,
                isPuzzlePiece: val,
                isDrawing: val && modifiedTableData?.drawing?.enabled,
            }));

            tmpBlock = set(
                tmpBlock,
                [
                    "figma",
                    "variables",
                    blockVarsMap.current[KEYMAP.generic_cluster],
                    "value",
                    currentCluster,
                    "props",
                    "tags_list",
                    "value",
                    "variables",
                    "value",
                    "variables",
                    optionsIdx,
                    "value",
                ],
                updatedOptions,
            );
        }

        // Original puzzle enabled logic
        if (puzzleEnabledIdx >= 0) {
            // Update existing variable
            tmpBlock = set(
                tmpBlock,
                [
                    "figma",
                    "variables",
                    blockVarsMap.current[KEYMAP.generic_cluster],
                    "value",
                    currentCluster,
                    "props",
                    "tags_list",
                    "value",
                    "variables",
                    "value",
                    "variables",
                    puzzleEnabledIdx,
                    "value",
                ],
                val,
            );
        } else {
            // Create new variable
            const variablesPath = [
                "figma",
                "variables",
                blockVarsMap.current[KEYMAP.generic_cluster],
                "value",
                currentCluster,
                "props",
                "tags_list",
                "value",
                "variables",
                "value",
                "variables",
            ];

            const currentVariables = get(tmpBlock, variablesPath, []);
            const newVariable = {
                name: "is_puzzle_enabled",
                type: "boolean",
                value: val,
                default: false,
            };

            tmpBlock = set(tmpBlock, variablesPath, [
                ...currentVariables,
                newVariable,
            ]);
        }

        setBlock(tmpBlock);
    };

    return (
        <Card>
            <Row justify="space-between">
                <Col span={12}>
                    <Form.Item label="Is Visible?">
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={["true", "1", true, 1].includes(
                                variables[
                                    blockVarsMap.current[KEYMAP.generic_cluster]
                                ]?.value[
                                    currentCluster
                                ]?.props?.tags_list?.value?.variables?.value?.variables?.find(
                                    (v: { name: string }) =>
                                        v.name === "is_cluster_visible",
                                )?.value ??
                                    variables[
                                        blockVarsMap.current[
                                            KEYMAP.generic_cluster
                                        ]
                                    ]?.value[
                                        currentCluster
                                    ]?.props?.tags_list?.value?.variables?.value?.variables?.find(
                                        (v: { name: string }) =>
                                            v.name === "is_cluster_visible",
                                    )?.default,
                            )}
                            onChange={(val) => {
                                let tmpBlock = cloneDeep(block);
                                const idx = variables[
                                    blockVarsMap.current[KEYMAP.generic_cluster]
                                ]?.value[
                                    currentCluster
                                ]?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
                                    (v: { name: string }) =>
                                        v.name === "is_cluster_visible",
                                );
                                if (idx >= 0) {
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ],
                                            "value",
                                            currentCluster,
                                            "props",
                                            "tags_list",
                                            "value",
                                            "variables",
                                            "value",
                                            "variables",
                                            idx,
                                            "value",
                                        ],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }
                            }}
                            disabled={disableWrite}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Button
                        type="primary"
                        loading={loadingUpdateTagData}
                        onClick={() =>
                            updateTagClusterDataInTable(currentCluster)
                        }
                        disabled={disableWrite}
                    >
                        Update Tags Data in Table
                    </Button>
                </Col>
            </Row>

            <Row justify="space-between" style={{ marginBottom: 16 }}>
                <Col span={12}>
                    <Form.Item label="Enable Puzzle">
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={isPuzzleEnabled}
                            onChange={updatePuzzleEnabled}
                            disabled={disableWrite}
                        />
                    </Form.Item>
                </Col>
            </Row>

            {isPuzzleEnabled && (
                <>
                    <Divider />
                    <div style={{ marginBottom: 16 }}>
                        <Button
                            type="primary"
                            onClick={() => setIsPreviewPuzzle(!isPreviewPuzzle)}
                        >
                            {isPreviewPuzzle ? "Hide" : "Show"} Preview
                        </Button>
                    </div>

                    {isPreviewPuzzle && (
                        <div
                            style={{
                                position: "fixed",
                                left: 0,
                                background: "white",
                                border: "3px solid black",
                                top: 0,
                                padding: "20px",
                                paddingTop: "10px",
                                minWidth: "400px",
                                zIndex: 5,
                                maxWidth: "100%",
                                maxHeight: "100vh",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h2>Table Preview</h2>
                                <Button.Group>
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            setIsPreviewPuzzle(!isPreviewPuzzle)
                                        }
                                    >
                                        Hide Preview
                                    </Button>
                                </Button.Group>
                            </div>
                            <div
                                style={{
                                    padding: "0 30px",
                                    paddingTop: "10px",
                                }}
                            >
                                <TablePreviewComponent
                                    tableData={
                                        modifiedTableData ||
                                        firstTableData ||
                                        DEFAULT_TABLE
                                    }
                                    showMeasures={false}
                                    type={TAG_SELECTION_PREVIEW}
                                />
                            </div>
                        </div>
                    )}

                    <Form.Item label="First Cluster Table Data">
                        <Input.TextArea
                            value={JSON.stringify(
                                modifiedTableData || firstTableData,
                                null,
                                2,
                            )}
                            rows={8}
                            readOnly
                        />
                    </Form.Item>
                </>
            )}

            {renderLinkSelect &&
                renderLinkSelect({
                    value: variables[
                        blockVarsMap.current[KEYMAP.generic_cluster]
                    ]?.value[
                        currentCluster
                    ]?.props?.tags_list?.value?.variables?.value?.variables?.find(
                        (v: { name: string }) => v.name === "options",
                    )?.linked_global_context_variable?.name,
                    onChange: (value: any) => {
                        let tmpBlock = cloneDeep(block);
                        const idx = variables[
                            blockVarsMap.current[KEYMAP.generic_cluster]
                        ]?.value[
                            currentCluster
                        ]?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
                            (v: { name: string }) => v.name === "options",
                        );
                        tmpBlock = set(
                            tmpBlock,
                            [
                                "figma",
                                "variables",
                                blockVarsMap.current[KEYMAP.generic_cluster],
                                "value",
                                currentCluster,
                                "props",
                                "tags_list",
                                "value",
                                "variables",
                                "value",
                                "variables",
                                idx,
                                "linked_global_context_variable",
                                "name",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    },
                })}

            <Form.Item label="Value from variable">
                <Select
                    value={
                        typeof variables[
                            blockVarsMap.current[KEYMAP.generic_cluster]
                        ]?.value[
                            currentCluster
                        ]?.props?.tags_list?.value?.variables?.value?.variables?.find(
                            (v: { name: string }) => v.name === "options",
                        )?.value === "string"
                            ? variables[
                                  blockVarsMap.current[KEYMAP.generic_cluster]
                              ]?.value[
                                  currentCluster
                              ]?.props?.tags_list?.value?.variables?.value?.variables?.find(
                                  (v: { name: string }) => v.name === "options",
                              )?.value
                            : null
                    }
                    placeholder="Select type"
                    options={[
                        {
                            label: "none",
                            value: null,
                        },
                        ...mentionsList?.map((v) => ({
                            label: v.text,
                            value: `@@${v.text}@@`,
                        })),
                    ]}
                    style={{
                        width: "150px",
                        flexGrow: 0,
                    }}
                    onChange={(val) => {
                        let tmpBlock = cloneDeep(block);
                        const idx = variables[
                            blockVarsMap.current[KEYMAP.generic_cluster]
                        ]?.value[
                            currentCluster
                        ]?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
                            (v: { name: string }) => v.name === "options",
                        );

                        if (idx >= 0) {
                            tmpBlock = set(
                                tmpBlock,
                                [
                                    "figma",
                                    "variables",
                                    blockVarsMap.current[
                                        KEYMAP.generic_cluster
                                    ],
                                    "value",
                                    currentCluster,
                                    "props",
                                    "tags_list",
                                    "value",
                                    "variables",
                                    "value",
                                    "variables",
                                    idx,
                                    "value",
                                ],
                                val !== null ? val : [],
                            );
                            setBlock(tmpBlock);
                        }
                    }}
                    disabled={disableWrite}
                />
            </Form.Item>

            {typeof variables[
                blockVarsMap.current[KEYMAP.generic_cluster]
            ]?.value[
                currentCluster
            ]?.props?.tags_list?.value?.variables?.value?.variables?.find(
                (v: { name: string }) => v.name === "options",
            )?.value !== "string" && (
                <TagsTable
                    {...{
                        disableWrite,
                        currentEditor,
                        setCurrentEditor,
                        hasMentions,
                        mentionsList,
                        setBlock: (v) => {
                            let tmpBlock = cloneDeep(block);
                            const idx = variables[
                                blockVarsMap.current[KEYMAP.generic_cluster]
                            ]?.value[
                                currentCluster
                            ]?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
                                (v: { name: string }) => v.name === "options",
                            );
                            if (idx >= 0) {
                                tmpBlock = set(
                                    tmpBlock,
                                    [
                                        "figma",
                                        "variables",
                                        blockVarsMap.current[
                                            KEYMAP.generic_cluster
                                        ],
                                        "value",
                                        currentCluster,
                                        "props",
                                        "tags_list",
                                        "value",
                                        "variables",
                                        "value",
                                        "variables",
                                        idx,
                                        "value",
                                    ],
                                    v,
                                );
                                setBlock(tmpBlock);
                            }
                        },
                        ...variables[
                            blockVarsMap.current[KEYMAP.generic_cluster]
                        ]?.value[
                            currentCluster
                        ]?.props?.tags_list?.value?.variables?.value?.variables?.find(
                            (v: { name: string }) => v.name === "options",
                        ),
                    }}
                    puzzleEnabled={isPuzzleEnabled}
                    tableData={
                        modifiedTableData || firstTableData || DEFAULT_TABLE
                    }
                />
            )}
        </Card>
    );
};

export default TagsCollectionCard;
