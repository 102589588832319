import React from "react";
import { Card, Col, Input, Row } from "antd";
import get from "lodash/get";

import { resetFilterHook } from "src/helpers";

// import { UsersField } from "..";
import { RenderField, Field } from "src/components";

const FilterComponent = (props) => {
    const { where = {}, onStateReset, onNameChange } = props;

    resetFilterHook(onStateReset);

    return (
        <Card bodyStyle={{ padding: "4px" }}>
            <Row gutter={[24, 0]} align="middle">
                <Col lg={12} md={24}>
                    <Field
                        label="Book title search"
                        placeholder="Book title"
                        icon="FontSizeOutlined"
                        component={RenderField}
                        onChange={(e) => onNameChange(e)}
                        value={get(where, "title._ilike")?.replace(/%/g, "")}
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default FilterComponent;
