export const getSortedWorksheetsData = (worksheets: any) => {
    return worksheets
        .map((t: any, index: number) => {
            return {
                ...t,
                index,
                order: t.groups[0]?.order || -1,
                group_worksheet_map_id: t.groups[0]?.id,
            };
        })
        .sort((a, b) => {
            if (a.status === b.status) return a.order - b.order;
            else return a.status > b.status ? 1 : -1;
        });
};
