import React from "react";
import { compose } from "src/helpers";
import {
    withHookForAggregateChapter,
    withHookForChapters,
} from "src/modules/book/operations";
import { withStateAndActionsForBook as withStateAndActionsForChapter } from "src/modules/book/resolvers";
import ListView from "./component";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    // withStateAndActionsForChapter,
    withHookForChapters,
    withHookForAggregateChapter,
)(List);
