import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Select } from "antd";
import { cloneDeep, set } from "lodash";
import {
    renderBlockAsOptions,
    miniRenderer,
} from "src/modules/worksheet/components/blocks/helpers";
import { v4 as uuid } from "uuid";

const CondiionsFormCard = (props: any) => {
    const {
        condition,
        conditionIndex,
        blockId,
        blocks,
        setBlock,
        block,
        conditions,
    } = props;

    return (
        <Card
            title={`Rule ${conditionIndex + 1}:`}
            extra={
                <Button
                    type="text"
                    danger
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        const tempConditions = [...conditions];

                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["data", "jumpTo", "conditions"],
                            tempConditions.filter(
                                (v, i) => i !== conditionIndex,
                            ),
                        );

                        setBlock(tmpBlock, blockId);
                    }}
                >
                    Delete this rule
                </Button>
            }
            bordered={false}
            bodyStyle={{
                padding: 0,
            }}
            headStyle={{
                padding: 0,
            }}
        >
            {condition?.rules.map(
                (
                    item: {
                        conjunction: any;
                        block: string | number;
                        isEqual: any;
                        option: any;
                    },
                    ci: number,
                ) => (
                    <Card
                        bodyStyle={{
                            padding: 0,
                        }}
                        headStyle={{
                            padding: 0,
                        }}
                        bordered={false}
                        key={uuid()}
                    >
                        {ci > 0 && (
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue={item?.conjunction}
                                    onChange={(value) => {
                                        const tempItem = cloneDeep(item);

                                        tempItem.conjunction = value;

                                        const tempRules = [...condition?.rules];

                                        tempRules[ci] = tempItem;

                                        const tempConditions =
                                            cloneDeep(conditions);
                                        tempConditions[conditionIndex].rules =
                                            tempRules;

                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["data", "jumpTo", "conditions"],
                                            tempConditions,
                                        );
                                        setBlock(tmpBlock, blockId);
                                    }}
                                    options={[
                                        {
                                            value: "and",
                                            label: "and",
                                        },
                                        {
                                            value: "or",
                                            label: "or",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            label={"If:"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                defaultValue={item.block}
                                onChange={(value) => {
                                    const tempItem = cloneDeep(item);

                                    tempItem.block = value;
                                    tempItem.option = 0;

                                    const tempRules = [...condition?.rules];

                                    tempRules[ci] = tempItem;

                                    const tempConditions =
                                        cloneDeep(conditions);
                                    tempConditions[conditionIndex].rules =
                                        tempRules;

                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["data", "jumpTo", "conditions"],
                                        tempConditions,
                                    );
                                    setBlock(tmpBlock, blockId);
                                }}
                                options={[
                                    ...renderBlockAsOptions(
                                        blocks.filter(
                                            (_: any, i: number) => i <= blockId,
                                        ),
                                    ).filter(
                                        (item: { type: string }) =>
                                            item.type === "mcq",
                                    ),
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Value is:"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                defaultValue={item?.isEqual}
                                onChange={(value) => {
                                    const tempItem = cloneDeep(item);

                                    tempItem.isEqual = value;

                                    const tempRules = [...condition?.rules];

                                    tempRules[ci] = tempItem;

                                    const tempConditions =
                                        cloneDeep(conditions);
                                    tempConditions[conditionIndex].rules =
                                        tempRules;

                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["data", "jumpTo", "conditions"],
                                        tempConditions,
                                    );
                                    setBlock(tmpBlock, blockId);
                                }}
                                options={[
                                    {
                                        value: true,
                                        label: "Equal to",
                                    },
                                    {
                                        value: false,
                                        label: "Not equal to",
                                    },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Option:"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                defaultValue={item?.option}
                                onChange={(value) => {
                                    const tempItem = cloneDeep(item);

                                    tempItem.option = value;

                                    const tempRules = [...condition?.rules];

                                    tempRules[ci] = tempItem;

                                    const tempConditions =
                                        cloneDeep(conditions);
                                    tempConditions[conditionIndex].rules =
                                        tempRules;

                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["data", "jumpTo", "conditions"],
                                        tempConditions,
                                    );
                                    setBlock(tmpBlock, blockId);
                                }}
                                options={blocks[
                                    item.block
                                ]?.data?.mcq?.options.map(
                                    (v: any, i: number) => ({
                                        value: i,
                                        label: (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "20px",
                                                }}
                                            >
                                                <strong>{`Option ${
                                                    i + 1
                                                }`}</strong>
                                                <div>{miniRenderer(v)}</div>
                                            </div>
                                        ),
                                    }),
                                )}
                            />
                        </Form.Item>

                        <Button
                            type="text"
                            danger
                            size="small"
                            style={{
                                marginBottom: "20px",
                                float: "right",
                            }}
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                const tempRules = [...condition?.rules].filter(
                                    (v, i) => i !== ci,
                                );

                                let tempConditions = cloneDeep(conditions);

                                if (tempRules.length > 0) {
                                    tempConditions[conditionIndex].rules =
                                        tempRules;
                                } else {
                                    tempConditions = tempConditions.filter(
                                        (_v: any, i: any) =>
                                            i !== conditionIndex,
                                    );
                                }

                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", "jumpTo", "conditions"],
                                    tempConditions,
                                );

                                setBlock(tmpBlock, blockId);
                            }}
                        >
                            Delete this condition
                        </Button>
                    </Card>
                ),
            )}

            <Form.Item>
                <Button
                    type="link"
                    onClick={() => {
                        const tempIndex = blocks.findIndex(
                            (v: { type: string }) => v.type === "mcq",
                        );

                        const newRule = {
                            block: tempIndex,
                            option: 0,
                            isEqual: true,
                            conjunction: "and",
                        };

                        const tempRules = [...condition?.rules];

                        tempRules.push(newRule);

                        const tempConditions = cloneDeep(conditions);
                        tempConditions[conditionIndex].rules = tempRules;

                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["data", "jumpTo", "conditions"],
                            tempConditions,
                        );

                        setBlock(tmpBlock, blockId);
                    }}
                >
                    <PlusCircleOutlined /> Add condition
                </Button>
            </Form.Item>

            <Divider />

            <Form.Item
                label={"Go To:"}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select
                    defaultValue={
                        condition?.jumpTo >= 0 ? condition?.jumpTo : null
                    }
                    onChange={(value) => {
                        const tempConditions = cloneDeep(conditions);
                        tempConditions[conditionIndex].jumpTo = value;

                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["data", "jumpTo", "conditions"],
                            tempConditions,
                        );
                        setBlock(tmpBlock, blockId);
                    }}
                    options={[
                        {
                            value: null,
                            label: `Next Block`,
                        },
                        ...renderBlockAsOptions(blocks).filter(
                            (item: any) => item.value !== blockId,
                        ),
                    ]}
                />
            </Form.Item>
        </Card>
    );
};

export default CondiionsFormCard;
