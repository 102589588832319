import { renderer } from "../../helpers";

const renderBlockText = (block: any) => {
    const {
        data: { v2_rich_text },
    } = block;
    return <div>{renderer(v2_rich_text)}</div>;
};

export default renderBlockText;
