import React, { useState } from "react";
import { Tag } from "antd";
import _ from "lodash";
import { DeleteOutlined } from "@ant-design/icons";
import emitter from "src/helpers/emitter";
import { TOGGLE_HIGHLIGHT } from "src/helpers/events/table/constants";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const TagForm = ({
    cell,
    isActive,
    tagIndex,
    removeTag,
    activeTags,
    setActiveTags,
}: any) => {
    const isActiveTag = activeTags?.includes(cell);
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id: cell,
            animateLayoutChanges: () => {
                return false;
            },
        });
    const handleClickActivate = (clickStatus: boolean) => {
        const cellItem = cell.split("-");
        if (isActive && isActiveTag !== clickStatus) {
            emitter.emit(TOGGLE_HIGHLIGHT, {
                coordinates: {
                    rowIndex: Number(cellItem[0]),
                    columnIndex: Number(cellItem[1]),
                },
                isHighlighted: clickStatus,
            });
        }
        if (clickStatus) {
            setActiveTags([...(activeTags || []), cell]);
        } else {
            setActiveTags(activeTags.filter((tag: any) => tag !== cell));
        }
    };
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (
        <Tag
            ref={setNodeRef}
            style={{
                pointerEvents: "auto",
                position: "relative",
                flex: "0 1 auto",
                paddingRight: "20px",
                ...style,
            }}
            color={isActiveTag ? "blue" : "default"}
            key={tagIndex}
            onClick={() => {
                if (!isActive) return;
                handleClickActivate(!isActiveTag);
            }}
            {...attributes}
            {...listeners}
        >
            {cell}
            <DeleteOutlined
                className="path-tag-icon"
                twoToneColor={"red"}
                onClick={(e) => {
                    if (!isActive) return;
                    // prevent click on parent
                    e.stopPropagation();
                    removeTag();
                }}
                style={{
                    position: "absolute",
                    right: "3px",
                    top: "3px",
                }}
            />
        </Tag>
    );
};

export default TagForm;
