import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";

const renderCardVideo = (props: any) => {
    return (
        <BlockCard {...props}>
            <div>Video Block</div>
        </BlockCard>
    );
};

export default renderCardVideo;
