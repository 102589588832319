import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Popover, Row, Select, Switch } from "antd";
import { FC } from "react";

interface DropAreaComponentProps {
    selectedCells: any[];
    areAllElementsSame: (cells: any[], path: string[]) => any;
    setProperty: (property: any) => void;
}

const DropAreaComponent: FC<DropAreaComponentProps> = ({
    selectedCells,
    areAllElementsSame,
    setProperty,
    allCategories,
}) => {
    const content = (
        <Row gutter={[5, 5]} style={{ width: "300px" }}>
            <Col span={10}>
                <Form.Item label="Enabled">
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={areAllElementsSame(selectedCells, [
                            "dragDrop",
                            "enabled",
                        ])}
                        onChange={(value) => {
                            setProperty({
                                type: "DRAG_DROP",
                                is_enabled: value,
                            });
                        }}
                    />
                </Form.Item>
            </Col>
            {areAllElementsSame(selectedCells, ["dragDrop", "enabled"]) && (
                <>
                    <Col span={24}>
                        <Form.Item label="Take Cell Background">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={areAllElementsSame(selectedCells, [
                                    "dragDrop",
                                    "takeCellBackground",
                                ])}
                                onChange={(value) => {
                                    setProperty({
                                        type: "DRAG_DROP",
                                        value,
                                        path: ["takeCellBackground"],
                                    });
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Accepted Categories">
                            <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Select accepted categories"
                                value={areAllElementsSame(selectedCells, [
                                    "dragDrop",
                                    "acceptedCategories",
                                ])}
                                options={allCategories?.map((category) => ({
                                    label: category,
                                    value: category,
                                }))}
                                onChange={(values) => {
                                    setProperty({
                                        type: "DRAG_DROP",
                                        value: values,
                                        path: ["acceptedCategories"],
                                    });
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Dimension Control">
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={areAllElementsSame(selectedCells, [
                                    "dragDrop",
                                    "dimensionControl",
                                    "enabled",
                                ])}
                                onChange={(value) => {
                                    setProperty({
                                        type: "DRAG_DROP",
                                        value,
                                        path: ["dimensionControl", "enabled"],
                                    });
                                }}
                            />
                        </Form.Item>

                        {areAllElementsSame(selectedCells, [
                            "dragDrop",
                            "dimensionControl",
                            "enabled",
                        ]) && (
                            <>
                                <Form.Item label="Min Width">
                                    <Input
                                        value={areAllElementsSame(
                                            selectedCells,
                                            [
                                                "dragDrop",
                                                "dimensionControl",
                                                "minWidth",
                                            ],
                                        )}
                                        type="number"
                                        placeholder="Min Width"
                                        onChange={(e) =>
                                            setProperty({
                                                type: "DRAG_DROP",
                                                value: e.target.value,
                                                path: [
                                                    "dimensionControl",
                                                    "minWidth",
                                                ],
                                            })
                                        }
                                    />
                                </Form.Item>

                                <Form.Item label="Max Width">
                                    <Input
                                        value={areAllElementsSame(
                                            selectedCells,
                                            [
                                                "dragDrop",
                                                "dimensionControl",
                                                "maxWidth",
                                            ],
                                        )}
                                        type="number"
                                        placeholder="Max Width"
                                        onChange={(e) =>
                                            setProperty({
                                                type: "DRAG_DROP",
                                                value: e.target.value,
                                                path: [
                                                    "dimensionControl",
                                                    "maxWidth",
                                                ],
                                            })
                                        }
                                    />
                                </Form.Item>

                                <Form.Item label="Min Height">
                                    <Input
                                        value={areAllElementsSame(
                                            selectedCells,
                                            [
                                                "dragDrop",
                                                "dimensionControl",
                                                "minHeight",
                                            ],
                                        )}
                                        type="number"
                                        placeholder="Min Height"
                                        onChange={(e) =>
                                            setProperty({
                                                type: "DRAG_DROP",
                                                value: e.target.value,
                                                path: [
                                                    "dimensionControl",
                                                    "minHeight",
                                                ],
                                            })
                                        }
                                    />
                                </Form.Item>

                                <Form.Item label="Max Height">
                                    <Input
                                        value={areAllElementsSame(
                                            selectedCells,
                                            [
                                                "dragDrop",
                                                "dimensionControl",
                                                "maxHeight",
                                            ],
                                        )}
                                        type="number"
                                        placeholder="Max Height"
                                        onChange={(e) =>
                                            setProperty({
                                                type: "DRAG_DROP",
                                                value: e.target.value,
                                                path: [
                                                    "dimensionControl",
                                                    "maxHeight",
                                                ],
                                            })
                                        }
                                    />
                                </Form.Item>
                            </>
                        )}
                    </Col>
                </>
            )}
        </Row>
    );

    return (
        <Popover
            destroyTooltipOnHide
            content={content}
            title="Drop Area"
            trigger="click"
        >
            <Button
                type={"primary"}
                ghost={
                    !areAllElementsSame(selectedCells, ["dragDrop", "enabled"])
                }
            >
                Drop Area
            </Button>
        </Popover>
    );
};

export default DropAreaComponent;
