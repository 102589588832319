import { DeleteOutlined } from "@ant-design/icons";
import {
    Badge,
    Divider,
    Input,
    Modal,
    Typography,
    Form,
    Space,
    Row,
    Col,
    Button,
    message,
    Card,
} from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { DragHandle } from "../../../V2MCQ/renderEditor";
import { isEditorEmpty } from "../../../../common";
import ComponentPropForm from "./ComponentPropForm";
import CorrectValuesComponent from "./CorrectValuesComponent";
import { captureException } from "@sentry/react";

const splitAndUpperCase = (str: string) =>
    str
        ?.split("_")
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(" ");

const ComponentsListEditor = (props: any) => {
    const {
        disableWrite,
        componentsList = [],
        componentsSchema = [],
        isModalOpen,
        onSave,
        closeForm,
        name,

        hasMentions,
        mentionsList = [],
        isEditor,
        setIsEditor,
        setIsNewBlock,
        isNewBlock,
    } = props;

    const tmpId = ``;

    const [isSubmitting, setSubmitting] = useState(false);
    const [curConfig, setCurConfig] = useState(componentsList);
    const [loading, setLoading] = useState(false);
    const [tempConfig, setTempConfig] = useState("");

    const [currentEditor, setCurrentEditor] = useState(tmpId);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [tmpId, currentEditor]);

    const handleSubmit = async () => {
        setSubmitting(true);

        let tempData = [];

        for (let i = 0; i < curConfig.length; i++) {
            let tempComp = curConfig[i];

            let objKeys = Object.keys(tempComp).filter((v) => v !== "type");

            for (let j = 0; j < objKeys.length; j++) {
                const data = tempComp[objKeys[j]];

                switch (data.type) {
                    case "STRING":
                        if (!data.value) {
                            message.error("Some fields are empty!");
                            setSubmitting(false);
                            return;
                        }
                        break;
                    case "JSONSTRING":
                        if (!_.isObject(data.value)) {
                            try {
                                tempComp[objKeys[j]].value = JSON.parse(
                                    data.value,
                                );
                            } catch (e) {
                                // captureException(e)
                                message.error("Some JSON fields are invalid!");
                                setSubmitting(false);
                                return;
                            }
                        } else {
                            if (
                                _.isArray(data?.value) &&
                                !data?.value?.length
                            ) {
                                message.error("Some fields are empty!");
                                setSubmitting(false);
                                return;
                            }
                        }
                        break;
                    case "CUSTOM_KEYBOARD":
                    case "RICH_TEXT_CUSTOM_KEYBOARD":
                    case "FILL":
                        if (!_.isObject(data.value)) {
                            try {
                                tempComp[objKeys[j]].value = JSON.parse(
                                    data.value,
                                );
                            } catch (e) {
                                // captureException(e);
                                message.error("Some JSON fields are invalid!");
                                setSubmitting(false);
                                return;
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
            tempData.push(tempComp);
        }
        try {
            await onSave(tempData);
            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e);
            console.log(e);
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        closeForm();
    };

    useEffect(() => {
        if (loading) {
            setTimeout(() => setLoading(false), 50);
        }
    }, [loading]);

    const getValidateStatus = (data: any, name: any = "") => {
        let error: "" | "error" = "";
        let msg = "";

        if (name !== "default_value") {
            switch (data.type) {
                case "BOOLEAN":
                    break;
                case "JSONSTRING":
                    if (!_.isObject(data.value)) {
                        try {
                            JSON.parse(data.value);
                        } catch (e) {
                            // captureException(e);
                            error = "error";
                            msg = "Invalid JSON";
                        }
                    }
                    break;
                case "CUSTOM_KEYBOARD":
                case "RICH_TEXT_CUSTOM_KEYBOARD":
                    if (!_.isObject(data.value) || !data?.value?.length) {
                        error = "error";
                        msg = "Field cannot be empty";
                    }
                    break;
                case "FILL":
                    if (!_.isObject(data.value)) {
                        error = "error";
                        msg = "Field cannot be empty";
                    }
                    break;
                case "RICH_TEXT":
                    if (isEditorEmpty(data.value)) {
                        error = "error";
                        msg = "";
                    }
                    break;
                default:
                    if (!data.value) {
                        error = "error";
                        msg = "Field cannot be empty";
                    }
                    break;
            }
        }
        return { error, msg };
    };

    const SortableItem = SortableElement(({ value, child }) => {
        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Badge.Ribbon
                    color={"cyan"}
                    text={value + 1}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            minWidth: "90px",
                        }}
                        bodyStyle={{
                            background: "transparent",
                            paddingBottom: 10,
                            paddingTop: 10,
                        }}
                    >
                        {splitAndUpperCase(child.type)}

                        <div
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "2px",
                            }}
                        >
                            <DragHandle />
                        </div>
                    </Card>
                </Badge.Ribbon>
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={index}
                        child={child}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setLoading(true);
        const tmpChildren = arrayMoveImmutable(
            curConfig,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        setCurConfig(tmpChildren);
    };

    return (
        <>
            <Modal
                title={
                    <Typography.Title level={4}>
                        Add Components data for {name}
                    </Typography.Title>
                }
                onCancel={closeForm}
                open={isModalOpen}
                destroyOnClose
                width={720}
                footer={null}
                maskClosable={false}
                style={{ top: 0 }}
            >
                {curConfig?.length > 0 && (
                    <>
                        <Button
                            disabled={disableWrite}
                            type="primary"
                            onClick={() => {
                                message.info(
                                    `Copied components config to clipboard!`,
                                );

                                navigator.clipboard.writeText(
                                    JSON.stringify(curConfig),
                                );
                            }}
                        >
                            Copy this components Config to Clipboard
                        </Button>
                        <Divider />
                    </>
                )}
                <Form.Item label={"Paste components config here"}>
                    <Input.TextArea
                        disabled={disableWrite}
                        value={tempConfig}
                        placeholder="Paste Config here"
                        onChange={(e) => setTempConfig(e.target.value)}
                    />
                    <Button
                        disabled={disableWrite}
                        style={{ marginTop: "10px" }}
                        type="primary"
                        onClick={() => {
                            try {
                                const parsedJson = JSON.parse(tempConfig);
                                if (!Array.isArray(parsedJson)) {
                                    message.error("Error in JSON!");
                                    return;
                                }
                                setLoading(true);
                                setCurConfig(parsedJson);
                                setTempConfig("");
                                message.info("Updated!");
                            } catch (e) {
                                // captureException(e);
                                message.error("Error in JSON!");
                            }
                        }}
                    >
                        Update config
                    </Button>
                </Form.Item>
                <Form>
                    <Row gutter={16}>
                        <Col
                            span={24}
                            style={{
                                display: "flex",
                                gap: "20px",
                                flexWrap: "wrap",
                            }}
                        >
                            {!disableWrite &&
                                componentsSchema.map(
                                    (item: any, key: number) => (
                                        <Button
                                            key={key}
                                            ghost
                                            type="primary"
                                            onClick={() => {
                                                let tmpBlock =
                                                    _.cloneDeep(curConfig);
                                                tmpBlock.push(item);
                                                setCurConfig(tmpBlock);
                                            }}
                                        >
                                            Add {splitAndUpperCase(item.type)}
                                        </Button>
                                    ),
                                )}
                        </Col>
                        <Divider />
                        <SortableList
                            items={curConfig}
                            onSortEnd={onSortEnd}
                            axis="xy"
                            useDragHandle
                        />
                        <Col
                            span={24}
                            style={{
                                marginTop: "20px",
                            }}
                        >
                            {!loading &&
                                Array.isArray(curConfig) &&
                                curConfig?.map((data, idx: number) =>
                                    !data ? (
                                        <></>
                                    ) : (
                                        <div
                                            key={`item-${idx}`}
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <Badge.Ribbon
                                                color={"cyan"}
                                                text={idx + 1}
                                                placement={"start"}
                                            >
                                                <div
                                                    style={{
                                                        border: "1px solid #717171",
                                                        borderRadius: "15px",
                                                        padding: "10px",
                                                        width: "100%",
                                                        display: "flex",
                                                        gap: "20px",
                                                    }}
                                                >
                                                    <div />
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Row
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            gutter={[20, 20]}
                                                        >
                                                            <Col span={21}>
                                                                <h3
                                                                    style={{
                                                                        textTransform:
                                                                            "capitalize",
                                                                    }}
                                                                >
                                                                    {splitAndUpperCase(
                                                                        data.type,
                                                                    )}
                                                                </h3>
                                                            </Col>
                                                            <Col
                                                                span={2}
                                                                style={{
                                                                    paddingTop:
                                                                        "4px",
                                                                }}
                                                            >
                                                                <Button
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    icon={
                                                                        <DeleteOutlined />
                                                                    }
                                                                    type="primary"
                                                                    shape="circle"
                                                                    danger
                                                                    size="small"
                                                                    onClick={() => {
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                curConfig,
                                                                            );
                                                                        _.pullAt(
                                                                            tmpBlock,
                                                                            idx,
                                                                        );
                                                                        setCurConfig(
                                                                            tmpBlock,
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Divider />
                                                        <Row
                                                            key={idx}
                                                            justify="start"
                                                            style={{
                                                                padding: "10px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {Object.keys(data)
                                                                .filter(
                                                                    (v) =>
                                                                        ![
                                                                            "type",
                                                                            "props",
                                                                            "correct_values",
                                                                        ].includes(
                                                                            v,
                                                                        ) &&
                                                                        !data[v]
                                                                            .is_local,
                                                                )
                                                                .map(
                                                                    (
                                                                        v,
                                                                        key,
                                                                    ) => {
                                                                        return (
                                                                            <ComponentPropForm
                                                                                {...{
                                                                                    getValidateStatus,
                                                                                    disableWrite,
                                                                                    data,
                                                                                    setCurConfig:
                                                                                        (
                                                                                            data: any,
                                                                                        ) => {
                                                                                            let tmpBlock =
                                                                                                _.cloneDeep(
                                                                                                    curConfig,
                                                                                                );

                                                                                            tmpBlock =
                                                                                                _.set(
                                                                                                    tmpBlock,
                                                                                                    [
                                                                                                        idx,
                                                                                                        v,
                                                                                                        "value",
                                                                                                    ],
                                                                                                    data,
                                                                                                );
                                                                                            setCurConfig(
                                                                                                tmpBlock,
                                                                                            );
                                                                                        },
                                                                                    idx,
                                                                                    v,
                                                                                    hasMentions,
                                                                                    mentionsList,
                                                                                    currentEditor,
                                                                                    setCurrentEditor,
                                                                                    key,
                                                                                }}
                                                                            />
                                                                        );
                                                                    },
                                                                )}
                                                            {data.props &&
                                                                Object.keys(
                                                                    data.props,
                                                                )
                                                                    .filter(
                                                                        (v) =>
                                                                            !data
                                                                                .props[
                                                                                v
                                                                            ]
                                                                                .is_local,
                                                                    )
                                                                    .map(
                                                                        (
                                                                            v,
                                                                            key,
                                                                        ) => {
                                                                            return (
                                                                                <ComponentPropForm
                                                                                    {...{
                                                                                        getValidateStatus,
                                                                                        disableWrite,
                                                                                        data: data.props,
                                                                                        setCurConfig:
                                                                                            (
                                                                                                data: any,
                                                                                            ) => {
                                                                                                let tmpBlock =
                                                                                                    _.cloneDeep(
                                                                                                        curConfig,
                                                                                                    );

                                                                                                tmpBlock =
                                                                                                    _.set(
                                                                                                        tmpBlock,
                                                                                                        [
                                                                                                            idx,
                                                                                                            "props",
                                                                                                            v,
                                                                                                            "value",
                                                                                                        ],
                                                                                                        data,
                                                                                                    );
                                                                                                setCurConfig(
                                                                                                    tmpBlock,
                                                                                                );
                                                                                            },
                                                                                        idx,
                                                                                        v,
                                                                                        hasMentions,
                                                                                        mentionsList,
                                                                                        currentEditor,
                                                                                        setCurrentEditor,
                                                                                        key,
                                                                                    }}
                                                                                />
                                                                            );
                                                                        },
                                                                    )}
                                                            {data.correct_values && (
                                                                <CorrectValuesComponent
                                                                    {...{
                                                                        correct_values:
                                                                            data.correct_values,
                                                                        getValidateStatus,
                                                                        disableWrite,
                                                                        data: data,
                                                                        setCurConfig:
                                                                            (
                                                                                data: any,
                                                                            ) => {
                                                                                let tmpBlock =
                                                                                    _.cloneDeep(
                                                                                        curConfig,
                                                                                    );

                                                                                tmpBlock =
                                                                                    _.set(
                                                                                        tmpBlock,
                                                                                        [
                                                                                            idx,
                                                                                            "correct_values",
                                                                                            "value",
                                                                                        ],
                                                                                        data,
                                                                                    );
                                                                                setCurConfig(
                                                                                    tmpBlock,
                                                                                );
                                                                            },
                                                                        idx,
                                                                        hasMentions,
                                                                        mentionsList,
                                                                        currentEditor,
                                                                        setCurrentEditor,
                                                                    }}
                                                                />
                                                            )}
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Badge.Ribbon>
                                        </div>
                                    ),
                                )}
                        </Col>
                        <Col style={{ marginTop: "30px" }} span={24}>
                            <Space style={{ float: "right" }}>
                                <Button type="default" onClick={closeForm}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                >
                                    Submit
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ComponentsListEditor;
