import React from "react";
import PropTypes from "prop-types";

import { PlusOutlined } from "@ant-design/icons";

import { Space, Form, Select } from "antd";

const FormItem = Form.Item;

export const RenderTags = (props) => {
    const {
        icon,
        input,
        label,
        type,
        noBotMarging = false,
        meta: { touched, error },
        onChange,
        selectStyle,
        inFilter = false,
        required,
    } = props;
    let validateStatus = "";
    if (touched && error) {
        validateStatus = "error";
    }

    const handleChange = (value) => {
        const { formik, name } = props;
        if (onChange) {
            onChange(value);
        } else {
            formik.handleChange({ target: { value, name } });
        }
    };

    let labels = inFilter
        ? {}
        : {
              labelCol: { span: 24 },
              wrapperCol: { span: 24 },
          };

    const labelObj = label
        ? {
              label: (
                  <Space align="center">
                      {icon && <PlusOutlined />}
                      {label}
                  </Space>
              ),
          }
        : {};
    return (
        <FormItem
            {...labelObj}
            validateStatus={validateStatus}
            help={error}
            style={{ width: "100%", marginBottom: noBotMarging && "0px" }}
            {...labels}
            required={required}
            hasFeedback
        >
            <Select
                type={type}
                style={{ width: "300px" }}
                {...input}
                onChange={handleChange}
                dropdownStyle={{ display: "none" }}
                mode="tags"
                tokenSeparators={[","]}
            ></Select>
        </FormItem>
    );
};

RenderTags.propTypes = {
    formik: PropTypes.object.isRequired,
    input: PropTypes.object,
    label: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.object,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    selectStyle: PropTypes.object,
    inFilter: PropTypes.bool,
    noBotMarging: PropTypes.bool,
    icon: PropTypes.string,
};
