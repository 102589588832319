import { DEFAULT_CUSTOM_KEYBOARD } from "src/components/CustomKeyboardEditor";
import { BLOCK_TYPES } from "../../common/index";
import { GRID_INPUT_OPTIONS, PUZZLE_OTHER } from "../Crossword/defaultValue";
const { V2_INPUT_AND_FORM_BLOCK } = BLOCK_TYPES;

const V2_INPUT_AND_FORM_DEFAULT_VALUE = {
    type: V2_INPUT_AND_FORM_BLOCK,
    data: {
        [V2_INPUT_AND_FORM_BLOCK]: {
            question_audio: "",
            options: GRID_INPUT_OPTIONS({
                type: "input",
                otherFields: {
                    correct_answer: [],
                    no_border: false,
                    width: 1,
                },
            }),
            custom_keyboard: DEFAULT_CUSTOM_KEYBOARD,
            result_type: "question", // field, question
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
        other: {
            ...PUZZLE_OTHER,
            feedbacks: [], // DEFAULT_FEEDBACKS,
        },
    },
};

export default V2_INPUT_AND_FORM_DEFAULT_VALUE;
