import renderBlockText from "./renderBlock";
import renderEditorText from "./renderEditor";
import isValidCallbackText from "./isValid";
import TEXT_DEFAULT_VALUE from "./defaultValue";
import renderSettingsText from "./renderSettings";
import renderCardText from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { TEXT_BLOCK } = BLOCK_TYPES;

const TEXT_BLOCK_CONFIG = {
    [TEXT_BLOCK]: {
        label: "Text",
        defaultValue: TEXT_DEFAULT_VALUE,
        renderBlock: renderBlockText,
        renderEditor: renderEditorText,
        renderCard: renderCardText,
        renderSettings: renderSettingsText,
        addCallback,
        isValidCallback: isValidCallbackText,
    },
};

export {
    renderBlockText,
    renderCardText,
    renderEditorText,
    renderSettingsText,
    isValidCallbackText,
    TEXT_DEFAULT_VALUE,
    TEXT_BLOCK_CONFIG,
};
