import { gql } from "@apollo/client";

export default gql`
    query other_job($type: String!, $status: [String!]!, $id: [Int!]!) {
        other_job(
            where: {
                type: { _eq: $type }
                status: { _in: $status }
                id: { _in: $id }
            }
        ) {
            id
            updated_at
            status
            data
        }
    }
`;
