import { FunctionComponent } from "react";

import {
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import { ORDER_QUERY, ORDERS_QUERY, ORDER_AGGREGATE_QUERY } from "../graphql";

export const withHookForOrders = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { pagination, where } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            ORDERS_QUERY,
            {
                variables: {
                    where,
                    ...pagination,
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "orders", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    orders: items,
                    subscribeToMore,
                    updateQuery,
                    loadOrdersData: loadItemsData,
                    refetchOrders: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateOrder = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { where } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            ORDER_AGGREGATE_QUERY,
            {
                variables: { where },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "orderAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    orderAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForOrder = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            ORDER_QUERY,
            {
                variables: {
                    id: getLastStringParamFromUrl(),
                },
            },
        );

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingOrder: loading,
                    orderData: data?.order,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
