import { Form, Switch, Select } from "antd";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";

interface DragAndDropSettingsProps {
    block: any;
    setBlock: (block: any) => void;
    disableWrite: boolean;
}

const DragAndDropSettings = ({
    block,
    setBlock,
    disableWrite,
}: DragAndDropSettingsProps) => {
    return (
        <Form layout="vertical">
            <Form.Item label="Enable Drag and Drop Mode">
                <Switch
                    disabled={disableWrite}
                    checked={!!block?.dragAndDropSettings?.enabled}
                    onChange={(checked) => {
                        setBlock({
                            ...block,
                            dragAndDropSettings: {
                                ...(block?.dragAndDropSettings || {}),
                                enabled: checked,
                            },
                        });
                    }}
                />
            </Form.Item>

            {block?.dragAndDropSettings?.enabled && (
                <Form.Item label="Categories">
                    <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Type and press enter to add categories"
                        disabled={disableWrite}
                        value={block?.dragAndDropSettings?.allCategories || []}
                        onChange={(categories) => {
                            setBlock({
                                ...block,
                                dragAndDropSettings: {
                                    ...block.dragAndDropSettings,
                                    allCategories: categories,
                                },
                            });
                        }}
                    />
                </Form.Item>
            )}
        </Form>
    );
};

export default DragAndDropSettings;
