import DRAG_AND_INPUT_DEFAULT_VALUE from "./defaultValue";
import renderCardDragAndInput from "./renderCard";
import renderBlockDragAndInput from "./renderBlock";
import renderSettingsDragAndInput from "./renderSettings";
import isValidCallbackDragAndInput from "./isValid";
import renderEditorDragAndInput from "./renderEditor";
import { addCallback, BLOCK_TYPES } from "../../common";

const { DRAG_AND_INPUT_BLOCK } = BLOCK_TYPES;

const DRAG_AND_INPUT_BLOCK_CONFIG = {
    [DRAG_AND_INPUT_BLOCK]: {
        label: "Drag and Input",
        defaultValue: DRAG_AND_INPUT_DEFAULT_VALUE,
        renderBlock: renderBlockDragAndInput,
        renderEditor: renderEditorDragAndInput,
        renderCard: renderCardDragAndInput,
        renderSettings: renderSettingsDragAndInput,
        addCallback,
        isValidCallback: isValidCallbackDragAndInput,
    },
};

export {
    DRAG_AND_INPUT_DEFAULT_VALUE,
    DRAG_AND_INPUT_BLOCK_CONFIG,
    renderBlockDragAndInput,
    renderCardDragAndInput,
    renderSettingsDragAndInput,
    renderEditorDragAndInput,
    isValidCallbackDragAndInput,
};
