import { message } from "antd";
import _ from "lodash";
import { DEFAULT_TABLE, getFigmaJson } from "../../../Figma/renderEditor";
import { captureException } from "@sentry/react";

export const getFigmaData = async (
    data: string,
    { variables = [], compute_functions = [] },
) => {
    if (!data) return;
    message.loading("Fetching figma data");
    let tempData: {
        url?: string;
        file_key?: string;
        node_id?: string;
        env?: string;
    } = {};

    try {
        const urlObject = new URL(data);
        let figmaContextId = urlObject.pathname.split("/")[1];
        // URL decode the figma context id
        figmaContextId = decodeURIComponent(figmaContextId);
        const fileKey = figmaContextId.split("|")[1];
        const nodeId = figmaContextId.split("|")[2];
        const env = figmaContextId.split("|")[3];

        if (!fileKey || !nodeId) throw new Error();
        tempData = {
            url: data,
            file_key: fileKey,
            node_id: nodeId,
            env,
        };
    } catch (e) {
        // captureException(e)
        message.destroy();
        message.error("Invalid URL");
        return;
    }

    let tempVariables = [];
    let tempCompFuncs = [];
    let figmaName = "";

    try {
        const data = await getFigmaJson(
            `figma_screen|${tempData.file_key}|${tempData.node_id}${
                tempData.env ? "|" + tempData.env : ""
            }`,
        );

        const prevVariables: any = {};
        const prevCompFuncs: any = {};

        variables.forEach(
            (v: { name: string | number; value: any; objectType: any }) => {
                prevVariables[v.name] = {
                    value: v.value,
                    type: v.objectType,
                };
            },
        );

        compute_functions.forEach((v: { name: string | number }) => {
            prevCompFuncs[v.name] = v;
        });

        tempVariables = (data.variables || [])
            .filter((v: { type: string }) => v.type === "GLOBAL")
            .map(
                (v: {
                    name: any;
                    label: any;
                    default: any;
                    defaultValue: any;
                    objectType: any;
                    type: any;
                    value: any;
                    schema: any;
                    options: any;
                }) => {
                    let newValue;
                    switch (v.objectType) {
                        case "DROPDOWN":
                        case "DROPDOWN_RICH_TEXT_OPTIONS":
                            newValue =
                                prevVariables[v.name]?.type === v.objectType &&
                                prevVariables[v.name]?.value;

                            if (!newValue || !newValue?.length)
                                newValue = v.default;

                            try {
                                if (_.isString(newValue))
                                    newValue = JSON.parse(newValue);
                                if (_.isArray(newValue)) {
                                    newValue = newValue.map((v) => ({
                                        value: v?.value,
                                        label: v?.label || v?.value,
                                    }));
                                } else {
                                    newValue = [];
                                }
                            } catch (e) {
                                // captureException(e);
                                console.log(v, e);
                                newValue = [];
                            }
                            break;

                        case "TABLE_DATA":
                            newValue =
                                prevVariables[v.name]?.type === v.objectType &&
                                prevVariables[v.name]?.value;

                            if (!newValue?.cells?.length)
                                newValue = v.default || v.value;

                            try {
                                if (_.isString(newValue))
                                    newValue = JSON.parse(newValue);
                            } catch (e) {
                                // captureException(e);
                                console.log(v, e);
                                newValue = _.cloneDeep(DEFAULT_TABLE);
                            }
                            break;
                        case "AUDIO_POOL":
                        case "DYNAMIC_COMPONENTS_DATA":
                            newValue =
                                prevVariables[v.name]?.type === v.objectType &&
                                prevVariables[v.name]?.value;

                            if (!newValue || !newValue?.length)
                                newValue = v.default;

                            try {
                                if (_.isString(newValue))
                                    newValue = JSON.parse(newValue);
                            } catch (e) {
                                // captureException(e);
                                console.log(v, e);
                                newValue = [];
                            }
                            break;
                        case "MATHQUILL_RICH_TEXT":
                            newValue =
                                prevVariables[v.name]?.type === v.objectType &&
                                prevVariables[v.name]?.value;

                            if (!newValue || !newValue?.length)
                                newValue = v.default;
                            try {
                                if (_.isString(newValue))
                                    newValue = JSON.parse(newValue);
                            } catch (e) {
                                // captureException(e);
                                console.log(v, e);
                                newValue = {
                                    default_value: "",
                                    correct_values: [],
                                };
                            }
                            break;
                        default:
                            newValue =
                                ((prevVariables[v.name]?.type ===
                                    v.objectType ||
                                    !prevVariables[v.name]?.type) &&
                                    prevVariables[v.name]?.value) ||
                                v.default ||
                                v.defaultValue;
                            break;
                    }

                    let retVal: any = {
                        name: v.name,
                        label: v.label,
                        value: newValue,
                        type: v.type,
                        objectType: v.objectType,
                    };

                    if (v.schema)
                        retVal.schema =
                            typeof v.schema === "string"
                                ? JSON.parse(v.schema)
                                : v.schema;

                    if (v.options)
                        retVal.options =
                            typeof v.options === "string"
                                ? JSON.parse(v.options)
                                : v.options;
                    return retVal;
                },
            );

        tempCompFuncs = (data.computeFunctions || [])
            .filter((v: { isGlobal: any }) => v.isGlobal)
            .map(
                (v: {
                    name: string;
                    output: string;
                    params: any[];
                    type: string;
                }) => {
                    let newValue = prevCompFuncs[v.name]?.output || v.output;

                    return {
                        ...v,
                        output: newValue,
                    };
                },
            );

        figmaName = data.name || "";
    } catch (e) {
        captureException(e);
        console.log(e);
        message.destroy();
        message.error("Could not fetch the data. Please check the url!");
        return;
    }

    message.destroy();
    message.success("Data Added");

    return {
        name: figmaName,
        compute_functions: tempCompFuncs,
        variables: tempVariables,
        figma_data: tempData,
    };
};
