import ReactPlayer from "react-player";
import { BLOCK_TYPES } from "../../common/index";
const { V2_AVATAR_BLOCK } = BLOCK_TYPES;

const renderBlock = (block: any) => {
    const {
        data: {
            [V2_AVATAR_BLOCK]: { value, type },
        },
    } = block;
    return (
        <>
            {type === "image" && (
                <div style={{ width: "40%" }}>
                    <img
                        src={value}
                        style={{ width: "-webkit-fill-available" }}
                    />
                </div>
            )}
            {type === "video" && (
                <div>
                    <ReactPlayer url={value} style={{ maxWidth: "100%" }} />
                </div>
            )}
        </>
    );
};

export default renderBlock;
