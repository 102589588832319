import React from "react";
import { Card, Col, Input, Row } from "antd";

import TagsField from "./TagsField";

const TagsAssignComponent = (props) => {
    const {
        isSet = false,
        selected,
        handleChange,
        dropdownOpen,
        tagGroup = "book",
    } = props;
    return (
        // <Card bodyStyle={{ padding: "8px" }}>
        // <Row gutter={[24, 0]} align="middle">
        // {isSet && (
        <TagsField
            {...props}
            value={selected}
            onChange={handleChange}
            dropdownOpen={dropdownOpen}
            tagGroup={tagGroup}
        />
        // )}
        // </Row>
        // </Card>
    );
};

export default TagsAssignComponent;
