import { EllipsisOutlined } from "@ant-design/icons";
import { Can } from "src/services/casl";
import { Col, Dropdown, Menu, Space } from "antd";
import { getActionMenuItems } from "../../../helpers";

export const Actions = ({
    disableActions,
    blocks,
    disableDuplicateAction,
    addBlock,
    deleteBlock,
    idx,
    type,
    addBlockCustom,
    block,
    // for progression stage
    updateId,
    ignoreId,
}: any) => {
    const getBlockActionsMenu = (idx: number, type: string) => {
        return (
            <Menu
                items={getActionMenuItems({
                    idx,
                    type,
                    blocks,
                    disableDuplicateAction,
                    addBlock,
                    deleteBlock,
                    addBlockCustom,
                    block,
                    // for progression stage
                    updateId,
                    ignoreId,
                })}
            />
        );
    };
    return (
        <Col span={2}>
            <Can I="edit" a={"worksheet_block"} passThrough>
                {(allowed: boolean) => (
                    <>
                        {!(disableActions || !allowed) && (
                            <Dropdown
                                overlay={() => getBlockActionsMenu(idx, type)}
                                trigger={["click"]}
                                overlayStyle={{ width: "100px" }}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <EllipsisOutlined
                                            style={{ fontSize: "20px" }}
                                            rotate={90}
                                        />
                                    </Space>
                                </a>
                            </Dropdown>
                        )}
                    </>
                )}
            </Can>
        </Col>
    );
};
