import { gql } from "@apollo/client";export default gql`mutation updatePlan($id: Int!, $object: subscription_plan_set_input) {
  updatePlan: update_subscription_plan_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
    banner
    message_banner
    created_at
    credits
    description
    parent_id
    slug
    status
    tags {
      id
      tag {
        id
        name
        value
      }
    }
    title
    type
    variants
    breakdown
    order
  }
}
`;
