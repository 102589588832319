import renderBlockCompareDigits from "./renderBlock";
import renderEditorCompareDigits from "./renderEditor";
import isValidCallbackCompareDigits from "./isValid";
import COMPARE_DIGITS_DEFAULT_VALUE from "./defaultValue";
import renderSettingsCompareDigits from "./renderSettings";
import renderCardCompareDigits from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common";

const { COMPARE_DIGITS_BLOCK } = BLOCK_TYPES;

const COMPARE_DIGITS_BLOCK_CONFIG = {
    [COMPARE_DIGITS_BLOCK]: {
        label: "Compare Digits",
        defaultValue: COMPARE_DIGITS_DEFAULT_VALUE,
        renderBlock: renderBlockCompareDigits,
        renderEditor: renderEditorCompareDigits,
        renderCard: renderCardCompareDigits,
        renderSettings: renderSettingsCompareDigits,
        addCallback,
        isValidCallback: isValidCallbackCompareDigits,
    },
};

export {
    renderBlockCompareDigits,
    renderCardCompareDigits,
    renderEditorCompareDigits,
    renderSettingsCompareDigits,
    isValidCallbackCompareDigits,
    COMPARE_DIGITS_DEFAULT_VALUE,
    COMPARE_DIGITS_BLOCK_CONFIG,
};
