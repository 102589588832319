import Latex from "katex-react";
import React from "react";

const MathRender = ({ src }) => {
    if (src) {
        return (
            <Latex throwOnError={false} errorColor={"#cc0000"}>
                {src}
            </Latex>
        );
    } else {
        return <></>;
    }
};

export default MathRender;
