import { useEffect, useState } from "react";
import { getSummaries, getCollectionId } from "../../../helpers";
import { utils, writeFile } from "xlsx";
import { Button, Input, Modal, Tooltip, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import axios from "axios";
import MetadataTagsForm from "../../MetadataTagsForm";
import { captureException } from "@sentry/react";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";

export const SharePopup = ({
    isSaving,
    blocks,
    worksheet_id,
    version,
    worksheet,
    onFinish,
    gcpSpreadsheetURL,
    isReadOnlyMode,
}: any) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [collectionId, setCollectionId] = useState(
        worksheet?.stats?.collection_id,
    );

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [gamifiedSet, setGamifiedSet] = useState<any>(
        worksheet?.stats?.set_id,
    );
    const [isGamifiedSetLoading, setIsGamifiedSetLoading] = useState(false);

    const downloadSubmissionsAsExcel = async () => {
        const rows = await getSummaries(
            gamifiedSet,
            worksheet?.type === "quiz_form",
        );

        const ws = utils.aoa_to_sheet(rows);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, ws, `Version - ${version}`);
        writeFile(
            workbook,
            `The Homework APP - Submissions for ${worksheet?.title}.xlsx`,
        );
    };

    useEffect(() => {
        if (gamifiedSet && !collectionId) {
            if (worksheet?.stats?.collection_id) {
                setCollectionId(worksheet?.stats?.collection_id);
            } else {
                getCollectionId(gamifiedSet).then((val) =>
                    setCollectionId(val),
                );
            }
        }
    }, [gamifiedSet]);

    const getQuizUerl = () => {
        let quizUrl = `${process.env.REACT_APP_CLIENT_BASE}/quiz/attempt?set_id=${gamifiedSet}&collection_id=${collectionId}`;

        const { other } = worksheet;

        if (!other) return quizUrl;

        const { utm_campaign, utm_content, utm_medium, utm_source, utm_term } =
            other;

        if (utm_source) quizUrl += `&utm_source=xxxx`;
        if (utm_medium) quizUrl += `&utm_medium=xxxx`;
        if (utm_campaign) quizUrl += `&utm_campaign=xxxx`;
        if (utm_term) quizUrl += `&utm_term=xxxx`;
        if (utm_content) quizUrl += `&utm_content=xxxx`;

        return quizUrl;
    };

    return (
        <>
            <Button
                type="dashed"
                block
                disabled={
                    isSaving || !blocks?.length || version < 2 || isReadOnlyMode
                }
                onClick={showModal}
            >
                Share
            </Button>
            <Modal
                title="Share Worksheet"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
            >
                {worksheet?.type === "personalized_learning" && (
                    <div
                        style={{
                            border: "1px dashed #808080",
                            margin: "10px auto",
                            padding: "10px 10px",
                        }}
                    >
                        <h3>Chat Flow for this worksheet</h3>

                        <div>
                            <Input.Group compact>
                                <Input
                                    style={{ width: "calc(100% - 50px)" }}
                                    defaultValue={`https://learning-sets-gamify.vercel.app/personalized_learning/attempt/${worksheet_id}`}
                                    readOnly={true}
                                />
                                <Tooltip title="copy set live url">
                                    <Button
                                        icon={<CopyOutlined />}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                `https://learning-sets-gamify.vercel.app/personalized_learning/attempt/${worksheet_id}`,
                                            );
                                            message.info(
                                                `copied link to clipboard!`,
                                            );
                                        }}
                                    />
                                </Tooltip>
                            </Input.Group>
                        </div>
                    </div>
                )}
                <KTWrapper feature="Live_worksheet">
                    <div
                        style={{
                            border: "1px dashed #808080",
                            margin: "10px auto",
                            padding: "10px 10px",
                        }}
                    >
                        <h3>Live worksheet</h3>

                        <Input.Group compact>
                            <Input
                                style={{ width: "calc(100% - 50px)" }}
                                defaultValue={
                                    [
                                        "personalized_learning_v3",
                                        "personalized_learning_v2",
                                    ].includes(worksheet?.type)
                                        ? `${process.env.REACT_APP_CLIENT_PREVIEW}/home/worksheet/${worksheet.id}`
                                        : `${window.location.host}/worksheet/live/${worksheet_id}`
                                }
                                readOnly={true}
                            />
                            <Tooltip title="copy worksheet live url">
                                <Button
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            [
                                                "personalized_learning_v3",
                                                "personalized_learning_v2",
                                            ].includes(worksheet?.type)
                                                ? `${process.env.REACT_APP_CLIENT_PREVIEW}/home/worksheet/${worksheet.id}`
                                                : `${window.location.host}/worksheet/live/${worksheet_id}`,
                                        );
                                        message.info(
                                            `copied link to clipboard!`,
                                        );
                                    }}
                                />
                            </Tooltip>
                        </Input.Group>
                    </div>
                </KTWrapper>

                {[
                    "personalized_learning_v3",
                    "personalized_learning_v2",
                ].includes(worksheet?.type) && (
                    <>
                        <div
                            style={{
                                border: "1px dashed #808080",
                                margin: "10px auto",
                                padding: "10px 10px",
                            }}
                        >
                            <h3>Live Worksheet with play/pause controller</h3>
                            <Input.Group compact>
                                <Input
                                    style={{ width: "calc(100% - 50px)" }}
                                    defaultValue={`${process.env.REACT_APP_CLIENT_PREVIEW}/home/worksheet/${worksheet.id}?controller=true`}
                                    readOnly={true}
                                />
                                <Tooltip title="Copy worksheet live url with controller">
                                    <Button
                                        icon={<CopyOutlined />}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                `${process.env.REACT_APP_CLIENT_PREVIEW}/home/worksheet/${worksheet.id}?controller=true`,
                                            );
                                            message.info(
                                                `Copied link to clipboard!`,
                                            );
                                        }}
                                    />
                                </Tooltip>
                            </Input.Group>
                        </div>
                        <div
                            style={{
                                border: "1px dashed #808080",
                                margin: "10px auto",
                                padding: "10px 10px",
                            }}
                        >
                            <h3>Worksheet assign link</h3>
                            <Input.Group compact>
                                <Input
                                    style={{ width: "calc(100% - 50px)" }}
                                    defaultValue={`${process.env.REACT_APP_CLIENT_PREVIEW}/home/${worksheet.id}`}
                                    readOnly={true}
                                />
                                <Tooltip title="Copy worksheet assign link">
                                    <Button
                                        icon={<CopyOutlined />}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                `${process.env.REACT_APP_CLIENT_PREVIEW}/home/${worksheet.id}`,
                                            );
                                            message.info(
                                                `Copied link to clipboard!`,
                                            );
                                        }}
                                    />
                                </Tooltip>
                            </Input.Group>
                        </div>
                    </>
                )}

                {![
                    "personalized_learning_v3",
                    "personalized_learning_v2",
                ].includes(worksheet?.type) && (
                    <>
                        {(worksheet?.type == "timed" ||
                            worksheet?.type == "quiz_form") && (
                            <div
                                style={{
                                    border: "1px dashed #808080",
                                    margin: "10px auto",
                                    padding: "10px 10px",
                                }}
                            >
                                <h3>
                                    {worksheet?.type == "timed"
                                        ? "Gamified"
                                        : "Quiz"}{" "}
                                    Set for this worksheet
                                </h3>

                                {gamifiedSet ? (
                                    <div>
                                        {worksheet?.type == "quiz_form" &&
                                        collectionId ? (
                                            <Input.Group compact>
                                                <Input
                                                    style={{
                                                        width: "calc(100% - 50px)",
                                                    }}
                                                    defaultValue={getQuizUerl()}
                                                    readOnly={true}
                                                />
                                                <Tooltip
                                                    title={`copy quiz live url`}
                                                >
                                                    <Button
                                                        icon={<CopyOutlined />}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                getQuizUerl(),
                                                            );
                                                            message.info(
                                                                `copied link to clipboard!`,
                                                            );
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Input.Group>
                                        ) : (
                                            <Input.Group compact>
                                                <Input
                                                    style={{
                                                        width: "calc(100% - 50px)",
                                                    }}
                                                    defaultValue={`${process.env.REACT_APP_CLIENT_BASE}/set/${gamifiedSet}`}
                                                    readOnly={true}
                                                />
                                                <Tooltip title="copy set live url">
                                                    <Button
                                                        icon={<CopyOutlined />}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                `${process.env.REACT_APP_CLIENT_BASE}/set/${gamifiedSet}`,
                                                            );
                                                            message.info(
                                                                `copied link to clipboard!`,
                                                            );
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Input.Group>
                                        )}
                                        <br />
                                        <Button
                                            block
                                            type={"primary"}
                                            onClick={downloadSubmissionsAsExcel}
                                        >
                                            Download Responses as Excel
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        type={"primary"}
                                        onClick={() => {
                                            setIsGamifiedSetLoading(true);
                                            axios
                                                .post(
                                                    process.env
                                                        .REACT_APP_WORKSHEET_TO_SET_API ||
                                                        "",
                                                    {
                                                        data: {
                                                            worksheet_id,
                                                            version,
                                                            worksheet_type:
                                                                worksheet?.type,
                                                        },
                                                    },
                                                )
                                                .then(function (response) {
                                                    setGamifiedSet(
                                                        response.data.data
                                                            .set_id,
                                                    );
                                                    setIsGamifiedSetLoading(
                                                        false,
                                                    );
                                                })
                                                .catch(function (error) {
                                                    captureException(error);
                                                    console.log(error);
                                                });
                                        }}
                                        loading={isGamifiedSetLoading}
                                    >
                                        Generate
                                    </Button>
                                )}
                            </div>
                        )}
                        {gamifiedSet && (
                            <div
                                style={{
                                    border: "1px dashed #808080",
                                    margin: "10px auto",
                                    padding: "10px 10px",
                                }}
                            >
                                <h3>Get Submissions in Google Sheets</h3>
                                {worksheet?.stats?.gcp_spreadsheet_id ||
                                gcpSpreadsheetURL ? (
                                    <Input.Group compact>
                                        <Input
                                            style={{
                                                width: "calc(100% - 50px)",
                                            }}
                                            defaultValue={
                                                gcpSpreadsheetURL ||
                                                `https://docs.google.com/spreadsheets/d/${worksheet?.stats?.gcp_spreadsheet_id}`
                                            }
                                            readOnly={true}
                                        />
                                        <Tooltip
                                            title={`copy ${
                                                worksheet?.type == "timed"
                                                    ? "set"
                                                    : "quiz"
                                            } live url`}
                                        >
                                            <Button
                                                icon={<CopyOutlined />}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        gcpSpreadsheetURL ||
                                                            `https://docs.google.com/spreadsheets/d/${worksheet?.stats.gcp_spreadsheet_id}`,
                                                    );
                                                    message.info(
                                                        `copied link to clipboard!`,
                                                    );
                                                }}
                                            />
                                        </Tooltip>
                                    </Input.Group>
                                ) : (
                                    <Button
                                        type={"primary"}
                                        onClick={() => {
                                            axios
                                                .post(
                                                    process.env
                                                        .REACT_APP_GOOGLE_CREATE_AUTH_LINK ||
                                                        "",
                                                    {
                                                        data: {
                                                            referrer_url:
                                                                window.location
                                                                    .href,
                                                            set_id: gamifiedSet,
                                                            worksheet_title:
                                                                worksheet?.title,
                                                            version: version,
                                                        },
                                                    },
                                                )
                                                .then(function (response) {
                                                    window.location.href =
                                                        response.data.data.url;
                                                })
                                                .catch(function (error) {
                                                    captureException(error);
                                                    console.log(error);
                                                });
                                        }}
                                    >
                                        Connect
                                    </Button>
                                )}
                            </div>
                        )}
                        <KTWrapper feature="Metadata_Tags">
                            <div
                                style={{
                                    border: "1px dashed #808080",
                                    margin: "10px auto",
                                    padding: "10px 10px",
                                }}
                            >
                                <h3>Metadata Tags</h3>

                                <MetadataTagsForm
                                    worksheet={worksheet}
                                    onFinish={onFinish}
                                />
                            </div>
                        </KTWrapper>
                    </>
                )}
            </Modal>
        </>
    );
};
