import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Select, Switch } from "antd";
import { cloneDeep, get, set } from "lodash";
import React from "react";
import { BlockPicker } from "react-color";
import { RenderUpload } from "src/components";
import { replaceCDNUrl } from "src/modules/worksheet/components/blocks/helpers";
import TablePaddingEditor from "../../../../Figma/renderEditor/TablePadding";
import TagContainer from "./TagContainer";

const SideContainer = (props) => {
    const {
        block,
        disableWrite,
        setBlock,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
        sideIndex,
        renderLinkSelect,
    } = props;
    const {
        data: { scale_balance_settings },
    } = block;
    const { containers } = scale_balance_settings;
    const currentCell = containers[sideIndex];
    const setCurrentCell = (cell: any) => {
        let tmpCell = cloneDeep(block);
        tmpCell = set(
            tmpCell,
            ["data", "scale_balance_settings", "containers", sideIndex],
            cell,
        );
        setBlock(tmpCell);
    };

    return (
        <Form
            style={{
                maxHeight: "75vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
            }}
        >
            <Row align={"middle"} gutter={24}>
                <Col span={16}>
                    <Row gutter={12}>
                        <Col>
                            <Form.Item label="Evaluation Type">
                                <Select
                                    value={currentCell.settings.evaluation}
                                    placeholder="Select type"
                                    options={[
                                        {
                                            label: "One by one",
                                            value: "ONE_BY_ONE",
                                        },
                                        // {
                                        //     label: "One by one till first incorrect",
                                        //     value: "ONE_BY_ONE_TILL_FIRST_INCORRECT",
                                        // },
                                        {
                                            label: "All in one go",
                                            value: "ALL_IN_ONE_GO",
                                        },
                                    ]}
                                    style={{
                                        width: "150px",
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(
                                            tmpCell,
                                            ["settings", "evaluation"],
                                            val,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                    disabled={disableWrite}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="Type">
                                <Select
                                    value={currentCell.type}
                                    placeholder="Select type"
                                    options={[
                                        {
                                            label: "Normal",
                                            value: "normal",
                                        },
                                        // {
                                        //     label: "One by one till first incorrect",
                                        //     value: "ONE_BY_ONE_TILL_FIRST_INCORRECT",
                                        // },
                                        {
                                            label: "DND",
                                            value: "dnd",
                                        },
                                    ]}
                                    style={{
                                        width: "150px",
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(tmpCell, ["type"], val);
                                        setCurrentCell(tmpCell);
                                    }}
                                    disabled={disableWrite}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Form.Item label="Feedback Type">
                        <Select
                            value={currentCell.settings.feedbackType}
                            placeholder="Select type"
                            options={[
                                {
                                    label: "No Feedback",
                                    value: "NO_FEEDBACK",
                                },
                                {
                                    label: "Manual Audio",
                                    value: "MANUAL_AUDIO",
                                },
                                {
                                    label: "Manual Story",
                                    value: "MANUAL_STORY",
                                },
                                // {
                                //     label: "Generated Audio",
                                //     value: "GENERATED_AUDIO",
                                // },
                                // {
                                //     label: "Generated Story",
                                //     value: "GENERATED_STORY",
                                // },
                            ]}
                            style={{
                                flexGrow: 0,
                            }}
                            onChange={(val) => {
                                let tmpCell = cloneDeep(currentCell);

                                tmpCell = set(
                                    tmpCell,
                                    ["settings", "feedbackType"],
                                    val,
                                );
                                setCurrentCell(tmpCell);
                            }}
                            disabled={disableWrite}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Has Highlight?">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={currentCell.settings.hasHighlight}
                            onChange={(val) => {
                                let tmpCell = cloneDeep(currentCell);
                                tmpCell = set(
                                    tmpCell,
                                    ["settings", "hasHighlight"],
                                    val,
                                );
                                setCurrentCell(tmpCell);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Has Feedback Stroke?">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={currentCell.settings.hasFeedbackStroke}
                            onChange={(val) => {
                                let tmpCell = cloneDeep(currentCell);
                                tmpCell = set(
                                    tmpCell,
                                    ["settings", "hasFeedbackStroke"],
                                    val,
                                );
                                setCurrentCell(tmpCell);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    {renderLinkSelect &&
                        renderLinkSelect({
                            value: currentCell?.linked_global_context_variable
                                ?.name,
                            onChange: (value) => {
                                let tmpCell = cloneDeep(currentCell);
                                tmpCell = set(
                                    tmpCell,
                                    ["linked_global_context_variable", "name"],
                                    value,
                                );
                                setCurrentCell(tmpCell);
                            },
                        })}
                </Col>
            </Row>
            <Divider />
            {currentCell.type == "dnd" && (
                <div>
                    <h3>DnD settings</h3>
                    <Row gutter={12}>
                        <Col>
                            <Form.Item label="Minimum items">
                                <Input
                                    type="number"
                                    style={{
                                        minWidth: "100px",
                                    }}
                                    disabled={disableWrite}
                                    value={currentCell?.dndSettings?.minItems}
                                    onChange={(e) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(
                                            tmpCell,
                                            ["dndSettings", "minItems"],
                                            e.target.value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="Maximum items">
                                <Input
                                    type="number"
                                    style={{
                                        minWidth: "100px",
                                    }}
                                    disabled={disableWrite}
                                    value={currentCell?.dndSettings?.maxItems}
                                    onChange={(e) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(
                                            tmpCell,
                                            ["dndSettings", "maxItems"],
                                            e.target.value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="Categories">
                                <Select
                                    disabled={disableWrite}
                                    mode="multiple"
                                    style={{ width: "100%", minWidth: "100px" }}
                                    placeholder="Select"
                                    value={currentCell?.dndSettings?.category}
                                    onChange={(val) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(
                                            tmpCell,
                                            ["dndSettings", "category"],
                                            val,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                    options={[
                                        {
                                            value: "left",
                                            label: "Left",
                                        },
                                        {
                                            value: "right",
                                            label: "Right",
                                        },
                                        {
                                            value: "both",
                                            label: "Both",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            )}
            <Divider />
            <Row>
                <Col span={12}>
                    <h3>Image Background</h3>
                    <Form.Item label="Enabled?">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={currentCell?.imageBackground?.enabled}
                            onChange={(value) => {
                                let tmpCell = cloneDeep(currentCell);
                                if (!tmpCell?.imageBackground)
                                    tmpCell.imageBackground = {};
                                tmpCell = set(
                                    tmpCell,
                                    ["imageBackground", "enabled"],
                                    value,
                                );
                                setCurrentCell(tmpCell);
                            }}
                        />
                    </Form.Item>
                    {currentCell?.imageBackground?.enabled && (
                        <>
                            <Form.Item label="Size">
                                <Select
                                    disabled={disableWrite}
                                    value={currentCell?.imageBackground?.size}
                                    onChange={(value) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(
                                            tmpCell,
                                            ["imageBackground", "size"],
                                            value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                >
                                    <Select.Option value="contain">
                                        Contain
                                    </Select.Option>
                                    <Select.Option value="cover">
                                        Cover
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item label="Position">
                                    <Select
                                        disabled={disableWrite}
                                        value={
                                            currentCell?.imageBackground
                                                ?.position
                                        }
                                        onChange={(value) => {
                                            let tmpCell =
                                                cloneDeep(currentCell);
                                            tmpCell = set(
                                                tmpCell,
                                                ["imageBackground", "position"],
                                                value,
                                            );
                                            setCurrentCell(tmpCell);
                                        }}
                                    >
                                        <Select.Option value="bottom">
                                            Bottom
                                        </Select.Option>
                                        <Select.Option value="top">
                                            Top
                                        </Select.Option>
                                        <Select.Option value="center">
                                            Center
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Form.Item label="Asset">
                                <RenderUpload
                                    singleUpload={true}
                                    disabled={disableWrite}
                                    addExtension={true}
                                    path="home-explore/document/"
                                    onChangeCustom={(e) => {
                                        const value = replaceCDNUrl(
                                            e.target.value,
                                            e.target.bucket,
                                        );
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = _.set(
                                            tmpCell,
                                            [
                                                "imageBackground",
                                                "src",
                                                "default",
                                            ],
                                            value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                    label="upload the file"
                                    value={
                                        currentCell?.imageBackground?.src
                                            ?.default
                                    }
                                />
                                <Input
                                    placeholder="Add value here"
                                    value={
                                        currentCell?.imageBackground?.src
                                            ?.default
                                    }
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = _.set(
                                            tmpCell,
                                            [
                                                "imageBackground",
                                                "src",
                                                "default",
                                            ],
                                            value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                />
                            </Form.Item>
                        </>
                    )}
                </Col>
                <Col span={12}>
                    <h3>Fill Color</h3>
                    <Form.Item label="Enabled?">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={currentCell?.fillColor?.enabled}
                            onChange={(value) => {
                                let tmpCell = cloneDeep(currentCell);
                                tmpCell = set(
                                    tmpCell,
                                    ["fillColor", "enabled"],
                                    value,
                                );
                                setCurrentCell(tmpCell);
                            }}
                        />
                    </Form.Item>
                    {currentCell?.fillColor?.enabled && (
                        <Form.Item label="Default Color">
                            <BlockPicker
                                colors={[]}
                                color={currentCell?.fillColor?.default}
                                onChangeComplete={(color: any) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = set(
                                        tmpCell,
                                        ["fillColor", "default"],
                                        color.hex,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                                triangle="hide"
                            />
                        </Form.Item>
                    )}
                </Col>
            </Row>
            <Divider />
            <Row justify={"space-between"}>
                <Col span={12}>
                    <TablePaddingEditor
                        blockData={currentCell}
                        setBlock={setCurrentCell}
                    />
                </Col>
                <Col span={12}>
                    <Row>
                        <Col>
                            <Form.Item label="Min height">
                                <Input
                                    type={"number"}
                                    disabled={disableWrite}
                                    value={currentCell?.minHeight?.default}
                                    onChange={(value) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(
                                            tmpCell,
                                            ["minHeight", "default"],
                                            value.target.value,
                                        );
                                        tmpCell = set(
                                            tmpCell,
                                            ["minHeight", "enabled"],
                                            true,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="Is height fixed?">
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={
                                        currentCell?.minHeight?.fixedHeight
                                    }
                                    onChange={(value) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(
                                            tmpCell,
                                            ["minHeight", "fixedHeight"],
                                            value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider />
            <h3>Flex size</h3>
            <Form.Item label="Enabled?">
                <Switch
                    disabled={disableWrite}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={currentCell?.flexSize?.enabled}
                    onChange={(value) => {
                        let tmpCell = cloneDeep(currentCell);
                        if (!tmpCell?.flexSize) tmpCell.flexSize = {};
                        tmpCell = set(tmpCell, ["flexSize", "enabled"], value);
                        setCurrentCell(tmpCell);
                    }}
                />
            </Form.Item>
            {currentCell?.flexSize?.enabled && (
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="Wrap">
                            <Select
                                disabled={disableWrite}
                                value={currentCell?.flexSize?.wrap}
                                onChange={(value) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = set(
                                        tmpCell,
                                        ["flexSize", "wrap"],
                                        value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            >
                                <Select.Option value="no-wrap">
                                    No Wrap
                                </Select.Option>
                                <Select.Option value="wrap">Wrap</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Align Items">
                            <Select
                                disabled={disableWrite}
                                value={currentCell?.flexSize?.alignItems}
                                onChange={(value) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = set(
                                        tmpCell,
                                        ["flexSize", "alignItems"],
                                        value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            >
                                <Select.Option value="start">
                                    Start
                                </Select.Option>
                                <Select.Option value="end">End</Select.Option>
                                <Select.Option value="center">
                                    Center
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {currentCell?.flexSize?.wrap == "wrap" && (
                        <Col span={12}>
                            <Form.Item label="Row Items">
                                <Input
                                    type={"number"}
                                    disabled={disableWrite}
                                    value={currentCell?.flexSize?.rowItems}
                                    onChange={(value) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(
                                            tmpCell,
                                            ["flexSize", "rowItems"],
                                            value.target.value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                ></Input>
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={12}>
                        <Form.Item label="Row Gap">
                            <Input
                                type={"number"}
                                disabled={disableWrite}
                                value={currentCell?.flexSize?.rowGap}
                                onChange={(value) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = set(
                                        tmpCell,
                                        ["flexSize", "rowGap"],
                                        value.target.value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Column Gap">
                            <Input
                                type={"number"}
                                disabled={disableWrite}
                                value={currentCell?.flexSize?.columnGap}
                                onChange={(value) => {
                                    let tmpCell = cloneDeep(currentCell);
                                    tmpCell = set(
                                        tmpCell,
                                        ["flexSize", "columnGap"],
                                        value.target.value,
                                    );
                                    setCurrentCell(tmpCell);
                                }}
                            ></Input>
                        </Form.Item>
                    </Col>
                </Row>
            )}
            <div>
                <TagContainer
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    disableWrite={disableWrite}
                    currentEditor={currentEditor}
                    setCurrentEditor={setCurrentEditor}
                    items={currentCell.items || []}
                    setBlock={(v) => {
                        console.log(v, "this is called");
                        let tmpCell = cloneDeep(currentCell);
                        tmpCell = set(tmpCell, ["items"], v);
                        setCurrentCell(tmpCell);
                    }}
                    schema={{
                        data: [
                            { text: [], showText: 1 },
                            {
                                height: 55,
                                showImg: 1,
                                img: {
                                    rotation: 0,
                                    asset: "https://cdn.homeworkapp.ai/sets-gamify-assets/dev/home-explore/document/1709121007520",
                                    type: "IMAGE",
                                },
                                width: 50,
                            },
                        ],
                        category: "",
                        value: 1,
                        bg_type1: 0,
                        enable: true,
                        name: "",
                        width: 50,
                        height: 55,
                        bg: { type: "SOLID", color: "#F2F2F2" },
                        showDisabled: 0,
                        index: "0.0",
                        type: "normal",
                        weight: 0,
                        maxDragLimit: 999,
                        padding: {
                            enabled: false,
                            custom: { enabled: false },
                        },
                        border: { enabled: false },
                    }}
                    categories={["left", "right", "both"]}
                />
            </div>
        </Form>
    );
};

export default SideContainer;
