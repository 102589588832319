import { gql } from "@apollo/client";
export default gql`
    query WorksheetBlocksQuery($worksheet_id: Int!) {
        publlishedBlocks: worksheet_worksheet_block_map(
            where: {
                worksheet_id: { _eq: $worksheet_id }
                is_published: { _eq: true }
            }
            order_by: { order: asc }
        ) {
            id
            order
            worksheet_id
            block {
                id
                reference_id
                children(order_by: { order: asc }) {
                    id
                    reference_id
                    children(order_by: { order: asc }) {
                        id
                        reference_id
                    }
                }
            }
        }
    }
`;
