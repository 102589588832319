import { isEditorEmpty } from "../../common";
import { BLOCK_TYPES } from "../../common/index";

const { LONG_ANSWER_BLOCK } = BLOCK_TYPES;

const isValidCallbackLongAnswer = ({ block }: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            [LONG_ANSWER_BLOCK]: { text, limit, hasLimit },
        },
    } = block;

    return {
        isValid:
            !isEditorEmpty(text) &&
            (!hasLimit || (hasLimit && limit && limit > 0)),
        error: <></>,
    };
};

export default isValidCallbackLongAnswer;
