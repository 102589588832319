const isValidCallbackButton = ({ block }: any) => {
    const {
        data: { button },
    } = block;
    return {
        isValid: Boolean(button.text.trim()),
        error: (
            <div>
                {/* <b>Validation Error: </b>
                <span
                    style={{
                        color: "red",
                        textDecoration: "underline",
                        fontStyle: "italic",
                    }}
                >
                    Button block cannot be Empty
                </span> */}
            </div>
        ),
    };
};

export default isValidCallbackButton;
