import React, { useState } from "react";
import _ from "lodash";
import { Row, Col, Form, Input, Select, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";

const TablePaddingEditor = (props: any) => {
    const { blockData, setBlock, disableWrite } = props;
    return (
        <>
            <h3>Padding Settings:</h3>
            <Row gutter={[12, 12]}>
                <Col span={12}>
                    <KTWrapper feature="Padding_Settings">
                        <Form.Item label="Enabled?">
                            <Switch
                                disabled={disableWrite}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={blockData?.padding?.enabled}
                                onChange={(value) => {
                                    let tmpCell = _.cloneDeep(blockData);
                                    if (tmpCell.padding === undefined) {
                                        tmpCell.padding = {
                                            enabled: false,
                                        };
                                    }
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["padding", "enabled"],
                                        value,
                                    );
                                    setBlock(tmpCell);
                                }}
                            />
                        </Form.Item>
                    </KTWrapper>
                </Col>
                {blockData?.padding?.enabled && (
                    <>
                        {!blockData?.padding?.custom?.enabled && (
                            <>
                                <Col span={12}>
                                    <Form.Item label="Horizontal Padding">
                                        {/*Number input */}
                                        <Input
                                            disabled={disableWrite}
                                            type="number"
                                            value={
                                                blockData?.padding?.horizontal
                                            }
                                            onChange={(e) => {
                                                let tmpBlock =
                                                    _.cloneDeep(blockData);
                                                if (
                                                    tmpBlock.padding ===
                                                    undefined
                                                )
                                                    tmpBlock.padding = {
                                                        horizontal: 0,
                                                    };
                                                tmpBlock.padding.horizontal =
                                                    Number(e.target.value);
                                                setBlock(tmpBlock);
                                            }}
                                            width={200}
                                            min={0}
                                            max={100}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Vertical Padding">
                                        {/*Number input */}
                                        <Input
                                            disabled={disableWrite}
                                            type="number"
                                            value={blockData?.padding?.vertical}
                                            onChange={(e) => {
                                                let tmpBlock =
                                                    _.cloneDeep(blockData);
                                                if (
                                                    tmpBlock.padding ===
                                                    undefined
                                                )
                                                    tmpBlock.padding = {
                                                        vertical: 0,
                                                    };
                                                tmpBlock.padding.vertical =
                                                    Number(e.target.value);
                                                setBlock(tmpBlock);
                                            }}
                                            width={200}
                                            min={0}
                                            max={100}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                        <>
                            <Col span={24}>
                                <Form.Item label="Custom Enabled?">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={
                                            blockData?.padding?.custom?.enabled
                                        }
                                        onChange={(value) => {
                                            let tmpCell =
                                                _.cloneDeep(blockData);
                                            if (
                                                tmpCell.padding.custom ===
                                                undefined
                                            ) {
                                                tmpCell.padding.custom = {
                                                    enabled: false,
                                                };
                                            }
                                            tmpCell = _.set(
                                                tmpCell,
                                                [
                                                    "padding",
                                                    "custom",
                                                    "enabled",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpCell);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            {blockData?.padding?.custom?.enabled && (
                                <>
                                    <Col span={12}>
                                        <Form.Item label="Top Padding">
                                            <Input
                                                disabled={disableWrite}
                                                type="number"
                                                value={
                                                    blockData?.padding?.custom
                                                        ?.top
                                                }
                                                onChange={(e) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(blockData);
                                                    if (
                                                        tmpBlock.padding
                                                            .custom ===
                                                        undefined
                                                    )
                                                        tmpBlock.padding.custom =
                                                            {
                                                                top: 0,
                                                            };
                                                    tmpBlock.padding.custom.top =
                                                        Number(e.target.value);
                                                    setBlock(tmpBlock);
                                                }}
                                                width={200}
                                                min={0}
                                                max={100}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Right Padding">
                                            <Input
                                                disabled={disableWrite}
                                                type="number"
                                                value={
                                                    blockData?.padding?.custom
                                                        ?.right
                                                }
                                                onChange={(e) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(blockData);
                                                    if (
                                                        tmpBlock.padding
                                                            .custom ===
                                                        undefined
                                                    )
                                                        tmpBlock.padding.custom =
                                                            {
                                                                right: 0,
                                                            };
                                                    tmpBlock.padding.custom.right =
                                                        Number(e.target.value);
                                                    setBlock(tmpBlock);
                                                }}
                                                width={200}
                                                min={0}
                                                max={100}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Bottom Padding">
                                            <Input
                                                disabled={disableWrite}
                                                type="number"
                                                value={
                                                    blockData?.padding?.custom
                                                        ?.bottom
                                                }
                                                onChange={(e) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(blockData);
                                                    if (
                                                        tmpBlock.padding
                                                            .custom ===
                                                        undefined
                                                    )
                                                        tmpBlock.padding.custom =
                                                            {
                                                                bottom: 0,
                                                            };
                                                    tmpBlock.padding.custom.bottom =
                                                        Number(e.target.value);
                                                    setBlock(tmpBlock);
                                                }}
                                                width={200}
                                                min={0}
                                                max={100}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Left Padding">
                                            <Input
                                                disabled={disableWrite}
                                                type="number"
                                                value={
                                                    blockData?.padding?.custom
                                                        ?.left
                                                }
                                                onChange={(e) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(blockData);
                                                    if (
                                                        tmpBlock.padding
                                                            .custom ===
                                                        undefined
                                                    )
                                                        tmpBlock.padding.custom =
                                                            {
                                                                left: 0,
                                                            };
                                                    tmpBlock.padding.custom.left =
                                                        Number(e.target.value);
                                                    setBlock(tmpBlock);
                                                }}
                                                width={200}
                                                min={0}
                                                max={100}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </>
                    </>
                )}
            </Row>
        </>
    );
};

export default TablePaddingEditor;
