import { Card, Form, Input, Select } from "antd";
import _ from "lodash";
import { SlateEditor } from "src/components";
import ComponentDetails from "../common/ComponentDetails";
import { BlockPicker } from "react-color";

const CustomRichTextEditor = ({
    disableWrite,
    hasMentions,
    mentionsList,
    block,
    setBlock,
    currentEditor,
    setCurrentEditor,
}) => {
    return (
        <div style={{ paddingBottom: "10px", display: "flex" }}>
            <Card
                style={{
                    minWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    maxHeight: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
                title={<h3>Configurations</h3>}
            >
                <ComponentDetails
                    block={block}
                    setBlock={setBlock}
                    disableWrite={disableWrite}
                />
                <Form.Item label="Text">
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`component_text`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(tmpBlock, ["data", "text"], value);
                            setBlock(tmpBlock);
                        }}
                        isFocused={currentEditor === `component_text`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={block?.data?.text}
                        placeholder={"Type your description here..."}
                    />
                </Form.Item>

                <Form.Item label="Background Color">
                    <BlockPicker
                        colors={[]}
                        color={block?.data?.background}
                        onChangeComplete={(color: any) => {
                            if (disableWrite) return;
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "background"],
                                color.hex,
                            );
                            setBlock(tmpBlock);
                        }}
                        triangle="hide"
                    />
                </Form.Item>

                <Form.Item label="Text Align">
                    <Select
                        value={block?.data?.align}
                        placeholder="Select type"
                        options={[
                            { label: "Left", value: "left" },
                            { label: "Center", value: "center" },
                            { label: "Right", value: "right" },
                            { label: "Justify", value: "justify" },
                        ]}
                        onChange={(val) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(tmpBlock, ["data", "align"], val);
                            setBlock(tmpBlock);
                        }}
                        disabled={disableWrite}
                    />
                </Form.Item>
            </Card>
        </div>
    );
};

export default CustomRichTextEditor;
