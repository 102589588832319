const isSelectionRectangle = (selection, selectedCells = []) => {
    if (!selection || selection.length === 0 || selection?.length === 1)
        return false;
    // console.log("selectedCells----Start", JSON.parse(JSON.stringify({selection})));

    if (selectedCells.length > 0) {
        const selectedCellsSet = new Set(selection);
        selectedCells?.forEach((cell) => {
            if (cell?.merge?.enabled) {
                cell.merge.mergedCells.forEach((mergedCell) => {
                    selectedCellsSet.add(mergedCell);
                });
                // isMergeEnabled = true;
            }
        });
        // if (isMergeEnabled) return false;
        selection = Array.from(selectedCellsSet);
    }

    // console.log("selectedCells----", JSON.parse(JSON.stringify({selection})));
    // Extract rows and columns from the selection
    const rows = selection.map((coord) => coord[0]);
    const cols = selection.map((coord) => coord[1]);

    // Get the unique row and column values
    const uniqueRows = [...new Set(rows)].sort((a, b) => a - b);
    const uniqueCols = [...new Set(cols)].sort((a, b) => a - b);

    // Check if all rows and columns form a continuous block
    const rowCount = uniqueRows.length;
    const colCount = uniqueCols.length;

    // Ensure there is no missing row or column in the sequence
    if (uniqueRows[rowCount - 1] - uniqueRows[0] + 1 !== rowCount) return false;
    if (uniqueCols[colCount - 1] - uniqueCols[0] + 1 !== colCount) return false;

    // Check if all coordinates are covered by this rectangular range
    for (let row of uniqueRows) {
        for (let col of uniqueCols) {
            if (!selection.some(([r, c]) => r === row && c === col)) {
                return false;
            }
        }
    }

    return true;
};

export default isSelectionRectangle;
