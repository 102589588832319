import { EditOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, message, Modal, Space, Upload } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { captureException } from "@sentry/react";

const FormItem = Form.Item;

const bucket_name = process.env.REACT_APP_BUCKET_NAME;
export const getPresignedURL = async (path = "", extension = "") => {
    const url =
        "https://qjbn7p56fd.execute-api.ap-south-1.amazonaws.com/production/generate";

    const data = {
        bucket_name: bucket_name,
        key: process.env.REACT_APP_BUCKET_BASE + path + Date.now() + extension,
    };
    // console.log(data);
    const options = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    };
    const response = await fetch(url, options);
    const resObj = await response.json();

    return resObj;
};

export const getGumletURL = async (path = "", extension = "") => {
    console.log("called herere for getGumletURL");
    const response = await axios.post(
        `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v2/getGumletUrl`,
        {},
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    );
    // .then(function (response) {
    //     console.log(response, "response");
    //     // if (response.data) {
    //     //     setlatexObj({
    //     //         ...latexObj,
    //     //         latex_text: `$$${response.data.data.latex_text}$$`,
    //     //     });
    //     // }
    // })
    // .catch(function (error) {
    //     console.log("error", error);
    // });
    console.log(response, "response");
    return {
        url: response.data.data.upload_url,
        bucket_url: response.data.data.playback_url,
    };
    // let headersList = {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer f7d4a9e50fc6cc2c636160771d955bec",
    //     "Access-Control-Allow-Origin": "*",
    // };

    // let bodyContent = JSON.stringify({
    //     format: "MP4",
    //     collection_id: "6606b823258edd12778f9c40",
    // });

    // let response = await fetch(
    //     "https://api.gumlet.com/v1/video/assets/upload",
    //     {
    //         method: "POST",
    //         body: bodyContent,
    //         headers: headersList,
    //     },
    // );

    // let data = await response.text();
    // console.log(data);
};

export const RenderUpload = (props) => {
    const { onRemove, disabled, useGamlet, dimension } = props;
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [fileList, setFileList] = useState(
        props.value
            ? [
                  {
                      uid: "-1",
                      name: props.uploadType != "custom" ? "image.png" : "file",
                      status: "done",
                      url: props.value,
                  },
              ]
            : [],
    );
    const [fileError, setFileError] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [presignedUrl, setPresignedUrl] = useState("");
    const [inValidDimension, setInValidDimension] = useState(false);

    useEffect(() => {
        setFileList(
            props.value
                ? [
                      {
                          uid: "-1",
                          name:
                              props.uploadType != "custom"
                                  ? "image.png"
                                  : "file",
                          status: "done",
                          url: props.value,
                      },
                  ]
                : [],
        );
    }, [props.value]);

    const onChangeHandler = ({ file, fileList }) => {
        // console.log(file.status);
        // console.log(fileList);
        // const arrayHelpers = props.arrayHelpers;

        if (inValidDimension) {
            props.setLoad(false);
            setFileError(true);
            setFileList([]);
            return;
        }

        if (file.status === "uploading") {
            if (props.setLoad) props.setLoad(true);
        }

        if (file.status == "done") {
            // if (dimension) {
            //     console.log("dimension", dimension, file);
            //     const img = new Image();
            //     img.src = file.url;
            //     img.onload = function () {
            //         if (
            //             img.width !== dimension.width ||
            //             img.height !== dimension.height
            //         ) {
            //             message.error(
            //                 `Image dimension should be ${dimension.width} x ${dimension.height}`,
            //             );
            //             setFileError(true);
            //             return;
            //         }
            //     };
            // }
            if (props.setLoad) props.setLoad(false);
        } else if (file.status == "removed") {
            if (props.onChangeCustom && props.singleUpload) {
                props.onChangeCustom({
                    target: {
                        value: null,
                        name: null,
                        bucket: bucket_name,
                    },
                });
            }

            if (props.setLoad) props.setLoad(false);
            // console.log(file);
            //remove value in form
            props?.input?.onChange("");
        } else if (file.status == "error") {
            setFileError(true);
        }
        setFileList(fileList);
    };

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = (file) => {
        if (inValidDimension) return;
        setPreviewImage(file.url || file.thumbUrl);
        setPreviewVisible(true);
    };

    const handleUpload = async ({ onSuccess, onError, file }) => {
        let extension = "";
        setInValidDimension(false);

        if (props.addExtension) {
            extension = file.name.split(".").pop();
            if (extension.length > 0) extension = "." + extension;
        }
        try {
            const xhr = new XMLHttpRequest();
            let url = "";
            let bucket_url = "";
            if (useGamlet) {
                const urls = await getGumletURL(
                    props.path,
                    extension || props.extension || "",
                );
                url = urls.url;
                bucket_url = urls.bucket_url;
            } else {
                const urls = await getPresignedURL(
                    props.path,
                    extension || props.extension || "",
                );
                url = urls.url;
                bucket_url = urls.bucket_url;
            }
            let isDimensionValid = true;

            // S3 requires PUT method!
            xhr.open("PUT", url);
            xhr.onreadystatechange = async () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        if (dimension) {
                            await new Promise((resolve) => {
                                const img = new Image();
                                img.src = bucket_url;
                                img.onload = function () {
                                    if (
                                        img.width !== dimension.width ||
                                        img.height !== dimension.height
                                    ) {
                                        message.error(
                                            `Image dimension should be ${dimension.width} x ${dimension.height}`,
                                        );
                                        setFileError(true);
                                        isDimensionValid = false;
                                    }
                                    resolve("");
                                };
                            });
                        }

                        if (!isDimensionValid) {
                            onError("Image dimension is not valid", null, file);
                            setInValidDimension(true);
                            setFileList([]);
                            return;
                        }
                        // Calls the update prop
                        if (props.onChangeCustom)
                            props.onChangeCustom({
                                target: {
                                    value: bucket_url,
                                    name: props.name,
                                    bucket: bucket_name,
                                },
                            });
                        else
                            props.formik?.handleChange({
                                target: {
                                    value: bucket_url,
                                    name: props.name,
                                },
                            });
                        onSuccess(null, file);
                    } else {
                        onError(xhr.responseText, xhr.response, file);
                    }
                }
            };
            xhr.send(file);
        } catch (e) {
            captureException(e);
            console.log("Error:", e);
            message.error("Something went wrong!");
            onError("Something went wrong!", e, file);
        }
    };

    const touched = props?.meta?.touched;
    const error = props?.meta?.error;
    const children = props.children;
    const label = props?.label;

    let validateStatus = "";
    if (touched && error) {
        validateStatus = "error";
    }

    const icon = "UploadOutlined";

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    return (
        <FormItem
            label={
                !props.editButton && (
                    <Space align="center">
                        {icon && <UploadOutlined />}
                        {label}
                    </Space>
                )
            }
            validateStatus={validateStatus}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            required={props.required}
            help={
                inValidDimension
                    ? `Please upload image with ${dimension.width} x ${dimension.height} dimension`
                    : error
                    ? fileError
                        ? `allowed formats - ${
                              props.accept
                                  ? props.accept
                                  : "png/jpeg/gif/svg/webp"
                          } `
                        : ""
                    : ""
            }
        >
            {props.uploadType !== "custom" && !props.editButton && (
                <div>
                    <Upload
                        disabled={disabled}
                        customRequest={handleUpload}
                        listType={!children && "picture-card"}
                        fileList={!children && fileList}
                        onPreview={handlePreview}
                        onChange={onChangeHandler}
                        accept="image/png, image/gif, image/jpeg, image/webp, image/svg+xml"
                    >
                        {children
                            ? children
                            : fileList.length >= 1
                            ? null
                            : uploadButton}
                    </Upload>
                    {!children && (
                        <Modal
                            open={previewVisible}
                            footer={null}
                            centered
                            onCancel={handleCancel}
                        >
                            <img
                                alt="img"
                                style={{ width: "100%" }}
                                src={previewImage}
                            />
                        </Modal>
                    )}
                </div>
            )}

            {props.editButton && (
                <Upload
                    disabled={disabled}
                    customRequest={handleUpload}
                    fileList={[]}
                    onChange={onChangeHandler}
                    accept="image/png, image/gif, image/jpeg, image/webp"
                >
                    <Button icon={<EditOutlined />} shape="circle"></Button>
                </Upload>
            )}

            {props.uploadType == "custom" && (
                <Upload
                    disabled={disabled}
                    customRequest={handleUpload}
                    fileList={!children && fileList}
                    onChange={onChangeHandler}
                    onRemove={onRemove}
                    accept={props.accept}
                >
                    {props.singleUpload ? (
                        <>
                            {fileList.length !== 1 && (
                                <Button icon={<UploadOutlined />}>
                                    Click to Upload
                                </Button>
                            )}
                        </>
                    ) : (
                        <Button icon={<UploadOutlined />}>
                            Click to Upload
                        </Button>
                    )}
                </Upload>
            )}
        </FormItem>
    );
};
RenderUpload.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    setLoad: PropTypes.func,
    formik: PropTypes.func,
    onChangeCustom: PropTypes.func,
    name: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    addExtension: PropTypes.bool,
    path: PropTypes.string,
    singleUpload: PropTypes.bool,
};
