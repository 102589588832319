import { gql } from "@apollo/client";export default gql`query BooksQuery($parent_id: Int!) {
  group_tab: home_explore_group_tab_map(where: {tab_id: {_eq: $parent_id}}) {
	  id
	  order
    book: group {
    	id
    	description
    	cover
    	other
    	title
    	type
    	status
    }
  }
}
`;
