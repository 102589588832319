import { gql } from "@apollo/client";

export default gql`
    mutation insert_worksheet_block_one(
        $object: worksheet_block_insert_input!
    ) {
        insert_worksheet_block_one(object: $object) {
            id
        }
    }
`;
