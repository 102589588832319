import { gql } from "@apollo/client";
export default gql`
query VersionModelMapQuery(
        $model_name: String!
        $model_id: Int!
        $version: Int = 0
    ) {
        versionModelMap: versioning_version_model_map(
            where: {
                model_name: { _eq: $model_name }
                model_id: { _eq: $model_id }
            }
        ) {
            id
            versions(where: { index: { _eq: $version } }) {
                id
                data
                index
            }
            versions_aggregate {
                aggregate {
                    max {
                        index
                    }
                    count
                }
            }
        }
    }
`;
