import { ContentList, ContentCreate, ContentEdit } from "./views";

import { Route } from "react-router-dom";
export const CONTENT_ROUTES = [
    <Route path="/collection/content" element={<ContentList />} />,
    <Route
        path="/collection/content/:collection_id"
        element={<ContentList />}
    />,

    <Route
        path="/collection/content/create/:collection_id"
        element={<ContentCreate />}
    />,
    <Route
        path="/collection/content/update/:content_id"
        element={<ContentEdit />}
    />,
];

export { ContentList, ContentCreate, ContentEdit } from "./views";
