import { BLOCK_SEARCH_QUERY, BLOCK_PARENT_QUERY } from "../../../graphql";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Component } from "./component";
import LoadingOverlay from "react-loading-overlay";
import { Spin } from "antd";
import { getParsedBlocks } from "../../edit_enhanced/container";

const OverlaySpinner = ({ active, children }: any) => {
    return (
        <LoadingOverlay active={active} spinner={<Spin />}>
            {children}
        </LoadingOverlay>
    );
};

const WorksheetBlockSearch = (props: any) => {
    const [isWorksheetActive, toggleWorksheetActive] = useState(true);
    const [searchQuery, setSearchQuery] = useState(``);

    const [error, setError] = useState(false);
    const [paginatedBlocks, setPaginatedBlocks] = useState([]);

    const [getBlocks, { loading, data: { blocks = [] } = {} }] =
        useLazyQuery(BLOCK_SEARCH_QUERY);
    const [getWorksheetStatus, { loading: loadingWorksheetStatus }] =
        useLazyQuery(BLOCK_PARENT_QUERY);

    const LIST_COUNT = 12;
    useEffect(() => {
        if (!loading) setPaginatedBlocks(blocks.slice(0, LIST_COUNT));
    }, [loading]);

    const loadMore = async () => {
        setPaginatedBlocks(
            blocks.slice(
                0,
                blocks.length > paginatedBlocks.length + LIST_COUNT
                    ? paginatedBlocks.length + LIST_COUNT
                    : blocks.length,
            ),
        );
    };

    const onSearch = (value: string) => {
        setSearchQuery(value);
        if (value.length < 10) setError(true);
        else {
            setError(false);
            getBlocks({
                variables: {
                    search: `%${value}%`,
                },
            });
        }
    };

    const redirectToWorksheet = async (block) => {
        const { id, parent_id, data_as_text } = block;

        const {
            data: {
                worksheet_block_by_pk: { worksheets },
            },
        } = await getWorksheetStatus({
            variables: {
                id: parent_id || id,
            },
        });

        const worksheetId = worksheets[0]?.worksheet_id;

        if (typeof window !== undefined)
            window
                .open(
                    `/worksheet/update_enhanced/${worksheetId}?blockId=${id}&blockText=${encodeURIComponent(
                        data_as_text,
                    )}&blockParentId=${parent_id}`,
                    "_blank",
                )
                .focus();
    };

    const handleWorksheetCheckbox = () => {
        const currValue = !isWorksheetActive;
        toggleWorksheetActive(currValue);

        // fetch data again
        if (searchQuery.length < 10) setError(true);
        else {
            setError(false);
            getBlocks({
                variables: {
                    search: `%${searchQuery}%`,
                    status: currValue ? `active` : `inactive`,
                },
            });
        }
    };

    const componentProps = {
        blocks: paginatedBlocks,
        getBlocks,
        loadMore,
        onSearch,
        redirectToWorksheet,
        error,
        showLoadMore: blocks.length > getParsedBlocks.length,
        isWorksheetActive,
        handleWorksheetCheckbox,
        searchQuery,
    };

    return (
        <OverlaySpinner active={loading || loadingWorksheetStatus}>
            <Component {...props} {...componentProps} />
        </OverlaySpinner>
    );
};
export default WorksheetBlockSearch;
