import {
    EditOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { SlateEditor } from "src/components/plate";
import CellInputPopup from "./CellInputPopupV2";
import HeadingPopupForm from "./CellInputPopupV2/HeadingPopupForm";
import { Button, Col, Popconfirm, Row, Space, Table } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { DEFAULT_V2_CELL } from "../defaultValue";
import {
    AudioPicker,
    BLOCK_TYPES,
    DurationPicker,
    isEditorEmpty,
    prefillText,
} from "../../../common/index";
import { renderer } from "../../../helpers";
import PreviewBlocksField from "../../../common/PreviewBlocksField";

const { V2_TABLE_BLOCK } = BLOCK_TYPES;

const EditorTableV2 = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    setIsNewBlock,
    isNewBlock,
    mentionsList = [],
    hasMentions,
    worksheet,

    showDuration = true,
    showBackgroundAudio = true,
    disableWrite,
    useInputAnswer,
    onInputVariableUpdate,
    hasPreviewBlocks,
    isReadOnlyMode,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            [V2_TABLE_BLOCK]: {
                row_heading = [],
                column_heading = [],
                data = [[]],
            },
            other: { description = [] },
        },
    } = block;

    const [currentEditor, setCurrentEditor] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isHeadModalOpen, setIsHeadModalOpen] = useState(false);
    const [headType, setheadType] = useState("row_heading");

    const [curHeadCol, setCurHeadCol] = useState(-1);

    const [curCellRow, setCurCellRow] = useState(-1);
    const [curCellCol, setCurCellCol] = useState(-1);
    const [curCell, setCurCell] = useState({});

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    const showHeadModal = (column: number, head = "row_heading") => {
        setheadType(head);
        setCurHeadCol(column);
        setIsHeadModalOpen(true);
    };

    const showModal = (row: number, column: number, cell: any) => {
        setCurCellRow(row);
        setCurCellCol(column);
        setCurCell(cell);
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setCurCellRow(-1);
        setCurCellCol(-1);
        setCurCell({});
        setIsModalOpen(false);
    };

    const closeHeadForm = () => {
        setCurHeadCol(-1);
        setIsHeadModalOpen(false);
    };

    // useEffect(() => {
    //     if (!disableWrite) setCurrentEditor(`${id || tmpId}_text`);
    // }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const columns = [
        {
            dataIndex: "column",
            key: "column",
            title: "",
            render: (text: any, __: any, index: number) => {
                const cellData =
                    index === data?.length ? null : column_heading[index];

                const cellStyle =
                    cellData?.type === "input"
                        ? { borderBottom: "2px solid #999" }
                        : {};

                const invalidStyle =
                    typeof text === "string" &&
                    text.length < 1 &&
                    cellData?.type === "text"
                        ? { background: "rgba(255,0,0,0.1)" }
                        : {};

                return {
                    props: {
                        style:
                            index === data.length
                                ? {}
                                : {
                                      borderBottom: "1px solid black",
                                      ...invalidStyle,
                                  },
                    },
                    children: (
                        <>
                            {index !== data.length && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <div />

                                    <Button
                                        disabled={disableWrite}
                                        shape="circle"
                                        type="default"
                                        icon={<EditOutlined />}
                                        size="small"
                                        onClick={() =>
                                            showHeadModal(
                                                index,
                                                "column_heading",
                                            )
                                        }
                                    />
                                </div>
                            )}
                            <div
                                style={
                                    index === data.length
                                        ? {}
                                        : {
                                              minHeight: "30px",
                                              ...cellStyle,
                                          }
                                }
                            >
                                {(cellData?.type === "text" ||
                                    !cellData?.type) &&
                                    text}
                                <div
                                    style={{
                                        color: "#999",
                                        textAlign: "center",
                                    }}
                                >
                                    {cellData?.type === "component" && "SVG"}
                                </div>
                            </div>
                        </>
                    ),
                };
            },
        },
        ...row_heading.map((item: any, i: number) => {
            return {
                width: "auto",
                title: () => (
                    <div>
                        <Button
                            disabled={disableWrite}
                            shape="circle"
                            type="default"
                            icon={<EditOutlined />}
                            style={{
                                float: "right",
                                marginLeft: "20px",
                            }}
                            size="small"
                            onClick={() => showHeadModal(i, "row_heading")}
                        />
                        {item?.type === "text" && renderer(item.text)}
                        {item?.type === "component" && "SVG"}
                    </div>
                ),
                dataIndex: i,
                key: i,
                render: (text: any, __: any, index: number) => {
                    const cellData =
                        index === data?.length ? null : data[index][i];

                    const cellStyle =
                        cellData?.type === "input"
                            ? { borderBottom: "2px solid #999" }
                            : {};

                    const invalidStyle =
                        typeof text === "string" &&
                        text.length < 1 &&
                        cellData?.type === "text"
                            ? { background: "rgba(255,0,0,0.1)" }
                            : {};

                    return {
                        props: {
                            style:
                                index === data.length
                                    ? {}
                                    : {
                                          borderLeft: "1px solid black",
                                          borderBottom: "1px solid black",
                                          ...invalidStyle,
                                      },
                        },
                        children: (
                            <>
                                {index !== data.length && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: "14px",
                                                color: "#555",
                                            }}
                                        >
                                            Cell {index + 1} {i + 1}
                                        </div>

                                        <Button
                                            disabled={disableWrite}
                                            shape="circle"
                                            type="default"
                                            icon={<EditOutlined />}
                                            size="small"
                                            onClick={() =>
                                                showModal(index, i, cellData)
                                            }
                                        />
                                    </div>
                                )}
                                <div
                                    style={
                                        index === data.length
                                            ? {}
                                            : {
                                                  minHeight: "30px",
                                                  ...cellStyle,
                                              }
                                    }
                                >
                                    {(cellData?.type === "text" ||
                                        !cellData?.type) &&
                                        text}
                                    <div
                                        style={{
                                            color: "#999",
                                            textAlign: "center",
                                        }}
                                    >
                                        {cellData?.type === "input" &&
                                            "Fill blank"}
                                        {cellData?.type === "component" &&
                                            (cellData?.component?.type ===
                                            "color_block" ? (
                                                <div
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "#444",
                                                        }}
                                                    >
                                                        Color Block
                                                    </div>
                                                    <div>
                                                        Color 1 -{" "}
                                                        {
                                                            cellData?.component
                                                                ?.bg_color
                                                                ?.colorCount1
                                                        }
                                                    </div>
                                                    <div>
                                                        Color 2 -{" "}
                                                        {
                                                            cellData?.component
                                                                ?.bg_color
                                                                ?.colorCount2
                                                        }{" "}
                                                    </div>
                                                </div>
                                            ) : (
                                                <>Component</>
                                            ))}
                                    </div>
                                </div>
                            </>
                        ),
                    };
                },
            };
        }),
        {
            dataIndex: "index",
            key: "index",
            render: (val: number) => {
                return {
                    props: {
                        style:
                            val >= 0
                                ? {
                                      borderLeft: "1px solid black",
                                  }
                                : null,
                    },
                    children:
                        val >= 0 && !disableWrite ? (
                            <Space>
                                <Popconfirm
                                    title={"Delete this row?"}
                                    icon={
                                        <QuestionCircleOutlined
                                            style={{ color: "red" }}
                                        />
                                    }
                                    onConfirm={() => {
                                        let tmpBlock = _.cloneDeep(block);

                                        let tempData =
                                            tmpBlock?.data[V2_TABLE_BLOCK].data;

                                        tempData.splice(val, 1);

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["data", V2_TABLE_BLOCK, "data"],
                                            tempData,
                                        );

                                        tempData =
                                            tmpBlock?.data[V2_TABLE_BLOCK]
                                                .column_heading;

                                        tempData.splice(val, 1);

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "data",
                                                V2_TABLE_BLOCK,
                                                "column_heading",
                                            ],
                                            tempData,
                                        );

                                        setBlock(tmpBlock);
                                    }}
                                    okText={"Yes"}
                                    cancelText={"Cancel"}
                                >
                                    <Button
                                        shape="circle"
                                        danger
                                        icon={
                                            <DeleteOutlined
                                                style={{ color: "red" }}
                                            />
                                        }
                                    />
                                </Popconfirm>

                                <Popconfirm
                                    title={"Add a row below?"}
                                    onConfirm={() => {
                                        let tmpBlock = _.cloneDeep(block);

                                        let tempData =
                                            tmpBlock?.data[V2_TABLE_BLOCK].data;

                                        tempData.splice(
                                            val + 1,
                                            0,
                                            row_heading.map(
                                                () => DEFAULT_V2_CELL,
                                            ),
                                        );

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["data", V2_TABLE_BLOCK, "data"],
                                            tempData,
                                        );

                                        tempData =
                                            tmpBlock?.data[V2_TABLE_BLOCK]
                                                .column_heading;

                                        tempData.splice(val + 1, 0, {
                                            type: "text", // text / component
                                            text: prefillText(`New Row`),
                                            component: {},
                                        });

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "data",
                                                V2_TABLE_BLOCK,
                                                "column_heading",
                                            ],
                                            tempData,
                                        );

                                        setBlock(tmpBlock);
                                    }}
                                    okText={"Yes"}
                                    cancelText={"Cancel"}
                                >
                                    <Button
                                        shape="circle"
                                        type="primary"
                                        icon={<PlusOutlined />}
                                    />
                                </Popconfirm>
                            </Space>
                        ) : null,
                };
            },
        },
    ];

    const actionButtons = () => {
        const cell: any = { key: data?.length + 1 };

        row_heading?.forEach((__: any, i: number) => {
            cell[i] = (
                <Space>
                    <Popconfirm
                        title={"Delete this column?"}
                        icon={
                            <QuestionCircleOutlined style={{ color: "red" }} />
                        }
                        onConfirm={() => {
                            let tmpBlock = _.cloneDeep(block);

                            let tempData =
                                tmpBlock?.data[V2_TABLE_BLOCK].row_heading;

                            tempData.splice(i, 1);

                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", V2_TABLE_BLOCK, "row_heading"],
                                tempData,
                            );

                            tempData = tmpBlock?.data[V2_TABLE_BLOCK].data?.map(
                                (val: any) =>
                                    val.filter(
                                        (_: any, idx: number) => idx !== i,
                                    ),
                            );

                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", V2_TABLE_BLOCK, "data"],
                                tempData,
                            );

                            setBlock(tmpBlock);
                        }}
                        okText={"Yes"}
                        cancelText={"Cancel"}
                    >
                        <Button
                            disabled={disableWrite}
                            shape="circle"
                            danger
                            icon={<DeleteOutlined style={{ color: "red" }} />}
                        />
                    </Popconfirm>

                    <Popconfirm
                        title={"Add a new column?"}
                        onConfirm={() => {
                            let tmpBlock = _.cloneDeep(block);

                            let tempData =
                                tmpBlock?.data[V2_TABLE_BLOCK].row_heading;

                            tempData.splice(i + 1, 0, {
                                type: "text",
                                text: prefillText(`New Heading`),
                            });

                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", V2_TABLE_BLOCK, "row_heading"],
                                tempData,
                            );

                            tempData = tmpBlock?.data[V2_TABLE_BLOCK].data?.map(
                                (val: any) => {
                                    val.splice(i + 1, 0, DEFAULT_V2_CELL);

                                    return val;
                                },
                            );

                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", V2_TABLE_BLOCK, "data"],
                                tempData,
                            );

                            setBlock(tmpBlock);
                        }}
                        okText={"Yes"}
                        cancelText={"Cancel"}
                    >
                        <Button
                            disabled={disableWrite}
                            shape="circle"
                            type="primary"
                            icon={<PlusOutlined />}
                        />
                    </Popconfirm>
                </Space>
            );
        });
        return cell;
    };

    const tableData = [
        ...data.map((item: any, i: number) => {
            const cell: any = { key: i + 1, index: i };

            cell["column"] =
                column_heading[i]?.type === "text" &&
                !isEditorEmpty(column_heading[i]?.text)
                    ? renderer(column_heading[i]?.text)
                    : "";

            item?.forEach((element: any, j: number) => {
                cell[j] =
                    element?.type === "text" && !isEditorEmpty(element?.text)
                        ? renderer(element?.text)
                        : "";
            });
            return cell;
        }),
        ...(!disableWrite ? [actionButtons()] : []),
    ];

    const cellInputProps = {
        isModalOpen,
        closeForm,
        curCellRow,
        curCellCol,
        curCell,
        block,
        setBlock,
        mentionsList,
        hasMentions,
        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        useInputAnswer,
        disableWrite,
        worksheet,
        blockId: id,
        block_tmpId: tmpId,
        onInputVariableUpdate,
    };

    const headInputProps = {
        disableWrite,
        isModalOpen: isHeadModalOpen,
        closeForm: closeHeadForm,
        curCellCol: curHeadCol,
        mentionsList,
        hasMentions,
        block,
        setBlock,
        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        curHeading:
            headType === "row_heading"
                ? row_heading[curHeadCol]
                : column_heading[curHeadCol],
        headType,
    };

    return (
        <div>
            <CellInputPopup {...cellInputProps} />
            <HeadingPopupForm {...headInputProps} />
            <h3
                style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                }}
            >
                Preview Area
            </h3>
            <Row
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                {hasPreviewBlocks && (
                    <PreviewBlocksField
                        disableWrite={disableWrite}
                        fixed_blocks={block?.data?.other?.fixed_blocks || []}
                        updateFixedBlocks={(data: any) => {
                            const obj = {
                                ...block,
                                data: {
                                    ...block.data,
                                    other: {
                                        ...block.data.other,
                                        fixed_blocks: data,
                                    },
                                },
                            };
                            setBlock(obj);
                        }}
                    />
                )}
                <Col span={22}>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Question
                    </h3>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_description`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "other", "description"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={
                            currentEditor === `${id || tmpId}_description`
                        }
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={description}
                        placeholder={"Type your question here..."}
                    />
                </Col>
                <Col span={24}>
                    {showBackgroundAudio &&
                        [
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <h3>Background Audio</h3>
                                <AudioPicker
                                    disabled={isReadOnlyMode}
                                    block={block}
                                    setBlock={setBlock}
                                />
                            </div>
                        )}
                </Col>
            </Row>

            <h3
                style={{
                    fontWeight: "bold",
                    margin: "10px 0",
                }}
            >
                Play Area
            </h3>
            <Row
                justify="space-between"
                gutter={20}
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                <Col span={24}>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={tableData}
                        pagination={false}
                        showHeader={true}
                        scroll={{ x: "max-content" }}
                        footer={() =>
                            data.length > 0 || disableWrite ? null : (
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        let tmpBlock = _.cloneDeep(block);

                                        const tempData =
                                            tmpBlock?.data[V2_TABLE_BLOCK].data;

                                        tempData.push(
                                            row_heading.map(
                                                () => DEFAULT_V2_CELL,
                                            ),
                                        );

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["data", V2_TABLE_BLOCK, "data"],
                                            tempData,
                                        );

                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "data",
                                                V2_TABLE_BLOCK,
                                                "column_heading",
                                            ],
                                            {
                                                type: "text", // text / component
                                                text: prefillText(`Row 1`),
                                                component: {},
                                            },
                                        );

                                        setBlock(tmpBlock);
                                    }}
                                >
                                    Add Row
                                </Button>
                            )
                        }
                    />
                </Col>
                {showDuration &&
                    [
                        "timed",
                        "personalized_learning",
                        "personalized_learning_v2",
                    ].includes(worksheet?.type) && (
                        <div
                            style={{
                                margin: "20px 0",
                            }}
                        >
                            <h3>Duration</h3>
                            <DurationPicker
                                disabled={disableWrite}
                                onChange={onDurationChange}
                                initialValue={block?.data?.other?.duration || 0}
                            />
                        </div>
                    )}
            </Row>
        </div>
    );
};

export default EditorTableV2;
