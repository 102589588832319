import renderBlockV2Avatar from "./renderBlock";
import renderEditorV2Avatar from "./renderEditor";
import isValidCallbackV2Avatar from "./isValid";
import V2_AVATAR_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2Avatar from "./renderSettings";
import renderCardV2Avatar from "./renderCard";
import { BLOCK_TYPES, addCallback } from "../../common/";

const { V2_AVATAR_BLOCK } = BLOCK_TYPES;

const V2_AVATAR_BLOCK_CONFIG = {
    [V2_AVATAR_BLOCK]: {
        label: "Avatar",
        defaultValue: V2_AVATAR_DEFAULT_VALUE,
        renderBlock: renderBlockV2Avatar,
        renderEditor: renderEditorV2Avatar,
        renderCard: renderCardV2Avatar,
        renderSettings: renderSettingsV2Avatar,
        addCallback,
        isValidCallback: isValidCallbackV2Avatar,
    },
};

export {
    renderBlockV2Avatar,
    renderCardV2Avatar,
    renderEditorV2Avatar,
    renderSettingsV2Avatar,
    isValidCallbackV2Avatar,
    V2_AVATAR_DEFAULT_VALUE,
    V2_AVATAR_BLOCK_CONFIG,
};
