import { Route } from "react-router-dom";

import { ChapterList, ChapterCreate, ChapterEdit } from "./views";
import { TopicList, TopicCreate, TopicEdit } from "./views";
import { BookList, BookCreate, BookEdit } from "./views";

export const BOOK_ROUTES = [
    <Route path="/book/chapter/:book_id" element={<ChapterList />} />,
    <Route path="/book/chapter/create/:book_id" element={<ChapterCreate />} />,
    <Route path="/book/chapter/update/:chapter_id" element={<ChapterEdit />} />,
    <Route path="/book/topic/:chapter_id" element={<TopicList />} />,
    <Route path="/book/topic/create/:chapter_id" element={<TopicCreate />} />,
    <Route path="/book/topic/update/:topic_id" element={<TopicEdit />} />,
    <Route path="/book" element={<BookList />} />,
    <Route path="/book/create" element={<BookCreate />} />,
    <Route path="/book/update/:book_id" element={<BookEdit />} />,
];

export { ChapterList, ChapterCreate, ChapterEdit } from "./views";
export { TopicList, TopicCreate, TopicEdit } from "./views";
export { BookList, BookCreate, BookEdit } from "./views";
