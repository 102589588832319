import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Form, Switch } from "antd";
import _ from "lodash";

const renderSettingsEmail = ({ block, setBlock }: any) => {
    const {
        data: { isRequired },
    } = block;

    return (
        <Form.Item label="Required?">
            <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={isRequired}
                onChange={(value) => {
                    let tmpBlock = _.cloneDeep(block);
                    tmpBlock = _.set(tmpBlock, ["data", "isRequired"], value);
                    setBlock(tmpBlock);
                }}
            />
        </Form.Item>
    );
};

export default renderSettingsEmail;
