import { Field, RenderSelect } from "src/components";
import { compose, resetFilterHook } from "src/helpers";
import { withBooks } from "src/modules/book/operations";
import { Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { withStateAndActionsForBook } from "../../resolvers/withStateAndActionsForBook";
import {
    graphQLClient,
    graphQLClientProd,
} from "../../../tab/components/PublishTabToProd";
import { BOOKS_QUERY } from "src/modules/book/graphql";

const Option = Select.Option;

const GroupsFieldComponent = (props) => {
    const {
        // loading,
        // books,
        where,
        pagination,
        onNameChange,
        onChange,
        value,
        onStateReset,
        dropdownOpen,
        env,
    } = props;
    resetFilterHook(onStateReset);

    const [books, setBooks] = useState([]);

    useEffect(() => {
        const asyncFunc = async () => {
            // console.log(where, env);
            if (env == "qa") {
                const tmp = (
                    await graphQLClient.request(BOOKS_QUERY, {
                        where,
                        ...pagination,
                    })
                ).books;
                setBooks(tmp);
            } else {
                setBooks(
                    (
                        await graphQLClientProd.request(BOOKS_QUERY, {
                            where,
                            ...pagination,
                        })
                    ).books,
                );
            }
        };
        asyncFunc();
    }, [where]);

    return (
        <Field
            labelInValue
            showSearch={true}
            filterOption={false}
            onSearch={onNameChange}
            // notFoundContent={loading ? <Spin size="small" /> : null}
            name={"groups"}
            component={RenderSelect}
            placeholder={"Group"}
            value={value}
            // loading={loading}
            onChange={(stuff: any) =>
                onChange({
                    ...stuff,
                    object: books.find(({ id }: any) => id == stuff.value),
                })
            }
            inFilter={true}
            noBotMarging={true}
            // mode={"multiple"}
            open={dropdownOpen}
        >
            {[
                // config.EMPTY_OPTION,
                ...(books?.map((book) => ({
                    id: book.id,
                    label: book.title || "",
                    value: book.id,
                })) || []),
            ]?.map(
                ({
                    id,
                    value,
                    label = "",
                }: {
                    id: string;
                    value: string;
                    label: string;
                }) => (
                    <Option key={id} value={value}>
                        {label}
                    </Option>
                ),
            )}
        </Field>
    );
};

const GroupsField = compose(
    withStateAndActionsForBook,
    // withBooks,
)(GroupsFieldComponent);

const BooksAssignComponent = (props) => {
    const { selected, handleChange, dropdownOpen, parentId, env } = props;

    return (
        // <Card bodyStyle={{ padding: "8px" }}>
        // <Row gutter={[24, 0]} align="middle">
        // {isBook && (
        <GroupsField
            value={selected}
            onChange={handleChange}
            dropdownOpen={dropdownOpen}
            where={{ parent_id: { _eq: parentId } }}
            env={env}
        />
        // )}
        // </Row>
        // </Card>
    );
};

export default BooksAssignComponent;
