import { BLOCK_TYPES } from "../../common";
import { isValidCallbackCrossword } from "../Crossword";

const { SWIPE_AND_FORM_BLOCK } = BLOCK_TYPES;

const isValidCallback = (props: any) =>
    isValidCallbackCrossword({
        ...props,
        blockType: SWIPE_AND_FORM_BLOCK,
    });

export default isValidCallback;
