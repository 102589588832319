import { compose } from "src/helpers";
import {
    withHookForVersionModelMap,
    withHookForWorksheet,
} from "src/modules/worksheet/operations";
import { useEffect, useState } from "react";
import { Component } from "./component";

const WorksheetPreview = (props: any) => {
    const { versionModelMap } = props;

    const [blocks, setBlocks] = useState<any>([]);

    useEffect(() => {
        if (versionModelMap) {
            if (versionModelMap.length) {
                const { data } = versionModelMap[0]?.versions?.[0] || {};
                // do not show inactive blocks;
                if (data)
                    setBlocks(
                        data.filter(
                            (block: any) =>
                                block?.data?.other?.status !== `inactive`,
                        ),
                    );
            }
        }
    }, [versionModelMap]);

    const componentProps = {
        blocks,
    };

    return <Component {...props} {...componentProps} />;
};

export default compose(
    withHookForWorksheet,
    withHookForVersionModelMap,
)(WorksheetPreview);
