import { compose } from "src/helpers";
// import lodash from 'lodash';
// import deepdash from 'deepdash-es';
import {
    withHookForWorksheet,
    withWorksheetBlocks,
} from "src/modules/worksheet/operations";

import { useEffect, useState } from "react";
import { Component } from "../preview/component";

const WorksheetPreview = (props: any) => {
    const { worksheetBlockMap } = props;

    const setParsedBlocks = (blocksArray: any) => {
        if (blocksArray.length) {
            // do not show inactive blocks
            const parsedBlocks = blocksArray
                .filter(
                    ({ block }: any) =>
                        block?.data?.other?.status !== `inactive`,
                )
                .slice()
                .sort(function (a: any, b: any) {
                    return a.order - b.order;
                })
                .map(({ id: worksheet_block_map_id, block }: any) => ({
                    ...block,
                    worksheet_block_map_id,
                }));
            setBlocks(parsedBlocks);
        }
    };

    const [blocks, setBlocks] = useState<any>([]);

    useEffect(() => {
        if (worksheetBlockMap) {
            setParsedBlocks(worksheetBlockMap);
        }
    }, [worksheetBlockMap]);

    const componentProps = {
        blocks,
        isLiveComponent: true,
    };

    return <Component {...props} {...componentProps} />;
};

export default compose(
    withHookForWorksheet,
    withWorksheetBlocks,
)(WorksheetPreview);
