import React, { useState } from "react";
import { Form, Input, Modal, Select, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { BlockPicker } from "react-color";
import { CustomKeyboardEditor, MathRender, SlateEditor } from "src/components";
import { cloneDeep, isString, set } from "lodash";
import CorrectValuesComponent from "../../../../../Figma/renderEditor/ComponentsListEditor/CorrectValuesComponent";

const InputCell = (props: any) => {
    const {
        isModalOpen,
        setModalOpen,
        inputCell,
        handleSubmit,
        currentEditor,
        setCurrentEditor,
        disableWrite,
        hasMentions,
        mentionsList,
    } = props;
    const [currentCell, setCurrentCell] = useState(inputCell);

    return (
        <Modal
            title={"Input"}
            onCancel={() => setModalOpen(false)}
            open={isModalOpen}
            destroyOnClose
            width={600}
            okText="Submit"
            maskClosable={false}
            onOk={() => {
                handleSubmit(currentCell);
                setModalOpen(false);
            }}
        >
            <Form.Item label="Enabled?">
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={currentCell?.enabled}
                    onChange={(value) =>
                        setCurrentCell({
                            ...currentCell,
                            enabled: value,
                        })
                    }
                />
            </Form.Item>
            {currentCell?.enabled && (
                <>
                    <Form.Item label="Fill">
                        <BlockPicker
                            colors={[]}
                            color={currentCell.fill || "#fff"}
                            onChangeComplete={(color: any) =>
                                setCurrentCell({
                                    ...currentCell,
                                    fill: color.hex,
                                })
                            }
                            triangle="hide"
                        />
                    </Form.Item>
                    <Form.Item label="Input Type">
                        <Select
                            value={currentCell?.type}
                            onChange={(value) =>
                                setCurrentCell({
                                    ...currentCell,
                                    type: value,
                                })
                            }
                        >
                            <Select.Option value="text">Text</Select.Option>
                            {/* <Select.Option value="number">Number</Select.Option> */}
                            <Select.Option value="latex">Latex</Select.Option>
                            <Select.Option value="latex-fill-in-blank">
                                Latex Fill In The Blank
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    {currentCell.type === "text" && (
                        <>
                            {/**Default Value field and placeholder field */}
                            <Form.Item label="Default Value">
                                <Input
                                    value={currentCell.default}
                                    placeholder="Default Value"
                                    onChange={(e: any) => {
                                        setCurrentCell({
                                            ...currentCell,
                                            default: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Placeholder">
                                <Input
                                    value={currentCell.placeholder}
                                    placeholder="Placeholder"
                                    onChange={(e: any) => {
                                        setCurrentCell({
                                            ...currentCell,
                                            placeholder: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Item>
                            {/* Switch for single line input */}
                            <Form.Item label="Single Line">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={currentCell.singleLine}
                                    onChange={(value) => {
                                        setCurrentCell({
                                            ...currentCell,
                                            singleLine: value,
                                        });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Dimension Control">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={
                                        currentCell.dimensionControl?.enabled
                                    }
                                    onChange={(value) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(
                                            tmpCell,
                                            ["dimensionControl", "enabled"],
                                            value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                />
                            </Form.Item>
                            {currentCell.dimensionControl?.enabled && (
                                <>
                                    <Form.Item label="Min Width">
                                        <Input
                                            value={
                                                currentCell?.dimensionControl
                                                    ?.minWidth
                                            }
                                            type="number"
                                            placeholder="Min Width"
                                            onChange={(e: any) => {
                                                let tmpCell =
                                                    cloneDeep(currentCell);
                                                tmpCell = set(
                                                    tmpCell,
                                                    [
                                                        "dimensionControl",
                                                        "minWidth",
                                                    ],
                                                    e.target.value,
                                                );
                                                setCurrentCell(tmpCell);
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Min Height">
                                        <Input
                                            value={
                                                currentCell?.dimensionControl
                                                    ?.minHeight
                                            }
                                            type="number"
                                            placeholder="Min Height"
                                            onChange={(e: any) => {
                                                let tmpCell =
                                                    cloneDeep(currentCell);
                                                tmpCell = set(
                                                    tmpCell,
                                                    [
                                                        "dimensionControl",
                                                        "minHeight",
                                                    ],
                                                    e.target.value,
                                                );
                                                setCurrentCell(tmpCell);
                                            }}
                                        />
                                    </Form.Item>
                                </>
                            )}
                            <Form.Item label="Input Keyboard Type?">
                                <Select
                                    disabled={disableWrite}
                                    placeholder="Select"
                                    showSearch
                                    value={currentCell?.inputType || "NUMBER"}
                                    style={{
                                        flex: 0.5,
                                        width: "100%",
                                    }}
                                    onChange={(value) =>
                                        setCurrentCell({
                                            ...currentCell,
                                            inputType: value,
                                        })
                                    }
                                    options={[
                                        {
                                            value: "NORMAL",
                                            label: `Normal`,
                                        },
                                        {
                                            value: "NUMBER",
                                            label: `Number`,
                                        },
                                        {
                                            value: "CUSTOM",
                                            label: `Custom`,
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </>
                    )}
                    {currentCell.type === "latex" && (
                        <>
                            <Form.Item label="Default Value">
                                <SlateEditor
                                    disabled={disableWrite}
                                    hasMentions={hasMentions}
                                    mentionsList={mentionsList}
                                    id={`input-default`}
                                    onChange={(value: any) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(
                                            tmpCell,
                                            ["default"],
                                            value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                    isFocused={
                                        currentEditor === `input-default`
                                    }
                                    setEditor={(id: string) =>
                                        setCurrentEditor(id)
                                    }
                                    value={
                                        isString(currentCell.default || "")
                                            ? []
                                            : currentCell.default ?? []
                                    }
                                    placeholder={"Type here..."}
                                />
                            </Form.Item>
                            <Form.Item label="Placeholder">
                                <SlateEditor
                                    disabled={disableWrite}
                                    hasMentions={hasMentions}
                                    mentionsList={mentionsList}
                                    id={`input-placeholder`}
                                    onChange={(value: any) => {
                                        let tmpCell = cloneDeep(currentCell);
                                        tmpCell = set(
                                            tmpCell,
                                            ["placeholder"],
                                            value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                    isFocused={
                                        currentEditor === `input-placeholder`
                                    }
                                    setEditor={(id: string) =>
                                        setCurrentEditor(id)
                                    }
                                    value={
                                        isString(currentCell.placeholder || "")
                                            ? []
                                            : currentCell.placeholder ?? []
                                    }
                                    placeholder={"Type here..."}
                                />
                            </Form.Item>
                        </>
                    )}
                    {currentCell.type === "latex-fill-in-blank" && (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    flexDirection: "column",
                                }}
                            >
                                <Input.TextArea
                                    style={{
                                        width: "100%",
                                        flexShrink: 0,
                                    }}
                                    rows={5}
                                    value={currentCell.config?.latex ?? ""}
                                    disabled={disableWrite}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        let tmpBlock = cloneDeep(currentCell);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["config", "latex"],
                                            value,
                                        );
                                        setCurrentCell(tmpBlock);
                                    }}
                                />
                                <div
                                    style={{
                                        marginTop: "15px",
                                        padding: "10px",
                                        width: "100%",
                                        border: "1px dashed #aaa",
                                    }}
                                >
                                    <MathRender
                                        src={
                                            currentCell.config?.latex
                                                ? `$${currentCell.config?.latex}$`
                                                : ""
                                        }
                                    />
                                </div>

                                <CorrectValuesComponent
                                    data={{
                                        props: {
                                            default_value: {
                                                value:
                                                    currentCell.config?.latex ??
                                                    "",
                                            },
                                        },
                                    }}
                                    correct_values={{
                                        value:
                                            currentCell.config
                                                ?.correct_values || [],
                                        type: "MATHQUILL_RICH_TEXT",
                                    }}
                                    disableWrite={false}
                                    setCurConfig={(values: any[]) => {
                                        let tmpBlock = cloneDeep(currentCell);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["config", "correct_values"],
                                            values,
                                        );
                                        setCurrentCell(tmpBlock);
                                    }}
                                    hasMentions={hasMentions}
                                    mentionsList={mentionsList}
                                />
                            </div>
                        </>
                    )}
                    <br />
                    {(currentCell.type !== "text" ||
                        !currentCell?.inputType ||
                        currentCell?.inputType === "CUSTOM") && (
                        <>
                            <Form.Item label="Keyboard">
                                <CustomKeyboardEditor
                                    disabled={false}
                                    hasLatex={[
                                        "latex-fill-in-blank",
                                        "latex",
                                    ].includes(currentCell?.type)}
                                    value={currentCell?.keyboard}
                                    onOk={(data: any) =>
                                        setCurrentCell({
                                            ...currentCell,
                                            keyboard: data,
                                        })
                                    }
                                />
                                {!currentCell?.keyboard && (
                                    <div
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        Not added!
                                    </div>
                                )}
                            </Form.Item>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

export default InputCell;
