import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";
import { addCallback } from "../../common";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { getBareBlock } from "../..";
import { Can } from "src/services/casl";

import { DEFAULT_PROGRESSION } from "./defaultValue";

const subCardStyle = { width: "80%", marginLeft: "18%" };

const renderCardGroup = (props: any) => {
    const {
        block,
        setBlock,
        idx,
        setCurrentBlock,
        currentBlock,
        currentSubBlock,
        setCurrentSubBlock,
        setSaveCounter,
        setIsNewBlock,

        cardTitle = "Progression Stage",
    } = props;
    const { children = [] } = block;

    const SortableItemCustom = SortableElement((props: any) => {
        const { sid, child } = props;

        const ribbonColor = "purple";

        const cardProps = {
            ...props,
            child,
            idx: sid,
            type: child.type,
            block: child,
            currentBlock: currentBlock === idx ? currentSubBlock : null,
            currentSubBlock,
            setCurrentBlock: () => {
                setCurrentBlock(idx);
                setCurrentSubBlock(sid);
            },
            deleteBlock: (index: number) => {
                setCurrentSubBlock(null);
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = [...children];
                _.pullAt(tmpChildren, sid);
                tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);
                setBlock(tmpBlock, idx);
            },
            disableDuplicateAction: false,
            ribbonColor,
            ribbonText: null,
            addBlockCustom: (type: string, index: number, newBlock: any) => {
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = [...children];
                const newBlock2 = newBlock
                    ? newBlock
                    : { tmpId: uuid(), ...getBareBlock(type) };
                tmpChildren.splice(index, 0, newBlock2);
                tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);

                setBlock(tmpBlock);
                setCurrentBlock(idx);
                setCurrentSubBlock(index);
            },
            // for progression stage
            updateId: true,
            ignoreId: true,
        };

        return (
            <div
                key={`${idx}_${sid}`}
                style={{ position: "relative", zIndex: 999 }}
            >
                <BlockCard {...cardProps}>
                    <span
                        style={{
                            textTransform: "capitalize",
                        }}
                    >
                        {child?.data?.type}
                    </span>
                </BlockCard>
            </div>
        );
    });

    const SortableContainerCustom: any = SortableContainer(
        ({ children }: any) => {
            return <div>{children}</div>;
        },
    );

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            children,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);
        setBlock(tmpBlock, idx);
    };

    const addOnCLick = () => {
        let tmpBlock = _.cloneDeep(block);
        tmpBlock = _.set(tmpBlock, ["children", children.length], {
            ...DEFAULT_PROGRESSION,
            order: children.length + 1,
            tmpId: uuid(),
        });
        const tmpBlocks = setBlock(tmpBlock, idx);
        setCurrentBlock(idx);
        setCurrentSubBlock(children.length);
        addCallback({
            setSaveCounter,
            currentBlocks: tmpBlocks,
        });
        setIsNewBlock(true);
    };

    return (
        <div style={{ position: "relative", zIndex: 99 }}>
            <BlockCard
                {...props}
                // for progression stage
                updateId={true}
            >
                {cardTitle}
            </BlockCard>
            <div style={subCardStyle}>
                <SortableContainerCustom onSortEnd={onSortEnd} useDragHandle>
                    {children.map((child: any, sid: number) => {
                        return (
                            <SortableItemCustom
                                {...props}
                                sid={sid}
                                key={`nested-item-${child.id || child.tmpId}`}
                                index={sid}
                                child={child}
                            />
                        );
                    })}
                </SortableContainerCustom>
            </div>

            <Can I="edit" a="worksheet_details" passThrough>
                {(allowed: boolean) =>
                    allowed ? (
                        <AddButton
                            blockProps={props}
                            onClick={() => addOnCLick()}
                            btnText={"Variant"}
                        />
                    ) : (
                        <></>
                    )
                }
            </Can>
        </div>
    );
};

const AddButton = ({ blockProps, onClick, btnText }: any) => {
    return (
        <div
            style={subCardStyle}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <BlockCard
                {...blockProps}
                disableActions={true}
                onClick={onClick}
                idx={`+`}
                ribbonColor="green"
            >
                <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Add {btnText}
                </div>
            </BlockCard>
        </div>
    );
};

export default renderCardGroup;
