import { SlateEditor } from "src/components/plate";
import {
    Button,
    Col,
    Form,
    message,
    Modal,
    Row,
    Space,
    Typography,
} from "antd";
import { cloneDeep, set } from "lodash";
import React, { useEffect, useState } from "react";
import { BLOCK_TYPES, isEditorEmpty } from "../../../../common";
import { captureException } from "@sentry/react";

const HeadingPopupForm = (props: any) => {
    const {
        isModalOpen,
        closeForm,
        curCellCol,
        mentionsList,
        hasMentions,
        block,
        setBlock,
        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        curHeading,
    } = props;

    const tmpId = `${curCellCol}_text`;

    const [isSubmitting, setSubmitting] = useState(false);
    const [currentHeading, setCurrentHead] = useState(curHeading?.text);

    const [currentEditor, setCurrentEditor] = useState(tmpId);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [tmpId, currentEditor]);

    useEffect(() => {
        setCurrentHead(curHeading?.text);
    }, [curCellCol]);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            let tmpBlock = cloneDeep(block);

            const tempData = tmpBlock?.data[BLOCK_TYPES.TABLE_BLOCK].heading;

            tempData[curCellCol].text = currentHeading;

            tmpBlock = set(
                tmpBlock,
                ["data", BLOCK_TYPES.TABLE_BLOCK, "heading"],
                tempData,
            );

            setBlock(tmpBlock);

            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e)
            message.success("Could not complete the request!");
        }
        setSubmitting(false);
        closeForm();
    };

    return (
        <>
            <Modal
                title={
                    <Typography.Title level={4}>
                        Update Heading
                    </Typography.Title>
                }
                open={isModalOpen}
                onCancel={closeForm}
                destroyOnClose
                width={720}
                footer={null}
            >
                <Form>
                    <Row gutter={16}>
                        <Col span={22}>
                            <div
                                style={{
                                    marginBottom: "20px",
                                }}
                            >
                                Heading Text:
                            </div>
                            <SlateEditor
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                id={tmpId}
                                onChange={(value: any) => setCurrentHead(value)}
                                isFocused={currentEditor === tmpId}
                                setEditor={(id: string) => setCurrentEditor(id)}
                                value={currentHeading || []}
                                placeholder={"Type the heading here..."}
                            />
                        </Col>

                        <Col span={24}>
                            <Space style={{ float: "right" }}>
                                <Button type="default" onClick={closeForm}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                    disabled={isEditorEmpty(
                                        currentHeading || [],
                                    )}
                                >
                                    Submit
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default HeadingPopupForm;
