import renderBlockV2Content from "./renderBlock";
import renderEditorV2Content from "./renderEditor";
import isValidCallbackV2Content from "./isValid";
import V2_CONTENT_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2Content from "./renderSettings";
import renderCardV2Content from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { V2_CONTENT_BLOCK } = BLOCK_TYPES;

const V2_CONTENT_BLOCK_CONFIG = {
    [V2_CONTENT_BLOCK]: {
        label: "V2 Content",
        defaultValue: V2_CONTENT_DEFAULT_VALUE,
        renderBlock: renderBlockV2Content,
        renderEditor: renderEditorV2Content,
        renderCard: renderCardV2Content,
        renderSettings: renderSettingsV2Content,
        addCallback,
        isValidCallback: isValidCallbackV2Content,
    },
};

export {
    renderBlockV2Content,
    renderCardV2Content,
    renderEditorV2Content,
    renderSettingsV2Content,
    isValidCallbackV2Content,
    V2_CONTENT_DEFAULT_VALUE,
    V2_CONTENT_BLOCK_CONFIG,
};
