function getStyleForShape(shapeType, ellipseSvgPath) {
    switch (shapeType) {
        case "Circle":
            return {
                clipPath: "circle(50% at 50% 50%)",
            };
        case "Ellipse":
            return {
                clipPath: `ellipse(50% 50% at 50% 50%)`,
            };

        case "Rectangle":
            return {
                clipPath: `polygon(${ellipseSvgPath})`,
            };

        default:
            return {
                clipPath: `polygon(${ellipseSvgPath})`,
            };
    }
}

export default getStyleForShape;
