import React, { useState, useEffect } from "react";
import { message, Button, Col, Row, Form, Select, Switch, Input } from "antd";
import _ from "lodash";

import { SlateEditor } from "src/components/plate";
import { getOptionIndex } from "src/helpers/functions";
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { AudioPicker, DurationPicker } from "../../common/index";
import VideoField from "../../common/VideoField";

const { Option } = Select;

const DragHandle = SortableHandle(() => <MenuOutlined />);
const renderEditorMCQ = (
    {
        block,
        setBlock,
        isEditor,
        setIsEditor,
        currentBlock,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        showDifficultyLevel = false,
        mentionsList = [],
        hasMentions,
        toggleShouldBlockUI,
        isReadOnlyMode,
    }: any,
    isMCQ = true,
) => {
    const {
        id,
        tmpId,
        data: {
            mcq: { text, options, correct_options, solution },
        },
    } = block;
    const [reOrder, setReOrder] = useState(false);

    const [currentEditor, setCurrentEditor] = useState(`${id || tmpId}_text`);
    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_text`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    const SortableItemCustom = SortableElement((props: any) => {
        const { option, idx } = props;
        return (
            <Row align={"middle"}>
                <Col span={1}>
                    <DragHandle />
                </Col>
                <Col span={23}>{renderOption(option, idx, false)}</Col>
            </Row>
        );
    });

    const SortableContainerCustom = SortableContainer(({ children }: any) => (
        <div>{children}</div>
    ));
    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        const newOptions = arrayMoveImmutable(
            options,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        let tmpBlock = _.cloneDeep(block);
        tmpBlock = _.set(tmpBlock, ["data", "mcq", "options"], newOptions);
        // setIsNewBlock(true);
        setBlock(tmpBlock);
    };

    const renderOption = (option: any, idx: number, isMCQ = false) => (
        <Row
            key={idx}
            justify="start"
            style={{
                border: "1px solid #ECECEC",
                borderRadius: "15px",
                marginBottom: "10px",
            }}
        >
            <Col
                span={1}
                style={{
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        padding: "10px 0px",
                    }}
                >
                    <div
                        style={{
                            borderRadius: "50%",
                            width: "24px",
                            height: "24px",
                            justifyContent: "center",
                            alignItems: "center",
                            background:
                                correct_options.length &&
                                isMCQ &&
                                correct_options?.includes(idx + 1)
                                    ? "#4EDEB2"
                                    : "lightgray",
                            color: "#333333",
                            display: "inline-flex",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        {getOptionIndex(idx)}.
                    </div>
                </div>
            </Col>
            <Col
                span={21}
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div style={{ width: "100%" }}>
                    <SlateEditor
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_option_${idx}`}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "mcq", "options", idx],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        value={option}
                        isFocused={
                            currentEditor == `${id || tmpId}_option_${idx}`
                        }
                        setEditor={(id: string) => {
                            if (isMCQ || !reOrder) setCurrentEditor(id);
                        }}
                        isOption={true}
                    />
                </div>
            </Col>
            {currentEditor == `${id || tmpId}_option_${idx}` && (
                <Col span={2} style={{ paddingTop: "10px" }}>
                    <Button
                        icon={<DeleteOutlined />}
                        type="primary"
                        shape="circle"
                        danger
                        size="small"
                        onClick={() => {
                            if (options.length == 1) {
                                message.warn(`Need at least 1 option`);
                                return;
                            }

                            let tmpBlock = _.cloneDeep(block);
                            const tmpOptions = [...options];
                            _.pullAt(tmpOptions, idx);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "mcq", "options"],
                                tmpOptions,
                            );

                            if (
                                isMCQ &&
                                correct_options[0] &&
                                idx < correct_options[0]
                            )
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "mcq", "correct_options"],
                                    [],
                                );

                            if (!isMCQ && Boolean(correct_options[idx]))
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "mcq", "correct_options"],
                                    [],
                                );

                            setBlock(tmpBlock);
                        }}
                    />
                </Col>
            )}
        </Row>
    );

    const renderCorrectOption = () => (
        <>
            <Row>
                <h3 style={{ padding: "0px 24px", fontWeight: "bold" }}>
                    Correct Option
                </h3>
            </Row>
            <Row
                style={{ padding: "0px 24px" }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    validateStatus={!correct_options[0] ? "error" : ""}
                    help={!correct_options[0] ? "Field cannot be empty" : ""}
                >
                    <Select
                        value={correct_options}
                        style={{ width: 120 }}
                        onChange={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["data", "mcq", "correct_options"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        mode={`multiple`}
                    >
                        {options.map((option: any, idx: number) => {
                            return (
                                <Option value={idx + 1} key={idx}>
                                    {idx + 1}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Row>
        </>
    );

    const renderCorrectOrder = () => (
        <Row>
            <Col span={24}>
                <h3
                    style={{
                        padding: "0px 24px",
                        fontWeight: "bold",
                    }}
                >
                    Correct Order of Options
                </h3>
            </Col>
            {options.map((option, idx: number) => {
                return (
                    <Col span={24}>
                        <Row
                            style={{ padding: "0px 24px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                validateStatus={
                                    getValidateStatus(correct_options, idx)
                                        .error
                                }
                                help={
                                    getValidateStatus(correct_options, idx).msg
                                }
                            >
                                <Select
                                    value={correct_options[idx] || null}
                                    style={{ width: 120 }}
                                    onChange={(value: any) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "data",
                                                "mcq",
                                                "correct_options",
                                                idx,
                                            ],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                >
                                    {options.map((option: any, idx: number) => {
                                        return (
                                            <Option value={idx + 1} key={idx}>
                                                {String.fromCharCode(
                                                    97 + idx,
                                                ).toUpperCase()}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Row>
                    </Col>
                );
            })}
        </Row>
    );

    return (
        <>
            <div>
                <h3
                    style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                    }}
                >
                    Preview Area
                </h3>
                <Row
                    style={{
                        width: "100%",
                        border: "1px dashed #808080",
                        margin: "10px auto",
                        padding: "20px",
                    }}
                >
                    <Col span={22}>
                        <SlateEditor
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`${id || tmpId}_text`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "mcq", "text"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            value={text}
                            isFocused={currentEditor == `${id || tmpId}_text`}
                            setEditor={(id: string) => setCurrentEditor(id)}
                        />
                    </Col>

                    <Col span={24}>
                        {[
                            "personalized_learning",
                            "personalized_learning_v2",
                        ].includes(worksheet?.type) && (
                            <div
                                style={{
                                    margin: "20px auto",
                                }}
                            >
                                <h3>Background Audio</h3>
                                <AudioPicker
                                    disabled={isReadOnlyMode}
                                    block={block}
                                    setBlock={setBlock}
                                />
                            </div>
                        )}
                    </Col>
                </Row>

                <h3
                    style={{
                        fontWeight: "bold",
                        margin: "10px 0",
                    }}
                >
                    Play Area
                </h3>
                <div
                    style={{
                        width: "100%",
                        border: "1px dashed #808080",
                        margin: "10px auto",
                        padding: "20px",
                    }}
                >
                    <Row justify="space-between">
                        <Col>
                            <h3
                                style={{
                                    padding: "0px 24px",
                                    fontWeight: "bold",
                                }}
                            >
                                Options
                            </h3>
                        </Col>
                        <Col>
                            {!isMCQ && (
                                <Form.Item label="Enable Re-order">
                                    <Switch
                                        checked={reOrder}
                                        onChange={setReOrder}
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <div>
                        {isMCQ ? (
                            options.map((option: any, idx: number) =>
                                renderOption(option, idx, true),
                            )
                        ) : reOrder ? (
                            <SortableContainerCustom
                                onSortEnd={onSortEnd}
                                useDragHandle
                            >
                                {options.map((option: any, idx: number) => (
                                    <SortableItemCustom
                                        {...{ option }}
                                        idx={idx}
                                        key={`item-${id}-${idx}`}
                                        index={idx}
                                    />
                                ))}
                            </SortableContainerCustom>
                        ) : (
                            options.map((option: any, idx: number) =>
                                renderOption(option, idx, false),
                            )
                        )}
                        <Button
                            type="dashed"
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "mcq", "options", options.length],
                                    [],
                                );
                                setIsNewBlock(true);
                                setBlock(tmpBlock);
                                setCurrentEditor(
                                    `${id || tmpId}_option_${options.length}`,
                                );
                            }}
                            style={{ margin: "0px 24px" }}
                        >
                            Add Option
                        </Button>
                    </div>
                    <br />

                    <Row>
                        <h3 style={{ padding: "0px 24px", fontWeight: "bold" }}>
                            Solution
                        </h3>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col span={22}>
                            <SlateEditor
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                id={`${id || tmpId}_solution`}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "mcq", "solution"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                value={solution}
                                isFocused={
                                    currentEditor == `${id || tmpId}_solution`
                                }
                                setEditor={(id: string) => setCurrentEditor(id)}
                                required={false}
                            />
                        </Col>
                    </Row>

                    {worksheet?.type === "quiz_form" ? (
                        <></>
                    ) : isMCQ ? (
                        renderCorrectOption()
                    ) : (
                        renderCorrectOrder()
                    )}

                    {showDifficultyLevel && (
                        <div>
                            <h3
                                style={{
                                    padding: "0px 24px",
                                    fontWeight: "bold",
                                }}
                            >
                                Difficulty Level
                            </h3>

                            <Select
                                value={block?.data?.other?.difficulty_level}
                                style={{ width: 120 }}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "other", "difficulty_level"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            >
                                <Option value={`easy`} key={`easy`}>
                                    Easy
                                </Option>
                                <Option value={`medium`} key={`medium`}>
                                    Medium
                                </Option>{" "}
                                <Option value={`hard`} key={`hard`}>
                                    Hard
                                </Option>
                            </Select>
                        </div>
                    )}

                    <div>
                        <VideoField
                            label={`Solution Video URL`}
                            path={["data", "other", "solution_video"]}
                            block={block}
                            setBlock={setBlock}
                            isYoutube={true}
                            toggleShouldBlockUI={toggleShouldBlockUI}
                        />
                    </div>

                    {[
                        "timed",
                        "personalized_learning",
                        "personalized_learning_v2",
                    ].includes(worksheet?.type) && (
                        <div
                            style={{
                                margin: "20px auto",
                            }}
                        >
                            <h3>Duration</h3>
                            <DurationPicker
                                onChange={onDurationChange}
                                initialValue={block?.data?.other?.duration || 0}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default renderEditorMCQ;

export function getValidateStatus(correct_options: number[], idx: number): any {
    let error: "" | "error" = "";
    let msg = "";
    if (!correct_options[idx]) {
        error = "error";
        msg = "Field cannot be empty";
    }

    const isUnique = correct_options.length === new Set(correct_options).size;
    if (!isUnique) {
        const uniq = correct_options
            .map((name: number) => ({
                count: 1,
                name: name,
            }))
            .reduce((result, b) => {
                result[b.name] = (result[b.name] || 0) + b.count;
                return result;
            }, {});

        if (
            Object.keys(uniq)
                .filter((a) => uniq[a] > 1)
                .includes(String(_.get(correct_options, idx)))
        ) {
            error = "error";
            msg = "Option selected multiple times";
        }
    }

    return { error, msg };
}
