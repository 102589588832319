import renderBlockV2Story from "./renderBlock";
import renderEditorV2Story from "./renderEditor";
import isValidCallbackV2Story from "./isValid";
import V2_STORY_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2Story from "./renderSettings";
import renderCardV2Story from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { V2_STORY_BLOCK } = BLOCK_TYPES;

const V2_STORY_BLOCK_CONFIG = {
    [V2_STORY_BLOCK]: {
        label: "V2 Story",
        defaultValue: V2_STORY_DEFAULT_VALUE,
        renderBlock: renderBlockV2Story,
        renderEditor: renderEditorV2Story,
        renderCard: renderCardV2Story,
        renderSettings: renderSettingsV2Story,
        addCallback,
        isValidCallback: isValidCallbackV2Story,
    },
};

export {
    renderBlockV2Story,
    renderCardV2Story,
    renderEditorV2Story,
    renderSettingsV2Story,
    isValidCallbackV2Story,
    V2_STORY_DEFAULT_VALUE,
    V2_STORY_BLOCK_CONFIG,
};
