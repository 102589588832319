import { BLOCK_TYPES } from "../../common";
import { isValidCallbackDragArrangeList } from "../DragArrangeList";

const { INPUT_AND_FORM_BLOCK } = BLOCK_TYPES;

const isValidCallback = (props: any) =>
    isValidCallbackDragArrangeList({
        ...props,
        blockType: INPUT_AND_FORM_BLOCK,
    });

export default isValidCallback;
