import React, { useEffect, useState } from "react";
import { Link } from "src/components";
import { Table, Button, Col, Divider, Row, Space, Spin } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";

import DeleteView from "src/modules/plan/components/DeleteView";
import { EditIcon, RenderTable, Layout, MetaTag } from "src/components";
import { MenuOutlined } from "@ant-design/icons";

import { FilterComponent } from "src/modules/plan/components";

import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import order from "pages/order";

const ListView: React.FC = (props: any) => {
    const { loading, plans, planAggregate, loadPlansData, updatePlan } = props;
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        if (plans.length) {
            // console.log(topics.reduce((acc, cur) => acc+cur.collections.length, 0));
            // setTotalSetCount(topics.reduce((acc, cur) => acc+cur.collections.length, 0)) ;
            setDataSource(
                plans
                    .map((t, index) => {
                        return {
                            ...t,
                            index,
                            order: t.order || -1,
                        };
                    })
                    .sort((a, b) => {
                        if (a.status === b.status) return a.order - b.order;
                        else return a.status > b.status ? 1 : -1;
                    }),
            );
        }
    }, [plans]);

    const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
    ));

    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(
                [].concat(dataSource),
                oldIndex,
                newIndex,
            ).filter((el) => !!el);

            newData.map((d, index) => {
                if (d.order !== index + 1) {
                    console.log("Updating order: ", d.id, index + 1);
                    updatePlan({ id: d.id, order: index + 1 });

                    newData[index].order = index;
                }
            });

            setDataSource(newData);
        }
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helper="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(
            (x) => x.index === restProps["data-row-key"],
        );
        return <SortableItem index={index} {...restProps} />;
    };

    const columns = [
        {
            title: "Sort",
            dataIndex: "sort",
            width: 30,
            className: "drag-visible",
            render: () => <DragHandle />,
        },
        {
            title: "Id",
            key: "id",
            render: (text, record) => record?.id,
        },
        {
            title: "Title",
            key: "title",
            render: (text, record) => record?.title,
        },
        {
            title: "Type",
            key: "type",
            render: (text, record) => record?.type,
        },
        {
            title: "Credits",
            key: "credits",
            render: (text, record) => record?.credits,
        },

        {
            title: "Slug",
            key: "slug",
            render: (text, record) => record?.slug,
        },
        {
            title: "Status",
            key: "status",
            render: (text, record) => record?.status,
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            render: (text, record) => (
                <Space size="middle">
                    <Link href={`/plan/update/${record?.id}`}>
                        <EditIcon isButton={true} />
                    </Link>
                    <Divider />
                    <DeleteView id={record?.id} isButton={true} />
                </Space>
            ),
        },
    ];

    return (
        <Layout titles={[{ name: "Plans", link: "/plan" }]}>
            <MetaTag title="Plans" />
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Plans List</h1>
                </Col>
                <Col>
                    <Link href={"/plan/create"}>
                        <Button type="primary" icon={<AppstoreAddOutlined />}>
                            Add
                        </Button>
                    </Link>
                </Col>
            </Row>
            <hr />

            {/* <FilterComponent isPlan={true} {...props} /> */}
            {/* <hr /> */}

            <Spin spinning={loading}>
                {Boolean(plans?.length) && (
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={dataSource || []}
                        rowKey="index"
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                    />
                )}
            </Spin>
        </Layout>
    );
};

export default ListView;
