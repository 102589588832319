import { Card } from "antd";
const renderBlock = (block: any) => {
    return (
        <div>
            <Card>V2 Table</Card>
        </div>
    );
};

export default renderBlock;
