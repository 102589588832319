import { Modal, Typography } from "antd";
import React from "react";
import CellForm from "./CellForm";

const CellInputPopup = (props: any) => {
    const { isModalOpen, closeForm } = props;

    return (
        <>
            <Modal
                title={
                    <Typography.Title level={4}>Update Cell</Typography.Title>
                }
                open={isModalOpen}
                onOk={closeForm}
                onCancel={closeForm}
                destroyOnClose
                width={720}
                footer={null}
            >
                <CellForm {...props} closeForm={closeForm} />
            </Modal>
        </>
    );
};

export default CellInputPopup;
