import { BLOCK_TYPES } from "../../common";
import { renderEditorCrossword } from "../Crossword";

const { DRAG_AND_SWIPE_BLOCK } = BLOCK_TYPES;

const renderEditor = (props: any) =>
    renderEditorCrossword({
        ...props,
        blockType: DRAG_AND_SWIPE_BLOCK,
    });

export default renderEditor;
