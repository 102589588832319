import { v4 as uuid } from "uuid";
import { DEFAULT_CUSTOM_KEYBOARD } from "src/components/CustomKeyboardEditor";

export const addNewStory = ({
    order,
    audio_url,
    audio_text,
    avatar_url,
    background_url,
    btn_name_1,
    btn_name_2,
    btn_name_3,
    btn_text_1,
    btn_text_2,
    btn_text_3,
    input_name,
    name,
    text,
    logic,
    template,
}: any) => {
    const storyOther = {
        duration: template || input_name || btn_name_1 || btn_name_2 ? -1 : 0,
        audio: audio_url
            ? {
                  other: {},
                  audio_texts: [
                      {
                          text: audio_text,
                          language: "Hindi",
                          artist: {
                              name: "Madhu_v2",
                              other: {
                                  voice_id: "XeXqtVbGZphseIE5t8Ga",
                              },
                          },
                          audio_urls: [
                              {
                                  other: {},
                                  url: audio_url,
                                  is_active: true,
                                  client: "",
                              },
                          ],
                      },
                  ],
                  display_text: "",
              }
            : { other: {}, audio_texts: [], display_text: "" },
    };

    const storyData = {
        name,
        logic: {
            compute_functions: [
                {
                    name: "getNextStory",
                    label: "Get Next Story",
                    output: logic?.trim()?.length
                        ? logic
                        : "function getNextStory(history, inputs, global_context_variables) {\n  return 'next';\n}",
                    params: [],
                    isGlobal: true,
                },
            ],
        },
        is_skip:
            template || input_name || btn_name_1 || btn_name_2 ? false : true,
        background: background_url
            ? {
                  default: "",
                  value: background_url,
                  loop: false,
                  type: "VIDEO",
              }
            : { type: "", value: "", default: "", loop: false },
    };

    const storyExtra = {
        tmpId: uuid(),
        id: uuid(),
        type: "v2_story",
        order: order,
    };

    switch (template) {
        case "template_1":
            return {
                data: {
                    other: storyOther,
                    children: [
                        {
                            data: {
                                v2_avatar: {
                                    type: "heygen_avatar",
                                    value: "https://images1.wionews.com/images/wion/900x1600/2023/6/17/1687029535624_luffyonepiece.webp",
                                    heygen: {
                                        video: avatar_url,
                                    },
                                },
                            },
                            type: "v2_avatar",
                            order: 0,
                            other: {
                                size: { width: 94, height: 94 },
                                position: { top: 28, left: 19 },
                            },
                            tmpId: uuid(),
                        },
                        {
                            data: {
                                name: btn_name_1,
                                button: {
                                    text: btn_text_1,
                                    color: "#333333",
                                    action: { type: "internal", value: "" },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#FFDE49",
                                },
                            },
                            type: "button",
                            order: 1,
                            other: {
                                size: { width: 194, height: 84 },
                                position: { top: 649, left: 0 },
                            },
                            tmpId: uuid(),
                        },
                        {
                            data: {
                                name: btn_name_2,
                                button: {
                                    text: btn_text_2,
                                    color: "#333333",
                                    action: { type: "internal", value: "" },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#FFDE49",
                                },
                            },
                            type: "button",
                            order: 2,
                            other: {
                                size: { width: 215, height: 84 },
                                position: { top: 648, left: 197 },
                            },
                            tmpId: uuid(),
                        },
                    ],
                    v2_story: storyData,
                },
                ...storyExtra,
            };
        case "template_2":
            return {
                data: {
                    other: storyOther,
                    children: [
                        {
                            data: {
                                v2_avatar: {
                                    type: "heygen_avatar",
                                    value: "https://images1.wionews.com/images/wion/900x1600/2023/6/17/1687029535624_luffyonepiece.webp",
                                    heygen: {
                                        video: avatar_url,
                                    },
                                },
                            },
                            type: "v2_avatar",
                            order: 0,
                            other: {
                                size: { width: 94, height: 94 },
                                position: { top: 28, left: 19 },
                            },
                            tmpId: uuid(),
                        },
                        {
                            data: {
                                name: btn_name_1,
                                button: {
                                    text: btn_text_1,
                                    color: "#333333",
                                    action: { type: "internal", value: "" },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#FFDE49",
                                },
                            },
                            type: "button",
                            order: 1,
                            other: {
                                size: { width: 413, height: 84 },
                                position: { top: 578, left: -2 },
                            },
                            tmpId: uuid(),
                        },
                        {
                            data: {
                                name: btn_name_2,
                                button: {
                                    text: btn_text_2,
                                    color: "#333333",
                                    action: { type: "internal", value: "" },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#FFDE49",
                                },
                            },
                            type: "button",
                            order: 2,
                            other: {
                                size: { width: 413, height: 84 },
                                position: { top: 648, left: -1 },
                            },
                            tmpId: uuid(),
                        },
                    ],
                    v2_story: storyData,
                },
                ...storyExtra,
            };
        case "template_3":
            return {
                data: {
                    other: storyOther,
                    children: [
                        {
                            data: {
                                v2_avatar: {
                                    type: "heygen_avatar",
                                    value: "https://images1.wionews.com/images/wion/900x1600/2023/6/17/1687029535624_luffyonepiece.webp",
                                    heygen: {
                                        video: avatar_url,
                                    },
                                },
                            },
                            type: "v2_avatar",
                            order: 0,
                            other: {
                                size: { width: 94, height: 94 },
                                position: { top: 28, left: 19 },
                            },
                            tmpId: uuid(),
                        },
                        {
                            data: {
                                name: btn_name_2,
                                button: {
                                    text: btn_text_2,
                                    color: "#333333",
                                    action: { type: "internal", value: "" },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#FFDE49",
                                },
                            },
                            type: "button",
                            order: 1,
                            other: {
                                size: { width: 192, height: 84 },
                                position: { top: 648, left: 0 },
                            },
                            tmpId: uuid(),
                        },
                        {
                            data: {
                                name: btn_name_3,
                                button: {
                                    text: btn_text_3,
                                    color: "#333333",
                                    action: { type: "internal", value: "" },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#FFDE49",
                                },
                            },
                            type: "button",
                            order: 2,
                            other: {
                                size: { width: 203, height: 84 },
                                position: { top: 648, left: 209 },
                            },
                            tmpId: uuid(),
                        },
                        {
                            data: {
                                name: btn_name_1,
                                button: {
                                    text: btn_text_1,
                                    color: "#333333",
                                    action: { type: "internal", value: "" },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#FFDE49",
                                },
                            },
                            type: "button",
                            order: 3,
                            other: {
                                size: { width: 186, height: 84 },
                                position: { top: 578, left: 108 },
                            },
                            tmpId: uuid(),
                        },
                    ],
                    v2_story: storyData,
                },
                ...storyExtra,
            };

        default:
            return {
                data: {
                    other: storyOther,
                    v2_story: storyData,
                    children: [
                        ...(avatar_url
                            ? [
                                  {
                                      other: {
                                          size: { height: 107, width: 107 },
                                          position: { left: 151, top: 174 },
                                      },
                                      data: {
                                          v2_avatar: {
                                              value: "https://images1.wionews.com/images/wion/900x1600/2023/6/17/1687029535624_luffyonepiece.webp",
                                              heygen: {
                                                  video: avatar_url,
                                              },
                                              type: "heygen_avatar",
                                          },
                                      },
                                      tmpId: uuid(),
                                      type: "v2_avatar",
                                      order: 0,
                                  },
                              ]
                            : []),
                        ...(btn_name_1 || (!btn_name_1 && input_name)
                            ? [
                                  {
                                      type: "button",
                                      data: {
                                          button: {
                                              text: btn_text_1 || "Submit",
                                              color: "#333333",
                                              font_size: "18",
                                              font_weight: "600",
                                              background_color: "#FFDE49",
                                              action: {
                                                  type: "internal",
                                                  value: "",
                                              },
                                          },
                                          name: btn_name_1 || "btn",
                                      },
                                      tmpId: uuid(),
                                      order: 1,
                                      other: {
                                          position: { top: 648, left: 0 },
                                          size: { height: 84, width: 412 },
                                      },
                                  },
                              ]
                            : []),
                        ...(input_name
                            ? [
                                  {
                                      type: "input",
                                      data: {
                                          name: input_name,
                                          value: "",
                                          input: {
                                              default_value: "",
                                              placeholder: "Input",
                                              custom_keyboard:
                                                  DEFAULT_CUSTOM_KEYBOARD,
                                          },
                                      },
                                      tmpId: uuid(),
                                      order: 2,
                                      other: {
                                          position: { top: 455, left: 89 },
                                          size: { width: 231, height: 56 },
                                      },
                                  },
                              ]
                            : []),
                        ...(btn_name_2
                            ? [
                                  {
                                      type: "button",
                                      data: {
                                          button: {
                                              text: btn_text_2,
                                              color: "#333333",
                                              font_size: "18",
                                              font_weight: "600",
                                              background_color: "#FFDE49",
                                              action: {
                                                  type: "internal",
                                                  value: "",
                                              },
                                          },
                                          name: btn_name_2,
                                      },
                                      tmpId: uuid(),
                                      order: 3,
                                      other: {
                                          position: { top: 570, left: -1 },
                                          size: { height: 84, width: 412 },
                                      },
                                  },
                              ]
                            : []),
                        ...(text
                            ? [
                                  {
                                      type: "v2_rich_text",
                                      data: {
                                          v2_rich_text: text
                                              ?.split("\\n")
                                              ?.map((v) => ({
                                                  type: "p",
                                                  children: [{ text: v }],
                                              })),
                                          textAlign: "left",
                                      },
                                      other: {
                                          position: { top: 322, left: 57 },
                                          size: { height: 100, width: 300 },
                                      },
                                      tmpId: uuid(),
                                      order: 4,
                                  },
                              ]
                            : []),
                    ],
                },
                ...storyExtra,
            };
    }
};
