import {
    WorksheetList,
    WorksheetCreateEnhanced,
    WorksheetEditEnhanced,
    WorksheetPreview,
    WorksheetLive,
    WorksheetBlockSearch,
    WorksheetEdit,
    PushToProd,
} from "./views";

import { Route } from "react-router-dom";
export const WORKSHEET_ROUTES = [
    <Route path="/worksheet/:group_id" element={<WorksheetList />} />,
    <Route
        path="/worksheet/create_enhanced/:group_id"
        element={<WorksheetCreateEnhanced />}
    />,
    <Route
        path="/worksheet/update_enhanced/:worksheet_id"
        element={<WorksheetEditEnhanced />}
    />,
    <Route
        path="/worksheet/preview/:worksheet_id"
        element={<WorksheetPreview />}
    />,
    <Route path="/worksheet/block/:worksheet_id" element={<WorksheetLive />} />,
    <Route path="/worksheet/block/search" element={<WorksheetBlockSearch />} />,
    <Route
        path="/worksheet/update/:worksheet_id"
        element={<WorksheetEdit />}
    />,
    <Route path="/worksheet/addToProd" element={<PushToProd />} />,
];

export {
    WorksheetList,
    WorksheetCreateEnhanced,
    WorksheetEditEnhanced,
    WorksheetPreview,
    WorksheetLive,
    WorksheetBlockSearch,
} from "./views";
