import { gql } from "@apollo/client";

export default gql`
    mutation insertUser($objects: [personalized_learning_user_insert_input!]!) {
        insert_personalized_learning_user(objects: $objects) {
            returning {
             id
            }
        }
    }
`;
