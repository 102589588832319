import { SlateEditor } from "src/components/plate";
import { Button, Form, Input, Select, Space, Switch } from "antd";
import _ from "lodash";
import { useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { CustomKeyboardEditor, MathRender, RenderUpload } from "src/components";
import { DEFAULT_CUSTOM_KEYBOARD } from "src/components/CustomKeyboardEditor";
import { AudioGenerateComponent } from "src/modules/worksheet/components/WorksheetEditor/components/Modals/AudioGenerateComponent";
import { replaceCDNUrl } from "src/modules/worksheet/components/blocks/helpers";
import { AudioPoolModal } from "../../../../Figma/renderEditor/AudioPoolModal";
import ComponentsListEditor from "../../../../Figma/renderEditor/ComponentsListEditor";
import CorrectValuesComponent from "../../../../Figma/renderEditor/ComponentsListEditor/CorrectValuesComponent";
import { BlockPicker } from "react-color";

export const DEFAULT_TABLE = {
    cells: [],
    gap: {
        horizontal: 0,
        vertical: 0,
    },
};

const VariablesForm = ({
    setBlock,
    isEditor,
    setIsEditor,
    setIsNewBlock,
    isNewBlock,
    hasMentions,
    mentionsList = [],
    disableWrite,
    isReadOnlyMode,
    currentEditor,
    setCurrentEditor,
    variables = [],
    renderLinkSelect,
}: any) => {
    const [openAudioModal, setOpenAudioModal] = useState("");
    return (
        <>
            {variables?.length ? (
                variables.map(
                    (
                        v: {
                            name: string;
                            label: string;
                            objectType: string;
                            value: any;
                            schema: any;
                            options: any;
                            linked_global_context_variable: any;
                            last_value: any;
                        },
                        i: number,
                    ) => (
                        <Form.Item
                            label={v.label || v.name}
                            key={i}
                            {...([
                                "RICH_TEXT",
                                "LATEX",
                                "TEXTAREA",
                                "FILL",
                                "DROPDOWN",
                                "TABLE_DATA",
                                "DROPDOWN_RICH_TEXT_OPTIONS",
                                "MATHQUILL_RICH_TEXT",
                            ].includes(v?.objectType)
                                ? { labelCol: { span: 24 } }
                                : {})}
                            validateStatus={!v?.value ? "error" : ""}
                            help={
                                !v?.value ? "Field cannot be empty or zero" : ""
                            }
                        >
                            {!v?.linked_global_context_variable?.name ? (
                                (() => {
                                    switch (v?.objectType) {
                                        case "OPTIONS":
                                            return (
                                                <Select
                                                    disabled={disableWrite}
                                                    defaultValue={v?.value}
                                                    placeholder="Select"
                                                    onChange={(value: any) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                variables,
                                                            );
                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [i, "value"],
                                                            value,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                    options={v?.options}
                                                />
                                            );

                                        case "BOOLEAN":
                                            return (
                                                <Switch
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={Boolean(v.value)}
                                                    onChange={(value) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                variables,
                                                            );
                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [i, "value"],
                                                            value,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            );
                                        case "STRING":
                                        case "NUMBER":
                                        case "COLOR":
                                            return (
                                                <Input
                                                    type={v?.objectType?.toLowerCase()}
                                                    value={v.value}
                                                    disabled={disableWrite}
                                                    onChange={(e) => {
                                                        const value =
                                                            e.target.value;
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                variables,
                                                            );

                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [i, "value"],
                                                            value,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            );
                                        case "TEXTAREA":
                                        case "JSONSTRING":
                                            return (
                                                <Input.TextArea
                                                    rows={5}
                                                    value={v.value}
                                                    disabled={disableWrite}
                                                    onChange={(e) => {
                                                        const value =
                                                            e.target.value;
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                variables,
                                                            );

                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [i, "value"],
                                                            value,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            );
                                        case "CUSTOM_KEYBOARD":
                                        case "RICH_TEXT_CUSTOM_KEYBOARD":
                                            return (
                                                <>
                                                    <CustomKeyboardEditor
                                                        disabled={disableWrite}
                                                        hasLatex={
                                                            v?.objectType ===
                                                            "RICH_TEXT_CUSTOM_KEYBOARD"
                                                        }
                                                        value={
                                                            _.isArray(v.value)
                                                                ? v.value
                                                                : DEFAULT_CUSTOM_KEYBOARD
                                                        }
                                                        onOk={(data: any) => {
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    variables,
                                                                );
                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [i, "value"],
                                                                data,
                                                            );
                                                            setBlock(tmpBlock);
                                                        }}
                                                    />
                                                    {!v?.value?.length && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Not added!
                                                        </div>
                                                    )}
                                                </>
                                            );
                                        case "LATEX":
                                            return (
                                                <>
                                                    <Input.TextArea
                                                        style={{
                                                            width: "100%",
                                                            flexShrink: 0,
                                                        }}
                                                        rows={5}
                                                        value={v.value}
                                                        disabled={disableWrite}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value;
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    variables,
                                                                );

                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [i, "value"],
                                                                value,
                                                            );
                                                            setBlock(tmpBlock);
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            marginTop: "15px",
                                                            padding: "10px",
                                                            width: "100%",
                                                            border: "1px dashed #aaa",
                                                        }}
                                                    >
                                                        <MathRender
                                                            src={
                                                                v.value
                                                                    ? `$${v.value}$`
                                                                    : ""
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            );
                                        case "RICH_TEXT":
                                            return (
                                                <>
                                                    <SlateEditor
                                                        disabled={disableWrite}
                                                        hasMentions={
                                                            hasMentions
                                                        }
                                                        mentionsList={
                                                            mentionsList
                                                        }
                                                        id={`${i}_figma_${i}`}
                                                        onChange={(
                                                            value: any,
                                                        ) => {
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    variables,
                                                                );
                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [i, "value"],
                                                                value,
                                                            );
                                                            setBlock(tmpBlock);
                                                        }}
                                                        isFocused={
                                                            currentEditor ===
                                                            `${i}_figma_${i}`
                                                        }
                                                        setEditor={(
                                                            id: string,
                                                        ) =>
                                                            setCurrentEditor(id)
                                                        }
                                                        value={v?.value || ""}
                                                        placeholder={
                                                            "Type here..."
                                                        }
                                                    />
                                                </>
                                            );
                                        case "FILL":
                                            return (
                                                <>
                                                    <Form.Item label="Background Type">
                                                        <Select
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            defaultValue={
                                                                v?.value?.type
                                                            }
                                                            style={{
                                                                width: 200,
                                                                marginBottom:
                                                                    "20px",
                                                            }}
                                                            placeholder="Background Type"
                                                            onChange={(
                                                                value: any,
                                                            ) => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        variables,
                                                                    );

                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            i,
                                                                            "value",
                                                                            "type",
                                                                        ],
                                                                        value,
                                                                    );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                            options={[
                                                                {
                                                                    value: "SOLID",
                                                                    label: "Solid Color",
                                                                },
                                                                {
                                                                    value: "IMAGE",
                                                                    label: "Image",
                                                                },
                                                                // {
                                                                //     value: "GIF",
                                                                //     label: "Gif",
                                                                // },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                    {v.value?.type ===
                                                        "SOLID" && (
                                                        <Form.Item label="Color">
                                                            <BlockPicker
                                                                colors={[]}
                                                                color={
                                                                    v.value
                                                                        ?.color
                                                                }
                                                                onChangeComplete={(
                                                                    color: any,
                                                                ) => {
                                                                    const value =
                                                                        color.hex;
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            variables,
                                                                        );

                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                i,
                                                                                "value",
                                                                                "color",
                                                                            ],
                                                                            value,
                                                                        );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                                triangle="hide"
                                                            />
                                                        </Form.Item>
                                                    )}
                                                    {["IMAGE", "GIF"].includes(
                                                        v.value?.type,
                                                    ) && (
                                                        <>
                                                            {/* <Form.Item label="Background scale mode">
                                                                <Select
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    defaultValue={
                                                                        v?.value
                                                                            ?.scaleMode
                                                                    }
                                                                    style={{
                                                                        width: 200,
                                                                        marginBottom:
                                                                            "20px",
                                                                    }}
                                                                    placeholder="Scale Mode"
                                                                    onChange={(
                                                                        value: any,
                                                                    ) => {
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                block,
                                                                            );
        
                                                                        tmpBlock =
                                                                            _.set(
                                                                                tmpBlock,
                                                                                [
                                                                                    i,
                                                                                    "value",
                                                                                    "scaleMode",
                                                                                ],
                                                                                value,
                                                                            );
                                                                        setBlock(
                                                                            tmpBlock,
                                                                        );
                                                                    }}
                                                                    options={[
                                                                        {
                                                                            value: "FILL",
                                                                            label: "Fill",
                                                                        },
                                                                        {
                                                                            value: "FIT",
                                                                            label: "Fit",
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item> */}
                                                            <Form.Item label="Asset">
                                                                <RenderUpload
                                                                    singleUpload={
                                                                        true
                                                                    }
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    addExtension={
                                                                        true
                                                                    }
                                                                    path="home-explore/document/"
                                                                    onChangeCustom={(
                                                                        e,
                                                                    ) => {
                                                                        const value =
                                                                            replaceCDNUrl(
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                                e
                                                                                    .target
                                                                                    .bucket,
                                                                            );
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                variables,
                                                                            );

                                                                        tmpBlock =
                                                                            _.set(
                                                                                tmpBlock,
                                                                                [
                                                                                    i,
                                                                                    "value",
                                                                                    "asset",
                                                                                ],
                                                                                value,
                                                                            );
                                                                        setBlock(
                                                                            tmpBlock,
                                                                        );
                                                                    }}
                                                                    label="upload the file"
                                                                    value={
                                                                        v.value
                                                                            ?.asset
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </>
                                            );
                                        case "DROPDOWN_RICH_TEXT_OPTIONS":
                                            return (
                                                <>
                                                    {(v?.value || []).map(
                                                        (
                                                            dropOp: {
                                                                label: any;
                                                                value: string;
                                                            },
                                                            idx: number,
                                                        ) => (
                                                            <Form.Item
                                                                label={`Option ${
                                                                    idx + 1
                                                                }`}
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "column",
                                                                    gap: "20px",
                                                                }}
                                                            >
                                                                <SlateEditor
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    hasMentions={
                                                                        false
                                                                    }
                                                                    mentionsList={[]}
                                                                    id={`${i}_${idx}_drop_label`}
                                                                    onChange={(
                                                                        value: any,
                                                                    ) => {
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                variables,
                                                                            );
                                                                        tmpBlock =
                                                                            _.set(
                                                                                tmpBlock,
                                                                                [
                                                                                    i,
                                                                                    "value",
                                                                                    idx,
                                                                                    "label",
                                                                                ],
                                                                                value,
                                                                            );
                                                                        setBlock(
                                                                            tmpBlock,
                                                                        );
                                                                    }}
                                                                    isFocused={
                                                                        currentEditor ===
                                                                        `${i}_${idx}_drop_label`
                                                                    }
                                                                    setEditor={(
                                                                        id: string,
                                                                    ) =>
                                                                        setCurrentEditor(
                                                                            id,
                                                                        )
                                                                    }
                                                                    value={
                                                                        dropOp?.label
                                                                    }
                                                                    placeholder={
                                                                        "Label"
                                                                    }
                                                                />
                                                                <Input
                                                                    placeholder="Value"
                                                                    value={
                                                                        dropOp?.value
                                                                    }
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    onChange={(
                                                                        e,
                                                                    ) => {
                                                                        const value =
                                                                            e
                                                                                .target
                                                                                .value;
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                variables,
                                                                            );

                                                                        tmpBlock =
                                                                            _.set(
                                                                                tmpBlock,
                                                                                [
                                                                                    i,
                                                                                    "value",
                                                                                    idx,
                                                                                    "value",
                                                                                ],
                                                                                value,
                                                                            );
                                                                        setBlock(
                                                                            tmpBlock,
                                                                        );
                                                                    }}
                                                                />
                                                                <Button
                                                                    ghost
                                                                    danger
                                                                    type="primary"
                                                                    onClick={() => {
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                variables,
                                                                            );
                                                                        const tmpOptions =
                                                                            [
                                                                                ...v.value,
                                                                            ];
                                                                        _.pullAt(
                                                                            tmpOptions,
                                                                            idx,
                                                                        );
                                                                        tmpBlock =
                                                                            _.set(
                                                                                tmpBlock,
                                                                                [
                                                                                    i,
                                                                                    "value",
                                                                                ],
                                                                                tmpOptions,
                                                                            );

                                                                        setBlock(
                                                                            tmpBlock,
                                                                        );
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </Form.Item>
                                                        ),
                                                    )}
                                                    <Button
                                                        disabled={disableWrite}
                                                        onClick={() => {
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    variables,
                                                                );

                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [
                                                                    i,
                                                                    "value",
                                                                    v.value
                                                                        ?.length ||
                                                                        0,
                                                                ],
                                                                {
                                                                    label: "",
                                                                    value: "",
                                                                },
                                                            );
                                                            setBlock(tmpBlock);
                                                        }}
                                                    >
                                                        Add Option
                                                    </Button>
                                                </>
                                            );

                                        case "DROPDOWN":
                                            return (
                                                <>
                                                    {(v?.value || []).map(
                                                        (
                                                            dropOp: {
                                                                label: string;
                                                                value: string;
                                                            },
                                                            idx: number,
                                                        ) => (
                                                            <Form.Item
                                                                label={`Option ${
                                                                    idx + 1
                                                                }`}
                                                            >
                                                                <Space.Compact
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <Input
                                                                        placeholder="Label"
                                                                        value={
                                                                            dropOp?.label
                                                                        }
                                                                        disabled={
                                                                            disableWrite
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => {
                                                                            const value =
                                                                                e
                                                                                    .target
                                                                                    .value;
                                                                            let tmpBlock =
                                                                                _.cloneDeep(
                                                                                    variables,
                                                                                );

                                                                            tmpBlock =
                                                                                _.set(
                                                                                    tmpBlock,
                                                                                    [
                                                                                        i,
                                                                                        "value",
                                                                                        idx,
                                                                                        "label",
                                                                                    ],
                                                                                    value,
                                                                                );
                                                                            setBlock(
                                                                                tmpBlock,
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Input
                                                                        placeholder="Value"
                                                                        value={
                                                                            dropOp?.value
                                                                        }
                                                                        disabled={
                                                                            disableWrite
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => {
                                                                            const value =
                                                                                e
                                                                                    .target
                                                                                    .value;
                                                                            let tmpBlock =
                                                                                _.cloneDeep(
                                                                                    variables,
                                                                                );

                                                                            tmpBlock =
                                                                                _.set(
                                                                                    tmpBlock,
                                                                                    [
                                                                                        i,
                                                                                        "value",
                                                                                        idx,
                                                                                        "value",
                                                                                    ],
                                                                                    value,
                                                                                );
                                                                            setBlock(
                                                                                tmpBlock,
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Button
                                                                        ghost
                                                                        danger
                                                                        type="primary"
                                                                        onClick={() => {
                                                                            let tmpBlock =
                                                                                _.cloneDeep(
                                                                                    variables,
                                                                                );
                                                                            const tmpOptions =
                                                                                [
                                                                                    ...v.value,
                                                                                ];
                                                                            _.pullAt(
                                                                                tmpOptions,
                                                                                idx,
                                                                            );
                                                                            tmpBlock =
                                                                                _.set(
                                                                                    tmpBlock,
                                                                                    [
                                                                                        i,
                                                                                        "value",
                                                                                    ],
                                                                                    tmpOptions,
                                                                                );

                                                                            setBlock(
                                                                                tmpBlock,
                                                                            );
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </Space.Compact>
                                                            </Form.Item>
                                                        ),
                                                    )}
                                                    <Button
                                                        disabled={disableWrite}
                                                        onClick={() => {
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    variables,
                                                                );

                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [
                                                                    i,
                                                                    "value",
                                                                    v.value
                                                                        ?.length ||
                                                                        0,
                                                                ],
                                                                {
                                                                    label: "",
                                                                    value: "",
                                                                },
                                                            );
                                                            setBlock(tmpBlock);
                                                        }}
                                                    >
                                                        Add Option
                                                    </Button>
                                                </>
                                            );
                                        case "AUDIO":
                                            return (
                                                <AudioGenerateComponent
                                                    name={v.name}
                                                    disableWrite={
                                                        isReadOnlyMode
                                                    }
                                                    audioData={v.value}
                                                    onSave={(value: any) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                variables,
                                                            );

                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [i, "value"],
                                                            value,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            );
                                        case "AUDIO_POOL":
                                            return (
                                                <>
                                                    <Button
                                                        type="primary"
                                                        onClick={() =>
                                                            setOpenAudioModal(
                                                                v.name,
                                                            )
                                                        }
                                                    >
                                                        Update Audio Pool
                                                    </Button>
                                                    {openAudioModal ===
                                                        v.name && (
                                                        <AudioPoolModal
                                                            isModalOpen={
                                                                openAudioModal ===
                                                                v.name
                                                            }
                                                            setIsModalOpen={(
                                                                value: boolean,
                                                            ) => {
                                                                value === true
                                                                    ? setOpenAudioModal(
                                                                          v.name,
                                                                      )
                                                                    : setOpenAudioModal(
                                                                          "",
                                                                      );
                                                            }}
                                                            name={v.name}
                                                            audioPool={v.value}
                                                            disableWrite={
                                                                disableWrite
                                                            }
                                                            schema={v.schema}
                                                            onSave={(
                                                                value: any,
                                                            ) => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        variables,
                                                                    );

                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            i,
                                                                            "value",
                                                                        ],
                                                                        value,
                                                                    );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            );
                                        case "DYNAMIC_COMPONENTS_DATA":
                                            return (
                                                <>
                                                    <Button
                                                        type="primary"
                                                        onClick={() =>
                                                            setOpenAudioModal(
                                                                v.name,
                                                            )
                                                        }
                                                    >
                                                        Add Component List Data
                                                    </Button>
                                                    {openAudioModal ===
                                                        v.name && (
                                                        <ComponentsListEditor
                                                            onSave={(
                                                                value: any,
                                                            ) => {
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        variables,
                                                                    );

                                                                tmpBlock =
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            i,
                                                                            "value",
                                                                        ],
                                                                        value,
                                                                    );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                            {...{
                                                                closeForm: () =>
                                                                    setOpenAudioModal(
                                                                        "",
                                                                    ),
                                                                name: v.name,
                                                                componentsList:
                                                                    v.value,
                                                                componentsSchema:
                                                                    v.schema,
                                                                isModalOpen:
                                                                    openAudioModal ===
                                                                    v.name,
                                                                hasMentions,
                                                                mentionsList,
                                                                isEditor,
                                                                setIsEditor,
                                                                setIsNewBlock,
                                                                isNewBlock,
                                                                disableWrite,
                                                            }}
                                                        />
                                                    )}
                                                    {!v?.value?.length && (
                                                        <div
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Not added!
                                                        </div>
                                                    )}
                                                </>
                                            );
                                        case "MATHQUILL_RICH_TEXT": {
                                            return (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "10px",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Input.TextArea
                                                        style={{
                                                            width: "100%",
                                                            flexShrink: 0,
                                                        }}
                                                        rows={5}
                                                        value={
                                                            v?.value
                                                                ?.default_value ??
                                                            ""
                                                        }
                                                        disabled={disableWrite}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value;
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    variables,
                                                                );

                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [
                                                                    i,
                                                                    "value",
                                                                    "default_value",
                                                                ],
                                                                value,
                                                            );
                                                            setBlock(tmpBlock);
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            marginTop: "15px",
                                                            padding: "10px",
                                                            width: "100%",
                                                            border: "1px dashed #aaa",
                                                        }}
                                                    >
                                                        <MathRender
                                                            src={
                                                                v?.value
                                                                    ?.default_value
                                                                    ? `$${v?.value?.default_value}$`
                                                                    : ""
                                                            }
                                                        />
                                                    </div>

                                                    <CorrectValuesComponent
                                                        data={{
                                                            props: {
                                                                default_value: {
                                                                    value:
                                                                        v?.value
                                                                            ?.default_value ??
                                                                        "",
                                                                },
                                                            },
                                                        }}
                                                        correct_values={{
                                                            value:
                                                                v?.value
                                                                    ?.correct_values ||
                                                                [],
                                                            type: "MATHQUILL_RICH_TEXT",
                                                        }}
                                                        disableWrite={false}
                                                        setCurConfig={(
                                                            values: any[],
                                                        ) => {
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    variables,
                                                                );
                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [
                                                                    i,
                                                                    "value",
                                                                    "correct_values",
                                                                ],
                                                                values,
                                                            );
                                                            setBlock(tmpBlock);
                                                        }}
                                                    />
                                                </div>
                                            );
                                        }
                                        default:
                                            return (
                                                <Input
                                                    value={v.value}
                                                    disabled={disableWrite}
                                                    onChange={(e) => {
                                                        const value =
                                                            e.target.value;
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                variables,
                                                            );

                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [i, "value"],
                                                            value,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            );
                                    }
                                })()
                            ) : (
                                <></>
                            )}
                            <div
                                style={{
                                    marginTop: !v
                                        ?.linked_global_context_variable?.name
                                        ? "20px"
                                        : 0,
                                }}
                            >
                                {renderLinkSelect &&
                                    renderLinkSelect({
                                        value: v?.linked_global_context_variable
                                            ?.name,
                                        onChange: (value) => {
                                            let tmpBlock =
                                                _.cloneDeep(variables);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    i,
                                                    "linked_global_context_variable",
                                                    "name",
                                                ],
                                                value,
                                            );

                                            // if (
                                            //     !v
                                            //         ?.linked_global_context_variable
                                            //         ?.name
                                            // ) {
                                            //     tmpBlock = _.set(
                                            //         tmpBlock,
                                            //         [i, "last_value"],
                                            //         v.value,
                                            //     );
                                            // }
                                            // tmpBlock = _.set(
                                            //     tmpBlock,
                                            //     [i, "value"],
                                            //     value !== null
                                            //         ? `@@${value}@@`
                                            //         : v.last_value || v.value,
                                            // );
                                            setBlock(tmpBlock);
                                        },
                                    })}
                            </div>
                        </Form.Item>
                    ),
                )
            ) : (
                <h4>No Variables</h4>
            )}
        </>
    );
};

export default VariablesForm;
