import renderBlockDragArrangeList from "./renderBlock";
import renderEditorDragArrangeList from "./renderEditor";
import isValidCallbackDragArrangeList from "./isValid";
import DRAG_ARRANGE_LIST_DEFAULT_VALUE from "./defaultValue";
import renderSettingsDragArrangeList from "./renderSettings";
import renderCardDragArrangeList from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common";

const { DRAG_ARRANGE_LIST_BLOCK } = BLOCK_TYPES;

const DRAG_ARRANGE_LIST_BLOCK_CONFIG = {
    [DRAG_ARRANGE_LIST_BLOCK]: {
        label: "Drag Arrange List",
        defaultValue: DRAG_ARRANGE_LIST_DEFAULT_VALUE,
        renderBlock: renderBlockDragArrangeList,
        renderEditor: renderEditorDragArrangeList,
        renderCard: renderCardDragArrangeList,
        renderSettings: renderSettingsDragArrangeList,
        addCallback,
        isValidCallback: isValidCallbackDragArrangeList,
    },
};

export {
    renderBlockDragArrangeList,
    renderCardDragArrangeList,
    renderEditorDragArrangeList,
    renderSettingsDragArrangeList,
    isValidCallbackDragArrangeList,
    DRAG_ARRANGE_LIST_DEFAULT_VALUE,
    DRAG_ARRANGE_LIST_BLOCK_CONFIG,
};
