import React, { useState } from "react";

import { Layout, MetaTag } from "src/components";
import { TopicForm } from "../../../components";
import { Card, Col, Row, Spin } from "antd";

const CreateView = (props: any) => {
    const { onFinish, onFinishFailed } = props;

    return (
        <Layout
            titles={[
                { name: "Topics", link: "/book/topic" },
                { name: "New", link: "" },
            ]}
        >
            <MetaTag title="Add Topic" />
            <Row justify="center" align="middle">
                <Col xs={12} lg={20} md={24}>
                    <Card
                        style={{ borderRadius: "8px", padding: "12px" }}
                        title={"Topic Create"}
                        bordered={true}
                    >
                        <TopicForm
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        />
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default CreateView;
