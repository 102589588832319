import React, { useState } from "react";
import { Button, Modal } from "antd";
import { BorderOuterOutlined } from "@ant-design/icons";
import TableBorderEditor from "../../../../../Figma/renderEditor/TableBorder";

interface BorderTagProps {
    cell: any;
    setCurrentCell: (value: any) => void;
    rowIndex: number;
    colIndex: number;
}

export const BorderTag: React.FC<BorderTagProps> = ({
    cell,
    setCurrentCell,
    rowIndex,
    colIndex,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Button
                icon={<BorderOuterOutlined />}
                type={cell?.border?.enabled ? "primary" : "default"}
                onClick={() => setIsModalOpen(true)}
            />

            <Modal
                title="Border Settings"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                <TableBorderEditor blockData={cell} setBlock={setCurrentCell} />
            </Modal>
        </>
    );
};
