import _, { cloneDeep, get, set } from "lodash";
import ComponentDetails from "../common/ComponentDetails";
import { Card, Form, Input, Select, Tag, Space, Switch, Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { CustomKeyboardEditor } from "src/components";
import { LogicModal } from "../common/LogicModal";
import { FeedbackPool } from "../common/FeedbackPool";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { StickerFunctions } from "../../../defaultValue";

const MultiSelectInputEditor = (props: any) => {
    const {
        block,
        disableWrite,
        setBlock,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
        renderLinkSelect,
    } = props;
    const {
        figma: { variables, compute_functions },
    } = block;

    const blockVarsMap: any = useRef({});
    const schema = variables.find((v: any) => v.name === "schema")?.value;
    const schemaIndex = variables.findIndex((v: any) => v.name === "schema");
    const [schemaValue, setSchemaValue] = useState(
        typeof schema === "string" ? JSON.parse(schema) : schema,
    );
    const [tagInput, setTagInput] = useState("");
    const [correctAnswerInput, setCorrectAnswerInput] = useState("");
    const [openFeedbackModal, setOpenFeedbackModal] = useState("");
    const [openInputFeedbackModal, setOpenInputFeedbackModal] = useState("");
    const [load, setLoad] = useState(false);

    useEffect(() => {
        setLoad(true);
        variables?.forEach((element: { name: string | number }, i: any) => {
            blockVarsMap.current[element.name] = i;
        });
        let v = setTimeout(() => {
            setLoad(false);
        }, 50);
        return () => {
            clearTimeout(v);
        };
    }, []);

    useEffect(() => {
        let tmpBlock = cloneDeep(block);
        tmpBlock = set(
            tmpBlock,
            ["figma", "variables", schemaIndex, "value"],
            schemaValue,
        );
        setBlock(tmpBlock);
    }, [schemaValue]);

    const missingComponentFunctions =
        StickerFunctions?.MultiSelectInput?.filter(
            (stickerFunction) =>
                !compute_functions?.some(
                    (item: any) => item.name === stickerFunction.name,
                ),
        );
    if (missingComponentFunctions.length > 0) {
        const tmpBlock = _.cloneDeep(block);
        _.set(
            tmpBlock,
            ["figma", "compute_functions"],
            [...compute_functions, ...missingComponentFunctions],
        );

        setBlock(tmpBlock);
    }

    if (load) return <></>;

    return (
        <div style={{ paddingBottom: "10px", display: "flex" }}>
            <Card
                style={{
                    minWidth: "600px",
                    // maxWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
                title={<h3>Configurations</h3>}
            >
                <ComponentDetails
                    block={block}
                    setBlock={setBlock}
                    disableWrite={disableWrite}
                />
                <LogicModal
                    disableWrite={disableWrite}
                    computeFUnctions={compute_functions}
                    onSave={(val: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["figma", "compute_functions"],
                            val,
                        );
                        setBlock(tmpBlock);
                    }}
                />
                {openFeedbackModal && openFeedbackModal !== "" && (
                    <FeedbackPool
                        isModalOpen={true}
                        setIsModalOpen={(value: boolean) => {
                            setOpenFeedbackModal(
                                value === true ? openFeedbackModal : "",
                            );
                            setOpenInputFeedbackModal("");
                        }}
                        name={openFeedbackModal}
                        feedbackPool={
                            blockVarsMap.current[openFeedbackModal] >= 0 &&
                            variables[blockVarsMap.current[openFeedbackModal]]
                                .value
                        }
                        disableWrite={disableWrite}
                        onSave={(value: any) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                [
                                    "figma",
                                    "variables",
                                    blockVarsMap.current[openFeedbackModal],
                                    "value",
                                ],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        currentEditor={currentEditor}
                        setCurrentEditor={setCurrentEditor}
                    />
                )}
                <Form.Item label="Feedback Pool">
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setOpenFeedbackModal("start_feedback");
                                setOpenInputFeedbackModal("");
                            }}
                            size="small"
                        >
                            Start
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                setOpenFeedbackModal("end_feedback");
                                setOpenInputFeedbackModal("");
                            }}
                            size="small"
                        >
                            End
                        </Button>
                    </Space>
                </Form.Item>
                <Form.Item label={"separate audio"}>
                    <Switch
                        disabled={disableWrite}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={schemaValue?.props?.separate_audio?.value}
                        onChange={(val) => {
                            setSchemaValue((prev: any) => ({
                                ...prev,
                                props: {
                                    ...prev?.props,
                                    separate_audio: {
                                        ...prev?.props?.separate_audio,
                                        value: val,
                                    },
                                },
                            }));
                        }}
                    />
                </Form.Item>
                <div
                    style={{
                        marginTop: "20px",
                    }}
                >
                    {renderLinkSelect &&
                        renderLinkSelect({
                            value: schemaValue?.result
                                ?.linked_global_context_variable?.name,
                            onChange: (value: any) => {
                                setSchemaValue((prev: any) => ({
                                    ...prev,
                                    result: {
                                        ...prev?.result,
                                        linked_global_context_variable: {
                                            ...prev?.result
                                                ?.linked_global_context_variable,
                                            name: value,
                                        },
                                    },
                                }));
                            },
                        })}
                </div>

                <Form.Item
                    label="Evaluation Type"
                    style={{
                        marginTop: "20px",
                    }}
                    validateStatus={
                        !schemaValue?.props?.evaluation_type?.value
                            ? "error"
                            : ""
                    }
                    help={
                        !schemaValue?.props?.evaluation_type?.value
                            ? "Field cannot be empty"
                            : ""
                    }
                >
                    <Select
                        value={schemaValue?.props?.evaluation_type?.value}
                        placeholder="Select type"
                        options={[
                            {
                                label: "Function",
                                value: "FUNCTION",
                            },
                            {
                                label: "Groq",
                                value: "GROQ",
                            },
                        ]}
                        style={{
                            flexGrow: 0,
                        }}
                        onChange={(val) => {
                            setSchemaValue((prev: any) => ({
                                ...prev,
                                props: {
                                    ...prev?.props,
                                    evaluation_type: {
                                        ...prev?.props?.evaluation_type,
                                        value: val,
                                    },
                                },
                            }));
                        }}
                        disabled={props?.disableWrite}
                    />
                </Form.Item>
                <Form.Item
                    label="Feedback Type"
                    validateStatus={
                        !schemaValue?.props?.feedback_type?.value ? "error" : ""
                    }
                    help={
                        !schemaValue?.props?.feedback_type?.value
                            ? "Field cannot be empty"
                            : ""
                    }
                >
                    <Select
                        value={schemaValue?.props?.feedback_type?.value}
                        placeholder="Select type"
                        options={[
                            {
                                label: "No Feedback",
                                value: "NO_FEEDBACK",
                            },
                            {
                                label: "Manual Audio",
                                value: "MANUAL_AUDIO",
                            },
                            {
                                label: "Manual Story",
                                value: "MANUAL_STORY",
                            },
                            {
                                label: "Generated Audio",
                                value: "GENERATED_AUDIO",
                            },
                            {
                                label: "Generated Story",
                                value: "GENERATED_STORY",
                            },
                            {
                                label: "Generated feedback",
                                value: "GENERATED_FEEDBACK",
                            },
                        ]}
                        style={{
                            flexGrow: 0,
                        }}
                        onChange={(val) => {
                            setSchemaValue((prev: any) => ({
                                ...prev,
                                props: {
                                    ...prev?.props,
                                    feedback_type: {
                                        ...prev?.props?.feedback_type,
                                        value: val,
                                    },
                                },
                            }));
                        }}
                        disabled={props?.disableWrite}
                    />
                </Form.Item>
                {schemaValue?.props?.evaluation_type?.value === "GROQ" && (
                    <>
                        <Form.Item
                            label="System Prompt"
                            style={{
                                marginTop: "20px",
                            }}
                            validateStatus={
                                !schemaValue?.props?.system_prompt?.value?.trim()
                                    ? "error"
                                    : ""
                            }
                            help={
                                !schemaValue?.props?.system_prompt?.value?.trim()
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Input.TextArea
                                disabled={disableWrite}
                                // style={{ width: 78 }}
                                value={schemaValue?.props?.system_prompt?.value}
                                onChange={(e) =>
                                    setSchemaValue((prev: any) => ({
                                        ...prev,
                                        props: {
                                            ...prev?.props,
                                            system_prompt: {
                                                ...prev?.props?.system_prompt,
                                                value: e?.target?.value,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label="Get Prompt"
                            style={{
                                marginTop: "20px",
                            }}
                            validateStatus={
                                !schemaValue?.props?.get_prompt?.value?.trim()
                                    ? "error"
                                    : ""
                            }
                            help={
                                !schemaValue?.props?.get_prompt?.value?.trim()
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Input.TextArea
                                disabled={disableWrite}
                                // style={{ width: 78 }}
                                value={schemaValue?.props?.get_prompt?.value}
                                onChange={(e) =>
                                    setSchemaValue((prev: any) => ({
                                        ...prev,
                                        props: {
                                            ...prev?.props,

                                            get_prompt: {
                                                ...prev?.props?.get_prompt,
                                                value: e?.target?.value,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label="Update Correct"
                            style={{
                                marginTop: "20px",
                            }}
                            validateStatus={
                                !schemaValue?.props?.update_correct?.value?.trim()
                                    ? "error"
                                    : ""
                            }
                            help={
                                !schemaValue?.props?.update_correct?.value?.trim()
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Input.TextArea
                                disabled={disableWrite}
                                // style={{ width: 78 }}
                                value={
                                    schemaValue?.props?.update_correct?.value
                                }
                                onChange={(e) =>
                                    setSchemaValue((prev: any) => ({
                                        ...prev,
                                        props: {
                                            ...prev?.props,

                                            update_correct: {
                                                ...prev?.props?.update_correct,
                                                value: e?.target?.value,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                    </>
                )}
                {(schemaValue?.props?.evaluation_type?.value === "GROQ" ||
                    schemaValue?.props?.feedback_type?.value ===
                        "GENERATED_FEEDBACK") && (
                    <Form.Item
                        label="Question Text"
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        <Input.TextArea
                            disabled={disableWrite}
                            // style={{ width: 78 }}
                            value={schemaValue?.props?.question_text?.value}
                            onChange={(e) =>
                                setSchemaValue((prev: any) => ({
                                    ...prev,
                                    props: {
                                        ...prev?.props,
                                        question_text: {
                                            ...prev?.props?.question_text,
                                            value: e?.target?.value,
                                        },
                                    },
                                }))
                            }
                        />
                    </Form.Item>
                )}
                {schemaValue?.props?.feedback_type?.value ===
                    "GENERATED_FEEDBACK" && (
                    <>
                        <Form.Item
                            label="Feedback Prompt"
                            style={{
                                marginTop: "20px",
                            }}
                            validateStatus={
                                !schemaValue?.props?.feedback_prompt?.value?.trim()
                                    ? "error"
                                    : ""
                            }
                            help={
                                !schemaValue?.props?.feedback_prompt?.value?.trim()
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Input.TextArea
                                disabled={disableWrite}
                                // style={{ width: 78 }}
                                value={
                                    schemaValue?.props?.feedback_prompt?.value
                                }
                                onChange={(e) =>
                                    setSchemaValue((prev: any) => ({
                                        ...prev,
                                        props: {
                                            ...prev?.props,
                                            feedback_prompt: {
                                                ...prev?.props?.feedback_prompt,
                                                value: e?.target?.value,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label="Get Feedback Prompt"
                            style={{
                                marginTop: "20px",
                            }}
                            validateStatus={
                                !schemaValue?.props?.get_feedback_prompt?.value?.trim()
                                    ? "error"
                                    : ""
                            }
                            help={
                                !schemaValue?.props?.get_feedback_prompt?.value?.trim()
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Input.TextArea
                                disabled={disableWrite}
                                // style={{ width: 78 }}
                                value={
                                    schemaValue?.props?.get_feedback_prompt
                                        ?.value
                                }
                                onChange={(e) =>
                                    setSchemaValue((prev: any) => ({
                                        ...prev,
                                        props: {
                                            ...prev?.props,
                                            get_feedback_prompt: {
                                                ...prev?.props
                                                    ?.get_feedback_prompt,
                                                value: e?.target?.value,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label="Update feedback"
                            style={{
                                marginTop: "20px",
                            }}
                            validateStatus={
                                !schemaValue?.props?.update_feedback?.value?.trim()
                                    ? "error"
                                    : ""
                            }
                            help={
                                !schemaValue?.props?.update_feedback?.value?.trim()
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Input.TextArea
                                disabled={disableWrite}
                                // style={{ width: 78 }}
                                value={
                                    schemaValue?.props?.update_feedback?.value
                                }
                                onChange={(e) =>
                                    setSchemaValue((prev: any) => ({
                                        ...prev,
                                        props: {
                                            ...prev?.props,

                                            update_feedback: {
                                                ...prev?.props?.update_feedback,
                                                value: e?.target?.value,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                    </>
                )}
                {schemaValue?.props?.separate_audio?.value && (
                    <>
                        <Form.Item
                            label="Audio Prompt"
                            style={{
                                marginTop: "20px",
                            }}
                            validateStatus={
                                !schemaValue?.props?.audio_prompt?.value?.trim()
                                    ? "error"
                                    : ""
                            }
                            help={
                                !schemaValue?.props?.audio_prompt?.value?.trim()
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Input.TextArea
                                disabled={disableWrite}
                                // style={{ width: 78 }}
                                value={schemaValue?.props?.audio_prompt?.value}
                                onChange={(e) =>
                                    setSchemaValue((prev: any) => ({
                                        ...prev,
                                        props: {
                                            ...prev?.props,
                                            audio_prompt: {
                                                ...prev?.props?.audio_prompt,
                                                value: e?.target?.value,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label="Get Audio Prompt"
                            style={{
                                marginTop: "20px",
                            }}
                            validateStatus={
                                !schemaValue?.props?.get_audio_prompt?.value?.trim()
                                    ? "error"
                                    : ""
                            }
                            help={
                                !schemaValue?.props?.get_audio_prompt?.value?.trim()
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Input.TextArea
                                disabled={disableWrite}
                                // style={{ width: 78 }}
                                value={
                                    schemaValue?.props?.get_audio_prompt?.value
                                }
                                onChange={(e) =>
                                    setSchemaValue((prev: any) => ({
                                        ...prev,
                                        props: {
                                            ...prev?.props,
                                            get_audio_prompt: {
                                                ...prev?.props
                                                    ?.get_audio_prompt,
                                                value: e?.target?.value,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label="Update Audio"
                            style={{
                                marginTop: "20px",
                            }}
                            validateStatus={
                                !schemaValue?.props?.update_audio?.value?.trim()
                                    ? "error"
                                    : ""
                            }
                            help={
                                !schemaValue?.props?.update_audio?.value?.trim()
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Input.TextArea
                                disabled={disableWrite}
                                // style={{ width: 78 }}
                                value={schemaValue?.props?.update_audio?.value}
                                onChange={(e) =>
                                    setSchemaValue((prev: any) => ({
                                        ...prev,
                                        props: {
                                            ...prev?.props,

                                            update_audio: {
                                                ...prev?.props?.update_audio,
                                                value: e?.target?.value,
                                            },
                                        },
                                    }))
                                }
                            />
                        </Form.Item>
                    </>
                )}
                <Form.Item
                    label="Input Type"
                    validateStatus={
                        !schemaValue?.props?.keyboard?.type ? "error" : ""
                    }
                    help={
                        !schemaValue?.props?.keyboard?.type
                            ? "Field cannot be empty"
                            : ""
                    }
                >
                    <Select
                        value={schemaValue?.props?.keyboard?.type}
                        placeholder="Select type"
                        options={[
                            {
                                label: "custom",
                                value: "CUSTOM",
                            },
                            {
                                label: "number",
                                value: "NUMBER",
                            },
                            {
                                label: "normal",
                                value: "NORMAL",
                            },
                        ]}
                        style={{
                            flexGrow: 0,
                        }}
                        onChange={(val) => {
                            setSchemaValue((prev: any) => ({
                                ...prev,
                                props: {
                                    ...prev?.props,
                                    keyboard: {
                                        ...prev?.props?.keyboard,
                                        type: val,
                                    },
                                },
                            }));
                        }}
                        disabled={props?.disableWrite}
                    />
                </Form.Item>
                <Form.Item
                    label="Default Tags"
                    style={{
                        marginTop: "20px",
                    }}
                >
                    {schemaValue?.props?.default_value?.value?.map(
                        (tag: any, i: number) => {
                            return (
                                <Tag
                                    closable
                                    onClose={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setSchemaValue((prevState: any) => ({
                                            ...prevState,
                                            props: {
                                                ...prevState.props,
                                                default_value: {
                                                    ...prevState?.props
                                                        ?.default_value,
                                                    value: prevState?.props?.default_value?.value?.filter(
                                                        (t: any, ti: number) =>
                                                            ti !== i,
                                                    ),
                                                },
                                            },
                                        }));
                                    }}
                                >
                                    {tag.text}
                                </Tag>
                            );
                        },
                    )}
                    <Input
                        disabled={disableWrite}
                        type="text"
                        style={{ width: 78 }}
                        value={tagInput}
                        onChange={(e) => setTagInput(e?.target?.value)}
                        onPressEnter={() => {
                            setSchemaValue((prevState: any) => ({
                                ...prevState,
                                props: {
                                    ...prevState?.props,
                                    default_value: {
                                        ...prevState?.props?.default_value,
                                        value: [
                                            ...prevState?.props?.default_value
                                                ?.value,
                                            { id: tagInput, text: tagInput },
                                        ],
                                    },
                                },
                            }));
                            setTagInput("");
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Correct Answers"
                    style={{
                        marginTop: "20px",
                    }}
                    validateStatus={
                        !schemaValue?.correct_values?.value?.length
                            ? "error"
                            : ""
                    }
                    help={
                        !schemaValue?.correct_values?.value?.length
                            ? "Field cannot be empty"
                            : ""
                    }
                >
                    {schemaValue?.correct_values?.value?.map(
                        (tag: any, i: number) => {
                            return (
                                <Tag
                                    closable
                                    onClose={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setSchemaValue((prevState: any) => ({
                                            ...prevState,
                                            correct_values: {
                                                ...prevState?.correct_values,
                                                value: prevState?.correct_values?.value?.filter(
                                                    (t: any, ti: number) =>
                                                        ti !== i,
                                                ),
                                            },
                                        }));
                                    }}
                                >
                                    {tag.text}
                                </Tag>
                            );
                        },
                    )}
                    <Input
                        disabled={disableWrite}
                        type="text"
                        style={{ width: 78 }}
                        value={correctAnswerInput}
                        onChange={(e) =>
                            setCorrectAnswerInput(e?.target?.value)
                        }
                        onPressEnter={() => {
                            setSchemaValue((prevState: any) => ({
                                ...prevState,
                                correct_values: {
                                    ...prevState?.correct_values,
                                    value: [
                                        ...prevState?.correct_values?.value,
                                        {
                                            id: correctAnswerInput,
                                            text: correctAnswerInput,
                                        },
                                    ],
                                },
                            }));
                            setCorrectAnswerInput("");
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Placeholder text"
                    style={{
                        marginTop: "20px",
                    }}
                >
                    <Input
                        type="text"
                        disabled={disableWrite}
                        // style={{ width: 78 }}
                        value={schemaValue?.props?.placeholder?.value}
                        onChange={(e) =>
                            setSchemaValue((prev: any) => ({
                                ...prev,
                                props: {
                                    ...prev?.props,
                                    placeholder: {
                                        ...prev?.props?.placeholder,
                                        value: e?.target?.value,
                                    },
                                },
                            }))
                        }
                    />
                </Form.Item>
                <CustomKeyboardEditor
                    disabled={props.disableWrite}
                    value={schemaValue?.props?.keyboard?.value}
                    onOk={(data: any) => {
                        setSchemaValue((prev: any) => ({
                            ...prev,
                            props: {
                                ...prev?.props,
                                keyboard: {
                                    ...prev?.props?.keyboard,
                                    value: data,
                                },
                            },
                        }));
                    }}
                />
            </Card>
        </div>
    );
};

export default MultiSelectInputEditor;
