import renderBlockVideo from "./renderBlock";
import renderEditorVideo from "./renderEditor";
import isValidCallbackVideo from "./isValid";
import VIDEO_DEFAULT_VALUE from "./defaultValue";
import renderSettingsVideo from "./renderSettings";
import renderCardVideo from "./renderCard";
import { BLOCK_TYPES, addCallback } from "../../common/index";

const { VIDEO_BLOCK } = BLOCK_TYPES;

const VIDEO_BLOCK_CONFIG = {
    [VIDEO_BLOCK]: {
        label: "Video",
        defaultValue: VIDEO_DEFAULT_VALUE,
        renderBlock: renderBlockVideo,
        renderEditor: renderEditorVideo,
        renderCard: renderCardVideo,
        addCallback,
        renderSettings: renderSettingsVideo,
        isValidCallback: isValidCallbackVideo,
    },
};

export {
    renderBlockVideo,
    renderCardVideo,
    renderEditorVideo,
    renderSettingsVideo,
    isValidCallbackVideo,
    VIDEO_DEFAULT_VALUE,
    VIDEO_BLOCK_CONFIG,
};
