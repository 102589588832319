import React, { useEffect, useState } from "react";
import { compose } from "src/helpers";
import {
    withHookForAggregateDocument,
    withHookForDocuments,
    withUpdateGroupDocumentMap,
} from "src/modules/document/operations";
import { withStateAndActionsForDocument } from "src/modules/document/resolvers";
import ListView from "./component";
import { useLazyQuery } from "@apollo/client";
import { DOCUMENTS_QUERY } from "src/modules/document/graphql";

const List: React.FC = (props: any) => {
    const { group_id, updateGroupTabMap, disableAddButton } = props;
    const [documents, setDocuments] = useState([]);

    const [getDocuments, { loading, error, data = {} }] = useLazyQuery(
        DOCUMENTS_QUERY,

        {
            variables: { group_id },
            fetchPolicy: "network-only",
        },
    );

    useEffect(() => {
        const asyncFunc = async () => {
            if (group_id) {
                const { data } = await getDocuments();
                // console.log("documents", data.documents);
                setDocuments(data.documents);
            }
        };
        asyncFunc();
    }, [group_id]);

    const extraProps = {
        group_id,
        loading,
        documents,
        updateGroupTabMap,
        disableAddButton,
    };

    return <ListView {...props} {...extraProps} />;
};

export default compose(withUpdateGroupDocumentMap)(List);
// withStateAndActionsForDocument,
// withHookForDocuments,
// withHookForAggregateDocument,
