import React from "react";
import { compose } from "src/helpers";
import {
    withHookForAggregateSet,
    withHookForSets,
} from "src/modules/set/operations";
import { withStateAndActionsForSet } from "src/modules/set/resolvers";
import ListView from "./component";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    withStateAndActionsForSet,
    withHookForSets,
    withHookForAggregateSet,
)(List);
