import { Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Radio } from "antd";
import { gql } from "graphql-request";
import { graphQLClient } from "src/modules/tab/components/PublishTabToProd";
import { getLastStringFromUrl } from "src/helpers";
import { useParams } from "react-router-dom";

const query = gql`
    query getGenericData($id: String!) {
        other_generic_data(where: { context_id: { _eq: $id } }) {
            context_id
            data
        }
    }
`;

const updateQuery = gql`
    mutation InsertGenericData($object: other_generic_data_insert_input = {}) {
        insert_other_generic_data_one(
            object: $object
            on_conflict: {
                constraint: generic_data_context_id_key
                update_columns: [data, model]
            }
        ) {
            id
        }
    }
`;

const UpdateGenericData = () => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const [data, setData] = useState<any>();
    const [contextId, setContextId] = useState<any>("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                const response: any = await graphQLClient.request(query, {
                    id,
                });
                setData(
                    JSON.stringify(
                        response?.other_generic_data?.[0]?.data ?? [],
                    ),
                );
                setContextId(response?.other_generic_data?.[0]?.context_id);
            } catch (error: any) {
                message.error(`Error in fetching data: ${error.message}`);
            }
        };
        getData();
    }, []);

    const handleUpdate = async () => {
        try {
            setLoading(true);
            const newObject = {
                context_id: contextId,
                data: JSON.parse(data),
                model: null,
            };
            const response: any = await graphQLClient.request(updateQuery, {
                object: newObject,
            });
            message.success("Data updated successfully");
            setLoading(false);
        } catch (error: any) {
            message.error(`Error in updating data: ${error.message}`);
            setLoading(false);
        }
    };

    return (
        <div
            style={{
                padding: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100dvh",
            }}
        >
            <Form
                layout={"vertical"}
                form={form}
                initialValues={{ layout: "vertical" }}
                style={{ width: "100%", maxWidth: "600px" }}
            >
                <Form.Item label="Context Id">
                    <Input
                        placeholder="input placeholder"
                        disabled
                        value={contextId}
                    />
                </Form.Item>
                <Form.Item label="Data">
                    <Input.TextArea
                        placeholder="input placeholder"
                        rows={10}
                        value={data}
                        onChange={(e) => {
                            setData(e?.target?.value);
                        }}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        onClick={handleUpdate}
                        loading={loading}
                        disabled={loading}
                    >
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default UpdateGenericData;

export const GENERIC_DATA_ROUTES = [
    <Route
        key="update-generic-data"
        path="/update-generic-data/:id"
        element={<UpdateGenericData />}
    />,
];

const a = [
    {
        image: "https://cdn.homeworkapp.ai/sets-gamify-assets/math-ai-assets/assets/onboarding/Image.png",
        activities: [15940, 15961, 15933, 15937, 16024, 16023, 16010],
        title: "Preparation for Challenge 1",
    },
    {
        image: "https://cdn.homeworkapp.ai/sets-gamify-assets/math-ai-assets/assets/onboarding/Image.png",
        activities: [16127, 16113, 16109, 16094, 16114, 16103, 16077],
        title: "Preparation for Challenge 2",
    },
];
