import { FunctionComponent } from "react";

import {
    getLastStringFromUrl,
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import {
    COLLECTION_QUERY,
    COLLECTIONS_QUERY,
    COLLECTION_AGGREGATE_QUERY,
} from "../graphql";

import set from "lodash/set";

export const withHookForCollections = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        let { where, pagination } = props;

        // dont show sets created by teachers as homeworks;
        where = set(where, ["type", "_neq"], "interactive_rapid_quiz");

        const id = parseInt(getLastStringParamFromUrl());
        const url_split =
            typeof window !== "undefined" &&
            window.location.pathname.split("/");
        const filter_type = url_split && url_split[url_split.length - 2];
        let filter = ["sets", "set_id", "_eq"];
        if (filter_type == "topic") {
            where = {};
            filter = ["groups", "group_id", "_eq"];
        }
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            COLLECTIONS_QUERY,
            {
                variables: {
                    where: id ? set(where, filter, id) : where,
                    ...pagination,
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "collections", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    collections: items,
                    subscribeToMore,
                    updateQuery,
                    loadCollectionsData: loadItemsData,
                    refetchCollections: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateCollection = (
    Component: FunctionComponent,
) => {
    const WithComponent = (props) => {
        const { where } = props;
        const set_id = parseInt(getLastStringParamFromUrl());

        const queryDocumentResult = useHasuraSubscriptionWithCache(
            COLLECTION_AGGREGATE_QUERY,
            {
                variables: {
                    where: set_id
                        ? set(where, ["sets", "set_id", "_eq"], set_id)
                        : where,
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "collectionAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    collectionAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForCollection = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            COLLECTION_QUERY,
            {
                variables: {
                    id: getLastStringFromUrl(),
                },
            },
        );

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingCollection: loading,
                    collection: data?.collection,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
