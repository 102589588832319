import { Empty } from "antd";
import {
    BLOCK_TYPES,
    BLOCK_TYPES_NORMAL,
    BLOCK_TYPES_PERSONALIZED_LEARNING,
    BLOCK_TYPES_PERSONALIZED_LEARNING_V2,
    BLOCK_TYPES_PERSONALIZED_LEARNING_V3,
    BLOCK_TYPES_PROGRESSION_STAGE,
    BLOCK_TYPES_TIMED,
    QUIZ_BLOCK_TYPES,
} from "./common";
import {
    CASE_STUDY_CONFIG,
    TEXT_BLOCK_CONFIG,
    // VIDEO_BLOCK_CONFIG,
    MCQ_BLOCK_CONFIG,
    SUBJECTIVE_BLOCK_CONFIG,
    RE_ORDER_BLOCK_CONFIG,
    IMAGE_BLOCK_CONFIG,
    // daily quiz
    // LONG_ANSWER_BLOCK_CONFIG,
    // SHORT_ANSWER_BLOCK_CONFIG,
    // EMAIL_BLOCK_CONFIG,
    // PHONE_BLOCK_CONFIG,
    // CONTENT_BLOCK_CONFIG,
    // TABLE_BLOCK_CONFIG,
    // AUDIO_BLOCK_CONFIG,
    // V2_CONTENT_BLOCK_CONFIG,
    // V2_FILL_BLANK_BLOCK_CONFIG,
    // V2_MIXED_BLOCK_CONFIG,
    // V2_RAPID_QA_BLOCK_CONFIG,
    // V2_THREE_LIVES_BLOCK_CONFIG,
    V2_STORIES_BLOCK_CONFIG,
    V2_STORY_BLOCK_CONFIG,
    // V2_AVATAR_BLOCK_CONFIG,
    // V2_TEXT_BLOCK_CONFIG,
    // V2_VOICE_BLOCK_CONFIG,
    CROSSWORD_BLOCK_CONFIG,
    // DRAG_AND_SWIPE_BLOCK_CONFIG,
    // DRAG_ARRANGE_LIST_BLOCK_CONFIG,
    // COMPARE_ARRANGE_LIST_BLOCK_CONFIG,
    // COMPARE_DIGITS_BLOCK_CONFIG,
    SCRATCH_ARRANGE_LIST_BLOCK_CONFIG,
    // BUTTON_BLOCK_CONFIG,
    PROGRESSION_STAGE_BLOCK_CONFIG,
    V2_MCQ_BLOCK_CONFIG,
    // SWIPE_AND_FORM_BLOCK_CONFIG,
    // INPUT_ARRANGE_LIST_BLOCK_CONFIG,
    // DRAG_EXPAND_NUMBER_BLOCK_CONFIG,
    // PAINT_ACTIVITY_BLOCK_CONFIG,
    // V2_TABLE_BLOCK_CONFIG,
    // COLOR_COMBINE_BLOCK_CONFIG,
    // INPUT_AND_FORM_BLOCK_CONFIG,
    // INPUT_EXPAND_NUMBER_BLOCK_CONFIG,
    V2_DRAG_AND_SWIPE_BLOCK_CONFIG,
    // V2_INPUT_AND_FORM_BLOCK_CONFIG,
    // DRAG_AND_TAG_BLOCK_CONFIG,
    // DRAG_AND_INPUT_BLOCK_CONFIG,
    FIGMA_BLOCK_CONFIG,
    PROGRESS_LEVEL_BLOCK_CONFIG,
    ALL_IN_ONE_BLOCK_CONFIG,
} from "./renderers";

export const BLOCK_DATA = {
    ...CASE_STUDY_CONFIG,
    ...IMAGE_BLOCK_CONFIG,
    ...MCQ_BLOCK_CONFIG,
    ...RE_ORDER_BLOCK_CONFIG,
    ...SUBJECTIVE_BLOCK_CONFIG,
    ...TEXT_BLOCK_CONFIG,
    // ...VIDEO_BLOCK_CONFIG,
    // ...LONG_ANSWER_BLOCK_CONFIG,
    // ...SHORT_ANSWER_BLOCK_CONFIG,
    // ...EMAIL_BLOCK_CONFIG,
    // ...PHONE_BLOCK_CONFIG,
    // ...CONTENT_BLOCK_CONFIG,
    // ...TABLE_BLOCK_CONFIG,

    // ...AUDIO_BLOCK_CONFIG,
    // ...BUTTON_BLOCK_CONFIG,
    // ...V2_CONTENT_BLOCK_CONFIG,
    // ...V2_FILL_BLANK_BLOCK_CONFIG,
    // ...V2_MIXED_BLOCK_CONFIG,
    // ...V2_RAPID_QA_BLOCK_CONFIG,
    // ...V2_THREE_LIVES_BLOCK_CONFIG,
    ...V2_STORIES_BLOCK_CONFIG,
    ...V2_STORY_BLOCK_CONFIG,
    // ...V2_AVATAR_BLOCK_CONFIG,
    // ...V2_TEXT_BLOCK_CONFIG,
    // ...V2_VOICE_BLOCK_CONFIG,

    ...V2_MCQ_BLOCK_CONFIG,
    ...CROSSWORD_BLOCK_CONFIG,
    // ...DRAG_AND_SWIPE_BLOCK_CONFIG,
    // ...DRAG_ARRANGE_LIST_BLOCK_CONFIG,
    // ...COMPARE_ARRANGE_LIST_BLOCK_CONFIG,
    // ...COMPARE_DIGITS_BLOCK_CONFIG,
    ...SCRATCH_ARRANGE_LIST_BLOCK_CONFIG,
    ...PROGRESSION_STAGE_BLOCK_CONFIG,
    // ...SWIPE_AND_FORM_BLOCK_CONFIG,
    // ...INPUT_ARRANGE_LIST_BLOCK_CONFIG,
    // ...DRAG_EXPAND_NUMBER_BLOCK_CONFIG,
    // ...PAINT_ACTIVITY_BLOCK_CONFIG,
    // ...V2_TABLE_BLOCK_CONFIG,
    // ...COLOR_COMBINE_BLOCK_CONFIG,
    // ...INPUT_AND_FORM_BLOCK_CONFIG,
    // ...INPUT_EXPAND_NUMBER_BLOCK_CONFIG,
    ...V2_DRAG_AND_SWIPE_BLOCK_CONFIG,
    // ...V2_INPUT_AND_FORM_BLOCK_CONFIG,
    // ...DRAG_AND_TAG_BLOCK_CONFIG,
    // ...DRAG_AND_INPUT_BLOCK_CONFIG,

    ...FIGMA_BLOCK_CONFIG,
    ...PROGRESS_LEVEL_BLOCK_CONFIG,
    ...ALL_IN_ONE_BLOCK_CONFIG,
};

export const isValidType = (type: string) => {
    return Object.values(BLOCK_TYPES).includes(type);
};

export const getBareBlock = (type: string) => {
    if (isValidType(type)) {
        const defaultValue = BLOCK_DATA[type].defaultValue;
        if (typeof defaultValue === "function") {
            return defaultValue();
        }
        return defaultValue;
    }
};

export const getAddCallback = (type: string) => {
    if (isValidType(type)) {
        return BLOCK_DATA[type]?.addCallback;
    }
};

export const getIsValidFunction = (type: string) => {
    if (isValidType(type)) {
        return BLOCK_DATA[type]?.isValidCallback;
    }
};

export const checkBlockValid = (block, worksheet, data = {}) => {
    const { type } = block;

    const isValidFunction =
        (type && getIsValidFunction(type)) ||
        (() => ({
            isValid: true,
        }));
    const { isValid }: any = isValidFunction({ block, worksheet, ...data });
    return isValid;
};

export const getCardPreview = (props: any) => {
    const { block } = props;
    const { type } = block;
    if (!isValidType(type)) {
        return <Empty description={`${type} not supported!`} />;
    }
    const { renderCard: Card } = BLOCK_DATA[type] || {};
    if (!Card) {
        return <Empty description={`${type} not supported!`} />;
    }
    return <Card {...props} block={block} />;
};

export const getEditor = (props: any) => {
    const { block } = props;

    if (!block) return <Empty description={`Add a block to start.`} />;

    const { type } = block;
    if (!isValidType(type)) {
        return <Empty description={`${type} not supported!`} />;
    }
    const { renderEditor: Editor } = BLOCK_DATA[type] || {};
    if (!Editor) {
        return <Empty description={`${type} not supported!`} />;
    }
    return <Editor {...props} />;
};

export const renderBlock = (block: any) => {
    const { type } = block;
    if (!isValidType(type)) {
        return <Empty description={`${type} not supported!`} />;
    }
    const { renderBlock: Block } = BLOCK_DATA[type] || {};
    if (!Block) {
        return <Empty description={`${type} not supported!`} />;
    }
    return <Block {...block} />;
};

export const renderSettings = (props: any) => {
    const { block } = props;
    if (!block) return <Empty description={`Add a block to start.`} />;
    const { type } = block;
    if (!isValidType(type)) {
        return <Empty description={`${type} not supported!`} />;
    }
    const { renderSettings: Settings } = BLOCK_DATA[type] || {};
    if (!Settings) return <></>;
    return <Settings {...props} />;
};

export const getBlockTypes = (worksheet: any) => {
    if (!worksheet) return [];

    const { type } = worksheet;

    let currBlockTypes: any = BLOCK_TYPES;
    if (type === `personalized_learning`)
        currBlockTypes = BLOCK_TYPES_PERSONALIZED_LEARNING;
    else if (type === `personalized_learning_v2`)
        currBlockTypes = BLOCK_TYPES_PERSONALIZED_LEARNING_V2;
    else if (
        ["personalized_learning_v3", "personalized_learning_v4"].includes(type)
    )
        currBlockTypes = BLOCK_TYPES_PERSONALIZED_LEARNING_V3;
    else if (type === `timed`) currBlockTypes = BLOCK_TYPES_TIMED;
    else if (type === `normal`) currBlockTypes = BLOCK_TYPES_NORMAL;
    else if (type === `quiz_form`) currBlockTypes = QUIZ_BLOCK_TYPES;
    else if (type === BLOCK_TYPES.PROGRESSION_STAGE_BLOCK)
        currBlockTypes = BLOCK_TYPES_PROGRESSION_STAGE;
    return Object.values(currBlockTypes).map((type: any) => ({
        label: BLOCK_DATA[type]?.label,
        value: type,
    }));
};

export const CustomEditor = (props: any) => {
    const { type } = props;

    if (!isValidType(type)) {
        return <></>;
    }
    const { renderEditor: Editor } = BLOCK_DATA[type] || {};
    if (!Editor) return <></>;
    return <Editor {...props} />;
};
