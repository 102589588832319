import React from "react";
import { Spin, Select } from "antd";

import { compose, config } from "src/helpers";
import { Field, RenderSelect } from "src/components";

import { withCollections } from "src/modules/collection/operations";

import { withStateAndActionsForCollection } from "../resolvers";
import { resetFilterHook } from "src/helpers";

const Option = Select.Option;

const SubjectsField = (props) => {
    const {
        loading,
        collections,
        where,
        onNameChange,
        onChange,
        value,
        onStateReset,
    } = props;

    // resetFilterHook(onStateReset);

    return (
        <Field
            labelInValue
            showSearch={true}
            filterOption={false}
            onSearch={onNameChange}
            notFoundContent={loading ? <Spin size="small" /> : null}
            name={"collections"}
            component={RenderSelect}
            placeholder={"Collections"}
            label={"Collections"}
            value={value || "None"}
            loading={loading}
            onChange={onChange}
            inFilter={true}
            noBotMarging={true}
        >
            {[
                // config.EMPTY_OPTION,
                ...(collections?.map((collection) => ({
                    id: collection.id,
                    label: collection.title || "",
                    value: JSON.stringify(collection),
                })) || []),
            ]?.map(
                ({
                    id,
                    value,
                    label = "",
                }: {
                    id: string;
                    value: string;
                    label: string;
                }) => (
                    <Option key={id} value={value}>
                        {label}
                    </Option>
                ),
            )}
        </Field>
    );
};

export default compose(
    withStateAndActionsForCollection,
    withCollections,
)(SubjectsField);
