import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
    Badge,
    Button,
    Card,
    Checkbox,
    Divider,
    Form,
    Input,
    Select,
    Switch,
    Table,
    Tag,
    message,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import { cloneDeep, pullAt, set } from "lodash";
import React, { useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import {
    CopyIcon,
    DeleteIcon,
    RenderUpload,
    SlateEditor,
} from "src/components";
import { v4 as uuid } from "uuid";
import { DragHandle } from ".";
import { useWorksheetState } from "src/modules/worksheet/components/contexts/WorksheetContext";

const SortableItem = SortableElement(({ value }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Badge.Ribbon color={"cyan"} text={value + 1} placement={"start"}>
                <Card
                    hoverable
                    style={{
                        borderRadius: "8px",
                        marginBottom: "4px",
                        boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                        position: "relative",
                        minWidth: "90px",
                    }}
                    bodyStyle={{
                        background: "transparent",
                        paddingBottom: 10,
                        paddingTop: 10,
                    }}
                >
                    {`Item ${value + 1}`}
                    <div
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "2px",
                        }}
                    >
                        <DragHandle />
                    </div>
                </Card>
            </Badge.Ribbon>
        </div>
    );
});

const SortableList = SortableContainer(({ items, disableWrite }) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "30px",
                width: "100%",
                flexWrap: "wrap",
            }}
        >
            {items.map((child, index) => (
                <SortableItem
                    key={`item-${index}`}
                    index={index}
                    value={index}
                    child={child}
                    disabled={disableWrite}
                />
            ))}
        </div>
    );
});

function sortAndUpdate(array1, array2) {
    // Create a copy of array1 to avoid modifying the original array
    const sortedArray = [...array1];

    // Sort the array based on the order specified in array2
    sortedArray.sort((a, b) => {
        const typeA = a.text ? "text" : a.img ? "image" : "drop";
        const typeB = b.text ? "text" : b.img ? "image" : "drop";
        const indexA = array2.indexOf(typeA);
        const indexB = array2.indexOf(typeB);
        return indexA - indexB;
    });

    // Update showImg, showText, and showItems values based on array2 content
    const showImgIndex = array2.indexOf("image");
    const showTextIndex = array2.indexOf("text");
    const showDropIndex = array2.indexOf("drop");

    sortedArray.forEach((item) => {
        if (item.text) {
            item.showText = showTextIndex !== -1 ? 1 : 0;
        } else if (item.img) {
            item.showImg = showImgIndex !== -1 ? 1 : 0;
        } else if (item.items) {
            item.showItems = showDropIndex !== -1 ? 1 : 0;
        }
    });

    return sortedArray;
}

const defValue = [
    {
        bg: {
            type: "SOLID",
            color: "#ffffff",
        },
        data: [
            {
                text: [],
                showText: 0,
            },
            {
                img: {
                    type: "IMAGE",
                    asset: "https://ik.imagekit.io/thehomeworkapp/Egyptian%20Numbers/1_NAs_t7oSd.png?updatedAt=1713003941290",
                    rotation: 0,
                },
                width: "32",
                height: "32",
                showImg: 1,
            },
        ],
        name: "1",
        asset: "https://ik.imagekit.io/thehomeworkapp/Egyptian%20Numbers/1_NAs_t7oSd.png?updatedAt=1713003941290",
        index: "0.0",
        tagId: "a01c5370-d30c-4ab4-8651-6f3d7679e581",
        type1: 1,
        value: 1,
        enable: true,
        bg_type1: 0,
        category: "num",
        maxDragLimit: "9",
        showDisabled: 0,
    },
];

const ItemsTable: React.FC = ({
    setBlock,
    disableWrite,
    currentEditor,
    setCurrentEditor,
    hasMentions,
    mentionsList = [],
    items = [],
    schema = [],
    tagList = [],
    renderLinkSelect,
}: any) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const tmpChildren = arrayMoveImmutable(
            items,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        setBlock(tmpChildren);
    };

    const {
        checkedDnDItemsTableColumnsList: checkedList,
        setCheckedDnDItemsTableColumnsList: setCheckedList,
        DnDItemsTableColumns: showColumns,
    }: any = useWorksheetState();

    const [selectionCounts, setSelectionCounts] = useState<{
        [key: string]: number;
    }>({});

    return (
        <>
            <SortableList
                items={items}
                onSortEnd={onSortEnd}
                axis="xy"
                useDragHandle
                disableWrite={disableWrite}
            />
            <Divider />
            <Checkbox.Group
                value={checkedList}
                options={Object.keys(showColumns)}
                onChange={(value) => {
                    setCheckedList(value);
                }}
            />
            <Table
                style={{
                    // overflow: "auto",
                    scrollbarWidth: "none",
                }}
                scroll={{ x: 2400, y: "60vh" }}
                size="small"
                pagination={false}
                dataSource={items?.map((v, i) => {
                    return {
                        key: i,
                        name: v?.name,
                        text: v?.zones?.find((v) => v.text)?.text || [],
                        image: v?.zones?.find((v) => v.img)?.img?.asset,
                        imgWidth: v?.zones?.find((v) => v.img)?.width,
                        imgHeight: v?.zones?.find((v) => v.img)?.height,
                        // background: v?.bg,
                        // weight: v?.value,
                        category: v?.category?.length ? v?.category : [],
                        order: v.zones
                            ?.filter(
                                (v) => v.showImg || v.showText || v.showItems,
                            )
                            ?.map((v) =>
                                v.text ? "text" : v.img ? "image" : "drop",
                            ),
                        feedback_type: v?.feedback_type,
                        check_type: v?.evaluationConfig?.mid?.evaluationType,
                        has_highlight:
                            v?.evaluationConfig?.start?.highlight?.enable,
                        has_stroke: v?.evaluationConfig?.end?.stroke?.enable,
                        has_fills: v?.evaluationConfig?.end?.fills?.enable,
                        disabled: v?.disabled,
                        maxItems: v?.maxItems,
                        value: v?.value,
                        default_tags:
                            v?.zones
                                ?.find((v: { items: any }) => v.items)
                                ?.items.map((op: any) =>
                                    JSON.stringify(
                                        tagList.find(
                                            (tg) => op.tagId === tg.tagId,
                                        ) || { tagId: op.tagId },
                                    ),
                                ) || [],
                        link_variable: v?.linked_global_context_variable?.name,
                        alignItems: v?.zones?.find(
                            (v: { schema: any }) => v.schema,
                        )?.alignItems,
                        width: v?.zones?.find((v: { schema: any }) => v.schema)
                            ?.width,
                    };
                })}
                columns={[
                    {
                        title: "Index",
                        dataIndex: "key",
                        width: 60,
                        render: (v) => v + 1,
                    },
                    {
                        title: "Name",
                        dataIndex: "name",
                        width: 120,
                        render: (v, data) => {
                            return (
                                <Input
                                    disabled={disableWrite}
                                    style={{
                                        minWidth: "100px",
                                    }}
                                    value={v}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].name =
                                            e.target.value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Text",
                        dataIndex: "text",
                        width: 200,
                        render: (v, data) => {
                            return (
                                <div
                                    style={{
                                        minWidth: "150px",
                                    }}
                                >
                                    <SlateEditor
                                        disabled={disableWrite}
                                        hasMentions={hasMentions}
                                        mentionsList={mentionsList}
                                        id={`${data.key}_tag_edit`}
                                        onChange={(value: any) => {
                                            let tmpBlock = cloneDeep(items);
                                            // tmpBlock[data?.key].text = value;
                                            tmpBlock[data?.key].zones =
                                                tmpBlock[data?.key].zones?.map(
                                                    (v) => {
                                                        if (v.text)
                                                            v.text = value;
                                                        return v;
                                                    },
                                                );
                                            setBlock(tmpBlock);
                                        }}
                                        isFocused={
                                            currentEditor ===
                                            `${data.key}_tag_edit`
                                        }
                                        setEditor={(id: string) =>
                                            setCurrentEditor(id)
                                        }
                                        value={v}
                                        required={false}
                                    />
                                </div>
                            );
                        },
                    },
                    {
                        title: "Image",
                        dataIndex: "image",
                        width: 120,
                        render: (v, data) => {
                            return (
                                <>
                                    <RenderUpload
                                        singleUpload={true}
                                        disabled={disableWrite}
                                        path="home-explore/document/"
                                        onChangeCustom={(e) => {
                                            const value = e.target.value;
                                            let tmpBlock = cloneDeep(items);
                                            // tmpBlock[data?.key].asset = value;
                                            tmpBlock[data?.key].zones =
                                                tmpBlock[data?.key].zones?.map(
                                                    (v) => {
                                                        if (v.img)
                                                            v.img.asset = value;
                                                        return v;
                                                    },
                                                );
                                            setBlock(tmpBlock);
                                        }}
                                        label=""
                                        value={v}
                                    />
                                    <Input
                                        disabled={disableWrite}
                                        placeholder="Add value here"
                                        value={v}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            let tmpBlock = cloneDeep(items);
                                            // tmpBlock[data?.key].asset = value;
                                            tmpBlock[data?.key].zones =
                                                tmpBlock[data?.key].zones?.map(
                                                    (v) => {
                                                        if (v.img)
                                                            v.img.asset = value;
                                                        return v;
                                                    },
                                                );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </>
                            );
                        },
                    },
                    {
                        title: "Image Dimensions",
                        dataIndex: "dimensions",
                        width: 120,
                        render: (v, data) => {
                            return (
                                <>
                                    <Form.Item
                                        label="Width"
                                        // validateStatus={
                                        //     !data?.width ? "error" : ""
                                        // }
                                        // help={
                                        //     !data?.width
                                        //         ? "Field cannot be empty"
                                        //         : ""
                                        // }
                                    >
                                        <Input
                                            type="number"
                                            style={{
                                                minWidth: "100px",
                                            }}
                                            disabled={disableWrite}
                                            value={data?.imgWidth}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[data?.key].zones =
                                                    tmpBlock[
                                                        data?.key
                                                    ].zones?.map((v) => {
                                                        if (v.img)
                                                            v.width = value;
                                                        return v;
                                                    });
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Height"
                                        // validateStatus={
                                        //     !data?.height ? "error" : ""
                                        // }
                                        // help={
                                        //     !data?.height
                                        //         ? "Field cannot be empty"
                                        //         : ""
                                        // }
                                    >
                                        <Input
                                            type="number"
                                            style={{
                                                minWidth: "100px",
                                            }}
                                            disabled={disableWrite}
                                            value={data?.imgHeight}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[data?.key].zones =
                                                    tmpBlock[
                                                        data?.key
                                                    ].zones?.map((v) => {
                                                        if (v.img)
                                                            v.height = value;
                                                        return v;
                                                    });
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                </>
                            );
                        },
                    },
                    {
                        title: "Order",
                        dataIndex: "order",
                        width: 120,
                        render: (v, data) => (
                            <Form.Item
                                validateStatus={!v?.length ? "error" : ""}
                                help={!v?.length ? "Field cannot be empty" : ""}
                            >
                                <Select
                                    disabled={disableWrite}
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    value={v}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].zones =
                                            sortAndUpdate(
                                                tmpBlock[data?.key].zones,
                                                val,
                                            );
                                        setBlock(tmpBlock);
                                    }}
                                    options={[
                                        {
                                            value: "text",
                                            label: "Text",
                                        },
                                        {
                                            value: "image",
                                            label: "Image",
                                        },
                                        {
                                            value: "drop",
                                            label: "Drop",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        ),
                    },
                    {
                        title: "Align Items",
                        dataIndex: "alignItems",
                        width: 120,
                        show:
                            showColumns.showAlignItems != null
                                ? checkedList.includes("showAlignItems")
                                : false,
                        render: (v, data) => {
                            return (
                                <Select
                                    value={v}
                                    placeholder="Select type"
                                    options={[
                                        { label: "Left", value: "MIN" },
                                        {
                                            label: "Center",
                                            value: "CENTER",
                                        },
                                        { label: "Right", value: "MAX" },
                                    ]}
                                    style={{
                                        width: "100px",
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].zones = tmpBlock[
                                            data?.key
                                        ].zones?.map((v) => {
                                            if (v.schema) v.alignItems = val;
                                            return v;
                                        });
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                            );
                        },
                    },
                    {
                        title: "Width",
                        dataIndex: "width",
                        width: 120,
                        render: (v, data) => {
                            return (
                                <Form.Item
                                    label="Width"
                                    validateStatus={!v ? "error" : ""}
                                    help={!v ? "Field cannot be empty" : ""}
                                >
                                    <Input
                                        disabled={disableWrite}
                                        style={{
                                            minWidth: "50px",
                                        }}
                                        type="number"
                                        value={v}
                                        onChange={(e) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[data?.key].zones =
                                                tmpBlock[data?.key].zones?.map(
                                                    (v) => {
                                                        if (v.schema)
                                                            v.width =
                                                                e.target.value;
                                                        return v;
                                                    },
                                                );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            );
                        },
                    },
                    {
                        title: "Categories",
                        dataIndex: "category",
                        width: 250,
                        render: (v, data) => {
                            return (
                                <>
                                    <Form.Item
                                        validateStatus={
                                            !v?.length ? "error" : ""
                                        }
                                        help={
                                            !v?.length
                                                ? "Field cannot be empty"
                                                : ""
                                        }
                                    >
                                        <Select
                                            disabled={disableWrite}
                                            mode="tags"
                                            style={{ width: "100%" }}
                                            placeholder="Select"
                                            value={v}
                                            onChange={(val) => {
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[data?.key].category =
                                                    val;
                                                setBlock(tmpBlock);
                                            }}
                                            options={Array.from(
                                                new Set(
                                                    items
                                                        .map((i) => i.category)
                                                        .flat(1),
                                                ),
                                            ).map((i) => ({
                                                value: i,
                                                label: i,
                                            }))}
                                        />
                                    </Form.Item>
                                    <br />
                                    <br />
                                    <Button
                                        disabled={disableWrite}
                                        onClick={() => {
                                            const key = "category";
                                            let tmpBlock = cloneDeep(items);
                                            const value =
                                                tmpBlock[data?.key][key];
                                            setBlock(
                                                tmpBlock.map((i) => ({
                                                    ...i,
                                                    [key]: value,
                                                })),
                                            );
                                        }}
                                        size="small"
                                        type="primary"
                                    >
                                        Apply to all
                                    </Button>
                                </>
                            );
                        },
                    },
                    {
                        title: "Linked Variable",
                        dataIndex: "link_variable",
                        width: 250,
                        show:
                            showColumns.showLinkedVariables != null
                                ? checkedList.includes("showLinkedVariables")
                                : false,
                        render: (v, data) =>
                            renderLinkSelect
                                ? renderLinkSelect({
                                      value: v,
                                      onChange: (value: any) => {
                                          let tmpBlock = cloneDeep(items);
                                          tmpBlock = set(
                                              tmpBlock,
                                              [
                                                  data.key,
                                                  "linked_global_context_variable",
                                                  "name",
                                              ],
                                              value,
                                          );
                                          //   tmpBlock = _.set(
                                          //       tmpBlock,
                                          //       [data.key, "value"],
                                          //       value !== null
                                          //           ? `@@${value}@@`
                                          //           : value,
                                          //   );
                                          setBlock(tmpBlock);
                                      },
                                      style: {
                                          width: 230,
                                      },
                                      next_line: true,
                                  })
                                : "",
                    },
                    {
                        title: "Value",
                        dataIndex: "value",
                        width: 140,
                        show:
                            showColumns.showValue != null
                                ? checkedList.includes("showValue")
                                : false,
                        render: (v, data) => {
                            return (
                                <Select
                                    value={v}
                                    placeholder="Select type"
                                    options={[
                                        {
                                            label: "none",
                                            value: null,
                                        },
                                        ...mentionsList?.map((v) => ({
                                            label: v.text,
                                            value: `@@${v.text}@@`,
                                        })),
                                    ]}
                                    style={{
                                        width: 120,
                                    }}
                                    onChange={(value) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].value = value;
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                            );
                        },
                    },
                    {
                        title: "Evaluation",
                        dataIndex: "check_type",
                        width: 180,
                        show:
                            showColumns.showEvaluation != null
                                ? checkedList.includes("showEvaluation")
                                : false,
                        render: (v, data) => {
                            return (
                                <Select
                                    disabled={disableWrite}
                                    style={{
                                        maxWidth: "150px",
                                    }}
                                    placeholder="Select"
                                    options={[
                                        {
                                            label: "One by one",
                                            value: "ONE_BY_ONE",
                                        },
                                        {
                                            label: "One by one till first incorrect",
                                            value: "ONE_BY_ONE_TILL_FIRST_INCORRECT",
                                        },
                                        {
                                            label: "All in one go",
                                            value: "ALL_IN_ONE_GO",
                                        },
                                    ]}
                                    value={v}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                data?.key,
                                                "evaluationConfig",
                                                "mid",
                                                "evaluationType",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Feedback Type",
                        dataIndex: "feedback_type",
                        width: 180,
                        show:
                            showColumns.showFeedbackType != null
                                ? checkedList.includes("showFeedbackType")
                                : false,
                        render: (v, data) => {
                            return (
                                <Select
                                    disabled={disableWrite}
                                    style={{
                                        maxWidth: "150px",
                                    }}
                                    placeholder="Select"
                                    options={[
                                        {
                                            label: "No Feedback",
                                            value: "NO_FEEDBACK",
                                        },
                                        {
                                            label: "Manual Audio",
                                            value: "MANUAL_AUDIO",
                                        },
                                        {
                                            label: "Manual Story",
                                            value: "MANUAL_STORY",
                                        },
                                        {
                                            label: "Generated Audio",
                                            value: "GENERATED_AUDIO",
                                        },
                                        {
                                            label: "Generated Story",
                                            value: "GENERATED_STORY",
                                        },
                                    ]}
                                    value={v}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].feedback_type = val;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Drop limit",
                        dataIndex: "maxItems",
                        width: 120,
                        render: (v, data) => {
                            return (
                                <Input
                                    disabled={disableWrite}
                                    type="number"
                                    style={{
                                        minWidth: "100px",
                                    }}
                                    value={v}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].maxItems =
                                            e.target.value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Drop",
                        dataIndex: "default_tags",
                        show:
                            showColumns.showDefaultTags != null
                                ? checkedList.includes("showDefaultTags")
                                : false,
                        width: 100,
                        render: (v, data) => (
                            <Select
                                disabled={disableWrite}
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Select"
                                value={v}
                                onChange={(val) => {
                                    let tmpBlock = cloneDeep(items);
                                    tmpBlock[data?.key].zones = tmpBlock[
                                        data?.key
                                    ].zones?.map((v) => {
                                        if (v.items)
                                            v.items = val.map((op: any) =>
                                                JSON.parse(op),
                                            );
                                        return v;
                                    });
                                    setBlock(tmpBlock);
                                }}
                                options={tagList.map((v: { name: any }) => ({
                                    label: v.name,
                                    value: JSON.stringify(v),
                                }))}
                            />
                        ),
                    },
                    {
                        title: "Answer Tags",
                        dataIndex: "answer_tags",
                        show: true,
                        width: 100,
                        render: (v: any, data: any) => {
                            console.log("here in v", v);

                            const onChange = (val: any) => {
                                let tmpBlock = cloneDeep(items);
                                console.log("here val 2", val);
                                tmpBlock[data?.key].answer_tags = tmpBlock[
                                    data?.key
                                ].answer_tags = val;
                                setBlock(tmpBlock);
                            };

                            const getOptionsWithCounts = () => {
                                return tagList.map((v: any) => {
                                    const count = selectionCounts[v.name] || 0;
                                    const label =
                                        count > 0
                                            ? `${v.name} (${count + 1})`
                                            : v.name;

                                    return {
                                        label,
                                        value: JSON.stringify({
                                            ...v,
                                            uniqueId: Date.now(),
                                        }),
                                    };
                                });
                            };

                            const handleChange = (selectedValues: string[]) => {
                                // Parse the selected values
                                const parsedValues = selectedValues.map((val) =>
                                    JSON.parse(val),
                                );

                                // Update selection counts
                                const newCounts: { [key: string]: number } = {};
                                parsedValues.forEach((val) => {
                                    const name = val.name;
                                    newCounts[name] =
                                        (newCounts[name] || 0) + 1;
                                });
                                setSelectionCounts(newCounts);

                                // If using with the block structure from original code
                                if (data?.key && onChange) {
                                    let tmpBlock = cloneDeep(items);
                                    tmpBlock[data.key].answer_tags =
                                        parsedValues;
                                    onChange(parsedValues);
                                } else if (onChange) {
                                    onChange(parsedValues);
                                }
                            };
                            return (
                                <>
                                    <Select
                                        disabled={disableWrite}
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder="Select"
                                        value={v}
                                        onChange={handleChange}
                                        options={getOptionsWithCounts()}
                                        // Allow selecting the same option multiple times
                                        filterOption={(input, option: any) =>
                                            option?.label
                                                ?.toLowerCase?.()
                                                ?.indexOf(
                                                    input.toLowerCase(),
                                                ) >= 0
                                        }
                                    />
                                </>
                            );
                        },
                    },
                    {
                        title: "Has Highlight?",
                        dataIndex: "has_highlight",
                        show:
                            showColumns.showHightlight != null
                                ? checkedList.includes("showHightlight")
                                : false,
                        render: (v, data) => {
                            return (
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={v}
                                    onChange={(value) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                data?.key,
                                                "evaluationConfig",
                                                "start",
                                                "highlight",
                                                "enable",
                                            ],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Has Feedback Stroke?",
                        dataIndex: "has_stroke",
                        show:
                            showColumns.showFeedbackStroke != null
                                ? checkedList.includes("showFeedbackStroke")
                                : false,
                        render: (v, data) => {
                            return (
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={v}
                                    onChange={(value) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                data?.key,
                                                "evaluationConfig",
                                                "end",
                                                "stroke",
                                                "enable",
                                            ],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Has Feedback Fill?",
                        dataIndex: "has_fills",
                        show:
                            showColumns.showFeedbackFill != null
                                ? checkedList.includes("showFeedbackFill")
                                : false,
                        render: (v, data) => {
                            return (
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={v}
                                    onChange={(value) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                data?.key,
                                                "evaluationConfig",
                                                "end",
                                                "fills",
                                                "enable",
                                            ],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Is enabled?",
                        dataIndex: "disabled",
                        show:
                            showColumns.showIsEnabled != null
                                ? checkedList.includes("showIsEnabled")
                                : false,
                        render: (v, data) => {
                            return (
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={!v}
                                    onChange={(value) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].disabled = !value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Actions",
                        key: "operation",
                        fixed: "right",
                        width: 100,
                        render: (v, data) => (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                }}
                            >
                                <CopyIcon
                                    disabled={disableWrite}
                                    isButton={true}
                                    onClick={() => {
                                        let tmpItems = cloneDeep(items);
                                        tmpItems.splice(data.key, 0, {
                                            ...cloneDeep(tmpItems[data.key]),
                                            itemId: uuid(),
                                        });
                                        setBlock(tmpItems);
                                        message.info(`Input duplicated!`);
                                    }}
                                />
                                <DeleteIcon
                                    title="Delete this?"
                                    disabled={disableWrite}
                                    onConfirm={() => {
                                        let tmpBlock = cloneDeep(items);
                                        pullAt(tmpBlock, data.key);
                                        setBlock(tmpBlock);
                                        message.success("successfully removed");
                                    }}
                                />
                            </div>
                        ),
                    },
                ].filter((item) => item.show ?? true)}
                rowKey="index"
                bordered
                footer={() => (
                    <>
                        <Button
                            disabled={disableWrite}
                            onClick={() => {
                                let tmpItems = cloneDeep(items);
                                tmpItems.push({ ...schema, itemId: uuid() });
                                setBlock(tmpItems);
                            }}
                        >
                            Add Item
                        </Button>
                        {!items?.length && (
                            <span
                                style={{
                                    color: "red",
                                    marginLeft: "10px",
                                    fontSize: "16px",
                                }}
                            >
                                No Items Added
                            </span>
                        )}
                    </>
                )}
            />
        </>
    );
};

export default ItemsTable;
