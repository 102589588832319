import { Route } from "react-router-dom";
import CreateHighlight from "./CreateHighlight";
import HighlightWrapper from ".";

const Comp = () => {
    const containerStyle = {
        display: "grid",
        gridTemplateColumns: "repeat(4, minmax(200px, 1fr))",
        gridGap: "20px",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100vw",
        minWidth: "min-content",
        background: "#f1f1f1",
        // padding: "20px",
        boxSizing: "border-box",
        // overflow: "hidden",
    };

    const elementStyle = {
        fontSize: "16px",
        marginBottom: "5px",
        padding: "10px",
        backgroundColor: "#ffffff",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
    };

    return (
        <div style={containerStyle as any}>
            <div id="1" style={elementStyle as any}>
                Text 1
            </div>
            {/* <div className="arrow z-10" /> */}
            <div id="2" style={elementStyle as any}>
                Text 2
            </div>
            <div id="3" style={elementStyle as any}>
                Text 3
            </div>
            <div id="4" style={elementStyle as any}>
                Text 4
            </div>
            <div id="5" style={elementStyle as any}>
                Text 5
            </div>
            <div id="6" style={elementStyle as any}>
                Text 6
            </div>
            <div id="7" style={elementStyle as any}>
                Text 7
            </div>
            <div id="8" style={elementStyle as any}>
                Text 8
            </div>
            <div id="9" style={elementStyle as any}>
                Text 9
            </div>
            <div id="10" style={elementStyle as any}>
                Text 10
            </div>
        </div>
    );
};

const Test = () => {
    return (
        <HighlightWrapper>
            <CreateHighlight
                ids={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
            >
                <Comp />
            </CreateHighlight>
        </HighlightWrapper>
    );
};

export const TEST_ROUTE = [
    <Route key="test" path="/test" element={<Test />} />,
];

export default Test;
