import React from "react";
import { Spin, Select } from "antd";

import { compose, config } from "src/helpers";
import { Field, RenderSelect } from "src/components";

import { withConcepts } from "src/modules/concept/operations";

import { withStateAndActionsForConcept } from "../resolvers";

const Option = Select.Option;

const SubjectsField = (props) => {
    const { loading, concepts, where, onNameChange, onChange, value } = props;

    return (
        <Field
            labelInValue
            showSearch={true}
            filterOption={false}
            onSearch={onNameChange}
            notFoundContent={loading ? <Spin size="small" /> : null}
            name={"concepts"}
            component={RenderSelect}
            placeholder={"Concepts"}
            label={"Concepts"}
            value={value || "None"}
            loading={loading}
            onChange={onChange}
            inFilter={true}
            noBotMarging={true}
        >
            {[
                // config.EMPTY_OPTION,
                ...(concepts?.map((concept) => ({
                    id: concept.id,
                    label: concept.name || "",
                    value: JSON.stringify(concept),
                })) || []),
            ]?.map(
                ({
                    id,
                    value,
                    label = "",
                }: {
                    id: string;
                    value: string;
                    label: string;
                }) => (
                    <Option key={id} value={value}>
                        {label}
                    </Option>
                ),
            )}
        </Field>
    );
};

export default compose(
    withStateAndActionsForConcept,
    withConcepts,
)(SubjectsField);
