import { Card } from "antd";
import { renderer } from "../../helpers/";

const renderBlock = (block: any) => {
    const {
        data: { text },
    } = block;
    return (
        <div>
            <Card>{renderer(text)}</Card>
        </div>
    );
};

export default renderBlock;
