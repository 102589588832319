import { gql } from "@apollo/client";export default gql`mutation DeleteContentsByCollectionID($submissionIds: [Int]!, $id: Int!) {
    delete_submission_question(where: { id: { _in: $submissionIds } }) {
        affected_rows
    }
    delete_submission(where: { collection_id: { _eq: $id } }) {
        affected_rows
    }

    delete_collection_content(where: { collection_id: { _eq: $id } }) {
        affected_rows
    }
}
`;
