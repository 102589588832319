import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography, Modal, List, Divider } from "antd";
import moment from "moment";
import { useState } from "react";

const { Text, Title } = Typography;

const ActiveUsersModal = (props: any) => {
    const {
        isSaving,
        activeUsers = [],
        isReadOnlyMode,
        setActiveEditor,
        disabled,
        isRead,
    } = props;

    const {
        user: { "https://hasura.io/jwt/claims/user_id": currentUserId } = {},
    } = useAuth0();

    const [isModalOpen, setIsModalOpen] = useState(isReadOnlyMode);

    const isClosable = true;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                type={"primary"}
                block
                disabled={isSaving}
                onClick={showModal}
                danger={isRead}
            >
                Active Users ({activeUsers?.length})
            </Button>
            <Modal
                title="Active Users"
                style={{ top: 20 }}
                open={isModalOpen || !isClosable}
                onOk={() => hideModal()}
                onCancel={() => hideModal()}
                footer={null}
                closable={isClosable}
                maskClosable={isClosable}
                width={600}
                destroyOnClose
            >
                {!isClosable && (
                    <Title type="danger" level={5}>
                        Someone Else is editing the worksheet right now!
                    </Title>
                )}
                <List
                    size="large"
                    bordered
                    dataSource={[
                        ...activeUsers.filter(
                            (user: { user_id: any }) =>
                                user.user_id === currentUserId,
                        ),
                        ...activeUsers.filter(
                            (user: { user_id: any }) =>
                                user.user_id !== currentUserId,
                        ),
                    ]}
                    renderItem={(
                        { name, email, last_seen, is_editor, user_id }: any,
                        index: number,
                    ) => (
                        <List.Item key={index} actions={[<span>{email}</span>]}>
                            <List.Item.Meta
                                title={
                                    <>
                                        {is_editor ? (
                                            <>
                                                {name}{" "}
                                                <Text type="success">
                                                    Current Editor
                                                </Text>
                                            </>
                                        ) : (
                                            name
                                        )}
                                    </>
                                }
                                description={
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "15px",
                                        }}
                                    >
                                        {user_id === currentUserId
                                            ? "Currently active"
                                            : `Last Seen -
                                        ${moment(last_seen).fromNow()}`}

                                        {!disabled &&
                                            currentUserId === user_id &&
                                            !is_editor && (
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        if (setActiveEditor)
                                                            setActiveEditor();
                                                    }}
                                                >
                                                    Get Edit Access
                                                </Button>
                                            )}
                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    );
};

export default ActiveUsersModal;
