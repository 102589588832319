import React, { useState, useEffect } from "react";
import { Button, Col, Row, Input, Space } from "antd";
import _ from "lodash";

import { SlateEditor } from "src/components/plate";
import { DeleteOutlined } from "@ant-design/icons";
import { AudioPicker, DurationPicker } from "../../common";
import VideoField from "../../common/VideoField";
import ColorField from "../../common/ColorField";

const V2_CONTENT_BLOCK = "v2_content";

const renderEditorContent = (
    {
        block,
        setBlock,
        isEditor,
        setIsEditor,
        currentBlock,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        isReadOnlyMode,
    }: any,
    isMCQ = true,
) => {
    const {
        id,
        tmpId,
        data: {
            [V2_CONTENT_BLOCK]: { text, options, video_url },
        },
    } = block;

    const videoUrl = block?.data[V2_CONTENT_BLOCK]?.video_url;

    const [currentEditor, setCurrentEditor] = useState(`${id || tmpId}_text`);
    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_text`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const onDurationChange = (duration: number) => {
        const obj = {
            ...block,
            data: { ...block.data, other: { ...block.data.other, duration } },
        };
        setBlock(obj);
    };

    return (
        <>
            <div>
                <Row style={{ width: "100%" }}>
                    <Col span={22}>
                        <SlateEditor
                            id={`${id || tmpId}_text`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", V2_CONTENT_BLOCK, "text"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            value={text}
                            isFocused={currentEditor == `${id || tmpId}_text`}
                            setEditor={(id: string) => setCurrentEditor(id)}
                        />
                    </Col>
                </Row>

                <Row justify="space-between">
                    <Col>
                        <h3 style={{ padding: "0px 24px", fontWeight: "bold" }}>
                            Options
                        </h3>
                    </Col>
                </Row>
                <div>
                    {options.map((option: any, idx: number) => (
                        <Space.Compact style={{ width: "100%" }}>
                            <Input
                                key={idx}
                                onChange={(e: any) => {
                                    const value = e.target.value;

                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            V2_CONTENT_BLOCK,
                                            "options",
                                            idx,
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                value={option}
                                style={{ margin: "10px" }}
                                addonBefore={`Option ${idx + 1}`}
                            />
                            <Button
                                icon={<DeleteOutlined />}
                                type="primary"
                                shape="circle"
                                danger
                                size="small"
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(block);
                                    const tmpOptions = [...options];
                                    _.pullAt(tmpOptions, idx);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", V2_CONTENT_BLOCK, "options"],
                                        tmpOptions,
                                    );

                                    setBlock(tmpBlock);
                                }}
                            />
                        </Space.Compact>
                    ))}

                    {options.length < 2 && (
                        <Button
                            type="dashed"
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        V2_CONTENT_BLOCK,
                                        "options",
                                        options.length,
                                    ],
                                    "",
                                );
                                setIsNewBlock(true);
                                setBlock(tmpBlock);
                                setCurrentEditor(
                                    `${id || tmpId}_option_${options.length}`,
                                );
                            }}
                            style={{ margin: "0px 24px" }}
                        >
                            Add Option
                        </Button>
                    )}
                </div>
                <br />

                <VideoField
                    label={`Solution Video URL`}
                    path={["data", "other", "solution_video"]}
                    block={block}
                    setBlock={setBlock}
                    disabled={isReadOnlyMode}
                />

                <VideoField
                    label={`Explanation Video URL`}
                    path={["data", V2_CONTENT_BLOCK, "video_url"]}
                    block={block}
                    setBlock={setBlock}
                    disabled={isReadOnlyMode}
                />

                <ColorField
                    label={`Background Color`}
                    path={["data", V2_CONTENT_BLOCK, "background_color"]}
                    block={block}
                    setBlock={setBlock}
                />

                {["timed", "personalized_learning"].includes(
                    worksheet?.type,
                ) && (
                    <div
                        style={{
                            border: "1px dashed #808080",
                            margin: "10px auto",
                            padding: "10px 10px",
                        }}
                    >
                        <h3>Duration</h3>
                        <DurationPicker
                            onChange={onDurationChange}
                            initialValue={block?.data?.other?.duration || 0}
                        />
                    </div>
                )}

                {["personalized_learning"].includes(worksheet?.type) && (
                    <div
                        style={{
                            border: "1px dashed #808080",
                            margin: "10px auto",
                            padding: "10px 10px",
                        }}
                    >
                        <h3>Background Audio</h3>
                        <AudioPicker
                            block={block}
                            disabled={isReadOnlyMode}
                            setBlock={setBlock}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default renderEditorContent;
