import { BLOCK_TYPES } from "../../common/index";
import getV2FillBlankDefaultValue from "../V2FillBlank/defaultValue";
const { V2_MIXED_BLOCK } = BLOCK_TYPES;

const V2_MIXED_DEFAULT_VALUE = {
    type: V2_MIXED_BLOCK,
    data: {
        [V2_MIXED_BLOCK]: {
            text: [],
        },
        other: {
            duration: 0,
            audio: "",
            difficulty_level: "medium",
            solution_video: "",
        },
        children: [getV2FillBlankDefaultValue()],
    },
};

export default V2_MIXED_DEFAULT_VALUE;
