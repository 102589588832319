import CancelIcon from "src/components/CancelIcon";
import { Form, Input, Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { AudioFilled } from "@ant-design/icons";
const { TextArea } = Input;
const FormItem = Form.Item;
import LatexSpeechToTextModal from "./LatexSpeechToTextModal";

export const LatexInsertComponent = (props: any) => {
    const { onChange, onDelete, value } = props;
    const inputRef = useRef<any>();
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    // Solving Caret Jumping in React Inputs
    const [val, setVal] = useState(value);
    const updateVal = (val: any) => {
        /* Make update synchronous, to avoid caret jumping when the value doesn't change asynchronously */
        setVal(val);
        /* Make the real update afterwards */
        onChange(val);
    };

    return (
        <div style={{ display: "flex" }}>
            <Form layout={"inline"} component={false}>
                <FormItem
                    className="latex-insert-form-item"
                    style={{ width: "80%" }}
                >
                    <TextArea
                        placeholder="type/paste latex"
                        value={val}
                        onChange={(e) => updateVal(e.target.value)}
                        ref={inputRef}
                        autoSize
                    />
                </FormItem>

                {/* <FormItem className="latex-insert-form-item">
          <Button
            type="primary"
            shape="circle"
            icon={<Save />}
            style={{ padding: "2px" }}
            onClick={() => {onChange(latex);}}
          />
        </FormItem> */}
                {/* <FormItem
                    className="latex-insert-form-item"
                    style={{ marginLeft: "10px" }}
                >
                    <Button
                        icon={
                            <AudioFilled
                                onClick={() => {
                                    setModalShow(true);
                                }}
                            />
                        }
                        shape="circle"
                        {...props}
                    />
                </FormItem> */}
                <FormItem
                    className="latex-insert-form-item"
                    style={{ marginLeft: "10px" }}
                >
                    <CancelIcon
                        isButton={true}
                        onClick={() => {
                            onDelete();
                        }}
                    />
                </FormItem>
            </Form>
            {/* <LatexSpeechToTextModal
                title="Add Equeation"
                show={modalShow}
                onHide={() => {
                    setModalShow(false);
                }}
                submitText="Copy Equeation"
            /> */}
        </div>
    );
};
