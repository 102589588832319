import {
    Col,
    Form,
    Modal,
    Row,
    Select,
    Switch,
    Typography,
    message,
} from "antd";
import { Button } from "antd";
import React, { useState } from "react";
import { BLOCK_TYPES } from "../../../common";
import KeyboardForm from "src/components/CustomKeyboardEditor/KeyboardForm";
import {
    DEFAULT_ALPHABETS_KEYBOARD,
    DEFAULT_DIGITS_KEYBOARD,
    DEFAULT_LATEX_KEYBOARD,
    DEFAULT_SETTINGS_KEYBOARD,
    DEFAULT_SYMBOLS_KEYBOARD,
} from "src/components/CustomKeyboardEditor/constants";

const { ALL_IN_ONE, V2_STORIES_BLOCK } = BLOCK_TYPES;

const KeyboardModal = (props: any) => {
    const { blocks, setBlock, disableWrite } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLatex, setIsLatex] = useState(false);
    const [blocksList, setBlocksList] = useState([]);
    const [digits, setDigits] = useState([]);
    const [symbols, setSymbols] = useState([]);
    const [alphabets, setAlphabets] = useState([]);
    const [latex, setLatex] = useState([]);
    const [settings, setSettings] = useState({ ...DEFAULT_SETTINGS_KEYBOARD });

    const showModal = () => {
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setIsModalOpen(false);
        setIsLatex(false);
        setBlocksList([]);
        setDigits([]);
        setSymbols([]);
        setAlphabets([]);
        setLatex([]);
        setSettings({ ...DEFAULT_SETTINGS_KEYBOARD });
    };

    const handleSubmit = () => {
        const customKeyboardValue = [];

        if (digits.length)
            customKeyboardValue.push({
                ...DEFAULT_DIGITS_KEYBOARD,
                keys: digits,
            });
        if (!isLatex && symbols.length)
            customKeyboardValue.push({
                ...DEFAULT_SYMBOLS_KEYBOARD,
                keys: symbols,
            });

        if (alphabets.length)
            customKeyboardValue.push({
                ...DEFAULT_ALPHABETS_KEYBOARD,
                keys: alphabets,
            });

        if (isLatex && latex?.length)
            customKeyboardValue.push({
                ...DEFAULT_LATEX_KEYBOARD,
                keys: latex,
            });

        customKeyboardValue.push(settings);

        setBlock({
            blocksList,
            keyboard: customKeyboardValue,
            isLatex,
        });
        closeForm();
        message.success("Success!");
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Update keyboard for blocks
            </Button>
            <Modal
                title={
                    <Typography.Title level={4}>
                        Update Keyboard for blocks
                    </Typography.Title>
                }
                onCancel={closeForm}
                onOk={handleSubmit}
                open={isModalOpen}
                destroyOnClose
                width={600}
                maskClosable={false}
                style={{ top: 10 }}
            >
                <Row
                    style={{
                        maxHeight: "75vh",
                        overflowY: "auto",
                        overflowX: "hidden",
                        scrollbarWidth: "thin",
                    }}
                    gutter={[20, 20]}
                >
                    <Col span={24}>
                        <Form.Item label={"Blocks"}>
                            <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Select"
                                value={blocksList}
                                onChange={(val) => {
                                    setBlocksList(val);
                                }}
                                options={blocks?.map((v, i) => ({
                                    value: i,
                                    label: `B${i + 1}: ${
                                        v?.data[ALL_IN_ONE]?.name ||
                                        v?.data[V2_STORIES_BLOCK]?.name
                                    }`,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={"Has Latex?"}>
                            <Switch
                                onChange={(val) => {
                                    setIsLatex(val);
                                }}
                                disabled={disableWrite}
                                checked={isLatex}
                            />
                        </Form.Item>
                        <Form.Item label={"Keyboard"}>
                            <KeyboardForm
                                {...{
                                    hasLatex: isLatex,
                                    digits,
                                    setDigits,
                                    symbols,
                                    setSymbols,
                                    alphabets,
                                    setAlphabets,
                                    latex,
                                    setLatex,
                                    settings,
                                    setSettings,
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default KeyboardModal;
