import { isEditorEmpty, isUrl } from "../../common";

const isValidCallbackContent = ({ block }: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            content: {
                title,
                description,
                button: {
                    buttonText,
                    buttonType, // internal/external
                    buttonUrl,
                },
            },
        },
    } = block;
    return {
        isValid:
            !isEditorEmpty(title) &&
            !isEditorEmpty(description) &&
            ((buttonType === "external" && isUrl(buttonUrl)) ||
                buttonType === "internal") &&
            Boolean(buttonText.trim()),
        error: <></>,
    };
};

export default isValidCallbackContent;
