import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Select } from "antd";
import { cloneDeep, set } from "lodash";
import { v4 as uuid } from "uuid";
import get from "lodash/get";
import { COMPUTE_CONJUNCTIONS } from "src/modules/worksheet/components/blocks/helpers/constants";

const VARIABLE_OPTIONS = [
    {
        label: "Question Number (in the current student level)",
        value: "question_number",
    },
    {
        label: "Student Level",
        value: "student_level",
    },

    {
        label: "Correct Count",
        value: "correct_count",
    },
];

export const DIFFICULTY_OPTIONS = [
    {
        value: "easy",
        label: "Easy",
    },
    {
        value: "medium",
        label: "Medium",
    },
    {
        value: "hard",
        label: "Hard",
    },
];
const getOptionValues = (item: any, block: any, studentLevel: string) => {
    const children = block?.children
        ?.map((_: any, idx: number) => ({ ..._, idx: idx }))
        ?.filter(
            (child: any) =>
                get(child, ["data", "other", "difficulty_level"]) ===
                studentLevel,
        );

    if (item.variable === "question_number") {
        return children.map((v) => ({
            label: v.idx + 1,
            value: v.idx,
        }));
    }

    if (item.variable === "correct_count") {
        return Array(children?.length)
            .fill()
            .map((v, idx: number) => ({ label: idx + 1, value: idx + 1 }));
    }

    return DIFFICULTY_OPTIONS;
};

const CondiionsFormCard = (props: any) => {
    const {
        condition,
        conditionIndex,
        blockId,
        blocks,
        setBlock,
        block,
        conditions,
    } = props;

    return (
        <Card
            title={`Rule ${conditionIndex + 1}:`}
            extra={
                <Button
                    type="text"
                    danger
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        const tempConditions = [...conditions];

                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["data", "logic_difficulty", "conditions"],
                            tempConditions.filter(
                                (v, i) => i !== conditionIndex,
                            ),
                        );

                        setBlock(tmpBlock, blockId);
                    }}
                >
                    Delete this rule
                </Button>
            }
            bordered={false}
            bodyStyle={{
                padding: 0,
            }}
            headStyle={{
                padding: 0,
            }}
        >
            {condition?.rules.map(
                (
                    item: {
                        conjunction: any;
                        isEqual: any;
                        option: any;
                        variable: string;
                    },
                    ci: number,
                ) => (
                    <Card
                        bodyStyle={{
                            padding: 0,
                        }}
                        headStyle={{
                            padding: 0,
                        }}
                        bordered={false}
                        key={uuid()}
                    >
                        {ci > 0 && (
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue={item?.conjunction}
                                    onChange={(value) => {
                                        const tempItem = cloneDeep(item);

                                        tempItem.conjunction = value;

                                        const tempRules = [...condition?.rules];

                                        tempRules[ci] = tempItem;

                                        const tempConditions =
                                            cloneDeep(conditions);
                                        tempConditions[conditionIndex].rules =
                                            tempRules;

                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "data",
                                                "logic_difficulty",
                                                "conditions",
                                            ],
                                            tempConditions,
                                        );
                                        setBlock(tmpBlock, blockId);
                                    }}
                                    options={COMPUTE_CONJUNCTIONS}
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            label={"If:"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                defaultValue={item.variable}
                                onChange={(value) => {
                                    const tempItem = cloneDeep(item);

                                    tempItem.variable = value;
                                    tempItem.option = null;

                                    const tempRules = [...condition?.rules];

                                    tempRules[ci] = tempItem;

                                    const tempConditions =
                                        cloneDeep(conditions);
                                    tempConditions[conditionIndex].rules =
                                        tempRules;

                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "data",
                                            "logic_difficulty",
                                            "conditions",
                                        ],
                                        tempConditions,
                                    );
                                    setBlock(tmpBlock, blockId);
                                }}
                                options={VARIABLE_OPTIONS}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Value is:"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                defaultValue={item?.isEqual}
                                onChange={(value) => {
                                    const tempItem = cloneDeep(item);

                                    tempItem.isEqual = value;

                                    const tempRules = [...condition?.rules];

                                    tempRules[ci] = tempItem;

                                    const tempConditions =
                                        cloneDeep(conditions);
                                    tempConditions[conditionIndex].rules =
                                        tempRules;

                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "data",
                                            "logic_difficulty",
                                            "conditions",
                                        ],
                                        tempConditions,
                                    );
                                    setBlock(tmpBlock, blockId);
                                }}
                                options={[
                                    {
                                        value: true,
                                        label: "Equal to",
                                    },
                                    {
                                        value: false,
                                        label: "Not equal to",
                                    },
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            label={"Option:"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                defaultValue={item?.option}
                                onChange={(value) => {
                                    const tempItem = cloneDeep(item);

                                    tempItem.option = value;

                                    const tempRules = [...condition?.rules];

                                    tempRules[ci] = tempItem;

                                    const tempConditions =
                                        cloneDeep(conditions);
                                    tempConditions[conditionIndex].rules =
                                        tempRules;

                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "data",
                                            "logic_difficulty",
                                            "conditions",
                                        ],
                                        tempConditions,
                                    );
                                    setBlock(tmpBlock, blockId);
                                }}
                                options={getOptionValues(
                                    item,
                                    block,
                                    condition?.rules?.filter(
                                        (r) => r.variable == "student_level",
                                    )[0]?.option,
                                )}
                            />
                        </Form.Item>

                        {/* <Button
                            type="text"
                            danger
                            size="small"
                            style={{
                                marginBottom: "20px",
                                float: "right",
                            }}
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                const tempRules = [...condition?.rules].filter(
                                    (v, i) => i !== ci,
                                );

                                let tempConditions = cloneDeep(conditions);

                                if (tempRules.length > 0) {
                                    tempConditions[conditionIndex].rules =
                                        tempRules;
                                } else {
                                    tempConditions = tempConditions.filter(
                                        (_v: any, i: any) =>
                                            i !== conditionIndex,
                                    );
                                }

                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", "logic_difficulty", "conditions"],
                                    tempConditions,
                                );

                                setBlock(tmpBlock, blockId);
                            }}
                        >
                            Delete this condition
                        </Button> */}
                    </Card>
                ),
            )}
            {/* 
            <Form.Item>
                <Button
                    type="link"
                    onClick={() => {
                        const newRule = {
                            variable: "question_number",
                            option: 0,
                            isEqual: true,
                            conjunction: "and",
                        };

                        const tempRules = [...condition?.rules];

                        tempRules.push(newRule);

                        const tempConditions = cloneDeep(conditions);
                        tempConditions[conditionIndex].rules = tempRules;

                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["data", "logic_difficulty", "conditions"],
                            tempConditions,
                        );

                        setBlock(tmpBlock, blockId);
                    }}
                >
                    <PlusCircleOutlined /> Add condition
                </Button>
            </Form.Item> */}

            <Divider />

            <Form.Item
                label={"Change Level To:"}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select
                    defaultValue={condition?.logic_difficulty}
                    onChange={(value) => {
                        const tempConditions = cloneDeep(conditions);
                        tempConditions[conditionIndex].logic_difficulty = value;

                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["data", "logic_difficulty", "conditions"],
                            tempConditions,
                        );
                        setBlock(tmpBlock, blockId);
                    }}
                    options={[
                        {
                            value: null,
                            label: `Dont Change`,
                        },
                        ...DIFFICULTY_OPTIONS,
                    ]}
                />
            </Form.Item>
        </Card>
    );
};

export default CondiionsFormCard;
