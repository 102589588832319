import React, { useEffect } from "react";
import { useRouter } from "src/helpers";
import HomeView from "./component";

const Home: React.FC = (props) => {
    const router = useRouter();
    useEffect(() => {
        const previousUrl = localStorage.getItem("previousUrl");
        if (previousUrl) {
            localStorage.removeItem("previousUrl");
            if (typeof window !== "undefined") router.push(previousUrl);
        }
    }, []);
    return <HomeView {...props} />;
};

export default Home;
