import renderBlockInputAndForm from "./renderBlock";
import renderEditorInputAndForm from "./renderEditor";
import isValidCallbackInputAndForm from "./isValid";
import INPUT_AND_FORM_DEFAULT_VALUE from "./defaultValue";
import renderSettingsInputAndForm from "./renderSettings";
import renderCardInputAndForm from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common";

const { INPUT_AND_FORM_BLOCK } = BLOCK_TYPES;

const INPUT_AND_FORM_BLOCK_CONFIG = {
    [INPUT_AND_FORM_BLOCK]: {
        label: "Input And Form",
        defaultValue: INPUT_AND_FORM_DEFAULT_VALUE,
        renderBlock: renderBlockInputAndForm,
        renderEditor: renderEditorInputAndForm,
        renderCard: renderCardInputAndForm,
        renderSettings: renderSettingsInputAndForm,
        addCallback,
        isValidCallback: isValidCallbackInputAndForm,
    },
};

export {
    renderBlockInputAndForm,
    renderCardInputAndForm,
    renderEditorInputAndForm,
    renderSettingsInputAndForm,
    isValidCallbackInputAndForm,
    INPUT_AND_FORM_DEFAULT_VALUE,
    INPUT_AND_FORM_BLOCK_CONFIG,
};
