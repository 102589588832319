import renderBlockLongAnswer from "./renderBlock";
import renderEditorLongAnswer from "./renderEditor";
import isValidCallbackLongAnswer from "./isValid";
import LONG_ANSWER_DEFAULT_VALUE from "./defaultValue";
import renderSettingsLongAnswer from "./renderSettings";
import renderCardLongAnswer from "./renderCard";
import { BLOCK_TYPES } from "../../common/index";

const { LONG_ANSWER_BLOCK } = BLOCK_TYPES;

const LONG_ANSWER_BLOCK_CONFIG = {
    [LONG_ANSWER_BLOCK]: {
        label: "Long Answer",
        defaultValue: LONG_ANSWER_DEFAULT_VALUE,
        renderBlock: renderBlockLongAnswer,
        renderEditor: renderEditorLongAnswer,
        renderCard: renderCardLongAnswer,
        renderSettings: renderSettingsLongAnswer,
        isValidCallback: isValidCallbackLongAnswer,
    },
};

export {
    renderBlockLongAnswer,
    renderCardLongAnswer,
    renderEditorLongAnswer,
    renderSettingsLongAnswer,
    isValidCallbackLongAnswer,
    LONG_ANSWER_DEFAULT_VALUE,
    LONG_ANSWER_BLOCK_CONFIG,
};
