import _ from "lodash";
import { useEffect, useState } from "react";
import ProgressionEditor from "./ProgressionEditor";
import { Button, Divider, Form, Input, message } from "antd";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import { v4 as uuid } from "uuid";

const DefaultEditor = ({
    block,
    setBlock,
    parentBlock,
    currentSubBlock,
    isEditor,
    setIsEditor,
    isNewBlock,
    setIsNewBlock,
    worksheet,
    toggleShouldBlockUI,
    onInputVariableUpdate,
    mentionsList = [],
    hasMentions,
    blocks,
    disableWrite,
    isReadOnlyMode,
}: any) => {
    const { children } = block;

    const [loading, setLoad] = useState(false);
    const [tempChildren, setTempChildren] = useState("");

    useEffect(() => {
        setLoad(true);
        setTempChildren("");
        setTimeout(() => setLoad(false), 50);
    }, [currentSubBlock, parentBlock]);

    if (currentSubBlock == null) {
        return (
            <>
                {block?.id && (
                    <div style={{ marginTop: "30px", width: "50%" }}>
                        <Form.Item label="Progression Stage Id">
                            <Input.Search
                                value={block.id}
                                onChange={() => {}}
                                enterButton="Copy"
                                size="middle"
                                onSearch={() => {
                                    message.info(`Copied Id to clipboard!`);
                                    navigator.clipboard.writeText(block.id);
                                }}
                            />
                        </Form.Item>
                    </div>
                )}
                <div>
                    Please select a child block to continue.
                    <br />
                    Click{" "}
                    <a
                        href={`${process.env.REACT_APP_CLIENT_PREVIEW}/home/worksheet/${worksheet?.id}/${block?.id}?pauseTimer=true`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        here{" "}
                    </a>
                    to open preview for this progression
                    <br />
                    Click{" "}
                    <a
                        href={`${process.env.REACT_APP_CLIENT_PREVIEW}/home/worksheet/${worksheet?.id}/${block?.id}?isLanding=true`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        here{" "}
                    </a>
                    to open live link for this progression.
                </div>
                {children?.length > 0 && (
                    <>
                        <Divider />
                        <Button
                            disabled={disableWrite}
                            type="primary"
                            onClick={() => {
                                message.info(
                                    `Copied Progression Stage to clipboard!`,
                                );

                                navigator.clipboard.writeText(
                                    JSON.stringify(children),
                                );
                            }}
                        >
                            Copy this Progression Stage to Clipboard
                        </Button>
                        <Divider />
                    </>
                )}
                <Form.Item label={"Paste Config here"}>
                    <Input.TextArea
                        disabled={disableWrite}
                        value={tempChildren}
                        placeholder="Paste Config here"
                        onChange={(e) => setTempChildren(e.target.value)}
                        rows={10}
                    />
                    <Button
                        disabled={disableWrite}
                        style={{ marginTop: "10px" }}
                        type="primary"
                        onClick={() => {
                            try {
                                const parsedJson = JSON.parse(tempChildren);
                                if (!Array.isArray(parsedJson)) {
                                    message.error("Error in JSON!");
                                    return;
                                }
                                let tmpBlock = _.cloneDeep(block);
                                const tmpChildren = [...parsedJson];
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["children"],
                                    tmpChildren?.map((child: any) =>
                                        updateKeysAndCopy(
                                            _.omit(
                                                {
                                                    ...child,
                                                    tmpId: uuid(),
                                                },
                                                ["id"],
                                            ),
                                            true,
                                        ),
                                    ),
                                );
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["children"],
                                    tmpChildren?.map((child: any) =>
                                        updateKeysAndCopy(
                                            _.omit(
                                                {
                                                    ...child,
                                                    tmpId: uuid(),
                                                },
                                                ["id"],
                                            ),
                                            true,
                                        ),
                                    ),
                                );
                                setBlock(tmpBlock);
                                setTempChildren("");
                                message.info("Updated!");
                            } catch (e) {
                                // captureException(e)
                                message.error("Error in JSON!");
                            }
                        }}
                    >
                        Update config
                    </Button>
                </Form.Item>
            </>
        );
    } else if (!loading && children[currentSubBlock]) {
        const editorProps = {
            blocks,
            block: children[currentSubBlock],
            setBlock: (subBlock: any) => {
                let tmpBlock = _.cloneDeep(block);

                tmpBlock = _.set(
                    tmpBlock,
                    ["children", currentSubBlock],
                    subBlock,
                );
                setBlock(tmpBlock);
            },
            isEditor,
            setIsEditor,
            currentBlock: currentSubBlock + 1,
            isNewBlock,
            setIsNewBlock,
            worksheet,
            toggleShouldBlockUI,
            onInputVariableUpdate,
            mentionsList,
            hasMentions,
            disableWrite,
            previewData: {
                progression_id: block?.id,
            },
            isReadOnlyMode,
        };

        return <ProgressionEditor {...editorProps} />;
    } else return <></>;
};

export default DefaultEditor;
