import { BLOCK_TYPES } from "../../common/index";
const { V2_AVATAR_BLOCK } = BLOCK_TYPES;
const V2_AVATAR_DEFAULT_VALUE = {
    type: V2_AVATAR_BLOCK,
    data: {
        [V2_AVATAR_BLOCK]: {
            type: "", // image | gif | video
            value: "",
        },
    },
};

export default V2_AVATAR_DEFAULT_VALUE;
