import { FunctionComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";

import {
    STUDENTS_QUERY,
    CREATE_STUDENT_MUTATION,
    UPDATE_STUDENT,
    DELETE_STUDENT_MUTATION,
} from "../graphql";
import { captureException } from "@sentry/react";

// Query
export const withStudents = (Component: FunctionComponent) =>
    graphql(STUDENTS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                students,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadQuestionsData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            students: [
                                ...previousResult.students,
                                ...fetchMoreResult.students,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                students,
                subscribeToMore,
                updateQuery,
                loadQuestionsData,
                refetchStudents: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreateStudent = (Component: FunctionComponent) =>
    graphql(CREATE_STUDENT_MUTATION, {
        props: ({ mutate }) => ({
            createStudent: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createStudent: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { insert_student_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createStudent: {
                                object,
                                __typename: "INSERT_STUDENT_ONE",
                            },
                        },
                    });
                    return insert_student_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateStudent = (Component: FunctionComponent) =>
    graphql(UPDATE_STUDENT, {
        props: ({ mutate }) => ({
            updateStudent: (object: Object) => {
                mutate({
                    variables: { ...object },
                    optimisticResponse: {
                        updateStudent: {
                            ...object,
                            __typename: "student",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withDeleteStudent = (Component: FunctionComponent) =>
    graphql(DELETE_STUDENT_MUTATION, {
        props: ({ mutate }) => ({
            deleteStudent: (id: string) => {
                mutate({
                    variables: { id },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteStudent: {
                            id,
                            __typename: "DELETE_STUDENT_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);
