import { Button, Form, Switch } from "antd";
import { calculateMultiStepMovementVectorsWithCachedGaps } from "./utils";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";

interface PuzzleSettingsProps {
    block: any;
    setBlock: (block: any) => void;
    disableWrite: boolean;
}

const PuzzleSettings = ({
    block,
    setBlock,
    disableWrite,
}: PuzzleSettingsProps) => {
    return (
        <KTWrapper feature="puzzle_mode">
            <Form layout="vertical">
                <Form.Item label="Enable Puzzle Mode">
                    <Switch
                        disabled={disableWrite}
                        checked={!!block?.puzzle?.enabled}
                        onChange={(checked) => {
                            setBlock({
                                ...block,
                                puzzle: {
                                    ...block?.puzzle,
                                    enabled: checked,
                                },
                            });
                        }}
                    />
                </Form.Item>
                {block?.puzzle?.enabled && (
                    <>
                        <Form.Item label="Enable Slider">
                            <Switch
                                disabled={disableWrite}
                                checked={!!block?.puzzle?.slider?.enabled}
                                onChange={(checked) => {
                                    setBlock({
                                        ...block,
                                        puzzle: {
                                            ...block?.puzzle,
                                            slider: {
                                                ...block?.puzzle?.slider,
                                                enabled: checked,
                                            },
                                        },
                                    });
                                }}
                            />
                        </Form.Item>
                        {block?.puzzle?.slider?.enabled && (
                            <Button
                                onClick={() => {
                                    const {
                                        cells,
                                        width,
                                        height,
                                        horizontalGap,
                                        verticalGap,
                                    } =
                                        calculateMultiStepMovementVectorsWithCachedGaps(
                                            block?.cells,
                                        );
                                    console.log(
                                        cells,
                                        width,
                                        height,
                                        horizontalGap,
                                        verticalGap,
                                    );
                                    setBlock({
                                        ...block,
                                        cells: cells,
                                        puzzle: {
                                            ...block?.puzzle,
                                            slider: {
                                                ...block?.puzzle?.slider,
                                                width: width,
                                                height: height,
                                                horizontalGap: horizontalGap,
                                                verticalGap: verticalGap,
                                            },
                                        },
                                    });
                                }}
                            >
                                Bake Data
                            </Button>
                        )}
                        <Form.Item label="Rotation">
                            <Switch
                                disabled={disableWrite}
                                checked={block?.rotationFlow?.enabled}
                                onChange={(checked) => {
                                    setBlock({
                                        ...block,
                                        rotationFlow: {
                                            ...block?.rotationFlow,
                                            enabled: checked,
                                        },
                                    });
                                }}
                            />
                        </Form.Item>
                    </>
                )}
            </Form>
        </KTWrapper>
    );
};

export default PuzzleSettings;
