export const KEYMAP = {
    check_type: "check_type",
    feedback_type: "feedback_type",
    start_audio: "audio_pool",
    start_feedback: "feedback_pool",
    has_correctness_stroke: "has_input_list_correctness_stroke",
    has_highlight: "has_input_list_highlight",
    correct_answer: "correctAnswer",
    is_multiple_select: "isMultipleSelect",
    min_select: "minSelect",
    max_select: "maxSelect",
    layout: "layout",
    options: "Components",
    toast_message: "toastMessage",
    correct_on_any_attempt: "correctOnAnyAttempt",
    tappable_function: "tappableFunction",
    evaluation_type: "evaluation_type",
    system_prompt: "system_prompt",
    question_text: "question_text",
    get_prompt: "get_prompt",
    update_correct: "update_correct"
};
