import React, { useState } from "react";
import { Link } from "src/components";
import { Button, Col, Divider, Row, Space, Spin } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";

import DeleteView from "src/modules/student/components/DeleteView";
import { EditIcon, RenderTable, Layout, MetaTag } from "src/components";

import { FilterComponent } from "src/modules/student/components";

const ListView: React.FC = (props: any) => {
    const { loading, students, studentAggregate, loadStudentsData } = props;

    const columns = [
        {
            title: "Mobile",
            key: "mobile",
            render: (text, record) => record?.mobile,
        },
        {
            title: "Class",
            key: "class",
            render: (text, record) => record?.class,
        },
        {
            title: "Streak",
            key: "streak",
            render: (text, record) => record?.streak,
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            render: (text, record) => (
                <Space size="middle">
                    <Link href={`/student/update/${record?.id}`}>
                        <EditIcon isButton={true} />
                    </Link>
                    <Divider />
                    <DeleteView id={record?.id} isButton={true} />
                </Space>
            ),
        },
    ];

    return (
        <Layout titles={[{ name: "Students", link: "/student" }]}>
            <MetaTag title="Students" />
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Students List</h1>
                </Col>
                <Col>
                    <Link href={"/student/create"}>
                        <Button type="primary" icon={<AppstoreAddOutlined />}>
                            Add
                        </Button>
                    </Link>
                </Col>
            </Row>
            <hr />

            {/* <FilterComponent isStudent={true} {...props} /> */}
            <hr />

            <Spin spinning={loading}>
                {Boolean(students?.length) && (
                    <RenderTable
                        loading={loading}
                        columns={columns}
                        data={students || []}
                        loadData={loadStudentsData}
                        aggregate={studentAggregate?.aggregate?.count}
                    />
                )}
            </Spin>
        </Layout>
    );
};

export default ListView;
