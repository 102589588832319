import { DEFAULT_CUSTOM_KEYBOARD } from "src/components/CustomKeyboardEditor";
import { BLOCK_TYPES } from "../../common/index";
const { DRAG_AND_INPUT_BLOCK } = BLOCK_TYPES;

const DRAG_AND_INPUT_DEFAULT_VALUE = {
    type: DRAG_AND_INPUT_BLOCK,
    data: {
        [DRAG_AND_INPUT_BLOCK]: {
            text: [],
            blanks: [
                {
                    correct_answer: [],
                },
            ],
            options: [],
            custom_keyboard: DEFAULT_CUSTOM_KEYBOARD,
            has_options: false,
            has_multiple: false,
            question_audio: "",
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
        other: {
            duration: 0,
            audio: "",
            description: [],
            global_feedback: null,
        },
    },
};

export default DRAG_AND_INPUT_DEFAULT_VALUE;
