import renderBlockV2Mixed from "./renderBlock";
import renderEditorV2Mixed from "./renderEditor";
import isValidCallbackV2Mixed from "./isValid";
import V2_MIXED_DEFAULT_VALUE from "./defaultValue";
import renderSettingsV2Mixed from "./renderSettings";
import renderCardV2Mixed from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { V2_MIXED_BLOCK } = BLOCK_TYPES;

const V2_MIXED_BLOCK_CONFIG = {
    [V2_MIXED_BLOCK]: {
        label: "V2Mixed",
        defaultValue: V2_MIXED_DEFAULT_VALUE,
        renderBlock: renderBlockV2Mixed,
        renderEditor: renderEditorV2Mixed,
        renderCard: renderCardV2Mixed,
        renderSettings: renderSettingsV2Mixed,
        addCallback,
        isValidCallback: isValidCallbackV2Mixed,
    },
};

export {
    renderBlockV2Mixed,
    renderCardV2Mixed,
    renderEditorV2Mixed,
    renderSettingsV2Mixed,
    isValidCallbackV2Mixed,
    V2_MIXED_DEFAULT_VALUE,
    V2_MIXED_BLOCK_CONFIG,
};
