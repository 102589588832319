import { cloneDeep, get, set } from "lodash";
import TableEditor from "../../../../Figma/renderEditor/TableEditor";
import { DEFAULT_TABLE } from "./index";
import { CLUSTER_KEYS } from "./mapping";

interface TableEditorWrapperProps {
    tableData: any;
    variables: any[];
    blockVarsMap: any;
    KEYMAP: any;
    currentCluster: number;
    block: any;
    setBlock: (block: any) => void;
    currentEditor: any;
    setCurrentEditor: any;
    disableWrite: boolean;
    hasMentions: boolean;
    mentionsList: any[];
    renderLinkSelect: any;
}

const TableEditorWrapper = ({
    tableData,
    variables,
    blockVarsMap,
    KEYMAP,
    currentCluster,
    block,
    setBlock,
    currentEditor,
    setCurrentEditor,
    disableWrite,
    hasMentions,
    mentionsList,
    renderLinkSelect,
}: TableEditorWrapperProps) => {
    return (
        <TableEditor
            table={
                tableData?.value ||
                (typeof tableData?.default === "string"
                    ? JSON.parse(tableData?.default)
                    : tableData?.default) ||
                DEFAULT_TABLE
            }
            tableVariableName={"tableData"}
            currentEditor={currentEditor}
            setCurrentEditor={setCurrentEditor}
            dropdownGlobalVariables={[]}
            droppableAreaIdsGlobalVariables={[]}
            disableWrite={disableWrite}
            tagsClusterList={variables[
                blockVarsMap.current[KEYMAP.generic_cluster]
            ]?.value?.map((v, i) => ({
                value: i,
                label: `${i + 1}: ${CLUSTER_KEYS[v.type]}`,
            }))}
            setBlock={handleSetBlock}
            hasMentions={hasMentions}
            mentionsList={mentionsList}
            renderLinkSelect={renderLinkSelect}
            tagsList={getTagsList()}
            optionsSchema={getOptionsSchema()}
        />
    );

    function handleSetBlock(
        value: any,
        {
            tag_id,
            prev_tag_id,
            tag_data,
            tag_cluster_index = -1,
            delete_prev_tag = false,
            oldTags = [],
            newTags = [],
        } = {},
    ) {
        let tmpBlock = cloneDeep(block);
        const idx = variables[
            blockVarsMap.current[KEYMAP.generic_cluster]
        ]?.value[
            currentCluster
        ]?.props?.table?.value?.variables?.value?.variables?.findIndex(
            (v: { name: string }) => v.name === "tableData",
        );

        if (idx >= 0) {
            tmpBlock = updateTableData(tmpBlock, idx, value);
            if (tag_id || prev_tag_id || tag_cluster_index > -1) {
                tmpBlock = handleTagUpdates(tmpBlock, {
                    tag_id,
                    prev_tag_id,
                    tag_data,
                    tag_cluster_index,
                    delete_prev_tag,
                });
            }
            if (newTags?.length) {
                tmpBlock = handleNewTags(tmpBlock, newTags);
            }
            if (oldTags?.length) {
                tmpBlock = handleOldTags(tmpBlock, oldTags);
            }
            setBlock(tmpBlock);
        }
    }

    // Helper functions for handleSetBlock
    function updateTableData(tmpBlock: any, idx: number, value: any) {
        return set(
            tmpBlock,
            [
                "figma",
                "variables",
                blockVarsMap.current[KEYMAP.generic_cluster],
                "value",
                currentCluster,
                "props",
                "table",
                "value",
                "variables",
                "value",
                "variables",
                idx,
                "value",
            ],
            value,
        );
    }

    function handleTagUpdates(
        tmpBlock: any,
        {
            tag_id,
            prev_tag_id,
            tag_data,
            tag_cluster_index,
            delete_prev_tag,
        }: {
            tag_id?: string;
            prev_tag_id?: string;
            tag_data?: any;
            tag_cluster_index?: number;
            delete_prev_tag?: boolean;
        },
    ) {
        let arrData =
            tmpBlock.figma?.variables[
                blockVarsMap.current[KEYMAP.generic_cluster]
            ]?.value;
        let tagIndex;
        let prevTagIndex;

        for (let index = 0; index < arrData.length; index++) {
            const element = arrData[index];

            if (element.type === "tags_collection") {
                let optionsIndex =
                    element?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
                        (val) => val.name === "options",
                    );
                if (optionsIndex < 0) continue;

                if (!prevTagIndex && prev_tag_id) {
                    tmpBlock = handlePrevTag(
                        tmpBlock,
                        element,
                        index,
                        optionsIndex,
                        prev_tag_id,
                        delete_prev_tag,
                    );
                }

                if (!tagIndex) {
                    tmpBlock = handleCurrentTag(
                        tmpBlock,
                        element,
                        index,
                        optionsIndex,
                        tag_id,
                        tag_data,
                        tag_cluster_index,
                    );
                }

                if (
                    ((!tag_id &&
                        ((tag_cluster_index > 0 &&
                            index >= tag_cluster_index) ||
                            tag_cluster_index < 0)) ||
                        (tag_id && tagIndex >= 0)) &&
                    (!prev_tag_id || (prev_tag_id && prevTagIndex >= 0))
                ) {
                    break;
                }
            }
        }
        return tmpBlock;
    }

    function handlePrevTag(
        tmpBlock: any,
        element: any,
        index: number,
        optionsIndex: number,
        prev_tag_id: string,
        delete_prev_tag: boolean,
    ) {
        const prevTagIndex =
            element?.props?.tags_list?.value?.variables?.value?.variables[
                optionsIndex
            ]?.value?.findIndex((v: any) => v.genericId === prev_tag_id);

        if (prevTagIndex >= 0) {
            if (delete_prev_tag) {
                return set(
                    tmpBlock,
                    [
                        "figma",
                        "variables",
                        blockVarsMap.current[KEYMAP.generic_cluster],
                        "value",
                        index,
                        "props",
                        "tags_list",
                        "value",
                        "variables",
                        "value",
                        "variables",
                        optionsIndex,
                        "value",
                    ],
                    element?.props?.tags_list?.value?.variables?.value?.variables[
                        optionsIndex
                    ]?.value?.filter((v: any) => v.genericId !== prev_tag_id),
                );
            } else {
                const currentTextVisibleValue = tmpBlock.figma?.variables[
                    blockVarsMap.current[KEYMAP.generic_cluster]
                ]?.value[
                    index
                ]?.props?.tags_list?.value?.variables?.value?.variables[
                    optionsIndex
                ]?.value?.find((v: any) => v.genericId === prev_tag_id)?.props
                    ?.textVisible?.value;
                if (currentTextVisibleValue == 0) {
                    return set(
                        tmpBlock,
                        [
                            "figma",
                            "variables",
                            blockVarsMap.current[KEYMAP.generic_cluster],
                            "value",
                            index,
                            "props",
                            "tags_list",
                            "value",
                            "variables",
                            "value",
                            "variables",
                            optionsIndex,
                            "value",
                            prevTagIndex,
                            "props",
                            "textVisible",
                            "value",
                        ],
                        1,
                    );
                } else {
                    // increase currentCount
                    const currentCount = tmpBlock.figma?.variables[
                        blockVarsMap.current[KEYMAP.generic_cluster]
                    ]?.value[
                        index
                    ]?.props?.tags_list?.value?.variables?.value?.variables[
                        optionsIndex
                    ]?.value?.find((v: any) => v.genericId === prev_tag_id)
                        ?.props?.currentCount?.value;

                    return set(
                        tmpBlock,
                        [
                            "figma",
                            "variables",
                            blockVarsMap.current[KEYMAP.generic_cluster],
                            "value",
                            index,
                            "props",
                            "tags_list",
                            "value",
                            "variables",
                            "value",
                            "variables",
                            optionsIndex,
                            "value",
                            prevTagIndex,
                            "props",
                            "currentCount",
                            "value",
                        ],
                        currentCount + 1,
                    );
                }
            }
        }
        return tmpBlock;
    }

    function handleCurrentTag(
        tmpBlock: any,
        element: any,
        index: number,
        optionsIndex: number,
        tag_id?: string,
        tag_data?: any,
        tag_cluster_index?: number,
    ) {
        if (tag_id) {
            const tagIndex =
                element?.props?.tags_list?.value?.variables?.value?.variables[
                    optionsIndex
                ]?.value?.findIndex((v: any) => v.genericId === tag_id);

            if (tagIndex >= 0 && tag_data) {
                return set(
                    tmpBlock,
                    [
                        "figma",
                        "variables",
                        blockVarsMap.current[KEYMAP.generic_cluster],
                        "value",
                        index,
                        "props",
                        "tags_list",
                        "value",
                        "variables",
                        "value",
                        "variables",
                        optionsIndex,
                        "value",
                        tagIndex,
                    ],
                    tag_data,
                );
            }
        } else if (tag_cluster_index === index && tag_data) {
            const tagArray: any = get(tmpBlock, [
                "figma",
                "variables",
                blockVarsMap.current[KEYMAP.generic_cluster],
                "value",
                index,
                "props",
                "tags_list",
                "value",
                "variables",
                "value",
                "variables",
                optionsIndex,
                "value",
            ]);

            if (tagArray?.length) {
                return set(
                    tmpBlock,
                    [
                        "figma",
                        "variables",
                        blockVarsMap.current[KEYMAP.generic_cluster],
                        "value",
                        index,
                        "props",
                        "tags_list",
                        "value",
                        "variables",
                        "value",
                        "variables",
                        optionsIndex,
                        "value",
                        tagArray?.length || 0,
                    ],
                    tag_data,
                );
            } else {
                return set(
                    tmpBlock,
                    [
                        "figma",
                        "variables",
                        blockVarsMap.current[KEYMAP.generic_cluster],
                        "value",
                        index,
                        "props",
                        "tags_list",
                        "value",
                        "variables",
                        "value",
                        "variables",
                        optionsIndex,
                        "value",
                    ],
                    [tag_data],
                );
            }
        }
        return tmpBlock;
    }

    function handleNewTags(tmpBlock: any, newTags: any[]) {
        let arrData =
            tmpBlock.figma?.variables[
                blockVarsMap.current[KEYMAP.generic_cluster]
            ]?.value;

        for (let index = 0; index < arrData.length; index++) {
            const element = arrData[index];

            if (element.type === "tags_collection") {
                let optionsIndex =
                    element?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
                        (val) => val.name === "options",
                    );
                if (optionsIndex < 0) continue;

                tmpBlock = set(
                    tmpBlock,
                    [
                        "figma",
                        "variables",
                        blockVarsMap.current[KEYMAP.generic_cluster],
                        "value",
                        index,
                        "props",
                        "tags_list",
                        "value",
                        "variables",
                        "value",
                        "variables",
                        optionsIndex,
                        "value",
                    ],
                    [
                        ...(element?.props?.tags_list?.value?.variables?.value
                            ?.variables[optionsIndex]?.value || []),
                        ...newTags,
                    ],
                );
                break;
            }
        }
        return tmpBlock;
    }

    function handleOldTags(tmpBlock: any, oldTags: any[]) {
        let oldTagsMap: { [key: string]: any } = {};
        oldTags?.forEach((v: any) => {
            oldTagsMap[v.genericId] = v;
        });

        let arrData =
            tmpBlock.figma?.variables[
                blockVarsMap.current[KEYMAP.generic_cluster]
            ]?.value;

        for (let index = 0; index < arrData.length; index++) {
            const element = arrData[index];

            if (element.type === "tags_collection") {
                let optionsIndex =
                    element?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
                        (val) => val.name === "options",
                    );
                if (optionsIndex < 0) continue;

                tmpBlock = set(
                    tmpBlock,
                    [
                        "figma",
                        "variables",
                        blockVarsMap.current[KEYMAP.generic_cluster],
                        "value",
                        index,
                        "props",
                        "tags_list",
                        "value",
                        "variables",
                        "value",
                        "variables",
                        optionsIndex,
                        "value",
                    ],
                    element?.props?.tags_list?.value?.variables?.value?.variables[
                        optionsIndex
                    ]?.value?.map((v: any) => {
                        if (oldTagsMap[v.genericId]) {
                            let ot = oldTagsMap[v.genericId];
                            delete oldTagsMap[v.genericId];
                            return ot;
                        }
                        return v;
                    }),
                );

                if (Object.keys(oldTagsMap)?.length === 0) break;
            }
        }
        return tmpBlock;
    }

    function getTagsList() {
        return (
            variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value
                ?.filter((v) => v.type === "tags_collection")
                .flatMap(
                    (v) =>
                        v?.props?.tags_list?.value?.variables?.value?.variables
                            ?.find((val) => val.name === "options")
                            ?.value?.map((valu) => {
                                const currentIsClusterVariable =
                                    v?.props?.tags_list?.value?.variables?.value?.variables?.find(
                                        (val) =>
                                            val.name === "is_cluster_visible",
                                    );
                                const isClusterVisibleValue =
                                    currentIsClusterVariable.value === null ||
                                    currentIsClusterVariable.value === undefined
                                        ? currentIsClusterVariable.default
                                        : currentIsClusterVariable.value;
                                return {
                                    ...valu,
                                    is_cluster_visible: isClusterVisibleValue,
                                };
                            }) || [],
                ) || []
        );
    }

    function getOptionsSchema() {
        return variables?.[
            blockVarsMap.current?.[KEYMAP.generic_cluster]
        ]?.schema
            ?.find((item: any) => item.type === "tags_collection")
            ?.props?.tags_list?.value?.variables?.value?.variables?.find(
                (v: { name: string }) => v.name === "options",
            )?.schema;
    }
};

export default TableEditorWrapper;
