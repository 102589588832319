// import { Quiz } from '@styled-icons/material/Quiz';
// import { ImportContacts } from '@styled-icons/material/ImportContacts';
// import { StickyNote2 } from '@styled-icons/material/StickyNote2';
import { PlayCircleOutlined } from "@ant-design/icons";
import { Layout, MetaTag } from "src/components";
import { Can } from "src/services/casl";
import { Col, Row } from "antd";
import { useRouter } from "src/helpers";
import React, { useEffect } from "react";
import NavCard from "./components/NavCard";

const HomeView: React.FC = (props) => {
    const router = useRouter();

    const navObjects = [
        {
            href: "/user",
            title: "user",
            entity: "user",

            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
        {
            href: "/set",
            title: "set",
            entity: "set",

            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
        // { href: '/template', title: 'Templates', icon: <StickyNote2 style={{ height: '8vh', color: '#5a67d8' }} /> },
        {
            href: "/collection",
            title: "collection",
            entity: "collection",

            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
        {
            href: "/question",
            title: "question",
            entity: "question",

            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
        {
            href: "/student",
            title: "student",
            entity: "student",

            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
        {
            href: "/meme",
            title: "meme",
            entity: "meme",

            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
        {
            href: "/concept",
            title: "concept",
            entity: "concept",

            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
        // {
        //     href: "/collection/content",
        //     title: "collection content",
        //     icon: (
        //         <PlayCircleOutlined
        //             style={{ height: "8vh", color: "#5a67d8" }}
        //         />
        //     ),
        // },
        {
            href: "/book",
            title: "Book",
            entity: "book",
            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
        {
            href: "/plan",
            title: "Plan",
            entity: "plan",

            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
        {
            href: "/tab",
            title: "Home Explore",
            entity: "tab",
            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
        {
            href: "/worksheet/block/search",
            title: "Question Search",
            entity: "worksheet_block_search",

            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
        {
            href: "/logs?limit=30&skip=0",
            title: "Dashboard Logs",
            entity: "logs",

            icon: (
                <PlayCircleOutlined
                    style={{ height: "8vh", color: "#5a67d8" }}
                />
            ),
        },
    ];

    // useEffect(() => {
    //     navObjects.map(({ href }: any) => router.prefetch(href));
    // }, []);

    return (
        <Layout>
            <MetaTag title="Home" />
            <Row gutter={[24, 24]} /* justify="center" */>
                {navObjects.map(({ href, title, icon, entity }) => (
                    <Can I="read" a={entity}>
                        {() => (
                            <Col span={4}>
                                <NavCard
                                    href={href}
                                    title={title}
                                    icon={icon}
                                />
                            </Col>
                        )}
                    </Can>
                ))}
            </Row>
        </Layout>
    );
};

export default HomeView;
