import { getIsValidFunction } from "../../../../blocks/index";
import { Actions } from "./Actions";
import { DragAndDrop } from "./DragAndDrop";
import { Content } from "./Content";
import { Row } from "antd";
import { checkChildPSValid } from "src/modules/worksheet/components/blocks/renderers/ProgressionStage/isValid";

export const BlockCard = ({
    idx,
    type,
    currentBlock,
    setCurrentBlock,
    deleteBlock,
    blocks,
    addBlock,
    children,
    disableActions,
    disableDuplicateAction = false,
    onClick,
    ribbonColor,
    currentSubBlock,
    block,
    addBlockCustom = null,
    ribbonText = null,
    worksheet,
    // for progression stage
    updateId,
    ignoreId,
}: any) => {
    const isValidFunction =
        (type && getIsValidFunction(type)) ||
        (() => ({
            isValid: true,
        }));
    let { isValid }: any = isValidFunction({
        block,
        currentSubBlock,
        currentBlock,
        worksheet,
    });

    if (type === "progression") isValid = checkChildPSValid(block);

    const contentProps = {
        ribbonColor,
        idx,
        currentBlock,
        isValid,
        onClick,
        setCurrentBlock,
        children,
        ribbonText,
    };

    const actionsProps = {
        disableActions,
        blocks,
        disableDuplicateAction,
        addBlock,
        deleteBlock,
        idx,
        type,
        addBlockCustom,
        block,
        // for progression stage
        updateId,
        ignoreId,
    };
    return (
        <Row
            align={"middle"}
            justify={"space-between"}
            style={{ position: "relative", zIndex: 999 }}
        >
            <DragAndDrop disableActions={disableActions} />
            <Content {...contentProps} />
            <Actions {...actionsProps} />
        </Row>
    );
};
