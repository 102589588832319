import { gql } from "@apollo/client";export default gql`query set($id: Int!) {
    set: set_by_pk(id: $id) {
        id
        title
        description
        cover
        chapter
        topic
        attempts
        duration
        status
        type
        subject
        class
        creator {
            email
        }
    }
}
`;
