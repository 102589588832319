import { Form, Select } from "antd";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import DroppedItem from "../../../AllInOne/renderEditor/ComponentsEditor/GenericTable/preview/DroppedItem";

interface TagSelectorProps {
    tagsList: any[];
    source: any;
    setSource: (source: any) => void;
    droppedSource?: any;
}

const TagSelector = ({
    tagsList,
    source,
    setSource,
    droppedSource,
}: TagSelectorProps) => {
    const modifiedTagsList = useMemo(
        () =>
            tagsList?.filter(
                (tag: any) =>
                    ["1", 1, true, "true"].includes(
                        tag?.props?.textVisible?.value,
                    ) || droppedSource?.genericId === tag?.genericId,
            ),
        [tagsList, droppedSource?.genericId],
    );

    const handleChange = (value: any) => {
        let newSource;
        if (value !== undefined && value !== "none") {
            newSource = cloneDeep(
                modifiedTagsList.find((tag) => tag.genericId === value) || {},
            );
            newSource.props.textVisible.value = true;
        } else {
            newSource = undefined;
        }
        setSource(newSource);
    };

    return (
        <Form.Item label="Select Tag">
            <Select
                style={{
                    width: "100%",
                }}
                value={source?.genericId}
                onChange={handleChange}
                placeholder="Select or add tags"
            >
                <Select.Option value={undefined} key="none">
                    None
                </Select.Option>
                {modifiedTagsList?.map((tagVal) => (
                    <Select.Option
                        key={tagVal.genericId}
                        value={tagVal.genericId}
                    >
                        <DroppedItem
                            droppedSource={tagVal}
                            tagSelector={true}
                        />
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default TagSelector;
