import React, { useEffect, useMemo, useState } from "react";
import TableRow, { TableRowLabels } from "./TableRow";
import { generateBorderConfig } from "./helpers";
import DrawingComponent from "./DrawingComponent";
import emitter from "src/helpers/emitter";
import {
    TABLE_PUZZLE_TAG_ACTIVATED,
    TAG_SELECTION_PREVIEW,
} from "../utils/constants";
import { MAIN_TABLE_CELL_SELECTED } from "src/helpers/events/table/constants";

function getAlignmentProperty(align: string) {
    switch (align) {
        case "LEFT":
            return "self-start";
        case "RIGHT":
            return "self-end";
        case "CENTER":
            return "center";
        default:
            return "self-start";
    }
}

const TablePreviewComponent = (props: any) => {
    const { type, mainTableSelectionActive } = props;
    const [tagActive, setTagActive] = useState<string | null>(null);

    useEffect(() => {
        const listener = (tagId: string) => {
            setTagActive(tagId);
        };
        if (type === TAG_SELECTION_PREVIEW) {
            emitter.on(TABLE_PUZZLE_TAG_ACTIVATED, listener);
        }
        return () => {
            if (type === TAG_SELECTION_PREVIEW) {
                emitter.off(TABLE_PUZZLE_TAG_ACTIVATED, listener);
            }
        };
    }, [type]);

    const { tableData, showMeasures } = props;

    if (!tableData) {
        return null;
    }

    const customizations = {
        border: tableData?.border?.enabled && tableData?.border,
        gap: tableData?.gap?.enabled && tableData?.gap,
        reorder:
            tableData?.reorder?.type &&
            tableData?.reorder?.type !== "NONE" &&
            tableData?.reorder,
        swipable: tableData?.swipable?.enabled && tableData?.swipable,
        minMaxWidth: tableData?.minMaxWidth,
        minHeightCustomization: tableData?.minHeight,
        align: tableData?.align,
        padding: tableData?.padding?.enabled && tableData?.padding,
    };

    const tableChildProps = {
        borderCustomizations: customizations.border,
        minMaxWidth: customizations.minMaxWidth,
        minHeightCustomization: customizations.minHeightCustomization,
        paddingCustomization: customizations.padding,
        tableData: tableData,
    };

    const tableFeedbackBorder = tableData.highlightBorder
        ? generateBorderConfig(tableData.highlightBorder)
        : {};

    const tableAlignmentStyle = useMemo(() => {
        const alignmentProperty = getAlignmentProperty(customizations?.align);
        return {
            display: "grid",
            placeItems: alignmentProperty,
        };
    }, [customizations.align]);

    const drawingEnabled = tableData?.drawing?.enabled;
    const puzzleEnabled = tableData?.puzzle?.enabled;

    return (
        <div
            style={{
                maxHeight: "85vh",
                maxWidth: "100%",
                overflow: "auto",
                ...tableFeedbackBorder,
                ...tableAlignmentStyle,
                position: "relative",
                padding: "10px",
                paddingLeft: "80px",
            }}
        >
            {showMeasures && <TableRowLabels tableData={tableData} />}
            {drawingEnabled ? (
                <DrawingComponent
                    tableChildProps={tableChildProps}
                    puzzleEnabled={puzzleEnabled}
                    tagActive={tagActive}
                    mainTableSelectionActive={mainTableSelectionActive}
                />
            ) : (
                <table
                    className="renderer-table"
                    id="table-preview-renderer"
                    style={{
                        borderSpacing: customizations.gap
                            ? `${customizations.gap.horizontal || 0}px ${
                                  customizations.gap.vertical || 0
                              }px`
                            : "0px",
                        borderCollapse: tableData?.borderCollapse
                            ? "collapse"
                            : "separate",
                    }}
                >
                    <tbody id="table-preview-renderer-body">
                        {tableData.cells?.map((row: any, rowIndex: number) => {
                            return (
                                <TableRow
                                    rowProps={tableChildProps}
                                    rowIndex={rowIndex}
                                    row={row}
                                    key={rowIndex}
                                    puzzleEnabled={puzzleEnabled}
                                    tagActive={tagActive}
                                    mainTableSelectionActive={
                                        mainTableSelectionActive
                                    }
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default TablePreviewComponent;
