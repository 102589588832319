import { gql } from "@apollo/client";export default gql`query plan($id: Int!) {
    plan: subscription_plan_by_pk(id: $id) {
        id
        banner
        message_banner

        created_at
        credits
        description
        parent_id
        slug
        status
        tags {
            id
            tag {
                id
                name
                value
            }
        }
        title
        type
        variants
        breakdown
        order
    }
}
`;
