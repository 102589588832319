import _ from "lodash";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { BLOCK_TYPES } from ".";

const { PHONE_BLOCK } = BLOCK_TYPES;

const InputBlock: React.FC<any> = ({
    placeholder = null,
    type = null,
    setBlock,
    block,
    countryCode,
}) => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                }}
            >
                {type === PHONE_BLOCK ? (
                    <>
                        <PhoneInput
                            enableSearch
                            country={"in"}
                            value={countryCode}
                            onChange={(phone, data) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "countryCode"],
                                    data.dialCode,
                                );
                                setBlock(tmpBlock);
                            }}
                            inputStyle={{
                                display: "none",
                            }}
                            style={{
                                width: 50,
                            }}
                            dropdownStyle={{
                                color: "#111",
                            }}
                        />
                        <div
                            style={{
                                borderBottom: "1px #ccc solid",
                                padding: "16px 10px",
                                fontSize: "24px",
                                color: "#ccc",
                                width: "100%",
                            }}
                        >
                            {`+${countryCode} ${placeholder}`}
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            borderBottom: "1px #ccc solid",
                            padding: "16px 10px",
                            fontSize: "24px",
                            color: "#ccc",
                            width: "100%",
                        }}
                    >
                        {placeholder ?? "Type Your answer here..."}
                    </div>
                )}
            </div>
        </>
    );
};

export default InputBlock;
