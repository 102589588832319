import React from "react";
import { compose } from "src/helpers";
import {
    withHookForAggregatePlan,
    withHookForPlans,
    withUpdatePlan,
} from "src/modules/plan/operations";
import { withStateAndActionsForPlan } from "src/modules/plan/resolvers";
import ListView from "./component";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    // withStateAndActionsForPlan,
    withHookForPlans,
    withHookForAggregatePlan,
    withUpdatePlan,
)(List);
