import { gql } from "@apollo/client";export default gql`query documentAggregate($group_id: Int) {
    documentAggregate: home_explore_document_aggregate(
        where: { groups: { group_id: { _eq: $group_id } } }
    ) {
        aggregate {
            count
        }
    }
}
`;
