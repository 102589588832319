import { FunctionComponent } from "react";

import {
    getLastStringFromUrl,
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import { TAB_QUERY, TABS_QUERY, TAB_AGGREGATE_QUERY } from "../graphql";

export const withHookForTabs = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { pagination } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(TABS_QUERY, {
            variables: {
                // ...pagination,
            },
        });
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "tabs", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    tabs: items,
                    subscribeToMore,
                    updateQuery,
                    loadTabsData: loadItemsData,
                    refetchTabs: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateTab = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            TAB_AGGREGATE_QUERY,
            {
                variables: {},
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "tabAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    tabAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForTab = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(TAB_QUERY, {
            variables: {
                id: getLastStringFromUrl(),
            },
        });

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingTab: loading,
                    tab: data?.tab,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
