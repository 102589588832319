import React, { useState, useRef, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Select, Space, Button } from "antd";
import type { InputRef } from "antd";

const findRecursiveVal = (options: any[], value: any): boolean =>
    options.some(
        (item: { value: any; options: any[] }) =>
            item?.value === value ||
            (item?.options?.length && findRecursiveVal(item?.options, value)),
    );

const CustomSelectField: React.FC<any> = ({
    options = [],
    onChange,
    disabled,
    placeholder,
    defaultValue,
    style,
    value,
    type,
    setOption,
    btnText,
}) => {
    const valueOption =
        !value || findRecursiveVal(options, value)
            ? []
            : [{ value, label: value }];

    const [items, setItems] = useState([...valueOption, ...options]);
    const [name, setName] = useState("");
    const inputRef = useRef<InputRef>(null);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    useEffect(() => {
        setItems([...valueOption, ...options]);
    }, [options]);

    const addItem = (
        e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    ) => {
        e.preventDefault();
        if (setOption) setOption(name);
        else setItems([{ label: name, value: name }, ...items]);
        setName("");
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    return (
        <Select
            value={value}
            disabled={disabled}
            style={{ width: 300, ...style }}
            defaultValue={defaultValue}
            placeholder={placeholder}
            showSearch
            dropdownRender={(menu) => (
                <>
                    <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                            type={type}
                        />
                        <Button
                            type="text"
                            disabled={!name?.trim()}
                            icon={<PlusOutlined />}
                            onClick={addItem}
                        >
                            {btnText || "Add item"}
                        </Button>
                    </Space>
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                </>
            )}
            options={items}
            onChange={onChange}
        />
    );
};

export default CustomSelectField;
