import { TextHtml } from "src/components";
import { isHTML } from "src/helpers";
import React from "react";
import { MathRender } from "../";
import ReactPlayer from "react-player";

const RenderPart = ({
    part,
    imgStyle = {},
    latexStyle = null,
    mentionStyle = {},
    textStyle,
}) => {
    if (!textStyle) textStyle = mentionStyle?.textStyle || {};
    return part ? (
        part.type === "parts" ? (
            <div>
                {part.val.map((p, i) => (
                    <RenderPart
                        key={`p_p_${i}`}
                        part={p}
                        imgStyle={imgStyle}
                        latexStyle={latexStyle}
                        textStyle={textStyle}
                    />
                ))}
            </div>
        ) : part.type === "img" ? (
            <div style={{ width: "100%" }}>
                <img
                    style={{ maxWidth: "100%", margin: "0 auto", ...imgStyle }}
                    alt="Question Image"
                    src={part.val}
                />
            </div>
        ) : part.type === "video" ? (
            <div
                style={{
                    margin: "10px auto",
                    width: "100%",
                }}
            >
                <ReactPlayer
                    url={part.val}
                    width={"100%"}
                    height={"100%"}
                    controls
                />
            </div>
        ) : isHTML(part.val) ? (
            <TextHtml html={part.val} />
        ) : part.type === "latex_div" ? (
            <div className={latexStyle ?? "overflow-x"} style={textStyle}>
                <MathRender src={part.val} />
            </div>
        ) : part.type === "break" ? (
            <br />
        ) : part.type === "latex_span" ? (
            <span className={latexStyle ?? "overflow-x"} style={textStyle}>
                <MathRender src={part.val} />
            </span>
        ) : part.type === "mention_span" ? (
            <span
                style={{
                    border: "1px solid #00e",
                    background: "#eef",
                    fontSize: "14px",
                    padding: "2px",
                    marginRight: "4px",
                    ...mentionStyle,
                }}
            >
                {`${part.val} `}
            </span>
        ) : part.type === "text" && part.val == "" ? (
            <br />
        ) : (
            <div className="full-width overflow-wrap-break" style={textStyle}>
                {part.val}
            </div>
        )
    ) : null;
};

export default RenderPart;
