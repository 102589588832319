import {
    getPluginType,
    insertNodes,
    PlateEditor,
    TElement,
} from "@udecode/plate-core";
import { ELEMENT_VIDEO } from "../createVideoPlugin";

export const insertVideo = (editor: PlateEditor, videoString: string) => {
    const text = { text: videoString };
    const video = {
        type: getPluginType(editor, ELEMENT_VIDEO),
        children: [text],
    };
    insertNodes<TElement>(editor, video);
};
