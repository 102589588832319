import { BLOCK_TYPES } from "../../common/index";
const { RE_ORDER_BLOCK } = BLOCK_TYPES;

const RE_ORDER_DEFAULT_VALUE = {
    type: RE_ORDER_BLOCK,
    data: {
        mcq: {
            text: [],
            options: [[]],
            correct_options: [],
            solution: [],
        },
    },
};

export default RE_ORDER_DEFAULT_VALUE;
