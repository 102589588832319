import { gql } from "@apollo/client";export default gql`query tab($id: Int!) {
    tab: home_explore_tab_by_pk(id: $id) {
        id
        banners
        created_at
        deeplink_template
        description
        group
        header_link
        last_child
        title
        type
        updated_at
        status
        order
        build_number

    }
}
`;
