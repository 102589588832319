import { getIsValidFunction } from "../..";
import { BLOCK_TYPES } from "../../common";

const { V2_STORIES_BLOCK, INPUT_AND_FORM_BLOCK } = BLOCK_TYPES;

const checkRootValid = (children: any[]) => {
    if (children?.length <= 0) return false;

    for (let index = 0; index < children.length; index++) {
        const retVal = checkChildPSValid(children[index]);
        if (!retVal) return false;
    }
    return true;
};

export const checkChildPSValid = (block: {
    data: {
        title: any;
        type: any;
        banner: any;
        card_banner: any;
        student_type: any;
        paths?: any[];
    };
}) => {
    if (!block) return false;

    const {
        data: { title, type, banner, card_banner, student_type, paths = [] },
    } = block;

    if (
        !title ||
        !type ||
        !banner ||
        !card_banner ||
        !student_type?.length ||
        !paths.length
    ) {
        return false;
    }

    for (let index = 0; index < paths.length; index++) {
        const retVal = checkPathValid(paths[index]);
        if (!retVal) return false;
    }
    return true;
};

export const checkPathValid = (path: {
    student_type: string;
    passing_badge_count: number;
    children: any[];
    retries_count: number;
}) => {
    if (!path) return false;

    const {
        student_type,
        passing_badge_count,
        children = [],
        retries_count,
    } = path;

    if (
        !student_type ||
        passing_badge_count <= 0 ||
        !retries_count ||
        retries_count < 1 ||
        !children?.length
    ) {
        return false;
    }

    for (let i = 0; i < children.length; i++) {
        const retVal = checkVariantsValid(children[i]);
        if (!retVal) return false;
    }

    return true;
};

export const checkVariantsValid = ({ variants, has_solution }: any) => {
    if (!variants?.length) return false;

    for (let j = 0; j < variants.length; j++) {
        const retVal = checkVariantValid(variants[j], has_solution);
        if (!retVal) return false;
    }

    return true;
};

export const checkVariantValid = (
    variant: {
        type: any;
        badge_count: any;
        block: any;
        screen_config: any;
        config: any[];
    },
    has_solution: boolean,
) => {
    if (!variant) return false;

    const { type, badge_count, block, screen_config, config = [] } = variant;

    if (
        !type ||
        badge_count <= 0 ||
        (screen_config?.button?.is_enabled && !screen_config?.button?.btn_text)
    ) {
        return false;
    }

    const isIncorrectConfig = checkLMConfig(config);

    if (isIncorrectConfig) return false;

    const isSol = checkSolutionConfig(config, has_solution, block?.type);

    if (!isSol) return false;

    return validateBlock(block);
};

export const checkSolutionConfig = (
    config: { type: string }[],
    has_solution: any,
    blockType: any,
) => {
    const noSolutionBlocks = [V2_STORIES_BLOCK, INPUT_AND_FORM_BLOCK];
    if (has_solution && !noSolutionBlocks.includes(blockType)) {
        const solution_present = config?.some(
            (element: { type: string }) => element?.type === "solution",
        );

        if (!solution_present) return false;
    }
    return true;
};

export const checkLMConfig = (config: any[]) => {
    return config?.some((element: { type?: any; other?: any; value: any }) => {
        if (element?.type !== "learning_mode" && !element?.value) return true;

        if (element?.type === "learning_mode") {
            const { other: { children = [] } = {} } = element;

            let hasStory = false;
            children.forEach(
                (lmc: { type: string; data: { children: string | any[] } }) => {
                    if (lmc?.type === "v2_stories") {
                        if (lmc?.data?.children?.length > 0) hasStory = true;
                        else return true;
                    }
                },
            );

            if (!hasStory) return true;
        }
        return false;
    });
};

const validateBlock = (blockObj: any) => {
    const { type } = blockObj;

    const isValidFunction =
        (type && getIsValidFunction(type)) ||
        (() => ({
            isValid: true,
        }));
    const { isValid }: any = isValidFunction({
        block: blockObj,
    });
    return isValid;
};

const isValidCallbackText = ({ block }: any) => {
    const { children } = block;

    return {
        isValid: checkRootValid(children),
        error: "",
    };
};

export default isValidCallbackText;
