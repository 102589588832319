import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import {
    Badge,
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    message,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import React, { useEffect, useState } from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { isEditorEmpty } from "src/modules/worksheet/components/blocks/common";
import ComponentPropForm from "../../../../Figma/renderEditor/ComponentsListEditor/ComponentPropForm";
import CorrectValuesComponent from "../../../../Figma/renderEditor/ComponentsListEditor/CorrectValuesComponent";
import { v4 as uuid } from "uuid";
import { CopyIcon } from "src/components";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import _ from "lodash";

const splitAndUpperCase = (str: string) =>
    str
        ?.split("_")
        .filter((x) => x.length > 0)
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(" ");

export const DragHandle = SortableHandle(() => <MenuOutlined />);

const SortableItem = SortableElement(({ value, child }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Badge.Ribbon color={"cyan"} text={value + 1} placement={"start"}>
                <Card
                    hoverable
                    style={{
                        borderRadius: "8px",
                        marginBottom: "4px",
                        boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                        position: "relative",
                        minWidth: "90px",
                    }}
                    bodyStyle={{
                        background: "transparent",
                        paddingBottom: 10,
                        paddingTop: 10,
                    }}
                >
                    {splitAndUpperCase(child.type)}

                    <div
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "2px",
                        }}
                    >
                        <DragHandle />
                    </div>
                </Card>
            </Badge.Ribbon>
        </div>
    );
});

const SortableList = SortableContainer(({ items, disableWrite }) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "30px",
                width: "100%",
                flexWrap: "wrap",
            }}
        >
            {items.map((child, index) => (
                <SortableItem
                    key={`item-${index}`}
                    index={index}
                    value={index}
                    child={child}
                    disabled={disableWrite}
                />
            ))}
        </div>
    );
});

const GenericItemsForm: React.FC = (props: any) => {
    const {
        disableWrite,
        value: componentsList = [],
        schema,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
        setBlock,
        renderLinkSelect,
    } = props;

    const componentsSchema =
        typeof schema === "string" ? JSON.parse(schema) : schema;

    const [loading, setLoading] = useState(false);
    const [tempConfig, setTempConfig] = useState("");

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setLoading(true);
        const tmpChildren = arrayMoveImmutable(
            componentsList,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        setBlock(tmpChildren);
    };

    useEffect(() => {
        if (loading) {
            setTimeout(() => setLoading(false), 50);
        }
    }, [loading]);

    const getValidateStatus = (data: any, name: any = "") => {
        let error: "" | "error" = "";
        let msg = "";

        if (name !== "default_value") {
            switch (data.type) {
                case "BOOLEAN":
                    break;
                case "JSONSTRING":
                    if (!_.isObject(data.value)) {
                        try {
                            JSON.parse(data.value);
                        } catch (e) {
                            // captureException(e)
                            error = "error";
                            msg = "Invalid JSON";
                        }
                    }
                    break;
                case "CUSTOM_KEYBOARD":
                case "RICH_TEXT_CUSTOM_KEYBOARD":
                    if (!_.isObject(data.value) || !data?.value?.length) {
                        if (
                            !(
                                typeof data?.value === "string" &&
                                data?.value?.includes("@@")
                            )
                        ) {
                            error = "error";
                            msg = "Field cannot be empty";
                        }
                    }
                    break;
                case "FILL":
                    if (!_.isObject(data.value)) {
                        error = "error";
                        msg = "Field cannot be empty";
                    }
                    break;
                case "RICH_TEXT":
                    if (isEditorEmpty(data.value)) {
                        error = "error";
                        msg = "";
                    }
                    break;
                default:
                    if (!data.value) {
                        error = "error";
                        msg = "Field cannot be empty";
                    }
                    break;
            }
        }
        return { error, msg };
    };

    return (
        <Card>
            <Form>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        {!componentsList?.length && (
                            <span
                                style={{
                                    color: "red",
                                    fontSize: "16px",
                                }}
                            >
                                No Items Added
                            </span>
                        )}
                    </Col>
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            gap: "20px",
                            flexWrap: "wrap",
                        }}
                    >
                        {!disableWrite &&
                            componentsSchema.map((item: any, key: number) => (
                                <Button
                                    key={key}
                                    ghost
                                    type="primary"
                                    onClick={() => {
                                        let tmpBlock =
                                            _.cloneDeep(componentsList);
                                        tmpBlock.push({
                                            ...item,
                                            genericId: uuid(),
                                        });
                                        setBlock(tmpBlock);
                                    }}
                                >
                                    Add {splitAndUpperCase(item.type)}
                                </Button>
                            ))}
                    </Col>
                    <Col span={24}>
                        <SortableList
                            items={componentsList}
                            onSortEnd={onSortEnd}
                            axis="xy"
                            useDragHandle
                            disableWrite={disableWrite}
                        />
                    </Col>
                    <Col span={24}>
                        {!loading &&
                            Array.isArray(componentsList) &&
                            componentsList?.map((data, idx: number) =>
                                !data ? (
                                    <></>
                                ) : (
                                    <div
                                        key={`item-${idx}`}
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <Badge.Ribbon
                                            color={"cyan"}
                                            text={idx + 1}
                                            placement={"start"}
                                        >
                                            <div
                                                style={{
                                                    border: "1px solid #717171",
                                                    borderRadius: "15px",
                                                    padding: "10px",
                                                    width: "100%",
                                                    display: "flex",
                                                    gap: "20px",
                                                }}
                                            >
                                                <div />
                                                <div
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Row
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        gutter={[20, 20]}
                                                    >
                                                        <Col span={20}>
                                                            <h3
                                                                style={{
                                                                    textTransform:
                                                                        "capitalize",
                                                                }}
                                                            >
                                                                {splitAndUpperCase(
                                                                    data.type,
                                                                )}
                                                            </h3>
                                                        </Col>
                                                        <Col
                                                            span={4}
                                                            style={{
                                                                paddingTop:
                                                                    "4px",
                                                                display: "flex",
                                                                gap: "10px",
                                                            }}
                                                        >
                                                            <CopyIcon
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                isButton={true}
                                                                onClick={() => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            componentsList,
                                                                        );
                                                                    tmpBlock.splice(
                                                                        idx,
                                                                        0,
                                                                        updateKeysAndCopy(
                                                                            componentsList[
                                                                                idx
                                                                            ],
                                                                            true,
                                                                            true,
                                                                        ),
                                                                    );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                    message.info(
                                                                        `Input duplicated!`,
                                                                    );
                                                                }}
                                                            />
                                                            <Button
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                icon={
                                                                    <DeleteOutlined />
                                                                }
                                                                type="primary"
                                                                shape="circle"
                                                                danger
                                                                size="small"
                                                                onClick={() => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            componentsList,
                                                                        );
                                                                    _.pullAt(
                                                                        tmpBlock,
                                                                        idx,
                                                                    );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Divider />
                                                    <Row
                                                        key={idx}
                                                        justify="start"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        gutter={[20, 20]}
                                                    >
                                                        {Object.keys(data)
                                                            .filter(
                                                                (v) =>
                                                                    ![
                                                                        "type",
                                                                        "props",
                                                                        "correct_values",
                                                                        "linked_global_context_variable",
                                                                        "genericId",
                                                                        "tmpId",
                                                                        "id",
                                                                        "value",
                                                                    ].includes(
                                                                        v,
                                                                    ) &&
                                                                    !data[v]
                                                                        .is_local,
                                                            )
                                                            .map((v, key) => {
                                                                return (
                                                                    <ComponentPropForm
                                                                        {...{
                                                                            getValidateStatus,
                                                                            disableWrite,
                                                                            data,
                                                                            setCurConfig:
                                                                                (
                                                                                    data: any,
                                                                                ) => {
                                                                                    let tmpBlock =
                                                                                        _.cloneDeep(
                                                                                            componentsList,
                                                                                        );

                                                                                    tmpBlock =
                                                                                        _.set(
                                                                                            tmpBlock,
                                                                                            [
                                                                                                idx,
                                                                                                v,
                                                                                                "value",
                                                                                            ],
                                                                                            data,
                                                                                        );
                                                                                    setBlock(
                                                                                        tmpBlock,
                                                                                    );
                                                                                },
                                                                            idx,
                                                                            v,
                                                                            hasMentions,
                                                                            mentionsList,
                                                                            currentEditor,
                                                                            setCurrentEditor,
                                                                            key,
                                                                        }}
                                                                    />
                                                                );
                                                            })}
                                                        {data.props &&
                                                            Object.keys(
                                                                data.props,
                                                            )
                                                                .filter(
                                                                    (v) =>
                                                                        !data
                                                                            .props[
                                                                            v
                                                                        ]
                                                                            .is_local,
                                                                )
                                                                .map(
                                                                    (
                                                                        v,
                                                                        key,
                                                                    ) => {
                                                                        return (
                                                                            <ComponentPropForm
                                                                                {...{
                                                                                    getValidateStatus,
                                                                                    disableWrite,
                                                                                    data: data.props,
                                                                                    setCurConfig:
                                                                                        (
                                                                                            data: any,
                                                                                        ) => {
                                                                                            let tmpBlock =
                                                                                                _.cloneDeep(
                                                                                                    componentsList,
                                                                                                );

                                                                                            tmpBlock =
                                                                                                _.set(
                                                                                                    tmpBlock,
                                                                                                    [
                                                                                                        idx,
                                                                                                        "props",
                                                                                                        v,
                                                                                                        "value",
                                                                                                    ],
                                                                                                    data,
                                                                                                );
                                                                                            setBlock(
                                                                                                tmpBlock,
                                                                                            );
                                                                                        },
                                                                                    idx,
                                                                                    v,
                                                                                    hasMentions,
                                                                                    mentionsList,
                                                                                    currentEditor,
                                                                                    setCurrentEditor,
                                                                                    key,
                                                                                }}
                                                                            />
                                                                        );
                                                                    },
                                                                )}
                                                        {data.correct_values && (
                                                            <>
                                                                <h3>
                                                                    Correct
                                                                    Answers
                                                                    Section
                                                                </h3>
                                                                {!(
                                                                    typeof data
                                                                        ?.correct_values
                                                                        ?.value ===
                                                                        "string" &&
                                                                    data?.correct_values?.value?.includes(
                                                                        "@@",
                                                                    )
                                                                ) && (
                                                                    <CorrectValuesComponent
                                                                        {...{
                                                                            correct_values:
                                                                                data.correct_values,
                                                                            getValidateStatus,
                                                                            disableWrite,
                                                                            data: data,
                                                                            setCurConfig:
                                                                                (
                                                                                    data: any,
                                                                                ) => {
                                                                                    let tmpBlock =
                                                                                        _.cloneDeep(
                                                                                            componentsList,
                                                                                        );

                                                                                    tmpBlock =
                                                                                        _.set(
                                                                                            tmpBlock,
                                                                                            [
                                                                                                idx,
                                                                                                "correct_values",
                                                                                                "value",
                                                                                            ],
                                                                                            data,
                                                                                        );
                                                                                    setBlock(
                                                                                        tmpBlock,
                                                                                    );
                                                                                },
                                                                            idx,
                                                                            hasMentions,
                                                                            mentionsList,
                                                                            currentEditor,
                                                                            setCurrentEditor,
                                                                        }}
                                                                    />
                                                                )}

                                                                <Form.Item label="variable">
                                                                    <Select
                                                                        value={
                                                                            typeof data
                                                                                .correct_values
                                                                                ?.value ===
                                                                                "string" &&
                                                                            data.correct_values?.value?.includes(
                                                                                "@@",
                                                                            )
                                                                                ? data
                                                                                      ?.correct_values
                                                                                      ?.value
                                                                                : null
                                                                        }
                                                                        placeholder="Select type"
                                                                        options={[
                                                                            {
                                                                                label: "none",
                                                                                value: null,
                                                                            },
                                                                            ...mentionsList?.map(
                                                                                (v: {
                                                                                    text: any;
                                                                                }) => ({
                                                                                    label: v.text,
                                                                                    value: `@@${v.text}@@`,
                                                                                }),
                                                                            ),
                                                                        ]}
                                                                        style={{
                                                                            width: "150px",
                                                                            flexGrow: 0,
                                                                        }}
                                                                        onChange={(
                                                                            val: any,
                                                                        ) => {
                                                                            let tmpBlock =
                                                                                _.cloneDeep(
                                                                                    componentsList,
                                                                                );

                                                                            tmpBlock =
                                                                                _.set(
                                                                                    tmpBlock,
                                                                                    [
                                                                                        idx,
                                                                                        "correct_values",
                                                                                        "value",
                                                                                    ],
                                                                                    val ??
                                                                                        [],
                                                                                );
                                                                            setBlock(
                                                                                tmpBlock,
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            disableWrite
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </>
                                                        )}

                                                        {renderLinkSelect &&
                                                            renderLinkSelect({
                                                                next_line: true,
                                                                value: data
                                                                    ?.linked_global_context_variable
                                                                    ?.name,
                                                                onChange: (
                                                                    v,
                                                                ) => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            componentsList,
                                                                        );

                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                idx,
                                                                                "linked_global_context_variable",
                                                                                "name",
                                                                            ],
                                                                            v,
                                                                        );
                                                                    // tmpBlock =
                                                                    //     _.set(
                                                                    //         tmpBlock,
                                                                    //         [
                                                                    //             idx,
                                                                    //             "value",
                                                                    //         ],
                                                                    //         v !==
                                                                    //             null
                                                                    //             ? `@@${v}@@`
                                                                    //             : v,
                                                                    //     );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                },
                                                            })}

                                                        <Form.Item label="Value from variable">
                                                            <Select
                                                                value={
                                                                    data?.value
                                                                }
                                                                placeholder="Select type"
                                                                options={[
                                                                    {
                                                                        label: "none",
                                                                        value: null,
                                                                    },
                                                                    ...mentionsList?.map(
                                                                        (v: {
                                                                            text: any;
                                                                        }) => ({
                                                                            label: v.text,
                                                                            value: `@@${v.text}@@`,
                                                                        }),
                                                                    ),
                                                                ]}
                                                                style={{
                                                                    width: "150px",
                                                                    flexGrow: 0,
                                                                }}
                                                                onChange={(
                                                                    val,
                                                                ) => {
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            componentsList,
                                                                        );
                                                                    tmpBlock =
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                idx,
                                                                                "value",
                                                                            ],
                                                                            val,
                                                                        );
                                                                    setBlock(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Badge.Ribbon>
                                    </div>
                                ),
                            )}
                    </Col>
                </Row>
            </Form>
            <Divider />
            {componentsList?.length > 0 && (
                <>
                    <Button
                        disabled={disableWrite}
                        type="primary"
                        onClick={() => {
                            message.info(
                                `Copied components config to clipboard!`,
                            );

                            navigator.clipboard.writeText(
                                JSON.stringify(componentsList),
                            );
                        }}
                    >
                        Copy this components Config to Clipboard
                    </Button>
                    <Divider />
                </>
            )}
            <Form.Item label={"Paste components config here"}>
                <Input.TextArea
                    disabled={disableWrite}
                    value={tempConfig}
                    placeholder="Paste Config here"
                    onChange={(e) => setTempConfig(e.target.value)}
                />
                <Button
                    disabled={disableWrite}
                    style={{ marginTop: "10px" }}
                    type="primary"
                    onClick={() => {
                        try {
                            const parsedJson = JSON.parse(tempConfig);
                            if (!Array.isArray(parsedJson)) {
                                message.error("Error in JSON!");
                                return;
                            }
                            setLoading(true);
                            setBlock(parsedJson);
                            setTempConfig("");
                            message.info("Updated!");
                        } catch (e) {
                            // captureException(e);
                            message.error("Error in JSON!");
                        }
                    }}
                >
                    Update config
                </Button>
            </Form.Item>
        </Card>
    );
};

export default GenericItemsForm;
