import { BLOCK_TYPES } from "../../common";
import { isValidCallbackCrossword } from "../Crossword";

const { V2_DRAG_AND_SWIPE_BLOCK } = BLOCK_TYPES;

const isValidCallback = (props: any) =>
    isValidCallbackCrossword({
        ...props,
        blockType: V2_DRAG_AND_SWIPE_BLOCK,
    });

export default isValidCallback;
