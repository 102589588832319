import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Select, Switch } from "antd";
import { cloneDeep, get, set } from "lodash";
import React from "react";
import { BlockPicker } from "react-color";
import { RenderUpload } from "src/components";
import { replaceCDNUrl } from "src/modules/worksheet/components/blocks/helpers";
import TablePaddingEditor from "../../../../Figma/renderEditor/TablePadding";
import TagContainer from "./TagContainer";

const TagClusterContainer = (props) => {
    const {
        block,
        disableWrite,
        setBlock,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
    } = props;
    const {
        data: { scale_balance_settings },
    } = block;
    const { dragItems = [] } = scale_balance_settings;
    const setCurrentCell = (cell: any) => {
        let tmpCell = cloneDeep(block);
        tmpCell = set(
            tmpCell,
            ["data", "scale_balance_settings", "dragItems"],
            cell,
        );
        setBlock(tmpCell);
    };

    return (
        <Form
            style={{
                maxHeight: "75vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
            }}
        >
            <div>
                <TagContainer
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    disableWrite={disableWrite}
                    currentEditor={currentEditor}
                    setCurrentEditor={setCurrentEditor}
                    items={dragItems || []}
                    setBlock={(v) => {
                        setCurrentCell(v);
                    }}
                    noEffects={true}
                    schema={{
                        data: [
                            { text: [], showText: 1 },
                            {
                                height: 55,
                                showImg: 1,
                                img: {
                                    rotation: 0,
                                    asset: "https://cdn.homeworkapp.ai/sets-gamify-assets/dev/home-explore/document/1709121007520",
                                    type: "IMAGE",
                                },
                                width: 50,
                            },
                        ],
                        category: "both",
                        value: 1,
                        bg_type1: 0,
                        enable: true,
                        name: "",
                        width: 50,
                        height: 55,
                        bg: { type: "SOLID", color: "#F2F2F2" },
                        showDisabled: 0,
                        index: "0.0",
                        type: "dnd",
                        weight: 0,
                        maxDragLimit: 999,
                        padding: {
                            enabled: false,
                            custom: { enabled: false },
                        },
                        border: { enabled: false },
                    }}
                    categories={["left", "right", "both"]}
                />
            </div>
        </Form>
    );
};

export default TagClusterContainer;
