import React, { useState, useEffect } from "react";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import emitter from "src/helpers/emitter";
import PuzzleTagManager from "../GenericTable/Puzzle/PuzzleTagManager";
import { TABLE_PUZZLE_TAG_ACTIVATED } from "../GenericTable/utils/constants";

import PuzzleTagModal from "../GenericTable/Puzzle/PuzzleTagModal";

interface PuzzleTagButtonProps {
    genericId: string;
    disabled: boolean;
    tag: any;
    tableData: any;
    setTag: (tag: any) => void;
}

const PuzzleTagButton: React.FC<PuzzleTagButtonProps> = ({
    genericId,
    disabled,
    tag,
    tableData,
    setTag,
}) => {
    const [tagActive, setTagActive] = useState(false);

    useEffect(() => {
        const listener = (tagId: string) => {
            if (tagId == null && tagActive) {
                setTagActive(false);
            } else {
                const isActive = tagId === genericId;
                if (tagActive !== isActive) {
                    setTagActive(isActive);
                }
            }
        };
        emitter.on(TABLE_PUZZLE_TAG_ACTIVATED, listener);
        return () => emitter.off(TABLE_PUZZLE_TAG_ACTIVATED, listener);
    }, [genericId, tagActive]);

    return (
        <>
            <Button
                type="text"
                icon={
                    tagActive ? (
                        <CheckCircleFilled
                            style={{
                                color: "#1790FF",
                            }}
                        />
                    ) : (
                        <CheckCircleOutlined
                            style={{
                                color: "#1790FF",
                            }}
                        />
                    )
                }
                size="large"
                shape="circle"
                disabled={disabled}
                onClick={() => {
                    if (!tagActive) {
                        PuzzleTagManager.activateTag(genericId);
                    } else {
                        PuzzleTagManager.deactivateActiveTag();
                    }
                }}
            />
            <br />
            {tagActive && (
                <Button
                    onClick={() => {
                        const updatedTag = PuzzleTagManager.generatePuzzleTag(
                            tag,
                            tableData,
                        );
                        console.log("updatedTag-----", updatedTag);
                        setTag(updatedTag);
                    }}
                >
                    Generate Puzzle Tag
                </Button>
            )}
            <br />
            {tag?.cells && tag.cells[0] && (
                <PuzzleTagModal tag={tag} setTag={setTag} />
            )}
        </>
    );
};

export default PuzzleTagButton;
