import { BLOCK_TYPES } from "../../common/index";
const { CONTENT_BLOCK } = BLOCK_TYPES;

const PHONE_DEFAULT_VALUE = {
    type: CONTENT_BLOCK,
    data: {
        [CONTENT_BLOCK]: {
            layout: "1",
            background: { image: "", sound: "" },
            title: [],
            description: [],
            button: {
                buttonColor: "#FFDE49",
                buttonText: "Play",
                textColor: "#000000",
                buttonType: "internal", // internal/external
                buttonUrl: "", // if external
            },
            isEnd: false,
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
    },
};

export default PHONE_DEFAULT_VALUE;
