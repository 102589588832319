import React, { useEffect, useRef, useState } from "react";
import { getBackgroundColor } from "./helpers";
import { renderer } from "src/modules/worksheet/components/blocks/helpers";

const latexTextStyle = {
    color: "#828282",
    fontFamily: "Epilogue",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "200",
    lineHeight: "150%", // This will effectively be 24px if the font size is 16px
    width: "auto",
};

const getNormalDropdownUpdatedStyles = (dropdownObj) => {
    const dimensionControl = {
        enabled: dropdownObj?.dimensionControl?.enabled,
        minWidth: Number(dropdownObj?.dimensionControl?.minWidth),
        minHeight: Number(dropdownObj?.dimensionControl?.minHeight),
        fontSize: 16,
    };
    if (dimensionControl?.enabled) {
        const newPaddingVert =
            (dimensionControl.minHeight - dimensionControl.fontSize) / 2;

        // const newPadding = `${newPaddingVert}px 8px`;
        return {
            parentStyles: {
                minWidth: dimensionControl.minWidth,
                minHeight: dimensionControl.minHeight,
            },
            childStyles: {
            minHeight: dimensionControl.minHeight - 2,
            minWidth: dimensionControl.minWidth - 2,
            }
        };
    } else {
        return {
            parentStyles:{},
            childStyles:{}
        };
    }
};

const DropdownComponent = ({ dropdownObj }) => {
    const [selected, setSelected] = useState(dropdownObj?.value ?? null);
    useEffect(() => {
        if (
            dropdownObj.default &&
            (!dropdownObj.value || dropdownObj.value === "")
        ) {
            setSelected(dropdownObj.default);
        } else {
            setSelected(dropdownObj.value);
        }
    }, [dropdownObj.value, dropdownObj.default]);

    const selectedLabel = dropdownObj.options?.find(
        (option) => option.value === selected,
    )?.label;

    const backgroundStyle = {
        backgroundColor: dropdownObj.fillIndex
            ? getBackgroundColor(dropdownObj.fillIndex)
            : getBackgroundColor(0),
    };

    const { 
        parentStyles,
        childStyles

     } = getNormalDropdownUpdatedStyles(dropdownObj);

    return (
        <div
            className="renderer-table-dropdown-wrapper"
            style={{
                ...backgroundStyle,
                ...parentStyles,
            }}
        >
            <div
                className="renderer-table-dropdown-item"
                style={{
                    ...backgroundStyle,
                    ...childStyles,
                }}
            >
                <span className="renderer-table-dropdown-text">
                    {selected ? (
                        dropdownObj.type === "latex" ? (
                            <>
                                {renderer(selectedLabel, {
                                    textStyle: latexTextStyle,
                                })}
                            </>
                        ) : (
                            selectedLabel
                        )
                    ) : (
                        dropdownObj.placeholder ?? "Select ▼"
                    )}
                </span>
            </div>
        </div>
    );
};

export default DropdownComponent;
