import renderBlockAllInOne from "./renderBlock";
import renderEditorAllInOne from "./renderEditor";
import isValidCallbackAllInOne from "./isValid";
import DEFAULT_BLOCK_VALUE from "./defaultValue";
import renderSettingsAllInOne from "./renderSettings";
import renderCardAllInOne from "./renderCard";
import { BLOCK_TYPES } from "../../common/index";

const { ALL_IN_ONE } = BLOCK_TYPES;

const ALL_IN_ONE_BLOCK_CONFIG = {
    [ALL_IN_ONE]: {
        label: "All In One",
        defaultValue: DEFAULT_BLOCK_VALUE,
        renderBlock: renderBlockAllInOne,
        renderEditor: renderEditorAllInOne,
        renderCard: renderCardAllInOne,
        renderSettings: renderSettingsAllInOne,
        isValidCallback: isValidCallbackAllInOne,
    },
};

export {
    renderBlockAllInOne,
    renderCardAllInOne,
    renderEditorAllInOne,
    renderSettingsAllInOne,
    isValidCallbackAllInOne,
    DEFAULT_BLOCK_VALUE,
    ALL_IN_ONE_BLOCK_CONFIG,
};
