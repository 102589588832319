import { createPluginFactory } from "@udecode/plate-core";

export const ELEMENT_LATEX = "latex";

export const createLatexPlugin = createPluginFactory<any>({
    key: ELEMENT_LATEX,
    isElement: true,
    isVoid: true,
    then: (editor, { type }) => ({
        deserializeHtml: {
            rules: [
                {
                    validNodeName: "LATEX",
                },
            ],
            getNode: (el) => ({
                type,
                // url: el.getAttribute('src'),
            }),
        },
    }),
});
