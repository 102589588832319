import React from "react";
import { Spin, Select } from "antd";

import { compose, config } from "src/helpers";
import { Field, RenderSelect } from "src/components";

import { withTabs } from "src/modules/tab/operations";

import { withStateAndActionsForTab } from "../resolvers";

const Option = Select.Option;

const SubjectsField = (props) => {
    const { loading, tabs, where, onNameChange, onChange, value } = props;

    return (
        <Field
            labelInValue
            showSearch={true}
            filterOption={false}
            onSearch={onNameChange}
            notFoundContent={loading ? <Spin size="small" /> : null}
            name={"tabs"}
            component={RenderSelect}
            placeholder={"Tabs"}
            label={"Tabs"}
            value={value || "None"}
            loading={loading}
            onChange={onChange}
            inFilter={true}
            noBotMarging={true}
        >
            {[
                // config.EMPTY_OPTION,
                ...(tabs?.map((tab) => ({
                    id: tab.id,
                    label: tab.title || "",
                    value: JSON.stringify(tab.id),
                })) || []),
            ]?.map(
                ({
                    id,
                    value,
                    label = "",
                }: {
                    id: string;
                    value: string;
                    label: string;
                }) => (
                    <Option key={id} value={value}>
                        {label}
                    </Option>
                ),
            )}
        </Field>
    );
};

export default compose(withStateAndActionsForTab, withTabs)(SubjectsField);
