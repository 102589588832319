import { gql } from "@apollo/client";export default gql`mutation updateSet(
    $id: Int!
    $title: String = ""
    $description: String = ""
    $cover: String = ""
    $chapter: String = ""
    $topic: String = ""
    $status: String = ""
    $duration: Int = 0
    $attempts: Int = 0
    $type: String = ""
    $subject: String = ""
    $class: String = ""
    $metadata_tags: json = null
) {
    updateSet: update_set_by_pk(
        pk_columns: { id: $id }
        _set: {
            title: $title
            description: $description
            cover: $cover
            chapter: $chapter
            topic: $topic
            status: $status
            duration: $duration
            attempts: $attempts
            type: $type
            subject: $subject
            class: $class
            metadata_tags: $metadata_tags
        }
    ) {
        id
        title
        description
        cover
        chapter
        topic
        attempts
        duration
        status
        type
        class
    }
}
`;
