import { Divider, Input, Modal, Typography } from "antd";
import { Form, Space, Row, Col, Button, message } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { SlateEditor } from "src/components";
import { AudioPicker, isEditorEmpty } from "..";
import { DEFAULT_COMPUTATION_SCHEMA } from "../../renderers/Crossword/defaultValue";
import ComputeFunctionEditor from "./ComputeFunctionEditor";
import { captureException } from "@sentry/react";

const ConditionField = (props: any) => {
    const {
        isModalOpen,
        closeForm,
        condition,
        onSave,
        curIndex,
        isEditor,
        setIsEditor,
        hasMentions,
        mentionsList = [],
        disableWrite,
        variables,
        isReadOnlyMode,
    } = props;

    const tmpId = `${curIndex}_text`;

    const [currentEditor, setCurrentEditor] = useState("");

    useEffect(() => {
        if (!isEditor) {
            setCurrentEditor("");
        }
    }, [tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor !== "" && setIsEditor) {
            setIsEditor(true);
        }
    }, [tmpId, currentEditor]);

    const [isSubmitting, setSubmitting] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [curCondition, setCurCondition] = useState(condition);
    const [tempCondition, setTempCondition] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => setCurCondition(condition), [condition]);

    useEffect(() => {
        if (
            (curCondition &&
                (isEditorEmpty(curCondition?.description) ||
                    isEditorEmpty(curCondition?.feedbacks?.check?.text) ||
                    isEditorEmpty(curCondition?.feedbacks?.correct?.text) ||
                    isEditorEmpty(curCondition?.feedbacks?.incorrect?.text))) ||
            !curCondition?.computation?.length
        ) {
            setDisabled(true);
            return;
        }

        const checkComputations = curCondition?.computation?.some(
            (element: {
                type: any;
                variables: any;
                operation_type: any;
                match_type: any;
                answer: any;
                digit_position: any;
                digit_repeat: any;
                digit_operation: any;
                number_operation: any;
                multiple_answers: any;
            }) => {
                const {
                    type,
                    variables,
                    operation_type,
                    match_type,
                    answer,
                    digit_position,
                    digit_repeat,
                    digit_operation,
                    number_operation,
                    multiple_answers = [],
                } = element;

                if (!variables?.length) {
                    return true;
                }
                switch (type) {
                    case "unique":
                        break;
                    case "single_answer":
                        if (!answer?.value?.trim()) return true;
                        break;
                    case "digit_position":
                        if (
                            !digit_position?.length ||
                            digit_position?.some(
                                (item: { position: string; value: string }) =>
                                    !item?.position || !item?.value,
                            )
                        ) {
                            return true;
                        }
                        break;
                    case "multiple_answers":
                        if (
                            !multiple_answers?.length ||
                            multiple_answers?.some(
                                (item: { type: string; value: string }) =>
                                    !item?.type || !item?.value,
                            )
                        ) {
                            return true;
                        }
                        break;
                    case "digit_repeat":
                        if (
                            !digit_repeat?.length ||
                            digit_repeat?.some(
                                (item: {
                                    repeat_value: string;
                                    value: string;
                                    match_type: string;
                                }) =>
                                    !item?.repeat_value ||
                                    !item?.value ||
                                    !item?.match_type,
                            )
                        ) {
                            return true;
                        }
                        break;
                    case "digit_operation":
                        if (
                            !digit_operation?.length ||
                            digit_operation?.some(
                                (item: { type: string; value: string }) =>
                                    !item?.type || !item?.value,
                            ) ||
                            !operation_type ||
                            !match_type ||
                            !answer?.value?.trim()
                        ) {
                            return true;
                        }
                        break;
                    case "number_operation":
                        if (
                            !number_operation?.length ||
                            number_operation?.some(
                                (item: { type: string; value: string }) =>
                                    !item?.type || !item?.value,
                            ) ||
                            !operation_type ||
                            !match_type ||
                            !answer?.value?.trim()
                        ) {
                            return true;
                        }
                        break;
                    default:
                        break;
                }

                return false;
            },
        );

        if (checkComputations) {
            setDisabled(true);
            return;
        }

        setDisabled(false);
    }, [curCondition]);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            await onSave(curCondition);
            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e);
            console.log(e);
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        closeForm();
    };

    useEffect(() => {
        if (loading) {
            setTimeout(() => setLoading(false), 50);
        }
    }, [loading]);

    return (
        <>
            <Modal
                title={
                    <Typography.Title level={4}>Add Condition</Typography.Title>
                }
                onCancel={closeForm}
                open={isModalOpen}
                destroyOnClose
                width={800}
                footer={null}
            >
                <Form>
                    <Button
                        disabled={disableWrite}
                        type="primary"
                        onClick={() => {
                            message.info(`Copied Condition to clipboard!`);

                            navigator.clipboard.writeText(
                                JSON.stringify(curCondition),
                            );
                        }}
                    >
                        Copy this Condition to Clipboard
                    </Button>
                    <Divider />

                    <Form.Item label={"Paste Condition here"}>
                        <Input.TextArea
                            disabled={disableWrite}
                            value={tempCondition}
                            placeholder="Paste Condition here"
                            onChange={(e) => setTempCondition(e.target.value)}
                        />
                        <Button
                            disabled={disableWrite}
                            style={{ marginTop: "10px" }}
                            type="primary"
                            onClick={() => {
                                try {
                                    const parsedJson =
                                        JSON.parse(tempCondition);
                                    setLoading(true);
                                    setCurCondition(parsedJson);
                                    setTempCondition("");
                                    message.success("Updated!");
                                } catch (e) {
                                    // captureException(e)
                                    message.error("Error in JSON!");
                                }
                            }}
                        >
                            Update Condition
                        </Button>
                    </Form.Item>

                    <hr />

                    <Row gutter={16}>
                        <Col span={24}>
                            <h3 style={{ fontWeight: "bold" }}>
                                Condition Text
                            </h3>
                            <SlateEditor
                                disabled={disableWrite}
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                id={`option_text`}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(curCondition);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["description"],
                                        value,
                                    );
                                    setCurCondition(tmpBlock);
                                }}
                                value={curCondition?.description}
                                isFocused={currentEditor === `option_text`}
                                setEditor={(id: string) => {
                                    setCurrentEditor(id);
                                }}
                                isOption={true}
                            />
                        </Col>

                        <Divider />

                        <Col span={24}>
                            <h3 style={{ fontWeight: "bold" }}>
                                Computation Function
                            </h3>
                            <div
                                style={
                                    !loading &&
                                    curCondition?.computation?.length > 0
                                        ? {
                                              border: "1px solid #555",
                                              padding: "16px",
                                          }
                                        : {}
                                }
                            >
                                {!loading &&
                                    curCondition?.computation?.map(
                                        (item: any, i: any) => (
                                            <ComputeFunctionEditor
                                                key={i}
                                                variables={variables}
                                                item={item}
                                                disableWrite={disableWrite}
                                                index={i}
                                                updateCondition={(
                                                    data: any,
                                                ) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(
                                                            curCondition,
                                                        );
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        ["computation", i],
                                                        data,
                                                    );
                                                    setCurCondition(tmpBlock);
                                                }}
                                                deleteComputeFunction={() => {
                                                    let tmpBlock =
                                                        _.cloneDeep(
                                                            curCondition,
                                                        );

                                                    const tmpConditions =
                                                        _.cloneDeep(
                                                            curCondition?.computation,
                                                        );

                                                    tmpConditions?.splice(i, 1);

                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        ["computation"],
                                                        tmpConditions,
                                                    );

                                                    setCurCondition(tmpBlock);
                                                    setLoading(true);
                                                }}
                                            />
                                        ),
                                    )}
                            </div>

                            {!curCondition?.computation?.length && (
                                <h4 style={{ color: "red" }}>
                                    Add Computation Function
                                </h4>
                            )}

                            <Button
                                type="primary"
                                ghost
                                disabled={disableWrite}
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(curCondition);
                                    if (tmpBlock?.computation?.length > 0) {
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "computation",
                                                tmpBlock?.computation?.length,
                                            ],
                                            DEFAULT_COMPUTATION_SCHEMA,
                                        );
                                    } else {
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["computation"],
                                            [DEFAULT_COMPUTATION_SCHEMA],
                                        );
                                    }
                                    setCurCondition(tmpBlock);
                                }}
                            >
                                Add Another Compute Function
                            </Button>
                        </Col>

                        <Divider />

                        <Col span={12}>
                            <h3 style={{ fontWeight: "bold" }}>
                                Feedback while Checking
                            </h3>
                            <SlateEditor
                                disabled={disableWrite}
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                id={`option_check`}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(curCondition);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["feedbacks", "check", "text"],
                                        value,
                                    );
                                    setCurCondition(tmpBlock);
                                }}
                                value={curCondition?.feedbacks?.check?.text}
                                isFocused={currentEditor === `option_check`}
                                setEditor={(id: string) => {
                                    setCurrentEditor(id);
                                }}
                                isOption={true}
                            />
                            <br />
                            <Form.Item label={"Audio"}>
                                <AudioPicker
                                    audio={
                                        curCondition?.feedbacks?.check?.audio
                                    }
                                    disabled={isReadOnlyMode}
                                    setBlockCustom={(value: any) => {
                                        let tmpBlock =
                                            _.cloneDeep(curCondition);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["feedbacks", "check", "audio"],
                                            value,
                                        );
                                        setCurCondition(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <h3 style={{ fontWeight: "bold" }}>
                                Feedback if Satisfied
                            </h3>
                            <SlateEditor
                                disabled={disableWrite}
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                id={`option_correct`}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(curCondition);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["feedbacks", "correct", "text"],
                                        value,
                                    );
                                    setCurCondition(tmpBlock);
                                }}
                                value={curCondition?.feedbacks?.correct?.text}
                                isFocused={currentEditor === `option_correct`}
                                setEditor={(id: string) => {
                                    setCurrentEditor(id);
                                }}
                                isOption={true}
                            />
                            <br />
                            <Form.Item label={"Audio"}>
                                <AudioPicker
                                    audio={
                                        curCondition?.feedbacks?.correct?.audio
                                    }
                                    disabled={isReadOnlyMode}
                                    setBlockCustom={(value: any) => {
                                        let tmpBlock =
                                            _.cloneDeep(curCondition);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["feedbacks", "correct", "audio"],
                                            value,
                                        );
                                        setCurCondition(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <h3 style={{ fontWeight: "bold" }}>
                                Feedback if not Satisfied
                            </h3>
                            <SlateEditor
                                disabled={disableWrite}
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                id={`option_incorrect`}
                                onChange={(value: any) => {
                                    let tmpBlock = _.cloneDeep(curCondition);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["feedbacks", "incorrect", "text"],
                                        value,
                                    );
                                    setCurCondition(tmpBlock);
                                }}
                                value={curCondition?.feedbacks?.incorrect?.text}
                                isFocused={currentEditor === `option_incorrect`}
                                setEditor={(id: string) => {
                                    setCurrentEditor(id);
                                }}
                                isOption={true}
                            />
                            <br />
                            <Form.Item label={"Audio"}>
                                <AudioPicker
                                    audio={
                                        curCondition?.feedbacks?.incorrect
                                            ?.audio
                                    }
                                    disabled={isReadOnlyMode}
                                    setBlockCustom={(value: any) => {
                                        let tmpBlock =
                                            _.cloneDeep(curCondition);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            ["feedbacks", "incorrect", "audio"],
                                            value,
                                        );
                                        setCurCondition(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Space style={{ float: "right" }}>
                                <Button type="default" onClick={closeForm}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                    disabled={isDisabled}
                                >
                                    Submit
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ConditionField;
