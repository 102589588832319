import { PlusOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Divider,
    Dropdown,
    Empty,
    Menu,
    Space,
    message,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { getCardPreview } from "../../../../blocks";
import { Can } from "src/services/casl";

const SortableItemCustom = SortableElement((props: any) => {
    const { blocks, idx } = props;
    return getCardPreview({
        ...props,
        block: blocks.find((x: any, index: number) => index == idx),
    });
});

const SortableContainerCustom = SortableContainer(({ children }: any) => {
    return (
        <div
            style={{ overflow: "scroll", height: "70vh", paddingRight: "10px" }}
        >
            {children}
        </div>
    );
});

export const BlocksColumn = (props: any) => {
    const {
        blocks = [],
        addBlock,
        blockTypes = [],
        setBlocks,
        worksheet,
        setIsEditor,
    } = props;

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        setBlocks(
            arrayMoveImmutable(blocks, oldIndex, newIndex).filter((el) => !!el),
        );
        if (worksheet?.type === "quiz_form") {
            message.warning(
                "Please update the branching logic and conditions and reference variables.",
            );
        }
    };

    const blocksMenu = (
        <Menu
            items={blockTypes.map(({ label, value }: any, idx: number) => ({
                key: idx,
                label: <span>{label}</span>,
                onClick: () => {
                    addBlock(value, blocks.length);
                },
            }))}
        />
    );

    return (
        <Card
            style={{ borderRadius: "8px", marginRight: "8px", height: "85vh" }}
            bordered={true}
            onClick={() => {
                setIsEditor(false);
            }}
        >
            <div>
                Content
                <div style={{ float: "right" }}>
                    <Can I="edit" a={"worksheet_block"} passThrough>
                        {(allowed: boolean) => (
                            <Dropdown
                                overlay={blocksMenu}
                                trigger={["click"]}
                                // overlayStyle={{ width: "100px" }}
                                disabled={!allowed}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space>
                                        <Button type="dashed">
                                            <PlusOutlined
                                                style={{ fontSize: "15px" }}
                                            />
                                        </Button>
                                    </Space>
                                </a>
                            </Dropdown>
                        )}
                    </Can>
                </div>
            </div>
            <Divider />
            <SortableContainerCustom onSortEnd={onSortEnd} useDragHandle>
                {blocks.map(({ type, id, tmpId }: any, idx: number) => (
                    <SortableItemCustom
                        {...props}
                        idx={idx}
                        type={type}
                        key={`item-${id || tmpId}`}
                        index={idx}
                    />
                ))}

                {!blocks.length && <Empty description={`Noting to show!`} />}
            </SortableContainerCustom>
        </Card>
    );
};
