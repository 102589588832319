import renderBlockEmail from "./renderBlock";
import renderEditorEmail from "./renderEditor";
import isValidCallbackEmail from "./isValid";
import EMAIL_DEFAULT_VALUE from "./defaultValue";
import renderSettingsEmail from "./renderSettings";
import renderCardEmail from "./renderCard";
import { BLOCK_TYPES } from "../../common/index";

const { EMAIL_BLOCK } = BLOCK_TYPES;

const EMAIL_BLOCK_CONFIG = {
    [EMAIL_BLOCK]: {
        label: "Email",
        defaultValue: EMAIL_DEFAULT_VALUE,
        renderBlock: renderBlockEmail,
        renderEditor: renderEditorEmail,
        renderCard: renderCardEmail,
        renderSettings: renderSettingsEmail,
        isValidCallback: isValidCallbackEmail,
    },
};

export {
    renderBlockEmail,
    renderCardEmail,
    renderEditorEmail,
    renderSettingsEmail,
    isValidCallbackEmail,
    EMAIL_DEFAULT_VALUE,
    EMAIL_BLOCK_CONFIG,
};
