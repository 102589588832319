import { createPluginFactory } from "@udecode/plate";

export const MARK_DROP_SHADOW = "filter";
export const createFontDropShadowPlugin = createPluginFactory({
    key: MARK_DROP_SHADOW,
    inject: {
        props: {
            nodeKey: MARK_DROP_SHADOW,
        },
    },
    then: (editor, { type }) => ({
        deserializeHtml: {
            isLeaf: true,
            getNode(element) {
                if (element.style.filter) {
                    return { [type]: element.style.filter };
                }
            },
            rules: [
                {
                    validStyle: {
                        filter: "*",
                    },
                },
            ],
        },
    }),
});
