import { BLOCK_TYPES } from "../../common/index";
import { GRID_INPUT_OPTIONS, PUZZLE_OTHER } from "../Crossword/defaultValue";
const { INPUT_EXPAND_NUMBER_BLOCK } = BLOCK_TYPES;

export const DEFAULT_INPUT_CELL = {
    type: "fillable",
    content: {
        type: "string",
        value: "0",
        asset: "",
    },
};

const INPUT_EXPAND_NUMBER_DEFAULT_VALUE = {
    type: INPUT_EXPAND_NUMBER_BLOCK,
    data: {
        [INPUT_EXPAND_NUMBER_BLOCK]: {
            grid: [[...Array(3)].map(() => DEFAULT_INPUT_CELL)],
            options: GRID_INPUT_OPTIONS({
                type: "input",
                otherFields: {
                    correct_answer: [],
                    width: 1,
                },
            }),
        },
        jumpTo: {
            default: null,
            conditions: [],
        },
        other: {
            ...PUZZLE_OTHER,
            feedbacks: [], // DEFAULT_FEEDBACKS,
        },
    },
};

export default INPUT_EXPAND_NUMBER_DEFAULT_VALUE;
