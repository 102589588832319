import React from "react";
import { Button, Popconfirm, message } from "antd";
import { QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { PopconfirmProps } from "antd/lib/popconfirm";

export type IPopconfirm = PopconfirmProps;

const DeleteIcon: React.FC<any> = (props) => {
    const {
        isButton = false,
        type,
        size,
        onConfirm,
        title = "Are you sure to delete this task?",
        onCancel = () => message.error("Cancel"),
        okText = "Yes",
        cancelText = "No",
        disabled = false,
    } = props;

    let buttonSize = "default";

    if (size === "sm") {
        buttonSize = "small";
    } else if (size === "lg") {
        buttonSize = "large";
    }

    return (
        <Popconfirm
            title={title}
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={onConfirm}
            onCancel={onCancel}
            okText={okText}
            cancelText={cancelText}
            disabled={disabled}
        >
            {isButton ? (
                <Button
                    htmlType={type}
                    size={buttonSize}
                    icon={<DeleteOutlined style={{ color: "red" }} />}
                    shape="circle"
                    danger
                    {...props}
                />
            ) : (
                <DeleteOutlined style={{ color: "red" }} />
            )}
        </Popconfirm>
    );
};

export default DeleteIcon;
