import { FunctionComponent } from "react";

import {
    getLastStringFromUrl,
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import {
    DOCUMENT_QUERY,
    DOCUMENTS_QUERY,
    DOCUMENT_AGGREGATE_QUERY,
} from "../graphql";

export const withHookForDocuments = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { pagination } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            DOCUMENTS_QUERY,
            {
                variables: {
                    ...pagination,
                    group_id: getLastStringParamFromUrl(),
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "documents", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    documents: items,
                    subscribeToMore,
                    updateQuery,
                    loadDocumentsData: loadItemsData,
                    refetchDocuments: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateDocument = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            DOCUMENT_AGGREGATE_QUERY,
            {
                variables: {
                    group_id: getLastStringParamFromUrl(),
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "documentAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    documentAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForDocument = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            DOCUMENT_QUERY,
            {
                variables: {
                    id: getLastStringFromUrl(),
                },
            },
        );

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingDocument: loading,
                    document: data?.document,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
