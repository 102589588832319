import { FunctionComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";

import {
    DOCUMENTS_QUERY,
    CREATE_DOCUMENT_MUTATION,
    UPDATE_DOCUMENT,
    DELETE_DOCUMENT_MUTATION,
    ADD_GROUP_DOCUMENT_MAP,
    UPDATE_GROUP_DOCUMENT_MAP,
} from "../graphql";
import { captureException } from "@sentry/react";

// Query
export const withDocuments = (Component: FunctionComponent) =>
    graphql(DOCUMENTS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                documents,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadQuestionsData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            documents: [
                                ...previousResult.documents,
                                ...fetchMoreResult.documents,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                documents,
                subscribeToMore,
                updateQuery,
                loadQuestionsData,
                refetchDocuments: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreateDocument = (Component: FunctionComponent) =>
    graphql(CREATE_DOCUMENT_MUTATION, {
        props: ({ mutate }) => ({
            createDocument: async (object: Object) => {
                // console.log(
                //     "🚀 ~ file: Operations.ts ~ line 60 ~ createDocument: ~ object",
                //     object,
                // );
                try {
                    const {
                        data: { insert_home_explore_document_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createDocument: {
                                object,
                                __typename: "INSERT_DOCUMENT_ONE",
                            },
                        },
                    });
                    return insert_home_explore_document_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateDocument = (Component: FunctionComponent) =>
    graphql(UPDATE_DOCUMENT, {
        props: ({ mutate }) => ({
            updateDocument: (object: Object) => {
                mutate({
                    variables: { ...object },
                    optimisticResponse: {
                        updateDocument: {
                            ...object,
                            __typename: "document",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withDeleteDocument = (Component: FunctionComponent) =>
    graphql(DELETE_DOCUMENT_MUTATION, {
        props: ({ mutate }) => ({
            deleteDocument: (id: string) => {
                mutate({
                    variables: { id },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteDocument: {
                            id,
                            __typename: "DELETE_DOCUMENT_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withAddGroupDocumentMap = (Component: FunctionComponent) =>
    graphql(ADD_GROUP_DOCUMENT_MAP, {
        props: ({ mutate }) => ({
            addGroupTabMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     updateBook: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);

export const withUpdateGroupDocumentMap = (Component: FunctionComponent) =>
    graphql(UPDATE_GROUP_DOCUMENT_MAP, {
        props: ({ mutate }) => ({
            updateGroupTabMap: (object: Object) => {
                mutate({
                    variables: { ...object },
                    // optimisticResponse: {
                    //     updateBook: {
                    //         ...object,
                    //         __typename: "group",
                    //     },
                    // },
                });
            },
        }),
    })(Component);
