import React from "react";
import { message } from "antd";

import { DeleteIcon } from "src/components";
import { compose } from "src/helpers";
import { withDeleteSet } from "src/modules/set/operations";
import { captureException } from "@sentry/react";

const DeleteView = (props) => {
    const { deleteSet, id, record } = props;

    const handleDelete = async () => {
        console.log(id, record);

        const submissionIds = record.submissions
            .map((submission) => submission?.submission_question?.id)
            .filter(function (element: any) {
                return element !== undefined;
            });
        // console.log("deleteSet", submissionIds);

        try {
            await deleteSet({ id, submissionIds });
            message.success("Deleted!");
        } catch (err) {
            captureException(err)
            message.error("Failed!");
        }
    };

    return <DeleteIcon {...props} onConfirm={handleDelete} />;
};

export default compose(withDeleteSet)(DeleteView);
