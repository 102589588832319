import { Modal, Button } from "antd";
import { useEffect, useState } from "react";
import { AudioForm } from "./AudioForm";
import {
    AUDIO_INFO_SCHEMA,
    AUDIO_TEXT_SCHEMA,
    AUDIO_URL_SCHEMA,
} from "./schema";
import { cloneDeep, isObject, isString } from "lodash";
import { Can } from "src/services/casl";

export const AudioGenerateComponent = (props) => (
    <Can I="edit" a={"assets"} passThrough>
        {(allowed: boolean) => (
            <AudioGenerateChild
                {...props}
                disableWrite={props.disableWrite || !allowed}
            />
        )}
    </Can>
);

const AudioGenerateChild = (props: any) => {
    const { name, disableWrite, audioData, isAudioPool = false } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [audioInfo, setAudioInfo]: any = useState(null);
    const [loading, setLoading] = useState(false);

    const closeForm = () => setIsModalOpen(false);

    useEffect(() => {
        if (!audioData) setAudioInfo(AUDIO_INFO_SCHEMA);
        else if (isObject(audioData)) setAudioInfo(audioData);
        else if (isString(audioData)) {
            let tmpAud: any = cloneDeep(AUDIO_INFO_SCHEMA);
            let tmpAudTxt: any = cloneDeep(AUDIO_TEXT_SCHEMA);
            let tmpAudUrl: any = cloneDeep(AUDIO_URL_SCHEMA);
            tmpAudUrl.url = audioData;
            tmpAudUrl.is_active = true;
            tmpAudUrl.client = "Upload";

            tmpAudTxt.audio_urls.push(tmpAudUrl);
            tmpAud.audio_texts.push(tmpAudTxt);
            setAudioInfo(tmpAud);
        }
    }, [audioData]);

    function checkIfUrlPresent(audioInfo: { audio_texts: any[] }) {
        if (
            audioInfo &&
            audioInfo.audio_texts &&
            audioInfo.audio_texts?.length &&
            audioInfo.audio_texts.every(
                (audioText: { text: string; audio_urls: any[] }) =>
                    audioText?.text ||
                    (audioText.audio_urls &&
                        audioText.audio_urls.every(
                            (audioUrl) =>
                                audioUrl.url !== null && audioUrl.url !== "",
                        )),
            )
        ) {
            return "Added";
        } else {
            return "Not Added";
        }
    }

    function checkIfUrlSelected(audioInfo: { audio_texts: any[] }) {
        if (
            audioInfo &&
            (!audioInfo.audio_texts?.length ||
                audioInfo.audio_texts.every(
                    (audioText: { text: string; audio_urls: any[] }) =>
                        audioText?.text ||
                        (audioText.audio_urls &&
                            audioText.audio_urls.some(
                                (audioUrl) => audioUrl.is_active,
                            )),
                ))
        ) {
            return "";
        } else {
            return "Not Selected";
        }
    }

    useEffect(() => {
        if (loading) {
            setTimeout(() => setLoading(false), 50);
        }
    }, [loading]);

    if (isAudioPool && audioInfo) {
        return (
            <AudioForm {...props} closeForm={closeForm} audioInfo={audioInfo} />
        );
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                }}
            >
                <Button onClick={() => setIsModalOpen(true)}>Add Audio</Button>
                <i>{checkIfUrlPresent(audioInfo)}</i>
                <strong
                    style={{
                        color: "red",
                    }}
                >
                    {checkIfUrlSelected(audioInfo)}
                </strong>
            </div>
            {isModalOpen && audioInfo && (
                <Modal
                    title={`Add Audio for ${name}`}
                    open={isModalOpen}
                    onCancel={closeForm}
                    footer={null}
                    width={720}
                    maskClosable={false}
                    style={{ top: 20 }}
                    destroyOnClose={!disableWrite}
                >
                    {!loading && (
                        <AudioForm
                            {...props}
                            closeForm={closeForm}
                            audioInfo={audioInfo}
                            isAudioPool={isAudioPool}
                        />
                    )}
                </Modal>
            )}
        </>
    );
};
