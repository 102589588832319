import { BLOCK_TYPES } from "../../common/index";
const { SUBJECTIVE_BLOCK } = BLOCK_TYPES;

const SUBJECTIVE_DEFAULT_VALUE = {
    type: SUBJECTIVE_BLOCK,
    data: {
        [SUBJECTIVE_BLOCK]: {
            text: [],
            solution: [],
        },
    },
};

export default SUBJECTIVE_DEFAULT_VALUE;
