import { gql } from "@apollo/client";export default gql`query TopicQuery($parent_id: Int!) {
  topics: group(where: {parent_id: {_eq: $parent_id}, type: {_eq: "topic"}}) {
    id
    description
    cover
    other
    title
    type
    status
    collections {
        id
        group_id
        order
        collection {
            id
            title
        }
    }
  }
}
`;
