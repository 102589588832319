import { Select } from "antd";
import { BLOCK_TYPES } from "../../common/index";
import { renderEditorImage } from "../Image";
import { renderEditorVideo } from "../Video";
import { renderEditorV2HeygenAvatar } from "../V2HeygenAvatar";
const { V2_AVATAR_BLOCK } = BLOCK_TYPES;

const EditorImage = renderEditorImage;
const EditorVideo = renderEditorVideo;
const EditorHeygenAvatar = renderEditorV2HeygenAvatar

const renderEditor = (props: any) => {
    const { block, setBlock, worksheet } = props;
    const {
        data: {
            [V2_AVATAR_BLOCK]: { value, type, heygen },
        },
    } = block;

    const setValue = (val: any) => {
        if (type === "heygen_avatar") {
            setBlock({
                ...block,
                data: {
                    ...block.data,
                    [V2_AVATAR_BLOCK]: { heygen: val, type, value },
                },
            });
            return
        }
        setBlock({
            ...block,
            data: {
                ...block.data,
                [V2_AVATAR_BLOCK]: { value: val, type, heygen },
            },
        });
    }

    const setType = (type: any) =>
        setBlock({
            ...block,
            data: {
                ...block.data,
                [V2_AVATAR_BLOCK]: { value, type, heygen },
            },
        });

    const childProps = {
        block: {
            data: { image: value, video: value, heygen },
        },
        setBlock: (b: any) => {
            setValue(b);
        },
        worksheet,
        type: V2_AVATAR_BLOCK,
    };

    return (
        <div
            style={{
                width: "100%",
                maxWidth: "350px",
            }}
        >
            <Select
                style={{
                    marginBottom: "20px",
                    width: "200px",
                }}
                placeholder="Avatar Type"
                onChange={setType}
                value={type}
            >
                <Select.Option value={"image"}>{"Image/GIF"}</Select.Option>
                <Select.Option value={"video"}>{"Video"}</Select.Option>
                <Select.Option value={"heygen_avatar"}>{"Heygen Avatar"}</Select.Option>
            </Select>

            {type === "image" && <EditorImage {...childProps} />}
            {type === "video" && <EditorVideo {...childProps} useGamlet={true} />}
            {type === "heygen_avatar" && <EditorHeygenAvatar {...childProps} useGamlet={true} />}
        </div>
    );
};

export default renderEditor;
