import axios from "axios";
import { gql, GraphQLClient } from "graphql-request";
import lodash from "lodash";
import deepdash from "deepdash-es";
const _ = deepdash(lodash);

const US_HASURA_ENDPOINT = `https://graphql.us.mathai.ai/v1/graphql`;
const US_API_ENDPOINT = `https://api.us.mathai.ai/test/ts`;

const INSERT_OR_UPDATE_WORKSHEET_MUTATION = gql`
    mutation createWorksheet($object: worksheet_worksheet_insert_input = {}) {
        insert_worksheet_worksheet_one(
            object: $object
            on_conflict: {
                constraint: worksheet_pkey
                update_columns: [
                    status
                    banner
                    title
                    description
                    type
                    class
                    subject
                    slug
                    other
                ]
            }
        ) {
            id
        }
    }
`;

const WORKSHEET_BLOCK_IDS_QUERY = gql`
    query WorksheetBlockIdsQuery($worksheet_id: Int!) {
        worksheetBlockMap: worksheet_worksheet_block_map(
            where: {
                worksheet_id: { _eq: $worksheet_id }
                is_published: { _is_null: true }
            }
            order_by: { order: asc }
        ) {
            id
            block {
                id
                children(order_by: { order: asc }) {
                    id
                    children(order_by: { order: asc }) {
                        id
                    }
                }
            }
        }
    }
`;

const fetchWorksheetBlockIds = async (worksheetId) => {
    try {
        const response = await graphQLClientUS.request(
            WORKSHEET_BLOCK_IDS_QUERY,
            {
                worksheet_id: worksheetId,
            },
        );
        return response.worksheetBlockMap;
    } catch (error) {
        console.error("Error fetching block IDs:", error);
        throw error;
    }
};

const DELETE_WORKSHEET_BLOCK_MAP_MUTATION = gql`
    mutation USDeleteWorksheetBlockMap($ids: [Int!]!) {
        delete_worksheet_worksheet_block_map(where: { id: { _in: $ids } }) {
            affected_rows
        }
    }
`;

const INSERT_WORKSHEET_BLOCK_MAP_MUTATION = gql`
    mutation MyMutation(
        $objects: [worksheet_worksheet_block_map_insert_input!] = {}
    ) {
        insert_worksheet_worksheet_block_map(
            objects: $objects
            on_conflict: {
                constraint: worksheet_block_map_pkey
                update_columns: [
                    block_id
                    is_published
                    order
                    updated_at
                    worksheet_id
                ]
            }
        ) {
            affected_rows
        }
    }
`;

const flattenData = (data) => {
    let flatBlocks = [];

    const flatten = (block) => {
        // Push the main block ID
        flatBlocks.push(block.id);

        // If the block has children, recursively flatten them
        if (
            block.block &&
            block.block.children &&
            block.block.children.length > 0
        ) {
            block.block.children.forEach((childBlock) => {
                flatten(childBlock);
            });
        }

        // If the block has another level of children inside the children (recursive)
        if (block.children && block.children.length > 0) {
            block.children.forEach((childBlock) => {
                flatten(childBlock);
            });
        }
    };

    // Start with the main blocks and flatten them
    data.forEach((block) => flatten(block));

    return flatBlocks;
};

const compareBlocksAndFindMissingIDs = async (
    indianWorksheetData: any,
    worksheetId: any,
) => {
    try {
        const fetchedData = await fetchWorksheetBlockIds(worksheetId);
        const flatFetchedBlocks = flattenData(fetchedData);
        const flatIndianBlocks = flattenData(indianWorksheetData);

        const flatFetchedBlocksMapIds = fetchedData.map((i: any) => i.id);
        const flatIndianBlocksMapIds = indianWorksheetData.map(
            (i: any) => i.id,
        );

        // console.log("flat-1", flatFetchedBlocks, flatIndianBlocks);
        // console.log("flat-2", flatFetchedBlocksMapIds, flatIndianBlocksMapIds);

        const missingChildrenBlockIds = flatFetchedBlocks.filter(
            (id) => !flatIndianBlocks.includes(id),
        );
        const missingWorksheetBlockMapIds: any[] =
            flatFetchedBlocksMapIds.filter(
                (id: any) => !flatIndianBlocksMapIds.includes(id),
            );

        return { missingChildrenBlockIds, missingWorksheetBlockMapIds };
    } catch (error) {
        console.error("Error comparing blocks:", error);
        throw error;
    }
};

const DELETE_WORKSHEET_BLOCKS_MUTATION = gql`
    mutation DeleteWorksheetBlocks($ids: [Int!]!) {
        delete_worksheet_block(where: { id: { _in: $ids } }) {
            affected_rows
        }
    }
`;

const deleteWorksheetBlocks = async (ids) => {
    try {
        const response = await graphQLClientUS.request(
            DELETE_WORKSHEET_BLOCKS_MUTATION,
            {
                ids,
            },
        );

        return response.delete_worksheet_block.affected_rows;
    } catch (error) {
        console.log("Error deleting worksheet blocks:", error);
    }
};

export const graphQLClientUS = new GraphQLClient(US_HASURA_ENDPOINT, {
    headers: {
        "x-hasura-admin-secret": process.env.REACT_APP_HASURA_ADMIN_SECRET,
    },
});

const insertOrUpdateWorksheet = async (object: any) => {
    return await graphQLClientUS.request(INSERT_OR_UPDATE_WORKSHEET_MUTATION, {
        object,
    });
};

const deleteWorksheetBlockMap = async (ids: any) => {
    return await graphQLClientUS.request(DELETE_WORKSHEET_BLOCK_MAP_MUTATION, {
        ids,
    });
};

const insertWorksheetBlockMap = async (objects: any) => {
    return await graphQLClientUS.request(INSERT_WORKSHEET_BLOCK_MAP_MUTATION, {
        objects,
    });
};

const publishWorksheet = async (worksheetId: any, levelReset = null) => {
    await axios
        .post(
            `${US_API_ENDPOINT}/v3/personalizedLearning/publishWorksheetV4`,
            {
                data: {
                    worksheet_id: Number(worksheetId),
                    level_reset: levelReset || [],
                },
            },
            { headers: { "Content-Type": "application/json" } },
        )
        .then(function (response) {
            return response?.data;
        });
};

const worksheteMetadataCacheClear = async (worksheetId: any) => {
    return await axios.post(
        `${US_API_ENDPOINT}/v3/personalizedLearning/deleteWorksheetMetadataCache`,
        { data: { id: worksheetId } },
    );
};

export const publishToSecondaryHasuraEndpoints = async (
    worksheet: any,
    worksheetBlockMap: any,
    levelReset: any,
) => {
    const cleanedWorksheetData = _.omit(worksheet, [
        "__typename",
        "stats",
        "user",
        "tags",
    ]);

    const cleanedWorksheetBlockMap = worksheetBlockMap.map((a: any) => ({
        ..._.omit(a, ["tags", "__typename", "__typename"]),
        block: {
            on_conflict: {
                constraint: "block_pkey",
                update_columns: [
                    "backend",
                    "created_at",
                    "data",
                    "data_as_text",
                    "is_hidden",
                    "is_published",
                    "order",
                    "parent_id",
                    "reference_id",
                    "reset_submission_version",
                    "type",
                    "updated_at",
                    "version",
                ],
            },
            data: {
                ..._.omit(a.block, ["tags", "__typename"]),
                children: {
                    on_conflict: {
                        constraint: "block_pkey",
                        update_columns: [
                            "backend",
                            "created_at",
                            "data",
                            "data_as_text",
                            "is_hidden",
                            "is_published",
                            "order",
                            "parent_id",
                            "reference_id",
                            "reset_submission_version",
                            "type",
                            "updated_at",
                            "version",
                        ],
                    },
                    data: a.block.children.map((b) => ({
                        ..._.omit(b, ["tags", "__typename"]),
                        children: {
                            on_conflict: {
                                constraint: "block_pkey",
                                update_columns: [
                                    "backend",
                                    "created_at",
                                    "data",
                                    "data_as_text",
                                    "is_hidden",
                                    "is_published",
                                    "order",
                                    "parent_id",
                                    "reference_id",
                                    "reset_submission_version",
                                    "type",
                                    "updated_at",
                                    "version",
                                ],
                            },
                            data: b.children.map((c) => ({
                                ..._.omit(c, ["tags", "__typename"]),
                            })),
                        },
                    })),
                },
            },
        },
    }));

    const worksheetId = worksheet.id;

    const { missingChildrenBlockIds, missingWorksheetBlockMapIds } =
        await compareBlocksAndFindMissingIDs(worksheetBlockMap, worksheetId);

    // Function to account for delete changes -- CAUTION
    if (missingChildrenBlockIds.length) {
        await deleteWorksheetBlocks(missingChildrenBlockIds);
    }
    if (missingWorksheetBlockMapIds.length) {
        await deleteWorksheetBlockMap(missingWorksheetBlockMapIds);
    }
    await insertOrUpdateWorksheet(cleanedWorksheetData);

    // insert workshetBlockMap
    await insertWorksheetBlockMap(cleanedWorksheetBlockMap);

    // call publish endpoint
    await publishWorksheet(worksheetId, levelReset);

    // call metadata cache endpoint
    await worksheteMetadataCacheClear(worksheetId);

    return;
};
