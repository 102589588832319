import { useLazyQuery } from "@apollo/client";
import DuplicateIcon from "src/components/DuplicateIcon";
import { compose } from "src/helpers";
import { message } from "antd";
import deepdash from "deepdash-es";
import lodash from "lodash";
import {
    LIVE_VERSION_MODEL_MAP_QUERY,
    VERSION_MODEL_MAP_QUERY,
    WORKSHEET_BLOCKS_QUERY,
    WORKSHEET_QUERY,
} from "../../graphql";
import {
    withCreateWorksheet,
    withInsertVersion,
    withInsertWorksheetBlockMap,
    // withVersionModelMap,
} from "../../operations";
import {
    getParsedBlocks,
    getWorksheetBlockMapObject,
} from "../../views/edit_enhanced/container";
import {
    getV4ParsedBlocks,
    getV4WorksheetBlockMapObject,
} from "../../views/update/container";
const _ = deepdash(lodash);
// const GET_WORKSHEET_BLOCK_MAP = gql``;

export const duplicateWorksheet = async ({
    getWorksheet,
    worksheetId,
    getWorksheetBlocks,
    groupId,
    order = 999,
    createWorksheet,
    insertWorksheetBlockMap,
    callback = null,
    addCopySuffix = true,
    maintainStatus = false,
}: any) => {
    const {
        data: { worksheet },
    } = await getWorksheet({
        variables: { id: worksheetId },
    });

    const {
        data: { worksheetBlockMap },
    } = await getWorksheetBlocks({
        variables: { worksheet_id: worksheetId },
    });

    const worksheetData = {
        ..._.omit(worksheet, [
            "user",
            "created_at",
            "updated_at",
            "__typename",
            "tags",
            // "user_id", TODO: add the current user_id
            "id",
            "stats",
        ]),
        status: maintainStatus ? worksheet?.status : "inactive",
        title: worksheet.title + (addCopySuffix ? " copy" : ""),
        slug: null,
        tags: {
            data: worksheet.tags.map(({ tag_id }: any) => ({
                tag_id,
            })),
        },
        other: { ...(worksheet?.other || {}), save_count: 1, publish_count: 0 },
        groups: {
            data: [
                {
                    group_id: groupId,
                    order,
                },
            ],
        },
    };
    const { id: worksheet_id } = await createWorksheet(worksheetData);

    if (worksheet?.type !== "personalized_learning_v4") {
        const parsedBlocks = getParsedBlocks(worksheetBlockMap);
        if (parsedBlocks.length) {
            const blockData =
                worksheet?.type === "personalized_learning_v3"
                    ? parsedBlocks?.map((v) => {
                          let level = _.omit(v, ["id"]);
                          if (level?.children?.length)
                              level.children = level.children?.map((w) => {
                                  let chunk = _.omit(w, ["id"]);
                                  return chunk;
                              });
                          return level;
                      })
                    : _.omitDeep(parsedBlocks, ["id"]);

            const payload = getWorksheetBlockMapObject({
                blockData,
                worksheetData: { worksheet_id },
            });

            const objects =
                worksheet?.type === "personalized_learning_v3"
                    ? payload?.map((v) => {
                          let block = _.omit(v, ["id"]);
                          return block;
                      })
                    : _.omitDeep(payload, ["id"]);

            await insertWorksheetBlockMap({ objects: objects[0] });
        }
    } else {
        const parsedBlocks = getV4ParsedBlocks(worksheetBlockMap);
        if (parsedBlocks.length) {
            const blockData = parsedBlocks?.map((v) => {
                let level = _.omit(v, ["id"]);
                if (level?.children?.length)
                    level.children = level.children?.map((w) => {
                        let chunk = _.omit(w, ["id"]);
                        if (chunk?.children?.length)
                            chunk.children = chunk.children?.map((w) => {
                                let block = _.omit(w, ["id"]);
                                return block;
                            });
                        return chunk;
                    });
                return level;
            });

            const payload = getV4WorksheetBlockMapObject({
                blockData,
                worksheetData: { worksheet_id },
            });

            const objects = payload?.map((v) => {
                let block = _.omit(v, ["id"]);
                return block;
            });

            await insertWorksheetBlockMap({ objects: objects });
        }
    }
    if (callback) {
        callback();
    }
    return worksheet_id;
};

export const duplicateWorksheetVersion = async ({
    prevWorkseetId,
    newWorksheetId,
    getWorksheetVersion,
    insertVersion,
    version = 0,
    version_model_map_id = null,
    getWorksheet,
}: any) => {
    const {
        data: { worksheet },
    } = await getWorksheet({
        variables: { id: newWorksheetId },
    });
    if (worksheet?.type === "personalized_learning_v4") return;
    // get version 0 for prev_worksheet_id
    const {
        data: { versionModelMap },
    } = await getWorksheetVersion({
        variables: {
            model_name: "worksheet",
            model_id: prevWorkseetId,
        },
    });

    if (!versionModelMap.length) return;

    const { data } = versionModelMap[0]?.versions[0];

    if (!data) return;

    const parsedData = _.omitDeep(
        worksheet?.type === "personalized_learning_v3"
            ? data?.map((v) => {
                  let level = _.omit(v, ["id"]);
                  if (level?.children?.length)
                      level.children = level.children?.map((w) => {
                          let chunk = _.omit(w, ["id"]);
                          return chunk;
                      });
                  return level;
              })
            : _.mapKeysDeep(data, (v, k) => {
                  if (k == "id") return "tmpId";
                  else return k;
              }),
        ["worksheet_block_map_id"],
    );

    if (!parsedData) return;

    // create version for new worksheet_id
    const versionModelMapData = !version_model_map_id
        ? {
              version_model_map: {
                  data: {
                      model_name: "worksheet",
                      model_id: newWorksheetId,
                  },
              },
          }
        : { version_model_map_id };

    return (
        await insertVersion({
            ...versionModelMapData,
            index: version,
            data: parsedData,
        })
    ).version_model_map_id;
};

export const DuplicateWorksheetButton = (props: any) => {
    // console.log(worksheetId, groupId);

    const [getWorksheet, {}] = useLazyQuery(WORKSHEET_QUERY);
    const [getWorksheetBlocks, {}] = useLazyQuery(WORKSHEET_BLOCKS_QUERY);
    const [getWorksheetVersion, {}] = useLazyQuery(VERSION_MODEL_MAP_QUERY);
    const [getWorksheetVersionLive, {}] = useLazyQuery(
        LIVE_VERSION_MODEL_MAP_QUERY,
    );
    return (
        <DuplicateIcon
            type={"primary"}
            onConfirm={async () => {
                const newWorksheetId = await duplicateWorksheet({
                    ...props,
                    getWorksheet,
                    getWorksheetBlocks,
                });

                const { worksheetId: prevWorkseetId, insertVersion } = props;
                const version_model_map_id = await duplicateWorksheetVersion({
                    prevWorkseetId: prevWorkseetId,
                    newWorksheetId,
                    getWorksheetVersion,
                    insertVersion,
                    getWorksheet,
                });

                await duplicateWorksheetVersion({
                    prevWorkseetId: prevWorkseetId,
                    newWorksheetId,
                    getWorksheetVersion: getWorksheetVersionLive,
                    insertVersion,
                    version: 1,
                    version_model_map_id,
                    getWorksheet,
                });
                message.success(`Successfully duplicated the worksheet!`);
            }}
            isButton={true}
        />
    );
};

export default compose(
    withCreateWorksheet,
    withInsertWorksheetBlockMap,
    withInsertVersion,
)(DuplicateWorksheetButton);
