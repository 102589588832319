import { gql } from "@apollo/client";export default gql`query collection($id: Int!) {
    collection: collection_by_pk(id: $id) {
        id
        title
        description
        cover
        chapter
        topic
        attempts
        type
        status
        duration
        show_memes
        format
        class
        subject
        creator {
            email
        }
        other

        tags {
            id
            tag {
                id
                name
                value
            }
        }
    }
}
`;
