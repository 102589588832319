import { gql } from "@apollo/client";export default gql`query sets(
    $limit: Int = 12
    $offset: Int = 0
    $where: set_bool_exp # $type: String # $parent_id: uuid
) {
    sets: set(
        limit: $limit
        offset: $offset
        where: $where # order_by: { metadata: { created_at: desc } }
        order_by: { id: desc }
    ) {
        id
        title
        description
        cover
        chapter
        topic
        attempts
        duration
        status
        type
        subject
        class
        creator {
            email
        }
        submissions {
            submission_question {
                id
            }
        }
    }
}
`;
