import React from "react";
import { message } from "antd";

import { DeleteIcon } from "src/components";
import { compose } from "src/helpers";
import { withDeleteCollection } from "src/modules/collection/operations";
import { promisify } from "util";
import { captureException } from "@sentry/react";

const DeleteView = (props) => {
    const {
        deleteCollection,
        id,
        record,
        incrementSetDuration,
        incrementCollectionDuration,
    } = props;

    const handleDelete = async () => {
        console.log("here");
        const submissionIds = record.submissions
            .map((submission) => submission?.submission_question?.id)
            .filter(Number);

        console.log("here2", submissionIds);

        try {
            await deleteCollection({ id, submissionIds });
            await Promise.all(
                record.sets_data.map(async ({ set_id }) => {
                    await incrementSetDuration({
                        duration: -1 * record.duration,
                        id: set_id,
                    });
                }),
            );
            message.success("Deleted!");
        } catch (err) {
            captureException(err)
            message.error("failed to delete");
        }
    };

    return <DeleteIcon {...props} onConfirm={handleDelete} />;
};

export default compose(withDeleteCollection)(DeleteView);
