import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

const MetaTags = (props) => {
    const { title, url, description, image } = props;
    // console.log(props);
    const displayImage =
        image || "https://sets-dashboard.vercel.app/icons/logo-icon.svg";
    return (
        <Helmet>
            {/* General tags */}
            <title>{`Sets Dashboard - ${title}`}</title>
            <meta name="description" content={description} />
            {/* OpenGraph tags */}
            {/* <meta property="og:url" content={`www.edgenus.com${url}`} /> */}
            <meta property="og:title" content={`Sets Dashboard - ${title}`} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={displayImage} />
            <meta property="og:type" content="website" />
            {/* Twitter Card tags */}
            <meta name="twitter:title" content={`Sets Dashboard - ${title}`} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={displayImage} />
            <meta name="twitter:card" content="summary" />
        </Helmet>
    );
};
MetaTags.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
};

export default MetaTags;
