import ReactPlayer from "react-player";

const renderBlockAudio = (block: any) => {
    const {
        data: { audio },
    } = block;
    return (
        <div>
            <ReactPlayer
                url={audio}
                controls
                file={{ forceAudio: true }}
                height={"60px"}
                maxWidth="100%"
            />
        </div>
    );
};

export default renderBlockAudio;
