import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    List,
    Row,
    Switch,
    message,
} from "antd";
import _ from "lodash";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { CustomEditor } from "../../..";
import ConfigEditor from "./ConfigEditor";
import { useState } from "react";
import InputVariableField from "../../../common/InputVariableField";
import { BLOCK_TYPES } from "../../../common";
import { CONFIG_VALUES } from "../defaultValue";
import { checkLMConfig, checkSolutionConfig } from "../isValid";
import ReactPlayer from "react-player";

const { V2_STORIES_BLOCK, COLOR_COMBINE_BLOCK, V2_TABLE_BLOCK } = BLOCK_TYPES;

const getVariablePaths = (block: any) => {
    let values: { name: string; path: string[] }[] = [];
    const { type } = block;

    switch (type) {
        case COLOR_COMBINE_BLOCK:
            values = [
                { name: "Color 1", path: ["answers", "color_1"] },
                { name: "Color 2", path: ["answers", "color_2"] },
                { name: "Color Count 1", path: ["answers", "color_count_1"] },
                { name: "Color Count 2", path: ["answers", "color_count_2"] },
            ];
            break;
        case V2_TABLE_BLOCK:
            const {
                data: {
                    [V2_TABLE_BLOCK]: { data = [] },
                },
            } = block;

            values = data.flatMap((row: any[], i: number) =>
                row.map((__, j) => ({
                    name: `Cell ${i + 1} ${j + 1}`,
                    path: ["answers", `${i}`, `${j}`],
                })),
            );
            break;
        default:
            values = [{ name: "Whole Block", path: ["answers"] }];
            break;
    }
    return values;
};

const BlockEditor = (props: any) => {
    const {
        block,
        setBlock,
        updatePrimary,
        currentBlock,
        onInputVariableUpdate,
        toggleShouldBlockUI,
        disableWrite,
        worksheet,

        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        previewData,

        has_solution,

        isReadOnlyMode,
    } = props;
    const {
        type,
        badge_count,
        block: variant,
        config = [],
        screen_config,
    } = block;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInputModalOpen, setIsInputModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showInputModal = () => {
        setIsInputModalOpen(true);
    };

    const closeForm = () => {
        setIsModalOpen(false);
        setIsInputModalOpen(false);
    };

    const customProps = {
        ...props,
        block: variant,
        setBlock: (data: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(tmpBlock, ["block"], data);
            setBlock(tmpBlock);
        },
        type: variant?.type,
        hasPreviewBlocks: true,
    };

    const configProps = {
        isModalOpen,
        closeForm,
        config,
        onSave: (data: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(tmpBlock, ["config"], data);
            setBlock(tmpBlock);
        },
        toggleShouldBlockUI,
        disableWrite,

        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        isReadOnlyMode,
    };

    const inputVariableFieldProps = {
        variablePaths: getVariablePaths(variant),
        onInputVariableUpdate,
        isModalOpen: isInputModalOpen,
        closeForm,
        disableWrite,
        block_id: variant?.id,
        block_tmpId: variant?.tmpId,
        input_variables:
            worksheet?.other?.input_variables?.filter(
                (item: { block_id: any }) =>
                    item.block_id === variant?.tmpId ||
                    item.block_id === variant?.id,
            ) || [],
    };

    const handleClick = () => {
        const queryParams = new URLSearchParams();

        const jsonData = {
            pauseTimer: true,
            context: "preview",
            ...previewData,
            variant_id: block?.id || block?.tmpId,
        };

        // Add parameters from JSON object to the query string
        for (const key in jsonData) {
            if (jsonData.hasOwnProperty(key)) {
                queryParams.append(key, jsonData[key]);
            }
        }

        // Construct the URL with the parameters
        const url = `${process.env.REACT_APP_CLIENT_PREVIEW}/home/worksheet/${
            worksheet?.id
        }/${previewData["progression_id"]}?${queryParams.toString()}`;

        // Open the URL in a new tab
        window.open(url, "_blank");
    };

    const getLMText = (element: { other?: { children?: any[] } }) => {
        const LMInorrectTxt = (
            <span style={{ color: "red" }}>Incorrect Config</span>
        );

        const { other: { children = [] } = {} } = element;
        let hasStory = false;
        children.forEach(
            (lmc: { type: string; data: { children: string | any[] } }) => {
                if (lmc?.type === "v2_stories") {
                    if (lmc?.data?.children?.length > 0) hasStory = true;
                    else return LMInorrectTxt;
                }
            },
        );
        if (!hasStory) return LMInorrectTxt;
        return "";
    };

    return (
        <>
            {![V2_STORIES_BLOCK].includes(variant?.type) &&
                (block?.tmpId || block?.id) && (
                    <Col span={15} style={{ marginTop: "30px" }}>
                        <Form.Item label="Variant Id">
                            <Input.Search
                                value={block?.id || block.tmpId}
                                onChange={() => {}}
                                enterButton="Copy"
                                size="middle"
                                onSearch={() => {
                                    message.info(
                                        `Copied Variant Id to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        `${block?.id || block?.tmpId}`,
                                    );
                                }}
                            />
                        </Form.Item>
                    </Col>
                )}
            <div
                style={{
                    width: "100%",
                    border: "1px dashed #808080",
                    margin: "10px auto",
                    padding: "20px",
                }}
            >
                {isModalOpen && <ConfigEditor {...configProps} />}
                {isInputModalOpen && (
                    <InputVariableField {...inputVariableFieldProps} />
                )}
                {![V2_STORIES_BLOCK].includes(variant?.type) && (
                    <Row gutter={[20, 20]}>
                        <Col span={12}>
                            <Form.Item
                                label="Make Primary"
                                rules={[{ required: true }]}
                                validateStatus={!type ? "error" : ""}
                                help={!type ? "Field cannot be empty" : ""}
                            >
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={type === "primary"}
                                    onChange={(value) =>
                                        updatePrimary(!value ? 0 : currentBlock)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Badge Count"
                                rules={[{ required: true }]}
                                validateStatus={
                                    !badge_count || badge_count <= 0
                                        ? "error"
                                        : ""
                                }
                                help={
                                    !badge_count || badge_count <= 0
                                        ? "Field cannot be empty"
                                        : ""
                                }
                            >
                                <Input
                                    disabled={disableWrite}
                                    value={badge_count}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock.badge_count = value;
                                        setBlock(tmpBlock);
                                    }}
                                    required
                                    type="number"
                                    placeholder={`Badge Count`}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="Button Enabled?">
                                        <Switch
                                            disabled={disableWrite}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                            checked={
                                                screen_config?.button
                                                    ?.is_enabled
                                            }
                                            onChange={(value) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "screen_config",
                                                        "button",
                                                        "is_enabled",
                                                    ],
                                                    value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                {screen_config?.button?.is_enabled && (
                                    <Col span={12}>
                                        <Form.Item
                                            label="Button Text"
                                            rules={[{ required: true }]}
                                            validateStatus={
                                                !screen_config?.button?.btn_text
                                                    ? "error"
                                                    : ""
                                            }
                                            help={
                                                !screen_config?.button?.btn_text
                                                    ? "Field cannot be empty"
                                                    : ""
                                            }
                                        >
                                            <Input
                                                disabled={disableWrite}
                                                value={
                                                    screen_config?.button
                                                        ?.btn_text
                                                }
                                                placeholder="Button Text"
                                                onChange={(e) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "screen_config",
                                                            "button",
                                                            "btn_text",
                                                        ],
                                                        e.target.value,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                        </Col>

                        <Col span={12}>
                            <Button type="dashed" onClick={() => showModal()}>
                                {config.length > 0 ? "Edit" : "Add"} Config{" "}
                                <PlusOutlined style={{ fontSize: "15px" }} />
                            </Button>
                        </Col>

                        <Col span={12}>
                            <Button
                                type="dashed"
                                onClick={() => showInputModal()}
                            >
                                {worksheet?.other?.input_variables?.some(
                                    (item: { block_id: any }) =>
                                        item.block_id === variant?.tmpId ||
                                        item.block_id === variant?.id,
                                )
                                    ? "Edit"
                                    : "Add"}{" "}
                                Input to Variable{" "}
                                <PlusOutlined style={{ fontSize: "15px" }} />
                            </Button>
                        </Col>

                        <Col span={24}>
                            {config.length > 0 && (
                                <List
                                    size="small"
                                    header={
                                        <div>
                                            Configurations{" "}
                                            {checkLMConfig(config) && (
                                                <span
                                                    style={{
                                                        color: "white",
                                                        background: "red",
                                                        padding: "4px 8px",
                                                        margin: "0 4px",
                                                        borderRadius: "4px",
                                                    }}
                                                >
                                                    Invalid
                                                </span>
                                            )}{" "}
                                            {!checkSolutionConfig(
                                                config,
                                                has_solution,
                                                variant?.type,
                                            ) && (
                                                <span
                                                    style={{
                                                        color: "white",
                                                        background: "red",
                                                        padding: "4px 8px",
                                                        margin: "0 4px",
                                                        borderRadius: "4px",
                                                    }}
                                                >
                                                    No Solution
                                                </span>
                                            )}
                                        </div>
                                    }
                                    bordered
                                    dataSource={config}
                                    renderItem={(item: any) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={
                                                    CONFIG_VALUES[item?.type]
                                                        ?.label
                                                }
                                                description={
                                                    <>
                                                        {item?.type ===
                                                            "block_state" && (
                                                            <span
                                                                style={{
                                                                    textTransform:
                                                                        "capitalize",
                                                                }}
                                                            >
                                                                {item?.value
                                                                    ?.split("_")
                                                                    .join(" ")}
                                                            </span>
                                                        )}
                                                        {item?.type ===
                                                            "solution" && (
                                                            <>{item?.value}</>
                                                        )}
                                                        {item?.type ===
                                                            "try_again" && (
                                                            <>
                                                                <div>
                                                                    Reset State:{" "}
                                                                    {item?.other
                                                                        ?.is_reset
                                                                        ? "True"
                                                                        : "False"}
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        gap: "10px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            flexShrink: 0,
                                                                        }}
                                                                    >
                                                                        Audio -
                                                                    </div>{" "}
                                                                    <strong
                                                                        style={{
                                                                            color: "#555",
                                                                        }}
                                                                    >
                                                                        {item
                                                                            ?.other
                                                                            ?.audio
                                                                            ? "Added!"
                                                                            : "Not added!"}
                                                                    </strong>
                                                                </div>
                                                            </>
                                                        )}
                                                        {item?.type ===
                                                            "learning_mode" && (
                                                            <>
                                                                Enabled{" "}
                                                                {getLMText(
                                                                    item,
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            )}
                        </Col>
                    </Row>
                )}
                <Divider />
                <Button
                    type="primary"
                    onClick={handleClick}
                    disabled={
                        !previewData["progression_id"] ||
                        !previewData["progression_activity_id"]
                    }
                >
                    Open Question Preview
                </Button>
                <Divider />
                <div>
                    <CustomEditor {...customProps} />
                </div>
            </div>
        </>
    );
};

export default BlockEditor;
