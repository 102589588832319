import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
    Badge,
    Button,
    Card,
    Divider,
    Form,
    Input,
    Select,
    Switch,
    Table,
    message,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import { cloneDeep, pullAt } from "lodash";
import React from "react";
import { v4 as uuid } from "uuid";
import {
    CopyIcon,
    DeleteIcon,
    RenderUpload,
    SlateEditor,
} from "src/components";
import { DragHandle } from ".";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { BlockPicker } from "react-color";
import { isEditorEmpty } from "src/modules/worksheet/components/blocks/common";

const SortableItem = SortableElement(({ value }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Badge.Ribbon color={"cyan"} text={value + 1} placement={"start"}>
                <Card
                    hoverable
                    style={{
                        borderRadius: "8px",
                        marginBottom: "4px",
                        boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                        position: "relative",
                        minWidth: "90px",
                    }}
                    bodyStyle={{
                        background: "transparent",
                        paddingBottom: 10,
                        paddingTop: 10,
                    }}
                >
                    {`Tag ${value + 1}`}
                    <div
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "2px",
                        }}
                    >
                        <DragHandle />
                    </div>
                </Card>
            </Badge.Ribbon>
        </div>
    );
});

const SortableList = SortableContainer(({ items, disableWrite }) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "30px",
                width: "100%",
                flexWrap: "wrap",
            }}
        >
            {items.map((child, index) => (
                <SortableItem
                    key={`item-${index}`}
                    index={index}
                    value={index}
                    child={child}
                    disabled={disableWrite}
                />
            ))}
        </div>
    );
});

export function sortAndUpdate(array1, array2) {
    // Create a copy of array1 to avoid modifying the original array
    const sortedArray = [...array1];

    // Sort the array based on the order specified in array2
    sortedArray.sort((a, b) => {
        const typeA = a.text ? "text" : "image";
        const typeB = b.img ? "image" : "text";
        const indexA = array2.indexOf(typeA);
        const indexB = array2.indexOf(typeB);
        return indexA - indexB;
    });

    // Update showImg and showText values based on array2 content
    const showImgIndex = array2.indexOf("image");
    const showTextIndex = array2.indexOf("text");

    sortedArray.forEach((item) => {
        if (item.text) {
            item.showText = showTextIndex !== -1 ? 1 : 0;
        } else if (item.img) {
            item.showImg = showImgIndex !== -1 ? 1 : 0;
        }
    });

    return sortedArray;
}

const TagTable: React.FC = ({
    items = [],
    setBlock,
    schema = {},
    disableWrite,
    currentEditor,
    setCurrentEditor,
    hasMentions,
    mentionsList = [],
    categories,
}: any) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const tmpChildren = arrayMoveImmutable(
            items,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        setBlock(tmpChildren);
    };

    return (
        <>
            <SortableList
                items={items}
                onSortEnd={onSortEnd}
                axis="xy"
                useDragHandle
                disableWrite={disableWrite}
            />
            <Divider />
            <Table
                style={{
                    scrollbarWidth: "none",
                }}
                scroll={{ x: 1500, y: "60vh" }}
                size="small"
                pagination={false}
                dataSource={items?.map((v, i) => {
                    return {
                        key: i,
                        name: v?.name,
                        text: v?.data?.find((v) => v.text)?.text || [],
                        asset: v?.data?.find((v) => v.img)?.img?.asset,
                        background: v?.bg,
                        order: v.data
                            ?.filter((v) => v.showImg || v.showText)
                            ?.map((v) => (v.text ? "text" : "image")),
                        //   .join(":")
                        // : "",
                        value: v?.value,
                        category: v?.category,
                        enable: v?.enable,
                        maxDragLimit: v?.maxDragLimit,
                        type1: v.type1,
                        width: v?.data?.find((v) => v.img)?.width,
                        height: v?.data?.find((v) => v.img)?.height,
                    };
                })}
                columns={[
                    {
                        title: "Index",
                        dataIndex: "key",
                        width: 60,
                        render: (v) => v + 1,
                    },
                    // {
                    //     title: "Sort",
                    //     dataIndex: "sort",
                    //     width: 60,
                    //     className: "drag-visible",
                    //     render: () => <DragHandle />,
                    // },
                    {
                        title: "Name",
                        dataIndex: "name",
                        render: (v, data) => {
                            return (
                                <Input
                                    disabled={disableWrite}
                                    style={{
                                        minWidth: "100px",
                                    }}
                                    value={v}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].name =
                                            e.target.value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Text",
                        dataIndex: "text",
                        render: (v, data) => {
                            return (
                                <div
                                    style={{
                                        minWidth: "150px",
                                    }}
                                >
                                    <SlateEditor
                                        disabled={disableWrite}
                                        hasMentions={hasMentions}
                                        mentionsList={mentionsList}
                                        id={`${data.key}_tag_edit`}
                                        onChange={(value: any) => {
                                            let tmpBlock = cloneDeep(items);
                                            // tmpBlock[data?.key].text = value;
                                            tmpBlock[data?.key].data = tmpBlock[
                                                data?.key
                                            ].data?.map((v) => {
                                                if (v.text) v.text = value;
                                                return v;
                                            });

                                            tmpBlock[data?.key].name =
                                                value[0]?.children[0]?.text;
                                            if (
                                                !isNaN(
                                                    parseInt(
                                                        value[0]?.children[0]
                                                            ?.text,
                                                    ),
                                                )
                                            ) {
                                                tmpBlock[data?.key].value =
                                                    parseInt(
                                                        value[0]?.children[0]
                                                            ?.text,
                                                    );
                                            }

                                            setBlock(tmpBlock);
                                        }}
                                        isFocused={
                                            currentEditor ===
                                            `${data.key}_tag_edit`
                                        }
                                        setEditor={(id: string) =>
                                            setCurrentEditor(id)
                                        }
                                        value={v}
                                        required={!data?.asset}
                                    />
                                </div>
                            );
                        },
                    },
                    {
                        title: "Image",
                        dataIndex: "asset",
                        render: (v, data) => {
                            return (
                                <Form.Item
                                    validateStatus={
                                        !v && isEditorEmpty(data.text)
                                            ? "error"
                                            : ""
                                    }
                                    help={
                                        !v && isEditorEmpty(data.text)
                                            ? "Field cannot be empty"
                                            : ""
                                    }
                                >
                                    <RenderUpload
                                        singleUpload={true}
                                        disabled={disableWrite}
                                        path="home-explore/document/"
                                        onChangeCustom={(e) => {
                                            const value = e.target.value;
                                            let tmpBlock = cloneDeep(items);
                                            // tmpBlock[data?.key].asset = value;
                                            tmpBlock[data?.key].data = tmpBlock[
                                                data?.key
                                            ].data?.map((v) => {
                                                if (v.img) v.img.asset = value;
                                                return v;
                                            });
                                            setBlock(tmpBlock);
                                        }}
                                        label=""
                                        value={v}
                                    />
                                    <Input
                                        placeholder="Add value here"
                                        value={v}
                                        disabled={disableWrite}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[data?.key].asset = value;
                                            tmpBlock[data?.key].data = tmpBlock[
                                                data?.key
                                            ].data?.map((v) => {
                                                if (v.img) v.img.asset = value;
                                                return v;
                                            });
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            );
                        },
                    },
                    {
                        title: "Dimensions",
                        dataIndex: "dimensions",
                        render: (v, data) => {
                            return (
                                <>
                                    <Form.Item
                                        label="Width"
                                        validateStatus={
                                            !data?.width ? "error" : ""
                                        }
                                        help={
                                            !data?.width
                                                ? "Field cannot be empty"
                                                : ""
                                        }
                                    >
                                        <Input
                                            type="number"
                                            style={{
                                                minWidth: "100px",
                                            }}
                                            disabled={disableWrite}
                                            value={data?.width}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[data?.key].data =
                                                    tmpBlock[
                                                        data?.key
                                                    ].data?.map((v) => {
                                                        if (v.img)
                                                            v.width = value;
                                                        return v;
                                                    });
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Height"
                                        validateStatus={
                                            !data?.height ? "error" : ""
                                        }
                                        help={
                                            !data?.height
                                                ? "Field cannot be empty"
                                                : ""
                                        }
                                    >
                                        <Input
                                            type="number"
                                            style={{
                                                minWidth: "100px",
                                            }}
                                            disabled={disableWrite}
                                            value={data?.height}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[data?.key].data =
                                                    tmpBlock[
                                                        data?.key
                                                    ].data?.map((v) => {
                                                        if (v.img)
                                                            v.height = value;
                                                        return v;
                                                    });
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </Form.Item>
                                </>
                            );
                        },
                    },
                    {
                        title: "Background",
                        dataIndex: "background",
                        render: (v, data) => {
                            return (
                                <>
                                    <BlockPicker
                                        colors={[]}
                                        color={
                                            typeof v === "string"
                                                ? JSON.parse(v).color
                                                : v.color
                                        }
                                        onChangeComplete={(color: any) => {
                                            if (disableWrite) return;
                                            const value = color.hex;
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[data?.key].bg = {
                                                type: "SOLID",
                                                color: value,
                                            };
                                            setBlock(tmpBlock);
                                        }}
                                        triangle="hide"
                                        width="90px"
                                    />
                                    <Button
                                        disabled={disableWrite}
                                        onClick={() => {
                                            const key = "bg";
                                            let tmpBlock = cloneDeep(items);
                                            const value =
                                                tmpBlock[data?.key][key];
                                            setBlock(
                                                tmpBlock.map((i) => ({
                                                    ...i,
                                                    [key]: value,
                                                })),
                                            );
                                        }}
                                        size="small"
                                        type="primary"
                                    >
                                        Apply to all
                                    </Button>
                                </>
                            );
                        },
                    },
                    {
                        title: "Order",
                        dataIndex: "order",
                        render: (v, data) => (
                            <Form.Item
                                validateStatus={!v?.length ? "error" : ""}
                                help={!v?.length ? "Field cannot be empty" : ""}
                            >
                                <Select
                                    disabled={disableWrite}
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Select"
                                    value={v}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].data =
                                            sortAndUpdate(
                                                tmpBlock[data?.key].data,
                                                val,
                                            );
                                        setBlock(tmpBlock);
                                    }}
                                    options={[
                                        {
                                            value: "text",
                                            label: "Text",
                                        },
                                        {
                                            value: "image",
                                            label: "Image",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        ),
                    },
                    {
                        title: "Type",
                        dataIndex: "category",
                        render: (v, data) => {
                            return (
                                <>
                                    <Form.Item
                                        validateStatus={!v ? "error" : ""}
                                        help={!v ? "Field cannot be empty" : ""}
                                    >
                                        <Select
                                            disabled={disableWrite}
                                            style={{ width: "100%" }}
                                            placeholder="Select"
                                            value={v}
                                            onChange={(value) => {
                                                let tmpBlock = cloneDeep(items);
                                                tmpBlock[data?.key].category =
                                                    value;
                                                setBlock(tmpBlock);
                                            }}
                                            options={Array.from(
                                                new Set(
                                                    [...categories].filter(
                                                        (i) => i,
                                                    ),
                                                ),
                                            ).map((i) => ({
                                                value: i,
                                                label: i,
                                            }))}
                                        />
                                    </Form.Item>
                                    <br />
                                    <br />
                                    <Button
                                        disabled={disableWrite}
                                        onClick={() => {
                                            const key = "category";
                                            let tmpBlock = cloneDeep(items);
                                            const value =
                                                tmpBlock[data?.key][key];
                                            setBlock(
                                                tmpBlock.map((i) => ({
                                                    ...i,
                                                    [key]: value,
                                                })),
                                            );
                                        }}
                                        size="small"
                                        type="primary"
                                    >
                                        Apply to all
                                    </Button>
                                </>
                            );
                        },
                    },
                    {
                        title: "Weight",
                        dataIndex: "value",
                        render: (v, data) => {
                            return (
                                <Input
                                    type="number"
                                    style={{
                                        minWidth: "100px",
                                    }}
                                    disabled={disableWrite}
                                    value={v}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].value =
                                            e.target.value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Max Limit",
                        dataIndex: "maxDragLimit",
                        render: (v, data) => {
                            return (
                                <>
                                    <Input
                                        disabled={disableWrite}
                                        type="number"
                                        style={{
                                            minWidth: "100px",
                                        }}
                                        value={v}
                                        onChange={(e) => {
                                            let tmpBlock = cloneDeep(items);
                                            tmpBlock[data?.key].maxDragLimit =
                                                e.target.value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                    <Button
                                        disabled={disableWrite}
                                        onClick={() => {
                                            const key = "maxDragLimit";
                                            let tmpBlock = cloneDeep(items);
                                            const value =
                                                tmpBlock[data?.key][key];
                                            setBlock(
                                                tmpBlock.map((i) => ({
                                                    ...i,
                                                    [key]: value,
                                                })),
                                            );
                                        }}
                                        size="small"
                                        type="primary"
                                    >
                                        Apply to all
                                    </Button>
                                </>
                            );
                        },
                    },
                    {
                        title: "Is enabled?",
                        dataIndex: "enable",
                        render: (v, data) => {
                            return (
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={v}
                                    onChange={(value) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].enable = value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Max used (hide tag)?",
                        dataIndex: "type1",
                        render: (v, data) => {
                            return (
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={!v}
                                    onChange={(value) => {
                                        let tmpBlock = cloneDeep(items);
                                        tmpBlock[data?.key].type1 = value
                                            ? 0
                                            : 1;
                                        tmpBlock[data?.key].bg_type1 = value
                                            ? 1
                                            : 0;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        title: "Actions",
                        key: "operation",
                        fixed: "right",
                        width: 100,
                        render: (v, data) => (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                }}
                            >
                                <CopyIcon
                                    disabled={disableWrite}
                                    isButton={true}
                                    onClick={() => {
                                        let tmpItems = cloneDeep(items);
                                        tmpItems.splice(data.key, 0, {
                                            ...cloneDeep(tmpItems[data.key]),
                                            tagId: uuid(),
                                        });
                                        setBlock(tmpItems);
                                        message.info(`Tag duplicated!`);
                                    }}
                                />
                                <DeleteIcon
                                    title="Delete this?"
                                    disabled={disableWrite}
                                    onConfirm={() => {
                                        let tmpBlock = cloneDeep(items);
                                        pullAt(tmpBlock, data.key);
                                        setBlock(tmpBlock);
                                        message.success("successfully removed");
                                    }}
                                />
                            </div>
                        ),
                    },
                ]}
                rowKey="index"
                bordered
                footer={() => (
                    <>
                        <Button
                            disabled={disableWrite}
                            onClick={() => {
                                let tmpItems = cloneDeep(items);
                                tmpItems.push({ ...schema, tagId: uuid() });
                                setBlock(tmpItems);
                            }}
                        >
                            Add Tag
                        </Button>
                        {!items?.length && (
                            <span
                                style={{
                                    color: "red",
                                    marginLeft: "10px",
                                    fontSize: "16px",
                                }}
                            >
                                No Tags Added
                            </span>
                        )}
                    </>
                )}
            />
        </>
    );
};

export default TagTable;
