import renderBlockColorCombine from "./renderBlock";
import renderEditorColorCombine from "./renderEditor";
import isValidCallbackColorCombine from "./isValid";
import COLOR_COMBINE_DEFAULT_VALUE from "./defaultValue";
import renderSettingsColorCombine from "./renderSettings";
import renderCardColorCombine from "./renderCard";
import { BLOCK_TYPES, addCallback } from "../../common";

const { COLOR_COMBINE_BLOCK } = BLOCK_TYPES;

const COLOR_COMBINE_BLOCK_CONFIG = {
    [COLOR_COMBINE_BLOCK]: {
        label: "Color Combine",
        defaultValue: COLOR_COMBINE_DEFAULT_VALUE,
        renderBlock: renderBlockColorCombine,
        renderEditor: renderEditorColorCombine,
        renderCard: renderCardColorCombine,
        renderSettings: renderSettingsColorCombine,
        addCallback,
        isValidCallback: isValidCallbackColorCombine,
    },
};

export {
    renderBlockColorCombine,
    renderCardColorCombine,
    renderEditorColorCombine,
    renderSettingsColorCombine,
    isValidCallbackColorCombine,
    COLOR_COMBINE_DEFAULT_VALUE,
    COLOR_COMBINE_BLOCK_CONFIG,
};
