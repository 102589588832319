// import { isEditorEmpty, BLOCK_TYPES } from "../../common";

// const { COLOR_COMBINE_BLOCK } = BLOCK_TYPES;

const isValidCallback = ({ block }: any) => {
    // if (!block || !block.data)
    //     return {
    //         isValid: true,
    //         error: "",
    //     };
    // const {
    //     data: {
    //         [COLOR_COMBINE_BLOCK]: { options, correct_options },
    //         other: { description = [] },
    //     },
    // } = block;

    // const checkCorrectOption = () => Boolean(correct_options.length);

    return {
        isValid: true,
        // !isEditorEmpty(description) &&
        // !options.some((option: any) => isEditorEmpty(option?.text)) &&
        // checkCorrectOption(),
        error: <></>,
    };
};

export default isValidCallback;
