import { FunctionComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";

import {
    USERS_QUERY,
    CREATE_USER_MUTATION,
    UPDATE_USER,
    DELETE_USER_MUTATION,
} from "../graphql";
import { captureException } from "@sentry/react";

// Query
export const withUsers = (Component: FunctionComponent) =>
    graphql(USERS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                users,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadQuestionsData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            users: [
                                ...previousResult.users,
                                ...fetchMoreResult.users,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                users,
                subscribeToMore,
                updateQuery,
                loadQuestionsData,
                refetchUsers: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreateUser = (Component: FunctionComponent) =>
    graphql(CREATE_USER_MUTATION, {
        props: ({ mutate }) => ({
            createUser: async (object: Object) => {
                // console.log('🚀 ~ file: Operations.ts ~ line 60 ~ createUser: ~ object', object);
                try {
                    const {
                        data: { insert_user_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createUser: {
                                object,
                                __typename: "INSERT_USER_ONE",
                            },
                        },
                    });
                    return insert_user_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateUser = (Component: FunctionComponent) =>
    graphql(UPDATE_USER, {
        props: ({ mutate }) => ({
            updateUser: (object: Object) => {
                mutate({
                    variables: { ...object },
                    optimisticResponse: {
                        updateUser: {
                            ...object,
                            __typename: "user",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withDeleteUser = (Component: FunctionComponent) =>
    graphql(DELETE_USER_MUTATION, {
        props: ({ mutate }) => ({
            deleteUser: (id: string) => {
                mutate({
                    variables: { id },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteUser: {
                            id,
                            __typename: "DELETE_USER_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);
