import { gql } from "@apollo/client";export default gql`query collections(
    $limit: Int = 12
    $offset: Int = 0
    $where: collection_bool_exp # $type: String # $parent_id: uuid
) {
    collections: collection(
        limit: $limit
        offset: $offset
        where: $where # order_by: { metadata: { created_at: desc } }
        order_by: { id: desc }
    ) {
        id
        title
        description
        type
        format
        topic
        status
        duration
        class
        subject
        attempts
        duration
        creator {
            email
        }
        submissions {
            submission_question {
                id
            }
        }
        sets (where: {set: {type: {_nin: ["interactive_rapid_quiz", "interactive_quiz"]}}}){
            id
            set_id
            order
            set {
                id
                title
                type
            }
        }

        groups {
            id
            group_id
            order
            group {
                id
                title
                parent_id
            }
        }

        tags {
            id
            tag {
                id
                name
                value
            }
        }
    }
}
`;
