import { Ability, AbilityBuilder } from "@casl/ability";

export const ability = new Ability([], {
    detectSubjectType: (object) => object.__typename,
});

export const defineAbilityFor = (user) => {
    const { can, cannot, rules } = new AbilityBuilder(Ability);
    const namespace = "https://hasura.io/jwt/claims/";
    const role = user && user[namespace + "role"];
    const user_id = user && user[namespace + "user_id"];

    if (role == "superuser") {
        can("create", "all");
        can("edit", "all");
        can("read", "all");

        can("delete", "all");
        can("publish", "all");
        can("save", "all");
        can("copyToProd", "all");

        can("edit_status", "all");
    } else if (role == "admin") {
        can("create", "all");
        cannot("create", "tag");
        can("read", "all");
        can("edit", "all");
        can("delete", "collection_content");
        can("publish", "all");
        can("save", "all");

        // can("read", "set");
        can("edit_status", "all");
    } else if (role == "creator") {
        can("create", "set");
        can("read", "set");

        can("create", "collection");
        can("read", "collection");
        // can("delete", "collection", { user_id });

        can("create", "collection_content");
        can("read", "collection_content");
        can("edit", "collection_content", { user_id });
        // can("delete", "collection_content", { user_id });

        can("create", "meme");
        can("read", "meme");
        can("edit", "meme", { user_id });

        can("read", "tab");
        can("create", "tab_book");
        can("edit", "tab_book");

        can("create", "tab_chapter");
        can("edit", "tab_chapter");

        can("create", "tab_topic");
        can("edit", "tab_topic");

        can("create", "document");
        can("edit", "document");

        can("create", "worksheet");
        can("edit", "worksheet");
        can("save", "worksheet");
        can("edit", "worksheet_details");
        can("edit", "worksheet_block");
        can("read", "worksheet_block");
        can("edit_status", "worksheet_block");
        can("edit", "assets");

        can("read", "worksheet_block_search");
    } else if (role == "memer") {
        can("create", "meme");
        can("read", "meme");
        can("edit", "meme", { user_id });
    } else if (role == "block_status") {
        can("read", "tab");
        can("edit", "worksheet");
        can("edit_status", "worksheet_block");
        can("publish", "all");
    } else if (role == "audio_editor") {
        can("read", "worksheet_block");
        can("save", "worksheet");
        can("read", "tab");
        can("edit", "assets");
    } else if (role == "viewer") {
        can("read", "tab");
        can("read", "worksheet_block");
    }

    return rules;
};
