import {
    Modal,
    message,
    Button,
    Col,
    Space,
    Row,
    Form,
    Input,
    Divider,
    Select,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { jumpLogicValid } from "../../../../ProgressLevel/isValid";
import { captureException } from "@sentry/react";

export const LogicModal = ({
    computeFUnctions,
    disableWrite,
    onSave,
    filterList = [],
    isAllGlobal = false,
    chunkIndex,
    blockIndex,
    chunkSlug,
    blockSlug,
}: any) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [curConfig, setCurConfig] = useState(computeFUnctions);
    const [loading, setLoading] = useState(false);
    const [tempChildren, setTempChildren] = useState("");

    const handleSubmit = async () => {
        setSubmitting(true);
        for (let i = 0; i < curConfig.length; i++) {
            let tempComp = curConfig[i];
            if (!tempComp?.output?.trim()?.length) {
                message.error("Some fields are empty!");
                setSubmitting(false);
                return;
            }
        }
        try {
            onSave(curConfig);
            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e);
            console.log(e);
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (loading) {
            setTimeout(() => setLoading(false), 50);
        }
    }, [loading]);

    useEffect(() => {
        setTempChildren("");
        setCurConfig(computeFUnctions);
    }, [isModalOpen]);

    useEffect(() => {
        console.log(computeFUnctions);
    }, []);

    const updateFunction = (inputFunction, currentConfig) => {
        const index = currentConfig.findIndex(
            (func) => func.name === inputFunction.name,
        );

        if (index !== -1) {
            // Update existing function
            currentConfig[index] = {
                ...currentConfig[index],
                ...inputFunction, // Spread the new values from inputFunction
            };
            message.info(`Updated function: ${inputFunction.name}`);
        } else {
            // Add new function
            currentConfig.push(inputFunction);
            message.info(`Added new function: ${inputFunction.name}`);
        }

        // Return the updated currentConfig
        return currentConfig;
    };

    const handleUpdateOneFunction = () => {
        try {
            const parsedJson = JSON.parse(tempChildren);
            console.log("Parsed JSON:", parsedJson); // Log the parsed JSON

            setCurConfig((prevConfig) => {
                const updatedConfig = updateFunction(parsedJson, prevConfig);
                console.log("Updated Config:", updatedConfig); // Log the updated config
                return updatedConfig;
            });

            setTempChildren("");
        } catch (e) {
            console.error("Error parsing JSON:", e); // Log the error
            message.error("Error in JSON!");
        }
    };

    return (
        <>
            <Button type="link" onClick={() => setIsModalOpen(true)}>
                Logic
            </Button>
            {!jumpLogicValid({
                functions: computeFUnctions,
                chunkIndex,
                blockIndex,
                chunkSlug,
                blockSlug,
            }) && (
                <span style={{ color: "red" }}>
                    Please check the logic functions
                </span>
            )}
            <Modal
                title={`Logic`}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                width={720}
                maskClosable={false}
                style={{ top: 10 }}
                destroyOnClose
            >
                <Row
                    style={{
                        maxHeight: "70vh",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                    }}
                >
                    {!disableWrite && (
                        <Col span={24}>
                            <Button
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied Functions to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(curConfig),
                                    );
                                }}
                                size="small"
                            >
                                Copy the functions to Clipboard
                            </Button>
                            <Divider />
                            <Form.Item label={"Paste Functions here"}>
                                <Input.TextArea
                                    disabled={disableWrite}
                                    value={tempChildren}
                                    placeholder="Paste Functions here"
                                    onChange={(e) =>
                                        setTempChildren(e.target.value)
                                    }
                                    rows={2}
                                />
                                <div style={{ display: "flex", gap: "10px" }}>
                                    {" "}
                                    {/* Adjust gap as needed */}
                                    <Button
                                        disabled={disableWrite}
                                        style={{ marginTop: "10px" }}
                                        type="primary"
                                        size="small"
                                        onClick={() => {
                                            try {
                                                console.log(
                                                    "Update One Function button clicked",
                                                ); // Log button click
                                                handleUpdateOneFunction();
                                                setTempChildren("");
                                                message.info("Updated!");
                                            } catch (e) {
                                                // captureException(e)
                                                message.error("Error in JSON!");
                                            }
                                        }}
                                    >
                                        Update One Function
                                    </Button>
                                    <Button
                                        disabled={disableWrite}
                                        style={{ marginTop: "10px" }}
                                        type="primary"
                                        size="small"
                                        onClick={() => {
                                            try {
                                                const parsedJson =
                                                    JSON.parse(tempChildren);
                                                setCurConfig(parsedJson);
                                                setTempChildren("");
                                                message.info("Updated!");
                                            } catch (e) {
                                                // captureException(e)
                                                message.error("Error in JSON!");
                                            }
                                        }}
                                    >
                                        Update All Functions
                                    </Button>
                                </div>
                            </Form.Item>
                            <Divider />
                        </Col>
                    )}
                    <Col span={24}>
                        <h3>Global Compute Functions</h3>
                        {curConfig?.length > 0 ? (
                            curConfig.map(
                                (
                                    v: {
                                        name: string;
                                        label: string;
                                        output: string;
                                        params: any[];
                                        isGlobal: boolean;
                                    },
                                    i: number,
                                ) =>
                                    (!isAllGlobal && !v.isGlobal) ||
                                    (filterList?.length > 0 &&
                                        !filterList.includes(v.name)) ? (
                                        <div key={i} />
                                    ) : (
                                        <>
                                            <Form.Item
                                                label={
                                                    <div>
                                                        <span
                                                            style={{
                                                                fontSize:
                                                                    "18px",
                                                                fontStyle:
                                                                    "italic",
                                                            }}
                                                        >
                                                            {v.label || v.name}
                                                        </span>
                                                        <strong
                                                            style={{
                                                                marginLeft:
                                                                    "10px",
                                                                fontSize:
                                                                    "14px",
                                                            }}
                                                        >
                                                            (params:{" "}
                                                            {v.params.join(
                                                                ", ",
                                                            )}
                                                            )
                                                        </strong>
                                                    </div>
                                                }
                                                key={i}
                                                labelCol={{ span: 24 }}
                                                validateStatus={
                                                    (v.name ===
                                                        "getNextChunk" &&
                                                        (v.output?.includes(
                                                            `C${
                                                                chunkIndex + 1
                                                            }`,
                                                        ) ||
                                                            (chunkSlug &&
                                                                v.output?.includes(
                                                                    chunkSlug,
                                                                )))) ||
                                                    (v.name ===
                                                        "getNextBlock" &&
                                                        (v.output?.includes(
                                                            `C${
                                                                chunkIndex + 1
                                                            }B${
                                                                blockIndex + 1
                                                            }`,
                                                        ) ||
                                                            (chunkSlug &&
                                                                blockSlug &&
                                                                (v.output?.includes(
                                                                    `${chunkSlug}:${blockSlug}`,
                                                                ) ||
                                                                    v.output?.includes(
                                                                        `chunk:${chunkSlug};block:${blockSlug}`,
                                                                    ) ||
                                                                    v.output?.includes(
                                                                        `${chunkSlug};${blockSlug}`,
                                                                    )))))
                                                        ? "error"
                                                        : ""
                                                }
                                                help={
                                                    (v.name ===
                                                        "getNextChunk" &&
                                                        (v.output?.includes(
                                                            `C${
                                                                chunkIndex + 1
                                                            }`,
                                                        ) ||
                                                            (chunkSlug &&
                                                                v.output?.includes(
                                                                    chunkSlug,
                                                                )))) ||
                                                    (v.name ===
                                                        "getNextBlock" &&
                                                        (v.output?.includes(
                                                            `C${
                                                                chunkIndex + 1
                                                            }B${
                                                                blockIndex + 1
                                                            }`,
                                                        ) ||
                                                            (chunkSlug &&
                                                                blockSlug &&
                                                                (v.output?.includes(
                                                                    `${chunkSlug}:${blockSlug}`,
                                                                ) ||
                                                                    v.output?.includes(
                                                                        `chunk:${chunkSlug};block:${blockSlug}`,
                                                                    ) ||
                                                                    v.output?.includes(
                                                                        `${chunkSlug};${blockSlug}`,
                                                                    )))))
                                                        ? "Invalid Field"
                                                        : ""
                                                }
                                            >
                                                <Select
                                                    value={v?.computeType}
                                                    placeholder="Select type"
                                                    options={[
                                                        {
                                                            label: "Behave graph",
                                                            value: "behave",
                                                        },
                                                        {
                                                            label: "Compute Function",
                                                            value: "compute",
                                                        },
                                                    ]}
                                                    style={{
                                                        width: "150px",
                                                        flexGrow: 0,
                                                    }}
                                                    onChange={(value) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                curConfig,
                                                            );
                                                        tmpBlock = _.set(
                                                            tmpBlock,
                                                            [i, "computeType"],
                                                            value,
                                                        );
                                                        setCurConfig(tmpBlock);
                                                    }}
                                                    disabled={disableWrite}
                                                />
                                                {v?.computeType == "behave" ? (
                                                    <>
                                                        <Col span={24}>
                                                            <a
                                                                href={`${
                                                                    process.env
                                                                        .REACT_APP_GRAPH_URL ??
                                                                    "https://flow-graph-editor.vercel.app"
                                                                }/compute?${v.params.join(
                                                                    ",",
                                                                )}`}
                                                                target="_blank"
                                                            >
                                                                behave graph
                                                                link
                                                            </a>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Row
                                                                style={{
                                                                    marginBottom:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <Button
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    type="primary"
                                                                    onClick={() => {
                                                                        message.info(
                                                                            `Copied behave graph to clipboard!`,
                                                                        );

                                                                        navigator.clipboard.writeText(
                                                                            JSON.stringify(
                                                                                v?.behaveGraph,
                                                                            ),
                                                                        );
                                                                    }}
                                                                    size="small"
                                                                >
                                                                    Copy the
                                                                    behave graph
                                                                    to Clipboard
                                                                </Button>
                                                                <strong
                                                                    style={{
                                                                        color: "#555",
                                                                        marginLeft:
                                                                            "10px",
                                                                    }}
                                                                >
                                                                    {v?.behaveGraph
                                                                        ? "Added!"
                                                                        : "Not added!"}
                                                                </strong>
                                                            </Row>
                                                            <Form.Item
                                                                label={
                                                                    "Paste behave graph here"
                                                                }
                                                            >
                                                                <Input.TextArea
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    value={
                                                                        tempChildren
                                                                    }
                                                                    placeholder="Paste behave graph here"
                                                                    onChange={(
                                                                        e,
                                                                    ) =>
                                                                        setTempChildren(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        )
                                                                    }
                                                                    rows={2}
                                                                />
                                                                <Button
                                                                    disabled={
                                                                        disableWrite
                                                                    }
                                                                    style={{
                                                                        marginTop:
                                                                            "10px",
                                                                    }}
                                                                    type="primary"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        try {
                                                                            const parsedJson =
                                                                                JSON.parse(
                                                                                    tempChildren,
                                                                                );
                                                                            let tmpBlock =
                                                                                _.cloneDeep(
                                                                                    curConfig,
                                                                                );
                                                                            tmpBlock =
                                                                                _.set(
                                                                                    tmpBlock,
                                                                                    [
                                                                                        i,
                                                                                        "behaveGraph",
                                                                                    ],
                                                                                    parsedJson,
                                                                                );
                                                                            setCurConfig(
                                                                                tmpBlock,
                                                                            );
                                                                            setTempChildren(
                                                                                "",
                                                                            );
                                                                            message.info(
                                                                                "Updated!",
                                                                            );
                                                                        } catch (e) {
                                                                            // captureException(
                                                                            //     e,
                                                                            // );
                                                                            message.error(
                                                                                "Error in JSON!",
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    Update
                                                                    behave graph
                                                                </Button>
                                                            </Form.Item>
                                                            <Divider />
                                                        </Col>
                                                    </>
                                                ) : (
                                                    <Input.TextArea
                                                        rows={5}
                                                        value={v.output}
                                                        disabled={disableWrite}
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value;
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    curConfig,
                                                                );
                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [i, "output"],
                                                                value,
                                                            );
                                                            setCurConfig(
                                                                tmpBlock,
                                                            );
                                                        }}
                                                    />
                                                )}
                                                <Button
                                                    disabled={disableWrite}
                                                    type="primary"
                                                    onClick={() => {
                                                        navigator.clipboard
                                                            .writeText(
                                                                JSON.stringify(
                                                                    v,
                                                                ),
                                                            )
                                                            .then(() => {
                                                                message.info(
                                                                    "Copied Function to clipboard!",
                                                                );
                                                            })
                                                            .catch((err) => {
                                                                message.error(
                                                                    "Failed to copy: " +
                                                                        err,
                                                                );
                                                            });
                                                    }}
                                                    size="small"
                                                    style={{
                                                        marginTop: "10px",
                                                    }} // Adjust the value as needed
                                                >
                                                    Copy the function to
                                                    Clipboard
                                                </Button>
                                            </Form.Item>
                                        </>
                                    ),
                            )
                        ) : (
                            <h4>No Compute Functions</h4>
                        )}
                    </Col>
                </Row>
                <Space
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingTop: "20px",
                    }}
                >
                    <Button
                        type="default"
                        onClick={() => setIsModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={isSubmitting}
                        disabled={disableWrite}
                    >
                        Submit
                    </Button>
                </Space>
            </Modal>
        </>
    );
};
