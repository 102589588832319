import {
    AppstoreAddOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    MenuOutlined,
} from "@ant-design/icons";
import { EditIcon, Layout, MetaTag, ViewIcon } from "src/components";
import DeleteView from "src/modules/book/components/DeleteView";
import { DocumentList } from "src/modules/document";
import { WorksheetList } from "src/modules/worksheet";
import { Can } from "src/services/casl";
import { Button, Col, Divider, Image, Row, Space, Spin, Table } from "antd";
import { Link } from "src/components";
import { useRouter } from "src/helpers";
import React, { useEffect, useState } from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { TabTopicList } from "../../topic/list";

const ListView: React.FC = (props: any) => {
    const { loading, chapters, chapterAggregate, loadChaptersData } = props;
    const [chapterId, setChapterId]: any = useState();
    const [groupId, setGroupId]: any = useState();
    const [groupTitle, setGroupTitle] = useState("");
    const [dataSource, setDataSource] = useState([]);

    const router = useRouter();
    const { isReady, query, asPath: pathname } = router;
    const { book_id } = query;
    const { chapterId: queryChapterId, groupId: queryGroupId, tabType } = query;
    const parentBook = chapters[0]?.parent;

    const data =
        chapters
            .slice()
            .sort(
                (a: any, b: any) =>
                    a.other?.chapter_number - b.other?.chapter_number,
            ) || [];

    useEffect(() => {
        if (data.length) {
            if (queryChapterId) setChapterId(parseInt(queryChapterId));
            else setChapterId(data[0].id);

            if (queryGroupId) {
                setGroupId(parseInt(queryGroupId));
                setGroupTitle(
                    data?.find((item: { id: any }) => item.id == queryGroupId)
                        ?.title,
                );
            } else if (!data[0].other.has_topics) {
                setGroupId(data[0].id);
                setGroupTitle(data[0].title);
            }

            const data1 = data.map((t, index) => {
                return {
                    ...t,
                    index,
                    order: t.other.chapter_number,
                };
            });

            setDataSource(data1);
        }
    }, [data.length]);

    if (!isReady) return <div>Loading...</div>;

    const columns = [
        {
            title: "Cover",
            key: "cover",
            render: (text, record) =>
                record?.cover ? (
                    <Image src={record?.cover} width={50} />
                ) : (
                    "no cover"
                ),
        },
        {
            title: "Title",
            key: "title",
            render: (text, record) => record?.title,
            // fixed: "left",
            width: 200,
        },
        {
            title: "Status",
            key: "status",
            render: (text, record) =>
                record?.status == "active" ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                ),
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            // fixed: "right",
            width: 200,
            render: (text, record) => (
                <Space size={"small"}>
                    {/* <CopyIcon
                        isButton={true}
                        onClick={() => {
                            message.info(`Copied chapter link to clipboard!`);
                            navigator.clipboard.writeText(
                                `${process.env.REACT_APP_CLIENT_BASE}/chapter/${record.id}`,
                            );
                        }}
                    />
                    <Divider /> */}

                    <ViewIcon
                        isButton={true}
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            });

                            setChapterId(record.id);

                            if (!record.other.has_topics) {
                                setGroupId(record.id);
                                setGroupTitle(record.title);
                                router.push(
                                    {
                                        pathname: `/tab/chapter/${book_id}`,
                                        query: {
                                            chapterId: record.id,
                                            groupId: record.id,
                                            tabType,
                                        },
                                    },
                                    undefined,
                                    { shallow: true },
                                );
                            } else {
                                router.push(
                                    {
                                        pathname: `/tab/chapter/${book_id}`,
                                        query: {
                                            chapterId: record.id,
                                            tabType,
                                        },
                                    },
                                    undefined,
                                    { shallow: true },
                                );
                            }
                        }}
                    />

                    <Divider />

                    <Can I="edit" a={"tab_chapter"} passThrough>
                        {(allowed) => (
                            <Link
                                href={{
                                    pathname: `/tab/chapter/update/${record?.id}`,
                                    query: {
                                        redirect_to: encodeURI(pathname),
                                        book_id,
                                    },
                                }}
                            >
                                <EditIcon isButton={true} disabled={!allowed} />
                            </Link>
                        )}
                    </Can>

                    <Divider />
                    <Can I="delete" a={"tab_chapter"} passThrough>
                        {(allowed) => (
                            <DeleteView
                                id={record?.id}
                                isButton={true}
                                disabled={!allowed}
                                record={record}
                            />
                        )}
                    </Can>
                </Space>
            ),
        },
    ];

    const DragHandle = SortableHandle(() => (
        <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
    ));

    const SortableItem = SortableElement((props) => (
        <tr
            {...props}
            style={{
                backgroundColor: props.selected ? "lightcyan" : "",
            }}
        />
    ));
    const SortableBody = SortableContainer((props) => <tbody {...props} />);

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helper="row-dragging"
            onSortEnd={() => {}}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = data.findIndex(
            (x) => x.index === restProps["data-row-key"],
        );

        const id = dataSource.filter(
            (d) => d.index === restProps["data-row-key"],
        )[0]?.id;
        return (
            <SortableItem
                index={index}
                {...restProps}
                selected={id == chapterId}
            />
        );
    };

    const chapter = data.filter((chapter: any) => chapter.id == chapterId)[0];
    return (
        <Layout
            titles={[
                { name: "Tab", link: "/tab" },
                { name: "Chapters", link: `/tab/chapter${book_id}` },
            ]}
        >
            <MetaTag title="Chapters" />

            {/* <FilterComponent isChapter={false} {...props} />
            <hr /> */}

            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <h1>Chapters List ({chapters.length})</h1>
                        </Col>
                        <Col>
                            <Can I="create" a={"tab_chapter"} passThrough>
                                {(allowed) => (
                                    <Link
                                        href={{
                                            pathname: `/tab/chapter/create/${book_id}`,
                                            query: {
                                                redirect_to: pathname,
                                                order: chapters.length + 1,
                                            },
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            icon={<AppstoreAddOutlined />}
                                            disabled={!allowed}
                                        >
                                            Add Chapter
                                        </Button>
                                    </Link>
                                )}
                            </Can>
                        </Col>
                    </Row>
                    <hr />
                    <Spin spinning={loading}>
                        {Boolean(chapters?.length) && (
                            <Table
                                pagination={false}
                                columns={columns}
                                dataSource={dataSource || []}
                                rowKey="index"
                                components={{
                                    body: {
                                        wrapper: DraggableContainer,
                                        row: DraggableBodyRow,
                                    },
                                }}
                            />
                        )}
                    </Spin>
                </Col>
                {chapter?.other?.has_topics && (
                    <Col span={8}>
                        <TabTopicList
                            book_id={book_id}
                            chapter_id={chapterId}
                            chapter_title={chapter?.title}
                            setGroupId={setGroupId}
                            groupId={groupId}
                            setGroupTitle={setGroupTitle}
                            redirect_to={pathname}
                        />
                    </Col>
                )}

                {tabType == "document_content" &&
                    Boolean(chapters?.length) &&
                    (chapter?.other?.has_topics ? Boolean(groupId) : true) && (
                        <Col span={!chapter?.other?.has_topics ? 16 : 8}>
                            <DocumentList
                                group_id={groupId}
                                group_title={groupTitle}
                                redirect_to={pathname}
                                disableAddButton={
                                    chapter?.other?.has_topics ? groupId : false
                                }
                            />
                        </Col>
                    )}

                {tabType == "worksheet_content" &&
                    Boolean(chapters?.length) &&
                    (chapter?.other?.has_topics ? Boolean(groupId) : true) && (
                        <Col span={!chapter?.other?.has_topics ? 16 : 8}>
                            <WorksheetList
                                group_id={groupId}
                                group_title={groupTitle}
                                redirect_to={pathname}
                                disableAddButton={
                                    chapter?.other?.has_topics ? groupId : false
                                }
                                book={parentBook}
                            />
                        </Col>
                    )}
            </Row>
        </Layout>
    );
};

export default ListView;
