import { Card, Form, Switch } from "antd";
import { cloneDeep, set } from "lodash";
import VariablesForm from "../common/VariablesForm";
import ComponentDetails from "../common/ComponentDetails";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const TimerEditor = (props: any) => {
    const { block, setBlock, disableWrite } = props;
    const {
        figma: { variables },
    } = block;

    if (!block.config) {
        let tmpBlock = cloneDeep(block);
        tmpBlock = set(tmpBlock, ["config"], {
            showInActionBar: false,
        });
    }

    return (
        <div style={{ paddingBottom: "10px", display: "flex" }}>
            <Card
                style={{
                    minWidth: "600px",
                    // maxWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
                title={<h3>Configurations</h3>}
            >
                <ComponentDetails
                    block={block}
                    setBlock={setBlock}
                    disableWrite={disableWrite}
                />
                <Form.Item label={"Show in Action Bar"}>
                    <Switch
                        disabled={disableWrite}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={block?.config?.showInActionBar}
                        onChange={(val) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["config", "showInActionBar"],
                                val,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>
                <VariablesForm
                    {...props}
                    variables={variables}
                    setBlock={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(tmpBlock, ["figma", "variables"], value);
                        setBlock(tmpBlock);
                    }}
                />
            </Card>
        </div>
    );
};

export default TimerEditor;
