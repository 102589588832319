import React from "react";
import { message } from "antd";

import { DeleteIcon } from "src/components";
import { compose } from "src/helpers";
import { withDeleteBook } from "src/modules/book/operations";
import { captureException } from "@sentry/react";

const DeleteView = (props) => {
    const { deleteBook, id, record, afterDelete } = props;

    const handleDelete = async () => {
        try {
            await deleteBook({ id });
            setTimeout(() => {
                if (afterDelete) afterDelete();
            }, 500);
            message.success("Deleted!");
        } catch (err) {
            captureException(err)
            message.error("Failed!");
        }
    };

    return <DeleteIcon {...props} onConfirm={handleDelete} />;
};

export default compose(withDeleteBook)(DeleteView);
