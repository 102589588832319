import React from "react";
import { Row, Col, Form, Button, Divider } from "antd";
import { withFormik, FieldArray } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderCheckBox,
    RenderField,
    RenderAutoComplete,
    RenderTags,
    RenderDynamicField,
    // RenderEnums,
    RenderSelect,
} from "src/components";
import { Select } from "antd";
import { TagsAssignComponent } from "src/modules/book/components";
const Option = Select.Option;

const PlanForm = (props) => {
    const {
        onFinishFailed,
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
    } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={12} md={24}>
                    <Field
                        label="Plan Title"
                        placeholder="Plan Title"
                        name={"title"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.title}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Plan type"
                        placeholder="Plan type"
                        name={"type"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.type}
                        rules={[{ required: true }]}
                    >
                        <Option value="freemium">Freemium</Option>
                        <Option value="premium">Premium</Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Plan Description"
                        placeholder="Plan Description"
                        name={"description"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.description}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Plan Banner"
                        placeholder="Plan Banner"
                        name={"banner"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.banner}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Plan Message Banner"
                        placeholder="Plan Message Banner"
                        name={"message_banner"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.message_banner}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Plan credits (-1 for unlimited)"
                        placeholder="Plan credits"
                        name={"credits"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.credits}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <FieldArray
                        label={"variants"}
                        name={"variants"}
                        render={(arrayHelpers) => (
                            <RenderDynamicField
                                label={"Variants"}
                                buttonText="Add Variants"
                                keys={[
                                    {
                                        key: "slug",
                                        type: "text",
                                        label: "slug",
                                    },
                                    {
                                        key: "title",
                                        type: "text",
                                        label: "title",
                                    },
                                    {
                                        key: "sub_title",
                                        type: "text",
                                        label: "sub title",
                                    },
                                    {
                                        key: "units_duration",
                                        type: "text",
                                        label: "Units for duration",
                                    },
                                    {
                                        key: "duration",
                                        type: "number",
                                        label: "validity (days)",
                                    },

                                    {
                                        key: "cost_nominal",
                                        type: "number",
                                        label: "cost base",
                                    },
                                    {
                                        key: "discount",
                                        type: "number",
                                        label: "discount %",
                                    },
                                    {
                                        key: "cost_used",
                                        type: "number",
                                        label: "cost after discount",
                                    },
                                    {
                                        key: "cost_final",
                                        type: "number",
                                        label: "cost after discount/taxes",
                                    },
                                ]}
                                arrayHelpers={arrayHelpers}
                                values={values.variants}
                                name={"variants"}
                            />
                        )}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <FieldArray
                        label={"breakdown"}
                        name={"breakdown"}
                        render={(arrayHelpers) => (
                            <RenderDynamicField
                                label={"Breakdown"}
                                buttonText="Add Breakdown"
                                keys={[
                                    {
                                        key: "variant_slug",
                                        type: "text",
                                        label: "variant slug",
                                    },
                                    {
                                        key: "title",
                                        type: "text",
                                        label: "title",
                                    },
                                    {
                                        key: "cost",
                                        type: "text",
                                        label: "Cost",
                                    },
                                    {
                                        key: "is_base",
                                        type: "checkbox",
                                        label: "is base?",
                                    },
                                ]}
                                arrayHelpers={arrayHelpers}
                                values={values.breakdown}
                                name={"breakdown"}
                            />
                        )}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Plan Slug"
                        placeholder="Plan Slug"
                        name={"slug"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.slug}
                        rules={[{ required: true }]}
                    />
                </Col>
                <Col lg={12} md={24}>
                    <Field
                        label="Plan Status"
                        placeholder="Plan Status"
                        name={"status"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.status}
                        rules={[{ required: true }]}
                    >
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <TagsAssignComponent
                        name="tags"
                        tagGroup="plan"
                        formik={props}
                        isSet={true}
                        handleChange={(data) => {
                            setFieldValue(
                                ["tags", "data"],
                                data.map((d) => ({
                                    tag_id: d.value,
                                    label: d.label,
                                })),
                            );
                        }}
                        selected={values?.tags?.data?.map((tag, index) => ({
                            value: tag.tag_id,
                            key: index,
                            label: tag.label,
                        }))}
                    />
                    <br />
                </Col>

                <Col lg={24} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const PlanFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { plan } = props;
        return {
            ...(plan?.id ? { id: plan.id } : {}),
            title: plan?.title || "",
            description: plan?.description || "",
            banner: plan?.banner || "",
            message_banner: plan?.message_banner || "",

            credits: plan?.credits || 0,
            slug: plan?.slug || (Math.random() + 1).toString(36).substring(7),
            type: plan?.type || "premium",
            status: plan?.status || "inactive",
            variants: plan?.variants || [
                {
                    slug: "default",
                    title: "Monthly Subscription",
                    sub_title: "Monthly Payment",
                    units_duration: "month",
                    duration: 0,
                    discount: 0,
                    cost_nominal: 0,
                    cost_used: 0,
                    cost_final: 0,
                },
            ],
            breakdown: plan?.breakdown || [
                {
                    variant_slug: "default",
                    title: "Prime Plan (Monthly subscription) x 1",
                    cost: 0,
                    is_base: true,
                },
                {
                    variant_slug: "default",
                    title: "CGST 12%",
                    cost: 0,
                    is_base: false,
                },
                {
                    variant_slug: "default",
                    title: "IGST 12%",
                    cost: 0,
                    is_base: false,
                },
            ],
            tags: {
                data:
                    plan?.tags?.map((tag) => ({
                        tag_id: tag.tag.id,
                        // value: tag.tag.value, name: tag.tag.name
                        label: `${tag.tag.value} (${tag.tag.name})` || "",
                    })) || [],
            },
        };
    },

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish({
            ...values,
            variants: values.variants.map(({ discount, ...rest }: any) => ({
                discount: discount || 0,
                ...rest,
            })),
            tags: {
                data: values.tags.data.map(({ tag_id }: any) => ({ tag_id })),
            },
        });
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

export default PlanFormWithFormik(PlanForm);
