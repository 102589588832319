import renderBlockDragExpandNumber from "./renderBlock";
import renderEditorDragExpandNumber from "./renderEditor";
import isValidCallbackDragExpandNumber from "./isValid";
import DRAG_EXPAND_NUMBER_DEFAULT_VALUE from "./defaultValue";
import renderSettingsDragExpandNumber from "./renderSettings";
import renderCardDragExpandNumber from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common";

const { DRAG_EXPAND_NUMBER_BLOCK } = BLOCK_TYPES;

const DRAG_EXPAND_NUMBER_BLOCK_CONFIG = {
    [DRAG_EXPAND_NUMBER_BLOCK]: {
        label: "Drag Expand Number",
        defaultValue: DRAG_EXPAND_NUMBER_DEFAULT_VALUE,
        renderBlock: renderBlockDragExpandNumber,
        renderEditor: renderEditorDragExpandNumber,
        renderCard: renderCardDragExpandNumber,
        renderSettings: renderSettingsDragExpandNumber,
        addCallback,
        isValidCallback: isValidCallbackDragExpandNumber,
    },
};

export {
    renderBlockDragExpandNumber,
    renderCardDragExpandNumber,
    renderEditorDragExpandNumber,
    renderSettingsDragExpandNumber,
    isValidCallbackDragExpandNumber,
    DRAG_EXPAND_NUMBER_DEFAULT_VALUE,
    DRAG_EXPAND_NUMBER_BLOCK_CONFIG,
};
