import _, { cloneDeep, set } from "lodash";
import { useEffect, useState } from "react";
import { BlockColumn } from "./BlockColumn";
import { BLOCK_TYPES } from "../../../common";
import ComponentsEditor from "./ComponentsEditor";
import { QuestionColumn } from "./QuestionColumn";

const { ALL_IN_ONE } = BLOCK_TYPES;

const Editor = (props: any) => {
    const {
        block,
        isEditor,
        setIsEditor,
        setIsNewBlock,
        isNewBlock,
        setBlock,
        setNewJob,
        updateStoriesJob,
        updateAudiosJob,
        setNewAudioJob,
        renderLinkSelect: renderLinkSelectGlobal,
        worksheet,
        chunkSlug,
    } = props;
    const {
        id,
        tmpId,
        data: {
            [ALL_IN_ONE]: { components = [], question_variables: qVars = [] },
        },
        backend,
    } = block;

    let question_variables =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.question_variables || []
            : qVars;

    const renderLinkSelect = (v) =>
        renderLinkSelectGlobal &&
        renderLinkSelectGlobal({ ...v, question_variables });

    const [currentComponentIndex, setCurrentComponentIndex] = useState(null);
    const [currentEditor, setCurrentEditor] = useState(`${id || tmpId}_text`);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        if (load)
            setTimeout(() => {
                setLoad(false);
            }, 50);
    }, [load]);

    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_text`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const blockColumnProps = {
        currentComponentIndex,
        setCurrentComponentIndex: (v) => {
            if (currentComponentIndex !== v) {
                setLoad(true);
                setCurrentComponentIndex(v);
            }
        },
        ...props,
        currentEditor,
        setCurrentEditor,
        setLoad,
        setNewJob,
        updateStoriesJob,
        updateAudiosJob,
        setNewAudioJob,
        renderLinkSelect,
        chunkSlug,
    };

    const componentsEditorProps = {
        ...props,
        currentEditor,
        setCurrentEditor,
        currentComponentIndex,
        setCurrentComponentIndex,
        block:
            currentComponentIndex !== null && components[currentComponentIndex],
        setBlock: (v: any) => {
            if (currentComponentIndex !== null) {
                let tmpBlock = cloneDeep(block);
                tmpBlock = set(
                    tmpBlock,
                    ["data", ALL_IN_ONE, "components", currentComponentIndex],
                    v,
                );
                setBlock(tmpBlock);
            }
        },
        renderLinkSelect,
    };

    return (
        <div
            style={{
                paddingBottom: "10px",
                display: "flex",
                flexShrink: 1,
            }}
        >
            <div
                style={{
                    maxWidth: "400px",
                    flexShrink: 0,
                }}
            >
                <BlockColumn {...blockColumnProps} />
            </div>
            <div
                style={{
                    width: "100%",
                    minWidth: "320px",
                    flexShrink: 1,
                    flexGrow: 0,
                }}
            >
                {currentComponentIndex !== null &&
                components[currentComponentIndex] ? (
                    load ? (
                        <></>
                    ) : (
                        <ComponentsEditor {...componentsEditorProps} />
                    )
                ) : (
                    <QuestionColumn {...blockColumnProps} />
                )}
            </div>
        </div>
    );
};

export default Editor;
