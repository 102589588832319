import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import {
    Button,
    Form,
    Row,
    Col,
    Space,
    Upload,
    Select,
    Switch,
    Card,
    message,
} from "antd";
import React, { useState } from "react";
import Microphone from "./Microphone";
import { captureException } from "@sentry/react";
const FormItem = Form.Item;

export const getPresignedURL = async (path = "", extension = "") => {
    const bucket_name = process.env.REACT_APP_BUCKET_NAME;
    const url =
        "https://qjbn7p56fd.execute-api.ap-south-1.amazonaws.com/production/generate";

    const data = {
        bucket_name: bucket_name,
        key: process.env.REACT_APP_BUCKET_BASE + path + Date.now() + extension,
    };
    // console.log(data);
    const options = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    };
    const response = await fetch(url, options);
    const resObj = await response.json();

    return resObj;
};

export const RenderRecord = (props) => {
    const [fileList, setFileList] = useState<any>(
        props.value
            ? [
                  {
                      type: "record",
                      name: props.uploadType != "custom" ? "image.png" : "file",
                      status: "done",
                      url: props.value,
                      original: true,
                  },
              ]
            : [],
    );
    const [selectedFile, setSelectedFile] = useState(props.value ? 0 : -1);
    const [fileError, setFileError] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectValue, setSelectValue] = useState("default");
    const [isDisabled, setIsDisabled] = useState(false);
    const touched = props?.meta?.touched;
    const error = props?.meta?.error;
    const label = props?.label;

    let validateStatus = "";
    if (touched && error) {
        validateStatus = "error";
    }

    const icon = "UploadOutlined";
    const handleSelect = (value) => {
        setIsDisabled(true);
        if (value === "record") {
            setFileList((prev) => [...prev, { type: "record", url: "" }]);
        } else if (value === "upload") {
            setFileList((prev) => [...prev, { type: "upload", url: "" }]);
        }
    };
    const onChangeHandler = ({ file, fileList }, index) => {
        // console.log(file.status);
        // console.log(fileList);
        // const arrayHelpers = props.arrayHelpers;

        if (file.status === "uploading") {
            if (props.setLoad) props.setLoad(true);
        }

        if (file.status == "done") {
            if (props.setLoad) props.setLoad(false);
        } else if (file.status == "removed") {
            //   if (props.onChangeCustom && props.singleUpload) {
            //     props.onChangeCustom({
            //       target: {
            //         value: null,
            //         name: null,
            //       },
            //     });
            //   }

            if (props.setLoad) props.setLoad(false);
            // console.log(file);
            //remove value in form
            props?.input?.onChange("");
        } else if (file.status == "error") {
            setFileError(true);
        }
        const mainFile = fileList[0];
        const files = [...fileList];
        files[index] = mainFile;
        // setFileList(files);
        // setSelectedFile(index);
    };

    const handleUpload = async ({ onSuccess, onError, file }, index) => {
        var url = URL.createObjectURL(file);
        const files = [...fileList];
        const newFile = {
            type: "record",
            file,
            url,
        };
        files[index] = newFile;
        setFileList(files);
        setSelectedFile(index);
        setIsDisabled(false);
    };
    const handleRemove = (file) => {
        console.log("onRemove");
    };
    const pushFile = async (audiofile, fileBlob, index) => {
        const files = [...fileList];
        const file = new File([fileBlob], "audio.mp3", {
            type: "audio/mpeg",
        });
        const url = URL.createObjectURL(fileBlob);
        const newFile = {
            ...audiofile,
            file,
            url,
        };
        files[index] = newFile;
        setFileList(files);
        setSelectedFile(index);
        setIsDisabled(false);
    };
    const handleFinal = async () => {
        if (selectedFile == -1) {
            setIsOpen(false);
            setFileList([]);
            props.onRemove();
            return true;
        }
        const selected = fileList[selectedFile];
        const file = selected.file;
        if (props.value && selectedFile == 0 && selected?.original == true) {
            setIsOpen(false);
            props.setEditState && props.setEditState(false);
            setFileList([selected]);
            return true;
        }
        let extension = "";

        if (props.addExtension) {
            extension = file.name.split(".").pop();
            if (extension.length > 0) extension = "." + extension;
        }
        try {
            const xhr = new XMLHttpRequest();
            const { url, bucket_url } = await getPresignedURL(
                props.path,
                extension || props.extension || "",
            );
            // S3 requires PUT method!
            xhr.open("PUT", url);
            xhr.onreadystatechange = async () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Calls the update prop
                        if (props.onChangeCustom) {
                            props.onChangeCustom({
                                target: {
                                    value: bucket_url,
                                    name: props.name,
                                },
                            });
                        } else
                            props.formik?.handleChange({
                                target: {
                                    value: bucket_url,
                                    name: props.name,
                                },
                            });
                    } else {
                    }
                }
            };
            xhr.send(file);
            setIsOpen(false);
            setFileList(props.isClearFinal ? [] : [selected]);
        } catch (e) {
            captureException(e);
            console.log("Error:", e);
            message.error("Something went wrong!");
        }
    };

    return (
        <>
            {props.children ? (
                React.cloneElement(props.children, {
                    onClick: () => {
                        let valid = true;
                        if (props.checkValid) valid = props.checkValid();
                        if (!valid) return;
                        setIsOpen(true);
                    },
                })
            ) : (
                <FormItem
                    label={
                        !props.editButton && (
                            <Space align="center">
                                {icon && <UploadOutlined />}
                                {label}
                            </Space>
                        )
                    }
                    validateStatus={validateStatus}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    required={props.required}
                    help={
                        error ||
                        (fileError
                            ? `allowed formats - ${
                                  props.accept ? props.accept : "png/jpeg/gif"
                              } `
                            : "")
                    }
                >
                    <Button
                        disabled={props.disabled}
                        onClick={() => setIsOpen(true)}
                    >
                        Add audio
                    </Button>
                </FormItem>
            )}
            {isOpen && (
                <Card
                    style={{
                        position: "fixed",
                        top: 100,
                        right: 20,
                        height: "auto",
                        width: "520px",
                        zIndex: 100,
                        maxHeight: "80vh",
                        overflowY: "auto",
                        backgroundColor: "#e6e6e6",
                    }}
                    hoverable
                    // open={isOpen}
                    // title="add audio"
                    // onCancel={() => setIsOpen(false)}
                    // destroyOnClose={true}
                    // onOk={() => {
                    //     handleFinal();
                    // }}
                    // wrapClassName="record-modal"
                    // mask={false}
                >
                    {fileList.map((f, index) =>
                        f.type == "record" ? (
                            <Col style={{ paddingBottom: "12px" }}>
                                <Microphone
                                    audiofile={f}
                                    index={index + 1}
                                    pushFile={(fileBlob) => {
                                        pushFile(f, fileBlob, index);
                                    }}
                                    onChangeAudio={() => {
                                        if (index == selectedFile)
                                            setSelectedFile(-1);
                                        else setSelectedFile(index);
                                    }}
                                    isSelected={index == selectedFile}
                                    handleDelete={() => {
                                        if (index == selectedFile)
                                            setSelectedFile(-1);
                                        else if (index < selectedFile)
                                            setSelectedFile(selectedFile - 1);
                                        const files = [...fileList];
                                        files.splice(index, 1);
                                        setIsDisabled(false);
                                        setFileList(files);
                                    }}
                                />
                            </Col>
                        ) : f.type === "upload" ? (
                            <Col style={{ paddingBottom: "12px" }}>
                                <Row>
                                    <Col span={1}>{index + 1}</Col>
                                    <Col span={21}>
                                        <Upload
                                            customRequest={(props) =>
                                                handleUpload(props, index)
                                            }
                                            fileList={[]}
                                            onChange={(props) =>
                                                onChangeHandler(props, index)
                                            }
                                            onRemove={handleRemove}
                                            accept={props.accept}
                                        >
                                            <Button icon={<UploadOutlined />}>
                                                Click to Upload
                                            </Button>
                                        </Upload>
                                    </Col>
                                    <Col span={2} align="right">
                                        <Switch
                                            onChange={() => {
                                                setSelectedFile(index);
                                            }}
                                            size="small"
                                            checked={index == selectedFile}
                                            disabled={!f.url}
                                        />
                                        <Button
                                            icon={<DeleteOutlined />}
                                            danger
                                            shape="circle"
                                            size="small"
                                            style={{ marginTop: "5px" }}
                                            onClick={() => {
                                                if (index == selectedFile)
                                                    setSelectedFile(-1);
                                                else if (index < selectedFile)
                                                    setSelectedFile(
                                                        selectedFile - 1,
                                                    );
                                                const files = [...fileList];
                                                files.splice(index, 1);
                                                setIsDisabled(false);
                                                setFileList(files);
                                            }}
                                        ></Button>
                                    </Col>
                                </Row>
                            </Col>
                        ) : (
                            <> </>
                        ),
                    )}
                    <br />
                    <Select
                        value={selectValue}
                        placeholder="Select type"
                        options={[
                            { value: "default", label: "Select type" },
                            { value: "upload", label: "Upload" },
                            { value: "record", label: "Record" },
                        ]}
                        style={{
                            width: "200px",
                        }}
                        onChange={handleSelect}
                        disabled={isDisabled}
                    ></Select>
                    <Row justify={"end"} gutter={[12, 12]}>
                        <Col>
                            <Button
                                onClick={() => {
                                    setIsOpen(false);
                                    setSelectedFile(props.value ? 0 : -1);
                                    setIsDisabled(false);
                                    setFileList(
                                        props.value
                                            ? [
                                                  {
                                                      type: "record",
                                                      name:
                                                          props.uploadType !=
                                                          "custom"
                                                              ? "image.png"
                                                              : "file",
                                                      status: "done",
                                                      url: props.value,
                                                      original: true,
                                                  },
                                              ]
                                            : [],
                                    );
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={handleFinal}>
                                Ok
                            </Button>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    );
};
