import { Can } from "src/services/casl";
import { Radio } from "antd";
import _ from "lodash";
export const Status = ({ block, setBlock, isReadOnlyMode }: any) => {
    return (
        <Can I="edit_status" a="worksheet_block" passThrough>
            {(allowed: boolean) => (
                <Radio.Group
                    disabled={!allowed || isReadOnlyMode}
                    value={
                        !block?.data?.other?.status
                            ? `active`
                            : block?.data?.other?.status
                    }
                    onChange={(e) => {
                        const blockStatus = e.target.value;
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            ["data", "other", "status"],
                            blockStatus,
                        );
                        setBlock(tmpBlock);
                    }}
                >
                    <Radio.Button value="active">Active</Radio.Button>
                    <Radio.Button value="inactive">Inactive</Radio.Button>
                </Radio.Group>
            )}
        </Can>
    );
};
