import React from "react";
import { Card, Col, Row } from "antd";
import { renderer } from "../../helpers";
import { renderTags } from "../../common";

const renderBlockV2FillBlank = (block: any) => {
    const {
        data: {
            v2_fill_blank: { text, blanks, has_options },
            other,
        },
        tags,
    } = block;

    const renderOption = (option: string, idx: number, isCorrect: boolean) => (
        <Card
            bodyStyle={{
                padding: "12px",
                background: isCorrect ? "#ECF87F" : "",
            }}
        >
            {renderer(option)}
        </Card>
    );

    return (
        <div>
            {renderTags(tags)}
            {other?.duration ? <span>Duration: {other?.duration}</span> : null}
            <Row gutter={[0, 12]}>
                <Col span={24}>
                    {<h3>Question Text</h3>}
                    <Card bodyStyle={{ padding: "12px" }}>
                        {renderer(text)}
                    </Card>
                </Col>

                {<h3>Options</h3>}
                {options.map(
                    ({ text, feedback, explanation }: any, idx: number) => (
                        <Col span={24}>
                            <h4>Text</h4>
                            {renderOption(
                                text,
                                idx,
                                correct_options[0] == idx + 1,
                            )}
                        </Col>
                    ),
                )}
            </Row>
        </div>
    );
};
export default renderBlockV2FillBlank;
