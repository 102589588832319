import React from "react";
import { message } from "antd";

import { DeleteIcon } from "src/components";
import { compose } from "src/helpers";
import { withDeletePlan } from "src/modules/plan/operations";
import { captureException } from "@sentry/react";

const DeleteView = (props) => {
    const { deletePlan, id } = props;

    const handleDelete = async () => {
        try {
            await deletePlan(id);
            message.success("Deleted!");
        } catch (err) {
            captureException(err)
            message.error("Failed!");
        }
    };

    return <DeleteIcon {...props} onConfirm={handleDelete} />;
};

export default compose(withDeletePlan)(DeleteView);
