import React, { useRef, useState, useEffect } from "react";
import { Link } from "src/components";
import { Button, Col, Divider, Popconfirm, Row, Space, Spin } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";

import DeleteView from "src/modules/content/components/DeleteView";
import { EditIcon, RenderTable, Layout, MetaTag } from "src/components";
import { message } from "antd";

import { FilterComponent } from "src/modules/content/components";
import {
    getLastStringFromUrl,
    getLastStringParamFromUrl,
} from "src/helpers/functions";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { number } from "yup";
import { Can } from "src/services/casl";
import { useAuth0 } from "@auth0/auth0-react";
import { captureException } from "@sentry/react";

const ListView: React.FC = (props: any) => {
    const {
        loading,
        contents,
        contentAggregate,
        loadContentsData,
        createContentBatch,
        incrementSetDuration,
        incrementCollectionDuration,
        deleteCollectionContent,
    } = props;
    const collection_id = parseInt(getLastStringFromUrl());

    const inputFile = useRef(null);
    const [file, setFile] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const { user } = useAuth0();

    useEffect(() => {
        if (file) {
            message.loading({ content: "Processing...", key: "import" });
            let f = file;
            const reader = new FileReader();
            reader.onload = (evt: any) => {
                // evt = on_file_select event
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                /* Update state */
                setData(Papa.parse(data, { header: true }).data); // shows data in json format
            };
            reader.readAsBinaryString(f);
        }
    }, [file]);

    useEffect(() => {
        async function createContentBatchFunc() {
            const namespace = "https://hasura.io/jwt/claims/";
            const user_id = user && user[namespace + "user_id"];
            const content_arr: any = [];
            const dataMod = data.filter((item: any) => {
                return item.type;
            });

            let durationIncrement = 0;
            dataMod.map((item: any, index: number) => {
                const { type, duration } = item;
                durationIncrement += parseInt(duration);
                const content: any = {
                    type,
                    duration,
                    index,
                    collection_id,
                    user_id,
                };

                if (type == "question") {
                    const t_options = [
                        item.question_option1,
                        item.question_option2,
                        item.question_option3,
                        item.question_option4,
                    ].filter((element) => {
                        return element !== "";
                    });
                    String.prototype.replaceAt = function (index, char) {
                        var a = this.split("");
                        a[index] = char;
                        return a.join("");
                    };

                    content["question"] = {
                        data: {
                            text: item["question_text"],
                            options: JSON.stringify(t_options)
                                .replaceAt("0", "{")
                                .replaceAt(
                                    JSON.stringify(t_options).length - 1,
                                    "}",
                                ),
                            correct_option: item["question_correct_option"],

                            other: {
                                tags: item["tags"]
                                    ? item["tags"]
                                        .split(",")
                                        .map(function (value) {
                                            return value.trim();
                                        })
                                    : [],
                            },
                        },
                    };
                } else if (type == "concept") {
                    content["concept"] = {
                        data: {
                            title: `Read, learn & quiz it!`,
                            images: {
                                data: [
                                    {
                                        url: item["concept_image"],
                                    },
                                ],
                            },
                        },
                    };
                } else
                    console.log(
                        "🚀 ~ file: component.tsx ~ line 54 ~ data.map ~ item: Invalid type",
                        item,
                    );
                content_arr.push(content);
            });
            try {
                const response = await createContentBatch(content_arr);

                // // update set duration
                response.returning[0].collection.sets.map(({ set_id }) => {
                    incrementSetDuration({
                        duration: durationIncrement,
                        id: set_id,
                    });
                });

                // update collection duration
                incrementCollectionDuration({
                    duration: durationIncrement,
                    id: collection_id,
                });

                // console.log({ response });
                message.success({
                    content: "Successfully Imported!",
                    key: "import",
                });
            } catch (e) {
                captureException(e)
                message.error(`Error importing content`);
            }

            setFile(null);
            setData(null);
        }
        if (data && data.length) {
            createContentBatchFunc();
        }
    }, [data]);

    const columns = [
        {
            title: "Index",
            key: "index",
            render: (text, record) => record?.index,
        },
        {
            title: "Duration",
            key: "duration",
            render: (text, record) => record?.duration,
        },
        // {
        //     title: "Type",
        //     key: "type",
        //     render: (text, record) => record?.type,
        // },
        {
            title: "Content",
            key: "content",
            render: (text, record) => {
                return (
                    // <Can I="edit" this={record} passThrough>
                    //     {(allowed) =>
                    //         allowed ? (
                    //             <Link
                    //                 href={
                    //                     record?.question_id
                    //                         ? `/question/update/${record?.question_id}`
                    //                         : `/concept/update/${record?.concept_id}`
                    //                 }
                    //             >
                    record?.type
                    //         </Link>
                    //     ) : (
                    //         `${record?.type}`
                    //     )
                    // }
                    // </Can>
                );
            },
        },

        // {
        //     title: "Action",
        //     key: "action",
        //     align: "right",
        //     render: (text, record) => (
        //         <Space size="middle">
        //             <Can I="edit" this={record} passThrough>
        //                 {(allowed) => (
        //                     <Link
        //                         href={`/collection/content/update/${record?.id}`}
        //                     >
        //                         <EditIcon isButton={true} disabled={!allowed} />
        //                     </Link>
        //                 )}
        //             </Can>
        //             {/* <Divider />
        //             <Can I="delete" a={"collection_content"} passThrough>
        //                 {(allowed) => (
        //                     <DeleteView
        //                         id={record?.id}
        //                         isButton={true}
        //                         disabled={!allowed}
        //                     />
        //                 )}
        //             </Can> */}
        //         </Space>
        //     ),
        // },
    ];

    return (
        <Layout titles={[{ name: "Contents", link: "collection/content" }]}>
            <MetaTag title="Contents" />
            <Row justify="space-between" align="middle">
                <Col span={12}>
                    <h1>Contents List</h1>
                </Col>

                <Col span={4}>
                    <Can I="create" a={"collection_content"} passThrough>
                        {(allowed) => (
                            <Button
                                type="primary"
                                icon={<AppstoreAddOutlined />}
                                onClick={() => inputFile.current.click()}
                                disabled={!allowed}
                            >
                                Import Content
                            </Button>
                        )}
                    </Can>

                    <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                        onChange={(e) => {
                            setFile(e.target.files[0]);
                        }}
                    />
                </Col>
                {/* <Col span={4}>
                    <Can I="create" a={"collection_content"} passThrough>
                        {(allowed) => (
                            <Link
                                href={
                                    collection_id
                                        ? `/collection/content/create/${collection_id}`
                                        : "/collection/content/create"
                                }
                            >
                                <Button
                                    type="primary"
                                    icon={<AppstoreAddOutlined />}
                                    disabled={!allowed}
                                >
                                    Add
                                </Button>
                            </Link>
                        )}
                    </Can>
                </Col> */}

                <Col span={4}>
                    <Can I="delete" this={{ ...contents[0] }} passThrough>
                        {(allowed) => (
                            <Popconfirm
                                title="Are you sure to delete this?"
                                onConfirm={() => {
                                    const submissionIds = [];
                                    let durationIncrement = 0;
                                    contents.map((content) => {
                                        durationIncrement -= parseInt(
                                            content.duration,
                                        );

                                        content.submissions.map(
                                            (submission) =>
                                                submission.submission_question &&
                                                submissionIds.push(
                                                    submission
                                                        .submission_question.id,
                                                ),
                                        );
                                    });

                                    // update collection duration
                                    incrementCollectionDuration({
                                        duration: durationIncrement,
                                        id: collection_id,
                                    });

                                    // update set duration
                                    contents[0].collection.sets.map(
                                        ({ set_id }) => {
                                            incrementSetDuration({
                                                duration: durationIncrement,
                                                id: set_id,
                                            });
                                        },
                                    );

                                    deleteCollectionContent({
                                        id: collection_id,
                                        submissionIds,
                                    });
                                }}
                                //   onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="danger" disabled={!allowed}>
                                    Delete all contents
                                </Button>
                            </Popconfirm>
                        )}
                    </Can>
                </Col>
            </Row>
            <hr />

            {/* <FilterComponent isContent={true} {...props} /> */}
            <hr />

            <Spin spinning={loading}>
                {Boolean(contents?.length) && (
                    <RenderTable
                        loading={loading}
                        columns={columns}
                        data={contents || []}
                        loadData={loadContentsData}
                        aggregate={contentAggregate?.aggregate?.count}
                    />
                )}
            </Spin>
        </Layout>
    );
};

export default ListView;
