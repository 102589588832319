import { MenuOutlined } from "@ant-design/icons";
import { Can } from "src/services/casl";
import { Col } from "antd";
import { SortableHandle } from "react-sortable-hoc";

const DragHandle = SortableHandle(() => <MenuOutlined />);

export const DragAndDrop = ({ disableActions }: any) => {
    return (
        <Col span={3}>
            <Can I="edit" a={"worksheet_block"} passThrough>
                {(allowed: boolean) => (
                    <>
                        {!(disableActions || !allowed) && (
                            <span style={{ cursor: "pointer" }}>
                                <DragHandle />
                            </span>
                        )}
                    </>
                )}
            </Can>
        </Col>
    );
};
