import ReactPlayer from "react-player";

const renderBlockVideo = (block: any) => {
    const {
        data: { video },
    } = block;
    return (
        <div>
            <ReactPlayer controls url={video} style={{ maxWidth: "100%" }} />
        </div>
    );
};

export default renderBlockVideo;
