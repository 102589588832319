import renderBlockCaseStudy from "./renderBlock";
import renderEditorCaseStudy from "./renderEditor";
import isValidCallbackCaseStudy from "./isValid";
import CASE_STUDY_DEFAULT_VALUE from "./defaultValue";
import renderSettingsCaseStudy from "./renderSettings";
import renderCardCaseStudy from "./renderCard";
import { BLOCK_TYPES, addCallback } from "../../common/index";

const { CASE_STUDY_BLOCK } = BLOCK_TYPES;

const CASE_STUDY_CONFIG = {
    [CASE_STUDY_BLOCK]: {
        label: "Case Study",
        defaultValue: CASE_STUDY_DEFAULT_VALUE,
        renderBlock: renderBlockCaseStudy,
        renderEditor: renderEditorCaseStudy,
        renderCard: renderCardCaseStudy,
        renderSettings: renderSettingsCaseStudy,
        addCallback,
        isValidCallback: isValidCallbackCaseStudy,
    },
};

export {
    renderBlockCaseStudy,
    renderCardCaseStudy,
    renderEditorCaseStudy,
    renderSettingsCaseStudy,
    isValidCallbackCaseStudy,
    CASE_STUDY_DEFAULT_VALUE,
    CASE_STUDY_CONFIG,
};
