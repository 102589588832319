import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";

const renderEditorText = ({ block, setBlock }: any) => {
    const value = block?.data?.text;
    const isValid = Boolean(value.trim());
    return (
        <div>
            <Form.Item
                rules={[
                    {
                        required: true,
                    },
                ]}
                validateStatus={!isValid ? "error" : ""}
                help={!isValid ? "Field cannot be empty" : ""}
            >
                <TextArea
                    rows={20}
                    value={value || ""}
                    onChange={(e) =>
                        setBlock({
                            ...block,
                            data: { text: e.target.value },
                        })
                    }
                />
            </Form.Item>
        </div>
    );
};

export default renderEditorText;
