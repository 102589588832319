import React, { useEffect, useState } from "react";
import { Modal, Button, Switch, Divider, Input, Form, Row, Col } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { BlockPicker } from "react-color";
import _ from "lodash";
import PuzzleTagPreview from "./PuzzleTagPreview";
import TableBorderEditor from "../../../../../Figma/renderEditor/TableBorder";
import { RenderUpload } from "src/components";
import { replaceCDNUrl } from "src/modules/worksheet/components/blocks/helpers";
import CellFields from "./CellFields";
import { BorderTag } from "./BorderTag";

interface PuzzleTagModalProps {
    tag: any;
    setTag: (tag: any) => void;
}

function applyBordersToCells(
    cells: any,
    borderSettings: any,
    boundaryBorders: any,
) {
    function getSelectiveBorder(rowIndex, colIndex, piece) {
        const isTopEdge =
            rowIndex === 0 || piece[rowIndex - 1][colIndex] === null;
        const isBottomEdge =
            rowIndex === piece.length - 1 ||
            piece[rowIndex + 1][colIndex] === null;
        const isLeftEdge = colIndex === 0;
        const isRightEdge = colIndex === piece[0].length - 1;

        const borderStyle = (enabled) => ({
            color: enabled ? borderSettings?.color ?? "#22194D" : "transparent",
            opacity: borderSettings?.opacity,
            width: borderSettings?.width,
            style: borderSettings?.style,
            enabled: enabled,
        });

        return {
            selective: true,
            top: borderStyle(isTopEdge),
            bottom: borderStyle(isBottomEdge),
            left: borderStyle(
                isLeftEdge || piece[rowIndex][colIndex - 1] === null,
            ),
            right: borderStyle(
                isRightEdge || piece[rowIndex][colIndex + 1] === null,
            ),
            enabled: true,
        };
    }

    return cells.map((row: any, rowIndex: number) =>
        row.map((cell: any, colIndex: number) => {
            if (cell === null) return null;

            return {
                ...cell,
                content: {
                    ...cell.content,
                    border: boundaryBorders
                        ? getSelectiveBorder(rowIndex, colIndex, cells)
                        : { ...borderSettings },
                },
            };
        }),
    );
}

const PuzzleTagModal: React.FC<PuzzleTagModalProps> = ({ tag, setTag }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isUniform, setIsUniform] = useState(false);

    useEffect(() => {
        if (!tag?.customBorder && isOpen) {
            // Find first non-null cell and get its border settings
            for (let row of tag.cells) {
                let breakLoop = false;
                for (let cell of row) {
                    if (cell) {
                        let borderSettings;
                        if (
                            cell.content?.border?.selective === true &&
                            !tag?.isDrawing
                        ) {
                            const border = cell.content.border;
                            // Check each direction for non-transparent border
                            if (border.top?.enabled) {
                                borderSettings = border.top;
                            } else if (border.bottom?.color) {
                                borderSettings = border.bottom;
                            } else if (border.left?.color) {
                                borderSettings = border.left;
                            } else if (border.right?.color) {
                                borderSettings = border.right;
                            }
                            borderSettings.boundary = true;
                        } else {
                            borderSettings = cell.content?.border ?? {};
                        }

                        if (borderSettings) {
                            setTag({
                                ...tag,
                                customBorder: _.cloneDeep(borderSettings),
                            });
                            breakLoop = true;
                            break;
                        }
                    }
                    if (breakLoop) break;
                }
                if (breakLoop) break;
            }
        }
    }, [isOpen]);

    const handleCellChange = (
        rowIndex: number,
        colIndex: number,
        value: string,
        updateAll: boolean = false,
    ) => {
        const newCells = tag.cells.map((row: any, rIndex: number) =>
            row.map((cell: any, cIndex: number) => {
                if (updateAll || (rIndex === rowIndex && cIndex === colIndex)) {
                    return {
                        ...cell,
                        content: {
                            ...cell.content,
                            text: {
                                ...cell.content?.text,
                                value: {
                                    ...cell.content?.text?.value,
                                    default: value,
                                },
                                enabled: true,
                            },
                        },
                    };
                }
                return cell;
            }),
        );
        setTag({ ...tag, cells: newCells });
    };

    const handleCellFillColorChange = (
        rowIndex: number,
        colIndex: number,
        color: string,
        updateAll: boolean = false,
    ) => {
        const newCells = tag.cells.map((row: any, rIndex: number) =>
            row.map((cell: any, cIndex: number) => {
                if (
                    (updateAll ||
                        (rIndex === rowIndex && cIndex === colIndex)) &&
                    cell
                ) {
                    return {
                        ...cell,
                        content: {
                            ...cell?.content,
                            fillColor: {
                                ...cell.content?.fillColor,
                                default: color,
                                enabled: true,
                            },
                        },
                    };
                }
                return cell;
            }),
        );
        setTag({ ...tag, cells: newCells });
    };

    const handleCellFillColorEnabled = (
        rowIndex: number,
        colIndex: number,
        enabled: boolean,
        updateAll: boolean = false,
    ) => {
        const newCells = tag.cells.map((row: any, rIndex: number) =>
            row.map((cell: any, cIndex: number) => {
                if (
                    (updateAll ||
                        (rIndex === rowIndex && cIndex === colIndex)) &&
                    cell
                ) {
                    return {
                        ...cell,
                        content: {
                            ...cell.content,
                            fillColor: {
                                ...cell.content?.fillColor,
                                enabled,
                            },
                        },
                    };
                }
                return cell;
            }),
        );
        setTag({ ...tag, cells: newCells });
    };

    const handleImageBackgroundChange = (
        rowIndex: number,
        colIndex: number,
        value: string,
        updateAll: boolean = false,
    ) => {
        const enabled =
            value !== null && value !== undefined && value.trim() !== "";
        const newCells = tag.cells.map((row: any, rIndex: number) =>
            row.map((cell: any, cIndex: number) => {
                if (
                    (updateAll ||
                        (rIndex === rowIndex && cIndex === colIndex)) &&
                    cell
                ) {
                    return {
                        ...cell,
                        content: {
                            ...cell.content,
                            imageBackground: {
                                ...cell.content?.imageBackground,
                                src: { default: value },
                                enabled,
                            },
                        },
                    };
                }
                return cell;
            }),
        );
        setTag({ ...tag, cells: newCells });
    };

    const handleFontSizeChange = (
        rowIndex: number,
        colIndex: number,
        size: number,
        updateAll: boolean = false,
    ) => {
        const newCells = tag.cells.map((row: any, rIndex: number) =>
            row.map((cell: any, cIndex: number) => {
                if (
                    (updateAll ||
                        (rIndex === rowIndex && cIndex === colIndex)) &&
                    cell
                ) {
                    return {
                        ...cell,
                        content: {
                            ...cell.content,
                            text: {
                                ...cell.content?.text,
                                size: {
                                    ...cell.content?.text?.size,
                                    default: size,
                                },
                            },
                        },
                    };
                }
                return cell;
            }),
        );
        setTag({ ...tag, cells: newCells });
    };

    const handleFontColorChange = (
        rowIndex: number,
        colIndex: number,
        color: string,
        updateAll: boolean = false,
    ) => {
        const newCells = tag.cells.map((row: any, rIndex: number) =>
            row.map((cell: any, cIndex: number) => {
                if (
                    (updateAll ||
                        (rIndex === rowIndex && cIndex === colIndex)) &&
                    cell
                ) {
                    return {
                        ...cell,
                        content: {
                            ...cell.content,
                            text: {
                                ...cell.content?.text,
                                color: {
                                    ...cell.content?.text?.color,
                                    default: color,
                                },
                            },
                        },
                    };
                }
                return cell;
            }),
        );
        setTag({ ...tag, cells: newCells });
    };

    // Function to update all cells
    const updateAllCells = (
        updateFunction: (
            rowIndex: number,
            colIndex: number,
            value: any,
            updateAll: boolean,
        ) => void,
        value: any,
    ) => {
        updateFunction(0, 0, value, true);
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)}>Edit puzzle tag</Button>
            <Modal
                title="Edit Puzzle Tag"
                open={isOpen}
                onOk={() => setIsOpen(false)}
                onCancel={() => setIsOpen(false)}
                width={800}
            >
                <div
                    style={{
                        border: "1px solid #d9d9d9",
                        borderRadius: "4px",
                        padding: "8px",
                        maxHeight: "600px",
                        overflow: "auto",
                    }}
                >
                    <PuzzleTagPreview
                        tag={tag}
                        background={tag?.input_background?.value?.color}
                    />
                </div>

                <TableBorderEditor
                    blockData={{ border: tag?.customBorder }}
                    setBlock={(value: any) => {
                        const borderSettings = value.border;
                        const boundaryBorders = value.border?.boundary;
                        const cells = applyBordersToCells(
                            tag?.cells,
                            borderSettings,
                            boundaryBorders,
                        );
                        setTag({ ...tag, cells, customBorder: value.border });
                    }}
                    disableWrite={false}
                    puzzleEditor={true}
                />
                <Divider />

                <div>
                    <Switch
                        checkedChildren="Uniform"
                        unCheckedChildren="Individual"
                        checked={isUniform}
                        onChange={(checked) => setIsUniform(checked)}
                    />
                </div>

                {isUniform && (
                    <CellFields
                        type="common"
                        cell={tag?.cells[0][0]} // Assuming all cells have the same common fields
                        rowIndex={0}
                        colIndex={0}
                        handleCellChange={(rowIndex, colIndex, value) =>
                            updateAllCells(handleCellChange, value)
                        }
                        handleFontSizeChange={(rowIndex, colIndex, size) =>
                            updateAllCells(handleFontSizeChange, size)
                        }
                        handleFontColorChange={(rowIndex, colIndex, color) =>
                            updateAllCells(handleFontColorChange, color)
                        }
                        handleCellFillColorEnabled={(
                            rowIndex,
                            colIndex,
                            enabled,
                        ) =>
                            updateAllCells(handleCellFillColorEnabled, enabled)
                        }
                        handleCellFillColorChange={(
                            rowIndex,
                            colIndex,
                            color,
                        ) => updateAllCells(handleCellFillColorChange, color)}
                        handleImageBackgroundChange={(
                            rowIndex,
                            colIndex,
                            value,
                        ) => updateAllCells(handleImageBackgroundChange, value)}
                    />
                )}

                <div
                    style={{
                        overflowX: "auto",
                        height: "500px",
                        width: "100%",
                        overflowY: "auto",
                    }}
                >
                    <table style={{ borderCollapse: "collapse" }}>
                        <tbody>
                            {tag?.cells?.map((row: any, rowIndex: number) => (
                                <tr key={rowIndex}>
                                    {row.map((cell: any, colIndex: number) => (
                                        <td
                                            key={colIndex}
                                            style={{
                                                padding: "4px",
                                                border: "1px solid #d9d9d9",
                                                height: "250px",
                                                width: "250px",
                                            }}
                                        >
                                            {cell !== null && (
                                                <>
                                                    <BorderTag
                                                        rowIndex={rowIndex}
                                                        colIndex={colIndex}
                                                        cell={cell?.content}
                                                        setCurrentCell={(
                                                            value: any,
                                                        ) => {
                                                            const newCells =
                                                                tag.cells.map(
                                                                    (
                                                                        row: any,
                                                                        rIndex: number,
                                                                    ) =>
                                                                        row.map(
                                                                            (
                                                                                c: any,
                                                                                cIndex: number,
                                                                            ) => {
                                                                                if (
                                                                                    rIndex ===
                                                                                        rowIndex &&
                                                                                    cIndex ===
                                                                                        colIndex
                                                                                ) {
                                                                                    return {
                                                                                        ...c,
                                                                                        content:
                                                                                            value,
                                                                                    };
                                                                                }
                                                                                return c;
                                                                            },
                                                                        ),
                                                                );
                                                            setTag({
                                                                ...tag,
                                                                cells: newCells,
                                                            });
                                                        }}
                                                    />
                                                    <CellFields
                                                        type={
                                                            isUniform
                                                                ? "non-common"
                                                                : "all"
                                                        }
                                                        cell={cell}
                                                        rowIndex={rowIndex}
                                                        colIndex={colIndex}
                                                        handleCellChange={
                                                            handleCellChange
                                                        }
                                                        handleFontSizeChange={
                                                            handleFontSizeChange
                                                        }
                                                        handleFontColorChange={
                                                            handleFontColorChange
                                                        }
                                                        handleCellFillColorEnabled={
                                                            handleCellFillColorEnabled
                                                        }
                                                        handleCellFillColorChange={
                                                            handleCellFillColorChange
                                                        }
                                                        handleImageBackgroundChange={
                                                            handleImageBackgroundChange
                                                        }
                                                    />
                                                </>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </>
    );
};

export default PuzzleTagModal;
