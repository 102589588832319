import React from "react";
import { Row, Col, Form, Button, Divider } from "antd";
import { withFormik, FieldArray } from "formik";
import FormikPersist from "src/components/FormikPersist";
import {
    Field,
    RenderCheckBox,
    RenderField,
    RenderAutoComplete,
    RenderTags,
    RenderDynamicField,
    RenderSelect,
    RenderUpload,
} from "src/components";
import { Select } from "antd";
const Option = Select.Option;

const QuestionForm = (props) => {
    const { onFinishFailed, values, handleSubmit, isSubmitting } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                <Col lg={24} md={24}>
                    <Field
                        label="Question Text"
                        placeholder="Question Text"
                        name={"text"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="text"
                        value={values.text}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Question Options"
                        placeholder="Question Options"
                        name={"options"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.options}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Question Correct Option"
                        placeholder="Question Correct Option"
                        name={"correct_option"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="number"
                        value={values.correct_option}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const QuestionFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { question } = props;

        return {
            ...(question?.id ? { id: question.id } : {}),
            text: question?.text || "",
            options: question?.options || `{"None"}`,
            correct_option: question?.correct_option || 0,
        };
    },

    async handleSubmit(values, { props: { onFinish } }: any) {
        await onFinish(values);
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

export default QuestionFormWithFormik(QuestionForm);
