import {
    useParams,
    useSearchParams,
    useNavigate,
    useLocation,
} from "react-router-dom";

export const useRouter = () => {
    const [searchParams]: any = useSearchParams();
    const queryParams = Object.fromEntries([...searchParams]);
    const routeParams = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const query = { ...routeParams, ...queryParams };

    const push = (href: any) => {
        if (!href) return;
        if (typeof href === "string") navigate(href);
        else {
            const { pathname, query } = href;
            navigate(`${pathname}?${new URLSearchParams(query).toString()}`);
        }
    };

    const back = () => navigate(-1);

    const asPath = location.pathname + location.search;

    return { query, push, asPath, isReady: true, back };
};
