import { FunctionComponent } from "react";

import {
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import { PLAN_QUERY, PLANS_QUERY, PLAN_AGGREGATE_QUERY } from "../graphql";

export const withHookForPlans = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { pagination, where } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            PLANS_QUERY,
            {
                variables: {
                    where,
                    ...pagination,
                },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "plans", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    plans: items,
                    subscribeToMore,
                    updateQuery,
                    loadPlansData: loadItemsData,
                    refetchPlans: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregatePlan = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { where } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            PLAN_AGGREGATE_QUERY,
            {
                variables: { where },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "planAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    planAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForPlan = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(PLAN_QUERY, {
            variables: {
                id: getLastStringParamFromUrl(),
            },
        });

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingPlan: loading,
                    planData: data?.plan,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
