import React from "react";
import { compose } from "src/helpers";
import {
    withHookForAggregateStudent,
    withHookForStudents,
} from "src/modules/student/operations";
import { withStateAndActionsForStudent } from "src/modules/student/resolvers";
import ListView from "./component";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    withStateAndActionsForStudent,
    withHookForStudents,
    withHookForAggregateStudent,
)(List);
