import renderBlockShortAnswer from "./renderBlock";
import renderEditorShortAnswer from "./renderEditor";
import isValidCallbackShortAnswer from "./isValid";
import SHORT_ANSWER_DEFAULT_VALUE from "./defaultValue";
import renderSettingsShortAnswer from "./renderSettings";
import renderCardShortAnswer from "./renderCard";
import { BLOCK_TYPES } from "../../common/index";

const { SHORT_ANSWER_BLOCK } = BLOCK_TYPES;

const SHORT_ANSWER_BLOCK_CONFIG = {
    [SHORT_ANSWER_BLOCK]: {
        label: "Short Answer",
        defaultValue: SHORT_ANSWER_DEFAULT_VALUE,
        renderBlock: renderBlockShortAnswer,
        renderEditor: renderEditorShortAnswer,
        renderCard: renderCardShortAnswer,
        renderSettings: renderSettingsShortAnswer,
        isValidCallback: isValidCallbackShortAnswer,
    },
};

export {
    renderBlockShortAnswer,
    renderCardShortAnswer,
    renderEditorShortAnswer,
    renderSettingsShortAnswer,
    isValidCallbackShortAnswer,
    SHORT_ANSWER_DEFAULT_VALUE,
    SHORT_ANSWER_BLOCK_CONFIG,
};
