import { gql } from "@apollo/client";export default gql`query documents($limit: Int = 12, $offset: Int = 0, $group_id: Int = 10) {
    documents: home_explore_document(
        limit: $limit
        offset: $offset
        where: { groups: { group_id: { _eq: $group_id } } }
    ) {
        id
        status
        banner
        category
        created_at
        cta_action
        cta_text
        preview_images
        title
        type
        updated_at
        url

        groups (where:  { group_id: { _eq: $group_id } } ) {
            id
            group_id
            order
        }
    }
}
`;
