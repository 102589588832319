export const KEYMAP = {
    generic_cluster: "table_and_tags",
    drag_drop_category: "drag_category",
    check_type: "check_type",
    feedback_type: "feedback_type",
    start_audio: "sound_pool",
    start_feedback: "feedback_pool",
    // end_audio: "end_audio",
    // has_correctness_stroke: "has_correctness_stroke",
    // has_highlight: "has_highlight",
};

export const CLUSTER_KEYS = {
    table_collection: "Table",
    tags_collection: "Tags Cluster",
    buttons_collection: "Buttons Clustor",
};

export const COMPUTE_FUNCTION_MULTIINPUT = [];

export const COMPUTE_FUNC_INPUT = [];
