import { CollectionList, CollectionCreate, CollectionEdit } from "./views";

import { Route } from "react-router-dom";
export const COLLECTION_ROUTES = [
    <Route path="/collection" element={<CollectionList />} />,
    <Route path="/collection/create" element={<CollectionCreate />} />,
    <Route
        path="/collection/update/:collection_id"
        element={<CollectionEdit />}
    />,

    <Route path="/collection/topic/:topic_id" element={<CollectionList />} />,
];

export { CollectionList, CollectionCreate, CollectionEdit } from "./views";
