import renderBlockProgressionStage from "./renderBlock";
import renderEditorProgressionStage from "./renderEditor";
import isValidCallbackProgressionStage from "./isValid";
import PROGRESSION_STAGE_DEFAULT_VALUE from "./defaultValue";
import renderSettingsProgressionStage from "./renderSettings";
import renderCardProgressionStage from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common/index";

const { PROGRESSION_STAGE_BLOCK } = BLOCK_TYPES;

const PROGRESSION_STAGE_BLOCK_CONFIG = {
    [PROGRESSION_STAGE_BLOCK]: {
        label: "Progression Stage",
        defaultValue: PROGRESSION_STAGE_DEFAULT_VALUE,
        renderBlock: renderBlockProgressionStage,
        renderEditor: renderEditorProgressionStage,
        renderCard: renderCardProgressionStage,
        renderSettings: renderSettingsProgressionStage,
        addCallback,
        isValidCallback: isValidCallbackProgressionStage,
    },
};

export {
    renderBlockProgressionStage,
    renderCardProgressionStage,
    renderEditorProgressionStage,
    renderSettingsProgressionStage,
    isValidCallbackProgressionStage,
    PROGRESSION_STAGE_DEFAULT_VALUE,
    PROGRESSION_STAGE_BLOCK_CONFIG,
};
