import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { cloneDeep, set } from "lodash";
import ComponentDetails from "../common/ComponentDetails";
import { useRef, useState, useEffect } from "react";
import { KEYMAP } from "./mapping";
import { LogicModal } from "../common/LogicModal";
import { FeedbackPool } from "../common/FeedbackPool";

const GeogebraEditor = (props: any) => {
    const {
        block,
        setBlock,
        disableWrite,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
    } = props;
    const {
        figma: { variables, compute_functions },
    } = block;
    const blockVarsMap: any = useRef({});

    const [load, setLoad] = useState(true);
    const [openFeedbackModal, setOpenFeedbackModal] = useState("");

    useEffect(() => {
        setLoad(true);
        variables?.forEach((element: { name: string | number }, i: any) => {
            blockVarsMap.current[element.name] = i;
        });
        let v = setTimeout(() => {
            setLoad(false);
        }, 50);
        return () => {
            clearTimeout(v);
        };
    }, []);

    let materialId =
        blockVarsMap.current[KEYMAP.material_id] >= 0 &&
        variables[blockVarsMap.current[KEYMAP.material_id]].value;

    return load ? (
        <></>
    ) : (
        <div style={{ paddingBottom: "10px", display: "flex" }}>
            {openFeedbackModal && openFeedbackModal !== "" && (
                <FeedbackPool
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        setOpenFeedbackModal(
                            value === true ? openFeedbackModal : "",
                        );
                    }}
                    name={openFeedbackModal}
                    feedbackPool={
                        blockVarsMap.current[openFeedbackModal] >= 0 &&
                        variables[blockVarsMap.current[openFeedbackModal]].value
                    }
                    disableWrite={disableWrite}
                    onSave={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            [
                                "figma",
                                "variables",
                                blockVarsMap.current[openFeedbackModal],
                                "value",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    currentEditor={currentEditor}
                    setCurrentEditor={setCurrentEditor}
                />
            )}
            <Card
                style={{
                    minWidth: "600px",
                    // maxWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
                title={<h3>Configurations</h3>}
            >
                <Row>
                    <Col span={12}>
                        <ComponentDetails
                            block={block}
                            setBlock={setBlock}
                            disableWrite={disableWrite}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Update Logic">
                            <LogicModal
                                disableWrite={disableWrite}
                                computeFUnctions={compute_functions}
                                onSave={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["figma", "compute_functions"],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Feedback Type">
                            <Select
                                value={
                                    blockVarsMap.current[
                                        KEYMAP.feedback_type
                                    ] >= 0 &&
                                    variables[
                                        blockVarsMap.current[
                                            KEYMAP.feedback_type
                                        ]
                                    ].value
                                }
                                placeholder="Select type"
                                options={
                                    variables[
                                        blockVarsMap.current[
                                            KEYMAP.feedback_type
                                        ]
                                    ]?.options
                                }
                                style={{
                                    flexGrow: 0,
                                }}
                                onChange={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                                KEYMAP.feedback_type
                                            ],
                                            "value",
                                        ],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                disabled={disableWrite}
                            />
                        </Form.Item>
                        {blockVarsMap.current[KEYMAP.start_feedback] >= 0 && (
                            <Form.Item label="Feedback Pool">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setOpenFeedbackModal(
                                            KEYMAP.start_feedback,
                                        );
                                    }}
                                    size="small"
                                >
                                    Add
                                </Button>
                            </Form.Item>
                        )}
                    </Col>
                </Row>
                <Form.Item
                    label="Material Id"
                    validateStatus={!materialId ? "error" : ""}
                    help={!materialId ? "Field cannot be empty" : ""}
                >
                    <Input
                        disabled={disableWrite}
                        value={materialId}
                        onChange={(e) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                [
                                    "figma",
                                    "variables",
                                    blockVarsMap.current[KEYMAP.material_id],
                                    "value",
                                ],
                                e.target.value,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>
            </Card>
        </div>
    );
};

export default GeogebraEditor;
