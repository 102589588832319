import React, { useState, useEffect } from "react";
import { Transfer, Button, Modal, Tooltip } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { useRouter } from "src/helpers";
import uniq from "lodash/uniq";
import debounce from "lodash/debounce";

import { compose } from "src/helpers/index";
import { withLiteBooks } from "src/modules/book/operations";
import {
    withAddGroupTabMap,
    withDeleteGroupTabMap,
} from "src/modules/tab/operations";
import { withStateAndActionsForBook } from "src/modules/book/resolvers";
import { captureException } from "@sentry/react";

const ExistingBook = (props) => {
    const router = useRouter();
    const { tabId } = router.query;
    const {
        // data
        loading,
        books = [],
        initialData = [],
        // flags
        disabled = true,
        oneWay,
        // operations
        addGroupTabMap,
        deleteGroupTabMap,
        // filter
        onNameChange,
        //callback
        callback,
    } = props;
    const [visible, setVisible] = useState(false);
    const [targetKeys, setTargetKeys] = useState<string[]>(
        initialData.map(({ key }: { key: string }) => key),
    );

    useEffect(() => {
        setTargetKeys(initialData.map((data: any) => data.key));
    }, [initialData]);

    const handleFinish = async () => {
        const initialKey = initialData.map((data) => data.key);
        const newKey = targetKeys.map((key) => key);
        try {
            await Promise.all([
                // Add books to tab
                ...uniq(targetKeys)
                    .filter((key) => initialKey.indexOf(key) === -1)
                    .map((group_id, i) => {
                        addGroupTabMap({
                            objects: [
                                {
                                    tab_id: tabId,
                                    group_id,
                                    order: initialKey.length + i + 1,
                                },
                            ],
                        });
                    }),

                // Remove books from tab
                ...uniq(initialKey)
                    .filter((key) => newKey.indexOf(key) === -1)
                    .map((group_id, i) => {
                        deleteGroupTabMap({
                            tab_id: tabId,
                            group_id,
                        });
                    }),
            ]);
        } catch (e) {
            captureException(e)
            console.log("🚀 ~ file: ExistingBook.tsx:48 ~ handleFinish ~ e", e);
        }
        callback();
        setVisible(false);
    };

    const handleSearch = (direction: string, value: string) => {
        if (direction === "left") {
            onNameChange(value);
        }
    };

    const btn = (
        <Button
            type="primary"
            icon={<AppstoreAddOutlined />}
            onClick={() => setVisible(!visible)}
            disabled={disabled || !tabId}
        >
            Add Existing Book
        </Button>
    );
    return (
        <>
            {tabId ? (
                btn
            ) : (
                <Tooltip title="Select a tab to enable">{btn}</Tooltip>
            )}
            <Modal
                width={"70%"}
                title="Add Existing Book"
                centered
                open={visible}
                onOk={handleFinish}
                onCancel={() => setVisible(false)}
            >
                <Transfer<{ title: string; cover: string }>
                    // oneWay={oneWay}
                    dataSource={[...books?.map(getBookItem), ...initialData]}
                    showSearch
                    targetKeys={targetKeys}
                    filterOption={() => books?.map(getBookItem)}
                    onChange={setTargetKeys}
                    onSearch={debounce(handleSearch, 500)}
                    render={
                        (item) => item.title
                        // <Card cover={item.cover}>{item.title}</Card>
                    }
                    listStyle={{ width: "50%", height: 300 }}
                />
            </Modal>
        </>
    );
};

export default compose(
    withStateAndActionsForBook,
    withLiteBooks,
    withAddGroupTabMap,
    withDeleteGroupTabMap,
)(ExistingBook);

export function getBookItem(book: any, i: number): any {
    return {
        key: book.id.toString(),
        title: book.title,
        cover: book.cover,
        chosen: false,
    };
}
