import React from "react";
import { renderer } from "src/modules/worksheet/components/blocks/helpers";

const latexTextStyle = {
    color: "#828282",
    fontFamily: "Epilogue",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "200",
    lineHeight: "150%", // This will effectively be 24px if the font size is 16px
};

const TextComponent = ({ textObj }) => {
    const {
        value: textValue,
        type,
        size = { default: 16 },
        color = { default: "#000" },
    } = textObj;
    const updatedLatexTextStyle = {
        ...latexTextStyle,
        color: color?.value ?? color?.default,
        fontSize: `${size?.value ?? size?.default}px`,
    };
    return (
        <>
            {(!type || type === "normal") && (
                <div className="renderer-table-text-wrapper">
                    <p
                        className="renderer-table-cell-content-text"
                        style={{
                            marginBlockEnd: "0",
                            color: color?.value ?? color?.default,
                            fontSize: `${size?.value ?? size?.default}px`,
                        }}
                    >
                        {textValue?.value ?? textValue?.default}
                    </p>
                </div>
            )}
            {type === "latex" && (
                <div className="renderer-table-latex-wrapper">
                    <p className="renderer-table-cell-content-text">
                        {renderer(textValue?.value ?? textValue?.default, {
                            textStyle: updatedLatexTextStyle,
                        })}
                    </p>
                </div>
            )}
        </>
    );
};

export default TextComponent;
