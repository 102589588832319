import { BlockCard } from "src/modules/worksheet/components/WorksheetEditor/components/Editor";
import { BLOCK_TYPES } from "../../common/";

const { V2_STORIES_BLOCK } = BLOCK_TYPES;

const renderCard = (props: any) => {
    const { block } = props;
    const {
        data: {
            [V2_STORIES_BLOCK]: { title },
        },
    } = block;

    const displayText = title
        ? title.slice(0, title.length > 10 ? 10 : title.length)
        : "...";

    return (
        <div style={{ position: "relative", zIndex: 99 }}>
            <BlockCard {...props}>
                {displayText}

                <div style={{ fontSize: "11px", fontWeight: "bold" }}>
                    {"Stories Block"}
                </div>
            </BlockCard>
        </div>
    );
};

export default renderCard;
