import { FunctionComponent } from "react";
import { graphql } from "@apollo/client/react/hoc";

import {
    ORDERS_QUERY,
    CREATE_ORDER_MUTATION,
    UPDATE_ORDER,
    DELETE_ORDER_MUTATION,
} from "../graphql";
import { captureException } from "@sentry/react";

// Query
export const withOrders = (Component: FunctionComponent) =>
    graphql(ORDERS_QUERY, {
        options: ({ where, pagination }) => {
            return {
                variables: {
                    where,
                    ...pagination,
                },
            };
        },
        props: ({ data }) => {
            const {
                loading,
                error,
                orders,
                fetchMore,
                subscribeToMore,
                updateQuery,
                refetch,
            } = data;
            const loadQuestionsData = (offset: number) => {
                return fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                            orders: [
                                ...previousResult.orders,
                                ...fetchMoreResult.orders,
                            ],
                        };
                    },
                });
            };
            if (error) throw new Error(error.message);
            return {
                loading,
                orders,
                subscribeToMore,
                updateQuery,
                loadQuestionsData,
                refetchOrders: refetch,
            };
        },
    })(Component);

// Mutation
export const withCreateOrder = (Component: FunctionComponent) =>
    graphql(CREATE_ORDER_MUTATION, {
        props: ({ mutate }) => ({
            createOrder: async (object: Object) => {
                // console.log('🚀 ~ file: Operations.ts ~ line 60 ~ createOrder: ~ object', object);
                try {
                    const {
                        data: { insert_order_one },
                    } = await mutate({
                        variables: { object },
                        optimisticResponse: {
                            __typename: "Mutation",
                            createOrder: {
                                object,
                                __typename: "INSERT_ORDER_ONE",
                            },
                        },
                    });
                    return insert_order_one;
                } catch (e) {
                    captureException(e)
                    console.error(e);
                }
            },
        }),
    })(Component);

export const withUpdateOrder = (Component: FunctionComponent) =>
    graphql(UPDATE_ORDER, {
        props: ({ mutate }) => ({
            updateOrder: (object: Object) => {
                mutate({
                    variables: { ...object },
                    optimisticResponse: {
                        updateOrder: {
                            ...object,
                            __typename: "order",
                        },
                    },
                });
            },
        }),
    })(Component);

export const withDeleteOrder = (Component: FunctionComponent) =>
    graphql(DELETE_ORDER_MUTATION, {
        props: ({ mutate }) => ({
            deleteOrder: (id: string) => {
                mutate({
                    variables: { id },
                    optimisticResponse: {
                        __typename: "Mutation",
                        deleteOrder: {
                            id,
                            __typename: "DELETE_ORDER_BY_PK",
                        },
                    },
                });
            },
        }),
    })(Component);
