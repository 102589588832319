import { PlusCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Form, Select, Input, Divider } from "antd";
import capitalize from "lodash/capitalize";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import React from "react";
import CondiionsFormCard from "./CondiionsFormCard";

import { v4 as uuid } from "uuid";
import {
    NO_CHECK_LATEX,
    miniRenderTxtImg,
    miniRenderer,
    renderBlockAsOptions,
} from "src/modules/worksheet/components/blocks/helpers";

const BlockJumpCard = (props: any) => {
    const { blocks, setBlock, idx, block } = props;

    const { type, data } = block;

    const { jumpTo, content } = data;

    const buttonType = content?.button?.buttonType;
    const buttonUrl = content?.button?.buttonUrl;
    const conditions = jumpTo?.conditions || [];

    return (
        <>
            <Badge.Ribbon color={"cyan"} text={idx + 1} placement={"start"}>
                <Card
                    key={idx}
                    title={
                        <div>
                            <strong>{`${capitalize(type)} Block`}</strong>
                            <div
                                style={{
                                    marginTop: "10px",
                                }}
                            >
                                {NO_CHECK_LATEX.includes(type)
                                    ? miniRenderTxtImg({
                                          type: type,
                                          value: data.text || data.image,
                                      })
                                    : miniRenderer(
                                          data[type]?.title ||
                                              data[type]?.text ||
                                              data.text ||
                                              [],
                                          "overflow-x-hidden",
                                      )}
                            </div>
                        </div>
                    }
                    style={{
                        marginBottom: "20px",
                        border: "1px solid black",
                        borderRadius: "8px",
                    }}
                    bodyStyle={{
                        paddingBottom: 0,
                    }}
                >
                    {type === "content" ? (
                        <Form.Item
                            label="Button Type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            validateStatus={!buttonType ? "error" : ""}
                            help={!buttonType ? "Field cannot be empty" : ""}
                        >
                            <Select
                                defaultValue={buttonType}
                                onChange={(value) => {
                                    let tmpBlock = cloneDeep(block);

                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "data",
                                            "content",
                                            "button",
                                            "buttonType",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock, idx);
                                }}
                                options={[
                                    { value: "internal", label: "Internal" },
                                    { value: "external", label: "External" },
                                ]}
                            />
                        </Form.Item>
                    ) : (
                        <></>
                    )}

                    {type === "content" &&
                    buttonType &&
                    buttonType === "external" ? (
                        <Form.Item
                            label="External URL"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            validateStatus={!buttonUrl ? "error" : ""}
                            help={!buttonUrl ? "Field cannot be empty" : ""}
                        >
                            <Input
                                value={buttonUrl}
                                onChange={(e) => {
                                    let tmpBlock = cloneDeep(block);

                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "data",
                                            "content",
                                            "button",
                                            "buttonUrl",
                                        ],
                                        e.target.value,
                                    );
                                    setBlock(tmpBlock, idx);
                                }}
                                required
                            />
                        </Form.Item>
                    ) : (
                        <>
                            {conditions.map((item: any, i: any) => (
                                <div key={uuid()}>
                                    <CondiionsFormCard
                                        conditionIndex={i}
                                        condition={item}
                                        blockId={idx}
                                        blocks={blocks}
                                        setBlock={setBlock}
                                        block={block}
                                        conditions={conditions}
                                    />
                                    <Divider
                                        style={{
                                            background: "black",
                                        }}
                                    />
                                </div>
                            ))}

                            {blocks
                                .filter((_: any, i: number) => i <= idx)
                                .some(
                                    (item: { type: string }) =>
                                        item.type === "mcq",
                                ) && (
                                <Form.Item>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            const tempConditions = [
                                                ...conditions,
                                            ];

                                            const tempIndex = blocks.findIndex(
                                                (v: { type: string }) =>
                                                    v.type === "mcq",
                                            );

                                            tempConditions.push({
                                                rules: [
                                                    {
                                                        block: tempIndex,
                                                        option: 0,
                                                        isEqual: true,
                                                        conjunction: "and",
                                                    },
                                                ],
                                                jumpTo: null,
                                            });

                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    "jumpTo",
                                                    "conditions",
                                                ],
                                                tempConditions,
                                            );

                                            setBlock(tmpBlock, idx);
                                        }}
                                    >
                                        <PlusCircleOutlined /> Add rule
                                    </Button>
                                </Form.Item>
                            )}
                            <Form.Item
                                label={
                                    jumpTo?.conditions?.length > 0
                                        ? "In ALL other cases jump to"
                                        : "Always go to"
                                }
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue={
                                        jumpTo?.default >= 0
                                            ? jumpTo?.default
                                            : null
                                    }
                                    onChange={(value) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["data", "jumpTo", "default"],
                                            value,
                                        );
                                        setBlock(tmpBlock, idx);
                                    }}
                                    options={[
                                        {
                                            value: null,
                                            label: `Next Block`,
                                        },
                                        ...renderBlockAsOptions(blocks).filter(
                                            (item: { value: any }) =>
                                                item.value !== idx,
                                        ),
                                    ]}
                                />
                            </Form.Item>
                        </>
                    )}
                </Card>
            </Badge.Ribbon>
        </>
    );
};

export default BlockJumpCard;
