import { gql } from "@apollo/client";export default gql`query BlockQuery($id: Int!) {
    block: worksheet_block_by_pk(id: $id) {
        id
        data
        parent_id
        type
        children(order_by: { order: asc }) {
            id
            data
            type
            order
            tags {
                id
                tag_id
                tag {
                    id
                    name
                    value
                }
            }
        }
        tags {
            id
            tag_id
            tag {
                id
                name
                value
            }
        }
    }
}
`;
