export const KEYMAP = {
    generic_cluster: "dndData:agtlsg12l6pv1z",
    // drag_drop_category: "drag_drop_category",
    // check_type: "globalEvaluationConfig",
    feedback_type: "feedback_type",
    start_audio: "global_feedback_audio_pool",
    start_feedback: "feedback_pool",
    // end_audio: "end_audio",
    has_correctness_stroke: "globalEvaluationConfig",
    // has_highlight: "globalEvaluationConfig",
    // has_fills: "globalEvaluationConfig",
    global_config: "globalEvaluationConfig",
    align_items: "alignItems",
};

export const CLUSTER_KEYS = {
    item_cluster: "Item Cluster",
    tag_cluster: "Tags Cluster",
};

export const COMPUTE_FUNC_INPUT = [];
export const COMPUTE_FUNCTION_MULTIINPUT = [];
