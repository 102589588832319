import renderBlockCrossword from "./renderBlock";
import renderEditorCrossword from "./renderEditor";
import isValidCallbackCrossword from "./isValid";
import CROSSWORD_DEFAULT_VALUE from "./defaultValue";
import renderSettingsCrossword from "./renderSettings";
import renderCardCrossword from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common";

const { CROSSWORD_BLOCK } = BLOCK_TYPES;

const CROSSWORD_BLOCK_CONFIG = {
    [CROSSWORD_BLOCK]: {
        label: "Crossword",
        defaultValue: CROSSWORD_DEFAULT_VALUE,
        renderBlock: renderBlockCrossword,
        renderEditor: renderEditorCrossword,
        renderCard: renderCardCrossword,
        renderSettings: renderSettingsCrossword,
        addCallback,
        isValidCallback: isValidCallbackCrossword,
    },
};

export {
    renderBlockCrossword,
    renderCardCrossword,
    renderEditorCrossword,
    renderSettingsCrossword,
    isValidCallbackCrossword,
    CROSSWORD_DEFAULT_VALUE,
    CROSSWORD_BLOCK_CONFIG,
};
