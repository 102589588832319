import { gql } from "@apollo/client";export default gql`mutation createMeme($object: meme_insert_input = {}) {
    insert_meme_one(
        object: $object
        on_conflict: { constraint: meme_pkey, update_columns: id }
    ) {
        id
        asset_url
        group
        status
        user_id
    }
}
`;
