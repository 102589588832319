import React from "react";
import { compose } from "src/helpers";
import {
    withHookForAggregateTab,
    withHookForTabs,
    withUpdateGroupTabMap,
    withUpdateTab,
} from "src/modules/tab/operations";
import { withStateAndActionsForTab } from "src/modules/tab/resolvers";
import ListView from "./component";

const List: React.FC = (props: any) => {
    return <ListView {...props} />;
};

export default compose(
    withStateAndActionsForTab,
    withHookForTabs,
    withHookForAggregateTab,
    withUpdateTab,
    withUpdateGroupTabMap,
)(List);
