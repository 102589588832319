import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";
import { cloneDeep, pullAt } from "lodash";
import { Fragment, useState, useEffect } from "react";
import { MathRender } from "src/components";
import MathQuillCorrectValues from "./MathQuillCorrectValue";

const CorrectValuesComponent = ({
    correct_values,
    disableWrite,
    // getValidateStatus,
    setCurConfig,
    data,
}: any): any => {
    const addVal = () => {
        let tempVal = cloneDeep(correct_values?.value);
        switch (correct_values?.type) {
            case "STRING":
                tempVal.push("");
                break;
            case "RICH_TEXT":
            case "LATEX":
                tempVal.push([]);
                break;
            default:
                break;
        }
        setCurConfig(tempVal);
    };

    const updateVal = (val: any, index: string | number) => {
        let tempVal = cloneDeep(correct_values?.value);
        tempVal[index] = val;
        setCurConfig(tempVal);
    };

    const delVal = (index: number) => {
        let tempVal = cloneDeep(correct_values?.value);
        pullAt(tempVal, index);
        setCurConfig(tempVal);
    };

    const [inputs, setInputs] = useState<Record<string, any[]>>({});

    useEffect(() => {
        function matchesNextString(
            latex: string,
            index: number,
            match: string,
        ) {
            if (index + match.length >= latex.length) return false;
            for (let i = 0; i < match.length; i++) {
                if (latex[index + i] != match[i]) return false;
            }
            return true;
        }

        function getInputNames(latex: string) {
            latex = latex.replace(/\s/, "");
            const values: string[] = [];
            const searchString = "\\phantom{";
            // console.log("latex", latex);
            for (let i = 0; i < latex.length; i++) {
                if (matchesNextString(latex, i, searchString)) {
                    let j = i + searchString.length;
                    let value = "";
                    const stack = ["{"];
                    do {
                        if (latex[j] == "{") {
                            stack.push("{");
                        } else if (latex[j] == "}") {
                            stack.pop();
                        }
                        value += latex[j];
                        j++;
                    } while (stack.length > 0 && j < latex.length);
                    value = value.substring(0, value.length - 1);
                    if (value.length > 0) {
                        values.push(value);
                    }
                    i = j - 1;
                }
            }
            return values;
        }

        if (
            !data?.props?.default_value?.value ||
            correct_values?.type !== "MATHQUILL_RICH_TEXT"
        ) {
            return;
        }
        const inputNames = getInputNames(data.props.default_value.value);
        const newInputs: Record<string, any[]> = {};
        inputNames.forEach((name, idx) => {
            if (!correct_values?.value || !correct_values?.value[idx])
                newInputs[name] = [""];
            else newInputs[name] = correct_values.value[idx];
        });
        if (JSON.stringify(newInputs) === JSON.stringify(inputs)) return;
        setInputs(newInputs);
        setCurConfig(Object.values(newInputs));
    }, [data]);

    const isMathQuill = correct_values?.type === "MATHQUILL_RICH_TEXT";

    return (
        <Row
            style={{
                width: "100%",
            }}
            gutter={[10, 20]}
        >
            {!isMathQuill && (
                <Col
                    span={24}
                    style={{
                        display: "flex",
                        gap: "30px",
                        alignItems: "center",
                    }}
                >
                    <span>Correct Answers</span>
                    <Button
                        disabled={disableWrite}
                        ghost
                        type="primary"
                        onClick={addVal}
                    >
                        Add New Correct Answer
                    </Button>
                </Col>
            )}
            {correct_values?.value?.map((v: any, key: number) => (
                <Fragment key={key}>
                    <Col span={22}>
                        {(() => {
                            switch (correct_values?.type) {
                                case "STRING":
                                    return (
                                        <Input
                                            disabled={disableWrite}
                                            value={v}
                                            placeholder="Correct Answer"
                                            onChange={(e) =>
                                                updateVal(e.target.value, key)
                                            }
                                        />
                                    );
                                case "LATEX":
                                    return (
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                            }}
                                        >
                                            <Input.TextArea
                                                disabled={disableWrite}
                                                value={v}
                                                placeholder="Correct Answer"
                                                onChange={(e) =>
                                                    updateVal(
                                                        e.target.value,
                                                        key,
                                                    )
                                                }
                                                style={{
                                                    width: "60%",
                                                    flexShrink: 0,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    padding: "10px",
                                                    width: "40%",
                                                    flexShrink: 0,
                                                    border: "1px dashed #aaa",
                                                }}
                                            >
                                                <MathRender
                                                    src={v ? `$${v}$` : ""}
                                                />
                                            </div>
                                        </div>
                                    );
                                case "MATHQUILL_RICH_TEXT":
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "10px",
                                                width: "100%",
                                            }}
                                            key={`latex-${key}-${
                                                Object.keys(inputs)[key]
                                            }`}
                                        >
                                            <div>
                                                Correct answer for{" "}
                                                <b>
                                                    {Object.keys(inputs)[key]}
                                                </b>
                                            </div>
                                            <MathQuillCorrectValues
                                                disableWrite={disableWrite}
                                                inputs={inputs}
                                                inputKey={
                                                    Object.keys(inputs)[key]
                                                }
                                                setCurConfig={setCurConfig}
                                                setInputs={setInputs}
                                            />
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    let tempInputs =
                                                        cloneDeep(inputs);
                                                    tempInputs[
                                                        Object.keys(inputs)[key]
                                                    ].push("");
                                                    setInputs(tempInputs);
                                                    setCurConfig(
                                                        Object.values(
                                                            tempInputs,
                                                        ),
                                                    );
                                                }}
                                                disabled={disableWrite}
                                            >
                                                Add new correct answer for{" "}
                                                {Object.keys(inputs)[key]}
                                            </Button>
                                        </div>
                                    );
                                default:
                                    return <></>;
                            }
                        })()}
                    </Col>

                    {!isMathQuill && (
                        <Col
                            span={2}
                            style={{
                                paddingTop: "4px",
                            }}
                        >
                            <Button
                                disabled={disableWrite}
                                icon={<DeleteOutlined />}
                                type="primary"
                                shape="circle"
                                danger
                                size="small"
                                onClick={() => delVal(key)}
                            />
                        </Col>
                    )}
                </Fragment>
            ))}
        </Row>
    );
};

export default CorrectValuesComponent;
