import { useLazyQuery } from "@apollo/client";
import { compose } from "src/helpers";
import { withUpdateUserOther } from "src/modules/collection/operations";
import { WORKSHEETS_QUERY } from "src/modules/worksheet/graphql";
import { withUpdateGroupWorksheetMap } from "src/modules/worksheet/operations";
import React, { useEffect, useState } from "react";
import ListView from "./component";

const List: React.FC = (props: any) => {
    const { group_id, updateGroupTabMap, disableAddButton } = props;
    const [worksheets, setWorksheets] = useState([]);

    const [
        getWorksheets,
        { loading, error, data = {}, refetch: refetchWorksheets },
    ] = useLazyQuery(
        WORKSHEETS_QUERY,

        {
            variables: { group_id },
            fetchPolicy: "network-only",
        },
    );

    useEffect(() => {
        const asyncFunc = async () => {
            if (group_id) {
                await getWorksheets();
                // console.log("worksheets", data.worksheets);
                // setWorksheets(data.worksheets);
            }
        };
        asyncFunc();
    }, [group_id]);

    useEffect(() => {
        if (data?.worksheets) setWorksheets(data.worksheets);
    }, [data]);

    const extraProps = {
        group_id,
        loading,
        worksheets,
        updateGroupTabMap,
        disableAddButton,
        refetchWorksheets,
    };

    return <ListView {...props} {...extraProps} />;
};

export default compose(withUpdateGroupWorksheetMap, withUpdateUserOther)(List);
// withStateAndActionsForWorksheet,
// withHookForWorksheets,
// withHookForAggregateWorksheet,
