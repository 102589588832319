import { InboxOutlined } from "@ant-design/icons";
import { captureException } from "@sentry/react";
import { message, Upload, Button, Space, Input, Form } from "antd";
import axios from "axios";
import React, { useState } from "react";

const { Dragger } = Upload;

const FileUpload = (props: any) => {
    const { setIsModalOpen, setBlock, jobName } = props;
    const [name, setName] = useState<any>(jobName);
    const [file, setFile] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if (file) {
            setLoading(true);
            const formData = new FormData();
            // formData.append("data", { x: 1 });
            formData.append("file", file);
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v3/personalizedLearning/generateStories`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                setBlock({ jobId: response?.data?.data?.job_id, name });
                setLoading(false);
                setFile(null);
                setIsModalOpen(false);
                message.success("File uploaded successfully.");
            } catch (e) {
                captureException(e)
                setLoading(false);
                message.error("No file uploaded.");
            }
        } else {
            setLoading(false);
            message.error("No file uploaded.");
        }
    };

    return (
        <>
            <Form.Item
                label="Upload zip file"
                style={{
                    marginTop: "20px",
                }}
            >
                <Dragger
                    fileList={file ? [file] : []}
                    customRequest={({ file }) => setFile(file)}
                    name="file"
                    onRemove={() => setFile(null)}
                    multiple={false}
                    disabled={loading}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                        Click or drag file to this area to upload
                    </p>
                </Dragger>
            </Form.Item>
            <Form.Item label="Job Name">
                <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Job Name"
                    disabled={loading}
                />
            </Form.Item>
            <Space>
                <Button
                    ghost
                    type="primary"
                    onClick={() => setIsModalOpen(false)}
                    disabled={loading}
                    loading={loading}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={!file || !name || loading}
                    loading={loading}
                >
                    Submit
                </Button>
            </Space>
        </>
    );
};

export default FileUpload;
