import renderBlockSubjective from "./renderBlock";
import renderEditorSubjective from "./renderEditor";
import isValidCallbackSubjective from "./isValid";
import SUBJECTIVE_DEFAULT_VALUE from "./defaultValue";
import renderSettingsSubjective from "./renderSettings";
import renderCardSubjective from "./renderCard";
import { BLOCK_TYPES, addCallback } from "../../common/index";

const { SUBJECTIVE_BLOCK } = BLOCK_TYPES;

const SUBJECTIVE_BLOCK_CONFIG = {
    [SUBJECTIVE_BLOCK]: {
        label: "Subjective",
        defaultValue: SUBJECTIVE_DEFAULT_VALUE,
        renderBlock: renderBlockSubjective,
        renderEditor: renderEditorSubjective,
        renderCard: renderCardSubjective,
        renderSettings: renderSettingsSubjective,
        addCallback,
        isValidCallback: isValidCallbackSubjective,
    },
};

export {
    renderBlockSubjective,
    renderCardSubjective,
    renderEditorSubjective,
    renderSettingsSubjective,
    isValidCallbackSubjective,
    SUBJECTIVE_DEFAULT_VALUE,
    SUBJECTIVE_BLOCK_CONFIG,
};
