import { gql } from "@apollo/client";export default gql`query student($id: Int!) {
    student: student_by_pk(id: $id) {
        id
        mobile
        class
        streak
        trophies
        points
        attempts
    }
}
`;
