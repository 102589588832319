import { isEditorEmpty } from "../../common";

const isValidCallbackPhone = ({ block }: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: { text },
    } = block;
    return {
        isValid: !isEditorEmpty(text),
        error: <></>,
    };
};

export default isValidCallbackPhone;
