import { DeleteOutlined } from "@ant-design/icons";
import { SlateEditor } from "src/components/plate";
import {
    Form,
    Space,
    Row,
    Col,
    Button,
    message,
    Select,
    Input,
    Checkbox,
    Divider,
} from "antd";
import _, { cloneDeep, set } from "lodash";
import React, { useEffect, useState } from "react";
import { BLOCK_TYPES, isEditorEmpty } from "../../../../common";
import {
    CustomKeyboardEditor,
    KeyboardError,
} from "src/components/CustomKeyboardEditor";
import CustomSelectField from "../../../../common/CustomSelectField";
import { captureException } from "@sentry/react";

const { Option } = Select;

const CellForm = (props: any) => {
    const {
        closeForm,
        curCell,
        curCellRow,
        curCellCol,
        mentionsList,
        hasMentions,
        block,
        setBlock,
        isEditor,
        setIsEditor,
        isNewBlock,
        setIsNewBlock,
        disableWrite,
        worksheet,
        blockId,
        block_tmpId,
        onInputVariableUpdate,
    } = props;

    const input_variables = (worksheet?.other?.input_variables || []).filter(
        (item: { block_id: any }) =>
            item.block_id === blockId || item.block_id === block_tmpId,
    );

    const tmpId = `${curCellRow}_${curCellCol}_text`;

    const [isSubmitting, setSubmitting] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [currentCell, setCurrentCell] = useState(curCell);
    const [inputVar, setInputVar] = useState(
        input_variables?.filter(
            (item: { path: any[] }) =>
                item?.path &&
                item?.path[0] === "answers" &&
                item?.path[1] == curCellRow &&
                item?.path[2] == curCellCol,
        )[0]?.variable_name,
    );

    const [currentEditor, setCurrentEditor] = useState(tmpId);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [tmpId, currentEditor]);

    useEffect(() => {
        if (
            (currentCell?.type === "text" &&
                isEditorEmpty(currentCell?.text || [])) ||
            !currentCell ||
            (currentCell.type === "options" &&
                (currentCell.correct_option < 0 ||
                    !currentCell.options ||
                    currentCell.options.length < 1 ||
                    currentCell.options.some((option: any) =>
                        isEditorEmpty(option),
                    ))) ||
            (currentCell.type === "input" &&
                (currentCell?.custom_keyboard?.length < 1 ||
                    currentCell.correct_answer?.length < 1 ||
                    currentCell.correct_answer?.some(
                        (v) => v === "" || v === null,
                    )))
        ) {
            setDisabled(true);
        } else setDisabled(false);
    }, [currentCell]);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            if (onInputVariableUpdate) {
                const input: {
                    values?: any[];
                    block_id: string;
                    block_tmpId: string;
                } = {
                    block_id: blockId,
                    block_tmpId,
                };

                const tempInpVal = input_variables.filter(
                    (item: { path: any[] }) =>
                        (item?.path && item?.path[0] !== "answers") ||
                        item?.path[1] != curCellRow ||
                        item?.path[2] != curCellCol,
                );

                if (inputVar) {
                    tempInpVal.push({
                        variable_name: inputVar,
                        path: ["answers", `${curCellRow}`, `${curCellCol}`],
                        is_global: true,
                        block_id: blockId || block_tmpId,
                    });
                }

                const variables = new Set();

                for (const obj of tempInpVal) {
                    const { variable_name } = obj;

                    if (variables.has(variable_name)) {
                        message.error("Variable already exists!");
                        setSubmitting(false);
                        return;
                    }

                    variables.add(variable_name);
                }
                input.values = tempInpVal;

                const retVal = await onInputVariableUpdate(input);

                if (!retVal) {
                    setSubmitting(false);
                    return;
                }
            }

            let tmpBlock = cloneDeep(block);

            const tempData = tmpBlock?.data[BLOCK_TYPES.V2_TABLE_BLOCK].data;

            tempData[curCellRow][curCellCol] = currentCell;

            tmpBlock = set(
                tmpBlock,
                ["data", BLOCK_TYPES.V2_TABLE_BLOCK, "data"],
                tempData,
            );

            setBlock(tmpBlock);

            message.success("Successfully Updated!");
            closeForm();
        } catch (e) {
            captureException(e)
            message.success("Could not complete the request!");
        } finally {
            setSubmitting(false);
        }
    };

    const renderOption = (option: any, idx: number) => (
        <Row
            key={idx}
            justify="start"
            style={{
                border: "1px solid #ECECEC",
                borderRadius: "15px",
                marginBottom: "10px",
            }}
        >
            <Col
                span={1}
                style={{
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        padding: "10px 0px",
                    }}
                >
                    <div
                        style={{
                            borderRadius: "50%",
                            width: "24px",
                            height: "24px",
                            justifyContent: "center",
                            alignItems: "center",
                            background:
                                currentCell?.correct_option === idx
                                    ? "#4EDEB2"
                                    : "lightgray",
                            color: "#333333",
                            display: "inline-flex",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        {idx + 1}.
                    </div>
                </div>
            </Col>
            <Col
                span={21}
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div style={{ width: "100%" }}>
                    <SlateEditor
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${tmpId}_option_${idx}`}
                        onChange={(value: any) => {
                            const tmpCell = cloneDeep(currentCell);
                            tmpCell.options[idx] = value;
                            setCurrentCell(tmpCell);
                        }}
                        value={option}
                        isFocused={currentEditor === `${tmpId}_option_${idx}`}
                        setEditor={(id: string) => {
                            setCurrentEditor(id);
                        }}
                        isOption={true}
                    />
                </div>
            </Col>
            {currentEditor === `${tmpId}_option_${idx}` && (
                <Col span={2} style={{ paddingTop: "10px" }}>
                    <Button
                        icon={<DeleteOutlined />}
                        type="primary"
                        shape="circle"
                        danger
                        size="small"
                        onClick={() => {
                            if (currentCell?.options.length == 1) {
                                message.warn(`Need at least 1 option`);
                                return;
                            }

                            const tmpCell = cloneDeep(currentCell);
                            tmpCell?.options.splice(idx, 1);
                            setCurrentCell(tmpCell);
                        }}
                    />
                </Col>
            )}
        </Row>
    );

    return (
        <Form>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item label="Cell Type">
                        <Select
                            defaultValue={currentCell?.type}
                            onChange={(value) => {
                                const tmpCell = cloneDeep(currentCell);
                                tmpCell.type = value;
                                if (
                                    value === "component" &&
                                    !tmpCell?.component?.type
                                ) {
                                    tmpCell.component = {
                                        type: "color_block",
                                        bg_color: {
                                            color1: "#ffffff",
                                            colorCount1: "0",
                                            color2: "#ffffff",
                                            colorCount2: "0",
                                        },
                                    };
                                }
                                setCurrentCell(tmpCell);
                            }}
                            options={[
                                { value: "text", label: "Text" },
                                { value: "input", label: "Input" },
                                { value: "component", label: "Component" },
                                // { value: "options", label: "Options" },
                            ]}
                        />
                    </Form.Item>
                </Col>

                {currentCell?.type === "text" && (
                    <Col span={22}>
                        <div
                            style={{
                                marginBottom: "20px",
                            }}
                        >
                            Cell Text:
                        </div>
                        <SlateEditor
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={tmpId}
                            onChange={(value: any) => {
                                const tmpCell = cloneDeep(currentCell);
                                tmpCell.text = value;
                                setCurrentCell(tmpCell);
                            }}
                            isFocused={currentEditor === tmpId}
                            setEditor={(id: string) => setCurrentEditor(id)}
                            value={currentCell?.text}
                            placeholder={"Type the cell data here..."}
                        />
                    </Col>
                )}

                {currentCell?.type === "options" && (
                    <Col span={24}>
                        <div>
                            {currentCell?.options.map(
                                (option: any, idx: number) =>
                                    renderOption(option, idx),
                            )}
                            <Button
                                type="dashed"
                                onClick={() => {
                                    const tmpCell = cloneDeep(currentCell);
                                    tmpCell?.options.push([]);
                                    setCurrentCell(tmpCell);
                                }}
                                style={{ margin: "0px 24px" }}
                            >
                                Add Option
                            </Button>
                        </div>
                        <br />
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            label={"Correct Option"}
                            validateStatus={
                                currentCell?.correct_option < 0 ? "error" : ""
                            }
                            help={
                                currentCell?.correct_option < 0
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Select
                                defaultValue={
                                    currentCell?.correct_option >= 0
                                        ? currentCell.correct_option
                                        : null
                                }
                                onChange={(value: any) => {
                                    const tmpCell = cloneDeep(currentCell);
                                    tmpCell.correct_option = value;
                                    setCurrentCell(tmpCell);
                                }}
                            >
                                <Option value={null} disabled>
                                    Select Correct Option
                                </Option>
                                {currentCell?.options.map(
                                    (_: any, idx: number) => {
                                        return (
                                            <Option value={idx} key={idx}>
                                                {idx + 1}
                                            </Option>
                                        );
                                    },
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                {currentCell?.type === "input" && (
                    <>
                        <Col span={22}>
                            <div
                                style={{
                                    marginBottom: "20px",
                                }}
                            >
                                Correct answers:
                            </div>
                            {hasMentions && mentionsList?.length > 0 && (
                                <Checkbox
                                    style={{ marginBottom: "20px" }}
                                    disabled={disableWrite}
                                    checked={currentCell?.is_correct_input}
                                    onChange={(e) => {
                                        setCurrentCell({
                                            ...currentCell,
                                            correct_answer: [],
                                            is_correct_input: e.target.checked,
                                        });
                                    }}
                                >
                                    {`Use Input Variables as Correct Answers`}
                                </Checkbox>
                            )}

                            {currentCell?.is_correct_input ? (
                                <Select
                                    disabled={disableWrite}
                                    value={currentCell?.correct_answer || []}
                                    placeholder="Correct Answers"
                                    style={{ width: "100%" }}
                                    onChange={(value: any) => {
                                        setCurrentCell({
                                            ...currentCell,
                                            correct_answer: value,
                                        });
                                    }}
                                    mode={`multiple`}
                                >
                                    {mentionsList.map(
                                        (item: any, idx: number) => {
                                            return (
                                                <Option
                                                    value={`@@${item?.data}@@`}
                                                    key={idx}
                                                >
                                                    {item?.data}
                                                </Option>
                                            );
                                        },
                                    )}
                                </Select>
                            ) : (
                                <>
                                    <Select
                                        disabled={disableWrite}
                                        value={
                                            currentCell?.correct_answer || []
                                        }
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder="Correct Answers"
                                        onChange={(value) => {
                                            setCurrentCell({
                                                ...currentCell,
                                                correct_answer: value,
                                            });
                                        }}
                                        options={[]}
                                        open={false}
                                    />
                                    <KeyboardError
                                        value={currentCell?.correct_answer.reduce(
                                            (acc: string, v: string) =>
                                                `${acc}${v}`,
                                            "",
                                        )}
                                        keyboards={currentCell?.custom_keyboard}
                                    />
                                </>
                            )}

                            <div style={{ margin: "20px 0" }}>
                                <CustomKeyboardEditor
                                    value={currentCell?.custom_keyboard}
                                    onOk={(data: any) => {
                                        let tmpCell = _.cloneDeep(currentCell);

                                        tmpCell = _.set(
                                            tmpCell,
                                            ["custom_keyboard"],
                                            data,
                                        );

                                        setCurrentCell(tmpCell);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col span={24}>
                            {onInputVariableUpdate &&
                                hasMentions &&
                                mentionsList?.length > 0 && (
                                    <Form.Item
                                        label="Variable"
                                        help="Input vairable for saving the data"
                                    >
                                        <Input
                                            disabled={disableWrite}
                                            value={inputVar}
                                            placeholder="Variable"
                                            style={{ width: "100%" }}
                                            onChange={(e: any) => {
                                                setInputVar(e.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                )}
                        </Col>
                    </>
                )}

                {currentCell?.type === "component" && (
                    <>
                        <Col span={24}>
                            <Form.Item label="Component Type">
                                <Select
                                    disabled
                                    defaultValue={currentCell?.component?.type}
                                    onChange={(value) => {
                                        const tmpCell = cloneDeep(currentCell);
                                        tmpCell.component = {
                                            ...(tmpCell.component || {}),
                                            type: value,
                                        };
                                        if (
                                            value === "color_block" &&
                                            !tmpCell?.component?.bg_color
                                        ) {
                                            tmpCell["component"].bg_color = {
                                                color1: "#ffffff",
                                                colorCount1: "0",
                                                color2: "#ffffff",
                                                colorCount2: "0",
                                            };
                                        }
                                        setCurrentCell(tmpCell);
                                    }}
                                    options={[
                                        {
                                            value: "svg",
                                            label: "SVG",
                                        },
                                        {
                                            value: "color_block",
                                            label: "Color Block",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        {currentCell?.component?.type === "svg" && (
                            <Col span={24}>
                                <div
                                    style={{
                                        padding: "10px",
                                        marginBottom: "10px",
                                        border: "1px solid #666",
                                    }}
                                >
                                    <h3>Select Fill Color</h3>
                                    <Form.Item label={"Pick Color"}>
                                        <Input
                                            disabled={disableWrite}
                                            type="color"
                                            style={{ maxWidth: "200px" }}
                                            value={currentCell?.component?.fill}
                                            onChange={(e) => {
                                                const tmpCell =
                                                    cloneDeep(currentCell);
                                                tmpCell["component"].fill =
                                                    e.target.value;
                                                setCurrentCell(tmpCell);
                                            }}
                                            required
                                        />
                                    </Form.Item>
                                    Or
                                    <Form.Item label={"Choose from Variable"}>
                                        <CustomSelectField
                                            disabled={disableWrite}
                                            value={currentCell?.component?.fill}
                                            onChange={(value: any) => {
                                                const tmpCell =
                                                    cloneDeep(currentCell);
                                                tmpCell["component"].fill =
                                                    value;
                                                setCurrentCell(tmpCell);
                                            }}
                                            options={mentionsList.map(
                                                (item: { text: any }) => ({
                                                    value: `@@${item.text}@@`,
                                                    label: item.text,
                                                }),
                                            )}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        )}

                        {currentCell?.component?.type === "color_block" && (
                            <>
                                <Col span={24}>
                                    <div
                                        style={{
                                            padding: "10px",
                                            marginBottom: "10px",
                                            border: "1px solid #666",
                                        }}
                                    >
                                        <h3>Select Fill Color 1</h3>
                                        <Form.Item label={"Pick Color"}>
                                            <Input
                                                disabled={disableWrite}
                                                type="color"
                                                style={{ maxWidth: "200px" }}
                                                value={
                                                    currentCell?.component
                                                        ?.bg_color?.color1
                                                }
                                                onChange={(e) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(
                                                            currentCell,
                                                        );

                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "component",
                                                            "bg_color",
                                                            "color1",
                                                        ],
                                                        e.target.value,
                                                    );
                                                    setCurrentCell(tmpBlock);
                                                }}
                                                required
                                            />
                                        </Form.Item>
                                        Or
                                        <Form.Item
                                            label={"Choose from Variable"}
                                        >
                                            <CustomSelectField
                                                disabled={disableWrite}
                                                value={
                                                    currentCell?.component
                                                        ?.bg_color?.color1
                                                }
                                                onChange={(value: any) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(
                                                            currentCell,
                                                        );

                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "component",
                                                            "bg_color",
                                                            "color1",
                                                        ],
                                                        value,
                                                    );
                                                    setCurrentCell(tmpBlock);
                                                }}
                                                options={mentionsList.map(
                                                    (item: { text: any }) => ({
                                                        value: `@@${item.text}@@`,
                                                        label: item.text,
                                                    }),
                                                )}
                                            />
                                        </Form.Item>
                                        <Divider />
                                        <Form.Item
                                            label="Color 1 count"
                                            help={"Number or input variable"}
                                        >
                                            <CustomSelectField
                                                type="number"
                                                value={
                                                    currentCell?.component
                                                        ?.bg_color?.colorCount1
                                                }
                                                onChange={(value: any) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(
                                                            currentCell,
                                                        );

                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "component",
                                                            "bg_color",
                                                            "colorCount1",
                                                        ],
                                                        value,
                                                    );

                                                    setCurrentCell(tmpBlock);
                                                }}
                                                placeholder={`Color 1 count`}
                                                options={mentionsList.map(
                                                    (item: { text: any }) => ({
                                                        value: item.text,
                                                        label: item.text,
                                                    }),
                                                )}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div
                                        style={{
                                            padding: "10px",
                                            marginBottom: "10px",
                                            border: "1px solid #666",
                                        }}
                                    >
                                        <h3>Select Fill Color 2</h3>
                                        <Form.Item label={"Pick Color"}>
                                            <Input
                                                disabled={disableWrite}
                                                type="color"
                                                style={{ maxWidth: "200px" }}
                                                value={
                                                    currentCell?.component
                                                        ?.bg_color?.color2
                                                }
                                                onChange={(e) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(
                                                            currentCell,
                                                        );

                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "component",
                                                            "bg_color",
                                                            "color2",
                                                        ],
                                                        e.target.value,
                                                    );
                                                    setCurrentCell(tmpBlock);
                                                }}
                                                required
                                            />
                                        </Form.Item>
                                        Or
                                        <Form.Item
                                            label={"Choose from Variable"}
                                        >
                                            <CustomSelectField
                                                disabled={disableWrite}
                                                value={
                                                    currentCell?.component
                                                        ?.bg_color?.color2
                                                }
                                                onChange={(value: any) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(
                                                            currentCell,
                                                        );

                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "component",
                                                            "bg_color",
                                                            "color2",
                                                        ],
                                                        value,
                                                    );
                                                    setCurrentCell(tmpBlock);
                                                }}
                                                options={mentionsList.map(
                                                    (item: { text: any }) => ({
                                                        value: `@@${item.text}@@`,
                                                        label: item.text,
                                                    }),
                                                )}
                                            />
                                        </Form.Item>
                                        <Divider />
                                        <Form.Item
                                            label="Color 2 count"
                                            help={"Number or input variable"}
                                        >
                                            <CustomSelectField
                                                type="number"
                                                value={
                                                    currentCell?.component
                                                        ?.bg_color?.colorCount2
                                                }
                                                onChange={(value: any) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(
                                                            currentCell,
                                                        );

                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "component",
                                                            "bg_color",
                                                            "colorCount2",
                                                        ],
                                                        value,
                                                    );

                                                    setCurrentCell(tmpBlock);
                                                }}
                                                placeholder={`Color 2 count`}
                                                options={mentionsList.map(
                                                    (item: { text: any }) => ({
                                                        value: item.text,
                                                        label: item.text,
                                                    }),
                                                )}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </>
                        )}
                    </>
                )}

                <Col span={24}>
                    <Space style={{ float: "right" }}>
                        <Button type="default" onClick={closeForm}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            loading={isSubmitting}
                            disabled={isDisabled}
                        >
                            Submit
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};

export default CellForm;
