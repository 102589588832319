import { FunctionComponent } from "react";

import {
    getLastStringParamFromUrl,
    useHasuraSubscriptionWithCache,
    useHasuraSubscriptionWithFilter,
} from "src/helpers";

import { SET_QUERY, SETS_QUERY, SET_AGGREGATE_QUERY } from "../graphql";
import { set } from "lodash";

export const withHookForSets = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        let { pagination, where } = props;
        where = set(
            where,
            ["type", "_nin"],
            ["interactive_rapid_quiz", "interactive_quiz"],
        );

        const queryDocumentResult = useHasuraSubscriptionWithCache(SETS_QUERY, {
            variables: {
                ...pagination,
                where,
            },
        });
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "sets", ...props },
        );
        const {
            loading,
            items,
            error,
            loadItemsData,
            fetchMore,
            refetch,
            subscribeToMore,
            updateQuery,
        } = graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    sets: items,
                    subscribeToMore,
                    updateQuery,
                    loadSetsData: loadItemsData,
                    refetchSets: refetch,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForAggregateSet = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const { where } = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(
            SET_AGGREGATE_QUERY,
            {
                variables: { where },
            },
        );
        const graphqlData = useHasuraSubscriptionWithFilter(
            queryDocumentResult,
            { queryName: "setAggregate", ...props },
        );
        const { loading, items, error, subscribeToMore, updateQuery } =
            graphqlData;

        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loading,
                    setAggregate: items,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};

export const withHookForSet = (Component: FunctionComponent) => {
    const WithComponent = (props) => {
        const {} = props;
        const queryDocumentResult = useHasuraSubscriptionWithCache(SET_QUERY, {
            variables: {
                id: getLastStringParamFromUrl(),
            },
        });

        const { loading, error, data, subscribeToMore, updateQuery } =
            queryDocumentResult;
        if (error) throw new Error(error.message);
        return (
            <Component
                {...{
                    loadingSet: loading,
                    set: data?.set,
                    subscribeToMore,
                    updateQuery,
                }}
                {...props}
            />
        );
    };
    return WithComponent;
};
