import React, { useState } from "react";
import { Link } from "src/components";
import { Button, Col, Divider, message, Row, Space, Spin } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";

import DeleteView from "src/modules/set/components/DeleteView";
import {
    EditIcon,
    ViewIcon,
    CopyIcon,
    RenderTable,
    Layout,
    MetaTag,
} from "src/components";
import { Can } from "src/services/casl";

import { FilterComponent } from "src/modules/set/components";

const ListView: React.FC = (props: any) => {
    const { loading, sets, setAggregate, loadSetsData } = props;

    const columns = [
        {
            title: "Id",
            key: "id",
            render: (text, record) => record?.id,
            fixed: "left",
        },
        {
            title: "Title",
            key: "title",
            render: (text, record) => record?.title,
            fixed: "left",
            width: 200,
        },
        {
            title: "Subject",
            key: "subject",
            render: (text, record) => record?.subject,
        },
        {
            title: "Class",
            key: "class",
            render: (text, record) => record?.class,
        },
        {
            title: "Chapter",
            key: "chapter",
            render: (text, record) => record?.chapter,
        },
        {
            title: "Topic",
            key: "topic",
            render: (text, record) => record?.topic,
        },
        {
            title: "Type",
            key: "type",
            render: (text, record) => record?.type,
        },
        {
            title: "Created By",
            key: "created_by",
            render: (text, record) => record?.creator?.email,
        },
        {
            title: "Status",
            key: "status",
            render: (text, record) => record?.status,
        },
        {
            title: "Action",
            key: "action",
            align: "right",
            fixed: "right",
            width: 200,
            render: (text, record) => (
                <Space size="middle">
                    <CopyIcon
                        isButton={true}
                        onClick={() => {
                            message.info(`Copied set link to clipboard!`);
                            navigator.clipboard.writeText(
                                `${process.env.REACT_APP_CLIENT_BASE}/set/${record.id}`,
                            );
                        }}
                    />
                    <Divider />
                    <Link href={`/collection/set/${record?.id}`}>
                        <ViewIcon isButton={true} />
                    </Link>
                    <Divider />

                    <Can I="edit" a={"set"} passThrough>
                        {(allowed) => (
                            <Link href={`/set/update/${record?.id}`}>
                                <EditIcon isButton={true} disabled={!allowed} />
                            </Link>
                        )}
                    </Can>

                    <Divider />
                    <Can I="delete" a={"set"} passThrough>
                        {(allowed) => (
                            <DeleteView
                                id={record?.id}
                                isButton={true}
                                disabled={!allowed}
                                record={record}
                            />
                        )}
                    </Can>
                </Space>
            ),
        },
    ];

    return (
        <Layout titles={[{ name: "Sets", link: "/set" }]}>
            <MetaTag title="Sets" />
            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Sets List</h1>
                </Col>
                <Col>
                    <Can I="create" a={"set"} passThrough>
                        {(allowed) => (
                            <Link href={"/set/create"}>
                                <Button
                                    type="primary"
                                    icon={<AppstoreAddOutlined />}
                                    disabled={!allowed}
                                >
                                    Add
                                </Button>
                            </Link>
                        )}
                    </Can>
                </Col>
            </Row>
            <hr />

            <FilterComponent isSet={false} {...props} />
            <hr />

            <Spin spinning={loading}>
                {Boolean(sets?.length) && (
                    <RenderTable
                        loading={loading}
                        columns={columns}
                        data={sets || []}
                        loadData={loadSetsData}
                        aggregate={setAggregate?.aggregate?.count}
                    />
                )}
            </Spin>
        </Layout>
    );
};

export default ListView;
