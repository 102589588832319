import React, { useEffect, useState } from "react";
import Settings from "./Settings";
import {
    Button,
    Card,
    Col,
    Collapse,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Switch,
    message,
} from "antd";
import { CopyIcon } from "src/components";
import SideContainer from "./SideContainer";
import TagClusterContainer from "./TagClusterContainer";
import { cloneDeep, set } from "lodash";

const { Panel } = Collapse;

const BalanceGameEditor = (props) => {
    const {
        block,
        disableWrite,
        setBlock,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
    } = props;

    const {
        data: { scale_balance_settings },
    } = block;
    const { containers, dragItems } = scale_balance_settings;
    const [tempConfig, setTempConfig] = useState("");

    // useEffect(() => {
    //     if (!block.config || !block.functions) {
    //         setBlock({
    //             ...block,
    //             config: block.config || block.data.config,
    //             functions: block.data.functions,
    //         });
    //     }
    // }, [block]);

    // if (!block.config || !block.functions) {
    //     return null;
    // }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "10px",
            }}
        >
            <Card
                style={{
                    minWidth: "600px",
                    // maxWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
            >
                <Row>
                    <Settings {...props} />
                </Row>
                <Row>
                    <Col span={24}>
                        <Collapse>
                            <Panel
                                header={
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "20px",
                                        }}
                                    >
                                        <span>Left side</span>
                                        <Button
                                            disabled={disableWrite}
                                            shape="circle"
                                            type={"primary"}
                                            size="small"
                                            icon={<CopyIcon />}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                message.info(
                                                    `Copied left side to clipboard!`,
                                                );
                                                navigator.clipboard.writeText(
                                                    JSON.stringify({
                                                        type: "side",
                                                        data: containers[0],
                                                    }),
                                                );
                                            }}
                                        />
                                    </div>
                                }
                                key="1"
                            >
                                <SideContainer sideIndex={0} {...props} />
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={24}>
                        <Collapse>
                            <Panel
                                header={
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "20px",
                                        }}
                                    >
                                        <span>Right side</span>
                                        <Button
                                            disabled={disableWrite}
                                            shape="circle"
                                            type={"primary"}
                                            size="small"
                                            icon={<CopyIcon />}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                message.info(
                                                    `Copied Right side to clipboard!`,
                                                );
                                                navigator.clipboard.writeText(
                                                    JSON.stringify({
                                                        type: "side",
                                                        data: containers[1],
                                                    }),
                                                );
                                            }}
                                        />
                                    </div>
                                }
                                key="1"
                            >
                                <SideContainer sideIndex={1} {...props} />
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={24}>
                        <Collapse>
                            <Panel
                                header={
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "20px",
                                        }}
                                    >
                                        <span>Tag cluster</span>
                                        <Button
                                            disabled={disableWrite}
                                            shape="circle"
                                            type={"primary"}
                                            size="small"
                                            icon={<CopyIcon />}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                message.info(
                                                    `Copied tag cluster to clipboard!`,
                                                );
                                                navigator.clipboard.writeText(
                                                    JSON.stringify({
                                                        type: "dragItems",
                                                        data: dragItems,
                                                    }),
                                                );
                                            }}
                                        />
                                    </div>
                                }
                                key="2"
                            >
                                <TagClusterContainer {...props} />
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={24}>
                        <Divider />
                        {!disableWrite && (
                            <Form.Item label={"Paste Scale config here"}>
                                <Input.TextArea
                                    disabled={disableWrite}
                                    value={tempConfig}
                                    placeholder="Paste Config here"
                                    onChange={(e) =>
                                        setTempConfig(e.target.value)
                                    }
                                />
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    onClick={() => {
                                        try {
                                            const parsedJson: any =
                                                JSON.parse(tempConfig);
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    "scale_balance_settings",
                                                ],
                                                parsedJson,
                                            );
                                            setBlock(tmpBlock);
                                            setTempConfig("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            //  captureException(e)
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Update scale config
                                </Button>
                            </Form.Item>
                        )}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default BalanceGameEditor;
