import { gql } from "@apollo/client";

export default gql`
    mutation update_worksheet_block_by_pk(
        $object: worksheet_block_set_input!
        $id: Int!
    ) {
        update_worksheet_block_by_pk(pk_columns: { id: $id }, _set: $object) {
            id
        }
    }
`;
