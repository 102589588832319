import {
    Modal,
    Form,
    message,
    Input,
    Button,
    Col,
    Space,
    Row,
    Divider,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { AudioGenerateComponent } from "src/modules/worksheet/components/WorksheetEditor/components/Modals/AudioGenerateComponent";
import { CopyIcon } from "src/components";
import {
    SortableHandle,
    SortableContainer,
    SortableElement,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { captureException } from "@sentry/react";
import axios from "axios";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: "pointer",
        }}
    />
));

const SortableContainerCustom = SortableContainer(({ children }: any) => {
    return <div>{children}</div>;
});

const SortableItemCustom = SortableElement((props: any) => {
    const { children, sid } = props;
    return (
        <div style={{ zIndex: 1000 }} key={`${sid}`}>
            {children}
        </div>
    );
});

export const AudioPoolModal = ({
    isModalOpen,
    setIsModalOpen,
    name,
    audioPool,
    disableWrite,
    schema,
    onSave,
}: any) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [curConfig, setCurConfig] = useState(
        (
            (typeof audioPool === "string"
                ? JSON.parse(audioPool)
                : audioPool) || []
        ).map((v: any) => ({ ...v, uniqueId: _.uniqueId() })),
    );
    const [loading, setLoading] = useState(false);
    const [tempConfig, setTempConfig] = useState("");
    const [isJsonModalOpen, setIsJsonModalOpen] = useState(false);
    const [isDurationModalOpen, setIsDurationModalOpen] = useState(false);

    const handleSubmit = async () => {
        setSubmitting(true);
        for (let i = 0; i < curConfig.length; i++) {
            let tempComp = curConfig[i];
            let objKeys = Object.keys(tempComp);
            if (objKeys.some((v) => !String(tempComp[v] ?? "")?.trim())) {
                message.error("Some fields are empty!");
                setSubmitting(false);
                return;
            }
        }
        try {
            let v = curConfig.map((v: { uniqueId: any }) => {
                delete v.uniqueId;
                return v;
            });
            await onSave(v);
            message.success("Successfully Updated!");
        } catch (e) {
            captureException(e);
            console.log(e);
            message.error("Could not complete the request!");
        }
        setSubmitting(false);
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (loading) {
            setTimeout(() => setLoading(false), 50);
        }
    }, [loading]);

    useEffect(() => {
        if (isModalOpen) {
            setCurConfig(
                (
                    (typeof audioPool === "string"
                        ? JSON.parse(audioPool)
                        : audioPool) || []
                ).map((v: any) => ({ ...v, uniqueId: _.uniqueId() })),
            );
        }
    }, [isModalOpen]);

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(curConfig);
        tmpBlock = arrayMoveImmutable(tmpBlock, oldIndex, newIndex).filter(
            (el) => !!el,
        );
        setCurConfig(tmpBlock);
    };

    return (
        <Modal
            title={`Audio Pool for ${name}`}
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
            width={720}
            maskClosable={false}
            style={{ top: 10 }}
            destroyOnClose
        >
            <Row
                style={{
                    maxHeight: "70vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollbarWidth: "thin",
                }}
            >
                <Col span={24}>
                    {schema && !disableWrite && (
                        <div
                            style={{
                                display: "flex",
                                gap: "1rem",
                                flexWrap: "wrap",
                            }}
                        >
                            <Button
                                ghost
                                type="primary"
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(curConfig);
                                    tmpBlock.push({
                                        ..._.cloneDeep(schema),
                                        uniqueId: _.uniqueId(),
                                    });
                                    setCurConfig(tmpBlock);
                                }}
                            >
                                Add New Audio
                            </Button>
                            <Button
                                ghost
                                type="primary"
                                onClick={() => {
                                    setIsJsonModalOpen(true);
                                }}
                            >
                                Add Audio with JSON
                            </Button>
                            <Button
                                ghost
                                type="primary"
                                onClick={() => {
                                    const obj: any = {};

                                    curConfig.forEach((audio: any) => {
                                        delete audio.uniqueId;

                                        const url =
                                            audio?.audio?.audio_texts?.[0]
                                                ?.audio_urls?.[0]?.url;
                                        const name = audio?.x ?? audio.name;

                                        obj[name] = url;
                                    });

                                    navigator.clipboard.writeText(
                                        JSON.stringify(obj),
                                    );
                                }}
                            >
                                copy url json
                            </Button>
                            <Button
                                ghost
                                type="primary"
                                onClick={() => {
                                    const obj: any = {};

                                    curConfig.forEach((audio: any) => {
                                        delete audio.uniqueId;

                                        const url =
                                            audio?.audio?.audio_texts?.[0]
                                                ?.text;
                                        const name = audio?.x ?? audio.name;

                                        obj[name] = url;
                                    });

                                    navigator.clipboard.writeText(
                                        JSON.stringify(obj),
                                    );
                                }}
                            >
                                copy text json
                            </Button>
                            <Button
                                ghost
                                type="primary"
                                onClick={() => {
                                    setIsDurationModalOpen(true);
                                }}
                            >
                                Get Duration
                            </Button>
                        </div>
                    )}

                    <Modal
                        title={`Add Audio with JSON`}
                        open={isJsonModalOpen}
                        onCancel={() => setIsJsonModalOpen(false)}
                        footer={null}
                        width={720}
                        maskClosable={false}
                        style={{ top: 10 }}
                        destroyOnClose
                    >
                        <div>
                            <form
                                style={{
                                    display: "grid",
                                    gap: "1rem",
                                    height: "min-content",
                                }}
                                onSubmit={(event: any) => {
                                    event.preventDefault(); // Prevent the form from refreshing the page
                                    const jsonInput =
                                        event.target.elements.jsonInput.value.trim();

                                    try {
                                        const parsedJSON =
                                            JSON.parse(jsonInput);

                                        let tmpBlock = _.cloneDeep(curConfig);

                                        if (
                                            typeof parsedJSON === "object" &&
                                            parsedJSON !== null
                                        ) {
                                            for (const [
                                                key,
                                                value,
                                            ] of Object.entries(parsedJSON)) {
                                                tmpBlock.push({
                                                    ..._.cloneDeep(schema),
                                                    ...(schema?.hasOwnProperty?.(
                                                        "x",
                                                    )
                                                        ? {
                                                              x: `${key}`,
                                                          }
                                                        : {
                                                              name: `${key}`,
                                                          }),
                                                    audio: {
                                                        display_text: "",
                                                        audio_texts: [
                                                            {
                                                                text: `${value}`,
                                                                language:
                                                                    "Hindi",
                                                                artist: {
                                                                    name: "Madhu_v2",
                                                                    other: {
                                                                        voice_id:
                                                                            "XeXqtVbGZphseIE5t8Ga",
                                                                    },
                                                                },
                                                                audio_urls: [],
                                                            },
                                                        ],
                                                        other: {},
                                                    },
                                                    uniqueId: _.uniqueId(),
                                                });
                                            }
                                        }

                                        setCurConfig(tmpBlock);
                                        setIsJsonModalOpen(false);
                                        message.info("Updated!");
                                    } catch (error) {
                                        message.error("Error in JSON!");
                                    }
                                }}
                            >
                                <label htmlFor="jsonInput">Enter JSON:</label>
                                <textarea
                                    id="jsonInput"
                                    name="jsonInput"
                                    rows={50}
                                    cols={50}
                                    placeholder="Enter valid JSON here..."
                                    style={{
                                        height: "300px",
                                    }}
                                />
                                <button
                                    type="submit"
                                    style={{ width: "fit-content" }}
                                >
                                    Add audios
                                </button>
                            </form>
                        </div>
                    </Modal>

                    <Modal
                        title={`Get duration`}
                        open={isDurationModalOpen}
                        onCancel={() => setIsDurationModalOpen(false)}
                        footer={null}
                        width={720}
                        maskClosable={false}
                        style={{ top: 10 }}
                        destroyOnClose
                    >
                        <div>
                            <form
                                style={{
                                    display: "grid",
                                    gap: "1rem",
                                    height: "min-content",
                                }}
                                onSubmit={async (event: any) => {
                                    event.preventDefault(); // Prevent the form from refreshing the page

                                    const hide = message.loading(
                                        "Getting duration...",
                                        20,
                                    );
                                    try {
                                        const jsonInput =
                                            event.target.elements.jsonInput.value.trim();
                                        const parsedJSON =
                                            JSON.parse(jsonInput);

                                        const { data } = await axios.post(
                                            `${process.env.REACT_APP_HOMEWORK_SERVER_QA_API_ENDPOINT}/v3/personalizedLearning/getAudioDuration`,
                                            {
                                                data: {
                                                    audios: parsedJSON,
                                                },
                                            },
                                        );

                                        console.log("here in data", data);
                                        hide();
                                        setIsDurationModalOpen(false);
                                        navigator.clipboard.writeText(
                                            JSON.stringify(data?.data ?? {}),
                                        );
                                        message.info("Copied to clipboard!");
                                    } catch (error) {
                                        message.error("Error in JSON!");
                                        hide();
                                    }
                                }}
                            >
                                <label htmlFor="jsonInput">Enter JSON:</label>
                                <textarea
                                    id="jsonInput"
                                    name="jsonInput"
                                    rows={50}
                                    cols={50}
                                    placeholder="Enter valid JSON here..."
                                    style={{
                                        height: "300px",
                                    }}
                                />
                                <button
                                    type="submit"
                                    style={{ width: "fit-content" }}
                                >
                                    get durations
                                </button>
                            </form>
                        </div>
                    </Modal>

                    <Divider />
                </Col>
                <Col span={24}>
                    {!loading && (
                        <SortableContainerCustom
                            onSortEnd={onSortEnd}
                            useDragHandle
                        >
                            {curConfig?.map((audio: any, audioIdx: number) => {
                                const audioName =
                                    name +
                                    "_" +
                                    Object.keys(audio)
                                        .filter(
                                            (key) =>
                                                !["audio", "uniqueId"].includes(
                                                    key,
                                                ),
                                        )
                                        .map((key) =>
                                            typeof audio[key] === "object"
                                                ? JSON.stringify(audio[key])
                                                : audio[key],
                                        )
                                        .join("_");
                                return (
                                    <SortableItemCustom
                                        disabled={disableWrite}
                                        index={audioIdx}
                                        sid={audio?.uniqueId}
                                        key={audio?.uniqueId}
                                    >
                                        <div
                                            key={audio?.uniqueId}
                                            style={{
                                                border: "1px dotted black",
                                                padding: "8px",
                                                margin: "8px 0",
                                            }}
                                        >
                                            <Space
                                                style={{
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <DragHandle />
                                                <h3>Name - {audioName}</h3>
                                                <Button
                                                    disabled={disableWrite}
                                                    icon={<DeleteOutlined />}
                                                    type="primary"
                                                    shape="circle"
                                                    danger
                                                    size="small"
                                                    onClick={() => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                curConfig,
                                                            );
                                                        _.pullAt(
                                                            tmpBlock,
                                                            audioIdx,
                                                        );
                                                        setCurConfig(tmpBlock);
                                                    }}
                                                />
                                                <Button
                                                    disabled={disableWrite}
                                                    icon={<CopyIcon />}
                                                    type="primary"
                                                    shape="circle"
                                                    size="small"
                                                    onClick={() => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                curConfig,
                                                            );
                                                        tmpBlock.splice(
                                                            audioIdx,
                                                            0,
                                                            {
                                                                ..._.cloneDeep(
                                                                    audio,
                                                                ),
                                                                uniqueId:
                                                                    _.uniqueId(),
                                                            },
                                                        );
                                                        setCurConfig(tmpBlock);
                                                        message.info(
                                                            `Duplicated!`,
                                                        );
                                                    }}
                                                />
                                                <Button
                                                    disabled={disableWrite}
                                                    type="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        message.info(
                                                            `Copied to clipboard!`,
                                                        );
                                                        navigator.clipboard.writeText(
                                                            JSON.stringify([
                                                                _.omit(audio, [
                                                                    "uniqueId",
                                                                ]),
                                                            ]),
                                                        );
                                                    }}
                                                >
                                                    Copy to clipboard
                                                </Button>
                                            </Space>
                                            {Object.keys(audio)
                                                .filter(
                                                    (key) =>
                                                        ![
                                                            "audio",
                                                            "uniqueId",
                                                        ].includes(key),
                                                )
                                                .map((key, i) => (
                                                    <Form.Item
                                                        key={i}
                                                        label={key}
                                                        validateStatus={
                                                            !audio[key]
                                                                ? "error"
                                                                : ""
                                                        }
                                                        help={
                                                            !audio[key]
                                                                ? "Required Field"
                                                                : ""
                                                        }
                                                    >
                                                        <Input
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            value={audio[key]}
                                                            placeholder="Type here..."
                                                            style={{
                                                                width: "50%",
                                                            }}
                                                            onChange={(e) => {
                                                                let value =
                                                                    e.target
                                                                        .value;
                                                                let tmpBlock =
                                                                    _.cloneDeep(
                                                                        curConfig,
                                                                    );
                                                                _.set(
                                                                    tmpBlock,
                                                                    [
                                                                        audioIdx,
                                                                        key,
                                                                    ],
                                                                    value,
                                                                );
                                                                setCurConfig(
                                                                    tmpBlock,
                                                                );
                                                            }}
                                                        />
                                                    </Form.Item>
                                                ))}
                                            {/* <Form.Item label={"audio"}> */}
                                            <AudioGenerateComponent
                                                name={audioName}
                                                disableWrite={false}
                                                audioData={audio.audio}
                                                onSave={(value: any) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(curConfig);
                                                    _.set(
                                                        tmpBlock,
                                                        [audioIdx, "audio"],
                                                        value,
                                                    );
                                                    setCurConfig(tmpBlock);
                                                }}
                                                isAudioPool={true}
                                                index={audioIdx}
                                            />
                                            {/* </Form.Item> */}
                                        </div>
                                    </SortableItemCustom>
                                );
                            })}
                        </SortableContainerCustom>
                    )}
                </Col>
                {curConfig?.length > 0 && schema && !disableWrite && (
                    <Col span={24}>
                        <Button
                            ghost
                            type="primary"
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(curConfig);
                                tmpBlock.push({
                                    ..._.cloneDeep(schema),
                                    uniqueId: _.uniqueId(),
                                });
                                setCurConfig(tmpBlock);
                            }}
                        >
                            Add New Audio
                        </Button>
                        <Divider />
                    </Col>
                )}
                <Col span={24}>
                    <Form.Item label={"Paste Audio Config here"}>
                        <Input.TextArea
                            disabled={disableWrite}
                            value={tempConfig}
                            placeholder="Paste Config here"
                            onChange={(e) => setTempConfig(e.target.value)}
                        />
                        <Space>
                            <Button
                                disabled={disableWrite}
                                style={{ marginTop: "10px" }}
                                type="primary"
                                onClick={() => {
                                    try {
                                        const parsedJson =
                                            JSON.parse(tempConfig);
                                        if (
                                            !Array.isArray(parsedJson) ||
                                            parsedJson?.some((v) => !v?.audio)
                                        ) {
                                            message.error("Error in JSON!");
                                            return;
                                        }
                                        setLoading(true);
                                        setCurConfig(
                                            parsedJson.map((v) => ({
                                                ...v,
                                                uniqueId: _.uniqueId(),
                                            })),
                                        );
                                        setTempConfig("");
                                        message.info("Updated!");
                                    } catch (e) {
                                        // captureException(e)
                                        message.error("Error in JSON!");
                                    }
                                }}
                            >
                                Replace
                            </Button>
                            <Button
                                disabled={disableWrite}
                                style={{ marginTop: "10px" }}
                                type="primary"
                                onClick={() => {
                                    try {
                                        const parsedJson =
                                            JSON.parse(tempConfig);
                                        if (
                                            !Array.isArray(parsedJson) ||
                                            parsedJson?.some((v) => !v?.audio)
                                        ) {
                                            message.error("Error in JSON!");
                                            return;
                                        }
                                        setCurConfig([
                                            ...curConfig,
                                            ...parsedJson.map((v) => ({
                                                ...v,
                                                uniqueId: _.uniqueId(),
                                            })),
                                        ]);
                                        setTempConfig("");
                                        message.info("Updated!");
                                    } catch (e) {
                                        // captureException(e);
                                        message.error("Error in JSON!");
                                    }
                                }}
                            >
                                Append
                            </Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
            <div
                style={{
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {curConfig?.length > 0 ? (
                    <Form.Item label={"Copy config to Clipboard"}>
                        <Space>
                            <Button
                                size="small"
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied audio config to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(
                                            curConfig.map(
                                                (v: { uniqueId: any }) => {
                                                    delete v.uniqueId;
                                                    return v;
                                                },
                                            ),
                                        ),
                                    );
                                }}
                            >
                                as Audio
                            </Button>
                            <Button
                                size="small"
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied audio config to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(
                                            curConfig.map(
                                                (audio: {
                                                    [x: string]: any;
                                                }) => {
                                                    delete audio.uniqueId;

                                                    const audioName =
                                                        name +
                                                        "_" +
                                                        Object.keys(audio)
                                                            .filter(
                                                                (key) =>
                                                                    ![
                                                                        "audio",
                                                                        "uniqueId",
                                                                    ].includes(
                                                                        key,
                                                                    ),
                                                            )
                                                            .map((key) =>
                                                                typeof audio[
                                                                    key
                                                                ] === "object"
                                                                    ? JSON.stringify(
                                                                          audio[
                                                                              key
                                                                          ],
                                                                      )
                                                                    : audio[
                                                                          key
                                                                      ],
                                                            )
                                                            .join("_");
                                                    return {
                                                        name: audioName,
                                                        text: [],
                                                        duration: 5,
                                                    };
                                                },
                                            ),
                                        ),
                                    );
                                }}
                            >
                                as Feedback
                            </Button>
                            <Button
                                size="small"
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied audio config to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(
                                            curConfig.map(
                                                (audio: {
                                                    [x: string]: any;
                                                }) => {
                                                    delete audio.uniqueId;

                                                    const audioName =
                                                        name +
                                                        "_" +
                                                        Object.keys(audio)
                                                            .filter(
                                                                (key) =>
                                                                    ![
                                                                        "audio",
                                                                        "uniqueId",
                                                                    ].includes(
                                                                        key,
                                                                    ),
                                                            )
                                                            .map((key) =>
                                                                typeof audio[
                                                                    key
                                                                ] === "object"
                                                                    ? JSON.stringify(
                                                                          audio[
                                                                              key
                                                                          ],
                                                                      )
                                                                    : audio[
                                                                          key
                                                                      ],
                                                            )
                                                            .join("_");
                                                    return {
                                                        name: audioName,
                                                        text: [
                                                            {
                                                                type: "p",
                                                                children: [
                                                                    {
                                                                        text:
                                                                            audio
                                                                                ?.audio
                                                                                ?.audio_texts?.[0]
                                                                                ?.text ||
                                                                            "",
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                        duration: 5,
                                                    };
                                                },
                                            ),
                                        ),
                                    );
                                }}
                            >
                                as Feedback with text
                            </Button>
                            <Button
                                size="small"
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied audio config to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(
                                            curConfig.map(
                                                (audio: {
                                                    [x: string]: any;
                                                }) => {
                                                    delete audio.uniqueId;

                                                    const audioName =
                                                        name +
                                                        "_" +
                                                        Object.keys(audio)
                                                            .filter(
                                                                (key) =>
                                                                    ![
                                                                        "audio",
                                                                        "uniqueId",
                                                                    ].includes(
                                                                        key,
                                                                    ),
                                                            )
                                                            .map((key) =>
                                                                typeof audio[
                                                                    key
                                                                ] === "object"
                                                                    ? JSON.stringify(
                                                                          audio[
                                                                              key
                                                                          ],
                                                                      )
                                                                    : audio[
                                                                          key
                                                                      ],
                                                            )
                                                            .join("_");
                                                    return {
                                                        x: audioName,
                                                        json: "{}",
                                                        alignment: "RIGHT",
                                                    };
                                                },
                                            ),
                                        ),
                                    );
                                }}
                            >
                                as Sticker
                            </Button>
                        </Space>
                    </Form.Item>
                ) : (
                    <div />
                )}
                <Space>
                    <Button
                        type="default"
                        onClick={() => setIsModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={isSubmitting}
                        disabled={disableWrite}
                    >
                        Submit
                    </Button>
                </Space>
            </div>
        </Modal>
    );
};
