import {
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Switch,
    message,
} from "antd";
import _ from "lodash";
import { RenderUpload } from "src/components";
import { useEffect, useState } from "react";
import { DEFAULT_PATH, STUDENT_TYPES } from "../defaultValue";
import { v4 as uuid } from "uuid";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import {
    CheckOutlined,
    CloseOutlined,
    MenuOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { Actions } from "../../../../WorksheetEditor/components/Editor/BlocksColumn/Actions";
import { arrayMoveImmutable } from "array-move";
import PathEditor from "./PathEditor";
import { checkPathValid } from "../isValid";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";

const ProgressionEditor = (props: any) => {
    const { block, setBlock, currentBlock, blocks, disableWrite, previewData } =
        props;
    const {
        data: {
            title,
            type,
            banner,
            card_banner,
            student_type = [],
            paths,
            has_solution,
        },
    } = block;

    const [currentSubBlock, setCurrentSubBlock] = useState(
        paths?.length > 0 ? 0 : -1,
    );

    const updateCurrentBlock = (val: number) => {
        setCurrentSubBlock(val);
    };

    const [loading, setLoad] = useState(false);

    useEffect(() => {
        setLoad(true);
        setTimeout(() => setLoad(false), 50);
    }, [currentSubBlock]);

    useEffect(() => {
        updateCurrentBlock(paths?.length > 0 ? 0 : -1);
    }, [currentBlock]);

    const DragHandle = SortableHandle(() => <MenuOutlined />);

    const SortableItem = SortableElement(({ value, child }) => {
        const actionsProps = {
            deleteBlock: () => {
                updateCurrentBlock(currentSubBlock - 1);
                let tmpBlock = _.cloneDeep(block);
                const tmpOptions = [...paths];
                _.pullAt(tmpOptions, currentSubBlock);
                tmpBlock = _.set(tmpBlock, ["data", "paths"], tmpOptions);

                setBlock(tmpBlock);
            },
            idx: currentSubBlock,
            addBlockCustom: (type: string, index: number, newBlock: any) => {
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = [...paths];
                tmpChildren.splice(index, 0, newBlock);
                tmpBlock = _.set(tmpBlock, ["data", "paths"], tmpChildren);
                setBlock(tmpBlock);
                setCurrentSubBlock(index);
            },
            block: child,
            // for progression stage
            updateId: true,
        };

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                }}
            >
                <Badge.Ribbon
                    color={"cyan"}
                    text={value + 1}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            background:
                                currentSubBlock === value ? "#F1F1F1" : "",
                            borderBottom: !checkPathValid(child)
                                ? "3px solid red"
                                : currentSubBlock === value
                                ? "3px solid indigo"
                                : "",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            minWidth: "90px",
                        }}
                        bodyStyle={{
                            background: "transparent",
                        }}
                        onClick={() => {
                            updateCurrentBlock(value);
                        }}
                    >
                        {`Path ${value + 1}`}

                        {currentSubBlock === value && !disableWrite && (
                            <div
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    padding: "2px",
                                }}
                            >
                                <DragHandle />
                            </div>
                        )}
                    </Card>
                </Badge.Ribbon>

                {currentSubBlock === value && !disableWrite && (
                    <Actions {...actionsProps} />
                )}
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={index}
                        child={child}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            paths,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(tmpBlock, ["data", "paths"], tmpChildren);
        setBlock(tmpBlock);
        updateCurrentBlock(newIndex);
    };

    const pathProps = {
        ...props,
        block: paths[currentSubBlock],
        setBlock: (subBlock: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(
                tmpBlock,
                ["data", "paths", currentSubBlock],
                subBlock,
            );

            const hasSolution = subBlock?.has_solution;
            if (!hasSolution) {
                tmpBlock = _.set(tmpBlock, ["data", "has_solution"], false);
            }
            setBlock(tmpBlock);
        },
        currentBlock: currentSubBlock,
        previewData: { ...previewData, progression_activity_id: block?.id },
    };

    return (
        <div>
            <h2
                style={{
                    fontWeight: "bold",
                    marginBottom: "20px",
                }}
            >
                Card Details
            </h2>
            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <Form.Item
                        label="Title"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        validateStatus={!title ? "error" : ""}
                        help={!title ? "Field cannot be empty" : ""}
                    >
                        <Input
                            value={title}
                            disabled={disableWrite}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "title"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label="Group Type"
                        rules={[{ required: true }]}
                        validateStatus={!type ? "error" : ""}
                        help={!type ? "Field cannot be empty" : ""}
                    >
                        <Select
                            disabled={disableWrite}
                            defaultValue={type}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "type"],
                                    value,
                                );

                                setBlock(tmpBlock);
                            }}
                            options={[
                                { value: "activity", label: "Activity" },
                                { value: "level", label: "Level" },
                                {
                                    value: "recommendation",
                                    label: "Recommendation",
                                },
                                { value: "challenge", label: "Challenge" },
                                { value: "practice", label: "Practice" },
                            ]}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label="Home Banner"
                        rules={[{ required: true }]}
                        validateStatus={!banner ? "error" : ""}
                        help={!banner ? "Field cannot be empty" : ""}
                    >
                        <RenderUpload
                            disabled={disableWrite}
                            path="home-explore/document/"
                            onChangeCustom={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "banner"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            label="upload an image"
                            value={banner}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label="Card Banner"
                        rules={[{ required: true }]}
                        validateStatus={!card_banner ? "error" : ""}
                        help={!card_banner ? "Field cannot be empty" : ""}
                    >
                        <RenderUpload
                            disabled={disableWrite}
                            path="home-explore/document/"
                            onChangeCustom={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "card_banner"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            label="upload an image"
                            value={card_banner}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label="Student Type"
                        rules={[{ required: true }]}
                        validateStatus={!student_type?.length ? "error" : ""}
                        help={
                            !student_type?.length ? "Field cannot be empty" : ""
                        }
                    >
                        {STUDENT_TYPES.map((item, key) => (
                            <Checkbox
                                disabled={disableWrite}
                                style={{ textTransform: "capitalize" }}
                                key={key}
                                onChange={(e) => {
                                    const value = e.target.checked;

                                    let tmpBlock = _.cloneDeep(block);

                                    let tempST = [...student_type];
                                    if (value) tempST.push(item);
                                    else
                                        tempST = tempST.filter(
                                            (i) => i !== item,
                                        );

                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "student_type"],
                                        tempST,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                checked={student_type?.includes(item)}
                            >
                                {item.split("_").join(" ")}
                            </Checkbox>
                        ))}
                    </Form.Item>
                </Col>

                <Col span={15}>
                    <Form.Item label="All Paths have blocks with solution">
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={has_solution}
                            disabled={disableWrite}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);

                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "has_solution"],
                                    value,
                                );

                                if (value) {
                                    const tempChildren = paths?.map(
                                        (item: any) => {
                                            const tempChildren =
                                                item?.children?.map(
                                                    (variant: any) => ({
                                                        ...variant,
                                                        has_solution: true,
                                                    }),
                                                );

                                            const tempItem = {
                                                ...item,
                                                children: tempChildren,
                                            };

                                            return {
                                                ...tempItem,
                                                has_solution: true,
                                            };
                                        },
                                    );
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "paths"],
                                        tempChildren,
                                    );
                                }

                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <hr />

            <h2
                style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    fontWeight: "bold",
                }}
            >
                Paths Details
            </h2>
            <div>
                {!disableWrite && (
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <Button
                            type="dashed"
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "paths", paths.length],
                                    {
                                        ...DEFAULT_PATH,
                                        order: paths.length + 1,
                                        id: uuid(),
                                        tmpId: uuid(),
                                    },
                                );
                                setBlock(tmpBlock);
                                updateCurrentBlock(paths.length);
                            }}
                        >
                            {"Add Path"}
                            <PlusOutlined style={{ fontSize: "15px" }} />
                        </Button>
                        <Input.Search
                            placeholder="Add Path with ID"
                            allowClear
                            enterButton="Add Path"
                            size="middle"
                            style={{
                                width: "500px",
                            }}
                            onSearch={(data) => {
                                if (!data) return;

                                let curPath = null;
                                blocks.some(
                                    (block: {
                                        children: {
                                            data: {
                                                paths: {
                                                    id: string;
                                                    tmpId: string;
                                                }[];
                                            };
                                        }[];
                                    }) =>
                                        block.children?.some((child) =>
                                            child?.data?.paths?.some((path) => {
                                                if (
                                                    path.id === data ||
                                                    path.tmpId === data
                                                ) {
                                                    curPath = path;
                                                    return true;
                                                }
                                                return false;
                                            }),
                                        ),
                                );

                                if (curPath) {
                                    let tmpBlock = _.cloneDeep(block);

                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", "paths", paths.length],
                                        updateKeysAndCopy(
                                            _.omit(curPath, [
                                                "id",
                                                "worksheet_block_map_id",
                                                "tmpId",
                                            ]),
                                            false,
                                        ),
                                    );
                                    setBlock(tmpBlock);
                                    updateCurrentBlock(paths.length);

                                    message.success("Path Added");
                                } else message.error("Path doesn't exist!");
                            }}
                        />
                    </div>
                )}
                <SortableList
                    items={paths}
                    onSortEnd={onSortEnd}
                    axis="xy"
                    useDragHandle
                />
                <Divider />
                {!loading && paths[currentSubBlock] && (
                    <PathEditor {...pathProps} />
                )}
            </div>
        </div>
    );
};

export default ProgressionEditor;
