import React, { useState, useEffect, useCallback } from "react";
import {
    focusEditor,
    getMark,
    getPluginType,
    select,
    setMarks,
    usePlateEditorRef,
    usePlateEditorState,
} from "@udecode/plate";
import { Form, Input } from "antd";

const DropShadowButton = ({
    id,
    pluginKey,
    label,
    placeholder,
}: {
    id?: string;
    pluginKey: string;
    label: string;
    placeholder?: string;
}): JSX.Element => {
    const editor = usePlateEditorState(id);
    const editorRef = usePlateEditorRef(id);

    const type = getPluginType(editorRef, pluginKey);

    const font = editorRef && (getMark(editorRef, type) as string);

    const [selectedFont, setSelectedFont] = useState<string>();

    useEffect(() => {
        if (editor?.selection) {
            setSelectedFont(font);
        }
    }, [font, editor?.selection]);

    const updateFont = useCallback(
        (value: string) => {
            if (editorRef && editor && editor.selection) {
                setSelectedFont(value);

                select(editorRef, editor.selection);
                // focusEditor(editorRef);

                setMarks(editor, { [type]: value });
            }
        },
        [editor, editorRef, type],
    );

    return (
        <Form.Item label={label} style={{ marginBottom: "0px" }}>
            <Input
                value={selectedFont}
                onChange={(e) => updateFont(e.target.value)}
                placeholder={placeholder}
            />
        </Form.Item>
    );
};

export default DropShadowButton;
