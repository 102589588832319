const LANGUAGES = [{ label: "Hindi", value: "Hindi" }];

const ARTISTS = [
    // { label: "Freya", value: "Freya" },
    { label: "Madhu (v2)", value: "Madhu_v2" },
    { label: "Shikha", value: "Shikha" },
    { label: "Madhu", value: "Madhu" },
];

const CLIENTS = [{ label: "ElevenLabs", value: "ElevenLabs" }];

const ELEVENLABS_VOICE_IDS: any = {
    // Freya: "q2Lk77OrIS2GWwC4GlQe",
    Shikha: "08he0gWQjoVZnRsi1ALW",
    Madhu: "YGaATdqy7Bl8arjfWPhl",
    Madhu_v2: "XeXqtVbGZphseIE5t8Ga",
};

export { LANGUAGES, ARTISTS, CLIENTS, ELEVENLABS_VOICE_IDS };
