import { isEditorEmpty } from "../../common";
import { BLOCK_TYPES } from "../../common/index";
const { V2_CONTENT_BLOCK } = BLOCK_TYPES;

const isValidCallbackV2Content = ({ block }: any) => {
    if (!block || !block.data)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            [V2_CONTENT_BLOCK]: { text, options },
        },
    } = block;
    return {
        isValid:
            !isEditorEmpty(text) && options.every((option: any) => !!option),
        error: (
            // <Alert
            //     message="Validation Error"
            //     description={`Question Text, options, correct option cannot be empty`}
            //     type="error"
            //     showIcon
            // />
            <></>
        ),
    };
};

export default isValidCallbackV2Content;
