import { PlusCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Form, Select, Input, Divider } from "antd";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import React from "react";
import CondiionsFormCard, { DIFFICULTY_OPTIONS } from "./CondiionsFormCard";

import { v4 as uuid } from "uuid";

const BlockJumpCard = (props: any) => {
    const { blocks, setBlock, idx, block } = props;

    const { data } = block;

    const { logic_difficulty } = data;

    const conditions = logic_difficulty?.conditions || [];

    return (
        <>
            <Badge.Ribbon color={"cyan"} text={idx + 1} placement={"start"}>
                <Card
                    key={idx}
                    title={null}
                    style={{
                        marginBottom: "20px",
                        border: "1px solid black",
                        borderRadius: "8px",
                    }}
                    bodyStyle={{
                        paddingBottom: 0,
                    }}
                >
                    <>
                        {conditions.map((item: any, i: any) => (
                            <div key={uuid()}>
                                <CondiionsFormCard
                                    conditionIndex={i}
                                    condition={item}
                                    blockId={idx}
                                    blocks={blocks}
                                    setBlock={setBlock}
                                    block={block}
                                    conditions={conditions}
                                />
                                <Divider
                                    style={{
                                        background: "black",
                                    }}
                                />
                            </div>
                        ))}

                        <Form.Item>
                            <Button
                                type="link"
                                onClick={() => {
                                    const tempConditions = [...conditions];

                                    tempConditions.push({
                                        rules: [
                                            {
                                                variable: "student_level",
                                                option: "easy",
                                                isEqual: true,
                                                conjunction: "and",
                                            },
                                            {
                                                variable: "question_number",
                                                option: null,
                                                isEqual: true,
                                                conjunction: "and",
                                            },
                                            {
                                                variable: "correct_count",
                                                option: null,
                                                isEqual: true,
                                                conjunction: "and",
                                            },
                                        ],
                                        logic_difficulty: null,
                                    });

                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "data",
                                            "logic_difficulty",
                                            "conditions",
                                        ],
                                        tempConditions,
                                    );

                                    setBlock(tmpBlock, idx);
                                }}
                            >
                                <PlusCircleOutlined /> Add rule
                            </Button>
                        </Form.Item>

                        <Form.Item
                            label={
                                logic_difficulty?.conditions?.length > 0
                                    ? "In ALL other cases change level to"
                                    : "Always change level to"
                            }
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                defaultValue={
                                    logic_difficulty?.default >= 0
                                        ? logic_difficulty?.default
                                        : null
                                }
                                onChange={(value) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["data", "logic_difficulty", "default"],
                                        value,
                                    );
                                    setBlock(tmpBlock, idx);
                                }}
                                options={[
                                    {
                                        value: null,
                                        label: `Dont Change`,
                                    },
                                    ...DIFFICULTY_OPTIONS,
                                ]}
                            />
                        </Form.Item>
                    </>
                </Card>
            </Badge.Ribbon>
        </>
    );
};

export default BlockJumpCard;
