import { ARTISTS, LANGUAGES, ELEVENLABS_VOICE_IDS } from "./constants";

const AUDIO_INFO_SCHEMA = {
    display_text: "",
    audio_texts: [], // AUDIO_TEXT_SCHEMA
    other: {},
};

const AUDIO_TEXT_SCHEMA = {
    text: "",
    language: LANGUAGES[0].value,
    artist: {
        name: ARTISTS[0].value,
        other: {
            voice_id: ELEVENLABS_VOICE_IDS[ARTISTS[0].value],
        },
    },
    audio_urls: [], // AUDIO_URL_SCHEMA
};

const AUDIO_URL_SCHEMA = {
    url: "",
    is_active: false,
    client: "",
    other: {},
};

export { AUDIO_INFO_SCHEMA, AUDIO_TEXT_SCHEMA, AUDIO_URL_SCHEMA };
