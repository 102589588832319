import React, { useState } from "react";
import { Button, Modal } from "antd";
import KeyboardForm from "./KeyboardForm";
import {
    ALPHABETS,
    DEFAULT_ALPHABETS_KEYBOARD,
    DEFAULT_DIGITS_KEYBOARD,
    DEFAULT_LATEX_KEYBOARD,
    DEFAULT_SETTINGS_KEYBOARD,
    DEFAULT_SYMBOLS_KEYBOARD,
    DIGITS,
    LATEX,
    SETTINGS,
    SYMBOLS,
    DEFAULT_CUSTOM_KEYBOARD as DCB,
} from "./constants";

export const DEFAULT_CUSTOM_KEYBOARD = DCB;

export const KeyboardError = ({ value, keyboards }: any) => {
    const allKeys = keyboards.map(({ keys }: any) => keys).flat();

    const missingKeys = [...new Set(value)].filter((i) => !allKeys.includes(i));
    return missingKeys.length ? (
        <div style={{ color: "#FF0000", padding: "4px 0", fontSize: "14px" }}>
            Error:{" "}
            {missingKeys.reduce((acc: string, k: string) => `${acc} ${k}`, "")}{" "}
            is missing from your keyboard. Please add them.
        </div>
    ) : (
        <></>
    );
};

export const CustomKeyboardEditor: React.FC<any> = ({
    value = DEFAULT_CUSTOM_KEYBOARD,

    onOk = (data: any) => {
        console.log(data);
    },
    disabled,
    hasLatex,
}: any) => {
    const customKeyboardIntialValue = value || DEFAULT_CUSTOM_KEYBOARD;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const defaultDigits =
        customKeyboardIntialValue.find(({ type }: any) => type === DIGITS)
            ?.keys || [];
    const [digits, setDigits] = useState(defaultDigits);

    const defaultSymbols =
        customKeyboardIntialValue.find(({ type }: any) => type === SYMBOLS)
            ?.keys || [];
    const [symbols, setSymbols] = useState(defaultSymbols);

    const defaultAlphabets =
        customKeyboardIntialValue.find(({ type }: any) => type === ALPHABETS)
            ?.keys || [];
    const [alphabets, setAlphabets] = useState(defaultAlphabets);

    const defaultLatex =
        customKeyboardIntialValue.find(({ type }: any) => type === LATEX)
            ?.keys || [];
    const [latex, setLatex] = useState(defaultLatex);

    const defaultSettings = customKeyboardIntialValue.find(
        ({ type }: any) => type === SETTINGS,
    ) || { ...DEFAULT_SETTINGS_KEYBOARD };
    const [settings, setSettings] = useState(defaultSettings);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        const customKeyboardValue = [];

        if (digits.length)
            customKeyboardValue.push({
                ...DEFAULT_DIGITS_KEYBOARD,
                keys: digits,
            });
        if (!hasLatex && symbols.length)
            customKeyboardValue.push({
                ...DEFAULT_SYMBOLS_KEYBOARD,
                keys: symbols,
            });

        if (alphabets.length)
            customKeyboardValue.push({
                ...DEFAULT_ALPHABETS_KEYBOARD,
                keys: alphabets,
            });

        if (hasLatex && latex?.length)
            customKeyboardValue.push({
                ...DEFAULT_LATEX_KEYBOARD,
                keys: latex,
            });

        // if (hasLatex) {
        customKeyboardValue.push(settings);
        // }

        onOk(customKeyboardValue);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type="primary" onClick={showModal} disabled={disabled}>
                Customize Keyboard
            </Button>
            {!value?.length ? (
                <span style={{ marginLeft: "20px", color: "red" }}>
                    Not Added!
                </span>
            ) : (
                <></>
            )}
            <Modal
                title="Customize Keyboard"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <KeyboardForm
                    {...{
                        hasLatex,
                        digits,
                        setDigits,
                        symbols,
                        setSymbols,
                        alphabets,
                        setAlphabets,
                        latex,
                        setLatex,
                        settings,
                        setSettings,
                    }}
                />
            </Modal>
        </>
    );
};

export default CustomKeyboardEditor;
