import renderBlockReOrder from "./renderBlock";
import renderEditorReOrder from "./renderEditor";
import isValidCallbackReOrder from "./isValid";
import RE_ORDER_DEFAULT_VALUE from "./defaultValue";
import renderSettingsReOrder from "./renderSettings";
import renderCardReOrder from "./renderCard";
import { BLOCK_TYPES, addCallback } from "../../common/index";

const { RE_ORDER_BLOCK } = BLOCK_TYPES;

const RE_ORDER_BLOCK_CONFIG = {
    [RE_ORDER_BLOCK]: {
        label: "Re-Order",
        defaultValue: RE_ORDER_DEFAULT_VALUE,
        renderBlock: renderBlockReOrder,
        renderEditor: renderEditorReOrder,
        renderCard: renderCardReOrder,
        renderSettings: renderSettingsReOrder,
        addCallback,
        isValidCallback: isValidCallbackReOrder,
    },
};
export {
    renderBlockReOrder,
    renderCardReOrder,
    renderEditorReOrder,
    renderSettingsReOrder,
    isValidCallbackReOrder,
    RE_ORDER_DEFAULT_VALUE,
    RE_ORDER_BLOCK_CONFIG,
};
