import { PlusOutlined } from "@ant-design/icons";
import { Form, Select, Space } from "antd";
import React from "react";

const FormItem = Form.Item;

export const RenderSelect = (props) => {
    const {
        icon,
        input,
        label,
        type,
        children,
        noBotMarging = false,
        meta: { touched, error },
        onChange,
        selectStyle,
        inFilter = false,
        span = 24,
        layout,
        defaultOpen = false,
        required,
        onChangeCallback,
        ...rest
    } = props;
    let validateStatus = "";
    if (touched && error) {
        validateStatus = "error";
    }

    const handleChange = (value) => {
        const { formik, name } = props;
        if (onChange) {
            onChange(value);
        } else {
            formik.handleChange({ target: { value, name } });
            if (onChangeCallback) {
                onChangeCallback(value);
            }
        }
    };

    let labels = inFilter
        ? {}
        : {
              labelCol: { span },
              wrapperCol: { span },
          };

    const labelObj = label
        ? {
              label: (
                  <Space align="left">
                      {icon && <PlusOutlined />}
                      {label}
                  </Space>
              ),
          }
        : {};

    return (
        <FormItem
            {...labelObj}
            layout="inline"
            validateStatus={validateStatus}
            help={error}
            style={{ width: "100%", marginBottom: noBotMarging && "0px" }}
            layout={layout}
            {...labels}
            required={required}
            hasFeedback
        >
            <Select
                style={{ align: "left" }}
                type={type}
                style={selectStyle}
                {...input}
                onChange={handleChange}
                defaultOpen={defaultOpen}
                {...rest}
            >
                {children}
            </Select>
        </FormItem>
    );
};

// RenderSelect.propTypes = {
//     formik: PropTypes.object.isRequired,
//     input: PropTypes.object,
//     label: PropTypes.string,
//     type: PropTypes.string,
//     meta: PropTypes.object,
//     onChange: PropTypes.func,
//     name: PropTypes.string.isRequired,
//     children: PropTypes.node,
//     selectStyle: PropTypes.object,
//     inFilter: PropTypes.bool,
//     noBotMarging: PropTypes.bool,
//     icon: PropTypes.string,
// };
