import { gql } from "@apollo/client";export default gql`query tabs(
    $where: home_explore_tab_bool_exp
) {
    tabs: home_explore_tab( where: $where) {
        id
        banners
        created_at
        deeplink_template
        description
        group
        header_link
        last_child
        title
        type
        updated_at
        status
        order
                build_number

        groups {
            id
            tab_id
            order
            group {
                cover
                description
                id
                other
                status
                title
                tags {
                    tag {
                        id
                        name
                        value
                        group
                    }
                }
                type
            }
        }
    }
}
`;
