import { QuestionList, QuestionCreate, QuestionEdit } from "./views";

import { Route } from "react-router-dom";
export const QUESTION_ROUTES = [
    <Route path="/question" element={<QuestionList />} />,

    <Route path="/question/create" element={<QuestionCreate />} />,
    <Route path="/question/update/:question_id" element={<QuestionEdit />} />,
];

export { QuestionList, QuestionCreate, QuestionEdit } from "./views";
