import renderBlockInputArrangeList from "./renderBlock";
import renderEditorInputArrangeList from "./renderEditor";
import isValidCallbackInputArrangeList from "./isValid";
import INPUT_ARRANGE_LIST_DEFAULT_VALUE from "./defaultValue";
import renderSettingsInputArrangeList from "./renderSettings";
import renderCardInputArrangeList from "./renderCard";
import { addCallback, BLOCK_TYPES } from "../../common";

const { INPUT_ARRANGE_LIST_BLOCK } = BLOCK_TYPES;

const INPUT_ARRANGE_LIST_BLOCK_CONFIG = {
    [INPUT_ARRANGE_LIST_BLOCK]: {
        label: "Input Arrange List",
        defaultValue: INPUT_ARRANGE_LIST_DEFAULT_VALUE,
        renderBlock: renderBlockInputArrangeList,
        renderEditor: renderEditorInputArrangeList,
        renderCard: renderCardInputArrangeList,
        renderSettings: renderSettingsInputArrangeList,
        addCallback,
        isValidCallback: isValidCallbackInputArrangeList,
    },
};

export {
    renderBlockInputArrangeList,
    renderCardInputArrangeList,
    renderEditorInputArrangeList,
    renderSettingsInputArrangeList,
    isValidCallbackInputArrangeList,
    INPUT_ARRANGE_LIST_DEFAULT_VALUE,
    INPUT_ARRANGE_LIST_BLOCK_CONFIG,
};
