import { isEditorEmpty } from "../../common";

const isValidCallbackV2Voice = ({ block }: any) => {
    if (!block)
        return {
            isValid: true,
            error: "",
        };
    const {
        data: {
            v2_voice: { title },
            other,
        },
    } = block;

    const description = other?.description || [];
    return {
        isValid: !isEditorEmpty(title) && !isEditorEmpty(description),
        error: <></>,
    };
};

export default isValidCallbackV2Voice;
