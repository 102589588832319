import { gql } from "@apollo/client";export default gql`query orders(
  $limit: Int = 12
  $offset: Int = 0
  $where: subscription_order_bool_exp # $type: String # $parent_id: uuid
) {
  orders: subscription_order(
    limit: $limit
    offset: $offset
    where: $where # order_by: { metadata: { created_at: desc } }
  ) {
    id
    human_readable_id
    created_at
    breakdown
    amount
    other
    payment_id
    status
    updated_at
    user_id
  }
}
`;
