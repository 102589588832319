import React from "react";
import { Card, Col, Row } from "antd";

import { renderer } from "../../helpers/";
import { renderTags } from "../../common/";

const renderBlockMCQ = (block: any, isMCQ = true) => {
    const {
        data: {
            mcq: { text, options, correct_options, solution },
            other,
        },
        heading,
        tags,
    } = block;

    const renderOption = (option: string, idx: number, isCorrect: boolean) => (
        <Card
            bodyStyle={{
                padding: "12px",
                background: isCorrect ? "#ECF87F" : "",
            }}
        >
            {renderer(option)}
        </Card>
    );

    return (
        <div>
            {renderTags(tags)}
            {other?.duration ? <span>Duration: {other?.duration}</span> : null}
            <Row gutter={[0, 12]}>
                <Col span={24}>
                    {heading && <h3>Question Text</h3>}
                    <Card bodyStyle={{ padding: "12px" }}>
                        {renderer(text)}
                    </Card>
                </Col>

                {heading && <h3>Options</h3>}
                {options.map((option: any, idx: number) => (
                    <Col span={24}>
                        {renderOption(
                            option,
                            idx,
                            correct_options[0] == idx + 1 && isMCQ,
                        )}
                    </Col>
                ))}

                {solution && (
                    <Col span={24}>
                        {heading && <h3>Solution</h3>}
                        <Card bodyStyle={{ padding: "12px" }}>
                            {renderer(solution)}
                        </Card>
                    </Col>
                )}

                {!isMCQ && Boolean(correct_options.length) && (
                    <>
                        <Col span={24}>
                            <h3>Correct Order</h3>
                        </Col>
                        {correct_options.map((correct_option: number) => (
                            <Col span={24}>
                                {renderOption(
                                    options[correct_option - 1],
                                    correct_option - 1,
                                    true,
                                )}
                            </Col>
                        ))}
                    </>
                )}
            </Row>
        </div>
    );
};
export default renderBlockMCQ;
